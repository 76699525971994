import { TableRow, TableCell, Typography, Stack, IconButton, TextField } from '@mui/material';
import SessionType from '../../types/SessionType';
import { shortFormat, shortHourFormat } from 'src/utils/formatTime';
import useObjectState from 'src/hooks/useObjectState';
import moment from 'moment';
import { EntityContextMenu } from 'src/sections/careflow/common';
import Iconify from 'src/components/Iconify';
import { SessionTableContext } from './DetailTableContext';
import { useContext } from 'react';

type SessionItemPropsType = {
  item: SessionType;
  onPress: (groupSession: SessionType) => void;
};

const SessionItem = ({ item, onPress }: SessionItemPropsType) => {
  const [{ isEditingNote, note }, updateState] = useObjectState<{
    showSessionUsersModal: boolean;
    selectedSession: SessionType | null;
    isEditingNote: boolean;
    note: string;
  }>({
    showSessionUsersModal: false,
    selectedSession: null,
    isEditingNote: false,
    note: '',
  });
  const detailTableContext = useContext(SessionTableContext);

  const renderTableCell = (title: string, item: SessionType) => (
    <TableCell onClick={() => onPress(item)}>{title}</TableCell>
  );

  const saveSessionNote = async (id: number) => {
    detailTableContext.updateSessionNote(id, note);
    updateState({
      isEditingNote: false,
      note: '',
    });
  };

  return (
    <TableRow style={{ cursor: 'pointer' }}>
      {renderTableCell(
        `${shortFormat(item.start)}, ${moment(item.start).format('hh:mm a')} - ${moment(
          item.end
        ).format('hh:mm a')}`,
        item
      )}
      {renderTableCell(
        item.groupSession.locationFhirName.split('*|*').join(', ') || 'No address',
        item
      )}
      {renderTableCell(
        `${item.sessionParticipants
          .filter((p) => p.attended)
          .length.toString()} / ${item.sessionParticipants.length.toString()}`,
        item
      )}
      {renderTableCell(item.status, item)}
      <TableCell sx={{ width: '300px' }}>
        <Stack direction="row" spacing={2}>
          {isEditingNote ? (
            <TextField
              value={note}
              onChange={(e) =>
                updateState({
                  note: e.target.value,
                })
              }
              sx={{
                height: '10px',
                top: '-8px',
              }}
            />
          ) : (
            <Typography variant="body2" sx={{ width: '210px', marginTop: '9px' }}>
              {item.note || 'N/A'}
            </Typography>
          )}

          <IconButton sx={{ top: '-7px' }}>
            {isEditingNote && note === item.note ? (
              <Iconify
                icon={'material-symbols:close-rounded'}
                fontSize={25}
                onClick={() =>
                  updateState({
                    note: '',
                    isEditingNote: false,
                  })
                }
              />
            ) : (
              <Iconify
                icon={isEditingNote ? 'mdi:content-save-check-outline' : 'eva:edit-2-outline'}
                fontSize={25}
                onClick={() => {
                  if (isEditingNote) {
                    saveSessionNote(item.id);
                  } else {
                    updateState((prev) => ({
                      note: item.note,
                      isEditingNote: !prev.isEditingNote,
                    }));
                  }
                }}
              />
            )}
          </IconButton>
        </Stack>
      </TableCell>
      <TableCell>
        <EntityContextMenu
          entity={item}
          items={[
            {
              label: 'Edit',
              icon: 'eva:edit-2-outline',
              onPress: () => {
                detailTableContext.setSelectedSession(item);
                detailTableContext.openAddEntityModal();
              },
            },
          ]}
        />
      </TableCell>
    </TableRow>
  );
};

export default SessionItem;
