import { PaginatedTaskResponse } from 'src/@types/crs/task';
import { PaginateQuery, paginateQueryToURLParams } from 'src/api/pagination/dtos';
import client from './_client';
import { convertFilterToPaginateQueryParams } from 'src/utils/gridView';

const baseUrl = '/task';

export const getTasks = async (
  queryParams: PaginateQuery
): Promise<PaginatedTaskResponse | null> => {
  const urlParams = paginateQueryToURLParams(queryParams);
  urlParams.delete('sortBy');
  let sortingBy = '';
  if (queryParams?.sortBy?.length) {
    const [orderBy, direction] = queryParams?.sortBy?.[0];
    sortingBy = `&sortBy=${orderBy}:${direction}`;
  }
  const filters = convertFilterToPaginateQueryParams(queryParams?.filter ?? {});
  const url = `${baseUrl}?${urlParams.toString()}${sortingBy}${filters}`;
  try {
    const response = await client.get<PaginatedTaskResponse>(url);
    return response.data;
  } catch (error) {
    return null;
  }
};
