import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router';
import api from 'src/services/api';
import { _goals } from 'src/_mock/settings/_careplan-automation';
import GoalsForm from './GoalsForm';
import GoalsTable from './GoalsTable';
import GoalsToolbar from './GoalsToolbar';

function GoalsTab() {
  return (
    <Routes>
      <Route index element={<GoalsList />} />
      <Route path="new" element={<GoalsForm />} />
      <Route path="edit/:id" element={<GoalsEditForm />} />
    </Routes>
  );
}

function GoalsList() {
  return (
    <>
      <GoalsToolbar />
      <GoalsTable />
    </>
  );
}

function GoalsEditForm() {
  const params = useParams();
  const {
    data: goal,
    isLoading,
    refetch,
  } = useQuery(['goals', params.id], async () => {
    if (!params.id) {
      return null;
    }

    return api.carePlan.getGoal(params.id);
  });

  useEffect(() => {
    refetch();
  }, []);

  if (!goal && !isLoading) return <Navigate to=".." />;
  return <GoalsForm isEdit currentGoal={goal} />;
}

export default Object.assign(GoalsTab, {
  path: 'goals',
});
