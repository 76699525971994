import { TextField, TextFieldProps, SxProps } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

const SearchTextField = ({ sx, InputProps, ...props }: TextFieldProps): JSX.Element => (
  <TextField
    sx={[{ color: '#919eab' }, ...(Array.isArray(sx) ? sx : [sx])]}
    id="outlined-search"
    type="search"
    placeholder="Search...."
    variant="outlined"
    InputProps={{
      startAdornment: <SearchIcon htmlColor="#919eab" />,
      ...(InputProps ?? {}),
    }}
    {...props}
  />
);

export default SearchTextField;
