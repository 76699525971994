import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Image from '../../../components/Image';
import { useFormContext } from 'react-hook-form';
import Iconify from '../../../components/Iconify';
import { alpha, styled } from '@mui/material/styles';
import getFileData from '../../../utils/getFileData';
import { varFade } from '../../../components/animate';
import { getFileUpload } from 'src/utils/getFileFormat';
import { UploadMultiFileProps } from 'src/components/upload';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';

const DropZoneStyle = styled('div')(({ theme }) => ({
  fontSize: 24,
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  border: `dashed 1px ${theme.palette.divider}`,
  '&:hover': { opacity: 0.72 },
}));

type Props = {
  restrictFiles?: boolean;
};

export default function Upload({restrictFiles}: Props) {
 
  const {
    watch,
    setValue,
  } = useFormContext();

  const values = watch();

  const handleRemove = (file: File | string) => {
    const filteredItems = values.files?.filter((_file:string) => _file !== file);
    setValue('files', filteredItems);
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const images = values.files || [];
      setValue('files', [
        ...images,
        ...acceptedFiles.map((file: Blob ) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        ),
      ]);
    },
    [setValue, values.files]
  );

  return (
    <>
      <UploadFile onDrop={handleDrop} files={values.files} restrictedFile={restrictFiles}/>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
        {values.files?.map((file:string, index:number) => {
          const { key, name } = getFileData(file, index);

          return (
            <Stack key={`stack-${index}`} direction="column" alignItems="center" justifyContent="center">
              <UploadFilePreview key={key} file={file} onRemove={() => handleRemove(file)} />
              <Typography variant='caption' noWrap sx={{ maxWidth: 120 }}>{name}</Typography>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
}

type UploadFilePreviewProps = {
  file: File | string;
  onRemove: VoidFunction;
};

function UploadFilePreview({ file, onRemove }: UploadFilePreviewProps) {
  const { preview, name } = getFileData(file);

  let filePreview = getFileUpload(name!, preview!);

  return (
    <Box
      {...varFade().inRight}
      sx={{
        p: 0,
        m: 0.5,
        width: 64,
        height: 64,
        borderRadius: 1.25,
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Image
        alt="preview"
        src={filePreview}
        sx={{
          height: 1,
          position: 'absolute',
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
        title={name}
      />

      <Box sx={{ top: 6, right: 6, position: 'absolute' }}>
        <IconButton
          size="small"
          onClick={onRemove}
          sx={{
            p: '2px',
            color: 'common.white',
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
            },
          }}
        >
          <Iconify icon={'eva:close-fill'} />
        </IconButton>
      </Box>
    </Box>
  );
}

type UploadFileProps =  UploadMultiFileProps;

function UploadFile({ files, restrictedFile, disabled, ...other }: UploadFileProps) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...other,
  });

  const buttonDisabled = files?.length === 2 ? true : false;

  return (
    <DropZoneStyle
      {...getRootProps()}
      sx={{
        ...(isDragActive && { opacity: 0.72 }),
        ...((buttonDisabled && restrictedFile) && {pointerEvents: 'none'})
      }}
    >
      <input {...getInputProps()} />

      { restrictedFile ? 
        (
          <Button variant="contained" color='info' disabled={buttonDisabled}>
            {!files?.length && (
              'Upload Front'
            )}
            {files?.length > 0 && (
              'Upload Back'
            )}
          </Button>
        ) : 
        <Button variant="contained" color='info'> Upload Notes </Button>
      }
      
    </DropZoneStyle>
  );
}
