import { Card, IconButton, Stack, Typography } from '@mui/material';
import { AnimatePresence, m } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/Iconify';
import { SHOW_FAMILY_CONTACTS, useEventBus } from 'src/event-bus';
import { Carousel } from 'src/sections/careflow/common'; // Move component to package
import { useCurrentPatient } from 'src/stores/patient-tabs';
import AdmissionDetailsView from '../../admissionDetails/AdmissionDetailsSummary';
import PatientInfo from './PatientInfo';
import AllergiesSummary from '../../allergy/AllergiesSummary';
import DiagnosisSummary from './diagnosis-summary/DiagnosisSummary';

// ----------------------------------------------------------------------
interface IPatientInfoHeaderProps {
  hideForesightStats?: boolean;
  patient?: {
    id: string;
  };
  displayMode: boolean;
}

const PatientInfoHeader = ({ hideForesightStats, patient: propPatient, displayMode = false }: IPatientInfoHeaderProps) => {
  const selectedPatient = useCurrentPatient();
  let patient: any = selectedPatient;

  if (propPatient) {
    patient = propPatient;
  }

  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [currentCarousel, setCurrentCarousel] = useState(0);

  const { onEvent, removeEvent } = useEventBus();
  const callback = () => {
    setCurrentCarousel(5);
    setExpanded(true);
  };

  useEffect(() => {
    onEvent(SHOW_FAMILY_CONTACTS, callback);
    return () => removeEvent(SHOW_FAMILY_CONTACTS, callback);
  });

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <Card>
      <PatientInfo
        patient={propPatient}
        hideForesightStats={hideForesightStats}
        onShowMorePrograms={() => {
          setCurrentCarousel(4);
          setExpanded(true);
        }}
        onShowMorePlanIdentifiers={() => {
          navigate('/dashboard/patient-demographics#identifiers');
        }}
      />

      <Stack px={3}>
        {patient && (
          <AnimatePresence>
            {expanded && (
              <m.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <Carousel
                  current={currentCarousel}
                  onChange={({ slide, behavior }) => {
                    setCurrentCarousel(slide);
                  }}
                >
                  {/* <PersonalDetails patient={patient} /> */}
                  <AdmissionDetailsView patient={patient} />
                  <DiagnosisSummary
                    patient={patient}
                    showButtons={!displayMode}
                  />
                  
                  {/* <CareTeam patient={patient} /> */}
                  {/* <AllergiesDetails patient={patient} /> */}
                  <AllergiesSummary
                    patient={patient}
                    isExpanded={false}
                    onExpand={function (index: number): void {
                      throw new Error('Function not implemented.');
                    }}
                    showButtons={!displayMode}
                  />
                  {/* <ProgramDetails patient={patient} /> */}
                  {/* <Contacts patient={patient} /> */}
                </Carousel>
              </m.div>
            )}
          </AnimatePresence>
        )}

        <Stack direction="row" justifyContent="flex-end" spacing={12}>
          <IconButton onClick={toggleExpanded}>
            <Typography>More information</Typography>
            <Iconify
              icon={expanded ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
            />
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
};

export default PatientInfoHeader;
