import { useState, useEffect } from 'react';
import TasksGrid, { TaskFilters, initialTaskFilters } from './TasksGrid/TasksGrid';
import { WrappedTask } from '../../../../@nicheaim/fhir-base/wrappers/Task';
import { TaskGridRowData, onSuccess } from '../../../../@types/crs/case';
import { getTaskGridRows, searchIfContainedInObj } from 'src/sections/crs/helpers/common';
import { Reference } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import useGridFilters, { GridFilters } from 'src/hooks/useGridFilters';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import moment from 'moment';
import { isBetweenDates } from 'src/utils/dates';
import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import { WrappedHealthcareService } from 'src/@nicheaim/fhir-base/wrappers/HealthcareService';

export interface CarePlanTaskGridProps {
  tasks: WrappedTask[];
  patient: WrappedPatient | null;
  carePlan?: WrappedCarePlan | null;
  goal?: WrappedGoal | null;
  healthCareServices?: WrappedHealthcareService[] | null;
  isNestedGrid?: boolean;
  showPagination?: boolean;
  onSuccessfulCreation: onSuccess;
  onSuccessfulEdit: onSuccess;
  title?: string;
}

const CaseTaskGrid = ({
  tasks,
  isNestedGrid,
  showPagination,
  patient,
  carePlan,
  goal,
  healthCareServices,
  onSuccessfulCreation,
  onSuccessfulEdit,
  title,
}: CarePlanTaskGridProps) => {
  const {
    isFilterDrawerOpen,
    onFilterDrawerOpen,
    onFilterDrawerClose,
    onSearchTextFieldChange,
    searchTextFieldValue,
    filters,
    onApplyFilters,
  } = useGridFilters<TaskFilters>(initialTaskFilters);
  const [taskGridRows, setTasksGridRows] = useState<TaskGridRowData[]>([]);
  const [owners, setOwners] = useState<Reference[]>([]);

  const {
    valueSets: [taskStatuses],
  } = useValueSetsByIdentifiers(['crs-task-status']);

  useEffect(() => {
    setOwners(
      tasks.reduce<Reference[]>((owners, { owner }) => {
        if (!owner) return owners;
        if (owners.find(({ reference }) => reference === owner?.reference)) return owners;
        return [...owners, owner];
      }, [])
    );
  }, [tasks]);

  useEffect(() => {
    const taskGridRows = getTaskGridRows(tasks, taskStatuses?.asList?.() ?? []);
    setTasksGridRows(filterTaskGridRows(taskGridRows, { filters, searchTextFieldValue }));
  }, [tasks, filters, searchTextFieldValue, taskStatuses]);
  return (
    <TasksGrid
      patient={patient}
      tasks={taskGridRows}
      owners={owners}
      goal={goal}
      searchTextFieldValue={searchTextFieldValue}
      onSearchTextFieldChange={onSearchTextFieldChange}
      isFilterDrawerOpen={isFilterDrawerOpen}
      onFilterDrawerOpen={onFilterDrawerOpen}
      onFilterDrawerClose={onFilterDrawerClose}
      onApplyFilters={onApplyFilters}
      filterValues={filters}
      isNestedGrid={isNestedGrid}
      showPagination={showPagination}
      onSuccessfulCreation={onSuccessfulCreation}
      onSuccessfulEdit={onSuccessfulEdit}
      carePlan={carePlan}
      healthCareServices={healthCareServices}
      title={title}
    />
  );
};

export const filterTaskGridRows = (
  taskGridRows: TaskGridRowData[],
  { filters, searchTextFieldValue }: GridFilters<TaskFilters>
): TaskGridRowData[] => {
  const { status, startDate, endDate, selectedOwners } = filters;
  let taskGridRowsFiltered = [...taskGridRows];
  const searchByString = searchTextFieldValue?.toLowerCase().trim() ?? '';
  if (searchByString.length >= 3) {
    taskGridRowsFiltered = taskGridRowsFiltered.filter((taskGridRow) =>
      searchIfContainedInObj(
        taskGridRow,
        ['task', 'owner', 'endDate.message', 'status'],
        searchByString
      )
    );
  }

  return taskGridRowsFiltered.filter((taskRow) => {
    const task = taskRow.wrappedTask;
    const taskStartDate = moment(task?.getStartDate() ?? null);
    const taskEndDate = moment(task?.getEndDate() ?? null);
    if (startDate?.isValid?.() && endDate?.isValid?.()) {
      if (!taskStartDate.isValid() || !taskEndDate.isValid()) return false;
      if (!isBetweenDates(taskStartDate, taskEndDate, startDate, endDate)) return false;
    }

    if (startDate?.isValid?.()) {
      if (!taskStartDate.isValid()) return false;
      if (!taskStartDate.isSameOrAfter(startDate)) return false;
    }

    if (endDate?.isValid?.()) {
      if (!taskEndDate.isValid()) return false;
      if (!taskEndDate.isSameOrBefore(endDate)) return false;
    }

    if (selectedOwners.length) {
      if (!selectedOwners.find((owner) => task?.owner?.reference === owner.reference)) return false;
    }

    if (status.length) {
      if (!status.find((status) => status.code === task?.status)) return false;
    }
    return true;
  });
};

export default CaseTaskGrid;
