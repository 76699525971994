import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
// routes
import { getRouter } from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { EventBusProvider } from './event-bus';
import { FhirClientProvider } from './@nicheaim/fhir-react';
import { createAxiosFhirClient } from './@nicheaim/fhir-base/clients/axios';
import axiosFhirInstance from './application/adapters/out/repositories/axiosFhirInstance';
import { DialogRenderer } from './stores/dialog';
import {
  getInitialPatientContext,
  PatientContext,
  PatientContextProvider,
} from './application/adapters/in/ui/contexts/PatientContext';
import { RouterProvider } from 'react-router';
import StandardLoadingBackdropProvider from './sections/careflow/common/StandardLoadingBackdropProvider';
import { createAxiosIndexedFhirClient } from './@nicheaim/fhir-base/clients/axios-indexed';
import axiosIndexedFhirInstance from './application/adapters/out/repositories/axiosIndexedFhirInstance';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import RefreshSSO from './sections/careflow/common/RefreshSSO';

// ----------------------------------------------------------------------

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});
export const fhirClient = createAxiosFhirClient(axiosFhirInstance);
export const indexedFhirClient = createAxiosIndexedFhirClient(
  axiosIndexedFhirInstance,
  axiosFhirInstance
);

const patientContext = ((): PatientContext => {
  const { patient, admissionDetails, careTeam } = getInitialPatientContext();
  return {
    patient: {
      ...patient,
      id: '81d5f8c4-f0bb-49ee-9ee2-183d30160dc2',
    },
    admissionDetails: {
      ...admissionDetails,
      id: '854c06de-af63-44dc-89e7-c7ffbac0e8ad',
    },
    careTeam: {
      ...careTeam,
      id: '078a5765-dfd7-41db-8d44-90ee7701c1c4',
    },
  };
})();

const router = getRouter();

export default function App() {
  return (
    <RefreshSSO>
      <QueryClientProvider client={queryClient}>
        <FhirClientProvider
          clients={{
            default: fhirClient,
            indexed: indexedFhirClient,
          }}
        >
          <MotionLazyContainer>
            <ThemeProvider>
              <ThemeSettings>
                <EventBusProvider>
                  <NotistackProvider>
                    <StandardLoadingBackdropProvider>
                      <DialogRenderer />
                      <ProgressBarStyle />
                      <ChartStyle />
                      <PatientContextProvider value={patientContext}>
                        <RouterProvider router={router} />
                      </PatientContextProvider>
                    </StandardLoadingBackdropProvider>
                  </NotistackProvider>
                </EventBusProvider>
              </ThemeSettings>
            </ThemeProvider>
          </MotionLazyContainer>
        </FhirClientProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </RefreshSSO>
  );
}
