import { memo, useMemo, useState, useEffect } from 'react';
import { WrappedCareTeam } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import CareTeamGrid, { CareTeamFilters, initialCareTeamFilters } from './CareTeamGrid/CareTeamGrid';
import useGridFilters, { GridFilters } from 'src/hooks/useGridFilters';
import { CRSGridProps } from 'src/@types/crs/case';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import { Reference } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { debounce } from 'src/utils/timers';
import { searchIfContainedInObj } from '../../helpers/common';
import { Collapse, Grid, IconButton, Stack, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
interface CaseCareTeamGridProps extends CRSGridProps {
  patient: WrappedPatient;
  careTeams: WrappedCareTeam[];
  onMemberUpdateSuccess: Function;
  carePlan: WrappedCarePlan;
  openCollapseExternal: boolean;
}

const CaseCareTeamGrid = memo(
  ({
    careTeams,
    patient,
    onMemberUpdateSuccess,
    onSuccessfulCreation,
    onSuccessfulEdit,
    carePlan,
    openCollapseExternal,
  }: CaseCareTeamGridProps) => {
    const [filteredCareTeams, setFilteredCareTeams] = useState<WrappedCareTeam[]>([]);

    const { filters, searchTextFieldValue, ...gridFiltersProps } =
      useGridFilters<CareTeamFilters>(initialCareTeamFilters);

    const handleFiltersChange = useMemo(
      () =>
        debounce((careTeams, filters, searchTextFieldValue) => {
          setFilteredCareTeams(filterCareTeams(careTeams, { filters, searchTextFieldValue }));
        }, 800),
      []
    );

    useEffect(() => {
      handleFiltersChange(careTeams, filters, searchTextFieldValue);
    }, [filters, searchTextFieldValue, careTeams, handleFiltersChange]);

    const managingOrganizations = useMemo(
      () =>
        careTeams?.reduce<Reference[]>(
          (allManagingOrganizations, careTeam) => [
            ...allManagingOrganizations,
            ...(careTeam?.managingOrganization?.reduce<Reference[]>((managingOrganizations, mg) => {
              if (
                managingOrganizations.find(({ reference }) => reference === mg.reference) ||
                allManagingOrganizations.find(({ reference }) => reference === mg.reference)
              )
                return managingOrganizations;
              return [...managingOrganizations, mg];
            }, []) ?? []),
          ],
          []
        ),
      [careTeams]
    );

    const [openCollapse, setOpenCollapse] = useState(false);

    useEffect(() => {
      setOpenCollapse(openCollapseExternal);
    }, [openCollapseExternal]);

    return (
      <>
        <Stack sx={{ ml: 2 }}>
          <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
            <Grid item xs={10}>
              <Typography sx={{ ml: 1 }}>
                List of Care Teams & Members
                <IconButton onClick={() => setOpenCollapse(!openCollapse)}>
                  <Iconify
                    icon={
                      openCollapse ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'
                    }
                  />
                </IconButton>
              </Typography>
            </Grid>
          </Grid>
        </Stack>
        <Collapse in={openCollapse}>
          <CareTeamGrid
            searchTextFieldValue={searchTextFieldValue}
            carePlan={carePlan}
            patient={patient}
            managingOrganizations={managingOrganizations}
            onMemberUpdateSuccess={onMemberUpdateSuccess}
            onSuccessfulCreation={onSuccessfulCreation}
            onSuccessfulEdit={onSuccessfulEdit}
            careTeams={filteredCareTeams}
            filterValues={filters}
            {...gridFiltersProps}
          />
        </Collapse>
      </>
    );
  }
);

const filterCareTeams = (
  careTeams: WrappedCareTeam[],
  { filters, searchTextFieldValue }: GridFilters<CareTeamFilters>
): WrappedCareTeam[] => {
  let filteredCareTeams = [...careTeams];

  const searchByString = searchTextFieldValue?.toLowerCase().trim() ?? '';
  if (searchByString.length >= 1) {
    filteredCareTeams = filteredCareTeams.filter((careTeam) =>
      searchIfContainedInObj(
        careTeam,
        [
          'name',
          'noOfMembers',
          'plainCategory',
          'managingOrganizationName',
          'severityStatus.message',
        ],
        searchByString
      )
    );
  }

  const { category, managingOrganization, status } = filters;

  return filteredCareTeams.filter((careTeam) => {
    if (status.length) {
      if (!status.some((status) => status.code === careTeam?.status)) return false;
    }

    if (managingOrganization.length) {
      if (
        !managingOrganization.some((mg) =>
          careTeam?.managingOrganization?.find((ctMG) => ctMG.reference === mg.reference)
        )
      )
        return false;
    }

    if (category.length) {
      if (
        !category.some((cat) =>
          careTeam?.category?.find((ctCat) => ctCat?.coding?.[0]?.code === cat.code)
        )
      )
        return false;
    }

    return true;
  });
};
export default CaseCareTeamGrid;
