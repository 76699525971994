import {
  Box,
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { truncate } from 'lodash';
import { useState } from 'react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { useOrganization } from 'src/@nicheaim/fhir-react';
import fhirSystem from 'src/fhir/system';

interface IIdentifierItem {
  title: string;
  value: string;
}

interface IPatientIdentifiersProps {
  patient: WrappedPatient;
}

const PatientIdentifiers = ({ patient }: IPatientIdentifiersProps) => {
  const identifiersList: IIdentifierItem[] = [];

  if (patient.getSSN()?.value) {
    identifiersList.push({
      title: 'Social Security Number',
      value: patient.getSSN()?.value || '',
    });
  }

  console.log({
    identifiers: patient.identifier,
  });

  return (
    <Card>
      <Grid container>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 2 }}
          xs={12}
        >
          <Grid item>
            <Typography variant="h6">Identifiers</Typography>
          </Grid>
          <Grid item></Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: '400px', overflow: 'auto' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patient.identifier
                    ?.filter(
                      (identifier) =>
                        identifier.system?.startsWith(
                          fhirSystem.healthPlan.withId('').asString()
                        ) && identifier.value
                    )
                    .map((identifier, index) => (
                      <PatientPlanIdentifier
                        key={index}
                        planId={identifier.system?.split('/').at(-1) || ''}
                        patientPlanId={identifier.value || ''}
                      />
                    ))}
                  {patient.identifier
                    ?.filter(
                      (identifier) =>
                        !identifier.system?.startsWith(
                          fhirSystem.healthPlan.withId('').asString()
                        ) &&
                        identifier.type?.coding?.[0]?.code !== 'MasterID' &&
                        identifier.type?.coding?.[0]?.code !== 'Patient UUID' &&
                        identifier.type?.text !== 'Patient UUID' &&
                        identifier.type?.text !== 'MasterID' &&
                        identifier.value
                    )
                    .map((identifier, index) => {
                      let type = identifier?.type?.text ?? identifier?.type?.coding?.[0].code;

                      if (identifier?.system?.toLowerCase()?.includes('us-ssn')) {
                        type = 'Social security number';
                      }
                      if (identifier?.system?.toLowerCase()?.includes('code=mrn&name=mrn')) {
                        type = 'MRN';
                      }
                      return (
                        <PatientPlanIdentifier
                          key={index}
                          type={type}
                          patientPlanId={identifier.value || ''}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

interface PatientPlanIdentifierProps {
  planId?: string;
  patientPlanId: string;
  type?: string;
}

function PatientPlanIdentifier({ planId, patientPlanId, type }: PatientPlanIdentifierProps) {
  const [plan, { isLoading }] = useOrganization(planId, {
    autofetch: type ? false : true,
  });

  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle2">
          {type ??
            (isLoading ? 'Loading...' : plan?.name || `Unnamed Plan {id: ${planId ?? 'N/A'}}`)}
        </Typography>
      </TableCell>
      <TableCell>
        <Stack>
          <Typography variant="body2">{patientPlanId}</Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );
}

export default PatientIdentifiers;
