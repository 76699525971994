// @ts-nocheck
import { useEffect, useState } from 'react';
import produce from 'immer';
import {
  Autocomplete,
  Button,
  Checkbox,
  createFilterOptions,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  TextField,
  Typography,
  FormControl,
  Grid,
  Divider,
  CircularProgress,
  Box,
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import {
  useCareTeams,
  usePractitioner,
  usePractitioners,
  useRelatedPerson,
  useRelatedPersons,
} from 'src/@nicheaim/fhir-react';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import {
  getCareTeamRolesExcludingLeader,
  isCareTeamLeader,
  normalizeAddress,
  normalizePhones,
} from './utils';
import { RelatedPersonWrapper } from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import PhoneField from './personal-details/PhoneField';
import EmailField from './personal-details/EmailField';
import AddressField from './personal-details/AddressField';
import { CareTeamParticipant } from 'src/application/adapters/in/ui/model';
import { PatientCard, PromptModal } from 'src/sections/careflow/common';
import { useEventBus } from 'src/event-bus';
import { Address } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

const filter = createFilterOptions<any>();

type EditCareTeamFormPropsType = {
  onSaveCallback?: (careTeam: CareTeamParticipant) => void;
  data: CareTeamParticipant;
  closeForm: () => void;
};

const renderActionButtons = (handleSave: () => void, clearAll: () => void, isLoading: boolean) => (
  <Grid container spacing={2} direction="row">
    <Grid item xs={6} style={{ marginTop: '15px' }}>
      <FormControl fullWidth>
        <Button
          disabled={isLoading}
          onClick={clearAll}
          size="large"
          fullWidth
          variant="outlined"
          color="secondary"
        >
          Clear all
        </Button>
      </FormControl>
    </Grid>
    <Grid item xs={6} style={{ marginTop: '15px' }}>
      <FormControl fullWidth>
        <Button
          disabled={isLoading}
          onClick={handleSave}
          size="large"
          style={{ width: '100%' }}
          variant="contained"
          color="secondary"
        >
          {isLoading ? <CircularProgress size={15} /> : 'Save'}
        </Button>
      </FormControl>
    </Grid>
  </Grid>
);

const EditPractitioner = ({ data, onSaveCallback, closeForm }: EditCareTeamFormPropsType) => {
  const patient = useCurrentPatient();
  const { dispatchEvent } = useEventBus();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const id = data?.member?.reference?.split('/')?.[1];
  const [participant, setParticipant] = useState<any>('');
  const [role, setRole] = useState<string | undefined>('');
  const [phones, setPhones] = useState<any>([{ system: 'phone', use: 'mobile' }]);
  const [emails, setEmails] = useState<any>([{ system: 'email', use: 'home' }]);
  const [address, setAddress] = useState<Address>({
    use: 'home',
    line: [],
    city: '',
    state: '',
    postalCode: '',
  });
  const [isLeader, setIsLeader] = useState(isCareTeamLeader(data));
  const [showTeamLeaderWarning, setShowTeamLeaderWarning] = useState<boolean>(false);

  const [initParticipant] = usePractitioner(id, {
    map: PractitionerWrapper,
  });

  const [careTeams, { update: updateCareTeams }] = useCareTeams({
    filter: {
      patient: patient?.id,
      _count: 1000,
    },
  });
  const [practitioners, { update: updatePractitioners }] = usePractitioners({
    filter: { active: true,  _count: 1000, },
    map: PractitionerWrapper,
  });
  const [relatedPersons] = useRelatedPersons({
    map: RelatedPersonWrapper,
    filter: {
      _count: 1000,
    }
  });

  useEffect(() => {
    if (initParticipant) {
      setParticipant(initParticipant);
      const initRoles = getCareTeamRolesExcludingLeader(data);
      const initEmails = initParticipant?.getEmails();
      const initPhones = initParticipant?.getPhones();
      const initAddress = initParticipant?.getPrimaryAddress();

      setRole(initRoles);
      setPhones(
        initPhones?.length && initPhones?.length > 0
          ? initPhones
          : [{ system: 'phone', use: 'mobile' }]
      );
      setEmails(
        initEmails?.length && initEmails?.length > 0
          ? initEmails
          : [{ system: 'email', use: 'home' }]
      );
      setAddress(initAddress || { use: 'home', line: [], city: '', state: '', postalCode: '' });
    }
  }, [data, initParticipant]);

  const clearAll = () => {
    setRole('');
    setParticipant(null);
  };

  function handleAddPhone(): void {
    setPhones([...phones, { system: 'phone', use: 'mobile' }]);
  }

  function handleChangePhoneType(type: string, index: number): void {
    const updatedPhones = [...phones];
    updatedPhones[index] = { ...updatedPhones[index], use: type };
    setPhones(updatedPhones);
  }

  function handleChangePhoneValue(value: string, index: number): void {
    const updatedPhones = [...phones];
    updatedPhones[index] = { ...updatedPhones[index], value };
    setPhones(updatedPhones);
  }

  function handleFavoritePhone(value: any, index: number): void {
    const updatedPhones = phones.map((e: any) => ({ ...e, rank: 2 }));
    if (!value) {
      updatedPhones[index].rank = 1;
    }
    setPhones(updatedPhones);
  }

  function handleRemovePhone(index: number): void {
    setPhones([...phones.slice(0, index), ...phones.slice(index + 1)]);
  }

  function handleAddEmail(): void {
    setEmails([...emails, { system: 'email', use: 'home' }]);
  }

  function handleChangeEmailType(type: string, index: number): void {
    const updatedEmails = [...emails];
    updatedEmails[index] = { ...updatedEmails[index], use: type };
    setEmails(updatedEmails);
  }

  function handleChangeEmailValue(value: string, index: number): void {
    const updatedEmails = [...emails];
    updatedEmails[index] = { ...updatedEmails[index], value };
    setEmails(updatedEmails);
  }

  function handleFavoriteEmail(value: any, index: number): void {
    const updatedEmails = emails.map((e: any) => ({ ...e, rank: 2 }));
    if (!value) {
      updatedEmails[index].rank = 1;
    }
    setEmails(updatedEmails);
  }

  function handleRemoveEmail(index: number): void {
    setEmails([...emails.slice(0, index), ...emails.slice(index + 1)]);
  }

  function handleAddAddress(): void {
    throw new Error('Function not implemented.');
  }

  function handleChangeAddress(addr: any): void {
    throw new Error('Function not implemented.');
  }

  function handleRemoveAddress(): void {
    throw new Error('Function not implemented.');
  }

  const updateCareTeam = async () => {
    const editIndex = careTeams[0].participant!.findIndex(
      (p) => p?.member?.reference === data.member.reference
    );

    let updatedMembers: any[] = [...(careTeams[0].participant?.filter((p) => p.role) || [])];
    if (isLeader) {
      updatedMembers = updatedMembers.map((member) => ({
        ...member,
        role: member.role?.filter((rol: any) => rol.text !== 'Team Leader'),
      }));
    }

    const updatedMember = {
      member: {
        reference: `${participant.resourceType}/${participant.id}`,
        display: participant?.getFullNameWithTitles(),
      },
      role: [
        ...(updatedMembers[editIndex]?.role?.filter(
          (r) => r?.coding?.[0]?.system !== 'careTeamRole'
        ) || []),
        {
          coding: [
            {
              system: 'careTeamRole',
            },
          ],
          text: role,
        },
        ...(isLeader
          ? [
              {
                coding: [
                  {
                    system: 'careTeamRole',
                  },
                ],
                text: 'Team Leader',
              },
            ]
          : []),
      ],
    };

    updatedMembers[editIndex] = updatedMember;

    await updateCareTeams(
      produce(careTeams[0], (draft) => {
        draft.participant = updatedMembers;
      })
    ).then(() => {
      onSaveCallback?.(updatedMember);
      closeForm();
    });
  };

  const updateParticipant = async () => {
    await updatePractitioners(
      produce(initParticipant!, (draft) => {
        draft.telecom = [...normalizePhones(phones), ...emails];
        draft.address = [normalizeAddress(address)];
      })
    );
  };

  const handleSave = async () => {
    setIsLoading(true);
    await updateCareTeam();
    await updateParticipant();
    dispatchEvent('UPDATE_CARETEAM_SUMMARY');
  };

  return (
    <Stack px={2}>
      <PromptModal
        title="Are you sure you want to reassign the Care Team Leader?"
        subtitle=""
        leftButtonTitle="No, go back"
        rightButtonTitle="Yes, reassign it"
        onCancelPress={() => {
          setShowTeamLeaderWarning(false);
          setIsLeader(false);
        }}
        onContinuePress={() => {
          setShowTeamLeaderWarning(false);
        }}
        open={showTeamLeaderWarning}
        disableButtons={false}
      />
      <Grid>
        <PatientCard id={patient?.id} showCloseButton={false} />
      </Grid>
      <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="subtitle1">Edit Care Team</Typography>
        <IconButton onClick={closeForm}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>
      {!initParticipant ? (
        <Box textAlign={'center'} sx={{ mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Autocomplete
            clearOnBlur
            disablePortal
            options={[...practitioners, ...relatedPersons]}
            getOptionLabel={(option: any) =>
              ['Practitioner', 'RelatedPerson'].includes(option.resourceType)
                ? option.getFullNameWithTitles()
                : ''
            }
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== '') {
                filtered.push({
                  value: params.inputValue,
                  display: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            value={participant}
            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
            disabled
            renderInput={(params: any) => (
              <TextField
                {...params}
                fullWidth
                label="Participant"
                margin="dense"
                size="small"
                variant="standard"
              />
            )}
            renderOption={(props, option) => {
              if (['Practitioner', 'RelatedPerson'].includes(option.resourceType)) {
                return <li {...props}>{option.getFullNameWithTitles()}</li>;
              }
              return <li {...props}>{option.display}</li>;
            }}
            onChange={(ev, newValue) => {
              setParticipant(newValue);
            }}
          />
          {phones?.map((phone: any, index: number) => (
            <PhoneField
              key={`phone-${index}`}
              isLast={phones?.length - 1 === index}
              onAdd={() => handleAddPhone()}
              onChangeType={(type) => handleChangePhoneType(type, index)}
              onChangeValue={(value) => handleChangePhoneValue(value, index)}
              onSelectFavorite={(event: React.MouseEvent<HTMLElement>, value: any) =>
                handleFavoritePhone(value, index)
              }
              onRemove={phones?.length > 1 ? () => handleRemovePhone(index) : undefined}
              rank={phone.rank}
              type={phone.use}
              value={phone.value}
              disabled
            />
          ))}
          {emails?.map((email: any, index: number) => (
            <EmailField
              key={`email-${index}`}
              isLast={emails?.length - 1 === index}
              onAdd={() => handleAddEmail()}
              onChangeType={(type) => handleChangeEmailType(type, index)}
              onChangeValue={(value) => handleChangeEmailValue(value, index)}
              onSelectFavorite={(event: React.MouseEvent<HTMLElement>, value: any) =>
                handleFavoriteEmail(value, index)
              }
              onRemove={
                phones?.length && phones?.length > 1 ? () => handleRemoveEmail(index) : undefined
              }
              rank={email.rank}
              type={email.use}
              value={email.value}
              disabled
            />
          ))}
          <AddressField value={address} onChange={setAddress} disabled />
          <TextField
            fullWidth
            label="Role"
            margin="dense"
            size="small"
            variant="standard"
            onChange={(ev) => setRole(ev.target.value)}
            value={role}
            disabled
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLeader}
                  onChange={(ev) => {
                    if (ev.target.checked === true) {
                      setShowTeamLeaderWarning(true);
                    }
                    setIsLeader(ev.target.checked);
                  }}
                />
              }
              label="Is Team Leader"
            />
          </FormGroup>
          {renderActionButtons(handleSave, clearAll, isLoading)}
        </>
      )}
    </Stack>
  );
};

const EditRelatedPerson = ({ data, onSaveCallback, closeForm }: EditCareTeamFormPropsType) => {
  const patient = useCurrentPatient();
  const { dispatchEvent } = useEventBus();
  const id = data?.member?.reference?.split('/')?.[1];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [initParticipant] = useRelatedPerson(id, {
    map: RelatedPersonWrapper,
  });
  const [participant, setParticipant] = useState<any>('');
  const [role, setRole] = useState<string | undefined>('');
  const [phones, setPhones] = useState<any>([{ system: 'phone', use: 'mobile' }]);
  const [emails, setEmails] = useState<any>([{ system: 'email', use: 'home' }]);
  const [address, setAddress] = useState<any>({
    use: 'home',
    line: [],
    city: '',
    state: '',
    postalCode: '',
  });
  const [isLeader, setIsLeader] = useState(isCareTeamLeader(data));
  const [showTeamLeaderWarning, setShowTeamLeaderWarning] = useState<boolean>(false);

  const [careTeams, { update: updateCareTeams }] = useCareTeams({
    filter: {
      patient: patient?.id,
    },
  });
  const [practitioners] = usePractitioners({
    filter: { active: true },
    map: PractitionerWrapper,
  });
  const [relatedPersons, { update: updateRelatedPersons }] = useRelatedPersons({
    map: RelatedPersonWrapper,
  });

  useEffect(() => {
    if (initParticipant) {
      setParticipant(initParticipant);
      const initRoles = getCareTeamRolesExcludingLeader(data);
      const initEmails = initParticipant?.getEmails();
      const initPhones = initParticipant?.getPhones();
      const initAddress = initParticipant?.getPrimaryAddress();

      setRole(initRoles);
      setPhones(
        initPhones?.length && initPhones?.length > 0
          ? initPhones
          : [{ system: 'phone', use: 'mobile' }]
      );
      setEmails(
        initEmails?.length && initEmails?.length > 0
          ? initEmails
          : [{ system: 'email', use: 'home' }]
      );
      setAddress(initAddress || { use: 'home', line: [], city: '', state: '', postalCode: '' });
    }
  }, [data, initParticipant]);

  const clearAll = () => {
    setRole('');
    setParticipant(null);
  };

  function handleAddPhone(): void {
    setPhones([...phones, { system: 'phone', use: 'mobile' }]);
  }

  function handleChangePhoneType(type: string, index: number): void {
    const updatedPhones = [...phones];
    updatedPhones[index] = { ...updatedPhones[index], use: type };
    setPhones(updatedPhones);
  }

  function handleChangePhoneValue(value: string, index: number): void {
    const updatedPhones = [...phones];
    updatedPhones[index] = { ...updatedPhones[index], value };
    setPhones(updatedPhones);
  }

  function handleFavoritePhone(value: any, index: number): void {
    const updatedPhones = phones.map((e: any) => ({ ...e, rank: 2 }));
    if (!value) {
      updatedPhones[index].rank = 1;
    }
    setPhones(updatedPhones);
  }

  function handleRemovePhone(index: number): void {
    setPhones([...phones.slice(0, index), ...phones.slice(index + 1)]);
  }

  function handleAddEmail(): void {
    setEmails([...emails, { system: 'email', use: 'home' }]);
  }

  function handleChangeEmailType(type: string, index: number): void {
    const updatedEmails = [...emails];
    updatedEmails[index] = { ...updatedEmails[index], use: type };
    setEmails(updatedEmails);
  }

  function handleChangeEmailValue(value: string, index: number): void {
    const updatedEmails = [...emails];
    updatedEmails[index] = { ...updatedEmails[index], value };
    setEmails(updatedEmails);
  }

  function handleFavoriteEmail(value: any, index: number): void {
    const updatedEmails = emails.map((e: any) => ({ ...e, rank: 2 }));
    if (!value) {
      updatedEmails[index].rank = 1;
    }
    setEmails(updatedEmails);
  }

  function handleRemoveEmail(index: number): void {
    setEmails([...emails.slice(0, index), ...emails.slice(index + 1)]);
  }

  function handleAddAddress(): void {
    throw new Error('Function not implemented.');
  }

  function handleChangeAddress(addr: any): void {
    throw new Error('Function not implemented.');
  }

  function handleRemoveAddress(): void {
    throw new Error('Function not implemented.');
  }

  const updateCareTeam = async () => {
    const editIndex = careTeams[0].participant!.findIndex(
      (p) => p?.member?.reference === data.member.reference
    );

    let updatedMembers = [...(careTeams[0].participant || [])];
    
    if (isLeader) {
      updatedMembers = updatedMembers.map((member) => ({
        ...member,
        role: member.role?.filter((rol: any) => rol.text !== 'Team Leader'),
      }));
    }

    const updatedMember = {
      member: {
        reference: `${participant.resourceType}/${participant.id}`,
        display: participant?.getFullNameWithTitles(),
      },
      role: [
        ...(updatedMembers[editIndex]?.role?.filter(
          (r) => r?.coding?.[0]?.system !== 'careTeamRole'
        ) || []),
        {
          coding: [
            {
              system: 'careTeamRole',
            },
          ],
          text: role,
        },
        ...(isLeader
          ? [
              {
                coding: [
                  {
                    system: 'careTeamRole',
                  },
                ],
                text: 'Team Leader',
              },
            ]
          : []),
      ],
    };
    updatedMembers[editIndex] = updatedMember;

    await updateCareTeams(
      produce(careTeams[0], (draft) => {
        draft.participant = updatedMembers;
      })
    ).then(() => {
      onSaveCallback?.(updatedMember);
      closeForm();
    });
  };

  const updateParticipant = async () => {
    await updateRelatedPersons(
      produce(initParticipant!, (draft) => {
        draft.telecom = [...normalizePhones(phones), ...emails];
        draft.address = [normalizeAddress(address)];
      })
    );
  };

  const handleSave = async () => {
    setIsLoading(true);
    await updateCareTeam();
    await updateParticipant();
    dispatchEvent('UPDATE_CARETEAM_SUMMARY');
  };

  return (
    <Stack px={2}>
      <PromptModal
        title="Are you sure you want to reassign the Care Team Leader?"
        subtitle=""
        leftButtonTitle="No, go back"
        rightButtonTitle="Yes, reassign it"
        onCancelPress={() => {
          setShowTeamLeaderWarning(false);
          setIsLeader(false);
        }}
        onContinuePress={() => {
          setShowTeamLeaderWarning(false);
        }}
        open={showTeamLeaderWarning}
        disableButtons={false}
      />
      <Grid>
        <PatientCard id={patient?.id} showCloseButton={false} />
      </Grid>
      <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="subtitle1">Edit Care Team</Typography>
        <IconButton onClick={closeForm}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>
      {!initParticipant ? (
        <Box textAlign={'center'} sx={{ mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Autocomplete
            clearOnBlur
            disablePortal
            options={[...practitioners, ...relatedPersons]}
            getOptionLabel={(option: any) =>
              ['Practitioner', 'RelatedPerson'].includes(option.resourceType)
                ? option.getFullNameWithTitles()
                : ''
            }
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              if (params.inputValue !== '') {
                filtered.push({
                  value: params.inputValue,
                  display: `Add "${params.inputValue}"`,
                });
              }

              return filtered;
            }}
            value={participant}
            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
            renderInput={(params: any) => (
              <TextField
                {...params}
                fullWidth
                label="Participant"
                margin="dense"
                size="small"
                variant="standard"
              />
            )}
            disabled
            renderOption={(props, option) => {
              if (['Practitioner', 'RelatedPerson'].includes(option.resourceType)) {
                return <li {...props}>{option.getFullNameWithTitles()}</li>;
              }
              return <li {...props}>{option.display}</li>;
            }}
            onChange={(ev, newValue) => {
              setParticipant(newValue);
            }}
          />
          {phones?.map((phone: any, index: number) => (
            <PhoneField
              key={`phone-${index}`}
              isLast={phones?.length - 1 === index}
              onAdd={() => handleAddPhone()}
              onChangeType={(type) => handleChangePhoneType(type, index)}
              onChangeValue={(value) => handleChangePhoneValue(value, index)}
              onSelectFavorite={(event: React.MouseEvent<HTMLElement>, value: any) =>
                handleFavoritePhone(value, index)
              }
              onRemove={phones?.length > 1 ? () => handleRemovePhone(index) : undefined}
              rank={phone.rank}
              type={phone.use}
              value={phone.value}
            />
          ))}
          {emails?.map((email: any, index: number) => (
            <EmailField
              key={`email-${index}`}
              isLast={emails?.length - 1 === index}
              onAdd={() => handleAddEmail()}
              onChangeType={(type) => handleChangeEmailType(type, index)}
              onChangeValue={(value) => handleChangeEmailValue(value, index)}
              onSelectFavorite={(event: React.MouseEvent<HTMLElement>, value: any) =>
                handleFavoriteEmail(value, index)
              }
              onRemove={
                phones?.length && phones?.length > 1 ? () => handleRemoveEmail(index) : undefined
              }
              rank={email.rank}
              type={email.use}
              value={email.value}
            />
          ))}
          <AddressField value={address} onChange={setAddress} />
          <TextField
            fullWidth
            label="Role"
            margin="dense"
            size="small"
            variant="standard"
            onChange={(ev) => setRole(ev.target.value)}
            value={role}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLeader}
                  onChange={(ev) => {
                    if (ev.target.checked === true) {
                      setShowTeamLeaderWarning(true);
                    }
                    setIsLeader(ev.target.checked);
                  }}
                />
              }
              label="Is Team Leader?"
            />
          </FormGroup>
          {renderActionButtons(handleSave, clearAll, isLoading)}
        </>
      )}
    </Stack>
  );
};

const CareTeamFormEdit = (props: EditCareTeamFormPropsType) => {
  const resourceType = props?.data?.member?.reference?.split('/')?.[0];
  if (resourceType === 'Practitioner') return <EditPractitioner {...props} />;
  if (resourceType === 'RelatedPerson') return <EditRelatedPerson {...props} />;
  return null;
};

export default CareTeamFormEdit;
