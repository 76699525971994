import { checkAclValidation, getRelatedAcls, CCM_ACLS } from "src/utils/permissions/permission.utils";

export const isOnServiceProviderRole = (user: any) => {
  if ( checkAclValidation({
    user,
    acl: getRelatedAcls(CCM_ACLS.TASK_ACTIVITIES.ACTIVITIES.READ),
  }) ||

  checkAclValidation({
    user,
    acl: getRelatedAcls(CCM_ACLS.TASK_ACTIVITIES.ALERTS.READ),
  }) || 
  checkAclValidation({
    user,
    acl: getRelatedAcls(CCM_ACLS.TASK_ACTIVITIES.PATIENTS.READ),
  })) return false;

  if (checkAclValidation({
    user,
    acl: getRelatedAcls(CCM_ACLS.TASK_ACTIVITIES.REFERRALS.READ),
  })) return true;

  return false;
}