import { ResourceWrapper } from '../model/ResourceWrapper';
import { FHIRRepository } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir';
import {
  FHIRCareTeamRepository,
  FHIREncounterRepository,
  FHIRPatientRepository,
} from '../../../out/repositories';
import {
  CareTeam,
  Encounter,
  FhirResource,
  Patient as FhirPatient,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { createContext, useContext } from 'react';

interface EntityData<ResourceType extends FhirResource> {
  id?: string;
  entity?: ResourceWrapper<ResourceType>;
  repository: FHIRRepository<ResourceType>;
}

export interface PatientContext {
  patient: EntityData<FhirPatient>;
  admissionDetails: EntityData<Encounter>;
  careTeam: EntityData<CareTeam>;
}

export interface PatientContextProps {
  patientContext: PatientContext;
}

export function getInitialPatientContext(): PatientContext {
  return {
    patient: {
      repository: new FHIRPatientRepository(),
    },
    admissionDetails: {
      repository: new FHIREncounterRepository(),
    },
    careTeam: {
      repository: new FHIRCareTeamRepository(),
    },
  };
}

const PatientContext = createContext<PatientContext>(getInitialPatientContext());
export const PatientContextProvider = PatientContext.Provider;
export const usePatientContext = () => useContext(PatientContext);
