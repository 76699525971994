import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import moment from 'moment';
import produce from 'immer';
import { camelCase } from 'lodash';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { MenuItem } from '@mui/material';
import { MobileDatePicker } from '@mui/lab';
import { FHIR_CATEGORIES } from 'src/config';
import Iconify from 'src/components/Iconify';
import { Languages } from 'src/@types/crs/patient';
import { useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Language from 'src/sections/crs/common/Language';
import { usePatient, useValueSet } from 'src/@nicheaim/fhir-react';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { convertValueToValueSet } from 'src/sections/crs/common/common-utils';
import { PatientWrapper, WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { FormProvider, RHFTextField, RHFSelect } from '../../../../../components/hook-form';
import { Coding, PatientGender } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { checkAclValidation, ViewPersonalDetails, getRelatedAcls, EditPersonalDetails } from 'src/utils/permissions/permission.utils';

type FormValue = {
  lastName: string;
  middleName: string;
  firstName: string;
  birthDate: Date;
  gender: string;
  ethnicity: string[];
  race: string[];
  language: Languages[];
};

type Props = {
  patient: WrappedPatient;
  open: boolean;
  user: any;
  onClose: VoidFunction;
};

const EditMode = ({ patient, open, user, onClose }: Props) => {

  const { enqueueSnackbar } = useSnackbar();

  const [, { update }] = usePatient(patient.id!, { map: PatientWrapper });

  const [ethnicityCategories] = useValueSet('omb-ethnicity-category', { map: ValueSetWrapper });
  const [raceCategories] = useValueSet('omb-race-category', { map: ValueSetWrapper });
  const [genderCategories] = useValueSet('us-core-birthsex', { map: ValueSetWrapper });
  const [simpleLanguage] = useValueSet('simple-language', { map: ValueSetWrapper });

  const defaultValues = useMemo(
    () =>
    ({
      lastName: patient?.name?.[0].family || '',
      firstName: patient?.name?.[0].given?.[0] || '',
      middleName: patient?.name?.[0].given?.[1] || '',
      birthDate: patient?.birthDate ? 
        moment.utc(new Date(patient?.birthDate)).format('MM/DD/YYYY') : 
        new Date(),
      gender: patient?.gender || '',
      ethnicity: patient.getEthnicityArray()?.map((e:Coding) => e.display),
      race: patient.getRaceArray()?.map((e:Coding) => e.display),
      language: patient?.getLanguage() || [],
    } as FormValue),
    [patient]
  );

  const EventSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First Name is required')
      .matches(/^[aA-zZ\s]+$/, 'First Name must be alphabets')
      .max(25, 'First Name must be a maximum of 25 characters'),
    lastName: Yup.string()
      .required('Last Name is required')
      .matches(/^[aA-zZ\s]+$/, 'Last Name must be alphabets')
      .max(25, 'Last Name must be a maximum of 25 characters'),
    middleName: Yup.string()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .matches(/^[aA-zZ\s]+$/, 'Middle Name must be alphabets')
      .max(25, 'Middle Name must be a maximum of 25 characters'),
    birthDate: Yup.date()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .required('Date of birth is required')
      .test(
        "date_of_birth_test",
        "Date of birth cannot be a date in the future",
        (value) => {
          const birthDate = moment(value ?? null);
          if (birthDate?.isSameOrAfter(moment())) {
            setError('birthDate', {
              message: 'Date of birth cannot be a date in the future',
              type: 'manual',
            });
            return false;
          }
          return true;
        }
      ),
    language: Yup.lazy(val => (Array.isArray(val) ? Yup.array().of(
      Yup.object().shape({
        value: Yup.string().min(2).required('Language is required')
      })) : Yup.object().shape({
        value: Yup.string().min(2).required('Language is required')})))
  });

  const methods = useForm({ resolver: yupResolver(EventSchema), defaultValues });

  const { reset, control, handleSubmit, setError } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [open, defaultValues]);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (data: FormValue) => {
    try {
      const codeValueRace = data?.race?.map((e:string) => ({
        url: FHIR_CATEGORIES.race || '',
        valueCodeableConcept: {
          coding: [convertValueToValueSet(e, raceCategories)],
        }
      })) || [];

      const codeValueEthnicity = data?.ethnicity?.map((e:string) => ({
        url: FHIR_CATEGORIES.ethnicity || '',
        valueCodeableConcept: {
          coding: [convertValueToValueSet(e, ethnicityCategories)],
        }
      })) || [];

      let codeValueLanguage: any = data?.language?.map((e: Languages) => {
        const coding = convertValueToValueSet(e.value || '', simpleLanguage);
        const communication = {
          language: { coding: [coding], text: coding?.display},
          preferred: e.preferred
        }
        return communication;
      })

      let extension: any = [
        ...(codeValueRace && codeValueRace),
        ...(codeValueEthnicity && codeValueEthnicity),
      ].filter((e: any) => e !== null);

      await update(
        produce(patient!, (draft) => {
          draft.name ??= [];
          draft.name[0] ??= {};
          draft.name[0].family = data.lastName;
          draft.name[0].given ??= [];
          draft.name[0].given[0] = data.firstName;
          draft.name[0].given[1] = data.middleName;
          draft.birthDate = moment.utc(new Date(data.birthDate)).format('YYYY-MM-DD');
          draft.gender = data.gender as PatientGender;
          draft.extension = [...extension];
          draft.communication = codeValueLanguage;
        })
      );

      handleClose();
      enqueueSnackbar('Patient was updated.');
    } catch {
      handleClose();
      enqueueSnackbar('Patient was not updated.', { variant: 'error' });
    }
  };
  if (!open) return null;

  return (
    <Dialog keepMounted={false} open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>Personal Details</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Typography variant="body2">Last Name</Typography>
                <RHFTextField name="lastName" label="" />

                <Typography variant="body2">Middle Name</Typography>
                <RHFTextField name="middleName" label="" />

                <Typography variant="body2">First Name</Typography>
                <RHFTextField name="firstName" label="" />

                <Typography variant="body2">Date of Birth</Typography>
                <Controller
                  name="birthDate"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <MobileDatePicker
                      {...field}
                      label=""
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!error?.message}
                          helperText={error?.message}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />

                <Typography variant="body2">Gender</Typography>
                <RHFSelect name="gender" label="">
                  <MenuItem disabled></MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                  <MenuItem value="unknown">Unknown</MenuItem>
                </RHFSelect>

                <Typography variant="body2">Ethnicity</Typography>
                <Controller
                  name="ethnicity"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      onChange={(event, newValue) => field.onChange(newValue)}
                      options={ethnicityCategories?.asList().map((option) => option.display) || []}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => <TextField label="" {...params} />}
                    />
                  )}
                />

                <Typography variant="body2">Race</Typography>
                <Controller
                  name="race"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      onChange={(event, newValue) => field.onChange(newValue)}
                      options={raceCategories?.asList().map((option) => option.display) || []}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => <TextField label="" {...params} />}
                    />
                  )}
                />

                <Language simpleLanguage={simpleLanguage}/>
              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" color="info" onClick={handleClose}>
                Cancel
              </Button>
              {checkAclValidation({ user, acl: getRelatedAcls(EditPersonalDetails) }) && (
                <Button variant="contained" color="info" type="submit">
                  Submit
                </Button>
              )}
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
};

type DisplayModeProps = {
  patient: WrappedPatient;
};

const DisplayMode = ({ patient }: DisplayModeProps) => (
  <Grid container spacing={3} sx={{ p: 1 }}>
    <Grid item xs={3}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          Last Name
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {patient?.name?.[0]?.family}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          Gender
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {patient?.gender}
        </Typography>
      </Stack>
    </Grid>

    <Grid item xs={3}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          Middle Name
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {patient?.name?.[0]?.given?.[1]}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          Race
        </Typography>

        <Typography
          variant="body2"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {patient?.getRace()?.display}
        </Typography>
      </Stack>
    </Grid>
    <Grid item xs={3}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          First Name
        </Typography>
        <Typography variant="body2"> {patient?.name?.[0]?.given?.[0]}</Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          Ethnicity
        </Typography>
        <Typography variant="body2">{patient?.getEthnicity()?.display}</Typography>
      </Stack>
    </Grid>
    <Grid item xs={3}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          Date of Birth
        </Typography>
        <Typography variant="body2">
          {patient.birthDate ? `${moment(patient.birthDate).format('MMM D Y')}` : 'N/A'}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          Age Group
        </Typography>
        <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
          {patient?.getAgeGroup()}
        </Typography>
      </Stack>
    </Grid>
  </Grid>
);

type PropDetails = {
  patient: WrappedPatient;
};

export default function PersonalDetails({ patient }: PropDetails) {

  const user = useAuth();
  const [openEditPersonalDetails, setEditPersonalDetails] = useState(false);

  const handleCloseEditPersonalDetails = () => {
    setEditPersonalDetails(false);
  };

  return (
    <>
      <Stack direction="row" style={{ display: "flex" }}>
        <Typography variant="button" sx={{ textTransform: 'uppercase', mt: 1 }}>
          Personal Details
        </Typography>
        {checkAclValidation({ user, acl: getRelatedAcls(ViewPersonalDetails) }) && (
          <IconButton onClick={() => setEditPersonalDetails(true)} sx={{ p: 0.5, ml: 1 }}>
            <Iconify icon={openEditPersonalDetails ? 'eva:save-outline' : 'eva:edit-outline'} />
          </IconButton>
        )}
      </Stack>
      <DisplayMode patient={patient} />
      <EditMode
        patient={patient}
        open={openEditPersonalDetails}
        user={user}
        onClose={handleCloseEditPersonalDetails}
      />
    </>
  );
}