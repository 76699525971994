import { Theme } from '@mui/material/styles';
//
import { TreeViewCollapseIcon, TreeViewExpandIcon, TreeViewEndIcon } from './CustomIcons';
import { TreeItemClassKey, TreeViewClassKey } from '@mui/lab';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

export default function TreeView(theme: Theme) {
  return {
    MuiTreeView: {
      defaultProps: {
        defaultCollapseIcon: <Iconify icon="mdi:chevron-down" />,
        defaultExpandIcon: <Iconify icon="mdi:chevron-right" />,
        defaultEndIcon: <></>,
      },
      styleOverrides: {
        root: {
          marginTop: 0,
        },
      } as Record<TreeViewClassKey, object>,
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          ...theme.typography.body2,
          paddingBottom: theme.spacing(1),
          paddingTop: theme.spacing(1),
        },
        iconContainer: { width: 'auto' },
      } as Record<TreeItemClassKey, object>,
    },
  };
}
