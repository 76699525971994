import { ActivityFilter } from './ActivityFilter';
import {
  ActivityRenderer,
  ActivityPriorityRenderer,
  ActivityLegendRenderer,
} from '../../ActivityRenderers';
import { ConcreteActivity } from 'src/sections/careflow/tasks-activities/model/Activity';
import { ActivityStatus } from 'src/sections/careflow/tasks-activities/model';
import { ActivityLegends } from 'src/sections/careflow/tasks-activities/model/ActivityLegends';
import { Typography, Box, CircularProgress } from '@mui/material';
import { usePatient, usePractitioner } from 'src/@nicheaim/fhir-react';
import { PatientWrapper, WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import React, { ReactNode } from 'react';
import { PatientCard } from 'src/sections/careflow/common';
import { renderDate } from '../../utils';
import {
  PractitionerWrapper,
  WrappedPractitioner,
} from 'src/@nicheaim/fhir-base/wrappers/Practitioner';

export interface Row {
  values: (ActivityRenderer | ReactNode)[];
  activity: ConcreteActivity;
}

export function mapActivitiesToRows({
  activities,
  date,
  isPatientView,
  patientId,
  contactTypes,
}: ActivityFilter): Row[] {
  return activities
    .filter((activity) => !date || activity.containsDay(date))
    .filter((activity) => !patientId || activity.patientFhirId === patientId)
    .map((activity) => ({
      activity,
      values: [
        ...(date ? [] : [renderDate(activity)]),
        ...(isPatientView ? [activity.specialty || 'N/A'] : []),
        <Typography key="patient-name">{activity.patientName}</Typography>,
        <Typography key="patient-age">{activity.patientAge ?? 'N/A'}</Typography>,
        <PatientProvider
          key="patient-id"
          id={activity.patientFhirId}
          render={(patient) => <Typography>{patient?.getMRN()?.value}</Typography>}
        />,
        {
          render: () => {
            const statusLegend = {
              [ActivityStatus.REQUESTED]: ActivityLegends.NEW,
              [ActivityStatus.READY]: ActivityLegends.READY,
              [ActivityStatus.CANCELLED]: ActivityLegends.CANCELLED,
              [ActivityStatus.COMPLETED]: ActivityLegends.COMPLETED,
            };

            return (
              <>
                <Typography>
                  {`${activity.type} - ${activity.activity} (${
                    contactTypes?.find((ct) => ct.code === activity.contactType)?.display ?? '...'
                  })`}
                </Typography>
                <Box sx={{ display: 'inline-flex' }}>
                  {![ActivityStatus.COMPLETED, ActivityStatus.CANCELLED].includes(
                    activity.status
                  ) &&
                    new ActivityLegendRenderer({
                      ...activity,
                    }).render(true)}

                  {new ActivityLegendRenderer({
                    ...activity,
                    legend: statusLegend[activity.status as keyof typeof statusLegend],
                  }).render(true)}
                </Box>
              </>
            );
          },
        },
        activity.script ?? 'N/A',
        {
          render: () => new ActivityPriorityRenderer(activity).render(),
        },
        <Typography key="activity-author">{activity.createdBy}</Typography>,
        <PractitionerProvider
          key="activity-staff"
          id={activity.staffFhirId}
          render={(practitioner, { isLoading }) => (
            <Typography>
              {isLoading ? <CircularProgress size={20} /> : practitioner?.getFullName()}
            </Typography>
          )}
        />,
      ],
    }));
}

interface PatientProviderProps {
  id: string;
  render: (patient: WrappedPatient | null, status: { isLoading: boolean }) => React.ReactElement;
}

export function PatientProvider({ id, render }: PatientProviderProps) {
  const [patient, { isLoading }] = usePatient(id, {
    map: PatientWrapper,
  });

  return render(patient, {
    isLoading,
  });
}

interface PractitionerProviderProps {
  id: string;
  render: (
    practitioner: WrappedPractitioner | null,
    status: {
      isLoading: boolean;
    }
  ) => React.ReactElement;
}

export function PractitionerProvider({ id, render }: PractitionerProviderProps) {
  const [practitioner, { isLoading }] = usePractitioner(id, {
    map: PractitionerWrapper,
  });

  return render(practitioner, {
    isLoading,
  });
}

export function getActivityNames(
  activity: ConcreteActivity,
  contactTypes: any
): React.ReactElement {
  return (
    <>
      <Box
        sx={{
          pt: 2,
          pb: 2,
        }}
      >
        <PatientCard id={activity?.patientFhirId} showCloseButton={false} />
      </Box>
      <Typography>
        {`${activity?.type} - ${activity?.activity} (${
          contactTypes?.concept?.find((ct) => ct.code === activity?.contactType)?.display ?? 'N/A'
        })`}
      </Typography>
    </>
  );
}

export function getColumns(isPatientView?: boolean, date?: Date) {
  return [
    ...(date ? [] : [{ label: 'Date' }]),
    ...(isPatientView
      ? [
          {
            label: 'Role', // Specialty TODO: Change for "role" in model.
          },
        ]
      : []),
    {
      label: 'Name',
    },
    {
      label: 'Age',
    },
    {
      label: 'ID',
    },
    {
      label: 'Activity',
    },
    {
      label: 'Script / tool',
    },
    {
      label: 'Priority',
    },
    {
      label: 'Created by',
    },
    {
      label: 'Assigned to',
    },
    {
      label: 'Actions',
    },
  ];
}
