import { useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';
import AssignUser from '../../common/AssignUser';
import { parseShortFormat } from 'src/utils/formatTime';
import { Box, Card, Collapse, IconButton, Stack, Typography } from '@mui/material';

interface Props {
  title?: string;
  workflowInstance: any;
  users: any[];
  openCollapseExternal: boolean;
  onOwnedAssign: (owned: string) => Promise<any>;
  caseRecord: any;
}

export default function CaseInformation({
  title,
  workflowInstance,
  users,
  openCollapseExternal,
  onOwnedAssign,
  caseRecord,
}: Props) {
  const { status, scope, ownedBy, description, createdOn } = workflowInstance;

  const ownedFromUsers = users
    .filter((e) => e.userName == ownedBy)
    .map((t) => ({
      id: t.id,
      fullName: `${t.firstName} ${t.lastName}`,
    }))[0];

  const [openModal, setOpenModal] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    setOpenCollapse(openCollapseExternal);
  }, [openCollapseExternal]);

  return (
    <Card sx={{ p: 4, display: 'block' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography sx={{ mt: 1 }}>{title}</Typography>
        <IconButton onClick={() => setOpenCollapse(!openCollapse)}>
          <Iconify
            icon={openCollapse ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          />
        </IconButton>
      </Box>

      <Collapse in={openCollapse}>
        <Stack spacing={2}>
          <Stack mt={2} direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">Case no.</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {caseRecord?.internalNumber}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">Status</Typography>
            <Typography
              variant="body2"
              sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
            >
              {caseRecord?.indexedStatus}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">Workflow Status</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {status}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">Scope</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {scope}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">Assigned To</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {ownedFromUsers?.fullName}
              <IconButton sx={{ p: 0.5, ml: 0.5 }} onClick={() => setOpenModal(true)}>
                <Iconify
                  color="#1890FF"
                  icon={openModal ? 'eva:save-outline' : 'eva:edit-outline'}
                />
              </IconButton>
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">Description</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {description}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="body2">Date</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {parseShortFormat(createdOn)}
            </Typography>
          </Stack>
        </Stack>
      </Collapse>
      <AssignUser
        open={openModal}
        onClose={handleCloseModal}
        users={users}
        ownedId={ownedFromUsers?.id}
        onOwnedAssign={onOwnedAssign}
      />
    </Card>
  );
}
