import { useMemo } from 'react';
import { useCarePlans, useCodeSystem } from 'src/@nicheaim/fhir-react';
import fhirSystem from '../system';
import { useCarePlanStatuses } from 'src/services/api/care-plan/statuses';

// TODO: Infer mapped type
export function useAllCarePlans(options?: Parameters<typeof useCarePlans>[0]) {
  const [carePlans, queryState] = useCarePlans({
    ...(options as any),
    filter: {
      identifier: fhirSystem.carePlan.forCode(''),
      status: 'active',
      _sort: '-created',
      ...options?.filter,
    },
  });

  return [carePlans, queryState] as const;
}

// TODO: Infer mapped type
export function useActiveCarePlans(options?: Parameters<typeof useCarePlans>[0]) {
  const [carePlans, queryState] = useAllCarePlans(options);
  const { data: carePlanStatuses } = useCarePlanStatuses();

  return [
    useMemo(
      () =>
        carePlans.filter((carePlan) => {
          const carePlanStatus = carePlan.extension?.find(
            (extension) => extension.url === fhirSystem.extension.CarePlan.currentStatus.asString()
          )?.valueCoding?.code;
          if (!carePlanStatus) return false;

          return (
            carePlanStatuses?.find((status) => carePlanStatus === status.uuid)?.treatAsInactive !==
            true
          );
        }),
      [carePlans, carePlanStatuses]
    ),
    queryState,
  ] as const;
}
