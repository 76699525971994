import StringOperator from './operator-components/StringOperator';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import NumberOperator from './operator-components/NumberOperator';
import OptionOperator from './operator-components/OptionOperator';

export type OperatorType = 'string' | 'choice' | 'number' | 'date' | 'boolean' | 'null';

type QuestionTypeMapping = Partial<
  Record<
    WrappedQuestionnaireItem['type'],
    {
      /**
       * Determines if the question type should be displayed
       */
      display?: string | false;
      operators: {
        value: string;
        label: string;
        type?: OperatorType;
        component?: React.ComponentType<{
          question: WrappedQuestionnaireItem;
          ruleId: string;
        }>;
        // FIXME: Move it somewhere else? Not applicable to all operators
        unit?: [string] | [string, string];
      }[];
    }
  >
>;

export const QUESTION_TYPES: QuestionTypeMapping = {
  group: {
    display: false,
    operators: [],
  },
  display: {
    display: false,
    operators: [],
  },
  string: {
    display: 'Text',
    operators: [
      {
        value: 'empty',
        label: 'Is empty',
      },
      {
        value: 'not empty',
        label: 'Is not empty',
      },
      {
        value: 'equals',
        label: 'Equals',
        type: 'string',
        component: StringOperator,
      },
      {
        value: 'not equals',
        label: 'Not equals',
        type: 'string',
        component: StringOperator,
      },
      {
        value: 'contains',
        label: 'Contains',
        type: 'string',
        component: StringOperator,
      },
      {
        value: 'not contains',
        label: 'Does not contain',
        type: 'string',
        component: StringOperator,
      },
    ],
  },
  get text() {
    return this.string;
  },
  'single-choice': {
    display: 'Choice',
    operators: [
      {
        value: 'no selection',
        label: 'None selected',
        type: 'null',
      },
      {
        value: 'selected option',
        label: 'Selected option',
        component: OptionOperator,
        type: 'choice',
      },
      {
        value: 'not selected option',
        label: 'Not selected option',
        component: OptionOperator,
        type: 'choice',
      },
    ],
  },
  'multiple-choice': {
    display: 'Multiple Choice',
    operators: [
      {
        value: 'no selection',
        label: 'None selected',
      },
      {
        value: 'selected more than',
        label: 'Selected more than',
        type: 'number',
        component: NumberOperator,
        unit: ['option', 'options'],
      },
      {
        value: 'selected less than',
        label: 'Selected less than',
        type: 'number',
        component: NumberOperator,
        unit: ['option', 'options'],
      },
      {
        value: 'selected exactly',
        label: 'Selected exactly',
        type: 'number',
        component: NumberOperator,
        unit: ['option', 'options'],
      },
      {
        value: 'selected options',
        label: 'Selected options',
        component: OptionOperator,
        unit: ['option', 'options'],
      },
      {
        value: 'not selected options',
        label: 'Not selected options',
        component: OptionOperator,
        unit: ['option', 'options'],
      },
      {
        value: 'selected all options',
        label: 'Selected all options',
      },
    ],
  },
  integer: {
    display: 'Integer',
    operators: [
      {
        value: 'empty',
        label: 'Is empty',
      },
      {
        value: 'not empty',
        label: 'Is not empty',
      },
      {
        value: '==',
        label: 'Equals',
        type: 'number',
        component: NumberOperator,
      },
      {
        value: '!=',
        label: 'Not equals',
        type: 'number',
        component: NumberOperator,
      },
      {
        value: '>',
        label: 'Greater than',
        type: 'number',
        component: NumberOperator,
      },
      {
        value: '>=',
        label: 'Greater than or equal to',
        type: 'number',
        component: NumberOperator,
      },
      {
        value: '<',
        label: 'Less than',
        type: 'number',
        component: NumberOperator,
      },
      {
        value: '<=',
        label: 'Less than or equal to',
        type: 'number',
        component: NumberOperator,
      },
    ],
  },
  get decimal() {
    return this.integer;
  },
};
