import { ObservationComponent } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { createWrapper } from '../../fhir-react/base';
import { fhirCodeSystemGenerator } from 'src/fhir/system';

const findComponentByCode = (component: ObservationComponent[], code: string) =>
  component?.find((c) => c.code?.coding?.some((c) => c.code === code));

export const ObservationWrapper = createWrapper('Observation', (observation) => ({
  hasCodeValue(code: string) {
    return observation?.component?.find((c) => c.code?.coding?.[0].code === code);
  },

  hasVitalsValues() {
    return observation?.component?.find((c) =>
      c.code?.coding?.some(
        (c) =>
          c?.code === '8480-6' ||
          c?.code === '8310-5' ||
          c?.code === '8867-4' ||
          c?.code === '9279-1' ||
          c?.code === '2708-6'
      )
    );
  },

  hasHeightAndWeightValues() {
    return observation?.component?.find((c) =>
      c.code?.coding?.some(
        (c) => c?.code === '8302-2' || c?.code === '29463-7' || c?.code === '39156-5'
      )
    );
  },

  hasOrthostaticValues() {
    return observation?.component?.find((c) =>
      c.code?.coding?.some(
        (c) =>
          c?.code === '8455-8' ||
          c?.code === '8453-3' ||
          c?.code === '8454-1' ||
          c?.code === '8310-5'
      )
    );
  },

  getVitalsValue(code: string) {
    if (!observation?.component) return;
    const vitals = findComponentByCode(observation.component, code);
    return vitals?.valueQuantity?.value?.toString() ?? '-';
  },

  getVitalsValueWithUnits(code: string) {
    if (!observation?.component) return;
    const vitals = findComponentByCode(observation.component, code);
    return vitals ? `${vitals.valueQuantity?.value} ${vitals.valueQuantity?.unit}` : '-';
  },

  getFirstFiveVitals() {
    return [
      '8480-6',
      '8310-5',
      '8867-4',
      '9279-1',
      '2708-6',
      '8302-2',
      '29463-7',
      '39156-5',
      '8455-8',
      '8453-3',
      '8454-1',
    ]
      .map((code) => (this.hasCodeValue(code) ? code : null))
      .filter((c) => c)
      .slice(0, 5);
  },

  getValue() {
    if (observation?.valueInteger) {
      return observation?.valueInteger;
    }
    if (observation?.valueString) {
      return observation?.valueString;
    }
    return observation?.valueCodeableConcept?.coding?.[0].code;
  },

  getGlucoseReadingValue() {
    return observation?.component?.find(i => i.code?.coding?.[0].system === fhirCodeSystemGenerator.glucose_check_types.toString()) ?? null;
  },

  getGlucoseInterventionValue() {
    return observation?.component?.find(i => i.code?.coding?.[0].system === fhirCodeSystemGenerator.glucose_intervention_types.toString()) ?? null;
  }
}));

export type WrappedObservation = ReturnType<typeof ObservationWrapper>;
