import { useState } from "react";
import Iconify from "src/components/Iconify";
import { TableMoreMenu } from "src/components/table";
import RelatedPersonAction from './RelatedPersionAction';
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { MenuItem, TableCell, TableRow, Typography } from "@mui/material";
  
  type Props = {
    row: any;
    patient: WrappedPatient | null;
  };
  
export default function RelatedPersonRow({ row, patient }: Props) {
  
  const [ data, setData ] = useState(null);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleEdit = (row: any) => {
    handleCloseMenu();
    setData(row);
    setOpenModalEdit(true);
  };

  return (
    <>
      <TableRow >
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {`${row?.name?.given?.[0]} ${row?.name?.family}`}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }} >
          <div style={{ display: 'flex', flexDirection: 'column'}}>
            <Typography variant="caption">
              {row?.telecom?.filter((e:any) => e.system === "phone")?.[0].value}
            </Typography>
            <Typography variant="caption">
              {row?.telecom?.filter((e:any) => e.system === "email")?.[0].value}
            </Typography>
          </div>
        </TableCell>
        <TableCell align="center">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={[
            <MenuItem key={'menu-i-edit-'} onClick={() => handleEdit(row)}>
                <Iconify icon={'mdi:pencil'} />
                Edit
            </MenuItem>
            ]}
          />
        </TableCell>
      </TableRow>
      <RelatedPersonAction 
        patient={patient} 
        contact={data} 
        open={openModalEdit} 
        onClose={() => setOpenModalEdit(false)}
      />
    </>
  );
};