import { IconButton, MenuItem, Stack, TextField, ToggleButton } from '@mui/material';
import { PatternFormat } from 'react-number-format';
import Iconify from 'src/components/Iconify';

interface PhoneFieldProps {
  fullWidth?: boolean;
  isLast?: boolean;
  onAdd?: () => void;
  onChangeValue?: (value: string) => void;
  onChangeType?: (type: string) => void;
  onRemove?: () => void;
  onSelectFavorite?: (event: React.MouseEvent<HTMLElement>, value: any) => void;
  type: string;
  value: string;
  rank?: number;
  disabled?: boolean;
}

const PhoneField = ({
  fullWidth,
  isLast,
  onAdd,
  onChangeType,
  onChangeValue,
  onRemove,
  onSelectFavorite,
  rank,
  type,
  value,
  disabled = false,
}: PhoneFieldProps) => {
  const handleChangeType = (ev: any) => {
    if (onChangeType) {
      onChangeType(ev.target.value);
    }
  };

  const handleChangeValue = (ev: any) => {
    if (onChangeValue) {
      onChangeValue(ev.target.value);
    }
  };

  return (
    <Stack alignItems="baseline" direction="row" spacing={1}>
      {onChangeType ? (
        <TextField
          select
          size="small"
          margin="dense"
          variant="standard"
          onChange={handleChangeType}
          value={type ?? 'other'}
          disabled={disabled}
        >
          <MenuItem value="mobile">Mobile</MenuItem>
          <MenuItem value="work">Work</MenuItem>
          <MenuItem value="home">Home</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </TextField>
      ) : null}
      <PatternFormat
        fullWidth={fullWidth}
        format="###-###-####"
        placeholder="Phone"
        size="small"
        variant="standard"
        value={value}
        customInput={TextField}
        onChange={handleChangeValue}
        disabled={disabled}
      />
      <Stack direction="row" spacing={0}>
        {isLast && !disabled ? (
          <IconButton size="small" onClick={onAdd}>
            <Iconify icon="eva:plus-outline" />
          </IconButton>
        ) : null}
        {onRemove ? (
          <IconButton size="small" disabled={!onRemove} onClick={onRemove}>
            <Iconify icon="eva:minus-outline" />
          </IconButton>
        ) : null}
        {onSelectFavorite ? (
          <ToggleButton
            size="small"
            value={rank === 1}
            selected={rank === 1}
            onChange={onSelectFavorite}
          >
            <Iconify icon={rank === 1 ? 'eva:star-fill' : 'eva:star-outline'} />
          </ToggleButton>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default PhoneField;
