import { useState } from 'react';
import { Box, IconButton, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { FormProvider, RHFTextField, RHFSelect } from '../../../../../components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const EditMode = () => {
  const EventSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Title is required'),
    description: Yup.string().max(5000),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
  });

  const {
    control,
    formState: { isSubmitting },
  } = methods;

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={3} sx={{ p: 2 }}>
        <Grid item xs={3}>
          <Stack direction="column" alignItems="left" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
              Family Member
            </Typography>
            <RHFSelect name="gender" label="">
              <MenuItem>Daughter</MenuItem>
              <MenuItem>Brother</MenuItem>
            </RHFSelect>
          </Stack>
        </Grid>

        <Grid item xs={3}>
          <Stack direction="column" alignItems="left" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
              Last Name
            </Typography>
            <RHFTextField name="lastName" label="" />
          </Stack>
        </Grid>

        <Grid item xs={3}>
          <Stack direction="column" alignItems="left" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
              Middle Name
            </Typography>
            <RHFTextField name="middleName" label="" />
          </Stack>
        </Grid>

        <Grid item xs={3}>
          <Stack direction="column" alignItems="left" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
              First Name
            </Typography>
            <RHFTextField name="firstName" label="" />
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

const DisplayMode = () => (
  <Grid container spacing={3} sx={{ p: 2 }}>
    <Grid item xs={3}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
          Family Member
        </Typography>
        <Typography variant="body2">Daughter</Typography>
      </Stack>
    </Grid>

    <Grid item xs={3}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
          Last Name
        </Typography>
        <Typography variant="body2">Billingsley</Typography>
      </Stack>
    </Grid>
    <Grid item xs={3}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
          Middle Name
        </Typography>
        <Typography variant="body2">N/A</Typography>
      </Stack>
    </Grid>
    <Grid item xs={3}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
          First Name
        </Typography>
        <Typography variant="body2">Melinda</Typography>
      </Stack>
    </Grid>
  </Grid>
);

export default function FamilyDetails() {
  const [editRelated, setEditRelated] = useState(false);

  return (
    <>
      <Box sx={{ my: 1 }}>
        <TextField
          select
          size="small"
          value="Family"
          sx={{
            '& fieldset': { border: '0 !important' },
            '& .MuiSelect-select': { mx: 2, px: 2, py: 1 },
            '& .MuiOutlinedInput-root': {
              p: 0,
              border: `1px solid #008ECC`,
              borderRadius: 2,
              typography: 'body2',
              color: '#008ECC',
            },
            '& .MuiSelect-icon': { color: '#008ECC' },
          }}
        >
          <MenuItem
            sx={{ typography: 'body2', m: 0.5, borderRadius: 0.75, color: '#008ECC' }}
            value="Family"
          >
            Family
          </MenuItem>
          <MenuItem
            sx={{ typography: 'body2', m: 0.5, borderRadius: 0.75, color: '#008ECC' }}
            value="Doctors"
          >
            Doctors
          </MenuItem>
          <MenuItem
            sx={{ typography: 'body2', m: 0.5, borderRadius: 0.75, color: '#008ECC' }}
            value="Friends"
          >
            Friends
          </MenuItem>
        </TextField>
      </Box>

      <Stack direction="row">
        <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
          Family Details
          <IconButton onClick={() => setEditRelated(!editRelated)}>
            <Iconify icon={editRelated ? 'eva:save-outline' : 'eva:edit-outline'} />
          </IconButton>
        </Typography>
        <Box>
          <TextField
            select
            size="small"
            value="Mick Billingsley"
            sx={{
              '& fieldset': { border: '0 !important' },
              '& .MuiSelect-select': { px: 1, py: 0.5 },
              '& .MuiOutlinedInput-root': {
                mt: 0.3,
                p: 0,
                border: `1px solid #008ECC`,
                borderRadius: 2,
                typography: 'body2',
                color: '#008ECC',
              },
              '& .MuiSelect-icon': { color: '#008ECC' },
            }}
          >
            <MenuItem
              sx={{ typography: 'body2', m: 0.5, borderRadius: 0.75, color: '#008ECC' }}
              value="Mick Billingsley"
            >
              Mick Billingsley
            </MenuItem>
            <MenuItem
              sx={{ typography: 'body2', m: 0.5, borderRadius: 0.75, color: '#008ECC' }}
              value="Lena Billingsley"
            >
              Lena Billingsley
            </MenuItem>
          </TextField>
        </Box>
      </Stack>
      {editRelated ? <EditMode /> : <DisplayMode />}
    </>
  );
}
