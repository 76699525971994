import { useQuery, useMutation } from '@tanstack/react-query';
import * as actions from './actions';

export function useGetCcmReferrals(params: actions.GetCcmReferralsQueryFilters) {
  return useQuery(['ccm-get-referrals'], () => actions.getCcmReferrals(params));
}

export function useGetCcmReferralNotes(referralId: number) {
  return useQuery(['ccm-get-referral-notes'], () => actions.getCcmReferralNotes(referralId));
}

export function useDeleteCcmReferral() {
  return useMutation(actions.deleteCcmReferral);
}
