// @ts-nocheck
import {
  Grid,
  Typography,
  Card,
  FormControl,
  TextField,
  Autocomplete,
  IconButton
} from "@mui/material";
import { useMemo } from "react";
import { Organization } from "src/@nicheaim/fhir-base/mappings/Organization";
import { useLocations } from "src/@nicheaim/fhir-react";
import Iconify from "src/components/Iconify";
import { useRoundingTypes } from "src/services/api/rounding/hooks";

type HeaderProps = {
  onChangeLocation?: (locationFhirId: string) => void;
  onChangeCategory?: (categoryId: number) => void;
  onAddRoundingPress: () => void;
}

export const Header = ({
  onChangeLocation,
  onChangeCategory,
  onAddRoundingPress,
}: HeaderProps) => {
  const [locations] = useLocations({
    filter: {
      _sort: 'name'
    }
  });
  const locationItems = useMemo(() => locations.map((location) => ({
    label: location.name,
    value: location.id
  })), [locations]);
  const { data: roundingTypes } = useRoundingTypes();

  return (
    <Grid item xl={12}>
      <Card>
        <Grid direction="row" container>
          <Grid xs={12} sm={5} xl={3} direction="row" sx={{ padding: 2 }}>
            <Typography sx={{ paddingTop: 1, paddingBottom: 2, paddingRight: 2 }} variant="h5">Type</Typography>
            <FormControl fullWidth style={{ paddingRight: '5px' }}>
              <Autocomplete
                options={roundingTypes?.map(rt => ({
                  label: rt.valueDisplayName,
                  value: rt.id,
                }))}
                disableClearable
                onChange={(_: any, value: any | null) =>
                  onChangeCategory?.(value.value)
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select rounding type" />
                )}
                style={{
                  width: 300
                }}
              />
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} xl={8} direction="row" sx={{ padding: 2 }}>
            <Typography sx={{ paddingTop: 1, paddingBottom: 2, paddingRight: 2 }} variant="h5">Location</Typography>
            <FormControl fullWidth style={{ paddingRight: '5px' }}>
              <Autocomplete
                options={locationItems}
                onChange={(_: any, value: any | null) =>
                  onChangeLocation?.(value.value)
                }
                disableClearable
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select location" />
                )}
                style={{
                  width: 300
                }}
              />
            </FormControl>
          </Grid>
          <Grid xs={12} sm={1} xl={1} item>
            <IconButton style={{ top: '48px', right: '-60px' }} onClick={onAddRoundingPress}>
              <Iconify icon="eva:plus-fill" />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}