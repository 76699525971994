import { MouseEvent, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Stack, TableCell, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { SHOW_SIDEBAR_COMPONENT, useEventBus } from 'src/event-bus';
import { ActionBar, StatusChip } from 'src/sections/careflow/common';
import SummaryTable from '../../SummaryTable2';
import LabSummaryForm from './LabSummaryForm';
import AddModal from 'src/sections/careflow/common/AddModal';

const labs = [];

interface LabSummaryProps {
  isExpanded: boolean;
  onExpand: (index: number) => void;
  patient: {
    id: string;
  };
}
const LabSummary = ({ onExpand, patient, isExpanded }: LabSummaryProps) => {
  const [showLabFormModal, setShowLabFormModal] = useState<boolean>(false);
  const [selectedLab, setSelectedLab] = useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const handleAdd = () => {
    setShowLabFormModal(true);
    setSelectedLab(null);
  };
  const handleEdit = (item: any, index: number) => {
    setShowLabFormModal(true);
    setSelectedLab(item);
  }

  const onCloseFormModal = () => {
    setShowLabFormModal(false);
    setSelectedLab(null);
  }
  return (
    <>
      <AddModal
        open={showLabFormModal}
        Component={
          <LabSummaryForm data={selectedLab} />
        }
        onClose={onCloseFormModal}
      />
      <SummaryTable
        title="Labs Summary"
        actionBar={
          <>
            <IconButton onClick={handleAdd}>
              <Iconify icon="eva:plus-fill" />
            </IconButton>
            <IconButton onClick={() => onExpand?.(5)}>
              <Iconify icon="eva:expand-fill" />
            </IconButton>
          </>
        }
        columns={
          isExpanded
            ? ['Date', 'Test / Code / Status', 'Units', 'Result', 'Range', 'Intervention']
            : ['Date', 'Test / Code / Status']
        }
        contextMenu={<></>}
        data={labs}
        rowRenderer={(row: any, index: number) => (
          <>
            <TableCell>
              <Typography variant="body2">
                {row.date.toLocaleDateString('en-US', {
                  month: 'short',
                  year: 'numeric',
                  day: 'numeric',
                })}
              </Typography>
            </TableCell>
            <TableCell key={`value-${index}`}>
              <Stack>
                <Typography variant="body2">{row.test}</Typography>
                <Typography variant="body2">{row.code}</Typography>
                {row.status === 'normal' && (
                  <Box>
                    <StatusChip label="Normal" color="success" size="small" />
                  </Box>
                )}
                {row.status === 'low' && (
                  <Box>
                    <StatusChip label="Low" color="error" size="small" />
                  </Box>
                )}
                {row.status === 'high' && (
                  <Box>
                    <StatusChip label="High" color="error" size="small" />
                  </Box>
                )}
              </Stack>
            </TableCell>
            {isExpanded && (
              <TableCell>
                <Typography variant="body2">g / dL</Typography>
              </TableCell>
            )}
            {isExpanded && (
              <TableCell>
                <Typography variant="body2">8.5</Typography>
              </TableCell>
            )}
            {isExpanded && (
              <TableCell>
                <Typography variant="body2">4.17 – 10.16</Typography>
              </TableCell>
            )}
            <TableCell align="right">
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={closeMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  onClick={(
                    (item, index): any =>
                    (): any =>
                      handleEdit(item, index)
                  )(row, index)}
                >
                  <Stack direction="row" spacing={2}>
                    <Iconify icon="eva:edit-fill" />
                    <Typography variant="body2">Edit</Typography>
                  </Stack>
                </MenuItem>
                <MenuItem>
                  <Stack direction="row" spacing={2}>
                    <Iconify icon="eva:copy-fill" />
                    <Typography variant="body2">Clone</Typography>
                  </Stack>
                </MenuItem>
              </Menu>
              <IconButton onClick={openMenu}>
                <Iconify icon="eva:more-vertical-fill" />
              </IconButton>
            </TableCell>
          </>
        )}
      />
    </>
  );
};

export default LabSummary;
