// @ts-nocheck
import { useState, useMemo, useEffect } from 'react';
import produce from 'immer';
import {
  Autocomplete,
  Button,
  Checkbox,
  createFilterOptions,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  TextField,
  Typography,
  CircularProgress,
  Grid,
  FormControl,
  Divider,
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import {
  useCareTeams,
  useEncounters,
  usePractitioners,
  useRelatedPersons,
} from 'src/@nicheaim/fhir-react';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { RelatedPersonWrapper } from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import PhoneField from './personal-details/PhoneField';
import EmailField from './personal-details/EmailField';
import AddressField from './personal-details/AddressField';
import { CareTeam } from 'src/@nicheaim/fhir-base/mappings/CareTeam';
import { PatientCard, PromptModal } from 'src/sections/careflow/common';
import { CareTeamParticipant } from 'src/application/adapters/in/ui/model';
import { useEventBus } from 'src/event-bus';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import { Practitioner } from 'src/@nicheaim/fhir-base/mappings/Practitioner';
import { RelatedPerson } from 'src/@nicheaim/fhir-base/mappings/RelatedPerson';
import fhirSystem from 'src/fhir/system';
import { getCareTeamRolesExcludingLeader, normalizeAddress } from './utils';
import { Address } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

const filter = createFilterOptions<any>();

type CareTeamFormPropsType = {
  closeForm: () => void;
};

const NOT_USER_ID = 'not-user-id';

const CareTeamForm = ({ closeForm }: CareTeamFormPropsType) => {
  const { dispatchEvent } = useEventBus();
  const patient = useCurrentPatient();
  const [isLoading, setIsLoading] = useState(false);
  const [participant, setParticipant] = useState<any>(null);
  const [role, setRole] = useState('');
  const [phones, setPhones] = useState<any>([{ system: 'phone', use: 'mobile' }]);
  const [emails, setEmails] = useState<any>([{ system: 'email', use: 'home' }]);
  const [address, setAddress] = useState<Address>({
    use: 'home',
    line: [],
    city: '',
    state: '',
    postalCode: '',
  });
  const [isLeader, setIsLeader] = useState(false);
  const [showTeamLeaderWarning, setShowTeamLeaderWarning] = useState<boolean>(false);
  const [practitioners, setPractitioners] = useState<Practitioner[]>([]);
  const [_, { update: updatePractitioner }] = usePractitioners({
    autofetch: false,
  });

  useEffect(() => {
    axiosFhirInstance
      .get(`/Practitioner?identifier=${fhirSystem.systemUser.forCode('')}&_count=1000`)
      .then(({ data }) => {
        setPractitioners(data.entry?.map((p) => p.resource) ?? []);
      });
  }, []);

  // queries to fill the user list
  const [careTeams, { create: createCareTeam, update: updateCareTeam }] = useCareTeams({
    filter: {
      patient: patient?.id,
      _count: 1000,
    },
  });
  const [relatedPersons, { create: createRelatedPerson, update: updateRelatedPerson }] =
    useRelatedPersons({
      filter: {
        patient: patient?.id,
        _count: 1000,
      },
      map: RelatedPersonWrapper,
    });
  const [encounters] = useEncounters({
    filter: {
      subject: patient?.id,
      _count: 1000,
    },
  });

  // parsed user list options
  const encounterUsersOptions = useMemo(() => {
    const parsedEncounters = encounters
      .filter(
        (relatedPerson) =>
          !careTeams?.[0]?.participant?.find(
            (careTeamMember) =>
              getFhirIdFromEntity(careTeamMember.member?.reference ?? '') ===
              getFhirIdFromEntity(relatedPerson.participant?.[0]?.individual?.reference ?? '')
          )
      )
      .map((encounter) => {
        const person = encounter.participant?.[0];
        return {
          display: person?.individual?.display,
          value: getFhirIdFromEntity(person?.individual?.reference ?? ''),
          type: person?.individual?.reference?.split('/')?.[0] ?? '',
        };
      }, []);

    return parsedEncounters;
  }, [encounters, careTeams]);

  // parsed care teams members
  const careTeamMembersOptions = useMemo(
    () =>
      careTeams.length > 0 && careTeams?.[0].participant
        ? careTeams?.[0]?.participant
            ?.filter((participant) => !participant.role?.find((role) => role?.coding?.[0].system))
            ?.filter(
              (participant) =>
                !relatedPersons.find(
                  (relatedPerson) =>
                    relatedPerson.id === getFhirIdFromEntity(participant.member?.reference ?? '')
                )
            )
            .map((participant) => ({
              display: participant.member?.display,
              value: getFhirIdFromEntity(participant.member?.reference ?? ''),
              type: participant.member?.reference?.split('/')[0] ?? '',
            }))
        : [],
    [careTeams, relatedPersons]
  );

  // parsed related persons
  const relatedPersonsOptions = useMemo(
    () =>
      relatedPersons
        .filter(
          (relatedPerson) =>
            !careTeams?.[0]?.participant?.find(
              (careTeamMember) =>
                careTeamMember.role?.find((role) => role?.coding?.[0].system) &&
                getFhirIdFromEntity(careTeamMember.member?.reference ?? '') === relatedPerson.id
            )
        )
        .map((relatedPerson) => ({
          display: relatedPerson.getFullNameWithTitles(),
          value: relatedPerson.id,
          type: relatedPerson.resourceType,
        })),
    [relatedPersons, careTeams]
  );

  // parsed practitioners
  const practitionerOptions = useMemo(
    () =>
      practitioners
        .map((practitioner) => PractitionerWrapper(practitioner))
        .map((practitioner) => ({
          display: practitioner.getFullNameWithTitles(),
          value: practitioner.id,
          type: practitioner.resourceType,
        })),
    [practitioners]
  );

  const usersOptions = useMemo(
    () => [
      ...careTeamMembersOptions,
      ...relatedPersonsOptions,
      ...encounterUsersOptions,
      ...practitionerOptions,
    ],
    [careTeamMembersOptions, encounterUsersOptions, relatedPersonsOptions, practitionerOptions]
  );

  const clearAll = () => {
    setRole('');
    setParticipant(null);
    setPhones([{ system: 'phone', use: 'mobile' }]);
    setEmails([{ system: 'email', use: 'home' }]);
    setAddress({
      use: 'home',
      line: [],
      city: '',
      state: '',
      postalCode: '',
    });
  };

  function handleAddPhone(): void {
    setPhones([...phones, { system: 'phone', use: 'mobile' }]);
  }

  function handleChangePhoneType(type: string, index: number): void {
    const updatedPhones = [...phones];
    updatedPhones[index] = { ...updatedPhones[index], use: type };
    setPhones(updatedPhones);
  }

  function handleChangePhoneValue(value: string, index: number): void {
    const updatedPhones = [...phones];
    updatedPhones[index] = { ...updatedPhones[index], value };
    setPhones(updatedPhones);
  }

  function handleFavoritePhone(value: any, index: number): void {
    const updatedPhones = phones.map((e: any) => ({ ...e, rank: 2 }));
    if (!value) {
      updatedPhones[index].rank = 1;
    }
    setPhones(updatedPhones);
  }

  function handleRemovePhone(index: number): void {
    setPhones([...phones.slice(0, index), ...phones.slice(index + 1)]);
  }

  function handleAddEmail(): void {
    setEmails([...emails, { system: 'email', use: 'home' }]);
  }

  function handleChangeEmailType(type: string, index: number): void {
    const updatedEmails = [...emails];
    updatedEmails[index] = { ...updatedEmails[index], use: type };
    setEmails(updatedEmails);
  }

  function handleChangeEmailValue(value: string, index: number): void {
    const updatedEmails = [...emails];
    updatedEmails[index] = { ...updatedEmails[index], value };
    setEmails(updatedEmails);
  }

  function handleFavoriteEmail(value: any, index: number): void {
    const updatedEmails = emails.map((e: any) => ({ ...e, rank: 2 }));
    if (!value) {
      updatedEmails[index].rank = 1;
    }
    setEmails(updatedEmails);
  }

  function handleRemoveEmail(index: number): void {
    setEmails([...emails.slice(0, index), ...emails.slice(index + 1)]);
  }

  function handleAddAddress(): void {
    throw new Error('Function not implemented.');
  }

  function handleChangeAddress(addr: any): void {
    throw new Error('Function not implemented.');
  }

  function handleRemoveAddress(): void {
    throw new Error('Function not implemented.');
  }

  const checkTypeAndAction = async (type: string, callback: () => void) => {
    if (participant?.type === type) {
      return callback?.();
    }
  };

  const saveParticipantAndUpdateCareTeam = async () => {
    let careTeamParticipant: CareTeamParticipant | null = null;
    let newParticipant: RelatedPerson | Practitioner | null = null;
    let existsParticipant: Practitioner | null = null;
    const parsedDisplay = participant.display.replace('Add ', '').replace('"', '').replace('"', '');

    if (participant.value !== NOT_USER_ID) {
      await checkTypeAndAction('Practitioner', async () => {
        const { data } = await axiosFhirInstance.get(`/Practitioner/${participant.value}`);
        console.log({
          practitioner: data,
        });
        existsParticipant = data;
      });

      await checkTypeAndAction('RelatedPerson', async () => {
        const { data } = await axiosFhirInstance.get(`/RelatedPerson/${participant.value}`);
        console.log({
          relatedPerson: data,
        });
        existsParticipant = data;
      });
    }

    console.log({
      existsParticipant,
    });

    if (existsParticipant) {
      console.log('exists');
      newParticipant = existsParticipant;

      await checkTypeAndAction('Practitioner', async () => {
        await updatePractitioner(
          produce(existsParticipant!, (draft) => {
            draft.telecom = [...phones, ...emails];
            draft.address = [normalizeAddress(address)];
          })
        );
      });

      await checkTypeAndAction('RelatedPerson', async () => {
        await updateRelatedPerson(
          produce(existsParticipant!, (draft) => {
            draft.telecom = [...phones, ...emails];
            draft.address = [normalizeAddress(address)];
          })
        );
      });
    } else {
      console.log('not exiss');
      await checkTypeAndAction('RelatedPerson', async () => {
        const personsResponse = await createRelatedPerson({
          resourceType: 'RelatedPerson',
          active: true,
          patient: {
            reference: `Patient/${patient?.id}`,
          },
          name: [
            {
              family: parsedDisplay,
            },
          ],
          telecom: [...(phones ? phones : []), ...(emails ? emails : [])],
          address: [normalizeAddress(address)],
        });

        newParticipant = personsResponse?.[0];
      });
    }

    if (careTeams?.length > 0) {
      await updateCareTeam(
        produce(careTeams[0], (draft) => {
          careTeamParticipant = {
            member: {
              reference: `${newParticipant?.resourceType}/${newParticipant?.id}`,
              display: parsedDisplay,
            },
            role: [
              {
                coding: [
                  {
                    system: 'careTeamRole',
                  },
                ],
                text: '',
              },
              ...(isLeader
                ? [
                    {
                      coding: [
                        {
                          system: 'careTeamRole',
                        },
                      ],
                      text: 'Team Leader',
                    },
                  ]
                : []),
            ],
          };

          console.log({
            careTeamParticipant,
            existsParticipant,
          });

          if (existsParticipant) {
            const practitionerIndex =
              careTeams[0]?.participant?.findIndex(
                (i) => getFhirIdFromEntity(i.member?.reference ?? '') === existsParticipant?.id
              ) ?? -1;

            if (practitionerIndex !== -1) {
              let newParticipantLists = [...(draft?.participant || [])];

              if (isLeader) {
                newParticipantLists = newParticipantLists.map((member) => ({
                  ...member,
                  role: member.role?.filter((rol: any) => rol.text !== 'Team Leader'),
                }));
              }

              const existingCareTeamRecord = newParticipantLists[practitionerIndex];
              newParticipantLists[practitionerIndex] = {
                ...careTeamParticipant,
                role: [
                  ...(existingCareTeamRecord.role ?? []),
                  ...(careTeamParticipant?.role ?? []),
                ],
              };

              console.log('1', {
                practitionerIndex,
                list: newParticipantLists,
              });

              draft.participant = [...newParticipantLists];
            } else {
              draft.participant = [...(draft?.participant || [])];

              if (isLeader) {
                draft.participant = draft.participant.map((member) => ({
                  ...member,
                  role: member.role?.filter((rol: any) => rol.text !== 'Team Leader'),
                }));
              }

              draft.participant.push(careTeamParticipant);

              console.log('2', {
                list: draft.participant,
              });
            }
          } else {
            draft.participant = [...(draft?.participant || [])];
            if (isLeader) {
              draft.participant = draft.participant.map((member) => ({
                ...member,
                role: member.role?.filter((rol: any) => rol.text !== 'Team Leader'),
              }));
            }
            draft.participant.push(careTeamParticipant);
            console.log('3', {
              list: draft.participant,
            });
          }
        })
      )
        .then(() => {
          clearAll();
          closeForm();
        })
        .catch((error) => console.log(error));
    } else {
      const newCareTeam: CareTeam = {
        resourceType: 'CareTeam',
        status: 'active',
        subject: {
          reference: `Patient/${patient?.id}`,
        },
        participant: [
          {
            member: {
              reference: `${newParticipant?.resourceType}/${newParticipant?.id}`,
              display: parsedDisplay,
            },
            role: [
              {
                coding: [
                  {
                    system: 'careTeamRole',
                  },
                ],
                text: role,
              },
              ...(isLeader
                ? [
                    {
                      coding: [
                        {
                          system: 'careTeamRole',
                        },
                      ],
                      text: 'Team Leader',
                    },
                  ]
                : []),
            ],
          },
        ],
      };

      await createCareTeam(newCareTeam)
        .then(() => {
          clearAll();
          closeForm();
        })
        .catch((error) => console.log(error));
    }
  };

  const saveCareTeam = async () => {
    await saveParticipantAndUpdateCareTeam();
  };

  const handleSave = async () => {
    if (participant === null) return;
    setIsLoading(true);
    await saveCareTeam();
    dispatchEvent('UPDATE_CARETEAM_SUMMARY');
  };

  const disabledButtons = participant?.type === 'Practitioner';

  useEffect(() => {
    if (participant) {
      if (participant.type === 'Practitioner') {
        axiosFhirInstance.get(`/Practitioner/${participant.value}`).then(({ data }) => {
          fillFieldsFromUser(data);
        });
      } else {
        axiosFhirInstance
          .get(`/RelatedPerson/${participant.value}`)
          .then(({ data }) => {
            fillFieldsFromUser(data);
          })
          .catch((error) => console.log(error));
      }
    }
  }, [participant]);

  const fillFieldsFromUser = (user: Practitioner) => {
    const initParticipant = PractitionerWrapper(user);
    const initRoles = getCareTeamRolesExcludingLeader(user);
    const initEmails = initParticipant?.getEmails();
    const initPhones = initParticipant?.getPhones();
    const initAddress = initParticipant?.getPrimaryAddress();

    setRole(initRoles ?? '');
    setPhones(
      initPhones?.length && initPhones?.length > 0
        ? initPhones
        : [{ system: 'phone', use: 'mobile' }]
    );
    setEmails(
      initEmails?.length && initEmails?.length > 0 ? initEmails : [{ system: 'email', use: 'home' }]
    );
    setAddress(initAddress || { use: '', line: [], city: '', state: '', postalCode: '' });
  };

  return (
    <Stack px={2}>
      <PromptModal
        title="Are you sure you want to reassign the Care Team Leader?"
        subtitle=""
        leftButtonTitle="No, go back"
        rightButtonTitle="Yes, reassign it"
        onCancelPress={() => {
          setShowTeamLeaderWarning(false);
          setIsLeader(false);
        }}
        onContinuePress={() => {
          setShowTeamLeaderWarning(false);
        }}
        open={showTeamLeaderWarning}
        disableButtons={false}
      />
      <Grid>
        <PatientCard id={patient?.id} showCloseButton={false} />
      </Grid>
      <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="subtitle1">Add Care Team</Typography>
        <IconButton onClick={closeForm}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>
      <Autocomplete
        clearOnBlur
        disablePortal
        options={usersOptions}
        getOptionLabel={(option: any) => option.display}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (params.inputValue !== '') {
            filtered.unshift({
              value: NOT_USER_ID,
              display: `Add "${params.inputValue}"`,
              type: 'RelatedPerson',
            });
          }

          return filtered;
        }}
        value={participant}
        renderInput={(params: any) => (
          <TextField
            {...params}
            fullWidth
            label="Participant"
            margin="dense"
            size="small"
            variant="standard"
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.display}
          </li>
        )}
        onChange={(ev, newValue) => {
          setParticipant(newValue);
        }}
      />

      {phones?.map((phone: any, index: number) => (
        <PhoneField
          key={`phone-${index}`}
          isLast={phones?.length - 1 === index}
          onAdd={() => handleAddPhone()}
          onChangeType={(type) => handleChangePhoneType(type, index)}
          onChangeValue={(value) => handleChangePhoneValue(value, index)}
          onSelectFavorite={(event: React.MouseEvent<HTMLElement>, value: any) =>
            handleFavoritePhone(value, index)
          }
          onRemove={phones?.length > 1 ? () => handleRemovePhone(index) : undefined}
          rank={phone.rank}
          type={phone.use}
          value={phone.value}
          disabled={disabledButtons}
        />
      ))}
      {emails?.map((email: any, index: number) => (
        <EmailField
          key={`email-${index}`}
          isLast={emails?.length - 1 === index}
          onAdd={() => handleAddEmail()}
          onChangeType={(type) => handleChangeEmailType(type, index)}
          onChangeValue={(value) => handleChangeEmailValue(value, index)}
          onSelectFavorite={(event: React.MouseEvent<HTMLElement>, value: any) =>
            handleFavoriteEmail(value, index)
          }
          onRemove={
            phones?.length && phones?.length > 1 ? () => handleRemoveEmail(index) : undefined
          }
          rank={email.rank}
          type={email.use}
          value={email.value}
          disabled={disabledButtons}
        />
      ))}
      <AddressField value={address} onChange={setAddress} disabled={disabledButtons} />
      <TextField
        fullWidth
        label="Role"
        margin="dense"
        size="small"
        variant="standard"
        onChange={(ev) => setRole(ev.target.value)}
        value={role}
        disabled={disabledButtons}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={isLeader}
              onChange={(ev) => {
                if (ev.target.checked === true) {
                  setShowTeamLeaderWarning(true);
                }
                setIsLeader(ev.target.checked);
              }}
            />
          }
          label="Is Team Leader"
        />
      </FormGroup>
      <Grid container spacing={2} direction="row">
        <Grid item xs={6} style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <Button
              disabled={isLoading}
              onClick={clearAll}
              size="large"
              fullWidth
              variant="outlined"
              color="secondary"
            >
              Clear all
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={6} style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <Button
              disabled={isLoading}
              onClick={handleSave}
              size="large"
              style={{ width: '100%' }}
              variant="contained"
              color="secondary"
            >
              {isLoading ? <CircularProgress size={15} /> : 'Save'}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CareTeamForm;
