import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Label from 'src/components/Label';
import DataTable, { DataTableColumn } from 'src/components/table/DataTable';
import api from 'src/services/api';
import {
  opportunityPriorities,
  opportunitySourceTypes,
  opportunityStatus,
} from 'src/_mock/settings/_careplan-automation';
import { CreatedUpdated } from '../common';
import OpportunitiesContextMenu from './OpportunitiesContextMenu';

const columns: DataTableColumn[] = [
  {
    header: 'Opportunity',
    field: 'name',
  },
  {
    header: 'Alias Name',
    field: 'alias',
  },
  {
    header: 'Priority',
    field: 'priority',
    render(value) {
      return opportunityPriorities[value];
    },
  },
  {
    header: 'Source Type',
    field: 'sourceType',
    render(value) {
      return opportunitySourceTypes[value];
    },
  },
  {
    header: 'Status',
    field: 'status',
    render(value) {
      return (
        <Label color={value === 'active' ? 'success' : 'info'}>{opportunityStatus[value]}</Label>
      );
    },
  },
  {
    header: 'Created',
    field: '$',
    render(value) {
      return <CreatedUpdated by={value.createdBy} date={value.createdOn} />;
    },
  },
  {
    header: 'Updated',
    field: '$',
    render(value) {
      return <CreatedUpdated by={value.updatedBy} date={value.updatedOn} />;
    },
  },
];

export default function OpportunitiesTable() {
  const { data: opportunities = [], refetch } = useQuery(
    ['opportunities'],
    async () =>
      (await api.carePlan.findOpportunities({
        status: '',
      })) as any[]
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <DataTable
        columns={columns}
        data={opportunities}
        selectable
        rowContextMenu={(item: any) => (
          <OpportunitiesContextMenu
            item={item}
            onDelete={() => {
              refetch();
            }}
          />
        )}
      />
    </div>
  );
}
