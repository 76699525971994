import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Button } from '@mui/material';
// components
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { useHealthcareServices } from 'src/@nicheaim/fhir-react';
import { HealthcareService } from 'src/@nicheaim/fhir-base/mappings/HealthcareService';
import fhirSystem from 'src/fhir/system';

// ----------------------------------------------------------------------

type FormValue = {
  name: string;
};

type ProgramFormProps = {
  isEdit?: boolean;
  currentProgram?: HealthcareService;
};

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
});

const resolver = yupResolver(schema);

export default function NewProgramForm({ isEdit, currentProgram }: ProgramFormProps) {
  const navigate = useNavigate();
  const { lob = '' } = useParams();
  const [, { create, update }] = useHealthcareServices({ autofetch: false });

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () =>
      ({
        name: currentProgram?.name || '',
      } as FormValue),
    [currentProgram]
  );

  const methods = useForm({ resolver, defaultValues });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentProgram) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentProgram]);

  const onSubmit = async (data: FormValue) => {
    try {
      if (isEdit && currentProgram) {
        await update({
          resourceType: 'HealthcareService',
          id: currentProgram.id,
          ...data,
        });
      } else {
        const [program] = await create({
          resourceType: 'HealthcareService',
          identifier: [
            {
              system: fhirSystem.program.asString(),
            },
            {
              system: fhirSystem.program.withId(lob).asString(),
            },
          ],
          // partOf: {
          //   type: 'Organization',
          //   id: lob,
          //   reference: `Organization/${lob}`,
          // },
          name: data.name,
          active: true,
        });
        // lob.identifier![0].value = lob.id;
        // lob.identifier![1].value = lob.id;
        await update(program);
      }

      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      navigate(isEdit ? '../..' : '..');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 3 }}>
        <Stack
          gap={3}
          sx={{
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <RHFTextField name="name" label="Program Name" />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }} gap={2}>
          <Button
            variant="text"
            color="inherit"
            component={RouterLink}
            to={isEdit ? '../..' : '..'}
          >
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {!isEdit ? 'Create Program' : 'Save Changes'}
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
