import { Typography, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import { Select } from 'src/components/Select';
import api from 'src/services/api';
import useCarePlanAutomationFormStore, {
  AutomationRule,
  useCarePlanAutomationFormError,
  useCarePlanAutomationFormRule,
} from '../../store';

interface GoalSelectorProps {
  question: WrappedQuestionnaireItem;
  rule: AutomationRule;
}

export default function GoalSelector({ question, rule }: GoalSelectorProps) {
  const saving = useCarePlanAutomationFormStore((s) => s.loading);
  const { enabled } = useCarePlanAutomationFormRule(rule.id);
  const { goal, setGoal } = useCarePlanAutomationFormRule(rule.id);
  const error = useCarePlanAutomationFormError(`rule(${question.linkId}:${rule.id})-goal`);

  const { data: mappings = [], isLoading } = useQuery(['care-plan-automation/goals'], () =>
    api.carePlan.findMappingsGoalGroupGoal()
  );

  const goals = mappings.reduce(
    (acc, mapping) => {
      const { goalGroup, goal } = mapping;
      const goalGroupId = `${goalGroup.id}`;
      const group = acc.groups.find((g) => g.id === goalGroupId);
      const option = { value: `${mapping.id}`, label: goal.name };

      if (group) {
        group.options.push(option);
      } else {
        acc.groups.push({
          id: goalGroupId,
          label: goalGroup.name,
          options: [option],
        });
      }

      acc.options.push(option);
      return acc;
    },
    {
      groups: [],
      options: [],
    } as {
      groups: {
        id: string;
        label: string;
        options: {
          label: string;
          value: string;
        }[];
      }[];
      options: {
        label: string;
        value: string;
      }[];
    }
  );

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
      <Typography sx={{ minWidth: 120, pb: error ? 2 : 0 }}>Goal:</Typography>
      <Select
        styles={{
          container: (provided) => ({
            ...provided,
            flex: 1,
          }),
        }}
        error={error}
        isDisabled={isLoading || !enabled || saving}
        isLoading={isLoading}
        placeholder="Select Goal"
        value={goals.options.find((o) => o.value === goal)}
        onChange={(option) => setGoal(option?.value || '')}
        options={goals.groups}
      />
    </Stack>
  );
}
