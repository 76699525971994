import client from './_client';
import moment from 'moment-timezone';
import {
  ActivityDueStatusType,
  ActivityStatusType,
} from 'src/sections/careflow/tasks-activities/components/Activities/constants';
moment.tz.setDefault('America/Los_Angeles');
export type GetActivitiesQueryFilters = {
  userEmail: string | null;
  patientFhirId: string | null;
  currentRange: {
    start: Date | null;
    end: Date | null;
  } | null;
  status: ActivityStatusType | null;
  dueStatus: ActivityDueStatusType | null;
  pagination: {
    take: number;
    page: 0;
  };
};

export async function getActivities(filters: GetActivitiesQueryFilters) {
  const {
    userEmail,
    patientFhirId,
    currentRange,
    status,
    dueStatus,
    pagination: { take, page },
  } = filters;

  // TODO: Use URLSearchParams or URL to build the final URL instead of manually concatenating the query params
  let url = `/ccm/activity/activities?take=${take}&skip=${page * take}`;

  if (userEmail) {
    url = `${url}&userEmail=${userEmail}`;
  }

  if (patientFhirId) {
    url = `${url}&patientFhirId=${patientFhirId}`;
  }

  if (status) {
    url = `${url}&status=${status}`;
  }

  if (dueStatus) {
    url = `${url}&dueStatus=${dueStatus}`;
  }

  if (currentRange) {
    console.log('currentRange', currentRange);
    const start = moment(currentRange.start).startOf('day').toISOString();
    const end = moment(currentRange.end).endOf('day').toISOString();
    url = `${url}&start=${start}&end=${end}`;
  }

  const { data } = await client.get(url);

  return data;
}

export async function create(params: any) {
  const { data } = await client.post(`/ccm/activity/create`, params);

  return data;
}

export async function update(id: number, params: any) {
  const { data } = await client.post(`/ccm/activity/edit/${id}`, params);

  return data;
}

export async function start(id: number) {
  const { data } = await client.post(`/ccm/activity/start/${id}`);

  return data;
}

export async function complete(id: number, params: any) {
  const { data } = await client.post(`/ccm/activity/complete/${id}`, params);

  return data;
}

export async function cancel(
  id: number,
  { completedDate, completedTime }: { completedDate: string; completedTime: string }
) {
  const { data } = await client.post(`/ccm/activity/cancel/${id}`, {
    completedDate,
    completedTime,
  });

  return data;
}

export async function getActivityById(id: number) {
  const { data } = await client.get(`/ccm/activity/by-id/${id}`);

  return data;
}

export async function getActivityByFhirId(id: string) {
  const { data } = await client.get(`/ccm/activity/by-fhir-id/${id}`);

  return data;
}

export async function getGoogleAuthUrl() {
  const { data } = await client.get(`/ccm/activity/auth-google-api`);

  return data;
}

export async function activitiesSummary(patientFhirId?: string, staffFhirId?: string) {
  let url = `/ccm/activity/summary?patientFhirId=${patientFhirId}`;

  if (staffFhirId) {
    url = `/ccm/activity/summary?staffFhirId=${staffFhirId}`;
  }
  const { data } = await client.get(url);

  return data;
}
