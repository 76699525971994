import { isEmpty } from 'lodash';
import { Address, Email, Patient, Phone } from "../Patient";
import { ResourceMapper } from "./ResourceMapper";
import {
  Address as FhirAddress,
  CodeableConcept,
  ContactPoint,
  HumanName,
  Patient as FhirPatient
} from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";

export class PatientMapper implements ResourceMapper<FhirPatient> {

  fromResource(fhirPatient: FhirPatient): Patient {
    const officialName = fhirPatient?.name?.find(name => name.use === 'official');
    const givenName = officialName?.given?.join(' ');
    const familyName = officialName?.family;
    return {
      addresses: fhirPatient.address
        ?.map((fhirAddress: FhirAddress) => {
          const { use, text = '' } = fhirAddress;
          return { use, value: text };
        })
      ,
      alias: fhirPatient?.name?.find(name => name.use === 'nickname')?.given?.join(' '),
      birthDate: new Date(fhirPatient?.birthDate || ''),
      emails: (fhirPatient?.telecom || [])
        .filter(contactPoint => contactPoint.system === 'email')
        .map(contactPoint => {
          const { use, value = '' } = contactPoint;
          return { use, value };
        })
      ,
      givenName,
      familyName,
      fullName: `${givenName} ${familyName}`,
      gender: fhirPatient?.gender,
      maritalStatus: fhirPatient?.maritalStatus?.coding?.[0] ?? null,
      phoneNumbers: (fhirPatient.telecom || [])
        .filter(contactPoint => contactPoint.system === 'phone')
        .map(contactPoint => {
          const { use, value = '' } = contactPoint;
          return { use, value };
        })
      ,
      ssn: fhirPatient?.identifier?.find(id => id.use === 'official')?.value,
      fhirResource: fhirPatient,
    };
  }

  toResource(patient: Patient): FhirPatient {
    return {
      resourceType: 'Patient',
      identifier: [
        {
          use: 'official',
          value: patient.ssn
        }
      ],
      name: [
        {
          use: 'official',
          given: patient.givenName?.split(' '),
          family: patient.familyName,
        },
        ...[
          isEmpty(patient.alias)
            ? []
            : [
              {
                use: 'nickname',
                given: patient.alias?.split(' ')
              }
            ]
        ] as HumanName[]
      ],
      address: patient.addresses?.map((address: Address): FhirAddress => ({
        use: address.use,
        text: address.value,
      })),
      birthDate: patient.birthDate?.toISOString(),
      telecom: isEmpty(patient.emails) && isEmpty(patient.phoneNumbers)
        ? undefined
        :
          [
            ...(patient.emails || []).map((email: Email): ContactPoint => ({
              use: email.use,
              value: email.value,
            })),
            ...(patient.phoneNumbers || []).map((phone: Phone): ContactPoint => ({
              use: phone.use,
              value: phone.value,
            }))
          ]
      ,
      gender: patient.gender,
      maritalStatus: isEmpty(patient.maritalStatus)
        ? undefined
        : {
          code: patient.maritalStatus?.code
        } as CodeableConcept
    };
  }

}
