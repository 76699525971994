import { useCallback, useContext, useState } from 'react';
import {
  Container,
  Grid,
  Tabs,
  Tab,
  Card,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Divider,
  Typography,
  IconButton,
  TableBody,
  TablePagination,
} from '@mui/material';
import { getGroupSessionsTableColumns } from '../../constants';
import Iconify from 'src/components/Iconify';
import TabItem from '../../types/TabItem';
import { GroupSessionContext } from '../../GroupSessionContext';
import GroupSessionType from '../../types/GroupSessionType';
import { EntityContextMenu } from 'src/sections/careflow/common';
import ComponentWithLoading from 'src/sections/careflow/common/ComponentWithLoading';
import { PaginationType } from '../../useController';
import { useMatch, useNavigate } from 'react-router';

const TAB_VALUES: TabItem[] = [
  {
    key: 0,
    value: 2,
    label: 'All',
  },
  {
    key: 1,
    value: 1,
    label: 'Active',
  },
  {
    key: 2,
    value: 0,
    label: 'Inactive',
  },
];

type GroupSessionListTablePropsType = {
  data: {
    pagination: PaginationType;
    items: GroupSessionType[];
  };
  onSelectGroupSession: (groupSession: GroupSessionType) => void;
  isLoading: boolean;
};

export const ListTable = ({
  data,
  onSelectGroupSession,
  isLoading,
}: GroupSessionListTablePropsType) => {
  const navigate = useNavigate();
  const { items, pagination } = data;
  const groupSessionContext = useContext(GroupSessionContext);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(TAB_VALUES[0].key);
  const renderTabs = useCallback(
    () => (
      <Tabs value={selectedTabIndex} variant="scrollable" scrollButtons="auto">
        {TAB_VALUES?.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            onClick={() => {
              setSelectedTabIndex(tab.key);
              groupSessionContext.filterEntities(null, tab.value as number);
            }}
            id={`status-tab-${tab.key}`}
            {...{
              'aria-controls': `status-tabpanel-${tab.key}`,
            }}
          />
        ))}
      </Tabs>
    ),
    [groupSessionContext, selectedTabIndex]
  );

  const handleChangePage = (_: any, newPage: number) => {
    groupSessionContext.getEntities({
      pagination: {
        take: pagination?.take ?? 0,
        page: newPage,
        total: 0,
      },
    });
  };

  const renderTableCell = (title: string, item: GroupSessionType) => (
    <TableCell onClick={() => {
      onSelectGroupSession(item);
      navigate(`/dashboard/group-sessions/${item.id}/sessions`);
    }}>{title}</TableCell>
  );

  return (
    <Card sx={{ marginBottom: 2, marginTop: 3 }}>
      <TableContainer sx={{ padding: 2 }}>
        <Grid justifyContent="space-between" container direction="row">
          <Grid item xl={9}>
            {renderTabs()}
          </Grid>
          <Grid item>
            <IconButton onClick={() => {
              navigate('/dashboard/group-sessions/new');
            }}>
              <Iconify icon="eva:plus-fill" />
            </IconButton>
          </Grid>
        </Grid>
        <Divider sx={{ marginBottom: 2 }} />
        <ComponentWithLoading
          isLoading={isLoading}
          Component={
            <Table>
              <TableHead>
                <TableRow>
                  {getGroupSessionsTableColumns.map((col: any, index: number) => (
                    <TableCell style={{ width: col.width }} key={index}>
                      <Typography>{col.label}</Typography>
                    </TableCell>
                  ))}
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.id} style={{ cursor: 'pointer' }}>
                    {renderTableCell(item.name, item)}
                    {renderTableCell(item.description, item)}
                    {renderTableCell(
                      item.locationFhirName.split('*|*').join(', ') || 'No address',
                      item
                    )}
                    {renderTableCell(item.participants?.length.toString() ?? '0', item)}
                    {renderTableCell(item.assessments?.length.toString() ?? '0', item)}
                    {renderTableCell(item.status ? 'active' : ' inactive', item)}
                    <TableCell>
                      <EntityContextMenu
                        entity={item}
                        items={[
                          {
                            label: 'Edit',
                            icon: 'eva:edit-2-outline',
                            onPress: () => {
                              groupSessionContext.openScheduleSessionModal(item);
                              navigate(`/dashboard/group-sessions/${item.id}/edit`);
                            },
                          },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          }
        />
        {items.length === 0 && (
          <Grid
            item
            justifyItems={'center'}
            alignContent="center"
            alignItems={'center'}
            sx={{ padding: 4, width: '100%' }}
          >
            <Typography variant="h6" textAlign="center">
              No Group Sessions found
            </Typography>
          </Grid>
        )}
        <TablePagination
          component="div"
          count={pagination?.total ?? 0}
          page={pagination?.page ?? 0}
          onPageChange={handleChangePage}
          rowsPerPage={pagination?.take ?? 0}
          rowsPerPageOptions={[]}
          labelDisplayedRows={() =>
            `Showing ${items?.length ?? 0} Group sessions | Page ${
              pagination && pagination?.total && pagination?.total > 0 ? pagination?.page + 1 : 0
            } of ${pagination && pagination?.total && Math.ceil(pagination?.total / pagination?.take)}`
          }
          showFirstButton
          showLastButton
        />
      </TableContainer>
    </Card>
  );
};
