import client from '../../_client';

export async function getSystemSettings() {
  const { data } = await client.get('/settings/system');

  return data;
}

export async function getSystemSetting(name: string) {
  const { data } = await client.get(`/settings/system/${name}`);

  return data;
}

export async function updateSystemSetting(name: string, value: any) {
  const { data } = await client.put(`/settings/system/${name}`, value);

  return data;
}
