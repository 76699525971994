import { useState } from 'react';
import { UploadFiles } from 'src/sections/crs/common';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { Box, Button, DialogActions, Link, Stack, Tab, } from '@mui/material';
import { WrappedDocumentReference } from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import Viewer from 'src/sections/crs/common/Viewer';

type Props = {
  nextStep: VoidFunction;
  backStep: VoidFunction;
  documentReferences: WrappedDocumentReference[] | null;
  documentLink: any; 
  patient: WrappedPatient | null;
  docRelated?: any;
};

export default function OutboundReferralAttach ({ 
  nextStep, 
  backStep, 
  documentReferences,
  documentLink,
  patient,
  docRelated
}: Props) {

  const [value, setValue] = useState('1');
  const [data, setData] = useState(null);
  const [showViewer, setShowViewer] = useState(false);

  const handleClick = (documentSelected:any, )=>{
    setShowViewer(true);
    setData(documentSelected);
  }
  
  return (
    <>
      <TabContext value={value}>
        <Box sx={{ px: 3 }}>
          <TabList onChange={(e, value) => setValue(value)}
          >
            <Tab 
              disableRipple 
              value="1" 
              label="Link" 
              onClick={() => setShowViewer(false)}
            />
            <Tab
              disableRipple
              value="2"
              label="Upload"
              sx={{ '& .MuiTab-wrapper': { whiteSpace: 'nowrap' } }}
              onClick={() => setShowViewer(false)}
            />
          </TabList>
        </Box>

        <TabPanel value="1" sx={{ p: 3 }}>
          <Stack spacing={2} sx= {{ mt: 2 }}>
            {documentLink?.map((l, index: number) => (
              <Link 
                key={'link-' + index}
                variant="subtitle2" 
                color="#1890FF" 
                underline="always" 
                onClick={() => handleClick(l)}
              >
                {l.content?.[0].attachment?.title}
              </Link>
            ))}
            
          </Stack>
        </TabPanel>
        <TabPanel value="2" sx={{ p: 3 }}>
            <Stack spacing={2} sx= {{ mt: 2 }} flexDirection='column'>
              <UploadFiles label='referral' docRelated={docRelated} documentReferences={documentReferences} patient={patient}/>
            </Stack>
        </TabPanel>
      </TabContext>
      <Stack spacing={2} alignItems="center">
        <DialogActions>
          <Box sx={{ flexGrow: 1 }} />
            <Button variant="outlined" color="info" onClick={backStep}>
              Back
            </Button>
            <Button variant="outlined" color="info" onClick={nextStep}>
              Next
            </Button>
        </DialogActions>
      </Stack>
      {showViewer && ( <Viewer documentReference={data} typeResource="documentReference" patientId={patient?.getMRN()?.value} />)}
    </>
  );
}
  