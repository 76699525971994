import { useNavigate } from 'react-router';
import { EntityContextMenu } from 'src/sections/careflow/common';
import api from 'src/services/api';
import { useDialogStore } from 'src/stores/dialog';

export interface InterventionsContextMenuProps {
  item: any;
  onEdit?: () => void | Promise<void>;
  onDelete?: () => void | Promise<void>;
  onAction?: (action: 'edit' | 'delete') => void | Promise<void>;
}

export default function InterventionsContextMenu({
  item,
  onEdit,
  onDelete,
  onAction,
}: InterventionsContextMenuProps) {
  const navigate = useNavigate();
  const { confirmDialog } = useDialogStore();

  return (
    <EntityContextMenu
      entity={item}
      items={[
        {
          label: 'Edit',
          icon: 'eva:edit-fill',
          onPress: async () => {
            navigate(`edit/${item.uuid}`);
            await onEdit?.();
            await onAction?.('edit');
          },
        },
        {
          label: 'Delete',
          icon: 'eva:trash-2-outline',
          onPress: async () => {
            const confirmed = await confirmDialog({
              title: 'Delete',
              description: 'Are you sure you want to delete this item?',
            });

            if (confirmed) {
              await api.carePlan.deleteIntervention(item.uuid);
              await onDelete?.();
              await onAction?.('delete');
            }
          },
        },
      ]}
    />
  );
}
