import jwtDecode from 'jwt-decode';
// routes
import { PATH_AUTH } from '../routes/paths';
//
import axios from './axios';
import { DEBUG_CONFIG, DEVELOPMENT_CONFIG } from '../config';

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  return validateToken(accessToken).isTokenValid;
};

const validateToken = (accessToken?: string) => {
  const result = {
    isTokenValid: false,
    remainingMsec: 0,
  };
  if (DEVELOPMENT_CONFIG.disableTokenCheck) {
    result.isTokenValid = true;
    return result;
  }
  if (!accessToken) {
    return result;
  }

  const decoded = jwtDecode<{ exp: number }>(accessToken);

  result.remainingMsec = decoded.exp * 1000 - Date.now();
  result.isTokenValid = result.remainingMsec > 0;

  if (DEBUG_CONFIG.debugTokenLife) {
    if (result.isTokenValid) {
      const remainingTotalSeconds = Math.floor(result.remainingMsec / 1000);
      const remainingMinutes = Math.floor(remainingTotalSeconds / 60);
      const remainingSeconds = remainingTotalSeconds % 60;
      console.log(
        `Remaining token life: ${remainingMinutes}:${
          remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds
        }`
      );
    } else {
      console.log(`Remaining token life: 0:00`);
    }
  }

  return result;
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    localStorage.removeItem('accessToken');

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken2', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(accessToken); // ~5 days by minimals server
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
