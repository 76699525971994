import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as actions from './actions';

export function useCarePlanStatuses() {
  return useQuery(['care-plan-statuses'], () => actions.findCarePlanStatuses());
}

export function useCarePlanStatus(id: string) {
  return useQuery(['care-plan-statuses', id], () => actions.getCarePlanStatus(id));
}

export function useCreateCarePlanStatusMutation() {
  const client = useQueryClient();
  return useMutation(actions.createCarePlanStatus, {
    onSuccess: (data) => {
      client.refetchQueries({
        predicate: (query) => query.queryKey[0] === 'care-plan-statuses',
      });
    },
  });
}

export function useUpdateCarePlanStatusMutation() {
  const client = useQueryClient();
  return useMutation(actions.updateCarePlanStatus, {
    onSuccess: (data) => {
      client.refetchQueries({
        predicate: (query) => query.queryKey[0] === 'care-plan-statuses',
      });
    },
  });
}

export function useDeleteCarePlanStatusMutation() {
  const client = useQueryClient();
  return useMutation(actions.deleteCarePlanStatus, {
    onSuccess: (data) => {
      client.refetchQueries({
        predicate: (query) => query.queryKey[0] === 'care-plan-statuses',
      });
    },
  });
}
