import { useState } from 'react';
// components
import Iconify from 'src/components/Iconify';
import { Stack, InputAdornment, TextField, Box, IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function ProgramsToolbar() {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ py: 2.5, px: 3 }}
      justifyContent="space-between"
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <IconButton onClick={() => navigate('../..')}>
          <Iconify icon="eva:arrow-back-outline" />
        </IconButton>

        <TextField
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          placeholder="Search programs..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon={'eva:search-fill'}
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Box>
        <IconButton>
          <Iconify icon="bi:file-earmark-excel-fill" />
        </IconButton>

        <IconButton>
          <Iconify icon="bi:filter" />
        </IconButton>

        <Link to="new">
          <IconButton>
            <Iconify icon="eva:plus-fill" />
          </IconButton>
        </Link>
      </Box>
    </Stack>
  );
}
