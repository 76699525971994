import { Navigate, Route, Routes } from 'react-router';
import EditActivity from './$activity.edit';
import ActivitiesTable from './components/ActivitiesTable';
import NewActivityForm from './new';

export default function ActivitiesIndex() {
  return (
    <Routes>
      <Route index element={<ActivitiesTable />} />
      <Route path="new" element={<NewActivityForm />} />
      <Route path=":activity" element={<Navigate to="edit" />} />
      <Route path=":activity/edit" element={<EditActivity />} />
    </Routes>
  );
}
