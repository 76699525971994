import { Box } from '@mui/material';
import { useNavigate } from 'react-router';
import { useCodeSystems } from 'src/@nicheaim/fhir-react';
import DataTable, { DataTableColumn } from 'src/components/table/DataTable';
import ActivityTypesToolbar from './ActivityTypesToolbar';
import ActivityTypesContextMenu from './ActivityTypesContextMenu';
import Label from 'src/components/Label';
import { CodeSystemConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

const columns = [
  {
    header: 'Activity Type',
    field: 'display',
  },
  {
    header: 'Status',
    field: 'property',
    render(value: CodeSystemConcept['property']) {
      const active =
        !value ||
        value.length === 0 ||
        value.find((p) => p.code === 'active')?.valueBoolean === true;

      return <Label color={active ? 'success' : 'info'}>{active ? 'Active' : 'Inactive'}</Label>;
    },
  },
] as DataTableColumn[];

export default function ActivityTypesTable() {
  const [[activityTypesCodeSystem]] = useCodeSystems({
    filter: {
      _id: 'ccm-activity-types',
    },
  });

  const activityTypes = activityTypesCodeSystem?.concept || [];

  return (
    <Box>
      <ActivityTypesToolbar />

      <DataTable
        selectable
        columns={columns}
        data={activityTypes}
        rowContextMenu={(item) => <ActivityTypesContextMenu item={item} />}
      />
    </Box>
  );
}
