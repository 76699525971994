import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Tabs,
  Tab,
  Divider,
  Card,
  IconButton,
} from "@mui/material";
import useObjectState from "src/hooks/useObjectState";
import { shortFormat, shortHourFormat } from "src/utils/formatTime";
import Interval from "../../../Model/Interval";
import IntervalDetail from "../../../Model/IntervalDetail";
import Rounding from "../../../Model/Rounding";
import { getTableHeaders, SectionEnum } from '../constants';
import IntervalDetailItem from "../IntervalDetailItem/IntervalDetaiIItem";
import moment from 'moment';
import { RoundingContext } from "../../../roundingContext";
import { RoundingItemContext } from "./RoundingItemContext";
import FlatList from 'flatlist-react';
import Iconify from "src/components/Iconify";
import ComponentWithLoading from "src/sections/careflow/common/ComponentWithLoading";

type RoundingItemPropsType = {
  rounding: Rounding;
  roundingListIndex: number;
  onPresEdit: (roundnig: Rounding) => void;
}

type RoundingItemStateType= {
  selectedIntervalIndex: number;
}

const RoundingItem = ({
  rounding,
  roundingListIndex,
  onPresEdit,
}: RoundingItemPropsType) => {
  const roundingContext = useContext(RoundingContext);
  const [{
    selectedIntervalIndex
  }, updateState] = useObjectState<RoundingItemStateType>({
    selectedIntervalIndex: rounding.intervals?.length - 1,
  });

  function getNextIntervalTimeLabel(start: Date){
      var _second = 1000;
      var _minute = _second * 60;
      var _hour = _minute * 60;
      var _day = _hour * 24;

      var now = new Date();
      var distance = start.getTime() - now.getTime();
      if (distance < 0) {
          return;
      }
      var days = Math.floor(distance / _day);
      var hours = Math.floor((distance % _day) / _hour);
      var minutes = Math.floor((distance % _hour) / _minute);

      return `Next Interval in ${days > 0 ? `${days} days` : ''} ${hours} hours and ${minutes} minutes`
  }

  let nextIntervalDate = useMemo(() => {
    const lastInterval = [...rounding?.intervals ?? []].pop();
    if (lastInterval) {
      const nextIntervalStart = moment(lastInterval.end).add(rounding.timeInterval, 'minutes');
      return getNextIntervalTimeLabel(nextIntervalStart.toDate())
    }
    return null;
  }, [rounding])

  const columns = getTableHeaders();

  const changeInterval = useCallback((intervalId: number, index: number) => {
    updateState({
      selectedIntervalIndex: index,
    });
    roundingContext.getIntervalDetails(intervalId, roundingListIndex, index)
  }, [roundingContext, roundingListIndex, updateState])

  const renderIntervalDetails = useCallback(() => (
    rounding.intervals?.map((interval: Interval, intervalIndex: number) => (
      <div
        key={interval.id}
        role="tabpanel"
        hidden={intervalIndex !== selectedIntervalIndex}
        id={`interval-tabpanel-${intervalIndex}`}
        aria-labelledby={`interval-tab-${intervalIndex}`}
        style={{ overflow: "scroll", height: "500px" }}
      >
        {interval.intervalDetails?.length === 0 && (
          <Grid alignItems="center">
            <Typography textAlign="center" variant="h5">No items</Typography>
          </Grid>
        )}
        <ComponentWithLoading
          isLoading={roundingContext.loadingIntervalDetails}
          Component={
            intervalIndex === selectedIntervalIndex && interval.intervalDetails ? (
              <FlatList
                list={interval.intervalDetails.sort((a, b) => {
                  if (
                    a.bedAssignment.bed.identifier < b.bedAssignment.bed.identifier
                  ) {
                    return -1;
                  }
    
                  if (
                    a.bedAssignment.bed.identifier > b.bedAssignment.bed.identifier
                  ) {
                    return 1;
                  }
    
                  return 0;
                })}
                renderItem={(intervalDetail, intervalDetailsIndex) => (
                  <IntervalDetailItem
                    key={intervalDetail.id}
                    item={intervalDetail}
                    sections={rounding.sections.split(',') as SectionEnum[] ?? []}
                    onCompletedItemCallback={(intervalDetail: IntervalDetail) => {
                      roundingContext.completedIntervalDetail?.(intervalDetail, roundingListIndex, intervalIndex, parseInt(intervalDetailsIndex));
                    }}
                  />
                )}
                hasMoreItems={interval.intervalDetails?.length < roundingContext.intervalDetailsTotalCount}
                loadMoreItems={() => {
                  roundingContext.getIntervalDetails(interval.id, roundingListIndex, intervalIndex, true)
                }}
                paginationLoadingIndicatorPosition="center"
              />
            ) : <></>}
          
        />
      </div>
    ))
  ), [rounding.intervals, rounding.sections, roundingContext, roundingListIndex, selectedIntervalIndex]);

  const renderTabs = useCallback(() => (
    <Tabs
      value={selectedIntervalIndex}
      variant="scrollable"
      scrollButtons="auto"
    >
      {rounding.intervals?.map((interval: Interval, index: number) => (
          <Tab
            key={interval.id}
            label={`
              ${
                shortFormat(moment(interval.start).toDate())
              }, ${
                moment(interval.start).format('h:mm A')
              } - ${
                shortFormat(moment(interval.end).toDate())
              }, ${
                moment(interval.end).format('h:mm A')
              } (${
                interval.completedDatetime ? 'completed' : 'incomplete'
              })
            `}
            onClick={() => changeInterval(interval.id, index)}
            id={`interval-tab-${index}`}
            {
              ...{
                'aria-controls': `interval-tabpanel-${index}`,
              }
            }
          />
        ))}
    </Tabs>
  ), [changeInterval, rounding.intervals, selectedIntervalIndex])

  return (
    <RoundingItemContext.Provider
      value={{
        rounding,
        roundingListIndex,
      }}
    >
       <Card sx={{ marginBottom: 2 }}>
        {rounding.category && (
          <Grid container sx={{ padding: 3, paddingBottom: 1 }} flexDirection="row" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">{rounding.category?.valueDisplayName}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                <IconButton
                  onClick={() => onPresEdit(rounding)}
                >
                  <Iconify icon={"tabler:edit"} fontSize={30} />
                </IconButton>
              </Typography>
            </Grid>
          </Grid>
        )}
        <TableContainer sx={{ padding: 2 }}>
          <Grid container direction="row">
            <Grid item xl={9}>
             {renderTabs()}
            </Grid>
            <Grid item xl={3} direction="row">
              {nextIntervalDate && (
              <Typography
                sx={{ paddingTop: '8px', paddingLeft: '20px', textAlign: 'right' }}
                variant="h6"
              >
                {nextIntervalDate}
              </Typography>
              )}
            </Grid>
          </Grid>
          <Divider sx={{ marginBottom: 2 }} />
          <Table>
            {columns && (
              <TableHead>
                <TableRow>
                  {columns.map((col: any, index: number) => (
                    <TableCell style={{ width: col.width }} key={index}>
                      <Typography>
                        {col.label}
                      </Typography>
                    </TableCell>
                  ))}
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
            )}
            
            
          </Table>
          {renderIntervalDetails()}
          {rounding.intervals?.length === 0 && (
          <Grid alignItems="center">
            <Typography
              style={{ textAlign: 'center', paddingTop: '20px' }}
            >
              No intervals
            </Typography>
          </Grid>
          )}
        </TableContainer>
      </Card>
    </RoundingItemContext.Provider>
  )
};

export default RoundingItem;
