import { ApiConfiguration } from 'src/api/clients/api_client';
import ApiClient from 'src/api/clients/axios_client';
import PatientService, { PatientApiClient } from './patientService';
import Cookies from 'js-cookie';

const accessToken = Cookies.get('careflowToken');
const baseUrl = process.env.REACT_APP_HOST_API;

const apiConfig = new ApiConfiguration();
apiConfig.accessToken = accessToken;
apiConfig.baseUrl = baseUrl ? baseUrl : '';

const patientApiClient = new PatientApiClient(new ApiClient(apiConfig));
export const patientService = new PatientService(patientApiClient);
