import {
  TableRow,
  TableCell,
  TableCellProps,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';

export interface LoadingTableRowProps {
  colSpan: number;
  align?: TableCellProps['align'];
  size?: CircularProgressProps['size'];
}
const LoadingTableRow = ({ colSpan, align = 'center', size = 70 }: LoadingTableRowProps) => (
  <TableRow>
    <TableCell colSpan={colSpan} align={align}>
      <CircularProgress size={size} />
    </TableCell>
  </TableRow>
);

export default LoadingTableRow;
