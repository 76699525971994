import { Card, Divider } from '@mui/material';
import ContactDetails from './details/ContactDetails';
import OtherDetails from './details/OtherDetails';
import AddressDetails from './details/AddressDetails';
import { PatientDetails } from '../PatientDetails';
import FamilyDetails from './details/FamilyDetails';
import { RelatedPersons } from './PatientRelatedPersons';


export default function PatientGroups({ patient }: PatientDetails) {
  return (
    <Card sx={{ p: 2, boxShadow: 'none' }}>
      <FamilyDetails />

      <Divider sx={{ my: 2 }} />

      <ContactDetails patient={patient} />

      <Divider sx={{ my: 2 }} />

      <AddressDetails />

      <Divider sx={{ my: 2 }} />

      <OtherDetails />

      <Divider sx={{ my: 2 }}/>

      <RelatedPersons patient={patient}/>
    </Card>
  )
}