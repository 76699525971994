import client from './_client';

//

export type APIListAvailableAssessmentsResponse = {
  id: string;
  title: string;
  startDate: string | null;
  expires: string | null;
  active: boolean;
}[];

export async function listAvailableAssessments() {
  const { data } = await client.get<APIListAvailableAssessmentsResponse>('/assessments/available');

  return data;
}

//
export interface APIStartAssessmentPayload {
  assessmentId: string;
  firstName: string;
  lastName: string;
  email: string;
}

export async function startAssessment(payload: APIStartAssessmentPayload) {
  const { data } = await client.post('/assessments/start', payload);

  return data;
}

//

export async function getAssessmentDetails(id: string) {
  const { data } = await client.get<APIGetAssessmentDetailsResponse>(`/assessments/${id}/details`);

  return data;
}

export interface APIGetAssessmentDetailsResponse {
  survey_id: string;
  language: string;
  response_id: number;
  end_url: string;
  name: string;
  groups: {
    code: string;
    name: string;
  }[];
  questions: Record<string, APIGetAssessmentDetailsResponse_Question>;
  response: {
    id: string;
    token: string;
    submitdate: string | null;
    lastpage: string | null;
    startlanguage: string;
    seed: string;
    startdate: string | null;
    datestamp: string | null;
    ipaddr: string | null;
    refurl: string | null;
  };
}

export interface APIGetAssessmentDetailsResponse_Question {
  id: string;
  code: string;
  question: string;
  type: APIGetAssessmentDetailsResponse_QuestionType;
  group_id: string;
  group_name: string;
  htmlcode: string;
  answer: {
    value: string;
    other: unknown;
  };
  subquestions: APIGetAssessmentDetailsResponse_Question[];
  options: {
    code: string;
    answer: string;
    sortorder: string;
  }[];
}

export type APIGetAssessmentDetailsResponse_QuestionType =
  | 'short free text'
  | 'long free text'
  | 'huge free text'
  | 'multiple short text'
  | '5 point choice'
  | 'list (radio)'
  | 'list (dropdown)'
  | 'list with comment'
  | 'array (yes/no/uncertain)'
  | 'multiple choice'
  | 'multiple choice with comments'
  | 'equation'
  | 'file upload'
  | 'gender'
  | 'language switch'
  | 'yes/no'
  | 'numerical input'
  | 'multiple numerical input'
  | 'date/time'
  | 'ranking'
  | 'text display'
  | 'array'
  | 'array (10 point choice)'
  | 'array (5 point choice)'
  | 'array (increase/same/decrease)'
  | 'array (numbers)'
  | 'array (texts)'
  | 'array by column'
  | 'array dual scale';

export type APIGetAssessmentDetailsResponse_ArrayQuestionType =
  | 'short free text'
  | 'numerical input'
  | '5 point choice'
  | 'list (radio)';
