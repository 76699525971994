import { useContext, useState } from 'react';
import {
  TableCell,
  TableRow,
  Typography,
  DialogContent,
  Dialog,
  Box,
  Grid,
  InputLabel,
  Alert,
  FormControl,
  TextField,
  Divider,
} from '@mui/material';
import { EntityContextMenu, PatientCard } from 'src/sections/careflow/common';
import Bed from 'src/sections/careflow/rounding/Model/Bed';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { shortFormat } from 'src/utils/formatTime';
import moment from 'moment';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import api from 'src/services/api';
import { CensusContext } from 'src/sections/careflow/census/CensusContext';

type BedItemPropsType = {
  bed: Bed;
};

const BedItem = ({ bed }: BedItemPropsType) => {
  const censusContext = useContext(CensusContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [note, setNote] = useState<string>('');
  const [showRemovePatient, setShowRemovePatient] = useState<boolean>(false);

  const onCancel = () => setShowRemovePatient(false);

  const onRemovePatient = () => {
    setIsLoading(true);
  };

  return (
    <>
      <Dialog open={showRemovePatient} onClose={onCancel}>
        <DialogContent>
          <Box sx={{ padding: 2, width: '500px' }}>
            <Box sx={{ mb: 2 }}>
              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Remove patient
                </Typography>
              </Grid>
            </Box>
            <Divider />
            <Box sx={{ padding: 1 }}>
              <PatientCard
                id={getFhirIdFromEntity(bed.currentBedAssignment?.patient?.fhirRefUri ?? '') || ''}
                showCloseButton={false}
              />
            </Box>
            <Divider />
            <Box>
              <Grid container>
                <Grid item xl={12} style={{ marginTop: '15px' }}>
                  <InputLabel>Note</InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      required
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      variant="standard"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {error && (
                <Alert style={{ marginTop: '20px' }} severity="error">
                  {error}
                </Alert>
              )}
              <ActionButtons
                isLoading={isLoading}
                leftButtonTitle="Cancel"
                onLeftButtonPress={onCancel}
                rightButtonTitle="Remove"
                onRightButtonPress={onRemovePatient}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <TableRow>
        <TableCell>{bed.identifier}</TableCell>
        <TableCell>
          <Typography>{bed.locationFhirName ?? 'No location'}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{bed.isActive ? 'active' : 'inactive'}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{bed.updatedBy}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{shortFormat(moment(bed.updatedOn).toDate())}</Typography>
        </TableCell>
        <TableCell>
          <EntityContextMenu
            entity={bed}
            items={[
              {
                label: 'Edit',
                icon: 'material-symbols:edit-outline',
                onPress: (bed) => {
                  censusContext.openAddBedModal(bed);
                },
              },
            ]}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default BedItem;
