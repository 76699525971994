import { IconButton, MenuItem, Stack, TextField } from '@mui/material';
import { useEffect } from 'react';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import Iconify from 'src/components/Iconify';
import StateSelector from './components/StateSelector';

interface AddressFieldProps {
  isLast?: boolean;
  onAdd?: () => void;
  onChange?: (addr: any) => void;
  onRemove?: () => void;
  onSelectFavorite?: (event: React.MouseEvent<HTMLElement>, value: any) => void;
  value: any;
  disabled?: boolean;
}

const AddressField = ({ isLast, onAdd, onChange, onRemove, value: addr, disabled = false }: AddressFieldProps) => {
  const handleChange = (value: any, prop: string) => {
    const updatedAddr = { ...addr };
    updatedAddr[prop] = value;
    if (onChange) {
      onChange(updatedAddr);
    }
  };

  return (
    <Stack spacing={2}>
      <Stack alignItems="baseline" direction="row" spacing={2}>
        <TextField
          select
          label="Address"
          size="small"
          margin="dense"
          variant="standard"
          onChange={(ev) => handleChange(ev.target.value, 'use')}
          value={addr.use ?? 'other'}
          disabled={disabled}
        >
          <MenuItem value="home">Home</MenuItem>
          <MenuItem value="work">Work</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </TextField>
        <TextField
          label={addr.line?.[0] ? "" : "Street Line 1"}
          size="small"
          variant="standard"
          onChange={(ev) => handleChange([ev.target.value, addr.line?.[1]], 'line')}
          placeholder="Street Line 1"
          value={addr.line?.[0] ?? ''}
          disabled={disabled}
        />
        {((onAdd || onRemove) && !disabled) && (
          <Stack direction="row" spacing={0}>
            {isLast ? (
              <IconButton size="small" onClick={onAdd}>
                <Iconify icon="eva:plus-outline" />
              </IconButton>
            ) : null}
            <IconButton size="small" disabled={!onRemove} onClick={onRemove}>
              <Iconify icon="eva:minus-outline" />
            </IconButton>
          </Stack>
        )}
      </Stack>
      <TextField
        label={addr.line?.[1] ? "" : "Street Line 2"}
        size="small"
        variant="standard"
        onChange={(ev) => handleChange([addr.line?.[0], ev.target.value], 'line')}
        placeholder="Street Line 2"
        value={addr.line?.[1] ?? ''}
        disabled={disabled}
      />
      <Stack alignItems="baseline" direction="row" spacing={2}>
        <TextField
          fullWidth
          label="City"
          size="small"
          variant="standard"
          onChange={(ev) => handleChange(ev.target.value, 'city')}
          placeholder="City"
          value={addr.city}
          disabled={disabled}
        />
        <StateSelector
          onChange={(ev) => handleChange(ev.target.value, 'state')}
          value={addr.state}
          disabled={disabled}
        />
      </Stack>
      <TextField
        label="Zip Code"
        size="small"
        variant="standard"
        onChange={(ev) => handleChange(ev.target.value, 'postalCode')}
        placeholder="Zip Code"
        value={addr.postalCode}
        disabled={disabled}
      />
    </Stack>
  );
};

export default AddressField;
