import { useCallback, useRef, useState } from 'react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import CustomModal, {
  CustomModalBasicProps,
  GridItem,
  GridSection,
} from 'src/components/CustomModal';
import InfoRibbon, { InfoTypography } from 'src/components/InfoRibbon';
import SearchMember from '../SearchMember/SearchMember';
import { CareTeamMember, CareTeamMemberWithEditableData } from 'src/@types/crs/case';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { updateCareTeamMembers } from 'src/services/api/case';

interface CareTeamMemberModalProps extends CustomModalBasicProps {
  patient?: WrappedPatient;
  careTeamMembers?: CareTeamMember[];
  careTeamId: string;
  onMemberUpdateSuccess: Function;
}

const CareTeamMemberModal = ({
  patient,
  open,
  onClose,
  careTeamMembers,
  careTeamId,
  onMemberUpdateSuccess,
}: CareTeamMemberModalProps) => {
  const title = 'Add New Member';
  const membersSelected = useRef<CareTeamMemberWithEditableData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleOnSave = useCallback(async () => {
    let error = false;

    const careTeamMembers = membersSelected.current?.map?.((member) => {
      if (member.editableData?.error?.startDate) error = true;
      return {
        id: member.id,
        resourceType: member.memberType,
        name: member.name,
        identifier: member.identifier,
        identifierType: member.identifierType,
        associatedOrg: member.associatedOrgName,
        associatedOrgId: member.associatedOrgId,
        role: member.editableData?.role?.display,
        roleId: member.editableData?.role?.code,
        startDate: member.editableData?.startDate?.toISOString?.(),
        endDate: member.editableData?.endDate?.toISOString?.(),
      };
    });
    if (error) {
      enqueueSnackbar("There's errors in member selection. Please fix them, before proceeding", {
        variant: 'error',
      });
      return;
    }

    const response = await updateCareTeamMembers({ careTeamMembers }, careTeamId);

    if (!response) {
      enqueueSnackbar("There's been an error. Please Try Again", { variant: 'error' });
      return;
    }
    enqueueSnackbar('Care Team Members succesfully updated');
    return true;
  }, [careTeamId]);

  return (
    <CustomModal
      open={open}
      title={title}
      breadcrumbs={['List of Care Teams & Members', 'Care Team Members', title]}
      onSave={async () => {
        setIsLoading(true);
        const wasSaved = await handleOnSave();
        setIsLoading(false);
        if (!wasSaved) return;
        onMemberUpdateSuccess();
        onClose?.({}, 'backdropClick');
      }}
      onClose={onClose}
      onCancel={onClose as Function}
      isLoading={isLoading}
      containerSx={[{ overflow: 'scroll', width: '70vw' }]}
      childrenWithoutPadding={
        <Box mt={3}>
          {!!patient && (
            <InfoRibbon containerSx={{ marginTop: 2 }}>
              <InfoTypography xs={2.2}>Patient {patient.getFullName()}</InfoTypography>
              <InfoTypography xs={2.2}>
                Date of Birth {patient.getBirthDateForDisplay()} ({patient.getAgeInYears()} years)
              </InfoTypography>
              <InfoTypography xs={2.2}>Identifier {patient.getMRN()?.value ?? ''}</InfoTypography>
              <InfoTypography xs={2.2}>
                Phone {patient.getPrimaryPhone()?.value ?? ''}
              </InfoTypography>
              <InfoTypography xs={2.2}>
                Email {patient.getPrimaryEmail()?.value ?? ''}
              </InfoTypography>
            </InfoRibbon>
          )}
        </Box>
      }
    >
      <GridSection mt={4}>
        <GridItem xs={12}>
          <SearchMember
            isLoading={isLoading}
            isEditable={true}
            patient={patient as WrappedPatient}
            careTeamMembers={careTeamMembers}
            membersSelectionRef={membersSelected}
          />
        </GridItem>
      </GridSection>
    </CustomModal>
  );
};

export default CareTeamMemberModal;
