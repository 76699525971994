import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Container,
  Grid,
  Typography,
  Card,
  Autocomplete,
  TextField,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import { PersonSearch as PersonSearchIcon } from '@mui/icons-material';
import Page from 'src/components/Page';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import useSettings from 'src/hooks/useSettings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { TaskResponse } from 'src/@types/crs/task';
import { getTasks } from 'src/services/api/task';
import getTaskListColumns from './taskColumns';
import useObjectState from 'src/hooks/useObjectState';
import { PaginateQuery } from 'src/api/pagination/dtos';
import SearchTextField from 'src/components/SearchTextField';
import { debounce } from 'src/utils/timers';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { useValueSets } from 'src/@nicheaim/fhir-react';
import {
  Reference,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import SearchMemberModal from '../case/components/SearchMember/SearchMemberModal';
import TaskModal, { getOwnerDisplayLabel } from '../case/components/TasksGrid/TaskModal';
import TaskDetailDrawer from '../case/components/TaskDetailDrawer';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import './styles.css';

interface GridState {
  page: number;
  rowsPerPage: number;
  orderBy: string | null;
  orderDirection: 'asc' | 'desc' | null;
  search: string;
  statusFilter: ValueSetComposeIncludeConcept[];
  ownerFilter: Reference[];
}

interface TaskDetailDrawerState {
  selectedTaskId: string;
  isTaskDetailDrawerOpen: boolean;
}

interface TaskEditModalState {
  taskToEdit: WrappedTask | null;
  isTaskEditModalOpen: boolean;
}

const initialGridState: GridState = {
  page: 1,
  rowsPerPage: 5,
  orderBy: 'createdAt',
  orderDirection: 'desc',
  search: '',
  statusFilter: [],
  ownerFilter: [],
};

const TaskList = () => {
  const { themeStretch } = useSettings();
  const [list, setList] = useState<TaskResponse[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isSearchMemberModalOpen, setIsSearchMemberModalOpen] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [gridState, updateGridState] = useObjectState<GridState>(initialGridState);
  const [{ selectedTaskId, isTaskDetailDrawerOpen }, updateDrawerState] =
    useObjectState<TaskDetailDrawerState>({
      selectedTaskId: '',
      isTaskDetailDrawerOpen: false,
    });
  const [{ taskToEdit, isTaskEditModalOpen }, updateEditModalState] =
    useObjectState<TaskEditModalState>({
      taskToEdit: null,
      isTaskEditModalOpen: false,
    });

  const [taskStatusRecords] = useValueSets({
    filter: {
      identifier: 'crs-task-status',
    },
    map: ValueSetWrapper,
  });

  const taskStatus = useMemo(() => taskStatusRecords?.[0] ?? null, [taskStatusRecords]);

  const handleSearchChange = useMemo(
    () =>
      debounce((search: string) => {
        updateGridState({ search });
      }, 600),
    []
  );
  const fetchTaskList = useCallback(async () => {
    const { page, rowsPerPage, orderBy, search, orderDirection, statusFilter, ownerFilter } =
      gridState;
    const paginateQuery: PaginateQuery = {
      page: page,
      limit: rowsPerPage,
      sortBy: orderBy && orderDirection ? [[orderBy, orderDirection.toUpperCase()]] : [],
    };
    if (search) {
      paginateQuery.search = search;
    }

    paginateQuery.filter = {};

    if (statusFilter.length) {
      paginateQuery.filter.status = `$in:${statusFilter.map(({ code }) => code).join(',')}`;
    }

    if (ownerFilter.length) {
      paginateQuery.filter.ownerFhirId = `$in:${ownerFilter
        .map(({ reference }) => reference)
        .join(',')}`;
    }

    const response = await getTasks(paginateQuery);
    const data = response?.data;

    setList(data ?? []);
    if (response?.meta?.totalItems !== undefined) setTotalItems(response?.meta?.totalItems);
  }, [gridState]);

  useEffect(() => {
    fetchTaskList();
  }, [fetchTaskList]);

  const handleChangeRowsPerPage = (rowsPerPage: number) => {
    updateGridState({ rowsPerPage });
  };

  const handlePageChange = (page: number) => {
    updateGridState({ page: page + 1 });
  };

  const handleSortModelChange = (sortModel: GridSortModel) => {
    const { field, sort } = sortModel?.[0] ?? {};
    updateGridState({
      orderBy: field ?? null,
      orderDirection: sort ?? null,
    });
  };

  const handleOnSuccesEdit = (_: any) => {
    fetchTaskList();
  };

  return (
    <Box sx={{ width: '100% !important' }}>
      <Page title="Task List">
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <HeaderBreadcrumbs
            title=""
            heading=""
            links={[
              { name: 'Home', href: PATH_DASHBOARD.root },
              { name: 'CRS' },
              { name: 'Task List', href: PATH_DASHBOARD.crs.task },
            ]}
          />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography variant="h4">Task List</Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: 4 }}>
            <Card sx={{ width: '100%', paddingTop: 4, paddingX: 1 }}>
              <Grid container sx={{ marginBottom: 4, paddingX: 3 }} spacing={2}>
                <Grid item xs={3}>
                  <SearchTextField
                    value={searchValue}
                    onChange={(event) => {
                      const value = event.target.value;
                      setSearchValue(value);
                      handleSearchChange(value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    value={gridState.statusFilter}
                    multiple
                    fullWidth
                    onChange={(_: React.SyntheticEvent, taskStatus) => {
                      updateGridState({ statusFilter: taskStatus });
                    }}
                    options={taskStatus?.asList() ?? []}
                    getOptionLabel={({ display }: ValueSetComposeIncludeConcept) => display ?? ''}
                    renderInput={(params) => (
                      <TextField {...params} label={'Status'} variant="outlined" />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    open={false}
                    value={gridState.ownerFilter}
                    multiple
                    fullWidth
                    sx={{
                      '& .MuiAutocomplete-popupIndicator': {
                        visibility: 'hidden',
                      },
                    }}
                    onChange={(_: React.SyntheticEvent, owners) => {
                      updateGridState({ ownerFilter: owners });
                    }}
                    options={gridState.ownerFilter}
                    getOptionLabel={({ display }: Reference) => display ?? ''}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={'Owner'}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <>
                              <Tooltip title="Click here to search by owner">
                                <IconButton
                                  onClick={() => {
                                    setIsSearchMemberModalOpen(true);
                                  }}
                                >
                                  <PersonSearchIcon />
                                </IconButton>
                              </Tooltip>
                              {params.InputProps.startAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <DataGrid
                sx={{
                  width: '100% !important',
                  '& .MuiDataGrid-columnHeader': { backgroundColor: '#f4f6f8' },
                  '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                  },
                }}
                rows={list}
                autoHeight={true}
                getCellClassName={() => 'taskGridBodyCell'}
                getRowId={({ fhirId }: TaskResponse) => fhirId}
                onRowDoubleClick={(params) => {
                  const { fhirId } = params.row as TaskResponse;
                  updateDrawerState({
                    isTaskDetailDrawerOpen: true,
                    selectedTaskId: fhirId,
                  });
                }}
                getRowSpacing={() => ({ bottom: 17 })}
                getRowHeight={() => 100}
                columns={getTaskListColumns(taskStatus?.asList?.() ?? [])}
                isRowSelectable={() => false}
                disableColumnFilter
                disableColumnMenu
                rowCount={totalItems}
                pagination
                paginationMode="server"
                sortingMode="server"
                sortModel={
                  gridState.orderBy && gridState.orderDirection
                    ? [{ field: gridState.orderBy, sort: gridState.orderDirection }]
                    : []
                }
                onSortModelChange={handleSortModelChange}
                pageSize={gridState.rowsPerPage}
                onPageSizeChange={handleChangeRowsPerPage}
                onPageChange={handlePageChange}
                rowsPerPageOptions={[5, 10, 25]}
                page={gridState.page - 1}
              />
            </Card>
          </Grid>
        </Container>
      </Page>

      <SearchMemberModal
        open={isSearchMemberModalOpen}
        onCancel={() => {
          setIsSearchMemberModalOpen(false);
        }}
        onSelectResource={(resource) => {
          updateGridState(({ ownerFilter }) => ({
            ownerFilter: [
              ...(ownerFilter ?? []),
              { display: getOwnerDisplayLabel(resource), reference: resource?.id },
            ],
          }));
          setIsSearchMemberModalOpen(false);
        }}
      />
      <TaskDetailDrawer
        onEdit={(task) => {
          updateDrawerState({ isTaskDetailDrawerOpen: false });
          updateEditModalState({
            taskToEdit: task,
            isTaskEditModalOpen: true,
          });
        }}
        open={isTaskDetailDrawerOpen}
        taskId={selectedTaskId}
        onSuccesfulEdit={() => {
          fetchTaskList();
        }}
        onCloseIconButtonClick={() => {
          updateDrawerState({ isTaskDetailDrawerOpen: false });
        }}
      />
      <TaskModal
        taskToEdit={taskToEdit as WrappedTask}
        onSuccessfulEdit={handleOnSuccesEdit}
        onSuccessfulCreation={handleOnSuccesEdit}
        open={isTaskEditModalOpen}
        onClose={() => {
          updateEditModalState({
            isTaskEditModalOpen: false,
          });
        }}
      />
    </Box>
  );
};

export default TaskList;
