import { Link } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import { PatientManager } from 'src/@types/crs/patient';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { useNavigate, useLocation } from 'react-router-dom';
import { TableCell, TableRow, Typography } from '@mui/material';

type Props = {
  row: PatientManager;
};

export default function PatientRow({ row }: Props) {
  const {
    id,
    mrn,
    referral,
    phone,
    email,
    gender,
    demographics,
    race,
    formattedBirthDate,
    age,
    registrationDateFormatted,
  } = row;

  const navigate = useNavigate();
  const location = useLocation();

  const handleOnRowDoubleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    const navigateType = id;
    navigate(`${location.pathname}/${navigateType}`, {
      replace: false,
    });
  };

  return (
    <>
      <TableRow onDoubleClick={handleOnRowDoubleClick}>
        <TableCell>
          <Typography variant="subtitle2">{mrn}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">{referral}</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {phone}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {email}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            {formattedBirthDate ? `${formattedBirthDate} (${age})` : null}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            {gender && `${gender?.charAt(0).toUpperCase()}${gender?.slice(1)}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {demographics && `${demographics?.charAt(0).toUpperCase()}${demographics?.slice(1)}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {race && `${race?.charAt(0).toUpperCase()}${race?.slice(1)}`}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">
            {registrationDateFormatted ? registrationDateFormatted : null}
          </Typography>
        </TableCell>
        {/* <TableCell>
          <Label
            color={(status === 'inactive' && 'error') || 'success'}
            sx={{ textTransform: 'uppercase' }}
          >
            {status}
          </Label>
        </TableCell> */}
        <TableCell>
          <Link to={PATH_DASHBOARD.crs.patient.details.forId(row.id)}>
            <Iconify icon={'ic:outline-open-in-new'} width={24} height={24} />
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
}
