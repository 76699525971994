import { useEffect, useMemo, useState } from 'react';
import useGridFilters, { GridFilters } from 'src/hooks/useGridFilters';
import CareTeamMembersGrid, {
  CareTeamMemberFilters,
  initialCareTeamMemberFilters,
} from './CareTeamMembersGrid/CareTeamMembersGrid';
import {
  CareTeamParticipant,
  Coding,
  Reference,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getCareTeamMemberGridRows, searchIfContainedInObj } from '../../helpers/common';
import { CareTeamMemberGridRowData } from 'src/@types/crs/case';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCareTeam } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import { debounce } from 'src/utils/timers';
import { isBetweenDates } from 'src/utils/dates';

interface CaseCareTeamMemberGridProps {
  patient: WrappedPatient;
  onMemberUpdateSuccess: Function;
  participants: CareTeamParticipant[];
  careTeam: WrappedCareTeam;
}

const CaseCareTeamMemberGrid = ({
  participants,
  patient,
  onMemberUpdateSuccess,
  careTeam,
}: CaseCareTeamMemberGridProps) => {
  const { filters, searchTextFieldValue, ...gridFiltersProps } =
    useGridFilters<CareTeamMemberFilters>(initialCareTeamMemberFilters);

  const [filteredCareTeamMembers, setFilteredCareTeamMembers] = useState<
    CareTeamMemberGridRowData[]
  >(getCareTeamMemberGridRows(participants));

  const associatedOrgs = useMemo(
    () =>
      participants?.reduce<Reference[]>((associatedOrgsx, participant) => {
        if (!participant?.onBehalfOf?.reference) return associatedOrgsx;
        if (
          associatedOrgsx.find(({ reference }) => reference === participant?.onBehalfOf?.reference)
        )
          return associatedOrgsx;
        return [...associatedOrgsx, participant.onBehalfOf];
      }, []) ?? [],
    [participants]
  );

  const roles = useMemo(
    () =>
      participants?.reduce<Coding[]>(
        (allRoles, participant) => [
          ...allRoles,
          ...(participant?.role?.reduce<Coding[]>((roles, role) => {
            if (
              roles.find(({ code }) => code === role?.coding?.[0]?.code) ||
              allRoles.find(({ code }) => code === role?.coding?.[0]?.code)
            )
              return roles;
            if (!role.coding?.[0]) return roles;
            return [...roles, role.coding?.[0]];
          }, []) ?? []),
        ],
        []
      ),
    [participants]
  );

  const handleFiltersChange = useMemo(
    () =>
      debounce((careTeamMembers, filters, searchTextFieldValue) => {
        setFilteredCareTeamMembers(
          filterCareTeamMembers(careTeamMembers, { filters, searchTextFieldValue })
        );
      }, 800),
    []
  );

  useEffect(() => {
    const careTeamMembers = getCareTeamMemberGridRows(participants);
    handleFiltersChange(careTeamMembers, filters, searchTextFieldValue);
  }, [filters, searchTextFieldValue, handleFiltersChange, participants]);

  return (
    <CareTeamMembersGrid
      searchTextFieldValue={searchTextFieldValue}
      roles={roles}
      associatedOrgs={associatedOrgs}
      careTeam={careTeam}
      onMemberUpdateSuccess={onMemberUpdateSuccess}
      careTeamMembers={filteredCareTeamMembers}
      patient={patient}
      filterValues={filters}
      {...gridFiltersProps}
    />
  );
};

const filterCareTeamMembers = (
  careTeamMembers: CareTeamMemberGridRowData[],

  { filters, searchTextFieldValue }: GridFilters<CareTeamMemberFilters>
): CareTeamMemberGridRowData[] => {
  let filteredCareTeamMembers = [...careTeamMembers];

  const searchByString = searchTextFieldValue?.toLowerCase().trim() ?? '';
  if (searchByString.length >= 1) {
    filteredCareTeamMembers = filteredCareTeamMembers.filter((careTeam) =>
      searchIfContainedInObj(
        careTeam,
        [
          'name',
          'identifier',
          'roleName',
          'startDate',
          'endDate',
          'memberType',
          'associatedOrgName',
        ],
        searchByString
      )
    );
  }

  const { associatedOrg, endDate, memberTypes, role, startDate } = filters;

  return filteredCareTeamMembers.filter(
    ({ startDateObj, endDateObj, associatedOrgId, memberType, roleId }) => {
      if (startDate?.isValid?.() && endDate?.isValid?.()) {
        if (!startDateObj?.isValid() || !endDateObj?.isValid()) return false;

        if (!isBetweenDates(startDateObj, endDateObj, startDate, endDate)) return false;
      }

      if (startDate?.isValid?.()) {
        if (!startDateObj?.isValid()) return false;
        if (!startDateObj?.isSameOrAfter(startDate)) return false;
      }

      if (endDate?.isValid?.()) {
        if (!endDateObj?.isValid()) return false;
        if (!endDateObj.isSameOrBefore(endDate)) return false;
      }
      if (associatedOrg.length) {
        if (!associatedOrg.find(({ reference }) => reference === associatedOrgId)) return false;
      }
      if (memberTypes.length) {
        if (!memberTypes.find(({ value }) => value === memberType)) return false;
      }
      if (role.length) {
        if (!role.find(({ code }) => code === roleId)) return false;
      }
      return true;
    }
  );
};

export default CaseCareTeamMemberGrid;
