import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import { Select } from 'src/components/Select';
import useCarePlanAutomationFormStore, { useCarePlanAutomationFormRule } from '../store';

interface OptionOperatorProps {
  question: WrappedQuestionnaireItem;
  ruleId: string;
}

function OptionOperator({ question, ruleId }: OptionOperatorProps) {
  const saving = useCarePlanAutomationFormStore((s) => s.loading);
  const { value = [], setValue } = useCarePlanAutomationFormRule(ruleId);
  const options =
    question.answerOption?.map((option) => ({
      label:
        option.valueString ??
        option.valueInteger ??
        option.valueDate ??
        option.valueTime ??
        option.valueCoding?.display ??
        option.valueCoding?.code ??
        option.valueReference?.display,
      value:
        option.valueString ??
        option.valueInteger ??
        option.valueDate ??
        option.valueTime ??
        option.valueCoding?.code ??
        option.valueReference,
    })) ?? [];
  const currentValue = value as typeof options[number] | typeof options | undefined;
  const selectedOption = Array.isArray(currentValue)
    ? question.repeats
      ? options.filter((o) => currentValue.some((c) => c.value === o.value))
      : options.find((o) => o.value === currentValue[0]?.value)
    : options.find((o) => o.value === currentValue?.value);

  return (
    <Select
      isDisabled={saving}
      isMulti={question.repeats}
      value={selectedOption}
      onChange={(value) => setValue(value)}
      options={options}
    />
  );
}

export default OptionOperator;
