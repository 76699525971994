import { createContext } from 'react';
import IntervalDetail from "../../../Model/IntervalDetail";
import { SectionEnum } from '../constants';

export type IntervalDetailItemContextType = {
  item: IntervalDetail | null;
  sections: SectionEnum[];
  onCompletedItemCallback?: (intervalDetail: IntervalDetail) => void;
}

export const IntervalDetailItemContext = createContext<IntervalDetailItemContextType>({
  item: null,
  sections: [],
  onCompletedItemCallback: () => { },
});
