import * as Yup from 'yup';
import useAuth from 'src/hooks/useAuth';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, FormGroup, IconButton, Stack, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect, useMemo } from 'react';
import { Group } from 'src/@nicheaim/fhir-base/mappings/Group';
import { HealthcareService } from 'src/@nicheaim/fhir-base/mappings/HealthcareService';
import { Organization } from 'src/@nicheaim/fhir-base/mappings/Organization';
import {
  useCareTeams,
  useCodeSystems,
  useGroups,
  useHealthcareServices,
  useOrganizations,
  usePatient,
} from 'src/@nicheaim/fhir-react';
import Iconify from 'src/components/Iconify';
import fhirSystem from 'src/fhir/system';
import {
  CareTeam,
  Coding,
  GroupMember,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { IPlanProgramRow } from './PlansDetails';

import produce from 'immer';
import { useStandardLoadingBackdrop } from '../../common/StandardLoadingBackdropProvider';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import RHFAutocomplete from 'src/components/hook-form/RHFAutocomplete';
import { getFhirIdFromEntity, referenceEquals } from 'src/utils/fhir';
import RHFDatePicker from 'src/components/hook-form/RHFDatePicker';
import { PatientCard } from '../../common';
import { useDialogStore } from 'src/stores/dialog';
import api from 'src/services/api';
import _ from 'lodash';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import moment from 'moment-timezone';
import ActionButtons from '../../common/ActionButtons';
import useAddEntityRequestStates from 'src/hooks/useAddEntityRequestStates';
import sha256 from 'crypto-js/sha256';
import Hex from 'crypto-js/enc-hex';
import { useSnackbar } from 'notistack';
import { useActiveExitDestinations } from 'src/services/api/patients/programs';
moment.tz.setDefault('America/Los_Angeles');

interface IPlansDetailsFormProps {
  patientId: string;
  editData?: IPlanProgramRow;
  onClose: () => void;
  handleAfterSave: (newProgramStatus?: string) => void;
}

interface IOptionValue<T = string> {
  label: string;
  id: T;
}

type FormValue = {
  id: string | undefined;
  plan: IOptionValue | null;
  lob: IOptionValue | null;
  program: IOptionValue | null;
  manualPlanId: string;
  status: IOptionValue<MembershipStatus>;
  inactiveReason: IOptionValue | null;
  exitDestination: IOptionValue | null;
  startDate: Date | null;
  endDate: Date | null;
};

export type MembershipStatus = 'new' | 'active' | 'pending' | 'inactive' | 'unknown';

const schema = Yup.object().shape({
  id: Yup.string().optional(),
  plan: Yup.object().nullable().required('Health Plan is required'),
  lob: Yup.object().nullable().required('LoB is required'),
  program: Yup.object().nullable().required('Program is required'),
  manualPlanId: Yup.string().optional(),
  status: Yup.object()
    .shape({
      id: Yup.string().oneOf(['new', 'active', 'pending', 'inactive']).required(),
      label: Yup.string().required(),
    })
    .required('Status is required'),
  startDate: Yup.date().when('status.id', {
    is: 'active',
    then: Yup.date().nullable().required('Start date is required'),
    otherwise: Yup.date().nullable().optional(),
  }),
  endDate: Yup.date().when('status.id', {
    is: 'inactive',
    then: Yup.date().nullable().required('End date is required'),
    otherwise: Yup.date().nullable().optional(),
  }),
  inactiveReason: Yup.object().when('status.id', {
    is: 'inactive',
    then: Yup.object().nullable().required('Disenrollment reason is required'),
    otherwise: Yup.object().nullable().optional(),
  }),
  exitDestination: Yup.object().when('status.id', {
    is: 'inactive',
    then: Yup.object().nullable().required('Exit destination is required'),
    otherwise: Yup.object().nullable().optional(),
  }),
});

export const membershipStatusMessage: Record<MembershipStatus, string> = {
  new: 'New',
  pending: 'Pending',
  active: 'Active',
  inactive: 'Inactive',
  unknown: 'Unknown (please review the fields)',
};

const resolver = yupResolver(schema);

export default function PlansDetailsForm({
  patientId,
  editData,
  onClose,
  handleAfterSave,
}: IPlansDetailsFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [patient] = usePatient(patientId, {
    map: PatientWrapper,
  });
  const loadingBackdrop = useStandardLoadingBackdrop();
  const [{ isLoading }, { setIsLoading }] = useAddEntityRequestStates();
  const { confirmDialog } = useDialogStore();
  const [careTeams, { create: createCareTeam, update: updateCareTeam }] = useCareTeams({
    filter: {
      patient: patientId,
    },
  });
  const [[programDisenrollmentReasons]] = useCodeSystems({
    filter: {
      _id: 'ccm-program-disenrollment-reasons',
    },
  });
  const { data: exitDestinations = [] } = useActiveExitDestinations();
  const loggedUser = useAuth().getCurrentUser();

  const methods = useForm({
    resolver,
    defaultValues: {
      id: undefined as string | undefined,
      plan: null as IOptionValue | null,
      lob: null as IOptionValue | null,
      program: null as IOptionValue | null,
      manualPlanId: '',
      manualPlanIdEditable: false,
      status: {
        id: 'new' as MembershipStatus,
        label: membershipStatusMessage.new,
      },
      inactiveReason: null as IOptionValue | null,
      exitDestination: null as IOptionValue | null,
      startDate: null as Date | null,
      endDate: null as Date | null,
    },
  });
  const [, { update: updatePatient }] = usePatient(patientId, {
    map: PatientWrapper,
    autofetch: false,
  });

  const [plans] = useOrganizations({
    filter: {
      identifier: fhirSystem.healthPlan.forCode(''), // to get all ?identifier=system| (?identifier=system|value)
    },
  });
  const selectedPlan = methods.watch('plan');

  const [lobs] = useOrganizations({
    filter: {
      identifier: fhirSystem.lineOfBusiness.withId(selectedPlan?.id || '').forCode(''),
    },
    autofetch: !!selectedPlan?.id,
  });
  const selectedLob = methods.watch('lob');

  const [programs] = useHealthcareServices({
    filter: {
      identifier: fhirSystem.program.withId(selectedLob?.id || '').forCode(''),
    },
    autofetch: !!selectedLob?.id,
  });
  const selectedProgram = methods.watch('program');

  const [
    [enrolledPatientsGroup],
    { create: createGroup, update: updateGroup, isLoading: isLoadingEnrolledPatientsGroup },
  ] = useGroups({
    filter: {
      identifier: fhirSystem.program.enrolledPatients.forCode(selectedProgram?.id),
    },
    autofetch: !!selectedProgram?.id,
    client: 'indexed',
  });

  useEffect(() => methods.reset(), [patientId]);

  useEffect(() => {
    if (!editData) {
      methods.setValue('lob', null);
    }

    if (!selectedPlan?.id) return;
    const manualPlanId = patient?.identifier?.find(
      (item) => item.system === fhirSystem.healthPlan.withId(selectedPlan.id).asString()
    )?.value;

    methods.setValue('manualPlanId', manualPlanId || '');
    methods.setValue('manualPlanIdEditable', !manualPlanId);
  }, [selectedPlan?.id, editData]);

  useEffect(() => {
    if (editData) return;

    methods.setValue('program', null);
  }, [selectedLob?.id, editData]);

  useEffect(() => {
    if (editData) return;
    if (selectedProgram?.id) return;

    methods.setValue('startDate', null);
    methods.setValue('endDate', null);
    methods.setValue('status', {
      id: 'new' as MembershipStatus,
      label: membershipStatusMessage.new,
    });
    methods.setValue('inactiveReason', null);
    methods.setValue('exitDestination', null);
  }, [selectedProgram?.id]);

  useEffect(
    function loadMembershipInfo() {
      if (!enrolledPatientsGroup) return;

      const memberships =
        enrolledPatientsGroup.member?.filter((item: GroupMember) =>
          referenceEquals(item.entity, {
            resourceType: 'Patient',
            id: patientId,
          })
        ) || [];

      const membership = methods.getValues('id')
        ? memberships.find((item) => item.id === methods.getValues('id'))
        : memberships.at(-1);
      if (!membership) return;
      const status = getMembershipStatus(membership);
      methods.setValue('status', {
        id: status,
        label: membershipStatusMessage[status],
      });

      if (status === 'inactive' && !methods.getValues('id')) return;

      const inactiveReason = membership.extension?.find(
        (item) => item.url === fhirSystem.extension.Group.member.inactiveReason.asString()
      );
      methods.setValue(
        'inactiveReason',
        inactiveReason
          ? {
              id: inactiveReason.valueCoding?.code || '',
              label: inactiveReason.valueCoding?.display || '',
            }
          : null
      );

      const exitDestination = membership.extension?.find(
        (item) => item.url === fhirSystem.extension.Group.member.exitDestination.asString()
      );
      methods.setValue(
        'exitDestination',
        exitDestination
          ? {
              id: exitDestination.valueCoding?.code || '',
              label: exitDestination.valueCoding?.display || '',
            }
          : null
      );

      methods.setValue(
        'startDate',
        membership.period?.start ? moment(membership.period.start).toDate() : null
      );

      methods.setValue(
        'endDate',
        membership.period?.end ? moment(membership.period.end).toDate() : null
      );
    },
    [selectedProgram, enrolledPatientsGroup, methods.watch('id')]
  );

  useEffect(() => {
    if (!editData) return;

    methods.setValue('id', editData.membership.id);

    methods.setValue('plan', {
      label: editData.plan.name || '',
      id: editData.plan.id || '',
    });

    methods.setValue('lob', {
      label: editData.lob.name || '',
      id: editData.lob.id || '',
    });

    methods.setValue('program', {
      label: editData.program.display || '',
      id: editData.program.code || '',
    });
  }, [editData]);

  const plansOptions = useMemo<IOptionValue[]>(
    () =>
      plans.map((item: Organization, index: number) => {
        const option: IOptionValue = {
          label: item.name || '-',
          id: item.id || String(index),
        };

        return option;
      }),
    [plans]
  );

  const lobsOptions = useMemo<IOptionValue[]>(
    () =>
      lobs.map((item: Organization, index: number) => {
        const option: IOptionValue = {
          label: item.name || '-',
          id: item.id || String(index),
        };

        return option;
      }),
    [lobs]
  );

  const programsOptions = useMemo<IOptionValue[]>(
    () =>
      programs.map((item: HealthcareService, index: number) => {
        const option: IOptionValue = {
          label: item.name || '-',
          id: item.id || String(index),
        };

        return option;
      }),
    [programs]
  );

  function normalizeGroup(group?: Group) {
    if (!group) {
      group = {
        resourceType: 'Group',
        actual: true,
        member: [],
        identifier: [
          {
            system: fhirSystem.program.enrolledPatients.asString(),
            value: selectedProgram?.id,
          },
        ],
        type: 'person',
      };
    }

    const programCoding: Coding = {
      display: selectedProgram?.label,
      code: selectedProgram?.id,
    };

    group.code ??= { coding: [] };
    group.code.coding = [programCoding];

    group.managingEntity ??= { reference: '' };
    group.managingEntity.reference = `Organization/${selectedLob?.id}`;
    group.member ??= [];
    return group as Group & {
      member: GroupMember[];
    };
  }

  console.log({
    enrolledPatientsGroup,
  });

  const onSubmit = async (data: FormValue) => {
    let shouldCreateActivities: boolean | null = false;
    if (data.status.id === 'active' && data.startDate) {
      if (moment(data.startDate).isSame(moment(), 'day')) {
        shouldCreateActivities = true;
      } else if (moment(data.startDate).isBefore(moment().startOf('day'))) {
        shouldCreateActivities = await confirmDialog({
          title: 'Create Activities',
          description:
            'The start date is in the past. Do you want to create the activities for this patient now?',
          confirmText: 'Yes',
          denyText: 'No',
          cancellable: true,
        });

        if (shouldCreateActivities === null) return;
      }
    }

    setIsLoading(true);
    loadingBackdrop.open();
    let enrollmentId = '';
    try {
      const group = produce(enrolledPatientsGroup, (group) => {
        group = normalizeGroup(group);

        const memberships = group.member!.filter((item: GroupMember) =>
          referenceEquals(item.entity, {
            resourceType: 'Patient',
            id: patientId,
          })
        );

        const memberId = editData?.membership.id;
        const membershipToUpdate = memberships.find((item, index) => {
          const itemhash = sha256(JSON.stringify(item)).toString(Hex);
          const itemIndexHash = sha256(JSON.stringify({ ...item, index })).toString(Hex);
          const edithash = sha256(JSON.stringify(editData?.membership)).toString(Hex);

          if (item.id && memberId && item.id === memberId) {
            console.log('FOUND WITH ID');
            return true;
          }

          if (itemhash === edithash) {
            console.log('FOUND WITH HASH');
            return true;
          }

          if (itemIndexHash === edithash) {
            console.log('FOUND WITH INDEX HASH');
            return true;
          }

          return false;
        });

        const index = membershipToUpdate
          ? group.member!.indexOf(membershipToUpdate)
          : group.member!.length;
        const newUpdatedHash = sha256(JSON.stringify({ ...membershipPayload, index })).toString(
          Hex
        );
        enrollmentId = newUpdatedHash;

        if (membershipToUpdate) {
          const updatedPayload = {
            ...membershipPayload,
            id: newUpdatedHash,
          };
          if (!group.member) group.member = [];
          group.member[group.member.indexOf(membershipToUpdate)] = updatedPayload;
          return group;
        }

        group.member!.push({
          ...membershipPayload,
          id: newUpdatedHash,
        });

        return group;
      });

      if (enrolledPatientsGroup) {
        await updateGroup(group);
      } else {
        await createGroup(group);
      }

      const loggedUserFhirId = getFhirIdFromEntity(loggedUser.user_fhir_uri);
      const newCareTeamParticipantPayload = {
        member: {
          reference: `Practitioner/${loggedUserFhirId}`,
          display: loggedUser.name,
        },
        role: [
          {
            coding: [
              {
                system: 'careTeamRole',
              },
            ],
            text: '',
          },
          {
            coding: [
              {
                system: 'careTeamRole',
              },
            ],
            text: 'Team Leader',
          },
        ],
      };

      if (status === 'active') {
        // check if the patient have a care team
        if (careTeams.length === 0) {
          // if the patient doesn't have a care team, create it with the current logged user as team leader
          const newCareTeam: CareTeam = {
            resourceType: 'CareTeam',
            status: 'active',
            subject: {
              reference: `Patient/${patient?.id}`,
            },
            participant: [newCareTeamParticipantPayload],
          };
          await createCareTeam(newCareTeam);
        } else {
          const currentCareTeam = careTeams[0];
          const careTeamLeader = currentCareTeam.participant?.find((participant) =>
            participant.role?.find((role) => role.text === 'Team Leader')
          );
          if (!careTeamLeader) {
            const updatedCareTeam = produce(currentCareTeam, (draft) => {
              const isLoggedUserOnCareTeam = draft.participant?.find(
                (participant) => participant.member?.id === loggedUserFhirId
              );

              if (isLoggedUserOnCareTeam) {
                const existingParticipantIndex =
                  draft.participant?.findIndex(
                    (participant) => participant.id === isLoggedUserOnCareTeam.id
                  ) ?? -1;
                if (existingParticipantIndex !== -1) {
                  draft.participant?.[existingParticipantIndex]?.role?.push({
                    coding: [
                      {
                        system: 'careTeamRole',
                      },
                    ],
                    text: 'Team Leader',
                  });
                }
              } else {
                draft.participant?.push(newCareTeamParticipantPayload);
              }
            });
            await updateCareTeam(updatedCareTeam);
          }
        }
      }

      const updatedPatient = produce(patient!, (draft) => {
        draft.identifier ??= [];

        const existingPatientPlanId = draft.identifier.find(
          (item) => item.system === fhirSystem.healthPlan.withId(selectedPlan?.id || '').asString()
        );
        if (existingPatientPlanId) {
          existingPatientPlanId.value = methods.getValues('manualPlanId');
          return;
        }

        draft.identifier.push({
          system: fhirSystem.healthPlan.withId(selectedPlan?.id || '').asString(),
          value: methods.getValues('manualPlanId'),
        });
      });

      await updatePatient(updatedPatient);

      if (shouldCreateActivities) {
        try {
          await api.patients.addedToProgram(patientId, data.program?.id || '', enrollmentId);
        } catch (error) {
          enqueueSnackbar('An error has occurred while creating automated activities', {
            variant: 'error',
          });
        }
      }

      onClose();
      handleAfterSave(`${editData?.program.code}:${editData?.program.display}:${status}`);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('An error has occurred while saving the patient enrollment', {
        variant: 'error',
      });
    } finally {
      loadingBackdrop.close();
      setIsLoading(false);
    }
  };

  const membershipPayload = useMemo<GroupMember>(() => {
    const status = (methods.getValues('status')?.id as MembershipStatus) || 'unknown';

    return {
      id: methods.getValues('id'),
      entity: {
        type: 'Patient',
        id: patientId,
        reference: `Patient/${patientId}`,
      },
      period: {
        start:
          ['active', 'inactive'].includes(status) && methods.getValues('startDate')
            ? moment(methods.getValues('startDate')).startOf('day')?.toISOString()
            : undefined,
        end:
          ['active', 'inactive'].includes(status) && methods.getValues('endDate')
            ? moment(methods.getValues('endDate')).startOf('day')?.toISOString()
            : undefined,
      },
      inactive: ['new', 'inactive'].includes(status),
      ...(status === 'inactive' &&
      methods.getValues('inactiveReason')?.id &&
      methods.getValues('exitDestination')?.id
        ? {
            extension: [
              {
                url: fhirSystem.extension.Group.member.inactiveReason.asString(),
                valueCoding: {
                  code: methods.getValues('inactiveReason')?.id,
                  display: methods.getValues('inactiveReason')?.label,
                },
              },
              {
                url: fhirSystem.extension.Group.member.exitDestination.asString(),
                valueCoding: {
                  code: methods.getValues('exitDestination')?.id,
                  display: methods.getValues('exitDestination')?.label,
                },
              },
            ],
          }
        : {}),
    };
  }, [
    patientId,
    methods.watch(['id', 'startDate', 'endDate', 'status', 'inactiveReason', 'exitDestination']),
  ]);

  const status = useMemo<MembershipStatus>(
    () => getMembershipStatus(membershipPayload),
    [membershipPayload]
  );

  console.log({
    isLoading,
    id: selectedProgram,
    isLoadingEnrolledPatientsGroup,
    status,
  });

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        width: 400,
        height: '100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        overflowY: 'scroll',
      }}
    >
      <Stack px={2}>
        <Stack justifyContent="center" alignItems="center" sx={{ m: 2 }}>
          <PatientCard id={patientId} showCloseButton={false} />
        </Stack>

        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Typography variant="subtitle1">Enroll Patient to Plan / Program</Typography>
          <IconButton onClick={onClose}>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>

        <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack gap={1}>
            <RHFAutocomplete
              name="plan"
              label="Plan"
              options={plansOptions}
              disablePortal
              TextFieldProps={{
                fullWidth: true,
                margin: 'dense',
                size: 'small',
                variant: 'standard',
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={!!editData}
            />

            <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
              <input type="hidden" name="id" value={editData?.membership.id} />
              <RHFTextField
                name="manualPlanId"
                label="Health Plan ID"
                fullWidth
                size="small"
                variant="standard"
                disabled={
                  methods.getValues('manualPlanId')
                    ? !methods.getValues('manualPlanIdEditable')
                    : false
                }
              />

              {methods.getValues('manualPlanId') &&
                (methods.watch('manualPlanIdEditable') ? (
                  <Button
                    variant="text"
                    onClick={() => {
                      methods.setValue('manualPlanIdEditable', false);

                      if (!selectedPlan?.id) return;

                      const manualPlanId =
                        patient?.identifier?.find(
                          (item) =>
                            item.system === fhirSystem.healthPlan.withId(selectedPlan.id).asString()
                        )?.value || '';

                      methods.setValue('manualPlanId', manualPlanId);
                    }}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    onClick={async () => {
                      const confirmed = await confirmDialog({
                        title: 'Confirm Update',
                        description: `Are you sure you want to update the Health Plan ID?`,
                        confirmText: 'Update',
                        denyText: 'Cancel',
                      });
                      if (!confirmed) return;
                      methods.setValue('manualPlanIdEditable', true);
                    }}
                  >
                    Edit
                  </Button>
                ))}
            </Stack>

            <RHFAutocomplete
              name="lob"
              label="LoB"
              disablePortal
              options={lobsOptions}
              TextFieldProps={{
                fullWidth: true,
                margin: 'dense',
                size: 'small',
                variant: 'standard',
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={!!editData || !selectedPlan}
            />

            <RHFAutocomplete
              name="program"
              label="Program"
              disablePortal
              options={programsOptions}
              TextFieldProps={{
                fullWidth: true,
                margin: 'dense',
                size: 'small',
                variant: 'standard',
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disabled={editData ? true : !selectedLob}
            />

            <FormGroup>
              <RHFAutocomplete
                name="status"
                options={(Object.keys(membershipStatusMessage) as MembershipStatus[])
                  .filter((k) => !['unknown'].includes(k))
                  .map((key) => ({
                    id: key,
                    label: membershipStatusMessage[key],
                  }))}
                label="Status"
                fullWidth
                size="small"
                TextFieldProps={{
                  variant: 'standard',
                }}
                multiple={false}
                disablePortal
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </FormGroup>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {['active', 'inactive'].includes(methods.watch('status')?.id) && (
                <RHFDatePicker
                  name="startDate"
                  label="Enrollment date"
                  TextFieldProps={{
                    size: 'small',
                    variant: 'standard',
                  }}
                  clearable
                />
              )}

              {['active', 'inactive'].includes(methods.watch('status')?.id) && (
                <RHFDatePicker
                  name="endDate"
                  label="End date"
                  TextFieldProps={{
                    size: 'small',
                    variant: 'standard',
                  }}
                  clearable
                />
              )}
            </LocalizationProvider>

            {methods.getValues('status')?.id === 'inactive' && (
              <RHFAutocomplete
                name="inactiveReason"
                options={
                  programDisenrollmentReasons?.concept
                    ?.filter(
                      (item) =>
                        !item.property ||
                        item.property.length === 0 ||
                        item.property.find((p) => p.code === 'active')?.valueBoolean === true
                    )
                    .map(
                      (item) =>
                        ({
                          id: item.code,
                          label: item.display,
                        } as IOptionValue)
                    ) || []
                }
                label="Inactive reason"
                fullWidth
                size="small"
                TextFieldProps={{
                  variant: 'standard',
                }}
                multiple={false}
                freeSolo={false}
              />
            )}

            {methods.getValues('status')?.id === 'inactive' && (
              <RHFAutocomplete
                name="exitDestination"
                options={
                  exitDestinations.map(
                    (item) =>
                      ({
                        id: item.uuid,
                        label: item.valueDisplayName,
                      } as IOptionValue)
                  ) || []
                }
                label="Exit destination"
                fullWidth
                size="small"
                TextFieldProps={{
                  variant: 'standard',
                }}
                multiple={false}
                freeSolo={false}
              />
            )}
          </Stack>
          <ActionButtons
            onLeftButtonPress={onClose}
            rightButtonType="submit"
            isLoading={
              isLoading || (selectedProgram?.id && isLoadingEnrolledPatientsGroup ? true : false)
            }
          />
        </FormProvider>
      </Stack>
    </Box>
  );
}

export function getMembershipStatus(group: GroupMember): MembershipStatus {
  const start = group.period?.start;
  const end = group.period?.end;
  const inactive = group.inactive ?? false;

  if (!start && !end) {
    return inactive ? 'new' : 'pending';
  }

  if (start && !inactive) {
    return 'active';
  }

  if (end && inactive) {
    return 'inactive';
  }

  return 'unknown';
}
