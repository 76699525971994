// @ts-nocheck
import { useMemo, useContext } from 'react';
import moment from 'moment';
import { EventApi } from '@fullcalendar/react';
import { ActivityLegendRenderer, ActivityPriorityRenderer } from '../ActivityRenderers';
import { Stack, Typography, Grid, FormControl, FormLabel, Box } from '@mui/material';
import { Activity, ConcreteActivity } from 'src/sections/careflow/tasks-activities/model/Activity';
import ActivityContextMenu, { activityContextMenuItems } from '../ActivityContextMenu';
import { ActivitiesContext, ActivitiesContextType } from '../ActivitiesContext';
import { usePopupState, bindHover, bindPopover } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import './styles.css';
import { shortFormat } from 'src/utils/formatTime';
import usePatientTabsStore from 'src/stores/patient-tabs';
import { useNavigate } from 'react-router';
import { ActivityStatus } from '../../../model';
import { PractitionerProvider } from '../ActivityListView/ActivityListTable/activity-list-table-utils';
import { CircularProgress } from '@mui/material';

const styles = {
  formControlContainer: { marginBottom: '20px' },
};

type ActivityCalendarItemPropsType = {
  activity: ConcreteActivity;
  event: EventApi;
};

const ActivityCalendarItem = ({ activity, event }: ActivityCalendarItemPropsType) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: `activity-details-popup-${activity.id}`,
  });
  const navigate = useNavigate();
  const patientStore = usePatientTabsStore();
  const activitiesContext: ActivitiesContextType = useContext(ActivitiesContext);
  const menuItems = useMemo(
    () => [
      {
        ...activityContextMenuItems.find((item) => item.id === 'accept'),
        onPress: (activity: any) => activitiesContext.acceptActivity(activity),
      },
      {
        ...activityContextMenuItems.find((item) => item.id === 'start'),
        onPress: async (activity: ConcreteActivity) => {
          const { patientFhirId, scriptId, taskId, id: activityId } = activity;

          if (!patientFhirId) return;
          await activitiesContext.startActivity(activity);

          if (!scriptId) return;

          patientStore.setCurrentPatient(patientFhirId);
          patientStore.openPatient(patientFhirId);

          navigate(`/dashboard/patient/${patientFhirId}/care-record/assessments/new/${scriptId}`, {
            state: {
              taskId,
              activityId,
            },
          });
        },
        rules: (activity: Activity) =>
          activity.status === ActivityStatus.READY && activity.scriptId,
      },
      {
        ...activityContextMenuItems.find((item) => item.id === 'edit'),
        onPress: (selectedActivity: any) =>
          activitiesContext.setSelectedEntity(new ConcreteActivity(selectedActivity)),
      },
      {
        ...activityContextMenuItems.find((item) => item.id === 'cancel'),
        onPress: (activity: any) => activitiesContext.cancelActivity(activity),
      },
    ],
    [activitiesContext, navigate, patientStore]
  );

  const renderActivityDetails = () => (
    <HoverPopover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box sx={{ width: '300px', padding: '20px' }}>
        <Box sx={{ my: 3 }}>
          <Grid item>
            <Typography variant="h5">
              Activity details
              <ActivityContextMenu
                activity={activity}
                items={menuItems}
                onUpdateActivity={activitiesContext.onCreateOrUpdateEntity}
              />
            </Typography>
          </Grid>
        </Box>
        <Grid style={styles.formControlContainer} className="form-control-container" item>
          <FormControl>
            <FormLabel>Date and time</FormLabel>
            <Typography variant="h6">
              {activity.allDay
                ? 'All day'
                : `${shortFormat(activity.date)} / ${moment(activity.start).format(
                    'hh:mm a'
                  )} - ${moment(activity.end).format('hh:mm a')}`}
            </Typography>
          </FormControl>
        </Grid>
        <Grid style={styles.formControlContainer} className="form-control-container" item>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Typography variant="h6">{activity.patientName}</Typography>
          </FormControl>
        </Grid>
        <Grid style={styles.formControlContainer} className="form-control-container" item>
          <FormControl>
            <FormLabel>Age</FormLabel>
            <Typography variant="h6">{activity.patientAge}</Typography>
          </FormControl>
        </Grid>
        <Grid style={styles.formControlContainer} className="form-control-container" item>
          <FormControl>
            <FormLabel>ID</FormLabel>
            <Typography variant="h6">{activity.id}</Typography>
          </FormControl>
        </Grid>
        <Grid style={styles.formControlContainer} className="form-control-container" item>
          <FormControl>
            <FormLabel>Type</FormLabel>
            <Typography variant="h6">{activity.type}</Typography>
          </FormControl>
        </Grid>
        <Grid style={styles.formControlContainer} className="form-control-container" item>
          <FormControl>
            <FormLabel>Activity</FormLabel>
            <Typography variant="h6">{activity.activity}</Typography>
          </FormControl>
        </Grid>
        <Grid style={styles.formControlContainer} className="form-control-container" item>
          <FormControl>
            <FormLabel>Contact type</FormLabel>
            <Typography variant="h6">{activity.contactType}</Typography>
          </FormControl>
        </Grid>
        <Grid style={styles.formControlContainer} className="form-control-container" item>
          <FormControl>
            <FormLabel>Staff / Team</FormLabel>
            <PractitionerProvider
              id={activity.staffFhirId}
              render={(practitioner, { isLoading }) =>
                isLoading ? (
                  <CircularProgress size={28} />
                ) : (
                  <Typography variant="h6">{practitioner?.getFullName()}</Typography>
                )
              }
            />
          </FormControl>
        </Grid>
        <Grid style={styles.formControlContainer} className="form-control-container" item>
          <FormControl>
            <FormLabel>Tool / Script</FormLabel>
            <Typography variant="h6">{activity.script}</Typography>
          </FormControl>
        </Grid>
        <Grid style={styles.formControlContainer} className="form-control-container" item>
          <FormControl>
            <FormLabel>Priority</FormLabel>
            <Typography variant="h6">{new ActivityPriorityRenderer(activity).render()}</Typography>
          </FormControl>
        </Grid>
      </Box>
    </HoverPopover>
  );

  return (
    <>
      {renderActivityDetails()}
      <Stack className="fullcalendar-item-container ">
        <Stack {...bindHover(popupState)}>
          <Stack>
            <Stack direction="row">
              <b className="fc-event-time">
                {event.allDay ? 'All day' : moment(activity.start).format('hh:mm a')} -{' '}
                {!event.allDay && moment(activity.end).format('hh:mm a')}
              </b>
              <b style={{ marginLeft: '5px', marginTop: '2px' }}>
                {new ActivityLegendRenderer(activity).render()}
              </b>
            </Stack>
            <b className="fc-event-title">{event.title}</b>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ActivityCalendarItem;
