import { Typography } from '@mui/material';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import { QUESTION_TYPES } from '../constants';
import { cleanQuestionText } from '../utils';

interface QuestionTitleProps {
  question: WrappedQuestionnaireItem;
}

function QuestionTitle({ question }: QuestionTitleProps) {
  const questionSettings = QUESTION_TYPES[question.type];
  if (!questionSettings) {
    return null;
  }

  const questionType = questionSettings.display ?? question.type;

  return (
    <Typography
      variant="subtitle1"
      sx={{ p: 1 }}
      dangerouslySetInnerHTML={{
        __html: `${cleanQuestionText(question.text)}` + (questionType ? ` (${questionType})` : ''),
        // + ` ID:${question.id}`,
      }}
    />
  );
}

export default QuestionTitle;
