import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';
import useTenantTheme from 'src/hooks/useTenantTheme';
// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const { logo, tenantName } = useTenantTheme();
  const alt = tenantName ? `${tenantName} logo` : 'Logo';

  const component = (
    <Box>
      <img src={logo} style={{ width: 150 }} alt={alt} />
    </Box>
  );

  if (disabledLink) {
    return <>{component}</>;
  }

  return <RouterLink to="/">{component}</RouterLink>;
}
