export enum ActivityPriority {
  LOW = 'routine',
  NORMAL = 'urgent',
  HIGH = 'asap',
  URGENT = 'stat',
}

export const mapActivityPriority = {
  [ActivityPriority.LOW]: 'Low',
  [ActivityPriority.NORMAL]: 'normal',
  [ActivityPriority.HIGH]: 'High',
  [ActivityPriority.URGENT]: 'Urgent',
};
