import { Card, IconButton, Box } from "@mui/material";
import Iconify from "src/components/Iconify";
import AuthTokenGuard from "../../common/AuthTokenGuard";
import { TitleBar } from "../../common/SummaryTable";
import AnalyticsIframe from "./AnalyticsIframe";
import useAuth from "src/hooks/useAuth";

type AnalyticsProps = {
  patient: any;
  onExpand: () => void;
};

const Analytics = ({
  patient,
  onExpand,
}: AnalyticsProps) => {
  const user = useAuth().getCurrentUser();
  const userId = user?.id;
  const fhirUserId = user?.user_fhir_uri;

  return (
    <Card
      sx={{ minHeight: `calc(100vh - 200px)`, height: '100%', flexDirection: 'column' }}
    >
      <TitleBar
        title={'Analytics'}
        actionBar={
          <>
            <IconButton onClick={onExpand}>
              <Iconify icon="eva:expand-fill" />
            </IconButton>
          </>
        }
      />
  
      <Box sx={{ p: 2, height: 'calc(100vh - 300px)' }}>
        <AuthTokenGuard>
          {patient &&
            patient.id !== undefined &&
            userId !== undefined &&
            fhirUserId !== undefined && (
              <AnalyticsIframe
                iamUserId={userId}
                fhirUserId={fhirUserId}
                patientId={patient.id}
              />
            )}
        </AuthTokenGuard>
      </Box>
    </Card>
  )
};

export default Analytics;
