import { Badge, IconButton } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { Questionnaire } from 'src/@nicheaim/fhir-base/mappings/Questionnaire';
import { QuestionnaireResponse } from 'src/@nicheaim/fhir-base/mappings/QuestionnaireResponse';
import { useQuestionnaireResponses, useQuestionnaires } from 'src/@nicheaim/fhir-react';
import Iconify from 'src/components/Iconify';
import Label, { LabelColor } from 'src/components/Label';
import { DataTableColumn } from 'src/components/table/DataTable';
import { useAllAssessments } from 'src/fhir/hooks/assessments';
import fhirSystem from 'src/fhir/system';
import { SummaryTable } from 'src/sections/careflow/common';
import { useCurrentPatient } from 'src/stores/patient-tabs';

interface AssessmentsTableProps {
  onZoom?: () => void;
}

export default function AssessmentsTable({ onZoom }: AssessmentsTableProps) {
  const navigate = useNavigate();
  const patient = useCurrentPatient();
  const expandedAssessments = true;
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.refetchQueries({
      predicate(query) {
        return ['CarePlan', 'activities', 'activities-summary'].includes(
          query.queryKey[0] as string
        );
      },
    });
  }, []);

  const columns: DataTableColumn[] = [
    {
      header: 'Date',
      field: 'response.authored',
      render(value: QuestionnaireResponse['authored']) {
        return moment(value).format('MMM D, YYYY');
      },
    },
    {
      header: 'Type',
      field: 'questionnaire.meta.tag',
      render(value: { system: string; code: string }[]) {
        return '';
      },
    },
    {
      header: 'Assessment',
      field: 'questionnaire.title',
    },
    {
      header: 'Staff',
      field: 'response.author.display',
      visible: expandedAssessments,
    },
    {
      header: 'Category',
      field: 'questionnaire.meta.tag',
      visible: expandedAssessments,
      render(value: { system: string; code: string }[]) {
        return '';
      },
    },
    {
      header: expandedAssessments ? 'Status' : '',
      field: 'response.status',
      size: 'small',
      render: (value: 'completed' | 'in-progress') => {
        const label: {
          color: LabelColor;
          text: string;
        } = {
          completed: {
            color: 'success' as LabelColor,
            text: 'Completed',
          },
          'in-progress': {
            color: 'info' as LabelColor,
            text: 'In Progress',
          },
        }[value] ?? {
          color: 'default' as LabelColor,
          text: 'Unknown',
        };

        if (expandedAssessments) {
          return (
            <Label variant="ghost" color={label.color}>
              {label.text}
            </Label>
          );
        }

        return <Badge variant="dot" color={label.color} />;
      },
    },
  ];

  const [questionnaires] = useAllAssessments({
    filter: {
      patient: patient?.id,
    },
    pagination: {
      pageSize: 1000,
    },
  });

  const [questionnaireResponses, { nextPage, refresh }] = useQuestionnaireResponses({
    filter: {
      patient: patient?.id,
    },
    pagination: {
      pageSize: 10,
    },
  });

  const assessments = questionnaireResponses.map((response) => {
    const surveyId = response.identifier?.system?.split('/').at(-1);
    const questionnaire = questionnaires.find((q) =>
      q.identifier?.some(
        (i) => i.system === fhirSystem.assessments.assessment.asString() && i.value === surveyId
      )
    );

    return {
      questionnaire,
      response,
    };
  });

  useEffect(() => {
    refresh();
  }, []);

  return (
    <SummaryTable
      title="Assessments"
      columns={columns}
      clickeable
      onItemClick={(row: typeof assessments[number]) => {
        console.log('clicked')
        const { id = 'unknown' } = row.response;

        if (row.response.status === 'completed') {
          navigate(`/dashboard/patient/${patient?.id}/care-record/assessments/${id}/view`);
        } else {
          navigate(`/dashboard/patient/${patient?.id}/care-record/assessments/${id}/fill`);
        }
      }}
      fetchMore={() => nextPage()}
      data={assessments}
      actionBar={
        <>
          <IconButton
            onClick={() =>
              navigate(`/dashboard/patient/${patient?.id}/care-record/assessments/new`)
            }
          >
            <Iconify icon="eva:plus-fill" />
          </IconButton>
          <IconButton>
            <Iconify icon="eva:search-fill" />
          </IconButton>
          <IconButton onClick={() => onZoom?.()}>
            <Iconify icon="eva:expand-fill" />
          </IconButton>
        </>
      }
    />
  );
}
