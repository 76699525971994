import client from './_client';

export type GetCcmReferralsQueryFilters = {
  type: string | null;
  status: string | null;
  person: any | null;
  userEmail: string | null;
  userFhirId: string | null;
  pagination: {
    take: number;
    page: 0;
  };
  includeParentReferrals: boolean;
};

export async function getCcmReferrals(filters: any) {
  const {
    type,
    status,
    person,
    userEmail,
    userFhirId,
    pagination: { take, page },
    includeParentReferrals = false,
  }: GetCcmReferralsQueryFilters = filters;
  let url = `/ccm/referral?take=${take}&skip=${page * take}`;

  if (type) {
    url = `${url}&type=${type}`;
  }

  if (status) {
    url = `${url}&status=${status}`;
  }

  if (userEmail) {
    url = `${url}&userEmail=${userEmail}`;
  }

  if (userFhirId) {
    url = `${url}&userFhirId=${userFhirId}`;
  }

  if (person) {
    url = `${url}&person=${person}`;
  }

  if (includeParentReferrals) {
    url = `${url}&includeParentReferrals=${1}`;
  }

  const { data } = await client.get(url);

  return data;
}

export async function getCcmReferralsSummary(staftId: string) {
  const { data } = await client.get(`/ccm/referral/summary/${staftId}`);
  return data;
}

export async function saveCcmReferral(params: any) {
  const url = `/ccm/referral/save`;
  const { data } = await client.post(url, params);
  return data;
}

export async function createCcmReferralNote(note: string, referralId: string, patientId: string) {
  const url = `/ccm/referral/notes`;
  const payload = {
    note,
    referralId,
    patientId
  }
  const { data } = await client.post(url, payload);
  return data;
}

export async function updateCcmReferral(id: number, params: any) {
  const url = `/ccm/referral/update/${id}`;
  const { data } = await client.put(url, params);
  return data;
}

export async function deleteCcmReferral(id: string) {
  const { data } = await client.delete(`ccm/referral/delete/${id}`);

  return data;
}

export const createProgramsByServiceRequest = async (serviceRequestId: string) => {
  const { data } = await client.post(`crs/referral/program`, { serviceRequestId });
  return data;
};

export const addCreatedBy = async (referralId: number, createdBy: string) => {
  const { data } = await client.post(`ccm/referral/add-created-by/${referralId}`, { createdBy });
  return data;
}
