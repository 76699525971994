import moment from 'moment';
import { ConcreteActivity } from '../../model/Activity';
import { ActivityStatus } from '../../model/ActivityStatus';

export const renderDate = (activity: ConcreteActivity) => {
  if (
    activity.allDay &&
    activity.status !== ActivityStatus.COMPLETED &&
    activity.status !== ActivityStatus.CANCELLED
  ) {
    return `${activity.end ? moment(activity.end).format('MMM DD Y') : 'Unknown'} - All day`;
  }

  if (
    activity.status === ActivityStatus.COMPLETED ||
    activity.status === ActivityStatus.CANCELLED
  ) {
    if (activity.completedDate) {
      return `${moment(activity.completedDate).format('MMM DD Y')} - ${moment(
        activity.completedTime
      ).format('hh:mm a')}`;
    } else {
      return 'Unknown';
    }
  }

  // FIXME: Not handling properly, end date is not just a time, it's a full date
  return [
    activity.date ? moment(activity.date).format('MMM DD Y') : 'Unknown',
    activity.start ? moment(activity.start).format('hh:mm a') : 'Unknown',
    activity.end ? moment(activity.end).format('hh:mm a') : 'Unknown',
  ].join(' - ');
};
