import { Navigate, Route, Routes } from 'react-router';
import EditLOB from './edit';
import LOBProgramsIndex from './programs';
import AddPatientToProgramForm from './programs/$program/patients/new';

export default function LOBIndex() {
  return (
    <Routes>
      <Route index element={<Navigate to="programs" replace />} />
      <Route path="edit" element={<EditLOB />} />
      <Route path="programs/*" element={<LOBProgramsIndex />} />
      <Route path="patients" element={<div />} />
      <Route path="patients/new" element={<AddPatientToProgramForm />} />
    </Routes>
  );
}
