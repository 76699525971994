import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Button } from '@mui/material';
// components
import { FormProvider, RHFSelect, RHFTextField } from '../../../../components/hook-form';
import {
  ahsManagerUser,
  CarePlanGoalGroup,
  goalGroupStatus,
  _goalGroups,
} from 'src/_mock/settings/_careplan-automation';
import { nanoid } from '@reduxjs/toolkit';
import api from 'src/services/api';
import axios from 'axios';
import { MenuItem } from '@mui/material';

// ----------------------------------------------------------------------

type FormValue = Pick<CarePlanGoalGroup, 'name' | 'status'>;

type GoalGroupsFormProps = {
  isEdit?: boolean;
  currentGoalGroup?: CarePlanGoalGroup;
};

const schema = Yup.object().shape({
  name: Yup.string().min(3).required('Name is required'),
  status: Yup.string().oneOf(['active', 'inactive']).required('Name is required'),
});

const resolver = yupResolver(schema);

export default function GoalGroupsForm({ isEdit, currentGoalGroup }: GoalGroupsFormProps) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () =>
      ({
        name: currentGoalGroup?.name || '',
        status: currentGoalGroup?.status || 'active',
      } as FormValue),
    [currentGoalGroup]
  );

  const methods = useForm({ resolver, defaultValues });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentGoalGroup) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentGoalGroup]);

  const onSubmit = async (data: FormValue) => {
    try {
      if (isEdit && currentGoalGroup) {
        await api.carePlan.updateGoalGroup({
          ...data,
          uuid: currentGoalGroup.uuid,
        });
      } else {
        await api.carePlan.createGoalGroup(data);
      }

      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      navigate('..');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(
          error.response?.data?.message?.[0] || error?.message || 'Something went wrong!',
          {
            variant: 'error',
          }
        );
      }

      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 3 }}>
        <Stack
          gap={3}
          sx={{
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <RHFTextField name="name" label="Goal Group Name" />

          {isEdit && (
            <RHFSelect name="status" label="Status">
              {Object.entries(goalGroupStatus).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </RHFSelect>
          )}
        </Stack>
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }} gap={2}>
          <Button variant="text" color="inherit" component={RouterLink} to="..">
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {!isEdit ? 'Create Goal Group' : 'Save Changes'}
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
