import { useMemo } from 'react';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { useValueSets } from 'src/@nicheaim/fhir-react';

const useValueSetsByIdentifiers = (identifiers: string[]) => {
  const [valueSets, callState] = useValueSets({
    filter: {
      identifier: identifiers.join(','),
    },
    map: ValueSetWrapper,
  });
  const valueSetsByIdentifier = useMemo(
    () =>
      identifiers.map(
        (identifierValue) =>
          valueSets.find(
            ({ identifier }) => !!identifier?.find?.(({ value }) => value === identifierValue)
          ) ?? null
      ),
    [valueSets]
  );
  return { valueSets: valueSetsByIdentifier, ...callState };
};

export default useValueSetsByIdentifiers;
