import { InputAdornment, TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import { QUESTION_TYPES } from '../constants';
import useCarePlanAutomationFormStore, { useCarePlanAutomationFormRule } from '../store';

interface NumberOperatorProps {
  question: WrappedQuestionnaireItem;
  ruleId: string;
}

function NumberOperator({ question, ruleId }: NumberOperatorProps) {
  const saving = useCarePlanAutomationFormStore((s) => s.loading);
  const { value, setValue, operator } = useCarePlanAutomationFormRule(ruleId);
  const { operators } = QUESTION_TYPES[question.type]!;
  const operatorSettings = operators.find((o) => o.value === operator);
  if (!operatorSettings) {
    return null;
  }

  return (
    <TextField
      disabled={saving}
      sx={{ flex: 1 }}
      size="small"
      value={value}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.valueAsNumber)}
      type="number"
      InputProps={
        operatorSettings.unit && {
          endAdornment: (
            <InputAdornment position="end">
              {value === 1 ? operatorSettings.unit[0] : operatorSettings.unit[1]}
            </InputAdornment>
          ),
        }
      }
    />
  );
}

export default NumberOperator;
