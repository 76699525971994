// @ts-nocheck
import { Container, Grid } from '@mui/material';
import { useEffect, useCallback, useState } from 'react';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Page from 'src/components/Page';
import useObjectState from 'src/hooks/useObjectState';
import useSettings from 'src/hooks/useSettings';
import { Carousel } from 'src/sections/careflow/common';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import AllergiesSummary from './allergy/AllergiesSummary';
import AttachmentSummary from './attachments/AttachmentSummary';
import {
  DiagnosisSummary,
  LabSummary,
  PatientInfoHeader,
  ServicesSummary,
  VitalsSummary,
} from './patient-info-header';
import MedicationsRequestsSummary from './medications-requests/MedicationsRequestsSummary';
import MedicationAdministrationsSummary from './medication-administrations/MedicationAdministrationsSummary';
import { useMatch } from 'react-router';

// ----------------------------------------------------------------------

export default function HealthRecord() {
  const patient = useCurrentPatient();
  const { themeStretch } = useSettings();
  const [open, setOpen] = useState(false);
  const [{ areTablesExpanded, currentTable }, updateState] = useObjectState({
    areTablesExpanded: false,
    currentTable: 0,
  });

  const medicationAdministrationUrlMatch =
      useMatch('/dashboard/patient/:patientId/health-record/medication-administration/:medicationAdministrationId')

  const onExpandView = useCallback((tableIndex: number, goToIndex?: number): void => {
    updateState((prev: any) => ({
      areTablesExpanded: !prev.areTablesExpanded,
      currentTable: goToIndex ?? tableIndex,
    }));
  }, []);

  useEffect(() => {
    if (medicationAdministrationUrlMatch) {
      onExpandView(3)
    }
  }, [medicationAdministrationUrlMatch, onExpandView]);

  console.log({
    currentTable
  })

  return (
    <>
      <Page title="Health Record">
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs
                title=" Health Record"
                links={[
                  {
                    href: '',
                    name: 'Dashboard',
                  },
                  {
                    href: '',
                    name: 'Health Record',
                  },
                  {
                    href: '',
                    name: patient?.getFullName() ?? '',
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <PatientInfoHeader />
            </Grid>
            <Grid item xs={12} style={{ position: 'relative' }}>
              <Carousel
                open={open}
                onClose={() => setOpen(false)}
                settings={{
                  infinite: false,
                  slidesToShow: areTablesExpanded ? 1 : 3,
                  slidesToScroll: areTablesExpanded ? 1 : 3,
                  draggable: false,
                }}
                current={currentTable}
                buttonsBehavior={areTablesExpanded ? 'slide' : 'page'}
                onChange={({ slide, behavior }) => {
                  updateState((prev: any) => ({
                    areTablesExpanded: prev.areTablesExpanded,
                    currentTable: slide,
                  }));
                }}
              >
                <DiagnosisSummary
                  isExpanded={areTablesExpanded}
                  patient={patient}
                  onExpand={onExpandView}
                />
                <ServicesSummary
                  isExpanded={areTablesExpanded}
                  patient={patient}
                  onExpand={onExpandView}
                />

                <MedicationsRequestsSummary
                  isExpanded={areTablesExpanded}
                  patient={patient}
                  onExpand={onExpandView}
                />

                <MedicationAdministrationsSummary
                  isExpanded={areTablesExpanded}
                  patient={patient}
                  onExpand={onExpandView}
                />

                <VitalsSummary patient={patient} isExpanded={areTablesExpanded} onExpand={onExpandView} />
                
                <LabSummary
                  patient={patient}
                  isExpanded={areTablesExpanded}
                  onExpand={onExpandView}
                />

                {/* <SummaryTable
                  data={servicesSummary}
                  actionBar={
                    <ActionBar
                      onExpand={() => {
                        setExpandedComponent(
                          <SummaryTable
                            actionBar={<ActionBar onCollapse={() => setOpen(false)} />}
                            data={servicesSummaryExpanded}
                          />
                        );
                        setOpen(true);
                      }}
                    />
                  }
                /> */}

                <AllergiesSummary
                  isExpanded={areTablesExpanded}
                  onExpand={onExpandView}
                  patient={patient}
                />

                <AttachmentSummary
                  isExpanded={areTablesExpanded}
                  onExpand={onExpandView}
                  patient={patient}
                />
              </Carousel>
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
}
