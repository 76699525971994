import { CareTeamMember } from 'src/@types/crs/case';
import { createWrapper } from '../../fhir-react/base';
import {
  getReferenceResourceId,
  getSeverityStatusByCareTeamStatus,
} from 'src/sections/crs/helpers/common';

export const CareTeamWrapper = createWrapper('CareTeam', (careTeam) => ({
  plainCategory: careTeam?.category?.[0]?.coding?.[0]?.display,
  noOfMembers: String(careTeam?.participant?.length ?? 0),
  managingOrganizationName:
    careTeam?.managingOrganization?.[0]?.display ?? careTeam?.managingOrganization?.[0]?.reference,
  managingOrganizationId: getReferenceResourceId(
    careTeam?.managingOrganization?.[0]?.reference ?? ''
  ),
  severityStatus: getSeverityStatusByCareTeamStatus(careTeam?.status),
  members: careTeam?.participant,
  getMembersList: (): CareTeamMember[] =>
    careTeam?.participant?.map((participant) => ({
      name: (participant?.member?.display ?? participant?.member?.reference) as string,
      id: participant?.member?.reference as string,
    })) as CareTeamMember[],
}));

export type WrappedCareTeam = ReturnType<typeof CareTeamWrapper>;
