// @ts-nocheck
import { ChangeEvent, useEffect, useMemo } from 'react';
import moment from 'moment';
import useObjectState from '../../../../../hooks/useObjectState';
import {
  Divider,
  Box,
  Grid,
  Typography,
  InputLabel,
  FormControl,
  Autocomplete,
  TextField,
  Alert,
  Select,
  MenuItem,
  Checkbox,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Table,
  IconButton,
} from '@mui/material';
import API from 'src/services/api';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import GroupSessionType from '../../types/GroupSessionType';
import { SaveGroupSessionInput } from 'src/services/api/groupSessions';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Iconify from 'src/components/Iconify';
import useAddEntityRequestStates from 'src/hooks/useAddEntityRequestStates';
import {
  AddGroupSessionStateType,
  ScheduleItemDayEnum,
  AddGroupSessionModalPropsType,
  FrequencyEnum,
  scheduleDayOptions,
  frequencyOptions,
  DropDownItem,
  ScheduleItemType,
  frequencyLabels,
} from './types';
import { Questionnaire } from 'src/@nicheaim/fhir-base/mappings/Questionnaire';
import { Location } from 'src/@nicheaim/fhir-base/mappings/Location';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import { useActiveAssessments } from 'src/fhir/hooks/assessments';
import { getDefaultState } from './utils';
import { defaulState } from './constants';
import PatientSearchDropdown from 'src/sections/careflow/common/PatientSearchDropdown';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddGroupSessionModal = ({
  onSaveCallback,
  onCloseModal,
  groupSession: session,
}: AddGroupSessionModalPropsType) => {
  const [state, updateState] = useObjectState<AddGroupSessionStateType>(getDefaultState(session));
  const {
    sessionName,
    description,
    startDate,
    endDate,
    schedules,
    assessmentItems,
    locationItems,
    peopleItems,
    patients,
    locations,
  } = state;
  const [{ isLoading, error, success }, { setIsLoading, setError, setSuccess }] =
    useAddEntityRequestStates();
  const [questionnaires] = useActiveAssessments({
    pagination: {
      pageSize: 1000,
    },
    filter: {
      _sort: 'name',
    },
  });
  // items to delete on the edition
  const patientsToDelete = useMemo(() =>
    session?.participants.filter(
      participant => !peopleItems.find(patient => patient?.value === participant.patient.id)
      ).map(i => i.id) ?? [],
    [peopleItems, session?.participants]);
  const assessmentsToDelete = useMemo(() =>
    session?.assessments.filter(
      sessionAssessment => !assessmentItems.find(assessment => assessment?.value === sessionAssessment.assessmentFhirId)
      ).map(i => i.id) ?? [],
    [assessmentItems, session?.assessments]);
  const schedulesToDelete = useMemo(() =>
    session?.schedules.filter(
      sessionSchedule => !schedules.find(schedule => schedule?.id === sessionSchedule.id)
      ).map(i => i.id) ?? [],
    [schedules, session?.schedules]);

  const assessmentsOptions = useMemo(
    () =>
      questionnaires.map((assessment: Questionnaire) => ({
        label: assessment.title,
        value: assessment.id,
        isRequired: false,
      })),
    [questionnaires]
  );
  const addressOptions = useMemo(
    () =>
      locations?.map((location: Location) => ({
        label: location.name,
        value: location.id,
        isRequired: false,
      })),
    [locations]
  );

  // functions
  const onClearAllPress = () => {
    updateState({
      ...defaulState,
      patients,
      locations,
    });
  };

  const validateRequiredFields = () => {
    if (!sessionName.trim() || peopleItems.length === 0 || schedules.length === 0) {
      return false;
    }

    return true;
  };

  const onSavePress = async () => {
    setIsLoading(true);
    if (!validateRequiredFields()) {
      setError('Please, fill all required fields');
      setSuccess(null);
      setIsLoading(false);
      return;
    }

    if (moment(startDate).format('YYYY-MM-DD') > moment(endDate).format('YYYY-MM-DD')) {
      setError('Start date cannot be greater than end date.');
      setSuccess(null);
      setIsLoading(false);
      return;
    }

    if (schedules.filter(s => s.startHour >= s.endHour).length > 0) {
      setError('Schedules with start hour greater than end hour are not allowed.');
      setSuccess(null);
      setIsLoading(false);
      return;
    }

    const data: SaveGroupSessionInput = {
      schedules: schedules,
      assessments: assessmentItems,
      patients: peopleItems,
      location: {
        label: locationItems.map((location) => location?.label).join('*|*'),
        value: locationItems.map((location) => location?.value).join(','),
      },
      patientsToDelete,
      assessmentsToDelete,
      schedulesToDelete,
      name: sessionName,
      description: description,
      startDate: moment(startDate).toISOString(),
      endDate: moment(endDate).toISOString(),
    };

    if (!session) {
      API.groupSessions
        .create(data)
        .then((createdGroupSession: GroupSessionType) => {
          setError(null);
          setSuccess('Group session successfully created.');
          completeGroupSessionSave(createdGroupSession);
        })
        .catch(() => incompleteGroupSessionSave());
    } else {
      API.groupSessions
        .update(session?.id, data)
        .then((updatedGroupSession: GroupSessionType) => {
          setError(null);
          setSuccess('Group session successfully created.');
          completeGroupSessionSave(updatedGroupSession);
        })
        .catch(() => incompleteGroupSessionSave());
    }
  };

  const completeGroupSessionSave = (groupSession: GroupSessionType) => {
    setTimeout(() => {
      onSaveCallback(groupSession);
      onCloseModal();
    }, 800);
  };

  const incompleteGroupSessionSave = () => {
    setError('Error saving group session. Please try again');
    setSuccess(null);
    setIsLoading(false);
  };

  function getFirstDayOfWeekOfMonth(year: number, month: number, dayOfWeek: number) {
    const firstDayOfMonth = new Date(year, month, 1);
    const daysUntilDayOfWeek = (dayOfWeek - firstDayOfMonth.getDay() + 7) % 7;
    const firstDayOfWeekDate = daysUntilDayOfWeek + 1;

    return new Date(year, month, firstDayOfWeekDate);
  }

  const calculateDateByFrequency = (scheduleParam: ScheduleItemType) => {
    if (scheduleParam.frequency === FrequencyEnum.LAST) {
      const selectedDay = scheduleParam.day;
      scheduleParam.date = moment()
        .utc()
        .endOf('month')
        .isoWeekday(selectedDay.replace(selectedDay[0], selectedDay[0].toUpperCase()))
        .format('Y-MM-DD');
    }

    if (scheduleParam.frequency === FrequencyEnum.WEEKLY) {
      const selectedDay = scheduleParam.day;
      scheduleParam.date = moment()
        .utc()
        .isoWeekday(selectedDay.replace(selectedDay[0], selectedDay[0].toUpperCase()))
        .format('Y-MM-DD');
    }

    if (scheduleParam.frequency === FrequencyEnum.MONTHLY) {
      const daysMap = (dayParam: ScheduleItemDayEnum): number => {
        let dayNumber = 1;
        switch (dayParam) {
          case ScheduleItemDayEnum.SUNDAY:
            dayNumber = 0;
            break;
          case ScheduleItemDayEnum.MONDAY:
            dayNumber = 1;
            break;
          case ScheduleItemDayEnum.TUESDAY:
            dayNumber = 2;
            break;
          case ScheduleItemDayEnum.WEDNESDAY:
            dayNumber = 3;
            break;
          case ScheduleItemDayEnum.THRUSDAY:
            dayNumber = 4;
            break;
          case ScheduleItemDayEnum.FRIDAY:
            dayNumber = 5;
            break;
          case ScheduleItemDayEnum.SATURRDAY:
            dayNumber = 6;
            break;
          default:
            dayNumber = 1;
        }

        return dayNumber;
      };
      const selectedDay = scheduleParam.day;
      const currentMonth = moment().utc().month();
      const currentYear = moment().utc().year();
      const selectedDate = moment(
        getFirstDayOfWeekOfMonth(currentYear, currentMonth, daysMap(selectedDay))
      )
        .utc()
        .format('YYYY-MM-DD');

      scheduleParam.date = selectedDate;
    }

    return scheduleParam.date;
  };

  const onChangeScheduleFrequency = (frequency: FrequencyEnum, index: number) => {
    const newSchedules = [...schedules];
    newSchedules[index].frequency = frequency;

    if (frequency === FrequencyEnum.LAST) {
      const selectedDay = newSchedules[index].day;
      newSchedules[index].date = moment()
        .utc()
        .endOf('month')
        .isoWeekday(selectedDay.replace(selectedDay[0], selectedDay[0].toUpperCase()))
        .format('Y-MM-DD');
    }

    newSchedules[index].date = calculateDateByFrequency(newSchedules[index]);

    updateState({
      schedules: newSchedules,
    });
  };

  const onChangeScheuduleDay = (day: ScheduleItemDayEnum, index: number) => {
    const newSchedules = [...schedules];
    newSchedules[index].day = day;
    newSchedules[index].disabledFrequency = false;

    if (newSchedules[index].frequency === FrequencyEnum.DAILY) {
      newSchedules[index].frequency = FrequencyEnum.WEEKLY;
    }

    if (day === ScheduleItemDayEnum.ALL) {
      newSchedules[index].frequency = FrequencyEnum.DAILY;
      newSchedules[index].disabledFrequency = true;
    }

    newSchedules[index].date = calculateDateByFrequency(newSchedules[index]);

    updateState({
      schedules: newSchedules,
    });
  };

  const onChangeScheduleDate = (date: string, index: number) => {
    const newSchedules = [...schedules];
    newSchedules[index].date = date;
    const justDaysArray = [...scheduleDayOptions];
    justDaysArray.shift();
    newSchedules[index].day = justDaysArray[moment(date).utc().day()];

    updateState({
      schedules: newSchedules,
    });
  };

  const onChangeScheduleStart = (start: string, index: number) => {
    const newSchedules = [...schedules];
    newSchedules[index].startHour = start;

    updateState({
      schedules: newSchedules,
    });
  };

  const onChangeScheuduleEnd = (end: string, index: number) => {
    const newSchedules = [...schedules];
    newSchedules[index].endHour = end;

    updateState({
      schedules: newSchedules,
    });
  };

  const onToggleCheckbox = (e: ChangeEvent<HTMLInputElement>, assessment: DropDownItem) => {
    updateState((prev) => ({
      assessmentItems: prev.assessmentItems?.map((currentAssessment) => {
        if (currentAssessment?.value === assessment?.value) {
          return {
            label: assessment?.label ?? '',
            value: assessment?.value ?? '',
            isRequired: !assessment?.isRequired,
          };
        }

        return currentAssessment;
      }),
    }));
  };

  useEffect(() => {
    axiosFhirInstance.get('/Location?_sort=name').then((locationsResponse: any) => {
      updateState({
        locations: locationsResponse.data.entry.map((r: any) => r.resource) as Location[],
      });
    });
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: 0,
        width: 680,
        height: '100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll',
      }}
    >
      <form>
        <Box
          sx={{
            my: 3,
            position: 'fixed',
            width: '680px',
            backgroundColor: 'white',
            opacity: 1,
            zIndex: 9,
            top: '-25px',
            left: 0,
            pl: 4,
            pr: 4,
          }}
        >
          <Box sx={{ my: 3 }}>
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {session ? 'Edit' : 'Add new'} group session
              </Typography>
            </Grid>
          </Box>
          <Divider />
        </Box>
        <Box sx={{ marginTop: '80px' }}>
          <Grid item style={{ marginTop: '15px' }}>
            <InputLabel shrink>Session name *</InputLabel>
            <FormControl fullWidth>
              <TextField
                value={sessionName}
                type="text"
                variant="standard"
                onChange={(e) =>
                  updateState({
                    sessionName: e.target.value,
                  })
                }
                required
              />
            </FormControl>
          </Grid>
          <Grid item style={{ marginTop: '15px' }}>
            <Box sx={{ mb: 2, flexDirection: 'row', justifyItems: 'space-between' }}>
              <Typography variant="h6">
                Schedules configuration
                <IconButton
                  onClick={() => {
                    const newSchedule = {
                      id: null,
                      tempId: (schedules?.length ?? 0) + 1,
                      day: ScheduleItemDayEnum.MONDAY,
                      date: moment().startOf('week').format('Y-MM-D'),
                      startHour: moment().format('HH:mm'),
                      endHour: moment().add(1, 'h').format('HH:mm'),
                      frequency: FrequencyEnum.WEEKLY,
                      disabledFrequency: false,
                    };

                    newSchedule.date = calculateDateByFrequency(newSchedule);

                    updateState((prev) => ({
                      schedules: [...(prev.schedules as ScheduleItemType[]), newSchedule],
                    }));
                  }}
                >
                  <Iconify icon="eva:plus-fill" />
                </IconButton>
              </Typography>
            </Box>
            <Table sx={{ mt: 1 }}>
              <TableHead>
                <TableCell>Day</TableCell>
                <TableCell>Frequency</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
                <TableCell />
              </TableHead>
              <TableBody>
                {schedules.map((schedule, index) => (
                  <TableRow key={schedule?.tempId}>
                    <TableCell>
                      {schedule.frequency !== FrequencyEnum.CUSTOM ? (
                        <Select
                          value={schedule.day}
                          onChange={(e) =>
                            onChangeScheuduleDay(e.target.value as ScheduleItemDayEnum, index)
                          }
                        >
                          {scheduleDayOptions.map((day) => (
                            <MenuItem key={day} value={day}>
                              {day}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <TextField
                          value={schedule.date}
                          defaultValue={schedule.date}
                          onChange={(e) => onChangeScheduleDate(e.target.value, index)}
                          type="date"
                          variant="standard"
                          required
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Select
                        value={schedule.frequency}
                        onChange={(e) =>
                          onChangeScheduleFrequency(e.target.value as FrequencyEnum, index)
                        }
                        disabled={schedule.disabledFrequency}
                        sx={{
                          width: '150px',
                        }}
                      >
                        {frequencyOptions
                          .filter((frequency) => {
                            if (
                              frequency === FrequencyEnum.DAILY &&
                              schedule.day !== ScheduleItemDayEnum.ALL
                            )
                              return false;

                            return true;
                          })
                          .map((frequency) => (
                            <MenuItem key={frequency} value={frequency}>
                              {frequencyLabels[frequency].replace(':day', schedule.day)}
                            </MenuItem>
                          ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={schedule.startHour}
                        defaultValue={schedule.startHour}
                        onChange={(e) => onChangeScheduleStart(e.target.value, index)}
                        type="time"
                        variant="standard"
                        required
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={schedule.endHour}
                        defaultValue={schedule.endHour}
                        onChange={(e) => onChangeScheuduleEnd(e.target.value, index)}
                        type="time"
                        variant="standard"
                        required
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() =>
                          updateState((prev) => ({
                            schedules: prev.schedules?.filter((s) => s.tempId !== schedule.tempId),
                          }))
                        }
                      >
                        <Iconify icon="mdi:trash-can-outline" fontSize={25} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {schedules.length === 0 && (
              <Alert style={{ marginTop: '20px' }} severity="info">
                Press the "+" button to configure a new schedule
              </Alert>
            )}
          </Grid>
          <Grid item style={{ marginTop: '15px' }}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6">Assessments configuration</Typography>
            </Box>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                disablePortal
                options={assessmentsOptions}
                value={assessmentItems}
                disableCloseOnSelect
                onChange={(_: any, value: any | null, reason, changes) => {
                  if (assessmentItems.find((i: DropDownItem) => i?.value === changes?.option?.value)) {
                    updateState({ assessmentItems: value.filter((i: DropDownItem) => i?.value !== changes?.option?.value) });
                    return;
                  }
                  updateState({ assessmentItems: value })
                }}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} label="Assessment" />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        assessmentItems.find((i) => i?.value === option?.value) ? true : false
                      }
                    />
                    {option?.label}
                  </li>
                )}
              />
            </FormControl>
            <Table sx={{ mt: 2 }}>
              <TableHead>
                <TableCell>Assessment</TableCell>
                <TableCell>Is required</TableCell>
              </TableHead>
              <TableBody>
                {assessmentItems.map((assessment, index) => (
                  <TableRow key={assessment?.value}>
                    <TableCell>
                      <Typography>{assessment?.label}</Typography>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={assessment?.isRequired}
                        onChange={(e) => {
                          onToggleCheckbox(e, assessment);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {assessmentItems.length === 0 && (
              <Alert style={{ marginTop: '20px' }} severity="info">
                Select at least one assessment to see the options
              </Alert>
            )}
          </Grid>
          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <TextField
                label="Start date"
                value={startDate}
                onChange={(e) => updateState({ startDate: e.target.value })}
                type="date"
                variant="standard"
              />
            </FormControl>
          </Grid>
          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <TextField
                label="End date"
                value={endDate}
                onChange={(e) => updateState({ endDate: e.target.value })}
                type="date"
                variant="standard"
              />
            </FormControl>
          </Grid>
          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                disablePortal
                options={addressOptions ?? []}
                value={locationItems}
                disableCloseOnSelect
                onChange={(_: any, value: any | null, reason, changes) => {
                  if (locationItems.find((i: DropDownItem) => i?.value === changes?.option?.value)) {
                    updateState({ locationItems: value.filter((i: DropDownItem) => i?.value !== changes?.option?.value) });
                    return;
                  }
                  updateState({ locationItems: value })
                }}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} label="Location" />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={locationItems.find((i) => i?.value === option?.value) ? true : false}
                    />
                    {option?.label}
                  </li>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <PatientSearchDropdown
                multiple
                value={peopleItems}
                onChange={(_: any, value: any | null, reason, changes) => {
                  if (peopleItems.find(i => i?.value === changes?.option.value)) {
                    updateState({ peopleItems: value.filter((i: DropDownItem) => i?.value !== changes?.option.value) });
                    return;
                  }
                  updateState({ peopleItems: value })
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={peopleItems.find((i) => i?.value === option?.value) ? true : false}
                    />
                    {option?.label}
                  </li>
                )}
                variant="standard"
                label="Participants"
              />
            </FormControl>
          </Grid>
          <Grid item style={{ marginTop: '15px' }}>
            <InputLabel shrink>Description</InputLabel>
            <FormControl fullWidth>
              <TextField
                value={description}
                type="text"
                variant="standard"
                onChange={(e) =>
                  updateState({
                    description: e.target.value,
                  })
                }
                required
              />
            </FormControl>
          </Grid>
          {error && (
            <Alert style={{ marginTop: '20px' }} severity="error">
              {error}
            </Alert>
          )}
          {success && (
            <Alert style={{ marginTop: '20px' }} severity="success">
              {success}
            </Alert>
          )}
          <ActionButtons
            isLoading={isLoading}
            onLeftButtonPress={onClearAllPress}
            rightButtonTitle="Save"
            onRightButtonPress={onSavePress}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AddGroupSessionModal;
