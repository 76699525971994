// @ts-nocheck
import { useEffect, useState } from 'react';
import produce from 'immer';
import {
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import {
  useCareTeams,
  useEncounters,
  usePractitioner,
  usePractitionerRoles,
  usePractitioners,
} from 'src/@nicheaim/fhir-react';
import { ActionBar } from '../../common';
import ProvidersForm from './ProvidersForm';
import { SHOW_SIDEBAR_COMPONENT, useEventBus } from 'src/event-bus';
import { shortFormat, getTimeSince } from 'src/utils/formatTime';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { Practitioner } from 'src/@nicheaim/fhir-base/mappings/Practitioner';

interface ProvidersProps {
  onCollapse?: () => void;
}

interface ProviderRowProps {
  onDelete: (provider: any) => void;
  onEdit: (provider: any) => void;
  provider: any;
}

const ProviderExpandedRow = ({ onDelete, onEdit, provider }: ProviderRowProps) => {
  const practitionerId = provider?.member?.reference?.split('/')?.[1];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const patient = useCurrentPatient();
  const [encounters] = useEncounters({
    filter: {
      patient: patient?.id,
      participant: provider?.member?.reference,
    },
  });
  const [practitioner] = usePractitioner(practitionerId, {
    map: PractitionerWrapper,
  });
  const [role] = usePractitionerRoles({
    filter: {
      practitioner: provider?.member?.reference,
    },
  });

  const validDate = encounters?.[0]?.period?.start;

  const lastVisit = validDate ? shortFormat(new Date(encounters[0].period.start)) : '';
  const daysSince = validDate ? getTimeSince(new Date(encounters[0].period.start)) : '';

  const address = practitioner?.getPrimaryAddressFormatted();
  const email = practitioner?.getPrimaryEmail();
  const phone = practitioner?.getPrimaryPhone();

  const handleEdit = () => {
    onEdit({
      provider,
      practitioner,
      role,
      encounter: encounters?.[0],
    });
    closeMenu();
  };

  const handleDelete = () => {
    onDelete(practitioner);
    closeMenu();
  };

  return (
    <TableRow>
      <TableCell>
        <Stack alignItems="center" direction="row" justifyContent="flex-start">
          {provider?.role?.[0]?.text === 'PCP' ? (
            <Iconify icon="eva:star-fill" sx={{ mr: '10px' }} />
          ) : null}
          <Typography variant="subtitle2">{practitioner?.getFullNameWithTitles()}</Typography>
        </Stack>
      </TableCell>
      <TableCell>
        {role && (
          <Typography variant="body2">{role?.[0]?.specialty?.[0].coding?.[0]?.display}</Typography>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="body2">{lastVisit}</Typography>
        {validDate ? <Typography variant="body2">({daysSince})</Typography> : null}
      </TableCell>
      <TableCell>
        <Table size="small">
          {phone?.value ? (
            <TableRow>
              <TableCell>
                <Typography variant="caption">Phone:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{phone.value}</Typography>
              </TableCell>
            </TableRow>
          ) : null}
          {email?.value ? (
            <TableRow>
              <TableCell>
                <Typography variant="caption">Email:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{email.value}</Typography>
              </TableCell>
            </TableRow>
          ) : null}
          {address ? (
            <TableRow>
              <TableCell>
                <Typography variant="caption">Address:</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{address}</Typography>
              </TableCell>
            </TableRow>
          ) : null}
        </Table>
      </TableCell>
      <TableCell align="right">
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleEdit}>
            <Stack direction="row" spacing={2}>
              <Iconify icon="eva:edit-fill" />
              <Typography variant="body2">Edit</Typography>
            </Stack>
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <Stack direction="row" spacing={2}>
              <Iconify icon="eva:trash-2-outline" />
              <Typography variant="body2">Delete</Typography>
            </Stack>
          </MenuItem>
        </Menu>
        <IconButton onClick={openMenu}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const ProvidersExpanded = ({ onCollapse }: ProvidersProps) => {
  const patient = useCurrentPatient();
  const [careTeams, { update: updateCareTeam, refresh: refreshCareTeams }] = useCareTeams({
    filter: {
      patient: patient?.id,
    },
  });
  const [, { update: updatePractitioner, refresh: refreshPractitioners }] = usePractitioners();

  const { dispatchEvent, onEvent, removeEvent } = useEventBus();

  const refresh = () => {
    refreshCareTeams();
    refreshPractitioners();
  };

  useEffect(() => {
    onEvent('UPDATE_PROVIDERS_SUMMARY', refresh);
    return () => removeEvent('UPDATE_PROVIDERS_SUMMARY', refresh);
  }, []);

  const handleAdd = () => {
    console.log('providers form expanded');
    dispatchEvent(SHOW_SIDEBAR_COMPONENT, <ProvidersForm />);
  };

  const handleEdit = (data: any) => {
    console.log('row', data);
    console.log('providers form edit expanded');
    dispatchEvent(SHOW_SIDEBAR_COMPONENT, <ProvidersForm data={data} />);
  };

  const handleDelete = async (practitioner: Practitioner) => {
    await updateCareTeam(
      produce(careTeams[0], (draft) => {
        draft.participant = careTeams?.[0]?.participant?.filter(
          (p) => p?.member?.reference !== `Practitioner/${practitioner?.id}`
        );
      })
    );
    await updatePractitioner(
      produce(practitioner, (draft) => {
        draft.active = false;
      })
    );
  };

  return (
    <Card>
      <Grid container>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 2 }}
          xs={12}
        >
          <Grid item>
            <Typography variant="subtitle1">Providers</Typography>
          </Grid>
          <Grid item>{<ActionBar onAdd={handleAdd} onCollapse={onCollapse} />}</Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Specialty</TableCell>
                  <TableCell>Last visit</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {careTeams?.[0]?.participant?.filter(
                      (participant) =>
                        participant?.member?.reference?.includes('Practitioner/') 
                        && participant.role?.find(
                          (codConcept) =>
                            codConcept.text === 'PCP' || codConcept.text === 'provider'
                        )
                    ).map((row: any, index: number) => (
                  <ProviderExpandedRow
                    key={`member-${index}`}
                    provider={row}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProvidersExpanded;
