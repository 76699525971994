import { FORESIGHT_IFRAME_URL } from 'src/config';
import useSmartLauncher, { ContextSmartLauncher } from 'src/hooks/useSmartLauncher';

interface IForesightStatsProps {
  iamUserId: string;
  fhirUserId: string;
  patientId: string;
  otherContext?: ContextSmartLauncher;
}

function ForesightStats({
  iamUserId,
  fhirUserId,
  patientId,
  otherContext,
}: IForesightStatsProps): JSX.Element {
  const { isDone, launchUrl, errorMsg } = useSmartLauncher({
    iamUserId,
    fhirUserId: fhirUserId,
    patientId,
    smartAppBaseLaunchUrl: FORESIGHT_IFRAME_URL,
    otherContext,
  });

  if (errorMsg) {
    return <div>{errorMsg}</div>;
  }

  return isDone && launchUrl ? (
    <iframe
      scrolling="no"
      src={launchUrl}
      title="foresights"
      style={{ border: 0, width: '350px', transform: 'scale(0.9)' }}
    />
  ) : (
    <div>Loading ...</div>
  );
}

export default ForesightStats;
