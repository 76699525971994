// TODO: Do we need to strip them? Trying first with showing as HTML content
// import striptags from 'striptags';

export function cleanQuestionText(questionText = '') {
  const cleanText = (questionText || '')
    // remove script tags
    .replace(/ ?<script.*?>.*?<\/script> ?/gis, '')
    // remove html tags
    .trim();

  return cleanText;
}
