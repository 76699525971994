import { useMutation, useQuery } from '@tanstack/react-query';
import * as actions from './actions';

export function useMedicationTransactions(
  medicationRequestId: string,
) {
  return useQuery(['ccm-medication-transactions-by-medication-request', medicationRequestId], () => actions.getMedicationTransactions(medicationRequestId));
}

export function useMedicationTransactionTypes() {
  return useQuery(['ccm-medication-transaction-types'], actions.getMedicationTransactionTypes);
}

export function useCreateMedicationTransaction() {
  return useMutation(actions.createMedicationTransaction)
}
