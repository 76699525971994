// @ts-nocheck
import { MedicationRequest } from "src/@nicheaim/fhir-base/mappings/MedicationRequest";
import { MedicationRequestFormState, PeriodUnitFhirEnum } from "./types";
import moment, { unitOfTime } from "moment";
import { getFhirIdFromReferenceString } from "../../tasks-activities/components/Activities/activities-utils";
import { Bundle, BundleEntry } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
import { defaultState } from "./constants";

export const getDefaultState = (medicationRequest?: MedicationRequest | null) => {
  if (medicationRequest) {
    const state: MedicationRequestFormState = {
      rxnumber: medicationRequest?.identifier?.find(i => i.type?.coding?.find(c => c.code === 'RX'))?.value ?? '',
      barcode: medicationRequest?.identifier?.find(i => i.type?.coding?.find(c => c.code === 'barcode'))?.value ?? '',
      location: medicationRequest?.supportingInformation?.[0] ? {
        label: medicationRequest?.supportingInformation?.[0].display,
        value: getFhirIdFromReferenceString(medicationRequest?.supportingInformation?.[0].reference ?? ''),
      } : null,
      medication: {
        label: medicationRequest?.medicationReference?.display,
        value: getFhirIdFromReferenceString(medicationRequest?.medicationReference?.reference ?? ''),
      },
      reasonForUse: {
        label: medicationRequest.reasonCode?.[0]?.text,
        value: medicationRequest.reasonCode?.[0]?.text, // TODO: coding?.[0].code
      },
      startDate: medicationRequest? moment(medicationRequest?.dispenseRequest?.validityPeriod?.start).toDate() :  moment().toDate(),
      startHour: medicationRequest ? moment(medicationRequest?.dispenseRequest?.validityPeriod?.start)?.format('HH:mm') : moment().format('HH:mm'),
      endDate: medicationRequest? moment(medicationRequest?.dispenseRequest?.validityPeriod?.end).toDate() :  moment().add(1, 'day').toDate(),
      endHour: medicationRequest ? moment(medicationRequest?.dispenseRequest?.validityPeriod?.end)?.format('HH:mm') : moment().format('HH:mm'),
      // requester: {
      //   label: medicationRequest.requester?.display,
      //   value: getFhirIdFromReferenceString(medicationRequest?.requester?.reference ?? ''),
      // },
      requester: medicationRequest.requester?.display ?? '',
      note: medicationRequest.note?.[0]?.text ?? '',
      selfAdministered: medicationRequest.performer ? true : false,
      allowPRN: medicationRequest?.dosageInstruction?.[0]?.asNeededBoolean ?? false,
      refills: medicationRequest.dispenseRequest?.numberOfRepeatsAllowed ?? 1,
      inventory: medicationRequest.dispenseRequest?.initialFill?.quantity?.value ?? 1,
      route: {
        label: medicationRequest.dosageInstruction?.[0].route?.text ?? '',
        value: medicationRequest.dosageInstruction?.[0].route?.coding?.[0]?.code ?? ''
      },
      schedule: medicationRequest.dosageInstruction?.[0]?.timing ? {
        interval_type: medicationRequest.dosageInstruction?.[0]?.timing.repeat?.periodUnit as PeriodUnitFhirEnum,
        interval_amount: medicationRequest.dosageInstruction?.[0]?.timing.repeat?.period,
        frequency: medicationRequest.dosageInstruction?.[0]?.timing.repeat?.frequency,
        times: medicationRequest.dosageInstruction?.map((dosage, index) => ({
          index,
          time: dosage?.timing?.repeat?.timeOfDay?.[0],
          quantity: dosage?.doseAndRate?.[0].rateQuantity?.value ?? 1,
        })) ?? [],
      } : {
        frequency: 1,
        interval_type: PeriodUnitFhirEnum.HOUR,
        interval_amount: 8,
        times: [
          {
            index: 0,
            time: moment().format('HH:mm'),
            quantity: 1,
          },
        ],
      },
    }

    return state;
  }

  return {
    rxnumber: '',
    barcode: '',
    location: null,
    medication: null,
    reasonForUse: null,
    startDate: moment().toDate(),
    startHour: moment().format('HH:mm'),
    endDate: moment().toDate(),
    endHour: moment().add(8, 'hour').format('HH:mm'),
    requester: '',
    note: '',
    selfAdministered: false,
    allowPRN: false,
    refills: 1,
    inventory: 1,
    route: null,
    schedule: {
      frequency: 1,
      interval_type: PeriodUnitFhirEnum.DAY,
      interval_amount: 1,
      times: [
        {
          index: 0,
          time: moment().format('HH:mm'),
          quantity: 1,
        },
      ],
    },
  };
}

export const getMedicationAdministrationsBundle = (medicationRequest: MedicationRequest) => {
  let bundle: Bundle = {
    resourceType: 'Bundle',
    type: "transaction",
    entry: [],
  }
  const startDate = moment(medicationRequest.dispenseRequest?.validityPeriod?.start);
  const endDate = moment(medicationRequest.dispenseRequest?.validityPeriod?.end);
  const addDaysCount = medicationRequest.dosageInstruction?.[0]?.timing?.repeat?.period;
  let reduceUnit: unitOfTime.DurationConstructor = 'days';

  switch (medicationRequest.dosageInstruction?.[0].timing?.repeat?.periodUnit) {
    case PeriodUnitFhirEnum.DAY:
      reduceUnit = 'days';
      break;
    case PeriodUnitFhirEnum.HOUR:
      reduceUnit = 'hours';
      break;
    case PeriodUnitFhirEnum.WEEK:
      reduceUnit = 'week';
      break;
    case PeriodUnitFhirEnum.MONTH:
      reduceUnit = 'months';
      break;
    case PeriodUnitFhirEnum.MINUTE:
      reduceUnit = 'minutes';
      break;
    default:
      break;
  }

  const time = medicationRequest.dosageInstruction?.[0]?.timing?.repeat?.timeOfDay?.[0];

  for (
    let m = moment(startDate).set({
      hour: +time?.split(':')[0],
      minute: +time?.split(':')[1],
      seconds: 0,
    });
    m.isSameOrBefore(endDate);
    m.add(addDaysCount, reduceUnit)
  ) {
    medicationRequest.dosageInstruction?.forEach((dosage, index) => {
      const currentTimeSplitted = dosage.timing?.repeat?.timeOfDay?.[0].split(':') ?? [];
      let currentTime = m;
      if (dosage.timing?.repeat?.periodUnit !== PeriodUnitFhirEnum.HOUR) {
        currentTime = m.clone().set({
          hour: +currentTimeSplitted?.[0],
          minute: +currentTimeSplitted?.[1],
          seconds: 0,
        })
      }
      const entry: BundleEntry = {
        resource: {
          resourceType: 'MedicationAdministration',
          effectiveDateTime: currentTime.toISOString(),
          identifier: [
            {
              type: {
                coding: [
                  {
                    code: 'Location'
                  }
                ],
                text: medicationRequest.supportingInformation?.[0]?.display,
              },
              value: getFhirIdFromReferenceString(medicationRequest.supportingInformation?.[0]?.reference ?? ''),
            },
          ],
          medicationReference: medicationRequest.medicationReference,
          request: {
            display: medicationRequest.identifier?.find(i => i.type?.coding?.find(c => c.code === 'RX'))?.value,
            reference: `MedicationRequest/${medicationRequest.id}`,
          },
          dosage: {
            rateQuantity: {
              value: dosage.doseAndRate?.[0]?.rateQuantity?.value,
            }
          },
          subject: medicationRequest.subject,
          status: 'in-progress',
        },
        request: {
          url: `MedicationAdministration`,
          method: 'POST',
        }
      };

      bundle.entry?.push(entry);
    })
  }

  return bundle;
}