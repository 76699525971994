import { Stack } from '@mui/material';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import { AutomationRule } from '../../store';
import GoalSelector from './GoalSelector';
import InterventionSelector from './InterventionSelector';
import OpportunitySelector from './OpportunitySelector';
import TimeframeSelector from './TimeframeSelector';
import PrioritySelector from './PrioritySelector';

interface AutomationResultSelectorProps {
  question: WrappedQuestionnaireItem;
  rule: AutomationRule;
}

function AutomationResultSelector({ question, rule }: AutomationResultSelectorProps) {
  return (
    <Stack gap={1} maxWidth="md" flex={1}>
      <OpportunitySelector question={question} rule={rule} />
      <PrioritySelector question={question} rule={rule} />
      <GoalSelector question={question} rule={rule} />
      <InterventionSelector question={question} rule={rule} />
      <TimeframeSelector question={question} rule={rule} />
    </Stack>
  );
}

export default AutomationResultSelector;
