import useTable from 'src/hooks/useTable';
import SummaryTable from './SummaryTable';
import { useEffect, useState } from 'react';
import { PaginateQuery } from 'src/api/pagination/dtos';
import { referralService } from 'src/crs/referral/services';
import { TABLE_HEAD_REFERRAL_PATIENT } from 'src/sections/crs/common/table-head';
import { referralDtoToReferralManager } from 'src/sections/crs/common/common-utils';

const URL_API = `${process.env.REACT_APP_FHIR_API_BASE_URL}/Patient`;

export default function PatientReferral({ patientId }: any) {
  const [referralData, setReferralData] = useState<any | undefined | null>(null);
  const [refresh, setRefresh] = useState(false);

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultRowsPerPage: 5,
    defaultOrderBy: 'id',
    initialIndex: 1,
    defaultCurrentPage: 1,
  });

  const getPaginateQuery = () => {
    const paginationQuery: PaginateQuery = { page: page, limit: rowsPerPage };
    paginationQuery.filter = { indexedFhirSubjectRefUri: `${URL_API}/${patientId}` };
    return paginationQuery;
  };

  const getReferralList = async () => {
    const referrals = await referralService.getAll(getPaginateQuery());
    if (referrals) {
      setReferralData(referrals);
    }
  };

  useEffect(() => {
    getReferralList();
  }, [page, rowsPerPage, patientId]);

  useEffect(() => {
    if (refresh) {
      getReferralList();
      setRefresh(false);
    }
  }, [refresh, referralData]);

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(event, newPage + 1);
  };

  const handleRefresh = () => {
    setRefresh(true);
  };

  const data = referralData?.data
    ? referralData.data.map((r: any) => referralDtoToReferralManager(r))
    : [];

  return (
    <SummaryTable
      title="Referrals"
      columns={TABLE_HEAD_REFERRAL_PATIENT}
      data={data}
      page={page}
      count={referralData ? referralData.meta.totalItems : 0}
      rowsPerPage={rowsPerPage}
      workflowType={'REFERRALS_WORKFLOW'}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={onChangeRowsPerPage}
      handleRefresh={handleRefresh}
    />
  );
}
