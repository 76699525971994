import { Route, Routes, useNavigate } from 'react-router';
import NewAssessmentById from './new.$questionnaire';
import NewAssessment from './new';
import AssessmentsTable from './components/AssessmentsTable';
import ViewAssessment from './$assessment.view';
import FillAssessment from './$assessment.fill';
import AssessmentLoadingScreen from './$assessment';
import { useCurrentPatient } from 'src/stores/patient-tabs';

interface AssessmentsIndexProps {
  onZoom?: () => void;
}

export default function AssessmentsIndex({ onZoom }: AssessmentsIndexProps) {
  const navigate = useNavigate();
  const patient = useCurrentPatient();

  return (
    <Routes>
      <Route path="*" element={<AssessmentsTable onZoom={onZoom} />} />
      <Route path="assessments/:assessment" element={<AssessmentLoadingScreen onZoom={onZoom} />} />
      <Route path="assessments/:assessment/view" element={<ViewAssessment onZoom={onZoom} />} />
      <Route
        path="assessments/:assessment/fill"
        element={
          <FillAssessment
            onZoom={onZoom}
            onSurveyCompleted={() => {
              navigate(`/dashboard/patient/${patient?.id}/care-record/assessments`);
            }}
          />
        }
      />
      <Route path="assessments/new" element={<NewAssessment onZoom={onZoom} />} />
      <Route
        path="assessments/new/:questionnaire"
        element={<NewAssessmentById onZoom={onZoom} />}
      />
    </Routes>
  );
}
