import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Label from '../../../../components/Label';
import Iconify from '../../../../components/Iconify';
import { Box, Card, Typography, Step, Stepper, StepLabel, StepConnector, } from '@mui/material';

const STEPS = ['Assign Referral', 'Validate Home Address', 'Valid Program Info', 'Consent Form', 'Client Interview', 'Dispotion', 'Close'];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  top: 10,
  left: 'calc(-50% + 20px)',
  right: 'calc(50% + 20px)',
  '& .MuiStepConnector-line': {
    borderTopWidth: 2,
    borderColor: theme.palette.divider,
  },
  '&.Mui-active, &.Mui-completed': {
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

function QontoStepIcon({ active, completed }: { active: boolean; completed: boolean }) {
  return (
    <Box
      sx={{
        zIndex: 9,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: active ? 'primary.main' : 'text.disabled',
      }}
    >
      {completed ? (

        <Label 
          sx={{ 
            backgroundColor: '#3f51b5', 
            color: 'white',
            borderRadius: 2,
          }} 
        >
          <Iconify
            icon={'eva:checkmark-fill'}
            sx={{ zIndex: 1, width: 20, height: 20, color: 'white' }}
          /> 
        </Label>
        
      ) : (
        
      <Label 
        sx={{ 
          backgroundColor: '#3f51b5', 
          color: 'white',
          borderRadius: 2
        }} 
      />
        /*<Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
          }}
        />*/
      )}
    </Box>
  );
}

export default function ReferralWorkflow(){

  const [ activeStep, setActiveStep ] = useState(0);

  const isComplete = activeStep === STEPS.length;

  return (
    <Card>
      <Typography sx={{ m: 2, p: 2, display: 'block' }}>
        Referral Workflow
      </Typography>

      <Stepper alternativeLabel activeStep={activeStep}>
        {STEPS.map((label) => (
          <Step key={label} >
            <StepLabel >
              {label}
            </StepLabel>
              
          </Step>
        ))}
      </Stepper>

    </Card>
  );
}