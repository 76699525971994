import { useForm } from 'react-hook-form';
import { FormProvider, RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import RHFAutocomplete from 'src/components/hook-form/RHFAutocomplete';

export default function DebugAutocomplate() {
  const form = useForm({});

  console.log('form', form.watch('autocomplete'));

  return (
    <FormProvider methods={form}>
      <RHFAutocomplete
        name="autocomplete"
        label="Autocomplete"
        options={[
          {
            id: 1,
            label: 'Test',
            value: 'test',
          },
          {
            id: 2,
            label: 'Test 2',
            value: 'test2',
          },
          {
            id: 3,
            label: 'Test 3',
            value: 'test3',
          },
        ]}
        getOptionLabel={(option) => `${ typeof option !== "string" && option.id}: ${ typeof option !== "string"  && option.label}`}
      />
      <RHFTextField name="text" label="Text" />
      <RHFCheckbox name="check" label="Check" />
    </FormProvider>
  );
}
