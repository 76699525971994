import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import { QUESTION_TYPES } from '../constants';
import { AutomationRule, useCarePlanAutomationFormRule } from '../store';

interface OperatorValueSelectorProps {
  question: WrappedQuestionnaireItem;
  rule: AutomationRule;
}

function OperatorValueSelector({ question, rule }: OperatorValueSelectorProps) {
  const { questionType, operator } = useCarePlanAutomationFormRule(rule.id);

  if (!questionType || !QUESTION_TYPES[questionType]) {
    return null;
  }

  const { operators } = QUESTION_TYPES[questionType]!;
  const operatorSettings = operators.find((o) => o.value === operator);
  if (!operatorSettings) {
    return null;
  }

  const { component: Component } = operatorSettings;
  if (Component) {
    return <Component question={question} ruleId={rule.id} />;
  }

  return null;
}

export default OperatorValueSelector;
