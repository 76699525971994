import { NoteManager } from 'src/@types/note';
import client from './_client';

export async function getGenericNotes(entityId?: string) {
  const { data } = await client.get(`/notes/${entityId}`);

  return data;
}

export async function createGenericNote(payload: NoteManager, entityId?: string) {
  const { data } = await client.post(`/notes/${entityId}`, payload);

  return data;
}

export async function updateGenericNote(payload: NoteManager, entityId?: string) {
  const { data } = await client.patch(`/notes/${entityId}`, payload);

  return data;
}
