import { 
  Autocomplete, 
  Box, 
  Button,
  CircularProgress,
  FormControl, 
  InputLabel, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableRow, 
  TextField, 
  Typography 
} from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from 'notistack';
import { useMemo, useState } from "react";
import useObjectState from "src/hooks/useObjectState";
import { TableHeadCustom } from "src/components/table";
import { useCodeSystem } from "src/@nicheaim/fhir-react";
import { referralService } from "src/crs/referral/services";
import { GridItem, GridSection } from "src/components/CustomModal";
import { getFullAddress } from "src/sections/crs/common/common-utils";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { ServiceByFiltersResponse } from "src/crs/referral/services/ReferralService";
import { TABLE_HEAD_CHILD_REFERRAL_SEARCH_SERVICE } from "src/sections/crs/common/table-head";
import { Address } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";

type Props = {
  patient?: WrappedPatient | null;
  updateState: any;
}

const SearchServices = ({ patient, updateState }: Props) => {

  const { enqueueSnackbar } = useSnackbar();
  const [state, updateStateFilters ] = useObjectState<any>({});
  const [services, setServices] = useState<any>([]);
  const [serviceType] = useCodeSystem('crs-referral-services');

  const {
    serviceTypeItem,
    postalCode,
    states,
    isLoading
  } = state;

  const referralServiceTypes = useMemo(
    () => serviceType?.concept?.map((type) => ({
      label: type.display,
      value: type.code
    })).sort((x1, x2) => (x1.label && x2.label && x1.label > x2.label ? 1 : x1.label && x2.label && x1.label < x2.label ? -1 : 0)) ?? [],
    [serviceType]
  );

  const postalCodes = useMemo(
    () => patient?.address?.map((address: Address) => ({
      label: address?.postalCode,
      value: address?.postalCode
    })).sort((x1, x2) => (x1.label && x2.label && x1.label > x2.label ? 1 : x1.label && x2.label && x1.label < x2.label ? -1 : 0)) ?? [],
    [patient]
  );

  const statesPatient = useMemo(
    () => patient?.address?.map((address: Address) => ({
      label: address?.state,
      value: address?.state
    })).sort((x1, x2) => (x1.label && x2.label && x1.label > x2.label ? 1 : x1.label && x2.label && x1.label < x2.label ? -1 : 0)) ?? [],
    [patient]
  );

  const validateRequiredFields = () => {
    if (!serviceTypeItem) {
      return false;
    }
    return true;
  };

  const handleSearchServices = async () => {

    if (!validateRequiredFields()) {
      enqueueSnackbar(`Please, fill all required fields`, { variant: 'error' });
      return;
    }

    setServices([]);

    const searchParams = {
      serviceType: serviceTypeItem?.label || serviceTypeItem,
      postalCode: postalCode?.value || postalCode,
      state: states?.value || states,
    };

    updateStateFilters({ isLoading: true });

    try {
      const servicesResponse = await referralService.getServicesByFilters(searchParams);
      if (servicesResponse) {
        setServices(servicesResponse);
      }
    } catch (error) {
      enqueueSnackbar(`An error has occurred.`, { variant: 'error' });
    }
    updateStateFilters({ isLoading: false });
  };

  const handleSelectedService = (data: ServiceByFiltersResponse) => {
    updateState({
      serviceTypeItem: {
        label: `${data?.healthcareService?.type?.[0]?.coding?.[0].code} | ${data?.organization?.name}`,
        value: {
          type: data?.healthcareService?.type?.[0]?.coding?.[0].code,
          healthcareService: data?.healthcareService,
          organizationId: data?.organization?.id
        }
      },
      referredToItem: {
        label: data?.organization?.name,
        value: `${data?.organization?.resourceType}/${data?.organization?.id}`
      },
      locationOrganization: {
        label: data?.location?.name,
        value: {
          locationId: `${data?.location?.resourceType}/${data?.location?.id}`,
          organizationId: `${data?.organization?.resourceType}/${data?.organization?.id}`
        }
      }, 
      openSearch: false
    })
  };
  
  return (
   <>
    <GridSection mt={0}>
      <GridItem xs={12} sx={{ ml: 1 }}>
        <form>
          <InputLabel >Service</InputLabel>
          <InputLabel shrink sx={{ mt: 2 }}>Service Type *</InputLabel>
          <FormControl fullWidth>
            <Autocomplete
              freeSolo
              disableClearable
              options={referralServiceTypes}
              value={serviceTypeItem}
              onChange={(_: any, value: any | null) => {
                if (value && value?.value) {
                  updateStateFilters({ serviceTypeItem: value });
                }
              }}
              onInputChange={(_: any, value: any | null) => {
                updateStateFilters({ serviceTypeItem: value });
              }}
              renderInput={(params) => (
              <TextField
                required
                variant="standard"
                {...params}
              />
              )}
            />
          </FormControl>
          <InputLabel shrink sx={{ mt: 2 }}>Postal Code</InputLabel>
          <FormControl fullWidth>
            <Autocomplete
              freeSolo
              disableClearable
              options={postalCodes}
              value={postalCode}
              onChange={(_: any, value: any | null) => {
                updateStateFilters({ postalCode: value });
              }}
              onInputChange={(_: any, value: any | null) => {
                updateStateFilters({ postalCode: value });
              }}
              renderInput={(params) => (
              <TextField
                required
                variant="standard"
                {...params}
              />
              )}
            />
          </FormControl>
          <InputLabel shrink sx={{ mt: 2 }}>State</InputLabel>
          <FormControl fullWidth>
            <Autocomplete
              freeSolo
              disableClearable
              options={statesPatient}
              value={states}
              onChange={(_: any, value: any | null) => {
                updateStateFilters({ states: value });
              }}
              onInputChange={(_: any, value: any | null) => {
                updateStateFilters({ states: value });
              }}
              renderInput={(params) => (
              <TextField
                required
                variant="standard"
                {...params}
              />
              )}
            />
          </FormControl>
          <Box  mt={2} display={'flex'} justifyContent={'flex-end'}>
            <Button
              variant='contained'
              onClick={handleSearchServices}
            >
              Search
            </Button>
          </Box>
        </form>
      </GridItem>
    </GridSection>
    <GridSection>
      <GridItem xs={12} sx={{ ml: 1 }}>
        <TableContainer component={Paper}>
          <Table
            size="small"
            aria-label="Services table"
            stickyHeader
          >
            <TableHeadCustom headLabel={TABLE_HEAD_CHILD_REFERRAL_SEARCH_SERVICE} />
            <TableBody>
              {!isEmpty(services)? (
                services?.map((row: ServiceByFiltersResponse, index: number) => {
                  return (
                    <TableRow key={'t-row-' + index} >
                      <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
                        {setRow(row?.healthcareService)}
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
                        {setRow(row?.organization)}
                      </TableCell>
                      <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
                        {setRow(row?.location)}
                      </TableCell>
                      <TableCell>
                        {(!isEmpty(row?.healthcareService) &&
                          !isEmpty(row?.organization) &&
                          !isEmpty(row?.location)) && (
                            <Button
                              sx={{
                                height: 36,
                                width: 63,
                              }}
                              onClick={() => {
                                handleSelectedService(row);
                              }}
                              variant="contained"
                            >
                              Select
                            </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <>
                  {isLoading ? (
                    <TableCell colSpan={TABLE_HEAD_CHILD_REFERRAL_SEARCH_SERVICE?.length} rowSpan={6}>
                      <Box  mt={2} display={'flex'} justifyContent={'center'}>
                        <CircularProgress/>
                      </Box>
                    </TableCell>
                  ): (
                    <TableCell colSpan={TABLE_HEAD_CHILD_REFERRAL_SEARCH_SERVICE?.length}>
                      <Typography variant="body2" align="center">No rows</Typography>
                    </TableCell>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </GridItem>
    </GridSection>
   </>
  )
}

const setRow = ( row : any ) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography variant="subtitle1">{row?.name}</Typography>
      </Box>

      <Box>{row?.comment}</Box>

      {row?.address ? <Box>{getFullAddress(row?.address)}</Box> : null}

      {row?.telecom && row?.telecom?.map((t:any, index: number) => (
        <Box key={'box-telecom-' + index}>{t?.value}</Box>
      ))}

    </Box>
  );
}

export default SearchServices;