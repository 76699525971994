import { has } from 'lodash';
import { createWrapper } from '../../fhir-react/base';
import { getTableRowDueTo } from 'src/sections/crs/common/common-utils';

export const ServiceRequestWrapper = createWrapper('ServiceRequest', (serviceRequest) => ({
  getIdFromReference(reference: string): string {
    return reference.split('/')?.[1];
  },

  getCasesIds(): string[] {
    if (serviceRequest?.supportingInfo && serviceRequest.supportingInfo.length > 0) {
      const cases = serviceRequest.supportingInfo
        .filter((s) => has(s, 'reference') && s?.reference)
        .filter((s) => s.reference?.startsWith('CarePlan/'))
        .map((s) => (s.reference ? this.getIdFromReference(s.reference) : ''));
      return cases;
    }
    return [];
  },

  hasCases(): boolean {
    return this.getCasesIds().length > 0;
  },

  hasHealthcareServices(): boolean {
    return this.getHealthcareServices().length > 0;
  },

  getDocumentReferenceIds(): string[] {
    if (serviceRequest?.supportingInfo && serviceRequest.supportingInfo.length > 0) {
      const documents = serviceRequest.supportingInfo
        .filter((s) => has(s, 'reference') && s?.reference)
        .filter((s) => s.reference?.startsWith('DocumentReference/'))
        .map((s) => (s.reference ? this.getIdFromReference(s.reference) : ''));
      return documents;
    }
    return [];
  },

  getHealthcareServiceIds(): string[] {
    if (serviceRequest?.performer && serviceRequest?.performer.length > 0) {
      const healthcareServices = serviceRequest.performer
        .filter((s) => has(s, 'reference') && s?.reference)
        .filter((s) => s.reference?.startsWith('HealthcareService/'))
        .map((s) => (s.reference ? this.getIdFromReference(s.reference) : ''));
      return healthcareServices;
    }
    return [];
  },

  getHealthcareServices(): any[] {
    if (serviceRequest?.performer && serviceRequest?.performer.length > 0) {
      const healthcareServices = serviceRequest.performer
        .filter(
          (s) => s.reference?.startsWith('HealthcareService/') || s.type === 'HealthcareService'
        );
      return healthcareServices;
    }
    return [];
  },

  getPastDue(): string {
    const result = getTableRowDueTo(
      new Date(serviceRequest?.occurrencePeriod?.end || ''),
      serviceRequest?.status
    ).subStatus;
    return result;
  },
  getPerfomerByType(type: string) {
    if (serviceRequest?.performer && serviceRequest?.performer.length > 0) {
      const performer = serviceRequest.performer
        .find((s) => s.type === type);
      return performer;
    }
    return null;
  }
}));
export type WrappedServiceRequest = ReturnType<typeof ServiceRequestWrapper>;
