import { MultiWrapper, Wrapper } from './Wrapper';
import { Card, Grid } from '@mui/material';

export const Section = ({ children, fullWidth }: Wrapper) => (
  <Grid item xs={fullWidth ? 12 : 6}>
    <Card
      sx={{
        height: '100%',
        padding: '10px',
        boxShadow: 3,
        elevation: 25,
      }}
    >
      {children}
    </Card>
  </Grid>
);

export const TwoColumnLayout = ({ children }: MultiWrapper) => (
  <Card sx={{ boxShadow: 'none' }}>
    <Grid container spacing={2} sx={{ p: 3 }}>
      {children}
    </Grid>
  </Card>
);
