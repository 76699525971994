import { IconButton } from '@mui/material';
import AssessmentsSelectorForm from './components/AssessmentsSelectorForm';
import Iconify from 'src/components/Iconify';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import { useNavigate } from 'react-router';

interface NewAssessmentProps {
  onZoom?: () => void;
}

export default function NewAssessment({ onZoom }: NewAssessmentProps) {
  const patient = useCurrentPatient();
  const navigate = useNavigate();

  return (
    <AssessmentsSelectorForm
      actionBar={
        <>
          <IconButton>
            <Iconify icon="eva:search-fill" />
          </IconButton>
          <IconButton onClick={() => onZoom?.()}>
            <Iconify icon="eva:expand-fill" />
          </IconButton>
          <IconButton
            onClick={() => navigate(`/dashboard/patient/${patient?.id}/care-record/assessments`)}
          >
            <Iconify icon="material-symbols:close-rounded" />
          </IconButton>
        </>
      }
    />
  );
}
