import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';
import useTable from 'src/hooks/useTable';
import sortByFields from 'sort-by';
import TextMaxLine from '../TextMaxLine';
import useIntersect from 'src/hooks/useIntersect';

export interface DataTableProps<T = any> {
  columns: DataTableColumn[];
  data: T[];
  sortBy?: string[];
  noContextMenu?: any;
  selectable?: boolean;
  clickeable?: boolean | ((row: T, index: number) => boolean);
  emptyMessage?: string | React.ReactNode;
  rowContextMenu?: (item: T) => React.ReactNode;
  fetchMore?: () => T[] | Promise<T[]> | void | Promise<void>;
  onMoreRows?: (rows: T[]) => void;
  renderRow?: (columns: DataTableColumn[], row: T, index: number) => React.ReactNode;

  /**
   * Function used to extract the key for every row. If no extractor is
   * specified will use index, which should be avoided.
   */
  keyExtractor?: (item: T, index: number) => string;

  onItemClick?: (item: T, index: number) => void;
  onItemEdit?: (item: T, index: number) => void;
  sx?: SxProps;
}

export interface DataTableColumn {
  header: string;
  field?: string;
  visible?: boolean;
  render?: (value: any, row: any) => any;
  align?: TableCellProps['align'];
  size?: TableCellProps['size'];
  width?: string | number;
  maxLines?: number;
}

function DataTableBase<T = any>({
  data = [],
  sortBy = [],
  keyExtractor,
  selectable,
  clickeable,
  emptyMessage = 'No data',
  columns,
  noContextMenu,
  rowContextMenu,
  onItemClick,
  fetchMore,
  onMoreRows,
  renderRow,
}: DataTableProps<T>) {
  const table = useTable();
  const [fetchingMore, setFetchingMore] = useState(false);
  const visibleColumns = columns.filter((column) => column.visible ?? true);
  const hasAllSelected = table.selected.length === data.length;
  const hasSelected = table.selected.length > 0 && table.selected.length < data.length;

  const { firstRef, secondRef } = useIntersect({
    onChange: ({ isIntersecting }) => {
      if (!isIntersecting) return;

      loadMoreData();
    },
  });

  function getRowId(row: any, index: number) {
    return keyExtractor ? keyExtractor(row, index) : `row-${index}`;
  }

  function handleSelectAll() {
    table.setSelected(hasAllSelected ? [] : data.map(getRowId));
  }

  function handleSelectRowFor(id: string) {
    return function handleSelectRow(event: React.MouseEvent<HTMLElement>) {
      event.stopPropagation();

      if (table.selected.includes(id)) {
        table.setSelected(table.selected.filter((row) => row !== id));
      } else {
        table.setSelected([...table.selected, id]);
      }
    };
  }

  function isClickeable(item: any, index: number) {
    if (!clickeable) {
      return false;
    }

    if (typeof clickeable === 'function') {
      return clickeable(item, index);
    }

    return true;
  }

  function handleClickRow(item: any, index: number) {
    if (isClickeable(item, index)) {
      onItemClick?.(item, index);
    }
  }

  async function loadMoreData() {
    setFetchingMore(true);
    const newData = (await fetchMore?.()) || [];
    onMoreRows?.(newData);
    setFetchingMore(false);
  }

  return (
    <TableContainer sx={{ maxHeight: '100%' }} ref={firstRef}>
      <Table stickyHeader>
        {columns && (
          <TableHead>
            <TableRow>
              {selectable && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={hasAllSelected}
                    indeterminate={hasSelected}
                    onClick={handleSelectAll}
                  />
                </TableCell>
              )}

              {visibleColumns.map((column, index) => (
                <TableCell key={column.field === '$' ? index : `${column.field}-${index}`}>
                  {column.header}
                </TableCell>
              ))}
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
        )}

        <TableBody>
          {data.length ? (
            data.sort(sortByFields(...sortBy)).map((row, index) => {
              const id = getRowId(row, index);
              const selected = table.selected.includes(id);
              const canBeClicked = isClickeable(row, index);

              return (
                <DataTableRow
                  key={id}
                  hover
                  selected={selected}
                  onClick={() => handleClickRow(row, index)}
                  sx={{ cursor: canBeClicked ? 'pointer' : undefined }}
                >
                  {selectable && (
                    <DataTableCell padding="checkbox">
                      <Checkbox checked={selected} onClick={handleSelectRowFor(id)} />
                    </DataTableCell>
                  )}

                  {visibleColumns.map((column, index) => (
                    <DataTableCell
                      key={index}
                      align={column.align}
                      size={column.size}
                      //  TODO: Ideally, column.width would be a number which would work like flex-basis
                      //  but it's not supported by the TableCell component
                      sx={{ width: column.width }}
                    >
                      {column.render ? (
                        column.render(
                          column.field
                            ? column.field === '$'
                              ? row
                              : _.get(row, column.field)
                            : null,
                          row
                        )
                      ) : (
                        <TextMaxLine
                          line={column.maxLines ?? 3}
                          title={
                            column.field
                              ? column.field === '$'
                                ? row
                                : _.get(row, column.field)
                              : ''
                          }
                        >
                          {column.field
                            ? column.field === '$'
                              ? row
                              : _.get(row, column.field)
                            : ''}
                        </TextMaxLine>
                      )}
                    </DataTableCell>
                  ))}

                  {noContextMenu === true ? null : (
                    <DataTableCell
                      align="right"
                      size="small"
                      // Stop propagation to avoid triggering the row click
                      onClick={(e) => e.stopPropagation()}
                    >
                      {rowContextMenu?.(row)}
                    </DataTableCell>
                  )}
                </DataTableRow>
              );
            })
          ) : (
            <DataTableRow>
              <DataTableCell colSpan={visibleColumns.length + 2}>
                <Typography variant="body2" align="center">
                  {emptyMessage}
                </Typography>
              </DataTableCell>
            </DataTableRow>
          )}
        </TableBody>
      </Table>

      {fetchMore &&
        (fetchingMore ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
            <Box ref={secondRef} sx={{ m: 3 }} />
          </Box>
        ))}
    </TableContainer>
  );
}

export default class DataTable<T = any> extends React.Component<DataTableProps<T>> {
  state = {
    error: null as Error | null,
  };

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  render() {
    if (this.state?.error) {
      console.error('DataTable', this.state.error);
      return <Alert color="error">{this.state.error.message}</Alert>;
    }

    return <DataTableBase {...this.props} />;
  }
}

class DataTableRow extends React.Component<TableRowProps> {
  state = {
    error: null as Error | null,
  };

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  render() {
    if (this.state?.error) {
      console.error('DataTableRow', this.state.error);
      return (
        <TableRow>
          <DataTableCell colSpan={1000}>
            <Alert color="error">{this.state.error.message}</Alert>
          </DataTableCell>
        </TableRow>
      );
    }

    return <TableRow {...this.props} />;
  }
}

class DataTableCell extends React.Component<TableCellProps> {
  state = {
    error: null as Error | null,
  };

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  render() {
    if (this.state?.error) {
      console.error('DataTableCell', this.state.error);
      return (
        <TableCell colSpan={this.props.colSpan}>
          <Alert color="error">{this.state.error.message}</Alert>
        </TableCell>
      );
    }

    return <TableCell {...this.props} />;
  }
}
