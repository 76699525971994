import { Box, Typography } from '@mui/material';
import QuestionTitle from './QuestionTitle';
import QuestionFooter from './QuestionFooter';
import QuestionRules from './QuestionRules';
import { QUESTION_TYPES } from '../constants';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';

export interface QuestionProps {
  question: WrappedQuestionnaireItem;
}

export default function Question({ question }: QuestionProps) {
  if (question.type === 'group') {
    return (
      <Box>
        <QuestionTitle question={question} />
        <GroupSubquestions question={question} />
      </Box>
    );
  }

  if (question.type === 'display') {
    return (
      <Box>
        <QuestionTitle question={question} />
      </Box>
    );
  }

  if (QUESTION_TYPES[question.type]) {
    // hide empty questions
    if (!question.text) {
      return null;
    }

    return (
      <Box>
        <QuestionTitle question={question} />
        <QuestionRules question={question} />
        <QuestionFooter question={question} />
      </Box>
    );
  }

  // A question that has not been identified in the
  return (
    <Box>
      <QuestionTitle question={question} />

      <Typography variant="body1" pl={2} color="GrayText">
        Unsupported question type: {question.type}.
      </Typography>
    </Box>
  );
}

function GroupSubquestions({ question }: QuestionProps) {
  return (
    <Box pl={2}>
      {question.item?.map((question, index) => (
        <Question key={index} question={question} />
      ))}
    </Box>
  );
}
