import { useContext, useMemo } from 'react';
import {
  Box,
  Modal,
  Divider,
  Typography,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
} from '@mui/material';
import { getTableHeaders, SectionEnum } from '../constants';
import IntervalDetailItem from '../IntervalDetailItem/IntervalDetaiIItem';
import { IntervalDetailItemContext } from '../IntervalDetailItem/IntervalDetailItemContext';
import { getFhirIdFromReferenceString } from 'src/sections/careflow/tasks-activities/components/Activities/activities-utils';
import PatientWidgets from './PatientWidgets';

type IntervalDetailSectionsModalType = {
  visible?: boolean;
  onClose?: () => void;
  section?: SectionEnum;
};

const IntervalDetailSectionsModal = ({
  visible = false,
  onClose = () => {},
  section,
}: IntervalDetailSectionsModalType) => {
  const { item, sections, onCompletedItemCallback } = useContext(IntervalDetailItemContext);
  const columns = getTableHeaders();
  const patient = useMemo(
    () => ({
      id: item?.bedAssignment.patient?.fhir_id,
    }),
    [item?.bedAssignment.patient?.fhir_id]
  );

  return (
    <Modal
      open={visible}
      onClose={onClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '80%',
            overflow: 'scroll',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 2,
            p: 4,
          }}
        >
          <Box
            sx={{
              my: 3,
              backgroundColor: 'white',
              opacity: 1,
              zIndex: 9,
              top: '-25px',
            }}
          >
            <Table>
              {columns && (
                <TableHead>
                  <TableRow>
                    {columns.map((col: any, index: number) => (
                      <TableCell style={{ width: col.width }} key={index}>
                        <Typography>{col.label}</Typography>
                      </TableCell>
                    ))}
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {item && (
                  <IntervalDetailItem
                    item={item}
                    showSections={false}
                    onCompletedItemCallback={onCompletedItemCallback}
                  />
                )}
              </TableBody>
            </Table>
          </Box>
          <Divider sx={{ marginBottom: 2 }} />
          <PatientWidgets
            section={section}
            sections={sections}
            patient={patient}
          />
        </Box>
      </>
    </Modal>
  );
};

export default IntervalDetailSectionsModal;
