// @ts-nocheck
import moment from "moment";
import { AddGroupSessionStateType } from "./types";

export const defaulState: AddGroupSessionStateType = {
  sessionName: '',
  description: '',
  groupSessionItem: null,
  startDate: moment().utc().format('YYYY-MM-DD'),
  endDate: moment().utc().add(1, 'day').format('YYYY-MM-DD'),
  schedules: [],
  assessmentItems: [],
  locationItems: [],
  peopleItems: [],
  patients: [],
  locations: [],
  loadingPatients: true,
};