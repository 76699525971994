// @ts-nocheck
import {
  Box,
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from '@mui/material';
import produce from 'immer';
import { useEffect, useState } from 'react';
import {
  useCareTeams,
  useEncounters,
  usePractitioners,
} from 'src/@nicheaim/fhir-react';
import {
  CareTeamParticipant,
  Practitioner,
  Reference,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import Iconify from 'src/components/Iconify';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import { getTimeSince, shortFormat } from 'src/utils/formatTime';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { formatAddress } from '../../health-record/patient-info-header/components/utils';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import { getFhirIdFromReferenceString } from '../../tasks-activities/components/Activities/activities-utils';
import { ActionBar } from '../../common';
import ProvidersForm from './ProvidersForm';
import AddModal from '../../common/AddModal';

interface ProvidersProps {
  onExpand?: () => void;
}
interface ProviderRowProps {
  onDelete: (data: any) => void;
  onEdit: (data: any) => void;
  provider: any;
}

const ProviderRow = ({ onDelete, onEdit, provider }: ProviderRowProps) => {
  const patient = useCurrentPatient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const practitionerId = provider?.member?.reference?.split('/')?.[1];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [practitioner, setPractitioner] = useState<any | null>(null)
  const [role, setRole] = useState<any | null>([])
  const [encounters] = useEncounters({
    filter: {
      patient: patient?.id,
      participant: provider?.member?.reference,
    },
  });
  const validDate = encounters?.[0]?.period?.start;
  const lastVisit = validDate ? shortFormat(new Date(encounters[0].period.start)) : '';
  const daysSince = validDate ? getTimeSince(new Date(encounters[0].period.start)) : '';

  const handleEdit = () => {
    onEdit({
      provider,
      practitioner,
      role,
      encounter: encounters?.[0],
    });
    closeMenu();
  };

  const handleDelete = async () => {
    onDelete(practitioner);
    closeMenu();
  };

  const renderPhoneNumbers = (currentPractitioner: any) =>
    currentPractitioner
      ?.getPhones()
      ?.filter((p) => p.value)
      .map((p, index: number) => (
        <Typography variant="caption" key={`phones-${index}`}>
          {`${index > 0 ? '/' : ''}${p?.value} (${p?.use})`}
        </Typography>
      ));

  const renderEmails = (currentPractitioner: any) =>
    currentPractitioner
      ?.getEmails()
      ?.filter((p) => p.value)
      .map((e, index: number) => (
        <Typography variant="caption" key={`emails-${index}`}>
          {`${index > 0 ? '/' : ''}${e?.value}`}
        </Typography>
      ));

  useEffect(() => {
    setIsLoading(true);
    if (!isLoading) {
      Promise.all([
        axiosFhirInstance
        .get(`/Practitioner/${practitionerId}`)
        .then(({ data }) => {
          setPractitioner(PractitionerWrapper(data));
        })
        .catch((error) =>
          console.log({
            error,
          })
        ),

      axiosFhirInstance
        .get(`/PractitionerRole?practitioner=${practitionerId}`)
        .then(({ data }) => {
          setRole(data.entry?.map(r => r.resource) ?? []);
        })
        .catch((error) =>
          console.log({
            error,
          })
        )
      ]).then(() => {
        setIsLoading(false)
      })
    }
  }, [provider]);

  return (
    <TableRow>
      <TableCell>
        <Stack sx={{ flex: 1, flexDirection: 'row' }}>
          <Grid flexDirection="row">
            <Stack alignItems="center" direction="row" justifyContent="flex-start">
              {provider?.role?.find(t => t.text === 'PCP') ? (
                <Iconify icon="eva:star-fill" sx={{ mr: '5px' }} />
              ) : null}
              <Typography variant="subtitle2">{practitioner?.getFullNameWithTitles()}</Typography>
            </Stack>
          </Grid>
          <Grid>{isLoading && <CircularProgress size={15} />}</Grid>
        </Stack>
        <Stack>
          {role && (
            <Typography variant="caption">
              {role?.[0]?.specialty?.[0].coding?.[0]?.display}
            </Typography>
          )}
          {renderEmails(practitioner)}
          {renderPhoneNumbers(practitioner)}
          {practitioner?.address && practitioner?.address?.length > 0 && (
            <Typography variant="caption">{formatAddress(practitioner?.address?.[0])}</Typography>
          )}
        </Stack>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{lastVisit}</Typography>
        {validDate ? <Typography variant="body2">({daysSince})</Typography> : null}
      </TableCell>
      <TableCell align="right">
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleEdit}>
            <Stack direction="row" spacing={2}>
              <Iconify icon="eva:edit-fill" />
              <Typography variant="body2">Edit</Typography>
            </Stack>
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <Stack direction="row" spacing={2}>
              <Iconify icon="eva:trash-2-outline" />
              <Typography variant="body2">Delete</Typography>
            </Stack>
          </MenuItem>
        </Menu>
        <IconButton onClick={openMenu}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const Providers = ({ onExpand }: ProvidersProps) => {
  const [showAddProviderForm, setShowAddProviderForm] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<any | null>(null);
  const patient = useCurrentPatient();
  const [participants, setParticipants] = useState<CareTeamParticipant[]>([]);
  const [careTeams, { update: updateCareTeam }] = useCareTeams({
    filter: {
      patient: patient?.id,
    },
  });
  const [, { update: updatePractitioner }] = usePractitioners();

  useEffect(() => {
    setParticipants(careTeams?.[0]?.participant ?? [])
  }, [careTeams]);

  const handleAdd = () => {
    setSelectedProvider(null);
    setShowAddProviderForm(true)
  };

  const handleEdit = (data: any) => {
    setSelectedProvider(data);
    setShowAddProviderForm(true)
  };

  const handleDelete = async (practitioner: Practitioner) => {
    await updateCareTeam(
      produce(careTeams[0], (draft) => {
        draft.participant = careTeams?.[0]?.participant?.filter(
          (p) => p?.member?.reference !== `Practitioner/${practitioner?.id}`
        );
      })
    );
    await updatePractitioner(
      produce(practitioner, (draft) => {
        draft.active = false;
      })
    );
  };

  const onUpdateProviderCallback = (practitionerReference?: Reference) => {
    setParticipants((prevParticipants) => {
      const newData = prevParticipants.map((participant: CareTeamParticipant) => {
        if (
          getFhirIdFromReferenceString(participant.member?.reference ?? '') ===
          getFhirIdFromReferenceString(practitionerReference?.reference ?? '')
        ) {
          return {
            ...participant,
          };
        }

        return participant;
      });

      return newData;
    });
  };

  const closeAddProviderForm = () => {
    setSelectedProvider(null);
    setShowAddProviderForm(false)
  };

  return (
    <Card>
      <AddModal
        open={showAddProviderForm}
        onClose={closeAddProviderForm}
        Component={<ProvidersForm closeForm={closeAddProviderForm} data={selectedProvider} onSaveCallback={onUpdateProviderCallback} />}
      />
      <Grid container>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 2 }}
          xs={12}
        >
          <Grid item>
            <Typography variant="h6">Providers</Typography>
          </Grid>
          <Grid item>{<ActionBar onAdd={handleAdd} onExpand={onExpand} />}</Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: '400px', overflow: 'auto' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Last visit</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {participants
                    ?.filter(
                      (participant) =>
                        participant.member?.reference.includes('Practitioner/') 
                        && participant.role?.find(
                          (codConcept) =>
                            codConcept.text === 'PCP' || codConcept.text === 'provider'
                        )
                    )
                    .map((row: any, index: number) => (
                      <ProviderRow
                        key={`member-${index}`}
                        provider={row}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Providers;
