import { Alert } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useOrganizations } from 'src/@nicheaim/fhir-react';
import Label from 'src/components/Label';
import DataTable, { DataTableColumn } from 'src/components/table/DataTable';
import fhirSystem from 'src/fhir/system';
import PlansToolbar from './PlansToolbar';

const columns: DataTableColumn[] = [
  {
    header: 'Plan Name',
    field: 'name',
  },
  {
    header: 'Status',
    field: 'active',
    render(active = true) {
      return <Label color={active ? 'success' : 'info'}>{active ? 'Active' : 'Inactive'}</Label>;
    },
  },
];

export default function PlansTable() {
  const navigate = useNavigate();
  const { client = '' } = useParams();
  if (!client) throw new Error('Client id is required');

  const [plans] = useOrganizations({
    filter: {
      identifier: fhirSystem.healthPlan.withId(client).forCode(''),
    },
  });

  return (
    <div>
      <PlansToolbar />

      {plans.length ? (
        <DataTable
          selectable
          clickeable
          columns={columns}
          data={plans}
          onItemEdit={(item) => navigate(`${item.id}/edit`)}
          onItemClick={(item) =>  {
            // TODO: declare better, tried to hot fix.
            if (item.id) {
              navigate(item.id)
            }
          }}
          sx={{ m: 2 }}
        />
      ) : (
        <Alert severity="info" sx={{ m: 2 }}>
          No plans found
        </Alert>
      )}
    </div>
  );
}
