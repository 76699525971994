export type FhirTimeframeDuration = 'd' | 'wk' | 'mo' | 'a';
export function sumFhirDuration(date: Date, time: number, unit: FhirTimeframeDuration) {
  const newDate = new Date(date);

  switch (unit) {
    case 'd':
      newDate.setDate(newDate.getDate() + time);
      break;
    case 'wk':
      newDate.setDate(newDate.getDate() + time * 7);
      break;
    case 'mo':
      newDate.setMonth(newDate.getMonth() + time);
      break;
    case 'a':
      newDate.setFullYear(newDate.getFullYear() + time);
      break;
  }

  return newDate;
}

export function isFhirTimeframeDuration(unit: string): unit is FhirTimeframeDuration {
  return ['d', 'wk', 'mo', 'a'].includes(unit as FhirTimeframeDuration);
}
