import { useState } from 'react';
// components
import Iconify from '../../../../components/Iconify';
import { Stack, InputAdornment, TextField, Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function OpportunitiesToolbar() {
  const [search, setSearch] = useState('');

  return (
    <Stack
      spacing={2}
      direction={{ xs: 'column', sm: 'row' }}
      sx={{ py: 2.5, px: 3 }}
      justifyContent="space-between"
    >
      <TextField
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        placeholder="Search opportunity..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />

      <Box>
        <IconButton>
          <Iconify icon="bi:file-earmark-excel-fill" />
        </IconButton>

        <IconButton>
          <Iconify icon="bi:filter" />
        </IconButton>

        <Link to="new">
          <IconButton>
            <Iconify icon="eva:plus-fill" />
          </IconButton>
        </Link>
      </Box>
    </Stack>
  );
}
