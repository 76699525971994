import {
  TableContainer,
  Typography,
  Grid,
  Table,
  Card,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Box,
  TablePagination,
} from "@mui/material";
import { shortFormat, shortHourFormat } from "src/utils/formatTime";
import moment from 'moment';
import Iconify from "src/components/Iconify";
import Rounding from "../../Model/Rounding";
import Interval from "../../Model/Interval";
import IntervalDetail from "../../Model/IntervalDetail";
import { useLocations } from "src/@nicheaim/fhir-react";
import { RoundingContext } from "../../roundingContext";
import { useContext } from "react";
import { PaginationType } from "../../controller";

type SearchResultListPropsType = {
  isLoading: boolean;
  intervals: Interval[];
  pagination: PaginationType;
  onSelectInterval: (rounding: Rounding, interval: Interval) => void;
}

export const SearchResultList = ({
  isLoading,
  intervals,
  onSelectInterval,
  pagination,
}: SearchResultListPropsType) => {
  const [locations] = useLocations();
  const roundingContext = useContext(RoundingContext);

  console.log({
    pagination
  })

  return (
    <Card sx={{ marginBottom: 2 }}>
      <Grid sx={{ padding: 3, paddingBottom: 1 }}>
        <Typography variant="h5">Intervals</Typography>
      </Grid>
      <TableContainer sx={{ padding: 2 }}>
        {isLoading && (
          <Box textAlign={'center'} >
            <CircularProgress />
          </Box>
        )}
  
        {console.log({
          intervals
        })}
        
        {!isLoading && intervals.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>
                    Location
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    Category
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    Start
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    End
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    Patients
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    Status
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>
                    <Typography>
                      Completed date
                    </Typography>
                  </Typography>
                </TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {intervals.map(interval => (
                <TableRow key={interval.id}>
                  <TableCell>
                    <Typography>
                      {locations?.find(location => location.id === interval.rounding.locationFhirId)?.name ?? 'N/A'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>
                      {interval.rounding.category.valueDisplayName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {`
                      ${
                        shortFormat(moment(interval.start).toDate())
                      }, ${
                        moment(interval.start).format('h:mm A')
                      }
                    `}
                  </TableCell>
                  <TableCell>
                    {`
                      ${
                        shortFormat(moment(interval.end).toDate())
                      }, ${
                        moment(interval.end).format('h:mm A')
                      }
                    `}
                  </TableCell>
                  <TableCell>
                    {interval.intervalDetails.length}
                  </TableCell>
                  <TableCell>
                    {
                      interval.intervalDetails.every((intervalDetail: IntervalDetail) =>
                      intervalDetail.isCompleted)
                      ? 'completed' : 'incomplete'
                    }
                  </TableCell>
                  <TableCell>
                    {interval.completedDatetime ? shortFormat(moment(interval.completedDatetime).toDate()) : 'N/A'}
                  </TableCell>
                  <TableCell>
                    <Iconify
                      icon="material-symbols:open-in-new"
                      style={{
                        width: 30,
                        height: 30,
                        cursor: 'pointer'
                      }}
                      onClick={() => onSelectInterval(interval.rounding, interval)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
  
        {!isLoading && intervals.length === 0 && (
          <TableContainer sx={{ padding: 2 }}>
            <Grid
              item
              justifyItems={'center'}
              alignContent="center"
              alignItems={"center"}
            >
              <Typography
                variant="h6"
                textAlign="center"
              >
                No intervals
              </Typography>
            </Grid>
          </TableContainer>
        )}
      </TableContainer>
      <TablePagination
        component="div"
        count={pagination?.total ?? 0}
        page={pagination?.page ?? 0}
        onPageChange={(_, newPage: number) => roundingContext.getEntities({
          take: pagination?.take ?? 10,
          page: newPage,
        })}
        rowsPerPage={pagination?.take ?? 0}
        rowsPerPageOptions={[]}
        labelDisplayedRows={() =>
          `Showing ${intervals?.length ?? 0} intervals | Page ${
            (pagination?.total ?? 0) > 0 ? pagination?.page + 1 : 0
          } of ${Math.ceil((pagination?.total ?? 0) / pagination?.take)}`
        }
        showFirstButton
        showLastButton
      />
    </Card>
  );
}

export default SearchResultList;