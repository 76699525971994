import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import { DocumentReference } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

export const getAttachmentsContextMenuItems = (updateState: any) => {
  const onDeletePress = async (attachment: DocumentReference, popupState: any) => {
    updateState({
      showDeleteAttachmentModal: true,
      selectedAttachment: attachment,
    });
    popupState.close();
  };

  const onEditPress = (attachment: DocumentReference) => {
    updateState({
      selectedAttachment: attachment,
      showAddAttachmentModal: true,
    });
  };

  const onDownloadPress = async (attachment: DocumentReference) => {
    const binaryId = attachment.content?.[0]?.attachment.url?.split('/').pop();

    const { data } = await axiosFhirInstance.get(`/Binary/${binaryId}`);

    if (!data) return;

    var element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:${attachment.content?.[0]?.attachment.contentType};base64,${data.data}`
    );
    element.setAttribute('download', `filename.${data.contentType?.split('/')[1]}`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  return [
    {
      label: 'Edit',
      icon: 'eva:edit-2-outline',
      onPress: onEditPress,
      rules: () => true,
    },
    {
      label: 'Delete',
      icon: 'bi:trash',
      onPress: onDeletePress,
      rules: () => true,
    },
    {
      label: 'Download',
      icon: 'bi:download',
      onPress: onDownloadPress,
      rules: () => true,
    },
  ];
};
