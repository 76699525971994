import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { WrapperOrganization } from 'src/@nicheaim/fhir-base/wrappers/Organization';
import Iconify from "src/components/Iconify";

type Props = {
  organization: WrapperOrganization;
  index: number;
  isSelected: boolean;
  onSelect: VoidFunction;
}; 

export default function AssignmentsList ({ organization, index, isSelected, onSelect }: Props) { 

  const alphabet = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ');

  const handleClick = ( identifier: any, selected: boolean ) => {
    if(identifier !== '' && selected){
      onSelect();
    }
  }

  return (
    <>
      <Grid container sx={{ m: 1 }}>
        <Grid item xs={12} md={7} sx={{ display: 'flex' }} >
          <div>
            <Typography variant="subtitle2">
              {organization.name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {organization.address?.[0].line}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {organization.getPrimaryAddressFormatted()}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {organization.address?.[0].country}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={1} sx={{ alignItems: 'center' }}>  
        { organization.address?.[0].extension?.[0].extension !== undefined &&  
            <Box
              sx={{
                my: 5,
                ml: 1,
                width: 24,
                height: 24,
                color: 'white',
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: '#0d9f6a',
              }}
            >
              {alphabet[index]}
            </Box>
        } 
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              mt: 4,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Button  
              onClick={() => handleClick(index, true)}
              variant={isSelected ? 'text' : 'outlined'}
              color={isSelected ? 'primary' : 'inherit'}
              startIcon={isSelected && <Iconify icon={'eva:checkmark-fill'} />} 
            > 
              {isSelected ? 'Selected' : 'Select This'}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider />
    </>

  )
}