import { useState } from 'react';
import useObjectState from './useObjectState';
import {
  GridFilterDrawerProps,
  GridFilterProps,
} from '../sections/crs/case/components/DataGridWithFilters/DataGridWithFilters';

export interface GridFiltersData<T> extends Omit<GridFilterDrawerProps<T>, 'filterValues'> {
  filters: T;
}

export type SearchFieldType = GridFilterProps['searchTextFieldValue'];

export interface GridFilters<T> {
  filters: T;
  searchTextFieldValue: SearchFieldType;
}

const useGridFilters = <T>(
  initialFiltersState: T,
  initialSearchFieldValue: SearchFieldType = null
): GridFiltersData<T> => {
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false);
  const [filters, updateFilters] = useObjectState<T>(initialFiltersState);
  const [searchTextFieldValue, setSearchTextFieldValue] =
    useState<SearchFieldType>(initialSearchFieldValue);

  const onSearchTextFieldChange = (value: string) => {
    setSearchTextFieldValue(value);
  };

  const onFilterDrawerOpen = () => {
    setIsFilterDrawerOpen(true);
  };

  const onFilterDrawerClose = () => {
    setIsFilterDrawerOpen(false);
  };

  const onApplyFilters = (filters: Partial<T>) => {
    updateFilters({ ...filters });
    setIsFilterDrawerOpen(false);
  };

  return {
    isFilterDrawerOpen,
    onFilterDrawerOpen,
    onFilterDrawerClose,
    onSearchTextFieldChange,
    filters,
    onApplyFilters,
    searchTextFieldValue,
  };
};

export default useGridFilters;
