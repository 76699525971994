import { useQuery, useMutation } from '@tanstack/react-query';
import * as actions from './actions';

export function useCensusBeds(params: actions.getBedsPropsType) {
    return useQuery(['get-census-beds'], () => actions.getBeds(params));
}

export function useCensusEmptyBeds(params: actions.getEmptyBedsPropsType) {
    return useQuery(['get-census-empty-beds'], () => actions.getEmptyBeds(params));
}

export function useBedExitDestinations() {
    return useQuery(['get-census-exit-destinations'], () => actions.getBedExitDestinations());
}
  

export function useGetCurrentBedAssignment(patientFhirId: string) {
    return useQuery(['get-current-bed-assignment'], () => actions.getCurrentBedAssignment(patientFhirId))
}

export function useGetLocationHistory(patientFhirId: string) {
    return useQuery(['get-location-history'], () => actions.getLocationHistory(patientFhirId))
}

export function useCensusSaveBed() {
    return useMutation(actions.censusSaveBed)
}

export function useCensusTransferBed() {
    return useMutation(actions.transferBed)
}