import { useMemo } from 'react';
import { useCodeSystem } from 'src/@nicheaim/fhir-react';

export function useActivityTypes() {
  const [codeSsytem, queryState] = useCodeSystem('ccm-activity-types');

  return [
    useMemo(() => {
      return codeSsytem?.concept ?? [];
    }, [codeSsytem]),
    queryState,
  ] as const;
}

export function useActiveActivityTypes() {
  const [activityTypes, queryState] = useActivityTypes();

  return [
    useMemo(
      () =>
        activityTypes.filter(
          (activityType) =>
            activityType.property?.find((property) => property.code === 'active')?.valueBoolean ??
            true
        ),
      [activityTypes]
    ),
    queryState,
  ] as const;
}
