// @ts-nocheck
import {
  Container,
  Grid,
} from "@mui/material";
import Bed from '../rounding/Model/Bed';
import Page from 'src/components/Page';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { Filters } from "./components/Filters/Filters";
import ListTable from "./components/ListTable/ListTable";
import AddBed from './components/AddBed/AddBed';
import { CensusProvider } from './CensusContext';
import BedAssignment from '../rounding/Model/BedAssignment';
import { useCensusBeds } from 'src/services/api/census/hooks';
import useObjectState from 'src/hooks/useObjectState';
import { useEffect } from "react";
import { BedPaginationType } from "src/services/api/census";
import AddModal from "../common/AddModal";
import TransferBed from "./components/TransferBed/TransferBed";

export type CensusStateType = {
  showAddBedModal: boolean;
  showTransferBedModal: boolean;
  selectedBed: Bed | null;
  searchValue: string | null;
  locationFhirId: string | null;
  pagination: BedPaginationType;
}

export const Census = () => {
  const [{
    selectedBed,
    showAddBedModal,
    showTransferBedModal,
    searchValue,
    locationFhirId,
    pagination,
  }, updateState] = useObjectState<CensusStateType>({
    showAddBedModal: false,
    showTransferBedModal: false,
    selectedBed: null,
    searchValue: null,
    locationFhirId: null,
    pagination: {
      take: 10,
      skip: 0,
    }
  })
  const { data: response, isLoading, isFetching, refetch } = useCensusBeds({
    search: searchValue,
    locationFhirId: locationFhirId,
    status: 'active',
    pagination: {
      take: pagination?.take,
      skip: pagination?.skip
    },
  });
  const beds = response?.beds;
  const total = response?.total;

  const onSaveEntity = (bedId: number, bedAssignment: BedAssignment | null) => {
    refetch();
  };

  const onChangeLocation = (locationId: string | null) => updateState({
    locationFhirId: locationId,
    pagination: {
      take: 10,
      skip: 0,
    },
  })

  const onChangeSearch = (search: string | null) => updateState({
    searchValue: search,
    pagination: {
      take: 10,
      skip: 0,
    },
  })

  const openAddBedModal = (bed: Bed | null) => {
    updateState({
      selectedBed: bed,
      showAddBedModal: true,
    })
  };

  const closeAddBedModal = () => {
    updateState({
      selectedBed: null,
      showAddBedModal: false,
    })
  };

  const openTransferBedModal = (bed: Bed | null) => {
    updateState({
      selectedBed: bed,
      showTransferBedModal: true,
    })
  };

  const closeTransferBedModal = () => {
    updateState({
      selectedBed: null,
      showTransferBedModal: false,
    })
  };

  useEffect(() => {
    refetch();
  }, [locationFhirId, refetch, searchValue, pagination])

  return (
    <CensusProvider
      value={{
        openAddBedModal,
        closeAddBedModal,
        openTransferBedModal,
        closeTransferBedModal,
        onSaveEntity,
      }}
    >
      <Page title="Census">
        <AddModal
          open={showAddBedModal}
          onClose={closeAddBedModal}
          Component={(
            <AddBed
              bed={selectedBed}
              onSaveCallback={onSaveEntity}
              onCancelPress={closeAddBedModal}
            />
          )}
        />

        <AddModal
          open={showTransferBedModal}
          onClose={closeTransferBedModal}
          Component={(
            <TransferBed
              bed={selectedBed}
              onSaveCallback={onSaveEntity}
              onCancelPress={closeTransferBedModal}
            />
          )}
        />
        <Container maxWidth={'xl'}>
          <Grid container spacing={3} sx={{ marginBottom: 2 }}>
            <Grid item xs={12}>
              <Breadcrumbs
                title="Census"
                links={[
                  {
                    href: '/dashboard',
                    name: 'Dashboard',
                  },
                  {
                    href: '',
                    name: 'Census',
                  }
                ]}
              />
            </Grid>
          </Grid>
          <Filters
            onChangeLocation={onChangeLocation}
            onChangeSearch={onChangeSearch}
          />
          <ListTable
            beds={isFetching ? [] : (beds ?? [])}
            isLoading={isLoading || isFetching}
            pagination={{
              take: pagination.take,
              page: pagination.skip,
              total,
            }}
            changePage={(newPage) => {
              updateState({
                pagination: {
                  take: pagination.take,
                  skip: newPage,
                },
              });
            }}
          />
        </Container>
      </Page>
    </CensusProvider>
  );
}
