const crsAcls = {
  PATIENT: {
    ASSESSMENTS: {
      START: 'crs.patient.assessments.start',
    },
    PATIENT_CARD: {
      READ: 'crs.patient.patient_card.read',
    },
  },
  PROVIDER_WINDOW: {
    OPEN_NON_EXISTENT_PATIENT: 'crs.provider_window.open_non_existent_patient',
  },
};

export default crsAcls;
