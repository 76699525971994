import { Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate, useNavigationType } from 'react-router';
import Label from 'src/components/Label';
import DataTable, { DataTableColumn } from 'src/components/table/DataTable';
import api from 'src/services/api';
import { conditionStatus, conditionTypes } from 'src/_mock/settings/_careplan-automation';
import { CreatedUpdated } from '../common';
import ConditionsContextMenu from './ConditionsContextMenu';

const columns = [
  {
    header: 'Condition',
    field: 'name',
  },
  {
    header: 'Type',
    field: 'type',
    render(value) {
      return conditionTypes[value];
    },
  },
  {
    header: 'Notes',
    field: 'notes',
  },
  {
    header: 'Rank',
    field: 'rank',
    render(value) {
      return value === -1 ? 'N/A' : value;
    },
  },
  {
    header: 'Status',
    field: 'status',
    render(value) {
      return (
        <Label color={value === 'active' ? 'success' : 'info'}>{conditionStatus[value]}</Label>
      );
    },
  },
  {
    header: 'Created',
    field: '$',
    render(value) {
      return <CreatedUpdated by={value.createdBy} date={value.createdOn} />;
    },
  },
  {
    header: 'Updated',
    field: '$',
    render(value) {
      return <CreatedUpdated by={value.updatedBy} date={value.updatedOn} />;
    },
  },
] as DataTableColumn[];

export default function ConditionsTable() {
  const { data: conditions = [], refetch } = useQuery(
    ['conditions', useNavigate()],
    async () =>
      (await api.carePlan.findConditions({
        status: '',
      })) as any[]
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Box sx={{ pt: 2 }}>
      <DataTable
        columns={columns}
        data={conditions}
        selectable
        rowContextMenu={(item: any) => (
          <ConditionsContextMenu
            item={item}
            onDelete={async () => {
              await refetch();
            }}
          />
        )}
      />
    </Box>
  );
}
