// TODO: ResourceWrappers
import {
  QueryKey,
  QueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import _ from 'lodash';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import {
  Element,
  FhirResource,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { FhirResourceFilters, FhirResourceMapping, FhirResourceName } from '../fhir-base/mappings';
import { useFhirContext } from './context';
import { Patch } from 'immer';

// Extract the resource type from the FhirResource union though it's name
type __ResourceByName<Name extends FhirResourceName> = FhirResource extends infer T
  ? T extends { resourceType: Name }
    ? T
    : never
  : never;

export function createWrapper<Type extends FhirResourceName, WrapperProperties>(
  resource: Type,
  wrapper: (resource: __ResourceByName<Type>) => WrapperProperties
) {
  type Resource = __ResourceByName<Type>;
  type WrappedResource<Name extends FhirResourceName> = Omit<
    __ResourceByName<Name>,
    keyof WrapperProperties
  > &
    WrapperProperties;

  return (resource: Resource): WrappedResource<Type> => Object.assign(resource, wrapper(resource));
}
export class ResourceWrapper<T extends FhirResource> {
  constructor(_resource: T) {
    Object.assign(this, _resource);
  }
}

export class ElementWrapper<T extends Element> {
  constructor(protected _element: T) {}
}

/**
 * Creates hooks for a FHIR resource.
 * @param resourceType The name of the resource. Must be in the FhirResourceMapping type.
 * @param options Optional options.
 * @param options.bundleHookName The name to use for the bundle hook.
 * @param options.resourceHookName The name to use for the resource hook.
 * @param options.resourceProviderName The name of the resource provider.
 * @returns The hooks for the resource.
 */
export function createFhirResourceHooks<
  ResourceType extends FhirResourceName,
  BundleHookName extends string | undefined = undefined,
  ResourceHookName extends string | undefined = undefined,
  CurrentResourceHookName extends string | undefined = undefined,
  ResourceProviderName extends string | undefined = undefined
>(
  resourceType: ResourceType,
  options?: {
    bundleHookName?: BundleHookName;
    resourceHookName?: ResourceHookName;
    currentResourceHookName?: CurrentResourceHookName;
    resourceProviderName?: ResourceProviderName;
  }
) {
  type Resource = FhirResourceMapping[ResourceType];
  type ResourceFilter = ResourceType extends keyof FhirResourceFilters
    ? FhirResourceFilters[ResourceType]
    : {};

  interface UseResourcesHookOptions<MappedValue> {
    client?: string;

    /**
     * Optional filter to apply to the bundle.
     */
    // filter?: ResourceFilter | string;
    filter?: any;

    /**
     * Whether to automatically fetch the resources. Useful to disable if you want to manually fetch the resources.
     * Or just want to use the resource actions without fetching.
     */
    autofetch?: boolean;

    map?: (resource: Resource) => MappedValue;

    /**
     * Record of Resource and property paths to resolve.
     *
     * Example:
     *  {
     *    Questionnaire: ['questionnaire'],
     *  }
     */
    resolve?: Record<string, string[]>;

    pagination?: {
      pageSize?: number;
      mode?: 'continuous' | 'paged';
    };

    baseQueryOptions?: Omit<
      UseInfiniteQueryOptions<
        {
          total: number;
          entries: (MappedValue | Resource)[];
          nextPage: string | undefined;
        },
        unknown,
        {
          total: number;
          entries: (MappedValue | Resource)[];
          nextPage: string | undefined;
        },
        {
          total: number;
          entries: (MappedValue | Resource)[];
          nextPage: string | undefined;
        },
        string[]
      >,
      'queryKey' | 'queryFn'
    >;
  }

  type UseResourcesHookReturn<
    ResourceType extends FhirResourceName,
    ReturnedResource = __ResourceByName<ResourceType>
  > = [
    ReturnedResource[],
    {
      total: number;
      isError: boolean;
      isLoading: boolean;
      isFetching: boolean;
      isSuccess: boolean;
      isRefetching: boolean;
      refresh: () => Promise<void>;
      find: (filter?: ResourceFilter) => Promise<ReturnedResource[]>;
      get: (id: string) => Promise<ReturnedResource>;
      nextPage: () => Promise<ReturnedResource[]>;
      create: UseMutateAsyncFunction<
        __ResourceByName<ResourceType>[],
        unknown,
        __ResourceByName<ResourceType> | __ResourceByName<ResourceType>[],
        unknown
      >;
      update: UseMutateAsyncFunction<
        void,
        unknown,
        __ResourceByName<ResourceType> | __ResourceByName<ResourceType>[],
        unknown
      >;
      remove: UseMutateAsyncFunction<
        void,
        unknown,
        __ResourceByName<ResourceType> | __ResourceByName<ResourceType>[] | string | string[],
        unknown
      >;
      patch: (
        patches: { id: string; patches: Patch[] }[]
      ) => ReturnType<
        UseMutateAsyncFunction<
          __ResourceByName<ResourceType>[],
          unknown,
          __ResourceByName<ResourceType>[],
          unknown
        >
      >;
    }
  ];

  function useFhirResources<MappedValue = Resource>(
    options: UseResourcesHookOptions<MappedValue> = {}
  ): UseResourcesHookReturn<ResourceType, MappedValue> {
    const { resolve, pagination } = options;
    const filter = options.filter ?? ({} as ResourceFilter);
    const { clients } = useFhirContext();
    const queryClient = useQueryClient();
    const clientName = options.client ?? 'default';
    const client = clients[clientName];
    if (!client) {
      throw new Error(`No client with name '${clientName}' found.`);
    }
    const mapper = ('map' in options && options.map) || ((resource: Resource) => resource);

    // Fetch the resource bundle.
    const query = useInfiniteQuery(
      [resourceType, ...Object.entries(filter).map(([key, value]) => `${key}=${value}`)],
      async function fetchData({ pageParam = '' }) {
        const pageFilter = { ...filter };

        if (pagination) {
          pageFilter._count ??= pagination.pageSize;
        }

        if (pageParam) {
          pageFilter._page_token = pageParam;
        }

        const data = await client.findAll<Resource>(resourceType, pageFilter);
        const entries = data.entry?.map((entry) => entry.resource!) || [];
        const resources = await Promise.all(
          entries.map(async (resource) => {
            for (const [resourceType, properties] of Object.entries(resolve || {})) {
              for (const property of properties) {
                const value = _.get(resource, property);

                if (typeof value === 'string') {
                  let url: URL | null = null;
                  try {
                    url = new URL(value);
                  } catch {}

                  if (url) {
                    const resource = await axios.get(url.href);
                    if ('resourceType' in resource.data) {
                      _.set(resource, property, resource.data);
                    }

                    _.set(resource, property, null);
                    continue;
                  }

                  // if reference includes a string it is supposed to be a
                  // reference to another resource type, extract it
                  const [specifiedResourceType, id] = value.includes('/')
                    ? value.split('/')
                    : [resourceType, value];

                  const resolvedResource = await client.findById(specifiedResourceType, id);
                  _.set(resource, property, resolvedResource);
                }
              }
            }

            return resource;
          })
        );

        return {
          total: data.total || 0,
          entries: resources.map((resource) => mapper(resource)),
          nextPage: data.link?.find((link) => link.relation === 'next')?.url,
        };
      },
      {
        enabled: options.autofetch,
        getNextPageParam: (lastPage) => {
          if (!lastPage.nextPage) {
            return;
          }

          const url = new URL(lastPage.nextPage);
          return url.searchParams.get('_page_token');
        },
        ...options.baseQueryOptions,
      }
    );

    // Create a resource
    const createMutation = useMutation(
      [resourceType, filter],
      async (resource: Resource | Resource[]) => {
        let createdResources: Resource[];
        if (Array.isArray(resource)) {
          createdResources = await Promise.all(
            resource.map((r) => client.createOne(resourceType, r))
          );
        } else {
          createdResources = [await client.createOne(resourceType, resource)];
        }

        await queryClient.refetchQueries({
          predicate(query) {
            return query.queryKey[0] === resourceType;
          },
        });
        return createdResources;
      }
    );

    // Update a resource
    const updateMutation = useMutation(
      [resourceType, filter],
      async (resource: Resource | Resource[]) => {
        if (Array.isArray(resource)) {
          await Promise.all(resource.map((r) => client.updateOne(resourceType, r)));
        } else {
          await client.updateOne(resourceType, resource);
        }

        await queryClient.refetchQueries({
          predicate(query) {
            return query.queryKey[0] === resourceType;
          },
        });
      }
    );

    // Remove a resource
    const removeMutation = useMutation(
      [resourceType, filter],
      async (resource: Resource | Resource[] | string | string[]) => {
        if (Array.isArray(resource)) {
          await Promise.all(
            resource.map((r) => {
              if (typeof r === 'string') {
                if (!r) {
                  throw new Error('Invalid resource id');
                }
                return client.removeOne(resourceType, r);
              }

              if (!r?.id) {
                throw new Error('Resource does not have an id');
              }

              return client.removeOne(resourceType, r.id);
            })
          );
        } else {
          if (typeof resource === 'string') {
            if (!resource) {
              throw new Error('Invalid resource id');
            }

            return client.removeOne(resourceType, resource);
          }

          if (!resource?.id) {
            throw new Error('Resource does not have an id');
          }

          await client.removeOne(resourceType, resource.id);
        }

        await queryClient.refetchQueries({
          predicate(query) {
            return query.queryKey[0] === resourceType;
          },
        });
      }
    );

    const patchMutation = useMutation(
      [resourceType, filter],
      async (patches: { id: string; patches: Patch[] }[]) => {
        const patched = await Promise.all(
          patches.map(({ id, patches }) =>
            client
              .patchOne<Resource>(
                resourceType,
                id,
                patches.map((patch) => ({
                  ...patch,
                  path: '/' + patch.path.join('/'),
                }))
              )
              .then((resource) => mapper(resource))
          )
        );

        await queryClient.refetchQueries({
          predicate(query) {
            return query.queryKey[0] === resourceType;
          },
        });
        return patched;
      }
    );

    const find = useCallback(
      async (filter?: ResourceFilter) => {
        const data = await client.findAll<Resource>(resourceType, filter);
        const entries = data.entry?.map((entry) => entry.resource!) || [];

        return entries.map((resource) => mapper(resource));
      },
      [resourceType, client]
    );

    const get = useCallback(
      async (id: string) => {
        const resource = await client.findById<Resource>(resourceType, id);
        return mapper(resource);
      },
      [resourceType, client]
    );

    const data = useMemo(
      () => ({
        total: 0,
        entries:
          query.data?.pages?.reduce((acc, page) => [...acc, ...page.entries], [] as any[]) || [],
      }),
      [query.data]
    );

    return [
      data.entries,
      {
        total: data.total,
        isError: query.isError,
        isFetched: query.isFetched,
        isFetchedAfterMount: query.isFetchedAfterMount,
        isFetching: query.isFetching,
        isFetchingNextPage: query.isFetchingNextPage,
        isFetchingPreviousPage: query.isFetchingPreviousPage,
        isLoading: query.isLoading,
        isLoadingError: query.isLoadingError,
        isPaused: query.isPaused,
        isPlaceholderData: query.isPlaceholderData,
        isPreviousData: query.isPreviousData,
        isRefetchError: query.isRefetchError,
        isRefetching: query.isRefetching,
        isStale: query.isStale,
        isSuccess: query.isSuccess,
        refresh: async () => {
          await query.refetch();
        },
        nextPage: async () => {
          const { data } = await query.fetchNextPage();
          await query.refetch();

          return data?.pages.at(-1)?.entries || [];
        },
        hasNextPage: query.hasNextPage,
        find,
        get,
        create: createMutation.mutateAsync,
        update: updateMutation.mutateAsync,
        remove: removeMutation.mutateAsync,
        patch: patchMutation.mutateAsync,
      },
    ] as any; // FIXME: refactor in progress
  }

  type UseResourceHookReturn<
    ResourceType extends FhirResourceName,
    ReturnedResource = __ResourceByName<ResourceType>
  > = [
    ReturnedResource | null,
    {
      isError: boolean;
      isLoading: boolean;
      isFetching: boolean;
      isSuccess: boolean;
      isRefetching: boolean;
      refresh: () => Promise<void>;
      update: UseMutateAsyncFunction<void, unknown, __ResourceByName<ResourceType>, unknown>;
      remove: UseMutateAsyncFunction<void, unknown, __ResourceByName<ResourceType>, unknown>;
    }
  ];

  /**
   * Get a single resource.
   */
  function useFhirResource<MappedValue = Resource>(
    id: string | null | undefined,
    options: UseResourcesHookOptions<MappedValue> = {}
  ): UseResourceHookReturn<ResourceType, MappedValue> {
    const { resolve } = options;
    const { clients } = useFhirContext();
    const clientName = options.client || 'default';
    const client = clients[clientName];
    if (!client) {
      throw new Error(`FHIR Client not found: ${clientName}`);
    }

    const mapper = ('map' in options && options.map) || ((resource: Resource) => resource);

    const query = useQuery(
      [resourceType, id],
      async () => {
        if (!id) {
          return null;
        }

        const resource = await client.findById<Resource>(resourceType, id);

        for (const [resourceType, properties] of Object.entries(resolve || {})) {
          for (const property of properties) {
            const value = _.get(resource, property);

            if (typeof value === 'string') {
              const [specifiedResourceType, id] = value.includes('/')
                ? value.split('/')
                : [resourceType, value];

              const resolvedResource = await client.findById(specifiedResourceType, id);
              _.set(resource, property, resolvedResource);
            }
          }
        }

        return mapper(resource);
      },
      {
        enabled: options.autofetch,
      }
    );

    const updateMutation = useMutation([resourceType, id], async (resource: Resource) => {
      await client.updateOne(resourceType, resource);
      await query.refetch();
    });

    const removeMutation = useMutation([resourceType, id], async (resource: Resource) => {
      if (!resource.id) {
        throw new Error('Resource does not have an id');
      }

      await client.removeOne(resourceType, resource.id);
      await query.refetch();
    });

    // const patchMutation = useMutation([resourceType, id], async (id: string, patch: Patch[]) => {
    //   if (!id) {
    //     throw new Error('Resource does not have an id');
    //   }

    //   const patchedResource = await client.patchOne(resourceType, id, patch);
    //   return patchedResource;
    // });

    return [
      (query.data as any) || null,
      {
        isError: query.isError,
        isLoading: query.isLoading,
        isFetching: query.isFetching,
        isSuccess: query.isSuccess,
        isRefetching: query.isRefetching,
        refresh: async () => {
          await query.refetch();
        },
        update: updateMutation.mutateAsync,
        remove: removeMutation.mutateAsync,
        // patch: patchMutation.mutateAsync,
      },
    ] as any;
  }

  // ----------------- Context -----------------
  /* UNSTABLE UNSTABLE UNSTABLE UNSTABLE UNSTABLE UNSTABLE UNSTABLE UNSTABLE */
  interface ResourceContextEntry {
    resource: ReturnType<typeof useFhirResource>;
    setResource: (id: string) => void;
  }

  // TODO: Support multiple resources under one context
  const resourceContext = createContext<Record<string, ResourceContextEntry>>({
    current: {
      resource: [
        null,
        {
          isError: false,
          isLoading: false,
          isSuccess: false,
          isFetching: false,
          isRefetching: false,
          refresh: async () => {
            throw new Error('No context provided');
          },
          update: async () => {
            throw new Error('No context provided');
          },
          remove: async () => {
            throw new Error('No context provided');
          },
        },
      ],
      setResource: (id: string) => {
        throw new Error('No context provided');
      },
    },
  });

  /**
   * Get current resource.
   * UNSTABLE UNSTABLE UNSTABLE UNSTABLE UNSTABLE UNSTABLE UNSTABLE UNSTABLE *
   */
  function useCurrentResource() {
    const { current } = useContext(resourceContext);

    return [current.resource, current.setResource] as const;
  }

  type ResourceProviderProps = {
    id?: string;
    autofetch?: boolean;
    children: React.ReactNode;
  };

  /* UNSTABLE UNSTABLE UNSTABLE UNSTABLE UNSTABLE UNSTABLE UNSTABLE UNSTABLE */
  function ResourceProvider({ children, id, autofetch }: ResourceProviderProps) {
    const [currentResourceId, setCurrentResourceId] = useState(id);
    const curentResource = useFhirResource(currentResourceId!, { autofetch });

    return (
      <resourceContext.Provider
        value={{
          current: {
            resource: curentResource as any,
            setResource: setCurrentResourceId,
          },
        }}
      >
        {children}
      </resourceContext.Provider>
    );
  }

  // Set hook names
  type UseBundleHookName = BundleHookName extends undefined
    ? `use${ResourceType}s`
    : BundleHookName;
  type UseResourceHookName = ResourceHookName extends undefined
    ? `use${ResourceType}`
    : ResourceHookName;
  type UseCurrentResourceHookName = CurrentResourceHookName extends undefined
    ? `useCurrent${ResourceType}`
    : CurrentResourceHookName;
  type ResourceProviderComponentName = ResourceProviderName extends undefined
    ? `${ResourceType}Provider`
    : ResourceProviderName;

  type ResourceHooks = {
    [key in UseBundleHookName]: typeof useFhirResources;
  } & {
    [key in UseResourceHookName]: typeof useFhirResource;
  } & {
    [key in UseCurrentResourceHookName]: typeof useCurrentResource;
  } & {
    [key in ResourceProviderComponentName]: typeof ResourceProvider;
  };

  Object.defineProperty(useFhirResources, 'name', {
    value: options?.bundleHookName ?? `use${resourceType}s`,
  });
  Object.defineProperty(useFhirResource, 'name', {
    value: options?.resourceHookName ?? `use${resourceType}`,
  });
  Object.defineProperty(useCurrentResource, 'name', {
    value: options?.currentResourceHookName ?? `useCurrent${resourceType}`,
  });
  Object.defineProperty(ResourceProvider, 'name', {
    value: options?.resourceProviderName ?? `${resourceType}Provider`,
  });

  return {
    [useFhirResources.name]: useFhirResources,
    [useFhirResource.name]: useFhirResource,
    [useCurrentResource.name]: useCurrentResource,
    [ResourceProvider.name]: ResourceProvider,
  } as ResourceHooks;
}
