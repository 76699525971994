import { createContext } from 'react';
import Catalog from './Model/Catalog';
import IntervalDetail from './Model/IntervalDetail';
import { PaginationType } from './controller';

export type RoundingContextType = {
  patientActivities: Catalog[] | undefined;
  patientLocations: Catalog[] | undefined;
  getEntities: (pagination: PaginationType) => void;
  completedIntervalDetail: (
    (
      intervalDetail: IntervalDetail,
      roundingIndex: number,
      intervalIndex: number,
      intervalDetailIndex: number
    ) => void
  ) | undefined;
  getIntervalDetails: (
    intervalId: number,
    roundingIndex: number,
    intervalIndex: number,
    loadMore?: boolean,
  ) => void;
  intervalDetailsTotalCount: number;
  loadingIntervalDetails: boolean;
};

export const RoundingContext = createContext<RoundingContextType>({
  patientActivities: [],
  patientLocations: [],
  getEntities: (pagination: PaginationType) => {},
  completedIntervalDetail: (
    intervalDetail,
    roundingIndex,
    intervalIndex,
    intervalDetailIndex
  ) => {},
  getIntervalDetails: () => {},
  intervalDetailsTotalCount: 0,
  loadingIntervalDetails: false,
});

export const RoundingProvider = RoundingContext.Provider;