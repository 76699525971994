import { useCallback, useState } from 'react';
import { Tabs, Tab, Card } from '@mui/material';
import TabItem from '../../group-sessions/types/TabItem';
import { CarePlanSummary } from './components/CarePlanSummary';
import { BarriersSummary } from './components/BarriersSummary';

type CarePlanSummaryProps = {
  isExpanded: boolean;
  onZoom?: () => void;
  patient?: {
    id: string;
  };
};

const TAB_VALUES: TabItem[] = [
  {
    key: 0,
    value: 0,
    label: 'Care Plan',
  },
  {
    key: 1,
    value: 1,
    label: 'Barriers',
  },
];

export const CarePlanIndex = ({ isExpanded, onZoom, patient }: CarePlanSummaryProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(TAB_VALUES[0].key);

  const renderTabs = useCallback(
    () => (
      <Tabs value={selectedTabIndex} variant="scrollable" scrollButtons="auto">
        {TAB_VALUES?.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            onClick={() => {
              setSelectedTabIndex(tab.key);
            }}
            id={`status-tab-${tab.key}`}
            {...{
              'aria-controls': `status-tabpanel-${tab.key}`,
            }}
          />
        ))}
      </Tabs>
    ),
    [selectedTabIndex]
  );

  return (
    <Card sx={{ height: `calc(100vh - 200px)`, display: 'flex', flexDirection: 'column' }}>
      {renderTabs()}
      {selectedTabIndex === TAB_VALUES[0].value && (
        <CarePlanSummary isExpanded={isExpanded} patient={patient} onZoom={() => onZoom?.()} />
      )}

      {selectedTabIndex === TAB_VALUES[1].value && (
        <BarriersSummary isExpanded={isExpanded} patient={patient} onZoom={() => onZoom?.()} />
      )}
    </Card>
  );
};
