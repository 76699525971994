import Page from 'src/components/Page';
import { Stack, Typography } from '@mui/material';
import { Navigate, useMatch } from 'react-router';
import usePatientTabsStore from 'src/stores/patient-tabs';

export default function NoPatientSelected({ title = 'No patient selected' }) {
  const match = useMatch('/dashboard/:tab');
  if (!match) {
    throw new Error(`<NoPatientSelected />: Can only be used on /dashboard/* routes`);
  }

  const currentPatientId = usePatientTabsStore((s) => s.currentPatientId);
  if (currentPatientId) {
    return <Navigate to={`/dashboard/patient/${currentPatientId}/${match.params.tab}`} replace />;
  }

  return (
    <Page title={title}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        p={12}
        maxWidth="sm"
        minHeight="100vh"
        margin="auto"
      >
        <Typography variant="h3" paragraph>
          No patient selected
        </Typography>

        <Typography sx={{ color: 'text.secondary' }} mb={12}>
          Please select a patient to view their care plan information.
        </Typography>
      </Stack>
    </Page>
  );
}
