import { Alert } from '@mui/material';
import { useNavigate } from 'react-router';
import { Questionnaire } from 'src/@nicheaim/fhir-base/mappings/Questionnaire';
import { useQuestionnaires } from 'src/@nicheaim/fhir-react';
import Label, { LabelColor } from 'src/components/Label';
import DataTable, { DataTableColumn } from 'src/components/table/DataTable';
import fhirSystem from 'src/fhir/system';
import AssessmentsToolbar from './AssessmentsToolbar';
import { useAllAssessments } from 'src/fhir/hooks/assessments';

const columns: DataTableColumn[] = [
  {
    header: 'Assessment',
    field: 'title',
  },
  {
    header: 'Status',
    field: 'status',
    render(value: Questionnaire['status']) {
      const mappings: Record<Questionnaire['status'], { title: string; color: LabelColor }> = {
        active: {
          title: 'Active',
          color: 'success',
        },
        draft: {
          title: 'Draft',
          color: 'info',
        },
        retired: {
          title: 'Retired',
          color: 'warning',
        },
        unknown: {
          title: 'Unknown',
          color: 'error',
        },
      };
      const mapping = mappings[value];

      return <Label color={mapping.color}>{mapping.title}</Label>;
    },
  },
];

export default function AssessmentsTable() {
  const navigate = useNavigate();
  const [assessments] = useAllAssessments({
    pagination: {
      pageSize: 1000,
    },
  });

  return (
    <div>
      <AssessmentsToolbar />

      {assessments.length ? (
        <DataTable
          selectable
          clickeable
          columns={columns}
          data={assessments}
          onItemEdit={(item) => navigate(`${item.id}/edit`)}
          onItemClick={(item) => navigate(`${item.id}`)}
          sx={{ m: 2 }}
        />
      ) : (
        <Alert severity="info" sx={{ m: 2 }}>
          No assessments found
        </Alert>
      )}
    </div>
  );
}
