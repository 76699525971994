import { Dialog, MenuItem, Stack, Typography } from '@mui/material';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { useGroups, usePatient } from 'src/@nicheaim/fhir-react';
import Iconify from 'src/components/Iconify';
import { ActionBar } from 'src/sections/careflow/common';
import {
  IPlanProgramRow,
  Membership,
} from 'src/sections/careflow/patient-demographics/components/PlansDetails';
import PlansDetailsExpanded from 'src/sections/careflow/patient-demographics/components/PlansDetailsExpanded';
import PlansDetailsForm, {
  getMembershipStatus,
} from 'src/sections/careflow/patient-demographics/components/PlansDetailsForm';
import { useStandardLeftDialog } from '../../Activities/StandardLeftDialogProvider';
import { Group } from 'src/@nicheaim/fhir-base/mappings/Group';
import fhirSystem from 'src/fhir/system';
import { useMemo, useState, useContext } from 'react';
import { getFhirIdFromReferenceString } from '../../Activities/activities-utils';
import { referenceEquals } from 'src/utils/fhir';
import { sortBy } from 'lodash';
import { ActivitiesContext } from '../../Activities/ActivitiesContext';
import PatientViewModel from '../ViewModel/PatientViewModel';

interface IEditPatientProgramsProps {
  patientId: string;
  patient?: PatientViewModel;
  handleOnClose: () => void;
}

export default function EditPatientPrograms({
  patientId,
  patient: fullPatient,
}: IEditPatientProgramsProps) {
  const activitiesContext = useContext(ActivitiesContext);
  const standardLeftDialog = useStandardLeftDialog();
  const [patient] = usePatient(patientId, {
    map: PatientWrapper,
  });

  console.log({
    patientResourceType: patient
  })

  const [groups, { refresh: refreshGroups }] = useGroups<Group>({
    filter: {
      member: patient?.id,
      identifier: fhirSystem.program.enrolledPatients.forCode(''),
    },
    autofetch: !!patient?.id,
  });

  // programs
  const enrolledPrograms = useMemo(
    () =>
      sortBy(
        groups.reduce((acc, group) => {
          const lobId = getFhirIdFromReferenceString(group.managingEntity?.reference || '');
          const program = group.code?.coding?.[0];
          const memberships = patient ? group.member?.filter((member) => referenceEquals(member.entity, patient)) || [] : [];

          if (!lobId || !program) {
            return acc;
          }

          const membeships: Membership[] = memberships.map((membership) => ({
            lobId,
            membership,
            program,
            status: getMembershipStatus(membership),
          })) as any;

          acc.push(
            ...membeships
          );

          return acc;
        }, [] as Membership[]),
        ({ membership }) =>
        ({
          new: 0,
          pending: 1,
          active: 2,
          inactive: 3,
          unknown: 4,
        }[getMembershipStatus(membership)]),
        ({ membership }) => (membership.period?.start ? -Date.parse(membership.period?.start) : 0),
        ({ membership }) => (membership.period?.end ? -Date.parse(membership.period?.end) : 0)
      ),
    [groups, patient]
  );

  const handleEdit = (row: IPlanProgramRow) => {
    standardLeftDialog.open(
      <PlansDetailsForm
        patientId={patientId}
        editData={row}
        onClose={() => {
          standardLeftDialog.close();
        }}
        handleAfterSave={(newProgramStatus) => {
          refreshGroups();
          const newProgramsStatuses = fullPatient?.programs_status
            ?.split(';')
            .map((s) => {
              const splitedProgram = s.split(':');
              const newSplitedProgram = newProgramStatus?.split(':');
              if (splitedProgram[0] === newSplitedProgram?.[0]) {
                splitedProgram[2] = newSplitedProgram?.[2];
                return splitedProgram.join(':');
              }

              return s;
            })
            .join(';');
          console.log({
            newProgramsStatuses,
          });
          activitiesContext.onCreateOrUpdateEntity({
            ...fullPatient,
            programs_status: newProgramsStatuses,
          });
        }}
      />
    );
  };

  const [showProgramsModal, setShowProgramsModal] = useState(false);

  return (
    <>
      <Dialog open={showProgramsModal} maxWidth="lg">
        <PlansDetailsExpanded
          actionBar={
            <ActionBar
              onCollapse={() => {
                setShowProgramsModal(false);
              }}
            />
          }
          patientId={patientId}
          enrolledPrograms={enrolledPrograms}
          handleEdit={handleEdit}
        />
      </Dialog>
      <MenuItem
        key={'edit-programs'}
        onClick={() => {
          setShowProgramsModal(true);
        }}
      >
        <Stack direction="row" spacing={2}>
          <Iconify icon={'material-symbols:fact-check'} />
          <Typography variant="body2">Edit Programs</Typography>
        </Stack>
      </MenuItem>
    </>
  );
}
