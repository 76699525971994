import { useQuestionnaires } from 'src/@nicheaim/fhir-react';
import fhirSystem from '../system';

export function useActiveAssessments(options?: Parameters<typeof useQuestionnaires>[0]) {
  const [questionnaires, status] = useQuestionnaires({
    ...(options as any),
    filter: {
      identifier: fhirSystem.assessments.assessment.forCode(''),
      status: 'active',
      _sort: 'title',
      ...options?.filter,
    },
  });

  return [questionnaires, status] as const;
}

export function useAllAssessments(options?: Parameters<typeof useQuestionnaires>[0]) {
  const [questionnaires, status] = useQuestionnaires({
    ...(options as any),
    filter: {
      identifier: fhirSystem.assessments.assessment.forCode(''),
      _sort: 'title',
      ...options?.filter,
    },
  });

  console.log({
    questionnaires,
    status
  })

  return [questionnaires, status] as const;
}
