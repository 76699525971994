import react, { useMemo, useContext } from 'react';
import {
  TableCell,
  TableRow,
  Grid,
  FormControl,
  Typography,
  Autocomplete,
  TextField,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@mui/material';
import useObjectState from 'src/hooks/useObjectState';
import { PatientCard } from 'src/sections/careflow/common';
import IntervalDetail from '../../../Model/IntervalDetail';
import Interval from '../../../Model/Interval';
import Iconify from 'src/components/Iconify';
import IntervalDetailSectionsModal from '../IntervalDetailSectionsModal/IntervalDetailSectionsModal';
import { RoundingContext } from '../../../roundingContext';
import { RoundingItemContext } from '../RoundingItem/RoundingItemContext';
import { getFhirIdFromReferenceString } from 'src/sections/careflow/tasks-activities/components/Activities/activities-utils';
import { SectionEnum, sectionIcons } from '../constants';
import API from 'src/services/api';
import { shortFormat, shortHourFormat } from 'src/utils/formatTime';
import moment from 'moment';
import SvgIconStyle from '../../../../../../components/SvgIconStyle';
import { IntervalDetailItemContext } from './IntervalDetailItemContext';

type ListTableIntervalDetailItemProps = {
  item: IntervalDetail;
  showSections?: boolean;
  sections?: SectionEnum[];
  onCompletedItemCallback?: (intervalDetail: IntervalDetail) => void;
};

type SelectOptionType = { label: string; value: number };

type ListTableIntervalDetailItemState = {
  isCompleted?: boolean;
  showSectionsModal?: boolean;
  patientActivityItem?: SelectOptionType | null;
  patientLocationItem?: SelectOptionType | null;
  sectionToExpand?: SectionEnum;
  isSaving?: boolean;
};

const IntervalDetailItem = ({
  item,
  onCompletedItemCallback = () => {},
  sections = [],
  showSections = true,
}: ListTableIntervalDetailItemProps) => {
  const roundingContext = useContext(RoundingContext);
  const { rounding } = useContext(RoundingItemContext);
  const intervals: Interval[] = rounding?.missedIntervals ?? [];
  const [
    { isCompleted, showSectionsModal, patientActivityItem, patientLocationItem, sectionToExpand, isSaving },
    updateState,
  ] = useObjectState<ListTableIntervalDetailItemState>({
    isCompleted: item.isCompleted,
    showSectionsModal: false,
    patientActivityItem: item.patientActivity
      ? {
          label: item.patientActivity.valueDisplayName,
          value: item.patientActivity.id,
        }
      : null,
    patientLocationItem: item.patientActivityLocation
      ? {
          label: item.patientActivityLocation?.valueDisplayName,
          value: item.patientActivityLocation?.id,
        }
      : null,
  });
  const patientId = useMemo(
    () => item.bedAssignment.patient?.fhir_id,
    [item.bedAssignment.patient?.fhir_id]
  );

  const onToggleCompleted = () => {
    if (!patientActivityItem || !patientLocationItem) {
      return;
    }

    updateState({
      isSaving: true,
    });

    API.roundings
      .completeIntervalDetail(item.id, {
        patientActivityId: patientActivityItem?.value ?? 0,
        patientLocationId: patientLocationItem?.value ?? 0,
      })
      .then((response: IntervalDetail) => {
        onCompletedItemCallback(response);
        updateState((prev) => ({
          isSaving: false,
          isCompleted: !prev.isCompleted,
        }));
      })
      .catch((err) => {
        updateState({
          isSaving: false,
        });
      });
  };

  const getPreviousInterval = () => {
    const currentInterval = intervals?.filter((inverval: Interval) =>
      inverval.intervalDetails.find(
        (intervalDetail: IntervalDetail) =>
          intervalDetail.id === item.id &&
          intervalDetail.bedAssignment.patient.id === item.bedAssignment.patient.id
      )
    )?.[0];
    const indexOf = intervals?.indexOf(currentInterval!) ?? -1;
    if (indexOf !== -1) {
      const previousInterval = intervals?.[indexOf - 1];
      if (previousInterval) {
        return previousInterval;
      }
    }

    return null;
  };

  const checkIntervalDetails = (intervalDetail: IntervalDetail) => {
    if (
      intervalDetail.bedAssignment.bed.id === item.bedAssignment.bed.id &&
      intervalDetail.bedAssignment.patient.id === item.bedAssignment.patient.id
    ) {
      return true;
    }

    return false;
  };

  const getLastObservedLabel = () => {
    let lastObservedDate: Date | undefined;
    const previousInterval = getPreviousInterval();

    if (previousInterval) {
      lastObservedDate = previousInterval.intervalDetails.find((i: IntervalDetail) =>
        checkIntervalDetails(i)
      )?.completedDatetime;
    }

    // take the current item complete date if it is completed and there are not previous interval
    if (item.completedDatetime) {
      lastObservedDate = item.completedDatetime;
    }

    if (lastObservedDate) {
      return (
        `${shortFormat(moment(lastObservedDate).toDate())}-
        ${shortHourFormat(moment(lastObservedDate).toDate())}`
      );
    }

    return null;
  };

  const getMissedIntervalsLabel = () => {
    let missedIntervalsCount = 0;

    intervals
      ?.filter((interval: Interval) => moment(interval.end).toDate() < new Date())
      .forEach((interval: Interval) => {
        interval.intervalDetails.forEach((intervalDetail: IntervalDetail) => {
          if (checkIntervalDetails(intervalDetail) && !intervalDetail.isCompleted) {
            missedIntervalsCount++;
          }
        });
      });

    return (
      <Typography sx={{ p: 1, fontSize: '9px', color: 'gray' }}>
        Missed Intervals: <span style={{ fontWeight: 'bold' }}>{missedIntervalsCount}</span>
      </Typography>
    );
  };

  const getCompleteIconColor = () => {
    if (
      isCompleted === true &&
      (item.patientActivity?.valueCode === 'unknown-not-observed' ||
        item.patientActivity?.valueCode === 'at-appointment')
    )
      return '#e1b12c';
    return isCompleted ? '#00b894' : '#b2bec3';
  };

  return (
    <IntervalDetailItemContext.Provider
      value={{
        item,
        sections,
        onCompletedItemCallback,
      }}
    >
      {showSections && (
        <IntervalDetailSectionsModal
          visible={showSectionsModal}
          onClose={() =>
            updateState({
              showSectionsModal: false,
              sectionToExpand: undefined,
            })
          }
          section={sectionToExpand}
        />
      )}
      <TableRow>
        <TableCell>
          <PatientCard id={patientId} showCloseButton={false} />
        </TableCell>
        <TableCell>
          <Typography fontSize={13}>{item.bedAssignment.bed?.identifier}</Typography>
        </TableCell>
        <TableCell>
          <Grid container direction="row" style={{ flexWrap: 'nowrap' }}>
            <Grid item>
              <FormControl fullWidth style={{ paddingRight: '5px' }}>
                <Autocomplete
                  options={
                    roundingContext.patientActivities?.map((a) => ({
                      label: a.valueDisplayName,
                      value: a.id,
                    })) ?? []
                  }
                  value={patientActivityItem}
                  onChange={(_: any, value: any | null) =>
                    updateState({ patientActivityItem: value })
                  }
                  getOptionLabel={(item) => item.label}
                  disabled={isCompleted}
                  renderInput={(params) => <TextField required {...params} error={patientActivityItem ? false : true} />}
                  style={{
                    width: 200,
                    marginTop: 30,
                  }}
                />
                <Typography sx={{ p: 1, fontSize: '9px', color: 'gray' }}>
                Last Observed:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {getLastObservedLabel() ?? 'Not observed'}
                </span>
              </Typography>
                
              </FormControl>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid item>
            <FormControl fullWidth>
              <Autocomplete
                options={
                  roundingContext.patientLocations?.map((a) => ({
                    label: a.valueDisplayName,
                    value: a.id,
                  })) ?? []
                }
                value={patientLocationItem}
                onChange={(_: any, value: any | null) =>
                  updateState({ patientLocationItem: value })
                }
                getOptionLabel={(item) => item.label}
                disabled={isCompleted}
                renderInput={(params) => <TextField required {...params} error={patientLocationItem ? false : true} />}
                style={{
                  width: 200,
                  marginTop: 30,
                }}
              />
              <Typography sx={{ fontSize: '11px', color: 'gray' }}>
                {getMissedIntervalsLabel()}
              </Typography>
            </FormControl>
          </Grid>
        </TableCell>
        <TableCell>
          <Grid style={{ minWidth: '230px' }}  container flexDirection="row">
            {sections.length > 0 && (
              <Tooltip title={sectionIcons[0].section.toUpperCase()}>
                <IconButton
                  sx={{
                    marginRight: 1,
                    padding: 0,
                    top: '-1px',
                  }}
                >
                  <Iconify
                    key={sectionIcons[0].icon}
                    icon={sectionIcons[0].icon}
                    sx={{
                      height: 25,
                      width: 25,
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      updateState({
                        showSectionsModal: true,
                        sectionToExpand: sectionIcons[0].section,
                      })
                    }
                  />
                </IconButton>
              </Tooltip>
            )}
            {showSections && (
              <>
                {sectionIcons
                  .filter((currentSection) =>
                    sections.find((sectionTitle) => sectionTitle === currentSection.section)
                  )
                  .map((section) => (
                    <Tooltip key={section.icon} title={section.section.toUpperCase()}>
                      <IconButton
                        sx={{
                          padding: 0,
                          cursor: 'pointer',
                          top: '-1px',
                        }}
                      >
                        <Iconify
                          icon={section.icon}
                          sx={{
                            marginRight: 1,
                            height: 25,
                            width: 25,
                            cursor: 'pointer',
                            ...section.customStyle,
                          }}
                          onClick={() =>
                            updateState({
                              showSectionsModal: true,
                              sectionToExpand: section.section,
                            })
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  ))}
              </>
            )}
          </Grid>
        </TableCell>
        <TableCell>
          <Tooltip title={'Health Record'}>
            <IconButton
              sx={{
                cursor: 'pointer',
                top: '0px',
              }}
            >
              <a style={{ top: '100px' }} target="_blank" href={`/dashboard/patient/${patientId}/health-record`} rel="noreferrer">
                <SvgIconStyle
                  src={`/assets/icons/navbar/ic_record.svg`}
                  sx={{
                    marginRight: 1,
                    height: 20,
                    width: 20,
                    cursor: 'pointer',
                    color: 'black',
                  }}
                />
              </a>
            </IconButton>
          </Tooltip>
          <Tooltip title={'Care Plan'}>
            <IconButton
              sx={{
                cursor: 'pointer',
                top: '0px',
              }}
              onClick={() =>
                updateState({
                  showSectionsModal: true,
                  sectionToExpand: SectionEnum.carePlan,
                })
              }
            >
              <SvgIconStyle
                src={`/assets/icons/navbar/ic_collaboration.svg`}
                sx={{
                  marginRight: 1,
                  height: 20,
                  width: 20,
                  cursor: 'pointer',
                  color: 'black',
                }}
              />
              {/* <Iconify
                icon={'material-symbols:grid-view-outline-rounded'}
                sx={{
                  marginRight: 1,
                  height: 30,
                  width: 30,
                  cursor: 'pointer',
                }}
                onClick={() => updateState({
                  showSectionsModal: true,
                  sectionToExpand: SectionEnum.carePlan,
                })}
              /> */}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          {isSaving ? (
            <CircularProgress />
          ): (
            <Iconify
              icon="material-symbols:check-circle-outline-rounded"
              sx={{
                marginRight: 1,
                height: 50,
                width: 50,
                cursor: 'pointer',
              }}
              color={getCompleteIconColor()}
              onClick={onToggleCompleted}
            />
          )}
          
        </TableCell>
      </TableRow>
    </IntervalDetailItemContext.Provider>
  );
};

export default IntervalDetailItem;
