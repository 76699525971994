export enum PATEINTS_LIST_PERSON {
  MY_PATIENTS = 'MY PATIENTS',
  ALL_PATIENTS = 'ALL PATIENTS',
}

export const getColumns = () => [
  { label: 'Name' },
  {
    label: 'ID',
  },
  // {
  //   label: 'Health Plan / Program',
  // },
  {
    label: 'Primary Phone',
  },
  {
    label: 'Status',
  },
  {
    label: 'Last Note',
  },
  {
    label: 'Last Activity Date',
  },
  {
    label: 'Next Activity',
  },
  {
    label: 'Care Team Lead',
  }
];
