import {
  Backdrop,
  Button,
  CircularProgress,
  DialogActions,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { DatePicker } from '@mui/lab';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { useMemo, useState } from 'react';
import getFileData from 'src/utils/getFileData';
import { isEmpty, isEqual, isNil } from 'lodash';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { UploadFiles } from 'src/sections/crs/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOrganizations } from 'src/@nicheaim/fhir-react';
import { InsuredOptionProps } from 'src/@types/crs/referral';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import {
  DocumentReference,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

const HOST_API = process.env.REACT_APP_HOST_API;
const FHIR_API = process.env.REACT_APP_FHIR_API_BASE_URL;

type FormValues = {
  beginDate: Date;
  documents: string[];
  documentsPHIN: DocumentReference[];
  files: Blob[];
  insuranceId: string;
  insurance: string;
};

type Props = InsuredOptionProps;

export default function StateMedicaid({
  patient,
  documentReference,
  coverage,
  serviceRequest,
  handleCreateBynary,
  handleCoverage,
  handleCreateDocumentReference,
  handleUpdateDocumentReference,
  handleUpdateResource,
  onCancel,
}: Props) {
  
  const { enqueueSnackbar } = useSnackbar();

  const authUser = useAuth().getCurrentUser();

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [ organization, ] = useOrganizations();

  const EventSchema = Yup.object().shape({
    insuranceId: Yup.string().required('Medicare Number is required'),
    beginDate: Yup.date().required('Expiration Date is required'),
  });

  let reference: string;
  if(serviceRequest){
   reference = `${serviceRequest?.resourceType}/${serviceRequest?.id}`;
  }else if(coverage){
    reference = `${coverage?.resourceType}/${coverage?.id}`;
  }

  const documentsRelated = useMemo(
    () => {
      let docs;
      if(serviceRequest && coverage){
        docs = documentReference?.
          filter(d => d.context?.related?.
            find( r => r.reference === `${serviceRequest?.resourceType}/${serviceRequest?.id}`
              && r.reference === `${coverage?.resourceType}/${coverage?.id}`)).
                map((e) => e.id)

      }else if(coverage){
        docs = documentReference?.
        filter(d => d.context?.related?.
          find( r => r.reference === `${coverage?.resourceType}/${coverage?.id}`)).
            map((e) => e.id)
      }else if(serviceRequest){
        docs = documentReference?.
        filter(d => d.context?.related?.
          find( r => r.reference === `${serviceRequest?.resourceType}/${serviceRequest?.id}`)).
            map((e) => e.id)
      }
      return docs;
    },
    [documentReference, serviceRequest, coverage]
  );

  const defaultValues = {
    insuranceId: coverage?.subscriberId || '',
    beginDate: coverage?.period?.start || new Date(),
    documents: documentsRelated || [],
  };

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const { control, handleSubmit } = methods;

  const convertBlobToBase64 = (file: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result?.toString().split(',')[1]);
      };
      reader.readAsDataURL(file);
    });

  const createBinaryCustom = async (data: FormValues): Promise<any> => {
    if (!data?.files?.length) return null;

    const resourceType = 'Binary';

    const mappedFile =  await Promise.all( 
      data.files.map(async (file: Blob) => {
        const contentType = file.type;
        const data = await convertBlobToBase64(file);
        const securityContext = { reference: `${patient?.resourceType}/${patient?.id}` };
        const name = getFileData(Object.assign(file)).name;
        const obj = { resourceType, contentType, securityContext, data};
        const binary = await handleCreateBynary(obj);

        const objNew = {...binary[0], name: name};
        return objNew;
      })
    );

    return mappedFile;
  };

  const createDocumentReference = async (binaryList: any) => {

    const organizationFhir = organization?.
    filter(e => e.id === authUser.organization_fhir_uri).
    map((r) => ({
      display: r.name, 
      reference: `Organization/${r.id}`
    }))[0];

    const documentReference = binaryList?.map((binary:any) => ({ 
      resourceType: 'DocumentReference',
      status: 'current',
      subject: { reference: `${patient?.resourceType}/${patient?.id}` },
      author: [{ 
        ...(!isEmpty(authUser.user_fhir_uri.trim()) && {
          reference: `Practitioner/${getFhirIdFromEntity(authUser.user_fhir_uri)}` 
        }), 
        display: authUser.name
      }],
      ...(organizationFhir && {
        custodian: organizationFhir
      }),
      date: new Date().toISOString(),
      content: [{ attachment: { 
        contentType: `${binary.contentType}`, 
        creation: new Date().toISOString(), 
        title: binary.name,
        url: `${FHIR_API}/Binary/${binary.id}` 
      }}],
     }));

     const createdDocumentReference = await handleCreateDocumentReference(documentReference);
     
    return createdDocumentReference;
  };
  
  const createDocumentReferencePHIN = async (data: DocumentReference[]) : Promise<any> => {
    
    if(data === undefined) return null;
    
    try{
      const document = data.map((doc) => {
        const documentUniqueId = doc?.identifier?.find((i) => i?.type?.text === 'documentUniqueId')?.value;
        const repositoryUniqueId = doc?.identifier?.find((i) => i?.type?.text === 'repositoryUniqueId')?.value;
        const hcid = doc?.identifier?.find((i) => i?.type?.text === 'hcid')?.value;

        doc.content[0].attachment.url = `${HOST_API}/crs/document/${documentUniqueId}?repositoryId=${repositoryUniqueId}&hcid=${hcid}`;
        doc.subject = { reference: `${patient?.resourceType}/${patient?.id}` };
  
        if(doc.context?.period?.end === null) delete doc.context?.period?.end;
        delete doc.content[0].attachment.data;
        delete doc.id;
  
        const { ...result } = doc;
        return result;
      })
  
      const documentReference = await handleCreateDocumentReference(document);

      return documentReference;

    }catch(e) {
      enqueueSnackbar('An error has occurred.', { variant:'error', autoHideDuration: 6000 });
    }
  };

  const mapCoverage = (data: any) => {
    const { insuranceId, beginDate } = data;

    const mapCoverage = {
      resourceType: 'Coverage',
      status: 'active',
      subscriberId: insuranceId,
      beneficiary: { reference: `${patient?.resourceType}/${patient?.id}` },
      payor: [{ display: 'stateMedicaid_Centers for Medicare & Medicaid Services (CMS)' }],
      class: [
        {
          type: {
            coding: [
              {
                system: 'http://terminology.hl7.org/CodeSystem/coverage-class',
                code: 'plan',
              },
            ],
          },
          value: 'state medicaid',
          name: 'State Medicaid',
        },
      ],
      period: {
        start: beginDate.toISOString(),
      },
    };

    return mapCoverage;
  };

  const updateDocumentsSelected = async (
    data: FormValues, 
    createdDocs: DocumentReference[] | undefined, 
    createdPhinDocs: DocumentReference[] | null, 
    resourceToRelate: any
  ): Promise<any> => {

    const setServiceRequest = serviceRequest !== undefined ? 
      [`${serviceRequest?.resourceType}/${serviceRequest?.id}`] : [];

    const setCoverage = !isNil(coverage) ? 
      `${coverage?.resourceType}/${coverage?.id}` :
       Array.isArray(resourceToRelate) ? 
        `${resourceToRelate?.[0]?.resourceType}/${resourceToRelate?.[0]?.id}` :
        `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`;

    const setCoverageArray = [setCoverage] ?? [];

    const { documents } = data;

    const delRelatedResource = documentsRelated?.filter((e:any) => !documents?.some((s:any) => s === e));

    const documentsNotRelated = documentReference?.filter(p => delRelatedResource?.
      some((f:any) => p.id === f)).
        map((l) =>{

          const related = l.context?.related || [];  

          const setReferences = [...setServiceRequest, ...setCoverageArray];
          const notRelated = related?.
            filter(r => !setReferences?.some((x1) => x1 === r.reference)) || [];
          
          if(l.context?.related && !isEmpty(notRelated)){ 
            l.context.related = [ ...notRelated ];
          }else{
            delete l.context?.related;
          }

          if(isEmpty(l.context)){
            delete l.context;
          }

          const {...result} = l;
          return result;
    })|| [];

    const documentsSelected = documents?.filter((e:any) => !documentsRelated?.some((s:any) => s == e));

    const documentReferenceSelected = documentReference?.filter(d => documentsSelected?.
      some((r: any) => d.id === r)).
        map((e) => {
          const context = e.context;
          const related = e.context?.related || [];  

          let resourceRelated: any;
          resourceRelated = related.
            filter(r => setServiceRequest?.some((x1) => x1 === r.reference));

          if(setCoverageArray?.length > 0){
            resourceRelated = resourceRelated?.
              filter((j: any) => setCoverageArray?.some((x1) => x1 === j.reference))
          }
            
          if(isEmpty(resourceRelated)){
            const setReferences = [...setServiceRequest, ...setCoverageArray];
            const mapReference: any = setReferences?.map((x2) => ({reference: x2 })); 
            related?.push(...mapReference);
          }

          if(isEmpty(resourceRelated)){
            e.context = { 
              ...context, 
              related: [ 
                ...related
            ]};
          }else {
            e.context = {...context, related: [ ...related ]};
          }

          const {...result} = e;
          return result;
    }) || [];

    const createdDocuments = createdDocs?.map((e) => {
      const context = e.context;
      const related = e.context?.related || [];

      let resourceRelated: any;
          resourceRelated = related.
            filter(r => setServiceRequest?.some((x1) => x1 === r.reference));

          if(setCoverageArray?.length > 0){
            resourceRelated = resourceRelated?.
              filter((j: any) => setCoverageArray?.some((x1) => x1 === j.reference))
          }
            
      if(isEmpty(resourceRelated)){
        const setReferences = [...setServiceRequest, ...setCoverageArray];
        const mapReference: any = setReferences?.map((x2) => ({reference: x2 })); 
        e.context = {...context, related: [ ...related, ...mapReference]};
      }else {
        e.context = {...context, related: [ ...related ]};
      }
      const {...result} = e;
      return result;
    }) || [];

    const phinDocuments = createdPhinDocs?.map((e) => {
      const context = e.context;
      const related = e.context?.related || [];

      let resourceRelated: any;
      resourceRelated = related.
        filter(r => setServiceRequest?.some((x1) => x1 === r.reference));

      if(setCoverageArray?.length > 0){
        resourceRelated = resourceRelated?.
          filter((j: any) => setCoverageArray?.some((x1) => x1 === j.reference))
      }
        
      if(isEmpty(resourceRelated)){
        const setReferences = [...setServiceRequest, ...setCoverageArray];
        const mapReference: any = setReferences?.map((x2) => ({reference: x2 })); 
        e.context = {...context, related: [ ...related, ...mapReference]};
      }else {
        e.context = {...context, related: [ ...related ]};
      }
      const {...result} = e;
      return result;
    }) || [];

    const allDocuments = [ ...documentsNotRelated, ...documentReferenceSelected, ...createdDocuments, ...phinDocuments];

    const updateDocumentReference = await handleUpdateDocumentReference(allDocuments);

    if(!isNil(serviceRequest)){
      const relateDocuments = [ ...documentReferenceSelected, ...createdDocuments, ...phinDocuments];
      const getDocuments = mapDocumentReferenceToResource(resourceToRelate, relateDocuments, documentsNotRelated);

      if(getDocuments.length > 0 && serviceRequest){
        serviceRequest.supportingInfo = getDocuments;
      }else if(serviceRequest){
        delete serviceRequest.supportingInfo;
      }

      handleUpdateResource(serviceRequest);
    }

    return allDocuments;
  };

  const updateDocuments = ( data: FormValues ) => {
    const { documents, files, documentsPHIN } = data;

    const arraysDocsEqual = isEqual(documents, documentsRelated);

    if((documentsRelated && 
      (documents.length !== documentsRelated.length)) || 
      !arraysDocsEqual ||
      files?.length > 0 ||
      documentsPHIN?.length > 0
    ){
      return true;
    }
    return false;
  };

  const mapDocumentReferenceToResource = (
    resource: any, 
    documentReference: DocumentReference[], 
    notRelate: DocumentReference[]
  ) => {
    const document = documentReference?.map((doc) => (`${doc?.resourceType}/${doc?.id}`));
    const notRelateMap = notRelate?.map((nDoc) => (`${nDoc?.resourceType}/${nDoc?.id}`));
    const supportingInfo = resource?.supportingInfo?.map((sd) => (`${sd?.reference}`)) ?? [];
    const setDocument = [...new Set([...supportingInfo, ...document])];
    const allDocuments = setDocument?.
      filter((x1) => !notRelateMap.
        some((x2) => x1 === x2)).map((x3) => ({reference: x3 })); 
    return allDocuments;
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setOpenBackdrop(true);

      let documentReferences;
      let documentPHIN;
      let resourceToRelated: any;

      if(data.files !== undefined){
        const createBinary = await createBinaryCustom(data);
        documentReferences = await createDocumentReference(createBinary);
      } 
      
      if (data?.documentsPHIN?.length) {
        documentPHIN = await createDocumentReferencePHIN(data.documentsPHIN) || [];
      }

      const mapCoverageResult = mapCoverage(data);
      if((Array.isArray(mapCoverageResult) && mapCoverageResult?.length > 0) || !isEmpty(mapCoverageResult)){
        resourceToRelated = await handleCoverage(mapCoverageResult);
      }

      const update = updateDocuments(data);

      if(update){
        const updateDocuments = await updateDocumentsSelected(data, documentReferences, documentPHIN, resourceToRelated);
      }
      
      setOpenBackdrop(false);
      onCancel();
    } catch (e) {
      onCancel();
      enqueueSnackbar('An error has occurred.', { variant: 'error' });
    }
  };

  return (
    // @ts-ignore
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3} sx={{ p: 2 }}>
        <Grid item xs={6}>
          <Stack direction="column" alignItems="left" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
              Medicaid Number
            </Typography>
            <RHFTextField name="insuranceId" label="" />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="column" alignItems="left" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
              Begin Date
            </Typography>
            <Controller
              name="beginDate"
              control={control}
              defaultValue={new Date()}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth error={!!error} helperText={error?.message} />
                  )}
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
      <Typography variant="body2" sx={{ mt: 1, ml: 2 }}>
        Medicaid Card Front and Back
      </Typography>
      <UploadFiles
        label='insurance'
        restrictFiles={true}
        documentReferences={documentReference}
        patient={patient}
        docRelated={documentsRelated}
      />
      <Stack alignItems="center">
        <DialogActions>
          <Button variant="outlined" color="info" type="submit">
            Save
          </Button>
        </DialogActions>
      </Stack>
    </FormProvider>
  );
}
