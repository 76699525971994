// @ts-nocheck
import {
  Alert,
  Box,
  Divider,
  FormControl,
  Grid,
  Typography,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import { useCarePlan, useCommunications } from 'src/@nicheaim/fhir-react';
import useAuth from 'src/hooks/useAuth';
import {
  Annotation,
  CarePlan,
  Communication,
  Reference,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import { getFhirIdFromReferenceString } from '../../../tasks-activities/components/Activities/activities-utils';
import useAddEntityRequestStates from 'src/hooks/useAddEntityRequestStates';
import { PatientCard, SummaryTable } from 'src/sections/careflow/common';
import Iconify from 'src/components/Iconify';
import useObjectState from 'src/hooks/useObjectState';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';
import fhirSystem from 'src/fhir/system';

export type CarePlanNotesPropsType = {
  carePlan: CarePlan;
  patient: {
    id: string;
  };
  onCancel: () => void;
};

const CarePlanNotes = ({ carePlan: propCarePlan, onCancel, patient }: CarePlanNotesPropsType) => {
  const [{ note }, updateState] = useObjectState({
    note: '',
  });
  const [{ isLoading, error, success }, { setIsLoading, setError, setSuccess }] =
    useAddEntityRequestStates();
  const authUser = useAuth().getCurrentUser();
  const [carePlan, { update: updateCarePlan, refresh }] = useCarePlan(propCarePlan.id);
  const [_, { create: createCommunication }] = useCommunications({
    autofetch: false,
  });
  const [communications] = useCommunications({
    filter: {
      'part-of': propCarePlan.id,
    },
    autofetch: true
  })

  const validateForm = () => {
    if (!note) {
      return false;
    }

    return true;
  };

  const onSavePress = () => {
    setIsLoading(true);
    if (!validateForm()) {
      setSuccess(null);
      setError('Please fill the note field');
      setIsLoading(false);
      return;
    }

    const ownerId = getFhirIdFromReferenceString(authUser.user_fhir_uri);
    const creationDate = moment.utc().toISOString();

    const newNote: Annotation = {
      text: note.replace(/<[^>]+>/g, ''),
      authorReference: {
        reference: `Practitioner/${ownerId}`,
        display: authUser.name,
      },
      time: creationDate,
    };

    const newCommunication: Communication = {
      resourceType: 'Communication',
      sent: creationDate,
      status: 'completed',
      note: [
        {
          text: note.replace(/<[^>]+>/g, ''),
          authorReference: {
            reference: `Practitioner/${ownerId}`,
            type: 'Practitioner',
          },
          time: creationDate,
        },
      ],
      text: {
        status: 'empty',
        div: note,
      },
      subject: {
        reference: `Patient/${patient?.id}`,
        type: 'Patient',
      },
      partOf: [
        {
          reference: `CarePlan/${carePlan?.id}`,
        },
      ],
      sender: {
        reference: `Practitioner/${ownerId}`,
        type: 'Practitioner',
        display: authUser.name,
      },
    };

    const lastUpdateExtension = carePlan?.extension?.find(i => i.url === fhirSystem.extension.CarePlan.lastUpdated.asString());

    if (lastUpdateExtension) {
      const index = carePlan?.extension?.indexOf(lastUpdateExtension) ?? -1;
      if (index !== -1) {
        carePlan.extension[index].valueDateTime = new Date().toISOString();
      }
    } else {
      carePlan?.extension?.push({
        url: fhirSystem.extension.CarePlan.lastUpdated.asString(),
        valueDateTime:new Date().toISOString(),
      });
    }

    updateCarePlan({
      ...(carePlan ?? {}),
      note: [...(carePlan?.note ?? []), newNote],
    })
      .then(() => {
        createCommunication(newCommunication)
          .then(() => {
            setError(null);
            setSuccess('Note saved successfully');
            setIsLoading(false);
            updateState({
              note: '',
            });
            refresh();
          })
          .catch((error) => {
            setError('Error saving note. Please try again');
            setSuccess(null);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setError('Error saving note. Please try again');
        setSuccess(null);
        setIsLoading(false);
      });
  };

  return (
    <Box
      sx={{
        borderRadius: 1,
        width: '70%',
        height: '100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        m: 5,
        overflowY: 'scroll',
      }}
    >
      <Box sx={{ my: 3, justifyContent: 'space-between', display: 'flex', width: '100%' }}>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Care Plan Notes
          </Typography>
        </Box>
        <Box sx={{ mt: -0.6 }}>
          <IconButton onClick={onCancel}>
            <Iconify icon="material-symbols:close-rounded" />
          </IconButton>
        </Box>
      </Box>
      <PatientCard id={patient?.id} showCloseButton={false} />
      <Box sx={{ pt: 2 }}>
        <Typography>
          <span style={{ fontWeight: 'bold' }}>Goal:</span> {propCarePlan?.parsed?.goal}
        </Typography>
      </Box>
      <Box sx={{ pt: 1 }}>
        <Typography>
          <span style={{ fontWeight: 'bold' }}>Intervention:</span>{' '}
          {propCarePlan?.parsed?.intervention}
        </Typography>
      </Box>
      <Divider sx={{ pt: 2 }} />
      <Grid item style={{ marginTop: '15px' }}>
        <FormControl fullWidth>
          <ReactQuill theme="snow" value={note} onChange={(val) => updateState({ note: val })} />
        </FormControl>
      </Grid>
      {error && (
        <Alert style={{ marginTop: '20px' }} severity="error">
          {error}
        </Alert>
      )}
      {success && (
        <Alert style={{ marginTop: '20px' }} severity="success">
          {success}
        </Alert>
      )}
      <ActionButtons
        isLoading={isLoading}
        leftButtonTitle="Clear note"
        onLeftButtonPress={() => updateState({ note: '' })}
        onRightButtonPress={onSavePress}
      />
      <Divider sx={{ mb: 2, mt: 2 }} />
      <SummaryTable
        columns={[
          {
            header: 'Date',
            field: 'sent',
            render(value: string) {
              return moment(value).format('MMM DD Y - hh:mm a');
            },
          },
          {
            header: 'Note',
            field: 'text',
            render(value: string) {
              return parse(value.div) ?? 'N/A';
            },
          },
          {
            header: 'Staff',
            field: 'sender',
            render(value: Reference) {
              return value.display ?? 'N/A';
            },
          },
        ]}
        // data={(carePlan?.note ?? []).sort(
        //   (a, b) => moment(b.time).toDate().getTime() - moment(a.time).toDate().getTime()
        // )}
        data={communications}
      />
    </Box>
  );
};

export default CarePlanNotes;
