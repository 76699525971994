import Viewer from './Viewer';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { useForm } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import getFileData from 'src/utils/getFileData';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { useCallback, useEffect, useState } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import BlockContent from 'src/components/upload/BlockContent';
import { useBinarys, useOrganizations } from 'src/@nicheaim/fhir-react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { DocumentReference } from 'src/@nicheaim/fhir-base/mappings/DocumentReference';
import { Backdrop, Box, Button, Card, CircularProgress, Dialog, DialogTitle, DialogActions, Grid, Stack } from '@mui/material';

const FHIR_API = process.env.REACT_APP_FHIR_API_BASE_URL;

type Props = {
  open: boolean;
  patient: WrappedPatient | null;
  resource?: any;
  createDocumentReference: any;
  refreshDocumentReference: any;
  handleUpdateResource: (data: any) => Promise<any>;
  onCancel: VoidFunction;
};

type FormValues = {
  file: any;
  name?: string;
  type?: string;
  preview?: string;
  author?: string;
};

export default function AttachmentAddForm({ 
  open, 
  patient, 
  resource, 
  createDocumentReference,
  refreshDocumentReference,
  handleUpdateResource,
  onCancel
}: Props) {

  const defaultValues = {
    file: '',
    name: '',
    type: '',
    preview: '',
    author: '',
  };

  const methods = useForm<FormValues>({ 
    defaultValues
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit
  } = methods;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      
      if (file) {
        setValue(
          'file',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const fileUp = watch('file');
  const { enqueueSnackbar } = useSnackbar();
  const authUser = useAuth().getCurrentUser();
  const [ organization, ] = useOrganizations();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [, { create: createBinary }] = useBinarys({ autofetch: false });

  useEffect(() => {
    if(fileUp){
      setValue('name',getFileData(fileUp).name);
      setValue('type',getFileData(fileUp).type);
      setValue('author', authUser.name);
    }
  },[fileUp]);

  const handleCreateBynary = async (data: any) => {
    const result = await createBinary(data);
    return result;
  };

  const convertBlobToBase64 = (file: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result?.toString().split(',')[1]);
    };
    reader.readAsDataURL(file);
  });

  const createBinaryCustom = async (file: FormValues): Promise<any> => {

    if(!file?.file) return null;

    const resourceType = 'Binary';
    const contentType = file?.type;
    const data = await convertBlobToBase64(file?.file);
    const securityContext = { reference: `${patient?.resourceType}/${patient?.id}` };
    const name = file.name;
    const obj = { resourceType, contentType, securityContext, data};
    const binary = await handleCreateBynary(obj);
    const objNew = {...binary[0], name: name};
    return objNew;

  };

  const mapFormDataToDocumentReference = (binary: any) => {

    const organizationFhir = organization?.
    filter(e => e.id === authUser.organization_fhir_uri).
    map((r) => ({
      display: r.name, 
      reference: `Organization/${r.id}`
    }))[0];

    const documentReference = { 
      resourceType: 'DocumentReference',
      status: 'current',
      subject: { reference: `${patient?.resourceType}/${patient?.id}` },
      author: [{ 
        ...(!isEmpty(authUser.user_fhir_uri.trim()) && {
          reference: `Practitioner/${getFhirIdFromEntity(authUser.user_fhir_uri)}` 
        }), 
        display: authUser.name
      }],
      ...(organizationFhir && {
        custodian: organizationFhir
      }),
      date: new Date().toISOString(),
      content: [{ attachment: { 
        contentType: `${binary?.contentType}`, 
        creation: new Date().toISOString(), 
        title: binary.name,
        url: `${FHIR_API}/Binary/${binary?.id}` 
      }}],
      ...(resource && {context:{ related: [{ reference: `${resource?.resourceType}/${resource?.id}` }]}})
     };
     
    return documentReference;
  };

  const mapDocumentReferenceToResource = (
    documentReference: DocumentReference[], 
  ) => {
    const document = documentReference?.map((doc) => (`${doc?.resourceType}/${doc?.id}`));
    const supportingInfo = resource?.supportingInfo?.map((sd) => (`${sd?.reference}`)) ?? [];
    const setDocument = [...new Set([...supportingInfo, ...document])];
    const allDocuments = setDocument?.map((x3) => ({reference: x3 })); 
    return allDocuments;
  };

  const onSubmit = async (data: FormValues) => {

    try {

      setOpenBackdrop(true);

      const resultBinary = await createBinaryCustom(data);
      const mapBinaryToDocumentReference = mapFormDataToDocumentReference(resultBinary);
      const saveDocumentReference = await createDocumentReference(mapBinaryToDocumentReference);
      const mapToResource = mapDocumentReferenceToResource(saveDocumentReference);

      if(mapToResource && resource){
        resource.supportingInfo = mapToResource;
        if(handleUpdateResource !== null) handleUpdateResource(resource);
      }
      refreshDocumentReference();
      enqueueSnackbar('Attachment was created');
      setOpenBackdrop(false);
      onClose();

    } catch (error) {
      enqueueSnackbar('Attachment was not created', { variant:'error' });
      setOpenBackdrop(false);
      onClose();
    }
  };

  const onClose = () => {
    onCancel();
    reset();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Add Attachment</DialogTitle>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              {fileUp === ''  ? ( <UploadFile onDrop={handleDrop} /> ) : (
                <Stack sx={{ m: 1 }}>
                  <Box
                    sx={{
                      m: 2,
                      display: 'grid',
                      columnGap: 2,
                      rowGap: 3,
                      gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                    }}
                  >
                    <RHFTextField disabled name="name" label="File Name" />
                    <RHFTextField disabled name="author" label="Author" />
                    <RHFTextField disabled name="type" label="File Type" />
                  </Box>

                  <Stack sx={{ m: 1 }}>
                    {fileUp && (
                      <Viewer file={fileUp} typeResource='blob' />
                    )}
                  </Stack>

                  <Stack spacing={2} alignItems="center">
                    <DialogActions>
                      <Box sx={{ flexGrow: 1 }} />

                      <Button variant="outlined" color="info" onClick={onClose} >
                        Cancel
                      </Button>

                      <Button variant="outlined" color="info" type="submit">
                        Save
                      </Button>
                    </DialogActions>
                  </Stack>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Card>
      </FormProvider>
    </Dialog>
  );
};


const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

type UploadFileProps = DropzoneOptions;

function UploadFile({ ...other }: UploadFileProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    ...other,
  });

  return (
    <DropZoneStyle
      {...getRootProps()}
      sx={{
        ...(isDragActive && { opacity: 0.72 }),
        ...((isDragReject) && {
          color: 'error.main',
          borderColor: 'error.light',
          bgcolor: 'error.lighter',
        })
      }}
    >
      <input {...getInputProps()} />
      <BlockContent />
    </DropZoneStyle>
  );
}