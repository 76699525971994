import { useEffect, useRef, useState } from 'react';
import { Navigate, useParams } from 'react-router';
import { useHealthcareServices, useList, useOrganizations } from 'src/@nicheaim/fhir-react';
import fhirSystem from 'src/fhir/system';
import NewAutomationForm, { NewAutomationFormProps } from './new';

export default function EditAutomationSheet() {
  const { sheet: sheetId = '' } = useParams();
  const [sheet, { isLoading }] = useList(sheetId);
  const [, { find: findOrganizations }] = useOrganizations();
  const [, { find: findHealthcareServices }] = useHealthcareServices();
  const [details, setDetails] = useState<NewAutomationFormProps['currentAutomation'] | null>(null);

  useEffect(() => {
    async function fetchDetails() {
      if (!sheet) {
        return;
      }

      const identifier = sheet.identifier?.find((identifier) =>
        identifier.system?.startsWith(fhirSystem.automation.activity.withId('').asString())
      );
      if (!identifier) {
        console.error('No identifier found');
        return;
      }

      const anchorEvent = identifier.system?.split('/').pop();
      if (!anchorEvent) {
        console.error('No anchor event found');
        return;
      }

      const programId = identifier.value;
      if (!programId) {
        console.error('No program ID found');
        return;
      }

      const [program] = await findHealthcareServices({ _id: programId });
      if (!program) {
        console.error('No program found');
        return;
      }

      const lobId = program.identifier
        ?.find((identifier) =>
          identifier.system?.startsWith(fhirSystem.program.withId('').asString())
        )
        ?.system?.split('/')
        .pop();
      if (!lobId) {
        console.error('No line of business ID found');
        return;
      }

      const [lob] = await findOrganizations({ _id: lobId });
      if (!lob) {
        console.error('No line of business found');
        return;
      }

      const planId = lob.identifier
        ?.find((identifier) =>
          identifier.system?.startsWith(fhirSystem.lineOfBusiness.withId('').asString())
        )
        ?.system?.split('/')
        .pop();
      if (!planId) {
        console.error('No plan ID found');
        return;
      }
      const [plan] = await findOrganizations({ _id: planId });
      if (!plan) {
        console.error('No plan found');
        return;
      }

      setDetails({
        id: sheet.id || '',
        plan,
        program,
        lob,
        anchorEvent,
      });
    }

    fetchDetails();
  }, [sheet, findOrganizations, findHealthcareServices]);

  if (!sheet) {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    return <Navigate to=".." />;
  }

  if (!details) {
    return <div>Loading...</div>;
  }

  return <NewAutomationForm isEdit currentAutomation={details} />;
}
