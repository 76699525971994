import { createContext } from "react";
import SessionParticipantType from "../../types/SessionParticipantType";
import SessionType from "../../types/SessionType";

type SessionTableContextType = {
  updateSessionParticipant: (sessionParticipants: SessionParticipantType) => void;
  updateSession: (session: SessionType) => void;
  updateSessionNote: (id: number, note: string) => void;
  openAddEntityModal: () => void;
  setSelectedSession: (session: SessionType) => void;
}

export const SessionTableContext = createContext<SessionTableContextType>({
  updateSessionParticipant: (sessionParticipants: SessionParticipantType) => {},
  updateSession: (sesion: SessionType) => {},
  updateSessionNote: (id: number, note: string) => {},
  openAddEntityModal: () => {},
  setSelectedSession: (session: SessionType) => {},
});