import { Navigate, Route, Routes } from 'react-router';
import ActivityTypesTable from './components/ActivityTypesTable';
import NewActivityTypeForm from './new';
import EditActivityType from './$activityType.edit';

export default function ActivityTypesIndex() {
  return (
    <Routes>
      <Route index element={<ActivityTypesTable />} />
      <Route path="new" element={<NewActivityTypeForm />} />
      <Route path=":activityType" element={<Navigate to="edit" />} />
      <Route path=":activityType/edit" element={<EditActivityType />} />
    </Routes>
  );
}
