import {
  Box,
  Typography,
  Card,
  List,
  ListItemButton,
  ListItemText,
  ListItem,
  Stack,
} from '@mui/material';
import api from 'src/services/api';
import { usePractitioner, useQuestionnaireResponses } from 'src/@nicheaim/fhir-react';
import Scrollbar from 'src/components/Scrollbar';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import fhirSystem from 'src/fhir/system';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { useActiveAssessments } from 'src/fhir/hooks/assessments';

interface AssessmentsSelectorFormProps {
  actionBar: any;
}

export default function AssessmentsSelectorForm({ actionBar }: AssessmentsSelectorFormProps) {
  const navigate = useNavigate();
  const { getCurrentUser } = useAuth();
  const { patientId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const patient = useCurrentPatient();
  const [, { create: createQuestionnaireResponse }] = useQuestionnaireResponses({
    autofetch: false,
  });
  const [availableAssessments = []] = useActiveAssessments({
    pagination: {
      pageSize: 1000,
    },
  });
  const { user_fhir_uri, name: currentUserName } = getCurrentUser();
  const practitionerId = getFhirIdFromEntity(user_fhir_uri);
  const [userPractitioner] = usePractitioner(practitionerId, {
    map: PractitionerWrapper,
  });

  /**
   * Start assessment on click.
   */
  async function handleAssessmentClick(assessmentId: string) {
    try {
      if (!patient) {
        throw new Error('No patient selected');
      }

      if (!userPractitioner) {
        throw new Error('Practitioner not found for the current user');
      }

      const assessment = availableAssessments.find((a) => a.id === assessmentId);
      if (!assessment) {
        throw new Error('Assessment not found');
      }

      const surveyId = assessment.identifier?.find(
        (i) => i.system === fhirSystem.assessments.assessment.asString()
      )?.value;
      if (!surveyId) {
        throw new Error(
          `Could not get Survey ID from assessment. Questionnaire ID: ${assessmentId}`
        );
      }

      const { token } = await api.assessments.startAssessment({
        assessmentId: surveyId,
        firstName: patient?.name?.[0].given?.[0] || '',
        lastName: patient?.name?.[0].family || '',
        email: patient.getPrimaryEmail()?.value || '',
      });

      const [questionnaireResponse] = await createQuestionnaireResponse({
        resourceType: 'QuestionnaireResponse',
        status: 'in-progress',
        authored: new Date().toISOString(),
        author: {
          id: userPractitioner.id,
          type: 'Practitioner',
          reference: `Practitioner/${userPractitioner.id}`,
          display: userPractitioner?.getFullName() || currentUserName,
        },
        identifier: {
          system: fhirSystem.assessments.response.withId(surveyId).asString(),
          value: token,
        },
        questionnaire: `Questionnaire/${assessment.id!}`,
        subject: {
          reference: `Patient/${patient.id!}`,
        },
      });

      navigate(
        `/dashboard/patient/${patientId}/care-record/assessments/${
          questionnaireResponse.id || ''
        }/fill`
      );
    } catch (error) {
      let { message } = error;

      if (axios.isAxiosError(error)) {
        message = Object.values(error.response?.data?.errors).join('\n');
      }

      enqueueSnackbar(message, { variant: 'error' });
    }
  }

  return (
    <Card sx={{ height: `calc(100vh - 200px)`, display: 'flex', flexDirection: 'column' }}>
      <TitleBar title="New assessment" actionBar={actionBar} />

      <Scrollbar>
        <List disablePadding>
          {availableAssessments.length ? (
            availableAssessments.map((assessment: any) => (
              <ListItemButton
                key={assessment.id}
                sx={{ py: 1 }}
                onClick={() => handleAssessmentClick(assessment.id)}
              >
                <ListItemText>{assessment.title}</ListItemText>
              </ListItemButton>
            ))
          ) : (
            <ListItem>
              <ListItemText sx={{ textAlign: 'center' }}>
                No assessments available.{' '}
                <Link to="/settings/assessments/new">Go to assessments settings</Link>
              </ListItemText>
            </ListItem>
          )}
        </List>
      </Scrollbar>
    </Card>
  );
}

function TitleBar({ title, actionBar }: any) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
      <Typography variant="subtitle1">{title}</Typography>
      <Box>{actionBar}</Box>
    </Stack>
  );
}
