import { has } from 'lodash';
import { createWrapper } from '../../fhir-react/base';

export const CommunicationWrapper = createWrapper('Communication', (communication) => ({
  getDefaultDateLocale(): string {
    return 'en-US';
  },
  getDefaultDateFormatOptions(): Intl.DateTimeFormatOptions {
    const dateFormat: Intl.DateTimeFormatOptions = {
      month: 'short',
      year: 'numeric',
      day: 'numeric',
    };

    return dateFormat;
  },
  getDateFormatOptionsShort(): Intl.DateTimeFormatOptions {
    const dateFormat: Intl.DateTimeFormatOptions = {
      month: 'numeric',
      year: 'numeric',
      day: 'numeric',
    };

    return dateFormat;
  },
  getSentDate(): Date | null {
    return communication.sent ? new Date(communication.sent) : null;
  },
  getSentDateDisplay(): string | null {
    const date = this?.getSentDate()?.toLocaleDateString(
      this.getDefaultDateLocale(),
      this.getDefaultDateFormatOptions()
    );
    return date ? date : null;
  },
  getSentDateDisplayShort(): string | null {
    return this.getSentDate()
      ? `${this.getSentDate()?.getFullYear()}-${this.getSentDate()?.getMonth()}-${this.getSentDate()?.getDate()}`
      : null;
  },
  getMessages(): string[] {
    if (communication.payload && communication.payload.length > 0) {
      const messages = communication.payload
        .filter((p) => has(p, 'contentString'))
        .map((p) => (p?.contentString ? p.contentString : ''));
      return messages;
    }
    return [];
  },
  getNote(): string[] {
    if (communication.note && communication.note.length > 0) {
      const messages = communication.note
        .filter((p) => has(p, 'text'))
        .map((p) => (p?.text ? p.text : ''));
      return messages;
    }
    return [];
  },
  hasMessages(): boolean {
    return this.getMessages().length > 0;
  },
  getMedium(): string | null {
    let result;
    if(communication?.medium?.length === 2) {
      result = `${communication?.medium?.[0].text} and ${communication?.medium?.[1].text}`
    }else if(communication?.medium?.length === 1){
      result = `${communication?.medium?.[0].text}`
    }    
    return result || null;   
  }
}));

export type WrappedCommunication = ReturnType<typeof CommunicationWrapper>;
