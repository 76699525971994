import _ from 'lodash';
import 'moment-timezone';
import moment from 'moment';
import agent from 'src/api/agent';
import { widgets } from '@ui-schema/ds-material';
import { UIMetaProvider } from '@ui-schema/ui-schema/UIMeta';
import { storeUpdater } from '@ui-schema/ui-schema/storeUpdater';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { relTranslator } from '@ui-schema/ui-schema/Translate/relT';
import { GridContainer } from '@ui-schema/ds-material/GridContainer';
import { getAddressFormated, mergeLineAddress } from 'src/utils/fhir';
import { createOrderedMap } from '@ui-schema/ui-schema/Utils/createMap';
import { injectPluginStack } from '@ui-schema/ui-schema/applyPluginStack';
import { UIStoreProvider, createStore } from '@ui-schema/ui-schema/UIStore';

const GridStack = injectPluginStack(GridContainer);

const ComplementaryInfoEdit = ({
  dataSchema,
  data,
  edit, 
  refreshWorkflow, 
  workflowInstance,
  handleEdit,
}: any) => {
  const { scope = {} } = data;
  const { checklistItem = {} } = scope;

  const addressFormated = getAddressFormated(
    checklistItem?.VALIDATE_HOME_ADDRESS_ITEM?.modifiedFields);

  const defaultStructure = {
    ...(checklistItem?.VALIDATE_ADDRESS_IN_COUNTY_ITEM?.validatedOn && {
      VALIDATE_ADDRESS_IN_COUNTY_ITEM: {
          validatedOn: 
            moment.utc(new Date(checklistItem?.VALIDATE_ADDRESS_IN_COUNTY_ITEM?.validatedOn)).
              format('YYYY-MM-DD'),
        }
      }),
    ...(checklistItem?.VALIDATE_HOME_ADDRESS_ITEM && {
      VALIDATE_HOME_ADDRESS_ITEM: {
        ...(checklistItem?.VALIDATE_HOME_ADDRESS_ITEM?.validatedOn && 
            { validatedOn: 
              moment.utc(new Date(checklistItem?.VALIDATE_HOME_ADDRESS_ITEM?.validatedOn)).
                format('YYYY-MM-DD')}),
          modifiedFields:{
            ...(checklistItem?.VALIDATE_HOME_ADDRESS_ITEM?.modifiedFields && 
              { ...addressFormated })
          }
        }
      }),
    ...(checklistItem?.CONTACT_CLIENT_ITEM && {
      CONTACT_CLIENT_ITEM: {
          ...(checklistItem?.CONTACT_CLIENT_ITEM?.validatedOn && 
            { validatedOn: 
              moment.utc(new Date(checklistItem?.CONTACT_CLIENT_ITEM?.validatedOn)).
                format('YYYY-MM-DD')}),
          modifiedFields:{
            ...(checklistItem?.CONTACT_CLIENT_ITEM?.modifiedFields?.contactOn && 
              { contactOn: 
                moment.utc(new Date(checklistItem?.CONTACT_CLIENT_ITEM?.modifiedFields?.contactOn)).
                  format('YYYY-MM-DD')}),
            ...(checklistItem?.CONTACT_CLIENT_ITEM?.modifiedFields?.nextContactOn && 
              { nextContactOn: 
                moment.utc(new Date(checklistItem?.CONTACT_CLIENT_ITEM?.modifiedFields?.nextContactOn)).
                  format('YYYY-MM-DD')})
          }
        }
      }),
    ...(checklistItem?.CONTACT_PCP_ITEM && {
      CONTACT_PCP_ITEM: {
          ...(checklistItem?.CONTACT_PCP_ITEM?.validatedOn && 
            { validatedOn: 
              moment.utc(new Date(checklistItem?.CONTACT_PCP_ITEM?.validatedOn)).
                format('YYYY-MM-DD')}),
          modifiedFields:{
            ...(checklistItem?.CONTACT_PCP_ITEM?.modifiedFields?.contactOn && 
              { contactOn: 
                moment.utc(new Date(checklistItem?.CONTACT_PCP_ITEM?.modifiedFields?.contactOn)).
                  format('YYYY-MM-DD')}),
            ...(checklistItem?.CONTACT_PCP_ITEM?.modifiedFields?.nextContactOn && 
              { nextContactOn: 
                moment.utc(new Date(checklistItem?.CONTACT_PCP_ITEM?.modifiedFields?.nextContactOn)).
                  format('YYYY-MM-DD')})
          }
        }
      }),
    ...(checklistItem?.CASE_CONTACT_ATTEMPT_ITEM && {
      CASE_CONTACT_ATTEMPT_ITEM: {
          ...(checklistItem?.CASE_CONTACT_ATTEMPT_ITEM?.validatedOn && 
            { validatedOn: 
              moment.utc(new Date(checklistItem?.CASE_CONTACT_ATTEMPT_ITEM?.validatedOn)).
                format('YYYY-MM-DD')}),
          modifiedFields:{
            ...(checklistItem?.CASE_CONTACT_ATTEMPT_ITEM?.modifiedFields?.contactOn && 
              { contactOn: 
                moment.utc(new Date(checklistItem?.CASE_CONTACT_ATTEMPT_ITEM?.modifiedFields?.contactOn)).
                  format('YYYY-MM-DD')}),
            ...(checklistItem?.CASE_CONTACT_ATTEMPT_ITEM?.modifiedFields?.nextContactOn && 
              { nextContactOn: 
                moment.utc(new Date(checklistItem?.CASE_CONTACT_ATTEMPT_ITEM?.modifiedFields?.nextContactOn)).
                  format('YYYY-MM-DD')})
          }
        }
      })
  };

  const formattedData = _.merge(checklistItem, defaultStructure);
  const schema: any = createOrderedMap(dataSchema);
  const [store, setStore] = useState<any>(() => createStore(createOrderedMap(checklistItem)));

  useEffect(() => {
    setStore(createStore(createOrderedMap(updatedData)));
  }, [data]);

  const updatedData = useMemo(() => formattedData, [data]);
  
  const onChange = useCallback(
    (actions) => {
      setStore(storeUpdater(actions));
    },
    [setStore]
  );

  useEffect(() => {
    if(edit){
      handleComplementaryInfo();
    }
  }, [edit])

  const handleComplementaryInfo = async () => {

    const parseStoreValues = store.valuesToJS();

    if(parseStoreValues.VALIDATE_HOME_ADDRESS_ITEM){
      const originalAddress = mergeLineAddress(parseStoreValues.VALIDATE_HOME_ADDRESS_ITEM?.modifiedFields);
      parseStoreValues.VALIDATE_HOME_ADDRESS_ITEM.modifiedFields = {...originalAddress};
    }

    const payloadForUpdateWorkflowinstance = {
      referenceId: workflowInstance?.referenceId,
      referenceName: workflowInstance?.referenceName,
      data: JSON.stringify({
        scope: {
          checklistItem: {
            ...parseStoreValues,
          },
        },
      }),
    };

    try {
      await agent.Workflow.updateWorkflowInstance(payloadForUpdateWorkflowinstance);
    } catch (err) {
      console.log('error on handleComplementaryInfo', err);
    } finally {
      refreshWorkflow(true);
    }

    refreshWorkflow(false);
    handleEdit();
  };

  return (
    <UIMetaProvider widgets={widgets} t={relTranslator}>
      <UIStoreProvider store={store} onChange={onChange} showValidity={true}>
        <GridStack isRoot schema={schema} />
      </UIStoreProvider>
    </UIMetaProvider>
  );
};

export default ComplementaryInfoEdit;
