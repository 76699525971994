import moment from 'moment';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';

export enum ReferralStatuses {
  draft = 'Requested',
  active = 'Accepted',
  completed = 'Closed',
  'on-hold' = 'Pending',
  revoked = 'Rejected',
  'entered-in-error' = 'Cancelled',
}

const defaultUsers: any[] = [];

export interface ServiceTypePayload {
  label: string;
  value: { type: string; healthcareService: any; organizationId: string };
}

export interface ReferralPayload {
  id: string | null;
  date: string;
  start: string;
  status: string;
  patientId: string | null;
  assignedTo: string;
  referredFrom: string;
  referredTo: string;
  locationOrganization: string;
  note: string | null;
  services: ServiceTypePayload[] | null;
  service: ServiceTypePayload | null;
  parentServiceRequest: WrappedServiceRequest | null | undefined;
}

export type AddReferralPropsType = {
  referral?: any | null | undefined;
  patient?: WrappedPatient | null;
  serviceRequest?: WrappedServiceRequest | null;
  openAdd: boolean;
  onCancel: VoidFunction;
};

export const defaultStateFieldsValues = (id: string, referral: WrappedServiceRequest | null) => ({
  date: referral?.occurrenceDateTime
    ? moment(referral?.occurrenceDateTime)?.format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD'),
  startHour: referral?.occurrenceDateTime ? moment(referral?.occurrenceDateTime)?.format('HH:mm') : moment().format('HH:mm'),
  referredFromItem: referral?.requester
    ? {
        label: referral?.requester?.display,
        value: referral?.requester?.reference,
      }
    : null,
  referredToItem: referral?.performer
    ? {
        label: referral?.getPerfomerByType('Organization')?.display,
        value: referral?.getPerfomerByType('Organization')?.reference,
      }
    : null,
  orgnization: null,
  organizaionItem: null,
  service: referral?.code?.coding?.[0].code ?? null,
  serviceTypeItem: referral?.code && referral?.getPerfomerByType('Organization')?.display
    ? `${referral?.code?.coding?.[0].code} | ${referral?.getPerfomerByType('Organization')?.display}` 
    : null,
  status: referral?.status ?? '',
  statusItem:
    referral?.status && ReferralStatuses[referral?.status as keyof typeof ReferralStatuses]
      ? {
          label: ReferralStatuses[referral?.status as keyof typeof ReferralStatuses],
          value: referral?.status,
        }
      : null,
  users: defaultUsers,
  assignedToItem: referral?.performer
    ? {
        label: referral?.getPerfomerByType('Practitioner')?.display,
        value: referral?.getPerfomerByType('Practitioner')?.reference,
      }
    : null,
  locationOrganization: referral?.locationReference 
    ? {
        label: referral?.locationReference?.find(e => e.type === 'Location')?.display,
        value: {
          locationId: referral?.locationReference?.find(e => e.type === 'Location')?.reference,
          organizationId: referral?.getPerfomerByType('Organization')?.reference
        }
      }
    : null,
  file: null,
  selectedFile: null,
  note: null,
  isLoading: false,
  success: null,
  openSearch: false,
});

export const REFERRAL_CREATE_ERROR_MESSAGE = 'Error saving the referral. Please try again';
