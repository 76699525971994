import { OrganizationManager } from 'src/@types/organization';
import client from '../../../services/api/_client';

export async function getOrganizationsList(tenantId?: number) {
  const { data } = await client.get(`/tenants/${tenantId}/organizations`);

  return data;
}

export async function getOrganization(uuid: string) {
  const { data } = await client.get(`/organizations/${uuid}`);

  return data;
}

export async function getUsersByOrganization(organizationId: number) {
  const { data } = await client.get(`/organizations/${organizationId}/users`);

  return data;
}

export async function createOrganization(payload: OrganizationManager) {
  const { data } = await client.post('/organizations', payload);

  return data;
}

export async function updateOrganization(payload: OrganizationManager, uuid?: string) {
  const { data } = await client.put(`/organizations/${uuid}`, payload);

  return data;
}

export async function deleteOrganization(uuid: string) {
  const { data } = await client.delete(`/organizations/${uuid}`);

  return data;
}
