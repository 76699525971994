import {
  Grid,
} from "@mui/material";
import {  ToggleButtonOptions } from "../../ToggleButtonOptions";
import {
  REFERRALS_LIST_STATUS,
  REFERRALS_LIST_TYPES,
  REFERRALS_LIST_PERSON,
} from '../constants';
import useAuth from "src/hooks/useAuth";
import { CCM_ACLS, checkAclValidation, getRelatedAcls } from "src/utils/permissions/permission.utils";

type ReferralsFiltersPropsType = {
  status: string;
  onChangeReferralsListStatus: (listStatus: REFERRALS_LIST_STATUS) => void;
  onChangeReferralsListType: (listType: REFERRALS_LIST_TYPES) => void;
  onChangeReferralsListPerson: (listType: REFERRALS_LIST_PERSON) => void;
  currentReferralsListType: REFERRALS_LIST_TYPES;
  currentReferralsListStatus: REFERRALS_LIST_STATUS;
  currentReferralsListPerson: REFERRALS_LIST_PERSON;
}

const listPersonItems = [
  {
    value: REFERRALS_LIST_PERSON.ANYONE,
    permissions: [CCM_ACLS.TASK_ACTIVITIES.REFERRALS.READ],
  },
  {
    value: REFERRALS_LIST_PERSON.ME,
    permissions: getRelatedAcls(CCM_ACLS.TASK_ACTIVITIES.ALL),
  },
]

const ReferralsFilters = ({
  status,
  onChangeReferralsListStatus,
  onChangeReferralsListType,
  onChangeReferralsListPerson,
  currentReferralsListType,
  currentReferralsListStatus,
  currentReferralsListPerson
}: ReferralsFiltersPropsType) => {
  const user = useAuth();
  return (
    <Grid item xl={12}>
      <Grid container spacing={4}>
        <Grid item>
          <ToggleButtonOptions
          selectedValue={currentReferralsListType}
            options={[
              { value: REFERRALS_LIST_TYPES.INBOUND },
              { value: REFERRALS_LIST_TYPES.OUTBOUND },
            ]}
            onOptionChange={(selectedOption) => onChangeReferralsListType(selectedOption.value as REFERRALS_LIST_TYPES)}
          />
        </Grid>
        <Grid item>
          <ToggleButtonOptions
            selectedValue={currentReferralsListStatus}
            options={[
              { value: REFERRALS_LIST_STATUS.ALL },
              { value: REFERRALS_LIST_STATUS.REQUESTED, label: 'REQUESTED' },
              { value: REFERRALS_LIST_STATUS.PENDING, label: 'PENDING' },
              { value: REFERRALS_LIST_STATUS.ACCEPTED, label: 'ACCEPTED' },
            ]}
            onOptionChange={(selectedOption) => onChangeReferralsListStatus(selectedOption.value as REFERRALS_LIST_STATUS)}
          />
        </Grid>
        <Grid item>
          <ToggleButtonOptions 
            selectedValue={currentReferralsListPerson}
            options={listPersonItems.filter(item => checkAclValidation({ user, acl: item.permissions }))}
            onOptionChange={(selectedOption) => onChangeReferralsListPerson(selectedOption.value as REFERRALS_LIST_PERSON)}/>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ReferralsFilters;