import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import useObjectState from '../../../../../../hooks/useObjectState';
import {
  Divider,
  Box,
  Grid,
  Typography,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  Checkbox,
  FormControlLabel,
  Alert,
  RadioGroup,
  Radio,
  Stack,
} from '@mui/material';
import { ActivityPriority, ActivityStatus } from '../../../model';
import { ConcreteActivity } from '../../../model/Activity';
import {
  defaultStateFieldsValues,
  ScheduleActivityPropsType,
  ScheduleActivityStateType,
} from '../constants';
import { getFhirIdFromReferenceString } from '../activities-utils';
import API from 'src/services/api';
import PatientCard from 'src/sections/careflow/common/PatientCard';
import { useCodeSystem, useCarePlans, useTask } from 'src/@nicheaim/fhir-react';
import useAuth from 'src/hooks/useAuth';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import { DatePicker } from '@mui/lab';
import fhirSystem from 'src/fhir/system';
import { CarePlanWrapper, WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import Label, { LabelColor } from 'src/components/Label';
import { CarePlan } from 'src/@nicheaim/fhir-base/mappings/CarePlan';
import useAddEntityRequestStates from 'src/hooks/useAddEntityRequestStates';
import { useUsersWithFhirUri } from 'src/services/api/users';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useActiveActivityDefinitions } from 'src/fhir/hooks/activities';
import { useActiveAssessments } from 'src/fhir/hooks/assessments';
import { useCarePlanStatuses } from 'src/services/api/care-plan/statuses';
import { useActiveCarePlans } from 'src/fhir/hooks/care-plans';

const ScheduleActivity = (props: ScheduleActivityPropsType) => {
  const { activity: propActivity, patient, shouldCompletedActivity = false } = props;
  const authUser = useAuth().getCurrentUser();
  const [state, updateState] = useObjectState<ScheduleActivityStateType>(
    defaultStateFieldsValues(getFhirIdFromReferenceString(authUser.user_fhir_uri) || '', propActivity)
  );
  const {
    activityItem,
    contactType,
    tool,
    toolItem,
    date,
    startHour,
    endHour,
    allDay,
    repeat,
    note,
    assignedTo,
    assignedToItem,
    reminder,
    contactMade,
    carePlans: selectedCarePlans,
    // start
    completeActivityDuration,
    completeActivityUnit,
    completedActivityNote,
    completedActivityNoteFhirId,
  } = state;
  const [{ isLoading: isSubmitting, error, success }, { setIsLoading, setError, setSuccess }] =
    useAddEntityRequestStates();
  const [activityDefinitions, { isLoading: isLoadingActivityDefinitions }] =
    useActiveActivityDefinitions({
      pagination: {
        pageSize: 1000,
      },
    });
  const [questionnaires, { isLoading: isLoadingQuestionnaires }] = useActiveAssessments({
    pagination: {
      pageSize: 1000,
    },
  });
  const [codeSystem, { isLoading: isLoadingActivityTypes }] = useCodeSystem(
    'ccm-activity-contact-types'
  );

  const { data: carePlanStatuses } = useCarePlanStatuses();
  const [_carePlans, { isLoading: isLoadingCarePlans }] = useActiveCarePlans({
    filter: {
      subject: `Patient/${patient?.fhirId}`,
    },
    map: CarePlanWrapper,
    autofetch: !!patient?.fhirId,
  });
  const carePlans = _carePlans as WrappedCarePlan[];

  const { data: users = [], isLoading: isLoadingUsers } = useUsersWithFhirUri();

  const isLoading =
    isLoadingActivityDefinitions ||
    isLoadingActivityTypes ||
    isLoadingCarePlans ||
    isLoadingQuestionnaires ||
    isLoadingUsers;

  const usersAndGroupsList = useMemo(
    () =>
      users.map((user: any) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: getFhirIdFromReferenceString(user.fhirUri) || '',
      })),
    [users]
  );

  const activitiesOptionsList = useMemo(
    () =>
      activityDefinitions.map((option) => ({
        name: option?.title,
        type: option?.topic?.[0]?.coding?.[0]?.display ?? '',
        id: option.id,
        typeId: option?.topic?.[0]?.coding?.[0]?.code,
      })),
    [activityDefinitions]
  );

  useEffect(() => {
    if (!usersAndGroupsList.length) return;

    updateState({
      assignedToItem: usersAndGroupsList.find((user) => user.value === assignedTo),
    });
  }, [assignedTo, usersAndGroupsList, updateState]);

  const toolsOptions = useMemo(
    () =>
      questionnaires.map((option) => ({
        label: option.title,
        value: option.id,
      })),
    [questionnaires]
  );

  const contactTypesOptions = useMemo(
    () =>
      codeSystem?.concept?.map((option) => ({
        label: option.display,
        value: option.code,
      })),
    [codeSystem]
  );

  const [, setLoadedRelatedCarePlans] = useState(false);
  const [task] = useTask(propActivity?.taskId, {
    autofetch: !!propActivity?.taskId,
  });

  useEffect(() => {
    if (!propActivity?.taskId) return;

    const realtedCarePlans =
      task?.output
        ?.filter(
          (output) =>
            output?.type?.coding?.[0].system ===
              fhirSystem.codeSystems.ccmActivityOutputTypes.asString() &&
            output.type.coding[0].code === 'linked-care-plan'
        )
        .map((output) => output?.valueReference?.id!)
        .filter(Boolean) ?? [];

    updateState({
      carePlans: carePlans.filter((carePlan) => realtedCarePlans.includes(carePlan.id!)),
    });
    setLoadedRelatedCarePlans(true);
  }, [carePlans, task, propActivity]);

  const onClearAllPress = () => {
    updateState(defaultStateFieldsValues(getFhirIdFromReferenceString(authUser.user_fhir_uri) || ''));
  };

  const validateRequiredFields = () => {
    if (!activityItem || !date || !assignedTo || !contactType || !patient) {
      if ((!allDay && !startHour) || !endHour) return false;

      return true;
    }

    if (shouldCompletedActivity) {
      if (completeActivityDuration === 0) return false;
    }

    return true;
  };

  console.log({
    propActivity
  })

  const onSavePress = async () => {
    setIsLoading(true);
    if (!validateRequiredFields()) {
      setIsLoading(false);
      setError('Please, fill all required fields');
      setSuccess(null);
      return;
    }

    const { onSaveCallback } = props;
    let newActivity: any = null;

    const isTheUserTheAssigned =
      assignedTo === getFhirIdFromReferenceString(authUser.user_fhir_uri) || false;

    const changeStatusIfUserIsAssigned = () => {
      if (shouldCompletedActivity) {
        return ActivityStatus.COMPLETED;
      }

      return isTheUserTheAssigned ? ActivityStatus.READY : ActivityStatus.REQUESTED;
    };

    let status = changeStatusIfUserIsAssigned();

    if (propActivity?.id) {
      if (shouldCompletedActivity) {
        status = ActivityStatus.COMPLETED;
      } else if (propActivity?.status === ActivityStatus.REQUESTED) {
        status = changeStatusIfUserIsAssigned();
      } else if (propActivity.staffFhirId !== assignedToItem?.value) {
        status = ActivityStatus.REQUESTED;
      } else {
        status = propActivity?.status;
      }
    }

    const activityData: any = {
      type: activityItem?.typeId,
      activity: activityItem?.id,
      date: moment(date).format(),
      start: moment(date)
        .set({
          hour: +startHour.split(':')[0],
          minute: +startHour.split(':')[1],
          seconds: 0,
        })
        .format(),
      end: moment(date)
        .set({
          hour: +endHour.split(':')[0],
          minute: +endHour.split(':')[1],
          seconds: 0,
        })
        .format(),
      priority: ActivityPriority.LOW,
      status,
      staff: assignedTo,
      contactType,
      script: tool,
      note,
      repeat,
      allDay,
      reminder,
      patient,
      duration: completeActivityDuration,
      unit: completeActivityUnit,
      contactMade,
      carePlans: selectedCarePlans.map((carePlan) => carePlan.id),
      completedNote: completedActivityNote,
      completedNoteFhirId: completedActivityNoteFhirId,
    };

    if (propActivity?.id) {
      const data = {
        ...activityData,
        id: propActivity?.id,
        status,
      };
      newActivity = await API.activities
        .update(data.id, {
          activity: data,
          authUser,
        })
        .catch((updateError) => console.log({ updateError }));
    } else {
      newActivity = await API.activities
        .create({
          activity: activityData,
          authUser,
        })
        .catch((createError) => console.log({ createError }));
    }

    if (!newActivity) {
      setIsLoading(false);
      setError('Error creating activity. Please try again');
      setSuccess(null);
      return;
    } else {
      setError(null);
      setSuccess('Activity successfully created');
      setTimeout(() => {
        onSaveCallback(
          new ConcreteActivity({
            ...newActivity,
            start: moment(newActivity.start).toDate(),
            end: moment(newActivity.end).toDate(),
          })
        );
      }, 1000);
    }
  };

  function renderCarePlanStatusBadge(carePlan: CarePlan) {
    const status = carePlan.extension?.find(
      (ext) => ext.url === fhirSystem.extension.CarePlan.currentStatus.asString()
    )?.valueCoding?.code;

    const label = carePlanStatuses?.find((carePlanStatus) => carePlanStatus.uuid === status);

    return (
      <Label variant="ghost" color={label?.color ?? 'default'}>
        {label?.name ?? 'Unknown'}
      </Label>
    );
  }

  return (
    <Box>
      <form>
        <Box
          sx={{
            my: 3,
            position: 'fixed',
            width: '400px',
            backgroundColor: 'white',
            opacity: 1,
            zIndex: 9,
            top: '-25px',
          }}
        >
          <Box sx={{ my: 3 }}>
            <Grid item>
              <PatientCard id={patient?.fhirId || ''} showCloseButton={false} />
            </Grid>
          </Box>
          <Box sx={{ my: 3 }}>
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {shouldCompletedActivity
                  ? propActivity?.id
                    ? 'Update'
                    : 'Create'
                  : propActivity?.id
                  ? 'Update'
                  : 'Schedule'}{' '}
                activity
              </Typography>
            </Grid>
          </Box>
          <Divider />
        </Box>
        <Box sx={{ marginTop: '180px' }}>
          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                options={activitiesOptionsList}
                value={activityItem}
                getOptionLabel={(item) => item.name || ''}
                groupBy={(option) => option.type}
                onChange={(_: any, value: any | null) =>
                  updateState({ activityItem: value, activity: value.value })
                }
                renderInput={(params) => (
                  <TextField required variant="standard" {...params} label="Activity" />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ marginTop: '20px' }}>
            <FormControl fullWidth>
              <InputLabel id="contact-type-label" style={{ left: -13 }}>
                Contact type *
              </InputLabel>
              <Select
                labelId="contact-type-label"
                value={contactType}
                onChange={(e) => updateState({ contactType: e.target.value })}
                variant="standard"
                required
              >
                {contactTypesOptions?.map((contactType) => (
                  <MenuItem key={contactType.value} value={contactType.value}>
                    {contactType.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                options={toolsOptions}
                value={toolItem}
                onChange={(_: any, value: any | null) =>
                  updateState({ toolItem: value, tool: value.value })
                }
                renderInput={(params) => <TextField variant="standard" {...params} label="Tool" />}
              />
            </FormControl>
          </Grid>

          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                multiple
                options={carePlans}
                value={selectedCarePlans}
                getOptionLabel={(option) => `${option.parsed.goal} - ${option.parsed.intervention}`}
                renderOption={(option, carePlan: WrappedCarePlan, { selected }) => (
                  <Stack component="li" {...option} direction="row">
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />{' '}
                    <Stack>
                      <Typography>
                        {carePlan.parsed.goal} - {carePlan.parsed.intervention}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        {renderCarePlanStatusBadge(carePlan)}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
                onChange={(_: any, value: any | null) => {
                  updateState({ carePlans: value });
                }}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} label="Goals and Interventions" />
                )}
                disableCloseOnSelect
              />
            </FormControl>
          </Grid>

          <Grid item style={{ marginTop: '15px' }}>
            <InputLabel shrink>Date *</InputLabel>
            <FormControl fullWidth>
              <DatePicker
                value={date}
                onChange={(date) => updateState({ date: moment(date).toDate() })}
                renderInput={(params) => <TextField variant="standard" type="date" {...params} />}
              />
            </FormControl>
          </Grid>
          {allDay ? (
            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Time</InputLabel>
              <FormControl fullWidth>
                <DatePicker
                  value={date}
                  onChange={(date) => updateState({ date: moment(date).toDate() })}
                  renderInput={(params) => <TextField variant="standard" type="date" {...params} />}
                  disabled
                />
              </FormControl>
            </Grid>
          ) : (
            <Grid container direction="row">
              <Grid item xs={6} style={{ marginTop: '15px' }}>
                <InputLabel shrink>Start hour *</InputLabel>
                <FormControl fullWidth style={{ paddingRight: '5px' }}>
                  <TextField
                    value={startHour}
                    defaultValue={startHour}
                    onChange={(e) => {
                      const selectedStartHour = e.target.value;
                      const startHourdate =  moment(date)
                      .set({
                        hour: +selectedStartHour.split(':')[0],
                        minute: +selectedStartHour.split(':')[1],
                        seconds: 0,
                      });
                      
                      const endHourDate =  moment(date)
                      .set({
                        hour: +endHour.split(':')[0],
                        minute: +endHour.split(':')[1],
                        seconds: 0,
                      });
                      updateState({
                        startHour: selectedStartHour,
                        completeActivityDuration: endHourDate.diff(startHourdate, 'minutes')
                      });
                    }}
                    type="time"
                    variant="standard"
                    required
                    disabled={allDay}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ marginTop: '15px' }}>
                <InputLabel shrink>End hour *</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    value={endHour}
                    defaultValue={endHour}
                    onChange={(e) => {
                      const startHourdate =  moment(date)
                      .set({
                        hour: +startHour.split(':')[0],
                        minute: +startHour.split(':')[1],
                        seconds: 0,
                      });
                      const selectedEndHour = e.target.value;
                      const endHourDate =  moment(date)
                      .set({
                        hour: +selectedEndHour.split(':')[0],
                        minute: +selectedEndHour.split(':')[1],
                        seconds: 0,
                      });
                      updateState({
                        endHour: selectedEndHour,
                        completeActivityDuration: endHourDate.diff(startHourdate, 'minutes')
                      });
                    }}
                    type="time"
                    variant="standard"
                    required
                    disabled={allDay}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
          <Grid item style={{ marginTop: '15px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() =>
                    updateState({
                      allDay: !allDay,
                    })
                  }
                  checked={allDay}
                />
              }
              label="All day"
            />
          </Grid>
          {(!shouldCompletedActivity && propActivity?.status !== ActivityStatus.COMPLETED) && (
          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <ReactQuill
                placeholder="Note..."
                theme="snow"
                value={note}
                onChange={(val) => updateState({ note: val })}
              />
            </FormControl>
          </Grid>)}
          {(shouldCompletedActivity || propActivity?.status === ActivityStatus.COMPLETED) && (
            <Grid item style={{ marginTop: '15px' }}>
              <FormControl fullWidth>
                <ReactQuill
                  placeholder="Note..."
                  theme="snow"
                  value={completedActivityNote}
                  onChange={(val) => updateState({ completedActivityNote: val })}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                options={usersAndGroupsList}
                value={assignedToItem}
                getOptionLabel={(item) => item.label}
                onChange={(_: any, value: any | null) =>
                  updateState({ assignedToItem: value, assignedTo: value.value })
                }
                renderOption={(props, option, { index }) => (
                  <li {...props} key={option.value + index}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField required variant="standard" {...params} label="Assigned to" />
                )}
              />
            </FormControl>
          </Grid>
          {(!shouldCompletedActivity || propActivity?.status === ActivityStatus.COMPLETED) && (
            <Grid item style={{ marginTop: '15px' }}>
              <FormControl fullWidth>
                <TextField
                  label="Reminder (minutes)"
                  value={reminder}
                  onChange={(e) => updateState({ reminder: e.target.value })}
                  id="outlined-basic"
                  variant="standard"
                  type="number"
                />
              </FormControl>
            </Grid>
          )}
          {(shouldCompletedActivity || propActivity?.status === ActivityStatus.COMPLETED) && (
            <>
              <Grid item style={{ marginTop: '15px' }}>
                <InputLabel shrink>Contact made</InputLabel>
                <FormControl fullWidth>
                  <RadioGroup
                    row
                    onChange={(e) =>
                      updateState({
                        contactMade: (e.target as HTMLInputElement).value === '1' ? true : false,
                      })
                    }
                  >
                    <FormControlLabel
                      checked={contactMade ? true : false}
                      value="1"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      checked={!contactMade ? true : false}
                      value="0"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item style={{ marginTop: '15px' }}>
                <InputLabel shrink>Duration (Minutes)</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    required
                    value={completeActivityDuration}
                    defaultValue={completeActivityDuration}
                    onChange={(e) => {
                      const selectedDuration = e.target.value;
                      const endHourDate =  moment(date)
                      .set({
                        hour: +startHour.split(':')[0],
                        minute: +startHour.split(':')[1],
                        seconds: 0,
                      }).add(selectedDuration, 'minutes');
                      updateState({
                        completeActivityDuration: +selectedDuration,
                        endHour: endHourDate.format('HH:mm'),
                      })
                    }}
                    type="number"
                    variant="standard"
                  />
                </FormControl>
              </Grid>
              <Grid item style={{ marginTop: '15px' }}>
                <InputLabel shrink>Unit</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    required
                    value={completeActivityUnit}
                    defaultValue={completeActivityUnit}
                    onChange={(e) => updateState({ completeActivityUnit: e.target.value })}
                    variant="standard"
                  />
                </FormControl>
              </Grid>
            </>
          )}
          {error && (
            <Alert style={{ marginTop: '20px' }} severity="error">
              {error}
            </Alert>
          )}
          {success && (
            <Alert style={{ marginTop: '20px' }} severity="success">
              {success}
            </Alert>
          )}
          <ActionButtons
            isLoading={isSubmitting}
            onLeftButtonPress={onClearAllPress}
            onRightButtonPress={onSavePress}
          />
        </Box>
      </form>
    </Box>
  );
};

export default ScheduleActivity;
