import { FhirResourceName } from '../fhir-base/mappings';
import { createFhirResourceHooks } from './base';

export * from './context';

export const { usePatients, usePatient, PatientProvider } = createFhirResourceHooks('Patient');

export const {
  useQuestionnaireResponses,
  useQuestionnaireResponse,
  useCurrentQuestionnaireResponse,
  QuestionnaireResponseProvider,
} = createFhirResourceHooks('QuestionnaireResponse');

export const {
  useQuestionnaire,
  useQuestionnaires,
  useCurrentQuestionnaire,
  QuestionnaireProvider,
} = createFhirResourceHooks('Questionnaire');

export const {
  usePlanDefinition,
  usePlanDefinitions,
  useCurrentPlanDefinition,
  PlanDefinitionProvider,
} = createFhirResourceHooks('PlanDefinition');

export const { useConditions, useCondition } = createFhirResourceHooks('Condition');

export const {
  useServiceRequests,
  useServiceRequest,
  ServiceRequestProvider,
  useCurrentServiceRequest,
} = createFhirResourceHooks('ServiceRequest');

export const {
  useActivityDefinitions,
  useActivityDefinition,
  ActivityDefinitionProvider,
  useCurrentActivityDefinition,
} = createFhirResourceHooks('ActivityDefinition');

export const { useTasks, useTask, TaskProvider, useCurrentTask } = createFhirResourceHooks('Task');

export const { useCodeSystems, useCodeSystem, useCurrentCodeSystem } =
  createFhirResourceHooks('CodeSystem');

export const { useImmunizations, useImmunization, useCurrentImmunization, ImmunizationProvider } =
  createFhirResourceHooks('Immunization');

export const { useValueSets, useValueSet } = createFhirResourceHooks('ValueSet');

export const {
  useCommunications,
  useCommunication,
  useCurrentCommunication,
  CommunicationProvider,
} = createFhirResourceHooks('Communication');

export const {
  useDocumentReferences,
  useDocumentReference,
  useCurrentDocumentReference,
  DocumentReferenceProvider,
} = createFhirResourceHooks('DocumentReference');

export const {
  useAllergyIntolerances,
  useAllergyIntolerance,
  useCurrentAllergyIntolerance,
  AllergyIntoleranceProvider,
} = createFhirResourceHooks('AllergyIntolerance');

export const { useCarePlans, useCarePlan, useCurrentCarePlan, CarePlanProvider } =
  createFhirResourceHooks('CarePlan');

export const { usePractitioners, usePractitioner, useCurrentPractitioner, PractitionerProvider } =
  createFhirResourceHooks('Practitioner');

export const {
  usePractitionerRoles,
  usePractitionerRole,
  useCurrentPractitionerRole,
  PractitionerRoleProvider,
} = createFhirResourceHooks('PractitionerRole');

export const { useGroups, useGroup, useCurrentGroup, GroupProvider } =
  createFhirResourceHooks('Group');

export const { useOrganizations, useOrganization, useCurrentOrganization, OrganizationProvider } =
  createFhirResourceHooks('Organization');

export const { useHealthcareService, useHealthcareServices } =
  createFhirResourceHooks('HealthcareService');

export const { useLists, useList, ListProvider, useCurrentList } = createFhirResourceHooks('List');

export const { useResources, useResource, useCurrentResource, ResourceProvider } =
  createFhirResourceHooks('' as FhirResourceName, {
    bundleHookName: 'useResources',
    resourceHookName: 'useResource',
    resourceProviderName: 'ResourceProvider',
    currentResourceHookName: 'useCurrentResource',
  });

export const { useBinarys, useBinary, BinaryProvider } = createFhirResourceHooks('Binary');

export const { useCareTeam, useCareTeams } = createFhirResourceHooks('CareTeam');

export const { useEncounter, useEncounters } = createFhirResourceHooks('Encounter');

export const { useCoverage, useCoverages, CoverageProvider } = createFhirResourceHooks('Coverage');

export const { useObservation, useObservations, useCurrentObservation, ObservationProvider } =
  createFhirResourceHooks('Observation');

export const { useMedication, useMedications, useCurrentMedication, MedicationProvider } =
  createFhirResourceHooks('Medication');

export const {
  useMedicationDispense,
  useMedicationDispenses,
  useCurrentMedicationDispense,
  MedicationDispenseProvider,
} = createFhirResourceHooks('MedicationDispense');

export const {
  useMedicationRequest,
  useMedicationRequests,
  useCurrentMedicationRequest,
  MedicationRequestProvider,
} = createFhirResourceHooks('MedicationRequest');

export const {
  useMedicationAdministration,
  useMedicationAdministrations,
  useCurrentMedicationAdministration,
  MedicationAdministrationProvider,
} = createFhirResourceHooks('MedicationAdministration');

export const {
  useRelatedPerson,
  useRelatedPersons,
  useCurrentRelatedPerson,
  RelatedPersonProvider,
} = createFhirResourceHooks('RelatedPerson');

export const { useGoals, useGoal } = createFhirResourceHooks('Goal');

export const {
  useEpisodeOfCare,
  useEpisodeOfCares,
  EpisodeOfCareProvider,
} = createFhirResourceHooks('EpisodeOfCare');

export const {
  useLocation,
  useLocations,
  useCurrentLocation,
} = createFhirResourceHooks('Location');

export const {
  useAppointment, 
  useAppointments, 
  useCurrentAppointment, 
  AppointmentProvider,
} = createFhirResourceHooks('Appointment');