// @ts-nocheck
import { IconButton } from '@mui/material';
import moment from 'moment';
import { useMemo } from 'react';
import { useAllergyIntolerances, useCommunications } from 'src/@nicheaim/fhir-react';
import Iconify from 'src/components/Iconify';
import useObjectState from 'src/hooks/useObjectState';
import {
  AllergyIntolerance,
  Communication,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { EntityContextMenu, SummaryTable, PromptModal } from 'src/sections/careflow/common';
import { shortFormat } from 'src/utils/formatTime';
import { getFhirIdFromReferenceString } from '../../tasks-activities/components/Activities/activities-utils';
import AddAllergy from './AddAllergy';
import { getAllergyContextMenuItems } from './utils';
import AddModal from '../../common/AddModal';

type AllergiesSummaryProps = {
  patient: any;
  isExpanded: boolean;
  onExpand: (index: number) => void;
  showButtons?: boolean;
};

const AllergiesSummary = ({ patient, isExpanded, onExpand, showButtons = true }: AllergiesSummaryProps) => {
  const [{ selectedAllergy, showAddAllergyModal, showDeleteAllergyModal }, updateState] =
    useObjectState({
      selectedAllergy: null,
      showAddAllergyModal: false,
      showDeleteAllergyModal: false,
    });
  const [allergies, { refresh: refreshAllergies, remove: deleteAllergy }] = useAllergyIntolerances({
    filter: {
      patient: patient?.id,
    },
  });

  const [communications, { remove: deleteCommunication, refresh: refreshCommunications }] =
    useCommunications({
      filter: {
        patient: patient?.id,
      },
    });

  const allergiesRows = useMemo(
    () =>
      allergies?.map((allergy: AllergyIntolerance) => {
        const communication: Communication | undefined = communications.find(
          (com: Communication) =>
            getFhirIdFromReferenceString(com?.partOf?.[0]?.reference ?? '') === allergy.id
        );
        return {
          id: allergy.id,
          type: allergy.type,
          category: allergy.category?.[0] ?? null,
          allergy: allergy.code?.coding?.[0]?.display ?? 'N/A',
          note: communication?.note?.[0]?.text,
          communicationId: communication?.id,
          onsetDateTime: allergy.onsetDateTime,
        };
      }),
    [allergies, communications]
  );

  const onCreateAllergyCallback = (newAllergy: AllergyIntolerance) => {
    updateState({
      selectedAllergy: null,
      showAddAllergyModal: false,
    });
    refreshCommunications();
    refreshAllergies();
  };

  const renderAddAllergyModal = () => (
    <AddModal
      open={showAddAllergyModal}
      onClose={() =>
        updateState({
          showAddAllergyModal: false,
        })
      }
      Component={
        <AddAllergy
          allergyIntolerance={selectedAllergy}
          onSaveCallback={onCreateAllergyCallback}
          onCancelPress={() =>
            updateState({
              showAddAllergyModal: false,
            })
          }
          patient={{
            fhirId: patient?.id,
          }}
        />
      }
    />
  );

  const renderDeleteAllergyPrompt = () => (
    <PromptModal
      open={showDeleteAllergyModal}
      onContinuePress={() => {
        if (selectedAllergy?.communicationId) {
          const communicationToDelete = communications.find(
            (c) => c.id === selectedAllergy?.communicationId
          );

          if (communicationToDelete) {
            deleteCommunication(communicationToDelete)
              .then(() => {
                deleteAllergy(selectedAllergy).then(() => {
                  updateState((prev: any) => ({
                    showDeleteAllergyModal: false,
                    selectedAllergy: null,
                  }));
                  refreshAllergies();
                });
              })
              .catch(() => {
                updateState({
                  showDeleteAllergyModal: false,
                  selectedAllergy: null,
                });
              });
          }
        }
      }}
      onCancelPress={() =>
        updateState({
          showDeleteAllergyModal: false,
        })
      }
      disableButtons={false}
    />
  );

  return (
    <>
      {renderDeleteAllergyPrompt()}
      {renderAddAllergyModal()}
      <SummaryTable
        title="Allergies"
        columns={[
          {
            header: 'Onset Date',
            field: 'onsetDateTime',
            render(onsetDateTime) {
              return onsetDateTime
                ? shortFormat(moment.utc(onsetDateTime).local().toDate())
                : 'N/A';
            },
          },
          {
            header: 'Type',
            field: 'type',
          },
          {
            header: 'Category',
            field: 'category',
            visible: isExpanded,
          },
          {
            header: 'Allergy',
            field: 'allergy',
          },
          {
            header: 'Note',
            field: 'note',
            visible: isExpanded,
          },
        ]}
        data={allergiesRows}
        actionBar={
          showButtons ? (
            <>
              <IconButton
                onClick={() =>
                  updateState({
                    showAddAllergyModal: true,
                  })
                }
              >
                <Iconify icon="eva:plus-fill" />
              </IconButton>
              <IconButton onClick={() => onExpand?.(6)}>
                <Iconify icon="eva:expand-fill" />
              </IconButton>
            </>
          ) : (<></>)
        }
        rowContextMenu={(currentAllergy: any) => (
          showButtons ? (
            <EntityContextMenu
              entity={currentAllergy}
              items={getAllergyContextMenuItems(updateState)}
            />
          ) : (<></>)
        )}
      />
    </>
  );
};

export default AllergiesSummary;
