import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import Label from 'src/components/Label';
import DataTable, { DataTableColumn } from 'src/components/table/DataTable';
import api from 'src/services/api';
import { CreatedUpdated } from '../common';
import ConditionsToOpportunitiesContextMenu from './ConditionsToOpportunitiesContextMenu';

const columns: DataTableColumn[] = [
  {
    header: 'Opportunity',
    field: 'opportunity.name',
  },
  {
    header: 'Condition',
    field: 'condition.name',
  },
  {
    header: 'Status',
    field: 'status',
    render(value) {
      return (
        <Label color={value === 'active' ? 'success' : 'info'}>
          {value === 'active' ? 'Active' : 'Inactive'}
        </Label>
      );
    },
  },
  {
    header: 'Created',
    field: '$',
    render(value) {
      return <CreatedUpdated by={value.createdBy} date={value.createdOn} />;
    },
  },
  {
    header: 'Updated',
    field: '$',
    render(value) {
      return <CreatedUpdated by={value.updatedBy} date={value.updatedOn} />;
    },
  },
];

export default function ConditionsToOpportunitiesTable() {
  const { data: mappings = [], refetch } = useQuery(
    ['mappings-conditions-to-opportunities'],
    async () =>
      (await api.carePlan.findMappingsConditionOpportunity({
        status: '',
      })) as any[]
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <DataTable
        columns={columns}
        data={mappings}
        selectable
        rowContextMenu={(item: any) => (
          <ConditionsToOpportunitiesContextMenu
            item={item}
            onDelete={async () => {
              await refetch();
            }}
          />
        )}
      />
    </div>
  );
}
