import { ActivityRenderer } from './ActivityRenderer';
import {
  Activity,
  ActivityPriority,
  ActivityStatus,
} from 'src/sections/careflow/tasks-activities/model';
import { mapActivityPriority } from 'src/sections/careflow/tasks-activities/model/ActivityPriority';
import { lowerCase, startCase } from 'lodash';
import { Box } from '@mui/material';

interface ColorStyles {
  color: string;
  backgroundColor: string;
}

const COLORS_BY_PRIORITY = new Map<string, ColorStyles>(
  Object.entries({
    [ActivityPriority.LOW]: {
      color: '#68B99C',
      backgroundColor: '#E5F3EE',
    },
    [ActivityPriority.NORMAL]: {
      color: '#00569D',
      backgroundColor: '#D6E4EF',
    },
    [ActivityPriority.HIGH]: {
      color: '#E04753',
      backgroundColor: '#F9DDDF',
    },
  })
);

const COLORS_BY_STATUS = new Map<string, ColorStyles>(
  Object.entries({
    [ActivityStatus.COMPLETED]: {
      color: '#99A5B1',
      backgroundColor: '#EDEFF1',
    },
  })
);

export class ActivityPriorityRenderer implements ActivityRenderer {
  constructor(private readonly activity: Activity) {}

  render(): any {
    return (
      <Box
        sx={{
          height: '24px',
          fontWeight: 'bold',
          fontSize: 'smaller',
          borderRadius: '15%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: 60,
          ...getColorStyles(this.activity),
        }}
      >
        {startCase(lowerCase(mapActivityPriority[this.activity.priority]))}
      </Box>
    );
  }
}

function getColorStyles(activity: Activity): ColorStyles | {} {
  const { status = '', priority } = activity;
  if (status === ActivityStatus.COMPLETED) {
    return COLORS_BY_STATUS.get(status) || {};
  }
  return COLORS_BY_PRIORITY.get(priority) || {};
}
