import { useDropzone } from 'react-dropzone';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { UploadProps } from './type';
import Image from '../Image';
import Iconify from '../Iconify';
import RejectionFiles from './RejectionFiles';
import produce from 'immer';
import { usePatient, useBinarys } from 'src/@nicheaim/fhir-react';
import { PatientWrapper } from '../../../src/@nicheaim/fhir-base/wrappers/Patient';
import { Box } from '@mui/material';

const FHIR_API = process.env.REACT_APP_FHIR_API_BASE_URL;

const RootStyle = styled('div')(({ theme }) => ({
  width: 144,
  height: 144,
  margin: 'auto',
  borderRadius: '50%',
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9,
    },
  },
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': { opacity: 0.72 },
}));

export default function UploadAvatar({
  error,
  file,
  helperText,
  sx,
  patient,
  onFileRejections,
  showFileRejections = true,
}: UploadProps) {
  const [, { update }] = usePatient(patient?.id!, { map: PatientWrapper });
  const [, { create: createBinary }] = useBinarys({
    autofetch: false,
  });
  const [imagesrc, setImagesrc] = useState(file);

  useEffect(() => {
    setImagesrc(file);
  }, [file]);

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        setImagesrc(fileReader?.result?.toString() || '');
        resolve(fileReader.result?.toString().split(',')[1]);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const mapFormDataToBinary = async (data: any): Promise<any> => {
    const resourceType = 'Binary';

    const mappedFile = await Promise.all(
      data.map(async (file: Blob) => {
        const contentType = file.type;
        const data = await convertBase64(file);
        const securityContext = { reference: `Patient/${patient?.id}` };
        return { resourceType, contentType, securityContext, data };
      })
    );

    return mappedFile;
  };

  const handleCreateBynary = async (data: any) => {
    const result = await createBinary(data);

    const photos: any = [
      {
        contentType: result[0]?.contentType,
        creation: new Date(),
        url: `${FHIR_API}/Binary/${result[0]?.id}`,
      },
    ];

    await update(
      produce(patient!, (draft: any) => {
        draft.photo = photos;
      })
    );
    return result;
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    accept: ['image/png', 'image/jpg', 'image/gif', 'image/jpeg'],
    onDrop: async (files) => {
      if (!files.length) return;
      const binaryData = await mapFormDataToBinary(files);
      const resultBinary = await handleCreateBynary(binaryData);
    },
  });

  useEffect(() => {
    onFileRejections?.(fileRejections);
  }, [fileRejections]);

  return (
    <>
      <RootStyle
        sx={{
          ...((isDragReject || error) && {
            borderColor: 'error.light',
          }),
          ...sx,
        }}
      >
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
          }}
        >
          <input {...getInputProps()} />

          {imagesrc && (
            <Image
              alt="avatar"
              src={typeof imagesrc === 'string' ? imagesrc : imagesrc?.preview}
              sx={{ zIndex: 50 }}
            />
          )}

          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...(imagesrc && {
                opacity: 0,
                color: 'common.white',
                bgcolor: 'grey.900',
                '&:hover': { opacity: 0.72 },
              }),
              ...((isDragReject || error) && {
                bgcolor: 'error.lighter',
              }),
            }}
          >
            <Iconify icon={'ic:round-add-a-photo'} sx={{ width: 24, height: 24, mb: 1 }} />
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>

      {helperText && helperText}

      {fileRejections.length > 0 && !!showFileRejections && (
        <RejectionFiles fileRejections={fileRejections} />
      )}
    </>
  );
}
