// @ts-nocheck
import { useContext, useState, useMemo } from 'react';
import {
  TableCell,
  TableRow,
  Typography,
  DialogContent,
  Dialog,
  Box,
  Grid,
  InputLabel,
  Alert,
  FormControl,
  TextField,
  Divider,
  Select,
  MenuItem,
} from '@mui/material';
import { EntityContextMenu, PatientCard } from 'src/sections/careflow/common';
import Bed from 'src/sections/careflow/rounding/Model/Bed';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { shortFormat } from 'src/utils/formatTime';
import moment from 'moment';
import { CensusContext } from '../../CensusContext';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import api from 'src/services/api';
import { useBedExitDestinations } from 'src/services/api/census';
import ReactQuill from 'react-quill';
import { useCommunications } from 'src/@nicheaim/fhir-react';
import useAuth from 'src/hooks/useAuth';
import { getFhirIdFromReferenceString } from 'src/sections/careflow/tasks-activities/components/Activities/activities-utils';
import fhirSystem from 'src/fhir/system';
import { useQueryClient } from '@tanstack/react-query';

type BedItemPropsType = {
  bed: Bed;
};

const BedItem = ({ bed }: BedItemPropsType) => {
  const queryClient = useQueryClient();
  const authUser = useAuth().getCurrentUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [note, setNote] = useState<string>('');
  const [showRemovePatient, setShowRemovePatient] = useState<boolean>(false);
  const censusContext = useContext(CensusContext);
  const [_, { create: createCommunication }] = useCommunications({
    autofetch: false,
  });
  const { data: exitDestinations } = useBedExitDestinations();
  const [exitDestination, setExitDestination] = useState<any>(bed.currentBedAssignment?.exitDestination?.id);

  const exitDestinationsOptions = useMemo(() => exitDestinations?.map((ed) => ({
    label: ed.valueDisplayName,
    value: ed.id
  })) ?? [], [exitDestinations])

  const onCancel = () => setShowRemovePatient(false);

  const onRemovePatient = () => {
    setIsLoading(true);

    if (!exitDestination) {
      setError('Please, select an exit destination');
      setIsLoading(false);
      return;
    }

    api.roundings
      .editBedPatient(
        bed?.id,
        0,
        moment(bed.currentBedAssignment?.start).format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
        note,
        exitDestination
      )
      .then(async (response) => {
        if (response.success) {
          queryClient.refetchQueries({
            predicate(query) {
              return ['ccm-notes'].includes(
                query.queryKey[0] as string
              );
            },
          });
          const creationDate = moment().toISOString();
          const authUserId = getFhirIdFromReferenceString(authUser?.user_fhir_uri ?? '');
          await createCommunication({
            resourceType: 'Communication',
            sent: creationDate,
            status: 'completed',
            note: [
              {
                text: note.replace(/<[^>]+>/g, ''),
                authorReference: {
                  reference: `Practitioner/${authUserId}`,
                  type: 'Practitioner',
                },
                time: creationDate,
              },
            ],
            text: {
              status: 'empty',
              div: note,
            },
            subject: {
              reference: `Patient/${bed.currentBedAssignment?.patient?.fhir_id}`,
              type: 'Patient',
            },
            category: [{
              text: 'Census',
              coding: [
                {
                  system: fhirSystem.note.type.asString(),
                  code: 'census',
                  display: 'Census',
                },
              ],
            }],
            sender: {
              reference: `Practitioner/${authUserId}`,
              type: 'Practitioner',
              display: authUser.name,
            },
          })

          setIsLoading(false);
          setError(null);
          setNote('');
          censusContext.onSaveEntity(bed.id, response.bedAssignment);
          onCancel();
        }
      })
      .catch(() => {
        setIsLoading(false);
        setError('Error removing the patient. Please try again.');
      });
  };

  return (
    <>
      <Dialog open={showRemovePatient} onClose={onCancel}>
        <DialogContent>
          <Box sx={{ padding: 2, width: '500px' }}>
            <Box sx={{ mb: 2 }}>
              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Remove patient
                </Typography>
              </Grid>
            </Box>
            <Divider />
            <Box sx={{ padding: 1 }}>
              <PatientCard
                id={getFhirIdFromEntity(bed.currentBedAssignment?.patient?.fhirRefUri ?? '')}
                showCloseButton={false}
              />
            </Box>
            <Box sx={{ marginTop: 2, marginBottom: 2 }}>
              <InputLabel shrink>Exit destination</InputLabel>
              <FormControl fullWidth>
                <Select
                  value={exitDestination}
                  onChange={(e) =>
                    setExitDestination(e.target.value)
                  }
                >
                  {exitDestinationsOptions.map(d => (
                    <MenuItem key={d.value} value={d.value}>{d.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <Grid container>
                <Grid item xl={12} style={{ marginTop: '15px' }}>
                  <InputLabel>Note</InputLabel>
                  <FormControl fullWidth>
                    <ReactQuill
                      placeholder="Note..."
                      theme="snow"
                      value={note}
                      onChange={(val) => setNote(val)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              {error && (
                <Alert style={{ marginTop: '20px' }} severity="error">
                  {error}
                </Alert>
              )}
              <ActionButtons
                isLoading={isLoading}
                leftButtonTitle="Cancel"
                onLeftButtonPress={onCancel}
                rightButtonTitle="Remove"
                onRightButtonPress={onRemovePatient}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <TableRow>
        <TableCell>{bed.identifier}</TableCell>
        <TableCell>
          {bed.currentBedAssignment?.patient ? (
            <PatientCard
              id={getFhirIdFromEntity(bed.currentBedAssignment?.patient.fhirRefUri)}
              showCloseButton={false}
            />
          ) : (
            <Typography>No patient</Typography>
          )}
        </TableCell>
        <TableCell>
          <Typography>{bed.locationFhirName ?? 'No location'}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{bed.isActive ? 'active' : 'inactive'}</Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {bed.currentBedAssignment?.start && bed.currentBedAssignment?.patient
              ? shortFormat(moment(bed.currentBedAssignment?.start).toDate())
              : 'N/A'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {bed.currentBedAssignment?.end && bed.currentBedAssignment?.patient
              ? shortFormat(moment(bed.currentBedAssignment?.end).toDate())
              : 'N/A'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>{bed.updatedBy}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{shortFormat(moment(bed.updatedOn).toDate())}</Typography>
        </TableCell>
        <TableCell>
          <EntityContextMenu
            entity={bed}
            items={[
              {
                label: 'Edit assignment',
                icon: 'material-symbols:edit-outline',
                onPress: censusContext.openAddBedModal,
              },
              ...(bed.currentBedAssignment?.patient
                ? [
                    {
                      label: 'Transfer Bed',
                      icon: 'mingcute:transfer-fill',
                      onPress: censusContext.openTransferBedModal,
                    },
                    {
                      label: 'Remove patient',
                      icon: 'eva:trash-2-outline',
                      onPress: () => setShowRemovePatient(true),
                    },
                  ]
                : []),
            ]}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default BedItem;
