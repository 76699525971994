// @ts-nocheck
import { Card, Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { Carousel } from 'src/sections/careflow/common';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import StandardDialogProvider from '../common/StandardDialogProvider';
import { PatientInfoHeader } from '../health-record/patient-info-header';
import CareTeam from '../health-record/patient-info-header/components/CareTeam';
import Contacts from '../health-record/patient-info-header/components/Contacts';
import PersonalDetails from '../health-record/patient-info-header/components/PersonalDetails';
import PatientIdentifiers from '../health-record/patient-info-header/PatientIdentifiers';
import PlansDetails from './components/PlansDetails';
import Providers from './components/Providers';
import ProvidersExpanded from './components/ProvidersExpanded';
import FamilyGroupCard from './FamilyGroupCard';
import AdmissionDetailsSummary from '../health-record/admissionDetails/AdmissionDetailsSummary';

// ----------------------------------------------------------------------

export default function PatientDemographics() {
  const patient = useCurrentPatient();
  const location = useLocation();
  const { themeStretch } = useSettings();
  const [open, setOpen] = useState(false);
  const [expandedComponent, setExpandedComponent] = useState<any>(null);
  const [actualPage, setActualPage] = useState<number>(0);

  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  const handleExpand = (Component: any) => () => {
    setExpandedComponent(<Component onCollapse={() => setOpen(false)} />);
    setOpen(true);
  };

  useEffect(() => {
    if (location.hash === '#identifiers') {
      setActualPage(7);
    }
  }, [location.hash]);

  return (
    <Page title="Patient Demographics">
      <StandardDialogProvider>
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs
                title="Patient Demographics"
                links={[
                  {
                    href: '',
                    name: 'Dashboard',
                  },
                  {
                    href: '',
                    name: 'Patient Demographics',
                  },
                  {
                    href: '',
                    name: patient?.getFullName() || '-',
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <PatientInfoHeader />
            </Grid>
            <Grid item xs={12} style={{ position: 'relative' }}>
              {patient && (
                <Carousel
                  expandedComponent={expandedComponent}
                  open={open}
                  onClose={closeModal}
                  buttonsBehavior={'page'}
                  settings={{ slidesToShow: 3, slidesToScroll: 3 }}
                  current={actualPage}
                  onChange={({ slide, behavior }) => {
                    setActualPage(slide);
                  }}
                >
                  <Card sx={{ p: 2 }}>
                    <PersonalDetails patient={patient} />
                  </Card>
                  
                  <AdmissionDetailsSummary patient={patient} />

                  <CareTeam />

                  <Card sx={{ p: 2 }}>
                    <Contacts patient={patient} />
                  </Card>

                  <Providers onExpand={handleExpand(ProvidersExpanded)} />

                  <PlansDetails
                    patient={patient}
                    handleOnExpandActionBar={(component) => {
                      setExpandedComponent(component);
                      openModal();
                    }}
                    handleOnCollapseActionBar={closeModal}
                  />

                  <FamilyGroupCard />

                  <PatientIdentifiers patient={patient} />

                </Carousel>
              )}
            </Grid>
          </Grid>
        </Container>
      </StandardDialogProvider>
    </Page>
  );
}
