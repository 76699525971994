import { Menu, MenuItem, Typography, Stack } from '@mui/material';
import { ConcreteActivity, Activity } from '../../../model/Activity';
import Iconify from 'src/components/Iconify';
import CompleteActivityMenu from './CompleteActivityMenu';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';

export enum ActivityActionTypes {
  START,
  COMPLETE,
  CANCEL,
}

type CompleteActivityMenuItemType = {
  activity: Activity;
  onSaveActivity: (activity: ConcreteActivity) => void;
  type: ActivityActionTypes;
};

const CompleteActivityMenuItem = ({
  type = ActivityActionTypes.COMPLETE,
  activity,
  onSaveActivity,
}: CompleteActivityMenuItemType) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
  const triggers = bindTrigger(popupState);
  return (
    <>
      <MenuItem key={'Complete activity'} {...triggers}>
        <Stack direction="row" spacing={2}>
          <Iconify
            icon={
              type === ActivityActionTypes.COMPLETE ? 'fluent-mdl2:completed' : 'mdi:paper-check'
            }
          />
          <Typography variant="body2">
            {type === ActivityActionTypes.COMPLETE ? 'Complete' : 'Start Next'} Activity
          </Typography>
        </Stack>
      </MenuItem>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClick={(e) => triggers.onClick(e)}
      >
        <CompleteActivityMenu
          activityId={activity.id}
          onSaveActivity={onSaveActivity}
          onCloseMenuItem={popupState.close}
          type={type}
        />
      </Menu>
    </>
  );
};

export default CompleteActivityMenuItem;
