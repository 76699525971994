import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
} from '@mui/material';

type ButtonType = "button" | "submit" | "reset";

interface ActionButtonsProps {
  leftButtonTitle?: string;
  leftButtonType?: ButtonType;
  rightButtonTitle?: string;
  rightButtonType?: ButtonType;
  onLeftButtonPress?: () => void;
  onRightButtonPress?: () => void;
  isLoading: boolean;
  disabled?: boolean;
}

const ActionButtons = ({
  leftButtonTitle = 'Clear all',
  leftButtonType = 'button',
  rightButtonTitle = 'Save',
  rightButtonType = 'button',
  onLeftButtonPress = () => {},
  onRightButtonPress = () => {},
  isLoading = false,
  disabled = false,
}: ActionButtonsProps) => {
  const onLeftButtonPressHandler = () => {
    onLeftButtonPress()
  }
  
  const onRightButtonPressHandler = () => {
    onRightButtonPress()
  }
  return (
    <Grid container spacing={2} direction="row">
      <Grid item xs={6} style={{ marginTop: '15px' }}>
        <FormControl fullWidth>
          <Button
            disabled={isLoading || disabled}
            onClick={onLeftButtonPressHandler}
            size="large"
            fullWidth
            variant="outlined"
            type={leftButtonType}
          >
            {leftButtonTitle}
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={6} style={{ marginTop: '15px' }}>
        <FormControl fullWidth>
          <Button
            disabled={isLoading || disabled}
            onClick={onRightButtonPressHandler}
            size="large"
            style={{ width: '100%' }}
            variant="contained"
            type={rightButtonType}
          >
            {isLoading ? <CircularProgress size={20} /> : rightButtonTitle}
          </Button>
        </FormControl>
      </Grid>
    </Grid>
  )
};

export default ActionButtons;
