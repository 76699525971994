import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router';
import api from 'src/services/api';
import InterventionsForm from './InterventionsForm';
import InterventionsTable from './InterventionsTable';
import InterventionsToolbar from './InterventionsToolbar';

function InterventionsTab() {
  return (
    <Routes>
      <Route index element={<InterventionsList />} />
      <Route path="new" element={<InterventionsForm />} />
      <Route path="edit/:id" element={<InterventionsEditForm />} />
    </Routes>
  );
}

function InterventionsList() {
  return (
    <>
      <InterventionsToolbar />
      <InterventionsTable />
    </>
  );
}

function InterventionsEditForm() {
  const params = useParams();
  const {
    data: intervention,
    isLoading,
    refetch,
  } = useQuery(['interventions', params.id], async () => {
    if (!params.id) {
      return null;
    }

    return api.carePlan.getIntervention(params.id);
  });

  useEffect(() => {
    refetch();
  }, []);

  if (!intervention && !isLoading) return <Navigate to=".." />;
  return <InterventionsForm isEdit currentIntervention={intervention} />;
}

export default Object.assign(InterventionsTab, {
  path: 'interventions',
});
