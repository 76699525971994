import { useEffect, useState } from "react";
import { Button, Grid, List, ListItem, Typography } from '@mui/material';
import Map from './Map';
import { Marker } from "@react-google-maps/api";
import { getLatitudeAndLongitude } from "src/sections/crs/common/common-utils";

const AddressList = ({
  addresses,
  saveSelectedAddress,
}: any) => {
  const [addressList, setAddressList] = useState(addresses || []);
  const [selectedMarker, setSelectedMarker] = useState<string>();
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  useEffect(()=> {
    setAddressList(addresses);
  }, [addresses]);

  useEffect(() => {
    console.log(addresses);
  }, []);

  const getLabelColor = (address: any): string => {
    const hasExtension = address?.extension?.[0]?.extension !== undefined;
    const isValid = address?.extension?.[0]?.extension?.find((a: any) => a.url === 'valid')?.valueBoolean;

    if (hasExtension && isValid) return '#009764';
    if (hasExtension) return '#e5881e';

    return '#db1919';
  };

  return (
    <Grid container spacing={2} alignContent="space-between" maxHeight={600}>
      <Grid
        item
        sm={5}
        maxHeight={600}
        sx={{
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            width: 10,
            WebkitAppearance: 'none',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            border: '2px solid',
            borderColor: '#E7EBF0',
            backgroundColor: 'rgba(0 0 0 / 0.5)',
          },
        }}
      >
        <List>
          {addressList?.length > 0 ? (
            addressList?.map((address: any, index: number) => (
              <ListItem
                divider
                key={
                  address?.extension?.[0]?.extension?.find((a: any) => a.url === 'identifier')
                    ?.valueString
                }
              >
                <Grid item md={1}>
                  <span
                    style={{
                      color: '#fff',
                      backgroundColor: getLabelColor(address),
                      padding: 2,
                    }}
                  >
                    {alphabet[index]}
                  </span>
                </Grid>
                <Grid item md={7} paddingLeft={1}>
                  <Typography variant="subtitle2">{address.name}</Typography>
                  <Typography variant="body2">
                    {Array.isArray(address.line) ? address.line.join(', ') : address.line}
                  </Typography>
                  <Typography variant="body2">
                    {address.city}, {address.district}, {address.state} {address.country}. {address.postalCode}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => saveSelectedAddress(address)}
                  >
                    Select This
                  </Button>
                </Grid>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <Typography>No addresses available</Typography>
            </ListItem>
          )}
        </List>
      </Grid>

      <Grid item sm={7}>
        <Map>
          {addressList?.map((address: any, i: number) => {
            const marker = {
              id: address?.extension?.[0]?.extension?.find((a: any) => a.url === 'identifier')
                ?.valueString,
              latitude: getLatitudeAndLongitude(address).latitude,
              longitude:getLatitudeAndLongitude(address).longitude,
            };
            if (
              marker.latitude &&
              address?.extension?.[0]?.extension?.find((a: any) => a.url === 'valid')?.valueBoolean
            ) {
              const iw = 70,
                ih = 94;
              return (
                window.google && (
                  <Marker
                    key={marker.id}
                    position={{
                      lat: parseFloat(marker.latitude.toString()),
                      lng: parseFloat(marker.longitude.toString()),
                    }}
                    onClick={() => setSelectedMarker(alphabet[i])}
                    icon={{
                      url: `https://waqi.info/mapicon/${alphabet[i]}.30.png`,
                      anchor: new window.google.maps.Point(iw / 4, ih / 2 - 5),
                      size: new window.google.maps.Size(iw / 2, ih / 2),
                      scaledSize: new window.google.maps.Size(iw / 2, ih / 2),
                    }}
                  />
                )
              );
            }
          })}
        </Map>
      </Grid>
    </Grid>
  );
};

export default AddressList;