import { createContext } from "react";
import GroupSessionType from "./types/GroupSessionType";
import { PaginationType } from "./useController";

type GroupSessionContextType = {
  openScheduleSessionModal: (groupSession?: GroupSessionType) => void;
  filterEntities: (filter: string | null, status: number) => void;
  getEntities: (data: { pagination: PaginationType }) => void;
}

export const GroupSessionContext = createContext<GroupSessionContextType>({
  openScheduleSessionModal: (groupSession?: GroupSessionType) => {},
  filterEntities: () => {},
  getEntities: (data: { pagination: PaginationType }) => {},
});