import { QuestionnaireItem } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { createWrapper } from '../../fhir-react/base';

export const QuestionnaireWrapper = createWrapper('Questionnaire', (questionnaire) => ({
  item: questionnaire.item?.map((item) => wrapQuestionnaireItem(item)),
}));

function wrapQuestionnaireItem(item: QuestionnaireItem): WrappedQuestionnaireItem {
  return {
    ...item,
    type: item.type === 'choice' ? (item.repeats ? 'multiple-choice' : 'single-choice') : item.type,

    // recurse
    item: item.item?.map((item) => wrapQuestionnaireItem(item)) || [],
  };
}

export type WrappedQuestionnaire = ReturnType<typeof QuestionnaireWrapper>;
export type WrappedQuestionnaireItem = Omit<QuestionnaireItem, 'item' | 'type'> & {
  type: Exclude<QuestionnaireItem['type'], 'choice'> | 'single-choice' | 'multiple-choice';
  item?: WrappedQuestionnaireItem[];
};
