import { useState } from 'react';
import { DatePicker } from '@mui/lab';
import { Stack, Typography, IconButton, TextField, MenuItem, Button } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { SHOW_SIDEBAR_COMPONENT, useEventBus } from 'src/event-bus';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';

interface LabSummaryFormProps {
  data?: any;
}

const LabSummaryForm = ({ data }: LabSummaryFormProps) => {
  const { dispatchEvent } = useEventBus();
  const [date, setDate] = useState<Date | null>(data?.date);
  const [test, setTest] = useState<any>(data?.test || '');
  const [code, setCode] = useState(data?.code || '');
  const [units, setUnits] = useState(data?.units || '');
  const [result, setResult] = useState(data?.result || '');
  const [range, setRange] = useState(data?.range || '');
  const [status, setStatus] = useState(data?.status || '');
  const [intervention, setIntervention] = useState(data?.intervention || '');

  const closeForm = () => dispatchEvent(SHOW_SIDEBAR_COMPONENT, null);

  const clearAll = () => {
    setDate(null);
    setTest('');
    setCode('');
    setUnits('');
    setResult('');
    setRange('');
    setStatus('');
    setIntervention('');
  };

  const handleSave = () => {
    clearAll();
    closeForm();
  };

  return (
    <Stack>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="subtitle1">Add Lab</Typography>
      </Stack>
      <Stack>
        <DatePicker
          label="Date"
          value={date}
          onChange={setDate}
          renderInput={(params) => <TextField variant="standard" {...params} />}
        />
      </Stack>
      <TextField
        fullWidth
        label="Test"
        margin="dense"
        size="small"
        variant="standard"
        onChange={(ev) => setTest(ev.target.value)}
        value={test}
      />
      <TextField
        fullWidth
        label="Code"
        margin="dense"
        size="small"
        variant="standard"
        onChange={(ev) => setCode(ev.target.value)}
        value={code}
      />
      <TextField
        fullWidth
        label="Intervention"
        margin="dense"
        size="small"
        variant="standard"
        onChange={(ev) => setIntervention(ev.target.value)}
        value={intervention}
      />
      <TextField
        fullWidth
        label="Units"
        margin="dense"
        size="small"
        variant="standard"
        onChange={(ev) => setUnits(ev.target.value)}
        value={units}
      />
      <TextField
        fullWidth
        label="Results"
        margin="dense"
        size="small"
        variant="standard"
        onChange={(ev) => setResult(ev.target.value)}
        value={result}
      />
      <TextField
        fullWidth
        label="Range"
        margin="dense"
        size="small"
        variant="standard"
        onChange={(ev) => setRange(ev.target.value)}
        value={range}
      />
      <TextField
        fullWidth
        label="Status"
        margin="dense"
        size="small"
        variant="standard"
        onChange={(ev) => setStatus(ev.target.value)}
        value={status}
      />
      <ActionButtons
        isLoading={false}
        onLeftButtonPress={clearAll}
        onRightButtonPress={handleSave}
      />
    </Stack>
  );
};

export default LabSummaryForm;
