import { Route, Routes } from 'react-router';
import PlanIndex from './$plan';
import PlansTable from './components/PlansTable';
import NewPlanForm from './new';

export default function ClientPlansIndex() {
  return (
    <Routes>
      <Route index element={<PlansTable />} />
      <Route path="new" element={<NewPlanForm />} />
      <Route path=":plan/*" element={<PlanIndex />} />
    </Routes>
  );
}
