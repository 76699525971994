// @ts-nocheck
import { MenuItem, TextField } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { useCodeSystem } from 'src/@nicheaim/fhir-react';

interface RelationshipSelectorProps {
  onChange?: (value: any) => void;
  value: any;
}

const RelationshipSelector = ({ onChange, value }: RelationshipSelectorProps) => {
  const [relationShipCodes] = useCodeSystem('contact-relationship-codes');
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const rel = relationShipCodes?.concept?.find((r) => r.code === event.target.value);
    if (onChange) {
      onChange(rel);
    }
  };

  const renderOptions = useCallback(() => (
    relationShipCodes?.concept?.sort((a, b) => {
      if (a?.display < b?.display) {
        return -1;
      }
      if (a?.display > b?.display) {
        return 1;
      }
      return 0;
    }).map((r) => (
      <MenuItem key={`race-${r.code}`} value={r.code}>
        {r.display}
      </MenuItem>
    ))
  ), [relationShipCodes])

  return (
    <TextField
      fullWidth
      select
      label="Relationship"
      margin="dense"
      variant="standard"
      value={value?.code}
      onChange={handleChange}
    >
      {renderOptions()}
    </TextField>
  );
};

export default RelationshipSelector;
