import { createRef, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import FullCalendar, {
  CalendarApi,
  DayHeaderContentArg,
  EventContentArg,
} from '@fullcalendar/react';
import {
  NEXT_DATE_RANGE_SELECTED,
  PREVIOUS_DATE_RANGE_SELECTED,
  useEventBus,
} from '../../../../../../event-bus';
import { ActivityViewProps } from '../ActivityViewProps';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import { RangeOption } from '../../../utils/date-range';
import { ConcreteActivity } from 'src/sections/careflow/tasks-activities/model/Activity';
import ActivityCalendarItem from './ActivityCalendarItem';
import './styles.css';

const VIEW_MAPPINGS = {
  [RangeOption.TODAY]: 'timeGridDay',
  [RangeOption.WEEK]: 'dayGridWeek',
  [RangeOption.MONTH]: 'dayGridMonth',
};

const DAYS_MAP = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export function ActivityCalendarView({ now, activities, currentRange }: ActivityViewProps) {
  const calendarRef = createRef<FullCalendar>();
  const { onEvent, removeEvent } = useEventBus();
  const currentView = useMemo(() => VIEW_MAPPINGS[currentRange.option], [currentRange.option]);
  const events = useMemo(() => [...mapActivitiesToEvents(activities)], [activities]);

  const getCalendar = useCallback(
    (): CalendarApi | undefined => calendarRef.current?.getApi(),
    [calendarRef]
  );

  useEffect(() => {
    console.log('NEXT AND PREV PRESS')
    onEvent(PREVIOUS_DATE_RANGE_SELECTED, () => getCalendar()?.prev());
    onEvent(NEXT_DATE_RANGE_SELECTED, () => getCalendar()?.next());
    return () => {
      removeEvent(PREVIOUS_DATE_RANGE_SELECTED);
      removeEvent(NEXT_DATE_RANGE_SELECTED);
    };
  });

  useEffect(() => {
    console.log('BUTTONS PRESS')
    getCalendar()?.changeView(currentView);
    const { start, end } = currentRange.value;
    getCalendar()?.select(start, end);
  }, [currentRange.value, currentView, getCalendar]);

  function getRangeOptions(
    option: RangeOption,
    now: Date,
    activitiesParams: ConcreteActivity[]
  ): Record<string, any> {
    return {
      dayMaxEvents: true,
      dayHeaderContent: (info: DayHeaderContentArg) => getDayHeaderContent(info.date, activitiesParams),
      eventContent: (eventContent: EventContentArg) => renderEventContent(eventContent, activitiesParams),
    };
  }

  function renderEventContent(eventContent: EventContentArg, activitiesParam: ConcreteActivity[]) {
    const { event } = eventContent;
    const activity: ConcreteActivity | undefined = activitiesParam.find((a) => String(a.id) === event.id);

    if (!activity) return;

    return <ActivityCalendarItem activity={activity} event={event} />;
  }

  function getDayHeaderContent(date: Date, activitiesParam: ConcreteActivity[]) {
    const currentDay = DAYS_MAP[date.getDay()];
    const activityCount = activitiesParam.filter((activity) => activity.containsDay(date)).length;
    return [currentDay, activityCount > 0 ? ` (${activityCount} Act)` : ''].join('');
  }

  function mapActivitiesToEvents(activitiesParam: ConcreteActivity[]): any[] {
    return activitiesParam.map((activity) => ({
      id: activity.id,
      title: `${activity.patientName}${activity.patientAge ? `, ${activity.patientAge}` : ''}`,
      extendedProps: {
        description: `${activity.type}, ${activity.activity}`,
      },
      start: activity.start,
      end: activity.end,
      date: activity.date,
      allDay: activity.allDay,
    }));
  }

  return (
    <FullCalendar
      ref={calendarRef}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      selectable={true}
      weekends={true}
      events={events}
      initialView={currentView}
      {...getRangeOptions(currentRange.option, now, activities)}
      headerToolbar={false}
    />
  );
}
