import { UploadFiles } from "../..";
import { useSnackbar } from 'notistack';
import useAuth from "src/hooks/useAuth";
import { useForm } from "react-hook-form";
import { isEmpty, isEqual } from "lodash";
import { useMemo, useState } from "react";
import getFileData from "src/utils/getFileData";
import { getFhirIdFromEntity } from "src/utils/fhir";
import { FormProvider } from "src/components/hook-form";
import { useOrganizations } from "src/@nicheaim/fhir-react";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { WrappedCarePlan } from "src/@nicheaim/fhir-base/wrappers/CarePlan";
import { DocumentReference } from "src/@nicheaim/fhir-base/mappings/DocumentReference";
import { WrappedServiceRequest } from "src/@nicheaim/fhir-base/wrappers/ServiceRequest";
import { WrappedDocumentReference } from "src/@nicheaim/fhir-base/wrappers/DocumentReference";
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle, Stack } from "@mui/material";

const HOST_API = process.env.REACT_APP_HOST_API;
const FHIR_API = process.env.REACT_APP_FHIR_API_BASE_URL;

type FormValues = {
  files: Blob[];
  documents: string[];
  documentsPHIN: DocumentReference[];
};

type Props = {
  open: boolean;
  onClose: VoidFunction;
  patient: WrappedPatient | null;
  serviceRequest: WrappedServiceRequest | null;
  documentReferences: WrappedDocumentReference[] | null;
  carePlan?: WrappedCarePlan | null;
  handleCreateBynary: (data: any) => Promise<any[] | null>;
  handleCreateDocumentReference: (data: any) => Promise<any>;
  handleUpdateDocumentReference: (data: any) => Promise<any>;
};

export default function OutboundReferralDocuments ({ 
  patient, 
  serviceRequest,
  documentReferences,
  carePlan,
  open,
  onClose, 
  handleCreateBynary,
  handleCreateDocumentReference,
  handleUpdateDocumentReference
}: Props) {
  
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const authUser = useAuth().getCurrentUser();

  const documentsRelated = useMemo(
    () => documentReferences?.
      filter(d => d.context?.related?.
        find( r => r.reference === `${serviceRequest?.resourceType}/${serviceRequest?.id}`)).
          map((e) => e.id),
    [documentReferences, open, serviceRequest]
  ) || [];

  const methods = useForm<FormValues>({});

  const {
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = methods;

  const [ organization, ] = useOrganizations();
  
  const handleClose = () => {
    reset();
    onClose();
  };

  const convertBlobToBase64 = (file: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result?.toString().split(',')[1]);
      };
      reader.readAsDataURL(file);
  });

  const createBinaryCustom = async (data: FormValues): Promise<any> => {
    if (!data?.files?.length) return null;

    const resourceType = 'Binary';

    const mappedFile =  await Promise.all( 
      data.files.map(async (file: Blob) => {
        const contentType = file.type;
        const data = await convertBlobToBase64(file);
        const securityContext = { reference: `${patient?.resourceType}/${patient?.id}` };
        const name = getFileData(Object.assign(file)).name;
        const obj = { resourceType, contentType, securityContext, data};
        const binary = await handleCreateBynary(obj);

        const objNew = {...binary?.[0], name: name};
        return objNew;
      })
    );

    return mappedFile;
  };

  const createDocumentReference = async (binaryList: any) => {

    const organizationFhir = organization?.
    filter(e => e.id === authUser.organization_fhir_uri).
    map((r) => ({
      display: r.name, 
      reference: `Organization/${r.id}`
    }))[0];

    const documentReference = binaryList?.map((binary:any) => ({ 
      resourceType: 'DocumentReference',
      status: 'current',
      subject: { reference: `${patient?.resourceType}/${patient?.id}` },
      author: [{ 
        ...(!isEmpty(authUser.user_fhir_uri.trim()) && {
          reference: `Practitioner/${getFhirIdFromEntity(authUser.user_fhir_uri)}` 
        }), 
        display: authUser.name
      }],
      ...(organizationFhir && {
        custodian: organizationFhir
      }),
      date: new Date().toISOString(),
      content: [{ attachment: { 
        contentType: `${binary.contentType}`, 
        creation: new Date().toISOString(),
        title: binary.name, 
        url: `${FHIR_API}/Binary/${binary.id}` 
      }}],
     }));

     const createdDocumentReference = await handleCreateDocumentReference(documentReference);
     
    return createdDocumentReference;
  };
  
  const createDocumentReferencePHIN = async (data: DocumentReference[]) : Promise<any> => {
    
    if(data === undefined) return null;
    
    try{
      const document = data.map((doc) => {
        const documentUniqueId = doc?.identifier?.find((i) => i?.type?.text === 'documentUniqueId')?.value;
        const repositoryUniqueId = doc?.identifier?.find((i) => i?.type?.text === 'repositoryUniqueId')?.value;
        const hcid = doc?.identifier?.find((i) => i?.type?.text === 'hcid')?.value;

        doc.content[0].attachment.url = `${HOST_API}/crs/document/${documentUniqueId}?repositoryId=${repositoryUniqueId}&hcid=${hcid}`;
        doc.subject = { reference: `${patient?.resourceType}/${patient?.id}` };
  
        if(doc.context?.period?.end === null) delete doc.context?.period?.end;
        delete doc.content[0].attachment.data;
        delete doc.id;
  
        const { ...result } = doc;
        return result;
      })
  
      const documentReference = await handleCreateDocumentReference(document);

      return documentReference;

    }catch(e) {
      enqueueSnackbar('An error has occurred.', { variant:'error', autoHideDuration: 6000 });
    }
  };

  const updateDocumentsSelected = async (
    data: FormValues, 
    createdDocs: DocumentReference[] | undefined, 
    createdPhinDocs: DocumentReference[] | null, 
    resourceToRelate: any
  ): Promise<any> => {

    const { documents } = data;

    const delRelatedResource = documentsRelated?.filter((e:any) => !documents?.some((s:any) => s === e));

    const documentsNotRelated = documentReferences?.filter(p => delRelatedResource?.
      some((f:any) => p.id === f)).
        map((l) =>{

          const related = l.context?.related || [];  

          const notRelated = related?.
            filter(r => r.reference !== `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`) || [];
          const notRelatedCarePlan = notRelated?.
            filter(r => r.reference !== `${carePlan?.resourceType}/${carePlan?.id}`) || [];
          
          if(l.context?.related && !isEmpty(notRelatedCarePlan)){ 
            l.context.related = [ ...notRelatedCarePlan ];
          }else{
            delete l.context?.related;
          }

          if(isEmpty(l.context)){
            delete l.context;
          }

          const {...result} = l;
          return result;
    })|| [];

    let documentsSelected: any;

    const documentReferenceSelected = documentReferences?.filter(d => documentsSelected?.
      some((r: any) => d.id === r)).
        map((e) => {
          const context = e.context;
          const related = e.context?.related || [];  

          const resourceRelated = related.
            find(r => r.reference === `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`);
          const carePlanRelated = related.
            find(r => r.reference === `${carePlan?.resourceType}/${carePlan?.id}`);

          if(isEmpty(carePlanRelated)){
            related?.push({reference: `${carePlan?.resourceType}/${carePlan?.id}`});
          }

          if(isEmpty(resourceRelated)){
            e.context = { 
              ...context, 
              related: [ 
                ...related, 
                { reference: `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`}
            ]};
          }else {
            e.context = {...context, related: [ ...related ]};
          }

          const {...result} = e;
          return result;
    }) || [];

    const createdDocuments = createdDocs?.map((e) => {
      const context = e.context;
      const related = e.context?.related || [];

      const resourceRelated = related.find(r => r.reference === `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`);
      
      if(isEmpty(resourceRelated)){
        e.context = {...context, related: [ ...related, { reference: `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`}]};
      }else {
        e.context = {...context, related: [ ...related ]};
      }
      const {...result} = e;
      return result;
    }) || [];

    const phinDocuments = createdPhinDocs?.map((e) => {
      const context = e.context;
      const related = e.context?.related || [];

      const resourceRelated = related.find(r => r.reference === `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`);
      
      if(isEmpty(resourceRelated)){
        e.context = {...context, related: [ ...related, { reference: `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`}]};
      }else {
        e.context = {...context, related: [ ...related ]};
      }
      const {...result} = e;
      return result;
    }) || [];

    const allDocuments = [ ...documentsNotRelated, ...documentReferenceSelected, ...createdDocuments, ...phinDocuments];

    const updateDocumentReference = await handleUpdateDocumentReference(allDocuments);
    return updateDocumentReference;
  };

  const updateDocuments = ( data: FormValues ) => {
    const { documents, files, documentsPHIN } = data;

    const arraysDocsEqual = isEqual(documents, documentsRelated);

    if((documentsRelated && 
      (documents.length !== documentsRelated.length)) || 
      !arraysDocsEqual ||
      files?.length > 0 ||
      documentsPHIN?.length > 0
    ){
      return true;
    }
    return false;
  };

  const onSubmit = async (data: FormValues) => {

    try{

      let documentReference;
      let documentPHIN;
      let resourceToRelated: any;

      setOpenBackdrop(true);
      
      if(data.files !== undefined){
        const createBinary = await createBinaryCustom(data);
        documentReference = await createDocumentReference(createBinary);
      }

      if(data?.documentsPHIN?.length){
        documentPHIN = await createDocumentReferencePHIN(data.documentsPHIN) || [];
      }

      const update = updateDocuments(data);

      if(serviceRequest && update){
        resourceToRelated = serviceRequest;
        const updateDocuments = await updateDocumentsSelected(data, documentReference, documentPHIN, resourceToRelated);
      }

      setOpenBackdrop(false);
      handleClose();
    }catch(e){
      handleClose();
      setOpenBackdrop(false);
      enqueueSnackbar('An error has ocurred.', { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="md">
      <DialogTitle>Outbound Referral Attachments</DialogTitle>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <Stack sx={{ m: 4 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <UploadFiles label='referral' documentReferences={documentReferences} patient={patient} docRelated={documentsRelated}/>
            <Stack alignItems="center">
              <DialogActions>
                <Box sx={{ flexGrow: 1 }} />
                  <Button variant="outlined" color="info" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="outlined" color="info" type="submit">
                    Submit
                  </Button>
              </DialogActions>
            </Stack>
          </FormProvider>
        </Stack>
    </Dialog>
  )
}