export const UI_SCHEMA = {
  VALIDATE_HOME_ADDRESS_ITEM: {
    "allOf": [
      {
        "type": "object",
        "properties": {
          "title": {
            "type": "string",
            "widget": "LabelBox",
            "title": "Home Address Valid",
            "readOnly": true
          },
          "validatedOn": {
            "type": "string",
            "format": "date",
            "title": "Home address validated on",
            "view": {
                "sizeMd": 6,
                "shrink": true
            },
            "readOnly": true
        },
          "modifiedFields":{
            "type": "object",
            "properties": {
              "city": {
                "type": "string",
                "title":"City",
                "deleteOnEmpty": true,
              },
              "country": {
                "type": "string",
                "title":"Country",
                "deleteOnEmpty": true,
              },
              "line_0": {
                "type": "string",
                "title":"Street 1",
                "deleteOnEmpty": true,
              },
              "line_1": {
                "type": "string",
                "title":"Street 2",
                "deleteOnEmpty": true,
              },
              "period": {
                "type": "object",
                "properties": {
                  "start": {
                    "type": "string",
                    "format": "date",
                    "title":"Start",
                    "deleteOnEmpty": true,
                  },
                  "end": {
                    "type": "string",
                    "format": "date",
                    "title":"End",
                    "deleteOnEmpty": true,
                  }
                }
              },
              "postalCode": {
                "type": "string",
                "title":"Postal Code",
                "deleteOnEmpty": true,
              },
              "state": {
                "type": "string",
                "title":"State",
                "deleteOnEmpty": true,
              },
            }
          }
        }
      }
    ]
  },
  VALIDATE_ADDRESS_IN_COUNTY_ITEM: {
    "allOf": [
      {
        "type": "object",
        "properties": {
          "title": {
            "type": "string",
            "widget": "LabelBox",
            "title": "Address In County",
            "readOnly": true
          },
          "validatedOn": {
            "type": "string",
            "format": "date",
            "title": "Address in county validated on",
            "view": {
                "sizeMd": 6,
                "shrink": true
            },
            "readOnly": true
        },
          "modifiedFields":{
            "type": "object",
            "properties": {
              "isInCounty": {
                "type": "boolean",
                "title":"Is In County?",
                "deleteOnEmpty": true,
              },
              "Notes": {
                "type": "string",
                "title":"Notes",
                "deleteOnEmpty": true
              }
            }
          }
        }
      }
    ]
  },
  CLIENT_INTERVIEW_CLIENT: {
    CONTACT_CLIENT_ITEM: {
      "allOf": [
        {
          "type": "object",
          "properties": {
            "title": {
              "type": "string",
              "widget": "LabelBox",
              "title": "Contact Client",
              "readOnly": true
            },
            "validatedOn": {
              "type": "string",
              "format": "date",
              "title": "Contact client validated on",
              "view": {
                  "sizeMd": 6,
                  "shrink": true
              },
              "readOnly": true
            },
            "modifiedFields":{
              "type": "object",
              "properties": {
                "contactOn": {
                  "type": "string",
                  "format": "date",
                  "title":"Contact On",
                  "deleteOnEmpty": true,
                },
                "nextContactOn": {
                  "type": "string",
                  "format": "date",
                  "title":"Next Contact On",
                  "deleteOnEmpty": true,
                },
                "outcome": {
                  "type": "string",
                  "widget": "Select",
                  "title":"Outcome",
                  "deleteOnEmpty": true,
                  "enum": [
                    "HealthJourney scheduled",
                    "Interested - requires follow-up",
                    "No answer - voicemail left",
                    "No answer - no voicemail left",
                    "Opt out - specify reason",
                    "Prompt to Call Letter",
                    "Wrong number",
                    "Other - specify in notes"
                  ]
                },
                "reasonNote": {
                  "type": "string",
                  "title":"Reason Note",
                  "deleteOnEmpty": true,
                }
              }
            }
          }
        }
      ]
    }
  },
  CLIENT_INTERVIEW_PCP:  {
    CONTACT_PCP_ITEM: {
      "allOf": [
        {
          "type": "object",
          "properties": {
            "title": {
              "type": "string",
              "widget": "LabelBox",
              "title": "Contact PCP",
              "readOnly": true
            },
            "validatedOn": {
              "type": "string",
              "format": "date",
              "title": "Contact pcp validated on",
              "view": {
                  "sizeMd": 6,
                  "shrink": true
              },
              "readOnly": true
            },
            "modifiedFields":{
              "type": "object",
              "properties": {
                "contactOn": {
                  "type": "string",
                  "format": "date",
                  "title":"Contact On",
                  "deleteOnEmpty": true,
                },
                "nextContactOn": {
                  "type": "string",
                  "format": "date",
                  "title":"Next Contact On",
                  "deleteOnEmpty": true,
                },
                "outcome": {
                  "type": "string",
                  "widget": "Select",
                  "title":"Outcome",
                  "deleteOnEmpty": true,
                  "enum": [
                    "HealthJourney scheduled",
                    "Interested - requires follow-up",
                    "No answer - voicemail left",
                    "No answer - no voicemail left",
                    "Opt out - specify reason",
                    "Prompt to Call Letter",
                    "Wrong number",
                    "Other - specify in notes"
                  ]
                },
                "reasonNote": {
                  "type": "string",
                  "title":"Reason Note",
                  "deleteOnEmpty": true,
                }
              }
            }
          }
        }
      ]
    }
  },
  CASE_CONTACT_ATTEMPT_ITEM: {
    "allOf": [
      {
        "type": "object",
        "properties": {
          "title": {
            "type": "string",
            "widget": "LabelBox",
            "title": "Contact Attempt",
            "readOnly": true
          },
          "validatedOn": {
            "type": "string",
            "format": "date",
            "title": "Contact Attempts validated on",
            "view": {
                "sizeMd": 6,
                "shrink": true
            },
            "readOnly": true
          },
          "modifiedFields":{
            "type": "object",
            "properties": {
              "contactOn": {
                "type": "string",
                "format": "date",
                "title":"Contact On",
                "deleteOnEmpty": true,
              },
              "nextContactOn": {
                "type": "string",
                "format": "date",
                "title":"Next Contact On",
                "deleteOnEmpty": true,
              },
              "outcome": {
                "type": "string",
                "widget": "Select",
                "title":"Outcome",
                "deleteOnEmpty": true,
                "enum": [
                  "HealthJourney scheduled",
                  "Interested - requires follow-up",
                  "No answer - voicemail left",
                  "No answer - no voicemail left",
                  "Opt out - specify reason",
                  "Prompt to Call Letter",
                  "Wrong number",
                  "Other - specify in notes"
                ]
              },
              "reasonNote": {
                "type": "string",
                "title":"Reason Note",
                "deleteOnEmpty": true,
              }
            }
          }
        }
      }
    ]
  }
}; 