import client from './_client';
import GroupSessionType from 'src/sections/careflow/group-sessions/types/GroupSessionType';
import {
  DropDownItem,
  ScheduleItemType,
} from 'src/sections/careflow/group-sessions/components/AddGroupSessionModal/types';
import { SessionsFilterEnum } from 'src/sections/careflow/group-sessions/components/SessionTable/SessionTable';

export type SaveGroupSessionInput = {
  schedules: ScheduleItemType[];
  assessments: DropDownItem[];
  patients: DropDownItem[];
  location: DropDownItem;
  patientsToDelete: number[];
  assessmentsToDelete: number[];
  schedulesToDelete: (number | null)[];
  name: string;
  description: string;
  startDate: string;
  endDate: string;
};

type GroupSessionListFilters = {
  filter: string | null;
  status: number;
  take: number;
  skip: number;
};

export const getGroupSessions = async ({
  take,
  skip,
  filter,
  status,
}: GroupSessionListFilters): Promise<{
  groupSessions: GroupSessionType[];
  total: number;
}> => {
  let url = `/ccm/group-session/list?take=${take}&skip=${skip}&status=${status}`;

  if (filter) {
    url = `${url}&filter=${filter}`;
  }

  const { data } = await client.get(url);

  return data;
};

export const toggleSessionParticipantAttended = async (id: number) => {
  const { data } = await client.post(
    `/ccm/group-session/session-participants/${id}/update-attended`
  );

  return data;
};

export const create = async (params: SaveGroupSessionInput) => {
  const { data } = await client.post('/ccm/group-session/save', params);

  return data;
};

export const update = async (id: number, params: SaveGroupSessionInput) => {
  const { data } = await client.post(`/ccm/group-session/save/${id}`, params);

  return data;
};

export const getSessionsByGroupSession = async (
  id: number,
  params: { filter: SessionsFilterEnum; take: number; page: number }
) => {
  const { data } = await client.get(
    `/ccm/group-session/${id}/sessions?take=${params.take}&page=${params.page}&filter=${params.filter}`
  );

  return data;
};

export const updateSessionNote = async (id: number, note: string) => {
  const { data } = await client.post(`/ccm/group-session/session/${id}/update-note`, {
    note,
  });

  return data;
};

export const updateSessionParticipantNote = async (id: number, note: string) => {
  const { data } = await client.post(`/ccm/group-session/session-participants/${id}/update-note`, {
    note,
  });

  return data;
};

export const updateSession = async (
  id: number,
  params: {
    patientIds: number[];
    status: string;
    date: string;
    start: string;
    end: string;
  }
) => {
  const { data } = await client.post(`/ccm/group-session/session-update/${id}`, params);

  return data;
};
