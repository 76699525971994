import { useEffect, useMemo, useState } from 'react';
import {
  HealthcareServiceWrapper,
  WrappedHealthcareService,
} from 'src/@nicheaim/fhir-base/wrappers/HealthcareService';
import { ServiceRequestWrapper } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { useHealthcareServices, useServiceRequests } from 'src/@nicheaim/fhir-react';

const useHealthCareServicesByServiceRequests = (
  serviceRequestIds: string[] | null,
  patientId?: string
): { healthCareServices: WrappedHealthcareService[]; isLoading: boolean } => {
  const [healthCareServiceIds, setHealthCareServiceIds] = useState<string[]>([]);
  const [serviceRequests, { isFetching: isServiceRequestsLoading }] = useServiceRequests({
    filter: serviceRequestIds?.length
      ? { _id: serviceRequestIds.join(',') ?? null }
      : { subject: patientId },
    map: ServiceRequestWrapper,
    autofetch: !!serviceRequestIds?.length || !!patientId,
  });

  const [healthCareServicesRecords, { isFetching: isHealthCareLoading }] = useHealthcareServices({
    filter: { _id: healthCareServiceIds.join(',') },
    map: HealthcareServiceWrapper,
    autofetch: !!healthCareServiceIds.length,
  });
  useEffect(() => {
    if (!serviceRequests.length) return;
    const healthCareServiceIds = serviceRequests.reduce<string[]>(
      (healthCareServiceIds, serviceRequest) => [
        ...healthCareServiceIds,
        ...serviceRequest.getHealthcareServiceIds(),
      ],
      []
    );
    if (!healthCareServiceIds.length) return;
    setHealthCareServiceIds(healthCareServiceIds);
  }, [serviceRequests]);

  const healthCareServices = useMemo(
    () => healthCareServicesRecords.filter(({ program }) => !!program?.length),
    [healthCareServicesRecords]
  );

  return { healthCareServices, isLoading: isServiceRequestsLoading || isHealthCareLoading };
};

export default useHealthCareServicesByServiceRequests;
