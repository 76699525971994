import { Navigate, Route, Routes } from 'react-router';
import ExitDestinationsTable from './components/ExitDestinationsTable';

// routes
import NewExitDestinationForm from './new';
import EditExitDestinationForm from './$exitDestination.edit';

export default function ExitDestinationsIndex() {
  return (
    <Routes>
      <Route index element={<ExitDestinationsTable />} />
      <Route path="new" element={<NewExitDestinationForm />} />
      <Route path=":exitDestination" element={<Navigate to="edit" />} />
      <Route path=":exitDestination/edit" element={<EditExitDestinationForm />} />
    </Routes>
  );
}
