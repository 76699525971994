import { useObservations } from 'src/@nicheaim/fhir-react';

export function useVitalSigns(options?: Parameters<typeof useObservations>[0]) {
  const [vitalsObservations, queryStatus] = useObservations({
    ...(options as any),
    filter: {
      category: 'vital-signs',
      code: '29274-8', // Vital signs
      ...options?.filter,
    },
  });

  return [vitalsObservations, queryStatus] as const;
}
