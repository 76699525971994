class EventBus {
  callbacks: any = {}; //TODO: Fix type

  on(event: string, callback: Function) {
    if (!this.callbacks[event]) {
      this.callbacks[event] = [];
    }
    this.callbacks[event].push(callback);
  }

  dispatch(event: string, data: unknown) {
    if (!this.callbacks[event]) return;
    this.callbacks[event].forEach((callback: Function) => callback.call(null, data));
  }

  remove(event: string, callback: Function) {
    const events = Object.keys(this.callbacks);
    events.forEach(
      (ev) =>
        (this.callbacks[ev] = [...this.callbacks[ev].filter((cb: Function) => cb !== callback)])
    );
  }
}

export default EventBus;
