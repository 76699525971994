import {
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import { useContext, useMemo, useState } from 'react';
import Iconify from 'src/components/Iconify';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useNavigate } from 'react-router-dom';
import { ActivitiesContext, ActivitiesContextType } from '../../Activities/ActivitiesContext';
import { ActivityActionTypes } from '../../Activities/CompleteActivityMenu/CompleteActivityMenuItem';
import PatientViewModel from '../ViewModel/PatientViewModel';
import AssignToPractitioner, { IAssignToPractitionerData } from './AssignToPractitioner';
import EditPatientPrograms from './EditPatientStatus';
import produce from 'immer';
import { CompleteActivityMenu } from '../../Activities/CompleteActivityMenu';
import api from 'src/services/api';
import { ConcreteActivity } from '../../../model/Activity';
import { useCodeSystem } from 'src/@nicheaim/fhir-react';
import usePatientTabsStore from 'src/stores/patient-tabs';
import { getActivityNames } from '../../Activities/ActivityListView/ActivityListTable/activity-list-table-utils';

type PatientContextMenuItemType = {
  label: string;
  icon: string;
  onPress: (patient: PatientViewModel) => void;
  rules: (patient: PatientViewModel) => boolean;
};

type PatientContextMenuPropsType = {
  patient: PatientViewModel;
  onUpdatePatient: (patient: PatientViewModel) => void;
};

const PatientContextMenu = ({ onUpdatePatient, patient }: PatientContextMenuPropsType) => {
  const assignToPracInitialValue: IAssignToPractitionerData = {
    patientId: '',
    assign: false,
  };
  const [assignToPrac, setAssignToPrac] =
    useState<IAssignToPractitionerData>(assignToPracInitialValue);
  const navigate = useNavigate();
  const activitiesContext: ActivitiesContextType = useContext(ActivitiesContext);
  const popupState = usePopupState({ variant: 'popover', popupId: 'schedule-activity-modal' });
  const triggers = useMemo(() => bindTrigger(popupState), [popupState]);
  const [codeSystem] = useCodeSystem('ccm-activity-contact-types');
  const patientStore = usePatientTabsStore();
  const menuItems: PatientContextMenuItemType[] = useMemo(
    () => [
      {
        label: 'Schedule Activity',
        icon: 'bi:calendar-check',
        onPress: (currentPatient: PatientViewModel) =>
          activitiesContext.scheduleActivityForPatient(currentPatient),
        rules: () => true,
      },
      {
        label: 'Create Activity',
        icon: 'carbon:task-complete',
        onPress: (currentPatient: PatientViewModel) =>
          activitiesContext.scheduleActivityForPatient(currentPatient, true),
        rules: () => true,
      },
      {
        label: 'View Health Record',
        icon: 'material-symbols:create-new-folder-sharp',
        onPress: (currentPatient: PatientViewModel) => {
          navigate('/dashboard/patient/' + currentPatient.fhirId + '/health-record');
        },
        rules: () => true,
      },
      {
        label: 'View Care Plan',
        icon: 'material-symbols:create-new-folder-sharp',
        onPress: (currentPatient: PatientViewModel) => {
          navigate('/dashboard/patient/' + currentPatient.fhirId + '/care-record');
        },
        rules: () => true,
      },
      {
        label: 'View Calendar',
        icon: 'material-symbols:calendar-apps-script',
        onPress: (currentPatient: PatientViewModel) =>
          activitiesContext.showCalendarForPatient(currentPatient),
        rules: () => true,
      },
      {
        label: 'Assign To Me',
        icon: 'clarity:assign-user-solid',
        onPress: (currentPatient: PatientViewModel) => {
          setAssignToPrac({
            patientId: currentPatient.fhirId,
            assign: true,
          });
          popupState.close();
        },
        rules: () => true,
      },
    ],
    [activitiesContext, navigate, popupState]
  );
  const [selectedActivity, setSelectedActivity] = useState<ConcreteActivity | null>(null);
  const [showCompleteActivityMenu, setShowCompleteActivityMenu] = useState<boolean>(false);

  const onCloseCompleteMenu = () => setShowCompleteActivityMenu(false);

  return (
    <>
      <Dialog open={showCompleteActivityMenu} onClose={onCloseCompleteMenu}>
        <DialogContent>
          <CompleteActivityMenu
            TitleComponent={
              <>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Start Next Activity
                </Typography>
                {selectedActivity && getActivityNames(selectedActivity, codeSystem)}
              </>
            }
            activityId={selectedActivity?.id ?? 0}
            type={ActivityActionTypes.START}
            onSaveActivity={(savedActivity) => {
              onCloseCompleteMenu();
            }}
            onCloseMenuItem={onCloseCompleteMenu}
          />
        </DialogContent>
      </Dialog>
      <IconButton {...triggers}>
        <Iconify color={'#637381'} icon="eva:more-vertical-fill" />
      </IconButton>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {patient.nextActivityId && (
          <MenuItem
            onClick={async () => {
              const activity: ConcreteActivity = await api.activities.getActivityById(
                patient.nextActivityId
              );
              if (!activity.scriptId) {
                setSelectedActivity(new ConcreteActivity(activity));
                setShowCompleteActivityMenu(true);
                popupState.close();
              } else {
                const { patientFhirId, scriptId, taskId, id: activityId } = activity;

                if (!patientFhirId) return;
                await activitiesContext.startActivity(activity);

                if (!scriptId) return;

                patientStore.setCurrentPatient(patientFhirId);
                patientStore.openPatient(patientFhirId);

                navigate(
                  `/dashboard/patient/${patientFhirId}/care-record/assessments/new/${scriptId}`,
                  {
                    state: {
                      taskId,
                      activityId,
                    },
                  }
                );
              }
            }}
          >
            <Stack direction="row" spacing={2}>
              <Iconify icon={'mdi:paper-check'} />
              <Typography variant="body2">Start Next Activity</Typography>
            </Stack>
          </MenuItem>
        )}
        {menuItems
          .filter((menuItem: PatientContextMenuItemType) => menuItem.rules(patient))
          .map((menuItem: PatientContextMenuItemType) => (
            <MenuItem
              key={menuItem.label}
              onClick={() => {
                menuItem.onPress(patient);
              }}
            >
              <Stack direction="row" spacing={2}>
                <Iconify icon={menuItem.icon} />
                <Typography variant="body2">{menuItem.label}</Typography>
              </Stack>
            </MenuItem>
          ))}
        {patient && (
        <EditPatientPrograms
          patientId={patient.fhirId}
          patient={patient}
          handleOnClose={() => {
            popupState.close();
          }}
        />
        )}
      </Menu>
      <AssignToPractitioner
        data={assignToPrac}
        onFinish={(practitionerName: string) => {
          const updatedPatient = produce(patient, (draft) => {
            draft.assignedToName = practitionerName;
            // draft.displayAssignedToProccesing = true;
          });

          onUpdatePatient(updatedPatient);
          setAssignToPrac(assignToPracInitialValue);
        }}
      />
    </>
  );
};

export default PatientContextMenu;
