import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Link as RouterLink,
  matchPath,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom';
// form
import { useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Button, TextField } from '@mui/material';
// components
import { FormProvider, RHFSelect } from 'src/components/hook-form';
import {
  useGroups,
  useHealthcareServices,
  useOrganizations,
  usePatients,
} from 'src/@nicheaim/fhir-react';
import fhirSystem from 'src/fhir/system';
import RHFAutocomplete from 'src/components/hook-form/RHFAutocomplete';
import { PatientWrapper, WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import RHFDatePicker from 'src/components/hook-form/RHFDatePicker';
import { MenuItem } from '@mui/material';

// ----------------------------------------------------------------------

type FormValue = {
  client: string;
  plan: string;
  lob: string;
  program: string;
  patient: WrappedPatient | null;
  start?: Date | null;
  end?: Date | null;
  status: 'new' | 'pending' | 'active' | 'inactive';
};

type ProgramFormProps = {
  isEdit?: boolean;
};

const schema = Yup.object().shape({
  client: Yup.string().required('Client is required'),
  plan: Yup.string().required('Plan is required'),
  lob: Yup.string().required('Line of Business is required'),
  program: Yup.string().required('Program is required'),
  patient: Yup.object().nullable().required('Patient is required'),
  start: Yup.date().nullable(),
  end: Yup.date().nullable(),
  status: Yup.string()
    .required('Status is required')
    .oneOf(['new', 'pending', 'active', 'inactive']),
});

const resolver = yupResolver(schema);

export default function AddPatientToProgramForm({ isEdit }: ProgramFormProps) {
  const navigate = useNavigate();
  const { params } = useMatch(
    '/settings/clients/:client/plans/:plan/lobs/:lob/programs/:program/patients/new'
  ) || {
    params: {
      client: '',
      plan: '',
      lob: '',
      program: '',
    },
  };
  const [[enrolledPatientsGroup], { create, update }] = useGroups({
    filter: {
      identifier: fhirSystem.program.enrolledPatients.forCode(params.program || 'unknown-id'),
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [patientInput, setPatientInput] = useState('');
  const [patients] = usePatients({
    filter: {
      'name:contains': patientInput.replace(/ +/, ' ').split(' ').join(','),
      _count: 10,
    },
    map: PatientWrapper,
    autofetch: !!patientInput,
  });

  const methods = useForm({
    resolver,
    defaultValues: {
      client: params.client || '',
      plan: params.plan || '',
      lob: params.lob || '',
      program: params.program || '',
      patient: null as string | null,
      start: null as Date | null,
      end: null as Date | null,
      status: 'new' as FormValue['status'],
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValue) => {
    try {
      if (!params.client) throw new Error('Client ID is required');
      if (!params.plan) throw new Error('Plan ID is required');
      if (!params.lob) throw new Error('Line of Business ID is required');
      if (!params.program) throw new Error('Program ID is required');
      if (!data.patient?.id) throw new Error('Patient is required');
      if (!data.status) throw new Error('Status is required');

      switch (data.status) {
        case 'pending':
          if (data.end) throw new Error('End date is for new patients');
          break;
        case 'new': {
          // if new, should not have start nor end date
          if (data.start) throw new Error('Start date is not for new patients');
          if (data.end) throw new Error('End date is for new patients');
          break;
        }
        case 'active': {
          // if active, should have start date
          if (!data.start) throw new Error('Start date is required for active patients');
          break;
        }
        case 'inactive': {
          // if inactive, should have start and end date
          if (!data.start) throw new Error('Start date is required for inactive patients');
          if (!data.end) throw new Error('End date is required for inactive patients');
          break;
        }
      }

      if (!enrolledPatientsGroup) {
        await create({
          resourceType: 'Group',
          identifier: [
            {
              system: fhirSystem.program.enrolledPatients.asString(),
              value: params.program,
            },
          ],
          type: 'person',
          actual: true,
          member: [
            {
              entity: {
                type: 'Patient',
                id: data.patient.id,
                reference: `Patient/${data.patient.id}`,
              },
              inactive: ['new', 'inactive'].includes(data.status),
              period:
                data.start || data.end
                  ? {
                      start: data.start?.toISOString(),
                      end: data.end?.toISOString(),
                    }
                  : undefined,
            },
          ],
          managingEntity: {
            reference: `Organization/${params.lob}`,
          },
          code: {
            coding: [
              {
                system: fhirSystem.program.asString(),
                code: params.program,
              },
            ],
          },
        });
      } else {
        await update({
          ...enrolledPatientsGroup,
          member: [
            ...(enrolledPatientsGroup.member || []),
            {
              entity: {
                type: 'Patient',
                id: data.patient.id,
                reference: `Patient/${data.patient.id}`,
              },
              inactive: ['new', 'inactive'].includes(data.status),
              period:
                data.start || data.end
                  ? {
                      start: data.start?.toISOString(),
                      end: data.end?.toISOString(),
                    }
                  : undefined,
            },
          ],
        });
      }

      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      navigate(isEdit ? '../..' : '..');
    } catch (error) {
      const message =
        error.response?.data?.message?.[0] || error?.message || 'Something went wrong!';

      enqueueSnackbar(message, {
        variant: 'error',
      });

      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit((data) => onSubmit(data as FormValue))}>
      <Box sx={{ p: 3 }}>
        <Stack
          gap={3}
          sx={{
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <Selector
            name="client"
            label="Client"
            useResourcesHook={useOrganizations}
            hookOptions={(input) => ({
              filter: {
                'name:contains': input ? input.split(' ').join(',') : undefined,
                identifier: fhirSystem.client.forCode(''),
              },
            })}
          />
          <Selector
            name="plan"
            label="Plan"
            useResourcesHook={useOrganizations}
            hookOptions={(input, selected) => ({
              filter: {
                'name:contains': input ? input.split(' ').join(',') : undefined,
                identifier: fhirSystem.healthPlan.withId(selected.client!).forCode(''),
              },
              autofetch: !!selected.client,
            })}
          />
          <Selector
            name="lob"
            label="LOB"
            useResourcesHook={useOrganizations}
            hookOptions={(input, selected) => ({
              filter: {
                'name:contains': input ? input.split(' ').join(',') : undefined,
                identifier: fhirSystem.lineOfBusiness.withId(selected.plan!).forCode(''),
              },
              autofetch: !!selected.plan,
            })}
          />
          <Selector
            name="program"
            label="Program"
            useResourcesHook={useHealthcareServices}
            hookOptions={(input, selected) => ({
              filter: {
                'name:contains': input ? input.split(' ').join(',') : undefined,
                identifier: fhirSystem.program.withId(selected.lob!).forCode(''),
              },
              autofetch: !!selected.lob,
            })}
          />

          <RHFAutocomplete
            name="patient"
            options={patients}
            onInputChange={(_, value) => {
              setPatientInput(value);
            }}
            renderInput={(params) => <TextField {...params} label="Patient" />}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.getFullName() || ''
            }
            filterOptions={(options) => options}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            inputMode="search"
            freeSolo={false}
            multiple={false}
          />

          <RHFDatePicker name="start" label="Start Date" clearable />
          <RHFDatePicker name="end" label="End Date" clearable />
          <RHFSelect name="status" label="Status">
            <MenuItem value="new">New</MenuItem>
            <MenuItem value="pending">Pending</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </RHFSelect>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }} gap={2}>
          <Button
            variant="text"
            color="inherit"
            component={RouterLink}
            to={isEdit ? '../..' : '..'}
          >
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {!isEdit ? 'Assign Program' : 'Save Changes'}
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}

interface SelectorProps {
  name: 'client' | 'plan' | 'lob' | 'program';
  label: string;
  useResourcesHook: typeof useOrganizations | typeof useHealthcareServices; // no type for the hook yet
  hookOptions: (
    input: string,
    selected: {
      client?: string;
      plan?: string;
      lob?: string;
      program?: string;
    }
  ) => Parameters<typeof useOrganizations>[0] | Parameters<typeof useHealthcareServices>[0];
}

function Selector({ name, useResourcesHook, label, hookOptions }: SelectorProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const form = useFormContext();
  const selected = useMemo<{
    client?: string;
    plan?: string;
    lob?: string;
    program?: string;
  }>(() => {
    const allSelected = matchPath(
      '/settings/clients/:client/plans/:plan/lobs/:lob/programs/:program/patients/new',
      location.pathname
    );
    const upToLOBSelected = matchPath(
      '/settings/clients/:client/plans/:plan/lobs/:lob/patients/new',
      location.pathname
    );
    const upToPlanSelected = matchPath(
      '/settings/clients/:client/plans/:plan/patients/new',
      location.pathname
    );
    const upToClientSelected = matchPath(
      '/settings/clients/:client/patients/new',
      location.pathname
    );

    if (allSelected) return allSelected.params;
    if (upToLOBSelected) return upToLOBSelected.params;
    if (upToPlanSelected) return upToPlanSelected.params;
    if (upToClientSelected) return upToClientSelected.params;

    return {
      client: undefined,
      plan: undefined,
      lob: undefined,
      program: undefined,
    };
  }, [location]);
  const [searchInput, setSearchInput] = useState('');
  const options = useResourcesHook(hookOptions(searchInput, selected) as any)[0];

  const selectedOption = useMemo(() => {
    return options.find((option) => option.id === selected[name]);
  }, [options, selected, name]);

  return (
    <RHFAutocomplete
      name={name}
      options={options}
      value={selectedOption}
      getOptionLabel={(option) => option.name || ''}
      onChange={(_, value) => {
        form.setValue(name, value?.id || '');
        navigate(getNewUrl(selected, name, value?.id), {
          replace: true,
        });
      }}
      onInputChange={(_, value) => {
        if (!value) return;

        setSearchInput(value);
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
      filterOptions={(options) => options}
      inputMode="search"
      freeSolo={false}
      multiple={false}
      // FIXME: Bug on navigating on change
      disabled
    />
  );
}

// TODO: Refactor to use <Navigate /> components instead
function getNewUrl(
  selected: {
    client?: string;
    plan?: string;
    lob?: string;
    program?: string;
  },
  name: string,
  value?: string
) {
  const prefix = '/settings';
  const suffix = '/patients/new';

  if (selected.client) {
    // there is a client selected
    if (name === 'client') {
      if (value) {
        // selecting a new one?
        return `${prefix}/clients/${value}${suffix}`;
      } else {
        // removing the current one?
        throw new Error('Cannot deselect the client');
      }
    } else {
      // no change
    }
  } else {
    // no client selected
    if (name === 'client') {
      // selecting a new one?
      if (value) {
        return `${prefix}/clients/${value}${suffix}`;
      }
    } else {
      // no change
    }
  }

  if (selected.plan) {
    // there is a plan selected
    if (name === 'plan') {
      if (value) {
        // selecting a new one?
        return `${prefix}/clients/${selected.client}/plans/${value}${suffix}`;
      } else {
        // removing the current one?
        return `${prefix}/clients/${selected.client}${suffix}`;
      }
    } else {
      // no change
    }
  } else {
    // no plan selected
    if (name === 'plan') {
      // selecting a new one?
      if (value) {
        return `${prefix}/clients/${selected.client}/plans/${value}${suffix}`;
      }
    } else {
      // no change
    }
  }

  if (selected.lob) {
    // there is a lob selected
    if (name === 'lob') {
      if (value) {
        // selecting a new one?
        return `${prefix}/clients/${selected.client}/plans/${selected.plan}/lobs/${value}${suffix}`;
      } else {
        // removing the current one?
        return `${prefix}/clients/${selected.client}/plans/${selected.plan}${suffix}`;
      }
    } else {
      // no change
    }
  } else {
    // no lob selected
    if (name === 'lob') {
      // selecting a new one?
      if (value) {
        return `${prefix}/clients/${selected.client}/plans/${selected.plan}/lobs/${value}${suffix}`;
      }
    } else {
      // no change
    }
  }

  if (selected.program) {
    // there is a program selected
    if (name === 'program') {
      if (value) {
        // selecting a new one?
        return `${prefix}/clients/${selected.client}/plans/${selected.plan}/lobs/${selected.lob}/programs/${value}${suffix}`;
      } else {
        // removing the current one?
        return `${prefix}/clients/${selected.client}/plans/${selected.plan}/lobs/${selected.lob}${suffix}`;
      }
    } else {
      // no change
    }
  } else {
    // no program selected
    if (name === 'program') {
      // selecting a new one?
      if (value) {
        return `${prefix}/clients/${selected.client}/plans/${selected.plan}/lobs/${selected.lob}/programs/${value}${suffix}`;
      }
    } else {
      // no change
    }
  }

  throw new Error('Cannot deselect the client');
}
