import { Box, Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Slider, { Settings } from 'react-slick';
import Iconify from 'src/components/Iconify';

const carouselSettings = {
  arrows: false,
  draggable: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  speed: 800,
};

interface CarouselProps {
  children: React.ReactNode[];
  current?: number;
  expandedComponent?: React.ReactNode;
  open?: boolean;
  settings?: Partial<Settings>;
  buttonsBehavior?: 'page' | 'slide';
  onChange?: (value: {
    page: number;
    slide: number;
    behavior: 'page' | 'slide';
    external: boolean;
  }) => void;
}

const Carousel = ({
  children,
  current = 0,
  expandedComponent,
  open = false,
  settings,
  buttonsBehavior = 'slide',
  onChange,
}: CarouselProps) => {
  const carouselRef = useRef<Slider>(null);
  const slidesToShow = settings?.slidesToShow ?? carouselSettings.slidesToShow;
  const slides = React.Children.count(children);
  const pages = useMemo(() => Math.ceil(slides / slidesToShow), [slides, slidesToShow]);
  const page = useMemo(() => Math.ceil((current - 1 ?? 0) / slidesToShow) + 1, [current, slidesToShow]);
  const [externallyTriggered, setExternallyTriggered] = useState(false);

  const handleChange = (currentSlide: number, nextSlide: number) => {
    const newPage = Math.max(Math.ceil(nextSlide / slidesToShow) - 1, 1);
    onChange?.({
      slide: nextSlide,
      page: newPage,
      behavior: buttonsBehavior,
      external: externallyTriggered,
    });
    setExternallyTriggered(false);
  };

  useEffect(() => {
    if (!isNaN(current)) {
      carouselRef.current?.slickGoTo(current);
      setExternallyTriggered(true);
    }
  }, [current]);

  console.log({
    slidesToShow,
    slide: current,
    page,
    pages
  })

  const disableNextButton = () => {
    if (slidesToShow === 1) {
      return (current + 1) === pages;
    }

    return page === pages;
  }

  return (
    <Stack spacing={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography>
          Viewing modules {(page - 1) * slidesToShow + 1} to{' '}
          {Math.min(page * slidesToShow, children.length)} of {children.length}
        </Typography>

        {children.length > slidesToShow && (
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              onClick={() => {
                carouselRef.current?.slickPrev();
              }}
              sx={{ borderColor: 'rgba(145, 158, 171, 0.32)', color: 'rgb(36, 31, 32)' }}
              startIcon={
                <Iconify
                  icon={'eva:arrow-ios-back-fill'}
                  sx={{ border: 'black', color: 'black' }}
                />
              }
              disabled={current === 0}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}
              onClick={() => {
                carouselRef.current?.slickNext();
              }}
              sx={{ borderColor: 'rgba(145, 158, 171, 0.32)', color: 'rgb(36, 31, 32)' }}
              disabled={disableNextButton()}
            >
              Next
            </Button>
          </Stack>
        )}
      </Stack>
      <Box style={{ position: 'relative' }}>
        <Slider ref={carouselRef} {...carouselSettings} {...settings} beforeChange={handleChange}>
          {children.map((c: any, index: number) => (
            <Box key={`carousel-${index}`} sx={{ px: 1 }}>
              {c}
            </Box>
          ))}
        </Slider>

        <Dialog
          disableEnforceFocus
          disablePortal
          disableScrollLock
          fullWidth
          hideBackdrop
          scroll="paper"
          maxWidth="xl"
          open={open}
          style={{ position: 'absolute', bottom: 'auto' }}
        >
          <DialogContent sx={{ padding: 0 }}>{expandedComponent}</DialogContent>
        </Dialog>
      </Box>
    </Stack>
  );
};

export default Carousel;
