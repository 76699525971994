import {
  Box,
  Button,
  Card,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';
import useTable from 'src/hooks/useTable';
import { capitalCase } from 'change-case';
import Iconify from 'src/components/Iconify';
import { useEffect, useState } from 'react';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getTimeSince } from 'src/utils/formatTime';
import { ReferralDto } from 'src/crs/dto/referral.dto';
import AddReferral from './child-referral/AddReferral';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useServiceRequests } from 'src/@nicheaim/fhir-react';
import { TABLE_HEAD_CHILD_REFERRAL } from '../../common/table-head';
import { TableHeadCustom, TableMoreMenu } from 'src/components/table';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ServiceRequestWrapper, WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import ReferralViewModel from 'src/sections/careflow/tasks-activities/components/Referrals/ViewModel/ReferralViewModel';
import uuidv4 from 'src/utils/uuidv4';

type Props = {
  patient?: WrappedPatient | null;
  serviceRequest?: WrappedServiceRequest | null;
  referrals?: ReferralDto[];
  openCollapseExternal: boolean;
};

export default function ReferralHistory({ patient, serviceRequest, openCollapseExternal }: Props) {
  const [collapse, setCollapse] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});

  const [serviceRequestChild, { refresh: refreshServiceRequestChild}] = 
    useServiceRequests({
      filter:{ 'based-on':serviceRequest?.id }, autofetch: !!serviceRequest?.id,
      map: ServiceRequestWrapper,
    });

  useEffect(() => {
    setCollapse(openCollapseExternal)
  }, [openCollapseExternal]);

  const handleClose = () => {
    setOpenAdd(false);
    refreshServiceRequestChild();
  };

  return (
    <Card>
      <Stack sx={{ m: 2 }}>
        <Grid
          container
          display={'flex'}
          alignItems={'center'}
          paddingRight={2}
        >
          <Grid item xs={10}>
            <Typography> 
              Child Referrals
              <IconButton onClick={() => setCollapse(!collapse)}>
                <Iconify icon={ collapse ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'} />
              </IconButton> 
            </Typography>
          </Grid>
          <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
            <Button 
              size="small" 
              sx={{ height: '36px' }}
              onClick={() => setOpenAdd(true)}
            >
              Add A Child Referal
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <Collapse in={collapse}>
        <TableContainer>
          <Table size="small" sx={{ mb: 2 }}>
            <TableHeadCustom headLabel={TABLE_HEAD_CHILD_REFERRAL} />
            <TableBody>
              {!isEmpty(serviceRequestChild) ? (
                serviceRequestChild?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <ReferralHistoryItem 
                    key={uuidv4()} 
                    referral={row} 
                    patient={patient}
                    refreshData={refreshServiceRequestChild}
                  />
                ))
              ):(
                <TableCell colSpan={TABLE_HEAD_CHILD_REFERRAL?.length}>
                  <Typography variant="body2" align="center">No rows</Typography>
                </TableCell>
              )}
            </TableBody>
            </Table>
        </TableContainer>
        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={serviceRequestChild ? serviceRequestChild.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </Box>
      </Collapse>
      
      <AddReferral 
        openAdd={openAdd}
        patient={patient}
        serviceRequest={serviceRequest}
        onCancel={handleClose}
      />
    </Card>
  );
}

export type ReferralItemProps = {
  referral?: WrappedServiceRequest | null;
  patient?: WrappedPatient | null;
  refreshData: () => void;
};

const ReferralHistoryItem = ( { referral, patient, refreshData } : ReferralItemProps) => {

  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState<ReferralViewModel>();
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleClose = () => {
    setOpenEdit(false);
    refreshData();
    setEditData(undefined);
  };
  
  const handleEditData = (data: any) => {
    setEditData(data);
    setOpenEdit(true);
  };

  return(
    <>
      <TableRow>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {referral?.status && capitalCase(referral.status)}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {referral?.performer && referral.getPerfomerByType('Practitioner')?.display}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {referral?.occurrenceDateTime &&
          `${format(new Date(referral.occurrenceDateTime), 'MMM dd, yyyy')} (${getTimeSince(
            new Date(referral.occurrenceDateTime)
          )})`}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {referral?.code?.coding?.[0]?.code}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {referral?.requester && referral.requester.display}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {referral?.performer && referral.getPerfomerByType('Organization')?.display}
        </TableCell>
        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
          {referral?.locationReference && referral.locationReference?.[0]?.display}
        </TableCell>
        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={[
              <>
                <MenuItem onClick={() => handleEditData(referral)}>
                  <Iconify icon={'mdi:pencil'} />
                  Edit
                </MenuItem>
                <a
                  href={`${PATH_DASHBOARD.crs.childReferral}/${referral?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <MenuItem sx={{ color: 'black' }}>
                    <OpenInNewIcon sx={{ color: 'black' }}/>
                    <Typography>Go</Typography>
                  </MenuItem>
                </a>
              </>
            ]}
          />
        </TableCell>
      </TableRow>
      
      <AddReferral 
        openAdd={openEdit}
        referral={editData}
        patient={patient}
        onCancel={handleClose}
      />
    </>
  )
};
