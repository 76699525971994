import { Box, Typography, Card, Stack, CircularProgress } from '@mui/material';
import { HOST_API } from 'src/config';
import styled from '@emotion/styled';
import { useQuestionnaire, useQuestionnaireResponse } from 'src/@nicheaim/fhir-react';

interface AssessmentAnswersCardProps {
  assessmentId: string;
  actionBar?: React.ReactNode;
}

export default function AssessmentAnswersCard({
  assessmentId,
  actionBar,
}: AssessmentAnswersCardProps) {
  const [assessment] = useQuestionnaireResponse(assessmentId);

  const questionnaireId = assessment?.questionnaire?.split('/').at(-1) ?? '';
  const [questionnaire] = useQuestionnaire(questionnaireId, {
    autofetch: !!questionnaireId,
  });

  if (!questionnaire) {
    return (
      <Card sx={{ height: `calc(100vh - 200px)`, display: 'flex', flexDirection: 'column' }}>
        <TitleBar title="Loading" actionBar={actionBar} />

        <Stack direction="column" justifyContent="center" alignItems="center" gap={2} sx={{ p: 5 }}>
          <Typography>Loading Assessment Details</Typography>
          <CircularProgress />
        </Stack>
      </Card>
    );
  }

  const iframeUrl = new URL(
    `${HOST_API}/assessments/view/${questionnaire?.identifier?.[0].value}/${assessment?.identifier?.value}`
  ).toString();
  const title = questionnaire.title || '';

  return (
    <Card sx={{ height: `calc(100vh - 200px)`, display: 'flex', flexDirection: 'column' }}>
      <TitleBar title={title} actionBar={actionBar} />

      <Box flexGrow={1}>
        <Iframe src={iframeUrl} title="Assessment Answers" />
      </Box>
    </Card>
  );
}

interface TitleBarProps {
  title: string;
  actionBar?: React.ReactNode;
}

function TitleBar({ title, actionBar }: TitleBarProps) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
      <Typography variant="subtitle1">{title}</Typography>
      <Box>{actionBar}</Box>
    </Stack>
  );
}

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  margin-bottom: 10px;
`;
