import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Checkbox,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { fullPatientService } from 'src/ccm/full-patient/services';
import Iconify from 'src/components/Iconify';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import useTable from 'src/hooks/useTable';
import { PaginateQuery } from 'src/api/pagination/dtos';
import { PaginatedFullPatientDto } from 'src/ccm/full-patient/services/FullPatientService';
import { FullPatientDto } from 'src/ccm/dto/full-patient.dto';
import StatusChip from '../../common/StatusChip';

const NewPatients = () => {
  const { themeStretch } = useSettings();
  const [patients, setPatients] = useState<any>();

  const { page, order, orderBy, rowsPerPage, onChangePage, onChangeRowsPerPage, setPage } =
    useTable({
      defaultRowsPerPage: 1,
      defaultOrderBy: 'id',
      initialIndex: 1,
      defaultCurrentPage: 1,
    });

  const getPaginateQuery = () => {
    const paginateQuery: PaginateQuery = {
      page: page,
      limit: rowsPerPage,
      filter: { status: 'active' },
    };
    // if (search) {
    //   paginateQuery.search = search;
    // }
    // if (filterMrn) {
    //   paginateQuery.search = {
    //     [column: string]: string | string[]
    //   }
    // }
    return paginateQuery;
  };

  const getNewPatientsList = async () => {
    console.log('getting patients');
    const patients = await fullPatientService.getAll(getPaginateQuery());
    console.log({ patients });
    if (patients) {
      Array.isArray(patients) ? setPatients(patients) : setPatients([patients]);
    }
  };

  useEffect(() => {
    getNewPatientsList();
  }, []);

  const getAge = (date: string) => {
    const today = new Date();
    const dateOfBirth = new Date(date);
    let age = today.getFullYear() - dateOfBirth.getFullYear();
    const m = today.getMonth() - dateOfBirth.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < dateOfBirth.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <Page title="My Patients - New">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" alignItems="baseline">
              <Typography variant="h4" sx={{ mr: 1.5 }}>
                My Patients - New
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <Stack direction="row" sx={{ py: 2, px: 2 }}>
                <Grid item xs={6}>
                  <Stack justifyContent="flex-end">
                    <TextField
                      placeholder="Search..."
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify
                              icon={'eva:search-fill'}
                              sx={{ color: 'text.disabled', width: 20, height: 20 }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      count={5}
                      rowsPerPage={5}
                      page={1}
                      onPageChange={() => {}}
                      onRowsPerPageChange={() =>{}}
                      sx={{ borderTop: 0 }}
                    /> */}
                  </Stack>
                </Grid>
              </Stack>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox />
                      </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Plan</TableCell>
                      <TableCell>Program</TableCell>
                      <TableCell>Primary Phone</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Last Note</TableCell>
                      <TableCell>Last Activity Date</TableCell>
                      <TableCell>Next Activity</TableCell>
                      <TableCell>Assigned To</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {patients?.map((row: any) => (
                      <TableRow key={row.fhirRefUri}>
                        <TableCell>
                          <Checkbox />
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">{`${row.given} ${row.family}`}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">{row.fhirRefUri}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">CCM</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">{row.program}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">714-555-1244</Typography>
                        </TableCell>
                        <TableCell>
                          {row.status === 'new' && (
                            <Box>
                              <StatusChip label="New" color="success" size="small" />
                            </Box>
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">Left VM for callback</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {row.updatedAt
                              ? new Date(row.updatedAt).toLocaleDateString('en-US', {
                                  month: 'short',
                                  year: 'numeric',
                                  day: 'numeric',
                                })
                              : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">{row.nextActivity}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">Diana Hoang</Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton>
                            <Iconify icon="eva:more-vertical-fill" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default NewPatients;
