import { Box, Typography, Grid, LinearProgress } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import {
  EventAvailable as EventAvailableIcon,
  Person as PersonIcon,
  Cake as CakeIcon,
  ContactMail as ContactMailIcon,
  ContactPhone as ContactPhoneIcon,
} from '@mui/icons-material';
import moment from 'moment';
import { TaskResponse, TaskStatusValue } from 'src/@types/crs/task';
import { formatDate, getAge } from 'src/utils/dates';
import { getSeverityDueDateData } from '../helpers/getSeverityDueDateData';
import TaskStatusSeverity from './TaskStatusSeverity';
import { TaskStatus } from '../case/components/TasksGrid/TaskModal';

const getTaskListColumns = (taskStatuses: TaskStatus[]): GridColDef[] => [
  {
    field: 'fhirId',
    headerName: 'ID',
    headerAlign: 'center',
    flex: 0.6,
    headerClassName: 'taskGridHeader',
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    flex: 1,
    headerClassName: 'taskGridHeader',
    headerAlign: 'center',
    renderCell: (params) => {
      const { createdAt } = params.row as TaskResponse;
      const creationDate = moment(createdAt ?? null);
      const { formattedDueDate } = getSeverityDueDateData(creationDate);
      if (formattedDueDate)
        return (
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <EventAvailableIcon sx={{ marginRight: 1 }} fontSize={'small'} />
            <Typography noWrap={true}>
              {formattedDueDate === 'NA' ? '' : formattedDueDate}
            </Typography>
          </Box>
        );
    },
  },
  {
    field: 'patientFullName',
    headerName: 'Beneficiary',
    headerAlign: 'center',
    flex: 1,
    headerClassName: 'taskGridHeader',
    renderCell: (params) => {
      const { patientFullName, patientBirthDate } = params.row as TaskResponse;
      const birthDate = moment(patientBirthDate ?? null);
      let formattedBirthDate = formatDate(birthDate);
      if (!birthDate.isValid()) formattedBirthDate = '';
      return (
        <Grid container alignItems={'center'}>
          <Grid item xs={12} display={'flex'} flexDirection={'row'}>
            <PersonIcon sx={{ marginRight: 1 }} fontSize={'small'} />
            <Typography>{patientFullName ?? ''}</Typography>
          </Grid>
          <Grid item xs={12} display={'flex'} flexDirection={'row'} marginTop={0.4}>
            <CakeIcon sx={{ marginRight: 1 }} fontSize={'small'} />
            <Typography>
              {formattedBirthDate ? `${formattedBirthDate} (${getAge(birthDate)} yr)` : ''}
            </Typography>
          </Grid>
        </Grid>
      );
    },
  },
  {
    field: 'ownerName',
    headerName: 'Owner',
    headerAlign: 'center',
    flex: 1,
    headerClassName: 'taskGridHeader',
    renderCell: (params) => {
      const { ownerName, ownerEmail, ownerPhone, ownerType } = params.row as TaskResponse;
      return (
        <Box justifyContent={'center'}>
          <Box display={'flex'} flexDirection={'row'} marginTop={0.4}>
            <PersonIcon sx={{ marginRight: 1 }} fontSize={'small'} />
            <Typography>{ownerName ?? ''}</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'row'} marginTop={0.4}>
            <ContactMailIcon sx={{ marginRight: 1 }} fontSize={'small'} />
            <Typography>{ownerEmail ?? ''}</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'row'} marginTop={0.4}>
            <ContactPhoneIcon sx={{ marginRight: 1 }} fontSize={'small'} />
            <Typography>{ownerPhone ?? ''}</Typography>
          </Box>
          {/* <Box display={'flex'} flexDirection={'row'} marginTop={0.4}>
            <GroupIcon sx={{ marginRight: 1 }} fontSize={'small'} />
            <Typography>{ownerType ?? ''}</Typography>
          </Box> */}
        </Box>
      );
    },
  },
  {
    field: 'requesterName',
    headerName: 'Requester',
    headerAlign: 'center',
    flex: 1,
    headerClassName: 'taskGridHeader',
    renderCell: (params) => {
      const { requesterName, requesterEmail } = params.row as TaskResponse;
      return (
        <Box justifyContent={'center'}>
          <Box display={'flex'} flexDirection={'row'} marginTop={0.4}>
            <PersonIcon sx={{ marginRight: 1 }} fontSize={'small'} />
            <Typography>{requesterName ?? ''}</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'row'} marginTop={0.4}>
            <ContactMailIcon sx={{ marginRight: 1 }} fontSize={'small'} />
            <Typography>{requesterEmail ?? ''}</Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    headerAlign: 'center',
    flex: 0.8,
    align: 'center',
    headerClassName: 'taskGridHeader',
    renderCell: (params) => {
      const { status } = params.row as TaskResponse;
      return (
        <Box
          sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}
        >
          <TaskStatusSeverity
            sx={{ width: '45%', alignSelf: 'center' }}
            status={status as TaskStatusValue}
            taskStatuses={taskStatuses}
          />

          <LinearProgress
            sx={{ marginTop: 2 }}
            variant="determinate"
            color="info"
            value={status === TaskStatusValue.Completed ? 100 : 0}
          />
        </Box>
      );
    },
  },
];

export default getTaskListColumns;
