import { IApiClient } from 'src/api/clients/api_client';
import { PaginateQuery, paginateQueryToURLParams } from 'src/api/pagination/dtos';
import { CreateFullPatientDto } from 'src/ccm/dto/create-full-patient.dto';
import { FullPatientDto } from 'src/ccm/dto/full-patient.dto';

export interface PaginatedFullPatientResponse {
  // data: PatientResponse[]; //TODO
  data: any;
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface PaginatedFullPatientDto {
  data: FullPatientDto[];
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export class FullPatientApiClient {
  apiBase: string;

  fullPatientApiClient: IApiClient;

  constructor(fullPatientApiClient: IApiClient) {
    this.apiBase = 'ccm/patient';
    this.fullPatientApiClient = fullPatientApiClient;
  }

  async getAll(queryParams: PaginateQuery): Promise<PaginatedFullPatientResponse | undefined> {
    const urlParams = paginateQueryToURLParams(queryParams);
    const url = `${this.apiBase}/?${urlParams.toString()}&status=new`; //TODO
    try {
      const response = await this.fullPatientApiClient.get<
        PaginatedFullPatientResponse | undefined
      >(url);
      return response;
    } catch (error) {
      console.error(error);
    }
  }

  async create(payload: CreateFullPatientDto): Promise<PaginatedFullPatientResponse | undefined> {
    try {
      const response = await this.fullPatientApiClient.post<CreateFullPatientDto, any>(
        `${this.apiBase}/`,
        payload
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  }
}

export default class FullPatientService {
  fullPatientApiClient: FullPatientApiClient;

  constructor(fullPatientApiClient: FullPatientApiClient) {
    this.fullPatientApiClient = fullPatientApiClient;
  }

  async getAll(queryParams: PaginateQuery): Promise<PaginatedFullPatientDto | undefined> {
    const fullPatients = await this.fullPatientApiClient.getAll(queryParams);
    if (!fullPatients) return;
    return fullPatients;
  }

  async create(payload: CreateFullPatientDto): Promise<any> {
    const patient = await this.fullPatientApiClient.create(payload);
    if (!patient) return;
    return patient;
  }
}
