import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
} from '@mui/material';
import useTable, { getComparator } from '../../../hooks/useTable';
import { PatientManager } from 'src/@types/crs/patient';
import { PatientRow } from '.';
import { TableToolbar } from '../common';
import { TableHeadCustom } from 'src/components/table';
import { PatientLayout } from '../PatientLayout';
import { PaginatedPatientDto } from 'src/crs/patient/services/patientService';
import { patientService } from 'src/crs/patient/services';
import { PaginateQuery } from 'src/api/pagination/dtos';
import { PatientDto } from 'src/crs/dto/patient/patient.dto';
import { Link } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { ReferralManager } from 'src/@types/crs/referral';
import {
  AddNewPatient,
  checkAclValidation,
  getRelatedAcls,
} from 'src/utils/permissions/permission.utils';
import useAuth from 'src/hooks/useAuth';
import { TABLE_HEAD_PATIENT_LIST } from '../common/table-head';
import NoResultsTableRow from '../common/NoResultsTableRow';
import LoadingTableRow from '../common/LoadingTableRow';
import { debounce, wait } from 'src/utils/timers';

export function PatientList() {
  const user = useAuth();
  const { page, order, orderBy, rowsPerPage, onChangePage, onChangeRowsPerPage, setPage } =
    useTable({
      defaultRowsPerPage: 5,
      defaultOrderBy: 'id',
      initialIndex: 1,
      defaultCurrentPage: 1,
    });

  const [filterMrn, setFilterMrn] = useState('');
  const [tableData, setTableData] = useState<PaginatedPatientDto | undefined | null>(null);
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  const getPaginateQuery = (page: number, rowsPerPage: number, search: string) => {
    const paginateQuery: PaginateQuery = { page: page, limit: rowsPerPage };
    if (search) {
      paginateQuery.search = search;
    }
    return paginateQuery;
  };

  const fetchPatientList = async (page: number, rowsPerPage: number, search: string) => {
    setIsLoading(true);
    await wait();
    const patients = await patientService.getAll(getPaginateQuery(page, rowsPerPage, search));
    setIsLoading(false);
    if (patients) {
      setTableData(patients);
    }
  };

  const getPatientList = useCallback(debounce(fetchPatientList, 600), []);

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(event, newPage + 1);
  };

  const handleSearch = (query: string) => {
    setSearch(query);
    setPage(1);
  };

  useEffect(() => {
    getPatientList(page, rowsPerPage, search);
  }, [page, rowsPerPage, filterMrn, search]);

  // const filteredPatients = applySortFilter({
  //   patientList,
  //   comparator: getComparator(order, orderBy),
  //   filterMrn,
  // });

  const patientDtoToPatientManager = (ref: PatientDto): PatientManager => {
    const row = {
      id: ref.indexedFhirRefUri?.slice(
        ref.indexedFhirRefUri?.lastIndexOf('/') + 1,
        ref.indexedFhirRefUri?.length
      ),
      mrn: ref.indexedMrn,
      referral: ref.indexedFullName,
      phone: ref.indexedPhone,
      email: ref.indexedEmail,
      birthDate: ref.indexedDob,
      gender: ref.indexedGender,
      demographics: ref.indexedEthnicity,
      race: ref.indexedRace,
      registrationDate: ref.indexedCreatedAt,
      formattedBirthDate: ref.indexedDOBFormatted,
      age: ref.indexedAge,
      registrationDateFormatted: ref.indexedCreatedAtFormatted,
    };
    return row;
  };

  const dataFormatted = (): PatientManager[] => {
    const data = tableData?.data ? tableData.data.map((r) => patientDtoToPatientManager(r)) : [];
    return data;
  };

  const rows = dataFormatted();

  return (
    <PatientLayout title="Patient List">
      <>
        {checkAclValidation({ user, acl: getRelatedAcls(AddNewPatient) }) && (
          <Grid item xs={12}>
            <Stack alignItems="flex-end">
              <Link to={PATH_DASHBOARD.crs.patient.new.value}>
                <Button type="submit" variant="contained" color="info">
                  Add Patient
                </Button>
              </Link>
            </Stack>
          </Grid>
        )}
        <Grid item xs={12}>
          <Card>
            <Stack direction="row" sx={{ py: 2, px: 2 }}>
              <Grid xs={6}>
                <TableToolbar filterMrn={search} onFilterMrn={handleSearch} />
              </Grid>
              <Grid xs={6}>
                <Stack justifyContent="flex-end">
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    count={tableData ? tableData.meta.totalItems : 0}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={onChangeRowsPerPage}
                    sx={{ borderTop: 0 }}
                  />
                </Stack>
              </Grid>
            </Stack>
            <TableContainer>
              <Table>
                <TableHeadCustom headLabel={TABLE_HEAD_PATIENT_LIST} />
                <TableBody>
                  {!isLoading ? (
                    rows?.length ? (
                      rows.map((row) => <PatientRow key={row.id} row={row} />)
                    ) : (
                      <NoResultsTableRow
                        colSpan={TABLE_HEAD_PATIENT_LIST.length}
                        text="No Patient Found."
                      />
                    )
                  ) : (
                    <LoadingTableRow colSpan={TABLE_HEAD_PATIENT_LIST.length} />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </>
    </PatientLayout>
  );
}

function applySortFilter({
  patientList,
  comparator,
  filterMrn,
}: {
  patientList: ReferralManager[];
  comparator: (a: any, b: any) => number;
  filterMrn: string;
}) {
  const stabilizedThis = patientList.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  patientList = stabilizedThis.map((el) => el[0]);

  if (filterMrn) {
    patientList = patientList.filter(
      (item: Record<string, any>) => item.mrn.toLowerCase().indexOf(filterMrn.toLowerCase()) !== -1
    );
  }

  return patientList;
}
