import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import produce from 'immer';
import {
  useDocumentReference,
  useOrganizations,
  usePractitioners,
  useValueSet,
} from 'src/@nicheaim/fhir-react';
import { useSnackbar } from 'notistack';
import { MenuItem } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { MobileDatePicker } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { convertValueToValueSet } from 'src/sections/crs/common/common-utils';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { OrganizationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Organization';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { WrappedDocumentReference } from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';

type FormValue = {
  name: string;
  category: string[];
  author: string[];
  custodian: string;
  type: string;
  date: string;
};

type Props = {
  document: WrappedDocumentReference | null;
  open: boolean;
  onClose: VoidFunction;
};

export default function DocumentEditForm({ document, open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const [organizations] = useOrganizations({ map: OrganizationWrapper });

  const [practitioner] = usePractitioners({ map: PractitionerWrapper });

  const [documentType] = useValueSet('crs-document-type-codes', { map: ValueSetWrapper });

  const [documentCategory] = useValueSet('crs-document-category-codes', { map: ValueSetWrapper });

  const [, { update }] = useDocumentReference(document?.id!);

  const defaultValues = useMemo(
    () =>
      ({
        name: document?.content?.[0].attachment.title,
        category: document?.category?.map((e) => (e.text ? e.text : e.coding?.[0].display)),
        author: document?.author?.map((e) => e.display),
        custodian: document?.custodian?.display,
        type: document?.type?.coding?.[0].display,
        date: document?.date,
      } as FormValue),
    [document]
  );

  const methods = useForm({ defaultValues });

  const { reset, control, handleSubmit } = methods;

  const onSubmit = async (data: FormValue) => {
    try {
      const newAuthor = data.author?.map((e) => {
        let author: any = practitioner
          ?.filter((r) => r.getFullName() === e)
          .map((r) => ({
            display: r.getFullName(),
            reference: `Practitioner/${r.id}`,
          }))[0];

        if (!author) {
          author = { display: e };
        }

        return author;
      });

      const codeValueCategory = data.category?.map((e) => {
        let valueSet = convertValueToValueSet(e, documentCategory);

        if (!valueSet) {
          valueSet = { code: e, display: e };
        }

        return {
          coding: [{ ...valueSet }],
          text: e,
        };
      }) || [];

      const newCustodian = organizations
        ?.filter((e) => e.name === data.custodian)
        .map((t) => {
          const reference = `Organization/${t.id}`;
          const display = t.name;
          return { reference, display };
        });

      const codeValueType = convertValueToValueSet(data.type, documentType);

      await update(
        produce(document!, (draft) => {
          draft.content[0].attachment.title = data.name;
          draft.category = [...codeValueCategory];
          draft.author = newAuthor;
          draft.custodian = newCustodian[0];
          draft.type = { coding: [{ ...codeValueType }] };
          draft.date = data.date;
        })
      );

      enqueueSnackbar('Document was updated.');
      onClose();
    } catch (e) {
      enqueueSnackbar('Document was not updated.', { variant: 'error' });
      onClose();
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [document]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm">
      <DialogTitle>Documents Details</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <Typography variant="body2">Name</Typography>
                <RHFTextField name="name" label="" />

                <Typography variant="body2">Category</Typography>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      freeSolo
                      onChange={(event, newValue) => field.onChange(newValue)}
                      options={documentCategory?.asList().map((option) => option.display) || []}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => <TextField label="" {...params} />}
                    />
                  )}
                />

                <Typography variant="body2">Author</Typography>
                <Controller
                  name="author"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      freeSolo
                      onChange={(event, newValue) => field.onChange(newValue)}
                      options={practitioner?.map((option) => option?.getFullName()) || []}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => <TextField label="" {...params} />}
                    />
                  )}
                />

                <Typography variant="body2">Custodian</Typography>
                <RHFSelect name="custodian" label="">
                  {organizations?.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </RHFSelect>

                <Typography variant="body2">Type</Typography>
                <RHFSelect name="type" label="">
                  {documentType?.asList().map((option) => (
                    <MenuItem key={option.code} value={option.display}>
                      {option.display}
                    </MenuItem>
                  ))}
                </RHFSelect>

                <Typography variant="body2">Date</Typography>
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <MobileDatePicker
                      {...field}
                      label=""
                      inputFormat="MMM dd, yyyy hh:mm"
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />

              <Button variant="contained" color="info" onClick={handleClose}>
                Cancel
              </Button>

              <Button variant="contained" color="info" type="submit">
                Submit
              </Button>
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
}
