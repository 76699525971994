import {
  Grid,
  TextField,
  FormControl,
  Autocomplete,
  Card,
  Typography,
} from "@mui/material";
import { useMemo } from "react";
import { useLocations } from "src/@nicheaim/fhir-react";

type FiltersPropsType = {
  onChangeSearch: (search: string | null) => void;
  onChangeLocation: (locationFhirId: string | null) => void;
};

export const Filters = ({
  onChangeSearch,
  onChangeLocation,
}: FiltersPropsType) => {
  const [locations] = useLocations({
    filter: {
      _sort: 'name'
    }
  });
  const locationOptions = useMemo(() => {
    const newLocations: { label: string | undefined; value: string | null | undefined; }[] = locations?.map(i => ({
      label: i.name,
      value: i.id,
    })) ?? [];
    newLocations.unshift({
      label: 'All',
      value: null,
    })
    return newLocations;
  }, [locations])
  return (
    <Grid item xl={12}>
      <Card>
        <Grid direction="row" container>
          <Grid xs={12} sm={6} xl={3} item direction="row" sx={{ padding: 2 }}>
            <Typography sx={{ paddingBottom: '12px', paddingRight: 2 }} variant="h5">Search</Typography>
            <FormControl fullWidth style={{ paddingRight: '5px' }}>
              <TextField
                placeholder="Search by bed identifier"
                onChange={(e) => onChangeSearch(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} xl={4}  item direction="row" sx={{ padding: 2 }}>
            <Typography sx={{ paddingBottom: '12px', paddingRight: 2 }} variant="h5">Location</Typography>
            <FormControl fullWidth style={{ paddingRight: '5px' }}>
              <Autocomplete
                options={locationOptions}
                disableClearable
                onChange={(_: any, value: any | null) =>
                  onChangeLocation(value.value)
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select location" />
                )}
                style={{
                  width: 300
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}

