import { Navigate, Route, Routes, useParams } from 'react-router';
import GoalsToGoalGroupsCreateForm from './GoalsToGoalGroupsCreateForm';
import GoalsToGoalGroupsEditForm from './GoalsToGoalGroupsEditForm';
import GoalToGoalGroupsTable from './GoalToGoalGroupsTable';
import GoalsToGoalGroupsToolbar from './GoalsToGoalGroupsToolbar';
import api from 'src/services/api';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

function GoalsToGoalGroupsTab() {
  return (
    <Routes>
      <Route index element={<GoalsToGoalGroupsList />} />
      <Route path="new" element={<GoalsToGoalGroupsCreateForm />} />
      <Route path="edit/:id" element={<EditForm />} />
    </Routes>
  );
}

function GoalsToGoalGroupsList() {
  return (
    <>
      <GoalsToGoalGroupsToolbar />
      <GoalToGoalGroupsTable />
    </>
  );
}

function EditForm() {
  const params = useParams();
  const {
    data: mapping,
    isLoading,
    refetch,
  } = useQuery(['mappings-goalgroups-to-goals', params.id], async () => {
    if (!params.id) {
      return null;
    }

    return api.carePlan.getMappingsGoalGroupGoal(params.id);
  });

  useEffect(() => {
    refetch();
  }, []);

  if (!mapping && !isLoading) return <Navigate to=".." />;
  return <GoalsToGoalGroupsEditForm currentMapping={mapping} />;
}

export default Object.assign(GoalsToGoalGroupsTab, {
  path: 'map-gg-g',
});
