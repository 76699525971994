// @ts-nocheck
// please remove // @ts-nocheck comments to work with these file
// and fix the issues
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  Divider,
  Box,
  Grid,
  Typography,
  FormControl,
  TextField,
  Alert,
  FormLabel,
  Autocomplete,
} from '@mui/material';
import Bed from 'src/sections/careflow/rounding/Model/Bed';
import { PatientCard } from 'src/sections/careflow/common';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { CensusContext } from '../../CensusContext';
import BedAssignment from 'src/sections/careflow/rounding/Model/BedAssignment';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import { useCensusEmptyBeds, useCensusTransferBed } from 'src/services/api/census/hooks';

export type TransferBedPropsType = {
  onSaveCallback: (bedId: number, bedAssignment: BedAssignment | null) => void;
  onCancelPress: () => void;
  bed: Bed;
};

const TransferBed = ({ onSaveCallback, onCancelPress, bed }: TransferBedPropsType) => {
  const [bedItem, setBedItem] = useState<{ label: string; value: number }>({
    label: bed?.identifier,
    value: bed?.id,
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const censusContext = useContext(CensusContext);

  const { mutateAsync: transferBed } = useCensusTransferBed();

  const { data: bedsResponse, isLoading: isLoadingBeds, refetch } = useCensusEmptyBeds({
    pagination: {
      take: 2000,
      skip: 0,
    },
    locationFhirId: bed.locationFhirId,
  });

  const bedOptions = useMemo(() => !isLoadingBeds ? bedsResponse?.filter(i => !i.currentBedAssignment)?.map((bed: Bed) => ({
    label: bed.identifier,
    value: bed.id,
  })) : [], [bedsResponse, isLoadingBeds]);

  const validateForm = () => {
    if (!bedItem || !bed.currentBedAssignment) {
      return false;
    }

    return true;
  };

  const onSavePress = async () => {
    setIsLoading(true);
    if (!validateForm()) {
      setIsLoading(false);
      setErrorMessage('Please fill all required fields and try again.');
      return;
    }

    if (bed?.currentBedAssignment && bedItem) {
        transferBed({
          bedAssignmentId: bed?.currentBedAssignment.id,
          bedId: bedItem.value
        })
        .then((newBedAssignment) => {
          if (newBedAssignment) {
            setErrorMessage(null);
            setSuccessMessage('Patient updated');
            setIsLoading(false);
            setTimeout(() => {
              onSaveCallback(bedItem.value, newBedAssignment);
              onCancelPress();
            }, 800);
          }
        })
        .catch(() => {
          setSuccessMessage(null);
          setErrorMessage('Error updating the patient. Please try again');
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    refetch()
  }, [bed.locationFhirId])

  return (
    <form action="">
      <Box>
        <Box sx={{ my: 3 }}>
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Transfer Bed
            </Typography>
          </Grid>
        </Box>
        <Divider />
        <Grid item style={{ marginTop: '20px' }}>
          <FormControl fullWidth>
            <FormLabel sx={{ marginBottom: 2 }}>Current assigned patient</FormLabel>
            <PatientCard
              showCloseButton={false}
              id={getFhirIdFromEntity(bed?.currentBedAssignment?.patient?.fhirRefUri)}
            />
          </FormControl>
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              options={bedOptions}
              value={bedItem}
              onChange={(_: any, value: any | null) => {
                setBedItem(value)
              }}
              renderInput={(params) => (
                <TextField variant="outlined" {...params} label="Bed" required />
              )}
              noOptionsText="No beds available"
            />
          </FormControl>
        </Grid>

        {errorMessage && (
          <Alert style={{ marginTop: '20px' }} severity="error">
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert style={{ marginTop: '20px' }} severity="success">
            {successMessage}
          </Alert>
        )}
        <ActionButtons
          isLoading={isLoading}
          leftButtonTitle="Cancel"
          onLeftButtonPress={censusContext.closeTransferBedModal}
          onRightButtonPress={onSavePress}
        />
      </Box>
    </form>
  );
};

export default TransferBed;
