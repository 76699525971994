import { Link } from '@mui/material';

const LinkComponent = (props: any) => {
  const {
    metasResponse
  } = props?.others;

  const { message, typeResponse } = metasResponse;
  
  return (
    <Link 
      target='_blank' 
      href={typeResponse.customResponse} 
      sx={{ color: 'black', fontSize: 12 }}
    >
      {message}
    </Link>
  );
};

export default LinkComponent;