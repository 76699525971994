import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router';
import api from 'src/services/api';
import ConditionsForm from './ConditionsForm';
import ConditionsTable from './ConditionsTable';
import ConditionsToolbar from './ConditionsToolbar';

function ConditionsTab() {
  return (
    <Routes>
      <Route index element={<ConditionsList />} />
      <Route path="new" element={<ConditionsForm />} />
      <Route path="edit/:id" element={<ConditionsEditForm />} />
    </Routes>
  );
}

function ConditionsList() {
  return (
    <>
      <ConditionsToolbar />
      <ConditionsTable />
    </>
  );
}

function ConditionsEditForm() {
  const params = useParams();
  const {
    data: condition,
    isLoading,
    refetch,
  } = useQuery(['conditions', params.id], async () => {
    if (!params.id) {
      return null;
    }

    return api.carePlan.getCondition(params.id);
  });

  useEffect(() => {
    refetch();
  }, []);

  if (!condition && !isLoading) return <Navigate to=".." />;
  return <ConditionsForm isEdit currentCondition={condition} />;
}

export default Object.assign(ConditionsTab, {
  path: 'conditions',
});
