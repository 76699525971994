import { DatePicker } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import produce from 'immer';
import { useState } from 'react';
import {
  useCareTeams,
  useEncounters,
  usePractitionerRoles,
  usePractitioners,
} from 'src/@nicheaim/fhir-react';
import Iconify from 'src/components/Iconify';
import {
  Address,
  CareTeamParticipant,
  Coding,
  ContactPoint,
  Encounter,
  Practitioner,
  PractitionerRole,
  Reference,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import { useStandardLoadingBackdrop } from '../../common/StandardLoadingBackdropProvider';
import AddressField from '../../health-record/patient-info-header/components/personal-details/AddressField';
import EmailField from '../../health-record/patient-info-header/components/personal-details/EmailField';
import PhoneField from '../../health-record/patient-info-header/components/personal-details/PhoneField';
import { normalizeAddress } from '../../health-record/patient-info-header/components/utils';

const specialties = [
  {
    system: 'http://snomed.info/sct',
    code: '408443003',
    display: 'General medical practice',
  },
  { display: 'Cardiology' },
  { display: 'Dermatology' },
  { display: 'Endocrinology' },
  { display: 'Family Medicine' },
  { display: 'Gastroenterology' },
  { display: 'Geriatric Medicine' },
  { display: 'Hematology/Oncology' },
  { display: 'Immunology & Allergy' },
  { display: 'Infectious Diseases' },
  { display: 'Internal Medicine' },
  { display: 'Medical Oncology' },
  { display: 'Neonatology' },
  { display: 'Nephrology' },
  { display: 'Neurology' },
  { display: 'Obstetrics-gynecology' },
  { display: 'Opthalmology' },
  { display: 'Palliative Medicine' },
  { display: 'Pediatrics' },
  { display: 'Psychiatry' },
  { display: 'Pulmonology' },
  { display: 'Rheumatology' },
  { display: 'Urology' },
];

interface ProvidersFormProps {
  data?: {
    encounter: Encounter;
    practitioner: Practitioner;
    provider: any;
    role: PractitionerRole[];
  };
  onSaveCallback: (practitionerReference?: Reference) => void;
  closeForm: () => void;
}

const ProvidersForm = ({ data, onSaveCallback = () => {} , closeForm }: ProvidersFormProps) => {
  const isEdition = data;
  const patient = useCurrentPatient();
  const [careTeams, { create: createCareTeam, update: updateCareTeam }] = useCareTeams({
    filter: {
      patient: patient?.id,
    },
  });
  const [, { create: createPractitioner, update: updatePractitioner }] = usePractitioners();
  const [, { create: createPractitionerRole, update: updatePractitionerRole }] =
    usePractitionerRoles();
  const [, { create: createEncounter, update: updateEncounter }] = useEncounters();
  const loadingBackdrop = useStandardLoadingBackdrop();

  const isPhone = (telecom: ContactPoint) => telecom.system === 'phone';
  const isEmail = (telecom: ContactPoint) => telecom.system === 'email';

  const initPrefix = data?.practitioner?.name?.[0]?.prefix?.[0];
  const initSuffix = data?.practitioner?.name?.[0]?.suffix?.[0];
  const initFirstName = data?.practitioner?.name?.[0]?.given?.[0];
  const initMiddleName = data?.practitioner?.name?.[0]?.given?.[1];
  const initLastName = data?.practitioner?.name?.[0]?.family;
  const initSpecialty = data?.role?.[0]?.specialty?.[0].coding?.[0]?.display;
  const initPhone = data?.practitioner?.telecom?.filter(isPhone)?.[0];
  const initEmail = data?.practitioner?.telecom?.filter(isEmail)?.[0];
  const initAddress = data?.practitioner?.address?.[0];
  const initLastVisit = data?.encounter?.period?.start
    ? new Date(data?.encounter?.period?.start)
    : null;
  const initIsPrimary = data?.provider?.role?.find((r) => r.text === 'PCP') ? true : false;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [prefix, setPrefix] = useState(initPrefix || '');
  const [suffix, setSuffix] = useState(initSuffix || '');
  const [firstName, setFirstName] = useState(initFirstName ?? '');
  const [middleName, setMiddleName] = useState(initMiddleName || '');
  const [lastName, setLastName] = useState(initLastName || '');
  const [specialty, setSpecialty] = useState(initSpecialty || '');
  const [phone, setPhone] = useState<ContactPoint>(
    initPhone || { system: 'phone', use: 'mobile', value: '' }
  );
  const [email, setEmail] = useState<ContactPoint>(
    initEmail || { system: 'email', use: 'home', value: '' }
  );
  const [address, setAddress] = useState<Address>(
    initAddress || {
      use: undefined,
      line: [],
      city: '',
      state: '',
      postalCode: '',
    }
  );
  const [lastVisit, setLastVisit] = useState<Date | null>(initLastVisit ?? null);
  const [isPrimary, setIsPrimary] = useState(initIsPrimary ?? false);

  const createDisplayName = () =>
    [prefix, [firstName, middleName && `${middleName?.[0]}.`]?.filter((n) => n), lastName, suffix]
      .filter((n) => n)
      .flat()
      .join(' ');

  const handleChangePhoneValue = (value: string) => {
    setPhone({ system: 'phone', use: phone.use, value });
  };

  const handleChangeEmailValue = (value: string) => {
    setEmail({ system: 'email', use: email.use, value });
  };

  const clearAll = () => {
    setPrefix('');
    setSuffix('');
    setFirstName('');
    setMiddleName('');
    setLastName('');
    setSpecialty('');
    setPhone({ system: 'phone', use: 'home', value: '' });
    setEmail({ system: 'email', use: 'home', value: '' });
    setAddress({
      use: undefined,
      line: [],
      city: '',
      state: '',
      postalCode: '',
    });
    setLastVisit(null);
  };

  const isValidForm = () => {
    if (firstName !== '' && lastName !== '' && specialty !== '') {
      return true;
    }

    return false;
  };

  const createProvider = async () => {
    const names = [firstName];
    if (middleName) {
      names.push(middleName);
    }
    const savedPractitioner = await createPractitioner({
      resourceType: 'Practitioner',
      active: true,
      name: [
        {
          family: lastName,
          given: names,
          suffix: [suffix],
          prefix: [prefix],
        },
      ],
      telecom: [phone, email],
      address: [address],
    });

    await createPractitionerRole({
      resourceType: 'PractitionerRole',
      active: true,
      practitioner: {
        reference: `Practitioner/${savedPractitioner?.[0]?.id}`,
        display: createDisplayName(),
      },
      specialty: [
        {
          coding: [specialties.find((s: Coding) => s.display === specialty)!],
        },
      ],
    });

    await createEncounter({
      resourceType: 'Encounter',
      status: 'finished',
      class: {
        system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
        code: 'AMB',
        display: 'ambulatory',
      },
      type: [
        {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '11429006',
              display: 'Consultation',
            },
          ],
        },
      ],
      subject: {
        reference: `Patient/${patient?.id}`,
      },
      participant: [
        {
          individual: {
            reference: `Practitioner/${savedPractitioner?.[0]?.id}`,
            display: createDisplayName(),
          },
        },
      ],
      period: {
        start: lastVisit?.toISOString(),
      },
    });

    let providerRole = [
      ...(data?.provider.role.filter((r) => r.text !== 'PCP') ?? [{ text: 'provider' }]),
    ];

    if (isPrimary) {
      providerRole.push({
        text: 'PCP',
      });
    }

    const newCareTeamParticipant: CareTeamParticipant = {
      member: {
        reference: `Practitioner/${savedPractitioner?.[0]?.id}`,
        display: createDisplayName(),
      },
      role: providerRole,
    };

    if (careTeams.length > 0) {
      updateCareTeam({
        ...careTeams[0],
        participant: [
          ...((isPrimary
            ? careTeams?.[0]?.participant?.map((p) => {
                if (p?.role?.[0]?.text === 'PCP')
                  return { ...p, role: p.role?.filter((r) => r.text !== 'PCP') };
                return p;
              })
            : careTeams?.[0]?.participant) || []),
          newCareTeamParticipant,
        ],
      })
        .then(() => {
          setSuccess('Provider created.');
          onSaveCallback(newCareTeamParticipant)
          setTimeout(() => {
            finishProcess();
          }, 800);
        })
        .catch(() => {
          setError('Error creating provider. Please try again');
          setTimeout(() => {
            finishProcess();
          }, 800);
        });
    } else {
      createCareTeam({
        resourceType: 'CareTeam',
        status: 'active',
        subject: {
          reference: `Patient/${patient?.id}`,
        },
        participant: [
          newCareTeamParticipant,
        ],
      })
        .then(() => {
          setSuccess('Provider updated.');
          onSaveCallback(newCareTeamParticipant)
          setTimeout(() => {
            finishProcess();
          }, 800);
        })
        .catch(() => {
          setError('Error updating provider. Please try again');
          setTimeout(() => {
            finishProcess();
          }, 800);
        });
    }
  };

  const updateProvider = async () => {
    const newPractitioner = produce(data!.practitioner, (draft) => {
      draft.name = [
        {
          family: lastName,
          given: [firstName, middleName].filter((n) => n),
          suffix: [suffix],
          prefix: [prefix],
        },
      ];
      draft.telecom = [phone, email];
      draft.address = [normalizeAddress(address)];
    });
    Promise.all([
      updatePractitioner(newPractitioner),
      updatePractitionerRole(
        produce(data!.role[0], (draft) => {
          draft.practitioner = {
            reference: `Practitioner/${data!.practitioner.id}`,
            display: createDisplayName(),
          };
          draft.specialty = [
            {
              coding: [specialties.find((s: Coding) => s.display === specialty)!],
            },
          ];
        })
      ),
      updateEncounter(
        produce(data!.encounter, (draft) => {
          draft.period = {
            start: lastVisit?.toISOString(),
          };
        })
      ),
      updateCareTeam(
        produce(careTeams[0], (draft) => {
          draft.participant = draft?.participant?.map((p) => {
            if (p?.member?.reference === `Practitioner/${data?.practitioner?.id}`) {
              let newRole = [...(p?.role?.filter((r) => r.text !== 'PCP') ?? [])];

              if (isPrimary) {
                newRole.push({
                  text: 'PCP',
                });
              }
              return {
                ...p,
                role: newRole,
              };
            }
            return {
              ...p,
              role: p?.role?.filter((r) => r.text !== 'PCP'),
            };
          });
        })
      ),
    ]).then((res) => {
      onSaveCallback({ reference: `Practitioner/${data?.practitioner?.id}` })
      setSuccess('Provider updated.');
      setTimeout(() => {
        finishProcess();
      }, 800);
    });
  };

  const finishProcess = () => {
    setIsLoading(false);
    clearAll();
    closeForm();
    loadingBackdrop.close();
  };

  const handleSave = async () => {
    if (!isValidForm()) {
      setIsLoading(false);
      setError('Please fill required fields and try again.');
      return;
    }

    loadingBackdrop.open();
    setError(null);
    setSuccess(null);
    setIsLoading(true);
    isEdition ? await updateProvider() : await createProvider();
  };

  return (
    <Stack px={2}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="subtitle1">Add Provider</Typography>
        <IconButton onClick={closeForm}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>
      <Stack spacing={2} alignItems="center" direction="row" justifyContent="space-between">
        <TextField
          fullWidth
          label="Prefix"
          margin="dense"
          size="small"
          variant="standard"
          onChange={(ev) => setPrefix(ev.target.value)}
          value={prefix}
        />
        <TextField
          fullWidth
          select
          label="Suffix"
          margin="dense"
          size="small"
          variant="standard"
          onChange={(ev) => setSuffix(ev.target.value)}
          value={suffix}
        >
          <MenuItem value="M.D.">M.D.</MenuItem>
        </TextField>
      </Stack>
      <TextField
        fullWidth
        label="First Name"
        margin="dense"
        size="small"
        variant="standard"
        required
        onChange={(ev) => setFirstName(ev.target.value)}
        value={firstName}
      />
      <TextField
        fullWidth
        label="Middle Name"
        margin="dense"
        size="small"
        variant="standard"
        onChange={(ev) => setMiddleName(ev.target.value)}
        value={middleName}
      />
      <TextField
        fullWidth
        label="Last Name"
        margin="dense"
        size="small"
        variant="standard"
        required
        onChange={(ev) => setLastName(ev.target.value)}
        value={lastName}
      />
      <TextField
        fullWidth
        select
        label="Specialty"
        margin="dense"
        size="small"
        variant="standard"
        required
        onChange={(ev) => setSpecialty(ev.target.value)}
        value={specialty}
      >
        {specialties.map((s: Coding, index: number) => (
          <MenuItem key={`specialty-${index}`} value={s.display}>
            {s.display}
          </MenuItem>
        ))}
      </TextField>
      <Box pt={2}>
        <PhoneField
          fullWidth
          onChangeValue={(value) => handleChangePhoneValue(value)}
          type={phone.use!}
          value={phone.value!}
        />
      </Box>
      <Box pt={3}>
        <EmailField
          fullWidth
          onChangeValue={(value) => handleChangeEmailValue(value)}
          type={email.use!}
          value={email.value!}
        />
      </Box>
      <AddressField value={address} onChange={setAddress} />
      <DatePicker
        label="Last Visit"
        value={lastVisit}
        onChange={setLastVisit}
        renderInput={(params) => (
          <TextField fullWidth margin="dense" size="small" variant="standard" {...params} />
        )}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox checked={isPrimary} onChange={(ev) => setIsPrimary(ev.target.checked)} />
          }
          label="Is PCP?"
        />
      </FormGroup>
      <Grid>
        {error && (
          <Alert style={{ marginTop: '20px' }} severity="error">
            {error}
          </Alert>
        )}
        {success && (
          <Alert style={{ marginTop: '20px' }} severity="success">
            {success}
          </Alert>
        )}
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid item xs={6} style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <Button
              disabled={isLoading}
              onClick={clearAll}
              size="large"
              fullWidth
              variant="outlined"
            >
              Clear all
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={6} style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <Button
              disabled={isLoading}
              onClick={handleSave}
              size="large"
              style={{ width: '100%', color: 'white' }}
              variant="contained"
            >
              {isLoading ? <CircularProgress size={20} /> : 'Save'}
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default ProvidersForm;
