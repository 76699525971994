import * as React from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  Dialog,
  DialogTitle,
  Fade,
  ListItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridRenderCellParams,
  GridRowModel,
  GridValueGetterParams,
  GridActionsCellItem,
  GridRowModes,
  GridColumns,
  GridRowModesModel,
  GridRowId,
  GridEventListener,
  GridRowParams,
  MuiEvent,
} from '@mui/x-data-grid';
import SaveIcon from '@mui/icons-material/Save';
import Add from '@mui/icons-material/Add';
import {
  Address,
  Patient,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { GridValueSetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { PatientDetails } from '../../PatientDetails';
import { usePatient } from 'src/@nicheaim/fhir-react';
import produce from 'immer';
import CancelIcon from '@mui/icons-material/Close';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import AddAddress from 'src/sections/crs/referral/components/workflow-step/AddressChecklistItem/AddAddress';
import moment from 'moment';
import { useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import {
  AddAddressDetails,
  checkAclValidation,
  DeleteAddressDetails,
  EditAddressDetails,
  getRelatedAcls,
} from 'src/utils/permissions/permission.utils';
import useAuth from 'src/hooks/useAuth';
import { DatePicker } from '@mui/lab';
import { isNil } from 'lodash';

const pageSize = 3;

const stylemodal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '16px',
  boxShadow: '0 0 2px 0 rgb(145 158 171 / 20%), 0 12px 24px -4px rgb(145 158 171 / 12%)',
  p: 4,
};

const JsOptions = {
  id: 'google-map-script',
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  libraries: ['places'],
} as any;

export function NewAddressDetails({ patient, onRefresh }: PatientDetails) {
  const user = useAuth();
  const [disabledadd, setDisabledAdd] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const patientaddress: Patient = patient as Patient;
  const [idDelete, setIdDelete] = React.useState('');
  const [rowDelete, setrowDelete] = React.useState<any>([]);
  const [editRow, setEditRow] = React.useState(null);
  const [, { update }] = usePatient(patient.id!, { map: PatientWrapper });
  const [disablePeriod, setDisablePeriod] = React.useState(true);
  const [rows, setRows] = React.useState<any>([]);
  const [openAddAddress, setOpenAddAddress] = React.useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [error, setError] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { isLoaded } = useJsApiLoader(JsOptions);

  React.useEffect(() => {
    setRows([]);
    if (patient?.address !== undefined) {
      const getPatientAddress: Address[] = JSON.parse(JSON.stringify(patient?.address));
      getPatientAddress?.map((ident: any, index: any) => {
        ident.id = index;
      });
      setRows(getPatientAddress);
    }
  }, [patient]);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleSaveClicks = (id: GridRowId) => () => {
    setDisabledAdd(false);
    setDisablePeriod(true);
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows?.find((row: any) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows?.filter((row) => row.id !== id));
    }
    setDisabledAdd(false);
  };

  const validateDateFields = (start: any, end: any) => {
    let isValid = true;
    const startDate = moment(start ?? null);
    const endDate = moment(end ?? null);
    if (startDate.isValid() && endDate.isValid()) {
      if (startDate.isAfter(endDate)) {
        setError(true);
        isValid = false;
      }
    }
    return isValid;
  };

  const saveChanges = async (data: Address) => {
    if (!validateDateFields(startDate, endDate)) return;

    const period = {
      ...(!isNil(startDate) && { start: new Date(startDate).toISOString() }),
      ...(!isNil(endDate) && { end: new Date(endDate).toISOString() }),
    };

    const newRow = data;
    newRow.period = period;

    const editRowId: any = editRow;

    const updateRow = rows?.map((obj) => {
      if (obj.id === editRowId?.id) {
        return {
          ...data,
        };
      } else {
        const { id, ...newObj } = obj;
        return {
          ...newObj,
        };
      }
    });

    const address = editRowId ? updateRow : [newRow, ...updateRow];

    try {
      await update(
        produce(patientaddress!, (draft) => {
          draft.address = address;
        })
      );

      const setUpdatedRow = address?.map((ident: any, index: any) => {
        const id = index;
        return { ...ident, id };
      });

      setRows(setUpdatedRow);
    } catch (error) {
      console.log('error', error);
    }
    await onRefresh?.();
    handleCloseAddress();
  };

  const saveDeleteIdentifier = async () => {
    const addressDelete = rows?.filter((row: any) => row.id != idDelete);
    const addressFHIR = addressDelete?.map(({ id, ...rest }) => rest);

    try {
      await update(
        produce(patientaddress!, (draft) => {
          draft.address = addressFHIR;
        })
      );

      const setUpdatedRow = addressFHIR?.map((ident: any, index: any) => {
        const id = index;
        return { ...ident, id };
      });

      setRows(setUpdatedRow);
    } catch (error) {
      console.log('error', error);
    }
    await onRefresh?.();
    handleClose();
  };

  const handleEditClicks = (id: GridRowId) => () => {
    setDisablePeriod(false);
    const editRow = rows?.find(({ id: rowId }: { id: any }) => rowId === id);
    if (editRow) {
      setEditRow(editRow);
      setStartDate(editRow?.period?.start);
      setEndDate(editRow?.period?.end);
      setOpenAddAddress(true);
    }
  };

  const saveId = (id: any) => {
    setrowDelete(rows?.filter((row: any) => row.id === id));
    const typeModal = rows?.filter((row: any) => row.id === id)[0].id;
    setIdDelete(typeModal);
    handleOpen();
  };

  const aclEdit = checkAclValidation({ user, acl: getRelatedAcls(EditAddressDetails) });
  const aclDelete = checkAclValidation({ user, acl: getRelatedAcls(DeleteAddressDetails) });
  const showEdit = aclEdit || aclDelete;

  const columns: GridColumns = [
    {
      field: 'street1',
      headerName: 'Street 1',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.line?.[0];
      },
      valueSetter: (params: GridValueSetterParams) => {
        return { ...params.row, line: [params.value] };
      },
      width: 220,
      editable: true,
      sortable: false,
    },
    {
      field: 'street2',
      headerName: 'Street 2',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.line?.[1];
      },
      valueSetter: (params: GridValueSetterParams) => {
        const address = params.row as Address;
        const { value: newValue } = params;
        let addressLines = address.line;
        if (!addressLines || addressLines.length === 0) {
          addressLines = [newValue];
        } else if (addressLines.length === 1) {
          addressLines = [...addressLines, newValue];
        } else {
          addressLines[1] = newValue;
        }
        return { ...params.row, line: addressLines };
      },
      width: 150,
      editable: true,
      sortable: false,
    },
    {
      field: 'postalCode',
      headerName: 'ZIP Code',
      width: 110,
      editable: true,
      sortable: false,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 160,
      editable: true,
      sortable: false,
    },
    {
      field: 'district',
      headerName: 'County',
      width: 160,
      editable: true,
      sortable: false,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 160,
      editable: true,
      sortable: false,
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 160,
      editable: true,
      sortable: false,
    },
    {
      field: 'start',
      headerName: 'Start',
      editable: true,
      width: 160,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => {
        const start = params.row.period !== undefined && params.row.period.start;
        const startDate = moment(start ? new Date(start) : null);
        return (
          <Typography variant="body2">
            {!startDate.isValid() ? '' : startDate.format('MM/DD/YYYY')}
          </Typography>
        );
      },
      valueGetter: (params: GridValueGetterParams) => {
        const start = params.row.period !== undefined ? new Date(params.row.period.start) : null;
        return start;
      },
      valueSetter: (params: GridValueSetterParams) => {
        const start = params.value ?? null;
        return { ...params.row, start };
      },
    },
    {
      field: 'end',
      headerName: 'End',
      editable: true,
      width: 160,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => {
        const end = params.row.period !== undefined && params.row.period.end;
        const endDate = moment(end ? new Date(end) : null);
        return (
          <Typography variant="body2">
            {!endDate.isValid() ? '' : endDate.format('MM/DD/YYYY')}
          </Typography>
        );
      },
      valueGetter: (params: GridValueGetterParams) => {
        const end = params.row.period !== undefined ? new Date(params.row.period.end) : null;
        return end;
      },
      valueSetter: (params: GridValueSetterParams) => {
        const end = params.value ?? null;
        return { ...params.row, end };
      },
    },
    {
      field: 'edit',
      headerName: '',
      type: 'actions',
      width: 90,
      cellClassName: 'actions',
      hide: !showEdit,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem key={'grid-a-save-' + id} icon={<SaveIcon />} label="Save" onClick={handleSaveClicks(id)} />,
            <GridActionsCellItem
              key={'grid-a-cancel-' + id}
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(id)}
            />,
          ];
        }

        return [
          aclEdit && isLoaded ? (
            <GridActionsCellItem
              label="Edit"
              className="textPrimary"
              onClick={handleEditClicks(id)}
              color="inherit"
              showInMenu
            />
          ) : (
            <></>
          ),
          aclDelete ? (
            <GridActionsCellItem
              label="Delete"
              className="textPrimary"
              onClick={() => {
                saveId(id);
              }}
              color="inherit"
              showInMenu
            />
          ) : (
            <></>
          ),
        ];
      },
    },
  ];

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow };
    setRows(rows?.map((row: any) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleCloseAddress = () => {
    setOpenAddAddress(false);
    setEditRow(null);
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <>
      <Stack direction="column" sx={{ pt: 1 }}>
        <Typography
          style={{ display: 'flex', justifyContent: 'space-between' }}
          variant="button"
          sx={{ textTransform: 'uppercase' }}
        >
          Address Details
          {checkAclValidation({ user, acl: getRelatedAcls(AddAddressDetails) }) && !!isLoaded && (
            <Button
              disabled={disabledadd}
              color="primary"
              startIcon={<Add />}
              onClick={() => setOpenAddAddress(true)}
            >
              Add
            </Button>
          )}
        </Typography>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            rows={rows ? rows : []}
            columns={columns}
            editMode="row"
            pageSize={pageSize}
            rowModesModel={rowModesModel}
            autoHeight
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            experimentalFeatures={{ newEditingApi: true }}
          />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <Box sx={stylemodal}>
                <Typography id="transition-modal-title" variant="h6" component="h2">
                  <div style={{ textAlign: 'center', fontSize: '25px', fontWeight: '300' }}>
                    Delete Address{' '}
                    <span style={{ fontWeight: '600' }}>{rowDelete?.[0]?.country}:</span>{' '}
                    {rowDelete?.[0]?.line[0]}, {rowDelete?.[0]?.state} {rowDelete?.[0]?.city}
                  </div>
                </Typography>
                <div style={{ textAlign: 'center', paddingTop: '30px' }}>
                  <Button variant="contained" onClick={saveDeleteIdentifier}>
                    Confirm
                  </Button>
                </div>
              </Box>
            </Fade>
          </Modal>
        </Box>
      </Stack>
      <Dialog open={openAddAddress} maxWidth="md">
        <DialogTitle> Add Address</DialogTitle>
        <Card sx={{ p: 2, overflowY: 'scroll' }}>
          <AddAddress
            externalAddress={editRow}
            isEditable={true}
            handleClose={handleCloseAddress}
            handleSave={saveChanges}
            height={'620px'}
            children={
              <>
                <ListItem>
                  <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(value) => {
                      setStartDate(value);
                      setError(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        {...params}
                        fullWidth
                        error={error}
                        helperText={error && `Start Date can't be greater than End Date`}
                      />
                    )}
                  />
                </ListItem>
                <ListItem>
                  <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(value) => {
                      setEndDate(value);
                      setError(false);
                    }}
                    renderInput={(params) => <TextField variant="outlined" {...params} fullWidth />}
                  />
                </ListItem>
              </>
            }
          />
        </Card>
      </Dialog>
    </>
  );
}
