import client from '../../_client';

export interface ProgramExitDestination {
  id: number;
  uuid: string;
  catalogCode: string;
  valueCode: string;
  valueDisplayName: string;
  longDisplayName: string | null;
  isActive: boolean;
  isDefault: boolean;
  order: number | null;
  ownerResourceUID: string | null;
  createdBy: string;
  createdOn: string; // type: date
  updatedBy: string;
  updatedOn: string; // type: date
  deletedBy: string | null;
  deletedOn: string | null; // type: date
}

export async function getExitDestinations() {
  const { data } = await client.get<ProgramExitDestination[]>(
    `/ccm/patient/program-exit-destinations`
  );
  return data;
}

export async function getExitDestination(uuid: string) {
  const { data } = await client.get<ProgramExitDestination>(
    `/ccm/patient/program-exit-destinations/${uuid}`
  );

  return data;
}

export async function updateProgramExitDestination(
  uuid: string,
  payload: Partial<ProgramExitDestination>
) {
  const { data } = await client.patch<ProgramExitDestination>(
    `/ccm/patient/program-exit-destinations/${uuid}`,
    payload
  );
  return data;
}

export async function createProgramExitDestination(payload: Partial<ProgramExitDestination>) {
  const { data } = await client.post<ProgramExitDestination>(
    `/ccm/patient/program-exit-destinations`,
    payload
  );
  return data;
}

export async function deleteProgramExitDestination(uuid: string) {
  const { data } = await client.delete<ProgramExitDestination>(
    `/ccm/patient/program-exit-destinations/${uuid}`
  );
  return data;
}
