import { useContext } from 'react';
import {
  Divider,
  Box,
  Grid,
  Typography,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Autocomplete,
} from '@mui/material';
import Bed from 'src/sections/careflow/rounding/Model/Bed';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import { useCensusSaveBed } from 'src/services/api/census';
import { CensusContext } from 'src/sections/careflow/census/CensusContext';
import useObjectState from 'src/hooks/useObjectState';
import { useLocations } from 'src/@nicheaim/fhir-react';
import useAuth from 'src/hooks/useAuth';

export type AddBedPropsType = {
  onSaveCallback: (bed: Bed) => void;
  onCancelPress: () => void;
  bed: Bed;
};

interface LocationItemI {
  label: string | null | undefined;
  value: string | undefined;
}

interface BedStateI {
  identifier: string;
  locationItem: LocationItemI | null;
  status: boolean;
  isNotValidated: boolean;
}

const AddBed = ({ onSaveCallback, onCancelPress, bed }: AddBedPropsType) => {
  const user = useAuth().getCurrentUser();
  const [{
    identifier,
    locationItem,
    status,
    isNotValidated,
  }, updateState] = useObjectState<BedStateI>({
    identifier: bed?.identifier ?? '',
    locationItem: bed?.locationFhirId ? {
      label: bed?.locationFhirName,
      value: bed?.locationFhirId,
    } : null,
    status: bed?.isActive ?? 0,
    isNotValidated: false,
  });
  const censusContext = useContext(CensusContext);
  const [locations] = useLocations({
    filter: {
      _sort: 'name'
    }
  });
  const { mutateAsync: saveBed, isLoading, isSuccess, isError } = useCensusSaveBed();

  const validateForm = () => {
    if (!locationItem || !identifier) return false;
    return true;
  };

  const onSavePress = async () => {
    if (!validateForm()) {
      updateState({
        isNotValidated: true,
      })
      return;
    }

    saveBed({
      id: bed?.id,
      status,
      locationFhirId: locationItem?.value!,
      locationFhirName: locationItem?.label!,
      identifier,
      createdByName: user.name,
    }).then((savedBed: Bed) => {
      onSaveCallback(savedBed);
      setTimeout(() => {
        onCancelPress();
      }, 800)
    }).catch(() => {
      
    })
  };


  return (
    <form action="">
      <Box>
        <Box sx={{ my: 3 }}>
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {bed ? 'Update bed' : 'Add bed'}
            </Typography>
          </Grid>
        </Box>
        <Divider />
        <Grid item xs={6} style={{ marginTop: '15px' }}>
          <InputLabel>Identifier *</InputLabel>
          <FormControl fullWidth style={{ paddingRight: '5px' }}>
            <TextField
              value={identifier}
              defaultValue={identifier}
              onChange={(e) => updateState({ identifier: e.target.value })}
              variant="standard"
              required
            />
          </FormControl>
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <InputLabel>Location *</InputLabel>
          <Autocomplete
            disablePortal
            options={locations.map((i) => ({
              label: i.name,
              value: i.id,
            }))}
            value={locationItem}
            groupBy={(option:any) => option?.name && option?.name}
            onChange={(_: any, value: any | null) =>
              updateState({ locationItem: value })
            }
            renderInput={(params) => (
              <TextField required variant="standard" {...params} />
            )}
          />
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <InputLabel>Status *</InputLabel>
          <Select
            value={status}
            style={{
              width: '100%'
            }}
            onChange={(e) => {
              const value = Number(e.target.value) === 0;
              updateState({ status: value });
            }}
            required
          >
            <MenuItem value={1} >Active</MenuItem>
            <MenuItem value={0} >Inactive</MenuItem>
          </Select>
        </Grid>
        {isNotValidated && (
          <Alert style={{ marginTop: '20px' }} severity="error">
           Please fill all fields and try again.
          </Alert>
        )}
        {isError && (
          <Alert style={{ marginTop: '20px' }} severity="error">
           Error saving the bed. Please try again.
          </Alert>
        )}
        {isSuccess && (
          <Alert style={{ marginTop: '20px' }} severity="success">
            Bed created successfully.
          </Alert>
        )}
        {bed?.currentBedAssignment && status === false && (
          <Alert style={{ marginTop: '20px' }} severity="warning">
            Please remove the patient from the bed before inactivating it.
          </Alert>
        )}
        <ActionButtons
          isLoading={isLoading}
          leftButtonTitle="Cancel"
          onLeftButtonPress={censusContext.closeAddBedModal}
          onRightButtonPress={onSavePress}
          disabled={bed?.currentBedAssignment && status === false ? true : false}
        />
      </Box>
    </form>
  );
};

export default AddBed;
