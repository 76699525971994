import moment from 'moment';
import { IdentifierCode, IDENTIFIER_MAP } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ContactPoint, Reference } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

export function getFhirIdFromEntity(fhirId: string) {
  const fhirIdArray = fhirId?.split('/');
  return fhirIdArray?.length > 0 ? fhirIdArray.slice(-1)[0] : null;
}

export function referenceEquals(
  reference: Reference,
  resource:
    | Reference
    | {
        resourceType: string;
        id?: string;
      }
) {
  const isResource = 'resourceType' in resource;
  const [resourceType, id] = isResource
    ? [resource.resourceType, resource.id]
    : resource.reference?.split('/') || [resource.type, resource.id];

  return (
    reference.reference === `${resourceType}/${id}` ||
    (reference.type === resourceType && reference.id === id)
  );
}

export function setStatus(statusList: any, statusValue: any) {
  const statusLabel = statusList
    .filter((e: any) => e.value === statusValue)
    .map((s: any) => s.label);
  return statusLabel;
}

export function getAddressFormated (address:any) {
  
  const objectAddress: {[key: string]: string} = {};
  for (let i: number = 0; i < address?.line?.length; i++) {
    objectAddress[`line_${i}`] = address?.line?.[i];
  }

  delete address?.line;
  if(address?.period){
    address.period.start = moment.utc(new Date(address.period.start)).format('YYYY-MM-DD');
    address.period.end = moment.utc(new Date(address.period.end)).format('YYYY-MM-DD');
  } 

  address = { ...address, ...objectAddress };

  return address;
}

export function mergeLineAddress (address:any){

  const line = [address?.line_0, address?.line_1];

  delete address.line_0;
  delete address.line_1;

  const originalAddress = address;
  address = {...originalAddress, line};

  return address;

}

export function getPrimaryPhone(telecom: any): ContactPoint | null {
  let mainPhone = null;
  if (telecom && telecom?.length > 0) {
    const phones = telecom.filter((t: any) => t?.system === 'phone');
    if (phones && phones?.length > 0) {
      mainPhone = phones[0];
    }
  }
  return mainPhone;
}

export function getIdentifier(patient: any, identifierCode: IdentifierCode) {
  let identifierType = IDENTIFIER_MAP.get(identifierCode);
  const type = identifierType?.type?.coding?.[0].code?.toLowerCase();
  const system = identifierType?.system;
  if (system) {
    const value = patient?.identifier?.filter((identifier: any) => identifier.type === type).
      map((e: any) => ({
        ...identifierType,
        use: e.use,
        value: e.value,
        period: {start: new Date().toISOString()}
      }))[0] || null;
    return value;
  }
}