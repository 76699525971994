import { Skeleton } from '@mui/material';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useMemo } from 'react';

const Map = ({
  children,
  center,
  width,
  height,
}: any) => {
  const JsOptions = useMemo<any>(() => ({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: ['places'],
  }), []);
  const { isLoaded } = useJsApiLoader(JsOptions);
  const mapContainerStyle = {
    width: width ? width : '500px',
    height: height ? height : '500px'
  };

  return isLoaded ? (
    <GoogleMap
      zoom={9}
      center={center?.lat !== '' && !isNaN(center?.lat) && center?.lng !== '' && !isNaN(center?.lng)
        ? center
        : { lat: 38.8335633, lng: -77.1595717 }}
      mapContainerStyle={mapContainerStyle}
    >
      {children}
    </GoogleMap>
  ) : (
    <Skeleton height={400} width={400} />
  );
};

export default Map;
