import { ActivityListTable } from './ActivityListTable';
import { getActivitiesByDate } from '../activities-utils';
import { ActivityLegends } from '../../../model';
import { ActivityViewProps } from '../ActivityViewProps';
import uuidv4 from '../../../../../../utils/uuidv4';

export function ActivityListView({
  isLoading,
  now,
  activities,
  currentRange,
  total,
  patientId,
  isPatientView,
}: ActivityViewProps) {
  return (
    <>
      {
        <ActivityListTable
          activities={activities}
          total={total}
          isLoading={isLoading}
          legend={ActivityLegends.PAST_DUE}
          now={now}
          isPatientView={isPatientView}
          patientId={patientId}
        />
      }
    </>
  );
}
