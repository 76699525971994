import { Button, Grid, TextField } from '@mui/material';
import { ToggleButtonOptions } from '../../ToggleButtonOptions';
import { PATEINTS_LIST_PERSON } from '../constants';

type PatientsFiltersPropsType = {
  selectedPatientListPerson: PATEINTS_LIST_PERSON;
  onChangePatientsListPerson: (listType: PATEINTS_LIST_PERSON) => void;
  onChangeSearch: (value: string) => void;
  onClearStatusFilter: () => void;
};

const PatientsFilters = ({
  selectedPatientListPerson,
  onChangePatientsListPerson,
  onChangeSearch,
  onClearStatusFilter,
}: PatientsFiltersPropsType) => (
  <Grid item xl={12}>
    <Grid container spacing={4}>
      <Grid item xl={4}>
        <TextField
          onChange={(e) => onChangeSearch(e.target.value)}
          required
          placeholder="Search"
          style={{
            width: '100%',
          }}
        />
      </Grid>
      <Grid item>
        <ToggleButtonOptions
          selectedValue={selectedPatientListPerson}
          options={[
            { value: PATEINTS_LIST_PERSON.MY_PATIENTS },
            { value: PATEINTS_LIST_PERSON.ALL_PATIENTS },
          ]}
          onOptionChange={(selectedOption) =>
            onChangePatientsListPerson(selectedOption.value as PATEINTS_LIST_PERSON)
          }
        />
      </Grid>
      <Grid item>
        <Button
          onClick={() => {
            onClearStatusFilter();
          }}
          variant="contained"
          sx={{
            padding: '15px',
            top: 1,
            backgroundColor: '#00AB55',
            // color: '#637381',
            boxShadow: 'none'
          }}
        >
          CLEAR STATUS FILTER 
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

export default PatientsFilters;
