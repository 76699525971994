import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { usePatient } from 'src/@nicheaim/fhir-react';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';
import usePatientTabsStore from 'src/stores/patient-tabs';
import { imageFromAttachment } from 'src/utils/formatPhoto';
import { CCMUser, CCM_ACLS, checkAclValidation } from 'src/utils/permissions/permission.utils';

interface PatientCardProps {
  id: string;
  showCloseButton?: boolean;
}

const defaultTab = 'patient-demographics';

const PatientCard = ({ id, showCloseButton = true }: PatientCardProps) => {
  const user = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const closePatient = usePatientTabsStore((s) => s.closePatient);
  const [patient, { isFetching }] = usePatient(id, {
    map: PatientWrapper,
  });

  const urlMatch = useMatch('/dashboard/patient/:patientId/:currentTab/*');
  const isActive = usePatientTabsStore((s) => s.currentPatientId === id && urlMatch);

  function onClick() {
    if (checkAclValidation({ user, acl: [CCM_ACLS.PATIENT_CARD.action, CCM_ACLS.CCM_ADMIN, CCM_ACLS.CCM_SYSTEM_ADMIN] })) {
      navigate(`/dashboard/patient/${id}/${urlMatch?.params.currentTab || defaultTab}`);
    }
  }

  function onClose() {
    const nextPatient = closePatient(id);

    if (!nextPatient) {
      navigate(`/dashboard/${urlMatch?.params.currentTab || defaultTab}`);
    } else {
      navigate(`/dashboard/patient/${nextPatient}/${urlMatch?.params.currentTab || defaultTab}`);
    }
  }

  const mrnId = useMemo(() => patient?.identifier?.find(identifier => identifier?.system?.toLowerCase()?.includes('code=mrn&name=mrn'))?.value, [patient]);
  const tempId = useMemo(() => patient?.identifier?.find(identifier => identifier.type?.coding?.[0].code !== 'MasterID')?.value, [patient]);

  if(!patient?.id && !isFetching) {
    closePatient(id)
    return null;
  } 

  return (
    <Card
      variant="outlined"
      sx={{
        background: '#f4f6f8',
        cursor: 'pointer',
        overflow: 'visible',
        borderColor: isActive ? theme.palette.primary.main : undefined,
        maxWidth: '250px',
        paddingRight: '40px'
      }}
    >
      {isFetching ? (
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', p: '16px 8px' }}
        >
          <CircularProgress size={20} />
        </Box>
      ) : (
        <Stack
          alignItems="center"
          direction="row"
          onClick={onClick}
          spacing={2}
          sx={{ p: '16px 8px' }}
        >
          <Avatar src={imageFromAttachment(patient?.photo?.[0])} />
          <Stack>
            <Typography variant="subtitle2">{patient?.getFullName()}</Typography>
            <Typography variant="caption">{mrnId || tempId}</Typography>
          </Stack>
        </Stack>
      )}
      {showCloseButton && (
        <IconButton
          sx={{
            position: 'absolute',
            right: -10,
            top: -10,
          }}
          onClick={onClose}
        >
          <Iconify
            icon="eva:close-circle-outline"
            sx={{
              color: 'red',
              height: 20,
              width: 20,
            }}
          />
        </IconButton>
      )}
    </Card>
  );
};

export default PatientCard;
