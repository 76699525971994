import {
  IconButton, Tab, Tabs, Modal, Card, Typography, Box
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import { EntityContextMenu, SummaryTable } from 'src/sections/careflow/common';
import MedicationRequestForm from './MedicationRequestForm';
import { useMedicationRequests } from 'src/@nicheaim/fhir-react';
import { MedicationRequest } from 'src/@nicheaim/fhir-base/mappings/MedicationRequest';
import moment from 'moment';
import useObjectState from 'src/hooks/useObjectState';
import AddModal from '../../common/AddModal';
import { useCallback, useEffect } from 'react';
import TabItem from '../../group-sessions/types/TabItem';
import DiscontinueMedicationRequestForm from './DiscontinueMedicationRequestForm';
import { useMatch, useNavigate } from 'react-router';
import usePatientTabsStore from 'src/stores/patient-tabs';
import MedicationsTransactionsSummary from '../medications-transactions/MedicationsTransactionsSummary';

interface MedicationsRequestsSummaryProps {
  isExpanded: boolean;
  patient: any;
  onExpand: (index: number) => void;
}

type MedicationsRequestsSummaryState = {
  selectedMedicationRequest?: MedicationRequest;
  showMedicationRequestForm: boolean;
  selectedTabIndex: number;
  showMedicationTransactionsModal: boolean;
  showDiscontinueFormModal: boolean;
}

const TAB_VALUES: TabItem[] = [
  {
    key: 0,
    value: 1,
    label: 'Active',
  },
  {
    key: 1,
    value: 2,
    label: 'Discontinued',
  },
];

const MedicationsRequestsSummary = ({ isExpanded, onExpand, patient }: MedicationsRequestsSummaryProps) => {
  const navigate = useNavigate();
  const [{
    selectedMedicationRequest,
    showMedicationRequestForm,
    selectedTabIndex,
    showMedicationTransactionsModal,
    showDiscontinueFormModal,
  }, updateState] = useObjectState<MedicationsRequestsSummaryState>({
    selectedMedicationRequest: undefined,
    showMedicationRequestForm: false,
    selectedTabIndex: 0,
    showMedicationTransactionsModal: false,
    showDiscontinueFormModal: false,
  })
  const patientStore = usePatientTabsStore();
  const [medicationsRequests] =
    useMedicationRequests({
      filter: {
        patient: `${patient?.id}`,
        _sort: '-authoredOn',
        status: selectedTabIndex === 0 ? 'active' : 'stopped'
      },
    });
  const medicationRequestUrlMatch =
    useMatch('/dashboard/patient/:patientId/health-record/medication-request/:medicationRequestId')

  const onCloseMedicationRequestForm = () => {
    if (medicationRequestUrlMatch?.params) {
      navigate(`/dashboard/patient/${patient?.id}/health-record`)
    }
    updateState({
      showMedicationRequestForm: false,
      selectedMedicationRequest: undefined,
    });
  }

  const renderTabs = useCallback(
    () => (
      <Tabs value={selectedTabIndex} variant="scrollable" scrollButtons="auto">
        {TAB_VALUES?.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            onClick={() => {
              updateState({ selectedTabIndex: tab.key as number });
            }}
            id={`status-tab-${tab.key}`}
            {...{
              'aria-controls': `status-tabpanel-${tab.key}`,
            }}
          />
        ))}
      </Tabs>
    ),
    [selectedTabIndex, updateState]
  );

  useEffect(() => {
    if (medicationRequestUrlMatch) {
      patientStore.setCurrentPatient(medicationRequestUrlMatch.params.patientId ?? '');
      patientStore.openPatient(medicationRequestUrlMatch.params.patientId ?? '');
    }
  }, [])

  useEffect(() => {
    if (medicationRequestUrlMatch) {
      updateState({
        showMedicationRequestForm: true,
      });
    }
  }, [])

  return (
    <Card sx={{ height: `calc(100vh - 200px)`, display: 'flex', flexDirection: 'column' }}>
      {renderTabs()}
      <AddModal
        Component={
          <MedicationRequestForm
            patient={patient!}
            onClose={onCloseMedicationRequestForm}
            medicationRequest={selectedMedicationRequest}
          />
        }
        containerStyle={{
          width: '600px'
        }}
        open={showMedicationRequestForm}
        onClose={onCloseMedicationRequestForm}
      />

      <Modal
        open={showDiscontinueFormModal}
        onClose={() => {
          updateState({
            showDiscontinueFormModal: false,
            selectedMedicationRequest: undefined,
          })
        }}
      >
        <DiscontinueMedicationRequestForm
          medicationRequest={selectedMedicationRequest!}
          onClose={() =>  updateState({
            showMedicationRequestForm: false,
            showDiscontinueFormModal: false,
          })}
          onSave={() => {}}
        />
      </Modal>

      <Modal
        open={showMedicationTransactionsModal}
        onClose={() => {
          updateState({
            showMedicationTransactionsModal: false,
            selectedMedicationRequest: undefined,
          })
        }}
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <MedicationsTransactionsSummary
            patient={patient}
            medicationRequest={selectedMedicationRequest!}
            isExpanded={true}
            onExpand={() => {}}
          />
        </Box>
      </Modal>

      <SummaryTable
        title="Medication Requests"
        columns={[
          {
            header: 'RX Number',
            field: '$',
            render(medicationRequest: MedicationRequest) {
              const identifier = medicationRequest.identifier?.find((i) => i.type?.coding?.find(c => c.code === 'RX'))
              return <Typography>{identifier?.value || 'N/A'}</Typography>
            },
          },
          {
            header: 'Barcode',
            field: '$',
            visible: isExpanded,
            render(medicationRequest: MedicationRequest) {
              const identifier = medicationRequest.identifier?.find(i => i.type?.coding?.find(c => c.code === 'barcode'))
              return <Typography>{identifier?.value || 'N/A'}</Typography>
            },
          },
          {
            header: 'Status',
            field: 'status',
            render(value) {
              return <Typography>{value === 'stopped' ? 'discontinued' : value}</Typography>;
            },
          },
          {
            header: 'Medication',
            field: '$',
            render(medicationRequest: MedicationRequest) {
              return <Typography>{medicationRequest?.medicationReference?.display}</Typography>
            }
          },
          {
            header: 'Route',
            field: '$',
            visible: isExpanded,
            render(medicationRequest: MedicationRequest) {
              return <Typography>{medicationRequest?.dosageInstruction?.[0]?.route?.text}</Typography>
            }
          },
          {
            header: 'Start',
            field: '$',
            render(medicationRequest: MedicationRequest) {
              const period = medicationRequest.dispenseRequest?.validityPeriod;
              return <Typography>{period ? moment(period.start).format('MMM DD YYYY') : 'N/A'}</Typography>
            },
          },
          {
            header: 'End',
            field: '$',
            render(medicationRequest: MedicationRequest) {
              const period = medicationRequest.dispenseRequest?.validityPeriod
              return <Typography>{period ? moment(period.end).format('MMM DD YYYY') : 'N/A'}</Typography>
            },
          },
        ]}
        data={medicationsRequests}
        actionBar={
          <>
            <IconButton
              onClick={() =>
                updateState({
                  showMedicationRequestForm: true,
                  selectedMedicationRequest: undefined,
                })
              }
            >
              <Iconify icon="eva:plus-fill" />
            </IconButton>
            <IconButton onClick={() => onExpand?.(2)}>
              <Iconify icon="eva:expand-fill" />
            </IconButton>
          </>
        }
        rowContextMenu={(currentMedicationRequest: any) => (
          <EntityContextMenu
            entity={currentMedicationRequest}
            items={[
              {
                label: 'Edit',
                icon: 'eva:edit-2-outline',
                onPress: (currentMedicationRequest) => {
                  updateState({
                    showMedicationRequestForm: true,
                    selectedMedicationRequest: currentMedicationRequest,
                  });
                },
              },
              {
                label: 'Discontinue',
                icon: 'mdi:cancel',
                rules: (currentMedicationRequest) => currentMedicationRequest.status === 'active',
                onPress: (currentMedicationRequest) => {
                  updateState({
                    showDiscontinueFormModal: true,
                    selectedMedicationRequest: currentMedicationRequest,
                  })
                },
              },
              {
                label: 'View Transactions',
                icon: 'icon-park-outline:transaction',
                rules: (currentMedicationRequest) => currentMedicationRequest.status === 'active',
                onPress: (currentMedicationRequest) => {
                  updateState({
                    showMedicationTransactionsModal: true,
                    selectedMedicationRequest: currentMedicationRequest,
                  })
                },
              },
            ]}
          />
        )}
      />
    </Card>
  );
};

export default MedicationsRequestsSummary;
