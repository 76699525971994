// @ts-nocheck
import moment from "moment";
import GroupSessionType from "../../types/GroupSessionType";
import { AddGroupSessionStateType, ScheduleItemDayEnum } from "./types";
import { defaulState } from "./constants";

export const getDefaultState = (groupSession?: GroupSessionType | null): AddGroupSessionStateType => {
  if (groupSession) {
    let locationNames = groupSession.locationFhirName.split('*|*');
    let locationIds = groupSession.locationFhirId.split(',');

    if (locationNames.length === 1 && locationNames[0] === '') {
      locationNames = [];
    }
    if (locationIds.length === 1 && locationIds[0] === '') {
      locationIds = [];
    }

    return {
      sessionName: groupSession.name,
      description: groupSession.description,
      groupSessionItem: null,
      startDate: moment(groupSession.startDate).format('YYYY-MM-DD'),
      endDate: moment(groupSession.endDate).format('YYYY-MM-DD'),
      schedules: groupSession.schedules.filter(e => e.deletedOn === null).map((schedule) => ({
        id: schedule.id,
        tempId: schedule.id,
        day: schedule.day,
        date: moment(schedule.date).format('YYYY-MM-DD'),
        startHour: schedule.startHour,
        endHour: schedule.endHour,
        frequency: schedule.frequency,
        disabledFrequency: schedule.day === ScheduleItemDayEnum.ALL ? true : false,
      })),
      assessmentItems: groupSession.assessments.filter(a => a.deletedOn === null).map((assessment) => ({
        label: assessment.assessmentFhirName,
        value: assessment.assessmentFhirId,
        isRequired: assessment.isRequired,
      })),
      locationItems: locationNames.map((name, index) => ({
        label: name,
        value: locationIds[index],
      })),
      peopleItems: groupSession.participants.filter(p => p.deletedOn === null).map((participant) => ({
        label: participant.patient.fullName,
        value: participant.patientId,
      })),
      patients: [],
      locations: [],
      loadingPatients: true,
    };
  }

  return defaulState;
};