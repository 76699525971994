import moment from 'moment';
import Iconify from 'src/components/Iconify';
import { GridColDef } from '@mui/x-data-grid';
import { isBetweenDates } from 'src/utils/dates';
import { useState, useEffect } from 'react';
import useObjectState from 'src/hooks/useObjectState';
import OutboundActionMenu from './OutboundActionMenu';
import Program from 'src/sections/crs/common/Program';
import { GridFilters } from 'src/hooks/useGridFilters';
import CheckboxList from 'src/components/CheckboxList';
import SeverityStatus from 'src/components/SeverityStatus';
import { ServiceRequestGridRowData } from 'src/@types/crs/case';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import PeriodFilter, { onDateChange } from 'src/components/PeriodFilter';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import OutboundReferralDocuments from '../outbound/OutboundReferralDocuments';
import useGridFilterConsolidation from 'src/hooks/useGridFilterConsolidation';
import OutboundReferralAnnotations from '../outbound/OutboundReferralAnnotations';
import { CommunicationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Communication';
import CollapsibleDataGrid from 'src/sections/crs/case/components/CollapsibleDataGrid';
import OutboundGrid, { initialServiceRequestFilters, ServiceRequestFilters } from './OutboundGrid';
import { Reference } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import {
  ServiceRequestWrapper,
  WrappedServiceRequest,
} from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { GridFilterDrawerProps } from 'src/sections/crs/case/components/DataGridWithFilters/DataGridWithFilters';
import OutbounReferralAdd, {
  ServiceRequestStatus,
  serviceRequestStatuses,
} from '../outbound/OutboundReferralAdd';
import {
  useBinarys,
  useCommunications,
  useDocumentReferences,
  useServiceRequests,
} from 'src/@nicheaim/fhir-react';
import {
  getDocuments,
  getAnnotations,
  getServiceRequestGridRows,
  searchIfContainedInObj,
} from 'src/sections/crs/helpers/common';
import { WrappedHealthcareService } from 'src/@nicheaim/fhir-base/wrappers/HealthcareService';

export interface CarePlanServiceRequestGridProps
  extends GridFilterDrawerProps<ServiceRequestFilters> {
  serviceRequests: WrappedServiceRequest[];
  patient: WrappedPatient | null;
  carePlan?: WrappedCarePlan | null;
  healthCareServices?: WrappedHealthcareService[] | null;
  isNestedGrid?: boolean;
  showPagination?: boolean;
  handleServiceRequest: any;
  title?: string;
}

const CaseOutboundGrid = ({
  serviceRequests,
  isNestedGrid,
  showPagination,
  patient,
  carePlan,
  healthCareServices,
  handleServiceRequest,
  title,
  filterValues,
  onSearchTextFieldChange,
  searchTextFieldValue,
  onFilterDrawerOpen,
  onFilterDrawerClose,
  isFilterDrawerOpen,
  onApplyFilters,
}: CarePlanServiceRequestGridProps) => {
  const { filters, updateFilters, onClearAllFilters } =
    useGridFilterConsolidation<ServiceRequestFilters>(filterValues, {
      ...initialServiceRequestFilters,
    });
  const [serviceRequestGridRows, setServiceRequestGridRows] = useState<ServiceRequestGridRowData[]>(
    []
  );

  const [
    {
      isServiceRequestModalOpen,
      isAssignProgramModalOpen,
      serviceRequestToEdit,
      isOpenAnnotations,
      isOpenDocuments,
    },
    updateState,
  ] = useObjectState<{
    isServiceRequestModalOpen: boolean;
    serviceRequestToEdit: WrappedServiceRequest | null;
    isAssignProgramModalOpen: boolean;
    isOpenAnnotations: boolean;
    isOpenDocuments: boolean;
  }>({
    isServiceRequestModalOpen: false,
    serviceRequestToEdit: null,
    isAssignProgramModalOpen: false,
    isOpenAnnotations: false,
    isOpenDocuments: false,
  });

  const [serviceRequestChilds] = useServiceRequests({
    filter: { category: 'outbound_referral_child', subject: patient?.id },
    map: ServiceRequestWrapper,
  });

  const [communications, { create: createCommunication }] = useCommunications({
    filter: { subject: patient?.id, category: 'doctors,internal,referral' },
    map: CommunicationWrapper,
  });

  const [, { create: createBinary }] = useBinarys({ autofetch: false });
  const [documentReferences, { create: createDocumentReference, update: updateDocumentReference }] =
    useDocumentReferences({ filter: { subject: patient?.id } });

  useEffect(() => {
    const serviceRequestGridRows = getServiceRequestGridRows(serviceRequests, serviceRequestChilds);
    setServiceRequestGridRows(
      filterServiceRequestGridRows(serviceRequestGridRows, { filters, searchTextFieldValue })
    );
  }, [serviceRequests, filters, searchTextFieldValue]);

  const handleOnServiceRequestEdit = (serviceRequest: WrappedServiceRequest) => {
    updateState({ serviceRequestToEdit: serviceRequest, isServiceRequestModalOpen: true });
  };

  const handleOnServiceRequestCopy = (serviceRequest: WrappedServiceRequest) => {
    delete serviceRequest?.id;
    updateState({ serviceRequestToEdit: serviceRequest, isServiceRequestModalOpen: true });
  };

  const columns: GridColDef[] = [
    { field: 'serviceReferral', headerName: 'Service Referral', flex: 0.5 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.3,
    },
    { field: 'childCount', headerName: 'Childs', flex: 0.2 },
    {
      field: '',
      headerName: 'Annotations',
      flex: 0.4,
      renderCell: (params) => {
        const { wrappedServiceRequest } = params.row as ServiceRequestGridRowData;
        const getCountCommunication = getAnnotations(
          communications,
          wrappedServiceRequest?.id
        ).length;
        return (
          <Stack direction="row" alignItems="center">
            <Typography variant="inherit">{getCountCommunication}</Typography>
            <IconButton
              onClick={() => {
                updateState({
                  serviceRequestToEdit: wrappedServiceRequest,
                  isOpenAnnotations: true,
                });
              }}
            >
              <Iconify
                icon="mdi:plus-box-multiple-outline"
                sx={{ width: 20, height: 20 }}
                color="#1890FF"
              />
            </IconButton>
          </Stack>
        );
      },
    },
    {
      field: ' ',
      headerName: 'Docs',
      flex: 0.2,
      renderCell: (params) => {
        const { wrappedServiceRequest } = params.row as ServiceRequestGridRowData;
        const getCountDocuments = getDocuments(documentReferences, wrappedServiceRequest?.id);
        return (
          <Stack direction="row" alignItems="center">
            <Typography variant="inherit">{getCountDocuments}</Typography>
            <IconButton
              onClick={() => {
                updateState({ serviceRequestToEdit: wrappedServiceRequest, isOpenDocuments: true });
              }}
            >
              <Iconify
                icon="mdi:plus-box-multiple-outline"
                sx={{ width: 20, height: 20 }}
                color="#1890FF"
              />
            </IconButton>
          </Stack>
        );
      },
    },
    { field: 'assignedTo', headerName: 'Assigned To', flex: 0.4 },
    { field: 'assignedOn', headerName: 'Assigned On', flex: 0.4 },
    {
      field: 'dueTo',
      headerName: 'Due To',
      flex: 0.5,
      renderCell: (params) => {
        const { dueTo } = params.row as ServiceRequestGridRowData;
        return <SeverityStatus status={dueTo} />;
      },
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0.2,
      sortable: false,
      renderCell: (params) => {
        const { wrappedServiceRequest } = params.row as ServiceRequestGridRowData;
        return (
          <OutboundActionMenu
            serviceRequest={wrappedServiceRequest}
            onEditServiceRequest={handleOnServiceRequestEdit}
            updateServiceRequest={handleServiceRequest}
            onAssignProgram={(serviceRequest) => {
              updateState({ serviceRequestToEdit: serviceRequest, isAssignProgramModalOpen: true });
            }}
            onCopyServiceRequest={handleOnServiceRequestCopy}
          />
        );
      },
    },
  ];

  const handleProgramAssignment = async (data: Reference[]) => {
    const nonHealthcareServicePerformers =
      serviceRequestToEdit?.performer?.filter?.(
        ({ reference }) => !reference?.includes?.('HealthcareService')
      ) ?? [];
    await handleServiceRequest({
      ...serviceRequestToEdit,
      performer: [...nonHealthcareServicePerformers, ...data],
    });
  };

  const handleDateFilterChange =
    (dateProp: 'endDate' | 'startDate'): onDateChange =>
    (newValue) => {
      updateFilters({ [dateProp]: newValue });
    };

  const { startDate, endDate, status } = filters;

  return (
    <>
      <CollapsibleDataGrid
        addButtonTitle={'Outbound Referral'}
        title={''}
        onAddButtonClick={() => {
          updateState({
            isServiceRequestModalOpen: true,
          });
        }}
        onFilterDrawerOpen={onFilterDrawerOpen}
        rows={serviceRequestGridRows}
        columns={columns}
        getRowId={(row) => row.id}
        onSearchTextFieldChange={onSearchTextFieldChange}
        searchTextFieldValue={searchTextFieldValue}
        renderCollapsibleContent={({ childs, id }: ServiceRequestGridRowData) => (
          <OutboundGrid
            serviceRequestId={id}
            patient={patient}
            serviceRequest={childs ?? []}
            isNestedGrid={isNestedGrid}
            showPagination={showPagination}
            carePlan={carePlan}
            documentReferences={documentReferences}
            title={title}
            handleServiceRequest={handleServiceRequest}
            handleCreateBynary={createBinary}
            handleCreateDocumentReference={createDocumentReference}
            handleUpdateDocumentReference={updateDocumentReference}
          />
        )}
        subGridRowsExtractor={({ childs }: ServiceRequestGridRowData) => childs ?? []}
        Filters={
          <ServiceRequestFilter
            selectedStatuses={status}
            endDate={endDate}
            startDate={startDate}
            onEndDateChange={handleDateFilterChange('endDate')}
            onStartDateChange={handleDateFilterChange('startDate')}
            onStatusSelectionChange={(checkedItems) => {
              updateFilters({
                status: checkedItems,
              });
            }}
          />
        }
        FilterDrawerProps={{
          title: 'Outbound Filters',
          open: isFilterDrawerOpen,
          onApplyButtonClick: () => {
            onApplyFilters(filters);
          },
          onCloseIconButtonClick: onFilterDrawerClose,
          onClearAllButtonClick: onClearAllFilters,
        }}
      />

      <Program
        healthCareServices={healthCareServices}
        open={isAssignProgramModalOpen}
        healthCareServiceRelated={serviceRequestToEdit?.performer ?? []}
        onCancel={() => {
          updateState({ isAssignProgramModalOpen: false });
        }}
        handleProgramAssigment={handleProgramAssignment}
      />

      <OutbounReferralAdd
        open={isServiceRequestModalOpen}
        serviceRequest={serviceRequestToEdit}
        patient={patient}
        carePlan={carePlan ?? null}
        documentReferences={documentReferences}
        title={'An Outbound Referral'}
        handleOutboundReferral={handleServiceRequest}
        handleCreateBynary={createBinary}
        handleCreateDocumentReference={createDocumentReference}
        handleUpdateDocumentReference={updateDocumentReference}
        onCancel={() => {
          updateState({
            isServiceRequestModalOpen: false,
          });
        }}
      />

      <OutboundReferralAnnotations
        open={isOpenAnnotations}
        communications={getAnnotations(communications, serviceRequestToEdit?.id)}
        serviceRequest={serviceRequestToEdit}
        onClose={() => {
          updateState({
            isOpenAnnotations: false,
          });
        }}
        handleCommunication={createCommunication}
      />

      <OutboundReferralDocuments
        open={isOpenDocuments}
        patient={patient}
        serviceRequest={serviceRequestToEdit}
        documentReferences={documentReferences}
        carePlan={carePlan}
        handleCreateBynary={createBinary}
        handleCreateDocumentReference={createDocumentReference}
        handleUpdateDocumentReference={updateDocumentReference}
        onClose={() => {
          updateState({
            isOpenDocuments: false,
          });
        }}
      />
    </>
  );
};

export const filterServiceRequestGridRows = (
  serviceRequestGridRows: ServiceRequestGridRowData[],
  { filters, searchTextFieldValue }: GridFilters<ServiceRequestFilters>
): ServiceRequestGridRowData[] => {
  const { status, startDate, endDate } = filters;
  let serviceRequestRowsFiltered = [...serviceRequestGridRows];
  const searchByString = searchTextFieldValue?.toLowerCase().trim() ?? '';
  if (searchByString.length >= 3) {
    serviceRequestRowsFiltered = serviceRequestRowsFiltered.filter((serviceRequestGridRows) =>
      searchIfContainedInObj(serviceRequestGridRows, ['serviceReferral', 'status'], searchByString)
    );
  }

  return serviceRequestRowsFiltered.filter((serviceRequestRow) => {
    const serviceRequest = serviceRequestRow.wrappedServiceRequest;
    const serviceRequestStartDate = moment(serviceRequest?.authoredOn ?? null);
    const serviceRequestEndDate = moment(serviceRequest?.occurrencePeriod?.end ?? null);
    if (startDate?.isValid?.() && endDate?.isValid?.()) {
      if (!serviceRequestStartDate.isValid() || !serviceRequestEndDate.isValid()) return false;
      if (!isBetweenDates(serviceRequestStartDate, serviceRequestEndDate, startDate, endDate))
        return false;
    }

    if (startDate?.isValid?.()) {
      if (!serviceRequestStartDate.isValid()) return false;
      if (!serviceRequestStartDate.isSameOrAfter(startDate)) return false;
    }

    if (endDate?.isValid?.()) {
      if (!serviceRequestEndDate.isValid()) return false;
      if (!serviceRequestEndDate.isSameOrBefore(endDate)) return false;
    }

    if (status.length) {
      if (!status.find((status) => status.value === serviceRequest?.status)) return false;
    }
    return true;
  });
};

interface ServiceRequestFilterProps {
  showPathwayFilter?: boolean;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  onStartDateChange: onDateChange;
  onEndDateChange: onDateChange;
  onStatusSelectionChange: (checkedStatus: ServiceRequestStatus[]) => void;
  selectedStatuses: ServiceRequestStatus[];
}

const ServiceRequestFilter = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onStatusSelectionChange,
  selectedStatuses,
}: ServiceRequestFilterProps) => (
  <>
    <Box py={3}>
      <Box marginTop={0} marginBottom={1}>
        <PeriodFilter
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={onStartDateChange}
          onEndDateChange={onEndDateChange}
        />
      </Box>
    </Box>
    <CheckboxList
      containerSx={{ marginBottom: 4 }}
      title="Status"
      keyExtractor={(item) => item.value as string}
      labelExtractor={(item) => item.label}
      onChange={onStatusSelectionChange}
      options={serviceRequestStatuses}
      externalCheckedItems={selectedStatuses}
    />
  </>
);

export default CaseOutboundGrid;
