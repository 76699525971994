import { NestedMenuItem } from 'mui-nested-menu';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import ActionButton from 'src/components/ActionButton';
import {
  Edit as EditIcon,
  ChevronRight as ChevronRightIcon,
  Check as CheckIcon,
  AccessTime as AccessTimeIcon,
  Person as PersonIcon,
  CalendarToday as CalendarTodayIcon,
  AppRegistration as AssignProgramIcon,
  CopyAllRounded as CopyTaskIcon,
  NoteAddOutlined as NoteIcon,
} from '@mui/icons-material';
import { Box, MenuItem, SxProps } from '@mui/material';
import { TaskStatus, getTaskOwnerByReference, getOwnerDisplayLabel } from './TaskModal';
import useObjectState from 'src/hooks/useObjectState';
import { getValueSetConceptValue } from 'src/sections/crs/helpers/common';
import { useEffect } from 'react';
import { onSuccess } from 'src/@types/crs/case';
import { updateTask } from 'src/services/api/case';
import { useSnackbar } from 'notistack';
import SearchTextField from 'src/components/SearchTextField';
import moment from 'moment';
import { getSeverityDueDateData } from 'src/sections/crs/helpers/getSeverityDueDateData';
import DatePickerMoment from 'src/components/DatePickerMoment';
import {
  AssignPrograms,
  checkAclValidation,
  getRelatedAcls,
} from 'src/utils/permissions/permission.utils';
import useAuth from 'src/hooks/useAuth';
import { Task } from 'src/@nicheaim/fhir-base/mappings/Task';

export type onResourceAction = (task: WrappedTask) => void;

export interface TaskActionMenuProps {
  onEditTask: onResourceAction;
  task: WrappedTask;
  onSuccessfulEdit: onSuccess;
  onSearchMemberOpen: onResourceAction;
  onAssignProgram: onResourceAction;
  onCopyTask: onResourceAction;
  onAddNoteToTask: onResourceAction;
  taskStatuses: TaskStatus[];
}

interface TaskActionState {
  status: string | null;
  endDate: moment.Moment | null;
  taskStatusOptions: TaskStatus[];
}

const TaskActionMenu = ({
  task,
  onEditTask,
  onSuccessfulEdit,
  onSearchMemberOpen,
  onAssignProgram,
  onCopyTask,
  onAddNoteToTask,
  taskStatuses,
}: TaskActionMenuProps) => {
  const user = useAuth();
  const [{ status, endDate }, updateState] = useObjectState<TaskActionState>({
    status: task?.status,
    taskStatusOptions: [],
    endDate: null,
  });

  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    const selectedStatus = getValueSetConceptValue(taskStatuses, task?.status);
    updateState({
      status: selectedStatus?.code ?? null,
      endDate: moment(task?.getDueDate() ?? null).isValid() ? moment(task?.getDueDate()) : null,
      taskStatusOptions: [
        ...taskStatuses,
        ...(selectedStatus && !taskStatuses.find(({ code }) => code === selectedStatus?.code)
          ? [selectedStatus]
          : []),
      ],
    });
  }, [task, taskStatuses]);

  return (
    <ActionButton
      renderChildren={(isMenuOpen, setIsMenuOpen) => (
        <Box>
          <NestedMenuItem
            sx={nestedMenuItemStyles}
            onClick={() => {
              setIsMenuOpen(false);
              onEditTask(task);
            }}
            leftIcon={<EditIcon htmlColor="#00ab55" />}
            rightIcon={null}
            label="Edit"
            parentMenuOpen={isMenuOpen}
          />
          {checkAclValidation({ user, acl: getRelatedAcls(AssignPrograms) }) && (
            <NestedMenuItem
              sx={nestedMenuItemStyles}
              onClick={() => {
                setIsMenuOpen(false);
                onAssignProgram(task);
              }}
              leftIcon={<AssignProgramIcon />}
              rightIcon={null}
              label="Assign Program"
              parentMenuOpen={isMenuOpen}
            />
          )}
          <NestedMenuItem
            sx={nestedMenuItemStyles}
            leftIcon={<AccessTimeIcon />}
            rightIcon={<ChevronRightIcon />}
            label="Status"
            parentMenuOpen={isMenuOpen}
          >
            {taskStatuses.map((taskStatus) => (
              <NestedMenuItem
                onClick={async () => {
                  task.status = taskStatus.code as Task['status'];
                  updateState({ status: taskStatus.code });
                  const response = await updateTask(
                    { status: taskStatus.code },
                    task?.id as string
                  );
                  if (!response) {
                    enqueueSnackbar('Unable to update Task Status. Please Try Again', {
                      variant: 'error',
                    });
                    return;
                  }
                  onSuccessfulEdit(response);
                }}
                key={taskStatus.code}
                sx={nestedMenuItemStyles}
                leftIcon={taskStatus.code === status ? <CheckIcon /> : null}
                rightIcon={null}
                label={taskStatus.display}
                parentMenuOpen={isMenuOpen}
              />
            ))}
          </NestedMenuItem>
          <NestedMenuItem
            sx={nestedMenuItemStyles}
            leftIcon={<PersonIcon />}
            rightIcon={<ChevronRightIcon />}
            label="Owner"
            parentMenuOpen={isMenuOpen}
          >
            <NestedMenuItem
              sx={nestedMenuItemStyles}
              leftIcon={
                getOwnerDisplayLabel(getTaskOwnerByReference(task?.owner)) ? <CheckIcon /> : null
              }
              rightIcon={null}
              label={getOwnerDisplayLabel(getTaskOwnerByReference(task?.owner))}
              parentMenuOpen={isMenuOpen}
            />
            <MenuItem>
              <SearchTextField
                fullWidth
                onFocus={() => {
                  onSearchMemberOpen(task);
                }}
              />
            </MenuItem>
          </NestedMenuItem>
          <NestedMenuItem
            sx={nestedMenuItemStyles}
            leftIcon={<CalendarTodayIcon />}
            rightIcon={<ChevronRightIcon />}
            label="Due Date"
            parentMenuOpen={isMenuOpen}
          >
            <NestedMenuItem
              sx={nestedMenuItemStyles}
              leftIcon={<CheckIcon />}
              rightIcon={null}
              label={
                getSeverityDueDateData(moment(endDate?.toISOString() ?? null))?.formattedDueDate ??
                ''
              }
              parentMenuOpen={isMenuOpen}
            />
            <MenuItem>
              <DatePickerMoment
                value={endDate}
                label={'Select New Due Date'}
                onChange={async (dueDate) => {
                  updateState({ endDate: dueDate });
                  if (!dueDate?.isValid?.()) return;
                  if (!task.executionPeriod) task.executionPeriod = { end: undefined };
                  task.executionPeriod.end = dueDate?.toISOString();
                  const response = await updateTask(
                    { endDate: dueDate.toISOString() },
                    task?.id as string
                  );
                  if (!response) {
                    enqueueSnackbar('Unable to update Task Status. Please Try Again', {
                      variant: 'error',
                    });
                    return;
                  }
                  setIsMenuOpen(false);
                  onSuccessfulEdit(response);
                }}
              />
            </MenuItem>
          </NestedMenuItem>
          <NestedMenuItem
            sx={nestedMenuItemStyles}
            onClick={() => {
              setIsMenuOpen(false);
              onCopyTask(task);
            }}
            leftIcon={<CopyTaskIcon />}
            rightIcon={null}
            label="Copy"
            parentMenuOpen={isMenuOpen}
          />
          <NestedMenuItem
            sx={nestedMenuItemStyles}
            onClick={() => {
              setIsMenuOpen(false);
              onAddNoteToTask(task);
            }}
            leftIcon={<NoteIcon />}
            rightIcon={null}
            label="Notes"
            parentMenuOpen={isMenuOpen}
          />
        </Box>
      )}
    />
  );
};

const nestedMenuItemStyles: SxProps = {
  paddingX: 3,
};

export default TaskActionMenu;
