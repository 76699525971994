import React from 'react';
import {
  Typography,
  Grid,
  Box,
  Divider,
  InputLabel,
  FormControl,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
} from '@mui/material';
import moment from 'moment';
import { ConcreteActivity } from '../../../model/Activity';
import { ActivityStatus } from '../../../model';
import useObjectState from '../../../../../../hooks/useObjectState';
import API from 'src/services/api';
import { ActivityActionTypes } from './CompleteActivityMenuItem';
import useAuth from 'src/hooks/useAuth';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DatePicker } from '@mui/lab';

type CompleteActivityMenuType = {
  TitleComponent?: React.ReactElement;
  activityId: number;
  onCloseMenuItem: () => void;
  onSaveActivity: (activity: ConcreteActivity) => void;
  type: ActivityActionTypes;
};

type CompleteActivityStateType = {
  date: Date;
  hour: string;
  duration: number;
  unit: string;
  note: string;
  contactMade: boolean | null;
  error: string | null;
  isLoading: boolean;
};

const CompleteActivityMenu = ({
  TitleComponent,
  onCloseMenuItem,
  activityId,
  onSaveActivity,
  type,
}: CompleteActivityMenuType) => {
  const authUser = useAuth().getCurrentUser();
  const [{ date, hour, duration, unit, contactMade, note, error, isLoading }, updateState] =
    useObjectState<CompleteActivityStateType>({
      date: moment().toDate(),
      hour: moment().format('HH:mm'),
      duration: 0,
      unit: '',
      note: '',
      contactMade: null,
      error: null,
      isLoading: false,
    });

  const validateForm = () => (contactMade === null ? false : true);

  const saveActivity = async () => {
    updateState({
      isLoading: true,
    });

    if (type === ActivityActionTypes.CANCEL) {
      const updateActivity = await API.activities.cancel(activityId, {
        completedDate: moment(date).format(),
        completedTime: moment(hour, 'HH:mm').format(),
      });
      onCloseMenuItem();
      onSaveActivity(
        new ConcreteActivity({
          ...updateActivity,
          start: moment(updateActivity.start).toDate(),
          end: moment(updateActivity.end).toDate(),
        })
      );
    } else {
      if (!validateForm()) {
        updateState({
          error: 'Please select one "contact made" option and try again.',
          isLoading: false,
        });
        return;
      }

      const updateActivity = await API.activities.complete(activityId, {
        completedDate: moment(date).format(),
        completedTime: moment(hour, 'HH:mm').format(),
        duration,
        unit,
        completedNote: note,
        status: ActivityStatus.COMPLETED,
        contactMade,
        authUser,
      });
      onCloseMenuItem();
      onSaveActivity(
        new ConcreteActivity({
          ...updateActivity,
          start: moment(updateActivity.start).toDate(),
          end: moment(updateActivity.end).toDate(),
        })
      );
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 2 }}>
        <Grid item>
          {TitleComponent ? (
            <>{TitleComponent}</>
          ) : (
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {type === ActivityActionTypes.COMPLETE && 'Complete'}
              {type === ActivityActionTypes.START && 'Start'}
              {type === ActivityActionTypes.CANCEL && 'Cancel'}
            </Typography>
          )}
        </Grid>
      </Box>
      <Divider />
      <Box>
        <Grid container>
          <Grid
            item
            xl={type === ActivityActionTypes.CANCEL ? 6 : 3}
            style={{ marginTop: '15px' }}
            sx={{ gap: 1 }}
          >
            <InputLabel shrink>Date</InputLabel>
            <FormControl fullWidth>
              <DatePicker
                value={date}
                onChange={(date) => updateState({ date: moment(date).toDate() })}
                renderInput={(params) => (
                  <TextField required variant="standard" type="date" {...params} />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xl={type === ActivityActionTypes.CANCEL ? 6 : 3} style={{ marginTop: '15px' }}>
            <InputLabel shrink>Time</InputLabel>
            <FormControl fullWidth>
              <TextField
                required
                value={hour}
                defaultValue={hour}
                onChange={(e) => {
                  console.log({
                    h: e.target.value,
                  });
                  updateState({ hour: e.target.value });
                }}
                type="time"
                variant="standard"
              />
            </FormControl>
          </Grid>

          {type !== ActivityActionTypes.CANCEL && (
            <>
              <Grid item xl={4} style={{ marginTop: '15px' }}>
                <InputLabel shrink>Duration (Minutes)</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    required
                    value={duration}
                    defaultValue={duration}
                    onChange={(e) => updateState({ duration: +e.target.value })}
                    type="number"
                    variant="standard"
                  />
                </FormControl>
              </Grid>
              <Grid item xl={2} style={{ marginTop: '15px' }}>
                <InputLabel shrink>Unit</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    required
                    value={unit}
                    defaultValue={unit}
                    onChange={(e) => updateState({ unit: e.target.value })}
                    variant="standard"
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
        {type !== ActivityActionTypes.CANCEL && (
          <>
            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Contact made</InputLabel>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  onChange={(e) =>
                    updateState({
                      contactMade: (e.target as HTMLInputElement).value === '1' ? true : false,
                    })
                  }
                >
                  <FormControlLabel value={'1'} control={<Radio />} label="Yes" />
                  <FormControlLabel value={'0'} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid container>
              <Grid item xl={12} style={{ marginTop: '15px' }}>
                <InputLabel>Note</InputLabel>
                <FormControl fullWidth>
                  <ReactQuill
                    theme="snow"
                    value={note}
                    onChange={(val) => updateState({ note: val })}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}
        {error && (
          <Alert style={{ marginTop: '20px' }} severity="error">
            {error}
          </Alert>
        )}
        <ActionButtons
          isLoading={isLoading}
          leftButtonTitle={type === ActivityActionTypes.CANCEL ? 'Clear and close' : 'Cancel'}
          onLeftButtonPress={onCloseMenuItem}
          rightButtonTitle={'Save and close'}
          onRightButtonPress={saveActivity}
        />
      </Box>
    </Box>
  );
};

export default CompleteActivityMenu;
