/* eslint-disable */
import React, { useState } from 'react';
import { startCase } from 'lodash';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export interface Option {
  value: string;
  label?: string;
  disabled?: boolean;
}

export interface OptionChangeFunction {
  (selectedOption: Option): void;
}

interface Options {
  selectedValue?: string | null;
  options: Option[];
  disabled?: boolean;
  onOptionChange: OptionChangeFunction;
}

export function ToggleButtonOptions({
  selectedValue,
  options,
  disabled = false,
  onOptionChange,
}: Options) {
  const [controlled] = useState(selectedValue !== undefined);
  const [currentValue, setCurrentValue] = !controlled
    ? useState(selectedValue ?? options?.[0]?.value)
    : [selectedValue ?? options?.[0]?.value, () => {}];

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string | null | undefined
  ) => {
    newValue ??= currentValue;
    setCurrentValue(newValue);
    onOptionChange(options.find((option) => option.value === newValue) || options[0]);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={currentValue}
      exclusive
      disabled={disabled}
      onChange={handleChange}
    >
      {options.map((option, index) => (
        <ToggleButton
          key={option.label || option.value || index}
          value={option.value}
          onClick={(event) => event.stopPropagation()}
          selected={currentValue === option.value}
          disabled={option.disabled}
        >
          {option.label || startCase(option.value)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
