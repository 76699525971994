import { Route, Routes } from 'react-router';
import ProgramIndex from './$program';
import ProgramsTable from './components/ProgramsTable';
import NewProgramForm from './new';

export default function LOBProgramsIndex() {
  return (
    <Routes>
      <Route index element={<ProgramsTable />} />
      <Route path="new" element={<NewProgramForm />} />
      <Route path=":program/*" element={<ProgramIndex />} />
    </Routes>
  );
}
