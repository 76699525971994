import create from 'zustand';
import uuidv4 from 'src/utils/uuidv4';
import { immer } from 'zustand/middleware/immer';
import { devtools } from 'zustand/middleware';
import shallow from 'zustand/shallow';
import { OperatorType, QUESTION_TYPES } from './constants';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import { fhirClient } from 'src/App';
import { PlanDefinition } from 'src/@nicheaim/fhir-base/mappings/PlanDefinition';
import { List } from 'src/@nicheaim/fhir-base/mappings/List';
import { clearRecoverableChanges } from 'src/hooks/useRecoverableChanges';
import { Bundle } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import api from 'src/services/api';
import { produceWithPatches } from 'immer';
import fhirSystem from 'src/fhir/system';
import fhirDuration from 'src/fhir/codes/duration';
import { OpportunityPriorities } from 'src/services/api/care-plan';

type State = {
  selectedAssessmentId: string;
  selectedAutomationSheetId: string;
  rules: AutomationRule[];
  loading: boolean;
  errors: [string, string][];
  valid: boolean;
  enabled: boolean;
  description: string;
};

type Actions = {
  setSelectedAssessment(questionnaireId: string): void;
  loadAutomationSheet(automationSheetId: string): Promise<void>;
  setDescription(description: string): void;
  setEnabled(enabled: boolean): void;
  addRule(
    question: WrappedQuestionnaireItem,
    rule?: Partial<Omit<AutomationRule, 'question' | 'questionType'>>
  ): void;
  getRule(ruleId: string): AutomationRule | undefined;

  updateRuleProperty<T extends keyof AutomationRule>(
    ruleId: string,
    property: T,
    value: AutomationRule[T]
  ): void;
  updateRuleResultProperty<T extends keyof AutomationRule['result']>(
    ruleId: string,
    property: T,
    value: AutomationRule['result'][T]
  ): void;
  setRuleOperator(ruleId: string, operator: string): void;
  setRuleValue(ruleId: string, value: any): void;
  setRuleResultOpportunity(ruleId: string, opportunityId: string): void;
  setRuleResultGoal(ruleId: string, goalId: string): void;
  setRuleResultIntervention(ruleId: string, interventionId: string): void;
  setRuleResultTimeframe(ruleId: string, timeframe: number): void;
  setRuleResultTimeframeUnit(ruleId: string, unit: string): void;
  setRuleResultPriority(ruleId: string, priority: string): void;
  setRuleEnabled(ruleId: string, enabled: boolean): void;
  setRuleStatus(ruleId: string, status: AutomationRuleStatus): void;

  deleteRule(ruleId: string): void;
  clearRules(): void;
  reset(): void;
  errorFor(field: string): string | null;
  validate(): boolean;

  save(): Promise<boolean>;
};

const getDefaultState = (): State => ({
  selectedAssessmentId: '',
  selectedAutomationSheetId: '',
  rules: [],
  loading: false,
  errors: [],
  valid: false,
  enabled: true,
  description: '',
});

const useStore = create(
  devtools(
    immer<State & Actions, [['zustand/devtools', never]]>((set, get) => ({
      ...getDefaultState(),

      setSelectedAssessment: (assessmentId) => {
        set(
          (s) => {
            s.selectedAssessmentId = assessmentId;
            s.selectedAutomationSheetId = '';
            s.rules = [];
          },
          false,
          // @ts-expect-error as typing is not accepting the extras
          { type: 'care-plan-automation-form/setSelectedAssessment', assessmentId }
        );

        get().validate();
      },

      setDescription: (description) =>
        set(
          (s) => {
            s.description = description;
          },
          false,
          // @ts-expect-error as typing is not accepting the extras
          { type: 'care-plan-automation-form/setDescription', description }
        ),

      setEnabled: (enabled) =>
        set(
          (s) => {
            s.enabled = enabled;
          },
          false,
          // @ts-expect-error as typing is not accepting the extras
          { type: 'care-plan-automation-form/setEnabled', enabled }
        ),

      loadAutomationSheet: async (automationSheetId) => {
        if (!automationSheetId) {
          set((s) => {
            s.rules = [];
            s.selectedAutomationSheetId = '';
            s.description = '';
          });
          return;
        }

        set(
          (s) => {
            s.loading = true;
          },
          false,
          // @ts-expect-error as typing is not accepting the extras
          { type: 'care-plan-automation-form/loadAutomationSheet/start', automationSheetId }
        );
        const automationSheet = await fhirClient.findById<List>('List', automationSheetId);
        if (!automationSheet) {
          set(
            (s) => {
              s.loading = false;
            },
            false,
            // @ts-expect-error as typing is not accepting the extras
            { type: 'care-plan-automation-form/loadAutomationSheet/start', automationSheetId }
          );
          throw new Error('Automation sheet not found');
        }

        const sheetIdentifierSystem = automationSheet.identifier?.find(
          (identifier) => identifier.use === 'official'
        )?.system;
        if (!sheetIdentifierSystem) {
          throw new Error('Invalid automation sheet, identifier not found');
        }
        const questionnaireId = sheetIdentifierSystem.split('/').pop();
        if (!questionnaireId) {
          set(
            (s) => {
              s.loading = false;
            },
            false,
            // @ts-expect-error as typing is not accepting the extras
            { type: 'care-plan-automation-form/loadAutomationSheet/start', automationSheetId }
          );
          throw new Error('Invalid automation sheet, identifier not found');
        }

        set(
          (s) => {
            s.selectedAssessmentId = questionnaireId;
            s.selectedAutomationSheetId = automationSheetId;
            s.enabled = automationSheet.status === 'current';
            s.description = automationSheet.note?.[0]?.text || '';
            s.rules = [];
          },
          false,
          // @ts-expect-error as typing is not accepting the extras
          { type: 'care-plan-automation-form/loadAutomationSheet', automationSheetId }
        );

        const savedRules = await fhirClient.findAll<PlanDefinition>('PlanDefinition', {
          identifier: fhirSystem.automation.carePlan.withId(automationSheetId).forCode(''),
        });

        // add saved rules
        for (const { resource: rule } of savedRules.entry || []) {
          if (!rule) {
            continue;
          }

          const ruleSettings = JSON.parse(
            rule.action?.[0]?.condition?.[0]?.expression?.expression || 'null'
          ) as Pick<AutomationRule, 'question' | 'questionType' | 'operator' | 'value'> & {
            operatorType: AutomationRule['type'];
          };

          const intervention = rule.action?.[0].id;
          const goal = rule.goal?.[0].id;
          const opportunity = rule.action?.[0].code?.[0]?.coding?.[0]?.code;
          const timeframe = rule.action?.[0].timingDuration?.value;
          const timeframeUnit = rule.action?.[0].timingDuration?.unit;
          const priority = rule.goal?.[0].priority?.id;

          if (
            !ruleSettings ||
            !intervention ||
            !goal ||
            !opportunity ||
            !opportunity ||
            !timeframe ||
            !timeframeUnit ||
            !priority
          ) {
            console.warn(`Warning invalid rule: ${rule.id}`, {
              ruleSettings,
              intervention,
              goal,
              opportunity,
              timeframe,
              timeframeUnit,
              priority,
            });
            continue;
          }

          get().addRule(
            {
              linkId: ruleSettings.question,
              type: ruleSettings.questionType,
            },
            {
              id: rule.id!,
              operator: ruleSettings.operator,
              value: ruleSettings.value,
              type: ruleSettings.operatorType,
              enabled: rule.status === 'active',
              status: 'actual',
              result: {
                intervention,
                goal,
                opportunity,
                timeframe,
                timeframeUnit,
                priority,
              },
            }
          );
        }

        get().validate();
        set(
          (s) => {
            s.loading = false;
          },
          false,
          // @ts-expect-error as typing is not accepting the extras
          { type: 'care-plan-automation-form/loadAutomationSheet/start', automationSheetId }
        );
      },

      addRule: (question, rule) => {
        set(
          (s) => {
            const questionSettings = QUESTION_TYPES[question.type];
            if (!questionSettings) return;

            // get first operator as default
            const operatorSettings = questionSettings.operators[0];
            if (!operatorSettings) return;

            const newRule: AutomationRule = {
              id: uuidv4(),
              enabled: true,
              question: question.linkId,
              questionType: question.type,
              operator: operatorSettings.value,
              type: operatorSettings.type || 'null',
              value: null,
              status: 'added',
              ...rule,
              result: {
                opportunity: '',
                goal: '',
                intervention: '',
                timeframe: 1,
                timeframeUnit: fhirDuration[0]?.value,
                priority: OpportunityPriorities[0]?.value,
                ...rule?.result,
              },
            };

            if (!s.rules.find((r) => r.id === newRule.id)) {
              s.rules.push(newRule);
            }
          },
          false,
          // @ts-expect-error as typing is not accepting the extras
          { type: 'care-plan-automation-form/addRule', question, rule }
        );

        get().validate();
      },

      getRule: (ruleId) => get().rules.find((r) => r.id === ruleId),

      updateRuleProperty: (ruleId, property, value) => {
        set(
          (s) => {
            const rule = s.rules.find((r) => r.id === ruleId);
            if (!rule) return;

            if (rule.status === 'actual') {
              rule.status = 'modified';
            }

            rule[property] = value;
          },
          false,
          // @ts-expect-error as typing is not accepting the extras
          { type: 'care-plan-automation-form/updateRuleProperty', ruleId, property, value }
        );

        get().validate();
      },

      updateRuleResultProperty: (ruleId, property, value) => {
        set(
          (s) => {
            const rule = s.rules.find((r) => r.id === ruleId);
            if (!rule) return;

            if (rule.status === 'actual') {
              rule.status = 'modified';
            }

            rule.result[property] = value;
          },
          false,
          // @ts-expect-error as typing is not accepting the extras
          { type: 'care-plan-automation-form/updateRuleResultProperty', ruleId, property, value }
        );
        get().validate();
      },

      setRuleOperator: (ruleId, operator) => {
        const rule = get().getRule(ruleId);
        if (!rule) return;

        const currentType = rule.type;
        const questionSettings = QUESTION_TYPES[rule.questionType];
        if (!questionSettings) return;

        const operatorSettings = questionSettings.operators.find((o) => o.value === operator);
        if (!operatorSettings) return;

        const newType = operatorSettings.type;
        get().updateRuleProperty(ruleId, 'operator', operator);
        get().updateRuleProperty(ruleId, 'type', newType || 'null');

        if (currentType !== newType) {
          get().updateRuleProperty(
            ruleId,
            'value',
            newType === 'boolean'
              ? false
              : newType === 'number'
              ? 0
              : newType === 'string'
              ? ''
              : null
          );
        }
      },

      setRuleValue: (ruleId, value) => get().updateRuleProperty(ruleId, 'value', value),

      setRuleResultOpportunity: (ruleId, opportunityId) =>
        get().updateRuleResultProperty(ruleId, 'opportunity', opportunityId),

      setRuleResultGoal: (ruleId, goalId) => get().updateRuleResultProperty(ruleId, 'goal', goalId),

      setRuleResultIntervention: (ruleId, interventionId) =>
        get().updateRuleResultProperty(ruleId, 'intervention', interventionId),

      setRuleResultTimeframe: (ruleId, timeframe) =>
        get().updateRuleResultProperty(ruleId, 'timeframe', timeframe),

      setRuleResultTimeframeUnit: (ruleId, timeUnit) =>
        get().updateRuleResultProperty(ruleId, 'timeframeUnit', timeUnit),

      setRuleResultPriority: (ruleId, priority) =>
        get().updateRuleResultProperty(ruleId, 'priority', priority),

      setRuleEnabled: (ruleId, enabled) => get().updateRuleProperty(ruleId, 'enabled', enabled),

      setRuleStatus: (ruleId, status) => get().updateRuleProperty(ruleId, 'status', status),

      deleteRule: (ruleId) => {
        set(
          (s) => {
            const rule = s.rules.find((r) => r.id === ruleId);
            if (!rule) return;

            if (rule.status !== 'added') {
              rule.status = 'deleted';
              return;
            }

            s.rules = s.rules.filter((r) => r.id !== ruleId);
          },
          false,
          // @ts-expect-error as typing is not accepting the extras
          { type: 'care-plan-automation-form/deleteRule', ruleId }
        );

        get().validate();
      },

      clearRules: () => {
        set(
          (s) => {
            for (const rule of s.rules) {
              get().deleteRule(rule.id);
            }
          },
          false,
          { type: 'care-plan-automation-form/clearRules' }
        );

        get().validate();
      },

      reset: () => {
        set(getDefaultState, false, {
          type: 'care-plan-automation-form/reset',
        });
        clearRecoverableChanges('care-plan-automation-form');
      },

      validate: () => {
        const state = get();

        set(
          (draft) => {
            draft.errors = [];

            if (!state.selectedAssessmentId) {
              draft.errors.push(['Please select an assessment', 'assessment']);
            }

            if (!state.selectedAutomationSheetId) {
              draft.errors.push(['Please select an automation sheet', 'automationSheet']);
            }

            if (state.rules.filter((r) => r.status !== 'deleted').length === 0) {
              draft.errors.push(['Please add at least one rule', 'rules']);
            }

            state.rules.forEach((rule) => {
              if (!rule.result.opportunity) {
                draft.errors.push([
                  'Please select an opportunity',
                  `rule(${rule.question}:${rule.id})-opportunity`,
                ]);
              }

              if (!rule.result.goal) {
                draft.errors.push([
                  'Please select a goal',
                  `rule(${rule.question}:${rule.id})-goal`,
                ]);
              }

              if (!rule.result.intervention) {
                draft.errors.push([
                  'Please select an intervention',
                  `rule(${rule.question}:${rule.id})-intervention`,
                ]);
              }

              if (!rule.result.timeframe) {
                draft.errors.push([
                  'Please select a timeframe',
                  `rule(${rule.question}:${rule.id})-timeframe`,
                ]);
              }

              if (!rule.result.timeframeUnit) {
                draft.errors.push([
                  'Please select a timeframe unit',
                  `rule(${rule.question}:${rule.id})-timeframeUnit`,
                ]);
              }
            });

            draft.valid = draft.errors.length === 0;
          },
          false,
          {
            type: 'care-plan-automation-form/validate',
            // @ts-expect-error as typing is not accepting the extras
            state,
          }
        );

        return get().valid;
      },

      errorFor: (field) => get().errors.find((e) => e[1] === field)?.[0] || null,

      save: async () => {
        const state = get();
        if (!state.validate()) return false;

        set(
          (s) => {
            s.loading = true;
          },
          false,
          // @ts-expect-error as typing is not accepting the extras
          { type: 'care-plan-automation-form/save/start', loading: true }
        );

        const transaction: Bundle = {
          resourceType: 'Bundle',
          type: 'transaction',
          entry: [],
        };

        const automationSheet = await fhirClient.findById<List>(
          'List',
          state.selectedAutomationSheetId
        );
        const opportunitiesAndConditions = await api.carePlan.findMappingsConditionOpportunity();
        const goalsAndGoalGroups = await api.carePlan.findMappingsGoalGroupGoal();
        const interventions = await api.carePlan.findInterventions();

        for (const rule of state.rules) {
          if (rule.status === 'deleted') {
            continue;
          }

          const intervention = interventions.find(
            (i) => i.id.toString() === rule.result.intervention
          );
          const opportunity = opportunitiesAndConditions.find(
            (o) => `${o.id}` === rule.result.opportunity
          );
          const goal = goalsAndGoalGroups.find((g) => `${g.id}` === rule.result.goal);

          const priority = OpportunityPriorities.find((p) => p.value === rule.result.priority);

          if (!intervention || !opportunity || !goal || !priority) {
            console.error({
              action: 'Invalid rule on save',
              rule,
              intervention,
              opportunity,
              goal,
              opportunitiesAndConditions,
              goalsAndGoalGroups,
              interventions,
              priority,
              OpportunityPriorities,
            });
            set(
              (s) => {
                s.loading = false;
              },
              false,
              // @ts-expect-error as typing is not accepting the extras
              { type: 'care-plan-automation-form/save/errored', loading: false }
            );
            return false;
          }

          const plan: PlanDefinition = {
            resourceType: 'PlanDefinition',
            id: rule.id,
            status: 'active',
            extension: [
              {
                url: fhirSystem.extension.PlanDefinition.createsAppointmentAlert.asString(),
                valueBoolean: intervention.createsAppointmentAlert,
              },
            ],
            identifier: [
              {
                use: 'official',
                system: fhirSystem.automation.carePlan.withId(automationSheet.id!).asString(),
                value: rule.id,
              },
              {
                use: 'secondary',
                system: fhirSystem.automation.carePlan.rule.asString(),
                value: rule.id,
              },
            ],
            type: {
              coding: [
                {
                  code: 'clinical-protocol',
                },
              ],
            },
            purpose: opportunity.opportunity.name,
            action: [
              {
                id: `${intervention.id}`,
                title: intervention.name,
                code: [
                  {
                    coding: [
                      {
                        code: `${opportunity.id}`,
                      },
                    ],
                  },
                ],
                condition: [
                  {
                    id: `${opportunity.opportunity.id}`,
                    kind: 'applicability',
                    expression: {
                      id: `${opportunity.condition.id}`,
                      description: opportunity.condition.name,
                      language: 'application/careflow-automation-rule+json',
                      expression: JSON.stringify({
                        question: rule.question,
                        questionType: rule.questionType,
                        operator: rule.operator,
                        operatorType: rule.type,
                        value: rule.value,
                      }),
                    },
                  },
                ],
                timingDuration: {
                  value: rule.result.timeframe,
                  unit: rule.result.timeframeUnit,
                },
              },
            ],
            goal: [
              {
                id: `${goal.id}`,
                category: {
                  id: `${goal.goalGroup.id}`,
                  text: goal.goalGroup.name,
                },
                description: {
                  id: `${goal.goal.id}`,
                  text: goal.goal.name,
                },
                priority: {
                  id: `${priority.value}`,
                  text: priority.label,
                },
              },
            ],
          };

          transaction.entry!.push({
            resource: plan,
            request: {
              method: 'PUT',
              url: `PlanDefinition/${rule.id}`,
            },
          });

          get().setRuleStatus(rule.id, 'actual');
        }

        try {
          await fhirClient.createOne<Bundle>('', transaction);

          const [, patches] = produceWithPatches(automationSheet, (draft) => {
            draft.status = state.enabled ? 'current' : 'retired';
            draft.note = [
              {
                text: state.description,
              },
            ];
            draft.entry = state.rules
              .filter((rule) => rule.status !== 'deleted')
              .map((rule) => ({
                item: {
                  reference: `PlanDefinition/${rule.id}`,
                },
              }));
          });

          await fhirClient.patchOne(
            'List',
            automationSheet.id!,
            patches.map((patch) => ({
              ...patch,
              path: '/' + patch.path.join('/'),
            }))
          );

          await Promise.all(
            state.rules.map(async (rule) => {
              if (rule.status === 'deleted') {
                await fhirClient.removeOne('PlanDefinition', rule.id);

                set(
                  (s) => {
                    s.rules = s.rules.filter((r) => r.id !== rule.id);
                  },
                  false,
                  // @ts-expect-error as typing is not accepting the extras
                  { type: 'care-plan-automation-form/save/rule-deleted', ruleId: rule.id }
                );
              }
            })
          );
        } catch (error) {
          console.error(error);
          set(
            (s) => {
              s.loading = false;
            },
            false,
            // @ts-expect-error as typing is not accepting the extras
            { type: 'care-plan-automation-form/save/errored', loading: false }
          );
          return false;
        }

        set(
          (s) => {
            s.loading = false;
          },
          false,
          // @ts-expect-error as typing is not accepting the extras
          { type: 'care-plan-automation-form/save/end', loading: false }
        );

        return true;
      },
    })),
    {
      name: 'care-plan-automation-form',
    }
  )
);

//-----------------------------------------------------------------------

export function useCarePlanAutomationFormRule(ruleId: string) {
  const rule = useCarePlanAutomationFormStore((s) => {
    const r = s.getRule(ruleId);

    return {
      questionType: r?.questionType,
      type: r?.type,
      value: r?.value,
      operator: r?.operator,
      opportunity: r?.result.opportunity,
      goal: r?.result.goal,
      intervention: r?.result.intervention,
      timeframe: r?.result.timeframe,
      timeframeUnit: r?.result.timeframeUnit,
      priority: r?.result.priority,
      enabled: r?.enabled,
      status: r?.status,

      setOperator: s.setRuleOperator,
      setValue: s.setRuleValue,
      setOpportunity: s.setRuleResultOpportunity,
      setGoal: s.setRuleResultGoal,
      setIntervention: s.setRuleResultIntervention,
      setTimeframe: s.setRuleResultTimeframe,
      setTimeframeUnit: s.setRuleResultTimeframeUnit,
      setPriority: s.setRuleResultPriority,
      setEnabled: s.setRuleEnabled,
      setStatus: s.setRuleStatus,
      deleteRule: s.deleteRule,
    };
  }, shallow);

  return {
    ...rule,
    setOperator: (operator: string) => rule.setOperator(ruleId, operator),
    setValue: (value: unknown) => rule.setValue(ruleId, value),
    setOpportunity: (opportunityId: string) => rule.setOpportunity(ruleId, opportunityId),
    setGoal: (goalId: string) => rule.setGoal(ruleId, goalId),
    setIntervention: (interventionId: string) => rule.setIntervention(ruleId, interventionId),
    setTimeframe: (timeframe: number) => rule.setTimeframe(ruleId, timeframe),
    setTimeframeUnit: (timeUnit: string) => rule.setTimeframeUnit(ruleId, timeUnit),
    setEnabled: (enabled: boolean) => rule.setEnabled(ruleId, enabled),
    setStatus: (status: AutomationRuleStatus) => rule.setStatus(ruleId, status),
    deleteRule: () => rule.deleteRule(ruleId),
  };
}

export function useCarePlanAutomationFormError(field: string) {
  return useCarePlanAutomationFormStore((s) => s.errorFor(field));
}

//-----------------------------------------------------------------------

export interface AutomationRule {
  id: string;
  question: string;
  questionType: WrappedQuestionnaireItem['type'];
  operator: string;
  type: OperatorType;
  value: unknown;
  result: AutomationRuleResult;
  enabled: boolean;
  status: AutomationRuleStatus;
}

export interface AutomationRuleResult {
  opportunity: string;
  goal: string;
  intervention: string;
  timeframe: number;
  timeframeUnit: string;
  priority: string;
}

export type AutomationRuleStatus = 'added' | 'deleted' | 'actual' | 'modified';

const useCarePlanAutomationFormStore = Object.assign(useStore, {
  getDefaultState,
});

export default useCarePlanAutomationFormStore;
