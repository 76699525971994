// @ts-nocheck
import {
  Box,
} from '@mui/material';
import NotesSummary from 'src/sections/careflow/care-plan/notes';
import { checkSection, SectionEnum } from '../constants';
import {
  DiagnosisSummary,
  LabSummary,
  MedicationsSummary,
  ServicesSummary,
  VitalsSummary,
} from 'src/sections/careflow/health-record/patient-info-header';
import { CarePlanIndex } from 'src/sections/careflow/care-plan/care-plan';

type PatientWidgetsPropsTypes = {
  section?: SectionEnum;
  sections?: SectionEnum[];
  patient: any;
};

const EXPANDED_WIDTH = '100%';
const SUMMARY_WIDTH = '50%';

const PatientWidgets = ({
  section,
  sections,
  patient,
}: PatientWidgetsPropsTypes) => { 
  const checkEqualSection = (currentSection: SectionEnum): boolean => section === currentSection;

  const allCondition = section === 'all';
  const diagnosisCondition = checkEqualSection(SectionEnum.diagnosis);
  const medicationsCondition = checkEqualSection(SectionEnum.medications);
  const vitalsCondition = checkEqualSection(SectionEnum.vitals);
  const labsCondition = checkEqualSection(SectionEnum.labs);
  const notesCondition = checkEqualSection(SectionEnum.notes);
  const servicesCondition = checkEqualSection(SectionEnum.services);
  const carePlanCondition = checkEqualSection(SectionEnum.carePlan);
  
  return (
    <Box flexDirection="row" display="flex" flexWrap="wrap">
      {checkSection(SectionEnum.diagnosis, sections ?? []) &&
      (diagnosisCondition || allCondition) ? (
        <Box sx={{ p: 1, width: diagnosisCondition ? EXPANDED_WIDTH : SUMMARY_WIDTH }}>
          <DiagnosisSummary patient={patient} onExpand={() => {}} />
        </Box>
      ) : (
        <></>
      )}
      {checkSection(SectionEnum.medications, sections ?? []) &&
      (medicationsCondition || allCondition) ? (
        <Box sx={{ p: 1, width: medicationsCondition ? EXPANDED_WIDTH : SUMMARY_WIDTH }}>
          <MedicationsSummary
            isExpanded={medicationsCondition}
            patient={patient}
            onExpand={() => {}}
          />
        </Box>
      ) : (
        <></>
      )}
      {checkSection(SectionEnum.vitals, sections ?? []) && (vitalsCondition || allCondition) ? (
        <Box sx={{ p: 1, width: vitalsCondition ? EXPANDED_WIDTH : SUMMARY_WIDTH }}>
          <VitalsSummary patient={patient} onExpand={() => {}} />
        </Box>
      ) : (
        <></>
      )}
      {checkSection(SectionEnum.labs, sections ?? []) && (labsCondition || allCondition) ? (
        <Box sx={{ p: 1, width: labsCondition ? EXPANDED_WIDTH : SUMMARY_WIDTH }}>
          <LabSummary isExpanded={labsCondition} patient={patient} onExpand={() => {}} />
        </Box>
      ) : (
        <></>
      )}
      {checkSection(SectionEnum.notes, sections ?? []) && (notesCondition || allCondition) ? (
        <Box sx={{ p: 1, width: notesCondition ? EXPANDED_WIDTH : SUMMARY_WIDTH }}>
          <NotesSummary isExpanded={notesCondition} patient={patient} onExpand={() => {}} />
        </Box>
      ) : (
        <></>
      )}
      {checkSection(SectionEnum.services, sections ?? []) && (servicesCondition || allCondition) ? (
        <Box sx={{ p: 1, width: servicesCondition ? EXPANDED_WIDTH : SUMMARY_WIDTH }}>
          <ServicesSummary patient={patient} onExpand={() => {}} />
        </Box>
      ) : (
        <></>
      )}

      {carePlanCondition ? (
        <Box sx={{ p: 1, width: carePlanCondition ? EXPANDED_WIDTH : SUMMARY_WIDTH }}>
          <CarePlanIndex
            isExpanded={carePlanCondition}
            patient={patient}
            onExpand={() => {}}
          />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  )
}

export default PatientWidgets;
