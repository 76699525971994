import { useQuery } from '@tanstack/react-query';
import api from 'src/services/api';
import {
  Box,
  Stack,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemButton,
  MenuItem,
} from '@mui/material';
import fhirDuration from 'src/fhir/codes/duration';
import { FormProvider, RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import RHFAutocomplete from 'src/components/hook-form/RHFAutocomplete';
import { TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import Label, { LabelColor } from 'src/components/Label';
import Iconify from 'src/components/Iconify';
import {
  CarePlanStatus,
  useCarePlanStatuses,
  useCreateCarePlanStatusMutation,
  useDeleteCarePlanStatusMutation,
  useUpdateCarePlanStatusMutation,
} from 'src/services/api/care-plan/statuses';
import { useDialogStore } from 'src/stores/dialog';
import { useSystemSetting, useUpdateSystemSettingMutation } from 'src/services/api/settings/system';

type FormValues = {
  shortTermBefore: number;
  shortTermBeforeUnit: typeof fhirDuration[number];
};

const schema = Yup.object().shape({
  shortTermBefore: Yup.number().nullable().required('Required'),
  shortTermBeforeUnit: Yup.object().nullable().required('Required'),
});

const resolver = yupResolver(schema);

export default function CarePlanDurationTable() {
  return (
    <Stack gap={2} py={3}>
      <TermRangesForm />
      <StatusForm />
    </Stack>
  );
}

/**********************************************************************
 * Long/Short Term Ranges
 **********************************************************************/

function TermRangesForm() {
  const [editting, setEditting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { data: carePlanAutomationSettings, refetch } = useSystemSetting('care-plan-automation');
  const { mutateAsync: updateSystemSetting } =
    useUpdateSystemSettingMutation('care-plan-automation');

  useEffect(() => {
    refetch();
  });

  const defaultValues = useMemo(
    () => ({
      shortTermBefore: carePlanAutomationSettings?.shortTermBefore || 1,
      shortTermBeforeUnit: carePlanAutomationSettings?.shortTermBeforeUnit || fhirDuration[0],
    }),
    [carePlanAutomationSettings]
  );

  const methods = useForm({
    resolver,
    defaultValues,
  });

  useEffect(() => {
    if (carePlanAutomationSettings) {
      methods.reset(carePlanAutomationSettings);
    }
  }, [carePlanAutomationSettings]);

  async function submit(data: FormValues) {
    try {
      await updateSystemSetting(data);
      methods.reset();
      enqueueSnackbar('Saved', { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to save', { variant: 'error' });
    }
    await refetch();
    setEditting(false);
  }

  function cancel() {
    methods.reset();
    setEditting(false);
  }

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(submit)}>
      <Box sx={{ px: 3 }}>
        <Stack
          gap={3}
          sx={{
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <Stack gap={1}>
            <Typography variant="subtitle1">Long/Short Term Ranges</Typography>

            <Stack direction="row" gap={1}>
              <RHFTextField
                name="shortTermBefore"
                label="Short term up to"
                type="number"
                size="small"
                disabled={!editting}
              />
              <RHFAutocomplete
                name="shortTermBeforeUnit"
                label="Duration"
                options={fhirDuration}
                size="small"
                isOptionEqualToValue={(option, value) => option.value === value.value}
                disabled={!editting}
              />
              <Stack justifyContent="center" alignItems="center">
                -
              </Stack>
              <TextField
                label="Long term"
                disabled
                fullWidth
                size="small"
                value={`> ${methods.watch('shortTermBefore')} ${
                  methods.watch('shortTermBeforeUnit')?.label?.toLowerCase() || 'day'
                }s`}
              />
              <Stack
                display="inline-flex"
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                {editting ? (
                  <>
                    <IconButton type="submit" size="small">
                      <Iconify icon="mdi:check" />
                    </IconButton>

                    <IconButton onClick={cancel} type="button" size="small">
                      <Iconify icon="mdi:close" />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setEditting(true);
                    }}
                    type="button"
                    size="small"
                  >
                    <Iconify icon="mdi:pencil" />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </FormProvider>
  );
}

/**********************************************************************
 * Status Form
 **********************************************************************/

const LabelColors = [
  {
    label: 'Success',
    value: 'success',
  },
  {
    label: 'Info',
    value: 'info',
  },
  {
    label: 'Error',
    value: 'error',
  },
  {
    label: 'Warning',
    value: 'warning',
  },
] as {
  label: string;
  value: LabelColor;
}[];

interface FormValue {
  uuid?: string;
  name: string;
  color: LabelColor;
  abbreviation: string;
  treatAsInactive: boolean;
}

function StatusForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { data: carePlanStatuses = [] } = useCarePlanStatuses();
  const { mutateAsync: createStatus } = useCreateCarePlanStatusMutation();
  const { mutateAsync: updateStatus } = useUpdateCarePlanStatusMutation();
  const { mutateAsync: deleteStatus } = useDeleteCarePlanStatusMutation();
  const [editing, setEditing] = useState('');
  const { confirmDialog } = useDialogStore();
  const { data: carePlanSettings } = useSystemSetting('care-plan-automation');
  const { mutateAsync: updateCarePlanDefaultStatus } =
    useUpdateSystemSettingMutation('care-plan-automation');

  function editNew() {
    setEditing('new');
  }

  function editExisting(uuid: string) {
    setEditing(uuid);
  }

  function cancelEdit() {
    setEditing('');
  }

  async function save(data: FormValue) {
    if (data.uuid === 'new') {
      delete data.uuid;
      await createStatus(data);
      if (!carePlanSettings.defaultStatus) {
        await updateCarePlanDefaultStatus({
          ...carePlanSettings,
          defaultStatus: data.uuid,
        });
      }

      enqueueSnackbar('Created succesfully', { variant: 'success' });
    } else {
      await updateStatus(data);
      enqueueSnackbar('Updated succesfully', { variant: 'success' });
    }

    setEditing('');
  }

  async function deleteExisting(uuid: string) {
    const confirmed = await confirmDialog({
      title: 'Delete Status',
      description: 'Are you sure you want to delete this status?',
    });

    if (confirmed) {
      await deleteStatus(uuid);
      enqueueSnackbar('Deleted succesfully', { variant: 'success' });
      if (carePlanStatuses.length && uuid === carePlanSettings.defaultStatus) {
        await updateCarePlanDefaultStatus({
          ...carePlanSettings,
          defaultStatus: carePlanStatuses[0].uuid,
        });
      }
    }
  }

  async function markAsDefault(uuid: string) {
    await updateCarePlanDefaultStatus({
      ...carePlanSettings,
      defaultStatus: uuid,
    });
    enqueueSnackbar('Primary status updated succesfully', { variant: 'success' });
  }

  return (
    <Box sx={{ px: 3 }}>
      <Stack
        gap={3}
        sx={{
          width: {
            xs: '100%',
            md: '50%',
          },
        }}
      >
        <Stack gap={1}>
          <Typography variant="subtitle1">Status</Typography>

          <List dense>
            {carePlanStatuses.map((item) =>
              editing === item.uuid ? (
                <StatusFormRow currentStatus={item} onSave={save} onCancel={cancelEdit} />
              ) : (
                <ListItem
                  key={item.uuid}
                  sx={{
                    '& .mark-as-default': {
                      display: 'none',
                    },
                    '&:hover .mark-as-default': {
                      display: 'inline-block',
                    },
                  }}
                  secondaryAction={
                    <>
                      {carePlanSettings?.defaultStatus === item.uuid ? (
                        <Label color="success">Default</Label>
                      ) : (
                        <Button
                          className="mark-as-default"
                          variant="outlined"
                          size="small"
                          onClick={() => markAsDefault(item.uuid)}
                        >
                          Mark as default
                        </Button>
                      )}

                      <IconButton size="small" onClick={() => editExisting(item.uuid)}>
                        <Iconify icon="mdi:pencil" />
                      </IconButton>
                      <IconButton size="small" onClick={() => deleteExisting(item.uuid)}>
                        <Iconify icon="mdi:delete" />
                      </IconButton>
                    </>
                  }
                >
                  <ListItemText>
                    <Label color={item.color}>{item.name}</Label>
                    <Label color={item.color} sx={{ ml: 1 }}>
                      {item.abbreviation || item.name.replaceAll(' ', '').slice(0, 4) || 'Unkn'}
                    </Label>
                  </ListItemText>
                </ListItem>
              )
            )}

            {editing === 'new' ? (
              <StatusFormRow onSave={save} onCancel={cancelEdit} />
            ) : (
              <ListItem>
                <ListItemButton onClick={editNew}>
                  <IconButton size="small" disableRipple>
                    <Iconify icon="mdi:plus" />
                  </IconButton>

                  <ListItemText primary="Create new status" />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Stack>
      </Stack>
    </Box>
  );
}

interface StatusFormRowProps {
  currentStatus?: CarePlanStatus;
  onSave?: (data: FormValue) => void;
  onCancel?: () => void;
}

function StatusFormRow({ currentStatus, onSave, onCancel }: StatusFormRowProps) {
  const methods = useForm({
    defaultValues: {
      uuid: currentStatus?.uuid ?? 'new',
      name: currentStatus?.name ?? '',
      abbreviation: currentStatus?.abbreviation ?? '',
      color: currentStatus?.color ?? LabelColors[0].value,
      treatAsInactive: currentStatus?.treatAsInactive ?? false,
    } as FormValue,
  });

  return (
    <FormProvider methods={methods}>
      <ListItem>
        <Stack direction="row" gap={1} width="100%">
          <RHFTextField name="name" label="Name" fullWidth size="small" variant="outlined" />
          <RHFTextField
            name="abbreviation"
            label="Abbreviation"
            inputProps={{ maxLength: 5 }}
            fullWidth
            size="small"
            variant="outlined"
          />

          <RHFSelect name="color" size="small" fullWidth label="Color" variant="outlined">
            {LabelColors.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                <Label color={item.value} fontSize={20}>
                  {item.label}
                </Label>
              </MenuItem>
            ))}
          </RHFSelect>

          <RHFCheckbox name="treatAsInactive" label="Treat as Inactive" style={{ flexShrink: 0 }} />

          <Stack display="inline-flex" direction="row" alignItems="center" justifyContent="center">
            <IconButton
              size="small"
              onClick={() => {
                onSave?.(methods.getValues());
              }}
            >
              <Iconify icon="mdi:check" />
            </IconButton>
            <IconButton size="small" onClick={onCancel}>
              <Iconify icon="mdi:close" />
            </IconButton>
          </Stack>
        </Stack>
      </ListItem>
    </FormProvider>
  );
}
