import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Button, TextField, MenuItem } from '@mui/material';
// components
import { FormProvider, RHFAutocomplete, RHFSelect } from '../../../../components/hook-form';
import {
  CarePlanGoalToGoalGroup,
  CarePlanOpportunity,
  goalToGoalGroupStatus,
} from 'src/_mock/settings/_careplan-automation';
import api from 'src/services/api';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// ----------------------------------------------------------------------

type FormValue = {
  opportunityId: CarePlanOpportunity['id'];
  goalId: CarePlanGoalToGoalGroup['goal']['id'];
  goalGroupId: CarePlanGoalToGoalGroup['goalGroup']['id'];
  status: CarePlanGoalToGoalGroup['status'];
  interventions: { label: string; value: number; }[];
};

type GoalsToGoalGroupsEditFormProps = {
  currentMapping: CarePlanGoalToGoalGroup;
};

const schema = Yup.object().shape({
  opportunityId: Yup.string().required('An Opportunity is required'),
  goalId: Yup.string().required('A Goal is required'),
  goalGroupId: Yup.string().required('A Goal Group is required'),
  status: Yup.string().oneOf(['active', 'inactive']).required('Status is required'),
  interventions: Yup.array().required('Interventions are required'),
});

const resolver = yupResolver(schema);

export default function GoalsToGoalGroupsEditForm({
  currentMapping,
}: GoalsToGoalGroupsEditFormProps) {
  const navigate = useNavigate();
  
  const { data: opportunities = [] } = useQuery(
    ['opportunities'],
    async () =>
      (await api.carePlan.findOpportunities({
        status: '',
      })) as any[]
  );

  const { data: goalGroups = [] } = useQuery(
    ['goal-groups'],
    async () =>
      (await api.carePlan.findGoalGroups({
        status: '',
      })) as any[]
  );

  const { data: goals = [] } = useQuery(
    ['goals'],
    async () =>
      (await api.carePlan.findGoals({
        status: '',
      })) as any[]
  );

  const { data: interventionsResponse = [], refetch } = useQuery(
    ['interventions'],
    async () =>
      (await api.carePlan.findInterventions({
        status: '',
      })) as any[]
  );

  const interventionsOptions = useMemo(() => interventionsResponse.map((intervention) => ({
    label: intervention.name,
    value: intervention.id,
  })), [interventionsResponse]);

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () =>
      ({
        opportunityId: currentMapping?.opportunity?.uuid || '',
        goalId: currentMapping?.goal.uuid || '',
        goalGroupId: currentMapping?.goalGroup.uuid || '',
        status: currentMapping?.status || goalToGoalGroupStatus.active,
        interventions: currentMapping?.interventions?.split('*|*').map((intervention: string) => {
          const interventionSplitted = intervention.split(':')
          return {
            label: interventionSplitted[0],
            value: parseInt(interventionSplitted[1])
          }
        }) ?? [],
      } as FormValue),
    [currentMapping]
  );

  const methods = useForm({ resolver, defaultValues });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (currentMapping) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMapping]);

  const onSubmit = async (data: FormValue) => {
    try {
      await api.carePlan.updateMappingsGoalGroupGoal({
        ...data,
        interventions: methods.getValues('interventions')?.map((intervention) => `${intervention.label}:${intervention.value}`).join('*|*'),
        uuid: currentMapping.uuid,
      });

      reset();
      enqueueSnackbar('Update success!');
      navigate('..');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(
          error.response?.data?.message?.[0] || error?.message || 'Something went wrong!',
          {
            variant: 'error',
          }
        );
      }

      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 3 }}>
        <Stack
          gap={3}
          sx={{
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <RHFSelect name="opportunityId" label="Opportunity">
            {opportunities?.map((opportunity) => (
              <MenuItem key={opportunity.uuid} value={opportunity.uuid}>
                {opportunity.name}
              </MenuItem>
            ))}
          </RHFSelect>
          
          <RHFSelect name="goalGroupId" label="Goal Group">
            {goalGroups.map((goalGroup) => (
              <MenuItem key={goalGroup.uuid} value={goalGroup.uuid}>
                {goalGroup.name}
              </MenuItem>
            ))}
          </RHFSelect>

          <RHFSelect name="goalId" label="Goal">
            {goals.map((goal) => (
              <MenuItem key={goal.uuid} value={goal.uuid}>
                {goal.name}
              </MenuItem>
            ))}
          </RHFSelect>

          <RHFSelect name="status" label="Status">
            {Object.entries(goalToGoalGroupStatus).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </RHFSelect>

          <RHFAutocomplete
            name="interventions"
            disablePortal
            multiple
            disableCloseOnSelect
            options={interventionsOptions}
            renderInput={(params: any) => (
              <TextField
                {...params}
                fullWidth
                label="Interventions"
                margin="dense"
                size="small"
              />
            )}
          />
        </Stack>

        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }} gap={2}>
          <Button variant="text" color="inherit" component={RouterLink} to="..">
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Save Changes
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
