import { CircularProgress, Typography, Card, Stack, Box } from '@mui/material';
import { Communication } from 'src/@nicheaim/fhir-base/mappings/Communication';
import DataTable, { DataTableProps } from 'src/components/table/DataTable';
import { ConcreteActivity } from 'src/sections/careflow/tasks-activities/model/Activity';
import ComponentWithLoading from './ComponentWithLoading';

const colors = {
  pastDueColor: '#db2b39',
  dueTodayColor: '#ffa400',
  futureTaskColor: '#98ce00',
};

const styles = {
  summaryCounterContainer: {
    padding: '10px',
    height: '30px',
    borderRadius: '5px',
    paddingTop: '5px',
    margin: '5px',
  },
  pastDueColor: {
    backgroundColor: `${colors.pastDueColor}6e`,
    textAlign: 'center',
  },
  dueTodayColor: {
    backgroundColor: `${colors.dueTodayColor}6e`,
  },
  futureTaskColor: {
    backgroundColor: `${colors.futureTaskColor}6e`,
  },
};

const loadingStyles = {
  height: '30px',
  margin: '5px',
  padding: '10px',
  paddingTop: '5px',
  width: '30px',
};

type SummaryTableProps = {
  title?: string;
  actionBar?: any;
  rowContextMenu?: (activity: any) => any;
  isFetching?: boolean;
  isLoading?: boolean;
  summary?: {
    pastDueCount: number;
    dueTodayCount: number;
    futureTaskCount: number;
  } | null;
} & Pick<
  DataTableProps,
  'clickeable' | 'onItemClick' | 'data' | 'columns' | 'onMoreRows' | 'fetchMore'
>;

function SummaryTable({
  actionBar,
  title,
  isFetching = false,
  isLoading,
  summary = null,
  ...dataTableProps
}: SummaryTableProps) {
  return (
    <Card sx={{ height: `calc(100vh - 200px)`, display: 'flex', flexDirection: 'column' }}>
      <TitleBar summary={summary} title={title} actionBar={actionBar} isFetching={isFetching} />
      <ComponentWithLoading
        isLoading={isLoading!}
        Component={<DataTable {...dataTableProps} />}
      />
    </Card>
  );
}

export function TitleBar({ title, actionBar, summary, isFetching }: any) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
      <Typography variant="subtitle1">{title}</Typography>
      {summary && (
        <Stack direction="row" alignItems="center" justifyContent="space-around">
          <Box sx={[styles.summaryCounterContainer, styles.pastDueColor]}>
            <Typography color={colors.pastDueColor}>{summary.pastDueCount}</Typography>
          </Box>
          <Box sx={[styles.summaryCounterContainer, styles.dueTodayColor]}>
            <Typography color={colors.dueTodayColor}>{summary.dueTodayCount}</Typography>
          </Box>
          <Box sx={[styles.summaryCounterContainer, styles.futureTaskColor]}>
            <Typography color={colors.futureTaskColor}>{summary.futureTaskCount}</Typography>
          </Box>
          <Box sx={loadingStyles}>{isFetching && <CircularProgress size={15} />}</Box>
        </Stack>
      )}
      <Box>{actionBar}</Box>
    </Stack>
  );
}

export default SummaryTable;
