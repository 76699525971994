// @ts-nocheck
import { useEffect, useMemo } from 'react';
import useObjectState from '../../../../../hooks/useObjectState';
import {
  Divider,
  Box,
  Grid,
  Typography,
  FormControl,
  TextField,
  Alert,
  Checkbox,
  Select,
  MenuItem,
} from '@mui/material';
import API from 'src/services/api';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import SessionType from '../../types/SessionType';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useAddEntityRequestStates from 'src/hooks/useAddEntityRequestStates';
import moment from 'moment';
import PatientSearchDropdown from 'src/sections/careflow/common/PatientSearchDropdown';

export type AddSessionModalPropsType = {
  onSaveCallback: (session: SessionType) => void;
  onCloseModal: () => void;
  session?: SessionType | null;
};

export type DropDownItem = {
  label: string;
  value: string | number;
  isRequired?: boolean;
} | null;

type AddGroupSessionStateType = {
  peopleItems: DropDownItem[];
  patients: any[];
  date: string;
  status: string;
  startHour: string;
  endHour: string;
};

const defaulState: AddGroupSessionStateType = {
  peopleItems: [],
  patients: [],
  date: moment().format('YYYY-MM-DD'),
  startHour: moment().format('HH:mm'),
  endHour: moment().add(1, 'day').format('HH:mm'),
  status: 'incomplete',
};

const getDefaultState = (session?: SessionType | null): AddGroupSessionStateType => {
  if (session) {
    return {
      peopleItems: session?.sessionParticipants.map((participant) => ({
        label: participant.participant.patient.fullName,
        value: participant.participant.patientId,
      })),
      date: moment(session.date).format('YYYY-MM-DD'),
      startHour: moment(session.start).format('HH:mm'),
      endHour: moment(session.end).format('HH:mm'),
      status: session.status,
      patients: [],
    };
  }

  return defaulState;
};

const AddSessionModal = ({ onSaveCallback, onCloseModal, session }: AddSessionModalPropsType) => {
  const [state, updateState] = useObjectState<AddGroupSessionStateType>(getDefaultState(session));
  const { status, date, startHour, endHour, peopleItems, patients } = state;
  const peopleOptions = useMemo(
    () =>
      patients.map((patient) => ({
        label: patient.name,
        value: patient.id,
      })),
    [patients]
  );
  const [{ isLoading, error, success }, { setIsLoading, setError, setSuccess }] =
    useAddEntityRequestStates();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const onClearAllPress = () => {
    updateState({
      ...defaulState,
      patients,
    });
  };

  const validateRequiredFields = () => {
    if (peopleItems.length === 0) {
      return false;
    }

    return true;
  };

  const onSavePress = async () => {
    setIsLoading(true);
    if (!validateRequiredFields()) {
      setError('Please, fill all required fields');
      setSuccess(null);
      setIsLoading(false);
      return;
    }

    if (!session) return;

    API.groupSessions
      .updateSession(session?.id, {
        patientIds: peopleItems.map((p) => p?.value as number),
        status,
        date: moment(date).toISOString(),
        start: moment(date + ' ' + startHour).toISOString(),
        end: moment(date + ' ' + endHour).toISOString(),
      })
      .then((responseSession: SessionType) => {
        console.log({
          responseSession,
        });
        setError(null);
        setSuccess('session participants successfully created.');
        completeSessionSave(responseSession);
      })
      .catch(() => incompleteSessionSave());
  };

  const completeSessionSave = (session: SessionType) => {
    setTimeout(() => {
      onSaveCallback(session);
      onCloseModal();
    }, 800);
  };

  const incompleteSessionSave = () => {
    setError('Error saving session participants. Please try again');
    setSuccess(null);
    setIsLoading(false);
  };

  useEffect(() => {
    API.patients
      .getCcmPatients({
        pagination: {
          take: 100,
          page: 0,
        },
        search: null,
        status: null,
        staffFhirId: null,
      })
      .then((response: any) => {
        updateState({
          patients: response.patients,
        });
      });
  }, []);

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: 0,
        width: 500,
        height: '100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll',
      }}
    >
      <form>
        <Box
          sx={{
            my: 3,
            position: 'fixed',
            width: '500px',
            backgroundColor: 'white',
            opacity: 1,
            zIndex: 9,
            top: '-25px',
            left: 0,
            pl: 4,
            pr: 4,
          }}
        >
          <Box sx={{ my: 3 }}>
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Edit session
              </Typography>
            </Grid>
          </Box>
          <Divider />
        </Box>
        <Box sx={{ marginTop: '80px' }}>
          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <PatientSearchDropdown
                multiple
                value={peopleItems}
                onChange={(_: any, value: any | null, reason, changes) => {
                  if (peopleItems.find(i => i?.value === changes?.option.value)) {
                    updateState({ peopleItems: value.filter((i: DropDownItem) => i?.value !== changes?.option.value) });
                    return;
                  }
                  updateState({ peopleItems: value })
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={peopleItems.find((p) => p?.value === option?.value) ? true : false}
                    />
                    {option?.label}
                  </li>
                )}
                label="Participants"
                variant='standard'
              />
            </FormControl>
          </Grid>
          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <TextField
                label="Start date"
                value={date}
                onChange={(e) => updateState({ date: e.target.value })}
                type="date"
                variant="standard"
              />
            </FormControl>
          </Grid>
          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <TextField
                label="Start hour"
                value={startHour}
                onChange={(e) => updateState({ startHour: e.target.value })}
                type="time"
                variant="standard"
              />
            </FormControl>
          </Grid>
          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <TextField
                label="End hour"
                value={endHour}
                onChange={(e) => updateState({ endHour: e.target.value })}
                type="time"
                variant="standard"
              />
            </FormControl>
          </Grid>
          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <Select value={status} onChange={(e) => updateState({ status: e.target.value })}>
                <MenuItem value={'completed'}>Completed</MenuItem>
                <MenuItem value={'incomplete'}>Incomplete</MenuItem>
                <MenuItem value={'cancelled'}>Cancelled</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {error && (
            <Alert style={{ marginTop: '20px' }} severity="error">
              {error}
            </Alert>
          )}
          {success && (
            <Alert style={{ marginTop: '20px' }} severity="success">
              {success}
            </Alert>
          )}
          <ActionButtons
            isLoading={isLoading}
            onLeftButtonPress={onClearAllPress}
            rightButtonTitle="Save"
            onRightButtonPress={onSavePress}
          />
        </Box>
      </form>
    </Box>
  );
};

export default AddSessionModal;
