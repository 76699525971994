import {
  Card,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
  debounce,
} from '@mui/material';
import { CaseRow } from '.';
import Page from 'src/components/Page';
import { TableToolbar } from '../common';
import { useCallback, useEffect, useState } from 'react';
import useTable from '../../../hooks/useTable';
import useSettings from 'src/hooks/useSettings';
import { caseService } from 'src/crs/case/service';
import { CaseManager } from 'src/@types/crs/referral';
import { TableHeadCustom } from 'src/components/table';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { PaginateQuery } from 'src/api/pagination/dtos';
import { TABLE_HEAD_CASES_LIST } from '../common/table-head';
import { PaginatedCaseDto } from 'src/crs/case/service/CaseService';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { caseDtoToCaseManager } from '../common/common-utils';
import NoResultsTableRow from '../common/NoResultsTableRow';
import LoadingTableRow from '../common/LoadingTableRow';
import { wait } from 'src/utils/timers';

export default function CaseList() {
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage, setPage } = useTable({
    defaultRowsPerPage: 5,
    defaultOrderBy: 'id',
    initialIndex: 1,
    defaultCurrentPage: 1,
  });

  const { themeStretch } = useSettings();

  const [tableData, setTableData] = useState<PaginatedCaseDto | undefined | null>(null);
  const [search, setSearch] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);

  const getPaginateQuery = (page: number, rowsPerPage: number, search: string) => {
    const paginateQuery: PaginateQuery = { page: page, limit: rowsPerPage };
    if (search) {
      paginateQuery.search = search;
    }
    return paginateQuery;
  };

  const fetchCaseList = async (page: number, rowsPerPage: number, search: string) => {
    setIsLoading(true);
    await wait();
    const cases = await caseService.getAll(getPaginateQuery(page, rowsPerPage, search));
    setIsLoading(false);
    if (cases) {
      setTableData(cases);
    }
  };

  const getCaseList = useCallback(debounce(fetchCaseList, 600), []);

  useEffect(() => {
    getCaseList(page, rowsPerPage, search);
  }, [page, rowsPerPage, search]);

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(event, newPage + 1);
  };

  const handleSearch = (query: string) => {
    setSearch(query);
    setPage(1);
  };

  const dataFormatted = (): CaseManager[] => {
    const data = tableData?.data ? tableData.data.map((r) => caseDtoToCaseManager(r)) : [];
    return data;
  };

  const rows = dataFormatted();

  return (
    <Page title="Case List">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          title=""
          heading=""
          links={[
            { name: 'Home', href: PATH_DASHBOARD.root },
            { name: 'CRS' },
            { name: 'Case List', href: PATH_DASHBOARD.crs.referral },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Case List</Typography>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <Stack direction="row" sx={{ py: 2, px: 2 }}>
                <Grid xs={6}>
                  <TableToolbar filterMrn={search} onFilterMrn={handleSearch} />
                </Grid>
                <Grid xs={6}>
                  <Stack justifyContent="flex-end">
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      count={tableData ? tableData.meta.totalItems : 0}
                      rowsPerPage={rowsPerPage}
                      page={page - 1}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={onChangeRowsPerPage}
                      sx={{ borderTop: 0 }}
                    />
                  </Stack>
                </Grid>
              </Stack>
              <TableContainer>
                <Table>
                  <TableHeadCustom headLabel={TABLE_HEAD_CASES_LIST} />
                  <TableBody>
                    {!isLoading ? (
                      rows?.length ? (
                        rows.map((row) => <CaseRow key={row.id} row={row} />)
                      ) : (
                        <NoResultsTableRow
                          colSpan={TABLE_HEAD_CASES_LIST.length}
                          text="No Cases Found."
                        />
                      )
                    ) : (
                      <LoadingTableRow colSpan={TABLE_HEAD_CASES_LIST.length} />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
