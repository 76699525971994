// @ts-nocheck
import { Alert, Box, Divider, Grid, MenuItem, Typography } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { Communication } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import PatientCard from 'src/sections/careflow/common/PatientCard';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import { FormProvider, RHFSelect } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { useNoteTypes } from 'src/services/api/ccm-notes/hooks';
import RHFQuill from 'src/components/hook-form/RHFQuill';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import api from 'src/services/api';
import { Note } from 'src/services/api/ccm-notes';
import { useQueryClient } from '@tanstack/react-query';

export type AddCommunicationPropsType = {
  onSaveCallback: (communication: Communication) => void;
  onCancelPress: () => void;
  communication?: Note | null;
  patient?: any;
  showPatientCard?: Boolean;
  referralId?: string;
  useReferralNote?: Boolean;
  enableNoneTypes: Boolean;
};

type FormValue = {
  type: string;
  note: string;
};

const getSchema = (enableNoneTypes = true) => {
  if (enableNoneTypes) {
    return yup.object().shape({
      type: yup.string().required('You must select a note type'),
      note: yup.string().required("Note can't be empty"),
    });
  } else {
    return yup.object().shape({
      note: yup.string().required("Note can't be empty"),
    });
  }
};

const AddCommunication = ({
  patient,
  onSaveCallback,
  onCancelPress,
  communication,
  showPatientCard = true,
  referralId = '',
  useReferralNote = false,
  enableNoneTypes = true,
}: AddCommunicationPropsType) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { data: noteTypes = [], refetch, isLoading: isLoadingNoteTypes } = useNoteTypes();

  const defaultValues = useMemo(
    () => ({
      type:
        (communication
          ? communication.source.concept?.coding?.[0]?.code
          : noteTypes[0]?.valueCode) ?? '',
      note: communication?.text ?? '',
    }),
    [communication, noteTypes]
  );
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(getSchema(enableNoneTypes)),
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communication, isLoadingNoteTypes]);

  const saveCommunication = async (data: FormValue) => {
    setIsLoading(true);

    if (communication?.id) {
      try {
        const updatedCommunication = await api.ccmNotes.updateNote(
          patient.fhirId,
          communication.id,
          data
        );

        queryClient.refetchQueries({
          predicate(query: any) {
            return ['ccm-notes'].includes(
              query.queryKey[0] as string
            );
          },
        });

        setError(null);
        setSuccess('Note successfully updated');
        setTimeout(() => {
          onSaveCallback(updatedCommunication);
        }, 1000);
      } catch {
        setIsLoading(false);
        setError('Error updating the note. Please try again');
        setSuccess(null);
      }
    } else {
      try {
        const newCommunications = useReferralNote
          ? await api.referrals.createCcmReferralNote(data.note, referralId, patient.fhirId)
          : await api.ccmNotes.createNote(patient.fhirId, data);
        setError(null);

        queryClient.refetchQueries({
          predicate(query: any) {
            return ['ccm-notes'].includes(
              query.queryKey[0] as string
            );
          },
        });
        
        setSuccess('Note successfully created');
        setTimeout(() => {
          onSaveCallback(newCommunications);
        }, 1000);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setError('Error creating the note. Please try again');
        setSuccess(null);
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(saveCommunication)} debug>
      <Box>
        {showPatientCard ? (
          <Box sx={{ my: 3 }}>
            <Grid item>
              {patient && patient?.fhirId && (
                <PatientCard id={patient?.fhirId} />
              )}
            </Grid>
          </Box>
        ) : null}

        <Box sx={{ my: 3 }}>
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {communication?.id ? 'Update note' : 'Add a new note'}
            </Typography>
          </Grid>
        </Box>
        <Divider />
        {enableNoneTypes ? (
          <Grid item style={{ marginTop: '15px' }}>
            <RHFSelect
              name="type"
              label="Type"
              fullWidth
              variant="standard"
              disabled={!!communication}
            >
              {noteTypes.map((option) => (
                <MenuItem key={option.valueCode} value={option.valueCode}>
                  {option.valueDisplayName}
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>
        ) : null}

        <Grid item style={{ marginTop: '15px' }}>
          <RHFQuill name="note" theme="snow" />
        </Grid>
        {error && (
          <Alert style={{ marginTop: '20px' }} severity="error">
            {error}
          </Alert>
        )}
        {success && (
          <Alert style={{ marginTop: '20px' }} severity="success">
            {success}
          </Alert>
        )}
        <ActionButtons
          isLoading={isLoading}
          onLeftButtonPress={onCancelPress}
          onRightButtonPress={handleSubmit(saveCommunication)}
        />
      </Box>
    </FormProvider>
  );
};

export default AddCommunication;
