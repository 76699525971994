import { useEffect, useState } from 'react';
import { Typography, Box, TypographyProps } from '@mui/material';
import client from 'src/services/api/_client';
import axios from 'axios';
import moment from 'moment';

export type VersioningInfoValue = string | null;

export interface VersioningInfo {
  shortHash: VersioningInfoValue;
  hash: VersioningInfoValue;
  tag: VersioningInfoValue;
  branch: VersioningInfoValue;
  date: VersioningInfoValue;
  message: VersioningInfoValue;
}

interface VersioningInfoFooterState {
  client: VersioningInfo;
  server: VersioningInfo;
}

const VersioningFooter = () => {
  const [versioningAppInfo, setVersioningAppInfo] = useState<VersioningInfoFooterState | null>(
    null
  );

  useEffect(() => {
    const getAppVersioningInfo = async () => {
      try {
        const { data: serverInfo } = await client.get<VersioningInfo>('/versioning');
        const { data: clientInfo } = await axios.get<VersioningInfo>('/app-versioning-info.json');
        const clientCommitDate = moment(clientInfo.date, 'YYYY-MM-DD HH:mm:ss Z')
          .utc()
          .toISOString();
        setVersioningAppInfo({
          client: { ...clientInfo, date: clientCommitDate },
          server: serverInfo,
        });
      } catch (error) {
        console.log('Error retrieving app version info', error);
      }
    };
    getAppVersioningInfo();
  }, []);

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        height: 50,
        backgroundColor: '#fff',
        width: '100%',
        right: 0,
        borderTopStyle: 'solid',
        borderTopColor: '#efefef',
        borderTopWidth: 1,
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        sx={{ height: '100%', width: '100%' }}
        px={3}
      >
        <Box display={'flex'} flexDirection={'column'}>
          <VersioningText type="client" versioningInfo={versioningAppInfo?.client} />
          <VersioningText type="server" versioningInfo={versioningAppInfo?.server} />
        </Box>
      </Box>
    </Box>
  );
};

interface VersioningTextProps {
  type: 'client' | 'server';
  versioningInfo: VersioningInfo | undefined;
}

const textVariant = 'xxs' as TypographyProps['variant'];
const VersioningText = ({ type, versioningInfo }: VersioningTextProps) => (
  <>
    {!!versioningInfo && (
      <Typography variant={textVariant} sx={{ textAlign: 'right' }}>
        <Typography variant={textVariant} sx={{ textTransform: 'capitalize', color: 'red' }}>
          {type}:{' '}
        </Typography>
        <Typography variant={textVariant} sx={{ color: 'green' }}>
          {versioningInfo?.hash} ({versioningInfo?.shortHash}){' '}
        </Typography>
        <Typography variant={textVariant} sx={{ color: 'black' }}>
          {formatCommitDate(versioningInfo?.date)} UTC
        </Typography>
      </Typography>
    )}
  </>
);

const formatCommitDate = (date: string | null | undefined) => {
  const dateMoment = moment(date ?? null);
  if (!dateMoment.isValid()) return 'Date Unavailable';
  return dateMoment.format('dddd, MMMM D, YYYY h:mm A');
};

export default VersioningFooter;
