import moment from 'moment';
import { AlertSeverity, SeverityStatusData } from '../../../components/SeverityStatus';

export interface DueDateData {
  timeFactor: 'day' | 'month' | 'year' | null;
  timeDiff: number;
  isPastDue: boolean;
  formattedDueDate: string;
  severityStatus: SeverityStatusData;
}

export const getSeverityDueDateData = (endDate: moment.Moment): DueDateData => {
  try {
    if (!endDate?.isValid?.()) throw new Error('Invalid Date');
    const duration = moment.duration(moment(endDate).startOf('day').diff(moment().startOf('day')));
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    let [timeFactor, timeDiff]: [string, number] = years
      ? ['year', years]
      : months
      ? ['month', months]
      : ['day', days];
    const originalTimeFactor = timeFactor;
    const isDueToday = timeDiff === 0;
    const isPastDue = timeDiff < 0;
    timeDiff = Math.abs(timeDiff);
    if (timeDiff > 1) timeFactor += 's';
    const formattedDueDate = `${endDate.format('MMM DD, YYYY')} (${
      isDueToday
        ? 'Today'
        : isPastDue
        ? `${timeDiff} ${timeFactor} ago`
        : `in ${timeDiff} ${timeFactor}`
    })`;
    return {
      isPastDue,
      timeFactor: originalTimeFactor as DueDateData['timeFactor'],
      timeDiff,
      formattedDueDate,
      severityStatus: {
        message: formattedDueDate,
        severity: isPastDue
          ? AlertSeverity.ERROR
          : isDueToday
          ? AlertSeverity.WARNING
          : AlertSeverity.SUCCESS,
      },
    };
  } catch (error) {
    return {
      isPastDue: false,
      timeFactor: null,
      timeDiff: 0,
      formattedDueDate: 'N/A',
      severityStatus: {
        message: 'N/A',
        severity: AlertSeverity.WARNING,
      },
    };
  }
};
