import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Stack,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';
import { TableHeadCustom } from 'src/components/table';
import { TABLE_HEAD_DISPOSITION_REFERRAL_CASE } from 'src/sections/crs/common/table-head';

const DispositionDialog = ({
  isOpen,
  handlerDisposition,
  handlerIsOpenDispositionDialog,
  message,
  data,
  type,
}: any) => {
  const [openDialog, setOpenDialog] = useState(isOpen);

  useEffect(() => {
    setOpenDialog(isOpen);
  }, [isOpen]);

  const handleCloseForm = () => {
    setOpenDialog(false);
    handlerIsOpenDispositionDialog(false);
  };

  const handleOnSaveDisposition = (e: any) => {
    handlerDisposition(e);
    setTimeout(() => {
      handleCloseForm();
    }, 1500);
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseForm}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="confirm-dialog-title">Disposition</DialogTitle>
      <Card sx={{ m: 2 }}>
        {type === 'CASE' ? (
          <DialogContent>{message}</DialogContent>
        ) : (
          <>
            <Stack direction="row">
              <Typography sx={{ my: 2, ml: 2, fontSize: `0.75rem` }}>{message}</Typography>
              {/* <Button 
                variant="contained" 
                size="small" 
                sx={{ height: 20, mt: 1.7, ml: 1 }}
                onClick={() => handleOnSaveDisposition("new")}
              >
                new case
              </Button> */}
            </Stack>

            {/* <TableContainer sx={{ mt: 2, maxHeight: 300 }}>
              <Table size="small" stickyHeader>
                <TableHeadCustom headLabel={TABLE_HEAD_DISPOSITION_REFERRAL_CASE} />
                <TableBody>
                  {!isEmpty(data?.data) ? (data?.data?.map((row) => (
                      <TableRow > 
                        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
                          {row?.fhirId}
                        </TableCell>
                        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem` }}>
                          {row?.createdOn && (format(new Date(row.createdOn), 'MMM dd, yyyy hh:mm'))}
                        </TableCell>
                        <TableCell style={{ cursor: 'pointer' }} sx={{ fontSize: `0.75rem`, textTransform: 'capitalize' }}>
                          {row?.workflowOwnedBy && row.workflowOwnedBy.split('@')[0].replace('.', ' ')}
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleOnSaveDisposition(row)}>
                            <Iconify 
                              sx={{ width: 18, height: 18}} 
                              icon={'ic:outline-open-in-new'}/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ):(
                    <TableCell colSpan={TABLE_HEAD_DISPOSITION_REFERRAL_CASE?.length}>
                      <Typography variant="body2" align="center">No rows</Typography>
                    </TableCell>
                  )}
                </TableBody>
                </Table>
            </TableContainer> */}
          </>
        )}

        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={handleCloseForm} variant="contained">
            Cancel
          </Button>
          {type === 'CASE' && (
            <Button
              onClick={(e) => {
                handleOnSaveDisposition(e);
              }}
              variant="contained"
              autoFocus
            >
              Submit
            </Button>
          )}
          {/* {type === "CASE" && ( */}
          <Button
            onClick={(e) => {

              if (type === 'CASE') {
                handleOnSaveDisposition(e);
                return;
              }
              handleOnSaveDisposition(data?.data?.[0] ?? 'new');
            }}
            variant="contained"
            autoFocus
          >
            Submit
          </Button>
          {/* )} */}
        </DialogActions>
      </Card>
    </Dialog>
  );
};

export default DispositionDialog;
