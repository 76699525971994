import Cookies from "js-cookie";
import ApiClient from "src/api/clients/axios_client";
import CaseService, { CaseApiClient } from "./CaseService";
import { ApiConfiguration } from "src/api/clients/api_client";

const accessToken = Cookies.get('careflowToken');
const baseUrl = process.env.REACT_APP_HOST_API;

const apiConfig = new ApiConfiguration();
apiConfig.accessToken = accessToken;
apiConfig.baseUrl = baseUrl ? baseUrl : '';

const caseApiClient = new CaseApiClient(new ApiClient(apiConfig));
export const caseService = new CaseService(caseApiClient);