import GroupSessionType from "../../types/GroupSessionType";
import { Location } from 'src/@nicheaim/fhir-base/mappings/Location';

export type AddGroupSessionModalPropsType = {
  onSaveCallback: (groupSession: GroupSessionType) => void;
  onCloseModal: () => void;
  groupSession?: GroupSessionType | null;
};

export type DropDownItem = {
  label: string;
  value: string | number;
  isRequired?: boolean;
} | null;

export type AddGroupSessionStateType = {
  sessionName: string;
  description: string;
  groupSessionItem: DropDownItem,
  startDate: string;
  endDate: string;
  schedules: ScheduleItemType[];
  assessmentItems: DropDownItem[];
  locationItems: DropDownItem[];
  peopleItems: DropDownItem[];
  patients: any[];
  locations: Location[] | null;
}

export enum ScheduleItemDayEnum {
  ALL = 'every day',
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THRUSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURRDAY = 'saturday',
  SUNDAY = 'sunday'
}

export enum FrequencyEnum {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  LAST = 'last',
  CUSTOM = 'custom',
}

export const frequencyLabels = {
  [FrequencyEnum.DAILY]: 'Every day',
  [FrequencyEnum.WEEKLY]: 'Every :day',
  [FrequencyEnum.MONTHLY]: 'Every first :day of the month',
  [FrequencyEnum.LAST]: 'Every last :day of the month',
  [FrequencyEnum.CUSTOM]: 'Custom',

}

export type ScheduleItemType = {
  id: number | null;
  tempId?: number;
  day: ScheduleItemDayEnum,
  date: string;
  startHour: string;
  endHour: string;
  frequency: FrequencyEnum;
  disabledFrequency: boolean;
  deletedOn?: Date | null;
}

export const scheduleDayOptions = [
  ScheduleItemDayEnum.ALL,
  ScheduleItemDayEnum.SUNDAY,
  ScheduleItemDayEnum.MONDAY,
  ScheduleItemDayEnum.TUESDAY,
  ScheduleItemDayEnum.WEDNESDAY,
  ScheduleItemDayEnum.THRUSDAY,
  ScheduleItemDayEnum.FRIDAY,
  ScheduleItemDayEnum.SATURRDAY,
]

export const frequencyOptions = [
  FrequencyEnum.DAILY,
  FrequencyEnum.WEEKLY,
  FrequencyEnum.MONTHLY,
  FrequencyEnum.LAST,
  FrequencyEnum.CUSTOM,
]