import axiosFhirInstance from "./axiosFhirInstance";
import { EncounterRepository } from "../../../ports/out/repositories";
import {
  AxiosFHIRRepository,
  TokenSource
} from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir";
import {
  Encounter
} from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";

export class FHIREncounterRepository extends AxiosFHIRRepository<Encounter> implements EncounterRepository {
  constructor(tokenSource?: TokenSource) {
    super('Encounter', axiosFhirInstance, tokenSource);
  }
}