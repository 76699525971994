import moment from 'moment';
import { getFhirIdFromEntity } from 'src/utils/fhir';

export enum REFERRALS_LIST_TYPES {
  ALL = 'ALL',
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export enum REFERRALS_LIST_STATUS {
  ALL = 'ALL',
  REQUESTED = 'draft',
  PENDING = 'on-hold',
  ACCEPTED = 'active',
}

export enum REFERRALS_LIST_PERSON {
  ANYONE = 'ANYONE',
  ME = 'ME',
}

export enum ReferralStatuses {
  draft = 'Requested',
  active = 'Accepted',
  completed = 'Closed',
  'on-hold' = 'Pending',
  revoked = 'Rejected',
  'entered-in-error' = 'Cancelled',
}

const defaultStatus = Object.entries(ReferralStatuses).find(current => current[1] === ReferralStatuses.draft);

export interface ReferralStateI {
  date: string;
  startHour: string;
  endHour: string;
  name: any;
  healthPlan: string | null;
  program: string | null;
  programItem: string | null;
  referredFromItem: any;
  referredFromFreeText: string | boolean;
  referredToFreeText: string | boolean;
  referredToItem: any;
  orgnization: string | null;
  organizaionItem: any;
  service: string | null;
  serviceTypeItem: any;
  serviceTypeItems: Array<any>,
  status: string;
  statusItem: any;
  users: Array<any>,
  assignedToItem: any;
  file: string | null;
  selectedFile: string | null;
  note: string | null;
  isLoading: boolean,
  error: string | null;
  errors: Array<any>,
  success: string | null;
  patientItem: any;
}

export const defaultStateFieldsValues = (loggedUser: any, referral: any | null): ReferralStateI => ({
  date: referral?.date
    ? moment(referral?.date)?.format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD'),
  startHour: referral?.start ? moment(referral?.start)?.format('HH:mm') : moment().format('HH:mm'),
  endHour: referral?.start ? moment(referral?.start)?.format('HH:mm') : moment().format('HH:mm'),
  name: referral?.name ?? '',
  healthPlan: null,
  program: null,
  programItem: null,
  referredFromItem: referral?.referredFromPractitionerId
    ? {
      label: referral?.referredFromPractitionerName,
      value: `Practitioner/${referral?.referredFromPractitionerId}`,
    }
    : referral?.referredFromOrganizationId
      ? {
        label: referral?.referredFromOrganizationName,
        value: `Organization/${referral?.referredFromOrganizationId}`,
      }
      : referral?.referredFromDisplay
        ? referral?.referredFromDisplay
        : null,
  referredFromFreeText: referral?.referredFromPractitionerId
    ? false
    : referral?.referredFromOrganizationId
      ? false
      : referral?.referredFromDisplay
        ? true
        : false,
  referredToFreeText: referral?.referredToPractitionerId
    ? false
    : referral?.referredToOrganizationId
      ? false
      : referral?.referredToDisplay
        ? true
        : false,
  referredToItem: referral?.referredToPractitionerId
    ? {
      label: referral?.referredToPractitionerName,
      value: `Practitioner/${referral?.referredToPractitionerId}`,
    }
    : referral?.referredToOrganizationId
      ? {
        label: referral?.referredToOrganizationName,
        value: `Organization/${referral?.referredToOrganizationId}`,
      }
      : referral?.referredToDisplay
        ? referral?.referredToDisplay
        : null,
  orgnization: null,
  organizaionItem: null,
  service: referral?.serviceCode ?? null,
  serviceTypeItem: null,
  serviceTypeItems: [],
  status: referral?.status ?? defaultStatus?.[0],
  statusItem:
    referral?.status && ReferralStatuses[referral?.status]
      ? {
        label: ReferralStatuses[referral?.status],
        value: referral?.status,
      }
      : {
        label: defaultStatus?.[1],
        value: defaultStatus?.[0]
      },
  assignedToItem: referral?.assignedToName
    ? {
      label: referral?.assignedToName,
      value: referral?.assignedToName,
    }
    : {
      label: loggedUser.name,
      value: loggedUser.email,
    },
  file: null,
  selectedFile: null,
  note: null as string | null,
  isLoading: false,
  error: null,
  errors: [],
  success: null,
  patientItem: referral ? { label: referral?.patientName, value: referral?.patientId } : null,
  users: []
});

export const REFERRAL_CREATE_ERROR_MESSAGE = 'Error saving the referral. Please try again';
