import { getReferenceResourceId } from 'src/sections/crs/helpers/common';
import { createWrapper } from '../../fhir-react/base';

export const PractitionerRoleWrapper = createWrapper('PractitionerRole', (practitionerRole) => ({
  practitionerName:
    practitionerRole?.practitioner?.display ?? practitionerRole?.practitioner?.reference,
  organizationName: practitionerRole?.organization?.display ?? '',
  organizationId: practitionerRole?.organization?.reference ?? '',
  roleName: practitionerRole?.code?.[0]?.coding?.[0]?.display,
  roleId: practitionerRole?.code?.[0]?.coding?.[0]?.code,
  getIdentifier: () => ({
    identifier: practitionerRole?.identifier?.[0]?.value ?? null,
    type: practitionerRole?.identifier?.[0]?.system ?? null,
  }),
}));

export type WrappedPractitionerRole = ReturnType<typeof PractitionerRoleWrapper>;
