export enum ActivityLegends {
  NEW = 'NEW',
  READY = 'READY',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',

  PAST_DUE = 'PAST_DUE',
  DUE_TODAY = 'DUE_TODAY',
  FUTURE_TASK = 'FUTURE_TASK',
}

export function isActivityLegend(value: string): value is ActivityLegends {
  return Object.values(ActivityLegends).includes(value as ActivityLegends);
}

export interface ActivityStatusDescription {
  status: ActivityLegends;
  description: string;
}

export const ACTIVITY_LEGENDS: Map<ActivityLegends, ActivityStatusDescription> = new Map([
  [
    ActivityLegends.NEW,
    {
      status: ActivityLegends.NEW,
      description: 'New',
    },
  ],
  [
    ActivityLegends.READY,
    {
      status: ActivityLegends.READY,
      description: 'Ready',
    },
  ],
  [
    ActivityLegends.PAST_DUE,
    {
      status: ActivityLegends.PAST_DUE,
      description: 'Past Due',
    },
  ],
  [
    ActivityLegends.COMPLETED,
    {
      status: ActivityLegends.COMPLETED,
      description: 'Completed',
    },
  ],
  [
    ActivityLegends.DUE_TODAY,
    {
      status: ActivityLegends.DUE_TODAY,
      description: 'Due today',
    },
  ],
  [
    ActivityLegends.FUTURE_TASK,
    {
      status: ActivityLegends.FUTURE_TASK,
      description: 'Future task',
    },
  ],
  [
    ActivityLegends.CANCELLED,
    {
      status: ActivityLegends.CANCELLED,
      description: 'Cancelled',
    },
  ],
]);
