import { useCallback, useState } from 'react';
import { ActionBar, EntityContextMenu, SummaryTable } from 'src/sections/careflow/common';
import DiagnosisSummaryForm from './DiagnosisSummaryForm';
import { useEncounters } from 'src/@nicheaim/fhir-react';
import { parseShortFormat } from 'src/utils/formatTime';
import { Encounter } from 'src/@nicheaim/fhir-base/mappings/Encounter';
import AddModal from 'src/sections/careflow/common/AddModal';

interface DiagnosisSummaryProps {
  isExpanded?: boolean;
  onExpand?: (index: number) => void;
  patient: any;
  showButtons?: boolean;
}

const DiagnosisSummary = ({ isExpanded = false, onExpand, patient, showButtons = true }: DiagnosisSummaryProps) => {
  const [showAddDiagnosisForm, setShowAddDiagnosisForm] = useState(false);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState<any>(null);
  const [encounters, { remove }] = useEncounters({
    filter: {
      subject: `Patient/${patient?.id}`,
    },
  });
  const encountersWithDiagnosis = encounters.filter((e) => e.hasOwnProperty('diagnosis'));

  const handleAdd = () => {
    setSelectedDiagnosis(null);
    setShowAddDiagnosisForm(true)
  }

  const handleEdit = (data) => {
    setSelectedDiagnosis(data);
    setShowAddDiagnosisForm(true)
  }

  const handleDelete = useCallback(
    (row: Encounter) => {
      remove(row);
    },
    [remove]
  );

  const closeAddDiagnosisForm = () => {
    setSelectedDiagnosis(null);
    setShowAddDiagnosisForm(false)
  }

  return (
    <>
      <AddModal
        open={showAddDiagnosisForm}
        onClose={closeAddDiagnosisForm}
        Component={
          <DiagnosisSummaryForm onClose={closeAddDiagnosisForm} data={selectedDiagnosis} />
        }
      />
      <SummaryTable
        title={'Diagnosis Summary'}
        actionBar={
          showButtons ? (
            <>
              <ActionBar onAdd={handleAdd} onExpand={() => onExpand?.(0)} />
            </>
          ) : (<></>)
        }
        columns={[
          {
            header: 'Date',
            field: '$',
            render(row) {
              return row?.period?.start ? parseShortFormat(row?.period?.start) : ''
            }
          },
          {
            header: 'Diagnosis / code',
            field: '$',
            render(row) {
              return row?.diagnosis?.[0]?.use?.coding?.[0]?.display
            }
          }
        ]}
        data={encountersWithDiagnosis}
        rowContextMenu={(currentAllergy: any) => (
          showButtons ? (
            <EntityContextMenu
              entity={currentAllergy}
              items={[
                {
                  label: 'Edit',
                  icon: 'eva:edit-2-outline',
                  onPress: handleEdit,
                  rules: () => true,
                },
                {
                  label: 'Delete',
                  icon: 'bi:trash',
                  onPress: handleDelete,
                  rules: () => true,
                },
              ]}
            />
          ) : (<></>)
        )}
      />
    </>
  );
};

export default DiagnosisSummary;
