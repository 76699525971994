import { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { CaseInformation, CaseSummary } from 'src/sections/crs/case/';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import Page from 'src/components/Page';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { PatientSummary } from 'src/sections/crs/patient';
import { ComplementaryInfo, AttachmentAddForm } from 'src/sections/crs/common';
import useSettings from 'src/hooks/useSettings';
import {
  useCarePlan,
  useCareTeams,
  useCommunications,
  useDocumentReferences,
  usePatient,
  usePlanDefinitions,
  useServiceRequests,
  useTasks,
} from 'src/@nicheaim/fhir-react';
import { PatientWrapper, WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { CarePlanWrapper, WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import { useParams } from 'react-router';
import Input from '../../../../components/workflow/common/components/Input';
import SelectType from '../../../../components/workflow/common/components/Select';
import DispositionType from '../../../../components/workflow/common/components/Disposition';
import CaseWorkflowComp from '../../../../components/workflow/Workflow';
import { CommunicationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Communication';
import { DocumentReferenceWrapper } from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import agent from 'src/api/agent';
import { CaseContactAttemptResponse } from 'src/crs/case/service/CaseService';
import { caseService } from 'src/crs/case/service';
import HiddenType from '../../../../components/workflow/common/components/Hidden';
import { TaskWrapper } from 'src/@nicheaim/fhir-base/wrappers/Task';
import {
  ServiceRequestWrapper,
  WrappedServiceRequest,
} from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { ContactForm } from 'src/sections/crs/case';
import { isEmpty } from 'lodash';
import NoteAddForm from '../../common/NoteAddForm';
import GoalsByPathway from './GoalsByPathway/GoalsByPathway';
import CaseCareTeamGrid from './CaseCareTeamGrid';
import { CareTeamWrapper } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import {
  PlanDefinitionWrapper,
  WrappedPlanDefinition,
} from 'src/@nicheaim/fhir-base/wrappers/PlanDefinition';
import ButtonType from 'src/components/workflow/common/components/Button';
import useAuth from 'src/hooks/useAuth';
import _ from 'lodash';
import { UI_SCHEMA } from 'src/utils/utilities';
import TasksByPathway from './TasksByPathway';
import {
  TABLE_HEAD_ASSESSMENTS,
  TABLE_HEAD_CONTACT,
  TABLE_HEAD_NOTES,
} from '../../common/table-head';
import {
  mapAssessmentsForms,
  mapCommunicationsToNotesDisplay,
  mapContactAttemptsCases,
} from '../../common/common-utils';
import Iconify from 'src/components/Iconify';
import { TableCustom } from '../../common/TableCustom';
import { DocumentList } from '../../patient/components/patientDocuments/DocumentList';
import { referralService } from 'src/crs/referral/services';
import { AssessmentsFormsResponse } from 'src/crs/referral/services/ReferralService';
import OutboundByPathway from '../../referral/components/outboundGrid/OutboundByPathway';
import StartAssessmentsButton from '../../patient/components/StartAssessmentsButton';
import {
  CRS_ACLS,
  checkAclValidation,
  getRelatedAcls,
} from 'src/utils/permissions/permission.utils';
import RelatedReferrals from './RelatedReferrals';
import { getReferenceResourceId } from '../../helpers/common';
import useHealthCareServicesByServiceRequests from 'src/hooks/useHealthCareServicesByServiceRequests';
import { REACT_APP_OUTBOUND_REFERRAL_FEATURE_FLAG } from 'src/config';
import { CarePlan } from 'src/@nicheaim/fhir-base/mappings/CarePlan';
import { Communication } from 'src/@nicheaim/fhir-base/mappings/Communication';
import { Annotation } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import produce from 'immer';

const getIdFromReference = (reference: string): string => reference.split('/')?.[1];

const getParseColumn = (obj: any, field: any) => {
  return (
    (obj &&
      Object.keys(obj).length > 0 &&
      obj[field] &&
      typeof obj[field] === 'string' &&
      JSON.parse(obj[field])) ||
    {}
  );
};

export default function CaseDetails() {
  const user = useAuth();

  const { themeStretch } = useSettings();

  const { id: carePlanId = null } = useParams();
  const [patientId, setPatientId] = useState<string | null>(null);
  const [workflowInstance, setWorkflowInstance] = useState<any>({});
  const [allUsers, setAllUsers] = useState([]);
  const [contactAttempt, setContactAttempt] = useState<CaseContactAttemptResponse[]>([]);
  const [openNote, setOpenNote] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [assessmentsForms, setAssessmentsForms] = useState<AssessmentsFormsResponse[]>([]);

  const [
    carePlan,
    { isSuccess: isSuccessCarePlan, update: updateCarePlan, refresh: refreshCarePlan },
  ] = useCarePlan(carePlanId, { map: CarePlanWrapper });
  const [
    patient,
    { isError: isErrorPatient, isLoading: isLoadingPatient, isSuccess: isSuccessPatient },
  ] = usePatient(patientId, {
    map: PatientWrapper,
  });

  const [
    serviceRequest,
    { refresh: refreshServiceRequest, create: createServiceRequest, update: updateServiceRequest },
  ] = useServiceRequests({
    filter: { category: 'outbound_referral', 'based-on': carePlan?.id, subject: patient?.id },
    map: ServiceRequestWrapper,
  });

  const [task, { refresh: refreshTask, create: createTask, update: updateTask }] = useTasks({
    filter: { _id: carePlan?.getTaskIds().join(',') },
    map: TaskWrapper,
  });

  const [communicationsQuery, setCommunicationsQuery] = useState({});

  const [communications, { refresh: refreshCommunications, create: createCommunication }] =
    useCommunications({
      filter: communicationsQuery,
      map: CommunicationWrapper,
    });

  const [
    documentReferences,
    {
      create: createDocumentReference,
      refresh: refreshDocumentReference,
      isFetching: isDocumentsLoading,
    },
  ] = useDocumentReferences({ filter: { related: carePlan?.id }, map: DocumentReferenceWrapper });
  // Get tasks
  const [tasks, { refresh: refreshTasksEntities }] = useTasks({
    filter: {
      _id: carePlan?.getTasksIdsByOutcomeReference()?.join(',') ?? null,
    },
    autofetch: !!carePlan?.getTasksIdsByOutcomeReference()?.join(','),
    map: TaskWrapper,
  });

  const [careTeams, { refresh: refreshCareTeams }] = useCareTeams({
    map: CareTeamWrapper,
    filter: {
      _id: carePlan?.getCareTeamIds().join(','),
    },
    autofetch: !!carePlan?.getCareTeamIds().length,
  });

  const [planDefinitionsRecords] = usePlanDefinitions({
    filter: {
      status: 'active',
    },
    map: PlanDefinitionWrapper,
  });

  const planDefinitions = useMemo(
    () =>
      planDefinitionsRecords.reduce<WrappedPlanDefinition[]>(
        (distinctPlanDefinitions, planDefinition) => {
          if (
            distinctPlanDefinitions.find(
              ({ goalDescription }) => goalDescription === planDefinition.goalDescription
            )
          )
            return distinctPlanDefinitions;

          return [...distinctPlanDefinitions, planDefinition];
        },
        []
      ),
    [planDefinitionsRecords]
  );

  const [caseRecord, setCaseRecord] = useState({});
  const [workflowStage, setWorkflowStage] = useState(undefined);
  const [disposition, setDisposition] = useState(false);
  const [reject, setReject] = useState(false);
  const [externalRefreshWorkFlow, setExternalRefreshWorkFlow] = useState(false);
  const [openContactClientForm, setOpenContactClientForm] = useState(false);
  const [checklistData, setChecklistData] = useState<any>({});
  const userEmail = user?.getCurrentUser?.()?.email;

  const getInboundReferrals = useCallback(
    (carePlan: WrappedCarePlan | null, outboundReferrals: WrappedServiceRequest[]): string[] =>
      carePlan?.supportingInfo?.reduce?.<string[]>((inboundReferrals, { reference }) => {
        const referralId = getReferenceResourceId(reference ?? '');
        //if serviceRequestId is in outboundReferrals array then can't be considered as an Inbound
        if (outboundReferrals.find(({ id }) => id === referralId)) return inboundReferrals;
        if (!referralId) return inboundReferrals;
        inboundReferrals.push(referralId);
        return inboundReferrals;
      }, []) ?? [],
    []
  );

  const inboundReferralIds = useMemo(
    () => getInboundReferrals(carePlan, serviceRequest),
    [carePlan, getInboundReferrals, serviceRequest]
  );

  const { healthCareServices } = useHealthCareServicesByServiceRequests(inboundReferralIds);

  /*
   ** Workflow Item Handlers
   */
  const assignReferralItemHandler = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    const payload = {
      item: checklistItem,
      record: {
        ...caseRecord,
        ...workflowInstance,
        assigned: {
          selectedUser: more.selectedUser,
        },
      },
    };

    try {
      await agent.Workflow.assignOwner(payload);
      refreshChecklistHandler();
    } catch (err) {
      console.log('error on trying to assignReferralItemHandler');
    }
  };
  const contactAttemptsHandler = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setOpenContactClientForm(true);
    setChecklistData(checklistItem);
  };
  const handlerDisposition = async ({
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
    more,
  }: any) => {
    setDisposition(true);
  };
  const handlerReject = async (open: any) => {
    setReject(open);
  };

  const countContactClient =
    Array.isArray(contactAttempt) &&
    contactAttempt?.filter((e) => e.noteType === 'Case Note').length;

  /*
   ** Initialization Case Workflow
   */
  const CASE_NAME = 'CASE';
  const INITIAL_WORKFLOW = 'CASES_WORKFLOW';

  useEffect(() => {
    fetchAssessments(carePlanId);
    fetchCaseRecord(carePlanId);
    fetchStageFromWorkflow();
    getAllUsers();
    fetchContactAttempt(carePlanId || '');
  }, [carePlanId]);

  const caseWorkflowData = useMemo(() => {
    return {
      id: 1,
      recordId: carePlanId,
      recordReference: CASE_NAME,
      stage: {
        id: workflowStage,
      },
      caseRecord,
      workflowInstance,
    };
  }, [workflowStage, workflowInstance]);
  const WorkflowHandler = {
    ASSIGN_CASE_ITEM: {
      type: 'payload',
      handler: assignReferralItemHandler,
      data: allUsers,
    },
    CASE_CONTACT_ATTEMPT_ITEM: {
      type: 'payload',
      handler: contactAttemptsHandler,
      data: `[${countContactClient}/5]`,
    },
    setDisposition: {
      type: 'confirm',
      handler: handlerDisposition,
      data: true,
    },
    setReject: {
      type: 'confirm',
      handler: (open: boolean = true) => handlerReject(open),
      data: false,
    },
  };
  const WorkflowData = {
    disposition,
    reject,
  };
  const WorkflowComponentMapping: any = {
    Input,
    Select: SelectType,
    Hidden: HiddenType,
    Disposition: DispositionType,
    Button: ButtonType,
  };

  const fetchCaseRecord = async (id: any) => {
    const result: any = await caseService.caseApiClient.getOne(id);
    setCaseRecord(result);
  };

  const fetchStageFromWorkflow = async () => {
    const result = await agent.Workflow.getInstance(carePlanId, CASE_NAME);
    setWorkflowInstance(result);
    setWorkflowStage(result?.stage?.id);
  };
  /*
   ** End Initialization Case Workflow
   */

  useEffect(() => {
    if (carePlan && isSuccessCarePlan) {
      if (carePlan?.subject?.reference) {
        setPatientId(getIdFromReference(carePlan.subject.reference));
      }
      setCommunicationsQuery({ ...communicationsQuery, 'part-of': carePlan.id });
    }
  }, [carePlan, isSuccessCarePlan]);

  const fetchWorkflowInstance = async () => {
    const result: any = await agent.Workflow.getInstance(carePlanId, CASE_NAME);
    setWorkflowInstance(result);
  };

  const getAllUsers = async () => {
    const result = await agent.User.getAllUsers();
    setAllUsers(result);
  };

  const fetchContactAttempt = async (fhirId: string) => {
    const result: any = await caseService.caseApiClient.getAllContactAttempt(fhirId);
    setContactAttempt(result);
  };

  const fetchAssessments = async (surveyId: any) => {
    const result: any = await referralService.referralApiClient.assessments(surveyId, 'CarePlan');
    setAssessmentsForms(mapAssessmentsForms(result));
  };

  useEffect(() => {
    fetchWorkflowInstance();
    getAllUsers();
    fetchContactAttempt(carePlanId || '');
  }, [carePlanId]);

  const handleReAssingOwned = async (ownedSelected: string) => {
    try {
      const payload = {
        record: {
          ...workflowInstance,
          assigned: {
            selectedUser: ownedSelected,
          },
        },
      };

      await agent.Workflow.reAssignOwner(payload);
      fetchWorkflowInstance();
    } catch (err) {
      console.log('error on handleReAssingOwned');
    }
  };

  const handleCreateContactForm = async (data: any) => {
    let resultContactAttempt: any = {};

    const workflowInstanceData =
      workflowInstance && workflowInstance.data && typeof workflowInstance.data === 'string'
        ? JSON.parse(workflowInstance.data)
        : workflowInstance && !workflowInstance?.data
        ? {}
        : workflowInstance?.data;

    const workflowInstanceDataSchema =
      workflowInstance &&
      workflowInstance.dataSchema &&
      typeof workflowInstance.dataSchema === 'string'
        ? JSON.parse(workflowInstance.dataSchema)
        : workflowInstance && !workflowInstance?.dataSchema
        ? {}
        : workflowInstance?.dataSchema;

    const payloadData =
      workflowInstanceData && Object.keys(workflowInstanceData).length > 0
        ? _.merge(workflowInstanceData, {
            scope: {
              checklistItem: {
                ...workflowInstanceData.checklistItem,
                CASE_CONTACT_ATTEMPT_ITEM: {
                  validatedOn: new Date().toISOString(),
                  validatedBy: userEmail || '',
                  modifiedFields: {
                    ...data,
                  },
                },
              },
            },
          })
        : {
            scope: {
              checklistItem: {
                CASE_CONTACT_ATTEMPT_ITEM: {
                  validatedOn: new Date().toISOString(),
                  validatedBy: userEmail || '',
                  modifiedFields: {
                    ...data,
                  },
                },
              },
            },
          };

    const CASE_CONTACT_ATTEMPT_ITEM = UI_SCHEMA.CASE_CONTACT_ATTEMPT_ITEM;

    const payloadDataSchema =
      workflowInstanceDataSchema && Object.keys(workflowInstanceDataSchema).length > 0
        ? _.merge(workflowInstanceDataSchema, {
            type: 'object',
            properties: {
              CASE_CONTACT_ATTEMPT_ITEM,
            },
          })
        : {
            type: 'object',
            properties: {
              CASE_CONTACT_ATTEMPT_ITEM,
            },
          };

    const payloadForUpdateWorkflowinstance = {
      referenceId: carePlanId,
      referenceName: CASE_NAME,
      data: JSON.stringify(payloadData),
      dataSchema: JSON.stringify(payloadDataSchema),
    };

    const payloadForSetItemCompleted = {
      item: checklistData,
      record: {
        ...caseRecord,
        ...workflowInstance,
        isValidRequired: false,
      },
    };

    try {
      resultContactAttempt = await caseService.createContactAttempt(data);
      if (!isEmpty(resultContactAttempt)) {
        const resultMark = await agent.Checklist.markItemCompleted(payloadForSetItemCompleted);
        const updateWorkflowInstance = await agent.Workflow.updateWorkflowInstance(
          payloadForUpdateWorkflowinstance
        );
        fetchContactAttempt(carePlanId || '');
      }
    } catch (err) {
      console.log('error on handleCreateContactForm markItemCompleted', err);
    } finally {
      setChecklistData({});
      setExternalRefreshWorkFlow(true);
      console.log('externalRefreshWorkFlow in contact form', externalRefreshWorkFlow);
    }

    setExternalRefreshWorkFlow(false);

    return resultContactAttempt;
  };

  const handleCreateCommunicationFhir = async (data: any) => {
    let result: any = {};
    try {
      result = await createCommunication(data);

      const setNote = result?.map((x1: Communication) => ({
        id: `${x1.resourceType}/${x1.id}`,
        text: x1?.payload?.[0]?.contentString,
      }));
      const note = carePlan?.note ?? [];
      const setNoteCarePlan: Annotation[] = [...setNote, ...note];

      if (setNoteCarePlan && carePlan) {
        carePlan.note = setNoteCarePlan;
        handleUpdateCase(carePlan);
      }

      handleCloseAddNote();
      refreshCommunications();
    } catch (err) {
      console.log('error on handleCreateCommunicationFhir', err);
    }

    return result;
  };

  const handleUpdateCase = async (data: CarePlan) => {
    const id = data?.id;
    const isEditing = Boolean(id);

    let result: any = {};

    const { ...newObject } = carePlan;

    if (isEditing) {
      result = await updateCarePlan(
        produce(newObject!, (draft) => {
          draft.supportingInfo = data.supportingInfo;
          draft.note = data.note;
        })
      );
    }

    refreshCarePlan();

    return result;
  };

  const handleCloseContactClientForm = () => {
    setOpenContactClientForm(false);
  };

  const handleOpenNote = () => {
    setOpenNote((prev) => !prev);
  };

  const handleCloseAddNote = () => {
    setOpenNote(false);
  };

  const refreshEntities = useCallback(() => {
    refreshCarePlan();
  }, []);

  const memoizedRefreshTasks = useCallback(() => {
    refreshTasksEntities();
  }, []);

  const memoizedRefreshCareTeam = useCallback((careTeam) => {
    refreshCareTeams();
  }, []);

  return (
    <Page title="Case Details">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          title=""
          heading=""
          links={[
            { name: 'Home', href: PATH_DASHBOARD.root },
            { name: 'CRS' },
            { name: 'Case List', href: PATH_DASHBOARD.crs.case },
            { name: 'Case Details' },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Case Details</Typography>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <CaseWorkflowComp
              data={caseWorkflowData}
              refreshRecord={fetchStageFromWorkflow}
              initialWorkflow={INITIAL_WORKFLOW}
              workflowHandler={WorkflowHandler}
              workflowData={WorkflowData}
              componentMapping={WorkflowComponentMapping}
              refreshWorkFlowExternal={externalRefreshWorkFlow}
            />
          </Grid>

          <Grid item xs={12}>
            <Card>
              <Typography sx={{ m: 4 }}>Case Summary</Typography>
              <CaseSummary tasks={tasks} serviceRequest={serviceRequest} carePlan={carePlan} />
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Typography sx={{ mt: 1.5 }} variant="overline">
                collapse all
              </Typography>
              <IconButton onClick={() => setOpenCollapse(!openCollapse)}>
                <Iconify
                  icon={openCollapse ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <CaseInformation
              title="Case Info"
              caseRecord={caseRecord}
              workflowInstance={workflowInstance}
              users={allUsers}
              onOwnedAssign={handleReAssingOwned}
              openCollapseExternal={openCollapse}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <ComplementaryInfo
              title="Case Complementary Info"
              workflowInstance={workflowInstance}
              dataSchema={getParseColumn(workflowInstance, 'dataSchema')}
              data={getParseColumn(workflowInstance, 'data')}
              refreshWorkflow={setExternalRefreshWorkFlow}
              openCollapseExternal={openCollapse}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            {patient && (
              <PatientSummary
                title="Patient Information"
                patient={patient}
                openCollapseExternal={openCollapse}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <RelatedReferrals
              isOpen={openCollapse}
              referralIds={inboundReferralIds}
              externalLoading={!carePlan}
              patientId={patientId ?? ''}
            />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={6}>
            <ReferralHistory
              patient={patient}
              carePlan={carePlan}
              openCollapseExternal={openCollapse}
            />
          </Grid> */}

          {REACT_APP_OUTBOUND_REFERRAL_FEATURE_FLAG && (
            <Grid item xs={12}>
              <OutboundByPathway
                patient={patient}
                carePlan={carePlan}
                serviceRequest={serviceRequest}
                healthCareServices={healthCareServices}
                refreshServiceRequest={refreshServiceRequest}
                createServiceRequest={createServiceRequest}
                updateServiceRequest={updateServiceRequest}
                openCollapseExternal={openCollapse}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <GoalsByPathway
              planDefinitions={planDefinitions}
              refreshEntities={refreshEntities}
              carePlan={carePlan}
              patient={patient}
              tasks={tasks}
              refreshTasks={memoizedRefreshTasks}
              openCollapseExternal={openCollapse}
            />
          </Grid>

          <Grid item xs={12}>
            <TasksByPathway
              tasks={tasks}
              patient={patient as WrappedPatient}
              carePlan={carePlan}
              healthCareServices={healthCareServices}
              refreshEntities={refreshEntities}
              refreshTasks={memoizedRefreshTasks}
              openCollapseExternal={openCollapse}
            />
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent sx={{ padding: 0, py: 3 }}>
                <CaseCareTeamGrid
                  carePlan={carePlan as WrappedCarePlan}
                  careTeams={careTeams}
                  patient={patient as WrappedPatient}
                  onSuccessfulCreation={refreshEntities}
                  onSuccessfulEdit={memoizedRefreshCareTeam}
                  onMemberUpdateSuccess={memoizedRefreshCareTeam}
                  openCollapseExternal={openCollapse}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <TableCustom
              title={'Contact Attempts'}
              data={Array.isArray(contactAttempt) && mapContactAttemptsCases(contactAttempt)}
              tableHead={TABLE_HEAD_CONTACT}
              childrenButtons={
                <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
                  <Button onClick={() => setOpenContactClientForm(true)}>Contact Attempts</Button>
                </Grid>
              }
              openCollapseExternal={openCollapse}
            />
          </Grid>

          <Grid item xs={12}>
            <TableCustom
              title={'Assessments / Forms'}
              data={Array.isArray(assessmentsForms) && assessmentsForms}
              tableHead={TABLE_HEAD_ASSESSMENTS}
              openCollapseExternal={openCollapse}
              childrenButtons={
                <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
                  {checkAclValidation({
                    user,
                    acl: getRelatedAcls(CRS_ACLS.PATIENT.ASSESSMENTS.START),
                  }) && (
                    <StartAssessmentsButton
                      keyRegistry="crs-patient-assessments"
                      patientId={patient?.id ?? ''}
                      additionalQueryParams={`&caseUUID=${carePlanId}`}
                    />
                  )}
                </Grid>
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TableCustom
              title={'Notes'}
              data={mapCommunicationsToNotesDisplay(communications, 
                ['notes_case', 'notes_task', 'notes_goal'])}
              tableHead={TABLE_HEAD_NOTES}
              handleOpen={handleOpenNote}
              titleButton={'Add A Note'}
              openCollapseExternal={openCollapse}
            />
          </Grid>

          <Grid item xs={12}>
            <DocumentList
              patient={patient}
              // documentReferences={isDocumentsLoading || !carePlan?.id ? [] : documentReferences}
              documentReferences={documentReferences}
              resource={carePlan}
              showCollapse={true}
              refreshDocumentReference={refreshDocumentReference}
              openCollapseExternal={openCollapse}
              handleUpdateResource={handleUpdateCase}
            />
          </Grid>
        </Grid>

        <NoteAddForm
          open={openNote}
          patient={patient}
          onCancel={handleCloseAddNote}
          resource={[carePlan]}
          handleCreate={handleCreateCommunicationFhir}
          typeNote="notes_case"
        />

        <ContactForm
          option="Case Note"
          countRecord={countContactClient}
          referral={caseRecord}
          open={openContactClientForm}
          onCancel={handleCloseContactClientForm}
          handleCreateContactForm={handleCreateContactForm}
        />
      </Container>
    </Page>
  );
}
