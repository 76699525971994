import {
  Backdrop,
  Button,
  CircularProgress,
  DialogActions,
  Stack,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { UploadFiles } from '../..';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import getFileData from 'src/utils/getFileData';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { FormProvider } from 'src/components/hook-form';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useOrganizations } from 'src/@nicheaim/fhir-react';
import { InsuredOptionProps } from 'src/@types/crs/referral';
import {
  DocumentReference,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

const HOST_API = process.env.REACT_APP_HOST_API;
const FHIR_API = process.env.REACT_APP_FHIR_API_BASE_URL;

type FormValues = {
  files: Blob[];
  documents: string[];
  documentsPHIN: DocumentReference[];
};

type Props = InsuredOptionProps;

export default function NotInsured({
  patient,
  documentReference,
  coverage,
  serviceRequest,
  handleCreateBynary,
  handleCreateDocumentReference,
  handleUpdateDocumentReference,
  onCancel,
}: Props) {

  const { enqueueSnackbar } = useSnackbar();

  const authUser = useAuth().getCurrentUser();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  
  const [ organization, ] = useOrganizations();
  
  let documentsRelated: any = [];

  if(serviceRequest !== null){
    documentsRelated  = documentReference?.
    filter(d => d.context?.related?.
      find( r => r.reference === `${serviceRequest?.resourceType}/${serviceRequest?.id}`)).map((e) => e.id);
  }else if(coverage !== null){
    documentsRelated  = documentReference?.
    filter(d => d.context?.related?.
      find( r => r.reference === `${coverage?.resourceType}/${coverage?.id}`)).map((e) => e.id);
  }

  const defaultValues = {
    documents: documentsRelated || [],
  };

  const methods = useForm<FormValues>({ defaultValues });

  const { handleSubmit } = methods;

  const convertBlobToBase64 = (file: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result?.toString().split(',')[1]);
      };
      reader.readAsDataURL(file);
  });

  const createBinaryCustom = async (data: FormValues): Promise<any> => {
    if (!data?.files?.length) return null;

    const resourceType = 'Binary';

    const mappedFile =  await Promise.all( 
      data.files.map(async (file: Blob) => {
        const contentType = file.type;
        const data = await convertBlobToBase64(file);
        const securityContext = { reference: `${patient?.resourceType}/${patient?.id}` };
        const name = getFileData(Object.assign(file)).name;
        const obj = { resourceType, contentType, securityContext, data};
        const binary = await handleCreateBynary(obj);

        const objNew = {...binary[0], name: name};
        return objNew;
      })
    );

    return mappedFile;
  };

  const createDocumentReference = async (binaryList: any) => {

    const organizationFhir = organization?.
    filter(e => e.id === authUser.organization_fhir_uri).
    map((r) => ({
      display: r.name, 
      reference: `Organization/${r.id}`
    }))[0];

    const documentReference = binaryList?.map((binary:any) => ({ 
      resourceType: 'DocumentReference',
      status: 'current',
      subject: { reference: `${patient?.resourceType}/${patient?.id}` },
      author: [{ 
        ...(!isEmpty(authUser.user_fhir_uri.trim()) && {
          reference: `Practitioner/${getFhirIdFromEntity(authUser.user_fhir_uri)}` 
        }), 
        display: authUser.name
      }],
      ...(organizationFhir && {
        custodian: organizationFhir
      }),
      date: new Date().toISOString(),
      content: [{ attachment: { 
        contentType: `${binary.contentType}`, 
        creation: new Date().toISOString(), 
        title: binary.name,
        url: `${FHIR_API}/Binary/${binary.id}` 
      }}],
     }));

     const createdDocumentReference = await handleCreateDocumentReference(documentReference);
     
    return createdDocumentReference;
  };

  const createDocumentReferencePHIN = async (data: DocumentReference[]) : Promise<any> => {
    
    if(data === undefined) return null;
    
    try{
      const document = data.map((doc) => {
        const documentUniqueId = doc?.identifier?.find((i) => i?.type?.text === 'documentUniqueId')?.value;
        const repositoryUniqueId = doc?.identifier?.find((i) => i?.type?.text === 'repositoryUniqueId')?.value;
        const hcid = doc?.identifier?.find((i) => i?.type?.text === 'hcid')?.value;

        doc.content[0].attachment.url = `${HOST_API}/crs/document/${documentUniqueId}?repositoryId=${repositoryUniqueId}&hcid=${hcid}`;
        doc.subject = { reference: `${patient?.resourceType}/${patient?.id}` };
  
        if(doc.context?.period?.end === null) delete doc.context?.period?.end;
        delete doc.content[0].attachment.data;
        delete doc.id;
  
        const { ...result } = doc;
        return result;
      })
  
      const documentReference = await handleCreateDocumentReference(document);

      return documentReference;

    }catch(e) {
      enqueueSnackbar('An error has occurred.', { variant:'error', autoHideDuration: 6000 });
    }
  };

  const updateDocumentsSelected = async (
    data: FormValues, 
    createdDocs: DocumentReference[] | undefined, 
    createdPhinDocs: DocumentReference[] | null, 
    resourceToRelate: any
  ): Promise<any> => {

    const { documents } = data;

    const delRelatedResource = documentsRelated.filter((e:any) => !documents.some((s:any) => s === e));
    
    const documentsNotRelated = documentReference?.filter(p => delRelatedResource?.some((f:any) => p.id === f)).map((l) =>{
      const related = l.context?.related;

      const notRelated = related?.filter(r => r.reference !== `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`) || [];
      
      if(l.context?.related && !isEmpty(notRelated)){  
       l.context.related = [ ...notRelated];
      }else if(isEmpty(notRelated)){
        delete l.context
      }

      const {...result} = l;
      return result;

    })|| [];

    const documentsSelected = documents.filter((e:any) => !documentsRelated.some((s:any) => s == e));

    const documentReferenceSelected = documentReference?.filter(d => documentsSelected?.some((r: any) => d.id === r)).map((e) => {
      const context = e.context;
      const related = e.context?.related || [];

      const resourceRelated = related.find(r => r.reference === `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`);

      if(isEmpty(resourceRelated)){
        e.context = {...context, related: [ ...related, { reference: `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`}]};
      }else {
        e.context = {...context, related: [ ...related ]};
      }

      const {...result} = e;
      return result;
    }) || [];

    const createdDocuments = createdDocs?.map((e) => {
      const context = e.context;
      const related = e.context?.related || [];

      const resourceRelated = related.find(r => r.reference === `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`);
      
      if(isEmpty(resourceRelated)){
        e.context = {...context, related: [ ...related, { reference: `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`}]};
      }else {
        e.context = {...context, related: [ ...related ]};
      }
      const {...result} = e;
      return result;
    }) || [];

    const phinDocuments = createdPhinDocs?.map((e) => {
      const context = e.context;
      const related = e.context?.related || [];

      const resourceRelated = related.find(r => r.reference === `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`);
      
      if(isEmpty(resourceRelated)){
        e.context = {...context, related: [ ...related, { reference: `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`}]};
      }else {
        e.context = {...context, related: [ ...related ]};
      }
      const {...result} = e;
      return result;
    }) || [];

    const allDocuments = [ ...documentsNotRelated, ...documentReferenceSelected, ...createdDocuments, ...phinDocuments];

    const updateDocumentReference = await handleUpdateDocumentReference(allDocuments);
    return updateDocumentReference;
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      setOpenBackdrop(true);

      let documentReferences;
      let documentPHIN;
      let resourceToRelated: any;

      if(data.files !== undefined){
        const createBinary = await createBinaryCustom(data);
        documentReferences = await createDocumentReference(createBinary);
      } 
      
      if (data?.documentsPHIN?.length) {
        documentPHIN = await createDocumentReferencePHIN(data.documentsPHIN) || [];
      }

      if(serviceRequest){
        resourceToRelated = serviceRequest;
      }else if(coverage){
        resourceToRelated = coverage;
      }

      if(resourceToRelated){
        const updateDocuments = await updateDocumentsSelected(data, documentReferences, documentPHIN, resourceToRelated);
      }

      setOpenBackdrop(false);
      onCancel();
      enqueueSnackbar('Identification saved.');
    } catch (e) {
      console.log('error',e)
      onCancel();
      enqueueSnackbar('Identification was not saved.', { variant: 'error' });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Typography variant="body2" sx={{ mt: 1, ml: 2 }}>
        Identification Card Front and Back
      </Typography>
      <UploadFiles
        documentReferences={documentReference}
        patient={patient}
      />
      <Stack alignItems="center">
        <DialogActions>
          <Button variant="outlined" color="info" type="submit">
            Save
          </Button>
        </DialogActions>
      </Stack>
    </FormProvider>
  );
}
