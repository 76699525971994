import { Grid, IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  NEXT_DATE_RANGE_SELECTED,
  PREVIOUS_DATE_RANGE_SELECTED,
  useEventBus,
} from '../../../../event-bus';
import { decrementRange, formatRange, incrementRange, StandardRange } from '../utils/date-range';

interface DateRangeChangeCallback {
  (newRange: StandardRange): void;
}

interface RangeStepperOptions {
  range: StandardRange;
  onDateRangeChanged: DateRangeChangeCallback;
}

const styles = {
  fontWeight: 'bold',
  fontSize: 'larger',
};

export function RangeStepper({ range, onDateRangeChanged }: RangeStepperOptions) {
  const { dispatchEvent } = useEventBus();

  const onDecrement = () => {
    onDateRangeChanged(decrementRange(range));
    dispatchEvent(PREVIOUS_DATE_RANGE_SELECTED);
  };

  const onIncrement = () => {
    onDateRangeChanged(incrementRange(range));
    dispatchEvent(NEXT_DATE_RANGE_SELECTED);
  };

  return (
    <Grid
      item
      container
      spacing={1}
      sx={styles}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Grid item>
        <IconButton onClick={onDecrement}>
          <KeyboardArrowLeftIcon sx={styles} />
        </IconButton>
      </Grid>
      <Grid item display="flex" alignItems="center">
        {formatRange(range)}
      </Grid>
      <Grid item>
        <IconButton onClick={onIncrement}>
          <KeyboardArrowRightIcon sx={styles} />
        </IconButton>
      </Grid>
    </Grid>
  );
}
