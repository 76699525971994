const usersAndGroupsList = [
  { label: 'Me', value: 'me' },
  { label: 'Fabian Rodriguez', value: 'fabian rodriguez' },
  { label: 'Neurology', value: 'neurology' },
];

const repeatFieldItems = [
  {
    label: 'Does not repeat',
    value: 'Does not repeat',
  },
  {
    label: 'Daily',
    value: 'Daily',
  },
  {
    label: 'Weekly on friday',
    value: 'Weekly on friday',
  },
  {
    label: 'Monthly on the last friday',
    value: 'Monthly on the last friday',
  },
  {
    label: 'Anually on Jun 15',
    value: 'Anually on Jun 15',
  },
  {
    label: 'Every weekday (Mon to Fri)',
    value: 'Every weekday (Mon to Fri)',
  },
  {
    label: 'Custom...',
    value: 'Custom...',
  },
];

export { usersAndGroupsList, repeatFieldItems };
