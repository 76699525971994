import axios from 'axios';
import Cookies from 'js-cookie';
import { HOST_API } from 'src/config';

const client = axios.create({
  baseURL: HOST_API,
  headers: {
    Authorization: `Bearer ${Cookies.get('careflowToken')}`,
  },
});

export default client;
