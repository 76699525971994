import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import Row from './Row';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import useTable from 'src/hooks/useTable';
import { Add } from '@mui/icons-material';
import Iconify from 'src/components/Iconify';
import { useQuery } from '@tanstack/react-query';
import {
  ObservationWrapper,
  WrappedObservation,
} from 'src/@nicheaim/fhir-base/wrappers/Observation';
import { TableHeadCustom } from 'src/components/table';
import { useObservations, useValueSet } from 'src/@nicheaim/fhir-react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { Observation } from 'src/@nicheaim/fhir-base/mappings/Observation';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { TABLE_HEAD_OBSERVATION } from 'src/sections/crs/common/table-head';
import useAddEntityRequestStates from 'src/hooks/useAddEntityRequestStates';
import { RegistryValueType, getRegistries } from 'src/services/api/registry';
import RegistryComponent, { ValueType } from 'src/components/RegistryComponent';
import { convertValueToValueSetExpansion } from 'src/sections/crs/common/common-utils';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  patient: WrappedPatient | null;
};

export default function ObservationDetails({ patient }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [add, setOpenAdd] = useState(false);
  const [editRow, setEditRow] = useState({});
  const [typeObservation, setTypeObservation] = useState('');
  const [valueObservation, setValueObservation] = useState<{
    valueType: RegistryValueType | null;
    value: ValueType;
  }>({ valueType: null, value: null });
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});

  const [valueSet] = useValueSet('crs-type-observation', { map: ValueSetWrapper });
  const [observation, { create: createObservation, update: updateObservation }] = useObservations({
    filter: { patient: patient?.id },
    map: ObservationWrapper,
  });
  const [{ error }, { setError }] = useAddEntityRequestStates();

  const getValue = () => {
    const value =
      typeof valueObservation.value === 'object'
        ? valueObservation.value?.code
        : valueObservation.value;
    if (!value) return null;
    switch (valueObservation?.valueType) {
      case RegistryValueType.CodeableConcept:
        if (typeof valueObservation.value === 'object') {
          return {
            valueCodeableConcept: {
              coding: [valueObservation.value],
              text: valueObservation.value?.display,
            },
          };
        }
        return null;
      case RegistryValueType.NUMBER: {
        const integer = parseInt(String(value));
        if (isNaN(integer)) return null;
        return { valueInteger: integer };
      }
      default: {
        return { valueString: value };
      }
    }
  };

  const { data: registries } = useQuery({
    queryKey: ['registries', { groupIdentifier: 'observations' }],
    queryFn: async () => {
      const registries = await getRegistries('observations');
      return registries.data;
    },
  });

  const validateRequiredFields = (value: any) => {
    if (isEmpty(typeObservation) || isEmpty(value)) {
      return false;
    }
    return true;
  };
  
  const mapObservation = (derivedFrom?: string) => {
    
    const value = getValue() ?? {};

    if(!validateRequiredFields(value)) return setError('Please, fill all required fields');

    return {
      resourceType: 'Observation',
      code: {
        coding: [convertValueToValueSetExpansion(typeObservation, valueSet)],
        text: convertValueToValueSetExpansion(typeObservation, valueSet)?.display,
      },
      effectiveDateTime: new Date().toISOString(),
      issued: new Date().toISOString(),
      status: 'preliminary',
      subject: {
        reference: `Patient/${patient?.id}`,
      },
      ...value,
      ...(derivedFrom && {
        derivedFrom: [
          {
            reference: `Observation/${derivedFrom}`,
            type: 'Observation',
          },
        ],
      }),
    };
  };

  const saveObservation = async () => {
    try {
      if (isEmpty(editRow)) {
        const observation = mapObservation() as Observation;
        if(observation){
          const result = await createObservation(observation);
          enqueueSnackbar('Observation was created.');
          handleClose();
        }
      } else {
        const observationEdit = editRow as Observation;
        observationEdit.status = 'amended';
        const createDerivedObservation = mapObservation(observationEdit?.id) as Observation;
        if(createDerivedObservation){
          const resultCreate = await createObservation(createDerivedObservation);
          const resultUpdate = await updateObservation(observationEdit);
          enqueueSnackbar('Observation was updated.');
          handleClose();
        }
      }
    } catch (error) {
      enqueueSnackbar('Observation was not created.', { variant: 'error' });
      handleClose();
    }
  };

  const handleClose = () => {
    setTypeObservation('');
    setEditRow({});
    setOpenAdd(false);
    setError(null);
  };

  const handleEdit = (data: any) => {
    setEditRow(data);
    setTypeObservation(data?.code?.coding?.[0].code);
    setOpenAdd(true);
  };

  return (
    <Card sx={{ m: 1 }}>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Stack sx={{ m: 2 }}>
              <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                <Grid item xs={10}>
                  <Typography variant="subtitle1">Observations</Typography>
                </Grid>
                <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
                  <Button
                    size="small"
                    sx={{ height: '36px' }}
                    startIcon={<Add />}
                    onClick={() => setOpenAdd(true)}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {add && (
                <>
                  <Grid
                    item
                    xs={12}
                    display={'flex'}
                    justifyContent={'center'}
                    sx={{ mx: 20, my: 2 }}
                  >
                    <FormControl 
                      sx={{ marginRight: 2 }} 
                      fullWidth 
                      error={!!error}
                    >
                      <InputLabel id="observation">Observation</InputLabel>
                      <Select
                        label="observation"
                        value={typeObservation}
                        onChange={(event) => {
                          setTypeObservation(event.target.value);
                          setError(null);
                        }}
                      >
                        {valueSet?.expansion?.contains?.map((option) => (
                          <MenuItem key={option.code} value={option.code}>
                            {option.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <RegistryComponent
                      disabled={!typeObservation}
                      registries={registries ?? []}
                      label="Value"
                      keyRegistry={typeObservation}
                      onValueChange={(value, valueType) => {
                        setValueObservation({ value, valueType });
                        setError(null);
                      }}
                      error={error}
                    />
                    
                    <IconButton sx={{ my: 1, ml: 2 }} onClick={saveObservation}>
                      <Iconify icon="eva:save-outline" />
                    </IconButton>
                    <IconButton sx={{ my: 1 }} onClick={handleClose}>
                      <Iconify icon="ic:outline-close" />
                    </IconButton>
                  </Grid>
                  {error &&
                    <Grid 
                      item 
                      xs={12}
                      display={'flex'}
                      justifyContent={'center'}
                    >
                      <FormHelperText sx={{color: '#d50000'}}>
                        {error}
                      </FormHelperText>
                    </Grid>
                  }
                </>
              )}
            </Stack>
            <Table size="small" sx={{ mb: 2 }}>
              <TableHeadCustom headLabel={TABLE_HEAD_OBSERVATION} />
              <TableBody>
                {!isEmpty(observation) ? (
                  observation
                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: WrappedObservation) => (
                      <Row key={uuidv4()} row={row} handleEdit={handleEdit} />
                    ))
                ) : (
                  <TableCell colSpan={TABLE_HEAD_OBSERVATION?.length}>
                    <Typography variant="body2" align="center">
                      No rows
                    </Typography>
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={observation.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </Card>
  );
}
