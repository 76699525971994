import { ElementType, lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { HOST_API, PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import HealthRecord from 'src/sections/careflow/health-record/HealthRecord';
import CareRecordIndex from 'src/sections/careflow/care-plan/CarePlan';
import { Activities } from '../sections/careflow/tasks-activities';
import { PatientDemographics } from 'src/sections/careflow/patient-demographics';
import Debug from 'src/sections/debug';
import CarePlanAutomationIndex from 'src/sections/settings/care-plan-automation';

//crs components
import ReferralList from 'src/sections/crs/referral/ReferralList';
import ReferralDetails from 'src/sections/crs/referral/components/ReferralDetails';
import CaseList from 'src/sections/crs/case/CaseList';
import CaseDetails from 'src/sections/crs/case/components/CaseDetails';
import OrganizationList from 'src/pages/organization/OrganizationList';
import OrganizationCreate from 'src/pages/organization/OrganizationCreate';
import Workflow from 'src/sections/crs/workflow/Workflow';
import NewPatients from 'src/sections/careflow/new-patients.tsx/components/NewPatients';
import NoPatientSelected from 'src/sections/careflow/patient';
import { PATH_DASHBOARD } from './paths';
import { PatientDetails, PatientList } from '../sections/crs/patient';
import ActivityListAutomationIndex from 'src/sections/settings/activity-list-automation';
import ClientsSettings from 'src/sections/settings/clients';
import AssessmentsSettingsIndex from 'src/sections/settings/assessments';
import NewPatient from 'src/sections/crs/patient/NewPatient';
import { Rounding } from 'src/sections/careflow/rounding';
import { Reports } from 'src/sections/careflow/reports';
import { Census } from 'src/sections/careflow/census';
import { GroupSessions } from 'src/sections/careflow/group-sessions';
import { MedPass } from 'src/sections/careflow/med-pass';
// import ProgramDisenrollmentReasons from 'src/sections/settings/program-disenrollment-reasons';
import TaskList from 'src/sections/crs/task/TaskList';
import CensusSettings from 'src/sections/settings/census/CensusSettings';
import ChildReferralDetails from 'src/sections/crs/referral/components/child-referral/ChildReferralDetails';
import ProgramSettingsIndex from 'src/sections/settings/program-settings';
import UsersOrganizationSettings from 'src/sections/settings/users-organization-settings/UsersOrganizationSettings';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export function getRouter() {
  return createBrowserRouter([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'logout',
          element: (
            <GuestGuard>
              <div>logout</div>
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        // Patient Screens Fallback
        {
          path: 'patient-demographics',
          element: <NoPatientSelected title="Patient Demographics" />,
        },
        { path: 'health-record', element: <NoPatientSelected title="Health Record" /> },
        { path: 'care-record', element: <NoPatientSelected title="Care Record" /> },
        { path: 'reports', element: <NoPatientSelected title="Reports" /> },
        // Patient Screens
        {
          path: 'patient/:id',
          children: [
            { path: 'patient-demographics', element: <PatientDemographics /> },
            { path: 'health-record/*', element: <HealthRecord /> },
            { path: 'care-record/*', element: <CareRecordIndex /> },
            { path: 'reports', element: <Reports /> },
          ],
        },
        { path: 'activities', element: <Activities /> },
        {
          path: 'roundings/*',
          children: [
            { path: 'list', element: <Rounding /> },
            { path: 'census', element: <Census /> },
          ],
        },
        {
          path: 'group-sessions/*',
          element: <GroupSessions />,
        },
        {
          path: 'med-pass/*',
          element: <MedPass />,
        },
        {
          path: 'organization',
          children: [
            { path: 'list', element: <OrganizationList /> },
            { path: 'new', element: <OrganizationCreate /> },
            { path: ':uuid/edit', element: <OrganizationCreate /> },
          ],
        },
        { path: 'referral', element: <ReferralList /> },
        { path: 'task', element: <TaskList /> },
        { path: 'permissions', element: <Permissions /> },
        { path: 'case', element: <CaseList /> },
        // { path: 'workflow', element: <Workflow /> },

        {
          path: PATH_DASHBOARD.crs.patient.list.value,
          handle: 'Patients',
          children: [
            {
              index: true,
              element: <PatientList />,
            },
            {
              path: PATH_DASHBOARD.crs.patient.details.value,
              element: <PatientDetails />,
              handle: 'Patient Details',
              children: [
                { path: 'general', element: <PatientDetails /> },
                { path: 'groups', element: <PatientDetails /> },
                { path: 'referrals-cases', element: <PatientDetails /> },
                { path: 'health', element: <PatientDetails /> },
                { path: 'insurance', element: <PatientDetails /> },
                { path: 'documents', element: <PatientDetails /> },
                { path: 'tasks', element: <PatientDetails /> },
              ],
            },
            {
              path: PATH_DASHBOARD.crs.patient.new.value,
              element: <NewPatient />,
              handle: 'New Patient',
            },
          ],
        },
        {
          path: 'referral',
          children: [
            { path: ':id', element: <ReferralDetails /> },
            { path: ':name/edit', element: <ReferralDetails /> },
          ],
        },
        {
          path: 'child-referral',
          children: [{ path: ':id', element: <ChildReferralDetails /> }],
        },
        {
          path: 'case',
          children: [
            { path: ':id', element: <CaseDetails /> },
            { path: ':name/edit', element: <CaseDetails /> },
          ],
        },

        {
          path: 'new-patients',
          element: <NewPatients />,
        },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':uuid/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    {
      path: 'settings',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'care-plan-automation/*',
          element: <CarePlanAutomationIndex />,
        },
        {
          path: 'activity-list-automation/*',
          element: <ActivityListAutomationIndex />,
        },
        {
          path: 'care-member',
          element: <ComingSoon />,
        },
        {
          path: 'assessments/*',
          element: <AssessmentsSettingsIndex />,
        },
        {
          path: 'clients/*',
          element: <ClientsSettings />,
        },
        {
          path: 'programs/*',
          element: <ProgramSettingsIndex />,
        },
        {
          path: 'care-member/assign',
          element: <ComingSoon />,
        },
        {
          path: 'script',
          element: <ComingSoon />,
        },
        {
          path: 'provider',
          element: <ComingSoon />,
        },
        {
          path: 'template',
          element: <ComingSoon />,
        },
        {
          path: 'consent-forms',
          element: <ComingSoon />,
        },
        {
          path: 'census',
          element: <CensusSettings />,
        },
        {
          path: 'users-organization',
          element: <UsersOrganizationSettings />,
        },
      ],
    },

    // Debug
    {
      path: '_debug/*',
      element: <Debug />,
    },
    // Misc
    {
      path: 'survey-saved',
      element: <SurveySaved />,
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'select-context', element: <SelectContext /> },
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      loader: () => {
        window.location.replace(`${HOST_API}/auth/login`);
      },
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductDetails'))
);
const EcommerceProductList = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductList'))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductCreate'))
);
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

//ADMIN
const Permissions = Loadable(lazy(() => import('../sections/careflow/permissions/Permissions')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const SelectContext = Loadable(lazy(() => import('../pages/SelectContext')));
const SurveySaved = Loadable(lazy(() => import('../sections/misc/SurveySaved')));
