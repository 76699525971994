import { IsDate, IsOptional, IsString } from 'class-validator';

export class CreateFullPatientDto {
  @IsString()
  @IsOptional()
  fhirRefUri: string;

  @IsString()
  @IsOptional()
  mintPatientId: string;

  @IsString()
  @IsOptional()
  given?: string;

  @IsString()
  @IsOptional()
  family?: string;

  @IsString()
  @IsOptional()
  fullName?: string;

  @IsString()
  @IsOptional()
  mrn?: string;

  @IsString()
  @IsOptional()
  phone?: string;

  @IsString()
  @IsOptional()
  email?: string;

  @IsDate()
  @IsDate()
  dateOfBirth?: Date;

  @IsString()
  @IsOptional()
  gender?: string;

  @IsString()
  @IsOptional()
  race?: string;

  @IsString()
  @IsOptional()
  ethnicity?: string;

  @IsString()
  @IsOptional()
  status?: string;

  @IsString()
  @IsOptional()
  program?: string;
}
