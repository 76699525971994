import { useNavigate } from 'react-router';
import { EntityContextMenu } from 'src/sections/careflow/common';
import { useDialogStore } from 'src/stores/dialog';
import useCarePlanAutomationFormStore from './AutomationSheetsForm/store';
import { List } from 'src/@nicheaim/fhir-base/mappings/List';
import { Questionnaire } from 'src/@nicheaim/fhir-base/mappings/Questionnaire';
import { useList } from 'src/@nicheaim/fhir-react';

export interface AutomationSheetsContextMenuProps {
  item: {
    sheet: List;
    assessment?: Questionnaire;
  };
  onDisable?: () => void | Promise<void>;
  onEnable?: () => void | Promise<void>;
  onEdit?: () => void | Promise<void>;
  onDelete?: () => void | Promise<void>;
  onAction?: (action: 'disable' | 'enable' | 'edit' | 'delete') => void | Promise<void>;
}

export default function AutomationSheetsContextMenu({
  item,
  onDisable,
  onEnable,
  onEdit,
  onDelete,
  onAction,
}: AutomationSheetsContextMenuProps) {
  const navigate = useNavigate();
  const reset = useCarePlanAutomationFormStore((s) => s.reset);
  const loadAutomationSheet = useCarePlanAutomationFormStore((s) => s.loadAutomationSheet);
  const [automationSheet, { update, refresh }] = useList(item.sheet.id || '');
  const isEnabled = item.sheet.status === 'current';

  const { confirmDialog } = useDialogStore();

  return (
    <EntityContextMenu
      entity={item}
      items={[
        {
          label: 'Edit',
          icon: 'eva:edit-fill',
          onPress: async () => {
            // FIXME: Should warn about previous changes before editting.
            reset();
            await loadAutomationSheet(item.sheet.id || '');
            navigate('edit');
            await onEdit?.();
            await onAction?.('edit');
          },
        },
        isEnabled
          ? {
              label: 'Disable',
              icon: 'eva:close-circle-outline',
              onPress: async () => {
                const confirmed = await confirmDialog({
                  title: 'Delete',
                  description: 'Are you sure you want to disable this item?',
                });

                if (confirmed) {
                  await update({
                    ...automationSheet,
                    mode: 'working',
                    resourceType: 'List',
                    status: 'retired',
                  });
                  await onDisable?.();
                  await onAction?.('disable');
                }
              },
            }
          : {
              label: 'Enable',
              icon: 'eva:checkmark-circle-2-outline',
              onPress: async () => {
                await update({
                  ...automationSheet,
                  mode: 'working',
                  resourceType: 'List',
                  status: 'current',
                });
                await onEnable?.();
                await onAction?.('enable');
              },
            },

        {
          label: 'Delete',
          icon: 'eva:trash-2-outline',
          onPress: async () => {
            const confirmed = await confirmDialog({
              title: 'Delete',
              description: 'Are you sure you want to delete this item, it will be deleted forever?',
            });

            if (confirmed) {
              await update({
                ...automationSheet,
                mode: 'working',
                resourceType: 'List',
                status: 'entered-in-error',
              });
              await onDelete?.();
              await onAction?.('delete');
            }
          },
        },
      ]}
    />
  );
}
