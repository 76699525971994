import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { GoalWrapper } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import { useGoals } from 'src/@nicheaim/fhir-react';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { ValueSetComposeIncludeConcept } from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources/resources';
import useObjectState from 'src/hooks/useObjectState';
import GoalsGrid, { GoalFilters, initialGoalFilters } from '../GoalsGrid/GoalsGrid';
import SeverityStatus, { AlertSeverity } from 'src/components/SeverityStatus';
import { GoalGridRowData } from '../../../../../@types/crs/case';

import {
  getGoalGridRows,
  getGoalsIds,
  searchIfContainedInObj,
} from 'src/sections/crs/helpers/common';
import './styles.css';
import useGridFilters, { GridFilters } from 'src/hooks/useGridFilters';
import { WrappedPlanDefinition } from 'src/@nicheaim/fhir-base/wrappers/PlanDefinition';
import { debounce } from 'src/utils/timers';
import moment from 'moment';
import { isBetweenDates } from 'src/utils/dates';
import Iconify from 'src/components/Iconify';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';

interface GoalsByPathwayProps {
  tasks: WrappedTask[];
  patient: WrappedPatient | null;
  carePlan: WrappedCarePlan | null;
  refreshEntities: Function;
  planDefinitions: WrappedPlanDefinition[];
  refreshTasks: Function;
  openCollapseExternal: boolean;
}
interface GoalsByPathwayState {
  selectedPathway: string;
  filteredGoalGridRows: GoalGridRowData[];
}

const pathwayAll: ValueSetComposeIncludeConcept = {
  code: 'all',
  display: 'All',
};

const GoalsByPathway = React.memo(
  ({
    patient,
    carePlan,
    tasks,
    refreshEntities,
    planDefinitions,
    refreshTasks,
    openCollapseExternal,
  }: GoalsByPathwayProps) => {
    const [{ selectedPathway, filteredGoalGridRows }, updateState] =
      useObjectState<GoalsByPathwayState>({
        selectedPathway: 'all',
        filteredGoalGridRows: [],
      });
    const {
      isFilterDrawerOpen,
      onFilterDrawerOpen,
      onFilterDrawerClose,
      onSearchTextFieldChange,
      searchTextFieldValue,
      filters,
      onApplyFilters,
    } = useGridFilters<GoalFilters>(initialGoalFilters);

    const {
      valueSets: [pathways, goalStatuses],
      isLoading: isPathWaysLoading,
    } = useValueSetsByIdentifiers(['crs-path-ways', 'crs-goal-lifecycle-status']);

    // Get goals
    const groupedGoals = useMemo(() => carePlan?.getGroupedGoals(), [carePlan]);
    const [goals, { isLoading: isGoalsLoading, refresh: refreshGoals }] = useGoals({
      filter: {
        _id: getGoalsIds(groupedGoals),
      },
      map: GoalWrapper,
      autofetch: !!getGoalsIds(groupedGoals),
    });

    const goalGridRows = useMemo(
      () => getGoalGridRows(goals, tasks, groupedGoals, goalStatuses?.asList?.() ?? []),
      [goals, tasks, groupedGoals, goalStatuses]
    );

    const handleFiltersChange = useMemo(
      () =>
        debounce((searchValue: string, filters: GoalFilters, goalGridRows: GoalGridRowData[]) => {
          updateState({
            filteredGoalGridRows: filterGoalGridRows(goalGridRows, {
              filters,
              searchTextFieldValue: searchValue,
            }),
          });
        }, 600),
      [updateState]
    );

    useEffect(() => {
      handleFiltersChange(searchTextFieldValue ?? '', filters, goalGridRows);
    }, [filters, updateState, goalGridRows, handleFiltersChange, searchTextFieldValue]);

    const handlePathwayTabChange = (_: React.SyntheticEvent, pathway: string) => {
      updateState({
        selectedPathway: pathway,
      });
    };

    const pathwaysList: ValueSetComposeIncludeConcept[] = [
      pathwayAll,
      ...(pathways?.asList?.() ?? []),
    ];

    const [openCollapse, setOpenCollapse] = useState(false);

    useEffect(() => {
      setOpenCollapse(openCollapseExternal);
    }, [openCollapseExternal]);
    return (
      <>
        <Card>
          {!isPathWaysLoading ? (
            <>
              <Stack sx={{ m: 2 }}>
                <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                  <Grid item xs={10}>
                    <Typography sx={{ ml: 1 }}>
                      List of Goals
                      <IconButton onClick={() => setOpenCollapse(!openCollapse)}>
                        <Iconify
                          icon={
                            openCollapse
                              ? 'eva:arrow-ios-upward-fill'
                              : 'eva:arrow-ios-downward-fill'
                          }
                        />
                      </IconButton>
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>

              <Collapse in={openCollapse}>
                <Tabs
                  scrollButtons={'auto'}
                  variant={'scrollable'}
                  value={selectedPathway}
                  onChange={handlePathwayTabChange}
                  aria-label="PathWay Tab"
                >
                  {pathwaysList.map(({ code, display }) => {
                    const statuses = getStatusCountByPathway(getGoalsByPathWay(code, goalGridRows));
                    return (
                      <Tab
                        key={code}
                        label={
                          <Box display={'flex'} flexDirection={'row'}>
                            <Typography sx={{ fontSize: '1em' }}>{display} </Typography>
                            {Object.entries(statuses).map(([severity, count]) => (
                              <SeverityStatusCount
                                key={severity}
                                count={count}
                                severity={severity as AlertSeverity}
                              />
                            ))}
                          </Box>
                        }
                        value={code}
                      />
                    );
                  })}
                </Tabs>
                <CardContent className={'cardContent'} sx={{ padding: 0 }}>
                  <Box sx={{ marginTop: 2, paddingBottom: 0 }}>
                    {pathwaysList.map((pathway) => {
                      const { code } = pathway;
                      const goalGridRowsByPathway = getGoalsByPathWay(code, filteredGoalGridRows);
                      return (
                        <TabPanel key={code} pathway={code} selectedPathway={selectedPathway}>
                          <GoalsGrid
                            onSuccessfulEdit={() => {
                              refreshGoals();
                            }}
                            onSuccessfulCreation={() => {
                              refreshEntities();
                            }}
                            showCategory={code === pathwayAll.code}
                            planDefinitions={planDefinitions}
                            patient={patient as WrappedPatient}
                            onSearchTextFieldChange={onSearchTextFieldChange}
                            goals={goalGridRowsByPathway}
                            onFilterDrawerOpen={onFilterDrawerOpen}
                            searchTextFieldValue={searchTextFieldValue}
                            defaultPathway={code !== pathwayAll.code ? pathway : undefined}
                            pathways={pathways?.asList?.() as ValueSetComposeIncludeConcept[]}
                            filterValues={filters}
                            showPathwayFilter={selectedPathway === pathwayAll.code}
                            isFilterDrawerOpen={isFilterDrawerOpen}
                            onApplyFilters={onApplyFilters}
                            onFilterDrawerClose={onFilterDrawerClose}
                            carePlan={carePlan as WrappedCarePlan}
                            onTaskSuccessfulCreation={() => {
                              refreshEntities();
                            }}
                            onTaskSuccessfulEdit={() => {
                              refreshTasks();
                            }}
                            title={'List of Tasks'}
                          />
                        </TabPanel>
                      );
                    })}
                  </Box>
                </CardContent>
              </Collapse>
            </>
          ) : (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                my: 2,
              }}
            >
              <CircularProgress size={50} />
            </Box>
          )}
        </Card>
      </>
    );
  }
);

interface TabPanelProps {
  children: React.ReactNode;
  selectedPathway: string;
  pathway: string;
}

const TabPanel = ({ children, selectedPathway, pathway }: TabPanelProps): JSX.Element => (
  <>{selectedPathway === pathway ? <>{children}</> : null}</>
);

type DueDateSeverities = AlertSeverity.SUCCESS | AlertSeverity.ERROR | AlertSeverity.WARNING;

export type StatusSeverityCount = Record<DueDateSeverities, number>;

const getStatusCountByPathway = (goals: GoalGridRowData[]): StatusSeverityCount => {
  const statuses: StatusSeverityCount = {
    [AlertSeverity.ERROR]: 0,
    [AlertSeverity.WARNING]: 0,
    [AlertSeverity.SUCCESS]: 0,
  };
  for (const {
    endDate: { severity },
  } of goals) {
    if (statuses.hasOwnProperty(severity as AlertSeverity))
      statuses[severity as DueDateSeverities]++;
  }
  return statuses;
};

const filterGoalGridRows = (
  goalGridRows: GoalGridRowData[],
  { filters, searchTextFieldValue }: GridFilters<GoalFilters>
): GoalGridRowData[] => {
  let filteredGoalGridRows = [...goalGridRows];
  const searchByString = searchTextFieldValue?.toLowerCase().trim() ?? '';
  if (searchByString.length >= 3) {
    filteredGoalGridRows = filteredGoalGridRows.filter((goalGridRow) =>
      searchIfContainedInObj(
        goalGridRow,
        ['goal', 'endDate.message', 'status', 'pathwayDisplay'],
        searchByString
      )
    );
  }
  const { startDate, endDate, selectedPathways, status } = filters;

  return filteredGoalGridRows.filter((goalRow) => {
    const goal = goalRow.wrappedGoal;
    const goalStartDate = moment(goal?.startDate ?? null);
    const goalEndDate = moment(goal?.getEndDate?.() ?? null);
    if (startDate?.isValid?.() && endDate?.isValid?.()) {
      if (!goalStartDate.isValid() || !goalEndDate.isValid()) return false;

      if (!isBetweenDates(goalStartDate, goalEndDate, startDate, endDate)) return false;
    }

    if (startDate?.isValid?.()) {
      if (!goalStartDate.isValid()) return false;
      if (!goalStartDate.isSameOrAfter(startDate)) return false;
    }

    if (endDate?.isValid?.()) {
      if (!goalEndDate.isValid()) return false;
      if (!goalEndDate.isSameOrBefore(endDate)) return false;
    }

    if (selectedPathways.length) {
      if (!selectedPathways.find((pathway) => pathway.code === goal?.getPathway?.())) return false;
    }

    if (status.length) {
      if (!status.find((status) => status.code === goal?.lifecycleStatus)) return false;
    }
    return true;
  });
};

const getGoalsByPathWay = (pathwayCode: string | null, goals: GoalGridRowData[]) => {
  if (!pathwayCode || pathwayCode === pathwayAll.code) return goals;
  return goals.filter(({ pathway }) => pathway === pathwayCode);
};

interface SeverityStatusCountProps {
  count: number;
  severity: AlertSeverity;
}

const SeverityStatusCount = ({ count, severity }: SeverityStatusCountProps) => (
  <SeverityStatus status={{ message: String(count), severity }} sx={{ height: '20px', ml: 0.5 }} />
);

export default GoalsByPathway;
