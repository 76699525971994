import { Grid, Autocomplete, TextField } from '@mui/material';
import { ToggleButtonOptions, OptionChangeFunction } from '../../ToggleButtonOptions';
import { RangeOption, RANGE_OPTIONS } from '../../../utils/date-range';
import {
  ACTIVITIES_VIEW_TYPES,
  ACTIVITIES_LIST_TYPES,
  activityStatusOptions,
  activityDueStatusOptions,
  ActivityDueStatusType,
  ActivityStatusType,
} from '../constants';
import PatientSearchDropdown from 'src/sections/careflow/common/PatientSearchDropdown';

type ActivityFiltersPropsType = {
  status: ActivityStatusType;
  dueStatus: ActivityDueStatusType;
  patients: any[];
  selectedPatient: { label: string; value: string } | null;
  onChangeCurrentRange: OptionChangeFunction;
  onChangeActivitiesViewType: (viewType: ACTIVITIES_VIEW_TYPES) => void;
  onChangeActivitiesListType: (listType: ACTIVITIES_LIST_TYPES) => void;
  onChangeStatus: (_: any, value: any | null) => void;
  onChangeDueStatus: (_: any, value: any | null) => void;
  onChangePatient: (value: string | null) => void;
  activitiesListType: ACTIVITIES_LIST_TYPES;
  activitiesViewType: ACTIVITIES_VIEW_TYPES;
  activitiesRange: string;
};

const ActivityFilters = ({
  status,
  dueStatus,
  patients,
  selectedPatient,
  onChangeCurrentRange,
  onChangeActivitiesViewType,
  onChangeActivitiesListType,
  onChangeStatus,
  onChangeDueStatus,
  onChangePatient,
  activitiesListType,
  activitiesViewType,
  activitiesRange,
}: ActivityFiltersPropsType) => (
  <Grid item xl={12}>
    <Grid container spacing={4}>
      <Grid item>
        <ToggleButtonOptions
          selectedValue={activitiesViewType}
          options={[
            { value: ACTIVITIES_VIEW_TYPES.LIST, disabled: activitiesRange === RangeOption.ALL },
            {
              value: ACTIVITIES_VIEW_TYPES.CALENDAR,
              disabled: activitiesRange === RangeOption.ALL,
            },
          ]}
          onOptionChange={(selectedOption) =>
            selectedOption.value &&
            onChangeActivitiesViewType(selectedOption.value as ACTIVITIES_VIEW_TYPES)
          }
        />
      </Grid>
      <Grid item>
        {/*  */}
        <ToggleButtonOptions
          options={RANGE_OPTIONS}
          onOptionChange={onChangeCurrentRange}
          selectedValue={activitiesRange}
        />
      </Grid>
      <Grid item>
        <ToggleButtonOptions
          selectedValue={activitiesListType}
          options={[
            { value: ACTIVITIES_LIST_TYPES.STAFF },
            { value: ACTIVITIES_LIST_TYPES.PATIENT },
          ]}
          onOptionChange={(selectedOption) =>
            onChangeActivitiesListType(selectedOption.value as ACTIVITIES_LIST_TYPES)
          }
        />
      </Grid>
      {activitiesListType === ACTIVITIES_LIST_TYPES.PATIENT && (
        <Grid item>
          <PatientSearchDropdown
            value={selectedPatient}
            onChange={(_: any, value: any | null) => {
              onChangePatient(value || null);
            }}
            sx={{ width: 300 }}
            useFhirId
            label="Patient"
          />
        </Grid>
      )}
      <Grid item>
        <Autocomplete
          options={activityStatusOptions}
          sx={{ width: 300 }}
          value={activityStatusOptions.find((i) => i.value === status)}
          getOptionLabel={(value) => value.label}
          onChange={onChangeStatus}
          disableClearable
          renderInput={(params) => <TextField {...params} label="Status" />}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          sx={{ width: 300 }}
          options={activityDueStatusOptions}
          value={activityDueStatusOptions.find((i) => i.value === dueStatus)}
          getOptionLabel={(value) => value.label}
          onChange={onChangeDueStatus}
          disableClearable
          renderInput={(params) => <TextField {...params} label="Due Status" />}
          disabled={['completed', 'cancelled'].includes(status)}
        />
      </Grid>
    </Grid>
  </Grid>
);

export default ActivityFilters;
