import {
  Box,
  Button,
  Card,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import DocumentRow from './DocumentRow';
import useAuth from 'src/hooks/useAuth';
import useTable from 'src/hooks/useTable';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';
import DocumentsFilters from './DocumentsFilters';
import { TableHeadCustom } from 'src/components/table';
import { parseShortFormat } from 'src/utils/formatTime';
import DocumentsTagFiltered from './DocumentsTagFiltered';
import { AttachmentAddForm } from 'src/sections/crs/common';
import { useDocumentReferences } from 'src/@nicheaim/fhir-react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { TABLE_HEAD_DOCUMENTS_PATIENT } from 'src/sections/crs/common/table-head';
import { DocumentReference } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import {
  AddAttachments,
  checkAclValidation,
  EditAttachments,
  getRelatedAcls,
} from 'src/utils/permissions/permission.utils';

type Props = {
  patient: WrappedPatient | null;
  documentReferences: DocumentReference[];
  resource?: any;
  showFilters?: boolean;
  showCollapse?: boolean;
  refreshDocumentReference: any;
  openCollapseExternal: boolean;
  handleUpdateResource: any;
};

export function DocumentList({
  patient,
  documentReferences,
  resource,
  showFilters,
  showCollapse,
  refreshDocumentReference,
  openCollapseExternal,
  handleUpdateResource,
}: Props) {
  const user = useAuth();
  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});

  const [, { create: createDocumentReference }] = useDocumentReferences({ autofetch: false });

  const [filterName, setFilterName] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterDate, setFilterDate] = useState<Date | null>(null);
  const [filterCustodian, setFilterCustodian] = useState('');
  const [filterCategory, setFilteCategory] = useState<string[]>([]);
  const [filterAuthor, setFilteAuthor] = useState<string[]>([]);
  const [openAttachment, setOpenAttachment] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(showFilters);

  useEffect(() => {
    setOpenCollapse(openCollapseExternal);
  }, [openCollapseExternal]);

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
  };

  const handleFilterType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterType(event.target.value);
  };

  const handleFilterCustodian = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterCustodian(event.target.value);
  };

  const handleFilterCategory = (filterCategory: string[]) => {
    setFilteCategory(filterCategory);
  };

  const handleFilterAuthor = (filterAuthor: string[]) => {
    setFilteAuthor(filterAuthor);
  };

  const handleCloseAddAttachment = () => {
    setOpenAttachment(false);
  };

  const handleClearAll = () => {
    setFilterName('');
    setFilterDate(null);
    setFilterType('');
    setFilterCustodian('');
    setFilteAuthor([]);
    setFilteCategory([]);
  };

  const filtersFiltered = {
    filterName,
    filterDate,
    filterType,
    filterCustodian,
    filterAuthor,
    filterCategory,
  };

  const filteredDocuments = applyFilter(documentReferences, filtersFiltered);

  const editAttachment = checkAclValidation({ user, acl: getRelatedAcls(EditAttachments) });
  const columns = editAttachment
    ? TABLE_HEAD_DOCUMENTS_PATIENT
    : TABLE_HEAD_DOCUMENTS_PATIENT.filter((e) => e.id !== 'actions');

  return (
    <Card>
      <Stack sx={{ m: 2 }}>
        <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
          <Grid item xs={10}>
            <Typography>
              Attachments
              {showCollapse && (
                <IconButton onClick={() => setOpenCollapse(!openCollapse)}>
                  <Iconify
                    icon={
                      openCollapse ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'
                    }
                  />
                </IconButton>
              )}
            </Typography>
          </Grid>
          {checkAclValidation({ user, acl: getRelatedAcls(AddAttachments) }) && (
            <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
              <Button size="small" sx={{ height: '36px' }} onClick={() => setOpenAttachment(true)}>
                Add An Attachment
              </Button>
            </Grid>
          )}
        </Grid>
      </Stack>
      {showFilters && (
        <Stack direction="row" justifyContent="right" sx={{ m: 2 }}>
          <DocumentsTagFiltered filters={filtersFiltered} onClearAll={handleClearAll} />

          <DocumentsFilters
            filterName={filterName}
            filterType={filterType}
            filterDate={filterDate}
            filterCustodian={filterCustodian}
            onFilterName={handleFilterName}
            onFilterType={handleFilterType}
            onFilterDate={(newValue) => {
              setFilterDate(newValue);
            }}
            onFilterCustodian={handleFilterCustodian}
            onFilterCategory={handleFilterCategory}
            onFilterAuthor={handleFilterAuthor}
            onClearAll={handleClearAll}
          />
        </Stack>
      )}
      <Collapse in={openCollapse}>
        <TableContainer>
          <Table size="small" sx={{ mb: 2 }}>
            <TableHeadCustom headLabel={columns} />
            <TableBody>
              {!isEmpty(filteredDocuments) ? (
                filteredDocuments
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <DocumentRow
                      key={`document-row-${index}`}
                      row={row}
                      patientId={patient?.getMRN()?.value}
                      editAttachment={editAttachment}
                      refreshDocumentReference={refreshDocumentReference}
                    />
                  ))
              ) : (
                <TableCell colSpan={TABLE_HEAD_DOCUMENTS_PATIENT?.length}>
                  <Typography variant="body2" align="center">
                    No rows
                  </Typography>
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredDocuments ? filteredDocuments.length : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </Box>
      </Collapse>
      <AttachmentAddForm
        open={openAttachment}
        patient={patient}
        resource={resource}
        onCancel={handleCloseAddAttachment}
        createDocumentReference={createDocumentReference}
        refreshDocumentReference={refreshDocumentReference}
        handleUpdateResource={handleUpdateResource}
      />
    </Card>
  );
}

function applyFilter(documents: DocumentReference[], filtersFiltered: any) {
  if (filtersFiltered.filterName) {
    documents = documents.filter((document) =>
      document.content?.[0].attachment.title?.includes(filtersFiltered.filterName)
    );
  }

  if (filtersFiltered.filterDate) {
    const dateFormated = parseShortFormat(filtersFiltered.filterDate.toString());
    documents = documents.filter((document) =>
      parseShortFormat(document.date || '').includes(dateFormated)
    );
  }

  if (filtersFiltered.filterType) {
    documents = documents.filter((document) =>
      document.type?.coding?.[0].display?.includes(filtersFiltered.filterType)
    );
  }

  if (filtersFiltered.filterCustodian) {
    documents = documents.filter((document) =>
      document.custodian?.display?.includes(filtersFiltered.filterCustodian)
    );
  }

  if (filtersFiltered.filterAuthor.length > 0) {
    documents = documents.filter((document) =>
      document.author?.some((e) => filtersFiltered.filterAuthor.includes(e.display))
    );
  }

  if (filtersFiltered.filterCategory.length > 0) {
    documents = documents.filter((document) =>
      document.category?.some((e) => filtersFiltered.filterCategory.includes(e.coding?.[0].display))
    );
  }

  return documents;
}
