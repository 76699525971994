import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as actions from './actions';

export function useSystemSettings() {
  return useQuery(['system-settings'], () => actions.getSystemSettings());
}

export function useSystemSetting(name: string) {
  return useQuery(['system-settings', name], () => actions.getSystemSetting(name));
}

export function useUpdateSystemSettingMutation(name: string) {
  const client = useQueryClient();
  return useMutation((value: any) => actions.updateSystemSetting(name, value), {
    onSuccess: (data) => {
      client.refetchQueries({
        predicate: (query) => query.queryKey[0] === 'system-settings',
      });
    },
  });
}
