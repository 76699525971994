import Iconify from "src/components/Iconify";
import InputStyle from "src/components/InputStyle";
import { InputAdornment, MenuItem, Stack, TextField } from "@mui/material";

const TAGS_TYPE = [
  'all',
  'doctors',
  'referral',
  'internal'
];

type Props = {
  filterName: string;
  filterTag: string;
  onFilterName: (value: string) => void;
  onFilterTag: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function OutboundReferralFilters({ filterName, filterTag, onFilterName, onFilterTag }: Props) {

  return (
    <>
      <Stack width={1} textAlign="center">
        <InputStyle
          placeholder="Search..."
          value={filterName}
          onChange={(event) => onFilterName(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack width={1} alignItems="flex-start">
        <TextField
          select
          size='medium'
          label="Category"
          value={filterTag}
          onChange={onFilterTag}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          sx={{
            width: 200,
            textTransform: 'capitalize',
          }}
        >
          {TAGS_TYPE.map((option) => (
            <MenuItem
              key={option}
              value={option}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </>
  )
}