import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Button } from '@mui/material';
// components
import { FormProvider, RHFSelect, RHFTextField } from '../../../../components/hook-form';
import {
  CarePlanCondition,
  conditionStatus,
  conditionTypes,
} from 'src/_mock/settings/_careplan-automation';
import api from 'src/services/api';
import { MenuItem } from '@mui/material';

// ----------------------------------------------------------------------

type FormValue = Pick<CarePlanCondition, 'type' | 'name' | 'notes' | 'rank' | 'status'>;

type ConditionsFormProps = {
  isEdit?: boolean;
  currentCondition?: CarePlanCondition;
};

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  type: Yup.string().oneOf(['medical', 'behavioral', 'social']).required('Type is required'),
  notes: Yup.string().default('N/A'),
  rank: Yup.number().min(-1).required('Rank is required'),
  status: Yup.string().oneOf(['active', 'inactive']).required('Status is required'),
});

const resolver = yupResolver(schema);

export default function ConditionsForm({ isEdit, currentCondition }: ConditionsFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () =>
      ({
        name: currentCondition?.name || '',
        type: currentCondition?.type || 'medical',
        notes: currentCondition?.notes || '',
        rank: currentCondition?.rank || -1,
        status: currentCondition?.status || 'active',
      } as FormValue),
    [currentCondition]
  );

  const methods = useForm({ resolver, defaultValues });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentCondition) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentCondition]);

  const selectedRank = watch('rank');

  const onSubmit = async (data: FormValue) => {
    try {
      if (isEdit && currentCondition) {
        await api.carePlan.updateCondition({
          ...data,
          uuid: currentCondition.uuid,
        });
      } else {
        await api.carePlan.createCondition(data);
      }
      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      navigate('..');
    } catch (error) {
      const message =
        error.response?.data?.message?.[0] || error?.message || 'Something went wrong!';

      enqueueSnackbar(message, {
        variant: 'error',
      });

      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 3 }}>
        <Stack
          gap={3}
          sx={{
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <RHFSelect name="type" label="Condition Type">
            {Object.entries(conditionTypes).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </RHFSelect>

          <RHFTextField name="name" label="Condition Name" />
          <RHFTextField name="notes" label="Notes" />
          {selectedRank > 0 ? (
            <RHFTextField name="rank" label="Rank" type="number" />
          ) : (
            <RHFSelect name="rank" label="Rank">
              <MenuItem value={1}>Enter an index</MenuItem>
              <MenuItem value={0}>Add to First</MenuItem>
              <MenuItem value={-1}>Add to Last</MenuItem>
            </RHFSelect>
          )}

          {isEdit && (
            <RHFSelect name="status" label="Status">
              {Object.entries(conditionStatus).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </RHFSelect>
          )}
        </Stack>
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }} gap={2}>
          <Button variant="text" color="inherit" component={RouterLink} to="..">
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {!isEdit ? 'Create Condition' : 'Save Changes'}
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
