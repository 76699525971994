import { Button, Card, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { usePatient, usePatients } from 'src/@nicheaim/fhir-react';
import { useEffect, useMemo } from 'react';
import produce from 'immer';

type PatientsFormValue = {
  given: string;
  family: string;
};

export default function PatientsForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [, { create }] = usePatients({ autofetch: false });
  const [patient, { update }] = usePatient(id!);
  const isEditting = !!id;

  const defaultValues = useMemo(
    () => ({
      given: patient?.name?.[0].given?.[0] || '',
      family: patient?.name?.[0].family || '',
    }),
    [patient, isEditting]
  );

  const form = useForm({
    defaultValues: {
      given: patient?.name?.[0].given?.[0] || '',
      family: patient?.name?.[0].family || '',
    },
  });
  const { handleSubmit } = form;

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues]);

  const onSubmit = handleSubmit(async (values: PatientsFormValue) => {
    if (isEditting) {
      await update(
        produce(patient!, (draft) => {
          draft.name ??= [];
          draft.name[0] ??= {};
          draft.name[0].family = values.family;
          draft.name[0].given ??= [];
          draft.name[0].given[0] = values.given;
        })
      );
    } else {
      console.log(
        await create({
          resourceType: 'Patient',
          name: [{ given: [values.given], family: values.family }],
        })
      );
    }

    form.reset(defaultValues);
  });

  return (
    <FormProvider methods={form} onSubmit={onSubmit}>
      <Card sx={{ p: 3 }}>
        <Typography variant="h6" pb={1}>
          Create Patient
        </Typography>

        <Stack direction="row" gap={3}>
          <RHFTextField name="family" label="Family" />
          <RHFTextField name="given" label="Given" />
        </Stack>

        <Stack direction="row" gap={1} mt={2}>
          <Button variant="contained" type="submit">
            {isEditting ? 'Save Changes' : 'Create Patient'}
          </Button>

          {isEditting && (
            <Button variant="outlined" onClick={() => navigate('..')}>
              Cancel
            </Button>
          )}
        </Stack>
      </Card>
    </FormProvider>
  );
}
