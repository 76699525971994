import { MenuItem, TextField } from '@mui/material';
import { ChangeEvent } from 'react';

const raceList = [
  {
    code: '1002-5',
    display: 'American Indian or Alaska Native',
  },
  {
    code: '2028-9',
    display: 'Asian',
  },
  {
    code: '2054-5',
    display: 'Black or African American',
  },
  {
    code: '2076-8',
    display: 'Native Hawaiian or Other Pacific Islander',
  },
  {
    code: '2106-3',
    display: 'White',
  },
  {
    code: '2029-7',
    display: 'Asian Indian',
  },
  {
    code: '2034-7',
    display: 'Chinese',
  },
  {
    code: '2036-2',
    display: 'Filipino',
  },
  {
    code: '2039-6',
    display: 'Japanese',
  },
  {
    code: '2040-4',
    display: 'Korean',
  },
  {
    code: '2047-9',
    display: 'Vietnamese',
  },
  {
    code: '2079-2',
    display: 'Native Hawaiian',
  },
  {
    code: '2086-7',
    display: 'Guamanian or Chamorro',
  },
  {
    code: '2080-0',
    display: 'Samoan',
  },
  {
    code: '2500-7',
    display: 'Other Pacific Islander',
  },
  {
    code: '2131-1',
    display: 'Other Race',
  },
];

interface RaceSelectorProps {
  onChange?: (value: any) => void;
  value: any
}

const RaceSelector = ({onChange, value} : RaceSelectorProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const race = raceList.find((r) => r.code === event.target.value);
    if (onChange) {
      onChange(race);
    }
  };

  return (
    <TextField
      fullWidth
      select
      label="Race"
      margin="dense"
      variant="standard"
      value={value?.code}
      onChange={handleChange}
    >
      {raceList.map((r) => (
        <MenuItem key={`race-${r.code}`} value={r.code}>
          {r.display}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default RaceSelector;
