import { Box } from '@mui/material';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import useCarePlanAutomationFormStore from '../store';
import AddRuleButton from './AddRuleButton';

interface QuestionFooterProps {
  question: WrappedQuestionnaireItem;
}

function QuestionFooter({ question }: QuestionFooterProps) {
  const rulesCount = useCarePlanAutomationFormStore(
    (s) => s.rules.filter((r) => r.question === question.linkId).length
  );

  return (
    <Box sx={{ pl: 1, pt: Number(rulesCount > 0) }}>
      <AddRuleButton question={question} />
    </Box>
  );
}

export default QuestionFooter;
