import { createContext, ReactNode, useEffect, useState } from 'react';
import { TENANT_INFO } from 'src/config';

type TenantThemeProps = {
  children: ReactNode;
};

type TenantTheme = {
  logo: string | undefined;
  pagePrefix: string | undefined;
  tenantName: string | undefined;
  tenantFavicon: string | undefined;
  tenantSpinnerLogo: string | undefined;
};

const initTenantTheme = {
  logo: undefined,
  pagePrefix: undefined,
  tenantFavicon: undefined,
  tenantName: undefined,
  tenantSpinnerLogo: undefined,
};

const TenantThemeContext = createContext<TenantTheme>(initTenantTheme);

function TenantThemeProvider({ children }: TenantThemeProps) {
  const [tenantTheme, setTenantTheme] = useState<TenantTheme>(initTenantTheme);

  useEffect(() => {
    const fetchTenantTheme = async () => {
      try {
        const data = {
          logo: TENANT_INFO.logo,
          pagePrefix: TENANT_INFO.pagePrefix,
          tenantFavicon: TENANT_INFO.tenantFavicon,
          tenantName: TENANT_INFO.tenantName,
          tenantSpinnerLogo: TENANT_INFO.tenantSpinnerLogo,
        };
        setTenantTheme(data);
      } catch (e) {
        console.error(e);
      }
    };
    fetchTenantTheme();
  }, []);

  return <TenantThemeContext.Provider value={tenantTheme}>{children}</TenantThemeContext.Provider>;
}

export { TenantThemeContext, TenantThemeProvider };
