import { Typography , Stack , useTheme } from '@mui/material';
import ReactSelect, { GroupBase, Props, StylesConfig, ThemeConfig } from 'react-select';
import ReactCreatableSelect, { CreatableProps } from 'react-select/creatable';

interface CustomSelectProps {
  error?: string | boolean | null;
}

export function Select<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group> & CustomSelectProps) {
  const { theme, styles } = useCustomization<Option, IsMulti, Group>(props);

  return (
    <Stack>
      <ReactSelect theme={theme} styles={styles} menuPortalTarget={document.body} {...props} />
      {props.error && <ErrorMessage error={props.error} />}
    </Stack>
  );
}

export function CreatableSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: CreatableProps<Option, IsMulti, Group> & CustomSelectProps) {
  const { theme, styles } = useCustomization<Option, IsMulti, Group>(props);

  return (
    <Stack>
      <ReactCreatableSelect
        theme={theme}
        styles={styles}
        menuPortalTarget={document.body}
        {...props}
      />
      {props.error && <ErrorMessage error={props.error} />}
    </Stack>
  );
}

function ErrorMessage({ error }: Pick<CustomSelectProps, 'error'>) {
  return (
    <Typography color="error" variant="caption">
      {error}
    </Typography>
  );
}

function useCustomization<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group> & CustomSelectProps) {
  const theme = useTheme();

  const customTheme: ThemeConfig = (defaultTheme) => ({
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      primary: theme.palette.primary.main,
      primary75: theme.palette.primary.dark,
      primary50: theme.palette.primary.light,
      primary25: theme.palette.primary.lighter,
    },
  });

  const styles: StylesConfig<Option, IsMulti, Group> = {
    ...props.styles,
    control: (provided, state) => ({
      ...provided,
      ...props.styles?.control,
      borderColor: props.error
        ? theme.palette.error.light
        : state.isFocused
        ? theme.palette.primary.main
        : theme.palette.grey[300],
      boxShadow: state.isFocused
        ? `0 0 0 1px ${props.error ? theme.palette.error.main : theme.palette.grey[300]}`
        : 'none',
      '&:hover': {
        borderColor: props.error
          ? theme.palette.error.main
          : state.isFocused
          ? theme.palette.primary.main
          : theme.palette.grey[400],
      },
    }),
  };

  return {
    theme: customTheme,
    styles,
  };
}
