import client from './_client';

const goalBaseUrl = '/goal';
const taskBaseUrl = '/task';
const careTeamBaseUrl = '/careTeam';

export const createGoal = async (payload: any): Promise<any> => {
  try {
    const { data } = await client.post(goalBaseUrl, payload);
    return data;
  } catch (error) {
    return false;
  }
};

export const editGoal = async (payload: any, goalId: string): Promise<any> => {
  try {
    const { data } = await client.put(`${goalBaseUrl}/${goalId}`, payload);
    return data;
  } catch (error) {
    return false;
  }
};

export const createTask = async (payload: any): Promise<any> => {
  try {
    const { data } = await client.post(taskBaseUrl, payload);
    return data;
  } catch (error) {
    return false;
  }
};

export const updateTask = async (payload: any, taskId: string): Promise<any> => {
  try {
    const { data } = await client.put(`${taskBaseUrl}/${taskId}`, payload);
    return data;
  } catch (error) {
    return false;
  }
};

export const updateCareTeamMembers = async (payload: any, careTeamId: string): Promise<any> => {
  try {
    const { data } = await client.put(`${careTeamBaseUrl}/${careTeamId}/members`, payload);
    return data;
  } catch (error) {
    return false;
  }
};

export const updateMemberPeriod = async (payload: any, careTeamId: string): Promise<any> => {
  try {
    const { data } = await client.put(`${careTeamBaseUrl}/${careTeamId}/member`, payload);
    return data;
  } catch (error) {
    return false;
  }
};

export const createCareTeam = async (payload: any): Promise<any> => {
  try {
    const { data } = await client.post(careTeamBaseUrl, payload);
    return data;
  } catch (error) {
    return false;
  }
};

export const updateCareTeam = async (payload: any, careTeamId: string): Promise<any> => {
  try {
    const { data } = await client.put(`${careTeamBaseUrl}/${careTeamId}`, payload);
    return data;
  } catch (error) {
    return false;
  }
};
