import { Grid, Button } from '@mui/material';
import CustomDrawer, { CustomDrawerProps } from './CustomDrawer';

export interface FilterDrawerProps extends CustomDrawerProps {
  onApplyButtonClick: Function;
  onClearAllButtonClick: Function;
}

const FilterDrawer = ({
  onApplyButtonClick,
  onClearAllButtonClick,
  children,
  ...props
}: FilterDrawerProps) => (
  <CustomDrawer {...props}>
    <>
      {children}
      <Grid container marginTop={3} justifyContent={'space-between'}>
        <Grid item xs={5.8}>
          <Button
            sx={{ height: 48 }}
            fullWidth
            onClick={() => {
              onApplyButtonClick();
            }}
            variant="contained"
          >
            Apply
          </Button>
        </Grid>
        <Grid item xs={5.8} marginBottom={3}>
          <Button
            fullWidth
            sx={{ height: 48, color: '#212b36', borderColor: 'rgba(145, 158, 171, 0.32)' }}
            onClick={() => {
              onClearAllButtonClick();
            }}
            variant="outlined"
          >
            Clear All
          </Button>
        </Grid>
      </Grid>
    </>
  </CustomDrawer>
);

export default FilterDrawer;
