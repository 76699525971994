import { ReactNode, useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';

interface IStandardDialogProviderProps {
  children: ReactNode;
  meta?: ReactNode;
  //   handleOnClose: () => void;
}

export default function AuthTokenGuard({ children, meta }: IStandardDialogProviderProps) {
  const { validateCareflowToken } = useAuth();
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);

  useEffect(() => {
    const _isTokenValid = validateCareflowToken();
    console.log('AuthTokenGuard->_isTokenValid: ', _isTokenValid);
    setIsTokenValid(() => _isTokenValid);
  }, []);

  if (isTokenValid) {
    return <>{children}</>;
  }

  return <div>Needs to login again</div>;
}
