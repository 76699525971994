import { Navigate, Route, Routes, useParams } from 'react-router';
import { _cpd } from 'src/_mock/settings/_careplan-automation';
import CarePlanDurationTable from './CarePlanDurationTable';

function CarePlanOthersTab() {
  return (
    <Routes>
      <Route index element={<CarePlanDurationTable />} />
    </Routes>
  );
}

export default Object.assign(CarePlanOthersTab, {
  path: 'others',
});
