import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { useActivityDefinitions } from 'src/@nicheaim/fhir-react';
import fhirSystem from '../system';

export function useActiveActivityDefinitions(
  options?: Parameters<typeof useActivityDefinitions>[0]
) {
  const [activityDefinitions, queryStatus] = useActivityDefinitions({
    ...(options as any),
    filter: {
      status: 'active',
      identifier: fhirSystem.activity.forCode(''),
      _sort: 'title',
      ...options?.filter,
    },
  });

  const sortedActivityDefinitions = useMemo(
    () => sortBy(activityDefinitions, ['topic.0.coding.0.display', 'title']),
    [activityDefinitions]
  );

  return [sortedActivityDefinitions, queryStatus] as const;
}

export function useAllActivityDefinitions(options?: Parameters<typeof useActivityDefinitions>[0]) {
  const [activityDefinitions, queryStatus] = useActivityDefinitions({
    ...(options as any),
    filter: {
      identifier: fhirSystem.activity.forCode(''),
      _sort: 'title',
      ...options?.filter,
    },
  });

  const sortedActivityDefinitions = useMemo(
    () => sortBy(activityDefinitions, ['topic.0.coding.0.display', 'title']),
    [activityDefinitions]
  );

  return [sortedActivityDefinitions, queryStatus] as const;
}
