import { Box, Typography } from '@mui/material';
import DatePickerMoment, { DatePickerMomentProps } from './DatePickerMoment';
import moment from 'moment';

export type onDateChange = DatePickerMomentProps['onChange'];
export interface PeriodFilterProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  onStartDateChange: onDateChange;
  onEndDateChange: onDateChange;
}

const PeriodFilter = ({
  onStartDateChange,
  onEndDateChange,
  startDate,
  endDate,
}: PeriodFilterProps) => (
  <Box>
    <Typography fontSize={'1rem'} fontWeight={'bold'} marginBottom={1.4}>
      Period
    </Typography>
    <DatePickerMoment
      containerSx={{ mb: 2 }}
      label={'Start Date'}
      value={startDate}
      onChange={onStartDateChange}
    />
    <DatePickerMoment label={'End Date'} value={endDate} onChange={onEndDateChange} />
  </Box>
);

export default PeriodFilter;
