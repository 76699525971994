export const getGroupSessionsTableColumns = [
  {
    label: 'Session Name',
  },
  {
    label: 'Description',
  },
  {
    label: 'Location',
  },
  {
    label: 'Attendees',
  },
  {
    label: 'Assessments',
  },
  {
    label: 'Status',
  },
  {
    label: 'Actions',
  }
]

export const getSessionsTableColumns = [
  {
    label: 'Date / Time',
  },
  {
    label: 'Location',
  },
  {
    label: 'Attendance',
  },
  {
    label: 'Status',
  },
  {
    label: 'Quick Note',
  },
  {
    label: 'Actions',
  }
];

export const getSessionUsersTableColumns = [
  {
    label: 'Patient'
  },
  {
    label: 'Assessments'
  },
  {
    label: 'Quick Note'
  },
  {
    label: 'Attended?'
  },
]