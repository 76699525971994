import { useQueryClient } from '@tanstack/react-query';
import produce from 'immer';
import { useNavigate } from 'react-router';
import { useCodeSystem, useCodeSystems } from 'src/@nicheaim/fhir-react';
import { CodeSystemConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { EntityContextMenu } from 'src/sections/careflow/common';
import api from 'src/services/api';
import { ProgramExitDestination } from 'src/services/api/patients/programs';
import { useDialogStore } from 'src/stores/dialog';

export interface ExitDestinationsContextMenuProps {
  item: ProgramExitDestination;
}

export default function ExitDestinationsContextMenu({ item }: ExitDestinationsContextMenuProps) {
  const navigate = useNavigate();
  const { confirmDialog } = useDialogStore();
  const { isActive } = item;
  const client = useQueryClient();

  return (
    <EntityContextMenu
      entity={item}
      items={[
        {
          label: 'Edit',
          icon: 'eva:edit-fill',
          onPress: () => navigate(`${item.uuid}/edit`),
        },
        isActive
          ? {
              label: 'Disable',
              icon: 'eva:trash-2-outline',
              onPress: async () => {
                const confirmed = await confirmDialog({
                  title: 'Disable',
                  description: 'Are you sure you want to disable this item?',
                });

                if (!confirmed) {
                  return;
                }

                await api.patients.programs.updateProgramExitDestination(item.uuid, {
                  isActive: false,
                });
                await client.refetchQueries(['program-exit-destinations']);
              },
            }
          : {
              label: 'Enable',
              onPress: async () => {
                const confirmed = await confirmDialog({
                  title: 'Enable',
                  description: 'Are you sure you want to enable this item?',
                });
                if (!confirmed) {
                  return;
                }

                await api.patients.programs.updateProgramExitDestination(item.uuid, {
                  isActive: true,
                });
                await client.refetchQueries(['program-exit-destinations']);
              },
              icon: 'eva:checkmark-circle-2-outline',
            },
      ]}
    />
  );
}
