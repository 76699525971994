interface User {
  id: string;
  name: string;
}

export const ahsManagerUser: User = {
  id: '1',
  name: 'AHS Manager',
};

interface LOB {
  id: string;
  name: string;
}

export const _lobs: LOB[] = [
  {
    id: '1',
    name: 'LOB 1',
  },
  {
    id: '2',
    name: 'LOB 2',
  },
  {
    id: '3',
    name: 'LOB 3',
  },
];

export interface Authoring {
  date: string;
  user: User;
}

// --------------------------------------------------------
export const conditionStatus = Object.freeze({
  ///////////////////////////////////////////////////////////////////
  active: 'Active', ///////////////////////////////////////////////////////////////////
  inactive: 'Inactive', ///////////////////////////////////////////////////////////////////
}) as Record<string, any>; ///////////////////////////////////////////////////////////////////
export type CarePlanConditionStatus = any; ////keyof typeof conditionStatus; ///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
export const conditionTypes = Object.freeze({
  ///////////////////////////////////////////////////////////////////
  medical: 'Medical', ///////////////////////////////////////////////////////////////////
  behavioral: 'Behavioral', ///////////////////////////////////////////////////////////////////
  social: 'Social', ///////////////////////////////////////////////////////////////////
}) as Record<string, any>; ///////////////////////////////////////////////////////////////////
export type CarePlanConditionType = any; ////keyof typeof conditionTypes; ///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
export type CarePlanCondition = any; //// {
////  ///////////////////////////////////////////////////////////////////
////  id: string; ///////////////////////////////////////////////////////////////////
////  name: string; ///////////////////////////////////////////////////////////////////
////  type: CarePlanConditionType; ///////////////////////////////////////////////////////////////////
////  notes: string; ///////////////////////////////////////////////////////////////////
////  rank: number; ///////////////////////////////////////////////////////////////////
////  status: CarePlanConditionStatus; ///////////////////////////////////////////////////////////////////
////  created: Authoring; ///////////////////////////////////////////////////////////////////
////  updated: Authoring | null; ///////////////////////////////////////////////////////////////////
////} ///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
export const _conditions: CarePlanCondition[] = [
  ///////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////
    id: '1', ///////////////////////////////////////////////////////////////////
    name: 'Siclke Cell Anemia', ///////////////////////////////////////////////////////////////////
    type: 'medical', ///////////////////////////////////////////////////////////////////
    notes: 'N/A', ///////////////////////////////////////////////////////////////////
    rank: 1, ///////////////////////////////////////////////////////////////////
    status: 'active', ///////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////
      date: '2022-07-01', ///////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////
    updated: {
      ///////////////////////////////////////////////////////////////////
      date: '2022-07-01', ///////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////
    id: '2', ///////////////////////////////////////////////////////////////////
    name: 'Thalassemia', ///////////////////////////////////////////////////////////////////
    type: 'medical', ///////////////////////////////////////////////////////////////////
    notes: 'N/A', ///////////////////////////////////////////////////////////////////
    rank: 2, ///////////////////////////////////////////////////////////////////
    status: 'active', ///////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////
      date: '2022-06-28', ///////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////
    id: '3', ///////////////////////////////////////////////////////////////////
    name: 'End Stage Renal Disease', ///////////////////////////////////////////////////////////////////
    type: 'medical', ///////////////////////////////////////////////////////////////////
    notes: 'N/A', ///////////////////////////////////////////////////////////////////
    rank: 3, ///////////////////////////////////////////////////////////////////
    status: 'active', ///////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////
      date: '2022-06-27', ///////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////
    id: '4', ///////////////////////////////////////////////////////////////////
    name: 'Schizophrenia', ///////////////////////////////////////////////////////////////////
    type: 'behavioral', ///////////////////////////////////////////////////////////////////
    notes: 'N/A', ///////////////////////////////////////////////////////////////////
    rank: 4, ///////////////////////////////////////////////////////////////////
    status: 'active', ///////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////
      date: '2022-06-22', ///////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////
    id: '5', ///////////////////////////////////////////////////////////////////
    name: 'Mental Retardation', ///////////////////////////////////////////////////////////////////
    type: 'behavioral', ///////////////////////////////////////////////////////////////////
    notes: 'N/A', ///////////////////////////////////////////////////////////////////
    rank: 5, ///////////////////////////////////////////////////////////////////
    status: 'active', ///////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////
      date: '2022-06-21', ///////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////
]; ///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
// --------------------------------------------------------

export const opportunityPriorities = Object.freeze({
  ///////////////////////////////////////////////////////////////////////////////////
  high: 'High', ///////////////////////////////////////////////////////////////////////////////////
  medium: 'Medium', ///////////////////////////////////////////////////////////////////////////////////
  low: 'Low', ///////////////////////////////////////////////////////////////////////////////////
} as const); ///////////////////////////////////////////////////////////////////////////////////
export type CarePlanOpportunityPriority = keyof typeof opportunityPriorities; ///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
export const opportunitySourceTypes = Object.freeze({
  ///////////////////////////////////////////////////////////////////////////////////
  sc: 'Scripts', ///////////////////////////////////////////////////////////////////////////////////
  br: 'Business Rules', ///////////////////////////////////////////////////////////////////////////////////
  both: 'Both', ///////////////////////////////////////////////////////////////////////////////////
  manual: 'Manual', ///////////////////////////////////////////////////////////////////////////////////
}) as Record<string, any>; ///////////////////////////////////////////////////////////////////////////////////
export type CarePlanOpportunitySourceType = any; //// keyof typeof opportunitySourceTypes; ///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
export const opportunityStatus = Object.freeze({
  ///////////////////////////////////////////////////////////////////////////////////
  active: 'Active', ///////////////////////////////////////////////////////////////////////////////////
  inactive: 'Inactive', ///////////////////////////////////////////////////////////////////////////////////
}) as Record<string, any>; ///////////////////////////////////////////////////////////////////////////////////
export type CarePlanOpportunityStatus = any; ////keyof typeof opportunityStatus; ///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
export type CarePlanOpportunity = any; //// {
////  ///////////////////////////////////////////////////////////////////////////////////
////  id: string; ///////////////////////////////////////////////////////////////////////////////////
////  name: string; ///////////////////////////////////////////////////////////////////////////////////
////  alias: string; ///////////////////////////////////////////////////////////////////////////////////
////  priority: CarePlanOpportunityPriority; ///////////////////////////////////////////////////////////////////////////////////
////  sourceType: CarePlanOpportunitySourceType; ///////////////////////////////////////////////////////////////////////////////////
////  status: CarePlanOpportunityStatus; ///////////////////////////////////////////////////////////////////////////////////
////  created: Authoring; ///////////////////////////////////////////////////////////////////////////////////
////  updated: Authoring | null; ///////////////////////////////////////////////////////////////////////////////////
////} ///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
export const _opportunities: CarePlanOpportunity[] = [
  ///////////////////////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////////////////////
    id: '1', ///////////////////////////////////////////////////////////////////////////////////
    name: 'Consider counseling the member for quitting alcohol', ///////////////////////////////////////////////////////////////////////////////////
    alias: 'Consider counseling the member for quitting alcohol', ///////////////////////////////////////////////////////////////////////////////////
    priority: 'high', ///////////////////////////////////////////////////////////////////////////////////
    sourceType: 'sc', ///////////////////////////////////////////////////////////////////////////////////
    status: 'active', ///////////////////////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////////////////////
      date: '2014-05-13', ///////////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////////////////////
    id: '2', ///////////////////////////////////////////////////////////////////////////////////
    name: 'Consider a cognitive assessment and follow up with the provider', ///////////////////////////////////////////////////////////////////////////////////
    alias: 'Consider a cognitive assessment and follow up with the provider', ///////////////////////////////////////////////////////////////////////////////////
    priority: 'high', ///////////////////////////////////////////////////////////////////////////////////
    sourceType: 'sc', ///////////////////////////////////////////////////////////////////////////////////
    status: 'inactive', ///////////////////////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////////////////////
      date: '2014-02-27', ///////////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////////////////////
    id: '3', ///////////////////////////////////////////////////////////////////////////////////
    name: 'Consider counseling the member for quitting alcohol', ///////////////////////////////////////////////////////////////////////////////////
    alias: 'Consider counseling the member for quitting alcohol', ///////////////////////////////////////////////////////////////////////////////////
    priority: 'high', ///////////////////////////////////////////////////////////////////////////////////
    sourceType: 'sc', ///////////////////////////////////////////////////////////////////////////////////
    status: 'active', ///////////////////////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////////////////////
      date: '2014-05-13', ///////////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////////////////////
    id: '4', ///////////////////////////////////////////////////////////////////////////////////
    name: 'Consider a cognitive assessment and follow up with the provider', ///////////////////////////////////////////////////////////////////////////////////
    alias: 'Consider a cognitive assessment and follow up with the provider', ///////////////////////////////////////////////////////////////////////////////////
    priority: 'high', ///////////////////////////////////////////////////////////////////////////////////
    sourceType: 'sc', ///////////////////////////////////////////////////////////////////////////////////
    status: 'inactive', ///////////////////////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////////////////////
      date: '2014-02-27', ///////////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////////////////////
    id: '5', ///////////////////////////////////////////////////////////////////////////////////
    name: 'Consider counseling the member for quitting alcohol', ///////////////////////////////////////////////////////////////////////////////////
    alias: 'Consider counseling the member for quitting alcohol', ///////////////////////////////////////////////////////////////////////////////////
    priority: 'high', ///////////////////////////////////////////////////////////////////////////////////
    sourceType: 'sc', ///////////////////////////////////////////////////////////////////////////////////
    status: 'active', ///////////////////////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////////////////////
      date: '2014-05-13', ///////////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////////////////////
    id: '6', ///////////////////////////////////////////////////////////////////////////////////
    name: 'Consider a cognitive assessment and follow up with the provider', ///////////////////////////////////////////////////////////////////////////////////
    alias: 'Consider a cognitive assessment and follow up with the provider', ///////////////////////////////////////////////////////////////////////////////////
    priority: 'high', ///////////////////////////////////////////////////////////////////////////////////
    sourceType: 'sc', ///////////////////////////////////////////////////////////////////////////////////
    status: 'active', ///////////////////////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////////////////////
      date: '2014-02-27', ///////////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////////////////////
]; ///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
// --------------------------------------------------------

export const conditionsToOpportunitiesStatus = Object.freeze({
  ///////////////////////////////////////////////////////////////////////////
  active: 'Active', ///////////////////////////////////////////////////////////////////////////
  inactive: 'Inactive', ///////////////////////////////////////////////////////////////////////////
}) as Record<string, any>; ///////////////////////////////////////////////////////////////////////////
export type CarePlanConditionsToOpportunitiesStatus = any; ////keyof typeof conditionsToOpportunitiesStatus; ///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
export type CarePlanConditionToOpportunity = any; ////{
////  ///////////////////////////////////////////////////////////////////////////
////  id: string; ///////////////////////////////////////////////////////////////////////////
////  condition: CarePlanCondition; ///////////////////////////////////////////////////////////////////////////
////  opportunity: CarePlanOpportunity; ///////////////////////////////////////////////////////////////////////////
////  status: CarePlanConditionsToOpportunitiesStatus; ///////////////////////////////////////////////////////////////////////////
////  created: Authoring; ///////////////////////////////////////////////////////////////////////////
////  updated: Authoring | null; ///////////////////////////////////////////////////////////////////////////
////} ///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
export const _conditionsToOpportunities: CarePlanConditionToOpportunity[] = [
  ///////////////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////////////
    id: '1', ///////////////////////////////////////////////////////////////////////////
    condition: _conditions[0], ///////////////////////////////////////////////////////////////////////////
    opportunity: _opportunities[0], ///////////////////////////////////////////////////////////////////////////
    status: 'active', ///////////////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////////////
      date: '2022-07-01', ///////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////////////
    id: '2', ///////////////////////////////////////////////////////////////////////////
    condition: _conditions[0], ///////////////////////////////////////////////////////////////////////////
    opportunity: _opportunities[1], ///////////////////////////////////////////////////////////////////////////
    status: 'active', ///////////////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////////////
      date: '2022-07-01', ///////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////////////
    id: '3', ///////////////////////////////////////////////////////////////////////////
    condition: _conditions[0], ///////////////////////////////////////////////////////////////////////////
    opportunity: _opportunities[0], ///////////////////////////////////////////////////////////////////////////
    status: 'active', ///////////////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////////////
      date: '2022-07-01', ///////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////////////
    id: '4', ///////////////////////////////////////////////////////////////////////////
    condition: _conditions[3], ///////////////////////////////////////////////////////////////////////////
    opportunity: _opportunities[2], ///////////////////////////////////////////////////////////////////////////
    status: 'active', ///////////////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////////////
      date: '2022-07-01', ///////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////////////
  {
    ///////////////////////////////////////////////////////////////////////////
    id: '5', ///////////////////////////////////////////////////////////////////////////
    condition: _conditions[4], ///////////////////////////////////////////////////////////////////////////
    opportunity: _opportunities[3], ///////////////////////////////////////////////////////////////////////////
    status: 'active', ///////////////////////////////////////////////////////////////////////////
    created: {
      ///////////////////////////////////////////////////////////////////////////
      date: '2022-07-01', ///////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, ///////////////////////////////////////////////////////////////////////////
    }, ///////////////////////////////////////////////////////////////////////////
    updated: null, ///////////////////////////////////////////////////////////////////////////
  }, ///////////////////////////////////////////////////////////////////////////
]; ///////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////
// --------------------------------------------------------
/////////////////////////////////////////////////////////////////////////////////

export const goalGroupStatus = Object.freeze({
  /////////////////////////////////////////////////////////////
  active: 'Active', /////////////////////////////////////////////////////////////////////////////////
  inactive: 'Inactive', /////////////////////////////////////////////////////////////////////////////////
}) as Record<string, any>; /////////////////////////////////////////////////////////////////////////////////
export type CarePlanGoalGroupStatus = any; ////keyof typeof goalGroupStatus;/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
export type CarePlanGoalGroup = any; //// {/////////////////////////////////////////////////////////////////////////////////
////  id: string;/////////////////////////////////////////////////////////////////////////////////
////  name: string;/////////////////////////////////////////////////////////////////////////////////
////  status: CarePlanGoalGroupStatus;/////////////////////////////////////////////////////////////////////////////////
////  created: Authoring;/////////////////////////////////////////////////////////////////////////////////
////  updated: Authoring | null;/////////////////////////////////////////////////////////////////////////////////
////}/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
export const _goalGroups: CarePlanGoalGroup[] = [
  /////////////////////////////////////////////////////////////////////////////////
  {
    /////////////////////////////////////////////////////////////////////////////////
    id: '1', /////////////////////////////////////////////////////////////////////////////////
    name: 'Access to care', /////////////////////////////////////////////////////////////////////////////////
    status: 'active', /////////////////////////////////////////////////////////////////////////////////
    created: {
      /////////////////////////////////////////////////////////////////////////////////
      date: '2014-05-13', /////////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, /////////////////////////////////////////////////////////////////////////////////
    }, /////////////////////////////////////////////////////////////////////////////////
    updated: null, /////////////////////////////////////////////////////////////////////////////////
  }, /////////////////////////////////////////////////////////////////////////////////
  {
    /////////////////////////////////////////////////////////////////////////////////
    id: '2', /////////////////////////////////////////////////////////////////////////////////
    name: 'Advanced directives ', /////////////////////////////////////////////////////////////////////////////////
    status: 'active', /////////////////////////////////////////////////////////////////////////////////
    created: {
      /////////////////////////////////////////////////////////////////////////////////
      date: '2014-02-27', /////////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, /////////////////////////////////////////////////////////////////////////////////
    }, /////////////////////////////////////////////////////////////////////////////////
    updated: null, /////////////////////////////////////////////////////////////////////////////////
  }, /////////////////////////////////////////////////////////////////////////////////
  {
    /////////////////////////////////////////////////////////////////////////////////
    id: '3', /////////////////////////////////////////////////////////////////////////////////
    name: 'Behavioral Health', /////////////////////////////////////////////////////////////////////////////////
    status: 'inactive', /////////////////////////////////////////////////////////////////////////////////
    created: {
      /////////////////////////////////////////////////////////////////////////////////
      date: '2014-01-01', /////////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, /////////////////////////////////////////////////////////////////////////////////
    }, /////////////////////////////////////////////////////////////////////////////////
    updated: null, /////////////////////////////////////////////////////////////////////////////////
  }, /////////////////////////////////////////////////////////////////////////////////
  {
    /////////////////////////////////////////////////////////////////////////////////
    id: '4', /////////////////////////////////////////////////////////////////////////////////
    name: 'Care', /////////////////////////////////////////////////////////////////////////////////
    status: 'inactive', /////////////////////////////////////////////////////////////////////////////////
    created: {
      /////////////////////////////////////////////////////////////////////////////////
      date: '2013-12-31', /////////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, /////////////////////////////////////////////////////////////////////////////////
    }, /////////////////////////////////////////////////////////////////////////////////
    updated: null, /////////////////////////////////////////////////////////////////////////////////
  }, /////////////////////////////////////////////////////////////////////////////////
]; /////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////
// --------------------------------------------------------
//////////////////////////////////////////////////////////
export const goalStatus = Object.freeze({
  //////////////////////////////////////////////////////////
  active: 'Active', //////////////////////////////////////////////////////////
  inactive: 'Inactive', //////////////////////////////////////////////////////////
}) as Record<string, any>; //////////////////////////////////////////////////////////
export type CarePlanGoalStatus = any; ////keyof typeof goalStatus;//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
export type CarePlanGoal = any; //// {//////////////////////////////////////////////////////////
////  id: string;//////////////////////////////////////////////////////////
////  name: string;//////////////////////////////////////////////////////////
////  alias: string;//////////////////////////////////////////////////////////
////  status: CarePlanGoalStatus;//////////////////////////////////////////////////////////
////  created: Authoring;//////////////////////////////////////////////////////////
////  updated: Authoring | null;//////////////////////////////////////////////////////////
////}//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
export const _goals: CarePlanGoal[] = [
  //////////////////////////////////////////////////////////
  {
    //////////////////////////////////////////////////////////
    id: '1', //////////////////////////////////////////////////////////
    name: 'Arrange safe transition plan.', //////////////////////////////////////////////////////////
    alias: 'Arrange safe transition plan.', //////////////////////////////////////////////////////////
    status: 'active', //////////////////////////////////////////////////////////
    created: {
      //////////////////////////////////////////////////////////
      date: '2013-12-31', //////////////////////////////////////////////////////////
      user: ahsManagerUser, //////////////////////////////////////////////////////////
    }, //////////////////////////////////////////////////////////
    updated: null, //////////////////////////////////////////////////////////
  }, //////////////////////////////////////////////////////////
  {
    //////////////////////////////////////////////////////////
    id: '2', //////////////////////////////////////////////////////////
    name: 'Eliminate alcohol consumption during pregnancy.', //////////////////////////////////////////////////////////
    alias: 'Eliminate alcohol consumption during pregnancy.', //////////////////////////////////////////////////////////
    status: 'active', //////////////////////////////////////////////////////////
    created: {
      //////////////////////////////////////////////////////////
      date: '2013-12-31', //////////////////////////////////////////////////////////
      user: ahsManagerUser, //////////////////////////////////////////////////////////
    }, //////////////////////////////////////////////////////////
    updated: null, //////////////////////////////////////////////////////////
  }, //////////////////////////////////////////////////////////
  {
    //////////////////////////////////////////////////////////
    id: '3', //////////////////////////////////////////////////////////
    name: 'Ensure a complete lab work on member.', //////////////////////////////////////////////////////////
    alias: 'Ensure a complete lab work on member.', //////////////////////////////////////////////////////////
    status: 'active', //////////////////////////////////////////////////////////
    created: {
      //////////////////////////////////////////////////////////
      date: '2013-12-31', //////////////////////////////////////////////////////////
      user: ahsManagerUser, //////////////////////////////////////////////////////////
    }, //////////////////////////////////////////////////////////
    updated: null, //////////////////////////////////////////////////////////
  }, //////////////////////////////////////////////////////////
]; //////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////
// --------------------------------------------------------

export const goalToGoalGroupStatus = Object.freeze({
  ////////////////////////////////////////////////////////////
  active: 'Active', ////////////////////////////////////////////////////////////
  inactive: 'Inactive', ////////////////////////////////////////////////////////////
}) as Record<string, any>; ////////////////////////////////////////////////////////////
export type CarePlanGoalToGoalGroupStatus = any; ////keyof typeof goalToGoalGroupStatus;////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
export type CarePlanGoalToGoalGroup = any; ///// {////////////////////////////////////////////////////////////
////  id: string;////////////////////////////////////////////////////////////
////  goal: CarePlanGoal;////////////////////////////////////////////////////////////
////  goalGroup: CarePlanGoalGroup;////////////////////////////////////////////////////////////
////  status: CarePlanGoalToGoalGroupStatus;////////////////////////////////////////////////////////////
////  created: Authoring;////////////////////////////////////////////////////////////
////  updated: Authoring | null;////////////////////////////////////////////////////////////
////}////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
export const _goalsToGoalGroups: CarePlanGoalToGoalGroup[] = [
  ////////////////////////////////////////////////////////////
  {
    ////////////////////////////////////////////////////////////
    id: '1', ////////////////////////////////////////////////////////////
    goal: _goals[0], ////////////////////////////////////////////////////////////
    goalGroup: _goalGroups[0], ////////////////////////////////////////////////////////////
    status: 'active', ////////////////////////////////////////////////////////////
    created: {
      ////////////////////////////////////////////////////////////
      date: '2013-12-31', ////////////////////////////////////////////////////////////
      user: ahsManagerUser, ////////////////////////////////////////////////////////////
    }, ////////////////////////////////////////////////////////////
    updated: null, ////////////////////////////////////////////////////////////
  }, ////////////////////////////////////////////////////////////
  {
    ////////////////////////////////////////////////////////////
    id: '2', ////////////////////////////////////////////////////////////
    goal: _goals[1], ////////////////////////////////////////////////////////////
    goalGroup: _goalGroups[0], ////////////////////////////////////////////////////////////
    status: 'active', ////////////////////////////////////////////////////////////
    created: {
      ////////////////////////////////////////////////////////////
      date: '2013-12-31', ////////////////////////////////////////////////////////////
      user: ahsManagerUser, ////////////////////////////////////////////////////////////
    }, ////////////////////////////////////////////////////////////
    updated: null, ////////////////////////////////////////////////////////////
  }, ////////////////////////////////////////////////////////////
  {
    ////////////////////////////////////////////////////////////
    id: '3', ////////////////////////////////////////////////////////////
    goal: _goals[2], ////////////////////////////////////////////////////////////
    goalGroup: _goalGroups[1], ////////////////////////////////////////////////////////////
    status: 'active', ////////////////////////////////////////////////////////////
    created: {
      ////////////////////////////////////////////////////////////
      date: '2013-12-31', ////////////////////////////////////////////////////////////
      user: ahsManagerUser, ////////////////////////////////////////////////////////////
    }, ////////////////////////////////////////////////////////////
    updated: null, ////////////////////////////////////////////////////////////
  }, ////////////////////////////////////////////////////////////
]; ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
// --------------------------------------------------------

export const interventionStatus = Object.freeze({
  //////////////////////////////////////////////////////////////////////////////
  active: 'Active', //////////////////////////////////////////////////////////////////////////////
  inactive: 'Inactive', //////////////////////////////////////////////////////////////////////////////
}) as Record<string, any>; //////////////////////////////////////////////////////////////////////////////
export type CarePlanInterventionStatus = any; ////keyof typeof interventionStatus;//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
export type CarePlanIntervention = any; //// {//////////////////////////////////////////////////////////////////////////////
////  id: string;//////////////////////////////////////////////////////////////////////////////
////  name: string;//////////////////////////////////////////////////////////////////////////////
////  alias: string;//////////////////////////////////////////////////////////////////////////////
////  status: CarePlanInterventionStatus;//////////////////////////////////////////////////////////////////////////////
////  appointment: boolean;//////////////////////////////////////////////////////////////////////////////
////  created: Authoring;//////////////////////////////////////////////////////////////////////////////
////  updated: Authoring | null;//////////////////////////////////////////////////////////////////////////////
////}//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
export const _interventions: CarePlanIntervention[] = [
  //////////////////////////////////////////////////////////////////////////////
  {
    //////////////////////////////////////////////////////////////////////////////
    id: '1', //////////////////////////////////////////////////////////////////////////////
    name: "Arrange for community services to clean up member's living space", //////////////////////////////////////////////////////////////////////////////
    alias: 'Coordinate with community services for cleaning my home.', //////////////////////////////////////////////////////////////////////////////
    status: 'active', //////////////////////////////////////////////////////////////////////////////
    appointment: false, //////////////////////////////////////////////////////////////////////////////
    created: {
      //////////////////////////////////////////////////////////////////////////////
      date: '2013-12-31', //////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, //////////////////////////////////////////////////////////////////////////////
    }, //////////////////////////////////////////////////////////////////////////////
    updated: null, //////////////////////////////////////////////////////////////////////////////
  }, //////////////////////////////////////////////////////////////////////////////
  {
    //////////////////////////////////////////////////////////////////////////////
    id: '2', //////////////////////////////////////////////////////////////////////////////
    name: "Arrange for member's health and care awareness", //////////////////////////////////////////////////////////////////////////////
    alias: 'Member health care awareness', //////////////////////////////////////////////////////////////////////////////
    status: 'active', //////////////////////////////////////////////////////////////////////////////
    appointment: true, //////////////////////////////////////////////////////////////////////////////
    created: {
      //////////////////////////////////////////////////////////////////////////////
      date: '2013-12-31', //////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, //////////////////////////////////////////////////////////////////////////////
    }, //////////////////////////////////////////////////////////////////////////////
    updated: null, //////////////////////////////////////////////////////////////////////////////
  }, //////////////////////////////////////////////////////////////////////////////
  {
    //////////////////////////////////////////////////////////////////////////////
    id: '3', //////////////////////////////////////////////////////////////////////////////
    name: "Arrange for modiying member's living space to enable easier and safer use of mobility aid", //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    alias:
      'Consider making changes to my living space so that I can move around using my mobility aidsafely and cooperate with care manager to modify my living space', //////////////////////////////////////////////////////////////////////////////
    status: 'active', //////////////////////////////////////////////////////////////////////////////
    appointment: false, //////////////////////////////////////////////////////////////////////////////
    created: {
      //////////////////////////////////////////////////////////////////////////////
      date: '2013-12-31', //////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, //////////////////////////////////////////////////////////////////////////////
    }, //////////////////////////////////////////////////////////////////////////////
    updated: null, //////////////////////////////////////////////////////////////////////////////
  }, //////////////////////////////////////////////////////////////////////////////
]; //////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
// --------------------------------------------------------
//////////////////////////////////////////////////////////////////////////////////////////////
export const carePlanAutomationStatus = Object.freeze({
  //////////////////////////////////////////////////////////////////////////////////////////////
  active: 'Active', //////////////////////////////////////////////////////////////////////////////////////////////
  inactive: 'Inactive', //////////////////////////////////////////////////////////////////////////////////////////////
}) as Record<string, any>; //////////////////////////////////////////////////////////////////////////////////////////////
export type CarePlanAutomationStatus = any; ////keyof typeof carePlanAutomationStatus;//////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////
export const carePlanAutomationTimespans = Object.freeze({
  //////////////////////////////////////////////////////////////////////////////////////////////
  day: 'Day', //////////////////////////////////////////////////////////////////////////////////////////////
  week: 'Week', //////////////////////////////////////////////////////////////////////////////////////////////
  month: 'Month', //////////////////////////////////////////////////////////////////////////////////////////////
}) as Record<string, any>; //////////////////////////////////////////////////////////////////////////////////////////////
export type CarePlanAutomationTimespan = any; ////keyof typeof carePlanAutomationTimespans;//////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////
export type CarePlanAutomation = any; //// {//////////////////////////////////////////////////////////////////////////////////////////////
////  id: string;//////////////////////////////////////////////////////////////////////////////////////////////
////  condition: CarePlanCondition;//////////////////////////////////////////////////////////////////////////////////////////////
////  opportunity: CarePlanOpportunity;//////////////////////////////////////////////////////////////////////////////////////////////
////  goalGroup: CarePlanGoalGroup;//////////////////////////////////////////////////////////////////////////////////////////////
////  goal: CarePlanGoal;//////////////////////////////////////////////////////////////////////////////////////////////
////  lob: LOB;//////////////////////////////////////////////////////////////////////////////////////////////
////  intervention: CarePlanIntervention;//////////////////////////////////////////////////////////////////////////////////////////////
////  timeframe: number;//////////////////////////////////////////////////////////////////////////////////////////////
////  timespan: CarePlanAutomationTimespan;//////////////////////////////////////////////////////////////////////////////////////////////
////  status: CarePlanAutomationStatus;//////////////////////////////////////////////////////////////////////////////////////////////
////  created: Authoring;//////////////////////////////////////////////////////////////////////////////////////////////
////  updated: Authoring | null;//////////////////////////////////////////////////////////////////////////////////////////////
////}//////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////
export const _cpa: CarePlanAutomation[] = [
  //////////////////////////////////////////////////////////////////////////////////////////////
  {
    //////////////////////////////////////////////////////////////////////////////////////////////
    id: '1', //////////////////////////////////////////////////////////////////////////////////////////////
    condition: _conditions[0], //////////////////////////////////////////////////////////////////////////////////////////////
    opportunity: _opportunities[0], //////////////////////////////////////////////////////////////////////////////////////////////
    goalGroup: _goalGroups[0], //////////////////////////////////////////////////////////////////////////////////////////////
    goal: _goals[0], //////////////////////////////////////////////////////////////////////////////////////////////
    intervention: _interventions[0], //////////////////////////////////////////////////////////////////////////////////////////////
    lob: _lobs[0], //////////////////////////////////////////////////////////////////////////////////////////////
    timeframe: 1, //////////////////////////////////////////////////////////////////////////////////////////////
    timespan: 'day', //////////////////////////////////////////////////////////////////////////////////////////////
    status: 'active', //////////////////////////////////////////////////////////////////////////////////////////////
    created: {
      //////////////////////////////////////////////////////////////////////////////////////////////
      date: '2013-12-31', //////////////////////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, //////////////////////////////////////////////////////////////////////////////////////////////
    }, //////////////////////////////////////////////////////////////////////////////////////////////
    updated: null, //////////////////////////////////////////////////////////////////////////////////////////////
  }, //////////////////////////////////////////////////////////////////////////////////////////////
  {
    //////////////////////////////////////////////////////////////////////////////////////////////
    id: '2', //////////////////////////////////////////////////////////////////////////////////////////////
    condition: _conditions[1], //////////////////////////////////////////////////////////////////////////////////////////////
    opportunity: _opportunities[1], //////////////////////////////////////////////////////////////////////////////////////////////
    goalGroup: _goalGroups[1], //////////////////////////////////////////////////////////////////////////////////////////////
    goal: _goals[1], //////////////////////////////////////////////////////////////////////////////////////////////
    intervention: _interventions[1], //////////////////////////////////////////////////////////////////////////////////////////////
    lob: _lobs[1], //////////////////////////////////////////////////////////////////////////////////////////////
    timeframe: 1, //////////////////////////////////////////////////////////////////////////////////////////////
    timespan: 'day', //////////////////////////////////////////////////////////////////////////////////////////////
    status: 'active', //////////////////////////////////////////////////////////////////////////////////////////////
    created: {
      //////////////////////////////////////////////////////////////////////////////////////////////
      date: '2013-12-31', //////////////////////////////////////////////////////////////////////////////////////////////
      user: ahsManagerUser, //////////////////////////////////////////////////////////////////////////////////////////////
    }, //////////////////////////////////////////////////////////////////////////////////////////////
    updated: null, //////////////////////////////////////////////////////////////////////////////////////////////
  }, //////////////////////////////////////////////////////////////////////////////////////////////
]; //////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////
// --------------------------------------------------------

export const carePlanDurationUnit = Object.freeze({
  day: 'Day',
  week: 'Week',
  month: 'Month',
});
export type CarePlanDurationUnit = keyof typeof carePlanDurationUnit;

export const carePlanDurationTerm = Object.freeze({
  short: 'Short',
  long: 'Long',
});
export type CarePlanDurationTerm = keyof typeof carePlanDurationTerm;

export const carePlanDurationOperator = Object.freeze({
  '<=': 'Less Than or Equal To',
  '>': 'Greater Than',
});
export type CarePlanDurationOperator = keyof typeof carePlanDurationOperator;

export interface CarePlanDuration {
  id: string;
  term: CarePlanDurationTerm;
  unit: CarePlanDurationUnit;
  duration: number;
  operator: CarePlanDurationOperator;
  created: Authoring;
  updated: Authoring | null;
}

export const _cpd: CarePlanDuration[] = [
  {
    id: '1',
    term: 'short',
    operator: '<=',
    duration: 45,
    unit: 'day',
    created: {
      date: '2013-12-31',
      user: ahsManagerUser,
    },
    updated: {
      date: '2014-02-06',
      user: ahsManagerUser,
    },
  },
  {
    id: '2',
    term: 'long',
    operator: '>',
    duration: 45,
    unit: 'day',
    created: {
      date: '2013-12-31',
      user: ahsManagerUser,
    },
    updated: {
      date: '2014-02-06',
      user: ahsManagerUser,
    },
  },
];
