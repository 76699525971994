import * as Yup from 'yup';
import { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Stack, Button } from '@mui/material';
// components
import { FormProvider } from '../../../../components/hook-form';
import { CarePlanGoalToGoalGroup } from 'src/_mock/settings/_careplan-automation';
import RHFList from 'src/components/hook-form/RHFList';
import api from 'src/services/api';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// ----------------------------------------------------------------------

type FormValue = {
  goalIds: CarePlanGoalToGoalGroup['goal']['id'][];
  goalGroupIds: CarePlanGoalToGoalGroup['goalGroup']['id'][];
  interventions: string;
};

const schema = Yup.object().shape({
  goalIds: Yup.array().of(Yup.string()).min(1, 'At least one goal is required'),
  goalGroupIds: Yup.array().of(Yup.string()).min(1, 'At least one goal group is required'),
});

const resolver = yupResolver(schema);

export default function GoalsToGoalGroupsForm() {
  const navigate = useNavigate();
  const { data: goalGroups = [] } = useQuery(
    ['goal-groups'],
    async () =>
      (await api.carePlan.findGoalGroups({
        status: '',
      })) as any[]
  );

  const { data: goals = [] } = useQuery(
    ['goals'],
    async () =>
      (await api.carePlan.findGoals({
        status: '',
      })) as any[]
  );

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () =>
      ({
        goalIds: [],
        goalGroupIds: [],
        interventions: '',
      } as FormValue),
    []
  );

  const methods = useForm({ resolver, defaultValues });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValue) => {
    try {
      await api.carePlan.createMappingsGoalGroupGoal(data);

      reset();
      enqueueSnackbar('Create success!');
      navigate('..');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(
          error.response?.data?.message?.[0] || error?.message || 'Something went wrong!',
          {
            variant: 'error',
          }
        );
      }

      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack
        gap={3}
        direction="row"
        justifyContent="center"
        sx={{
          p: 3,
        }}
      >
        <RHFList
          name="goalGroupIds"
          label="Goal Group"
          items={goalGroups.map((goalGroup) => ({
            value: goalGroup.uuid,
            label: goalGroup.name,
          }))}
        />

        <RHFList
          name="goalIds"
          label="Goal"
          items={goals.map((goal) => ({
            value: goal.uuid,
            label: goal.name,
          }))}
        />
      </Stack>

      <Stack direction="row" justifyContent="flex-end" sx={{ p: 3 }} gap={2}>
        <Button variant="text" color="inherit" component={RouterLink} to="..">
          Cancel
        </Button>
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          Create Mapping
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
