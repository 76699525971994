import { Box } from '@mui/material';
import DataTable, { DataTableColumn } from 'src/components/table/DataTable';
import ExitDestinationsToolbar from './ExitDestinationsToolbar';
import ExitDestinationsContextMenu from './ExitDestinationsContextMenu';
import Label from 'src/components/Label';
import { CodeSystemConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { useEffect } from 'react';
import { useExitDestinations } from 'src/services/api/patients/programs';

const columns = [
  {
    header: 'Exit Destination',
    field: 'valueDisplayName',
  },
  {
    header: 'Status',
    field: 'isActive',
    render(active: CodeSystemConcept['property']) {
      return <Label color={active ? 'success' : 'info'}>{active ? 'Active' : 'Inactive'}</Label>;
    },
  },
] as DataTableColumn[];

export default function ExitDestinationsTable() {
  const { data: exitDestinations = [], refetch } = useExitDestinations();

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Box>
      <ExitDestinationsToolbar />

      <DataTable
        selectable
        columns={columns}
        data={exitDestinations}
        rowContextMenu={(item) => <ExitDestinationsContextMenu item={item} />}
      />
    </Box>
  );
}
