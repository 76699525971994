import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { SummaryTable } from '../../common';
import { ImmunizationAddForm } from '../../referral';
import { useImmunizations } from 'src/@nicheaim/fhir-react';
import { Button, Card, Grid, Stack, Typography } from '@mui/material';
import { mapImmunizationsToDisplay } from '../../common/common-utils';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { AppointmentList } from './patientHealth/appointments/AppointmentList';
import ObservationDetails from './patientHealth/observations/ObservationDetails';
import { STATUS_OPTION_IMMUNIZATION, TABLE_HEAD_IMMUNIZATIONS } from '../../common/table-head';

type Props = {
  patient: WrappedPatient | null;
};

export default function PatientHealth({ patient }: Props) {

  const { enqueueSnackbar } = useSnackbar();
  const [openImmunization, setOpenImmunization] = useState(false);

  const [ immunizations,{ create: createImmunization, refresh: refreshImmunizations }, ] 
    = useImmunizations({ filter: { patient: patient?.id } });

  const handleCloseAddImmunization = () => {
    setOpenImmunization(false);
  };

  const handleCreateImmunization = async (data: any) => {
    const result = await createImmunization(data);
    refreshImmunizations();
    enqueueSnackbar('Immunization created successfully!');
    handleCloseAddImmunization();
  };

  return (
    <>
      <Card sx={{ py: 2, boxShadow: 'none' }}>
        <Card>
          <Stack sx={{ m: 2 }}>
            <Grid
              container
              display={'flex'}
              alignItems={'center'}
              paddingRight={2}
            >
              <Grid item xs={10}>
                <Typography variant="subtitle1">
                  Immunizations
                </Typography>
              </Grid>
              <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
                <Button 
                  size="small" 
                  sx={{ height: '36px' }}
                  onClick={() => setOpenImmunization(true)}
                >
                  Add Immunization
                </Button>
              </Grid>
            </Grid>
          </Stack>

          <SummaryTable
            header={TABLE_HEAD_IMMUNIZATIONS}
            data={mapImmunizationsToDisplay(immunizations)}
            statusList={STATUS_OPTION_IMMUNIZATION}
            sx={{ padding: 0 }}
          />
        </Card>

        <ObservationDetails patient={patient}/>

        <AppointmentList patient={patient}/>
      </Card>
      <ImmunizationAddForm
        open={openImmunization}
        onCancel={handleCloseAddImmunization}
        handleCreateImmunization={handleCreateImmunization}
        patient={patient}
      />
    </>
  );
};