import client from '../_client';

type UpdateSessionParticipantAssessmentTokenParamsType = {
  sessionParticipantId: number;
  token: string;
}

export async function updateSessionParticipantAssessmentToken(params: UpdateSessionParticipantAssessmentTokenParamsType) {
  const { data } = await client.post(
    `/ccm/group-session/participant-assessment/update-token`,
    {
      ...params
    }
  );

  return data;
}
