import { UserManager } from 'src/@types/user';
import client from '../_client';

type GetUserListPropsType = {
  organizationIds?: string[];
}

export async function getUsersList({
  organizationIds
}: GetUserListPropsType) {
  let url = '/users';

  if (organizationIds) {
    url = url + `?organizationIds=${organizationIds.join(',')}`;
  }
  const { data } = await client.get<UserManager[]>(url);

  return data;
}

export type GetUsersListPaginateQueryFilters = {
  take: number, skip: number, search?: string
}

export async function getUsersListPaginate({ take, skip, search }: GetUsersListPaginateQueryFilters) {
  let url = `/users/paginate?take=${take}&skip=${skip}`;
  

  if (search) {
    url = url + `&search=${search}`;
  }

  const { data } = await client.get<UserManager[]>(url);

  return data;
}

export async function getUserByUuid(uuid: string) {
  const { data } = await client.get<UserManager>(`/users/${uuid}`);

  return data;
}

export async function getUserByEmail(email: string) {
  const { data } = await client.get<UserManager>(`/users/by-email/${email}`);

  return data;
}

export async function createUser(payload: UserManager) {
  const { data } = await client.post('/users', payload);

  return data;
}

export async function updateUser(payload: UserManager, uuid?: string) {
  const { data } = await client.put(`/users/${uuid}`, payload);

  return data;
}

export async function addExistingUser(payload?: UserManager, uuid?: string) {
  const { data } = await client.post(`/users/add-existing-user/${uuid}`, payload);

  return data;
}

export async function deleteUser(uuid: string) {
  const { data } = await client.delete(`/users/${uuid}`);

  return data;
}
