import { 
  Button, 
  Card, 
  CardContent, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  FormControl, 
  FormControlLabel,
  Grid, 
  Radio, 
  RadioGroup, 
  TextField, 
  Typography 
} from "@mui/material";
import _ from 'lodash';
import { Link } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

const AddressInCountyDialog = ({
  isOpen,
  handleClose,
  handleSave,
  saveCountyAddress,
  addressList,
  addressSaved = {},
  handleOpenFromIsCounty
}: any) => {
  const [openDialog, setOpenDialog] = useState(isOpen);
  const [addresses, setAddresses] = useState<any[]>(addressList);
  const [validAddress, setValidAddress] = useState<any>(null);
  const [validAddressIndex, setValidAddressIndex] = useState<number>(-1);
  const [notes, setNotes] = useState<string>('');
  const [inCounty, setInCounty] = useState<string>('out');
  const [savePayload, setSavePayload] = useState({ county: '', notes: '' });

  const parseAddressSaved =
    addressSaved && typeof addressSaved === 'string' ? JSON.parse(addressSaved) : addressSaved;
  const getAddressSaved =
    parseAddressSaved && Object.keys(parseAddressSaved).length > 0
      ? _.get(parseAddressSaved, 'scope.checklistItem.VALIDATE_HOME_ADDRESS_ITEM.modifiedFields', '')
      : '';

  useEffect(() => {
    setOpenDialog(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setAddresses(addressList);
  }, [addressList]);

  useEffect(() => {
    if (addresses) selectValidAddressIndex();
  }, [addresses]);

  const selectValidAddressIndex = useCallback(() => {
    const validIndex = addresses?.findIndex((address: any) => address?.extension?.[0]?.extension?.find((a: any) => a.url === 'valid')?.valueBoolean);

    if (validIndex !== -1) {
      setValidAddressIndex(validIndex);
      setValidAddress(addresses.at(validIndex));
    }
  }, [addresses]);

  const handleNotesChange = (e: any) => {
    setNotes(e.target.value);
    setSavePayload((prev) => ({ county: prev.county, notes: e.target.value }));
  };

  const handleInCountyChange = (e: any) => {
    setInCounty(e.target.value);
    setSavePayload((prev) => ({ county: e.target.value, notes: prev.notes }));
  };

  const markAddress = () => {
    const payload = addresses && addresses.splice(validAddressIndex, 1)?.[0];
    const countyExtensionIndex = payload?.extension?.[0]?.extension?.findIndex((ext: any) => ext.url === 'inCounty');
    const countyNotesExtensionIndex = payload?.extension?.[0]?.extension?.findIndex((ext: any) => ext.url === 'inCountyNotes');
    const inCountyExtension = {
      url: 'inCounty',
      valueBoolean: inCounty === 'in',
    };
    const inCountyNotesExtension = {
      url: 'inCountyNotes',
      valueString: notes,
    };
    
    if (countyExtensionIndex !== -1) payload?.extension?.[0]?.extension?.splice(countyExtensionIndex, 1, inCountyExtension)
    else payload?.extension?.[0]?.extension?.push(inCountyExtension);

    if (countyNotesExtensionIndex !== -1) payload?.extension?.[0]?.extension?.splice(countyNotesExtensionIndex, 1, inCountyNotesExtension)
    else payload?.extension?.[0]?.extension?.push(inCountyNotesExtension);

    if (addresses) setAddresses((currentAddresses) => { currentAddresses.push(payload); return currentAddresses });

    saveCountyAddress(savePayload);
    handleSave(addresses);
  };

  const showAddress = (validAddress && validAddress.line?.[0]) ? validAddress.line?.[0] : 
    (getAddressSaved &&
      Object.keys(getAddressSaved).length > 0 &&
      getAddressSaved?.line &&
      _.isArray(getAddressSaved?.line))
      ? getAddressSaved?.line?.[0]
      : getAddressSaved.line;

  return (
    <Dialog open={openDialog}>
      <DialogTitle>
        <Typography variant="subtitle1">Fix Invalid Address</Typography>
      </DialogTitle>

      <DialogContent>
        {(validAddress !== null && validAddressIndex !== -1) ||
        (getAddressSaved && Object.keys(getAddressSaved).length > 0) ? (
          <Card>
            <CardContent>
              <Typography variant="body2">
                {showAddress} is in or out the county?
              </Typography>

              <FormControl fullWidth>
                <RadioGroup
                  row
                  name="inCounty"
                  defaultValue={inCounty}
                  onChange={handleInCountyChange}
                >
                  <Grid container>
                    <Grid item sm={6}>
                      <FormControlLabel value="in" control={<Radio />} label="In County" />
                    </Grid>
                    <Grid item sm={6}>
                      <FormControlLabel value="out" control={<Radio />} label="Out of County" />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>

              <Grid container>
                <Grid item sm={12}>
                  <FormControl fullWidth>
                    <Typography>Note</Typography>
                    <TextField
                      id="note"
                      variant="outlined"
                      name="countyNotes"
                      onChange={handleNotesChange}
                      multiline
                      fullWidth
                      rows={4}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              {addressSaved && (
                <Grid container>
                  <Grid item sm={12} sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                      <Link
                       component="button"
                       onClick={handleOpenFromIsCounty}
                       sx={{ color: 'black', fontSize: 12 }}
                       >
                        ** &nbsp; Fix Invalid Address
                       </Link>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              

              <Grid container spacing={3} justifyContent="center" sx={{ mt: addressSaved ? 0 : 1 }}>
                <Grid item >
                  <Button variant="contained" onClick={handleClose}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={markAddress}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardContent>
              <Typography>
                This patient has no valid address, please select or add a valid address to continue
              </Typography>
              <Button variant="contained" onClick={handleClose}>
                Cancel
              </Button>
            </CardContent>
          </Card>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddressInCountyDialog;