type TimerHandle = ReturnType<typeof setTimeout>;

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number = 300
): ((...args: Parameters<T>) => void) => {
  let timer: TimerHandle | null;

  return (...args: Parameters<T>) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      func?.(...args);
      timer = null;
    }, delay);
  };
};

export const wait = (milliSeconds: number = 600): Promise<null> =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, milliSeconds);
  });
