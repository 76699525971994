import moment from 'moment';

export const dateFormat = 'MMM DD, YYYY';

export const isBetweenDates = (
  start1: moment.Moment,
  end1: moment.Moment,
  start2: moment.Moment,
  end2: moment.Moment
): boolean => start1.isSameOrAfter(start2, 'date') && end1.isSameOrBefore(end2, 'date');

export const momentDateComparator = (date1: moment.Moment, date2: moment.Moment): number => {
  if (date1.isSame(date2, 'date')) return 0;
  if (date1.isBefore(date2, 'date')) return -1;
  return 1;
};

export const checkIfDateIsAfterAnother = (
  date1: moment.Moment | null,
  date2: moment.Moment | null
) => date1?.isValid?.() && date2?.isValid?.() && date1?.isAfter?.(date2, 'date');

export const formatDate = (date: moment.Moment): string => date?.format(dateFormat);

export const getAge = (birthDate: moment.Moment): number =>
  moment().diff(moment(birthDate), 'years');
