import { TextField } from '@mui/material';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import useCarePlanAutomationFormStore, { useCarePlanAutomationFormRule } from '../store';

interface StringOperatorProps {
  question: WrappedQuestionnaireItem;
  ruleId: string;
}

function StringOperator({ ruleId }: StringOperatorProps) {
  const saving = useCarePlanAutomationFormStore((s) => s.loading);
  const { value, setValue } = useCarePlanAutomationFormRule(ruleId);

  return (
    <TextField
      disabled={saving}
      sx={{ flex: 1 }}
      size="small"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

export default StringOperator;
