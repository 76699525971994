import useObjectState from '../../../../../../hooks/useObjectState';
import {
  Divider,
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  Autocomplete,
  TextField,
  Alert,
} from '@mui/material';
import API from 'src/services/api';
import PatientCard from 'src/sections/careflow/common/PatientCard';
import PatientViewModel from '../ViewModel/PatientViewModel';

export const patientStatuses = {
  new: 'new',
  active: 'active',
  pending: 'pending',
  inactive: 'inactive',
};

export type EditPatientPropsType = {
  onSaveCallback: (patient: PatientViewModel) => void;
  userPatient?: PatientViewModel | null | undefined;
  patient?: {
    fhirId: string | undefined;
  };
};

interface StateI {
  status: string | undefined,
  statusItem: any;
  isLoading: boolean,
  error: string | null,
  success: string | null,
}

const EditPatient = (props: EditPatientPropsType) => {
  const { userPatient: propsPatient, patient } = props;
  const [state, updateState] = useObjectState<StateI>({
    status: propsPatient?.status ?? '',
    statusItem: null,
    isLoading: false,
    error: null,
    success: null,
  });
  const { status, statusItem, isLoading, error, success } = state;
  const onClearAllPress = () => {
    updateState({
      status: '',
    });
  };

  const validateRequiredFields = () => {
    if (!status) {
      return false;
    }
    return true;
  };

  const onSavePress = async () => {
    const { onSaveCallback } = props;
    updateState({
      isLoading: true,
    });
    if (!validateRequiredFields()) {
      updateState({
        isLoading: false,
        error: 'Please, fill all required fields',
        success: null,
      });
      return;
    }

    const updatedPatient = propsPatient?.fhirId && status ? await API.patients.changeStatus(propsPatient?.fhirId, status) : undefined;
    if (updatedPatient) {
      completePatientCreation(() => onSaveCallback(updatedPatient));
    } else {
      errorPatientCreation('Error saving the referral. Please try again');
    }
  };

  const completePatientCreation = (onSaveCallback: () => void) => {
    updateState({
      error: null,
      success: 'Referral saved successfully',
      isLoading: false,
    });
    setTimeout(() => {
      onSaveCallback();
    }, 1000);
  };

  const errorPatientCreation = (message: string) => {
    updateState({
      error: message,
      isLoading: false,
      success: null,
    });
  };

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: 0,
        width: 400,
        height: '100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll',
      }}
    >
      <form>
        <Box
          sx={{
            my: 3,
            position: 'fixed',
            width: '320px',
            backgroundColor: 'white',
            opacity: 1,
            zIndex: 9,
            top: '-25px',
          }}
        >
          <Box sx={{ my: 3 }}>
            <Grid item>
              {patient?.fhirId && (
                <PatientCard id={patient?.fhirId} />
              )}
            </Grid>
          </Box>
          <Box sx={{ my: 3 }}>
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {propsPatient ? 'Edit Patient' : 'Add Patient'}
              </Typography>
            </Grid>
          </Box>
          <Divider />
        </Box>
        <Box sx={{ marginTop: '150px' }}>
          <Grid item style={{ marginTop: '15px' }}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                options={Object.entries(patientStatuses).map((current) => ({
                  label: current[1],
                  value: current[0],
                }))}
                value={statusItem}
                getOptionLabel={(item) => item.label}
                onChange={(_: any, value: any | null) =>
                  updateState({ statusItem: value, status: value.value })
                }
                renderInput={(params) => (
                  <TextField required variant="standard" {...params} label="Status" />
                )}
              />
            </FormControl>
          </Grid>
          {error && (
            <Alert style={{ marginTop: '20px' }} severity="error">
              {error}
            </Alert>
          )}
          {success && (
            <Alert style={{ marginTop: '20px' }} severity="success">
              {success}
            </Alert>
          )}
          <Grid container spacing={2} direction="row">
            <Grid item xs={6} style={{ marginTop: '15px' }}>
              <FormControl fullWidth>
                <Button
                  disabled={isLoading}
                  onClick={onClearAllPress}
                  size="large"
                  fullWidth
                  variant="outlined"
                  color="secondary"
                >
                  Clear all
                </Button>
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ marginTop: '15px' }}>
              <FormControl fullWidth>
                <Button
                  disabled={isLoading}
                  onClick={onSavePress}
                  size="large"
                  style={{ width: '100%' }}
                  variant="contained"
                  color="secondary"
                >
                  Save
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default EditPatient;
