// @ts-nocheck
import { DatePicker } from "@mui/lab";
import { Grid, Autocomplete, TextField } from "@mui/material"
import { useMemo } from "react"
import { useLocations } from "src/@nicheaim/fhir-react"

const Filters = ({
  onChangeLocation,
  onChangeDate,
  date,
}: { onChangeLocation: (value: any) => void; onChangeDate: (value: Date | null) => void; date: Date; }) => {
  const [locations] = useLocations();
  const locationsItems = useMemo(() => locations.map(location => ({
      label: location.name,
      value: location.id,
    })), [locations]);

  return (
    <Grid item xl={12}>
      <Grid container spacing={4}>
        <Grid item>
          <Autocomplete
            sx={{ width: 300 }}
            options={locationsItems ?? []}
            getOptionLabel={(value) => value.label}
            onChange={(e, value) => onChangeLocation(value)}
            disableClearable
            renderInput={(params) => <TextField {...params} label="Location" />}
          />
        </Grid>
        <Grid item>
          <DatePicker
            value={date}
            onChange={(date) => {
              console.log({
                date
              })
              onChangeDate(date)
            }}
            renderInput={(params) => <TextField type="date" {...params} />}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Filters;
