import { Box, IconButton, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import produce from 'immer';
import { PatientWrapper, WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import Iconify from 'src/components/Iconify';
import {
  ADD_PERSON,
  SHOW_SIDEBAR_COMPONENT,
  SWITCH_ACTIVE_PERSON,
  useEventBus,
} from 'src/event-bus';
import ContactForm from './ContactForm';
import { usePatient } from 'src/@nicheaim/fhir-react';
import {
  HumanName,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { formatAddress } from './utils';

interface ContactsProps {
  patient: WrappedPatient;
}

const Contacts = ({ patient }: ContactsProps) => {
  const { dispatchEvent } = useEventBus();
  const [, { update }] = usePatient(patient.id!, {
    map: PatientWrapper,
  });

  const openPerson = (person: any) => {
    dispatchEvent(ADD_PERSON, { data: person });
    dispatchEvent(SWITCH_ACTIVE_PERSON, person);
  };

  const addNewContact = () => dispatchEvent(SHOW_SIDEBAR_COMPONENT, <ContactForm />);

  const handleEdit = useCallback(
    (item: any) => dispatchEvent(SHOW_SIDEBAR_COMPONENT, <ContactForm data={item} />),
    [dispatchEvent]
  );

  const handleDelete = useCallback(
    (data: any) => {
      update(
        produce(patient!, (draft) => {
          draft.contact = draft.contact?.filter((c: any) => c.id !== data?.id);
        })
      );
    },
    [patient, update]
  );

  const formatName = (name?: HumanName) => {
    if (!name) return '';
    return `${name?.given?.join(' ')} ${name?.family || ''}`;
  };


  return (
    <Stack sx={{ flex: 1 }}>
      <Stack direction="row" alignItems="baseline" justifyContent="space-between" spacing={12}>
        <Typography variant="h6" sx={{ mb: 1.5 }}>
          Contacts
        </Typography>
        <Stack direction="row">
          <IconButton onClick={addNewContact}>
            <Iconify icon="eva:plus-outline" />
          </IconButton>
          <IconButton>
            <Iconify icon="eva:search-outline" />
          </IconButton>
        </Stack>
      </Stack>
      <Box sx={{ height: '400px', overflow: 'auto' }}>
        {patient?.contact?.map((contact) => (
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }} key={contact.id}>
            <Stack sx={{ flex: 1 }}>
              <Stack alignItems="center" direction="row" justifyContent="space-between">
                <Stack direction="row">
                  <Typography variant="subtitle2">
                    {formatName(contact.name)}
                    {contact.relationship && (
                      <Typography variant="caption">
                        {' '}
                        / {contact.relationship?.[0].coding?.[0]?.display}
                      </Typography>
                    )}
                  </Typography>
                </Stack>
              </Stack>
              {contact.telecom?.map((t: any, index: number) => (
                <Typography key={`contact-telecom-${index}`} variant="caption">
                  {t.value}
                </Typography>
              ))}
              {contact.address && (
                <Typography variant="caption">{formatAddress(contact.address)}</Typography>
              )}
            </Stack>
            <Box>
              <IconButton onClick={() => handleEdit(contact)}>
                <Iconify icon="eva:edit-outline" />
              </IconButton>
              <IconButton onClick={() => handleDelete(contact)}>
                <Iconify icon="eva:trash-2-outline" />
              </IconButton>
            </Box>
          </Stack>
        ))}
      </Box>
    </Stack>
  );
};

export default Contacts;
