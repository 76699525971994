import { 
  AddReferralPropsType,
  defaultStateFieldsValues,
  ReferralPayload,
  ReferralStatuses, 
  REFERRAL_CREATE_ERROR_MESSAGE, 
  ServiceTypePayload
} from './constants';
import {
  Autocomplete,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import {
  useCommunications,
  useDocumentReferences,
  useHealthcareServices,
  useLocations,
  useOrganizations,
} from 'src/@nicheaim/fhir-react';
import agent from 'src/api/agent';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { isArray, isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import Iconify from 'src/components/Iconify';
import SearchServices from './SearchServices';
import CustomModal from 'src/components/CustomModal';
import useObjectState from 'src/hooks/useObjectState';
import { referralService } from 'src/crs/referral/services';
import { Location } from 'src/@nicheaim/fhir-base/mappings/Location';
import { Organization } from 'src/@nicheaim/fhir-base/mappings/Organization';
import { HealthcareService } from 'src/@nicheaim/fhir-base/mappings/HealthcareService';

const getIdFromReference = (reference: string): string =>
  reference ? reference?.split('/')?.[1] : '';

const AddReferral = (props: AddReferralPropsType) => {

  const { referral: propReferral, patient, serviceRequest, openAdd, onCancel } = props;
  
  const { enqueueSnackbar } = useSnackbar();

  const authUser = useAuth().getCurrentUser();

  useEffect(() => {
    updateState(defaultStateFieldsValues(authUser.user_fhir_uri,propReferral))
  },[openAdd, propReferral]);

  const [state, updateState] = useObjectState<any>({});
  
  const {
    date,
    startHour,
    serviceTypeItem,
    referredFromItem,
    referredToItem,
    status,
    statusItem,
    users,
    assignedToItem,
    locationOrganization,
    file,
    selectedFile,
    note,
    isLoading,
    success,
    openSearch
  } = state;

  const [communications] = useCommunications({
    autofetch: propReferral?.id ? true : false,
    filter: {
      'part-of': propReferral?.id,
    },
  });

  const [organizations] = useOrganizations({
    pagination: {
      pageSize: 1000,
    },
  });

  const [requesterOrganizations] = useOrganizations({
    filter: {
      type: 'referral-requester-organizations',
    },
    pagination: {
      pageSize: 1000,
    },
  });

  const [providerOrganizations] = useOrganizations({
    filter: {
      type: 'external-service-provider',
    },
    pagination: {
      pageSize: 1000,
    },
  });

  const [_, { create: createDocumentReference }] = useDocumentReferences({
    autofetch: false,
  });

  const [documentReferences] = useDocumentReferences({
    filter: {
      related: propReferral?.fhirId,
      autofetch: propReferral?.fhirId ? true : false,
    },
    pagination: {
      pageSize: 10,
    },
  });

  const [healthcareServices] = useHealthcareServices({
    pagination: {
      pageSize: 1000,
    }
  });

  const [location] = useLocations({
    pagination: {
      pageSize: 1000,
    },
  });

  const providerOrganizationsList = useMemo(() => {
    let setProviderOrganization: Organization[] = [];

    const filter = (serviceTypeItem?.value !== undefined && serviceTypeItem?.value?.organizationId) 
    || (locationOrganization?.value !== undefined && getIdFromReference(locationOrganization?.value?.organizationId || ''));

    if (filter) {
      setProviderOrganization = providerOrganizations.filter(e => e.id === filter);
    }else{
      setProviderOrganization = providerOrganizations;
    }

    return setProviderOrganization
      .map((group: any) => ({
        label: group.name,
        value: `${group.resourceType}/${group.id}`,
      }))
      .sort((x1, x2) => (x1.label > x2.label ? 1 : x1.label < x2.label ? -1 : 0)) ?? []
  }, [providerOrganizations, serviceTypeItem, locationOrganization]);

  const requesterOrganizationsList = useMemo(
    () =>
      requesterOrganizations
        .map((group: any) => ({
          label: group.name,
          value: `${group.resourceType}/${group.id}`,
        }))
        .sort((x1, x2) => (x1.label > x2.label ? 1 : x1.label < x2.label ? -1 : 0)) ?? [],
    [requesterOrganizations]
  );

  const lastAttachment = useMemo(() => {
    const lastDocumentReference = documentReferences?.[0];
    if (lastDocumentReference?.content?.[0]?.attachment) {
      return lastDocumentReference?.content?.[0]?.attachment;
    }
    return null;
  }, [documentReferences]);

  const referralServiceTypes = useMemo(() => {
    const types: ServiceTypePayload[] = [];
    if (organizations.length > 0 && healthcareServices.length > 0) {

      let healthCareService: HealthcareService[] = [];
      if (referredToItem?.value !== undefined) {
        healthCareService = healthcareServices.
          filter((h) => h.providedBy?.reference === referredToItem?.value);
      }else{
        healthCareService = healthcareServices;
      }

      const filtered = healthCareService.filter((h) => h?.type?.[0]?.coding?.[0] && h?.id);
      
      filtered.forEach((h) => {
        h.type?.forEach((t) => {
          t?.coding?.forEach((c) => {
            if (c?.code && h?.id && h?.providedBy?.reference) {
              const org = organizations.find(
                (o) => o?.id === getIdFromReference(h?.providedBy?.reference || '')
              );
              if (org?.id) {
                types.push({
                  label: `${c?.code} | ${org?.name}`,
                  value: {
                    type: c?.code,
                    healthcareService: h,
                    organizationId: org?.id,
                  },
                });
              }
            }
          });
        });
      });
    } else {
      return [];
    }

    return types.sort((a, b) => a.value.type.localeCompare(b.value.type));
  }, [healthcareServices, organizations, referredToItem]);

  const locationOrganizationsList = useMemo(() => {
    let setLocation: Location[] = [];
    const filter = (referredToItem?.value !== undefined && referredToItem?.value) 
    || (serviceTypeItem?.value !== undefined && `Organization/${serviceTypeItem?.value?.organizationId}`);

    if (filter) {
      setLocation = location.filter(e => e.managingOrganization?.reference === filter)
    }else{
      setLocation = location;
    }
    
    return setLocation
      .map((group: any) => ({
        label: group.name,
        value: {
          locationId: `${group.resourceType}/${group.id}`,
          organizationId: group?.managingOrganization?.reference
        },
      }))
      .sort((x1, x2) => (x1.label > x2.label ? 1 : x1.label < x2.label ? -1 : 0));
  }, [location, serviceTypeItem, referredToItem]);

  const userList = useMemo(
    () =>
      users
        ?.map((user: any) => ({
          label: user.userName,
          value: user.userName,
        }))
        .sort((x1, x2) => (x1.label > x2.label ? 1 : x1.label < x2.label ? -1 : 0)) ?? [],
    [users]
  );

  const userListWithNames = useMemo(
    () =>
      users
        ?.map((user: any) => ({
          label: `${user.firstName} ${user.middleName} ${user.lastName}`,
          value: user.fhirUri,
        }))
        .sort((x1, x2) => (x1.label > x2.label ? 1 : x1.label < x2.label ? -1 : 0)) ?? [],
    [users]
  );

  const usersAndRequesterGroupsList = useMemo(
    () =>
      userList
        .concat(requesterOrganizationsList)
        .sort((x1, x2) => (x1.label > x2.label ? 1 : x1.label < x2.label ? -1 : 0)),
    [userList, requesterOrganizationsList]
  );

  const getAllUsers = async () => {
    const result = await agent.User.getAllUsers();
    updateState({
      users: isArray(result) ? [...result] : [],
    });
  };
  
  useEffect(() => {
    getAllUsers();
  }, [openAdd]);
  
  const validateRequiredFields = () => {
    if (
      !date ||
      !startHour ||
      !status ||
      !serviceTypeItem ||
      !referredFromItem ||
      !referredToItem ||
      !locationOrganization ||
      !assignedToItem
    ) {
      return false;
    }
    return true;
  };

  const onClearAllPress = () => {
    updateState(defaultStateFieldsValues(authUser.user_fhir_uri, null));
  };

  const onSavePress = async () => {
    updateState({
      isLoading: true,
    });
    if (!validateRequiredFields()) {
      updateState({
        isLoading: false,
        success: null,
      });
      enqueueSnackbar('Please, fill all required fields.', { variant: 'error' })
      return;
    }

    const time = `${date} ${startHour}`;

    const payload: ReferralPayload = {
      id: propReferral?.id ?? null, 
      date: new Date(time).toISOString(),
      start: startHour,
      status: statusItem?.value,
      patientId: patient?.id ?? null,
      assignedTo: assignedToItem?.value,
      referredFrom: referredFromItem?.value,
      referredTo: referredToItem?.value,
      locationOrganization: locationOrganization?.value?.locationId ?? null,
      note,
      services: null,
      service: serviceTypeItem.value,
      parentServiceRequest: serviceRequest
    };
    
    handleSaveReferral(payload);
  };

  const handleSaveReferral = async (payload: ReferralPayload) => {

    if (propReferral?.id) {
      const updatedReferral = await updateReferral(payload);
      
      if(!isEmpty(updatedReferral) && selectedFile){
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64 = reader.result?.toString()?.replace(/^data:(.*,)?/, '');
          const newAttachment = await createAttachment(selectedFile, base64, updatedReferral);
          if (!newAttachment) {
            errorReferralCreation();
            enqueueSnackbar('Error uploading file.', { variant: 'error' })
          }else{
            completeReferralCreation();
            enqueueSnackbar('Referral saved successfully');
          }
        };
        reader.onerror = () => {
          errorReferralCreation();
          enqueueSnackbar('Error reading the selected file', { variant: 'error' })
        };
        reader.readAsDataURL(selectedFile);
      } else if(!isEmpty(updatedReferral)){
        completeReferralCreation();
        enqueueSnackbar('Referral saved successfully');
      }else {
        errorReferralCreation();
        enqueueSnackbar(`${REFERRAL_CREATE_ERROR_MESSAGE}`, { variant: 'error' })
      }
    } else {
      try {
        let newReferrals = await createReferral(payload);

        if(!isEmpty(newReferrals) && selectedFile){
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64 = reader.result?.toString()?.replace(/^data:(.*,)?/, '');
            const newAttachment = await createAttachment(selectedFile, base64, newReferrals);
            if (!newAttachment) {
              errorReferralCreation();
              enqueueSnackbar('Error uploading file.', { variant: 'error' })
            }else{
              completeReferralCreation();
              enqueueSnackbar('Referral saved successfully');
            }
          };
          reader.onerror = () => {
            errorReferralCreation();
            enqueueSnackbar('Error reading the selected file', { variant: 'error' })
          };
          reader.readAsDataURL(selectedFile);
        } else if(!isEmpty(newReferrals)){
          completeReferralCreation();
          enqueueSnackbar('Referral saved successfully');
        }else {
          errorReferralCreation();
          enqueueSnackbar(`${REFERRAL_CREATE_ERROR_MESSAGE}`, { variant: 'error' })
        }
      } catch (e) {
        errorReferralCreation();
        enqueueSnackbar(`${REFERRAL_CREATE_ERROR_MESSAGE}`, { variant: 'error' })
      }
    }
    onCancel();
  };

  const updateReferral = async (payload: any) => {
    const response = await referralService.updateChildReferral(propReferral?.id,payload);
    return response;
  };
    
  const createReferral = async (payload: any) => {
    const response = await referralService.createChildReferral(payload);
    return response;
  };

  const createAttachment = async (selectedFile: any, base64: any, relatedReferrals: any) => {
    const referralReferences = {
      reference: `ServiceRequest/${relatedReferrals?.id}`,
      type: 'ServiceRequest',
    };

    const attachmentPayload: any = {
      resourceType: 'DocumentReference',
      status: 'current',
      context: {
        related: [referralReferences],
      },
      subject: {
        reference: `Patient/${patient?.id}`,
        display: patient?.getFullName(),
        type: 'Patient',
      },
      author: [
        {
          reference: authUser.user_fhir_uri,
          display: authUser.name,
          type: 'Practitioner',
        },
      ],
      date: moment().format('YYYY-MM-DDTHH:MM:ssZ'),
      content: [
        {
          attachment: {
            contentType: selectedFile?.type,
            size: selectedFile?.size,
            data: base64,
          },
        },
      ],
    };

    return createDocumentReference(attachmentPayload);
  };

  const completeReferralCreation = () => {
    updateState({
      success: 'Referral saved successfully' as any,
      isLoading: false,
    });
  };

  const errorReferralCreation = () => {
    updateState({
      isLoading: false,
      success: null,
    });
  };

  const handleDownloadAttachment = async () => {
    if (lastAttachment) {
      const element = document.createElement('a');
      element.setAttribute(
        'href',
        `data:${lastAttachment?.contentType};base64,${lastAttachment?.data}`
      );
      element.setAttribute('download', `filename.${lastAttachment.contentType?.split('/')[1]}`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  return (
    <CustomModal
      open={openAdd}
      onCancel={onCancel}
      onSave={onSavePress}
      breadcrumbs={[' ']}
      title={propReferral ? 'Edit Referral' : 'Add Referral'}
      containerSx={[
        { overflowY: 'scroll', height: '92vh' },
        openSearch ? { width: '80vw' } : {},
      ]}
      childrenWithoutPadding={<></>}
      isLoading={isLoading}
    >
      <Card sx={{ mt: 2 }}>
        <Grid
          container
          spacing={1}
          sx={{ p: 2}}
        >
          <Grid item xs={openSearch ? 5 : 12}>
            <Grid item>
              <InputLabel shrink>Date *</InputLabel>
              <FormControl fullWidth>
                <TextField
                  value={date}
                  defaultValue={date}
                  onChange={(e) => updateState({ date: e.target.value })}
                  type="date"
                  variant="standard"
                  required
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ marginTop: '15px' }}>
              <InputLabel shrink>Start hour *</InputLabel>
              <FormControl fullWidth style={{ paddingRight: '5px' }}>
                <TextField
                  value={startHour}
                  defaultValue={startHour}
                  onChange={(e) => updateState({ startHour: e.target.value })}
                  type="time"
                  variant="standard"
                  required
                />
              </FormControl>
            </Grid>
            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Status *</InputLabel>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  options={Object.entries(ReferralStatuses).map((current) => ({
                    label: current[1],
                    value: current[0],
                  }))}
                  value={statusItem}
                  getOptionLabel={(item) => item.label}
                  onChange={(_: any, value: any | null) =>
                    updateState({ statusItem: value, status: value.value })
                  }
                  renderInput={(params) => <TextField required variant="standard" {...params} />}
                />
              </FormControl>
            </Grid>

            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Service *</InputLabel>
              <FormControl fullWidth>
              <Autocomplete
                  freeSolo
                  clearOnBlur
                  disablePortal
                  options={referralServiceTypes}
                  value={serviceTypeItem}
                  onChange={(_: any, value: any | null) => {
                  if (value && value?.value) {
                    updateState({ serviceTypeItem: value });
                  }
                  }}
                  renderInput={(params) => (
                    <Stack direction='row'>
                      <TextField
                        required
                        variant="standard"
                        {...params}
                      />
                      <IconButton 
                        onClick={() => updateState({
                          openSearch: true
                        })}
                      >
                        <Iconify icon="ic:baseline-search" />
                      </IconButton>
                    </Stack>
                  )}
              />
              </FormControl>
            </Grid>
            
            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Referred from *</InputLabel>
              <FormControl fullWidth>
                <Autocomplete
                  freeSolo
                  clearOnBlur
                  disablePortal
                  options={usersAndRequesterGroupsList}
                  value={referredFromItem}
                  onChange={(_: any, value: any | null) => {
                    if (value && value?.value) {
                      updateState({
                        referredFromFreeText: false,
                        referredFromItem: value,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      variant="standard"
                      {...params}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Referred to *</InputLabel>
              <FormControl fullWidth>
                <Autocomplete
                  freeSolo
                  clearOnBlur
                  disablePortal
                  options={providerOrganizationsList}
                  value={referredToItem}
                  onChange={(_: any, value: any | null) => {
                    if (value && value?.value) {
                      updateState({
                        referredToFreeText: false,
                        referredToItem: value,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      variant="standard"
                      {...params}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Location *</InputLabel>
              <FormControl fullWidth>
                <Autocomplete
                  freeSolo
                  clearOnBlur
                  disablePortal
                  options={locationOrganizationsList}
                  value={locationOrganization}
                  onChange={(_: any, value: any | null) => {
                    if (value && value?.value) {
                      updateState({
                        locationOrganization: value,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      variant="standard"
                      {...params}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Assigned to *</InputLabel>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  options={userListWithNames}
                  value={assignedToItem}
                  onChange={(_: any, value: any | null) => updateState({ assignedToItem: value })}
                  renderInput={(params) => <TextField required variant="standard" {...params} />}
                />
              </FormControl>
            </Grid>

            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Attachment</InputLabel>
              <FormControl fullWidth>
                <TextField
                  value={file}
                  defaultValue={file}
                  onChange={(e) =>
                    updateState({ file: e.target.value as any, selectedFile: (e.target as any)?.files[0] })
                  }
                  variant="standard"
                  required
                  type="file"
                />
              </FormControl>
            </Grid>

            {propReferral?.id && lastAttachment ? (
              <Grid item style={{ marginTop: '15px' }}>
                <InputLabel shrink>
                  Download Attachment{' '}
                  <IconButton onClick={handleDownloadAttachment}>
                    <Iconify icon="bi:download" />
                  </IconButton>
                </InputLabel>
              </Grid>
            ) : null}

            {propReferral?.id ? (
              <Grid>
                <Typography variant="caption">Notes</Typography>
                <List>
                  {communications.map((c) =>
                    c?.note?.[0]?.text ? (
                      <>
                        <ListItem>
                          <ListItemText secondary={c?.note?.[0]?.text} />
                        </ListItem>
                        <Divider variant="middle" component="li" />
                      </>
                    ) : null
                  )}
                </List>
              </Grid>
            ) : (
              <Grid item style={{ marginTop: '15px' }}>
                <InputLabel shrink>Note</InputLabel>
                <FormControl fullWidth>
                  <TextField
                    value={note}
                    onChange={(e) => updateState({ note: e.target.value as any })}
                    id="outlined-basic"
                    variant="standard"
                    multiline
                    maxRows={6}
                    rows={6}
                  />
                </FormControl>
              </Grid>
            )}    
          </Grid>

          {openSearch && (
            <Grid item xs={6.9}>
              <SearchServices 
                patient={patient}
                updateState={updateState}
              />
            </Grid>
          )}

          <Stack direction="row" justifyItems="center" spacing={2} sx={{ mt: 2 }}>
            <Button
              disabled={isLoading}
              onClick={onClearAllPress}
              variant='outlined'
              color='secondary'
              size='small'
            >
              Clear all
            </Button>
          </Stack>
        </Grid>
      </Card>
    </CustomModal>
  );
};

export default AddReferral;
