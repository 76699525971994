import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Typography,
  TableBody,
  Grid,
  Divider
} from "@mui/material";
import SessionParticipantType from "../../types/SessionParticipantType";
import { getSessionUsersTableColumns } from '../../constants';
import SessionUserItem from "./SessionUserItem";

type SessionTablePropsType = {
  items: SessionParticipantType[];
  title?: string;
}

const SessionUsersModal = ({
  items,
  title,
}: SessionTablePropsType) => (
    <Grid>
      <Grid sx={{ padding: 3, paddingBottom: 1 }}>
        <Typography variant="h5">Participants</Typography>
        <Typography variant="h5" sx={{ marginTop: 2 }}>{title}</Typography>
      </Grid>
      <TableContainer sx={{ padding: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              {getSessionUsersTableColumns.map((col: any, index: number) => (
                <TableCell style={{ width: col.width }} key={index}>
                  <Typography>
                    {col.label}
                  </Typography>
                </TableCell>
              ))}
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <SessionUserItem
                key={item.id}
                item={item}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )

export default SessionUsersModal;
