import { useNavigate } from 'react-router';
import { List } from 'src/@nicheaim/fhir-base/mappings/List';
import { useList } from 'src/@nicheaim/fhir-react';
import { EntityContextMenu } from 'src/sections/careflow/common';
import { useDialogStore } from 'src/stores/dialog';

export interface AutomationsContextMenuProps {
  item: List;
}

export default function AutomationsContextMenu({ item }: AutomationsContextMenuProps) {
  const navigate = useNavigate();
  const { confirmDialog } = useDialogStore();
  const [sheet, { update }] = useList(item.id || 'undefined');
  const isActive = item.status === 'current';

  return (
    <EntityContextMenu
      entity={item}
      items={[
        {
          label: 'Edit',
          icon: 'eva:edit-fill',
          onPress: () => navigate(`${item.id}/edit`),
        },
        isActive
          ? {
              label: 'Disable',
              icon: 'eva:trash-2-outline',
              onPress: async () => {
                const confirmed = await confirmDialog({
                  title: 'Delete',
                  description: 'Are you sure you want to disable this item?',
                });

                if (confirmed) {
                  update({
                    ...sheet,
                    resourceType: 'List',
                    status: 'retired',
                    mode: 'working',
                  });
                }
              },
            }
          : {
              label: 'Enable',
              onPress: async () => {
                update({
                  ...sheet,
                  resourceType: 'List',
                  status: 'current',
                  mode: 'working',
                });
              },
              icon: 'eva:checkmark-circle-2-outline',
            },
      ]}
    />
  );
}
