import { Link, useParams } from 'react-router-dom';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { usePatient, usePatients } from 'src/@nicheaim/fhir-react';

export default function PatientDetails() {
  const { id = '' } = useParams();
  const [[patient], { isLoading }] = usePatients({
    filter: {
      _id: id,
    },
    map: PatientWrapper,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Link to=".."> Go back </Link>

      <h2>
        Patient: {patient.getFullName()} &lt;{patient.getPrimaryEmail()?.value}&gt;{' '}
      </h2>
    </div>
  );
}
