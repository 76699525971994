export enum HttpMethod {
  'GET' = 'GET',
  'DELETE' = 'DELETE',
  'POST' = 'POST',
  'PUT' = 'PUT',
  'PATCH' = 'PATCH',
}

const RESOURCE_RETURN_METHODS = [
  HttpMethod.POST,
  HttpMethod.PUT,
  HttpMethod.PATCH,
]

export function isResourceReturnMethod(method: HttpMethod) {
  return RESOURCE_RETURN_METHODS.includes(method);
}
