import { Navigate, Route, Routes } from 'react-router';
import EditClient from './edit';
import ClientPlansIndex from './plans';
import AddPatientToProgramForm from './plans/$plan/lobs/$lob/programs/$program/patients/new';

export default function ClientIndex() {
  return (
    <Routes>
      <Route index element={<Navigate to="plans" replace />} />
      <Route path="edit" element={<EditClient />} />
      <Route path="plans/*" element={<ClientPlansIndex />} />
      <Route path="patients" element={<div />} />
      <Route path="patients/new" element={<AddPatientToProgramForm />} />
    </Routes>
  );
}
