import { ChangeEvent } from 'react';
import { TextField, MenuItem } from '@mui/material';

const states = [
  {
    display: 'Alabama',
    code: 'AL',
  },
  {
    display: 'Alaska',
    code: 'AK',
  },
  {
    display: 'Arizona',
    code: 'AZ',
  },
  {
    display: 'Arkansas',
    code: 'AR',
  },
  {
    display: 'California',
    code: 'CA',
  },
  {
    display: 'Colorado',
    code: 'CO',
  },
  {
    display: 'Connecticut',
    code: 'CT',
  },
  {
    display: 'Delaware',
    code: 'DE',
  },
  {
    display: 'District Of Columbia',
    code: 'DC',
  },
  {
    display: 'Florida',
    code: 'FL',
  },
  {
    display: 'Georgia',
    code: 'GA',
  },
  {
    display: 'Hawaii',
    code: 'HI',
  },
  {
    display: 'Idaho',
    code: 'ID',
  },
  {
    display: 'Illinois',
    code: 'IL',
  },
  {
    display: 'Indiana',
    code: 'IN',
  },
  {
    display: 'Iowa',
    code: 'IA',
  },
  {
    display: 'Kansas',
    code: 'KS',
  },
  {
    display: 'Kentucky',
    code: 'KY',
  },
  {
    display: 'Louisiana',
    code: 'LA',
  },
  {
    display: 'Maine',
    code: 'ME',
  },
  {
    display: 'Maryland',
    code: 'MD',
  },
  {
    display: 'Massachusetts',
    code: 'MA',
  },
  {
    display: 'Michigan',
    code: 'MI',
  },
  {
    display: 'Minnesota',
    code: 'MN',
  },
  {
    display: 'Mississippi',
    code: 'MS',
  },
  {
    display: 'Missouri',
    code: 'MO',
  },
  {
    display: 'Montana',
    code: 'MT',
  },
  {
    display: 'Nebraska',
    code: 'NE',
  },
  {
    display: 'Nevada',
    code: 'NV',
  },
  {
    display: 'New Hampshire',
    code: 'NH',
  },
  {
    display: 'New Jersey',
    code: 'NJ',
  },
  {
    display: 'New Mexico',
    code: 'NM',
  },
  {
    display: 'New York',
    code: 'NY',
  },
  {
    display: 'North Carolina',
    code: 'NC',
  },
  {
    display: 'North Dakota',
    code: 'ND',
  },
  {
    display: 'Ohio',
    code: 'OH',
  },
  {
    display: 'Oklahoma',
    code: 'OK',
  },
  {
    display: 'Oregon',
    code: 'OR',
  },
  {
    display: 'Pennsylvania',
    code: 'PA',
  },
  {
    display: 'Puerto Rico',
    code: 'PR',
  },
  {
    display: 'Rhode Island',
    code: 'RI',
  },
  {
    display: 'South Carolina',
    code: 'SC',
  },
  {
    display: 'South Dakota',
    code: 'SD',
  },
  {
    display: 'Tennessee',
    code: 'TN',
  },
  {
    display: 'Texas',
    code: 'TX',
  },
  {
    display: 'Utah',
    code: 'UT',
  },
  {
    display: 'Vermont',
    code: 'VT',
  },
  {
    display: 'Virginia',
    code: 'VA',
  },
  {
    display: 'Washington',
    code: 'WA',
  },
  {
    display: 'West Virginia',
    code: 'WV',
  },
  {
    display: 'Wisconsin',
    code: 'WI',
  },
  {
    display: 'Wyoming',
    code: 'WY',
  },
];

interface StateSelectorProps {
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value?: any;
  disabled?: boolean;
}

const StateSelector = ({ onChange, value, disabled = false }: StateSelectorProps) => (
    <TextField
      fullWidth
      select
      label="State"
      margin="dense"
      placeholder="State"
      size="small"
      variant="standard"
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {states.map((state: any) => (
        <MenuItem key={`state-${state.code}`} value={state.code}>
          {state.display}
        </MenuItem>
      ))}
    </TextField>
  );

export default StateSelector;
