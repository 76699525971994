import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Popper,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Modal,
  Card,
} from '@mui/material';
import { EntityContextMenu, SummaryTable } from 'src/sections/careflow/common';
import Iconify from 'src/components/Iconify';
import Label from 'src/components/Label';
import { CarePlanWrapper, WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import {
  Reference,
  CarePlan,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import fhirSystem from 'src/fhir/system';
import CreateCarePlanModal from './CreateCarePlanModal';
import moment from 'moment';
import { shortFormat } from 'src/utils/formatTime';
import { sumFhirDuration } from 'src/utils/time';
import CarePlanNotes from './CarePlanNotes';
import { useCarePlanStatuses } from 'src/services/api/care-plan/statuses';
import { useSystemSetting } from 'src/services/api/settings/system';
import { REACT_APP_CARE_PLAN_PDF_URL } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import { checkAclValidation, permissionsAcls } from 'src/utils/permissions/permission.utils';
import { useActiveCarePlans } from 'src/fhir/hooks/care-plans';
import AddModal from 'src/sections/careflow/common/AddModal';

type CarePlanSummaryProps = {
  isExpanded: boolean;
  onZoom?: () => void;
  patient?: {
    id: string;
  };
};

export const CarePlanSummary = ({ isExpanded, onZoom, patient }: CarePlanSummaryProps) => {
  const user = useAuth();
  const [editCarePlan, setEditCarePlan] = useState<CarePlan | null>(null);
  const [showCreateCarePlanModal, setShowCreateCarePlanModal] = useState(false);
  const [showCarePlanNotesModal, setShowCarePlanNotesModal] = useState(false);
  const [category, setCategory] = useState('enrolled');
  const [startDate, setStartDate] = useState('May 01, 2022');
  const [endDate, setEndDate] = useState('Jul 01, 2022');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const { data: carePlanStatuses } = useCarePlanStatuses();
  const { data: carePlanSettings } = useSystemSetting('care-plan-automation');
  const carePlanPdfUrl = useMemo(
    () => REACT_APP_CARE_PLAN_PDF_URL?.replace('PATIENT_ID', patient?.id ?? ''),
    [patient]
  );

  // TODO: useActiveCarePlasn should infer mapped type
  const [_carePlans, { refresh: refreshCarePlans, nextPage }] =
    useActiveCarePlans({
      filter: {
        patient: patient?.id,
      },
      map: CarePlanWrapper,
      pagination: {
        pageSize: 10,
      },
    });
  const carePlans = _carePlans;

  console.log([
    carePlans
  ])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const closeSearchPanel = () => setAnchorEl(null);

  const handleCategory = (ev: any) => {
    console.log(ev.target.value as string);
    setCategory(ev.target.value as string);
  };

  const changeStartDate = (el: any) => {
    setStartDate(el.target.value);
  };
  const changeEndDate = (ev: any) => {
    setEndDate(ev.target.value);
  };

  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setCategory('');
  };

  const renderCreateCarePlanModal = () => (
    <AddModal
      open={showCreateCarePlanModal}
      onClose={() => setShowCreateCarePlanModal(false)}
      Component={
        <CreateCarePlanModal
          currentCarePlan={editCarePlan}
          onSave={() => {
            setEditCarePlan(null);
            setShowCreateCarePlanModal(false);
            refreshCarePlans();
          }}
          onCancel={() => {
            setEditCarePlan(null);
            setShowCreateCarePlanModal(false);
          }}
        />
      }
    />
  );

  const renderCarePlanNotesModal = () => (
    <Modal
      open={showCarePlanNotesModal}
      onClose={() => setShowCarePlanNotesModal(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '40px' }}
    >
      <CarePlanNotes
        patient={patient!}
        carePlan={editCarePlan!}
        onCancel={() => {
          setShowCarePlanNotesModal(false);
        }}
      />
    </Modal>
  );

  return (
    <Card sx={{ height: `calc(100vh - 200px)`, display: 'flex', flexDirection: 'column' }}>
      {renderCreateCarePlanModal()}
      {renderCarePlanNotesModal()}
      <SummaryTable
        columns={[
          {
            header: 'Priority',
            field: 'parsed.priority',
          },
          {
            header: 'Role',
            field: 'author',
            visible: isExpanded,
            render(author: Reference) {
              if (!author) return null;
              if (author.extension) {
                const createdByAutomation = author.extension.find(
                  (ext) => ext.url === fhirSystem.extension.CarePlan.createdByAutomation.asString()
                );
                if (createdByAutomation) {
                  return (
                    <Box>
                      <Typography>Automation</Typography>
                    </Box>
                  );
                }
              }
              return author.display;
            },
          },
          {
            header: 'Goal',
            field: 'parsed.goal',
          },
          {
            header: 'Condition',
            field: 'parsed.condition',
            visible: isExpanded,
          },
          {
            header: 'Goal Group',
            field: 'parsed.goalGroup',
            visible: isExpanded,
          },
          {
            header: 'Intervention',
            field: 'parsed.intervention',
          },
          {
            header: 'Status',
            field: 'extension',
            render(value: CarePlan['extension'], row) {
              const status = value?.find(
                (ext) => ext.url === fhirSystem.extension.CarePlan.currentStatus.asString()
              )?.valueCoding?.code;

              const label = carePlanStatuses?.find(
                (carePlanStatus) => carePlanStatus.uuid === status
              );

              if (isExpanded) {
                return (
                  <Label variant="ghost" color={label?.color ?? 'default'}>
                    {label?.name ?? 'Unknown'}
                  </Label>
                );
              }

              return (
                <Label variant="ghost" color={label?.color ?? 'default'}>
                  {label?.abbreviation || label?.name.replaceAll(' ', '').slice(0, 4) || 'Unkn'}
                </Label>
              );
            },
          },
          {
            header: 'Sign Off',
            field: '$',
            visible: isExpanded,
            render(_, row) {
              return 'No';
            },
          },
          {
            header: 'Start Date',
            field: 'period.start',
            visible: isExpanded,
            render(value: string) {
              if (!value) return 'N/A';
              return shortFormat(moment(value).toDate());
            },
          },
          {
            header: 'Due Date',
            field: 'period.end',
            visible: isExpanded,
            render(value: string) {
              if (!value) return 'N/A';
              return shortFormat(moment(value).toDate());
            },
          },
          {
            header: 'Term',
            field: 'period.end',
            visible: isExpanded,
            width: '100px',
            render(value: string, row: WrappedCarePlan) {
              if (!carePlanSettings) return '...';

              const longTermAt = sumFhirDuration(
                moment(row.period?.start).toDate(),
                carePlanSettings.shortTermBefore,
                carePlanSettings.shortTermBeforeUnit.value
              );

              return moment(value).toDate() > longTermAt ? 'Long Term' : 'Short Term';
            },
          },
          {
            header: 'Appointment Alert',
            field: 'parsed.appointmentAlert',
            visible: isExpanded,
            render(value: boolean) {
              return value ? 'Yes' : 'No';
            },
          },
          {
            header: 'Last Updated',
            field: '$',
            visible: isExpanded,
            render(iterationCarePlan: CarePlan) {
              const lastUpdatedExtension = iterationCarePlan?.extension?.find(
                (extension) =>
                  extension.url === fhirSystem.extension.CarePlan.lastUpdated.asString()
              );
              return lastUpdatedExtension ? moment(lastUpdatedExtension.valueDateTime).format('MMM, DD YYYY hh:mm a') : 'N/A'
            },
          },
        ]}
        fetchMore={() => nextPage()}
        rowContextMenu={(row: WrappedCarePlan) => (
          <EntityContextMenu
            entity={row}
            items={[
              {
                label: 'Edit',
                icon: 'eva:edit-2-outline',
                onPress: () => {
                  setEditCarePlan(row);
                  setShowCreateCarePlanModal(true);
                },
              },
              {
                label: 'Care Plan Notes',
                icon: 'material-symbols:clinical-notes-outline-rounded',
                onPress: () => {
                  setEditCarePlan(row);
                  setShowCarePlanNotesModal(true);
                },
              },
            ]}
          />
        )}
        data={carePlans}
        actionBar={
          <>
            {checkAclValidation({ user, acl: permissionsAcls.writeCarePlan }) && (
              <IconButton
                onClick={() => {
                  setEditCarePlan(null);
                  setShowCreateCarePlanModal(true);
                }}
              >
                <Iconify icon="eva:plus-fill" />
              </IconButton>
            )}
            <IconButton onClick={handleClick}>
              <Iconify icon="eva:search-fill" />
            </IconButton>
            <IconButton onClick={() => onZoom?.()}>
              <Iconify icon="eva:expand-fill" />
            </IconButton>
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              onResize={undefined}
              onResizeCapture={undefined}
            >
              <Box sx={{ p: 1, bgcolor: 'background.paper' }}>
                <Stack>
                  <Stack alignItems="center" direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2">Filters</Typography>
                    <IconButton onClick={closeSearchPanel}>
                      <Iconify icon="eva:close-fill" />
                    </IconButton>
                  </Stack>
                  <Typography variant="subtitle1">Category</Typography>
                  <FormControl variant="standard" size="small">
                    <Select label="Category" value={category} onChange={handleCategory}>
                      <MenuItem value="enrolled">Enrolled</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography variant="subtitle1" mt={2}>
                    Date
                  </Typography>
                  <Stack direction="row" my={2} spacing={4}>
                    <TextField variant="standard" value={startDate} onChange={changeStartDate} />
                    <TextField variant="standard" value={endDate} onChange={changeEndDate} />
                  </Stack>
                  <Stack alignItems="center">
                    <Button
                      variant="outlined"
                      sx={{
                        borderColor: 'rgba(145, 158, 171, 0.32)',
                        color: 'rgb(36, 31, 32)',
                      }}
                      onClick={clearAll}
                    >
                      <Typography variant="subtitle2">Clear All</Typography>
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Popper>
            <IconButton>
              <a href={carePlanPdfUrl} target="_blank" rel="noreferrer">
                <Iconify
                  sx={{ marginTop: '5px' }}
                  color="#637381"
                  fontSize={30}
                  icon="material-symbols:download"
                />
              </a>
            </IconButton>
          </>
        }
      />
    </Card>
  );
};
