import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Button, MenuItem } from '@mui/material';
// components
import { FormProvider, RHFSelect } from '../../../../components/hook-form';
import {
  CarePlanConditionToOpportunity,
  conditionsToOpportunitiesStatus,
  _conditions,
  _conditionsToOpportunities,
  _opportunities,
} from 'src/_mock/settings/_careplan-automation';
import api from 'src/services/api';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// ----------------------------------------------------------------------

type FormValue = {
  conditionId: CarePlanConditionToOpportunity['condition']['id'];
  opportunityId: CarePlanConditionToOpportunity['opportunity']['id'];
  status: CarePlanConditionToOpportunity['status'];
};

type ConditionToOpportunityFormProps = {
  currentMapping: CarePlanConditionToOpportunity;
};

const schema = Yup.object().shape({
  opportunityId: Yup.string().required('Opportunity is required'),
  conditionId: Yup.string().required('Condition is required'),
  status: Yup.string().oneOf(['active', 'inactive']).required('Status is required'),
});

const resolver = yupResolver(schema);

export default function ConditionsForm({ currentMapping }: ConditionToOpportunityFormProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { data: conditions = [] } = useQuery(
    ['conditions'],
    async () =>
      (await api.carePlan.findConditions({
        status: '',
      })) as any[]
  );

  const { data: opportunities = [] } = useQuery(
    ['opportunities'],
    async () =>
      (await api.carePlan.findOpportunities({
        status: '',
      })) as any[]
  );

  const defaultValues = useMemo(
    () =>
      ({
        conditionId: currentMapping?.condition.uuid || '',
        opportunityId: currentMapping?.opportunity.uuid || '',
        status: currentMapping?.status || conditionsToOpportunitiesStatus.active,
      } as FormValue),
    [currentMapping]
  );

  const methods = useForm({ resolver, defaultValues });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (currentMapping) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMapping]);

  const onSubmit = async (data: FormValue) => {
    try {
      await api.carePlan.updateMappingsConditionOpportunity({
        ...data,
        uuid: currentMapping?.uuid,
      });

      reset();
      enqueueSnackbar('Update success!');
      navigate('..');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        enqueueSnackbar(
          error.response?.data?.message?.[0] || error?.message || 'Something went wrong!',
          {
            variant: 'error',
          }
        );
      }

      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 3 }}>
        <Stack
          gap={3}
          sx={{
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <RHFSelect name="conditionId" label="Condition">
            {conditions.map((condition) => (
              <MenuItem key={condition.uuid} value={condition.uuid}>
                {condition.name}
              </MenuItem>
            ))}
          </RHFSelect>

          <RHFSelect name="opportunityId" label="Opportunity">
            {opportunities.map((opportunity) => (
              <MenuItem key={opportunity.uuid} value={opportunity.uuid}>
                {opportunity.name}
              </MenuItem>
            ))}
          </RHFSelect>

          <RHFSelect name="status" label="Status">
            {Object.entries(conditionsToOpportunitiesStatus).map(([key, value]) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </RHFSelect>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }} gap={2}>
          <Button variant="text" color="inherit" component={RouterLink} to="..">
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Save Changes
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
