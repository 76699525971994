import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import AddressList from "./AddressList";
import AddAddress from "./AddAddress";
import { CloseOutlined } from "@mui/icons-material";

const FixAddressDialog = ({
  isOpen,
  handleClose,
  addressesList,
  handleSave,
  saveSelectedAddress,
  addAddressTab,
  handleAddAddressOpen,
}: any) => {
  const [addresses, setAddresses] = useState<any[]>(addressesList || []);
  const [openDialog, setOpenDialog] = useState(isOpen);

  useEffect(() => {
  }, [addAddressTab]);

  useEffect(() => {
    if (openDialog) handleAddAddressOpen(false);
  }, [openDialog]);

  useEffect(() => {
    setAddresses(addressesList);
  }, [addressesList]);

  useEffect(() => {
    setOpenDialog(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth="md">
        <DialogTitle>
          <Grid container>
            <Grid item xs={6}>
              <Stack direction="row" spacing={2}>
                <Typography
                  variant="subtitle1">
                  Addresses {JSON.stringify(addAddressTab)}
                </Typography>
                {
                  !addAddressTab
                  && <Button
                      variant="contained"
                      size="small"
                      onClick={() => handleAddAddressOpen(true)}>
                      Add Address
                    </Button>
                }
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row" justifyContent="end">
                <IconButton size="small" onClick={handleClose}>
                  <CloseOutlined />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          {
            addAddressTab
            ?
              <AddAddress
                handleClose={() => handleAddAddressOpen(false)}
                handleSave={handleSave} />
            :
              <AddressList addresses={addresses} saveSelectedAddress={saveSelectedAddress} />
          }
        </DialogContent>
    </Dialog>
  );
};

export default FixAddressDialog;