import moment from 'moment';
import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { isBetweenDates } from 'src/utils/dates';
import OutboundActionMenu from './OutboundActionMenu';
import useObjectState from 'src/hooks/useObjectState';
import CheckboxList from 'src/components/CheckboxList';
import SeverityStatus from 'src/components/SeverityStatus';
import useGridFilters, { GridFilters } from 'src/hooks/useGridFilters';
import { ServiceRequestGridRowData } from '../../../../../@types/crs/case';
import useGridFilterConsolidation from 'src/hooks/useGridFilterConsolidation';
import PeriodFilter, { onDateChange } from '../../../../../components/PeriodFilter';
import { WrappedPatient } from '../../../../../@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCarePlan } from '../../../../../@nicheaim/fhir-base/wrappers/CarePlan';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { WrappedDocumentReference } from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import { getServiceRequestGridRows, searchIfContainedInObj } from 'src/sections/crs/helpers/common';
import DataGridWithFilters from 'src/sections/crs/case/components/DataGridWithFilters/DataGridWithFilters';
import OutbounReferralAdd, {
  ServiceRequestStatus,
  serviceRequestStatuses,
} from '../outbound/OutboundReferralAdd';

export interface ServiceRequestFilters {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  status: ServiceRequestStatus[];
}
interface ServiceRequestGridProps {
  title?: string;
  isNestedGrid?: boolean;
  showPagination?: boolean;
  patient: WrappedPatient | null;
  carePlan?: WrappedCarePlan | null;
  serviceRequestId: string | undefined;
  serviceRequest: WrappedServiceRequest[];
  documentReferences: WrappedDocumentReference[] | null;
  handleServiceRequest: any;
  handleCreateBynary: any;
  handleCreateDocumentReference: any;
  handleUpdateDocumentReference: any;
}

export const initialServiceRequestFilters: ServiceRequestFilters = {
  startDate: null,
  endDate: null,
  status: [],
};

const OutboundGrid = ({
  serviceRequestId,
  serviceRequest,
  isNestedGrid,
  showPagination,
  patient,
  carePlan,
  documentReferences,
  title,
  handleServiceRequest,
  handleCreateBynary,
  handleCreateDocumentReference,
  handleUpdateDocumentReference,
}: ServiceRequestGridProps) => {
  const {
    isFilterDrawerOpen,
    onFilterDrawerOpen,
    onFilterDrawerClose,
    onSearchTextFieldChange,
    searchTextFieldValue,
    filters,
    onApplyFilters,
  } = useGridFilters<ServiceRequestFilters>(initialServiceRequestFilters);

  const {
    filters: filter,
    updateFilters,
    onClearAllFilters,
  } = useGridFilterConsolidation<ServiceRequestFilters>(filters, {
    ...initialServiceRequestFilters,
  });

  const [serviceRequestGridRows, setServiceRequestGridRows] = useState<ServiceRequestGridRowData[]>(
    []
  );

  const [{ isServiceRequestModalOpen, serviceRequestToEdit }, updateState] = useObjectState<{
    isServiceRequestModalOpen: boolean;
    serviceRequestToEdit: WrappedServiceRequest | null;
  }>({
    serviceRequestToEdit: null,
    isServiceRequestModalOpen: false,
  });

  const handleDateFilterChange =
    (dateProp: 'endDate' | 'startDate'): onDateChange =>
    (newValue) => {
      updateFilters({ [dateProp]: newValue });
    };

  const handleOnServiceRequestEdit = (serviceRequest: WrappedServiceRequest) => {
    updateState({ serviceRequestToEdit: serviceRequest, isServiceRequestModalOpen: true });
  };

  const columns: GridColDef[] = [
    { field: 'serviceReferral', headerName: 'Service Referral', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
    },
    { field: 'assignedTo', headerName: 'Assigned To', flex: 1 },
    { field: 'assignedOn', headerName: 'Assigned On', flex: 1 },
    {
      field: 'dueTo',
      headerName: 'Due To',
      flex: 1,
      renderCell: (params) => {
        const { dueTo } = params.row as ServiceRequestGridRowData;
        return <SeverityStatus status={dueTo} />;
      },
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        const { wrappedServiceRequest } = params.row as ServiceRequestGridRowData;
        return (
          <OutboundActionMenu
            serviceRequest={wrappedServiceRequest}
            onEditServiceRequest={handleOnServiceRequestEdit}
            updateServiceRequest={handleServiceRequest}
          />
        );
      },
    },
  ];

  useEffect(() => {
    const serviceRequestGridRows = getServiceRequestGridRows(serviceRequest);
    setServiceRequestGridRows(
      filterServiceRequestGridRows(serviceRequestGridRows, { filters, searchTextFieldValue })
    );
  }, [serviceRequest, filters, searchTextFieldValue]);

  const { startDate, endDate, status: selectedStatuses } = filter;
  return (
    <>
      <DataGridWithFilters
        addButtonTitle={'Child'}
        title={title || ''}
        onAddButtonClick={() => {
          updateState({
            isServiceRequestModalOpen: true,
            serviceRequestToEdit: null,
          });
        }}
        onRowClick={(params) => {
          const { wrappedServiceRequest } = params.row as ServiceRequestGridRowData;
          updateState({ serviceRequestToEdit: wrappedServiceRequest });
        }}
        getRowClassName={() => 'rowPointer'}
        onFilterDrawerOpen={onFilterDrawerOpen}
        rows={serviceRequestGridRows}
        columns={columns}
        getRowId={(row) => row.id}
        showPagination={showPagination}
        autoHeight={true}
        onSearchTextFieldChange={onSearchTextFieldChange}
        searchTextFieldValue={searchTextFieldValue}
        isNestedGrid={isNestedGrid}
        Filters={
          <ServiceRequestFilter
            startDate={startDate}
            endDate={endDate}
            selectedStatuses={selectedStatuses}
            onStartDateChange={handleDateFilterChange('startDate')}
            onEndDateChange={handleDateFilterChange('endDate')}
            onStatusSelectionChange={(checkedItems) => {
              updateFilters({ status: checkedItems });
            }}
          />
        }
        FilterDrawerProps={{
          title: 'Child',
          open: isFilterDrawerOpen,
          onApplyButtonClick: () => {
            onApplyFilters(filter);
          },
          onCloseIconButtonClick: onFilterDrawerClose,
          onClearAllButtonClick: onClearAllFilters,
        }}
      />

      <OutbounReferralAdd
        serviceRequestId={serviceRequestId}
        open={isServiceRequestModalOpen}
        serviceRequest={serviceRequestToEdit}
        patient={patient}
        carePlan={carePlan ?? null}
        documentReferences={documentReferences}
        title={'A Child'}
        handleOutboundReferral={handleServiceRequest}
        handleCreateBynary={handleCreateBynary}
        handleCreateDocumentReference={handleCreateDocumentReference}
        handleUpdateDocumentReference={handleUpdateDocumentReference}
        onCancel={() => {
          updateState({
            isServiceRequestModalOpen: false,
          });
        }}
      />
    </>
  );
};

interface ServiceRequestFilterProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  onStartDateChange: onDateChange;
  onEndDateChange: onDateChange;
  onStatusSelectionChange: (checkedStatus: ServiceRequestStatus[]) => void;
  selectedStatuses: ServiceRequestStatus[];
}

const ServiceRequestFilter = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onStatusSelectionChange,
  selectedStatuses,
}: ServiceRequestFilterProps) => (
  <Box py={3}>
    <Box marginBottom={1}>
      <PeriodFilter
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
      />
    </Box>

    <CheckboxList
      containerSx={{ marginBottom: 4, marginTop: 3 }}
      title="Status"
      keyExtractor={(item) => item.value as string}
      labelExtractor={(item) => item.label}
      onChange={onStatusSelectionChange}
      options={serviceRequestStatuses}
      externalCheckedItems={selectedStatuses}
    />
  </Box>
);

const filterServiceRequestGridRows = (
  serviceRequestGridRows: ServiceRequestGridRowData[],
  { filters, searchTextFieldValue }: GridFilters<ServiceRequestFilters>
): ServiceRequestGridRowData[] => {
  let filteredServiceRequestGridRows = [...serviceRequestGridRows];
  const searchByString = searchTextFieldValue?.toLowerCase().trim() ?? '';
  if (searchByString.length >= 3) {
    filteredServiceRequestGridRows = filteredServiceRequestGridRows.filter(
      (serviceRequestGridRow) =>
        searchIfContainedInObj(serviceRequestGridRow, ['serviceReferral', 'status'], searchByString)
    );
  }
  const { startDate, endDate, status } = filters;

  return filteredServiceRequestGridRows.filter((serviceRequestRow) => {
    const serviceRequest = serviceRequestRow.wrappedServiceRequest;
    const serviceRequestStartDate = moment(serviceRequest?.authoredOn ?? null);
    const serviceRequestEndDate = moment(serviceRequest?.occurrencePeriod?.end ?? null);
    if (startDate?.isValid?.() && endDate?.isValid?.()) {
      if (!serviceRequestStartDate.isValid() || !serviceRequestEndDate.isValid()) return false;

      if (!isBetweenDates(serviceRequestStartDate, serviceRequestEndDate, startDate, endDate))
        return false;
    }

    if (startDate?.isValid?.()) {
      if (!serviceRequestStartDate.isValid()) return false;
      if (!serviceRequestStartDate.isSameOrAfter(startDate)) return false;
    }

    if (endDate?.isValid?.()) {
      if (!serviceRequestEndDate.isValid()) return false;
      if (!serviceRequestEndDate.isSameOrBefore(endDate)) return false;
    }

    if (status.length) {
      if (!status.find((status) => status.value === serviceRequest?.status)) return false;
    }
    return true;
  });
};

export default OutboundGrid;
