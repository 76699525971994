import { Alert } from '@mui/material';
import { useParams } from 'react-router';
import { useOrganizations } from 'src/@nicheaim/fhir-react';
import fhirSystem from 'src/fhir/system';
import NewClientForm from '../new';

export default function EditClient() {
  const { client: clientId = '' } = useParams();
  const [[client], { isLoading }] = useOrganizations({
    filter: {
      identifier: fhirSystem.client.forCode(clientId),
    },
  });

  if (!clientId && !isLoading) return <Alert severity="error">Client not found</Alert>;
  return <NewClientForm isEdit currentClient={client!} />;
}
