import React from 'react';
import {
  Typography,
  Grid,
  Box,
  Divider,
  InputLabel,
  FormControl,
  TextField,
  Alert,
} from '@mui/material';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DatePicker } from '@mui/lab';
import useAuth from 'src/hooks/useAuth';
import useObjectState from 'src/hooks/useObjectState';
import ActionButtons from '../../common/ActionButtons';
import { MedicationRequest } from 'src/@nicheaim/fhir-base/mappings/MedicationRequest';
import { useMedicationAdministrations, useMedicationRequests } from 'src/@nicheaim/fhir-react';
import { Bundle, BundleEntry } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import { useQueryClient } from '@tanstack/react-query';

type DiscontinueMedicationRequestFormPropsType = {
  medicationRequest: MedicationRequest;
  onClose: () => void;
  onSave: (medicationRequest: MedicationRequest) => void;
};

type DiscontinueMedicationRequestFormState = {
  date: Date;
  hour: string;
  note: string;
  reason: string;
  error: string | null;
  isLoading: boolean;
};

const DiscontinueMedicationRequestForm = ({
  onClose,
  medicationRequest,
  onSave,
}: DiscontinueMedicationRequestFormPropsType) => {
  const queryClient = useQueryClient();
  const authUser = useAuth().getCurrentUser();
  const [{ date, hour, note, reason, error, isLoading }, updateState] =
    useObjectState<DiscontinueMedicationRequestFormState>({
      date: moment().toDate(),
      hour: moment().format('HH:mm'),
      note: '',
      reason: '',
      error: null,
      isLoading: false,
    });
  const [_, { update }] = useMedicationRequests({
    autofetch: false,
  });
  const [medicationsAdministrations] = useMedicationAdministrations({
    filter: {
      request: medicationRequest?.id,
      status: 'not-done',
    }
  });

  const onDiscontinue = async () => {
    updateState({
      isLoading: true,
    });

    if (!reason.trim()) {
      updateState({
        error: 'Please fill the reason field and try again',
        isLoading: false,
      });
      return;
    }

    const updatedPayload: MedicationRequest = {
      ...medicationRequest,
      status: 'stopped',
      statusReason: {
        coding: [],
        text: reason,
      }
    }
    updatedPayload.note?.push({
      text: note,
      time: moment().toISOString(),
      authorString: authUser.name,
    });
    await update(updatedPayload)
    .then(async () => {
      const discontinueAdministrationsPayload: Bundle = {
        resourceType: 'Bundle',
        type: "transaction",
        entry: medicationsAdministrations.map((ad) => {
          const entry: BundleEntry = {
            resource: {
              ...ad,
              status: 'stopped',
            },
            request: {
              url: `MedicationAdministration/${ad.id}`,
              method: 'PUT',
            }
          };

          return entry;
        })
      }

      await axiosFhirInstance.post('', discontinueAdministrationsPayload)
      .then(() => {
        updateState({
          isLoading: false,
          error: null,
        });
        onSave(updatedPayload);
        queryClient.refetchQueries({
          predicate(query) {
            return ['MedicationAdministration',  'MedicationDispense'].includes(
              query.queryKey[0] as string
            );
          },
        });
        onClose();
      }).catch(() => {
        updateState({
          error: 'Error elminating the pending medications.',
          isLoading: false,
        });
      })
    })
    .catch(() => {
      updateState({
        error: 'Please select one "contact made" option and try again.',
        isLoading: false,
      });
    })
  };

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Grid item>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Discontinue Medication Request #{medicationRequest?.identifier?.find(i => i.type?.coding?.find(c => c.code === 'RX'))?.value ?? ''}
          </Typography>
        </Grid>
      </Box>
      <Divider />
      <Box>
        <Grid container>
          <Grid
            item
            xl={6}
            style={{ marginTop: '15px' }}
            sx={{ gap: 1 }}
          >
            <InputLabel shrink>Date</InputLabel>
            <FormControl fullWidth>
              <DatePicker
                value={date}
                onChange={(date) => updateState({ date: moment(date).toDate() })}
                renderInput={(params) => (
                  <TextField required variant="standard" type="date" {...params} />
                )}
                disabled
              />
            </FormControl>
          </Grid>
          <Grid item xl={6} style={{ marginTop: '15px' }}>
            <InputLabel shrink>Time</InputLabel>
            <FormControl fullWidth>
              <TextField
                required
                value={hour}
                defaultValue={hour}
                onChange={(e) => {
                  console.log({
                    h: e.target.value,
                  });
                  updateState({ hour: e.target.value });
                }}
                type="time"
                variant="standard"
                disabled
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xl={12} style={{ marginTop: '15px' }}>
          <InputLabel>Status reason</InputLabel>
          <FormControl fullWidth>
            <TextField
              value={reason}
              defaultValue={reason}
              onChange={(e) => {
                updateState({ reason: e.target.value });
              }}
              variant="standard"
              required
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Grid item xl={12} style={{ marginTop: '15px', marginBottom: 70 }}>
            <InputLabel sx={{ mb: 2 }}>Note</InputLabel>
            <FormControl fullWidth>
              <ReactQuill
                theme="snow"
                value={note}
                onChange={(val) => updateState({ note: val })}
                style={{
                  height: '100px'
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        {error && (
          <Alert style={{ marginTop: '20px' }} severity="error">
            {error}
          </Alert>
        )}
        <ActionButtons
          isLoading={isLoading}
          leftButtonTitle={'Cancel'}
          onLeftButtonPress={onClose}
          rightButtonTitle={'Sign and close'}
          onRightButtonPress={onDiscontinue}
        />
      </Box>
    </Box>
  );
};

export default DiscontinueMedicationRequestForm;
