import { Stack, Box } from '@mui/material';
import { useEffect } from 'react';
import { useLocation, useMatch } from 'react-router';
import { REACT_APP_SECTION_CRS } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import { PatientCard } from 'src/sections/careflow/common';
import PatientCardSearch from 'src/sections/crs/common/PatientCardSearch';
import usePatientTabsStore from 'src/stores/patient-tabs';
import { getCuteScrollProps } from 'src/utils/cssStyles';
import {
  CCM_ACLS,
  CCMUser,
  checkAclValidation,
  CRS_ACLS,
  getRelatedAcls,
  SysAdmin,
} from 'src/utils/permissions/permission.utils';
import { validate as isUUID } from 'uuid';

export default function PatientTabs() {
  const user = useAuth();
  const { openPatientIds } = usePatientTabsStore();
  const location = useLocation();
  const patientViewCCM = useMatch('/dashboard/patient/:patientId/:currentTab');
  const patientViewCRS = useMatch('/dashboard/crs/patient/:patientId');
  const urlMatch = patientViewCCM ?? patientViewCRS;

  const setCurrentPatient = usePatientTabsStore((s) => s.setCurrentPatient);
  const openPatient = usePatientTabsStore((s) => s.openPatient);
  const isOpen = usePatientTabsStore((s) =>
    s.openPatientIds.includes(urlMatch?.params.patientId || '')
  );

  useEffect(() => {
    if (!urlMatch || !urlMatch.params.patientId) {
      return;
    }

    if (!isOpen && isUUID(urlMatch.params.patientId ?? '')) {
      openPatient(urlMatch.params.patientId);
    } else {
      setCurrentPatient(urlMatch.params.patientId);
    }
  }, [location, urlMatch, setCurrentPatient, openPatient, isOpen]);

  console.log({
    acls: getRelatedAcls(CCM_ACLS.PATIENT_CARD.read)
  })
  return (
    <Box
      sx={{
        width: '100%',
        overflowX: 'scroll',
        display: 'flex',
        flexDirection: 'row',
        ...getCuteScrollProps(),
      }}
    >
      <>
        {REACT_APP_SECTION_CRS.toLowerCase() !== 'crs' ? (
          <>
            {checkAclValidation({ user, acl: getRelatedAcls(CCM_ACLS.PATIENT_CARD.read) }) && (
              <Stack direction="row" gap={1}>
                {openPatientIds.map((id) => (
                  <PatientCard key={id} id={id} />
                ))}
              </Stack>
            )}
          </>
        ) : (
          <>
            {checkAclValidation({
              user,
              acl: getRelatedAcls(CRS_ACLS.PATIENT.PATIENT_CARD.READ),
            }) && (
              <Stack direction="row" gap={1}>
                {openPatientIds.map((id) => (
                  <PatientCardSearch key={id} id={id} />
                ))}
              </Stack>
            )}
          </>
        )}
      </>
    </Box>
  );
}
