import { useEffect, useState } from 'react';
import { useDialogStore } from 'src/stores/dialog';

export type UseRecoverableChangesReturn<T> = void;

export interface UseRecoverableChangesOptions<T> {
  key: string;
  state: T;
  dialog?: {
    title?: string;
    description?: string;
    recoverText?: string;
    discardText?: string;
  };
  areChangesUnsaved?(state: T | null): boolean;
  onRecover?(changes: T): Promise<void> | void;
  onDiscard?(): Promise<void> | void;
}

/**
 * Check local storage for unsaved changes. If there are any, ask the user if they want to recover them.
 */
export default function useRecoverableChanges<T>(
  options: UseRecoverableChangesOptions<T>
): UseRecoverableChangesReturn<T> {
  const key = `unsaved-changes:${options.key}`;

  const {
    state,
    onRecover,
    onDiscard,
    areChangesUnsaved,
    dialog: {
      title = 'Recover unsaved changes',
      description = 'You have unsaved changes from your previous session. Do you want to recover them?',
      recoverText = 'Recover',
      discardText = 'Discard',
    } = {},
  } = options;
  const { confirmDialog } = useDialogStore();
  const [alreadyShown, setAlreadyShown] = useState(false);
  const [alreadyDecided, setAlreadyDecided] = useState(false);

  useEffect(() => {
    if (alreadyShown || !key || !confirmDialog) return;
    setAlreadyShown(true);
    recoverPreviousChanges();

    async function recoverPreviousChanges() {
      const recoveredChanges = JSON.parse(localStorage.getItem(key) || 'null');
      if (areChangesUnsaved ? !areChangesUnsaved(recoveredChanges) : !recoveredChanges) {
        // nothing to recover, start saving changes
        setAlreadyDecided(true);
        return;
      }

      const userWantsRecovery = await confirmDialog({
        key,
        title,
        description,
        confirmText: recoverText,
        denyText: discardText,
      });

      if (userWantsRecovery) {
        await onRecover?.(recoveredChanges);
      } else {
        await onDiscard?.();
      }

      localStorage.removeItem(key);
      setAlreadyDecided(true);
    }
  }, [
    key,
    alreadyShown,
    onRecover,
    onDiscard,
    confirmDialog,
    areChangesUnsaved,
    title,
    description,
    recoverText,
    discardText,
  ]);

  // Save changes to local storage only if user already
  useEffect(() => {
    if (!alreadyShown || !alreadyDecided) {
      return;
    }

    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state, alreadyShown, alreadyDecided]);
}

export function clearRecoverableChanges(key: string) {
  localStorage.removeItem(`unsaved-changes:${key}`);
}

export function clearAllRecoverableChanges() {
  Object.keys(localStorage)
    .filter((key) => key.startsWith('unsaved-changes:'))
    .forEach((key) => localStorage.removeItem(key));
}

export function getRecoverableChanges<T>(key: string): T | null {
  return JSON.parse(localStorage.getItem(`unsaved-changes:${key}`) || 'null');
}
