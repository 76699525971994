import { Box, Card, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';
import { useQuestionnaireResponse } from 'src/@nicheaim/fhir-react';
import Iconify from 'src/components/Iconify';
import { useCurrentPatient } from 'src/stores/patient-tabs';

interface AssessmentLoadingScreenProps {
  onZoom?: () => void;
}

// FIXME: NOT NAVIGATABLE, DELETE THIS ROUTE
export default function AssessmentLoadingScreen({ onZoom }: AssessmentLoadingScreenProps) {
  const patient = useCurrentPatient();
  const navigate = useNavigate();
  const { assessment: assessmentId = '' } = useParams();
  const [questionnaireResponse, { refresh, ...others }] = useQuestionnaireResponse(assessmentId, {
    baseQueryOptions: {
      refetchOnMount: 'always',
    },
  });
  const { isLoading } = others;

  console.log('log assessment index', others);

  useEffect(() => {
    refresh();
  }, []);

  if (!patient) {
    return <Navigate to="/dashboard/patient" replace />;
  }

  if (!questionnaireResponse && !isLoading) {
    return <Navigate to={`/dashboard/patient/${patient?.id}/care-record/assessments`} />;
  }

  if (isLoading) {
    return (
      <Card sx={{ height: `calc(100vh - 200px)`, display: 'flex', flexDirection: 'column' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
          <Typography variant="subtitle1">View Assessment</Typography>

          <Box>
            <IconButton onClick={() => onZoom?.()}>
              <Iconify icon="eva:expand-fill" />
            </IconButton>

            <IconButton
              onClick={() => navigate(`/dashboard/patient/${patient?.id}/care-record/assessments`)}
            >
              <Iconify icon="material-symbols:close-rounded" />
            </IconButton>
          </Box>
        </Stack>

        <Stack direction="column" alignItems="center" justifyContent="center" gap={2} sx={{ p: 5 }}>
          <Typography>Loading assessment info</Typography>
          <CircularProgress />
        </Stack>
      </Card>
    );
  }

  console.log(questionnaireResponse);
  return questionnaireResponse?.status === 'completed' ? (
    <Navigate
      to={`/dashboard/patient/${patient?.id}/care-record/assessments/${assessmentId}/view`}
      replace
    />
  ) : (
    <Navigate
      to={`/dashboard/patient/${patient?.id}/care-record/assessments/${assessmentId}/fill`}
      replace
    />
  );
}
