import { LoadingButton } from '@mui/lab';
import {
  Box,
  Fade,
  Modal,
  ModalProps,
  Typography,
  Backdrop,
  SxProps,
  Button,
  Grid,
  GridProps,
} from '@mui/material';
import React from 'react';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface CustomModalBasicProps extends Pick<ModalProps, 'open' | 'onClose'> {}

export interface CustomModalProps
  extends Omit<ModalProps, 'slots' | 'slotProps' | 'closeAfterTransition'> {
  title: string;
  breadcrumbs: string[];
  onCancel: Function;
  onSave?: Function;
  isLoading?: boolean;
  containerSx?: SxProps;
  showSaveButton?: boolean;
  childrenWithoutPadding?: React.ReactNode;
}

const buttonStyles: SxProps = {
  height: 36,
};

const padding: SxProps = { paddingX: 3 };

const CustomModal = ({
  title,
  open,
  breadcrumbs,
  onSave,
  onCancel,
  children,
  isLoading,
  containerSx,
  showSaveButton = true,
  childrenWithoutPadding = null,
  ...modalProps
}: CustomModalProps) => (
  <Modal
    closeAfterTransition
    open={open}
    slots={{ backdrop: Backdrop }}
    slotProps={{
      backdrop: {
        timeout: 500,
      },
    }}
    {...modalProps}
  >
    <Fade in={open}>
      <Box
        sx={[
          {
            position: 'absolute' as 'absolute',
            transform: 'translate(-50%, -50%)',
            width: '40vw',
            top: '50%',
            left: '50%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '16px',
            paddingY: 3,
          },
          ...spreadSxProp(containerSx),
        ]}
      >
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} sx={padding}>
          <Typography variant="h6">{title}</Typography>
          <Box sx={{ marginLeft: 1 }} display={'flex'} flexDirection={'row'} alignItems={'center'}>
            {breadcrumbs.map((breadcrumbTitle, index) => (
              <BreadCrumbTitle
                key={breadcrumbTitle}
                useSeparator={!!index}
                title={breadcrumbTitle}
              />
            ))}
          </Box>
        </Box>
        {!!childrenWithoutPadding && <Box sx={{ width: '100%' }}>{childrenWithoutPadding}</Box>}
        <Box sx={{ width: '100%', ...padding }}>{children}</Box>
        <Box sx={padding} mt={4} display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
          <Button
            sx={{
              color: '#212b36',
              width: 78,
              border: 0,
              mr: 1.2,
              '&:hover': { border: 0 },
              ...buttonStyles,
            }}
            onClick={() => {
              onCancel?.();
            }}
            variant="outlined"
            color="inherit"
            disabled={!!isLoading}
          >
            Cancel
          </Button>
          {showSaveButton && (
            <LoadingButton
              loading={!!isLoading}
              sx={[buttonStyles, { width: 127 }]}
              onClick={() => {
                onSave?.();
              }}
              variant="contained"
            >
              Save Changes
            </LoadingButton>
          )}
        </Box>
      </Box>
    </Fade>
  </Modal>
);

export const gridItemColumnSize = 5.88;

export const GridSection = ({ children, mt }: { children: React.ReactNode; mt?: number }) => (
  <Grid
    item
    mt={mt ?? 3}
    xs={12}
    display={'flex'}
    flexDirection={'row'}
    justifyContent={'space-between'}
  >
    {children}
  </Grid>
);

export const GridItem = ({ children, xs, ...gridProps }: GridProps) => (
  <Grid item xs={xs ?? gridItemColumnSize} {...gridProps}>
    {children}
  </Grid>
);

export interface BreadCrumbTitleProps {
  title: string;
  useSeparator?: boolean;
}

const BreadCrumbTitle = ({ title, useSeparator }: BreadCrumbTitleProps) => {
  const styles: SxProps = {
    color: '#919eab',
    mx: 0.4,
  };
  return (
    <>
      {!!useSeparator && <Typography sx={{ fontSize: '1.4rem', ...styles }}>•</Typography>}
      <Typography sx={{ fontSize: '0.8rem', ...styles }}>{title}</Typography>
    </>
  );
};

export default CustomModal;
