import { useState } from 'react';

interface UseCycleReturnType<T> {
  value: T;
  index: number;
  direction: number;
  next: () => void;
  prev: () => void;
  reset: () => void;
  set: (value: T) => void;
  getIndexOf: (value: T) => number | null;
  setIndex: (index: number) => void;
}

interface UseCycleOptions {
  initial?: number;

  /**
   * If true, the cycle will loop back to the first value when the last value is reached.
   */
  loopback?: boolean;
}

export default function useCycle<T>(values: T[], options?: UseCycleOptions): UseCycleReturnType<T> {
  const [index, setIndex] = useState(options?.initial || 0);
  const [direction, setDirection] = useState(1);
  const value = values[index];

  /**
   * Makes sure the index is in the range of the values. And update direction
   * as needed
   */
  const cycle = (newIndex: number) => {
    if (options?.loopback) {
      if (direction > 0 && newIndex >= values.length - 1) {
        setIndex(values.length - 1);
        setDirection(-1);
      } else if (direction < 0 && newIndex <= 0) {
        setIndex(0);
        setDirection(1);
      } else {
        setIndex(newIndex);
      }
    } else {
      if (direction > 0 && newIndex >= values.length) {
        setIndex(0);
      } else if (direction < 0 && newIndex < 0) {
        setIndex(values.length - 1);
      } else {
        setIndex(newIndex);
      }
    }
  };

  /**
   * Cycle through to the next value
   */
  const next = () => {
    cycle(index + direction);
  };

  /**
   * Cycle through to the previous value
   */
  const prev = () => {
    cycle(index - direction);
  };

  /**
   * Reset the index to the initial value
   */
  const reset = () => {
    setIndex(options?.initial || 0);
  };

  /**
   * Set the index to a specific value
   *
   * If the value is not in the values array, nothing will happen to pevent
   * unkown behavior.
   */
  const set = (value: T) => {
    const index = values.indexOf(value);

    if (index === -1) {
      return;
    }

    cycle(index);
  };

  /**
   * Get the index of a specific value
   *
   * If the value is not in the values array, null will be returned.
   */
  const getIndexOf = (value: T) => {
    const index = values.indexOf(value);

    if (index === -1) {
      return null;
    }

    return index;
  };

  /**
   * Set the index to a specific value
   */
  const setIndexTo = (index: number) => {
    cycle(index);
  };

  return {
    value,
    index,
    direction,
    next,
    prev,
    reset,
    set,
    getIndexOf,
    setIndex: setIndexTo,
  };
}
