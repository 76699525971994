import { IconButton, MenuItem, Stack, TextField, ToggleButton } from '@mui/material';
import Iconify from 'src/components/Iconify';

interface EmailFieldProps {
  fullWidth?: boolean;
  isLast?: boolean;
  onAdd?: () => void;
  onChangeValue?: (value: string) => void;
  onChangeType?: (type: string) => void;
  onRemove?: () => void;
  onSelectFavorite?: (event: React.MouseEvent<HTMLElement>, value: any) => void;
  rank?: number;
  type: string;
  value: string;
  disabled?: boolean;
}

const EmailField = ({
  fullWidth,
  isLast,
  onAdd,
  onChangeType,
  onChangeValue,
  onRemove,
  onSelectFavorite,
  rank,
  type = 'home',
  value = '',
  disabled = false,
}: EmailFieldProps) => {
  const handleChangeType = (ev: any) => {
    if (onChangeType) {
      onChangeType(ev.target.value);
    }
  };

  const handleChangeValue = (ev: any) => {
    if (onChangeValue) {
      onChangeValue(ev.target.value);
    }
  };

  return (
    <Stack alignItems="baseline" direction="row" spacing={2}>
      {onChangeType ? (
        <TextField
          select
          label="Email"
          size="small"
          margin="dense"
          variant="standard"
          onChange={handleChangeType}
          value={type}
          disabled={disabled}
        >
          <MenuItem value="home">Personal</MenuItem>
          <MenuItem value="work">Work</MenuItem>
        </TextField>
      ) : null}
      <TextField
        fullWidth
        placeholder="Email"
        size="small"
        variant="standard"
        onChange={handleChangeValue}
        value={value}
        disabled={disabled}
      />
      <Stack direction="row" spacing={0}>
        {isLast && !disabled ? (
          <IconButton size="small" onClick={onAdd}>
            <Iconify icon="eva:plus-outline" />
          </IconButton>
        ) : null}
        {onRemove ? (
          <IconButton size="small" disabled={!onRemove} onClick={onRemove}>
            <Iconify icon="eva:minus-outline" />
          </IconButton>
        ) : null}
        {onSelectFavorite ? (
          <ToggleButton
            size="small"
            value={rank === 1}
            selected={rank === 1}
            onChange={onSelectFavorite}
          >
            <Iconify icon={rank === 1 ? 'eva:star-fill' : 'eva:star-outline'} />
          </ToggleButton>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default EmailField;
