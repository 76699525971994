import { Button, Dialog, DialogContent, DialogActions, Typography } from '@mui/material';
import { exists } from 'i18next';
import produce from 'immer';
import { useEffect, useState } from 'react';
import { CareTeam } from 'src/@nicheaim/fhir-base/mappings/CareTeam';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { useCareTeams, usePractitioner } from 'src/@nicheaim/fhir-react';
import useAuth from 'src/hooks/useAuth';
import { getFhirIdFromReferenceString } from '../../Activities/activities-utils';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';

export interface IAssignToPractitionerData {
  patientId: string;
  assign: boolean;
}

interface IAssignToPractitionerProps {
  data: IAssignToPractitionerData;
  onFinish: (practitionerName: string) => void;
}

const message = {
  alreadyAssigned: 'This patient is already assigned to you.',
  successfullyAssigned: (name?: string) =>
    `You have been assigned to ${name ? name : 'the'} Care Team`,
};

export default function AssignToPractitioner({ data, onFinish }: IAssignToPractitionerProps) {
  const [, { create: createCareTeam, update: updateCareTeam }] = useCareTeams({
    filter: {
      patient: data.patientId,
    },
  });

  const user = useAuth();
  const [userId, setUserId] = useState<string>();
  const [fhirUserId, setFhirUserId] = useState<string>();
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const [practitioner] = usePractitioner(getFhirIdFromReferenceString(fhirUserId ?? ''), {
    map: PractitionerWrapper,
  });

  useEffect(() => {
    const currentUser = user.getCurrentUser();
    if (currentUser.id === undefined) return;
    setUserId(currentUser.id);
    if (currentUser.user_fhir_uri === undefined) return;
    setFhirUserId(currentUser.user_fhir_uri);
  }, [user]);

  function getCareTeam() {
    const newCareTeam: CareTeam = {
      resourceType: 'CareTeam',
      status: 'active',
      subject: {
        reference: `Patient/${data.patientId}`,
      },
      participant: [],
    };

    return newCareTeam;
  }

  async function assignPractitionerToPatient() {
    const { data: response } = await axiosFhirInstance.get(`/CareTeam?subject=${data.patientId}`);
    const hasExistingTeam = response?.entry?.length > 0;
    const careTeam = hasExistingTeam ? response?.entry?.[0]?.resource : getCareTeam();

    const alreadyInTeam = careTeam?.participant?.some((p) => p?.member?.reference === fhirUserId);
    if (alreadyInTeam) {
      setDialogMessage(message.alreadyAssigned);
      setShowDialog(!!alreadyInTeam);
      return;
    }

    const updatedCareTeam: CareTeam | CareTeam[] = produce(careTeam, (draft) => {
      if (draft.participant === undefined) draft.participant = [];

      const participantIndex = draft.participant.findIndex(
        (p) => p.member?.reference === fhirUserId
      );

      if (participantIndex < 0) {
        const newParticipant = {
          member: {
            reference: fhirUserId,
            display: practitioner?.getFullNameWithTitles() || '-',
          },
          role: [{ coding: [{ system: 'careTeamRole' }] }],
        };
        draft.participant.push(newParticipant);
      }
    }) as any;

    if (hasExistingTeam) {
      await updateCareTeam(updatedCareTeam);
    } else {
      await createCareTeam(updatedCareTeam);
    }
    setDialogMessage(message.successfullyAssigned());
    setShowDialog(true);

    const practitionerName = practitioner?.getFullNameWithTitles() ?? '-';

    onFinish(practitionerName);
  }

  useEffect(() => {
    if (!data.patientId || !data.assign || !practitioner) return;
    assignPractitionerToPatient();
  }, [data.patientId, data.assign, practitioner]);

  return (
    <>
      <Dialog open={showDialog}>
        <DialogContent>
          <Typography variant="h6">{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={() => {
              setShowDialog(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
