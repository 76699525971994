import { Box, IconButton, Grid, Stack, TextField, Typography, MenuItem } from '@mui/material';
import { useState } from 'react';
import Iconify from 'src/components/Iconify';
import { FormProvider, RHFTextField } from '../../../../../components/hook-form';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { MobileDatePicker } from '@mui/lab';

const EditMode = () => {

  const EventSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Title is required'),
    description: Yup.string().max(5000),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
  });

  const {
    control,
    formState: { isSubmitting },
  } = methods;

  return (
    <FormProvider methods={methods}  > 
      <Grid container spacing={3} sx={{ p: 2 }}>
        <Grid item xs={3} >
          <Stack direction="column" alignItems="left" justifyContent="space-between" >
            <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
              Street 1
            </Typography>
            <RHFTextField name="street1" label="" />

            <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
              Zip Code
            </Typography>
            <RHFTextField name="zipcode" label="" />
          </Stack>
        </Grid>

        <Grid item xs={3} >
          <Stack direction="column" alignItems="left" justifyContent="space-between" >
            <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
              Street 2
            </Typography>
            <RHFTextField name="street2" label="" />

            <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
              Country
            </Typography>
            <RHFTextField name="country" label="" />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="column" alignItems="left" justifyContent="space-between" >
            <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
              City
            </Typography>
            <RHFTextField name="city" label="" />

            <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
              Vigency
            </Typography>
            <Controller
              name="start"
              control={control}
              render={({ field }) => (
                <MobileDatePicker
                  {...field}
                  label=""
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="column" alignItems="left" justifyContent="space-between" >
            <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
                State
            </Typography>
            <RHFTextField name="state" label="" />
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  )
}
    
const DisplayMode = () => (
    <Grid container spacing={3} sx={{ p: 2 }}>
      <Grid item xs={3} >
        <Stack direction="column" alignItems="left" justifyContent="space-between" >
          <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
            Street 1
          </Typography>
          <Typography variant="body2">3382 Pine Tree Lane</Typography> 

          <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
            Zip Code
          </Typography>
          <Typography variant="body2">20005</Typography>
        </Stack>
      </Grid>

      <Grid item xs={3} >
        <Stack direction="column" alignItems="left" justifyContent="space-between" >
          <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
            Street 2
          </Typography>
          <Typography variant="body2">N/A</Typography> 

          <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
            Country
          </Typography>
          <Typography variant="body2">United States</Typography>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack direction="column" alignItems="left" justifyContent="space-between" >
          <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
            City
          </Typography>
          <Typography variant="body2">Washington</Typography> 

          <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
            Vigency
          </Typography>
          <Typography variant="body2">Feb 2011 to Current</Typography> 
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack direction="column" alignItems="left" justifyContent="space-between" >
          <Typography variant="body2" sx={{ color: 'text.secondary', py: 1 }}>
              State
          </Typography>
          <Typography variant="body2">MD</Typography> 
        </Stack>
      </Grid>
    </Grid>
  )

export default function () {

  const [editAddress, setEditAddress] = useState(false);

  return (
    <>
     <Stack direction="row" >
        <Typography variant='button' sx={{ textTransform: 'uppercase' }}>Address Details
          <IconButton onClick={() => setEditAddress(!editAddress)}>
            <Iconify icon={editAddress ? 'eva:save-outline' : 'eva:edit-outline' }/> 
          </IconButton>
        </Typography> 
        <Box >
          <TextField
            select
            size="small"
            value="Address 1"
            sx={{
              '& fieldset': { border: '0 !important' },
              '& .MuiSelect-select': { px: 1, py: 0.5 },
              '& .MuiOutlinedInput-root': { mt: 0.3, p: 0, border: `1px solid #008ECC`, borderRadius: 2, typography: 'body2', color: '#008ECC' },
              '& .MuiSelect-icon': { color: '#008ECC' },
            }}
          >
            <MenuItem sx={{ typography: 'body2', m: 0.5, borderRadius: 0.75, color: '#008ECC' }} value="Address 1">
              Address 1
            </MenuItem>
            <MenuItem sx={{ typography: 'body2', m: 0.5, borderRadius: 0.75, color: '#008ECC' }} value="Address 2">
              Address 2
            </MenuItem>
          </TextField>
        </Box>
      </Stack>
      
      
      {editAddress ? <EditMode />: <DisplayMode /> }
    </>
  );
}