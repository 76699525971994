import { Dialog, DialogContent, DialogTitle, Grid, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface IStandardLeftDialogContextData {
  setElement: (element: JSX.Element | null) => void;
}

const initialValue: IStandardLeftDialogContextData = { setElement: () => {} };

const StandardDialogContext = createContext<IStandardLeftDialogContextData>(initialValue);

export function useStandardLeftDialog() {
  const { setElement } = useContext<IStandardLeftDialogContextData>(StandardDialogContext);

  function closeStandardDialog() {
    setElement(null);
  }

  return { open: setElement, close: closeStandardDialog };
}

export interface IStandardLeftDialogData {
  isOpen: boolean;
  childrenDialogElement?: JSX.Element;
}

interface IStandardLeftDialogProviderProps {
  children: ReactNode;
  meta?: ReactNode;
  //   handleOnClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  paper: {
    margin: 0,
    height: '100%',
    width: '33vw',
    maxHeight: '100%',
    backgroundColor: 'transparent',
  },
  paperScrollBody: {
    overflowY: 'scroll',
  },
}));

export default function StandardLeftDialogProvider({
  children,
  meta,
}: IStandardLeftDialogProviderProps) {
  const classes = useStyles();
  const [childrenDialogElement, setChildrenDialogElement] = useState<JSX.Element | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    if (childrenDialogElement === null) {
      setOpenDialog(false);
    } else {
      setOpenDialog(true);
    }
  }, [childrenDialogElement]);

  function _setChildrenDialogElement(element: JSX.Element | null) {
    setChildrenDialogElement(element);
  }

  return (
    <StandardDialogContext.Provider value={{ setElement: _setChildrenDialogElement }}>
      <div>{children}</div>

      <Dialog
        classes={{
          root: classes.dialogRoot,
          paper: classes.paper,
          paperScrollBody: classes.paperScrollBody,
        }}
        open={openDialog}
        fullWidth
        maxWidth="md"
        onClose={() => {
          setChildrenDialogElement(null);
        }}
      >
        <DialogTitle>
          <Grid container>
            <Grid item xs={6}>
              <Stack direction="row" spacing={2}>
                {/* <Typography variant="subtitle1">{data.title}</Typography> */}
              </Stack>
            </Grid>
            {/* <Grid item xs={6}>
            <Stack direction="row" justifyContent="end">
              <Button
                color="inherit"
                variant="outlined"
                size="small"
                onClick={() => {
                  handleOnClose(currentSelectedRadioOption);
                }}
              >
                Done
              </Button>
            </Stack>
          </Grid> */}
          </Grid>
        </DialogTitle>

        {/* <Typography variant="body2">{data.text}</Typography> */}

        {childrenDialogElement && childrenDialogElement}
      </Dialog>
    </StandardDialogContext.Provider>
  );
}
