import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import Label from 'src/components/Label';
import DataTable, { DataTableColumn } from 'src/components/table/DataTable';
import api from 'src/services/api';
import { goalGroupStatus } from 'src/_mock/settings/_careplan-automation';
import { CreatedUpdated } from '../common';
import GoalGroupsContextMenu from './GoalGroupsContextMenu';

const columns: DataTableColumn[] = [
  {
    header: 'Goal Group',
    field: 'name',
  },
  {
    header: 'Status',
    field: 'status',
    render(value) {
      return (
        <Label color={value === 'active' ? 'success' : 'info'}>{goalGroupStatus[value]}</Label>
      );
    },
  },
  {
    header: 'Created',
    field: '$',
    render(value) {
      return <CreatedUpdated by={value.createdBy} date={value.createdOn} />;
    },
  },
  {
    header: 'Updated',
    field: '$',
    render(value) {
      return <CreatedUpdated by={value.updatedBy} date={value.updatedOn} />;
    },
  },
];

export default function GoalGroupsTable() {
  const { data: goalGroups = [], refetch } = useQuery(
    ['goal-groups'],
    async () =>
      (await api.carePlan.findGoalGroups({
        status: '',
      })) as any[]
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <DataTable
        columns={columns}
        data={goalGroups}
        selectable
        rowContextMenu={(item: any) => (
          <GoalGroupsContextMenu
            item={item}
            onDelete={() => {
              refetch();
            }}
          />
        )}
      />
    </div>
  );
}
