import { Box, Tab, Tabs, Typography } from '@mui/material';

import { Route, Routes, useMatch, useNavigate } from 'react-router';
import PatientDetails from './Patient/PatientDetails';
import PatientsList from './Patient/PatientsList';
import PatientsContextExample from './PatientsContextExample';
import DebugAutocomplate from './DebugAutocomplate';

export default function Debug() {
  const navigate = useNavigate();
  const currentTab = useMatch('/_debug/:tab/*')?.params.tab;

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h3">Debug Page</Typography>

      <Tabs value={currentTab} onChange={(_, tab) => navigate(tab)}>
        <Tab value="patients" label="Patients" />
        <Tab value="patients-context" label="Patients (Context)" />
        <Tab value="autocomplete" label="Autocomplete" />
      </Tabs>

      <Routes>
        <Route path="patients/*">
          <Route index element={<PatientsList />} />
          <Route path=":id" element={<PatientDetails />} />
          <Route path="edit/:id" element={<PatientsList />} />
        </Route>
        <Route path="patients-context" element={<PatientsContextExample />} />
        <Route path="autocomplete" element={<DebugAutocomplate />} />
      </Routes>
    </Box>
  );
}
