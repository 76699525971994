import { useState } from 'react';
import {
  Button,
  Grid,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
  Divider,
} from '@mui/material';
import usePatientTabsStore from 'src/stores/patient-tabs';
import { PatientCard } from 'src/sections/careflow/common';
import { MAX_ALLOWED_OPENED_PETIENTS } from './constants';

type MaxWorkspaceModalProps = {
  open: boolean;
  onClose: () => void;
}

const MaxWorkspaceModal = ({
  open,
  onClose,
}: MaxWorkspaceModalProps) => {
  const { openPatientIds, closePatient, openPatient } = usePatientTabsStore();
  const [selectedPatientsIds, setSelectedPatientsIds] = useState<string[]>([]);

  const closeSelectedPatients = () => {
    selectedPatientsIds.forEach(patientIdToClose => {
      closePatient(patientIdToClose);
    });
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center', pb: 2 }} id="notification-patient-modal-title">
        {`Workspace limit reached (${MAX_ALLOWED_OPENED_PETIENTS})`}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText sx={{ textAlign: 'center' }} id="notification-patient-modal-description">
        Close one or more of the workspaces to continue
        </DialogContentText>
        <List sx={{ mt: 2 }}>
          {openPatientIds?.map((openPatientId) => (
            <Grid key={openPatientId} sx={{ mb: 2 }} container flexDirection="row">
              <Checkbox
                onChange={() => {
                  const found = selectedPatientsIds.find(id => id === openPatientId);
                  if (!found) {
                    setSelectedPatientsIds([
                      ...selectedPatientsIds,
                      openPatientId,
                    ])
                  } else {
                    setSelectedPatientsIds(selectedPatientsIds.filter(id => id !== openPatientId))
                  }
                }}
              />
              <PatientCard showCloseButton={false} id={openPatientId} />
            </Grid>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color='error' onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={closeSelectedPatients}
          variant="contained" color='primary'
          disabled={selectedPatientsIds.length === 0}
        >
          Close selected
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MaxWorkspaceModal;
