import { ValueSetComposeIncludeConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { createWrapper } from '../../fhir-react/base';

export const ValueSetWrapper = createWrapper('ValueSet', (valueSet) => ({
  asList(): ValueSetComposeIncludeConcept[] {
    const concept = valueSet?.compose?.include?.[0].concept;
    if (concept && concept.length > 0) {
      const list = valueSet.compose?.include[0].concept;
      if (list) return list;
    }
    return [];
  },

  asListForInput(
    mapper: {
      [key: string]: string;
    } = {
      display: 'label',
      code: 'value',
    }
  ) {
    const list = this.asList();
    if (!list || list.length <= 0) return [];
    return list.map((el: ValueSetComposeIncludeConcept) => {
      const mapped: {
        [key: string]: string;
      } = {};
      for (const [key, value] of Object.entries(mapper)) {
        if (typeof key === 'string') {
          mapped[value] = key;
        }
      }
      return mapped;
    });
  },
}));

export type WrappedValueSet = ReturnType<typeof ValueSetWrapper>;
