import Rounding from "../../Model/Rounding";
import RoundingItem from "./RoundingItem/RoundingItem";
import {
  TableContainer,
  Typography,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";

type RoundingListTableProps = {
  roundings: Rounding[];
  isLoading: boolean;
  onEditRounding: (rounding: Rounding) => void;
}

const ListTable = ({ roundings, isLoading = true, onEditRounding }: RoundingListTableProps) => (
    <>
      {isLoading && (
        <Box textAlign={'center'}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && roundings.length > 0 && (
        roundings.map((rounding: Rounding, index: number) => (
          <RoundingItem
            key={rounding?.id}
            rounding={rounding}
            roundingListIndex={index}
            onPresEdit={onEditRounding}
          />
        ))
      )}

      {!isLoading && roundings.length === 0 && (
        <TableContainer sx={{ padding: 2 }}>
          <Grid
            item
            justifyItems={'center'}
            alignContent="center"
            alignItems={"center"}
          >
            <Typography
              variant="h6"
              textAlign="center"
            >
              No Roundings
            </Typography>
          </Grid>
        </TableContainer>
      )}
    </>
  )

export default ListTable;
