// @ts-nocheck
import { Languages } from 'src/@types/crs/patient';
import { FHIR_CATEGORIES } from 'src/config';
import {
  Address,
  CodeableConcept,
  Coding,
  ContactPoint,
  Extension,
  HumanName,
  Identifier,
  Patient,
  PatientGender,
  Attachment,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { formatAddress } from 'src/sections/careflow/health-record/patient-info-header/components/utils';
import { getAge } from 'src/utils/formatTime';
import { createWrapper } from '../../fhir-react/base';

const IDENTIFIER_TYPE_CODE_SYSTEM_URL = 'http://terminology.hl7.org/CodeSystem/v2-0203';

export enum IdentifierCode {
  MEDICAL_RECORD_NUMBER = 'MRN',
  SOCIAL_SECURITY_NUMBER = 'SSN',
  DRIVERS_LICENSE_NUMBER = 'DL',
  PASSPORT_NUMBER = 'PPN',
  TAX_ID_NUMBER = 'TAX',
  DONOR_REGISTRATION_NUMBER = 'DR',
  SOCIAL_BENEFICIARY_IDENTIFIER = 'SB',
  MASTER_PERSON_UUID = 'MASTER_PERSON_UUID',
  MASTER_PATIENT_UUID = 'MASTER_PATIENT_UUID',
}

export const IDENTIFIER_MAP: Map<IdentifierCode, Identifier> = newIdentifierMap()
  .add(
    IdentifierCode.MEDICAL_RECORD_NUMBER,
    'Medical record number',
    process.env.REACT_APP_SYSTEM_MRN
  )
  .add(
    IdentifierCode.SOCIAL_SECURITY_NUMBER,
    'Social security number',
    'http://hl7.org/fhir/sid/us-ssn'
  )
  .add(
    IdentifierCode.DRIVERS_LICENSE_NUMBER,
    "Driver's license number",
    IDENTIFIER_TYPE_CODE_SYSTEM_URL
  )
  .add(IdentifierCode.PASSPORT_NUMBER, 'Passport number', IDENTIFIER_TYPE_CODE_SYSTEM_URL)
  .add(IdentifierCode.TAX_ID_NUMBER, 'Tax ID number', IDENTIFIER_TYPE_CODE_SYSTEM_URL)
  .add(
    IdentifierCode.DONOR_REGISTRATION_NUMBER,
    'Donor Registration Number',
    IDENTIFIER_TYPE_CODE_SYSTEM_URL
  )
  .add(
    IdentifierCode.SOCIAL_BENEFICIARY_IDENTIFIER,
    'Social Beneficiary Identifier',
    IDENTIFIER_TYPE_CODE_SYSTEM_URL
  )
  .add(
    IdentifierCode.MASTER_PERSON_UUID, 
    'Master Person UUID',
    `${process.env.REACT_APP_MPI_SEARCH_URL}/persons`
  )
  .add(
    IdentifierCode.MASTER_PATIENT_UUID, 
    'Master Patient UUID',
    `${process.env.REACT_APP_MPI_SEARCH_URL}/patients`
  )
  .getValue();

export const PatientWrapper = createWrapper('Patient', (patient) => ({
  getDefaultDateLocale(): string {
    return 'en-US';
  },

  getDefaultDateFormatOptions(): Intl.DateTimeFormatOptions {
    return {
      month: 'short',
      year: 'numeric',
      day: 'numeric',
    };
  },

  getMRN(): Identifier | null {
    return getIdentifier(patient, IdentifierCode.MEDICAL_RECORD_NUMBER);
  },

  getSSN(): Identifier | null {
    return getIdentifier(patient, IdentifierCode.SOCIAL_SECURITY_NUMBER);
  },

  getIdentifier(code: IdentifierCode) {
    return getIdentifier(patient, code);
  },

  getFullName(): string | null {
    let fullName = null;
    if (patient.name?.[0]?.given?.[0] && patient.name?.[0]?.family) {
      fullName = `${patient.name?.[0]?.given?.[0]} ${patient.name?.[0]?.family}`;
    }
    return fullName;
  },

  getAlias(): HumanName | null {
    return patient.name?.find((n) => n.use === 'nickname') || null;
  },

  getPrimaryPhone(): ContactPoint | null {
    let mainPhone = null;
    if (patient.telecom && patient.telecom?.length > 0) {
      const phones = patient.telecom.filter((t) => t?.system === 'phone');
      if (phones && phones?.length > 0) {
        mainPhone = phones[0];
      }
    }
    return mainPhone;
  },

  getPhoto(): Attachment | null {
    if (!patient.photo) return null;
    return patient.photo[0];
  },
  getPhoneNumbers() {
    if (!patient.telecom) return [];

    return patient.telecom.filter((t) => t?.system === 'phone') as (ContactPoint & {
      system: 'phone';
    })[];
  },

  getEmails() {
    if (!patient.telecom) return [];
    return patient.telecom.filter((t) => t?.system === 'email') as (ContactPoint & {
      system: 'email';
    })[];
  },

  getPrimaryEmail(): ContactPoint | null {
    let mainPhone = null;
    if (patient.telecom && patient.telecom?.length > 0) {
      const phones = patient.telecom.filter((t) => t?.system === 'email');
      if (phones && phones?.length > 0) {
        mainPhone = phones[0];
      }
    }
    return mainPhone;
  },

  getAddresses(): Address[] | null {
    if (!patient.address) return [];
    return patient.address;
  },

  getAddressesFormatted(): string[] | undefined {
    const addresses = this.getAddresses();
    return addresses?.map((a) => formatAddress(a));
  },

  getPrimaryAddress(): Address | null {
    let mainAddress = null;
    if (patient.address && patient.address?.length > 0) {
      mainAddress = patient.address[0];
    }
    return mainAddress;
  },

  getPrimaryAddressFormatted(): string | null {
    const mainAddress = this.getPrimaryAddress();

    if (!mainAddress) return null;

    return formatAddress(mainAddress);
  },

  getAgeInYears(): number | null {
    return patient.birthDate ? getAge(new Date(patient.birthDate)) : null;
  },

  getBirthDate(): Date | null {
    return patient.birthDate ? new Date(patient.birthDate) : null;
  },

  getBirthDateForDisplay(): string | null {
    const date = this?.getBirthDate()?.toLocaleDateString(
      this.getDefaultDateLocale(),
      this.getDefaultDateFormatOptions()
    );
    return date ? date : null;
  },

  getAgeGroup(): string | null {
    const age = this.getAgeInYears();
    let ageGroup = null;
    if (age) {
      if (age < 21) {
        ageGroup = 'young';
      }
      if (age >= 21) {
        ageGroup = 'adult';
      }
    }
    return ageGroup;
  },

  getStatus(): string {
    return patient.active ? 'active' : 'inactive';
  },

  getExtensionByUrl(url: string): Extension | null {
    const ext = patient.extension ? patient.extension?.filter((e) => e?.url === url)?.[0] : null;

    return ext || null;
  },

  getRace(): Coding | null {
    let race = null;
    const coding = process.env.REACT_APP_RACE_URL
      ? this.getExtensionByUrl(process.env.REACT_APP_RACE_URL)?.valueCodeableConcept?.coding
      : null;

    if (coding && Array.isArray(coding) && coding?.length > 0) {
      race = coding[0];
    }

    return race;
  },

  getRaceArray(): Coding[] | any{
    let race;
    const coding = process.env.REACT_APP_RACE_URL && patient?.extension
      ? patient.extension.filter((e) => e?.url === process.env.REACT_APP_RACE_URL).map(e => e.valueCodeableConcept?.coding?.[0])
      : null;
      
    if (coding && Array.isArray(coding) && coding?.length > 0) {
      race = coding;
    }

    return race;
  },

  getEthnicity(): Coding | null {
    let ethnicity= null;
    const coding = process.env.REACT_APP_ETHNICITY_URL
      ? this.getExtensionByUrl(process.env.REACT_APP_ETHNICITY_URL)?.valueCodeableConcept?.coding
      : null;

    if (coding && Array.isArray(coding) && coding?.length > 0) {
      ethnicity = coding[0];
    }

    return ethnicity;
  },

  getEthnicityArray(): Coding[] | any{
    let ethnicity;
    const coding = process.env.REACT_APP_ETHNICITY_URL && patient?.extension
      ? patient.extension.filter((e) => e?.url === process.env.REACT_APP_ETHNICITY_URL).map(e => e.valueCodeableConcept?.coding?.[0])
      : null;
      
    if (coding && Array.isArray(coding) && coding?.length > 0) {
      ethnicity = coding;
    }

    return ethnicity;
  },

  getGender(): PatientGender | null {
    return patient?.gender;
  },

  getMaritalStatus(): CodeableConcept | null {
    return patient?.maritalStatus || null;
  },

  getSexualOrientation(): Coding | null {
    let sexualOrientation = null;
    const coding = FHIR_CATEGORIES.sexualOrientation
      ? this.getExtensionByUrl(FHIR_CATEGORIES.sexualOrientation)?.valueCodeableConcept?.coding
      : null;

    if (coding && Array.isArray(coding) && coding?.length > 0) {
      sexualOrientation = coding[0];
    }

    return sexualOrientation;
  },
  getOrganization: (): string | undefined =>
    patient?.managingOrganization?.display || patient?.managingOrganization?.reference,

  getLanguage() : Languages[] | null {
    const language = patient?.communication?.map(e => ({
      value: e?.language?.coding?.[0].display,
      preferred: e?.preferred
    }));
    return language ? language : null;
  }
}));

function getIdentifier(patient: Patient, identifierCode: IdentifierCode): Identifier | null {
  let identifierType = IDENTIFIER_MAP.get(identifierCode);
  const system = identifierType?.system;
  const code = identifierType?.type?.coding?.[0]?.code;
  if (system || code) {
    const value = patient.identifier?.
      find((identifier) => identifier?.system === system || 
        identifier?.type?.coding?.[0].code === code) || null;
    return value;
  }
  return null;
}

function newIdentifierMap() {
  const entries: Map<IdentifierCode, Identifier> = new Map();
  const map = {
    add: (code: IdentifierCode, display: string, system?: string) => {
      entries.set(code, {
        system,
        type: {
          coding: [
            {
              system,
              code,
              display,
            },
          ],
          text: display,
        },
      });
      return map;
    },
    getValue: () => entries,
  };
  return map;
}

export type WrappedPatient = ReturnType<typeof PatientWrapper>;