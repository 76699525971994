import moment from 'moment';
import useAuth from 'src/hooks/useAuth';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';
import { getAge } from 'src/utils/formatTime';
import { PATH_DASHBOARD } from 'src/routes/paths';
import ProviderWindow from '../../common/ProviderWindow';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { Box, Card, CardProps, Collapse, IconButton, Stack, Typography } from '@mui/material';

interface Props extends CardProps {
  title?: string;
  patient: WrappedPatient;
  openCollapseExternal: boolean;
}

export default function PatientSummary({ title, patient, openCollapseExternal }: Props) {

  const user = useAuth();

  const [userId, setUserId] = useState<string>('');
  const [fhirUserId, setFhirUserId] = useState<string>('');
  const [patientMrn, setPatientMrn] = useState<string>('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(openCollapseExternal)
  }, [openCollapseExternal]);

  useEffect(() => {
    const currentUser = user.getCurrentUser();
    if (currentUser.id === undefined) return;
    setUserId(currentUser.id);
    if (currentUser.user_fhir_uri === undefined) return;
    setFhirUserId(currentUser.user_fhir_uri);
  }, [user]);
  
  useEffect(() => {
    if (patient) {
      const tempMrn = patient.getMRN()?.value
      if (tempMrn) {
        setPatientMrn(tempMrn)
      }
    }
  }, [patient])
  
  return (
    <Card sx={{ p: 4, display: 'block' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography sx={{ mt: 1 }}>{title}</Typography>
        <IconButton onClick={() => setOpen(!open)}>
          <Iconify icon={ open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'} />
        </IconButton>
      </Box>

      <Collapse in={open}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
            <Link to={PATH_DASHBOARD.crs.patient.details.forId(patient?.id!)} target="_blank">
              Patient Details
            </Link>
            <ProviderWindow 
              userId={userId} 
              fhirUserId={fhirUserId} 
              patientId={patientMrn} 
              patientMiNTIdentifier={true} 
              patientData={true} 
            />
          </Stack>
        </Stack>
        <Stack>
          <PatientItem key={patient.id} patient={patient} />
        </Stack>
      </Collapse>
    </Card>
  );
}

type PatientItemProps = {
  patient: WrappedPatient;
};

function PatientItem({ patient }: PatientItemProps) {
  return (
    <Stack spacing={2} sx={{ m: 2 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">MRN</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {patient?.getMRN()?.value}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Name</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {patient?.getFullName()}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Date of Birth</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {patient?.birthDate && moment.utc(new Date(patient?.birthDate)).format('MMM DD, YYYY')}
            {patient?.birthDate && ` (${getAge(new Date(patient?.birthDate))} years)`}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Age Group</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', textTransform: 'capitalize' }}>
          {patient?.getAgeGroup()}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Phone</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {patient?.getPrimaryPhone()?.value}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Email</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {patient?.getPrimaryEmail()?.value}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Address</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {patient?.getPrimaryAddressFormatted()}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Gender</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary', textTransform: 'capitalize' }}>
          {patient?.gender}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Race</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {patient?.getRace()?.display}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Ethnicity</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {patient?.getEthnicity()?.display}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Enrollments</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">Proxy</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }} />
      </Stack>
    </Stack>
  );
}
