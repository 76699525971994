import { Container, Grid, Typography } from '@mui/material';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../routes/paths';
import { useMatches } from 'react-router';
import { TLink } from '../../components/Breadcrumbs';
import { Wrapper } from '../../utils/layout';

interface PatientLayoutProps extends Wrapper {
  title: string;
}

export function PatientLayout({ title, children }: PatientLayoutProps) {
  const { themeStretch } = useSettings();
  const matches = useMatches();
  const links = [
    { name: 'Home', href: PATH_DASHBOARD.root },
    { name: 'CRS' },
    ...matches
      .filter((match) => match.handle)
      .map((match) => ({ name: match.handle, href: match.pathname } as TLink)),
  ];

  return (
    <Page title={title}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs title="" heading="" links={links} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          {children}
        </Grid>
      </Container>
    </Page>
  );
}
