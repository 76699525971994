import { Route, Routes } from 'react-router';
import LOBsTable from './components/LOBsTable';
import LOBIndex from './$lob';
import NewLOBForm from './new';

export default function PlanLOBsIndex() {
  return (
    <Routes>
      <Route index element={<LOBsTable />} />
      <Route path="new" element={<NewLOBForm />} />
      <Route path=":lob/*" element={<LOBIndex />} />
    </Routes>
  );
}
