import { Box, TextField } from '@mui/material';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect } from 'react';
import { WrappedCareTeam } from 'src/@nicheaim/fhir-base/wrappers/CareTeam';
import { CareTeamMemberGridRowData } from 'src/@types/crs/case';
import CustomModal, {
  CustomModalBasicProps,
  GridItem,
  GridSection,
} from 'src/components/CustomModal';
import DatePickerMoment from 'src/components/DatePickerMoment';
import InfoRibbon, { InfoTypography } from 'src/components/InfoRibbon';
import useObjectState from 'src/hooks/useObjectState';
import { updateMemberPeriod } from 'src/services/api/case';
import { checkIfDateIsAfterAnother } from 'src/utils/dates';
import { capitalize } from 'src/utils/string';

interface CareTeamMemberEditModalProps extends CustomModalBasicProps {
  careTeamMember: CareTeamMemberGridRowData | null;
  careTeam: WrappedCareTeam;
  onMemberUpdateSuccess: Function;
}

interface CareTeamMemberFormState {
  isLoading: boolean;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  startDateError: string | null;
}

const getInitialFormState = (
  careTeamMember: CareTeamMemberGridRowData | null
): CareTeamMemberFormState => ({
  isLoading: false,
  startDate: careTeamMember?.startDateObj ?? null,
  endDate: careTeamMember?.endDateObj ?? null,
  startDateError: null,
});

const CareTeamMemberEditModal = ({
  open,
  onClose,
  careTeamMember,
  careTeam,
  onMemberUpdateSuccess,
}: CareTeamMemberEditModalProps) => {
  const title = 'Edit Member';
  const [{ isLoading, startDate, endDate, startDateError }, updateState] =
    useObjectState<CareTeamMemberFormState>({ ...getInitialFormState(careTeamMember) });

  useEffect(() => {
    if (!open) return;
    updateState({ ...getInitialFormState(careTeamMember) });
  }, [open, careTeamMember]);

  const { enqueueSnackbar } = useSnackbar();

  const handleOnSave = async () => {
    if (checkIfDateIsAfterAnother(startDate, endDate)) {
      updateState({ startDateError: " Start Date can't be after End Date" });
      return;
    }
    updateState({ startDateError: null });
    const response = await updateMemberPeriod(
      {
        id: careTeamMember?.id,
        resourceType: careTeamMember?.memberType,
        startDate: startDate?.isValid?.() ? startDate?.toISOString() : null,
        endDate: endDate?.isValid?.() ? endDate?.toISOString() : null,
      },
      careTeam.id as string
    );
    if (!response) {
      enqueueSnackbar("There's been an error. Please Try Again", { variant: 'error' });
      return;
    }
    enqueueSnackbar('Care Team Member succesfully updated');
    return true;
  };

  return (
    <CustomModal
      open={open}
      title={title}
      breadcrumbs={['List of Care Teams & Members', 'Care Team Members', title]}
      onSave={async () => {
        updateState({ isLoading: true });
        const wasSaved = await handleOnSave();
        updateState({ isLoading: false });
        if (!wasSaved) return;
        onMemberUpdateSuccess();
        onClose?.({}, 'backdropClick');
      }}
      onClose={onClose}
      onCancel={onClose as Function}
      isLoading={isLoading}
      containerSx={[{ width: '70vw' }]}
      childrenWithoutPadding={
        <Box mt={3}>
          <InfoRibbon containerSx={{ marginTop: 2 }}>
            <InfoTypography xs={3}>Care Team: {careTeam?.name ?? ''}</InfoTypography>
            <InfoTypography xs={3}>Category: {careTeam?.plainCategory ?? ''}</InfoTypography>
            <InfoTypography xs={3.3}>
              Managing Organization: {careTeam?.managingOrganizationName ?? ''}
            </InfoTypography>
            <InfoTypography xs={2.2}>
              Status: {capitalize(careTeam?.severityStatus?.message ?? '')}
            </InfoTypography>
          </InfoRibbon>
        </Box>
      }
    >
      <>
        <GridSection>
          <GridItem xs={12}>
            <TextField
              fullWidth
              label={'Identifier'}
              defaultValue={careTeamMember?.identifier}
              type="text"
              placeholder="Identifier"
              variant="outlined"
              disabled
            />
          </GridItem>
        </GridSection>
        <GridSection>
          <GridItem xs={12}>
            <TextField
              fullWidth
              label={'Name'}
              defaultValue={careTeamMember?.name}
              type="text"
              placeholder="Name"
              variant="outlined"
              disabled
            />
          </GridItem>
        </GridSection>
        <GridSection>
          <GridItem xs={12}>
            <TextField
              fullWidth
              label={'Member Type'}
              defaultValue={careTeamMember?.memberType}
              type="text"
              placeholder="Member Type"
              variant="outlined"
              disabled
            />
          </GridItem>
        </GridSection>
        <GridSection>
          <GridItem xs={12}>
            <TextField
              fullWidth
              label={'Associated Organization'}
              defaultValue={careTeamMember?.associatedOrg}
              type="text"
              placeholder="Associated Organization"
              variant="outlined"
              disabled
            />
          </GridItem>
        </GridSection>
        <GridSection>
          <GridItem xs={12}>
            <TextField
              fullWidth
              label={'Role'}
              defaultValue={careTeamMember?.role}
              type="text"
              placeholder="Role"
              variant="outlined"
              disabled
            />
          </GridItem>
        </GridSection>
        <GridSection>
          <GridItem xs={5.9}>
            <DatePickerMoment
              value={startDate}
              error={startDateError}
              disabled={isLoading}
              label={'Start Date'}
              onChange={(value) => {
                updateState({
                  startDate: value,
                });
              }}
            />
          </GridItem>
          <GridItem xs={5.9}>
            <DatePickerMoment
              value={endDate}
              disabled={isLoading}
              label={'End Date'}
              onChange={(value) => {
                updateState({
                  endDate: value,
                });
              }}
            />
          </GridItem>
        </GridSection>
      </>
    </CustomModal>
  );
};

export default CareTeamMemberEditModal;
