import { Alert, Box, Divider, Stack, Grid, Avatar, FormControl, TextField, Typography } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import { FormProvider, RHFAutocomplete } from 'src/components/hook-form';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOrganizations, usePractitionerRoles } from 'src/@nicheaim/fhir-react';
import { PractitionerRole } from 'src/@nicheaim/fhir-base/mappings/PractitionerRole';
import { fhirClient } from 'src/App';

export type EditUserOrganizationModalPropsType = {
  onSaveCallback: (role: PractitionerRole) => void;
  onCancelPress: () => void;
  user: any;
};

type FormValue = {
  organizations: { label: string; value: string }[];
};

const getSchema = () => yup.object().shape({
    organizations: yup.array().nullable().required("Organization can't be empty"),
  });

const EditUserOrganizationModal = ({
  onSaveCallback,
  onCancelPress,
  user,
}: EditUserOrganizationModalPropsType) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [practitionerRoles, { create }] = usePractitionerRoles({
    filter: {
      practitioner: user?.fhirUri,
      active: true,
    }
  });

  const [requesterOrganizations, { isLoading: requesterLoading }] = useOrganizations({
    filter: {
      type: 'referral-requester-organizations,external-service-provider,external-and-requester-organizations',
    },
    pagination: {
      pageSize: 1000,
    },
  });

  const organizationsOptions = useMemo(() => requesterOrganizations.map((org) => ({
      label: org.name,
      value: org.id,
    })) ?? [], [requesterOrganizations])

  const defaultValues = useMemo(
    () => ({
      organizations: practitionerRoles?.map((pr) => ({
        label: pr.organization?.display,
        value: pr.organization?.reference?.split('/')[1],
      })) ?? [],
    }),
    [practitionerRoles]
  );

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(getSchema()),
  });

  const { handleSubmit } = methods;

  useEffect(() => {
    if (practitionerRoles?.length > 0) {
      methods.setValue('organizations', practitionerRoles?.map((pr) => ({
        label: pr.organization?.display ?? 'Untitled',
        value: pr.organization?.reference?.split('/')[1],
      })) ?? [])
    }
  }, [methods, practitionerRoles])

  const onSaveUserOrganization = async (data: FormValue) => {
    setIsLoading(true);
    
    await Promise.all(practitionerRoles?.map((pr) => fhirClient.updateOne(`PractitionerRole`, {
      ...pr,
      active: false,
    })));

    if (data && data?.organizations && data?.organizations?.length > 0) {
      await Promise.all(data.organizations?.map((org) => {
        const newPractitionerRolePayload: PractitionerRole = {
          resourceType: "PractitionerRole",
          practitioner: {
            reference: user.fhirUri,
            display: `${user.firstName} ${user.middleName} ${user.lastName}`,
          },
          organization: {
            reference: `Organization/${org.value}`,
            display: org.label,
          },
          active: true,
        }

        return create(newPractitionerRolePayload);
      }))
      .then(() => {
        setIsLoading(false);
        setError(null);
        setSuccess('Saved successfully.');
  
        setTimeout(() => {
          onCancelPress();
        }, 800)
      })
      .catch(() => {
        setError('Something went wrong, please try again.');
        setSuccess(null);
        setIsLoading(false);
      })
    }
  };

  return (
    <FormProvider methods={methods} debug>
      <Box>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{ p: '16px 8px', backgroundColor: '#f7f7f7', borderRadius: 1 }}
        >
          <Avatar alt={user.firstName} src={user.photo} sx={{ mr: 2 }} />
          <Stack>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              {`${user.firstName} ${user.middleName} ${user.lastName}`}
            </Typography>
          </Stack>
        </Stack>

        <Box sx={{ my: 3 }}>
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Edit User Organization
            </Typography>
          </Grid>
        </Box>
        <Divider />
        <Grid item style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <RHFAutocomplete
              name="organizations"
              disablePortal
              multiple
              options={organizationsOptions}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Organizations"
                  margin="dense"
                  size="small"
                  variant="standard"
                  required
                />
              )}
            />
          </FormControl>
        </Grid>
        {error && (
          <Alert style={{ marginTop: '20px' }} severity="error">
            {error}
          </Alert>
        )}
        {success && (
          <Alert style={{ marginTop: '20px' }} severity="success">
            {success}
          </Alert>
        )}
        <ActionButtons
          isLoading={requesterLoading || isLoading}
          onLeftButtonPress={onCancelPress}
          onRightButtonPress={handleSubmit(async (data) => {
            await onSaveUserOrganization(data as FormValue)
          })}
        />
      </Box>
    </FormProvider>
  );
};

export default EditUserOrganizationModal;
