import { 
  Box, 
  Card, 
  Chip, 
  Grid, 
  IconButton, 
  Stack, 
  Typography 
} from '@mui/material';
import { useState } from 'react';
import { format } from 'date-fns';
import { paramCase } from 'change-case';
import useAuth from 'src/hooks/useAuth';
import Iconify from 'src/components/Iconify';
import { InsuranceInfo } from '../../referral';
import { useCoverages } from 'src/@nicheaim/fhir-react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { CoverageWrapper, WrappedCoverage } from 'src/@nicheaim/fhir-base/wrappers/Coverage';
import { AddInsurance, checkAclValidation, EditInsurance, getRelatedAcls } from 'src/utils/permissions/permission.utils';

type Props = {
  patient: WrappedPatient | null;
};

export default function PatientInsurance({ patient }: Props) {

  const user = useAuth();
  const [openInsuranceInfo, setOpenInsuranceInfo] = useState(false);
  const [ coverage, { refresh: refreshCoverage } ] = useCoverages({filter: { patient: patient?.id}, 
  map: CoverageWrapper});
  const [row, setRow] = useState(null);

  const handleEditInsuranceInfo = (row: any) => {
    setRow(row);
    setOpenInsuranceInfo(true);
  };

  const handleCloseInsuranceInfo = () => {
    setRow(null);
    setOpenInsuranceInfo(false);
  };

  return (
    <Card sx={{ p: 2, boxShadow: 'none'  }}>
      <Typography variant='button' sx={{ textTransform: 'uppercase' }}>Insurance</Typography>
      <Box
        sx={{
          display: 'grid',
          gap: 3,
          pt: 2,
          gridTemplateColumns: 'repeat(3, 1fr)',
        }}
      >
        {coverage.map((e:any, index:number) => (
          <Card key={index}>
            <Box sx={{ 
              display: 'flex', 
              p: 1, 
              mt: 2, 
              flexDirection: 'row', 
              alignItems: 'center',
              backgroundColor:'#1890FF' }
            }>
              <Chip
                size='small'
                sx={{
                  backgroundColor: 'white',
                  color: '#1890FF',
                  borderRadius: 2,
                  minWidth: 26,
                  p: 0
                }}
                label={index + 1}
              />
              <Typography 
                variant='h6' 
                sx={{ margin: 'auto', color: 'white'}}>
                  {e?.class?.[0].name}
              </Typography>
              {checkAclValidation({ user, acl: getRelatedAcls(EditInsurance) }) && (
                <IconButton onClick={() => handleEditInsuranceInfo(e)}>
                  <Iconify icon='eva:edit-outline' color='white'/>
                </IconButton>
              )}
            </Box>
            <Grid container>
              <CoveraItem coverage={e}/>
            </Grid>
          </Card>
        ))}
        {checkAclValidation({ user, acl: getRelatedAcls(AddInsurance) }) && (
          <Card>
            <Box sx={{ display: 'flex', flexDirection: 'column', p: 4 }}>
              <Box sx={{ margin:'auto'}}>
                <IconButton onClick={() => setOpenInsuranceInfo(true)}>
                  <Iconify  icon='ic:baseline-plus'/>
                </IconButton>
              </Box>
              <Typography variant='h6' sx={{ margin: 'auto', color: 'text.secondary'}}>
                  Add New Insurance
              </Typography>
            </Box>
          </Card>
        )}
      </Box>

      <InsuranceInfo
        patient={patient}
        coverage={row}
        open={openInsuranceInfo}
        onCancel={handleCloseInsuranceInfo}
        refreshCoverage={refreshCoverage}
      />
    </Card>
  );
};

type ItemProps = {
  coverage: WrappedCoverage | null;
};

const CoveraItem = ({ coverage }: ItemProps) => {

  let commercial: number = 0;

  if(paramCase(coverage?.class?.[0].name || '') === 'commercial-medicaid'){
    commercial = 2;
  } else {
    commercial = 1;
  }

  return(
    <>
      {paramCase(coverage?.class?.[0].name || '') === 'state-medicaid' && (
        <Grid item xs={12}>
          <Stack sx={{ p: 1 }}>
            <Typography variant="subtitle2" sx={{ color: '#1890FF'}}>
              Medicaid Number
            </Typography>
            <Typography variant="body2">
              {coverage?.subscriberId} 
            </Typography>

            <Typography variant="subtitle2" sx={{ color: '#1890FF', pt: 1}}>
              Begin Date
            </Typography>
            <Typography variant="body2">
              {coverage?.period?.start && `${format(new Date(coverage.period.start), 'MMM, dd yyyy')}`}
            </Typography>
          </Stack>
        </Grid>
      )}

      {paramCase(coverage?.class?.[0].name || '').includes('commercial')  && (
        <>
          <Grid item xs={6}>
            <Stack sx={{ p: 1 }}>
              <Typography variant="subtitle2" sx={{ color: '#1890FF'}}>
                Insurance Company
              </Typography>
              <Typography variant="body2">
                {coverage?.payor?.[0].display?.split('_')[commercial]} 
              </Typography>

              <Typography variant="subtitle2" sx={{ color: '#1890FF', pt: 1}}>
                Policy Number 
              </Typography>
              <Typography variant="body2">
                {coverage?.subscriberId}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack sx={{ p: 1 }}>
              <Typography variant="subtitle2" sx={{ color: '#1890FF'}}>
                Expiration Date
              </Typography>
              <Typography variant="body2">
                {coverage?.period?.end && `${format(new Date(coverage.period.end), 'MMM, dd yyyy')}`} 
              </Typography>

              <Typography variant="subtitle2" sx={{ color: '#1890FF', pt: 1}}>
                Group Number
              </Typography>
              <Typography variant="body2">
                {coverage?.class?.[0].value}
              </Typography>
            </Stack>
          </Grid>
        </>
      )}

      {paramCase(coverage?.class?.[0].name || '').includes('medicare-part') && (
        <Grid item xs={12}>
          <Stack sx={{ p: 1 }}>
            <Typography variant="subtitle2" sx={{ color: '#1890FF'}}>
              Medicare Number
            </Typography>
            <Typography variant="body2">
              {coverage?.subscriberId} 
            </Typography>

            <Typography variant="subtitle2" sx={{ color: '#1890FF', pt: 1}}>
              Start Date
            </Typography>
            <Typography variant="body2">
              {coverage?.period?.start && `${format(new Date(coverage.period.start), 'MMM, dd yyyy')}`}
            </Typography>
          </Stack>
        </Grid>
      )}
    </>
  )
};