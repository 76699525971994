import {
  Box,
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Iconify from 'src/components/Iconify';
import Searchbar from 'src/layouts/dashboard/header/Searchbar';
import { ActionBar } from '../common';
import { useStandardDialog } from '../common/StandardDialogProvider';

interface IContextMenuProps {
  // row: IPlanProgramRow;
  handleEdit: () => void;
}

function ContextMenu({ handleEdit }: IContextMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  return (
    <TableCell align="right">
      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
        <MenuItem onClick={() => {}}>
          <Stack direction="row" spacing={2}>
            <Iconify icon="eva:trash-2-outline" />
            <Typography variant="body2">Remove</Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <IconButton onClick={openMenu}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
    </TableCell>
  );
}

export default function FamilyGroupCard() {
  const standardDialog = useStandardDialog();

  function getSearchBar() {
    return (
      <div>
        <Searchbar />
      </div>
    );
  }

  function handleAdd() {
    standardDialog.open(getSearchBar());
  }

  return (
    <Card>
      <Grid container>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 2 }}
          xs={12}
        >
          <Grid item>
            <Typography variant="h6">Family</Typography>
          </Grid>
          <Grid item>
            <ActionBar onAdd={handleAdd} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: '400px', overflow: 'auto' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/*
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">Katherine McBride</Typography>
                    </TableCell>

                    <ContextMenu handleEdit={() => {}} />
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">Vincent Brooks</Typography>
                    </TableCell>

                    <ContextMenu handleEdit={() => {}} />
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
