import _ from 'lodash';
import 'moment-timezone';
import moment from 'moment';
import { Stack, Typography } from '@mui/material';
import { getAddressFormated } from 'src/utils/fhir';

const ComplementaryInfoReadMode = ({ data }: any) => {
	const { scope = {} } = data;
  const { checklistItem = {} } = scope;

	const addressFormated = getAddressFormated(
    checklistItem?.VALIDATE_HOME_ADDRESS_ITEM?.modifiedFields);

	const checklistItemModified = { ...(checklistItem?.VALIDATE_HOME_ADDRESS_ITEM && {
		VALIDATE_HOME_ADDRESS_ITEM: {
			...(checklistItem?.VALIDATE_HOME_ADDRESS_ITEM?.validatedOn && 
					{ validatedOn: 
						moment.utc(new Date(checklistItem?.VALIDATE_HOME_ADDRESS_ITEM?.validatedOn)).
							format('YYYY-MM-DD')}),
				modifiedFields:{
					...(checklistItem?.VALIDATE_HOME_ADDRESS_ITEM?.modifiedFields && 
						{ ...addressFormated })
				}
			}
		}),
	}

	const formattedData = _.merge(checklistItem, checklistItemModified);

  const isNotAllowed = ['extension', 'id', 'type', 'typeNote', 'address'];

  const checkIfIsObject = (obj: any) =>
    typeof obj === 'object' && obj !== null && !Array.isArray(obj);

  const isDate = (value: string) => {
    const tmpArray = ['start', 'end', 'validatedOn', 'nextContactOn', 'contactOn'];

    return tmpArray.includes(value);
  };

  const getComplementaryInfoName = (key: any) => {
    let complementaryKeyName = '';

    switch (key) {
      case 'VALIDATE_ADDRESS_IN_COUNTY_ITEM':
        complementaryKeyName = 'Address is in County';
        break;
      case 'VALIDATE_HOME_ADDRESS_ITEM':
        complementaryKeyName = 'Fix Invalid Address';
        break;
      case 'validatedOn':
        complementaryKeyName = 'Validated On';
        break;
      case 'validatedBy':
        complementaryKeyName = 'Validated By';
        break;
      case 'Notes':
        complementaryKeyName = 'Notes';
        break;
      case 'city':
        complementaryKeyName = 'City';
        break;
      case 'district':
        complementaryKeyName = 'County';
        break;
      case 'use':
        complementaryKeyName = 'Use';
        break;
      case 'line_0':
        complementaryKeyName = 'Street 1';
        break;
      case 'line_1':
          complementaryKeyName = 'Street 2';
          break;
      case 'state':
        complementaryKeyName = 'State';
        break;
      case 'country':
        complementaryKeyName = 'Country';
        break;
      case 'postalCode':
        complementaryKeyName = 'Postal Code';
        break;
      case 'isInCounty':
        complementaryKeyName = 'Is In County';
        break;
      case 'start':
        complementaryKeyName = 'Start';
        break;
      case 'end':
        complementaryKeyName = 'End';
        break;
      case 'CONTACT_CLIENT_ITEM':
        complementaryKeyName = 'Contact Client';
        break;
      case 'CONTACT_PCP_ITEM':
        complementaryKeyName = 'Contact PCP';
        break;
      case 'CASE_CONTACT_ATTEMPT_ITEM':
        complementaryKeyName = 'Case Contact Attempt';
        break;
      case 'contactOn':
        complementaryKeyName = 'Contact On';
        break;
      case 'nextContactOn':
        complementaryKeyName = 'Next Contact On';
        break;
      case 'reasonNote':
        complementaryKeyName = 'Reason Note';
        break;
      case 'outcome':
        complementaryKeyName = 'Outcome';
        break;
      default:
        complementaryKeyName = 'Not Found Key';
    }

    return complementaryKeyName;
  };

  const ObjectComponent = ({ elem }: any) => {
    if(isNotAllowed.includes(elem?.[0])) {
      return <></>;
    }

    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">{getComplementaryInfoName(elem?.[0])}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {isDate(elem?.[0])
            ? moment.utc(new Date(elem?.[1]).toISOString()).format('MM/DD/YYYY')
            : elem?.[1]}
        </Typography>
      </Stack>
    );
  };

  return formattedData && Object.keys(formattedData).length > 0 ? (
   <>
	 	{Object.entries(formattedData).map((item: any, index: number) => {
			return (
				<div key={'div-0-' + index} className="complementary-item-container">
					<Typography sx={{ fontSize: '1rem', fontWeight: '400' }}>
						{getComplementaryInfoName(item?.[0])}
					</Typography>

					<Stack>
						<Stack spacing={2} sx={{ m: 2 }}>
							{item?.[1] &&
								Object.keys(item?.[1]).length > 0 &&
								Object.entries(item?.[1]).sort().map((elem: any) => {
									return elem?.[0] !== 'modifiedFields' ? (
										<Stack direction="row" alignItems="center" justifyContent="space-between">
											<Typography variant="body2">{getComplementaryInfoName(elem?.[0])}</Typography>
											<Typography variant="body2" sx={{ color: 'text.secondary' }}>
												{isDate(elem?.[0])
													? moment.utc(new Date(elem?.[1]).toISOString()).format('MM/DD/YYYY')
													: elem?.[1]}
											</Typography>
										</Stack>
									) : (
										elem?.[1] &&
											Object.keys(elem?.[1]).length > 0 &&
											Object.entries(elem?.[1]).map((i: any) => {
												if (checkIfIsObject(i?.[1])) {
													return Object.entries(i?.[1]).map((el: any) => {
														if (checkIfIsObject(el?.[1])) {
															return Object.entries(el?.[1]).map((objItem: any, index: number) => (
																<ObjectComponent key={'objectC-' + index} elem={objItem} />
															));
														} else if (Array.isArray(el?.[1])) {
															return el?.[1].map((arItem: any) => {
																return arItem && <ObjectComponent elem={[el?.[0], arItem]} />;
															});
														} else {
															return <ObjectComponent elem={el} />;
														}
													});
												} else {
													if(!isNotAllowed.includes(i?.[0])) {
														return (
															<Stack
																direction="row"
																alignItems="center"
																justifyContent="space-between"
															>
																<Typography variant="body2">
																	{getComplementaryInfoName(i?.[0])}
																</Typography>
																<Typography variant="body2" sx={{ color: 'text.secondary' }}>
																	{i?.[0] === 'isInCounty'
																		? JSON.stringify(i?.[1]) === 'true'
																			? 'Yes'
																			: 'No'
																		: i?.[1]}
																</Typography>
															</Stack>
														);
													}
												}
											})
									);
								})}
						</Stack>
					</Stack>
				</div>
			);
		})}
	 </>
  ) : (
    <Typography sx={{ fontSize: '0.875rem', marginTop: '-16px', marginBottom: '-20px !important' }}>
      No data
    </Typography>
  );
};

export default ComplementaryInfoReadMode;