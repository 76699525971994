import { useQuery, useMutation } from '@tanstack/react-query';
import * as actions from './actions';

export function useRoundings(params: actions.getRoundingPropsType) {
    return useQuery(['get-roundings'], () => actions.getRoundings(params));
}

export function useRoundingTypes() {
    return useQuery(['get-rounding-types'], () => actions.getRoundingTypes());
}

export function useRoundingPatientActivities() {
    return useQuery(['get-rounding-patient-activities'], () => actions.getRoundingPatientActivities());
}

export function useRoundingPatientLocations() {
    return useQuery(['get-rounding-patient-locations'], () => actions.getRoundingPatientActivityLocations());
}
  

export function useBedsToRounding(roundingId: number) {
    return useQuery(['get-beds-to-rounding'], () => actions.getBedsToRounding(roundingId), {
        cacheTime: 0,
        staleTime: 0,
    });
}

export function useSaveRoundingMutation() {
    return useMutation(actions.saveRounding);
}
  