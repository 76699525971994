import ccmAcls from './ccmAcls';

const ccmAclsGroups = {
  // search bar acls groups
  simpleSearch: [
    ccmAcls.CCM_ADMIN,
    ccmAcls.CCM_SYSTEM_ADMIN,
    ccmAcls.SEARCH.name,
    ccmAcls.SEARCH.SIMPLE_SEARCH,
  ],
  advancedSearch: [
    ccmAcls.CCM_ADMIN,
    ccmAcls.CCM_SYSTEM_ADMIN,
    ccmAcls.SEARCH.name,
    ccmAcls.SEARCH.ADVANCED_SEARCH,
  ],
  // care plan acls groups
  writeCarePlan: [
    ccmAcls.CCM_ADMIN,
    ccmAcls.CAREPLAN.name,
    ccmAcls.CCM_SYSTEM_ADMIN,
    ccmAcls.CAREPLAN.write,
  ],
  readCarePlan: [
    ccmAcls.CCM_ADMIN,
    ccmAcls.CCM_SYSTEM_ADMIN,
    ccmAcls.CAREPLAN.name,
    ccmAcls.CAREPLAN.read,
  ],
  // activities
  readActivities: [
    ccmAcls.TASK_ACTIVITIES.name,
    ccmAcls.TASK_ACTIVITIES.ACTIVITIES.name,
    ccmAcls.TASK_ACTIVITIES.ACTIVITIES.name + '.*',
    ccmAcls.TASK_ACTIVITIES.ACTIVITIES.READ,
  ],
  // referrals
  readReferrals: [
    ccmAcls.TASK_ACTIVITIES.name,
    ccmAcls.TASK_ACTIVITIES.REFERRALS.name,
    ccmAcls.TASK_ACTIVITIES.REFERRALS.name + '.*',
    ccmAcls.TASK_ACTIVITIES.REFERRALS.READ,
  ],
  // patients
  readPatients: [
    ccmAcls.TASK_ACTIVITIES.name,
    ccmAcls.TASK_ACTIVITIES.PATIENTS.name,
    ccmAcls.TASK_ACTIVITIES.PATIENTS.name + '.*',
    ccmAcls.TASK_ACTIVITIES.PATIENTS.READ,
  ],
  // alerts
  readAlerts: [
    ccmAcls.TASK_ACTIVITIES.name,
    ccmAcls.TASK_ACTIVITIES.ALERTS.name,
    ccmAcls.TASK_ACTIVITIES.ALERTS.name + '.*',
    ccmAcls.TASK_ACTIVITIES.ALERTS.READ,
  ],
};

export default ccmAclsGroups;
