import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useMemo } from 'react';
import produce from 'immer';
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCodeSystem, useObservation, useObservations } from 'src/@nicheaim/fhir-react';
import {
  Observation,
  ObservationComponent,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import fhirSystem, { fhirCodeSystemGenerator } from 'src/fhir/system';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import RHFDatePicker from 'src/components/hook-form/RHFDatePicker';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import { PatientCard } from 'src/sections/careflow/common';

export enum vitalTypes {
  vitals = 'vitals',
  heightAndWeight = 'heightAndWeight',
  orthostatic = 'orthostatics',
}

const vitals = [
  {
    code: {
      coding: [
        {
          code: '85354-9',
          display: 'Blood pressure panel with all children optional',
          system: 'http://loinc.org',
        },
      ],
    },
    valueQuantity: {
      code: 'mm[Hg]',
      system: 'http://unisofmeasure.org',
      unit: 'mmHg',
    },
  },
  {
    code: {
      coding: [
        {
          code: '39156-5',
          display: 'Body mass index (BMI) [Ratio]',
          system: 'http://loinc.org',
        },
      ],
    },
    valueQuantity: {
      code: 'kg/m2',
      system: 'http://unitsofmeasure.org',
      unit: 'kg/m2',
    },
  },
  {
    code: {
      coding: [
        {
          code: '8302-2',
          display: 'Body height',
          system: 'http://loinc.org',
        },
      ],
    },
    valueQuantity: {
      code: '[in_i]',
      system: 'http://unitsofmeasure.org',
      unit: 'in',
    },
  },
  {
    code: {
      coding: [
        {
          code: '29463-7',
          display: 'Body Weight',
          system: 'http://loinc.org',
        },
      ],
    },
    valueQuantity: {
      code: '[lb_av]',
      system: 'http://unitsofmeasure.org',
      unit: 'lbs',
    },
  },
  {
    code: {
      coding: [
        {
          code: '2708-6',
          display: 'Oxygen saturation in Arterial blood',
          system: 'http://loinc.org',
        },
      ],
    },
    valueQuantity: {
      code: '%',
      system: 'http://unitsofmeasure.org',
      unit: '%',
    },
  },
  {
    code: {
      coding: [
        {
          code: '9279-1',
          display: 'Respiratory rate',
          system: 'http://loinc.org',
        },
      ],
      text: 'Respiratory rate',
    },
    valueQuantity: {
      code: '/min',
      system: 'http://unitsofmeasure.org',
      unit: 'breaths/minute',
    },
  },
  {
    code: {
      coding: [
        {
          code: '8867-4',
          display: 'Heart rate',
          system: 'http://loinc.org',
        },
      ],
      text: 'Heart rate',
    },
    valueQuantity: {
      code: '/min',
      system: 'http://unitsofmeasure.org',
      unit: 'beats/minute',
    },
  },
  {
    code: {
      coding: [
        {
          code: '8310-5',
          display: 'Body temperature',
          system: 'http://loinc.org',
        },
      ],
      text: 'Body temperature',
    },
    valueQuantity: {
      code: 'Fah',
      system: 'http://unitsofmeasure.org',
      unit: 'F',
    },
  },
  {
    code: {
      coding: [
        {
          system: 'http://loinc.org',
          code: '8480-6',
          display: 'Systolic blood pressure',
        },
      ],
    },
    valueQuantity: {
      unit: 'mmHg',
      system: 'http://unitsofmeasure.org',
      code: 'mm[Hg]',
    },
  },
  {
    code: {
      coding: [
        {
          system: 'http://loinc.org',
          code: '8462-4',
          display: 'Diastolic blood pressure',
        },
      ],
    },
    valueQuantity: {
      unit: 'mmHg',
      system: 'http://unitsofmeasure.org',
      code: 'mm[Hg]',
    },
  },
  {
    code: {
      coding: [
        {
          system: 'http://loinc.org',
          code: '8459-0',
          display: 'Systolic blood pressure--sitting',
        },
      ],
    },
    valueQuantity: {
      unit: 'mmHg',
      system: 'http://unitsofmeasure.org',
      code: 'mm[Hg]',
    },
  },
  {
    code: {
      coding: [
        {
          system: 'http://loinc.org',
          code: '8453-3',
          display: 'Diastolic blood pressure--sitting',
        },
      ],
    },
    valueQuantity: {
      unit: 'mmHg',
      system: 'http://unitsofmeasure.org',
      code: 'mm[Hg]',
    },
  },
  {
    code: {
      coding: [
        {
          system: 'http://loinc.org',
          code: '8460-8',
          display: 'Systolic blood pressure--standing',
        },
      ],
    },
    valueQuantity: {
      unit: 'mmHg',
      system: 'http://unitsofmeasure.org',
      code: 'mm[Hg]',
    },
  },
  {
    code: {
      coding: [
        {
          system: 'http://loinc.org',
          code: '8454-1',
          display: 'Diastolic blood pressure--standing',
        },
      ],
    },
    valueQuantity: {
      unit: 'mmHg',
      system: 'http://unitsofmeasure.org',
      code: 'mm[Hg]',
    },
  },
  {
    code: {
      coding: [
        {
          system: 'http://loinc.org',
          code: '8461-6',
          display: 'Systolic blood pressure--supine',
        },
      ],
    },
    valueQuantity: {
      unit: 'mmHg',
      system: 'http://unitsofmeasure.org',
      code: 'mm[Hg]',
    },
  },
  {
    code: {
      coding: [
        {
          system: 'http://loinc.org',
          code: '8455-8',
          display: 'Diastolic blood pressure--supine',
        },
      ],
    },
    valueQuantity: {
      unit: 'mmHg',
      system: 'http://unitsofmeasure.org',
      code: 'mm[Hg]',
    },
  },
];

const Schema = Yup.object().shape({
  typeOfVitals: Yup.string()
    .oneOf(
      [
        vitalTypes.vitals.toString(),
        vitalTypes.heightAndWeight.toString(),
        vitalTypes.orthostatic.toString(),
      ],
      'Invalid type of vitals selected'
    )
    .required(),
  date: Yup.date().required('Date is required').typeError('Invalid date'),
});

interface VitalsSummaryFormProps {
  patient: any;
  data?: Observation;
  onClose: () => void;
  onSuccessCallback: () => void;
}

const VitalsSummaryForm = ({ patient, data, onClose, onSuccessCallback }: VitalsSummaryFormProps) => {
  const id = data?.id;
  const isEditting = Boolean(id);
  const [isLoading, setIsLoading] = useState(false);
  const [, { create }] = useObservations({ autofetch: false });
  const [vital, { update }] = useObservation(id);
  const [readingTypes] = useCodeSystem('ccm-glucose-check-types');
  const [interventionTypes] = useCodeSystem('ccm-glucose-intervention-types');

  const findComponentByCode = (component: ObservationComponent[], code: string) =>
    component?.find((c) => c.code?.coding?.some((c) => c.code === code));

  const getVitalsValue = (observation: Observation | undefined, code: string) => {
    if (!observation?.component) return;
    const vitals = findComponentByCode(observation.component, code);
    return vitals?.valueQuantity?.value?.toString();
  };

  const getValueBySystem = (observation: Observation | undefined, system: string) => {
    const component = observation?.component?.find((comp: any) => comp.code.coding?.find(i => i.system === system));
    return component;
  }

  const closeForm = onClose;
  const methods = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      typeOfVitals:
        data?.code.coding?.find((c) => c.system === fhirSystem.healthRecord.vitalsType.asString())
          ?.code || vitalTypes.vitals.toString(),
      date: data?.effectiveDateTime ? new Date(data?.effectiveDateTime) : new Date(),
      temperature: getVitalsValue(data, '8310-5') ?? '',
      pulse: getVitalsValue(data, '8867-4') ?? '',
      respirations: getVitalsValue(data, '9279-1') ?? '',
      O2sat: getVitalsValue(data, '2708-6') ?? '',
      height: getVitalsValue(data, '8302-2') ?? '',
      weight: getVitalsValue(data, '29463-7') ?? '',
      systolicSitting: getVitalsValue(data, '8459-0') ?? '',
      diastolicSitting: getVitalsValue(data, '8453-3') ?? '',
      systolicStanding: getVitalsValue(data, '8460-8') ?? '',
      diastolicStanding: getVitalsValue(data, '8454-1') ?? '',
      systolicLying: getVitalsValue(data, '8461-6') ?? '',
      diastolicLying: getVitalsValue(data, '8455-8') ?? '',
      bloodPressureSystolic: getVitalsValue(data, '8480-6') ?? '',
      bloodPressureDiastolic: getVitalsValue(data, '8462-4') ?? '',
      reading: getValueBySystem(data, fhirCodeSystemGenerator.glucose_check_types.toString())?.valueString ?? '',
      readingType: getValueBySystem(data, fhirCodeSystemGenerator.glucose_check_types.toString())?.code.coding?.[0]?.code ?? '',
      intervention: getValueBySystem(data, fhirCodeSystemGenerator.glucose_intervention_types.toString())?.code.coding?.[0]?.code ?? '',
    },
  });

  const typeOfVitals = methods.watch('typeOfVitals');

  // useUpdateLogger(methods.watch());
  console.log({ errors: methods.formState.errors });

  const BMI = useMemo(() => {
    const height = methods.getValues('height');
    const weight = methods.getValues('weight');
    const h = parseFloat(height);
    const w = parseFloat(weight);
    if (!h || !w) return '';
    return ((w / (h * h)) * 703).toFixed(2);
  }, [methods.watch('height'), methods.watch('weight')]);

  const clearAll = () => {
    methods.setValue('date', new Date());
    methods.setValue('temperature', '');
    methods.setValue('pulse', '');
    methods.setValue('respirations', '');
    methods.setValue('O2sat', '');
    methods.setValue('height', '');
    methods.setValue('weight', '');
    methods.setValue('systolicSitting', '');
    methods.setValue('diastolicSitting', '');
    methods.setValue('systolicStanding', '');
    methods.setValue('diastolicStanding', '');
    methods.setValue('systolicLying', '');
    methods.setValue('diastolicLying', '');
    methods.setValue('bloodPressureSystolic', '');
    methods.setValue('bloodPressureDiastolic', '');
    methods.setValue('reading', '');
    methods.setValue('readingType', '');
  };


  const buildVital = (code: string, value: string): ObservationComponent | null => {
    const vital = vitals.find((v: any) => v.code.coding[0].code === code);
    if (!vital) return null;

    return {
      code: {
        coding: [
          {
            code: vital.code.coding[0].code,
            display: vital.code.coding[0].display,
            system: vital.code.coding[0].system,
          }
        ],
      },
      valueQuantity: {
        ...vital?.valueQuantity,
        value: parseFloat(value),
      },
    };
  };

  const buildComponent = () =>
    [
      methods.getValues('bloodPressureSystolic')
        ? buildVital('8480-6', methods.getValues('bloodPressureSystolic'))
        : null,
      methods.getValues('bloodPressureDiastolic')
        ? buildVital('8462-4', methods.getValues('bloodPressureDiastolic'))
        : null,
      methods.getValues('temperature')
        ? buildVital('8310-5', methods.getValues('temperature'))
        : null,
      methods.getValues('pulse') ? buildVital('8867-4', methods.getValues('pulse')) : null,
      methods.getValues('respirations')
        ? buildVital('9279-1', methods.getValues('respirations'))
        : null,
      methods.getValues('O2sat') ? buildVital('2708-6', methods.getValues('O2sat')) : null,
      methods.getValues('height') ? buildVital('8302-2', methods.getValues('height')) : null,
      methods.getValues('weight') ? buildVital('29463-7', methods.getValues('weight')) : null,
      BMI ? buildVital('39156-5', BMI) : null,
      methods.getValues('systolicSitting')
        ? buildVital('8459-0', methods.getValues('systolicSitting'))
        : null,
      methods.getValues('diastolicSitting')
        ? buildVital('8453-3', methods.getValues('diastolicSitting'))
        : null,
      methods.getValues('systolicStanding')
        ? buildVital('8460-8', methods.getValues('systolicStanding'))
        : null,
      methods.getValues('diastolicStanding')
        ? buildVital('8454-1', methods.getValues('diastolicStanding'))
        : null,
      methods.getValues('systolicLying')
        ? buildVital('8461-6', methods.getValues('systolicLying'))
        : null,
      methods.getValues('diastolicLying')
        ? buildVital('8455-8', methods.getValues('diastolicLying'))
        : null,
    ].filter((v): v is ObservationComponent => Boolean(v));
  
  const getGlucoseComponents = () => [
    ...methods.getValues('readingType') ? [{
      code: {
        coding: [
          {
            code: methods.getValues('readingType'),
            display: readingTypes?.concept?.find(i => i.code === methods.getValues('readingType'))?.display,
            system: fhirCodeSystemGenerator.glucose_check_types.toString(),
          }
        ],
      },
      valueString: methods.getValues('reading'),
    }] : [],
    ...methods.getValues('intervention') ? [{
      code: {
        coding: [
          {
            code: methods.getValues('intervention'),
            display: interventionTypes?.concept?.find(i => i.code === methods.getValues('intervention'))?.display,
            system: fhirCodeSystemGenerator.glucose_intervention_types.toString(),
          }
        ],
      },
      valueString: interventionTypes?.concept?.find(i => i.code === methods.getValues('intervention'))?.display,
    }] : [],
  ]

  const createVitals = async () => {
    const component = buildComponent();
    await create({
      resourceType: 'Observation',
      effectiveDateTime: methods.getValues('date').toISOString(),
      category: [
        {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/observation-category',
              code: 'vital-signs',
              display: 'Vital Signs',
            },
          ],
          text: 'Vital Signs',
        },
      ],
      status: 'final',
      code: {
        coding: [
          {
            code: '29274-8',
            display: 'Vital signs measurements',
            system: 'http://loinc.org',
          },
          {
            code: typeOfVitals,
            system: fhirSystem.healthRecord.vitalsType.asString(),
          },
        ],
      },
      subject: { reference: `Patient/${patient?.id}` },
      component: [
        ...component,
        ...typeOfVitals === vitalTypes.vitals ? getGlucoseComponents() : [],
      ],
    });
    return true;
  };

  const updateVitals = async () => {
    const component = buildComponent();
    const p = produce(vital!, (draft) => {
      draft.code ??= {};
      draft.code.coding ??= [];
      draft.code.coding[1] = {
        code: typeOfVitals,
        system: fhirSystem.healthRecord.vitalsType.asString(),
      };
      draft.effectiveDateTime = methods.getValues('date').toISOString();
      draft.component = [
        ...component,
        ...typeOfVitals === vitalTypes.vitals ? getGlucoseComponents() : [],
      ];
    });
    await update(p);
    return true;
  };

  const handleSave = async () => {
    setIsLoading(true)
    const successful = isEditting ? await updateVitals() : await createVitals();
    if (!successful) {
      return;
    }
    setIsLoading(false)
    clearAll();
    closeForm();
    onSuccessCallback();
  };

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(handleSave)} debug>
      <Box
        sx={{
          my: 3,
          position: 'fixed',
          width: '400px',
          backgroundColor: 'white',
          opacity: 1,
          zIndex: 9,
          top: '-25px',
        }}
      >
        <Box sx={{ my: 3 }}>
          <Grid item>
            <PatientCard id={patient?.id || ''} showCloseButton={false} />
          </Grid>
        </Box>
        <Box sx={{ my: 3 }}>
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {isEditting ? 'Update' : 'Add'} Vitals
            </Typography>
          </Grid>
        </Box>
        <Divider />
      </Box>
      <Stack sx={{ pt: 23 }}>
        <Stack spacing={2}>
          <Stack>
            <RHFDatePicker
              name="date"
              label="Date"
              clearable
              TextFieldProps={{
                variant: 'standard',
              }}
            />
          </Stack>
          <RHFSelect
            name="typeOfVitals"
            fullWidth
            label="Type of vitals"
            margin="dense"
            size="small"
            variant="standard"
          >
            <MenuItem value={vitalTypes.vitals}>Vitals</MenuItem>
            <MenuItem value={vitalTypes.heightAndWeight}>Height & Weight</MenuItem>
            <MenuItem value={vitalTypes.orthostatic}>Orthostatic</MenuItem>
          </RHFSelect>
          {typeOfVitals === vitalTypes.vitals && (
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Blood Pressure</FormLabel>
                <Stack direction="row" spacing={2} alignItems="baseline">
                  <RHFTextField
                    name="bloodPressureSystolic"
                    fullWidth
                    label="Systolic"
                    margin="dense"
                    size="small"
                    variant="standard"
                  />
                  <Typography variant="body2">/</Typography>
                  <RHFTextField
                    name="bloodPressureDiastolic"
                    fullWidth
                    label="Diastolic"
                    margin="dense"
                    size="small"
                    variant="standard"
                  />
                </Stack>
              </FormControl>
              <RHFTextField
                name="temperature"
                fullWidth
                label="Temperature"
                margin="dense"
                size="small"
                variant="standard"
              />
              <RHFTextField
                name="pulse"
                fullWidth
                label="Pulse"
                margin="dense"
                size="small"
                variant="standard"
              />
              <RHFTextField
                name="respirations"
                fullWidth
                label="Respirations"
                margin="dense"
                size="small"
                variant="standard"
              />
              <RHFTextField
                name="O2sat"
                fullWidth
                label="O2 Sat."
                margin="dense"
                size="small"
                variant="standard"
              />
              <FormControl>
                <FormLabel>Blood Glucose</FormLabel>
                <RHFSelect
                  name="intervention"
                  fullWidth
                  label="Intervention"
                  margin="dense"
                  size="small"
                  variant="standard"
                >
                  {interventionTypes?.concept?.map((code, index) => (
                    <MenuItem key={code.code + index} value={code.code}>{code.display}</MenuItem>
                  ))}
                </RHFSelect>
                <Stack direction="row" spacing={2} alignItems="baseline">
                  <RHFTextField
                    name="reading"
                    fullWidth
                    label="Reading"
                    margin="dense"
                    size="small"
                    variant="standard"
                  />
                  <Typography variant="body2">/</Typography>
                  <RHFSelect
                    name="readingType"
                    fullWidth
                    label="Type of check"
                    margin="dense"
                    size="small"
                    variant="standard"
                  >
                    {readingTypes?.concept?.map((code, index) => (
                      <MenuItem key={code.code + index} value={code.code}>{code.display}</MenuItem>
                    ))}
                  </RHFSelect>
                </Stack>
              </FormControl>
            </Stack>
          )}
          {typeOfVitals === vitalTypes.heightAndWeight && (
            <Stack spacing={2}>
              <RHFTextField
                name="height"
                fullWidth
                label="Height"
                margin="dense"
                size="small"
                variant="standard"
              />
              <RHFTextField
                name="weight"
                fullWidth
                label="Weight"
                margin="dense"
                size="small"
                variant="standard"
              />
              <TextField
                fullWidth
                label="BMI"
                margin="dense"
                size="small"
                variant="standard"
                value={BMI}
                disabled
              />
            </Stack>
          )}
          {typeOfVitals === vitalTypes.orthostatic && (
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>Lying Blood Pressure</FormLabel>
                <Stack direction="row" spacing={2} alignItems="baseline">
                  <RHFTextField
                    name="systolicLying"
                    label="Systolic"
                    margin="dense"
                    size="small"
                    variant="standard"
                  />
                  <Typography variant="body2">/</Typography>
                  <RHFTextField
                    name="diastolicLying"
                    fullWidth
                    label="Diastolic"
                    margin="dense"
                    size="small"
                    variant="standard"
                  />
                </Stack>
              </FormControl>
              <FormControl>
                <FormLabel>Sitting Blood Pressure</FormLabel>
                <Stack direction="row" spacing={2} alignItems="baseline">
                  <RHFTextField
                    name="systolicSitting"
                    fullWidth
                    label="Systolic"
                    margin="dense"
                    size="small"
                    variant="standard"
                  />
                  <Typography variant="body2">/</Typography>
                  <RHFTextField
                    name="diastolicSitting"
                    fullWidth
                    label="Diastolic"
                    margin="dense"
                    size="small"
                    variant="standard"
                  />
                </Stack>
              </FormControl>
              <FormControl>
                <FormLabel>Standing Blood Pressure</FormLabel>
                <Stack direction="row" spacing={2} alignItems="baseline">
                  <RHFTextField
                    name="systolicStanding"
                    fullWidth
                    label="Systolic"
                    margin="dense"
                    size="small"
                    variant="standard"
                  />
                  <Typography variant="body2">/</Typography>
                  <RHFTextField
                    name="diastolicStanding"
                    fullWidth
                    label="Diastolic"
                    margin="dense"
                    size="small"
                    variant="standard"
                  />
                </Stack>
              </FormControl>
              <RHFTextField
                name="temperature"
                fullWidth
                label="Temperature"
                margin="dense"
                size="small"
                variant="standard"
              />
              <RHFTextField
                name="respirations"
                fullWidth
                label="Respirations"
                margin="dense"
                size="small"
                variant="standard"
              />
              <RHFTextField
                name="O2sat"
                fullWidth
                label="O2 Sat."
                margin="dense"
                size="small"
                variant="standard"
              />
            </Stack>
          )}
        </Stack>
        <ActionButtons
          isLoading={isLoading}
          onLeftButtonPress={clearAll}
          rightButtonType="submit"
        />
      </Stack>
    </FormProvider>
  );
};

export default VitalsSummaryForm;
