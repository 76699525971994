import { CommonFields } from 'src/sections/careflow/permissions/types';
import client from './_client';

export interface GridView extends Partial<CommonFields> {
  id: number;
  code: string;
  name: string;
  description: string;
  scope: string;
  data: object;
  isDefault: boolean;
  module: string;
  isPredefined: boolean;
  dataConverted?: { filter?: any; sort?: any };
}

export enum GridViewModules {
  REFERRALLIST = 'ReferralList',
}

export enum GridViewConverter {
  PaginateQuery = 'PaginateQuery',
}

const baseUrl = '/gridviews';

export const getGridViewsByModule = async (
  module: GridViewModules,
  converter?: GridViewConverter
): Promise<GridView[] | null> => {
  try {
    const { data } = await client.get(
      `${baseUrl}/${module}${converter ? `?converter=${converter}` : ''}`
    );

    return data;
  } catch (error) {
    return null;
  }
};
