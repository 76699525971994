import { Box, IconButton } from '@mui/material';
import { CircularProgress, Typography, Stack, Card } from '@mui/material';
import produce from 'immer';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { usePractitioner } from 'src/@nicheaim/fhir-react';
import { useQuestionnaire, useQuestionnaireResponses, useTask } from 'src/@nicheaim/fhir-react';
import Iconify from 'src/components/Iconify';
import fhirSystem from 'src/fhir/system';
import useAuth from 'src/hooks/useAuth';
import api from 'src/services/api';
import { useUpdateSessionParticipantAssessmentToken } from 'src/services/api/group-sessions';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import { getFhirIdFromEntity } from 'src/utils/fhir';

interface NewAssessmentByIdProps {
  onZoom?: () => void;
}

export default function NewAssessmentById({ onZoom }: NewAssessmentByIdProps) {
  const { questionnaire: questionnaireId = '' } = useParams();
  const { getCurrentUser } = useAuth();
  const { user_fhir_uri, name: currentUserName } = getCurrentUser();
  const practitionerId = getFhirIdFromEntity(user_fhir_uri);

  const navigate = useNavigate();
  const patient = useCurrentPatient();
  const { state } = useLocation();
  const [taskId] = useState(state?.taskId);
  const [sessionParticipantAssessmentId] = useState(state?.sessionParticipantAssessmentId);
  const { mutateAsync: updateAssessmentToken } = useUpdateSessionParticipantAssessmentToken();
  const [task, { isLoading: isLoadingTask, update: updateTask, refresh: refreshTask }] = useTask(
    taskId,
    {
      autofetch: !!taskId,
    }
  );
  const [questionnaire, { isLoading: isLoadingQuestionnaire }] = useQuestionnaire(questionnaireId);
  const [, { create: createQuestionnaireResponse }] = useQuestionnaireResponses();
  const [userPractitioner, { isLoading: isLoadingPractitioner }] = usePractitioner(practitionerId, {
    map: PractitionerWrapper,
  });

  useEffect(() => {
    refreshTask();
  });

  const isLoading = isLoadingQuestionnaire || isLoadingTask || isLoadingPractitioner;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!questionnaire) {
      throw new Error('Questionnaire not found');
    }

    if (!patient) {
      throw new Error('No patient selected');
    }

    if (!userPractitioner) {
      throw new Error('Practitioner not found for the current user');
    }

    setTimeout(() => {
      (async () => {
        if (task) {
          console.log('task found, checking for existing response');
          const existingResponse = task.identifier?.find(
            (i) => i.system === fhirSystem.activity.completedWithAssessment.asString()
          );
          if (existingResponse) {
            console.log('existing response found, navigating to fill');
            navigate(
              `/dashboard/patient/${patient.id}/care-record/assessments/${existingResponse.value}`,
              {
                replace: true,
              }
            );
            return;
          }
        }

        console.log('no existing response found, creating new response');
        const surveyId = questionnaire.identifier?.find(
          (i) => i.system === fhirSystem.assessments.assessment.asString()
        )?.value;
        if (!surveyId) {
          throw new Error(
            `Could not get Survey ID from assessment. Questionnaire ID: ${questionnaireId}`
          );
        }

        const { token } = await api.assessments.startAssessment({
          assessmentId: surveyId,
          firstName: patient.name?.[0].given?.[0] || '',
          lastName: patient.name?.[0].family || '',
          email: patient.getPrimaryEmail()?.value || '',
        });

        const [questionnaireResponse] = await createQuestionnaireResponse({
          resourceType: 'QuestionnaireResponse',
          status: 'in-progress',
          authored: new Date().toISOString(),
          author: {
            id: userPractitioner?.id,
            type: 'Practitioner',
            reference: `Practitioner/${userPractitioner?.id}`,
            display: userPractitioner?.getFullName() || currentUserName,
          },
          identifier: {
            system: fhirSystem.assessments.response.withId(surveyId).asString(),
            value: token,
          },
          questionnaire: `Questionnaire/${questionnaireId}`,
          subject: {
            reference: `Patient/${patient.id}`,
          },
        });

        if (sessionParticipantAssessmentId) {
          await updateAssessmentToken({
            sessionParticipantId: sessionParticipantAssessmentId,
            token,
          });
        }

        if (task) {
          console.log('task found, updating task');
          await updateTask(
            produce(task, (draft) => {
              draft.identifier =
                draft.identifier?.filter(
                  (i) => i.system !== fhirSystem.activity.completedWithAssessment.asString()
                ) || [];

              draft.identifier.push({
                system: fhirSystem.activity.completedWithAssessment.asString(),
                value: questionnaireResponse.id,
              });
            })
          );
        }

        navigate(
          `/dashboard/patient/${patient.id}/care-record/assessments/${questionnaireResponse.id}/fill`,
          {
            replace: true,
          }
        );
      })();
    }, 1000);
  }, [
    isLoading,
    getCurrentUser,
    questionnaire,
    navigate,
    patient,
    questionnaireId,
    createQuestionnaireResponse,
    userPractitioner,
    taskId,
    sessionParticipantAssessmentId,
    updateAssessmentToken
  ]);

  return (
    <Card sx={{ height: `calc(100vh - 200px)`, display: 'flex', flexDirection: 'column' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
        <Typography variant="subtitle1">New assessment</Typography>

        <Box>
          <IconButton onClick={() => onZoom?.()}>
            <Iconify icon="eva:expand-fill" />
          </IconButton>
          <IconButton
            onClick={() => navigate(`/dashboard/patient/${patient?.id}/care-record/assessments`)}
          >
            <Iconify icon="material-symbols:close-rounded" />
          </IconButton>
        </Box>
      </Stack>

      <Stack direction="column" alignItems="center" justifyContent="center" gap={2} sx={{ p: 5 }}>
        <Typography>Preparing assessment response</Typography>
        <CircularProgress />
      </Stack>
    </Card>
  );
}
