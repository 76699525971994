import { List } from 'src/@nicheaim/fhir-base/mappings/List';
import { useLists, useQuestionnaires } from 'src/@nicheaim/fhir-react';
import Label, { LabelColor } from 'src/components/Label';
import DataTable, { DataTableColumn } from 'src/components/table/DataTable';
import fhirSystem from 'src/fhir/system';
import AutomationSheetsContextMenu from './AutomationSheetsContextMenu';
import { useAllAssessments } from 'src/fhir/hooks/assessments';

const columns: DataTableColumn[] = [
  {
    header: 'Assessment',
    field: 'assessment.title',
  },
  {
    header: 'Automation Sheet Name',
    field: 'sheet.title',
  },
  {
    header: 'Description',
    field: 'sheet.note.0.text',
  },
  {
    header: 'Status',
    field: 'sheet.status',
    render(value: List['status']) {
      const mappings: Record<List['status'], { title: string; color: LabelColor }> = {
        current: {
          title: 'Active',
          color: 'success',
        },
        retired: {
          title: 'Inactive',
          color: 'info',
        },
        'entered-in-error': {
          title: 'Error',
          color: 'error',
        },
      };
      const mapping = mappings[value];

      return <Label color={mapping.color}>{mapping.title}</Label>;
    },
  },
];

export default function CarePlanAutomationTable() {
  const [automationSheets, { refresh }] = useLists({
    filter: {
      identifier: fhirSystem.automation.carePlan.forCode(''),
      'status:not': 'entered-in-error',
    },
  });
  const [assessments] = useAllAssessments({
    pagination: {
      pageSize: 1000,
    },
  });

  const sheets = automationSheets
    .map((sheet) => {
      const assessmentId = sheet.identifier
        ?.find(
          (identifier) =>
            identifier.system?.startsWith(fhirSystem.automation.carePlan.asString()) &&
            identifier.use === 'official'
        )
        ?.system?.split('/')
        .pop();
      const assessment = assessments.find((assessment) => assessment.id === assessmentId);

      return {
        sheet,
        assessment,
      };
    })
    .sort((a, b) => ((a.assessment?.title || '') > (b.assessment?.title || '') ? 1 : -1));

  return (
    <div>
      <DataTable
        columns={columns}
        data={sheets}
        selectable
        rowContextMenu={(item) => (
          <AutomationSheetsContextMenu item={item} onAction={() => refresh()} />
        )}
      />
    </div>
  );
}
