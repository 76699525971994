import { Route, Routes } from 'react-router';
import PatientsTable from './components/PatientsTable';
import AddPatientToProgramForm from './new';

export default function LOBProgramsIndex() {
  return (
    <Routes>
      <Route index element={<PatientsTable />} />
      <Route path="new" element={<AddPatientToProgramForm />} />
    </Routes>
  );
}
