import useObjectState from "./useObjectState";

type useAddEntityStatesStateType<T> = {
  selectedEntity: T | null;
  showAddEntityModal: boolean;
}

type useAddEntityStatesActionsType<T> = {
  openAddEntityModal: () => void;
  closeAddEntityModal: () => void;
  setSelectedEntity: (entity: T) => void;
}

const useAddEntityStates = <T>(): [
  useAddEntityStatesStateType<T>,
  useAddEntityStatesActionsType<T>,
] => {
  const [state, updateState] = useObjectState<useAddEntityStatesStateType<T>>({
    selectedEntity: null,
    showAddEntityModal: false,
  });

  const openAddEntityModal = () => updateState({
    showAddEntityModal: true,
  })

  const closeAddEntityModal = () => updateState({
    showAddEntityModal: false,
  })

  const setSelectedEntity = (entity: T) => updateState({
      selectedEntity: entity
    })

  return [
    state,
    {
      openAddEntityModal,
      closeAddEntityModal,
      setSelectedEntity
    }
  ]
};

export default useAddEntityStates;
