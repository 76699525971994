// @ts-nocheck
import ListTable from "./components/ListTable/ListTable";
import { ROUNDING_VIEW_TYPES, useController } from './controller';
import {
  AlertTitle,
  Container,
  Grid,
  Modal,
  Alert,
} from "@mui/material";
import { Filters } from "./components/Filters/Filters";
import { Header } from "./components/Header/Header";
import Page from 'src/components/Page';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { RoundingProvider } from "./roundingContext";
import SearchResultList from "./components/SearchResult/SearchResultList";
import AddRounding from "./components/AddRounding/AddRounding";
import AddModal from "../common/AddModal";
;

export const Rounding = () => {
  const [{
    pagination,
    openAddRoundingModal,
    categoryId,
    locationFhirId,
    isLoading,
    selectedRounding,
    roundings,
    intervals,
    intervalDetailsTotalCount,
    patientActivities,
    patientLocations,
    intervalsStatus,
    dateRange,
    viewType,
    loadingIntervalDetails,
  }, {
    updateState,
    onSaveRounding,
    changeCategory,
    changeLocation,
    onChangeintervalsStatus = () => {},
    completedIntervalDetail = () => {},
    onStartDateChange = () => {},
    onEndDateChange = () => {},
    onSearchButtonPress = () => {},
    onClearSearchPress = () => {},
    onSelectInterval = () => {},
    getIntervalDetails = () => {},
    getEntities,
  }] = useController();

  const onCloseAddRoundingModal = () => updateState?.({ openAddRoundingModal: false, selectedRounding: null })

  const addRounding = () => updateState?.({ openAddRoundingModal: true });

  console.log({
    loadingIntervalDetails
  })

  return (
    <RoundingProvider
      value={{
        getEntities,
        patientActivities,
        patientLocations,
        completedIntervalDetail,
        getIntervalDetails,
        intervalDetailsTotalCount: intervalDetailsTotalCount ?? 0,
        loadingIntervalDetails: loadingIntervalDetails ?? false,
      }}
    >
      <AddModal
        Component={
          <AddRounding
            onCloseModal={onCloseAddRoundingModal}
            onSaveCallback={(savedRounding) => onSaveRounding?.(savedRounding)}
            rounding={selectedRounding!}
          />
        }
        open={openAddRoundingModal!}
        onClose={onCloseAddRoundingModal}
        containerStyle={{
          width: 500
        }}
      />
      <Page title="Rounding">
        <Container maxWidth={'xl'}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs
                title="Rounding"
                links={[
                  {
                    href: '',
                    name: 'Dashboard',
                  },
                  {
                    href: '',
                    name: 'Rounding',
                  }
                ]}
              />
            </Grid>
            <Header
              onChangeLocation={changeLocation}
              onChangeCategory={changeCategory}
              onAddRoundingPress={addRounding}
            />
            <Filters
              status={intervalsStatus}
              onChangeintervalsStatus={onChangeintervalsStatus!}
              dateRange={dateRange}
              onChangeDatesCallbacks={{
                onStartDateChange,
                onEndDateChange,
              }}
              onSearchButtonPress={onSearchButtonPress}
              onClearSearchPress={onClearSearchPress}
              viewType={viewType ?? ROUNDING_VIEW_TYPES.ROUNDING_LIST_VIEW}
              isRoundingSelected={selectedRounding ? true : false}
              disabled={!categoryId || !locationFhirId}
            />
            
            <Grid item xl={12}>
              {categoryId && locationFhirId ? (
                <>
                  {(viewType === ROUNDING_VIEW_TYPES.ROUNDING_LIST_VIEW && !openAddRoundingModal) && (
                    <ListTable
                      roundings={selectedRounding ? [selectedRounding] : roundings ?? []}
                      isLoading={isLoading ?? true}
                      onEditRounding={(currentRounding) => {
                        updateState?.({ openAddRoundingModal: true, selectedRounding: currentRounding })
                      }}
                    />
                  )}

                  {(viewType === ROUNDING_VIEW_TYPES.ROUNDING_LIST_VIEW && openAddRoundingModal) && (
                    <ListTable
                      roundings={roundings ?? []}
                      isLoading={isLoading ?? true}
                      onEditRounding={(currentRounding) => {
                        updateState?.({ openAddRoundingModal: true, selectedRounding: currentRounding })
                      }}
                    />
                  )}
                </>
              ) : (
                <Alert severity="info">
                  Please select category and location filter to see the roundings
                </Alert>
              )}
              
              {viewType === ROUNDING_VIEW_TYPES.SEARCH_RESULT_VIEW && (
                <SearchResultList
                  intervals={intervals ?? []}
                  onSelectInterval={onSelectInterval}
                  isLoading={isLoading ?? true}
                  pagination={pagination!}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Page>
    </RoundingProvider>
  )
}