import { Box, CircularProgress } from '@mui/material';
import { useTasks } from 'src/@nicheaim/fhir-react';
import TasksByPathway from '../../case/components/TasksByPathway/TasksByPathway';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import useHealthCareServicesByServiceRequests from 'src/hooks/useHealthCareServicesByServiceRequests';
import { TaskWrapper } from 'src/@nicheaim/fhir-base/wrappers/Task';

export interface PatientTasksProps {
  patient: WrappedPatient;
}

const PatientTasks = ({ patient }: PatientTasksProps) => {
  const [tasks, { isLoading: isTasksLoading, refresh: refreshTaskList }] = useTasks({
    filter: {
      subject: patient?.id,
    },
    map: TaskWrapper,
  });

  const { healthCareServices, isLoading: isHealthCareServicesLoading } =
    useHealthCareServicesByServiceRequests(null, patient?.id);

  const isLoading = isTasksLoading || isHealthCareServicesLoading || !patient?.id;

  return (
    <>
      {!isLoading ? (
        <Box mt={4}>
          <TasksByPathway
            tasks={tasks}
            patient={patient as WrappedPatient}
            healthCareServices={healthCareServices}
            refreshEntities={refreshTaskList}
            refreshTasks={refreshTaskList}
            isCollapsible={false}
          />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 5 }}>
          <CircularProgress size={60} />
        </Box>
      )}
    </>
  );
};

export default PatientTasks;
