import { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import CollapsibleCard from 'src/sections/crs/common/CollapsibleCard';

import { referralService } from 'src/crs/referral/services';
import { PaginateQuery } from 'src/api/pagination/dtos';
import { ReferralDto } from 'src/crs/dto/referral.dto';
import { ReferralTabPanel, NoResultText, LoadingComponent } from './components';

export interface RelatedReferralsProps {
  isOpen: boolean;
  referralIds: string[];
  externalLoading: boolean;
  patientId: string;
}

const RelatedReferrals = ({
  isOpen,
  referralIds,
  externalLoading,
  patientId,
}: RelatedReferralsProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [referrals, setReferrals] = useState<ReferralDto[]>([]);

  useEffect(() => {
    const getReferralRecords = async () => {
      if (!referralIds.length) {
        setIsLoading(false);
        return;
      }
      setIsLoading(true);
      const paginateQuery: PaginateQuery = {
        limit: 999,
        filter: {
          fhirId: referralIds,
        },
      };

      const referrals = await referralService.getAll(paginateQuery);
      setIsLoading(false);
      if (referrals?.data?.length) setReferrals(referrals.data);
    };
    getReferralRecords();
  }, [referralIds]);

  return (
    <CollapsibleCard title="Related Referrals" isOpenExternal={isOpen}>
      <Box>
        {!isLoading && !externalLoading ? (
          referrals.length ? (
            <>
              <Tabs
                value={selectedTab}
                variant={'scrollable'}
                onChange={(_, newSelectedTab) => {
                  setSelectedTab(newSelectedTab);
                }}
                TabIndicatorProps={{
                  sx: {
                    bottom: 5,
                  },
                }}
              >
                {referrals.map((_, index) => (
                  <Tab
                    sx={{ textAlign: 'left' }}
                    label={`Referral ${index + 1}`}
                    key={`referralTab${String(index)}`}
                  />
                ))}
              </Tabs>

              {referrals.map((referral, index) => (
                <ReferralTabPanel
                  referral={referral}
                  index={index}
                  key={`referralTabPanel${String(index)}`}
                  selectedTab={selectedTab}
                  patientId={patientId}
                />
              ))}
            </>
          ) : (
            <NoResultText text="No referrals related found" />
          )
        ) : (
          <LoadingComponent />
        )}
      </Box>
    </CollapsibleCard>
  );
};

export default RelatedReferrals;
