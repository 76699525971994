import axiosFhirInstance from "./axiosFhirInstance";
import { PatientRepository } from "../../../ports/out/repositories";
import {
  AxiosFHIRRepository,
  TokenSource
} from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir";
import {
  Patient
} from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";

export class FHIRPatientRepository extends AxiosFHIRRepository<Patient> implements PatientRepository {
  constructor(tokenSource?: TokenSource) {
    super('Patient', axiosFhirInstance, tokenSource);
  }
}