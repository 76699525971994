import { PaginationType } from "src/sections/careflow/rounding/controller";
import Bed from "../../../rounding/Model/Bed";
import BedItem from "../BedItem/BedItem";
import {
  TableContainer,
  Typography,
  Grid,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Card,
  TableBody,
  CircularProgress,
  Box,
  TablePagination,
} from "@mui/material";

export type BedListTableProps = {
  beds: Bed[];
  isLoading: boolean;
  pagination: PaginationType;
  changePage: (page: number) => void;
}

const columns = [
  {
    label: 'Identifier'
  },
  {
    label: 'Patient'
  },
  {
    label: 'Location'
  },
  {
    label: 'Is active'
  },
  {
    label: 'Start period'
  },
  {
    label: 'End period'
  },
  {
    label: 'Last Updated by'
  },
  {
    label: 'Last Updated'
  },
  {
    label: 'Actions'
  }
];

const ListTable = ({ beds, isLoading, pagination, changePage }: BedListTableProps) => {
  const handleChangePage = (_: any, newPage: number) => {
    changePage(newPage);
  };
  return (
    <>
      <Card sx={{ marginBottom: 2, marginTop: 3 }}>
        <Grid sx={{ padding: 3, paddingBottom: 1 }}>
          <Typography variant="h5">Beds</Typography>
        </Grid>
        <TableContainer sx={{ padding: 2 }}>
          <Table>
            {columns && (
              <TableHead>
                <TableRow>
                  {columns.map((col: any, index: number) => (
                    <TableCell style={{ width: col.width }} key={index}>
                      <Typography>
                        {col.label}
                      </Typography>
                    </TableCell>
                  ))}
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {beds?.map((bed: Bed) => (
                <BedItem
                  key={bed?.id}
                  bed={bed}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading && (
          <Box textAlign={'center'} padding={2}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading && beds.length === 0 && (
          <TableContainer sx={{ padding: 2 }}>
            <Grid
              item
              justifyItems={'center'}
              alignContent="center"
              alignItems={"center"}
            >
              <Typography
                variant="h6"
                textAlign="center"
              >
                No beds
              </Typography>
            </Grid>
          </TableContainer>
        )}
        <TablePagination
          component="div"
          count={pagination?.total ?? 0}
          page={pagination?.page ?? 0}
          onPageChange={handleChangePage}
          rowsPerPage={pagination?.take ?? 0}
          rowsPerPageOptions={[]}
          labelDisplayedRows={() =>
            `Showing ${beds?.length ?? 0} Beds | Page ${
              pagination && pagination?.total && pagination?.total > 0 ? pagination?.page + 1 : 0
            } of ${pagination && pagination?.total && Math.ceil(pagination?.total / pagination?.take)}`
          }
          showFirstButton
          showLastButton
        />
      </Card>
    </>
  )
}

export default ListTable;
