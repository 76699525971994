import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { Registry, RegistryFilter, getRegistries } from 'src/services/api/registry';

interface UseRegistryProps {
  queryKey?: string;
  filter?: RegistryFilter;
}

const useRegistry = (props?: UseRegistryProps): UseQueryResult<Registry[]> => {
  const { queryKey, filter } = props ?? {};
  const queryData = useQuery({
    queryKey: [queryKey ?? 'registries', { filter: filter ?? undefined }],
    queryFn: async () => {
      const registries = await getRegistries(filter?.groupIdentifier, filter?.keyRegistry);
      return registries.data;
    },
  });

  return queryData;
};

export default useRegistry;
