import { 
  Box,
  Button,
  Card,
  Dialog, 
  DialogActions, 
  DialogTitle, 
  Grid,
  IconButton, 
  Stack, 
  TextField, 
  Typography, 
} from '@mui/material';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { MobileDatePicker } from '@mui/lab';
import Iconify from 'src/components/Iconify';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '../../../../../components/hook-form';

type FormValue = {
  enrrollments: string;
  proxy: string;
  registrationDate: Date;
};

type EditModeProps = {
  open:boolean;
  onClose: VoidFunction;
};

const EditMode = ({ open, onClose }: EditModeProps ) => {

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(() =>({
    enrrollments: '',
    proxy: '',
    registrationDate: new Date(),
    } as FormValue),
    ['']
  );

  const EventSchema = Yup.object().shape({
    enrrollments: Yup.string().required('Enrrollments is required'),
    proxy: Yup.string().required('Proxy is required'),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    handleSubmit,
  } = methods;

  const handleClose = () =>{
    reset(defaultValues);
    onClose();
  };

  const onSubmit = async (data: FormValue) => {

    try{
      console.log('data',data);
      onClose();
      enqueueSnackbar('Other Details was updated.');

    }catch(e){

      reset(defaultValues);
      onClose();
      enqueueSnackbar('Other Details was not updated.', { variant:'error' });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>Other Details</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2}  sx={{ p: 2 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Enrollments
                </Typography>
                <RHFTextField name="enrrollments" label="" />  

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Proxy
                </Typography>
                <RHFTextField name="proxy" label="" />

                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Registration Date
                </Typography>
                <Controller
                  name="registrationDate"
                  control={control}
                  render={({ field }) => (
                    <MobileDatePicker
                      {...field}
                      label=""
                      inputFormat="dd/MM/yyyy"
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />

              <Button variant="contained" color="info" onClick={handleClose}>
                Cancel
              </Button>

              <Button variant="contained" color="info" type="submit">
                Submit
              </Button>
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
};

const DisplayMode = () => (
  <Grid container spacing={3} sx={{ p: 2 }}>
    <Grid item xs={4}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          Enrollments
        </Typography>
        <Typography variant="body2">N/A</Typography>
      </Stack>
    </Grid>

    <Grid item xs={4}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold'  }}>
          Proxy
        </Typography>
        <Typography variant="body2">N/A</Typography>
      </Stack>
    </Grid>
    <Grid item xs={4}>
      <Stack direction="column" alignItems="left" justifyContent="space-between">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold'  }}>
          Registration Date
        </Typography>
        <Typography variant="body2">Dec 20, 2021</Typography>
      </Stack>
    </Grid>
  </Grid>
);

export default function () {

  const [editOther, setEditOther] = useState(false);

  const handleCloseEditOther = () => {
    setEditOther(false);
  }

  return (
    <>
      <Typography variant="button" sx={{ textTransform: 'uppercase' }}>
        Other Details
        <IconButton onClick={() => setEditOther(true)}>
          <Iconify icon={editOther ? 'eva:save-outline' : 'eva:edit-outline'} />
        </IconButton>
      </Typography>
      <EditMode open={editOther} onClose={handleCloseEditOther}/> 
      <DisplayMode />
    </>
  );
}
