// @ts-nocheck
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useAllergyIntolerances, useCommunications } from 'src/@nicheaim/fhir-react';
import useAuth from 'src/hooks/useAuth';
import {
  AllergyIntolerance,
  Communication,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import PatientCard from 'src/sections/careflow/common/PatientCard';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import { getFhirIdFromReferenceString } from '../../tasks-activities/components/Activities/activities-utils';

export type AddAllergyPropsType = {
  onSaveCallback: (allergyIntolerance: AllergyIntolerance) => void;
  onCancelPress: () => void;
  allergyIntolerance?:
    | {
        id: string;
        communicationId: string;
        note: string;
        category: string;
        type: string;
        allergy: string;
        onsetDateTime: Date;
      }
    | null
    | undefined;
  patient?: any;
};

const AddAllergy = ({
  patient,
  onSaveCallback,
  onCancelPress,
  allergyIntolerance,
}: AddAllergyPropsType) => {
  const { user_fhir_uri } = useAuth().getCurrentUser();
  const ownerId = getFhirIdFromReferenceString(user_fhir_uri);
  const [, { create, update }] = useAllergyIntolerances({
    autofetch: false,
  });
  const [, { create: createCommunication, update: updateCommunication }] = useCommunications({
    autofetch: false,
  });
  const [onsetDate, setOnsetDate] = useState<string>(
    allergyIntolerance?.onsetDateTime
      ? moment.utc(allergyIntolerance?.onsetDateTime).local().format('YYYY-MM-DD')
      : ''
  );
  const [note, setNote] = useState<string>(allergyIntolerance?.note ?? '');
  const [category, setCategory] = useState<any>(allergyIntolerance?.category ?? '');
  const [allergy, setAllergy] = useState<string>(allergyIntolerance?.allergy ?? '');
  const [intolerance, setIntolerance] = useState<any>(allergyIntolerance?.type ?? '');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSavePress = async () => {
    if (!onsetDate || !category || !allergy || !intolerance) {
      setError('Please fill all fields');
      return;
    }
    setIsLoading(true);
    const payload: AllergyIntolerance = {
      id: allergyIntolerance?.id ?? undefined,
      resourceType: 'AllergyIntolerance',
      patient: {
        reference: `Patient/${patient?.fhirId}`,
        type: 'Patient',
      },
      code: {
        coding: [
          {
            code: '91931000',
            display: allergy,
            system: 'http://hl7.org/fhir/ValueSet/allergyintolerance-code',
          },
        ],
      },
      category: [category],
      clinicalStatus: {
        coding: [
          {
            code: 'active',
          },
        ],
      },
      type: intolerance,
      onsetDateTime: moment(onsetDate).utc().toISOString(),
    };

    if (allergyIntolerance?.id) {
      update(payload)
        .then(() => {
          if (allergyIntolerance?.communicationId) {
            const updateCommunicationPayload = getCommunicationPayload(allergyIntolerance.id);
            if (note) {
              updateCommunication(updateCommunicationPayload);
            }
          }
          setError(null);
          setSuccess('Allergy successfully updated');
          setTimeout(() => {
            onSaveCallback({
              ...payload,
            });
          }, 1000);
        })
        .catch(() => {
          setIsLoading(false);
          setError('Error updating the allergy. Please try again');
          setSuccess(null);
        });
    } else {
      create(payload)
        .then(async (allergyIntolerances: AllergyIntolerance[]) => {
          const newAllergyIntolerance = allergyIntolerances[0];
          if (note) {
            const communicationPayload = getCommunicationPayload(newAllergyIntolerance.id);
            await createCommunication(communicationPayload);
          }

          setError(null);
          setSuccess('Allergy saved successfully');
          setTimeout(() => {
            onSaveCallback(newAllergyIntolerance);
          }, 1000);
        })
        .catch(() => {
          setIsLoading(false);
          setError('Error creating the allergy. Please try again');
          setSuccess(null);
        });
    }
  };

  const getCommunicationPayload = (allergyIntoleranceId: string | undefined): Communication => ({
    id: allergyIntolerance?.communicationId ?? undefined,
    resourceType: 'Communication',
    sent: moment().format('YYYY-MM-DD'),
    status: 'completed',
    note: [
      {
        text: note,
        authorReference: {
          reference: `Practitioner/${ownerId}`,
          type: 'Practitioner',
        },
        time: moment().format('YYYY-MM-DD'),
      },
    ],
    subject: {
      reference: `Patient/${patient?.fhirId}`,
      type: 'Patient',
    },
    sender: {
      reference: `Practitioner/${ownerId}`,
      type: 'Practitioner',
    },
    partOf: [
      {
        reference: `AllergyIntolerance/${allergyIntoleranceId}`,
        type: 'AllergyIntolerance',
      },
    ],
  });

  return (
    <form action="">
      <Box>
        <Box sx={{ my: 3 }}>
          <Grid item>
            <PatientCard id={patient?.fhirId} />
          </Grid>
        </Box>
        <Box sx={{ my: 3 }}>
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {allergy?.id ? 'Edit patient allergy' : 'Add allergy to patient'}
            </Typography>
          </Grid>
        </Box>
        <Divider />
        <Grid item style={{ marginTop: '15px' }}>
          <InputLabel shrink>Onset Date *</InputLabel>
          <FormControl fullWidth>
            <TextField
              value={onsetDate}
              defaultValue={onsetDate}
              onChange={(e) => setOnsetDate(e.target.value)}
              type="date"
              variant="standard"
              required
            />
          </FormControl>
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <InputLabel id="type-label">Category *</InputLabel>
          <FormControl fullWidth>
            <Select
              labelId="category-label"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              variant="standard"
              required
            >
              <MenuItem value={'medication'}>Medication</MenuItem>
              <MenuItem value={'food'}>Food</MenuItem>
              <MenuItem value={'environment'}>Environment</MenuItem>
              <MenuItem value={'biologic'}>Biologic</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <InputLabel id="type-label">Type *</InputLabel>
          <FormControl fullWidth>
            <Select
              labelId="type-label"
              value={intolerance}
              onChange={(e) => setIntolerance(e.target.value)}
              variant="standard"
              required
            >
              <MenuItem value={'allergy'}>Allergy</MenuItem>
              <MenuItem value={'intolerance'}>Intolerance</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <InputLabel id="allergy-intolerance-label">Allergy / Intolerance *</InputLabel>
          <FormControl fullWidth>
            <TextField
              required
              value={allergy}
              variant="standard"
              onChange={(e) => setAllergy(e.target.value)}
            />
          </FormControl>
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <TextField
              label="Note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              id="outlined-basic"
              variant="standard"
              multiline
              maxRows={6}
              rows={6}
            />
          </FormControl>
        </Grid>
        {error && (
          <Alert style={{ marginTop: '20px' }} severity="error">
            {error}
          </Alert>
        )}
        {success && (
          <Alert style={{ marginTop: '20px' }} severity="success">
            {success}
          </Alert>
        )}
        <ActionButtons
          isLoading={isLoading}
          leftButtonTitle="Cancel"
          onLeftButtonPress={onCancelPress}
          onRightButtonPress={onSavePress}
        />
      </Box>
    </form>
  );
};

export default AddAllergy;
