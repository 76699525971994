import { IconButton, Stack, Switch } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { Select } from 'src/components/Select';
import { QUESTION_TYPES } from '../constants';
import AutomationResultSelector from './AutomationResultSelector';
import useCarePlanAutomationFormStore, {
  useCarePlanAutomationFormRule,
  type AutomationRule,
} from '../store';
import OperatorValueSelector from './OperatorValueSelector';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';

export interface RuleProps {
  question: WrappedQuestionnaireItem;
  rule: AutomationRule;
}

function Rule({ question, rule }: RuleProps) {
  const saving = useCarePlanAutomationFormStore((s) => s.loading);
  const { enabled, setEnabled, deleteRule, status } = useCarePlanAutomationFormRule(rule.id);
  const setOperator = useCarePlanAutomationFormStore((s) => s.setRuleOperator);

  if (!QUESTION_TYPES[rule.questionType]) {
    return null;
  }

  const { operators } = QUESTION_TYPES[rule.questionType]!;
  const selectedOperator = operators.find((o) => o.value === rule.operator);

  return (
    <Stack
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{
        p: 1,
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        borderBottom: '1px solid #e0e0e0',
        borderRadius: 1,
      }}
      gap={1}
    >
      <Stack gap={0.5} maxWidth={350}>
        <Stack direction="row" gap={1} flexWrap="wrap">
          <Stack direction="row" gap={0.5} justifyContent="center" alignItems="center">
            {/* <Switch
              checked={enabled}
              onChange={(e) => setEnabled(e.target.checked)}
              size="small"
              disabled={saving}
            /> */}
            <IconButton onClick={deleteRule} disabled={saving}>
              <Iconify icon="eva:trash-2-outline" />
            </IconButton>
          </Stack>

          <Select
            isDisabled={!enabled || saving}
            styles={{
              container: (provided) => ({
                ...provided,
                flex: '1 1',
              }),
            }}
            placeholder="Choose an operator"
            options={operators}
            value={selectedOperator}
            onChange={(option) => {
              if (!option) return;

              setOperator(rule.id, option.value);
            }}
          />
        </Stack>

        <Stack minWidth={300}>
          <OperatorValueSelector question={question} rule={rule} />
        </Stack>
      </Stack>

      <AutomationResultSelector question={question} rule={rule} />
    </Stack>
  );
}

export default Rule;
