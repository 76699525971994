import { ChangeEvent } from 'react';
import { TextField, MenuItem } from '@mui/material';
import { Coding } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

const sexualOrientations = [
  { code: 'AS', display: 'Asexual' },
  { code: 'BI', display: 'Bisexual' },
  { code: 'GA', display: 'Gay' },
  { code: 'LE', display: 'Lesbian' },
  { code: 'QU', display: 'Queer' },
  { code: 'QS', display: 'Questioning' },
  { code: 'ST', display: 'Straight' },
  { code: 'SE', display: 'Something Else' },
  { code: 'MU', display: 'Multiple Sexual Orientations' },
  { code: 'DC', display: 'Decline to Answer' },
  { code: 'NI', display: 'No Information' },
  { code: 'UN', display: 'Unknown' },
  { code: 'OT', display: 'Other' },
];

interface SexualOrientationSelectorProps {
  onChange?: (value: any) => void;
  value?: Coding;
}

const SexualOrientationSelector = ({ onChange, value }: SexualOrientationSelectorProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const so = sexualOrientations.find((so) => so.code === event.target.value);
    if (onChange) {
      onChange(so);
    }
  };

  return (
    <TextField
      fullWidth
      select
      label="Sexual Orientation"
      margin="dense"
      size="small"
      variant="standard"
      value={value?.code}
      onChange={handleChange}
    >
      {sexualOrientations.map((so: any) => (
        <MenuItem key={`so-${so.code}`} value={so.code}>
          {so.display}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SexualOrientationSelector;
