import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';

export interface INotificationDialogRadioData {
  label: string;
  value: string;
}

export interface INotificationDialogRadioOptionsData {
  defatulValue: string;
  radioOptionsList: INotificationDialogRadioData[];
}

export interface INotificationDialogData {
  isOpen: boolean;
  title: string;
  text: string;
  radioOptions?: INotificationDialogRadioOptionsData;
}

interface INotificationDialogProps {
  data: INotificationDialogData;
  handleOnClose: (currentSelectedRadioOption?: string) => void;
}

export default function NotificationDialog({ data, handleOnClose }: INotificationDialogProps) {
  const [openDialog, setOpenDialog] = useState(data.isOpen);
  const initialValueSRO = data.radioOptions?.defatulValue || '';
  const [currentSelectedRadioOption, setCurrentSelectedRadioOption] =
    useState<string>(initialValueSRO);

  useEffect(() => {
    setOpenDialog(data.isOpen);
  }, [data]);

  const handleOnRadioGroupChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    setCurrentSelectedRadioOption(event.target.value);
  };

  return (
    <Dialog open={openDialog} fullWidth maxWidth="md">
      <DialogTitle>
        <Grid container>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography variant="subtitle1">{data.title}</Typography>
            </Stack>
          </Grid>
          {/* <Grid item xs={6}>
            <Stack direction="row" justifyContent="end">
              <Button
                color="inherit"
                variant="outlined"
                size="small"
                onClick={() => {
                  handleOnClose(currentSelectedRadioOption);
                }}
              >
                Done
              </Button>
            </Stack>
          </Grid> */}
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Typography variant="body2">{data.text}</Typography>

        {data.radioOptions && (
          <>
            <FormControl fullWidth>
              <RadioGroup
                row
                name="inCounty"
                defaultValue={data.radioOptions.defatulValue}
                onChange={handleOnRadioGroupChange}
              >
                <Grid container>
                  {data.radioOptions.radioOptionsList.map((item: INotificationDialogRadioData, index: number) => {
                    return (
                      <Grid key={'grid-' + index} item sm={6}>
                        <FormControlLabel
                          value={item.value}
                          control={<Radio />}
                          label={item.label}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </RadioGroup>
            </FormControl>

            <Grid container spacing={3} justifyContent="right" marginTop={1}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleOnClose(currentSelectedRadioOption);
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
