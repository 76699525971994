import { useMemo, useState } from 'react';
import {
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Stack,
  Dialog,
  DialogContent,
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import { ConcreteActivity } from 'src/sections/careflow/tasks-activities/model/Activity';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';
import { CompleteActivityMenu } from './CompleteActivityMenu';
import { ActivityActionTypes } from './CompleteActivityMenu/CompleteActivityMenuItem';
import { ActivityStatus } from 'src/sections/careflow/tasks-activities/model';
import { useCodeSystem } from 'src/@nicheaim/fhir-react';
import { getActivityNames } from './ActivityListView/ActivityListTable/activity-list-table-utils';
import { ContextMenuItemType } from 'src/sections/careflow/common/EntityContextMenu';

type ActivityContextMenuPropsType = {
  activity: ConcreteActivity;
  color?: string;
  items: ContextMenuItemType<ConcreteActivity>[];
  onUpdateActivity: (activity: ConcreteActivity) => void;
};

export const activityContextMenuItems: ContextMenuItemType<ConcreteActivity>[] = [
  {
    id: 'accept',
    label: 'Accept',
    icon: 'bi:calendar-check',
    rules: (activity: ConcreteActivity) => activity.status === ActivityStatus.REQUESTED,
  },
  {
    id: 'start',
    label: 'Start',
    icon: 'bi:play-fill',
    rules: (activity: ConcreteActivity) =>
      activity.status === ActivityStatus.READY && activity.scriptId ? true : false,
  },
  {
    id: 'edit',
    label: 'Edit',
    icon: 'eva:edit-2-outline',
  },
];

const ActivityContextMenu = ({
  onUpdateActivity,
  items,
  activity,
  color = '#637381',
}: ActivityContextMenuPropsType) => {
  const [menuType, setMenuType] = useState<ActivityActionTypes>(ActivityActionTypes.COMPLETE);
  const [selectedActivityId, setSelectedActivityId] = useState<number | null>(null);
  const [showCompleteActivityMenu, setShowCompleteActivityMenu] = useState<boolean>(false);
  const popupState = usePopupState({ variant: 'popover', popupId: 'schedule-activity-modal' });
  const triggers = useMemo(() => bindTrigger(popupState), [popupState]);
  const menuItems: ContextMenuItemType<ConcreteActivity>[] = useMemo(() => items, [items]);
  const [codeSystem] = useCodeSystem('ccm-activity-contact-types');

  const onCloseCompleteMenu = () => setShowCompleteActivityMenu(false);

  return (
    <>
      <Dialog open={showCompleteActivityMenu} onClose={onCloseCompleteMenu}>
        <DialogContent>
          <CompleteActivityMenu
            TitleComponent={
              <>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {menuType === ActivityActionTypes.COMPLETE && 'Complete'}
                  {menuType === ActivityActionTypes.CANCEL && 'Cancel'} Activity
                </Typography>
                {activity && getActivityNames(activity, codeSystem)}
              </>
            }
            activityId={selectedActivityId ?? 0}
            type={menuType}
            onSaveActivity={(savedActivity) => {
              onUpdateActivity?.(savedActivity);
              onCloseCompleteMenu();
            }}
            onCloseMenuItem={onCloseCompleteMenu}
          />
        </DialogContent>
      </Dialog>
      <IconButton {...triggers}>
        <Iconify color={color} icon="eva:more-vertical-fill" />
      </IconButton>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuItems
          .filter((menuItem) =>
            menuItem.rules ? menuItem.rules(activity) : true
          )
          .map((menuItem) => (
            <MenuItem
              key={menuItem.label}
              onClick={() => {
                menuItem?.onPress?.(activity, null);
                popupState.close();
              }}
              disabled={typeof menuItem.disabled === 'function' ? menuItem.disabled?.(activity) : menuItem.disabled}
            >
              <Stack direction="row" spacing={2}>
                <Iconify icon={menuItem.icon || ''} />
                <Typography variant="body2">{menuItem.label}</Typography>
              </Stack>
            </MenuItem>
          ))}
        {activity.status !== ActivityStatus.COMPLETED &&
          activity.status !== ActivityStatus.CANCELLED && (
            <MenuItem
              onClick={() => {
                setMenuType(ActivityActionTypes.CANCEL);
                setSelectedActivityId(activity?.id);
                setShowCompleteActivityMenu(true);
                popupState.close();
              }}
            >
              <Stack direction="row" spacing={2}>
                <Iconify icon="material-symbols:close" />
                <Typography variant="body2">Cancel</Typography>
              </Stack>
            </MenuItem>
          )}
        {activity.status !== ActivityStatus.COMPLETED &&
          activity.status !== ActivityStatus.CANCELLED && (
            <MenuItem
              onClick={() => {
                setMenuType(ActivityActionTypes.COMPLETE);
                setSelectedActivityId(activity?.id);
                setShowCompleteActivityMenu(true);
                popupState.close();
              }}
            >
              <Stack direction="row" spacing={2}>
                <Iconify icon="fluent-mdl2:completed" />
                <Typography variant="body2">Complete Activity</Typography>
              </Stack>
            </MenuItem>
          )}
      </Menu>
    </>
  );
};

export default ActivityContextMenu;
