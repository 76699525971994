const styles = {
  quickActionBox: {
    backgroundColor: '#dfe3e8',
    borderRadius: '16px',
    padding: '15px',
  },
  quickActionBoxTitle: {
    color: '#241f20',
    fontSize: '18px',
    fontFamily: 'Public Sans',
  },
  quickActionBoxCounterContainer: {
    paddingTop: '5px',
    borderRadius: 25,
    width: 50,
    height: 50,
    marginLeft: '18px',
  },
};

export default styles;
