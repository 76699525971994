import { 
  Autocomplete, 
  Box, 
  Button, 
  Card, 
  Chip, 
  Dialog, 
  DialogActions, 
  DialogTitle, 
  Grid, 
  Stack, 
  TextField, 
  Typography 
} from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from 'notistack';
import useAuth from "src/hooks/useAuth";
import { useEffect, useMemo } from "react";
import { getFhirIdFromEntity } from "src/utils/fhir";
import { Controller, useForm } from "react-hook-form";
import { useCodeSystem, useValueSet } from "src/@nicheaim/fhir-react";
import { FormProvider, RHFTextField } from "src/components/hook-form";
import { ValueSetWrapper } from "src/@nicheaim/fhir-base/wrappers/ValueSet";
import { WrappedServiceRequest } from "src/@nicheaim/fhir-base/wrappers/ServiceRequest";
import { convertValueToValueSet, getCodeSystemFromConcept } from "src/sections/crs/common/common-utils";

type FormValue = {
  category: string[];
  note: string;
};

type Props = {
  serviceRequest: WrappedServiceRequest | null;
  open: boolean;
  onClose: VoidFunction;
  handleCommunication: (data: any) => Promise<any>;
};

export default function AnnotationForm({ open, onClose, serviceRequest, handleCommunication }: Props) {

  const { enqueueSnackbar } = useSnackbar();

  const [communicationCategory] = useValueSet('crs-communication-categories', { map: ValueSetWrapper });
  const categoryCommunicationCoding = convertValueToValueSet('notes_outbound_referral', communicationCategory);
  const [ annotationsCodes ] = useValueSet('crs-annotations-codes', { map: ValueSetWrapper });

  const authUser = useAuth().getCurrentUser();

  const defaultValues = useMemo(() =>({
    category: [],
    note: '',
  } as FormValue), [document]);

  const methods = useForm({ defaultValues });

  const {
    reset,
    control,
    handleSubmit,
  } = methods;

  const mapFormDataToCommunication = (data: FormValue) => {

    const { category, note } = data;

    const sender = { 
      ...(!isEmpty(authUser.user_fhir_uri.trim()) && {
        reference: `Practitioner/${getFhirIdFromEntity(authUser.user_fhir_uri)}` 
      }), 
      display: authUser.name
    }

    const codeValueCategory = category.map((e) => {
      let valueSet = convertValueToValueSet(e, annotationsCodes);

      if(!valueSet){
        valueSet = { code: e, display: e};
      }

      return (
        { 
          coding: [{ ...valueSet}],
          text: e 
        }
      );
    });

    return{
      resourceType: 'Communication',
      status: 'completed',
      partOf: [
        {
          reference: `ServiceRequest/${serviceRequest?.id}`
        }
      ],
      sender: sender,
      subject: serviceRequest?.subject,
      category: [
        ...codeValueCategory, 
        {
          coding: [categoryCommunicationCoding],
          text: categoryCommunicationCoding?.display
        }
      ],
      sent: new Date().toISOString(),
      note: [{text: note}]
    }
  };

  const onSubmit = async (data: FormValue) => {

    try{

      const resultMap = mapFormDataToCommunication(data);
      const result = await handleCommunication(resultMap);

      if(result) enqueueSnackbar('Annotations was created.');
      handleClose();
    }catch(e){
      enqueueSnackbar('Annotations was not created.', { variant:'error' });
    }
  };

  const handleClose = () =>{
    reset(defaultValues);
    onClose();
  };

  useEffect(() => {
    if(open){
      reset(defaultValues);
    }
  }, [document]);

  return(
    <Dialog open={open} fullWidth={true} maxWidth="sm">
    <DialogTitle>Annotations</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2}  sx={{ p: 2 }}>
                
              <Typography variant="body2">
                  Category
                </Typography>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      freeSolo
                      onChange={(event, newValue) => field.onChange(newValue)}
                      options={annotationsCodes?.asList().map((option) => option.display) || []}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => <TextField label="" {...params} />}
                    />
                  )}
                />

                <Typography variant="body2">
                  Note
                </Typography>
                <RHFTextField name="note" label="Note" multiline rows={10} />

              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />

              <Button variant="contained" color="info" onClick={handleClose}>
                Cancel
              </Button>

              <Button variant="contained" color="info" type="submit">
                Submit
              </Button>
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
};