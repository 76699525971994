import { createContext } from 'react';
import EventBus from './eventBus';

const eventBus = new EventBus();
const eventBusObj = {
  onEvent: (event: string, callback: Function) => eventBus.on.call(eventBus, event, callback),
  dispatchEvent: (event: string, data: unknown) => eventBus.dispatch.call(eventBus, event, data),
  removeEvent: (event: string, callback: Function) =>
    eventBus.remove.call(eventBus, event, callback),
};

const EventBusContext = createContext<any>(null); //TODO: Fix type

const EventBusProvider = ({ children }: any) => (
  <EventBusContext.Provider value={eventBusObj}>{children}</EventBusContext.Provider>
);

export { EventBusContext, EventBusProvider };
