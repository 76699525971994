import { Card } from '@mui/material';
import { useParams } from 'react-router';
import PatientReferral from './patientReferralCases/patientReferral';
import PatientCase from './patientReferralCases/patientCase';

export default function PatientReferralCases() {

  const { patientId } = useParams();

  return (
    <Card sx={{ py: 2, boxShadow: 'none' }}>
      <PatientReferral patientId={patientId}/>

      <PatientCase patientId={patientId} />
    </Card>
  );
};