import {
  Box,
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Group } from 'src/@nicheaim/fhir-base/mappings/Group';
import { Organization } from 'src/@nicheaim/fhir-base/mappings/Organization';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { useGroups, useOrganization, usePatient } from 'src/@nicheaim/fhir-react';
import Iconify from 'src/components/Iconify';
import { SHOW_SIDEBAR_COMPONENT, useEventBus } from 'src/event-bus';
import fhirSystem from 'src/fhir/system';
import {
  Coding,
  GroupMember,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { getFhirIdFromEntity, referenceEquals } from 'src/utils/fhir';
import { ActionBar } from '../../common';
import PlansDetailsExpanded from './PlansDetailsExpanded';
import PlansDetailsForm, {
  MembershipStatus,
  getMembershipStatus,
  membershipStatusMessage,
} from './PlansDetailsForm';
import { sortBy } from 'lodash';
import useProgramsPlanPatient from 'src/hooks/useProgramsPlanPatient';

export interface Membership {
  lobId: string;
  membership: GroupMember & {
    index: number;
  };
  program: Coding;
  status: MembershipStatus;
}

export interface IPlanProgramRow {
  plan: Organization;
  lob: Organization;
  program: Coding;
  membership: GroupMember;
}

interface IContextMenuProps {
  row: IPlanProgramRow;
  handleEdit: (row: IPlanProgramRow) => void;
}

function ContextMenu({ row, handleEdit }: IContextMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  return (
    <TableCell align="right">
      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
        <MenuItem
          onClick={() => {
            handleEdit(row);
            closeMenu();
          }}
        >
          <Stack direction="row" spacing={2}>
            <Iconify icon="eva:edit-fill" />
            <Typography variant="body2">Edit</Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <IconButton onClick={openMenu}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
    </TableCell>
  );
}

interface IPlansDetailsProps {
  handleOnExpandActionBar: (component: JSX.Element) => void;
  handleOnCollapseActionBar: () => void;
  patient: WrappedPatient;
  hideContextMenu?: boolean;
}

const PlansDetails = ({
  handleOnExpandActionBar,
  handleOnCollapseActionBar,
  patient,
  hideContextMenu,
}: IPlansDetailsProps) => {
  const patientId = patient.id || '';
  const { dispatchEvent } = useEventBus();

  const [groups, { refresh: refreshPrograms }] = useGroups<Group>({
    filter: {
      member: patient?.id,
      identifier: fhirSystem.program.enrolledPatients.forCode(''),
    },
    autofetch: !!patient?.id,
  });

  // programs
  const enrolledPrograms = useMemo(
    () =>
      sortBy(
        groups.reduce((acc, group) => {
          const lobId = getFhirIdFromEntity(group.managingEntity?.reference || '');
          const program = group.code?.coding?.[0];
          const memberships = group.member || [];

          if (!lobId || !program) {
            return acc;
          }

          acc.push(
            ...memberships
              .map((membership, index) => ({
                ...membership,
                index,
              }))
              .filter((member) => referenceEquals(member.entity, patient))
              .map((membership) => ({
                groupId: group.id,
                lobId,
                membership,
                program,
                status: getMembershipStatus(membership),
              }))
          );

          return acc;
        }, [] as Membership[]),
        ({ membership }) =>
          ({
            new: 0,
            pending: 1,
            active: 2,
            inactive: 3,
            unknown: 4,
          }[getMembershipStatus(membership)]),
        ({ membership }) => (membership.period?.start ? -Date.parse(membership.period?.start) : 0),
        ({ membership }) => (membership.period?.end ? -Date.parse(membership.period?.end) : 0)
      ),
    [groups]
  );

  const handleAdd = () => {
    dispatchEvent(
      SHOW_SIDEBAR_COMPONENT,
      <Modal
        open
        onClose={() => {
          dispatchEvent(SHOW_SIDEBAR_COMPONENT, null);
        }}
      >
        <PlansDetailsForm
          patientId={patientId}
          onClose={() => {
            dispatchEvent(SHOW_SIDEBAR_COMPONENT, null);
          }}
          handleAfterSave={() => {
            refreshPrograms();
          }}
        />
      </Modal>
    );
  };

  const handleEdit = (row: IPlanProgramRow) => {
    dispatchEvent(
      SHOW_SIDEBAR_COMPONENT,
      <Modal
        open
        onClose={() => {
          dispatchEvent(SHOW_SIDEBAR_COMPONENT, null);
        }}
      >
        <PlansDetailsForm
          patientId={patientId}
          editData={row}
          onClose={() => {
            dispatchEvent(SHOW_SIDEBAR_COMPONENT, null);
          }}
          handleAfterSave={() => {
            refreshPrograms();
          }}
        />
      </Modal>
    );
  };

  const onExpandActionBar = () => {
    handleOnExpandActionBar(
      <PlansDetailsExpanded
        actionBar={<ActionBar onCollapse={handleOnCollapseActionBar} />}
        patientId={patientId}
        enrolledPrograms={enrolledPrograms}
        handleEdit={handleEdit}
      />
    );
  };

  return (
    <Card>
      <Grid container>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 2 }}
          xs={12}
        >
          <Grid item>
            <Typography variant="h6">Plans / Programs Details</Typography>
          </Grid>
          <Grid item>
            <ActionBar onExpand={onExpandActionBar} onAdd={handleAdd} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: '400px', overflow: 'auto' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Plan / Program</TableCell>
                    <TableCell>Dates</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {enrolledPrograms.map((membership) => (
                    <ProgramEnrollment
                      key={membership.membership.id}
                      membership={membership}
                      patientId={patientId}
                      hideContextMenu={hideContextMenu}
                      handleEdit={handleEdit}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

interface ProgramEnrollmentProps {
  expanded?: boolean;
  membership: Membership;
  patientId: string;
  hideContextMenu?: boolean;
  handleEdit?: (row: IPlanProgramRow) => void;
}

export function ProgramEnrollment({
  expanded,
  membership,
  patientId,
  hideContextMenu,
  handleEdit,
}: ProgramEnrollmentProps) {
  const [lob] = useOrganization(membership.lobId);
  const [plan] = useOrganization(getFhirIdFromEntity(lob?.partOf?.reference ?? ''), {
    autofetch: !!lob,
  });
  const [patient] = usePatient(patientId);

  const patientPlanId = useMemo(
    () =>
      patient?.identifier?.find(
        (identifier) =>
          identifier.system === fhirSystem.healthPlan.withId(plan?.id || '').asString()
      )?.value,
    [plan, patient]
  );

  return (
    <TableRow key={membership.membership.id}>
      {expanded && (
        <>
          <TableCell>
            <Typography variant="subtitle2">{patientPlanId}</Typography>
          </TableCell>

          <TableCell>
            <Typography variant="subtitle2">{membership.membership.id}</Typography>
          </TableCell>
        </>
      )}

      <TableCell>
        <Typography variant="subtitle2">
          {plan?.name} / {membership.program.display}
        </Typography>
      </TableCell>
      <TableCell>
        <Stack>
          <Stack direction="row" alignItems="center">
            <Typography variant="caption">S:</Typography>
            <Typography variant="body2">
              {membership.membership.period?.start &&
                moment(membership.membership.period.start).toDate().toLocaleDateString('en-US', {
                  month: 'short',
                  year: 'numeric',
                  day: 'numeric',
                })}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography variant="caption">E:</Typography>
            <Typography variant="body2">
              {membership.membership.period?.end &&
                moment(membership.membership.period.end).toDate().toLocaleDateString('en-US', {
                  month: 'short',
                  year: 'numeric',
                  day: 'numeric',
                })}
            </Typography>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center">
          <Typography variant="body2">{membershipStatusMessage[membership.status]}</Typography>
        </Stack>
      </TableCell>

      {!hideContextMenu && lob && plan && (
        <ContextMenu
          row={{
            lob,
            membership: membership.membership,
            plan,
            program: membership.program,
          }}
          handleEdit={handleEdit || (() => {})}
        />
      )}
    </TableRow>
  );
}

export default PlansDetails;
