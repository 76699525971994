import { Typography, Stack } from '@mui/material';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import { Select } from 'src/components/Select';
import useCarePlanAutomationFormStore, {
  AutomationRule,
  useCarePlanAutomationFormError,
  useCarePlanAutomationFormRule,
} from '../../store';
import { OpportunityPriorities } from 'src/services/api/care-plan';

interface PrioritySelectorProps {
  question: WrappedQuestionnaireItem;
  rule: AutomationRule;
}

export default function PrioritySelector({ question, rule }: PrioritySelectorProps) {
  const saving = useCarePlanAutomationFormStore((s) => s.loading);
  const { enabled } = useCarePlanAutomationFormRule(rule.id);
  const { priority, setPriority } = useCarePlanAutomationFormRule(rule.id);
  const error = useCarePlanAutomationFormError(`rule(${question.linkId}:${rule.id})-priority`);

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
      <Typography sx={{ minWidth: 120, pb: error ? 2 : 0 }}>Priority:</Typography>
      <Select
        styles={{
          container: (provided) => ({
            ...provided,
            flex: 1,
          }),
        }}
        error={error}
        isDisabled={!enabled || saving}
        placeholder="Select Priority"
        options={OpportunityPriorities}
        value={OpportunityPriorities.find((o) => o.value === priority)}
        onChange={(option) => {
          setPriority(option?.value || '', priority || '');
        }}
      />
    </Stack>
  );
}
