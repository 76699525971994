import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { useMatch, useNavigate } from 'react-router';
import Iconify from 'src/components/Iconify';
import { Patient } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { usePatients } from 'src/@nicheaim/fhir-react';
import produce from 'immer';
import PatientsForm from './PatientForm';
import { createWrapper } from 'src/@nicheaim/fhir-react/base';
import useIntersect from 'src/hooks/useIntersect';
import { useRef } from 'react';

// const mapper: ResourceMapper<Patient> = (patient) => {
//   return patient.id!;
// };
// onst value = mapper({
//   id: '123',
//   resourceType: 'Patient',
//   name: [
//     {
//       family: 'Smith',
//       given: ['John'],
//     },
//   ],
// });

// function mapper() {
//   return {
//     alive: true,
//   }
// }

// function createWrapper(mapperFn: <U>() => U){
//   return function wrapper(resource: Patient){
//     return Object.assign(resource, mapperFn());
//   }
// }
// const wrapper = createWrapper(() => ({
//   alive: true,
//   fullName: 'John Smith',
// }));

// const wrapper = createWrapper<Patient>(() => ({ alive: true }));

// const x = wrapper({
//   resourceType: 'Patient',
// }).alive;
// console.log(x);
// const mapper: ResourceMapper<Patient> = (resource) => ({
//   alive: true
// })

// mapper({}).

export default function PatientsList() {
  const navigate = useNavigate();
  const isEditting = !!useMatch('/_debug/patients/edit/*');
  const [patients, { remove, update, nextPage }] = usePatients({
    map: createWrapper('Patient', (resource) => ({
      fullName: `(${resource.name?.[0].given?.[0]} ${resource.name?.[0].family})`,
    })),
    pagination: {
      pageSize: 10,
    },
  });
  const { secondRef } = useIntersect({
    firstRef: useRef(document.getElementById('root')),
    onChange(isIntersecting) {
      console.log('isIntersecting', isIntersecting);
      if (isIntersecting) {
        nextPage();
      }
    },
  });

  const handleDelete = (patient: Patient) => () => remove(patient);
  const handleView = (patient: Patient) => () => navigate(`/_debug/patients/${patient.id}`);
  const handleEdit = (patient: Patient) => () => navigate(`/_debug/patients/edit/${patient.id}`);
  const handleToggle = (patient: Patient) => () =>
    update(
      produce(patient, (draft) => {
        draft.active = !draft.active;
      })
    );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5">Patients</Typography>

      <PatientsForm />

      <List>
        {patients.map((patient) => (
          <ListItem
            key={patient.id}
            disablePadding
            secondaryAction={
              <>
                {!isEditting && (
                  <IconButton onClick={handleEdit(patient)}>
                    <Iconify icon="eva:edit-2-fill" />
                  </IconButton>
                )}
                <IconButton onClick={handleToggle(patient)}>
                  {patient.active ? (
                    <Iconify icon="ic:baseline-delete" />
                  ) : (
                    <Iconify icon="ic:baseline-restore-from-trash" />
                  )}
                </IconButton>
                <IconButton onClick={handleDelete(patient)}>
                  <Iconify icon="ic:baseline-delete-forever" />
                </IconButton>
              </>
            }
          >
            <ListItemButton onClick={handleView(patient)}>
              <ListItemText>
                {/* @ts-ignore */}
                Patient: {patient.fullName}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
        <Button ref={secondRef} onClick={nextPage}>
          Load More
        </Button>
      </List>
    </Box>
  );
}
