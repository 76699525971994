import {
  Bundle,
  DocumentReference,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import client from './_client';

export async function getDocuments(patientId: string) {
  const { data } = await client.get<Bundle<DocumentReference[]>>(
    `crs/document/patient/${patientId}`
  );
  return data;
}

export async function getBinaryDocument(
  patient: string,
  documentUniqueId: string,
  repositoryUniqueId: string,
  hcid: string
) {
  const { data } = await client.get<DocumentReference>(
    `crs/document/${patient}?documentId=${documentUniqueId}&repositoryId=${repositoryUniqueId}&hcid=${hcid}`
  );
  return data;
}

export const generateTemplate = async (payload: {
  rawData: string;
  templateURL: string;
  languageURL: string;
}): Promise<string> => {
  try {
    const { data } = await client.post<string>(`crs/document/generate-template`, payload);
    return data;
  } catch (error) {
    return '';
  }
};
