import moment from 'moment';
import { ConcreteActivity } from '../../model/Activity';
import { EntityTypeStatesEnum } from './QuickActions/constants';
import { repeatFieldItems } from './ScheduleActivity/_mock';
import { CarePlan } from 'src/@nicheaim/fhir-base/mappings/CarePlan';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';

export enum ActivityEnum {
  FOLLOW_UP = 'Follow up',
  ROUNDING = 'Rounding',
  SDOH_ASSESSMENT = 'SDoH Assessment',
}

export enum ActivityTypesEnum {
  ASSESSMENT = 'Assessment',
}

export enum ACTIVITIES_VIEW_TYPES {
  LIST = 'List',
  CALENDAR = 'Calendar',
}

export enum ACTIVITIES_LIST_TYPES {
  STAFF = 'Staff',
  PATIENT = 'Patient',
}

export type ScheduleActivityStateType = {
  activityType: string;
  activity: string | null;
  activityItem: ActivityItemType | null;
  contactType: string | null;
  tool: string;
  toolItem: {
    label: string;
    value: string;
  } | null;
  date: Date;
  startHour: string;
  endHour: string;
  allDay: boolean;
  repeat: string;
  note: string;
  assignedTo: string | null;
  assignedToItem: {
    label: string;
    value: string;
  } | null;
  reminder: string | number;
  users: [];
  carePlans: WrappedCarePlan[];
  completeActivityDuration?: number;
  completeActivityUnit?: string;
  contactMade: boolean;
  completedActivityNote: string;
  completedActivityNoteFhirId: string;
};

export type ScheduleActivityPropsType = {
  onSaveCallback: (activity: ConcreteActivity) => void;
  activity?: ConcreteActivity | null | undefined;
  patient?: {
    fhirId: string | undefined;
  };
  shouldCompletedActivity?: boolean;
};

export type ActivityItemType = {
  name: string;
  type: string;
  id: string;
  typeId: string;
};

export const defaultStateFieldsValues = (
  userFhirId: string,
  activity?: ConcreteActivity | null | undefined,
): ScheduleActivityStateType => ({
  activityType: activity?.activityTypeId ?? '',
  activity: activity?.activityTypeId ?? null,
  activityItem: activity?.activity
    ? {
        name: activity?.activity,
        type: activity?.type,
        id: activity?.activityId,
        typeId: activity?.activityTypeId,
      }
    : null,
  contactType: activity?.contactType ?? null,
  tool: activity?.scriptId ?? '',
  toolItem: activity?.scriptId
    ? {
        label: activity.script,
        value: activity.scriptId,
      }
    : null,
  date: activity?.date ? activity.date : moment().toDate(),
  startHour: activity?.start ? moment(activity?.start)?.format('HH:mm') : moment().format('HH:mm'),
  endHour: activity?.end ? moment(activity?.end)?.format('HH:mm') : moment().format('HH:mm'),
  allDay: activity?.allDay ?? false,
  repeat: activity?.repeat ?? repeatFieldItems?.[0]?.value,
  note: activity?.note ?? '',
  assignedTo: activity?.staffFhirId ?? userFhirId,
  assignedToItem: {
    label: activity?.staff ?? 'Me',
    value: activity?.staffFhirId ?? userFhirId,
  },
  reminder: activity?.reminder ?? '',
  users: [],
  carePlans: [] as WrappedCarePlan[],
  completeActivityDuration: activity?.completedDuration,
  completeActivityUnit: activity?.completedUnit,
  contactMade: activity?.contactMade ?? false,
  completedActivityNote: activity?.completedNote ?? '',
  completedActivityNoteFhirId: (activity as any)?.completedNoteFhirId ?? '',
});

export const activityStatusFilterOptions = [
  {
    label: 'All',
    value: 'all',
    key: 'activities_all',
  },
  {
    label: 'New',
    value: EntityTypeStatesEnum.NEW,
    key: 'activities_requested',
  },
  {
    label: 'Past due',
    value: EntityTypeStatesEnum.PAST_DUE,
    key: 'activities_past_due',
  },
  {
    label: 'Due today',
    value: EntityTypeStatesEnum.DUE_TODAY,
    key: 'activities_due_today',
  },
  {
    label: 'Future task',
    value: EntityTypeStatesEnum.FUTURE_TASK,
    key: 'activities_future_task',
  },
  {
    label: 'Completed',
    value: EntityTypeStatesEnum.COMPLETED,
    key: 'activities_completed',
  },
  {
    label: 'Cancelled',
    value: EntityTypeStatesEnum.CANCELLED,
    key: 'activities_cancelled',
  },
  {
    label: 'Requested',
    value: EntityTypeStatesEnum.REFERRAL_NEW,
    key: 'referrals_draft',
  },
  {
    label: 'Pending',
    value: EntityTypeStatesEnum.REFERRAL_PENDING,
    key: 'referrals_on-hold',
  },
  {
    label: 'Accepted',
    value: EntityTypeStatesEnum.ACCEPTED,
    key: 'referrals_active',
  },
  {
    label: 'New',
    value: EntityTypeStatesEnum.PATIENT_NEW,
    key: 'patients_new',
  },
  {
    label: 'Pending',
    value: EntityTypeStatesEnum.PATIENT_PENDING,
    key: 'patients_pending',
  },
  {
    label: 'Active',
    value: EntityTypeStatesEnum.PATIENT_ACTIVE,
    key: 'patients_active',
  },
];

export const ActivityStatus = {
  ALL: 'all',
  REQUESTED: 'requested',
  READY: 'ready',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
} as const;
export type ActivityStatusType = typeof ActivityStatus[keyof typeof ActivityStatus];
export const activityStatusOptions = [
  {
    label: 'All',
    value: ActivityStatus.ALL,
  },
  {
    label: 'New',
    value: ActivityStatus.REQUESTED,
  },
  {
    label: 'Ready',
    value: ActivityStatus.READY,
  },
  {
    label: 'Completed',
    value: ActivityStatus.COMPLETED,
  },
  {
    label: 'Cancelled',
    value: ActivityStatus.CANCELLED,
  },
];

export const ActivityDueStatus = {
  ALL: 'all',
  PAST_DUE: 'past_due',
  DUE_TODAY: 'due_today',
  FUTURE_TASK: 'future_task',
} as const;
export type ActivityDueStatusType = typeof ActivityDueStatus[keyof typeof ActivityDueStatus];
export const activityDueStatusOptions = [
  {
    label: 'All',
    value: ActivityDueStatus.ALL,
  },
  {
    label: 'Past due',
    value: ActivityDueStatus.PAST_DUE,
  },
  {
    label: 'Due today',
    value: ActivityDueStatus.DUE_TODAY,
  },
  {
    label: 'Future task',
    value: ActivityDueStatus.FUTURE_TASK,
  },
];
