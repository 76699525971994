import { IconButton, Modal } from "@mui/material";
import Iconify from "src/components/Iconify";
import { SummaryTable } from "../../common";
import useObjectState from "src/hooks/useObjectState";
import MedicationRequestTransactionForm from "../medications-requests/MedicationRequestTransactionForm";
import { MedicationRequest } from "src/@nicheaim/fhir-base/mappings/MedicationRequest";
import { MedicationTransaction, useMedicationTransactions } from "src/services/api/medication-transaction";
import moment from "moment";
import parse from 'html-react-parser';
import { useMedicationDispenses, useMedicationRequest } from "src/@nicheaim/fhir-react";
import { useEffect } from "react";

type MedicationsTransactionsSummaryProps = {
  isExpanded: boolean;
  patient: any;
  medicationRequest: MedicationRequest;
  onExpand: (index: number) => void;
  showButtons?: boolean;
}

type MedicationsTransactionsSummaryState = {
  showMedicationTransactionForm: boolean;
}

const MedicationsTransactionsSummary = ({
  patient,
  medicationRequest,
  isExpanded,
  onExpand,
  showButtons = true,
}: MedicationsTransactionsSummaryProps) => {
  const [{
    showMedicationTransactionForm,
  }, updateState] = useObjectState<MedicationsTransactionsSummaryState>({
    showMedicationTransactionForm: false,
  })

  const { data: medicationTransactions = [] } = useMedicationTransactions(medicationRequest.id!);
  const [dispense, { refresh }] = useMedicationDispenses({
    autofetch: medicationTransactions?.length > 0 ? true : false,
    filter: {
      prescription: medicationRequest.id,
      _sort: '-whenPrepared',
    }
  })
  const [request] = useMedicationRequest(medicationRequest?.id);

  const onCloseMedicationRequestTransactionModal = () => {
    updateState({
      showMedicationTransactionForm: false,
    });
  }

  useEffect(() => {
    console.log('refresh dispense')
    refresh();
  }, [medicationTransactions])

  return (
    <>
      <Modal
        open={showMedicationTransactionForm}
        onClose={onCloseMedicationRequestTransactionModal}
      >
        <MedicationRequestTransactionForm
          patient={patient}
          medicationRequest={medicationRequest}
          onClose={onCloseMedicationRequestTransactionModal}
          onSave={() => {}}
        />
      </Modal>
      <SummaryTable
        title="Medication Transactions"
        data={medicationTransactions ?? []}
        columns={[
          {
            header: 'Date',
            field: '$',
            render(transaction: MedicationTransaction) {
              return moment(transaction.performedDate).format('MMM DD, YYYY hh:mm a');
            }
          },
          {
            header: 'Type',
            field: '$',
            render(transaction: MedicationTransaction) {
              return transaction.transactionType.valueDisplayName;
            }
          },
          {
            header: 'Medication',
            field: '$',
            render(transaction: MedicationTransaction) {
              return transaction.medicationName;
            }
          },
          {
          header: 'Quantity',
            field: '$',
            render(transaction: MedicationTransaction) {
              return transaction.quantity;
            }
          },
          {
            header: 'Inventory',
              field: '$',
              render() {
                return `Quantity: ${dispense?.[0]?.quantity?.value}, Refill ${request?.dispenseRequest?.numberOfRepeatsAllowed}`;
              }
          },
          {
            header: 'Unit',
            field: '$',
            visible: isExpanded,
            render(transaction: MedicationTransaction) {
              return transaction.unitName;
            }
          },
          {
            header: 'Staff',
            field: '$',
            render(transaction: MedicationTransaction) {
              return `${transaction.staff.firstName} ${transaction.staff.lastName}`;
            }
          },
          {
            header: 'Note',
            field: '$',
            visible: isExpanded,
            render(transaction: MedicationTransaction) {
              return parse(transaction.note ?? 'N/A');
            }
          }
        ]}
        actionBar={
          <>
            {showButtons && (
              <>
                <IconButton
                  onClick={() =>
                    updateState({
                      showMedicationTransactionForm: true,
                    })
                  }
                >
                  <Iconify icon="eva:plus-fill" />
                </IconButton>
              </>
            )}
          </>
        }
      />
    </>
  )
}

export default MedicationsTransactionsSummary;
