import { Box, Tooltip, Typography } from '@mui/material';
import {
  ActivityLegendRenderer,
  ActivityPriorityRenderer,
} from '../tasks-activities/components/Activities/ActivityRenderers';
import { ConcreteActivity } from '../tasks-activities/model/Activity';
import { ActivityLegends } from '../tasks-activities/model/ActivityLegends';
import { ActivityStatus } from '../tasks-activities/model/ActivityStatus';
import { renderDate } from '../tasks-activities/components/Activities/utils';
import parse from 'html-react-parser';

export const SUMMARY_ACTIVITY_TABLE_COLUMNS = (contactTypes: any[]) => [
  {
    header: 'Date',
    field: '$',
    render(activity: ConcreteActivity) {
      return renderDate(activity);
    },
  },
  {
    header: 'Activity',
    field: '$',
    render(activity: ConcreteActivity) {
      const statusLegend = {
        [ActivityStatus.REQUESTED]: ActivityLegends.NEW,
        [ActivityStatus.READY]: ActivityLegends.READY,
        [ActivityStatus.CANCELLED]: ActivityLegends.CANCELLED,
        [ActivityStatus.COMPLETED]: ActivityLegends.COMPLETED,
      };

      return (
        <>
          <Typography>
            {`${activity.type} - ${activity.activity} (${
              contactTypes.find((ct) => ct.code === activity.contactType)?.display ?? '...'
            })`}
          </Typography>
          <Box sx={{ display: 'inline-flex' }}>
            {![ActivityStatus.COMPLETED, ActivityStatus.CANCELLED].includes(activity.status) &&
              new ActivityLegendRenderer({
                ...activity,
              }).render(true)}

            {new ActivityLegendRenderer({
              ...activity,
              legend: statusLegend[activity.status as keyof typeof statusLegend],
            }).render(true)}
          </Box>
        </>
      );
    },
  },
];

export const EXPANDED_ACTIVITY_TABLE_COLUMNS = (contactTypes: any[]) => [
  ...SUMMARY_ACTIVITY_TABLE_COLUMNS(contactTypes),
  {
    header: 'Script / Tool',
    field: 'script',
    render(value: string) {
      return value ?? 'N/A';
    },
  },
  {
    header: 'Priority',
    field: '$',
    render: (activity: ConcreteActivity) => new ActivityPriorityRenderer(activity).render(),
  },
  {
    header: 'Created By',
    field: 'createdBy',
  },
  {
    header: 'Assigned To',
    field: 'staff',
  },
  {
    header: 'Mins',
    field: 'completedDuration',
    render(value: string) {
      return value ?? 'N/A';
    },
  },
  {
    header: 'Note',
    field: '$',
    render(activity: ConcreteActivity) {
      if (activity.status === ActivityStatus.COMPLETED) {
        return (
          <Tooltip title={activity.completedNote ?? 'N/A'}>
            <Typography sx={{ width: '200px' }}>{parse(activity.completedNote ?? 'N/A')}</Typography>
          </Tooltip>
        );
      }
      return (
        <Tooltip title={activity.note ?? 'N/A'}>
          <Typography sx={{ width: '200px' }}>{parse(activity.note ?? 'N/A')}</Typography>
        </Tooltip>
      );
    },
  },
];

export enum WidgetEnum {
  activities = 'activities',
  analytics = 'analytics',
  notes = 'notes',
  assessments = 'assessments',
  careplan = 'care-plan',
  diagnosis = 'diagnosis',
  medications = 'medications',
  vitals = 'vitals',
  labs = 'labs',
  services = 'services',
  referrals = 'referrals',
}

export const cards = [
  WidgetEnum.activities,
  WidgetEnum.analytics,
  WidgetEnum.notes,
  WidgetEnum.assessments,
  WidgetEnum.careplan,
  WidgetEnum.referrals,
];
