import React, { useEffect } from 'react';
import { TextField, Grid, Autocomplete } from '@mui/material';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import CustomModal, {
  CustomModalBasicProps,
  GridItem,
  GridSection,
  gridItemColumnSize,
} from 'src/components/CustomModal';
import { WrappedPlanDefinition } from 'src/@nicheaim/fhir-base/wrappers/PlanDefinition';
import { onSuccess } from 'src/@types/crs/case';
import useObjectState from 'src/hooks/useObjectState';
import DatePickerMoment from 'src/components/DatePickerMoment';
import moment from 'moment';
import { ValueSetComposeIncludeConcept } from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources/resources';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import * as goalApiHelpers from 'src/services/api/case';
import { useSnackbar } from 'notistack';
import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import SearchMember, {
  SearchMemberProps,
  getMemberResourceTypes,
} from '../SearchMember/SearchMember';
import {
  TaskOwnerOption,
  TaskOwnerState,
  TaskOwnerValue,
  getOwnerDisplayLabel,
} from '../TasksGrid/TaskModal';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import { getValueSetConceptValue } from 'src/sections/crs/helpers/common';

export interface ActionData {
  payload: any;
  successfulMessage: string;
  actionCall: Function;
}
export interface GoalModalProps extends CustomModalBasicProps {
  pathways: ValueSetComposeIncludeConcept[];
  onSuccessfulCreation: onSuccess;
  onSuccessfulEdit: onSuccess;
  patient: WrappedPatient | null;
  goalToEdit: WrappedGoal | null;
  planDefinitions: WrappedPlanDefinition[];
  carePlan?: WrappedCarePlan | null;
  defaultPathway?: ValueSetComposeIncludeConcept;
}

export interface GoalModalState {
  selectedPlanDefinition: WrappedPlanDefinition | null;
  selectedLifeCycleStatus: GoalLifeCycleStatusOption | null;
  selectedAchievementStatus: GoalAchievementStatusOption | null;
  selectedPathway: ValueSetComposeIncludeConcept | null;
  description: string;
  comments: string;
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  isLoading: boolean;
  taskOwner: TaskOwnerValue | null;
}
export interface ErrorFieldState {
  owner?: string | null;
  lifecycleStatus?: string | null;
  category?: string | null;
  startDate?: string | null;
  description?: string | null;
  pathway?: string | null;
  endDate?: string | null;
}
const getInitialState = ({
  defaultPathway,
  goalToEdit,
  pathways,
  goalStatuses,
  goalAchievementStatuses,
}: {
  defaultPathway: ValueSetComposeIncludeConcept | null | undefined;
  goalToEdit: WrappedGoal | null | undefined;
  pathways: ValueSetComposeIncludeConcept[];
  goalStatuses: GoalLifeCycleStatusOption[];
  goalAchievementStatuses: GoalAchievementStatusOption[];
}): GoalModalState => {
  const lifecycleStatus = getValueSetConceptValue(goalStatuses ?? [], goalToEdit?.lifecycleStatus);
  const achievementStatus = getValueSetConceptValue(
    goalAchievementStatuses ?? [],
    goalToEdit?.getPlainAchievementStatus?.()
  );

  let startDate: moment.Moment | null = moment();
  let endDate: moment.Moment | null = null;

  if (goalToEdit) {
    startDate = moment(goalToEdit?.startDate ?? null).isValid()
      ? moment(goalToEdit?.startDate)
      : null;
    endDate = moment(goalToEdit?.getEndDate?.() ?? null).isValid()
      ? moment(goalToEdit?.getEndDate?.())
      : null;
  }
  const pathway = goalToEdit?.getPathway()
    ? pathways.find(({ code }) => code === goalToEdit?.getPathway())
    : null;

  return {
    startDate,
    endDate,
    selectedLifeCycleStatus: lifecycleStatus ?? null,
    selectedAchievementStatus: achievementStatus ?? null,
    selectedPathway: pathway ?? defaultPathway ?? null,
    description: goalToEdit?.getPlainDescription?.() ?? '',
    comments: goalToEdit?.getComments?.() ?? '',
    selectedPlanDefinition: null,
    isLoading: false,
    taskOwner: null,
  };
};

const initialErrorState: ErrorFieldState = {
  owner: null,
  lifecycleStatus: null,
  category: null,
  startDate: null,
  description: null,
  pathway: null,
  endDate: null,
};

const getInitialTaskOwnerState = () => ({
  isSearchMemberOpen: false,
  selectedTaskOwner: null,
  taskOwnerOptions: [...getMemberResourceTypes()],
});

const GoalModal = ({
  patient,
  onClose,
  open,
  pathways,
  defaultPathway,
  carePlan,
  onSuccessfulCreation,
  onSuccessfulEdit,
  goalToEdit,
  planDefinitions,
  ...goalModalProps
}: GoalModalProps): JSX.Element => {
  const {
    valueSets: [goalStatuses, goalAchievementStatuses],
  } = useValueSetsByIdentifiers(['crs-goal-lifecycle-status', 'crs-goal-achievement-status']);
  const { enqueueSnackbar } = useSnackbar();

  const [
    {
      selectedPlanDefinition,
      selectedLifeCycleStatus,
      selectedAchievementStatus,
      selectedPathway,
      description,
      comments,
      startDate,
      endDate,
      isLoading,
      taskOwner,
    },
    updateState,
  ] = useObjectState<GoalModalState>(
    getInitialState({
      defaultPathway,
      goalToEdit,
      pathways,
      goalStatuses: [],
      goalAchievementStatuses: [],
    })
  );

  const [{ isSearchMemberOpen, selectedTaskOwner, taskOwnerOptions }, updateTaskOwner] =
    useObjectState<TaskOwnerState>(getInitialTaskOwnerState());

  const [errors, updateErrorFieldState] = useObjectState<ErrorFieldState>(initialErrorState);

  useEffect(() => {
    if (!open) return;
    updateState(
      getInitialState({
        defaultPathway,
        goalToEdit,
        pathways,
        goalStatuses: goalStatuses?.asList?.() ?? [],
        goalAchievementStatuses: goalAchievementStatuses?.asList?.() ?? [],
      })
    );
    updateErrorFieldState(initialErrorState);
    updateTaskOwner(getInitialTaskOwnerState());
  }, [
    goalStatuses,
    goalAchievementStatuses,
    pathways,
    open,
    defaultPathway,
    goalToEdit,
    updateErrorFieldState,
    updateState,
    updateTaskOwner,
  ]);

  const handleOnSave = async () => {
    const errors: ErrorFieldState = {};
    updateErrorFieldState(initialErrorState);
    if (!selectedLifeCycleStatus) {
      errors.lifecycleStatus = 'Must select a valid status';
    }
    if (startDate && endDate && endDate?.isBefore(startDate, 'date')) {
      errors.startDate = "Start Date can't be less than End Date";
    }
    if (startDate && !startDate?.isValid()) {
      errors.startDate = 'Must specify a valid date';
    }
    if (endDate && !endDate?.isValid()) {
      errors.endDate = 'Must specify a valid date';
    }
    if (!selectedPathway) {
      errors.pathway = 'Must select a category';
    }
    if (!description) {
      errors.description = 'Must specify a description';
    }
    if (Object.keys(errors).length) {
      updateErrorFieldState({ ...errors });
      return;
    }
    updateState({ isLoading: true });
    const isCreating = !goalToEdit;
    const { payload, actionCall, successfulMessage } = getGoalPayload(isCreating);
    const response = !goalToEdit
      ? await goalApiHelpers.createGoal(payload)
      : await goalApiHelpers?.editGoal(payload, goalToEdit?.id as string);
    updateState({ isLoading: false });
    if (!response) {
      enqueueSnackbar("There's been an error. Please Try Again", { variant: 'error' });
      return;
    }
    enqueueSnackbar(successfulMessage);
    actionCall?.(response);
    onClose?.({}, 'backdropClick');
  };

  const getGoalPayload = (isCreating: boolean): ActionData => ({
    payload: {
      description,
      comments,
      categoryCode: selectedPathway?.code,
      categoryDisplay: selectedPathway?.display,
      lifecycleStatus: selectedLifeCycleStatus?.code,
      achievementStatus: selectedAchievementStatus?.code ?? null,
      achievementStatusText: selectedAchievementStatus?.display ?? null,
      startDate: startDate?.isValid() ? startDate.format('YYYY-MM-DD') : null,
      endDate: endDate?.isValid() ? endDate.format('YYYY-MM-DD') : null,
      ...(isCreating
        ? {
            patientId: patient?.id,
            patientName: patient?.getFullName?.() ?? null,
            carePlanId: carePlan?.id,
            planDefinitionId: selectedPlanDefinition?.id,
            ownerId: taskOwner?.id ? `${taskOwner?.resourceType}/${taskOwner?.id}` : undefined,
            ownerDisplay: getOwnerDisplayLabel(taskOwner) ?? null,
          }
        : {}),
    },
    actionCall: isCreating ? onSuccessfulCreation : onSuccessfulEdit,
    successfulMessage: isCreating ? 'Goal Succesfully Created' : 'Goal Successfully Modified',
  });

  return (
    <CustomModal
      open={open}
      title={!goalToEdit ? 'Add New Goal' : 'Edit Goal'}
      breadcrumbs={['List of Goals', !goalToEdit ? 'Add New Goal' : 'Edit Goal']}
      onSave={handleOnSave}
      onCancel={onClose as Function}
      isLoading={isLoading}
      containerSx={[{ overflowX: 'scroll' }, isSearchMemberOpen ? { width: '94vw' } : {}]}
      {...goalModalProps}
    >
      <Grid container my={3} justifyContent={'space-between'}>
        <Grid item xs={isSearchMemberOpen ? 6 : 12}>
          {!!patient && (
            <GridSection mt={0}>
              <GridItem>
                <TextField
                  fullWidth
                  label={'Organization'}
                  defaultValue={patient?.getOrganization()}
                  type="text"
                  placeholder="Organization"
                  variant="outlined"
                  disabled
                />
              </GridItem>
              <GridItem>
                <TextField
                  fullWidth
                  label={'Patient Name'}
                  defaultValue={patient?.getFullName()}
                  type="text"
                  placeholder="Patient Name"
                  variant="outlined"
                  disabled
                />
              </GridItem>
            </GridSection>
          )}
          {!!!goalToEdit && (
            <GridSection>
              <GridItem xs={selectedPlanDefinition ? gridItemColumnSize : 12}>
                <Autocomplete
                  disabled={isLoading}
                  value={selectedPlanDefinition}
                  fullWidth
                  onChange={(_: React.SyntheticEvent, planDefinition) => {
                    let proposedPathway = pathways.find(
                      ({ code }) => code === planDefinition?.goalCategory
                    );
                    updateState({
                      selectedPlanDefinition: planDefinition,
                      ...(planDefinition?.goalDescription
                        ? { description: planDefinition?.goalDescription as string }
                        : {}),
                      ...(proposedPathway
                        ? { selectedPathway: proposedPathway as ValueSetComposeIncludeConcept }
                        : {}),
                    });
                  }}
                  options={planDefinitions}
                  getOptionLabel={({ goalDescription }: WrappedPlanDefinition) =>
                    goalDescription ?? ''
                  }
                  renderInput={(params) => (
                    <TextField {...params} label={'Plan Definition'} variant="outlined" />
                  )}
                />
              </GridItem>
              {!!selectedPlanDefinition && (
                <GridItem>
                  <Autocomplete
                    fullWidth
                    disabled={isLoading}
                    value={selectedTaskOwner}
                    onChange={(_: React.SyntheticEvent, taskOwnerOption) => {
                      const optionAsValue = taskOwnerOption as TaskOwnerValue;
                      const optionAsOption = taskOwnerOption as TaskOwnerOption;
                      if (
                        !optionAsValue?.resourceType &&
                        optionAsOption?.value !== 'Patient' &&
                        optionAsOption
                      ) {
                        updateTaskOwner({
                          isSearchMemberOpen: true,
                          selectedTaskOwner: optionAsOption,
                        });
                        return;
                      }
                      if (optionAsOption?.value === 'Patient') {
                        updateTaskOwner({ selectedTaskOwner: patient });
                        updateState({ taskOwner: patient });
                        return;
                      }
                      updateTaskOwner({ selectedTaskOwner: null });
                      updateState({ taskOwner: null });
                    }}
                    options={taskOwnerOptions}
                    getOptionLabel={(option) => {
                      const optionAsValue = option as TaskOwnerValue;
                      const optionAsOption = option as TaskOwnerOption;

                      if (!optionAsValue?.resourceType) return optionAsOption?.label as string;
                      let display = getOwnerDisplayLabel(optionAsValue);
                      if (optionAsValue?.resourceType === 'Patient') {
                        display = `${display} - Current Patient`;
                      }

                      return display;
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={'Task Owner'} variant="outlined" />
                    )}
                  />
                </GridItem>
              )}
            </GridSection>
          )}
          <GridSection>
            <GridItem>
              <Autocomplete
                disabled={isLoading}
                value={selectedLifeCycleStatus}
                fullWidth
                onChange={(_: React.SyntheticEvent, newValue) => {
                  if (newValue)
                    updateErrorFieldState({
                      lifecycleStatus: null,
                    });
                  updateState({ selectedLifeCycleStatus: newValue });
                }}
                options={goalStatuses?.asList?.() ?? []}
                getOptionLabel={({ display }: GoalLifeCycleStatusOption) => display ?? ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.lifecycleStatus}
                    helperText={errors.lifecycleStatus}
                    label={'Status'}
                    variant="outlined"
                  />
                )}
              />
            </GridItem>
            <GridItem>
              <Autocomplete
                disabled={isLoading}
                value={selectedAchievementStatus}
                fullWidth
                onChange={(_: React.SyntheticEvent, newValue) => {
                  updateState({
                    selectedAchievementStatus: newValue,
                  });
                }}
                options={goalAchievementStatuses?.asList?.() ?? []}
                getOptionLabel={({ display }: GoalAchievementStatusOption) => display ?? ''}
                renderInput={(params) => (
                  <TextField {...params} label={'Achievement Status'} variant="outlined" />
                )}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <GridItem>
              <DatePickerMoment
                disabled={isLoading}
                error={errors.startDate}
                label={'Start Date'}
                value={startDate}
                onChange={(value) => {
                  if (value?.isValid?.() || value === null) {
                    updateErrorFieldState({
                      startDate: null,
                    });
                  }
                  updateState({
                    startDate: value,
                  });
                }}
              />
            </GridItem>
            <GridItem>
              <DatePickerMoment
                disabled={isLoading}
                error={errors.endDate}
                label={'End Date'}
                value={endDate}
                onChange={(value) => {
                  if (value?.isValid?.() || value === null) {
                    updateErrorFieldState({
                      endDate: null,
                    });
                  }
                  updateState({
                    endDate: value,
                  });
                }}
              />
            </GridItem>
          </GridSection>
          <GridSection>
            <Autocomplete
              disabled={isLoading}
              value={selectedPathway}
              fullWidth
              onChange={(_: React.SyntheticEvent, newValue) => {
                if (newValue) {
                  updateErrorFieldState({ pathway: null });
                }
                updateState({
                  selectedPathway: newValue,
                });
              }}
              options={pathways}
              getOptionLabel={({ display }: ValueSetComposeIncludeConcept) => display as string}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.pathway}
                  helperText={errors.pathway}
                  label={'Category'}
                  variant="outlined"
                />
              )}
            />
          </GridSection>
          <GridSection>
            <TextField
              fullWidth
              disabled={isLoading}
              value={description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = event.target;
                if (value.trim().length) {
                  updateErrorFieldState({ description: null });
                }
                updateState({
                  description: value,
                });
              }}
              label={'Description'}
              placeholder="Description"
              variant="outlined"
              error={!!errors.description}
              helperText={errors.description}
            />
          </GridSection>
          <GridSection>
            <TextField
              fullWidth
              disabled={isLoading}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                updateState({
                  comments: event.target.value,
                });
              }}
              value={comments}
              label={'Comments'}
              placeholder="Comments"
              variant="outlined"
            />
          </GridSection>
        </Grid>
        {isSearchMemberOpen && (
          <Grid item xs={5.9}>
            <SearchMember
              patient={patient as WrappedPatient}
              onSelectResource={(resource) => {
                updateTaskOwner({ selectedTaskOwner: resource, isSearchMemberOpen: false });
                updateState({ taskOwner: resource });
              }}
              onClear={() => {
                updateTaskOwner({ selectedTaskOwner: null, isSearchMemberOpen: false });
                updateState({ taskOwner: null });
              }}
              externalResourceType={(() => {
                const selectedOption = selectedTaskOwner as TaskOwnerOption;
                return selectedOption?.value as SearchMemberProps['externalResourceType'];
              })()}
            />
          </Grid>
        )}
      </Grid>
    </CustomModal>
  );
};

export type GoalLifeCycleStatusOption = ValueSetComposeIncludeConcept;
export type GoalAchievementStatusOption = ValueSetComposeIncludeConcept;

export default GoalModal;
