import { Navigate, Route, Routes } from 'react-router';
import EditProgram from './edit';
import ProgramPatientsIndex from './patients';

export default function ProgramIndex() {
  return (
    <Routes>
      <Route index element={<Navigate to="patients" replace />} />
      <Route path="edit" element={<EditProgram />} />
      <Route path="patients/*" element={<ProgramPatientsIndex />} />
    </Routes>
  );
}
