import Cookies from 'js-cookie';
import { ApiConfiguration } from 'src/api/clients/api_client';
import ApiClient from 'src/api/clients/axios_client';
import FullPatientService, { FullPatientApiClient } from './FullPatientService';

const accessToken = Cookies.get('careflowToken');
const baseUrl = process.env.REACT_APP_HOST_API;

const apiConfig = new ApiConfiguration();
apiConfig.accessToken = accessToken;
apiConfig.baseUrl = baseUrl ? baseUrl : '';

const fullPatientsApiClient = new FullPatientApiClient(new ApiClient(apiConfig));
export const fullPatientService = new FullPatientService(fullPatientsApiClient);
