import {
  Container,
  Grid,
  Box,
  Modal,
  useTheme,
  TextField,
  InputAdornment,
  Avatar,
  Typography,
} from "@mui/material";
import Breadcrumbs from 'src/components/Breadcrumbs';
import { useEffect, useMemo } from "react";
import useObjectState from "src/hooks/useObjectState";
// import { Filters } from "src/sections/careflow/census/components/Filters/Filters";
import Page from "src/components/Page";
import { BedPaginationType } from "src/services/api/census";
import { UserManager } from "src/@types/user";
import { EntityContextMenu, SummaryTable } from "src/sections/careflow/common";
import Label from "src/components/Label";
import Iconify from "src/components/Iconify";
import _ from 'lodash';
import EditUserOrganizationModal from "./components/EditUserOrganizationModal";
import { useUsersPaginate } from "src/services/api/users";
import api from "src/services/api";
import { usePractitionerRoles } from "src/@nicheaim/fhir-react";

type UsersOrganizationSettingsStateType = {
  selectedUser: any;
  showEditUserModal: boolean;
  searchValue?: string;
  locationFhirId: string | null;
  pagination: BedPaginationType;
  users: UserManager[];
}

const UsersOrganizationSettings = () => {
  const [{
    users,
    selectedUser,
    showEditUserModal,
    searchValue,
    pagination,
  }, updateState] = useObjectState<UsersOrganizationSettingsStateType>({
    users: [],
    showEditUserModal: false,
    selectedUser: null,
    locationFhirId: null,
    pagination: {
      take: 10,
      skip: 0,
    },
  });

  const [practitionerRoles] = usePractitionerRoles({
    filter: {
      practitioner: users?.map((u: any) => u?.fhirUri).join(',') ?? '',
      active: true,
    }
  });

  const theme = useTheme();

  const onSaveEntity = (user: UserManager) => {
  };

  const onChangeSearch = _.debounce((e: any) =>
    updateState({
      searchValue: e.target.value,
    }), 400);

  const openEditUser = (user: UserManager | null) => {
    updateState({
      selectedUser: user,
      showEditUserModal: true,
    })
  };

  const closeEditUser = () => {
    updateState({
      selectedUser: null,
      showEditUserModal: false,
    })
  };

  useEffect(() => {
    updateState({ pagination: { take: 10, skip: 0 } });
    api.users.getUsersListPaginate({
      take: 10,
      skip: 0,
      search: searchValue,
    }).then((response) => updateState({
      users: response,
    }))
  }, [searchValue])

  return (
    <Page title="Manage Users Organization">
      <Modal
        open={showEditUserModal}
        onClose={closeEditUser}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: 0,
            width: 400,
            height: '100%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <EditUserOrganizationModal
            user={selectedUser}
            // TODO: declare better, tried to hot fix.
            onSaveCallback={(role: any) => {
              onSaveEntity(role);
            }}
            onCancelPress={closeEditUser}
          />
        </Box>
      </Modal>
      <Container maxWidth={'xl'}>
        <Grid container spacing={3} sx={{ marginBottom: 2 }}>
          <Grid item xs={12}>
            <Breadcrumbs
              title="Users Organization Management"
              links={[
                {
                  href: '/dashboard',
                  name: 'Dashboard',
                },
                {
                  href: '',
                  name: 'Settings',
                },
                {
                  href: '',
                  name: 'Users Organization Management',
                }
              ]}
            />
          </Grid>
        </Grid>
        <SummaryTable
          title="Users"
          data={users || []}
          fetchMore={() => {
            updateState({ pagination: { ...pagination, skip: pagination.skip + pagination.take } });
            api.users.getUsersListPaginate({
              take: pagination.take,
              skip: pagination.skip,
              search: searchValue,
            }).then((response) => updateState({
              users: [...users, ...response],
            }))
          }}
          columns={[
            {
              header: 'Profile',
              field: '$',
              render(user: UserManager) {
                return <Avatar alt={user.userName} src={user.photo} sx={{ mr: 2 }} />
              }
            },
            {
              header: 'First Name',
              field: '$',
              render(user: UserManager) {
                return  <Typography>{`${user.firstName} ${user.middleName} ${user.lastName}`}</Typography>;
              }
            },
            {
              header: 'Email',
              field: '$',
              render(user: UserManager) {
                return  <Typography>{`${user.userName}`}</Typography>;
              }
            },
            {
              header: 'Organization',
              field: '$',
              render(user: UserManager) {
                return <Typography>{practitionerRoles?.filter(i => i.practitioner?.reference === (user as any)?.fhirUri)?.map((pr) => pr.organization?.display ?? 'Untitled').join(', ') ?? 'No Organization'}</Typography>
              }
            },
            {
              header: 'Status',
              field: '$',
              render(user: UserManager) {
                return (
                  <Label
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={(user.status === 'Inactive' && 'error') || 'success'}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    <Typography>{user.status}</Typography>
                  </Label>
                )
              }
            }
          ]}
          rowContextMenu={(user: UserManager) => (
            <EntityContextMenu
              entity={user}
              items={[
                {
                  icon: 'eva:edit-2-outline',
                  label: 'Edit Organization',
                  onPress: () => openEditUser(user),
                }
              ]}
            />
          )}
          actionBar={(
            <TextField
              // value={searchValue}
              onChange={onChangeSearch}
              placeholder="Search user..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon={'eva:search-fill'}
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Container>
    </Page>
  );
}

export default UsersOrganizationSettings;

