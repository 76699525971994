import { Box, Typography } from '@mui/material';
import { useQuestionnaires } from 'src/@nicheaim/fhir-react';
import {
  QuestionnaireWrapper,
  WrappedQuestionnaire,
  WrappedQuestionnaireItem,
} from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import useCarePlanAutomationFormStore from './store';
import AutomationSheetSelector from './AutomationSheetSelector';
import RecoverUnsavedChanges from './RecoverUnsavedChanges';
import FormActions from './FormActions';
import Question from './Question';
import DetectExternalRuleChanges from './DetectExternalRuleChanges';
import { useActiveAssessments, useAllAssessments } from 'src/fhir/hooks/assessments';

function CarePlanAutomationForm() {
  const [questionnaires] = useAllAssessments({ map: QuestionnaireWrapper });
  const questionnaireId = useCarePlanAutomationFormStore((s) => s.selectedAssessmentId);
  const questionnaire = questionnaires.find((q) => q.id === questionnaireId);

  return (
    <Box sx={{ p: 3 }}>
      <DetectExternalRuleChanges />
      <RecoverUnsavedChanges />
      <FormActions />
      <AutomationSheetSelector />

      {questionnaire && (
        <Box>
          <Typography variant="h6" sx={{ mt: 3 }}>
            Questions
          </Typography>

          <Box>
            {questionnaire.item?.map((question) => (
              <Question key={question.id!} question={question as WrappedQuestionnaireItem} /> // TODO: fix type
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default CarePlanAutomationForm;
