import Cookies from 'js-cookie';
import { isValidToken } from '../jwt';
import {
  newPermissionsContainer,
  NO_ALLOWING_CONTAINER,
  PermissionsContainer,
} from './permissions.container';
import { DEVELOPMENT_CONFIG, HOST_API } from '../../config';
import { getAclsFromStorage } from 'src/services/api/permissions';

export function getUserPermissions(): PermissionsContainer {
  const careflowToken = Cookies.get('careflowToken');
  if (careflowToken && isValidToken(careflowToken)) {
    const acls = getAclsFromStorage();

    return newPermissionsContainer(acls, (dat) => dat);
  } else {
    return NO_ALLOWING_CONTAINER;
  }
}

async function logout() {
  const logoutResult = await fetch(`${HOST_API}/auth/logout-no-redirect`, {
    method: 'post',
    body: new URLSearchParams({ refreshToken: Cookies.get('refreshToken') || '' }),
  });
  const redirectUrl = (await logoutResult?.json())?.redirectTo;
  sessionStorage.setItem('sessionExpired', 'true');
  window.location.href = redirectUrl;
}

export async function checkPermissions() {
  if (!DEVELOPMENT_CONFIG.disableTokenCheck && getUserPermissions() === NO_ALLOWING_CONTAINER) {
    console.log('checkPersmissions logout');
    await logout();
  }
}
