import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import Iconify from 'src/components/Iconify';

interface ActionBarProps {
  onAdd?: () => void;
  onCollapse?: () => void;
  onExpand?: () => void;
  onFind?: () => void;
  custom?: ReactElement;
}

const ActionBar = ({ onAdd, onCollapse, onExpand, onFind, custom }: ActionBarProps) => (
  <>
    {onAdd && (
      <IconButton onClick={onAdd}>
        <Iconify icon="eva:plus-fill" />
      </IconButton>
    )}
    {onFind && (
      <IconButton onClick={onFind}>
        <Iconify icon="eva:search-fill" />
      </IconButton>
    )}
    {onExpand && (
      <IconButton onClick={onExpand}>
        <Iconify icon="eva:expand-fill" />
      </IconButton>
    )}
    {onCollapse && (
      <IconButton onClick={onCollapse}>
        <Iconify icon="eva:collapse-fill" />
      </IconButton>
    )}
    {custom}
  </>
);

export default ActionBar;
