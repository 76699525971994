// @ts-nocheck
// please remove // @ts-nocheck comments to work with these file
// and fix the issues
import { useEffect, useMemo } from 'react';
import moment from 'moment';
import useObjectState from '../../../../../../hooks/useObjectState';
import {
  Divider,
  Box,
  Button,
  Grid,
  Typography,
  InputLabel,
  FormControl,
  Autocomplete,
  TextField,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Stack,
} from '@mui/material';
import {
  defaultStateFieldsValues,
  REFERRAL_CREATE_ERROR_MESSAGE,
  ReferralStateI,
  ReferralStatuses,
} from '../constants';
import API from 'src/services/api';
import PatientCard from 'src/sections/careflow/common/PatientCard';
import {
  useDocumentReferences,
  useCommunications,
  useOrganizations,
  useHealthcareServices,
  useHealthcareService,
  useServiceRequest,
  useOrganization,
  useBinarys,
  usePractitionerRoles,
} from 'src/@nicheaim/fhir-react';
import useAuth from 'src/hooks/useAuth';
import ReferralViewModel from '../ViewModel/ReferralViewModel';
import { ServiceRequestWrapper } from '../../../../../../@nicheaim/fhir-base/wrappers/ServiceRequest';
import { HealthcareServiceWrapper } from '../../../../../../@nicheaim/fhir-base/wrappers/HealthcareService';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Iconify from '../../../../../../components/Iconify';
import { FormProvider } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import _, { isArray, isString } from 'lodash';
import { LoadingButton } from '@mui/lab';
import ReactQuill from 'react-quill';
import parse from 'html-react-parser';
import { config } from 'src/config';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import PatientSearchDropdown from 'src/sections/careflow/common/PatientSearchDropdown';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { CCM_ACLS, checkAclValidation, getRelatedAcls } from 'src/utils/permissions/permission.utils';
import { isOnServiceProviderRole } from '../../../utils/roles';
import { Practitioner } from 'src/@nicheaim/fhir-base/mappings/Practitioner';
import { ContactPoint } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

const getIdFromReference = (reference: string): string =>
  reference ? reference?.split('/')?.[1] : '';

interface ServiceTypePayload {
  label: string;
  value: { type: string; healthcareService: any; organizationId: string };
}

interface Option {
  label: string;
  value: string;
}

interface ReferralPayload {
  id: number | null;
  date: string;
  start: string;
  end: string;
  status: string;
  patientId: string | null;
  assignedTo: Option | null;
  referredFrom: string;
  referredFromFreeText: boolean;
  referredTo: string;
  referredToFreeText: boolean;
  note: string | null;
  services: ServiceTypePayload[] | null;
  service?: ServiceTypePayload | null;
  authUserEmail?: string;
}

export type AddReferralPropsType = {
  onSaveCallback: (referral: ReferralViewModel) => void;
  onSaveMultipleCallback: (referrals: ReferralViewModel[]) => void;
  referral?: ReferralViewModel | null | undefined;
  patient?: {
    fhirId: string | undefined;
  };
};

const getFullName = (practitioner: Practitioner): string | null => {
  let fullName = null;
  if (practitioner.name?.[0]?.given?.[0] && practitioner.name?.[0]?.family) {
    fullName = `${practitioner.name?.[0]?.given?.[0]} ${practitioner.name?.[0]?.family}`;
  }
  return fullName;
}

const getPrimaryEmail = (practitioner: Practitioner): ContactPoint | null => {
  let mainPhone = null;
  if (practitioner.telecom && practitioner.telecom?.length > 0) {
    const phones = practitioner.telecom.filter((t) => t?.system === 'email');
    if (phones && phones?.length > 0) {
      mainPhone = phones[0];
    }
  }
  return mainPhone;
}

const AddReferral = (props: AddReferralPropsType) => {
  const { referral: propReferral, patient } = props;
  const authUser = useAuth().getCurrentUser();

  const theme = useTheme();

  const useHelperTextStyles = makeStyles({
    root: {
      color: theme.palette.error.main,
    },
  });

  const helperTextStyles = useHelperTextStyles();

  const [practitionerRoles] = usePractitionerRoles({
    filter: {
      practitioner: getFhirIdFromEntity(authUser?.user_fhir_uri),
      active: true,
    }
  });

  const organizationIds = useMemo(() => 
    practitionerRoles?.map(o => getFhirIdFromEntity(o.organization?.reference!) ?? '') ?? []
  , [practitionerRoles])

  const [usersPractitionerRoles] = usePractitionerRoles({
    autofetch: organizationIds.length > 0 ? true : false,
    filter: {
      'organization': organizationIds.join(','),
      active: true,
      '_include': 'PractitionerRole:practitioner'
    }
  });

  const [communications, { refresh: refreshCommunications, update: updateCommunication }] =
    useCommunications({
      autofetch: propReferral?.fhirId ? true : false,
      filter: {
        'part-of': propReferral?.fhirId,
      },
    });

  const [state, updateState] = useObjectState(
    defaultStateFieldsValues(authUser, propReferral)
  );

  const {
    date,
    startHour,
    endHour,
    name,
    serviceTypeItem,
    serviceTypeItems,
    referredFromItem,
    referredFromFreeText,
    referredToItem,
    referredToFreeText,
    status,
    statusItem,
    assignedToItem,
    file,
    selectedFile,
    note,
    isLoading,
    errors,
    success,
    patientItem,
  } = state;

  const methods = useForm({});

  const { handleSubmit } = methods;

  const availableStatuses = useMemo(() => {
    const allStatuses = Object.entries(ReferralStatuses).map((current) => ({
      label: current[1],
      value: current[0],
    }));

    if (isOnServiceProviderRole(authUser) && !propReferral?.id) {
      return Object.entries(ReferralStatuses).filter(current => current[1] === ReferralStatuses.draft).map((current) => ({
        label: current[1],
        value: current[0],
      }));
    }

    if (
      isOnServiceProviderRole(authUser) &&
      propReferral?.id &&
      practitionerRoles.find(p => getFhirIdFromEntity(p.organization?.reference ?? '') === propReferral.referredFromOrganizationId)
    ) {
      return Object.entries(ReferralStatuses).filter(current => current[1] === ReferralStatuses.draft || current[1] === ReferralStatuses['entered-in-error']).map((current) => ({
        label: current[1],
        value: current[0],
      }));
    }

    return allStatuses;
  }, [authUser, practitionerRoles, propReferral]);

  const showAssignedToInput = useMemo(() => {
    if (
      !isOnServiceProviderRole(authUser)
    ) {
      return true;
    }

    if (isOnServiceProviderRole(authUser)) {
      return false;
    }

    return true;
  }, [authUser])

  const [requesterOrganizations] = useOrganizations({
    filter: {
      type: 'referral-requester-organizations,external-and-requester-organizations',
    },
    pagination: {
      pageSize: 1000,
    },
  });

  const [, { create: createBinary }] = useBinarys({
    autofetch: false,
  });

  const [providerOrganizations] = useOrganizations({
    // filter: {
    //   '_has:HealthcareService:organization:id': providerOrganizationsFilter,
    // },
    filter: {
      type: 'external-service-provider,external-and-requester-organizations',
    },
    pagination: {
      pageSize: 1000,
    },
  });

  const providerOrganizationsList = useMemo(
    () =>
      providerOrganizations
        .map((group: any) => ({
          label: group.name,
          value: `${group.resourceType}/${group.id}`,
        }))
        .sort((x1, x2) => (x1.label > x2.label ? 1 : x1.label < x2.label ? -1 : 0)) ?? [],
    [providerOrganizations]
  );

  const requesterOrganizationsList = useMemo(
    () =>
      _.sortBy(
        requesterOrganizations.map((group: any) => ({
          label: group.name,
          value: `${group.resourceType}/${group.id}`,
        })),
        'label'
      ) ?? [],
    [requesterOrganizations]
  );

  const userListWithNames = useMemo(
    () =>
      usersPractitionerRoles.filter(p => p.resourceType === 'Practitioner')
        .map((practitioner: Practitioner) => ({
          label: getFullName(practitioner),
          value: getPrimaryEmail(practitioner)?.value,
        })) ?? [],
        //.sort((x1, x2) => (x1.label > x2.label ? 1 : x1.label < x2.label ? -1 : 0)) ?? [],
    [usersPractitionerRoles]
  );

  const usersAndRequesterGroupsList = useMemo(
    () => {
      if (isOnServiceProviderRole(authUser)) {
        return practitionerRoles.map(({ organization }: any) => ({
          label: organization?.display,
          value: organization?.reference,
        })).sort((x1, x2) => (x1.label > x2.label ? 1 : x1.label < x2.label ? -1 : 0))
      }

      return requesterOrganizationsList
        // .concat(requesterOrganizationsList)
        .sort((x1, x2) => (x1.label > x2.label ? 1 : x1.label < x2.label ? -1 : 0))
    },
    [requesterOrganizationsList]
  );

  const [, { create: createDocumentReference }] = useDocumentReferences({
    autofetch: false,
  });

  const [documentReferences] = useDocumentReferences({
    filter: {
      related: propReferral?.fhirId,
      autofetch: propReferral?.fhirId ? true : false,
    },
    pagination: {
      pageSize: 10,
    },
  });

  const lastAttachment = useMemo(() => {
    const lastDocumentReference = documentReferences?.[0];
    if (lastDocumentReference?.content?.[0]?.attachment) {
      console.log('DEBUG lastDocumentReference: ', lastDocumentReference);
      return lastDocumentReference?.content?.[0]?.attachment;
    }
    return null;
  }, [documentReferences]);

  

  const fetchServices = propReferral ? false : true;

  const [healthcareServices] = useHealthcareServices({
    // pagination: {
    //   pageSize: 1000, 
    // },
    filter: {
      _count: !fetchServices ? 0 : 1000,
    }
  });

 

  const [existingServiceRequest] = useServiceRequest(propReferral?.fhirId, {
    map: ServiceRequestWrapper,
  });

  const existingHealthcareServiceReference = useMemo(() => existingServiceRequest?.performer?.find(i => i.type === 'HealthcareService') ?? null, [existingServiceRequest]);

  const [existingHealthcareService] = useHealthcareService(
    getIdFromReference(existingHealthcareServiceReference?.reference ?? '') ?? null,
    { map: HealthcareServiceWrapper }
  );

  const [existingServiceOrg] = useOrganization(
    getIdFromReference(existingHealthcareService?.providedBy?.reference ?? '') ?? null
  );

  const existingService = useMemo(() => {
    const type = existingServiceRequest?.code?.coding?.[0]?.code;
    if (
      existingHealthcareService &&
      existingHealthcareService?.type &&
      existingServiceOrg &&
      type
    ) {
      return {
        label: `${type} | ${existingServiceOrg?.name}`,
        value: {
          type: type,
          healthcareService: existingHealthcareService,
          organizationId: existingServiceOrg?.id,
        },
      };
    } else if (
      existingHealthcareServiceReference?.display &&
      existingHealthcareServiceReference?.type === 'HealthcareService' &&
      !existingHealthcareServiceReference?.reference
    ) {
      return existingHealthcareServiceReference?.display;
    }
    return null;
  }, [
    existingHealthcareServiceReference,
    existingHealthcareService,
    existingServiceOrg,
    existingServiceRequest,
  ]);

  useEffect(() => {
    console.log('DEBUG existingServiceRequest: ', existingServiceRequest);
  }, [existingServiceRequest]);

  useEffect(() => {
    console.log('DEBUG existingHealthcareServiceReference: ', existingHealthcareServiceReference);
  }, [existingHealthcareServiceReference]);

  useEffect(() => {
    console.log('DEBUG existingService: ', existingService);
    if (existingService) {
      updateState({ serviceTypeItem: existingService });
    }
  }, [existingService]);

  useEffect(() => {
    if (propReferral?.fhirId) {
      refreshCommunications();
    }
  }, [propReferral?.fhirId]);

  const referralServiceTypes = useMemo(
    () =>
      _.sortBy(
        healthcareServices
          .filter((h) => h?.type?.[0]?.coding?.[0]?.code && h?.id && h?.providedBy?.display)
          .map((h) => ({
            label: `${h?.type?.[0]?.coding?.[0]?.display || h?.type?.[0]?.coding?.[0]?.code} | ${h?.providedBy?.display}`,
            value: {
              type: h?.type?.[0]?.coding?.[0]?.display || h?.type?.[0]?.coding?.[0]?.code,
              healthcareService: h,
              organizationId: h?.providedBy?.reference
                ? getIdFromReference(h?.providedBy?.reference)
                : null,
            },
          })),
        'value.type'
      ),
    [healthcareServices]
  );

  const onClearAllPress = () => {
    updateState(defaultStateFieldsValues(authUser, null));
  };

  const validateRequiredFields = () => {
    if (
      !date ||
      name ||
      !referredToItem?.value ||
      !status ||
      (propReferral?.id && !serviceTypeItem) ||
      (!propReferral?.id && !serviceTypeItems)
    ) {
      return false;
    }

    if (
      !isOnServiceProviderRole(authUser)
      && !assignedToItem?.value
    ) {
      return false;
    }

    return true;
  };

  const onSavePress = async () => {
    console.log('DEBUG: onSavePress props: ', props);
    updateState({
      isLoading: true,
    });
    if (!validateRequiredFields()) {
      updateState({
        isLoading: false,
        errors: ['Please, fill all required fields'],
        success: null,
      });
      console.log('DEBUG: Please, fill all required fields');
      return;
    }

    let servicesPayload: any = null;
    if (serviceTypeItems?.length > 0) {
      servicesPayload = serviceTypeItems.map((s: any) => (isString(s) ? s : s.value));
    } else if (propReferral?.id && serviceTypeItem) {
      servicesPayload = [isString(serviceTypeItem) ? serviceTypeItem : serviceTypeItem.value];
    }

    let assignedTo: Option | null = assignedToItem;

    if (isOnServiceProviderRole(authUser)) {
      assignedTo = propReferral ? { label: propReferral.assignedToName, value: propReferral.assignedToName } : null;
    }
    const payload: ReferralPayload = {
      id: propReferral?.id ?? null,
      date,
      start: startHour,
      end: endHour,
      status: statusItem?.value,
      patientId: patient ? patient?.fhirId : patientItem?.value,
      assignedTo: assignedTo,
      referredFrom: referredFromItem?.value,
      referredFromFreeText: !!referredFromFreeText,
      referredTo: referredToItem?.value,
      referredToFreeText: !!referredToFreeText,
      note,
      services: servicesPayload,
      authUserEmail: authUser.email,
      service: null, //serviceTypeItem ? serviceTypeItem.value : null,
    };

    handleSaveReferral(payload);
  };

  const handleSaveReferral = async (payload: ReferralPayload) => {
    console.log('DEBUG handleSaveReferral payload: ', payload);
    const { onSaveCallback, onSaveMultipleCallback } = props;

    if (propReferral?.id) {
      const updatedReferral = await updateReferral(payload);
      if (updatedReferral) {
        if (selectedFile) {
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64 = reader.result?.toString()?.replace(/^data:(.*,)?/, '');
            const newAttachment = await createAttachment(selectedFile, base64, [updatedReferral]);
            if (!newAttachment) {
              errorReferralCreation(['Error uploading file.']);
              return;
            }

            completeReferralCreation(() => onSaveCallback(updatedReferral));
          };
          reader.onerror = () => {
            errorReferralCreation(['Error reading the selected file']);
          };
          // reader.readAsDataURL(selectedFile); // TODO: fix parameter
        } else {
          completeReferralCreation(() => onSaveCallback(updatedReferral));
        }
      } else {
        errorReferralCreation([REFERRAL_CREATE_ERROR_MESSAGE]);
      }
    } else {
      try {
        const newReferrals = await createReferral(payload);
        if (selectedFile) {
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64 = reader.result?.toString()?.replace(/^data:(.*,)?/, '');
            if (newReferrals) {
              const newAttachment = await createAttachment(selectedFile, base64, newReferrals);
              if (!newAttachment) {
                errorReferralCreation(['Error uploading file.']);
                return;
              }

              completeReferralCreation(() => onSaveMultipleCallback(newReferrals));
            } else {
              errorReferralCreation([REFERRAL_CREATE_ERROR_MESSAGE]);
            }
          };
          reader.onerror = () => {
            errorReferralCreation(['Error reading the selected file']);
          };
          // reader.readAsDataURL(selectedFile); // TODO: fix parameter
        } else {
          completeReferralCreation(() => onSaveMultipleCallback(newReferrals));
        }
      } catch (e) {
        if (e?.response?.data?.response?.message) {
          const errorMessage = e.response.data.response.message;
          errorReferralCreation(errorMessage);
        } else {
          errorReferralCreation([REFERRAL_CREATE_ERROR_MESSAGE]);
        }
      }
    }
  };

  const updateReferral = async (payload: any) => {
    if (!propReferral?.fhirId) {
      return;
    }

    const id = Number(propReferral?.fhirId);
    return API.referrals.updateCcmReferral(id, payload);
  }

  const createReferral = async (payload: any) => API.referrals.saveCcmReferral(payload);

  const createAttachment = async (selectedFile: any, base64: any, relatedReferrals: any) => {
    const referralReferences = relatedReferrals.map((r: any) => ({
      reference: `ServiceRequest/${r?.fhirId}`,
      type: 'ServiceRequest',
    }));

    let newBinary = await createBinary({
      resourceType: 'Binary',
      data: base64,
      contentType: `application/${selectedFile?.name.split('.').pop()}`// selectedFile?.type,
    })

    if (!newBinary) return;

    const attachmentPayload: any = {
      resourceType: 'DocumentReference',
      status: 'current',
      type: {
        coding: [
          {
            code: 'referral',
            display: 'Referral',
          },
        ],
      },
      context: {
        related: referralReferences,
      },
      subject: {
        reference: `Patient/${propReferral?.patientFhirId ?? (patient?.fhirId ?? patientItem?.value)}`,
        type: 'Patient',
      },
      author: [
        {
          reference: authUser.user_fhir_uri,
          type: 'Practitioner',
        },
      ],
      date: moment().format('YYYY-MM-DDTHH:MM:ssZ'),
      content: [
        {
          attachment: {
            // contentType: `application/${selectedFile?.name.split('.').pop()}`,
            // size: selectedFile?.size,
            // data: base64,
            title: selectedFile.name,
            url: `${config.fhirServerUrl}/Binary/${newBinary?.[0]?.id}`
          },
        },
      ],
    };

    return createDocumentReference(attachmentPayload);
  };

  const completeReferralCreation = (onSaveCallback: () => void) => {
    updateState({
      errors: [],
      success: 'Referral saved successfully',
      isLoading: false,
    });
    setTimeout(() => {
      onSaveCallback();
    }, 1000);
  };

  const errorReferralCreation = (messages: string[]) => {
    updateState({
      errors: messages,
      isLoading: false,
      success: null,
    });
  };

  const handleDownloadAttachmentFromBinary = async () => {
    const binaryId = lastAttachment?.url?.split('/').pop();

    const { data } = await axiosFhirInstance.get(`/Binary/${binaryId}`);

    if (!data) return;

    var element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:${lastAttachment?.contentType};base64,${data.data}`
    );
    element.setAttribute('download', `filename.${data.contentType?.split('/')[1]}`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const handleDownloadAttachmentFromContent = async () => {
    if (lastAttachment) {
      const element = document.createElement('a');
      element.setAttribute(
        'href',
        `data:${lastAttachment?.contentType};base64,${lastAttachment?.data}`
      );
      element.setAttribute('download', `filename.${lastAttachment.contentType?.split('/')[1]}`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  };

  const downloadAttachment = async () => {
    if (lastAttachment && lastAttachment?.url) {
      await handleDownloadAttachmentFromBinary()
    } else if (lastAttachment) {
      await handleDownloadAttachmentFromContent();
    }
  }

  const [{ editingNoteId, savingEditedNote }, setEditingNote] = useObjectState({
    editingNoteId: '',
    savingEditedNote: false,
  });

  const handleSaveNoteChanges = async () => {
    setEditingNote({
      savingEditedNote: true,
    });
    const communication = communications.find((c) => c?.id === editingNoteId);

    if (!communication || !note) {
      return;
    }

    const payload = {
      ...communication,
      note: [
        {
          text: note,
        },
      ],
    };

    await updateCommunication(payload);
    await refreshCommunications();
    setEditingNote({
      editingNoteId: '',
      savingEditedNote: false,
    });
    updateState({ note: '' });
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSavePress)}>
      <Box
        sx={{
          position: 'absolute' as 'absolute',
          top: 0,
          width: 400,
          height: '100%',
          p: 1,
          overflowY: 'scroll',
        }}
      >
        <form>
          <Box
            sx={{
              my: 3,
              position: 'fixed',
              width: '400px',
              backgroundColor: 'white',
              opacity: 1,
              zIndex: 9,
              top: '-25px',
            }}
          >
            <Box sx={{ my: 3 }}>
              <Grid item>
                <PatientCard id={patient?.fhirId || ''} />
              </Grid>
            </Box>
            <Box sx={{ my: 3 }}>
              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {propReferral ? 'Edit Referral' : 'Add Referral'}
                </Typography>
              </Grid>
            </Box>
            <Divider />
          </Box>
          <Box sx={{ marginTop: patient ? '200px' : '90px' }}>
            {!patient && (
            <Grid item style={{ marginTop: '15px' }}>
              <FormControl fullWidth>
                <PatientSearchDropdown
                  value={patientItem}
                  onChange={(_: any, value: any | null) => {
                    updateState({ patientItem: value })
                  }}
                  useFhirId
                  label="Patient *"
                />
              </FormControl>
            </Grid>)}
            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Created Date *</InputLabel>
              <FormControl fullWidth>
                <TextField
                  value={date}
                  defaultValue={date}
                  onChange={(e) => updateState({ date: e.target.value })}
                  type="date"
                  variant="standard"
                  required
                  disabled={propReferral ? true : false}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ marginTop: '15px' }}>
              <InputLabel shrink>Time *</InputLabel>
              <FormControl fullWidth style={{ paddingRight: '5px' }}>
                <TextField
                  value={startHour}
                  defaultValue={startHour}
                  onChange={(e) => updateState({ startHour: e.target.value })}
                  type="time"
                  variant="standard"
                  required
                  disabled={propReferral ? true : false}
                />
              </FormControl>
            </Grid>

            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Status *</InputLabel>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  options={availableStatuses}
                  value={statusItem}
                  getOptionLabel={(item) => item.label}
                  onChange={(_: any, value: any | null) =>
                    updateState({ statusItem: value, status: value.value })
                  }
                  renderInput={(params) => <TextField required variant="standard" {...params} />}
                  disabled={availableStatuses.length === 1}
                />
              </FormControl>
            </Grid>

            {propReferral?.id ? (
              <Grid item style={{ marginTop: '15px' }}>
                <InputLabel shrink>Service *</InputLabel>
                <FormControl fullWidth>
                  <Autocomplete
                    freeSolo
                    clearOnBlur
                    disablePortal
                    options={referralServiceTypes}
                    value={serviceTypeItem}
                    getOptionLabel={(item) =>  {

                      if (typeof item === 'string') {
                        return item;
                      } else if (item?.label && typeof item?.label === 'string') {
                        return item?.label;
                      }

                      return '';
                    }}
                    onChange={(_: any, value: any | null) => {
                      console.log('debug serviceTypeItem onChange value: ', value);
                      if (value && value?.value) {
                        updateState({ serviceTypeItem: value });
                      } else {
                        updateState({ serviceTypeItem: value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        variant="standard"
                        {...params}
                        helperText="To add new Service not in the dropdown selection press <enter>"
                        FormHelperTextProps={{
                          classes: {
                            root: helperTextStyles.root,
                          },
                        }}
                      />
                    )}
                    renderOption={((props, option, { index }) => (
                      <li {...props} key={option.value + index}>{option.label}</li>
                    ))}
                    disabled={true}
                  />
                </FormControl>
              </Grid>
            ) : (
              <Grid item style={{ marginTop: '15px' }}>
                <InputLabel shrink>Services *</InputLabel>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    freeSolo
                    clearOnBlur
                    disablePortal
                    options={referralServiceTypes}
                    value={serviceTypeItems}
                    getOptionLabel={(item) =>  {

                      if (typeof item === 'string') {
                        return item;
                      } else if (item?.label && typeof item?.label === 'string') {
                        return item?.label;
                      }

                      return '';
                    }}
                    onChange={(_: any, value: any | null) => {
                      console.log('debug serviceTypeItems onChange value: ', value);
                      if (value && value?.value) {
                        updateState({ serviceTypeItems: value });
                      } else {
                        updateState({ serviceTypeItems: value });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        variant="standard"
                        {...params}
                        helperText="To add new Service not in the dropdown selection press <enter>"
                        FormHelperTextProps={{
                          classes: {
                            root: helperTextStyles.root,
                          },
                        }}
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li 
                        {...props} 
                        key={option.value.organizationId}
                      >
                        {option?.label}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Referred from *</InputLabel>
              <FormControl fullWidth>
                <Autocomplete
                  freeSolo
                  clearOnBlur
                  disablePortal
                  options={usersAndRequesterGroupsList}
                  value={referredFromItem}
                  getOptionLabel={(item) => (typeof item !== 'string' && item?.label ? item?.label : item)}
                  onChange={(_: any, value: any | null) => {
                    console.log('debug referredFromItem onChange value: ', value);
                    if (value && value?.value) {
                      updateState({
                        referredFromFreeText: false,
                        referredFromItem: value,
                      });
                    } else if (value) {
                      updateState({
                        referredFromFreeText: true,
                        referredFromItem: { label: value, value: value },
                      });
                    } else {
                      updateState({
                        referredFromFreeText: false,
                        referredFromItem: null,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      variant="standard"
                      {...params}
                      helperText="To add new Referred from organization not in the dropdown selection press <enter>"
                      FormHelperTextProps={{
                        classes: {
                          root: helperTextStyles.root,
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Referred to *</InputLabel>
              <FormControl fullWidth>
                <Autocomplete
                  freeSolo
                  clearOnBlur
                  disablePortal
                  options={providerOrganizationsList}
                  value={referredToItem}
                  onChange={(_: any, value: any | null) => {
                    console.log('debug referredToItem onChange value: ', value);
                    if (value && value?.value) {
                      updateState({
                        referredToFreeText: false,
                        referredToItem: value,
                      });
                    } else if (value) {
                      updateState({
                        referredToFreeText: true,
                        referredToItem: { label: value, value: value },
                      });
                    } else {
                      updateState({
                        referredToFreeText: false,
                        referredToItem: null,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      variant="standard"
                      {...params}
                      helperText="To add new Referred to organization not in the dropdown selection press <enter>"
                      FormHelperTextProps={{
                        classes: {
                          root: helperTextStyles.root,
                        },
                      }}
                    />
                  )}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.value}>
                      {option?.label}
                    </li>
                  )}
                />
              </FormControl>
            </Grid>

            {showAssignedToInput && (
            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Assigned to *</InputLabel>
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  options={userListWithNames}
                  value={assignedToItem}
                  onChange={(_: any, value: any | null) => updateState({ assignedToItem: value })}
                  renderInput={(params) => <TextField required variant="standard" {...params} />}
                  renderOption={(props, option, { selected, index }) => (
                    <li {...props} key={option.value + index}>
                      {option?.label}
                    </li>
                  )}
                />
              </FormControl>
            </Grid>)}

            <Grid item style={{ marginTop: '15px' }}>
              <InputLabel shrink>Attachment</InputLabel>
              <FormControl fullWidth>
                <TextField
                  value={file}
                  defaultValue={file}
                  onChange={(e) =>
                    updateState({ file: e.target.value as any, selectedFile: (e.target as any)?.files[0] })
                  }
                  variant="standard"
                  required
                  type="file"
                />
              </FormControl>
            </Grid>

            {propReferral?.id && lastAttachment ? (
              <Grid item style={{ marginTop: '15px' }}>
                <InputLabel shrink>
                  Download Attachment{' '}
                  <IconButton onClick={downloadAttachment}>
                    <Iconify icon="bi:download" />
                  </IconButton>
                </InputLabel>
              </Grid>
            ) : null}

            {propReferral?.id ? (
              <Grid item sx={{ mt: 4 }}>
                <Typography variant="caption">Notes</Typography>
                <List>
                  {communications.map((c) =>
                    c?.note?.[0]?.text ? (
                      editingNoteId === c?.id ? (
                        <Stack direction="row">
                          <Grid>
                            <InputLabel shrink>Note</InputLabel>
                            <FormControl fullWidth>
                              <ReactQuill
                                theme="snow"
                                // value={note} // TODO: fix the valuetype
                                onChange={(val) => updateState({ note: val })}
                              />
                            </FormControl>
                            {/* <FormControl fullWidth>
                              <TextField
                                value={note}
                                onChange={(e) => updateState({ note: e.target.value })}
                                id="outlined-basic"
                                variant="standard"
                                multiline
                                maxRows={3}
                                rows={3}
                              />
                            </FormControl> */}
                          </Grid>

                          <Stack spacing={2}>
                            <LoadingButton
                              disabled={isLoading || savingEditedNote || !note}
                              loading={savingEditedNote}
                              onClick={handleSaveNoteChanges}
                              size="small"
                              variant="outlined"
                              color="secondary"
                            >
                              Update Note
                            </LoadingButton>
                            <Button
                              disabled={isLoading}
                              onClick={() => {
                                setEditingNote({
                                  editingNoteId: '',
                                  savingEditedNote: false,
                                });
                                updateState({ note: '' });
                              }}
                              size="small"
                              variant="outlined"
                              color="secondary"
                            >
                              Cancel
                            </Button>
                          </Stack>
                        </Stack>
                      ) : (
                        <>
                          <ListItem>
                            <ListItemText secondary={parse(c?.note?.[0]?.text)} />

                            <IconButton
                              onClick={() => {
                                setEditingNote({
                                  editingNoteId: c?.id,
                                  savingEditedNote: false,
                                });
                                updateState({ note: c?.note?.[0]?.text });
                              }}
                            >
                              <Iconify icon="bi:pencil-square" />
                            </IconButton>
                          </ListItem>
                          <Divider variant="middle" component="li" />
                        </>
                      )
                    ) : null
                  )}

                  {communications.length > 0 && <Divider variant="middle" component="li" />}
                </List>
              </Grid>
            ) : (
              <Grid item style={{ marginTop: '15px' }}>
                <InputLabel shrink>Note</InputLabel>
                <FormControl fullWidth>
                  <ReactQuill
                    theme="snow"
                    // value={note || null} // TODO: fix the value type
                    onChange={(val) => updateState({ note: val })}
                  />
                </FormControl>
              </Grid>
            )}

            {isArray(errors)
              ? errors.map((e) => (
                  <Alert key={e} sx={{ marginTop: '20px' }} severity="error">
                    {e}
                  </Alert>
                ))
              : null}

            {success && (
              <Alert style={{ marginTop: '20px' }} severity="success">
                {success}
              </Alert>
            )}
            <Grid container spacing={2} direction="row">
              <Grid item xs={6} style={{ marginTop: '15px' }}>
                <FormControl fullWidth>
                  <Button
                    disabled={isLoading}
                    onClick={onClearAllPress}
                    size="large"
                    fullWidth
                    variant="outlined"
                    color="secondary"
                  >
                    Clear all
                  </Button>
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ marginTop: '15px' }}>
                <FormControl fullWidth>
                  <Button
                    disabled={isLoading || !!editingNoteId}
                    onClick={onSavePress}
                    size="large"
                    style={{ width: '100%' }}
                    variant="contained"
                    color="secondary"
                  >
                    Save
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </FormProvider>
  );
};

export default AddReferral;
