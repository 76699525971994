// @ts-nocheck
import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Link,
  Stack,
  Typography,
  Modal,
  Card,
} from '@mui/material';
import { useGetCurrentBedAssignment } from 'src/services/api/census';
import moment from 'moment';
import LocationHistorySummary from './LocationHistorySummary';

interface AdmissionDetailsSummaryProps {
  patient: any;
};

const AdmissionDetailsSummary = ({ patient }: AdmissionDetailsSummaryProps) => {
  const [showLocationHistoryModal, setShowLocationHistoryModal] = useState<boolean>(false);
  const { data: currentBedAssignment, refetch } = useGetCurrentBedAssignment(patient?.id);

  const onViewLocationHistoryPress = () => {
    setShowLocationHistoryModal(!showLocationHistoryModal);
  };

  useEffect(() => {
    refetch()
  }, [refetch, patient?.id])

  return (
    <Card sx={{ p: 2 }}>
      <Modal
        open={showLocationHistoryModal}
        onClose={onViewLocationHistoryPress}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <LocationHistorySummary patient={patient} />
      </Modal>
      <Stack alignItems="center" direction="row" sx={{ mb: 1.5 }}>
        <Typography variant="subtitle1">Admission Details</Typography>
      </Stack>
      <Grid>
        <Box sx={{ height: '400px', overflow: 'auto' }}>
          (<Link onClick={onViewLocationHistoryPress}>View Location history</Link>)
          {!currentBedAssignment ? (
            <Grid container sx={{ mt: 3 }}>
              <Typography variant='subtitle'>No current admission</Typography>
            </Grid>
          ) : (
            <Grid container sx={{ mt: 1 }}>
              <Grid sx={{ marginTop: 2 }} item xs={12}>
                <Typography variant="caption">Location</Typography>
                <Grid item>
                  <Typography variant="subtitle2">{currentBedAssignment?.bed?.locationFhirName}</Typography>
                </Grid>
              </Grid>
              <Grid sx={{ marginTop: 2 }} item xs={12}>
                <Typography variant="caption">Status</Typography>
                <Grid item>
                  <Typography variant="subtitle2" style={{ textTransform: 'capitalize' }}>
                    {currentBedAssignment?.isActive ? 'Admitted' : ' Discharged'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ marginTop: 2 }} item xs={12}>
                <Typography variant="caption">Admission Date</Typography>
                <Grid item>
                  <Typography variant="subtitle2">
                    {moment(currentBedAssignment?.start).format('MMM DD YYYY')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ marginTop: 2 }} item xs={12}>
                <Typography variant="caption">Discharge Date</Typography>
                <Grid item>
                  <Typography variant="subtitle2">
                    {!currentBedAssignment?.isActive ? moment(currentBedAssignment?.updatedOn).format('MMM DD YYYY') : 'N/A'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={{ marginTop: 2 }} item xs={12}>
                <Typography variant="caption">Exit Destination</Typography>
                <Grid item>
                  <Typography variant="subtitle2">{currentBedAssignment.exitDestination?.valueDisplayName}</Typography>
                </Grid>
              </Grid>
              <Grid sx={{ marginTop: 2 }} item xs={12}>
                <Typography variant="caption">Referrer</Typography>
                <Grid item>
                  <Typography variant="subtitle2">N/A</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
    </Card>
  );
};

export default AdmissionDetailsSummary;
