// @ts-nocheck
import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import {
  Typography,
  Grid,
  Box,
  Divider,
  InputLabel,
  FormControl,
  TextField,
  Alert,
} from '@mui/material';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useAuth from 'src/hooks/useAuth';
import { MedicationRequest } from 'src/@nicheaim/fhir-base/mappings/MedicationRequest';
import { useMedication, useMedicationDispenses, useMedicationRequests } from 'src/@nicheaim/fhir-react';
import { useQueryClient } from '@tanstack/react-query';
import { useUsersWithFhirUri } from 'src/services/api/users';
import { getFhirIdFromReferenceString } from '../../tasks-activities/components/Activities/activities-utils';
import { FormProvider } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import RHFAutocomplete from 'src/components/hook-form/RHFAutocomplete';
import RHFTextField from 'src/components/hook-form/RHFTextField';
import RHFDatePicker from 'src/components/hook-form/RHFDatePicker';
import { useCreateMedicationTransaction, useMedicationTransactionTypes } from 'src/services/api/medication-transaction';import ActionButtons from '../../common/ActionButtons';
import fhirSystem from 'src/fhir/system';

type MedicationRequestTransactionFormPropsType = {
  patient: any;
  medicationRequest: MedicationRequest | null;
  onClose: () => void;
  onSave: (medicationRequest: MedicationRequest) => void;
};

type Option = {
  label: string;
  value: string | number;
} | null;

type MedicationRequestTransactionFormState = {
  transactionType: Option;
  quantity: number;
  unit: Option;
  performedDate: Date;
  performedTime: string;
  staff: Option;
};

const schema = Yup.object().shape({
  transactionType: Yup.object().nullable().required('Transaction type is required'),
  quantity: Yup.number().nullable().required('Quantity is required'),
  unit: Yup.object().nullable(),// .required('Unit is required'),
  performedDate: Yup.date().nullable().required('Performed date is required'),
  performedTime: Yup.string().nullable().required('Performed time is required'),
  staff: Yup.object().nullable().required('Staff is required'),
});

const resolver = yupResolver(schema);

const MedicationRequestTransactionForm = ({
  patient,
  onClose,
  medicationRequest,
  onSave,
}: MedicationRequestTransactionFormPropsType) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [validatedTransaction, setValidatedTransaction] = useState<boolean>(false);
  const { mutateAsync } = useCreateMedicationTransaction();
  const [medication, { isLoading: loadingMedication }] = useMedication(getFhirIdFromReferenceString(medicationRequest?.medicationReference?.reference ?? ''));
  const [medicationDispenses, { update: updateMedicationDispense }] = useMedicationDispenses({
    filter: {
      prescription: medicationRequest?.id,
      _sort: '-whenPrepared',
    },
  })
  const [_, { update: updateMedicationRequest }] = useMedicationRequests({
    autofetch: false,
  });
  const { data: medicationTransactionTypes } = useMedicationTransactionTypes(); 
  const [note, setNote] = useState<string>('');
  const queryClient = useQueryClient();
  const loggedUser = useAuth().getCurrentUser();

  const medicationForm = useMemo(() => medication?.form?.coding?.find(c => c.system === fhirSystem.medication.dosageForm.asString()) ?? null, [medication]);

  // default form values
  const defaultValues = useMemo((): MedicationRequestTransactionFormState => ({
    transactionType: null,
    quantity: 1,
    unit: null,
    performedDate: moment().toDate(),
    performedTime: moment().format('HH:mm'),
    staff: null,
  }), [])

  // init form
  const methods = useForm({
    resolver,
    defaultValues,
  });

  // get staff users and parse to drop down options
  const { data: users = [], isLoading: isLoadingUsers } = useUsersWithFhirUri();
  const staffOptions = useMemo(() =>
    users.map(user => ({
      label: `${user.firstName} ${user.lastName}`,
      value: getFhirIdFromReferenceString(user.fhirUri ?? ''),
    })) ?? [], [users])

  const transactionTypes = useMemo(() =>
    medicationTransactionTypes?.map(catalog => ({
      label: catalog.valueDisplayName,
      value: catalog.id,
    })) ?? [], [medicationTransactionTypes])

  const onSubmit = async (data: MedicationRequestTransactionFormState) => {
    setIsLoading(true);
    if (
      !data.transactionType ||
      !data.performedDate ||
      !data.performedTime ||
      !data.quantity ||
      !data.staff
      // !data.unit 
    ) {
      setIsLoading(false);
      return;
    }

    mutateAsync({
      medicationRequestFhirId: medicationRequest?.id!,
      medicationRequestRXNumber: medicationRequest?.identifier?.find((i) => i.type?.coding?.find(c => c.code === 'RX'))?.value ?? 'N/A',
      staffFhirId: data.staff.value as string,
      patientFhirId: patient?.id,
      performedDate: moment(data.performedDate).toString(),
      transactionTypeId: data.transactionType.value as number,
      quantity: data.quantity,
      unitId: data.unit?.value as string,
      unitName: data.unit?.label,
      medicationId: getFhirIdFromReferenceString(medicationRequest?.medicationReference?.reference ?? '')!,
      medicationName: medicationRequest?.medicationReference?.display ?? 'N/A',
      note: note,
      loggedUser,
    })
    .then(async () => {
      const dispense = medicationDispenses?.[0];
      if (dispense) {
        if (data.transactionType?.value === medicationTransactionTypes?.find(i => i.valueCode === 'refill-quantity')?.id) {
          const maxRefills = medicationRequest?.dispenseRequest?.numberOfRepeatsAllowed ?? 0;
          
          await updateMedicationDispense({
            ...dispense,
            quantity: {
              value: (dispense?.quantity?.value ?? 0) + data.quantity,
            },
          }).then((err) => {
            console.log('========== update medication dispense', err)
          })
  
          await updateMedicationRequest({
            ...medicationRequest!,
            dispenseRequest: {
              numberOfRepeatsAllowed: maxRefills > 0 ? maxRefills - 1 : 0,
            },
          }).then((err) => {
            console.log('========== update medication request', err)
          })
        }

        if (data.transactionType?.value === medicationTransactionTypes?.find(i => i.valueCode === 'returned')?.id) {
          await updateMedicationDispense({
            ...dispense,
            quantity: {
              value: (dispense?.quantity?.value ?? 0) + data.quantity,
            },
          }).then((err) => {
            console.log('========== update medication dispense', err)
          })
        }

        if (data.transactionType?.value === medicationTransactionTypes?.find(i => i.valueCode === 'med-count')?.id) {
          await updateMedicationDispense({
            ...dispense,
            quantity: {
              value: data.quantity,
            },
          }).then((err) => {
            console.log('========== update medication dispense', err)
          })
        }

        if (
          (dispense?.quantity?.value ?? 0) >= data.quantity &&
         (
          data.transactionType?.value === medicationTransactionTypes?.find(i => i.valueCode === 'disposed')?.id ||
          data.transactionType?.value === medicationTransactionTypes?.find(i => i.valueCode === 'lost')?.id ||
          data.transactionType?.value === medicationTransactionTypes?.find(i => i.valueCode === 'dispensed')?.id ||
          data.transactionType?.value === medicationTransactionTypes?.find(i => i.valueCode === 'spillage')?.id
         )
        ) {
          await updateMedicationDispense({
            ...dispense,
            quantity: {
              value: (dispense?.quantity?.value ?? 0) - data.quantity,
            },
          })
        }

        queryClient.refetchQueries({
          predicate(query) {
            return ['ccm-medication-transactions-by-medication-request'].includes(
              query.queryKey[0] as string
            );
          },
        });
      }

      setTimeout(() => {
        setIsLoading(false);
        onClose();
      }, 800)
    })
    .catch((error) => {
      setIsLoading(false);
      console.log('========= TRAN ERROR =========', {
        error
      })
    })
  };

  

  const disableButtons = useMemo(() => {
    const dispense = medicationDispenses?.[0];
    if (
      medicationRequest?.dispenseRequest?.numberOfRepeatsAllowed === 0 && 
      methods?.getValues('transactionType')?.value === medicationTransactionTypes?.find(i => i.valueCode === 'refill-quantity')?.id
    ) {
      setValidationError('Max refill quantity reached')
      setValidatedTransaction(false)
      return true
    };
    
    if (
      (dispense?.quantity?.value ?? 0) < parseInt(methods?.getValues('quantity').toString()) &&
      (
        methods?.getValues('transactionType')?.value === medicationTransactionTypes?.find(i => i.valueCode === 'disposed')?.id ||
        methods?.getValues('transactionType')?.value === medicationTransactionTypes?.find(i => i.valueCode === 'dispensed')?.id ||
        methods?.getValues('transactionType')?.value === medicationTransactionTypes?.find(i => i.valueCode === 'lost')?.id ||
        methods?.getValues('transactionType')?.value === medicationTransactionTypes?.find(i => i.valueCode === 'spillage')?.id
      )
    ) {
      setValidationError('Quantity is greater than remaining quantity')
      setValidatedTransaction(false)
      return true
    };

    setValidationError(null)
    setValidatedTransaction(true)
    return false;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch('transactionType'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch('quantity'),
    medicationRequest?.dispenseRequest?.numberOfRepeatsAllowed,
    medicationRequest?.dispenseRequest?.quantity?.value,
    medicationTransactionTypes
  ])
    
  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Grid item>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Add Transaction for Medication Request #{medicationRequest?.identifier?.find(i => i.type?.coding?.find(c => c.code === 'RX'))?.value ?? ''}
          </Typography>
        </Grid>
      </Box>
      <Divider />
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid item style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <RHFAutocomplete
              disablePortal
              options={transactionTypes}
              name="transactionType"
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Transaction type"
                  margin="dense"
                  size="small"
                  variant="standard"
                  required
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid container>
          <Grid
            item
            xl={6}
            style={{ marginTop: '22px' }}
            sx={{ gap: 1 }}
          >
            <FormControl fullWidth>
              <RHFTextField
                required
                label="Quantity"
                name={"quantity"}
                type="number"
                variant="standard"
              />
            </FormControl>
          </Grid>
          <Grid item xl={6} style={{ marginTop: '17px' }}>
            <FormControl fullWidth>
              <RHFAutocomplete
                disablePortal
                options={[...(medicationForm ? [{ label: medicationForm?.display, value: medicationForm?.code }] : [])]}
                name="unit"
                disabled={loadingMedication}
                renderInput={(params: any) => (
                  <RHFTextField
                    {...params}
                    fullWidth
                    label="Unit"
                    margin="dense"
                    size="small"
                    variant="standard"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xl={6}
            style={{ marginTop: '15px' }}
            sx={{ gap: 1 }}
          >
            {/* <InputLabel shrink>Performed Date</InputLabel> */}
            <FormControl fullWidth>
              <RHFDatePicker
                label="Performed Date"
                name={"performedDate"}
                TextFieldProps={{
                  variant: 'standard',
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xl={6} style={{ marginTop: '15px' }}>
            {/* <InputLabel shrink>Performed Time</InputLabel> */}
            <FormControl fullWidth>
              <RHFTextField
                InputLabelProps={{
                  shrink: true,
                }}
                label="Performed Time"
                name="performedTime"
                type="time"
                variant="standard"
                required
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xl={12} style={{ marginTop: '15px' }}>
          <FormControl fullWidth>
            <RHFAutocomplete
              disablePortal
              options={staffOptions}
              name="staff"
              renderInput={(params: any) => (
                <RHFTextField
                  {...params}
                  fullWidth
                  label="Staff"
                  margin="dense"
                  size="small"
                  variant="standard"
                  required
                />
              )}
              disabled={isLoadingUsers}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Grid item xl={12} style={{ marginTop: '15px', marginBottom: 70 }}>
            <InputLabel sx={{ mb: 2 }}>Note</InputLabel>
            <FormControl fullWidth>
              <ReactQuill
                theme="snow"
                value={note}
                onChange={(val) => setNote(val)}
                style={{
                  height: '100px'
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        {(!validatedTransaction && validationError) && (
          <Alert style={{ marginTop: '20px' }} severity="warning">
            {validationError}
          </Alert>
        )}
        <ActionButtons
          isLoading={isLoading}
          leftButtonTitle={'Cancel'}
          onLeftButtonPress={onClose}
          rightButtonTitle={'Save and submit'}
          rightButtonType='submit'
          disabled={disableButtons}
        />
      </FormProvider>
    </Box>
  );
};

export default MedicationRequestTransactionForm;
