import ccmAcls from './ccm/ccmAcls';
import ccmAclsGroups from './ccm/ccmAclsGroups';
import crsAcls from './crs/crsAcls';
import { getUserPermissions } from './get.user.permissions';

export const adminAll: string = 'admin.*';
export const SysAdmin: string = '*';
export const CCMUser: string = 'ccm.*';
export const CRSUserAdmin: string = 'crs.*';
export const PatientEditReferral: string = 'crs.patient.editReferral';
export const PatientEditCase: string = 'crs.patient.editCase';
export const AddNewPatient: string = 'crs.patient.add';
export const AssignPrograms: string = 'crs.case.assignPrograms';
export const ViewPersonalDetails: string = 'crs.patient.personalDetails.view';
export const EditPersonalDetails: string = 'crs.patient.personalDetails.edit';
export const ViewContactDetails: string = 'crs.patient.contactDetails.view';
export const EditContactDetails: string = 'crs.patient.contactDetails.edit';
export const AddContactDetailsPhone: string = 'crs.patient.contactDetailsPhone.add';
export const DeleteContactDetailsPhone: string = 'crs.patient.contactDetailsPhone.delete';
export const AddContactDetailsEmail: string = 'crs.patient.contactDetailsEmail.add';
export const DeleteContactDetailsEmail: string = 'crs.patient.contactDetailsEmail.delete';
export const AddAddressDetails: string = 'crs.patient.addressDetails.add';
export const EditAddressDetails: string = 'crs.patient.addressDetails.edit';
export const DeleteAddressDetails: string = 'crs.patient.addressDetails.delete';
export const AddIdentifierDetails: string = 'crs.patient.identifierDetails.add';
export const EditIdentifierDetails: string = 'crs.patient.identifierDetails.edit';
export const DeleteIdentifierDetails: string = 'crs.patient.identifierDetails.delete';
export const AddInsurance: string = 'crs.patient.insurance.add';
export const EditInsurance: string = 'crs.patient.insurance.edit';
export const AddAttachments: string = 'crs.patient.documentsAttachments.add';
export const EditAttachments: string = 'crs.patient.documentsAttachments.edit';
export const AddNotes: string = 'crs.patient.documentsNotes.add';
export const SimpleSearch: string = 'crs.patient.simpleSearch';
export const AdvancedSearch: string = 'crs.patient.advancedSearch';

// CCM ACLS AND ACLS GROUPS
export const CCM_ACLS = ccmAcls;
export const permissionsAcls = ccmAclsGroups;

// CRS ACLS
export const CRS_ACLS = crsAcls;

export function getMatchingPermissions(
  assignedPermissionsSpec: string,
  requiredPermissions: string[]
): string[] {
  if (!assignedPermissionsSpec || !requiredPermissions) {
    return [];
  }
  const regex = new RegExp(
    ['^', assignedPermissionsSpec.replace(/\./g, '\\.').replace(/\*/g, '.+'), '$'].join('')
  );
  return requiredPermissions.filter((permission) => permission.match(regex));
}

export function isAllowed(
  assignedPermissionsSpecs: string[],
  ...requiredPermissions: string[]
): boolean {
  if (
    !assignedPermissionsSpecs ||
    !requiredPermissions ||
    assignedPermissionsSpecs.length === 0 ||
    requiredPermissions.length === 0
  ) {
    return false;
  }
  const matchingPermissions = assignedPermissionsSpecs.reduce((matchingPermissions, spec) => {
    getMatchingPermissions(spec, requiredPermissions).forEach((permission) => {
      matchingPermissions.add(permission);
    });
    return matchingPermissions;
  }, new Set<string>());
  return requiredPermissions.some((permission) => matchingPermissions.has(permission));
}

export const getRelatedAcls = (acl: string) => {
  const userPermissions = getUserPermissions();
  try {
    const acls = acl.split('.');
    return [
      SysAdmin,
      ...acls.reduce<string[]>((upperAcls, acl, index) => {
        if (index === acls.length - 1) return upperAcls;
        const previousAcl = acls?.[index - 1];
        if (previousAcl) return [...upperAcls, `${previousAcl}.${acl}.*`];
        return [...upperAcls, `${acl}.*`];
      }, []),
      acl,
    ];
  } catch (error) {}
};

export function checkAclValidation(validation: any): boolean {
  const userPermissions = getUserPermissions();

  const { user, acl: permissions } = validation as {
    user: any;
    acl: string[];
  };

  const isAclValid = permissions.filter((permission) => userPermissions.isAllowed(permission));

  return isAclValid.length > 0;
}
