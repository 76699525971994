import moment from 'moment';
import { WrappedGoal } from '../../../@nicheaim/fhir-base/wrappers/Goal';
import { WrappedTask } from '../../../@nicheaim/fhir-base/wrappers/Task';
import {
  GroupedGoals,
  GoalGridRowData,
  TaskGridRowData,
  CareTeamStatus,
  Option,
  CareTeamMemberGridRowData,
  ServiceRequestGridRowData,
} from '../../../@types/crs/case';
import { getSeverityDueDateData } from './getSeverityDueDateData';
import { AlertSeverity, SeverityStatusData } from '../../../components/SeverityStatus';
import { capitalize } from 'src/utils/string';
import { TaskStatus } from '../case/components/TasksGrid/TaskModal';
import { GoalLifeCycleStatusOption } from '../case/components/GoalsGrid/GoalModal';
import {
  CareTeamParticipant,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { NOTAPPLICABLE } from '../constants';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { WrappedCommunication } from 'src/@nicheaim/fhir-base/wrappers/Communication';
import { WrappedDocumentReference } from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import { format } from 'date-fns';

export const searchIfContainedInObj = <T>(
  obj: T,
  searcheableProps: Array<string>,
  valueToSearch: string
): boolean => {
  for (const searcheableProp of searcheableProps) {
    const props = searcheableProp.split('.');
    const propValue = props.reduce<any>((innerProp, prop) => {
      if (!innerProp) {
        innerProp = obj?.[prop as keyof T];
        return innerProp;
      }
      innerProp = innerProp?.[prop];
      return innerProp;
    }, null);
    if (!propValue || typeof propValue !== 'string') continue;
    if (propValue.toLowerCase().includes(valueToSearch)) return true;
  }
  return false;
};

export const getTasksByGoal = (
  goalId: string | undefined,
  tasks: WrappedTask[],
  groupedGoals: GroupedGoals | undefined
): WrappedTask[] =>
  tasks.filter(({ id: taskId }) => {
    if (!goalId || !taskId) return false;
    return groupedGoals?.[goalId].includes(taskId);
  });

export const getGoalGridRows = (
  goals: WrappedGoal[],
  tasks: WrappedTask[],
  groupedGoals: GroupedGoals | undefined,
  goalStatuses: GoalLifeCycleStatusOption[]
): GoalGridRowData[] =>
  goals.map((goal) => {
    const { target, id, getPlainDescription, getPathway, lifecycleStatus, getPathwayDisplay } =
      goal;
    const endDateMoment = moment(target?.[0]?.dueDate ?? null, 'YYYY-MM-DD');
    const { severityStatus } = getSeverityDueDateData(endDateMoment);
    const goalStatus = getValueSetConceptValue(goalStatuses ?? [], lifecycleStatus);

    return {
      pathwayDisplay: getPathwayDisplay?.() ?? '',
      pathway: getPathway?.() || '',
      id: id || '',
      endDate: { ...severityStatus, date: endDateMoment },
      status: goalStatus?.display ?? '',
      goal: getPlainDescription?.() ?? '',
      tasks: getTasksByGoal(id, tasks, groupedGoals),
      wrappedGoal: goal,
    };
  });

export const getTaskGridRows = (
  tasks: WrappedTask[],
  taskStatuses: TaskStatus[]
): TaskGridRowData[] =>
  tasks.map((task) => {
    const { id, getDueDate, description, owner, status, basedOn } = task;
    const endDate = moment(getDueDate() ?? null);
    const { severityStatus } = getSeverityDueDateData(endDate);

    const taskStatus = getValueSetConceptValue(taskStatuses ?? [], status);
    return {
      id: id ?? '',
      task: description ?? '',
      status: taskStatus?.display ?? '',
      endDate: {
        ...severityStatus,
        date: endDate,
      },
      owner: owner?.display ?? '',
      basedOn: basedOn ?? [],
      wrappedTask: task,
    };
  });

export const getServiceRequestBasedOn = (
  serviceRequestId: string | undefined,
  serviceRequest: WrappedServiceRequest[],
): WrappedServiceRequest[] =>
  serviceRequest?.filter((e) => e.basedOn?.find(s => s.reference === `ServiceRequest/${serviceRequestId}`));

export const getAnnotations = (
  communications: WrappedCommunication[],
  serviceRequestId: string | undefined
): WrappedCommunication[] => 
communications?.filter(e => 
  e.partOf?.some(s => s.reference === `ServiceRequest/${serviceRequestId}`));

export const getDocuments = (
  documents: WrappedDocumentReference[],
  serviceRequestId: string | undefined
): Number => 
documents?.filter(d => d.context?.related?.
  find(r => r.reference === `ServiceRequest/${serviceRequestId}`)).length;

export const getServiceRequestGridRows = (
  serviceRequests: WrappedServiceRequest[], 
  serviceRequestsChild?: WrappedServiceRequest[]
) : ServiceRequestGridRowData[] => 
  serviceRequests.map((serviceRequest) => {
    const { id, code, status, occurrencePeriod, performer, authoredOn, basedOn } = serviceRequest;
    const endDateMoment = moment(occurrencePeriod?.end ?? undefined, 'YYYY-MM-DD');
    const { severityStatus } = getSeverityDueDateData(endDateMoment);
    const child = serviceRequestsChild && getServiceRequestBasedOn(id, serviceRequestsChild);
    return {
      id: id ?? '',
      serviceReferral: code?.coding?.[0].display || '',
      status: status, 
      assignedTo: performer?.[0].display || '',
      assignedOn: authoredOn ? format(new Date(authoredOn), 'MMM dd, yyyy') : "",
      dueTo: {...severityStatus, date: endDateMoment}, 
      basedOn: basedOn ?? [], 
      wrappedServiceRequest: serviceRequest, 
      childs: child,
      childCount: child?.length
    };
  });

export const getGoalsIds = (groupedGoals: GroupedGoals | undefined): string | null => {
  if (!groupedGoals) return null;
  return Object.keys(groupedGoals).join(',');
};

export const getTasksIds = (groupedGoals: GroupedGoals | undefined): string | null => {
  if (!groupedGoals) return null;
  return Object.values(groupedGoals)
    .reduce<string[]>((taskIds, tasks) => [...taskIds, ...(tasks || [])], [])
    .join(',');
};

export const getSeverityStatusByCareTeamStatus = (
  status: string | undefined
): SeverityStatusData => {
  const statusSeverityMap: Record<CareTeamStatus, AlertSeverity> = {
    [CareTeamStatus.PROPOSED]: AlertSeverity.WARNING,
    [CareTeamStatus.ACTIVE]: AlertSeverity.SUCCESS,
    [CareTeamStatus.SUSPENDED]: AlertSeverity.ERROR,
    [CareTeamStatus.INACTIVE]: AlertSeverity.DEFAULT,
    [CareTeamStatus.ENTEREDINERROR]: AlertSeverity.ERROR,
  };
  return {
    message: status,
    severity: statusSeverityMap?.[status as CareTeamStatus],
  };
};

export const getOption = <T extends Option<any>>(
  options: T[],
  optionValue: T['value']
): T | null => {
  let option: T | null = null;
  if (optionValue) {
    option = options.find(({ value }) => value === optionValue) as T;
    if (!option) {
      option = {
        label: capitalize(optionValue ?? ''),
        value: optionValue,
      } as T;
    }
  }
  return option;
};

export const getCareTeamMemberGridRows = (
  participants: CareTeamParticipant[]
): CareTeamMemberGridRowData[] =>
  participants?.length
    ? participants.map((participant) => {
        const { member, onBehalfOf, role: participantRole, period } = participant ?? {};
        const startDate = moment(period?.start ?? null);
        const endDate = moment(period?.end ?? null);
        const resourceType = getReferenceResourceType(member?.reference ?? '');
        let associatedOrgName = onBehalfOf?.display ?? '';
        let associatedOrgId = onBehalfOf?.reference ?? null;
        let associatedOrg = associatedOrgName ?? associatedOrgId ?? '';
        let roleName =
          participantRole?.[0]?.text ?? participantRole?.[0]?.coding?.[0]?.display ?? '';
        let roleId = participantRole?.[0]?.coding?.[0]?.code ?? null;
        let role = roleName ?? roleId ?? '';
        if (resourceType !== 'PractitionerRole') {
          associatedOrg = NOTAPPLICABLE;
          associatedOrgId = null;
          role = NOTAPPLICABLE;
          roleId = null;
        }

        return {
          id: getReferenceResourceId(member?.reference ?? ''),
          associatedOrg,
          associatedOrgName,
          associatedOrgId,
          role,
          roleName,
          roleId,
          name: member?.display ?? '',
          identifier: member?.identifier?.value ?? '',
          identifierType: member?.identifier?.system ?? '',
          memberType: resourceType,
          startDate: startDate.isValid() ? startDate.format('MMM DD, YYYY') : '',
          endDate: endDate.isValid() ? endDate.format('MMM DD, YYYY') : '',
          startDateObj: startDate.isValid() ? startDate : null,
          endDateObj: endDate.isValid() ? endDate : null,
          member: participant as CareTeamParticipant,
        };
      })
    : [];

export const getReferenceResourceType = (reference: string): string => reference?.split?.('/')?.[0];
export const getReferenceResourceId = (reference: string): string => reference?.split?.('/')?.[1];

export const getReference = (resourceType: string, id: string): string => `${resourceType}/${id}`;

export const getObjectIfNotExist = <T>(
  options: T[] | null | undefined,
  searchCallback: (resource: T) => boolean,
  objectCreator: () => T
) => {
  const option = options?.find?.(searchCallback);
  if (option) return option;
  return objectCreator();
};

export const getValueSetConceptValue = (
  values: ValueSetComposeIncludeConcept[],
  valueToSearch: string | null | undefined
): ValueSetComposeIncludeConcept | null => {
  if (!valueToSearch || !values?.length) return null;
  const concept = getObjectIfNotExist<ValueSetComposeIncludeConcept>(
    values,
    ({ code }) => code === valueToSearch,
    () => ({
      code: valueToSearch,
      display: capitalize(valueToSearch ?? ''),
    })
  );
  return concept;
};
