import { Alert } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useGroups, usePatients } from 'src/@nicheaim/fhir-react';
import DataTable, { DataTableColumn } from 'src/components/table/DataTable';
import fhirSystem from 'src/fhir/system';
import PatientsToolbar from './PatientsToolbar';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import Label, { LabelColor } from 'src/components/Label';
import { GroupMember } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import moment from 'moment';
import usePatientTabsStore from 'src/stores/patient-tabs';

const columns: DataTableColumn[] = [
  {
    header: 'Patient Name',
    field: 'patient',
    render(patient) {
      return patient.getFullName();
    },
  },
  {
    header: 'Start Date',
    field: 'period.start',
    render(date) {
      if (!date) {
        return '';
      }

      return moment(new Date(date)).format('MM/DD/YYYY');
    },
  },
  {
    header: 'End Date',
    field: 'period.end',
    render(date) {
      if (!date) {
        return '';
      }

      return moment(new Date(date)).format('MM/DD/YYYY');
    },
  },
  {
    header: 'Status',
    field: '$',
    render(enrollment: GroupMember) {
      type EnrollmentStatus = 'new' | 'pending' | 'active' | 'inactive' | 'unknown';
      const mappings: Record<EnrollmentStatus, { title: string; color: LabelColor }> = {
        new: {
          title: 'New',
          color: 'success',
        },
        pending: {
          title: 'Pending',
          color: 'info',
        },
        active: {
          title: 'Active',
          color: 'warning',
        },
        inactive: {
          title: 'Inactive',
          color: 'warning',
        },
        unknown: {
          title: 'Unknown',
          color: 'error',
        },
      };

      let value: EnrollmentStatus = 'unknown';

      if (enrollment.inactive ?? true) {
        if (!enrollment.period?.start && !enrollment.period?.end) {
          value = 'new';
        } else if (enrollment.period?.start) {
          value = 'inactive';
        } else {
          value = 'unknown';
        }
      } else {
        if (enrollment.period?.end && new Date(enrollment.period.end) < new Date()) {
          value = 'inactive';
        } else if (enrollment.period?.start) {
          value = 'active';
        } else {
          value = 'pending';
        }
      }
      const mapping = mappings[value];

      return <Label color={mapping.color}>{mapping.title}</Label>;
    },
  },
];

export default function PatientsTable() {
  const { program = '' } = useParams();
  const { openPatient } = usePatientTabsStore();
  const [[enrolledPatientsGroup]] = useGroups({
    filter: {
      identifier: fhirSystem.program.enrolledPatients.forCode(program),
    },
  });
  const [patients] = usePatients({
    filter: {
      _id: enrolledPatientsGroup?.member?.map((member) => member.entity?.id).join(','),
    },
    map: PatientWrapper,
  });

  const enrolledPatients =
    enrolledPatientsGroup?.member?.map((enrollment) => ({
      ...enrollment,
      patient: patients.find((patient) => patient.id === enrollment.entity?.id),
    })) || [];

  return (
    <div>
      <PatientsToolbar />

      {patients.length ? (
        <DataTable
          selectable
          clickeable
          columns={columns}
          data={enrolledPatients}
          // onItemClick={(item: typeof enrolledPatients[number]) =>
          //   openPatient(item.patient?.id || '')
          // }
          // onItemClick={(item) => navigate(item.id)}
          sx={{ m: 2 }}
        />
      ) : (
        <Alert severity="info" sx={{ m: 2 }}>
          No patients found
        </Alert>
      )}
    </div>
  );
}
