// @ts-nocheck
import * as Yup from 'yup';
import {
  Divider,
  Box,
  Grid,
  Stack,
  Typography,
  TextField,
  MenuItem,
  Autocomplete,
  createFilterOptions,
} from '@mui/material';
import PatientCard from 'src/sections/careflow/common/PatientCard';
import { useCarePlans } from 'src/@nicheaim/fhir-react';
import useAuth from 'src/hooks/useAuth';
import { CarePlan } from 'src/@nicheaim/fhir-base/mappings/CarePlan';
import { FormProvider, RHFSelect } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import RHFAutocomplete from 'src/components/hook-form/RHFAutocomplete';
import { useQuery } from '@tanstack/react-query';
import api from 'src/services/api';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import fhirSystem from 'src/fhir/system';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Condition } from 'src/@nicheaim/fhir-base/mappings/Condition';
import {
  ActivityDefinition,
  Goal,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import produce from 'immer';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import {
  GoalGroup,
  Intervention,
  MappingConditionOpportunity,
  MappingGoalGroupGoal,
  OpportunityPriorities,
} from 'src/services/api/care-plan';
import RHFDatePicker from 'src/components/hook-form/RHFDatePicker';
import { useCarePlanStatuses } from 'src/services/api/care-plan/statuses';
import { useSystemSetting } from 'src/services/api/settings/system';
import Label from 'src/components/Label';
import { useCarePlanConditions } from 'src/services/api/care-plan/conditions';
import { CarePlanCondition } from 'src/_mock/settings/_careplan-automation';
import sha256 from 'crypto-js/sha256';
import Hex from 'crypto-js/enc-hex';

export interface CreateCarePlanModalProps {
  onSave?: (carePlan: CarePlan) => void;
  onCancel?: () => void;
  currentCarePlan?: CarePlan | null;
}

type FormValue = {
  condition: CarePlanCondition | null;
  opportunity: MappingConditionOpportunity | null;
  group: GoalGroup | null;
  goal: MappingGoalGroupGoal | null;
  intervention: Intervention | null;
  startDate: Date | null;
  endDate: Date | null;
  priority: typeof OpportunityPriorities[number] | null;
  status: string | null;
};

const schema = Yup.object().shape({
  opportunity: Yup.object().nullable().required('Opportunity is required'),
  goal: Yup.object().nullable().required('Goal is required'),
  intervention: Yup.object().nullable().required('Intervention is required'),
  startDate: Yup.date().nullable().required('Start Date is required'),
  endDate: Yup.date().nullable().required('Due Date is required'),
  priority: Yup.object().nullable().required('Priority is required'),
  status: Yup.string().nullable().required('Status is required'),
});

const resolver = yupResolver(schema);

const filterOpportunities = createFilterOptions<MappingConditionOpportunity>({});
const filterGoals = createFilterOptions<MappingGoalGroupGoal>({});
const filterInterventions = createFilterOptions<Intervention>({});

function CreateCarePlanModal({ onSave, onCancel, currentCarePlan }: CreateCarePlanModalProps) {
  const [selectedCondition, setSelectedCondition] = useState<CarePlanCondition | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const patient = useCurrentPatient();
  const authUser = useAuth().getCurrentUser();
  const [, { find: findCarePlan, create: createCarePlan, update: updateCarePlan }] = useCarePlans({
    autofetch: false,
  });
  const { data: carePlanConditions } = useCarePlanConditions();
  const { data: carePlanStatuses = [], isLoading: isLoadingStatuses } = useCarePlanStatuses();
  const { data: carePlanAutomationSettings, isLoading: isLoadingSettings } =
    useSystemSetting('care-plan-automation');
  const {
    data: conditionsAndOpportunitiesMappings = [],
    isLoading: isLoadingOpportunities,
    refetch: refetchOpportunities,
  } = useQuery(['care-plan-automation/opportunities'], () =>
    api.carePlan.findMappingsConditionOpportunity()
  );

  const {
    data: goalGroupsAndGoalsMappings = [],
    isLoading: isLoadingGoals,
    refetch: refetchGoals,
  } = useQuery(['care-plan-automation/goals'], () => api.carePlan.findMappingsGoalGroupGoal());

  const {
    data: interventions = [],
    isLoading: isLoadingInterventions,
    refetch: refetchInterventions,
  } = useQuery(['care-plan-automation/interventions'], () => api.carePlan.findInterventions());

  const isLoading =
    isLoadingGoals ||
    isLoadingInterventions ||
    isLoadingOpportunities ||
    isLoadingStatuses ||
    isLoadingSettings;

  useEffect(() => {
    refetchOpportunities();
    refetchGoals();
    refetchInterventions();
  }, []);

  const defaultValues = useMemo((): FormValue => {
    const fallback = {
      condition: null,
      opportunity: null,
      group: null,
      goal: null,
      intervention: null,
      startDate: new Date(),
      endDate: null,
      priority: null,
      status: carePlanAutomationSettings?.defaultStatus ?? null,
    };
    const customOpportunityId = sha256(JSON.stringify(currentCarePlan?.title)).toString(Hex);
    const customGoalId = sha256(
      JSON.stringify(currentCarePlan?.contained?.[1].description?.text)
    ).toString(Hex);
    const customInterventionId = sha256(
      JSON.stringify(currentCarePlan?.contained?.[2]?.title)
    ).toString(Hex);

    if (!currentCarePlan || isLoading) {
      return fallback;
    }

    const carePlanCondition = currentCarePlan.contained?.find(
      (resource): resource is Condition => resource.resourceType === 'Condition'
    );

    if (!carePlanCondition) {
      console.error('CarePlan Condition is not found');
      return fallback;
    }

    const carePlanGoal = currentCarePlan.contained?.find(
      (resource): resource is Goal => resource.resourceType === 'Goal'
    );
    if (!carePlanGoal) {
      console.error('CarePlan Goal is not found');
      return fallback;
    }

    const carePlanActivityDefinition = currentCarePlan.contained?.find(
      (resource): resource is ActivityDefinition => resource.resourceType === 'ActivityDefinition'
    );
    if (!carePlanActivityDefinition) {
      console.error('CarePlan ActivityDefinition is not found');
      return fallback;
    }

    const opportunity =
      conditionsAndOpportunitiesMappings.find(
        (mapping) =>
          mapping.condition.id.toString() === carePlanCondition.code?.coding?.[0]?.id?.toString() &&
          mapping.opportunity.id.toString() === carePlanGoal.priority?.id?.toString()
      ) || null;

    const isCustomOpportunity = carePlanGoal.priority?.id === customOpportunityId;

    const goal =
      goalGroupsAndGoalsMappings.find(
        (mapping) =>
          mapping.goalGroup.id.toString() === carePlanGoal.category?.[0]?.id &&
          mapping.goal.id.toString() === carePlanGoal.description?.id
      ) || null;

    const isCustomGoal = currentCarePlan.contained?.[1]?.description?.id === customGoalId;

    const intervention =
      interventions.find(
        (intervention) =>
          intervention.id.toString() === carePlanActivityDefinition.code?.coding?.[0]?.id
      ) || null;

    const isCustomIntervention =
      currentCarePlan.contained?.[2]?.code?.coding?.[0].id === customInterventionId;

    if (!opportunity && !isCustomOpportunity) {
      console.error('CarePlan Opportunity is not found');
    }

    if (!goal && !isCustomGoal) {
      console.error('CarePlan Goal is not found');
    }

    console.log({
      isCustomIntervention,
    });

    if (!intervention && !isCustomIntervention) {
      console.error('CarePlan Intervention is not found');
    }

    const statusExtension = currentCarePlan.extension?.find(
      (extension) => extension.url === fhirSystem.extension.CarePlan.currentStatus.asString()
    )?.valueCoding;

    console.log({
      currentCarePlan,
    });

    return {
      condition: carePlanConditions?.find(
        (i) => i.id.toString() === carePlanCondition.code?.coding?.[0].id
      ),
      opportunity: isCustomOpportunity
        ? {
            opportunity: {
              id: customOpportunityId,
              name: currentCarePlan?.title,
            },
          }
        : opportunity,
      group: isCustomGoal
        ? {
            id: currentCarePlan.contained?.[1]?.category?.[0]?.id,
            name: currentCarePlan.contained?.[1]?.category?.[0]?.text,
          }
        : null,
      goal: isCustomGoal
        ? {
            goal: {
              id: customGoalId,
              name: currentCarePlan.contained?.[1]?.description?.text,
            },
            goalGroup: {
              id: currentCarePlan.contained?.[1]?.category?.[0]?.id,
              name: currentCarePlan.contained?.[1]?.category?.[0]?.text,
            },
          }
        : goal,
      intervention: isCustomIntervention
        ? {
            code: {
              coding: [
                {
                  id: customInterventionId,
                  display: currentCarePlan.contained?.[2]?.title,
                },
              ],
            },
            id: customInterventionId,
            name: currentCarePlan.contained?.[2]?.title,
            createsAppointmentAlert: false,
          }
        : intervention,
      startDate: currentCarePlan.period?.start
        ? moment(currentCarePlan.period.start).toDate()
        : null,
      endDate: currentCarePlan.period?.end ? moment(currentCarePlan.period.end).toDate() : null,
      priority:
        OpportunityPriorities.find(
          (priority) => priority.value === carePlanGoal.priority?.coding?.[0]?.code
        ) || null,
      status:
        carePlanStatuses.find((status) => status.uuid.toString() === statusExtension?.code)?.uuid ||
        null,
    };
  }, [currentCarePlan, isLoading]);

  const methods = useForm({
    resolver,
    defaultValues,
  });

  const {
    reset,
    formState: { isSubmitting },
  } = methods;

  const filteredConditionsAndOpportunitiesMappings = useMemo(
    () =>
      conditionsAndOpportunitiesMappings.filter((o) => o.condition.id === selectedCondition?.id) ??
      [],
    [conditionsAndOpportunitiesMappings, selectedCondition?.id]
  );

  const groupOptions = useMemo(() => {
    const groups: GoalGroup[] = [];

    goalGroupsAndGoalsMappings.forEach((i) => {
      if (!groups.find((g) => g.id === i.goalGroup.id)) {
        groups.push(i.goalGroup);
      }
    });

    return groups;
  }, [goalGroupsAndGoalsMappings]);

  const isCustomGoal = useMemo(
    () => !goalGroupsAndGoalsMappings.find((i) => i.id === methods.getValues('goal')?.id),
    [goalGroupsAndGoalsMappings, methods.watch('goal')]
  );

  useEffect(() => {
    const startDate = methods.watch('startDate');
    const endDate = methods.watch('endDate');
    if (!startDate || !endDate) {
      return;
    }

    if (startDate > endDate) {
      methods.setValue('endDate', startDate);
    }
  }, [methods.watch('startDate')]);

  useEffect(() => {
    if (!isLoading && currentCarePlan) {
      reset(defaultValues);
    }
    if (!isLoading) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCarePlan, defaultValues, isLoading]);

  const onSubmit = async (data: FormValue) => {
    if (!patient) {
      enqueueSnackbar('Patient is not selected', { variant: 'error' });
      return;
    }

    if (!authUser) {
      enqueueSnackbar('User is not authenticated', { variant: 'error' });
      return;
    }

    const subject = {
      id: patient.id || '',
      type: 'Patient',
      reference: `Patient/${patient.id}`,
      display: patient.getFullName() || '',
    };

    try {
      if (
        !data.condition ||
        !data.endDate ||
        !data.startDate ||
        !data.intervention ||
        !data.goal ||
        !data.opportunity ||
        !data.status
      ) {
        return;
      }

      if (isCustomGoal && !data.group) {
        methods.setError('group', 'You must select a goal group');
        return;
      }

      if (currentCarePlan) {
        const [carePlan] = await findCarePlan({
          _id: currentCarePlan?.id,
        });

        const updatedCarePlan = produce(carePlan, (draft) => {
          if (
            !data.endDate ||
            !data.startDate ||
            !data.intervention ||
            !data.goal ||
            !data.opportunity ||
            !data.status
          ) {
            return;
          }

          if (isCustomGoal && !data.group) {
            methods.setError('group', 'You must select a gaol group');
            return;
          }

          draft.title = data.opportunity.opportunity?.name;
          draft.extension ??= [];
          const extensions = draft.extension;

          const lastUpdatedExtension = extensions.find(
            (extension) => extension.url === fhirSystem.extension.CarePlan.lastUpdated.asString()
          );

          if (lastUpdatedExtension) {
            lastUpdatedExtension.valueDateTime = new Date().toISOString();
          } else {
            draft.extension.push({
              url: fhirSystem.extension.CarePlan.lastUpdated.asString(),
              valueDateTime: new Date().toISOString(),
            });
          }

          const createsAppointmentExtension = extensions.find(
            (extension) =>
              extension.url === fhirSystem.extension.CarePlan.createsAppointmentAlert.asString()
          );
          if (createsAppointmentExtension) {
            createsAppointmentExtension.valueBoolean = data.intervention.createsAppointmentAlert;
          } else {
            draft.extension.push({
              url: fhirSystem.extension.CarePlan.createsAppointmentAlert.asString(),
              valueBoolean: data.intervention.createsAppointmentAlert,
            });
          }

          const statusExtension = extensions.find(
            (extension) => extension.url === fhirSystem.extension.CarePlan.currentStatus.asString()
          );
          const selectedStatus = carePlanStatuses.find((status) => status.uuid === data.status);
          if (!selectedStatus) {
            return;
          }
          if (statusExtension) {
            statusExtension.valueCoding = {
              code: selectedStatus.uuid,
              display: selectedStatus.name,
            };
          } else {
            draft.extension.push({
              url: fhirSystem.extension.CarePlan.currentStatus.asString(),
              valueCoding: {
                code: selectedStatus.uuid,
                display: selectedStatus.name,
              },
            });
          }

          const condition = draft.contained?.find(
            (resource): resource is Condition => resource.resourceType === 'Condition'
          );
          if (condition) {
            condition.code = {
              coding: [
                {
                  id: data.condition.id.toString(),
                  display: data.condition.name,
                },
              ],
            };
          }

          const goal = draft.contained?.find(
            (resource): resource is Goal => resource.resourceType === 'Goal'
          );
          if (goal) {
            goal.category = [
              {
                id: isCustomGoal ? data.group?.id.toString() : data.goal.goalGroup.id.toString(),
                text: isCustomGoal ? data.group?.name : data.goal.goalGroup.name,
              },
            ];
            goal.description = {
              id: data.goal.goal.id.toString(),
              text: data.goal.goal.name,
            };
            goal.priority = {
              id: data.opportunity.opportunity.id.toString(),
              coding: [
                {
                  code: data.priority?.value,
                  display: data.priority?.label,
                },
              ],
            };
            goal.target = [
              {
                dueDate: data.endDate.toISOString().split('T')[0],
              },
            ];
          }

          const activityDefinition = draft.contained?.find(
            (resource): resource is ActivityDefinition =>
              resource.resourceType === 'ActivityDefinition'
          );
          if (activityDefinition) {
            activityDefinition.title = data.intervention.name;
            activityDefinition.code = {
              coding: [
                {
                  id: data.intervention.id.toString(),
                  display: data.intervention.name,
                },
              ],
            };
          }

          draft.period = {
            start: data.startDate.toISOString(),
            end: data.endDate.toISOString(),
          };
        });

        await updateCarePlan(updatedCarePlan);

        reset();
        enqueueSnackbar('Care Plan updated successfully', { variant: 'success' });
        onSave?.(updatedCarePlan);
        return;
      }

      const selectedStatus = carePlanStatuses.find((status) => status.uuid === data.status);
      if (!selectedStatus) {
        return;
      }

      const [carePlan] = await createCarePlan({
        resourceType: 'CarePlan',
        status: 'active',
        intent: 'order',
        subject,
        author: {
          display: 'Staff',
        },
        created: new Date().toISOString(),
        extension: [
          {
            url: fhirSystem.extension.CarePlan.createsAppointmentAlert.asString(),
            valueBoolean: data.intervention.createsAppointmentAlert,
          },
          {
            url: fhirSystem.extension.CarePlan.currentStatus.asString(),
            valueCoding: {
              code: selectedStatus.uuid,
              display: selectedStatus.name,
            },
          },
          {
            url: fhirSystem.extension.CarePlan.lastUpdated.asString(),
            valueDateTime: new Date().toISOString(),
          },
        ],
        title: data.opportunity.opportunity.name,
        contained: [
          {
            resourceType: 'Condition',
            id: 'condition',
            subject,
            code: {
              coding: [
                {
                  id: data.condition.id.toString(),
                  display: data.condition.name,
                },
              ],
            },
          },
          {
            resourceType: 'Goal',
            id: 'goal',
            subject,
            description: {
              id: data.goal.goal.id.toString(),
              text: data.goal.goal.name,
            },
            priority: {
              id: data.opportunity?.opportunity.id.toString(),
              coding: [
                {
                  code: data.priority?.value,
                  display: data.priority?.label,
                },
              ],
            },
            category: [
              {
                id: isCustomGoal ? data.group?.id.toString() : data.goal.goalGroup.id.toString(),
                text: isCustomGoal ? data.group?.name : data.goal.goalGroup.name,
              },
            ],
            lifecycleStatus: 'active',
            target: [
              {
                dueDate: data.endDate.toISOString().split('T')[0],
              },
            ],
          },
          {
            resourceType: 'ActivityDefinition',
            id: 'activity',
            title: data.intervention.name,
            code: {
              coding: [
                {
                  id: data.intervention.id.toString(),
                  display: data.intervention.name,
                },
              ],
            },
            status: 'active',
          },
        ],
        goal: [
          {
            reference: '#goal',
          },
        ],
        addresses: [
          {
            reference: '#condition',
          },
        ],
        activity: [
          {
            reference: {
              reference: '#activity',
            },
          },
        ],
        period: {
          start: data.startDate.toISOString(),
          end: data.endDate.toISOString(),
        },
      });

      await updateCarePlan(
        produce(carePlan, (draft) => {
          draft.identifier ??= [];
          draft.identifier?.push({
            system: fhirSystem.carePlan.asString(),
            value: '',
          });
        })
      );

      reset();
      enqueueSnackbar('Care Plan created successfully', { variant: 'success' });
      onSave?.(carePlan);
    } catch (error) {
      const message =
        error.response?.data?.message?.[0] || error?.message || 'Something went wrong!';

      enqueueSnackbar(message, {
        variant: 'error',
      });

      console.error(error);
    }
  };

  const goalGroupAndGoalFiltered = useMemo(() =>
    goalGroupsAndGoalsMappings.filter(
      i => i.opportunityId === methods.getValues('opportunity')?.opportunity?.id
    ),
  [methods.watch('opportunity')]);

  const interventionsFiltered = useMemo(() => interventions.filter((intervention) => methods.getValues('goal')?.interventions?.includes(intervention.id)) ?? [], [methods.watch('goal')])
  
  return (
    <Box>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Box
          sx={{
            my: 3,
            position: 'fixed',
            width: '320px',
            backgroundColor: 'white',
            opacity: 1,
            zIndex: 9,
            top: '-25px',
          }}
        >
          <Box sx={{ my: 3 }}>
            <Grid item>
              <PatientCard id={patient?.id || ''} />
            </Grid>
          </Box>
          <Box sx={{ my: 3 }}>
            <Grid item>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {currentCarePlan ? 'Update' : 'Add'} Care Plan Entry
              </Typography>
            </Grid>
          </Box>
          <Divider />
        </Box>
        <Stack sx={{ marginTop: '150px' }} gap={3}>
          <RHFAutocomplete
            name="condition"
            label="Condition"
            freeSolo={false}
            onChange={(e, value) => {
              setSelectedCondition(value);
              // methods.setValue('opportunity', null);
            }}
            options={carePlanConditions}
            getOptionLabel={(option) => option?.name ?? ''}
            TextFieldProps={{
              variant: 'standard',
            }}
            noOptionsText="No conditions found"
          />

          <RHFAutocomplete
            name="opportunity"
            label="Opportunity"
            freeSolo={false}
            options={filteredConditionsAndOpportunitiesMappings}
            getOptionLabel={(option) => option.opportunity?.name ?? ''}
            TextFieldProps={{
              variant: 'standard',
            }}
            filterOptions={(options, params) => {
              const filtered = filterOpportunities(options, params);

              const { inputValue } = params;
              const isExisting = options.some((option) => inputValue === option.opportunity.name);

              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  opportunity: {
                    id: sha256(JSON.stringify(inputValue)).toString(Hex),
                    name: inputValue,
                  },
                });
              }

              return filtered;
            }}
            noOptionsText="No opportunities found"
          />

          {methods.getValues('goal') && isCustomGoal && (
            <RHFAutocomplete
              name="group"
              label="Goal group"
              freeSolo={false}
              options={groupOptions}
              getOptionLabel={(option) => option.name ?? 'noname'}
              isOptionEqualToValue={(option, value) => option.Id === value.id}
              TextFieldProps={{
                variant: 'standard',
              }}
            />
          )}

          <RHFAutocomplete
            name="goal"
            label="Goal"
            freeSolo={false}
            options={goalGroupAndGoalFiltered}
            getOptionLabel={(option) => option.goal.name}
            groupBy={(option) => option.goalGroup.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            TextFieldProps={{
              variant: 'standard',
            }}
            filterOptions={(options, state) => {
              const filtered = filterGoals(options, state);

              const { inputValue } = state;
              const isExisting = options.some((option) => inputValue === option.goal.name);

              if (!isExisting && inputValue !== '') {
                const newGoalGroup: MappingGoalGroupGoal = {
                  goal: {
                    id: sha256(JSON.stringify(state.inputValue)).toString(Hex),
                    name: state.inputValue,
                  },
                  goalGroup: {
                    id: sha256(JSON.stringify('custom')).toString(Hex),
                    name: 'Custom',
                  },
                };

                filtered.push(newGoalGroup);
              }

              return filtered;
            }}
            onChange={() => {
              methods.setValue('group', null);
            }}
          />

          <RHFAutocomplete
            name="intervention"
            label="Intervention"
            freeSolo={false}
            options={interventionsFiltered}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            TextFieldProps={{
              variant: 'standard',
            }}
            filterOptions={(options, state) => {
              // const filteredOptions = options.filter((i) =>
              //   i.name.toLowerCase().includes(state.inputValue)
              // );
              // if (filteredOptions.length > 0) {
              //   return filteredOptions;
              // }

              // const newIntervention: Intervention = {
              //   id: sha256(JSON.stringify(state.inputValue)).toString(Hex),
              //   name: state.inputValue,
              //   createsAppointmentAlert: false,
              // };
              // return [newIntervention];
              const filtered = filterInterventions(options, state);

              const { inputValue } = state;
              const isExisting = options.some((option) => inputValue === option.name);

              if (!isExisting && inputValue !== '') {
                const newIntervention: Intervention = {
                  id: sha256(JSON.stringify(state.inputValue)).toString(Hex),
                  name: state.inputValue,
                  createsAppointmentAlert: false,
                };

                filtered.push(newIntervention);
              }

              return filtered;
            }}
          />

          <RHFDatePicker
            name="startDate"
            label="Start Date"
            TextFieldProps={{
              variant: 'standard',
            }}
          />

          <RHFDatePicker
            name="endDate"
            label="Due Date"
            minDate={methods.watch('startDate') || new Date()}
            TextFieldProps={{
              variant: 'standard',
            }}
          />

          <RHFAutocomplete
            name="priority"
            freeSolo={false}
            multiple={false}
            options={OpportunityPriorities}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            TextFieldProps={{
              variant: 'standard',
            }}
            label="Priority"
          />

          <RHFSelect name="status" size="small" fullWidth label="Status" variant="standard">
            {carePlanStatuses.map((item) => (
              <MenuItem key={item.uuid} value={item.uuid}>
                <Label color={item.color} fontSize={20}>
                  {item.name}
                </Label>
              </MenuItem>
            ))}
          </RHFSelect>

          <ActionButtons
            isLoading={isSubmitting}
            onLeftButtonPress={() => {
              reset();
              onCancel?.();
            }}
            leftButtonTitle="Cancel"
            rightButtonType="submit"
          />
        </Stack>
      </FormProvider>
    </Box>
  );
}

export default CreateCarePlanModal;
