// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
// other
import { getUserPermissions } from '../../../utils/permissions/get.user.permissions';
import { NavListProps } from '../../../components/nav-section/type';
import { matchPath } from 'react-router';
import Iconify from 'src/components/Iconify';
import { CCM_ACLS } from 'src/utils/permissions/permission.utils';
import { IS_CRS, Modules } from 'src/config';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard_02'),
  menuItem: getIcon('ic_menu_item'),
  checklist: getIcon('ic_checklist_03'),
  collaboration: getIcon('ic_collaboration'),
  record: getIcon('ic_record'),
  personalInformation: getIcon('ic_personal_information'),
  report: getIcon('ic_report'),
  rounding: <Iconify icon="material-symbols:fact-check-outline" />,
  permissions: <Iconify icon="material-symbols:admin-panel-settings-outline" />,
  task: <Iconify icon="material-symbols:format-list-bulleted" />,
};

interface SectionPermissions {
  subheader: string;
  itemsByPermission: Record<string, NavListProps>;
  module?: Modules;
}

const mappings: SectionPermissions[] = [
  {
    subheader: 'Admin',
    itemsByPermission: {
      'admin.users.menu': {
        title: 'Users',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user,
      },
      'admin.organizations.menu': {
        title: 'Organizations',
        path: PATH_DASHBOARD.organization.list,
        icon: ICONS.banking,
      },
      'admin.permissions.menu': {
        title: 'Permissions',
        path: PATH_DASHBOARD.general.permissions,
        icon: ICONS.permissions,
      },
    },
  },
  {
    subheader: 'TOOLS',
    module: Modules.CCM,
    itemsByPermission: {
      [CCM_ACLS.TASK_ACTIVITIES.MENU]: {
        title: 'Tasks / Activities',
        path: PATH_DASHBOARD.general.activities,
        icon: ICONS.checklist,
      },
      [CCM_ACLS.CENSUS.name]: {
        title: 'Census',
        path: PATH_DASHBOARD.general.roundings.census,
        icon: <Iconify icon={'icon-park-outline:hospital-bed'} />,
      },
      [CCM_ACLS.ROUNDINGS.name]: {
        title: 'Roundings',
        path: PATH_DASHBOARD.general.roundings.list,
        icon: ICONS.rounding,
        checkActive(pathname) {
          return (
            !!matchPath('/dashboard/roundings', pathname) ||
            !!matchPath('/dashboard/roundings/add-rounding', pathname) ||
            !!matchPath('/dashboard/roundings/:roundingId/edit', pathname)
          );
        },
      },
      [CCM_ACLS.GROUP_SESSIONS.name]: {
        title: 'Group Sessions',
        path: PATH_DASHBOARD.general.groupSessions,
        icon: <Iconify icon={'ic:outline-groups-3'} />,
        checkActive(pathname) {
          return (
            !!matchPath('/dashboard/group-sessions', pathname) ||
            !!matchPath('/dashboard/group-sessions/:groupSessionId/sessions', pathname) ||
            !!matchPath('/dashboard/group-sessions/:groupSessionId/edit', pathname) ||
            !!matchPath(
              '/dashboard/group-sessions/:groupSessionId/sessions/:sessionId/edit',
              pathname
            )
          );
        },
      },
      [CCM_ACLS.MED_PASS.name]: {
        title: 'Med Pass',
        path: PATH_DASHBOARD.general.medPass,
        icon: <Iconify icon={'material-symbols:medication-liquid-outline-sharp'} />,
        checkActive(pathname) {
          return !!matchPath('/dashboard/med-pass', pathname) ||
          !!matchPath('/dashboard/med-pass/medication-details/:medicationId/:date', pathname);
        },
      },
      'ccm.reports': {
        title: 'Analytics / Reports',
        path: PATH_DASHBOARD.general.reports,
        icon: ICONS.report,
        checkActive(pathname) {
          return (
            !!matchPath('/dashboard/patient/:patientId/reports/*', pathname) ||
            !!matchPath('/dashboard/reports/*', pathname)
          );
        },
      },
    },
  },
  {
    subheader: 'PATIENT DETAILS',
    module: Modules.CCM,
    itemsByPermission: {
      [CCM_ACLS.PATIENt_DEMOGRAPHICS.name]: {
        title: 'Patient Demographics',
        path: PATH_DASHBOARD.general.patientDemographics,
        icon: ICONS.personalInformation,
        checkActive(pathname) {
          return (
            !!matchPath('/dashboard/patient/:patientId/patient-demographics/*', pathname) ||
            !!matchPath('/dashboard/patient-demographics/*', pathname)
          );
        },
      },
      [CCM_ACLS.HEALTH_RECORD.name]: {
        title: 'Health Record',
        path: PATH_DASHBOARD.general.healthRecords,
        icon: ICONS.record,
        checkActive(pathname) {
          return (
            !!matchPath('/dashboard/patient/:patientId/health-record/*', pathname) ||
            !!matchPath('/dashboard/health-record/*', pathname)
          );
        },
      },
      [CCM_ACLS.CAREPLAN.name]: {
        title: 'Care Record',
        path: PATH_DASHBOARD.general.careRecord,
        icon: ICONS.collaboration,
        checkActive(pathname) {
          return (
            !!matchPath('/dashboard/patient/:patientId/care-record/*', pathname) ||
            !!matchPath('/dashboard/care-record/*', pathname)
          );
        },
      },
    },
  },
  {
    subheader: 'CRS',
    module: Modules.CRS,
    itemsByPermission: {
      'crs.dashboard.menu': {
        title: 'My Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
      },
      'crs.patient.menu': {
        title: 'Patient',
        path: PATH_DASHBOARD.crs.patient.list.value,
        icon: ICONS.personalInformation,
      },
      'crs.referral.menu': {
        title: 'Referral',
        path: PATH_DASHBOARD.crs.referral,
        icon: ICONS.collaboration,
      },
      'crs.case.menu': {
        title: 'Case',
        path: PATH_DASHBOARD.crs.case,
        icon: ICONS.checklist,
      },
      'crs.task.menu': {
        title: 'Task',
        path: PATH_DASHBOARD.crs.task,
        icon: ICONS.task,
      },
      // 'crs.workflow.menu': {
      //   title: 'Workflow',
      //   path: PATH_DASHBOARD.crs.workflow,
      //   icon: ICONS.personalInformation,
      // },
    },
  },
];

export interface Section {
  subheader: string;
  items: NavListProps[];
}

const getNavConfig = (): Section[] => {
  const userPermissions = getUserPermissions();
  const mappingsByModule = IS_CRS
    ? mappings.filter(({ module }) => module === Modules.CRS || module === undefined)
    : mappings;
  return mappingsByModule.reduce((sections, sectionPermissions) => {
    const permissions = Object.keys(sectionPermissions.itemsByPermission);
    const allowedPermissions = permissions.filter((permission) =>
      userPermissions.isAllowed(permission)
    );
    if (allowedPermissions.length > 0) {
      sections.push({
        subheader: sectionPermissions.subheader,
        items: allowedPermissions.map(
          (permission) => sectionPermissions.itemsByPermission[permission]
        ),
      });
    }
    return sections;
  }, [] as Section[]);
};

export default getNavConfig;
