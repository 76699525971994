// @ts-nocheck
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  RefObject,
  MutableRefObject,
} from 'react';
import { useNavigate, useLocation } from 'react-router';
// @mui
import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Container,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuList,
  MenuItem,
  Popper,
  Paper,
  Stack,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Card,
} from '@mui/material';
import { DatePicker } from '@mui/lab';
// utils
import cssStyles from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/Iconify';
import { usePatients } from 'src/@nicheaim/fhir-react';
import {
  Address,
  Patient,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import {
  IdentifierCode,
  PatientWrapper,
  WrappedPatient,
} from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { fullPatientService } from 'src/ccm/full-patient/services';
import { CreateFullPatientDto } from 'src/ccm/dto/create-full-patient.dto';
import { PATH_DASHBOARD } from 'src/routes/paths';
import usePatientTabsStore from 'src/stores/patient-tabs';
import { isEmpty } from 'lodash';
import getNavConfig, { Section } from '../navbar/NavConfig';
import { getIdentifier, getPrimaryPhone } from 'src/utils/fhir';
import client from 'src/services/api/_client';
import {
  AdvancedSearch,
  checkAclValidation,
  getRelatedAcls,
  permissionsAcls,
  SimpleSearch,
} from 'src/utils/permissions/permission.utils';
import useAuth from 'src/hooks/useAuth';
import { MAX_ALLOWED_OPENED_PETIENTS } from './constants';
import MaxWorkspaceModal from './MaxWorkspaceModal';
import AddAddress from 'src/sections/crs/referral/components/workflow-step/AddressChecklistItem/AddAddress';
import { useJsApiLoader } from '@react-google-maps/api';
import { IS_CRS } from 'src/config';
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));
const SearchbarResultsStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  alignItems: 'flex-start',
  backgroundColor: 'white',
  display: 'flex',
  left: 0,
  padding: '0 40px',
  position: 'absolute',
  width: '100%',
  zIndex: 99,
  boxShadow: '0px 5px 10px 0px #cdcdcd',
  marginTop: '30px',
}));

// ----------------------------------------------------------------------

interface AdvancedSearchResultsProps {
  patients: Patient[];
  showResults: boolean;
}

interface MPISearchResultsProps {
  patients: any;
  showResults: boolean;
  addPatient: () => void;
}

export default function Searchbar() {
  const { openPatientIds, openPatient } = usePatientTabsStore();
  const user = useAuth();
  const [isPatientCreationLoading, setIsPatientCreationLoading] = useState<boolean>(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [showCreatePatientFromMPIModal, setShowCreatePatientFromMPIModal] = useState(false);
  const [showMaxPatientAllowedModal, setShowMaxPatientAllowedModal] = useState(false);
  const [isOpenSimpleSearch, setOpenSimpleSearch] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [isOpenAdvancedSearch, setOpenAdvancedSearch] = useState(false);
  const [showAdvancedSearchResults, setShowAdvancedSearchResults] = useState(false);
  const [showMPISearchResults, setShowMPISearchResults] = useState(false);
  const [mpiSearchResult, setMPISearchResult] = useState<any>([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [dateOfBirthError, setDateOfBirthError] = useState(false);
  const [plan, setPlan] = useState('');
  const [lineOfBusiness, setLineOfBusiness] = useState('');
  const [program, setProgram] = useState('');
  const [address, setAddress] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [isAddressDialogOpen, setIsAddressDialogOpen] = useState(false);
  const [fhirAddress, setFhirAddress] = useState<Address | null>(null);

  const isClosingAddressDialog: MutableRefObject<boolean> = useRef(false);

  const JsOptions = useMemo<any>(
    () => ({
      id: 'google-map-script',
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
      libraries: ['places'],
    }),
    []
  );
  const { isLoaded: isGoogleAPILoaded } = useJsApiLoader(JsOptions);
  const onCloseAddressDialog = useCallback(() => {
    isClosingAddressDialog.current = true;
    setIsAddressDialogOpen(false);
  }, []);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleOpenSimpleSearch = () => {
    setOpenSimpleSearch((prev) => !prev);
    setTooltipOpen(false);
  };

  const handleCloseSimpleSearch = () => {
    setName('');
    setOpenSimpleSearch(false);
  };

  const handleOpenAdvancedSearch = () => {
    setOpenAdvancedSearch(true);
  };

  const handleCloseAdvancedSearch = () => {
    if (isAddressDialogOpen || isClosingAddressDialog.current) {
      isClosingAddressDialog.current = false;
      return;
    }
    setOpenAdvancedSearch(false);
    setShowAdvancedSearchResults(false);
  };

  const handleTiptoolToggle = () => {
    setTooltipOpen(!isTooltipOpen);
  };

  const handleTiptoolClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setTooltipOpen(false);
  };

  const toISODateString = (isoDateString: Date) => isoDateString?.toISOString().split('T')[0];
  const [, { create, find }] = usePatients({
    map: PatientWrapper,
    autofetch: false, //query && !isPatientCreationLoading ? true : false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [patients, setPatients] = useState<WrappedPatient[]>([]);

  const handleSimpleSearch = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = event.target;
    setName(value);
    if (value.length < 3) {
      return;
    }

    const formNameQuery = (name: string) => {
      const nameParts = name.trim().split(' ');
      if (!value) {
        return {};
      }
      if (nameParts.length === 1) {
        return { 'name:contains': name };
      } else {
        return { given: nameParts[0], family: nameParts[1] };
      }
    };

    // attempt search by identifier
    setIsLoading(true);
    const resultByIdentifier = await find({
      identifier: value,
    });
    const resultByName = await find(formNameQuery(value) as any);
    setIsLoading(false);
    setPatients([...resultByIdentifier, ...resultByName]);
  };

  const validateFields = () => {
    setFirstNameError(!firstName);
    setLastNameError(!lastName);
    setDateOfBirthError(!dateOfBirth);
    return firstName && lastName && dateOfBirth;
  };

  const handleAdvancedSearch = async () => {
    if (!validateFields()) return;

    const query = {} as any;
    if (firstName) {
      query['given:contains'] = firstName;
    }
    if (lastName) {
      query['family:contains'] = lastName;
    }
    if (dateOfBirth) {
      query.birthdate = toISODateString(dateOfBirth);
    }

    setIsLoading(true);
    const results = await find(query);
    setIsLoading(false);
    setPatients(results);
    setShowAdvancedSearchResults(true);
    setShowMPISearchResults(false);
  };

  const handleMPISearch = async (createIfNotExist?: boolean): Promise<any> => {
    const payload = {
      name: [{ family: lastName, given: [firstName] }],
      birthDate: dateOfBirth ? toISODateString(dateOfBirth) : null,
      createIfNotExist,
    };

    const res = await client.post('/mpi/search', payload);
    const data = res?.data[0];

    if (isEmpty(data)) {
      setMPISearchResult([]);
    } else {
      const patient = {
        uuid: data?.uuid,
        firstName: data?.name?.[0]?.given?.[0],
        lastName: data?.name?.[0]?.family,
        birthDate: data?.birthDate,
        phone: getPrimaryPhone(data?.telecom)?.value,
      };
      setMPISearchResult([patient]);
    }
    setShowAdvancedSearchResults(false);
    setShowMPISearchResults(true);

    return data;
  };

  const searchModule = (pathname: string) =>
    getNavConfig().find((section: Section) =>
      section.items.find((item) => pathname.includes(item.path))
    );

  const currentModule = searchModule(location.pathname);

  const clearForm = () => {
    setFhirAddress(null);
    setFirstName('');
    setLastName('');
    setMiddleName('');
    setDateOfBirth(null);
    setPlan('');
    setLineOfBusiness('');
    setProgram('');
    setAddress('');
    setIdentifier('');
    setMPISearchResult([]);
    setShowAdvancedSearchResults(false);
    setShowMPISearchResults(false);
  };

  useEffect(() => {
    if (!isLoading && showAdvancedSearchResults && patients.length === 0) {
      handleMPISearch(false);
    }
  }, [showAdvancedSearchResults, patients, isLoading, isPatientCreationLoading]);

  const handleOpenPerson = (person: any) => {
    let shouldOpenPatient = true;
    if (currentModule?.subheader === 'CRS') {
      navigate(PATH_DASHBOARD.crs.patient.details.forId(person.id || ''));
    } else {
      if (openPatientIds.length >= MAX_ALLOWED_OPENED_PETIENTS) {
        shouldOpenPatient = false;
        setShowMaxPatientAllowedModal(true);
      }
    }

    if (shouldOpenPatient) {
      openPatient(person.id);
      clearForm();
      handleCloseSimpleSearch();
      handleCloseAdvancedSearch();
    }
  };

  const handleAddPatient = async () => {
    try {
      if (!isEmpty(firstName) && !isEmpty(lastName) && dateOfBirth !== null) {
        setIsPatientCreationLoading(true);

        const result = await handleMPISearch(true);
        let fullPatient: CreateFullPatientDto | null = null;

        result?.identifier?.push({
          use: 'official',
          type: 'master_patient_uuid',
          value: result?.uuid,
          system: `${process.env.REACT_APP_MPI_SEARCH_URL}/patients`,
        });

        const mrn = getIdentifier(result, IdentifierCode.MEDICAL_RECORD_NUMBER);
        const master_person_uuid = getIdentifier(result, IdentifierCode.MASTER_PERSON_UUID);
        const master_patient_uuid = getIdentifier(result, IdentifierCode.MASTER_PATIENT_UUID);

        if (!isEmpty(mrn) && !isEmpty(master_person_uuid) && !isEmpty(master_patient_uuid)) {
          const [fhirPatient] = await create({
            resourceType: 'Patient',
            active: true,
            identifier: [mrn, master_person_uuid, master_patient_uuid],
            name: [{ given: [firstName, middleName], family: lastName }],
            birthDate: dateOfBirth?.toISOString().split('T')[0],
            address: fhirAddress ? [fhirAddress] : undefined,
          });

          if (!showCreatePatientFromMPIModal) {
            fullPatient = new CreateFullPatientDto();
            fullPatient.fhirRefUri = fhirPatient.id!;
            fullPatient.given = firstName;
            fullPatient.family = lastName;
            fullPatient.fullName = `${firstName} ${lastName}`;
            fullPatient.status = 'new';

            await fullPatientService.create(fullPatient);
          }

          if (currentModule?.subheader === 'CRS') {
            handleCloseAdvancedSearch();
            navigate(PATH_DASHBOARD.crs.patient.details.forId(fhirPatient.id || ''));
          } else {
            const tempPatient = {
              id: fhirPatient.id,
              uuid: fhirPatient.id,
              firstName,
              lastName,
              fullPatient: `${firstName} ${lastName}`,
              status: 'new',
              fhirRefUri: fhirPatient.id!,
              birthDate: dateOfBirth?.toISOString().split('T')[0],
            };
            setMPISearchResult([tempPatient]);
          }

          setIsPatientCreationLoading(false);
          setDisableButtons(false);
          onCloseCreatePatientFromMPIModal();
          handleOpenPerson(fhirPatient);
          handleCloseAdvancedSearch();
        }
      }
    } catch (e) {
      setIsPatientCreationLoading(false);
      console.error(e);
    }
  };

  const MPISearchResults = ({ showResults, patients, addPatient }: MPISearchResultsProps) => (
    <Stack spacing={2}>
      {showResults && patients.length ? (
        patients.map((patient: any) => (
          <Stack key={patient.uuid} direction="row" sx={{ alignItems: 'center' }}>
            <Box sx={{ cursor: 'pointer' }}>
              <Grid container spacing={2}>
                <Grid item>
                  <Avatar />
                </Grid>
                <Grid item sx={{ color: 'text.primary' }}>
                  <Stack>
                    <Typography variant="body1">
                      {patient?.firstName} {patient?.lastName}
                    </Typography>
                    <Typography variant="caption">
                      {patient?.phone?.[0]} / {patient?.birthDate}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            {isPatientCreationLoading && <CircularProgress size={24} sx={{ ml: '14px' }} />}
          </Stack>
        ))
      ) : (
        <Box>
          <Typography variant="body1" sx={{ color: '#637381', textTransform: 'uppercase' }}>
            No results were found
          </Typography>
          <Stack direction="row" sx={{ alignItems: 'baseline' }}>
            <Typography variant="body1" sx={{ color: '#637381' }}>
              Do you want to{' '}
            </Typography>
            <Button variant="text" disabled={isPatientCreationLoading} onClick={addPatient}>
              Create a new Patient
            </Button>
          </Stack>
        </Box>
      )}
    </Stack>
  );

  const advancedSearchResults = ({ showResults, patients }: AdvancedSearchResultsProps) => {
    const handleOpenPerson = (person: any) => {
      let shouldOpenPatient = true;

      if (currentModule?.subheader === 'CRS') {
        navigate(PATH_DASHBOARD.crs.patient.details.forId(person.id || ''));
      } else {
        if (openPatientIds.length >= MAX_ALLOWED_OPENED_PETIENTS) {
          shouldOpenPatient = false;
        }
      }

      if (shouldOpenPatient) {
        handleCloseAdvancedSearch();
        openPatient(person.id);
        clearForm();
      }
    };

    return (
      <Stack spacing={2}>
        {showResults && patients.length ? (
          patients.map((patient) => (
            <Box
              key={patient.id}
              onClick={() => handleOpenPerson(patient)}
              sx={{ cursor: 'pointer' }}
            >
              <Grid container spacing={2}>
                <Grid item>
                  <Avatar />
                </Grid>
                <Grid item sx={{ color: 'text.primary' }}>
                  <Stack>
                    <Typography variant="body1">
                      {patient?.name?.[0]?.given?.join(' ')} {patient?.name?.[0]?.family}
                    </Typography>
                    <Typography variant="caption">
                      {patient?.telecom?.filter((t) => t.system === 'phone')?.[0]?.value} /{' '}
                      {patient?.birthDate}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          ))
        ) : (
          <Box>
            <Typography variant="body1" sx={{ color: '#637381', textTransform: 'uppercase' }}>
              No results were found
            </Typography>
          </Box>
        )}
      </Stack>
    );
  };

  const onCloseCreatePatientFromMPIModal = () => setShowCreatePatientFromMPIModal(false);

  const onCloseShowMaxPatientAllowedModal = () => setShowMaxPatientAllowedModal(false);

  const simpleSearchCRS = getRelatedAcls(SimpleSearch) || [];
  const advancedSearchCRS = getRelatedAcls(AdvancedSearch) || [];

  return (
    <>
      <ClickAwayListener onClickAway={handleCloseSimpleSearch}>
        <div>
          {/* CREATE PATIENT FROM MPI RESULT */}
          <Dialog open={showCreatePatientFromMPIModal} onClose={onCloseCreatePatientFromMPIModal}>
            <DialogTitle id="delete-note-modal-title">
              {'This patient does not exists on the database'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="delete-note-modal-description">
                Do you want to create this patient?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disabled={disableButtons} onClick={onCloseCreatePatientFromMPIModal}>
                No, go back
              </Button>
              <Button
                disabled={disableButtons}
                onClick={() => {
                  setDisableButtons(true);
                  handleAddPatient();
                }}
              >
                Yes, create
              </Button>
            </DialogActions>
          </Dialog>
          {/* CCM PATIENT LIMIT WORKSPACE MODAL */}
          <MaxWorkspaceModal
            open={showMaxPatientAllowedModal}
            onClose={onCloseShowMaxPatientAllowedModal}
          />
          <ButtonGroup sx={{ paddingRight: 3 }} ref={anchorRef}>
            <IconButton size="small" onClick={handleOpenSimpleSearch}>
              <Iconify icon={'eva:search-fill'} width={20} height={20} />
            </IconButton>
            <IconButton size="small" onClick={handleTiptoolToggle}>
              <Iconify icon={'eva:arrow-ios-downward-outline'} width={20} height={20} />
            </IconButton>
          </ButtonGroup>
          <Popper
            disablePortal
            anchorEl={anchorRef.current}
            open={isTooltipOpen}
            sx={{
              zIndex: 1,
            }}
            placement="bottom-start"
            onResize={undefined}
            onResizeCapture={undefined}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleTiptoolClose}>
                <MenuList autoFocusItem>
                  {checkAclValidation({
                    user,
                    acl: [...permissionsAcls.simpleSearch, ...simpleSearchCRS],
                  }) && (
                    <MenuItem onClick={handleOpenSimpleSearch}>
                      <Iconify icon={'eva:search-fill'} width={20} height={20} />
                      Search
                    </MenuItem>
                  )}
                  {checkAclValidation({
                    user,
                    acl: [...permissionsAcls.advancedSearch, ...advancedSearchCRS],
                  }) && (
                    <MenuItem onClick={handleOpenAdvancedSearch}>
                      <Iconify icon={'eva:maximize-outline'} width={20} height={20} />
                      Advanced Search
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popper>

          {isOpenSimpleSearch && (
            <Container>
              <SearchbarStyle>
                <Input
                  autoFocus
                  fullWidth
                  disableUnderline
                  placeholder="Search…"
                  sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify
                        icon={'eva:search-fill'}
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  }
                  onChange={handleSimpleSearch}
                />
                <Button variant="contained" onClick={handleCloseSimpleSearch}>
                  Search
                </Button>
              </SearchbarStyle>
              <SearchbarResultsStyle>
                {patients.length && name.length >= 3 && (
                  <List
                    sx={{
                      color: 'text.primary',
                      width: '100%',
                      maxHeight: 600,
                      overflow: 'scroll',
                    }}
                  >
                    {patients?.map((patient) => (
                      <ListItem
                        key={patient.id}
                        alignItems="flex-start"
                        onClick={() => handleOpenPerson(patient)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <ListItemAvatar>
                          {/* //TODO: refactor intoname getter */}
                          <Avatar
                            alt={`${patient?.name?.[0]?.given?.join(' ')} ${
                              patient?.name?.[0]?.family
                            }`}
                            src="/static/images/avatar/1.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${patient?.name?.[0]?.given?.join(' ')} ${
                            patient?.name?.[0]?.family
                          }`}
                          secondary={
                            <>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                              >
                                {patient?.telecom?.filter((t) => t.system === 'phone')?.[0]?.value}{' '}
                                / {patient?.birthDate}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
                {!isLoading && patients.length === 0 && name.length > 0 && (
                  <List sx={{ color: 'text.primary' }}>
                    <ListItem alignItems="flex-start">
                      <ListItemText primary={`No results were found for patient ${name}`} />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </List>
                )}
              </SearchbarResultsStyle>
            </Container>
          )}

          {isOpenAdvancedSearch && (
            <ClickAwayListener onClickAway={handleCloseAdvancedSearch}>
              <SearchbarResultsStyle>
                <Container sx={{ padding: 2 }}>
                  <Stack direction="row" spacing={4}>
                    <Stack spacing={2}>
                      <Typography
                        variant="caption"
                        sx={{ color: '#637381', textTransform: 'uppercase' }}
                      >
                        Advanced Search
                      </Typography>
                      <TextField
                        label="First Name"
                        size="small"
                        value={firstName}
                        error={firstNameError}
                        onChange={(ev) => {
                          setFirstName(ev.target.value);
                          if (showMPISearchResults) {
                            setShowMPISearchResults(false);
                          }
                        }}
                      />
                      <TextField
                        label="Last Name"
                        size="small"
                        value={lastName}
                        error={lastNameError}
                        onChange={(ev) => {
                          setLastName(ev.target.value);
                          if (showMPISearchResults) {
                            setShowMPISearchResults(false);
                          }
                        }}
                      />
                      <DatePicker
                        label="Date of Birth"
                        value={dateOfBirth}
                        onChange={(date) => {
                          setDateOfBirth(date);
                          if (showMPISearchResults) {
                            setShowMPISearchResults(false);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" error={dateOfBirthError} />
                        )}
                      />
                      <Typography variant="caption">Other Details</Typography>
                      <TextField
                        label="Middle Name"
                        size="small"
                        value={middleName}
                        onChange={(ev) => setMiddleName(ev.target.value)}
                      />
                      <TextField
                        label="Plan"
                        size="small"
                        value={plan}
                        onChange={(ev) => setPlan(ev.target.value)}
                      />
                      <TextField
                        label="Line of Business"
                        size="small"
                        value={lineOfBusiness}
                        onChange={(ev) => setLineOfBusiness(ev.target.value)}
                      />
                      <TextField
                        label="Program"
                        size="small"
                        value={program}
                        onChange={(ev) => setProgram(ev.target.value)}
                      />
                      <Accordion>
                        <AccordionSummary expandIcon={<Iconify icon={'eva:plus-outline'} />}>
                          Identifiers
                        </AccordionSummary>
                        <AccordionDetails>
                          <TextField
                            label="Identifiers"
                            size="small"
                            value={identifier}
                            onChange={(ev) => setIdentifier(ev.target.value)}
                          />
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary expandIcon={<Iconify icon={'eva:plus-outline'} />}>
                          Address
                        </AccordionSummary>
                        <AccordionDetails>
                          <TextField
                            label={!IS_CRS ? 'Address' : undefined}
                            placeholder="Address"
                            size="small"
                            value={address}
                            defaultValue={''}
                            InputProps={{
                              readOnly: IS_CRS,
                              onClick: () => {
                                if (!IS_CRS) return;
                                setIsAddressDialogOpen(true);
                              },
                            }}
                            onChange={(ev) => {
                              if (IS_CRS) return;
                              setAddress(ev.target.value);
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                      <Stack direction="row" justifyContent="flex-end" spacing={2}>
                        <Button color="primary" variant="outlined" onClick={clearForm}>
                          Clear All
                        </Button>
                        <Button color="primary" variant="contained" onClick={handleAdvancedSearch}>
                          Search
                        </Button>
                      </Stack>
                    </Stack>
                    <Stack spacing={2}>
                      <Typography
                        variant="caption"
                        sx={{ color: '#637381', textTransform: 'uppercase' }}
                      >
                        Patients
                      </Typography>
                      {showAdvancedSearchResults
                        ? advancedSearchResults({
                            patients,
                            showResults: showAdvancedSearchResults,
                          })
                        : null}
                      {showMPISearchResults ? (
                        <MPISearchResults
                          showResults={showMPISearchResults}
                          patients={mpiSearchResult}
                          addPatient={handleAddPatient}
                        />
                      ) : null}
                    </Stack>
                  </Stack>
                </Container>
              </SearchbarResultsStyle>
            </ClickAwayListener>
          )}
        </div>
      </ClickAwayListener>
      {!!isGoogleAPILoaded && (
        <Dialog
          disableEscapeKeyDown={false}
          open={isAddressDialogOpen}
          maxWidth="md"
          onClose={onCloseAddressDialog}
        >
          <DialogTitle> Add Address</DialogTitle>
          <Card sx={{ p: 2, overflowY: 'scroll' }}>
            <AddAddress
              externalAddress={fhirAddress}
              isEditable={true}
              handleClose={onCloseAddressDialog}
              handleSave={(address: Address) => {
                setFhirAddress(address);
                setAddress(formatAddress(address));
                onCloseAddressDialog();
              }}
              height={'620px'}
            />
          </Card>
        </Dialog>
      )}
    </>
  );
}

const formatAddress = (address: Address): string => {
  const { line, city, state, postalCode, country } = address ?? {};

  let formattedAddressSegments = [];

  if (line && line.length > 0) formattedAddressSegments.push(line.join(' ').trim());
  if (city) formattedAddressSegments.push(city.trim());
  if (state || postalCode)
    formattedAddressSegments.push(`${state || ''} ${postalCode || ''}`.trim());
  if (country) formattedAddressSegments.push(country.trim());

  return formattedAddressSegments.join(', ').trim();
};
