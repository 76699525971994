import { createContext } from "react";
import Rounding from "../../../Model/Rounding";

type RoundingItemContextType = {
  rounding: Rounding | null;
  roundingListIndex: number | null;
}

export const RoundingItemContext = createContext<RoundingItemContextType>({
  rounding: null,
  roundingListIndex: null,
});