import axios from 'axios';
import Cookies from 'js-cookie';
import { FHIR_COOKIE_KEY } from 'src/@nicheaim/fhir-base/clients/axios';
import { config } from 'src/config';

const FHIR_API = process.env.REACT_APP_FHIR_API_BASE_URL;

const axiosFhirInstance = axios.create({
  baseURL: FHIR_API,
  headers: {
    Authorization: `Bearer ${Cookies.get(FHIR_COOKIE_KEY)}`,
  },
});

if (config.fhirTenantId) {
  axiosFhirInstance.defaults.headers.common['X-Tenant-Id'] = config.fhirTenantId;
}

axiosFhirInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosFhirInstance;
