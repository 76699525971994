import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Button } from '@mui/material';
// components
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { useCodeSystem } from 'src/@nicheaim/fhir-react';
import { useDialogStore } from 'src/stores/dialog';
import uuidv4 from 'src/utils/uuidv4';
import produce from 'immer';

// ----------------------------------------------------------------------

type FormValue = {
  name: string;
};

type PlanFormProps = {
  isEdit?: boolean;
  currentReason?: string;
};

const schema = Yup.object().shape({
  name: Yup.string().required('Reason is required'),
});

const resolver = yupResolver(schema);

export default function NewProgramDisenrollmentReasonForm({
  isEdit,
  currentReason,
}: PlanFormProps) {
  const navigate = useNavigate();
  const confirm = useDialogStore((s) => s.confirmDialog);
  const { enqueueSnackbar } = useSnackbar();
  const [disenrollmentReasonsCodeSystem, { update, isLoading }] = useCodeSystem(
    'ccm-program-disenrollment-reasons',
    {
      autofetch: isEdit,
    }
  );
  const disenrollmentReason = useMemo(
    () =>
      disenrollmentReasonsCodeSystem?.concept?.find((concept) => concept.code === currentReason),
    [disenrollmentReasonsCodeSystem, currentReason]
  );

  console.log({
    disenrollmentReasonsCodeSystem,
    disenrollmentReason,
    currentReason,
  });

  const defaultValues = useMemo(
    () =>
      ({
        name: disenrollmentReason?.display || '',
      } as FormValue),
    [disenrollmentReason]
  );

  const methods = useForm({ resolver, defaultValues });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentReason) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, disenrollmentReason]);

  const onSubmit = async (data: FormValue) => {
    if (isLoading) return;

    if (!disenrollmentReasonsCodeSystem) {
      await update({
        id: 'ccm-program-disenrollment-reasons',
        resourceType: 'CodeSystem',
        content: 'complete',
        description: 'Program Disenrollment Reasons',
        status: 'active',
        concept: [
          {
            code: uuidv4(),
            display: data.name,
          },
        ],
      });
      navigate('..');
      return;
    }

    try {
      if (isEdit && currentReason) {
        await update({
          ...disenrollmentReasonsCodeSystem,
          concept:
            disenrollmentReasonsCodeSystem.concept?.map((concept) => {
              if (concept.code === currentReason) {
                return {
                  ...concept,
                  display: data.name,
                };
              }

              return concept;
            }) || [],
          ...data,
        });
      } else {
        disenrollmentReasonsCodeSystem?.concept?.push({
          code: uuidv4(),
          display: data.name,
        });

        await update(disenrollmentReasonsCodeSystem);
      }

      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      navigate(isEdit ? '../..' : '..');
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = async () => {
    if (!disenrollmentReasonsCodeSystem) return;

    // ask for confirmation before deleting
    const confirmed = await confirm({
      title: 'Delete Disenrollment Reason',
      description: 'Are you sure you want to delete this disenrollment reason?',
    });

    if (confirmed) {
      try {
        await update(
          produce(disenrollmentReasonsCodeSystem, (draft) => {
            const concept = draft.concept?.find((c) => c.code === currentReason);

            if (!concept) {
              return;
            }

            const property = concept.property?.find((p) => p.code === 'active');

            if (property) {
              property.valueBoolean = false;
            } else {
              concept.property ??= [];
              concept.property.push({
                code: 'active',
                valueBoolean: false,
              });
            }
          })
        );

        enqueueSnackbar('Delete success!');
        navigate('..');
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 3 }}>
        <Stack
          gap={3}
          sx={{
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <RHFTextField name="name" label="Program Disenrollment Reason" />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }} gap={2}>
          {isEdit && (
            <Button variant="text" color="error" onClick={onDelete}>
              Delete
            </Button>
          )}
          <Button variant="text" color="inherit" component={RouterLink} to="..">
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {!isEdit ? 'Create Disenrollment Reason' : 'Save Changes'}
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
