import { Button, Chip, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { DocumentFilters } from "src/@types/crs/patient";
import Iconify from "src/components/Iconify";
import { parseShortFormat } from "src/utils/formatTime";

const RootStyle = styled('div')({
  flexGrow: 1,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});

const WrapperStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'stretch',
  margin: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.divider}`,
}));

const LabelStyle = styled((props) => (
  <Typography component="span" variant="subtitle2" {...props} />
))(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  borderRight: `solid 1px ${theme.palette.divider}`,
}));

type Props = {
  filters: DocumentFilters;
  onClearAll: () => void;
};

export default function DocumentsTagFiltered ({
  filters,
  onClearAll,
}: Props) { 

  const { filterName, filterDate, filterType, filterCustodian, filterAuthor, filterCategory } = filters;

  const setFilters = 
    filterName || 
    filterDate || 
    filterType || 
    filterCustodian || 
    filterAuthor.length > 0 || 
    filterCategory.length > 0; 
  
  return(
    <RootStyle>

      {filterName && (
        <WrapperStyle>
          <LabelStyle>Name:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            <Chip size="small" label={filterName} sx={{ m: 0.5 }} />
          </Stack>
        </WrapperStyle>
      )}

      {filterDate && (
        <WrapperStyle>
          <LabelStyle>Date:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            <Chip size="small" label={parseShortFormat(filterDate.toString())} sx={{ m: 0.5 }} />
          </Stack>
        </WrapperStyle>
      )}

      {filterType && (
        <WrapperStyle>
          <LabelStyle>Type:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            <Chip size="small" label={filterType} sx={{ m: 0.5 }} />
          </Stack>
        </WrapperStyle>
      )}

      {filterCustodian && (
        <WrapperStyle>
          <LabelStyle>Custodian:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            <Chip size="small" label={filterCustodian} sx={{ m: 0.5 }} />
          </Stack>
        </WrapperStyle>
      )}

      {filterAuthor.length > 0 && (
        <WrapperStyle>
          <LabelStyle>Author:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            {filterAuthor.map((author, index) => (
              <Chip
                key={`authorShowFilter-${index}`}
                label={author}
                size="small"
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}

      {filterCategory.length > 0 && (
        <WrapperStyle>
          <LabelStyle>Category:</LabelStyle>
          <Stack direction="row" flexWrap="wrap" sx={{ p: 0.75 }}>
            {filterCategory.map((category, index) => (
              <Chip
                key={`categoryShowFilter-${index}`}
                label={category}
                size="small"
                sx={{ m: 0.5 }}
              />
            ))}
          </Stack>
        </WrapperStyle>
      )}

      {setFilters && (
        <Button
          color="error"
          size="small"
          onClick={onClearAll}
            startIcon={<Iconify icon={'eva:trash-2-outline'} />}
          >
            Clear All
          </Button>
      )}

    </RootStyle>
  )
}