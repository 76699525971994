// @ts-nocheck
import { capitalize } from 'lodash';
import {
  Address,
  ContactPoint,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { createWrapper } from '../../fhir-react/base';

export const RelatedPersonWrapper = createWrapper('RelatedPerson', (relatedPerson) => ({
  getIdAsReference(): string {
    return `RelatedPerson/${relatedPerson?.id}`;
  },

  getFullName(): string | null {
    let fullName = null;
    if (relatedPerson.name?.[0]?.given?.[0] && relatedPerson.name?.[0]?.family) {
      fullName = `${relatedPerson.name?.[0]?.given?.[0]} ${relatedPerson.name?.[0]?.family}`;
    }
    return fullName;
  },

  getFullNameWithTitles(): string | null {
    if (!relatedPerson.name) return null;
    const { prefix, suffix, given, family } = relatedPerson.name[0];
    return [prefix?.[0], given?.[0], given?.[1]?.[0], family, suffix].filter((n) => n).join(' ');
  },

  getPrimaryAddress(): Address | null {
    let mainAddress = null;
    if (relatedPerson.address && relatedPerson.address?.length > 0) {
      mainAddress = relatedPerson.address[0];
    }
    return mainAddress;
  },

  getPrimaryAddressFormatted(): string | null {
    const mainAddress = this.getPrimaryAddress();

    if (!mainAddress) return null;

    const addr = [];
    if (mainAddress?.line) addr.push(mainAddress.line.filter((l) => l).join(' '));
    if (mainAddress?.city) addr.push(mainAddress.city);
    if (mainAddress?.state) addr.push(`${mainAddress.state},`);
    if (mainAddress?.postalCode) addr.push(mainAddress.postalCode);
    if (mainAddress?.use) addr.push(`(${capitalize(mainAddress.use)})`);

    return addr.join(' ');
  },

  getPrimaryPhone(): ContactPoint | null {
    let mainPhone = null;
    if (relatedPerson.telecom && relatedPerson.telecom?.length > 0) {
      const phones = relatedPerson.telecom.filter((t) => t?.system === 'phone');
      if (phones && phones?.length > 0) {
        mainPhone = phones[0];
      }
    }
    return mainPhone;
  },

  getPhones(): ContactPoint[] | null {
    return relatedPerson?.telecom?.filter((t) => t?.system === 'phone') || null;
  },

  getPrimaryEmail(): ContactPoint | null {
    let mainPhone = null;
    if (relatedPerson.telecom && relatedPerson.telecom?.length > 0) {
      const phones = relatedPerson.telecom.filter((t) => t?.system === 'email');
      if (phones && phones?.length > 0) {
        mainPhone = phones[0];
      }
    }
    return mainPhone;
  },

  getEmails(): ContactPoint[] | null {
    return relatedPerson?.telecom?.filter((t) => t?.system === 'email') || null;
  },
}));

export type WrappedRelatedPerson = ReturnType<typeof RelatedPersonWrapper>;
