import useRecoverableChanges from 'src/hooks/useRecoverableChanges';
import useCarePlanAutomationFormStore from './store';

export default function RecoverUnsavedChanges() {
  const store = useCarePlanAutomationFormStore();

  useRecoverableChanges({
    key: 'care-plan-automation-form',
    state: {
      assessmentId: store.selectedAssessmentId,
      automationSheetId: store.selectedAutomationSheetId,
      rules: store.rules,
    },
    // if there is an assessment id set, we probably still have the changes loaded
    dialog: store.selectedAssessmentId
      ? {
          title: 'Keep unsaved changes?',
          description: 'You have not saved your changes. Do you want to keep them, or start over?',
          recoverText: 'Keep',
        }
      : undefined,
    areChangesUnsaved(state) {
      return !!state?.assessmentId;
    },
    async onRecover(changes) {
      if (store.selectedAssessmentId) {
        // this means we already have the changes loaded, so we don't need to recover them
        return;
      }

      await store.loadAutomationSheet(changes.automationSheetId);
      changes.rules.forEach((rule) => {
        store.addRule(
          {
            linkId: rule.question,
            type: rule.questionType,
          },
          rule
        );
      });
    },
    onDiscard() {
      store.reset();
    },
  });

  return null;
}
