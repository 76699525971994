import { Alert, Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Page from 'src/components/Page';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import ReportsIframe from './ReportsIframe';
import AuthTokenGuard from '../common/AuthTokenGuard';

export default function Reports() {
  const user = useAuth();
  const patient = useCurrentPatient();
  const { themeStretch } = useSettings();

  const [userId, setUserId] = useState<string>();
  const [fhirUserId, setFhirUserId] = useState<string>();

  useEffect(() => {
    const currentUser = user.getCurrentUser();
    if (currentUser.id === undefined) return;
    setUserId(currentUser.id);
    if (currentUser.user_fhir_uri === undefined) return;
    setFhirUserId(currentUser.user_fhir_uri);
  }, [user]);

  return (
    <Page title="Reports" sx={{ height: '100%' }}>
      <Container maxWidth={themeStretch ? false : 'xl'} sx={{ height: '100%' }}>
        <Grid container spacing={3} sx={{ height: '100%' }}>
          <Grid item xs={12}>
            <Breadcrumbs
              title="Patient Demographics"
              links={[
                {
                  href: '',
                  name: 'Dashboard',
                },
                {
                  href: '',
                  name: 'Reports',
                },
                {
                  href: '',
                  name: patient?.getFullName() || '-',
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} sx={{ height: '100%' }}>
            <AuthTokenGuard>
              {patient?.id !== undefined && userId !== undefined && fhirUserId !== undefined && (
                <ReportsIframe iamUserId={userId} fhirUserId={fhirUserId} patientId={patient.id} />
              )}
            </AuthTokenGuard>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
