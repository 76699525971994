import { MobileDatePicker } from '@mui/lab';
import Iconify from 'src/components/Iconify';
import { Draggable } from 'react-beautiful-dnd';
import { 
  AddContactDetailsEmail, 
  checkAclValidation, 
  DeleteContactDetailsEmail, 
  getRelatedAcls 
} from 'src/utils/permissions/permission.utils';
import { RHFTextField } from 'src/components/hook-form';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';

const Email = ({ user }: any) => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'email',
  });

  const handleAddEmail = () => {
    append({
      use: 'home',
      value: '',
      system: 'email',
      period: { start: new Date().toISOString(), end: new Date().toISOString() },
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
    <>
      <Stack direction="row" sx={{ pb: 1 }}  style={{ display: "flex" }}>
        <Typography sx={{ ml: 1, mt: 0.7, py: 0.5 }}>
          Email
        </Typography> 
        {checkAclValidation({ user, acl: getRelatedAcls(AddContactDetailsEmail) }) && (
          <IconButton onClick={handleAddEmail} sx={{ p: 0.5, ml: 1 }}>
            <Iconify icon={'eva:plus-fill'}/>
          </IconButton>
        )}
      </Stack>
      {fields.map((item, index) => (
        <Draggable key={item.id} draggableId={item.id} index={index}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <Stack key={item.id} direction="row" sx={{ mb: 2 }}>
                <Iconify icon={'material-symbols:drag-indicator'} width={32} height={50} />

                <Box>
                  <Controller
                    name={`email.${index}.use`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        select
                        sx={{ mx: 1 }}
                        fullWidth={true}
                        SelectProps={{ native: true }}
                        error={!!error}
                        helperText={error?.message}
                      >
                        <option>home</option>
                        <option>work</option>
                      </TextField>
                    )}
                  />
                </Box>

                <Box sx={{ flexGrow: 1, ml: 3 }}>
                  <RHFTextField name={`email.${index}.value`} label="" sx={{ mb: 1 }} />

                  <Stack direction="row" spacing={2} sx={{ py: 2 }}>
                    <Controller
                      name={`email.${index}.period.start`}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <MobileDatePicker
                          {...field}
                          value={field.value !== undefined ? field.value : null}
                          label="Start"
                          views={['year', 'month']}
                          inputFormat="MMM yyyy"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!error?.message}
                              helperText={error?.message}
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name={`email.${index}.period.end`}
                      control={control}
                      render={({ field }) => (
                        <MobileDatePicker
                          {...field}
                          value={field.value !== undefined ? field.value : null}
                          label="End"
                          views={['year', 'month']}
                          inputFormat="MMM yyyy"
                          renderInput={(params) => <TextField size="small" {...params} fullWidth />}
                        />
                      )}
                    />
                  </Stack>
                </Box>

                {checkAclValidation({ user, acl: getRelatedAcls(DeleteContactDetailsEmail) }) && (
                  <IconButton
                    onClick={() => handleRemove(index)}
                    size="medium"
                    sx={{
                      '&:hover': {
                        background: 'none',
                      },
                    }}
                  >
                    <Iconify icon={'eva:trash-2-outline'} color="red" />
                  </IconButton>
                )}
              </Stack>
            </div>
          )}
        </Draggable>
      ))}
    </>
  );
};

export default Email;
