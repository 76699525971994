import { Card, Container, Stack, Typography, Tab, Tabs } from '@mui/material';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { Navigate, Route, Routes, useMatch, useNavigate } from 'react-router';

// tabs
import ConditionsTab from './ConditionsTab';
import OpportunitiesTab from './OpportunitiesTab';
import GoalGroupsTab from './GoalGroupsTab';
import GoalsTab from './GoalsTab';
import ConditionsToOpportunitiesTab from './ConditionsToOpportunitiesTab';
import GoalsToGoalGroupsTab from './GoalsToGoalGroupsTab';
import InterventionsTab from './InterventionsTab';
import CarePlanAutomationTab from './AutomationSheetsTab';
import CarePlanOthersTab from './CarePlanDurationTab';
import Breadcrumbs from 'src/components/Breadcrumbs';

export default function CarePlanAutomationIndex() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const match = useMatch('/settings/care-plan-automation/:tab/*');
  const currentTab = match?.params.tab || ConditionsTab.path;

  return (
    <Page title="Care Plan Automation">
      <Container maxWidth={themeStretch ? undefined : 'xl'} sx={{ height: '100%' }}>
        <Stack spacing={3} direction="column" sx={{ height: '100%' }}>
          <Stack direction="row" alignItems="baseline">
            <Breadcrumbs
              title="Care Plan"
              links={[
                {
                  href: '',
                  name: 'Dashboard',
                },
                {
                  href: '',
                  name: 'Configuration Settings',
                },
                {
                  href: '',
                  name: 'Care Plan Automation',
                },
              ]}
            />
          </Stack>

          <Card>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              sx={{ px: 2, bgcolor: 'background.neutral' }}
              value={currentTab}
              onChange={(_, tab) => navigate(tab)}
            >
              <Tab value={ConditionsTab.path} label="Conditions" />
              <Tab value={OpportunitiesTab.path} label="Opportunities" />
              <Tab value={ConditionsToOpportunitiesTab.path} label="Map C&O" />
              <Tab value={GoalGroupsTab.path} label="Goal Groups" />
              <Tab value={GoalsTab.path} label="Goals" />
              <Tab value={GoalsToGoalGroupsTab.path} label="Map GG&G" />
              <Tab value={InterventionsTab.path} label="Interventions" />
              <Tab value={CarePlanAutomationTab.path} label="Automations" />
              <Tab value={CarePlanOthersTab.path} label="Others" />
            </Tabs>

            <Routes>
              <Route index element={<Navigate to={ConditionsTab.path} />} />
              <Route path={`${ConditionsTab.path}/*`} element={<ConditionsTab />} />
              <Route path={`${OpportunitiesTab.path}/*`} element={<OpportunitiesTab />} />
              <Route
                path={`${ConditionsToOpportunitiesTab.path}/*`}
                element={<ConditionsToOpportunitiesTab />}
              />
              <Route path={`${GoalGroupsTab.path}/*`} element={<GoalGroupsTab />} />
              <Route path={`${GoalsTab.path}/*`} element={<GoalsTab />} />
              <Route path={`${GoalsToGoalGroupsTab.path}/*`} element={<GoalsToGoalGroupsTab />} />
              <Route path={`${InterventionsTab.path}/*`} element={<InterventionsTab />} />
              <Route path={`${CarePlanAutomationTab.path}/*`} element={<CarePlanAutomationTab />} />
              <Route path={`${CarePlanOthersTab.path}/*`} element={<CarePlanOthersTab />} />
            </Routes>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}
