import { useGetLocationHistory } from 'src/services/api/census';
import moment from 'moment';
import BedAssignment from '../../rounding/Model/BedAssignment';
import { SummaryTable } from '../../common';
import { Box } from '@mui/material';
import { useEffect } from 'react';

interface LocationHistorySummaryProps {
  patient: {
    id: string;
  }
};

const LocationHistorySummary = ({ patient }: LocationHistorySummaryProps) => {
  const { data: locations, refetch, isLoading } = useGetLocationHistory(patient?.id);

  useEffect(() => {
    refetch()
  }, [patient?.id, refetch])

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxHeight: '80%',
        overflow: 'scroll',
        boxShadow: 24,
        borderRadius: 2,
        p: 0,
      }}
    >
      <SummaryTable
        title="Location History"
        isLoading={isLoading}
        columns={[
          {
            header: 'Location',
            field: '$',
            render(bedAssignment: BedAssignment) {
              return bedAssignment.bed.locationFhirName ?? 'N/A'
            },
          },
          {
            header: 'Referrer',
            field: '$',
            render() {
              return 'N/A'
            }
          },
          {
            header: 'Admission Date',
            field: 'start',
            render(value: Date) {
              return moment(value).format('MMM DD YYYY')
            }
          },
          {
            header: 'Discharge Date',
            field: 'updatedOn',
            render(value: Date) {
              return moment(value).format('MMM DD YYYY')
            }
          },
          {
            header: 'Discharge Reason',
            field: '$',
            render(bedAssignment: BedAssignment) {
              return bedAssignment.exitDestination.valueDisplayName ?? 'N/A'
            }
          },
        ]}
        data={locations ?? []}
      />
    </Box>
  );
};

export default LocationHistorySummary;
