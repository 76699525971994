import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import DataGridWithFilters, {
  GridFilterDrawerProps,
} from '../DataGridWithFilters/DataGridWithFilters';
import {
  TaskGridRowData,
  onSuccess,
  SeverityStatusDataWithDate,
} from '../../../../../@types/crs/case';
import SeverityStatus from 'src/components/SeverityStatus';
import { Reference } from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources/resources';
import PeriodFilter, { onDateChange } from '../../../../../components/PeriodFilter';
import CheckboxList from 'src/components/CheckboxList';
import useGridFilterConsolidation from 'src/hooks/useGridFilterConsolidation';
import useObjectState from 'src/hooks/useObjectState';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import TaskModal, { TaskStatus, getOwnerDisplayLabel } from './TaskModal';
import { WrappedPatient } from '../../../../../@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCarePlan } from '../../../../../@nicheaim/fhir-base/wrappers/CarePlan';
import TaskActionMenu from './TaskActionMenu';
import SearchMemberModal from '../SearchMember/SearchMemberModal';
import { useSnackbar } from 'notistack';
import { updateTask } from 'src/services/api/case';
import { onSelectResource } from '../SearchMember/SearchMember';
import { momentDateComparator } from 'src/utils/dates';
import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import Program from 'src/sections/crs/common/Program';
import TaskDetailDrawer from '../TaskDetailDrawer';
import './styles.css';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import { WrappedHealthcareService } from 'src/@nicheaim/fhir-base/wrappers/HealthcareService';
import ModalCommunication from 'src/sections/crs/common/ModalCommunication';

export interface TaskFilters {
  selectedOwners: Reference[];
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  status: TaskStatus[];
}
interface TasksGridProps extends GridFilterDrawerProps<TaskFilters> {
  tasks: TaskGridRowData[];
  owners: Reference[];
  isNestedGrid?: boolean;
  showPagination?: boolean;
  onSuccessfulCreation: onSuccess;
  onSuccessfulEdit: onSuccess;
  patient: WrappedPatient | null;
  carePlan?: WrappedCarePlan | null;
  goal?: WrappedGoal | null;
  healthCareServices?: WrappedHealthcareService[] | null;
  title?: string;
}

export const initialTaskFilters: TaskFilters = {
  selectedOwners: [],
  startDate: null,
  endDate: null,
  status: [],
};

const TasksGrid = ({
  tasks,
  owners,
  isNestedGrid,
  showPagination,
  onSearchTextFieldChange,
  searchTextFieldValue,
  onFilterDrawerOpen,
  onFilterDrawerClose,
  isFilterDrawerOpen,
  onApplyFilters,
  filterValues,
  onSuccessfulCreation,
  onSuccessfulEdit,
  patient,
  carePlan,
  goal,
  healthCareServices,
  title,
}: TasksGridProps) => {
  const { filters, updateFilters, onClearAllFilters } = useGridFilterConsolidation<TaskFilters>(
    filterValues,
    initialTaskFilters
  );

  const [
    {
      isTaskModalOpen,
      taskToEdit,
      taskToView,
      isTaskDetailDrawerOpen,
      isSearchMemberModalOpen,
      isAssignProgramModalOpen,
      addNoteToTask,
    },
    updateState,
  ] = useObjectState<{
    isTaskModalOpen: boolean;
    taskToEdit: WrappedTask | null;
    taskToView: WrappedTask | null;
    isSearchMemberModalOpen: boolean;
    isAssignProgramModalOpen: boolean;
    isTaskDetailDrawerOpen: boolean;
    addNoteToTask : boolean;
  }>({
    taskToEdit: null,
    taskToView: null,
    isTaskModalOpen: false,
    isSearchMemberModalOpen: false,
    isAssignProgramModalOpen: false,
    isTaskDetailDrawerOpen: false,
    addNoteToTask: false,
  });

  const {
    valueSets: [taskStatuses],
  } = useValueSetsByIdentifiers(['crs-task-status']);

  const { enqueueSnackbar } = useSnackbar();

  const handleDateFilterChange =
    (dateProp: 'endDate' | 'startDate'): onDateChange =>
    (newValue) => {
      updateFilters({ [dateProp]: newValue });
    };

  const handleOwnerChange: onSelectResource = async (resource) => {
    updateState({ isSearchMemberModalOpen: false });
    const response = await updateTask(
      {
        ownerId: `${resource?.resourceType}/${resource?.id}`,
        ownerDisplay: getOwnerDisplayLabel(resource),
      },
      taskToEdit?.id as string
    );
    if (!response) {
      enqueueSnackbar('Unable to update Task Owner. Please Try Again', {
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar('Task Owner Succesfully Updated');
    onSuccessfulEdit(response);
  };

  const handleOnTaskEdit = (task: WrappedTask) => {
    updateState({ taskToEdit: task, isTaskModalOpen: true });
  };

  const handleOnTaskCopy = (task: WrappedTask) => {
    delete task?.id;
    updateState({ taskToEdit: task, isTaskModalOpen: true });
  };

  const handleAddNoteToTask = (task: WrappedTask) => {
    updateState({ taskToEdit: task, addNoteToTask: true });
  }; 

  const handleTaskToProgram = async (task: WrappedTask) => {
    updateState({ isAssignProgramModalOpen: false });
    const response = await updateTask({ basedOn: task }, taskToEdit?.id as string);
    if (!response) {
      enqueueSnackbar('Unable to update task. Please Try Again', {
        variant: 'error',
      });
      return;
    }
    enqueueSnackbar('Task Succesfully Updated');
    onSuccessfulEdit(response);
  };

  const columns: GridColDef[] = [
    { field: 'task', headerName: 'Task', flex: 2 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
    },
    { field: 'owner', headerName: 'Owner', flex: 2 },
    {
      field: 'endDate',
      headerName: 'End Date',
      flex: 1,
      sortComparator: (_, __, cellParams1, cellParams2) => {
        const { date: date1 } = (cellParams1?.value as SeverityStatusDataWithDate) ?? {};
        const { date: date2 } = (cellParams2?.value as SeverityStatusDataWithDate) ?? {};
        return momentDateComparator(date1, date2);
      },
      renderCell: (params) => {
        const { endDate } = params.row as TaskGridRowData;
        return <SeverityStatus status={endDate} />;
      },
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0.3,
      sortable: false,
      renderCell: (params) => {
        const { wrappedTask } = params.row as TaskGridRowData;
        return (
          <TaskActionMenu
            taskStatuses={taskStatuses?.asList?.() ?? []}
            task={wrappedTask}
            onEditTask={handleOnTaskEdit}
            onSearchMemberOpen={(task) => {
              updateState({ taskToEdit: task, isSearchMemberModalOpen: true });
            }}
            onSuccessfulEdit={onSuccessfulEdit}
            onAssignProgram={(task) => {
              updateState({ taskToEdit: task, isAssignProgramModalOpen: true });
            }}
            onCopyTask={handleOnTaskCopy}
            onAddNoteToTask={handleAddNoteToTask}
          />
        ); //TODO need to add functionality to open edit modal
      },
    },
  ];

  const { startDate, endDate, selectedOwners, status: selectedStatuses } = filters;
  return (
    <>
      <DataGridWithFilters
        addButtonTitle={'New Task'}
        title={title || ''}
        onAddButtonClick={() => {
          updateState({
            isTaskModalOpen: true,
            taskToEdit: null,
          });
        }}
        onRowClick={(params, event) => {
          const { wrappedTask } = params.row as TaskGridRowData;
          updateState({ isTaskDetailDrawerOpen: true, taskToView: wrappedTask });
        }}
        getRowClassName={() => 'rowPointer'}
        onFilterDrawerOpen={onFilterDrawerOpen}
        rows={tasks}
        columns={columns}
        getRowId={(row) => row.id}
        showPagination={showPagination}
        autoHeight={true}
        onSearchTextFieldChange={onSearchTextFieldChange}
        searchTextFieldValue={searchTextFieldValue}
        isNestedGrid={isNestedGrid}
        Filters={
          <TaskFilter
            taskStatuses={taskStatuses?.asList?.() ?? []}
            owners={owners}
            startDate={startDate}
            endDate={endDate}
            selectedOwners={selectedOwners}
            selectedStatuses={selectedStatuses}
            onStartDateChange={handleDateFilterChange('startDate')}
            onEndDateChange={handleDateFilterChange('endDate')}
            onOwnerSelectionChange={(_, newValue) => {
              updateFilters({ selectedOwners: newValue });
            }}
            onStatusSelectionChange={(checkedItems) => {
              updateFilters({ status: checkedItems });
            }}
          />
        }
        FilterDrawerProps={{
          title: 'Tasks Filters',
          open: isFilterDrawerOpen,
          onApplyButtonClick: () => {
            onApplyFilters(filters);
          },
          onCloseIconButtonClick: onFilterDrawerClose,
          onClearAllButtonClick: onClearAllFilters,
        }}
      />

      <TaskModal
        goal={goal}
        taskToEdit={taskToEdit as WrappedTask}
        onSuccessfulEdit={onSuccessfulEdit}
        onSuccessfulCreation={onSuccessfulCreation}
        patientExternal={patient}
        open={isTaskModalOpen}
        carePlan={carePlan}
        onClose={() => {
          updateState({
            isTaskModalOpen: false,
          });
        }}
      />
      <SearchMemberModal
        open={isSearchMemberModalOpen}
        onCancel={() => {
          updateState({ isSearchMemberModalOpen: false });
        }}
        patient={patient as WrappedPatient}
        onSelectResource={handleOwnerChange}
      />
      <Program
        healthCareServices={healthCareServices}
        open={isAssignProgramModalOpen}
        healthCareServiceRelated={taskToEdit?.basedOn as Reference[]}
        onCancel={() => {
          updateState({ isAssignProgramModalOpen: false });
        }}
        handleProgramAssigment={handleTaskToProgram}
      />
      <TaskDetailDrawer
        onEdit={(task) => {
          updateState({ isTaskDetailDrawerOpen: false });
          handleOnTaskEdit(task);
        }}
        open={isTaskDetailDrawerOpen}
        taskExternal={taskToView}
        goal={goal}
        onSuccesfulEdit={onSuccessfulEdit}
        onCloseIconButtonClick={() => {
          updateState({ isTaskDetailDrawerOpen: false });
        }}
      />
      <ModalCommunication
        open={addNoteToTask}
        patient={patient}
        onCancel={() => {
          updateState({ addNoteToTask: false });
        }}
        carePlan={carePlan}
        resource={taskToEdit}
        typeNote="notes_task"
      />
    </>
  );
};

export type OnOwnerSelectionChange = (event: React.SyntheticEvent, newValue: Reference[]) => void;
interface TaskFilterProps {
  owners: Reference[];
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  selectedOwners: Reference[];
  onStartDateChange: onDateChange;
  onEndDateChange: onDateChange;
  onOwnerSelectionChange: OnOwnerSelectionChange;
  onStatusSelectionChange: (checkedStatus: TaskStatus[]) => void;
  selectedStatuses: TaskStatus[];
  taskStatuses: TaskStatus[];
}

const TaskFilter = ({
  owners,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onOwnerSelectionChange,
  selectedOwners,
  onStatusSelectionChange,
  selectedStatuses,
  taskStatuses,
}: TaskFilterProps) => (
  <Box py={3}>
    <Box marginBottom={1}>
      <PeriodFilter
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={onStartDateChange}
        onEndDateChange={onEndDateChange}
      />
    </Box>

    <CheckboxList
      containerSx={{ marginBottom: 4, marginTop: 3 }}
      title="Status"
      keyExtractor={(item) => item.code as string}
      labelExtractor={(item) => item.display}
      onChange={onStatusSelectionChange}
      options={taskStatuses}
      externalCheckedItems={selectedStatuses}
    />
    <Box>
      <Typography mb={1} fontWeight={'bold'}>
        Owner
      </Typography>
      <Autocomplete
        value={selectedOwners}
        fullWidth
        multiple
        onChange={onOwnerSelectionChange}
        options={owners}
        getOptionLabel={(option) => option?.display || ''}
        defaultValue={[]}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
      />
    </Box>
  </Box>
);

export default TasksGrid;
