import { Navigate, useParams } from 'react-router';
import { useActivityDefinition } from 'src/@nicheaim/fhir-react';
import NewActivityTypeForm from './new';

export default function EditActivity() {
  const { activity: activityId = '' } = useParams();
  const [activity, { isLoading }] = useActivityDefinition(activityId);

  if (!activity) {
    if (isLoading) {
      return <div>Loading...</div>;
    }

    return <Navigate to=".." />;
  }

  return <NewActivityTypeForm isEdit currentActivity={activity} />;
}
