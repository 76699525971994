import React from 'react';
import {
  CircularProgress,
  Box,
} from '@mui/material'

type ComponentWithLoadingType = {
  Component: React.ReactElement;
  isLoading: boolean;
}

const ComponentWithLoading = ({
  Component,
  isLoading,
}: ComponentWithLoadingType) => (
    isLoading ? (
      <Box textAlign={'center'} sx={{ paddingTop: 10 }}>
        <CircularProgress />
      </Box>
    ) : (
      <>{Component}</>
    )
  )

export default ComponentWithLoading;
