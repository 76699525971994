// @ts-nocheck
import { Address } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { createWrapper } from '../../fhir-react/base';

export const OrganizationWrapper = createWrapper('Organization', (organization) => ({
  
  getPrimaryAddress(): Address | null {
    let mainAddress = null;
    if (organization.address && organization.address?.length > 0) {
      mainAddress = organization.address[0];
    }
    return mainAddress;
  },

  getPrimaryAddressFormatted(): string | null {
    const mainAddress = this.getPrimaryAddress();
    return mainAddress
      ? `${mainAddress?.city}, ${mainAddress?.state}, ${mainAddress?.postalCode}`
      : null;
  },
}));
export type WrapperOrganization = ReturnType<typeof OrganizationWrapper>;
