import { useNavigate } from 'react-router';
import { ActivityDefinition } from 'src/@nicheaim/fhir-base/mappings/ActivityDefinition';
import { useActivityDefinition } from 'src/@nicheaim/fhir-react';
import { EntityContextMenu } from 'src/sections/careflow/common';
import { useDialogStore } from 'src/stores/dialog';

export interface ActivitiesContextMenuProps {
  item: ActivityDefinition;
}

export default function ActivitiesContextMenu({ item }: ActivitiesContextMenuProps) {
  const navigate = useNavigate();
  const { confirmDialog } = useDialogStore();
  const [activity, { update }] = useActivityDefinition(item.id || 'undefined');
  const isActive = item.status === 'active';

  return (
    <EntityContextMenu
      entity={item}
      items={[
        {
          label: 'Edit',
          icon: 'eva:edit-fill',
          onPress: (currentItem: any) => navigate(`${currentItem.id}/edit`),
        },
        isActive
          ? {
              label: 'Disable',
              icon: 'eva:trash-2-outline',
              onPress: async () => {
                const confirmed = await confirmDialog({
                  title: 'Disable',
                  description: 'Are you sure you want to disable this item?',
                });

                if (confirmed) {
                  update({
                    ...activity,
                    resourceType: 'ActivityDefinition',
                    status: 'draft',
                  });
                }
              },
            }
          : {
              label: 'Enable',
              onPress: async (currentItem) => {
                update({
                  ...activity,
                  resourceType: 'ActivityDefinition',
                  status: 'active',
                });
              },
              icon: 'eva:checkmark-circle-2-outline',
            },
      ]}
    />
  );
}
