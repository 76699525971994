import { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableCellProps,
  Dialog,
  DialogTitle,
} from '@mui/material';
import moment from 'moment';
import { getSeverityDueDateData } from 'src/sections/crs/helpers/getSeverityDueDateData';
import { useDocumentReferences } from 'src/@nicheaim/fhir-react';
import {
  DocumentReferenceWrapper,
  WrappedDocumentReference,
} from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import { spreadSxProp } from 'src/utils/cssStyles';
import Viewer from 'src/sections/crs/common/Viewer';
import { NOTAPPLICABLE } from 'src/sections/crs/constants';
import { LoadingComponent, NoResultText } from './components';

export interface AttachmentsTableProps {
  referralId: string;
  patientId: string;
}

export const AttachmentsTable = ({ referralId, patientId }: AttachmentsTableProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<WrappedDocumentReference | null>(null);

  const [documentReferences, { isFetching: isLoading }] = useDocumentReferences({
    baseQueryOptions: {
      queryKeyHashFn: () => referralId,
    },
    filter: {
      related: referralId,
    },
    map: DocumentReferenceWrapper,
    autofetch: !!referralId,
  });

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePreviewDialogVisibility = (isVisible: boolean) => () => {
    setIsPreviewDialogOpen(isVisible);
  };
  return (
    <>
      <Box sx={{ marginTop: 2.7 }}>
        <Typography variant="body1" sx={{ color: 'text.secondary', fontWeight: '500' }}>
          Attachments
        </Typography>
        <Box
          sx={{
            marginTop: 1.8,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Name</TableHeadCell>
                  <TableHeadCell>Created On</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading ? (
                  documentReferences.length ? (
                    (rowsPerPage > 0
                      ? documentReferences.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : documentReferences
                    ).map((row, index) => {
                      const title =
                        row?.content?.[0]?.attachment?.title ?? `Document Reference ${index}`;
                      const { formattedDueDate: createdOn } = getSeverityDueDateData(
                        moment(row?.date ?? null)
                      );
                      return (
                        <TableRow key={title}>
                          <TableCell>
                            <Typography
                              variant="body2"
                              sx={{
                                textDecoration: 'underline',
                                color: '#008ecc',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setSelectedDocument(row);
                                handlePreviewDialogVisibility(true)();
                              }}
                            >
                              {title}
                            </Typography>
                          </TableCell>
                          <TableCell>{createdOn !== NOTAPPLICABLE ? createdOn : ''}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableCell colSpan={2}>
                      <NoResultText text="No attachments found" />
                    </TableCell>
                  )
                ) : (
                  <TableCell colSpan={2}>
                    <LoadingComponent />
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={documentReferences?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isPreviewDialogOpen}
        onClose={handlePreviewDialogVisibility(false)}
      >
        <DialogTitle>Preview Document</DialogTitle>
        <Viewer
          documentReference={selectedDocument}
          typeResource="documentReference"
          patientId={patientId}
        />
      </Dialog>
    </>
  );
};

const TableHeadCell = ({ sx, ...props }: TableCellProps) => (
  <TableCell sx={[{ backgroundColor: '#f6f9fc' }, ...spreadSxProp(sx)]} {...props} />
);

export default AttachmentsTable;
