import client from '../_client';
import { Catalog } from '../_types';

export type MedicationTransaction = {
  id: number;
  uuid: string;
  medicationRequestId: string;
  medicationRequestRXNumber: string;
  transactionType: Catalog;
  transactionTypeId: number;
  quantity: number;
  unitName: string;
  unitId: string;
  medicationName: string;
  medicationId: string;
  performedDate: Date;
  staff: any;
  patient: any;
  note: string;
  createdBy: string;
  createdOn: Date;
  updatedBy?: string;
  updatedOn?: Date;
  deletedOn?: Date;
  deletedBy?: string;
};

export async function getMedicationTransactions(medicationRequestId: string) {
  const { data } = await client.get<MedicationTransaction[]>(`/ccm/medication-transactions/medication-request/${medicationRequestId}/list`);

  return data;
}

export async function getMedicationTransactionTypes() {
  const { data } = await client.get<Catalog[]>(`/ccm/medication-transactions/types`);

  return data;
}

export type CreateMedicationTransactionType = {
  medicationRequestFhirId: string;
  medicationRequestRXNumber: string;
  loggedUser: any;
  patientFhirId: string;
  staffFhirId: string;
  transactionTypeId: number;
  quantity: number;
  unitId: string;
  unitName: string;
  medicationId: string;
  medicationName: string;
  performedDate: string;
  note: string;
}

export async function createMedicationTransaction(params: CreateMedicationTransactionType) {
  const { data } = await client.post<MedicationTransaction>(`/ccm/medication-transactions/create`, params)

  return data
}
