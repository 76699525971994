import { useCallback, useState } from 'react';
import { Box, Collapse, IconButton } from '@mui/material';
import { GridRowId, GridColDef, GridRowProps, GridRow } from '@mui/x-data-grid';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import DataGridWithFilters, {
  DataGridWithFiltersProps,
  OnVisibleRowsChange,
} from './DataGridWithFilters/DataGridWithFilters';
import { spreadSxProp } from 'src/utils/cssStyles';

export type RenderCollapsibleContent = (row: any) => JSX.Element;

export interface CollapsibleDataGridProps extends DataGridWithFiltersProps {
  renderCollapsibleContent: RenderCollapsibleContent;
  subGridRowsExtractor: (row: any) => any[];
}

const CollapsibleDataGrid = ({
  renderCollapsibleContent,
  components,
  dataGridSx,
  columns,
  rows,
  subGridRowsExtractor,
  onVisibleRowsChange: _,
  ...dataGridProps
}: CollapsibleDataGridProps) => {
  const [openRows, setOpenRows] = useState<GridRowId[]>([]);
  const [visibleRows, setVisibleRows] = useState<GridRowId[]>([]);

  const calculateHeightDinamically = () => {
    const initialHeight = 110;
    let height = initialHeight;
    const rowHeight = 60;
    height += (visibleRows.length + 1) * rowHeight;
    for (const rowId of visibleRows) {
      if (!openRows.includes(rowId)) continue;
      const row = rows.find(({ id }) => id === rowId);
      if (!row) continue;
      let noOfRows = subGridRowsExtractor(row).length ?? 0;
      if (!noOfRows || noOfRows <= 2) noOfRows = 4;
      height += initialHeight + noOfRows * rowHeight;
    }
    return height;
  };

  const toggleRow = (id: string) => {
    setOpenRows((openRows) => {
      if (openRows.includes(id)) {
        return openRows.filter((openRow) => openRow !== id);
      }
      return [...openRows, id];
    });
  };

  const onVisibleRowsChange: OnVisibleRowsChange = useCallback((visibleRows) => {
    setVisibleRows(visibleRows);
  }, []);

  const gridColumns: GridColDef[] = [
    {
      field: 'expand',
      headerName: '',
      align: 'center',
      flex: 0.2,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          sx={{ padding: 0 }}
          onClick={() => {
            toggleRow(params?.row?.id);
          }}
        >
          {openRows.includes(params?.row?.id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      ),
    },
    ...columns,
  ];

  return (
    <DataGridWithFilters
      rows={rows}
      columns={gridColumns}
      components={{
        Row: (rowProps: GridRowProps) => (
          <CollapsibleRow
            rowProps={rowProps}
            openRows={openRows}
            renderCollapsibleContent={renderCollapsibleContent}
          />
        ),
        ...components,
      }}
      onVisibleRowsChange={onVisibleRowsChange}
      dataGridSx={[{ height: calculateHeightDinamically() }, ...spreadSxProp(dataGridSx)]}
      {...dataGridProps}
    />
  );
};

interface CollapsibleRowProps {
  rowProps: GridRowProps;
  openRows: GridRowId[];
  renderCollapsibleContent: RenderCollapsibleContent;
}

const CollapsibleRow = ({ rowProps, openRows, renderCollapsibleContent }: CollapsibleRowProps) => {
  const { row } = rowProps;

  return (
    <Box sx={{ paddingBottom: 1 }}>
      <GridRow {...rowProps} />
      <Collapse in={openRows.includes(row?.id as GridRowId)} timeout="auto" unmountOnExit>
        <Box px={3}>{renderCollapsibleContent(row)}</Box>
      </Collapse>
    </Box>
  );
};

export default CollapsibleDataGrid;
