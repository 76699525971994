import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Popper,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Card,
  CircularProgress,
} from '@mui/material';
import { EntityContextMenu, SummaryTable } from 'src/sections/careflow/common';
import Iconify from 'src/components/Iconify';
import { useCarePlans, useCommunications } from 'src/@nicheaim/fhir-react';
import {
  CarePlan,
  Communication,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import moment from 'moment';
import AddBarrierModal from './AddBarrierModal';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import useAddEntityRequestStates from 'src/hooks/useAddEntityRequestStates';
import AddModal from 'src/sections/careflow/common/AddModal';

type BarriersSummaryProps = {
  isExpanded: boolean;
  onZoom?: () => void;
  patient?: {
    id: string;
  };
};

export const BarriersSummary = ({ isExpanded, onZoom, patient }: BarriersSummaryProps) => {
  const [showAddBarrierModal, setShowAddBarrierPlanModal] = useState(false);
  const [category, setCategory] = useState('enrolled');
  const [startDate, setStartDate] = useState('May 01, 2022');
  const [endDate, setEndDate] = useState('Jul 01, 2022');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const [{ isLoading }, { setIsLoading }] = useAddEntityRequestStates();

  const [carePlans, { update: updateCarePlan, refresh: getCarePlans }] = useCarePlans({
    filter: {
      subject: patient?.id,
      _count: 10,
    }
  });
  const ids = useMemo(
    () =>
      (
        [...carePlans]?.pop()?.supportingInfo?.map((i) => getFhirIdFromEntity(i?.reference ?? '')) ?? []
      ).join(','),
    [carePlans]
  );
  const [communications, { remove: deleteCommunication }] = useCommunications({
    autofetch: ids ? true : false,
    filter: {
      _id: ids,
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const closeSearchPanel = () => setAnchorEl(null);

  const handleCategory = (ev: any) => {
    console.log(ev.target.value as string);
    setCategory(ev.target.value as string);
  };

  const changeStartDate = (el: any) => {
    setStartDate(el.target.value);
  };
  const changeEndDate = (ev: any) => {
    setEndDate(ev.target.value);
  };

  const clearAll = () => {
    setStartDate('');
    setEndDate('');
    setCategory('');
  };

  const renderAddBarrierModal = () => (
    <AddModal
      open={showAddBarrierModal}
      onClose={() => setShowAddBarrierPlanModal(false)}
      Component={
        <AddBarrierModal
          patient={patient}
          onSaveCallback={() => {
            setShowAddBarrierPlanModal(false);
            getCarePlans();
          }}
          onCancelPress={() => {
            setShowAddBarrierPlanModal(false);
          }}
        />
      }
    />
  );

  useEffect(() => {
    console.log('get care plans');
    getCarePlans();
  }, []);

  return (
    <Card sx={{ height: `calc(100vh - 200px)`, display: 'flex', flexDirection: 'column' }}>
      {renderAddBarrierModal()}
      <SummaryTable
        columns={[
          {
            header: 'Date',
            field: '$',
            render(communication: Communication) {
              return moment(communication.sent).format('MMM DD Y - hh:mm a');
            },
          },
          {
            header: 'Barriers',
            field: '$',
            visible: isExpanded,
            render(communication: Communication) {
              return communication.note?.[0]?.text ?? 'N/A';
            },
          },
        ]}
        fetchMore={() => {}}
        rowContextMenu={(row: Communication) =>
          isLoading ? (
            <CircularProgress size={30} />
          ) : (
            <EntityContextMenu
              entity={row}
              items={[
                {
                  label: 'Delete',
                  icon: 'bi:trash',
                  onPress: async (currentCommunication: Communication) => {
                    console.log({
                      currentCommunication,
                    });
                    setIsLoading(true);
                    const response = await axiosFhirInstance.get(
                      `/CarePlan?subject=${patient?.id}`
                    );
                    const patientCarePlans: any = response.data.entry;
                    await Promise.all(
                      patientCarePlans.map(async ({ resource }: any) => {
                        await updateCarePlan({
                          ...resource,
                          supportingInfo:
                            resource.supportingInfo?.filter(
                              (sp: any) => getFhirIdFromEntity(sp.reference) !== currentCommunication.id
                            ) ?? [],
                        });
                      })
                    ).then(() => {
                      setIsLoading(false);
                      getCarePlans();
                      if (currentCommunication) {
                        deleteCommunication(currentCommunication?.id!).catch((err) => {
                          console.log({
                            err,
                          });
                        });
                      }
                    });
                  },
                  rules: () => true,
                },
              ]}
            />
          )
        }
        data={communications}
        actionBar={
          <>
            <IconButton
              onClick={() => {
                setShowAddBarrierPlanModal(true);
              }}
              disabled={carePlans?.length === 0 ? true : false}
            >
              <Iconify icon="eva:plus-fill" />
            </IconButton>
            <IconButton onClick={handleClick}>
              <Iconify icon="eva:search-fill" />
            </IconButton>
            <IconButton onClick={() => onZoom?.()}>
              <Iconify icon="eva:expand-fill" />
            </IconButton>
            <Popper
              id={id}
              open={open}
              anchorEl={anchorEl}
              onResize={undefined}
              onResizeCapture={undefined}
            >
              <Box sx={{ p: 1, bgcolor: 'background.paper' }}>
                <Stack>
                  <Stack alignItems="center" direction="row" justifyContent="space-between">
                    <Typography variant="subtitle2">Filters</Typography>
                    <IconButton onClick={closeSearchPanel}>
                      <Iconify icon="eva:close-fill" />
                    </IconButton>
                  </Stack>
                  <Typography variant="subtitle1">Category</Typography>
                  <FormControl variant="standard" size="small">
                    <Select label="Category" value={category} onChange={handleCategory}>
                      <MenuItem value="enrolled">Enrolled</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography variant="subtitle1" mt={2}>
                    Date
                  </Typography>
                  <Stack direction="row" my={2} spacing={4}>
                    <TextField variant="standard" value={startDate} onChange={changeStartDate} />
                    <TextField variant="standard" value={endDate} onChange={changeEndDate} />
                  </Stack>
                  <Stack alignItems="center">
                    <Button
                      variant="outlined"
                      sx={{
                        borderColor: 'rgba(145, 158, 171, 0.32)',
                        color: 'rgb(36, 31, 32)',
                      }}
                      onClick={clearAll}
                    >
                      <Typography variant="subtitle2">Clear All</Typography>
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Popper>
          </>
        }
      />
    </Card>
  );
};
