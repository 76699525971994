import { useCallback, useEffect } from "react";
import useObjectState from "src/hooks/useObjectState"
import API from 'src/services/api';
import GroupSessionType from "./types/GroupSessionType";
import { useMatch, useNavigate } from "react-router";

export enum GROUP_SESSION_VIEW_TYPE {
  LIST,
  DETAILS
}

export type PaginationType = {
  take: number;
  page: number;
  total: number;
}

type GroupSessionsControllerStateType = {
  entities: GroupSessionType[];
  status: number;
  filter: string | null;
  selectedGroupSession: GroupSessionType | null;
  showAddBedModal: boolean;
  total: number;
  isLoading: boolean;
  pagination: PaginationType;
}

type GroupSessionControllerResponseType = {
  filterEntities: (filter: string | null, status: number) => void;
  closeAddSessionModal: () => void;
  openAddSessionModal: (groupSession?: GroupSessionType) => void;
  onSelectGroupSession: (groupSession: GroupSessionType) => void;
  onSaveGroupSession: (groupSession: GroupSessionType) => void;
  onDeleteGroupSession: (groupSession: GroupSessionType) => void;
  getEntities: (data: { pagination: PaginationType }) => void;
}

const useController = (): [GroupSessionsControllerStateType, GroupSessionControllerResponseType] => {
  const navigate = useNavigate();
  const [state, updateState] = useObjectState<GroupSessionsControllerStateType>({
    entities: [],
    status: 2, // 2 for all
    filter: null,
    showAddBedModal: false,
    selectedGroupSession: null,
    total: 0,
    isLoading: true,
    pagination: {
      take: 10,
      page: 0,
      total: 0,
    },
  });
  const urlData = useMatch('/dashboard/group-sessions/:id/edit');
  console.log({
    urlData
  })

  const filterEntities = (filter: string | null, status: number) => {
    updateState({
      isLoading: true,
      status: status,
      filter: filter,
    });
  }

  const closeAddSessionModal = () => {
    updateState({ selectedGroupSession: null });
    navigate('/dashboard/group-sessions')
  }

  const openAddSessionModal = (groupSession?: GroupSessionType) =>
    updateState({ selectedGroupSession: groupSession })

  const onSelectGroupSession =(groupSession: GroupSessionType) => {
    updateState({
      selectedGroupSession: groupSession,
    }) 
  }

  const onSaveGroupSession = (groupSession: GroupSessionType) => {
    updateState(prev => {
      const tempGroupSessions = [...prev.entities ?? []];
      const index = tempGroupSessions.findIndex(g => g.id === groupSession.id);

      if (index !== -1) {
        tempGroupSessions[index] = groupSession;
      } else {
        tempGroupSessions.unshift(groupSession);
      }
      return {
        entities: tempGroupSessions,
      }
    })
  }

  const onDeleteGroupSession = (groupSession: GroupSessionType) =>
    updateState(prev => ({
      entities: prev.entities?.filter(currentGroupSession => currentGroupSession.id !== groupSession.id),
    }))

  const getEntities = useCallback(({
    pagination
  }: {
    pagination: PaginationType
  }) => {
    API.groupSessions.getGroupSessions({
      take: pagination.take,
      skip: pagination.page,
      filter: state.filter,
      status: state.status,
    }).then((data) => {
      updateState({
        entities: data.groupSessions.map((s) =>  ({ ...s, sessions: s.sessions ?? [] })),
        total: data.total,
        isLoading: false,
        pagination: {
          take: pagination.take,
          page: pagination.page,
          total: data.total,
        },
      })
    }).catch(erro => console.log({erro}))
  }, [state.filter, state.status, updateState])

  useEffect(() => {
    getEntities({
      pagination: {
        take: 10,
        page: 0,
        total: 0,
      },
    });
  }, [getEntities, state.status, state.filter]);

  return [
    state,
    {
      filterEntities,
      closeAddSessionModal,
      openAddSessionModal,
      onSelectGroupSession,
      onDeleteGroupSession,
      onSaveGroupSession,
      getEntities,
    }
  ]
};

export default useController;
