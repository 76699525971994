import { Alert } from '@mui/material';
import { useParams } from 'react-router';
import { useOrganizations } from 'src/@nicheaim/fhir-react';
import fhirSystem from 'src/fhir/system';
import NewLOBForm from '../new';

export default function EditLOB() {
  const { lob: lobId = '' } = useParams();
  const [[plan], { isLoading }] = useOrganizations({
    filter: {
      identifier: fhirSystem.lineOfBusiness.forCode(lobId),
    },
  });

  if (!plan && !isLoading) return <Alert severity="error">Plan not found</Alert>;
  return <NewLOBForm isEdit currentLOB={plan!} />;
}
