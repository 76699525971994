import { Backdrop, Button, CircularProgress, Stack } from '@mui/material';
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';

interface IStandardLoadingBackdropContextData {
  openLodingBackdrop: () => void;
  closeLodingBackdrop: () => void;
}

const initialValue: IStandardLoadingBackdropContextData = {
  openLodingBackdrop: () => {},
  closeLodingBackdrop: () => {},
};

const StandardLoadingBackdropContext =
  createContext<IStandardLoadingBackdropContextData>(initialValue);

export function useStandardLoadingBackdrop() {
  const { openLodingBackdrop, closeLodingBackdrop } =
    useContext<IStandardLoadingBackdropContextData>(StandardLoadingBackdropContext);
  return { open: openLodingBackdrop, close: closeLodingBackdrop };
}

interface IStandardDialogProviderProps {
  children: ReactNode;
  meta?: ReactNode;
  //   handleOnClose: () => void;
}

export default function StandardLoadingBackdropProvider({
  children,
  meta,
}: IStandardDialogProviderProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [showCloseButton, setShowCloseButton] = useState<boolean>(false);

  const [start, setStart] = useState(false);
  const firstStart = useRef(true);
  const tick = useRef<any>();

  useEffect(() => {
    if (firstStart.current) {
      firstStart.current = !firstStart.current;
      return;
    }

    if (start) {
      tick.current = setInterval(() => {
        setShowCloseButton(true);
      }, 5000); // display close button after 5 seconds
    } else {
      clearInterval(tick.current);
    }

    return () => clearInterval(tick.current);
  }, [start]);

  function openBackdrop() {
    setOpen(true);
    setStart(true);
  }

  function closeBackdrop() {
    setOpen(false);
    setStart(false);
    setShowCloseButton(false);
  }

  return (
    <StandardLoadingBackdropContext.Provider
      value={{ openLodingBackdrop: openBackdrop, closeLodingBackdrop: closeBackdrop }}
    >
      <div>{children}</div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <Stack spacing={2} justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
          {showCloseButton && <Button onClick={closeBackdrop}>Close</Button>}
        </Stack>
      </Backdrop>
    </StandardLoadingBackdropContext.Provider>
  );
}
