// @ts-nocheck
import moment from "moment"
import { DayOfWeekFhirEnum, MedicationRequestFormState, PeriodUnitFhirEnum } from "./types"

export const dayOfWeekOptions = [
  DayOfWeekFhirEnum.SUNDAY,
  DayOfWeekFhirEnum.MONDAY,
  DayOfWeekFhirEnum.TUESDAY,
  DayOfWeekFhirEnum.WEDNESDAY,
  DayOfWeekFhirEnum.THRUSDAY,
  DayOfWeekFhirEnum.FRIDAY,
  DayOfWeekFhirEnum.SATURRDAY,
]

export const periodUnitOptions = [
  // NOT USE FOR NOW
  // PeriodUnitFhirEnum.MINUTE,
  PeriodUnitFhirEnum.HOUR,
  PeriodUnitFhirEnum.DAY,
  PeriodUnitFhirEnum.WEEK,
  // NOT USE FOR NOW
  // PeriodUnitFhirEnum.MONTH,
]

export const periodUnitLabels = {
  [PeriodUnitFhirEnum.MINUTE]: 'Minute',
  [PeriodUnitFhirEnum.HOUR]: 'Hour',
  [PeriodUnitFhirEnum.DAY]: 'Day',
  [PeriodUnitFhirEnum.WEEK]: 'Week',
  [PeriodUnitFhirEnum.MONTH]: 'Month',
}


export const defaultState: MedicationRequestFormState = {
  rxnumber: '',
  barcode: '',
  location: null,
  medication: null,
  reasonForUse: null,
  startDate: moment().toDate(),
  startHour: moment().format('HH:mm'),
  endDate: moment().toDate(),
  endHour: moment().format('HH:mm'),
  requester: null,
  note: '',
  selfAdministered: false,
  allowPRN: false,
  refills: 1,
  inventory: 1,
  route: null,
  schedule: {
    frequency: 1,
    interval_type: PeriodUnitFhirEnum.DAY,
    interval_amount: 1,
    times: [
      {
        index: 0,
        time: moment().format('HH:mm'),
        quantity: 1,
      },
    ],
  },
}