import {
  Grid,
  TextField,
  FormControl,
} from "@mui/material";

type FiltersPropsType = {
  onChangeFilter: (value: string) => void;
};

const Filters = ({ onChangeFilter, }: FiltersPropsType) => (
  <Grid item xl={12} sx={{ mt: 2}}>
    <Grid container spacing={4}>
      <Grid item xl={2}>
        <FormControl
          fullWidth
          style={{ paddingRight: '5px' }}
        >
          <TextField
            placeholder="Search"
            onChange={(e) => {
              onChangeFilter(e.target.value)
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  </Grid>
);

export default Filters;
