import { Card, Container, Stack, Typography } from '@mui/material';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { Navigate, Route, Routes } from 'react-router';
import DisenrollmentReasonsTable from './components/DisenrollmentReasonsTable';

// routes
import NewDisenrollmentReason from './new';
import EditProgramDisenrollmentReason from './$disenrollmentReason.edit';

export default function DisenrollmentReasonsIndex() {
  return (
    <Routes>
      <Route index element={<DisenrollmentReasonsTable />} />
      <Route path="new" element={<NewDisenrollmentReason />} />
      <Route path=":disenrollmentReason" element={<Navigate to="edit" />} />
      <Route path=":disenrollmentReason/edit" element={<EditProgramDisenrollmentReason />} />
    </Routes>
  );
}
