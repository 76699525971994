// @ts-nocheck
import { IconButton, Modal, Typography } from "@mui/material";
import Iconify from "src/components/Iconify";
import { EntityContextMenu, SummaryTable } from "../../common";
import useObjectState from "src/hooks/useObjectState";
import moment from "moment";
import ReferralViewModel from "../../tasks-activities/components/Referrals/ViewModel/ReferralViewModel";
import { REFERRALS_LIST_PERSON, REFERRALS_LIST_STATUS, REFERRALS_LIST_TYPES, ReferralStatuses } from "../../tasks-activities/components/Referrals/constants";
import { shortFormat } from "src/utils/formatTime";
import { useDeleteCcmReferral, useGetCcmReferrals } from "src/services/api/ccm-referrals";
import useAuth from "src/hooks/useAuth";
import { getFhirIdFromReferenceString } from "../../tasks-activities/components/Activities/activities-utils";
import AddModal from "../../common/AddModal";
import AddReferral from "../../tasks-activities/components/Referrals/AddReferral/AddReferral";
import axiosFhirInstance from "src/application/adapters/out/repositories/axiosFhirInstance";
import { useEffect, useMemo } from "react";
import ReferralNotes from "../../tasks-activities/components/Referrals/ReferralNotes/ReferralNotes";
import { GetCcmReferralsQueryFilters } from "src/services/api/referrals";

type ReferralsSummaryPropsType = {
  isExpanded: boolean;
  patient: any;
  onExpand: () => void;
  showButtons?: boolean;
}

type ReferralsSummaryStateType = {
  showAddReferralModal: boolean;
  selectedReferral?: ReferralViewModel;
  showAddReferralNoteModal: boolean;
  selectedReferralNote?: any;
}

const ReferralsSummary = ({
  patient,
  isExpanded,
  onExpand,
  showButtons = true,
}: ReferralsSummaryPropsType) => {
  const authUser = useAuth().getCurrentUser();
  const [{
    showAddReferralModal,
    selectedReferral,
    showAddReferralNoteModal,
  }, updateState] = useObjectState<ReferralsSummaryStateType>({
    showAddReferralModal: false,
    showAddReferralNoteModal: false,
    selectedReferralNote: undefined,
  })

  const { mutateAsync: deleteReferral } = useDeleteCcmReferral();
  const params: GetCcmReferralsQueryFilters = useMemo(() => ({
    type: REFERRALS_LIST_TYPES.INBOUND,
    person: REFERRALS_LIST_PERSON.ANYONE,
    status: REFERRALS_LIST_STATUS.ALL,
    patientFhirId: patient?.id,
    userEmail: authUser.email,
    userFhirId: getFhirIdFromReferenceString(authUser.user_fhir_uri)!,
    pagination: {
      take: 10,
      page: 0,
    },
    includeParentReferrals: false,
  }), [patient, authUser])
  const { data = [], refetch: refreshReferrals, isFetching,  } = useGetCcmReferrals(params);

  const referralList = useMemo(() => data?.referrals ?? [], [data]);

  const onCloseAddReferralModal = () => {
    updateState({
      showAddReferralModal: false,
      selectedReferral: undefined,
    });
  }

  const onCloseAddReferralNoteModal = () => {
    updateState({
      showAddReferralNoteModal: false,
      selectedReferral: undefined,
    });
  }

  const onDownloadAttachment = async (referral: any) => {
    const { data } = await axiosFhirInstance.get(`DocumentReference?related=${referral?.fhirId}`);
    const element = document.createElement('a');
    if (data.entry.length > 0) {
      const attachment = data?.entry[0]?.resource;
      const binaryId = attachment.content?.[0]?.attachment.url?.split('/').pop();

      if (binaryId) {
        const { data: binaryResponse } = await axiosFhirInstance.get(`/Binary/${binaryId}`);

        if (!binaryResponse) return;

        element.setAttribute(
          'href',
          `data:${attachment?.contentType};base64,${binaryResponse.data}`
        );
        element.setAttribute('download', `filename.${binaryResponse.contentType?.split('/')[1]}`);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      } else {
        element.setAttribute(
          'href',
          `data:${attachment.content?.[0]?.attachment.contentType};base64,${attachment.content?.[0]?.attachment?.data}`
        );
        element.setAttribute(
          'download',
          `filename.${attachment.content?.[0]?.attachment.contentType?.split('/')[1]}`
        );

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      }
      
    }
  };

  const onDeleteReferral = (referral: ReferralViewModel) => {
    deleteReferral(referral.fhirId).then(() => {
      refreshReferrals();
    })
  }


  useEffect(() => {
    refreshReferrals();
  }, [patient?.id])

  return (
    <>
      <AddModal
        Component={
          <AddReferral
            onSaveCallback={() => {
              onCloseAddReferralModal();
              refreshReferrals();
            }}
            onSaveMultipleCallback={() => {
              onCloseAddReferralModal();
              refreshReferrals();
            }}
            referral={selectedReferral}
            patient={{
              fhirId: patient?.id,
            }}
          />
        }
        open={showAddReferralModal}
        onClose={onCloseAddReferralModal}
      />
      <Modal
        open={showAddReferralNoteModal}
        onClose={onCloseAddReferralNoteModal}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '40px' }}
      >
        <ReferralNotes
          referral={selectedReferral!}
          onCancel={() => onCloseAddReferralNoteModal()}
        />
      </Modal>
      <SummaryTable
        title="Referrals"
        data={referralList}
        isLoading={!patient || isFetching ? true : false}
        columns={[
          {
            header: 'Date',
            field: '$',
            render(referral: ReferralViewModel) {
              return moment(referral.date).utc().format('MMM DD, yyyy')
            }
          },
          {
            header: 'Name / DOB',
            field: '$',
            render(referral: ReferralViewModel) {
              return <>
                {referral.patientName}
                <br/>
                {referral.patientBirthdate
                ? shortFormat(new Date(referral.patientBirthdate))
                : 'N/A'}
              </>
            }
          },
          {
            header: 'Referred from',
            field: '$',
            visible: isExpanded,
            render(referral: ReferralViewModel) {
              return (
                <Typography>
                  {referral.referredFromPractitionerId ? (
                    <>
                      {referral.referredFromPractitionerName} -{' '}
                      {referral.referredFromPractitionerOrganizationName}
                    </>
                  ) : referral.referredFromOrganizationId ? (
                    <>{referral.referredFromOrganizationName}</>
                  ) : referral.referredFromDisplay ? (
                    <>{referral.referredFromDisplay}</>
                  ) : null}
                </Typography>
              )
            }
          },
          {
            header: 'Referred To',
            field: '$',
            visible: isExpanded,
            render(referral: ReferralViewModel) {
              return (
                <Typography>
                  {referral.referredToPractitionerId ? (
                    <>
                      {referral.referredToPractitionerName} -{' '}
                      {referral.referredToPractitionerOrganizationName}
                    </>
                  ) : referral.referredToOrganizationName ? (
                    <>{referral.referredToOrganizationName}</>
                  ) : referral.referredToDisplay ? (
                    <>{referral.referredToDisplay}</>
                  ) : null}
                </Typography>
              )
            }
          },
          {
            header: 'Service',
            field: '$',
            render(referral: ReferralViewModel) {
              return referral.serviceName;
            }
          },
          {
            header: 'Status',
            field: '$',
            render(referral: ReferralViewModel) {
              return ReferralStatuses[referral.status];
            }
          },
          {
            header: 'Created By',
            field: '$',
            visible: isExpanded,
            render(referral: ReferralViewModel) {
              return referral.createdBy ?? referral.createdByLegacy;
            }
          },
          {
            header: 'Assigned to',
            field: '$',
            visible: isExpanded,
            render(referral: ReferralViewModel) {
              return referral.assignedToName;
            }
          },
          {
            header: 'Last Updated / Last Updated By',
            field: '$',
            visible: isExpanded,
            render(referral: ReferralViewModel) {
              return `${(referral.lastChangeDate ? `${moment(referral.lastChangeDate).format('MMM DD, YYYY hh:mm a')} ${referral.lastChangeBy}`  : '-')}`;
            }
          },
          // {
          //   header: 'Referral Note',
          //   field: '$',
          //   visible: isExpanded,
          //   render(referral: ReferralViewModel) {
          //     return '';
          //   }
          // }
        ]}
        rowContextMenu={(referral) => (
          <EntityContextMenu
            entity={referral}
            items={[
              {
                label: 'Edit',
                icon: 'bi:calendar-check',
                onPress: (currentReferral: ReferralViewModel) =>
                  updateState({
                    selectedReferral: currentReferral,
                    showAddReferralModal: true,
                  }),
                rules: () => true,
              },
              {
                label: 'Referral Notes',
                icon: 'bi:journal-plus',
                onPress: (currentReferral: ReferralViewModel) =>
                  updateState({
                    selectedReferral: currentReferral,
                    showAddReferralNoteModal: true,
                  }),
                rules: () => true,
              },
              {
                label: 'Delete',
                icon: 'bi:trash',
                onPress: onDeleteReferral,
                rules: () => true,
              },
              {
                label: 'Attachments',
                icon: 'bi:download',
                onPress: (currentReferral: ReferralViewModel) =>
                  onDownloadAttachment(currentReferral),
                rules: (currentReferral) => !!currentReferral?.lastDocumentReference,
              },
            ]}
          />
        )}
        actionBar={
          <>
            {showButtons && (
              <>
                <IconButton
                  onClick={() =>
                    updateState({
                      showAddReferralModal: true,
                      selectedReferral: undefined,
                    })
                  }
                >
                  <Iconify icon="eva:plus-fill" />
                </IconButton>
                <IconButton onClick={onExpand}>
                  <Iconify icon="eva:expand-fill" />
                </IconButton>
              </>
            )}
          </>
        }
      />
    </>
  )
}

export default ReferralsSummary;
