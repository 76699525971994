import { useEffect } from 'react';
import useObjectState from './useObjectState';

const useGridFilterConsolidation = <T>(filterValues: T, initialFiltersState: T) => {
  const [filters, updateFilters] = useObjectState<T>(initialFiltersState);

  useEffect(() => {
    updateFilters({ ...filterValues });
  }, [filterValues, updateFilters]);

  const onClearAllFilters = () => {
    updateFilters({ ...initialFiltersState });
  };

  return {
    filters,
    updateFilters,
    onClearAllFilters,
  };
};

export default useGridFilterConsolidation;
