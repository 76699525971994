import { 
  FormControl,
  FormHelperText,
  IconButton, 
  MenuItem, 
  Select, 
  Stack, 
  Table, 
  TableBody, 
  TableCell, 
  TableRow, 
  TextField, 
  Typography 
} from '@mui/material';
import { capitalCase } from 'change-case';
import Iconify from 'src/components/Iconify';
import { TableHeadCustom } from 'src/components/table';
import { usePractitioners } from 'src/@nicheaim/fhir-react';
import RHFAutocomplete from 'src/components/hook-form/RHFAutocomplete';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import { TABLE_HEAD_APPOINTMENT_PARTICIPANT } from 'src/sections/crs/common/table-head';
import { AppointmentParticipantRequired, AppointmentParticipantStatus } from './AppointmentForm';

const AppointmentParticipantList = () => {
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'participant',
  });

  const handleAddParticipant = () => {
    append({
      actor: {
        label: '',
        value: ''
      },
      status: '',
      required: '',
    });
  };

  const handleRemove = (index: number) => {
    remove(index);
  };

  const [practitioner] = usePractitioners({ map: PractitionerWrapper });

  return (
    <>
      <Stack direction="row" sx={{ pb: 1 }}  style={{ display: "flex" }}>
        <Typography sx={{ ml: 1, mt: 0.7, py: 0.5 }}>
          Participant
        </Typography> 
        <IconButton onClick={handleAddParticipant} sx={{ p: 0.5, ml: 1 }}>
          <Iconify icon={'eva:plus-fill'}/>
        </IconButton>
      </Stack>
      <Table size="small" sx={{ mb: 2 }}>
        <TableHeadCustom headLabel={TABLE_HEAD_APPOINTMENT_PARTICIPANT} />
        <TableBody>
          {fields.map((item, index) => (
            <TableRow key={item?.id}>
              <TableCell sx={{ width: '300px' }}>
                <RHFAutocomplete
                  options={practitioner?.map(option => ({
                    label: option?.getFullName(),
                    value: option.id,
                  })) ?? []}
                  name={`participant.${index}.actor`}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      fullWidth
                      margin="dense"
                      size="small"
                      variant="standard"
                      required
                    />
                  )}
                />
                <FormHelperText sx={{ color: '#FF4842'}}></FormHelperText>
              </TableCell>

              <TableCell sx={{ width: '200px'}}>
                <Controller
                  name={`participant.${index}.status`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl sx={{mt: error && 2 }}>
                      <Select
                        {...field}
                        variant="standard"
                        error={!!error}
                        sx={{ width: '200px'}}
                      >
                        {Object.entries(AppointmentParticipantStatus).map((option) => (
                          <MenuItem key={option[0]} value={option[1]}>
                            {capitalCase(option[1])}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{ color: '#FF4842'}}>{error?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
              </TableCell>

              <TableCell sx={{ width: '200px'}}>
                <Controller
                  name={`participant.${index}.required`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl sx={{mt: error && 2 }}>
                      <Select
                        {...field}
                        variant="standard"
                        error={!!error}
                        sx={{ width: '200px'}}
                      >
                        {Object.entries(AppointmentParticipantRequired).map((option) => (
                          <MenuItem key={option[0]} value={option[1]}>
                            {capitalCase(option[1])}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{ color: '#FF4842'}}>{error?.message}</FormHelperText>
                    </FormControl>
                  )}
                />
              </TableCell>
              <TableCell>
              <IconButton
                onClick={() => handleRemove(index)}
                size="medium"
                sx={{
                  '&:hover': {
                    background: 'none',
                  },
                }}
              >
                <Iconify icon={'eva:trash-2-outline'} color="red" />
              </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
    </>
  );
};

export default AppointmentParticipantList;
