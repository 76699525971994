import { Route, Routes } from 'react-router';
import AutomationsTable from './components/AutomationsTable';
import NewAutomationForm from './new';
import EditAutomationSheet from './$sheet.edit';

export default function AutomationsIndex() {
  return (
    <Routes>
      <Route index element={<AutomationsTable />} />
      <Route
        path="new"
        element={<NewAutomationForm />}
        loader={async () => {
          return Math.random();
        }}
      />
      <Route path=":sheet/edit" element={<EditAutomationSheet />} />
    </Routes>
  );
}
