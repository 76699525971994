import produce from 'immer';
import { useNavigate } from 'react-router';
import { useCodeSystem, useCodeSystems } from 'src/@nicheaim/fhir-react';
import { CodeSystemConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { EntityContextMenu } from 'src/sections/careflow/common';
import { useDialogStore } from 'src/stores/dialog';

export interface ActivityTypesContextMenuProps {
  item: CodeSystemConcept;
}

export default function ActivityTypesContextMenu({ item }: ActivityTypesContextMenuProps) {
  const navigate = useNavigate();
  const { confirmDialog } = useDialogStore();
  const [activityTypesCodeSystem, { update }] = useCodeSystem('ccm-activity-types');
  const isActive = (item.property?.find((p) => p.code === 'active')?.valueBoolean ?? true) === true;

  if (!activityTypesCodeSystem) {
    return null;
  }

  return (
    <EntityContextMenu
      entity={item}
      items={[
        {
          label: 'Edit',
          icon: 'eva:edit-fill',
          onPress: () => navigate(`${item.code}/edit`),
        },
        isActive
          ? {
              label: 'Disable',
              icon: 'eva:trash-2-outline',
              onPress: async () => {
                const confirmed = await confirmDialog({
                  title: 'Disable',
                  description: 'Are you sure you want to disable this item?',
                });

                if (!confirmed) {
                  return;
                }

                await update(
                  produce(activityTypesCodeSystem, (draft) => {
                    const concept = draft.concept?.find((c) => c.code === item.code);

                    if (!concept) {
                      return;
                    }

                    const property = concept.property?.find((p) => p.code === 'active');

                    if (property) {
                      property.valueBoolean = false;
                    } else {
                      concept.property ??= [];
                      concept.property.push({
                        code: 'active',
                        valueBoolean: false,
                      });
                    }
                  })
                );
              },
            }
          : {
              label: 'Enable',
              onPress: async () => {
                update(
                  produce(activityTypesCodeSystem, (draft) => {
                    const concept = draft.concept?.find((c) => c.code === item.code);

                    if (!concept) {
                      return;
                    }

                    const property = concept.property?.find((p) => p.code === 'active');
                    if (property) {
                      property.valueBoolean = true;
                    }
                  })
                );
              },
              icon: 'eva:checkmark-circle-2-outline',
            },
      ]}
    />
  );
}
