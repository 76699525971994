import 'moment-timezone';
import { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import Iconify from 'src/components/Iconify';
import { Theme } from '@mui/material/styles';
import Scrollbar from 'src/components/Scrollbar';
import { Clear as ClearIcon } from '@mui/icons-material';
import ComplementaryInfoRead from './ComplementaryInfoRead';
import ComplementaryInfoEdit from './ComplementaryInfoEdit';
import { Card, Stack, Typography, Grid, Tooltip, IconButton, Collapse, Box } from '@mui/material';

const ComplementaryInfoComponent = ({
  title,
  dataSchema,
  data,
  workflowInstance,
  refreshWorkflow,
  openCollapseExternal
}: any) => { 

  const { scope = {} } = data;
  const { checklistItem = {} } = scope;
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleEdit = () => {
    setEditData(false);
    setEditMode(false);
  };

  const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: theme?.palette?.common?.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme?.shadows?.[1],
      fontSize: 11,
      marginTop: '-145px',
    },
  }))(Tooltip); 

  useEffect(() => {
    setOpen(openCollapseExternal)
  }, [openCollapseExternal]);

  return (
    <Card sx={{ p: 4, display: 'block' }}>
     
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Typography sx={{ mt: 1 }}>{title}</Typography>
        <IconButton onClick={() => setOpen(!open)}>
          <Iconify icon={ open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'} />
        </IconButton>
      </Box>

      <Collapse in={open}>
        <Stack sx={{ mb: 3 }}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Grid item xs={3} sx={{ textAlign: 'right', marginBottom: 2 }}>
              {!editMode && Object.keys(checklistItem).length > 0 ? (
                <LightTooltip
                  title="Edit Complementary Info"
                  placement="bottom"
                  style={{ marginRight: 15 }}
                >
                  <IconButton
                    sx={{ p: 0.5, ml: 0.5, marginRight: '0 !important' }}
                    onClick={() => setEditMode(true)}
                  >
                    <Iconify color="#1890FF" icon="eva:edit-outline" />
                  </IconButton>
                </LightTooltip>
              ) : editMode && Object.keys(checklistItem).length > 0 ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                  <LightTooltip title="Close Complementary Info" placement="bottom">
                    <ClearIcon
                      className="font-small margin-xs-right-btn text-primary div-hand"
                      sx={{ color: '#1890FF', cursor: 'pointer' }}
                      onClick={() => {
                        setEditMode(false);
                      }}
                    />
                  </LightTooltip>
                  <LightTooltip title="Save Complementary Info" placement="bottom">
                    <IconButton
                      sx={{ p: 0.5, ml: 0.5, marginRight: '0 !important' }}
                      onClick={() => setEditData(!editData)}
                    >
                      <Iconify color="#1890FF" icon="eva:save-outline" />
                    </IconButton>
                  </LightTooltip>
                </div>
              ) : null}
            </Grid>
          </Grid>

          <Scrollbar sx={{ height: 477}}>
            {editMode ? (
              <ComplementaryInfoEdit 
                dataSchema={dataSchema} 
                data={data} 
                edit={editData} 
                refreshWorkflow={refreshWorkflow} 
                workflowInstance={workflowInstance}
                handleEdit={handleEdit}
              />
            ) : (
              <ComplementaryInfoRead data={data} />
            )}
          </Scrollbar>
        </Stack>
      </Collapse>
   </Card>
  )
};

export default ComplementaryInfoComponent;