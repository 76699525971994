// @ts-nocheck
import { useState } from 'react';
import produce from 'immer';
import { DatePicker } from '@mui/lab';
import { Stack, Typography, IconButton, TextField, Button, Autocomplete } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { SHOW_SIDEBAR_COMPONENT, useEventBus } from 'src/event-bus';
import { useCareTeams, useServiceRequest, useServiceRequests } from 'src/@nicheaim/fhir-react';
import { servicesValueSet } from 'src/_mock/settings/_servicesValueSet';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import { CareTeamParticipant } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';

interface ServicesSummaryFormProps {
  data?: any;
  onClose: () => void;
}

const ServicesSummaryForm = ({ data, onClose }: ServicesSummaryFormProps) => {
  const patient = useCurrentPatient();
  const id = data?.id;
  const isEditting = Boolean(id);

  const startDateInit = data?.occurrencePeriod?.start
    ? new Date(data?.occurrencePeriod?.start)
    : null;
  const endDateInit = data?.occurrencePeriod?.end ? new Date(data?.occurrencePeriod?.end) : null;
  const initPerformer = data?.performer?.[0]?.display;
  const initType = data?.category?.[0]?.coding?.[0]?.display;
  const initCode = data?.category?.[0]?.coding?.[0]?.code;
  const initServices = data?.category?.[0]?.text;
  const initDiagnosis = data?.reasonCode?.[0]?.coding?.[0]?.display;

  const [, { create, update }] = useServiceRequests({ autofetch: false });
  const [careTeam] = useCareTeams({
    filter: {
      subject: `Patient/${patient?.id}`,
    },
  });
  const participants = careTeam?.[0]?.participant;

  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState<Date | null>(startDateInit);
  const [type, setType] = useState(initType || '');
  const [typeDisplayed, setTypeDisplayed] = useState(initType || '');
  const [code, setCode] = useState(initCode || '');
  const [endDate, setEndDate] = useState<Date | null>(endDateInit);
  const [servicesReceived, setServicesReceived] = useState(initServices || '');
  const [diagnoses, setDiagnoses] = useState(initDiagnosis || '');
  const [performer, setPerformer] = useState(initPerformer || '');
  const [performerDisplayed, setPerformerDisplayed] = useState(initPerformer || '');

  const clearAll = () => {
    setDate(null);
    setType('');
    setCode('');
    setEndDate(null);
    setServicesReceived('');
    setDiagnoses('');
    setPerformer('');
  };

  const createServiceRequest = async () => {
    await create({
      resourceType: 'ServiceRequest',
      intent: 'order',
      status: 'active',
      subject: { reference: `Patient/${patient?.id}` },
      occurrencePeriod: { start: date?.toISOString(), end: endDate?.toISOString() },
      category: [
        {
          coding: [
            {
              code,
              display: type,
              system: 'http://hl7.org/fhir/ValueSet/procedure-code',
            },
          ],
          text: servicesReceived,
        },
      ],
      performer: [{ display: performer }],
      reasonCode: [{ coding: [{ display: diagnoses }] }],
    }).then(() => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  };

  const updateServiceRequest = async () => {
    const updatedService = produce(data, (draft) => {
      draft.category = [
        {
          coding: [
            {
              code,
              display: type,
              system: 'http://hl7.org/fhir/ValueSet/procedure-code',
            },
          ],
          text: servicesReceived,
        },
      ];
      draft.occurrencePeriod = {
        start: date?.toISOString(),
        end: endDate?.toISOString()
      };
      draft.performer= [{ display: performer }];
      draft.reasonCode = [{ coding: [{ display: diagnoses }] }];
    });
    await update(updatedService).then(() => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    isEditting ? await updateServiceRequest() : await createServiceRequest();
    clearAll();
    onClose();
  };

  return (
    <Stack px={2}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="subtitle1">{isEditting ? 'Update' : 'Add'} Services</Typography>
        <IconButton onClick={onClose}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>
      <Stack>
        <DatePicker
          label="Date"
          value={date}
          onChange={setDate}
          renderInput={(params) => <TextField variant="standard" {...params} />}
        />
      </Stack>
      <Autocomplete
        disablePortal
        freeSolo
        options={servicesValueSet.compose.include[0].concept}
        getOptionLabel={(option: any) => `${option.display} (${option.code})`}
        inputValue={typeDisplayed}
        value={servicesValueSet.compose.include[0].concept.find((s) => s?.display === type)}
        renderInput={(params: any) => (
          <TextField
            {...params}
            fullWidth
            label="Type"
            margin="dense"
            size="small"
            variant="standard"
          />
        )}
        onChange={(ev, newValue) => {
          setType(newValue?.display || '');
          setCode(newValue?.code || '');
        }}
        onInputChange={(ev, newValue) => {
          setTypeDisplayed(newValue || '');
        }}
      />
      <Autocomplete
        disablePortal
        freeSolo
        options={participants || []}
        getOptionLabel={(option: CareTeamParticipant ) => option.member?.display || ''}
        inputValue={performerDisplayed}
        value={participants?.find((p) => p.member?.display === performer)}
        renderInput={(params: any) => (
          <TextField
            {...params}
            fullWidth
            label="Provider"
            margin="dense"
            size="small"
            variant="standard"
          />
        )}
        onChange={(ev, newValue) => {
          setPerformer(newValue?.member?.display || '');
        }}
        onInputChange={(ev, newValue) => {
          setPerformerDisplayed(newValue || '');
        }}
      />
      <DatePicker
        label="End Date"
        value={endDate}
        onChange={setEndDate}
        renderInput={(params) => <TextField variant="standard" {...params} />}
      />
      <TextField
        fullWidth
        label="Services Received"
        margin="dense"
        size="small"
        variant="standard"
        onChange={(ev) => setServicesReceived(ev.target.value)}
        value={servicesReceived}
      />
      <TextField
        fullWidth
        label="Diagnoses"
        margin="dense"
        size="small"
        variant="standard"
        onChange={(ev) => setDiagnoses(ev.target.value)}
        value={diagnoses}
      />
      <ActionButtons
        leftButtonTitle='Clear All'
        onLeftButtonPress={clearAll}
        onRightButtonPress={handleSave}
        isLoading={isLoading}
      />
    </Stack>
  );
};

export default ServicesSummaryForm;
