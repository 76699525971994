import { Chip, Stack, Typography } from '@mui/material';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { capitalCase } from 'change-case';

type PatientBasicInfoProps = {
  patient: WrappedPatient;
};

export function PatientBasicInfo({ patient }: PatientBasicInfoProps) {
  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ my: 1}} spacing={2}>
        <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
          {`${patient?.name?.[0]?.given?.[0]} 
            ${patient?.name?.[0]?.given?.[1] !== undefined ? patient?.name?.[0]?.given?.[1] : ''} 
            ${patient?.name?.[0]?.family}`} 
        </Typography>
        <Chip 
          size="small"
          label={capitalCase(patient.getStatus())} 
          color={(patient.getStatus() === 'active' && 'info') || 'error'}
        />
      </Stack>
      <Stack direction="row" alignItems="center">
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, pr: 1 }}>
          MRN:
        </Typography>
        <Typography variant="body2" sx={{ mr: 2 }}>
          {patient.getMRN()?.value}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', p: 1 }}>
          Age:
        </Typography>
        <Typography variant="body2" sx={{ mr: 2 }}>
          {patient.getAgeInYears()} years
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', p: 1 }}>
          Gender:
        </Typography>
        <Typography variant="body2" sx={{ mr: 2 }}>
          {patient.gender}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', p: 1 }}>
          Phone:
        </Typography>
        <Typography variant="body2" sx={{ mr: 2 }}>
          {patient.getPrimaryPhone()?.value}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary', p: 1 }}>
          Email:
        </Typography>
        <Typography variant="body2" sx={{ mr: 2 }}>
          {patient.getPrimaryEmail()?.value}
        </Typography>
      </Stack>
    </>
  );
}
