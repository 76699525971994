import { Card, Container, Stack, Typography } from '@mui/material';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { Route, Routes } from 'react-router';
import ClientsTable from './components/ClientsTable';

// routes
import NewClient from './new';
import ClientIndex from './$client';
// import ClientsTree from './components/ClientsTree';

export default function ClientsIndex() {
  const { themeStretch } = useSettings();

  return (
    <Page title="Care Plan Automation">
      <Container maxWidth={themeStretch ? undefined : 'xl'} sx={{ height: '100%' }}>
        <Stack spacing={3} direction="column" sx={{ height: '100%' }}>
          {/* TODO: Move to Breadcrumbs component */}
          <Stack direction="row" alignItems="baseline">
            <Typography variant="h4" sx={{ mr: 1.5 }}>
              Care Plan
            </Typography>
            <Typography variant="body2" sx={{ mx: 1 }}>
              Dashboard
            </Typography>
            <Typography sx={{ mx: 1 }}>&#8226;</Typography>
            <Typography variant="body2" sx={{ mx: 1 }}>
              Configuration Settings
            </Typography>
            <Typography sx={{ mx: 1 }}>&bull;</Typography>
            <Typography variant="caption" sx={{ mx: 1 }}>
              Clients Management
            </Typography>
          </Stack>

          <Card>
            <Routes>
              <Route index element={<ClientsTable />} />
              <Route path="new" element={<NewClient />} />
              <Route path=":client/*" element={<ClientIndex />} />
            </Routes>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}
