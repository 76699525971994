import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import CollapsibleCard from '../../common/CollapsibleCard';
import { LoadingComponent } from '../../case/components/RelatedReferrals/components';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import { IdentifierDetails } from '../../patient/components/details/IdentifierDetails';
import { Identifier } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
export interface ReferralIdentifiersProps {
  isOpen: boolean;
  serviceRequest: WrappedServiceRequest | null;
  onUpdateIdentifiers: (newIdentifiers: Identifier[]) => void;
}

const ReferralIdentifiers = ({
  isOpen,
  serviceRequest,
  onUpdateIdentifiers,
}: ReferralIdentifiersProps) => {
  const {
    valueSets: [serviceRequestIdentifiers],
    isLoading: isLoadingServiceRequestIdentifiers,
  } = useValueSetsByIdentifiers(['crs-serviceRequest-identifier-types']);

  const isLoading = isLoadingServiceRequestIdentifiers || !serviceRequest?.id;

  return (
    <CollapsibleCard title="Identifiers" isOpenExternal={isOpen}>
      {!isLoading ? (
        <IdentifierDetails
          sxContainer={{ height: 260 }}
          identifiers={serviceRequest?.identifier ?? []}
          catalog={serviceRequestIdentifiers?.asList?.() ?? []}
          onUpdateIdentifiers={onUpdateIdentifiers}
        />
      ) : (
        <LoadingComponent />
      )}
    </CollapsibleCard>
  );
};

export default ReferralIdentifiers;
