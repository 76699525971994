import { Box } from '@mui/material';
import { useNavigate } from 'react-router';
import DataTable, { DataTableColumn } from 'src/components/table/DataTable';
import ActivityListAutomationsToolbar from './AutomationsToolbar';
import { useLists } from 'src/@nicheaim/fhir-react';
import fhirSystem from 'src/fhir/system';
import Label, { LabelColor } from 'src/components/Label';
import { List } from 'src/@nicheaim/fhir-base/mappings/List';
import { EntityContextMenu } from 'src/sections/careflow/common';
import AutomationsContextMenu from './AutomationsContextMenu';

const columns = [
  {
    header: 'Automation Event',
    field: 'title',
  },

  {
    header: 'Status',
    field: 'status',
    render(value: List['status']) {
      const mappings: Record<List['status'], { title: string; color: LabelColor }> = {
        current: {
          title: 'Active',
          color: 'success',
        },
        retired: {
          title: 'Inactive',
          color: 'info',
        },
        'entered-in-error': {
          title: 'Error',
          color: 'error',
        },
      };
      const mapping = mappings[value];

      return <Label color={mapping.color}>{mapping.title}</Label>;
    },
  },
] as DataTableColumn[];

export default function AutomationTable() {
  const [automationSheets] = useLists({
    filter: {
      identifier: fhirSystem.automation.activity.forCode(''),
    },
  });

  return (
    <Box>
      <ActivityListAutomationsToolbar />

      <DataTable
        selectable
        columns={columns}
        data={automationSheets}
        rowContextMenu={(item) => <AutomationsContextMenu item={item} />}
      />
    </Box>
  );
}
