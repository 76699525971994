import { Card, Container, Stack, Typography, Tab, Tabs } from '@mui/material';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { Navigate, Route, Routes, useMatch, useNavigate } from 'react-router';

// tabs
import Breadcrumbs from 'src/components/Breadcrumbs';
import DisenrollmentReasonsIndex from './disenrollment-reasons';
import ExitDestinationsIndex from './exit-destinations';
import { StrictMode } from 'react';

export default function ProgramSettingsIndex() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const match = useMatch('/settings/programs/:tab/*');
  const currentTab = match?.params.tab || 'disenrollment-reasons';

  return (
    <StrictMode>
      <Page title="Care Plan Automation">
        <Container maxWidth={themeStretch ? undefined : 'xl'} sx={{ height: '100%' }}>
          <Stack spacing={3} direction="column" sx={{ height: '100%' }}>
            <Stack direction="row" alignItems="baseline">
              <Breadcrumbs
                title="Care Plan"
                links={[
                  {
                    href: '',
                    name: 'Dashboard',
                  },
                  {
                    href: '',
                    name: 'Configuration Settings',
                  },
                  {
                    href: '',
                    name: 'Programs Settings',
                  },
                ]}
              />
            </Stack>

            <Card>
              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                sx={{ px: 2, bgcolor: 'background.neutral' }}
                value={currentTab}
                onChange={(_, tab) => navigate(tab)}
              >
                <Tab value={'disenrollment-reasons'} label="Disenrollment Reasons" />
                <Tab value={'exit-destinations'} label="Exit Destinations" />
              </Tabs>

              <Routes>
                <Route index element={<Navigate to={'disenrollment-reasons'} />} />
                <Route path={`disenrollment-reasons/*`} element={<DisenrollmentReasonsIndex />} />
                <Route path={`exit-destinations/*`} element={<ExitDestinationsIndex />} />
                {/* <Route
                path={`${ConditionsToOpportunitiesTab.path}/*`}
                element={<ConditionsToOpportunitiesTab />}
              />
              <Route path={`${GoalGroupsTab.path}/*`} element={<GoalGroupsTab />} />
              <Route path={`${GoalsTab.path}/*`} element={<GoalsTab />} />
              <Route path={`${GoalsToGoalGroupsTab.path}/*`} element={<GoalsToGoalGroupsTab />} />
              <Route path={`${InterventionsTab.path}/*`} element={<InterventionsTab />} />
              <Route path={`${CarePlanAutomationTab.path}/*`} element={<CarePlanAutomationTab />} />
              <Route path={`${CarePlanOthersTab.path}/*`} element={<CarePlanOthersTab />} /> */}
              </Routes>
            </Card>
          </Stack>
        </Container>
      </Page>
    </StrictMode>
  );
}
