import useObjectState from "./useObjectState";

type useAddEntityRequestStatesStateType = {
  isLoading: boolean;
  error: string | null;
  success: string | null;
}

type useAddEntityRequestStatesActionsType = {
  setIsLoading: (isLoading: boolean) => void;
  setError: (error: string | null) => void;
  setSuccess: (success: string | null) => void;
}

const useAddEntityRequestStates = (): [
  useAddEntityRequestStatesStateType,
  useAddEntityRequestStatesActionsType,
] => {
  const [state, updateState] = useObjectState<useAddEntityRequestStatesStateType>({
    isLoading: false,
    error: null,
    success: null,
  });

  const setIsLoading = (isLoading: boolean) => updateState({
    isLoading,
  })

  const setError = (error: string | null) => updateState({
    error,
  })

  const setSuccess = (success: string | null) => updateState({
    success,
  })

  return [
    state,
    {
      setIsLoading,
      setError,
      setSuccess
    }
  ]
};

export default useAddEntityRequestStates;
