import moment from 'moment';
import { shortFormat } from '../../../../utils/formatTime';
import { DateRange } from '../model';
import { format } from 'date-fns';

export enum RangeOption {
  TODAY = 'today',
  WEEK = 'week',
  MONTH = 'month',
  ALL = 'all',
}

export interface StandardRange {
  option: RangeOption;
  value: DateRange;
}

interface RangeFactory {
  (date: Date): DateRange;
}

export const RANGE_OPTIONS = [
  { value: RangeOption.TODAY },
  { value: RangeOption.WEEK },
  { value: RangeOption.MONTH },
  { value: RangeOption.ALL },
];

const OPTION_RANGES = new Map<String, RangeFactory>(
  Object.entries({
    [RangeOption.TODAY]: dayRange,
    [RangeOption.WEEK]: weekRange,
    [RangeOption.MONTH]: monthRange,
  })
);

export function dayRange(date: Date): DateRange {
  return {
    start: moment(date).startOf('day').toDate(),
    end: moment(date).endOf('day').toDate(),
  };
}

export function weekRange(date: Date): DateRange {
  return {
    start: moment(date).startOf('week').toDate(),
    end: moment(date).endOf('week').toDate(),
  };
}

export function monthRange(date: Date): DateRange {
  return {
    start: moment(date).startOf('month').toDate(),
    end: moment(date).endOf('month').toDate(),
  };
}

export function getRange(option: RangeOption, now: Date): DateRange | null {
  const rangeFunction = OPTION_RANGES.get(option);
  if (rangeFunction) {
    return rangeFunction(now);
  }
  return null;
}

function addToRange(range: StandardRange, count: number): StandardRange {
  const { option: rangeOption, value: dateRange } = range;
  let addFunction;
  let startFunction;
  let endFunction;
  switch (rangeOption) {
    case RangeOption.TODAY:
      addFunction = (date: Date, count: number) => moment(date).add(count, 'days').toDate();
      startFunction = (date: Date) => moment(date).startOf('day').toDate();
      endFunction = (date: Date) => moment(date).endOf('day').toDate();
      break;
    case RangeOption.WEEK:
      addFunction = (date: Date, count: number) => moment(date).add(count, 'weeks').toDate();
      startFunction = (date: Date) => moment(date).startOf('week').toDate();
      endFunction = (date: Date) => moment(date).endOf('week').toDate();
      break;
    case RangeOption.MONTH:
      addFunction = (date: Date, count: number) => moment(date).add(count, 'months').toDate();
      startFunction = (date: Date) => moment(date).startOf('month').toDate();
      endFunction = (date: Date) => moment(date).endOf('month').toDate();
  }
  if (!addFunction || !startFunction || !endFunction) {
    return range;
  }

  const nextRangeStart = addFunction(dateRange.end, count);
  return {
    option: rangeOption,
    value: {
      start: startFunction(nextRangeStart),
      end: endFunction(nextRangeStart),
    },
  };
}

export function incrementRange(range: StandardRange): StandardRange {
  return addToRange(range, 1);
}

export function decrementRange(range: StandardRange): StandardRange {
  return addToRange(range, -1);
}

export function formatRange(range: StandardRange): string {
  const { option: rangeOption, value: dateRange } = range;
  if (!dateRange) {
    return '';
  }

  console.log('formatRange', dateRange.start, dateRange.end);
  switch (rangeOption) {
    case RangeOption.TODAY:
      return shortFormat(dateRange.start);
    case RangeOption.WEEK:
      return `${shortFormat(dateRange.start)} - ${shortFormat(dateRange.end)}`;
    case RangeOption.MONTH:
      return format(dateRange.start, 'MMM yyyy');
  }

  return '';
}
