import { format } from 'date-fns';
import useTabs from 'src/hooks/useTabs';
import { useEffect, useState } from 'react';
import { fToNow } from 'src/utils/formatTime';
import AnnotationForm from './AnnotationForm';
import TextMaxLine from 'src/components/TextMaxLine';
import EmptyContent from 'src/components/EmptyContent';
import OutboundReferralFilters from './OutboundReferralFilters';
import { WrappedCommunication } from 'src/@nicheaim/fhir-base/wrappers/Communication';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { Button, Card, Chip, Dialog, DialogTitle, Stack, Tab, Tabs, Typography } from '@mui/material';

const styleTab = [
  {
    '&.MuiTab-root': { 
      maxWidth: "lg", maxHeight: "lg", background: "#F8F8F8",
      '&:last-of-type': {
        marginRight: 2,
      }
    },
    '&.Mui-selected': { 
      maxWidth: "lg", maxHeight: "lg", background: 'white',
      '& .MuiTypography-subtitle2': { 
          color: '#008ECC'
      },
      '& .MuiTypography-caption': {
        color: '#008ECC'
      },
      '& .MuiChip-root': {
        color: 'white'
      }
    }
  }
];

interface Props {
  open: boolean;
  communications: WrappedCommunication[];
  serviceRequest: WrappedServiceRequest | null;
  onClose: VoidFunction;
  handleCommunication: (data: any) => Promise<any>;
}

export default function OutboundReferralAnnotations ({ 
  open, 
  communications, 
  serviceRequest, 
  onClose, 
  handleCommunication 
}: Props) {

  const [noteData, setNoteData] = useState<WrappedCommunication[]>();
  
  useEffect(() => {
    setNoteData([]);
    if(communications){
      setNoteData(communications)
    }
  },[open, communications]);

  const [openAnnotation, setOpenAnnotation] = useState(false);
  
  const { currentTab, onChangeTab, setCurrentTab  } = useTabs();

  const [filterName, setFilterName] = useState('');

  const [filterTag, setFilterTag] = useState('all');

  const handleFilterName = (filterName: string) => {
    setFilterName(filterName);
    setCurrentTab(dataFiltered?.[0].id || '')
  };
  const handleFilterTag = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterTag(event.target.value);
    setCurrentTab(dataFiltered?.[0].id || '')
  };

  const handleCloseAnnotation = () => {
    setOpenAnnotation(false);
  };

  const dataFiltered = applySortFilter({
    noteData,
    filterName, 
    filterTag
  });
  
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Outbound Referral Annotations </DialogTitle>
      <Stack sx={{ m: 4 }}>
        <Card>
          <Stack direction="row" sx={{ m:2 }} spacing={2}>
            <OutboundReferralFilters 
              filterName={filterName} 
              filterTag={filterTag} 
              onFilterName={handleFilterName} 
              onFilterTag={handleFilterTag}
            />

            <Stack width={1} alignItems="end">
              <Button size='large' color="info" onClick={() => setOpenAnnotation(true)}>
                Add a New Annotation
              </Button>
            </Stack>
          </Stack>

          {dataFiltered?.length !== 0 ? (
              <Card sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', m: 2 }}>
                <Tabs
                  orientation="vertical"
                  value={currentTab}
                  onChange={onChangeTab}
                  TabIndicatorProps={{
                    style: { background: "white" }
                  }}
                >
                  {dataFiltered?.map((tab) => (
                    <Tab 
                      key={tab.id} 
                      value={tab.id}
                      label={<TabItem key={tab.id} item={tab}/>}
                      sx={styleTab}
                    />
                  ))}
                </Tabs>

                {dataFiltered?.map((data) => {
                  const isMatched = data?.id === currentTab;
                  return isMatched && <NoteItem key={data?.id} item={data} />;
                })}
              </Card>
            ):(
              <EmptyContent
                title="No Data"
                sx={{
                  '& span.MuiBox-root': { height: 160 },
                }}
              />
            )
          }
        </Card>
      </Stack>

      <AnnotationForm 
        open={openAnnotation} 
        onClose={handleCloseAnnotation}
        serviceRequest={serviceRequest}
        handleCommunication={handleCommunication}
      />
    </Dialog>
  )
}

// ----------------------------------------------------------------------

type TabItemProps = {
  item: WrappedCommunication;
};

function TabItem({ item }: TabItemProps) {

  const { category, note, sender, sent } = item;
  
  return (

    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" >
          {sender?.display} wrote:
        </Typography>
        <Typography variant="caption" sx={{ mt: 0.5, display: 'block' }}>
          {sent && (format(new Date(sent), 'MMM dd, yyyy'))}&nbsp;
          ({ sent && fToNow(new Date(sent))} ago)
        </Typography>
      </Stack>
      <Stack direction="row">
        <TextMaxLine variant='body2' textAlign='left' textTransform='initial' line={2} persistent>
          {note?.[0].text}
        </TextMaxLine>
      </Stack>
      <Stack direction="row" flexWrap="wrap">
        {category?.map((tag, index) => (
          <Chip size="small" key={`category-${index}`} label={`${tag.coding?.[0].display} Note`}
            sx={{ mr: 1, mb: 1, color: '#C0C0C0', background: '#008ECC', fontSize: 10 }} 
          />
        ))}
      </Stack>
    </Stack>
  );
}

// ----------------------------------------------------------------------

type NoteItemProps = {
  item: WrappedCommunication;
};

function NoteItem({ item }: NoteItemProps) {

  const { sender, note } = item;
  
  return (

    <Stack spacing={2} sx={{ m: 2 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5" sx={{ color:'#008ECC', textTransform: 'capitalize', }}>
          {sender?.display} wrote:
        </Typography>
      </Stack>
      <Stack direction="row">
        <Typography variant='body2' textAlign='left' textTransform='initial'>
          {note?.[0].text}
        </Typography>
      </Stack>
    </Stack>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  noteData,
  filterName,
  filterTag,
}: {
  noteData: WrappedCommunication[] | undefined;
  filterName: string;
  filterTag: string;
}) {

  if (filterName) {
    noteData = noteData?.filter(
      (item: Record<string, any>) =>
        item.sender.display.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterTag !== 'all') {
    noteData = noteData?.filter((item) => item.category?.some((c) => c.text?.toLowerCase() === filterTag.toLowerCase()));
  }

  return noteData;
}