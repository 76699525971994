import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router';
import api from 'src/services/api';
import ConditionsToOpportunitiesCreateForm from './ConditionsToOpportunitiesCreateForm';
import ConditionsToOpportunitiesEditForm from './ConditionsToOpportunitiesEditForm';
import ConditionsToOpportunitiesTable from './ConditionsToOpportunitiesTable';
import ConditionsToOpportunitiesToolbar from './ConditionsToOpportunitiesToolbar';

function ConditionsToOpportunitiesTab() {
  return (
    <Routes>
      <Route index element={<ConditionsToOpportunitiesList />} />
      <Route path="new" element={<ConditionsToOpportunitiesCreateForm />} />
      <Route path="edit/:id" element={<EditForm />} />
    </Routes>
  );
}

function ConditionsToOpportunitiesList() {
  return (
    <>
      <ConditionsToOpportunitiesToolbar />
      <ConditionsToOpportunitiesTable />
    </>
  );
}

function EditForm() {
  const params = useParams();
  const {
    data: mapping,
    isLoading,
    refetch,
  } = useQuery(['mappings-conditions-to-opportunities', params.id], async () => {
    if (!params.id) {
      return null;
    }

    return api.carePlan.getMappingsConditionOpportunity(params.id);
  });

  useEffect(() => {
    refetch();
  }, []);

  if (!mapping && !isLoading) return <Navigate to=".." />;
  return <ConditionsToOpportunitiesEditForm currentMapping={mapping} />;
}

export default Object.assign(ConditionsToOpportunitiesTab, {
  path: 'map-c-o',
});
