import { Box, Modal } from "@mui/material";

type AddModalProps = {
  Component: React.ReactElement;
  open: boolean;
  onClose: () => void;
  containerStyle?: any;
}

const AddModal = ({
  Component,
  open,
  onClose,
  containerStyle,
}: AddModalProps) => (
  <Modal
    open={open}
    onClose={onClose}
    onBackdropClick={onClose}
    aria-labelledby="keep-mounted-modal-title"
    aria-describedby="keep-mounted-modal-description"
  >
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: 0,
        p: 4,
        width: 450,
        height: '100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        overflowY: 'scroll',
        ...containerStyle,
      }}
    >
      {Component}
    </Box>
  </Modal>
)

export default AddModal;
