import { AllergyIntolerance } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

export const getAllergyContextMenuItems = (updateState: any) => {
  const onDeletePress = async (allergy: AllergyIntolerance, popupState: any) => {
    updateState({
      showDeleteAllergyModal: true,
      selectedAllergy: allergy,
    });
    popupState.close();
  };

  const onEditPress = (allergy: AllergyIntolerance) => {
    updateState({
      selectedAllergy: allergy,
      showAddAllergyModal: true,
    });
  };

  return [
    {
      label: 'Edit',
      icon: 'eva:edit-2-outline',
      onPress: onEditPress,
      rules: () => true,
    },
    {
      label: 'Delete',
      icon: 'bi:trash',
      onPress: onDeletePress,
      rules: () => true,
    },
  ];
};
