import { useEffect } from 'react';
import { useList, usePlanDefinitions } from 'src/@nicheaim/fhir-react';
import fhirSystem from 'src/fhir/system';
import { useDialogStore } from 'src/stores/dialog';
import useCarePlanAutomationFormStore from './store';

export default function DetectExternalRuleChanges() {
  const selectedAutomationSheet = useCarePlanAutomationFormStore(
    (s) => s.selectedAutomationSheetId
  );
  const { confirmDialog } = useDialogStore();
  const [automationSheet] = useList(selectedAutomationSheet);
  const [rules] = usePlanDefinitions({
    filter: {
      identifier: fhirSystem.automation.carePlan.rule.withId(selectedAutomationSheet).forCode(''),
    },
  });

  console.log({
    rules: rules,
    automationSheet: automationSheet,
  });

  useEffect(() => {
    async function detectChanges() {
      if (rules.length !== (automationSheet?.entry?.length || 0)) {
        const confirms = await confirmDialog({
          title: 'Rules updated externally',
          description:
            'Rules have been updated externally but are not reflected on the automation sheet, do you want to update it to match?',
        });

        if (confirms) {
        }
      }
    }

    // detectChanges();
  }, [rules.length, confirmDialog, automationSheet?.entry?.length]);

  return null;
}
