import { FileRejection } from 'react-dropzone';
// @mui
import { alpha } from '@mui/material/styles';
import { Alert, AlertTitle, Box, Paper, Tooltip, Typography } from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';
import getFileData from '../../utils/getFileData';

// ----------------------------------------------------------------------

type Props = {
  fileRejections: FileRejection[];
  onClose?: () => void;
  variant?: 'Alert' | 'Paper';
};

export default function RejectionFiles({ fileRejections, onClose, variant = 'Paper' }: Props) {
  const isAlert = variant === 'Alert';
  const Container = isAlert ? Alert : Paper;
  return (
    <Container
      {...(isAlert ? { onClose, severity: 'error' } : {})}
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {isAlert && <AlertTitle>Error on files</AlertTitle>}

      {fileRejections.map(({ file, errors }) => {
        const { path, size } = getFileData(file);
        const fileText = `${path} - ${size ? fData(size) : ''}`;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Tooltip title={fileText}>
              <Typography variant="subtitle2" noWrap>
                {fileText}
              </Typography>
            </Tooltip>

            {errors.map((error) => (
              <Box key={error.code} component="li" sx={{ typography: 'caption' }}>
                {error.message}
              </Box>
            ))}
          </Box>
        );
      })}
    </Container>
  );
}
