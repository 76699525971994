import { Button } from '@mui/material';
import useCarePlanAutomationFormStore from '../store';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';

interface AddRuleButtonProps {
  question: WrappedQuestionnaireItem;
}

export default function AddRuleButton({ question }: AddRuleButtonProps) {
  const saving = useCarePlanAutomationFormStore((s) => s.loading);
  const addRule = useCarePlanAutomationFormStore((s) => s.addRule);

  return (
    <Button onClick={() => addRule(question)} disabled={saving}>
      Add Rule
    </Button>
  );
}
