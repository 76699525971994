import { SxProps, Alert, Box, Typography } from '@mui/material';
import { spreadSxProp } from 'src/utils/cssStyles';

export enum AlertSeverity {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  DEFAULT = 'default',
}

export type SeverityStatusData = {
  message: string | undefined;
  severity: AlertSeverity | undefined;
};

interface SeverityStatusProps {
  status: SeverityStatusData;
  sx?: SxProps;
}

const SeverityStatus = ({
  status: { severity, message },
  sx,
}: SeverityStatusProps): JSX.Element => {
  const basicAlerts = [
    AlertSeverity.ERROR,
    AlertSeverity.SUCCESS,
    AlertSeverity.INFO,
    AlertSeverity.WARNING,
  ];

  if (!severity) return <Typography>{message ?? ''}</Typography>;

  const isCustomAlert = !basicAlerts.includes(severity);
  let customAlertStyles: {
    [k in AlertSeverity]?: SxProps;
  } = {
    [AlertSeverity.DEFAULT]: { backgroundColor: '#f4f6f8', color: '#637381' },
  };
  return (
    <Alert
      icon={false}
      severity={
        (!isCustomAlert ? severity : AlertSeverity.INFO) as
          | AlertSeverity.ERROR
          | AlertSeverity.SUCCESS
          | AlertSeverity.INFO
          | AlertSeverity.WARNING
      }
      sx={[
        {
          height: '30px',
          fontSize: '0.9em',
          textTransform: 'capitalize',
          fontWeight: 'bold',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 1,
          ...(customAlertStyles?.[severity] ?? {}),
        },
        ...spreadSxProp(sx),
      ]}
    >
      {message}
    </Alert>
  );
};

export default SeverityStatus;
