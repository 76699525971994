import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: false,
  });
}

export function longFormat(date: Date): string {
  return moment(date).format('dddd, MMMM DD, YYYY, h:mm a');
}

export function parseShortFormat(date: string): string {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
  });
}

export function shortFormat(date: Date): string {
  return moment(date).format('MMM DD, yyyy');
}

export function shortHourFormat(date: Date): string {
  return format(date, 'h:mm aa');
}
export function getAge(birthDate: Date) {
  return birthDate ? Math.trunc(Math.abs(Date.now() - birthDate.getTime()) / 31557600000) : null;
}

export function getTimeSince(date: Date) {
  const days = getDaysSince(date);
  if (days > 365) return `${getYearsSince(date)} years ago`;
  if (days > 31) return `${getMonthsSince(date)} months ago`;
  return `${days} days ago`;
}

export function getTimeSince1(date: Date) {
  const days = getDaysSince(date) * -1;

  if (days < 31) return `in ${days} days`;
  if (days > 365) return `in ${getYearsSince(date) * -1} years`;
  if (days > 31) return `in ${getMonthsSince(date) * -1} months`;
}

export function getDaysSince(date: Date) {
  const today = moment();
  return today.diff(date, 'days');
}

export function getMonthsSince(date: Date) {
  const today = moment();
  return today.diff(date, 'months');
}

export function getYearsSince(date: Date) {
  const today = moment();
  return today.diff(date, 'years');
}

export function fDateLink(date: Date | string | number | undefined) {
  if (date === undefined) return null;
  return format(new Date(date), 'MM/dd/yyyy');
}
