import {
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  CircularProgress,
  Box,
  Alert,
} from '@mui/material';
import moment from 'moment';
import { useContext } from 'react';
import Iconify from 'src/components/Iconify';
import { ActivitiesContext } from '../../Activities/ActivitiesContext';
import { getColumns } from '../constants';
import PatientViewModel from '../ViewModel/PatientViewModel';
import PatientContextMenu from './PatientContextMenu';
import useAuth from 'src/hooks/useAuth';
import { checkAclValidation, getRelatedAcls } from 'src/utils/permissions/permission.utils';

type PatientsListTableProps = {
  isLoading: boolean;
  patients: PatientViewModel[];
  filterStatus: string;
  onDeleteItem: (patient: PatientViewModel) => void;
};

interface IProgramsStatusData {
  programId: string | null;
  programName: string | null;
  status: string | null;
}

function PatientsListTable({
  isLoading,
  patients,
  filterStatus,
  onDeleteItem,
}: PatientsListTableProps) {
  const authUser = useAuth();
  const columns = getColumns();
  const { onCreateOrUpdateEntity } = useContext(ActivitiesContext);

  function getProgramsStatusList(programsStatus: string = '') {
    const programs = programsStatus.split(';');
    const programStatusList: IProgramsStatusData[] = programs.map((x) => {
      const splitData = x.split(':');
      const newProgram: IProgramsStatusData = {
        programId: splitData[0] && splitData[0] !== ' null' ? splitData[0] : null,
        programName: splitData[1] && splitData[1] !== ' null' ? splitData[1] : null,
        status: splitData[2] && splitData[2] !== ' null' ? splitData[2] : null,
      };

      return newProgram;
    });

    const filteredProgramStatusList = programStatusList.filter(
      (x: IProgramsStatusData) => filterStatus === 'all' || x.status?.includes(filterStatus)
    );

    return filteredProgramStatusList;
  }

  function getProgramsStatusRender(programsStatus: string = '') {
    const programStatusList: IProgramsStatusData[] = getProgramsStatusList(programsStatus);

    return (
      <>
        {programStatusList &&
          programStatusList.map((item) => (
            <Chip
              key={item.programId}
              label={`${item.programName || '-'}: ${item.status || '-'}`}
            />
          ))}
      </>
    );
  }

  console.log({
    patients
  })

  return (
    <TableContainer sx={{ padding: 2 }}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="body1" sx={{ ml: 1, mb: 2, fontWeight: 'light' }}>
            Patients
          </Typography>
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col: any) => (
              <TableCell key={col.label}>
                <Typography>{col.label}</Typography>
              </TableCell>
            ))}
            <TableCell>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            patients.map((patient) => (
            <TableRow key={patient.id}>
              <TableCell>
                <Typography>{patient.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{patient.id}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{patient.primaryPhone}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{getProgramsStatusRender(patient.programs_status || '')}</Typography>
              </TableCell>
              <TableCell>
                {patient.lastNoteRole ? checkAclValidation({
                  user: authUser,
                  acl: getRelatedAcls(`ccm.notes.${patient.lastNoteRole}.read`)
                }) ? <Typography>{patient.lastNote}</Typography> : <Alert severity='warning' color='warning'>{patient.lastNoteRoleDisplay}</Alert> : <Typography>{patient.lastNote}</Typography>}
              </TableCell>
              <TableCell>
                <Typography>
                  {patient.lastActivityDate
                    ? moment(patient.lastActivityDate).format('DD MMM Y')
                    : 'N/A'}
                </Typography>
              </TableCell>
              <TableCell>
                {patient.nextActivityActivity ?? 'N/A'}
                <br />
                {patient.nextActivityStart
                  ? ` - ${moment(patient.nextActivityStart).format('DD MMM Y')}`
                  : ''}
                {patient.nextActivityStart && !patient.allDay
                  ? ` - ${moment(patient.nextActivityStart).format('hh:mm a')}`
                  : ''}
                {patient.allDay ? ' All day' : ''}
              </TableCell>
              <TableCell>
                {patient?.careTeamLeaderName}{' '}
                {patient.displayAssignedToProccesing && (
                  <Tooltip title="Your request is processing...">
                    <Typography>
                      <Iconify
                        icon={'mdi:warning-circle-outline'}
                        color="#1C9CEA"
                        width={24}
                        height={24}
                      />
                    </Typography>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>
                <PatientContextMenu onUpdatePatient={onCreateOrUpdateEntity} patient={patient} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!isLoading && patients.length === 0 && (
        <Grid alignItems="center">
          <Typography style={{ textAlign: 'center', paddingTop: '20px' }}>No Patients</Typography>
        </Grid>
      )}
      {isLoading && (
        <Box textAlign={'center'} marginTop={5}>
          <CircularProgress />
        </Box>
      )}
    </TableContainer>
  );
}

export default PatientsListTable;
