import axios, { AxiosInstance } from 'axios';
import { FhirClient } from '..';
import { createAxiosFhirClient, fhirCookieExists, getFhirCookie, setFhirCookie } from './axios';

export function createAxiosIndexedFhirClient(
  indexedClient: string | AxiosInstance,
  normalClient: string | AxiosInstance
): FhirClient {
  const instance =
    typeof indexedClient === 'string'
      ? axios.create({
          baseURL: indexedClient,
        })
      : indexedClient;

  // make sure content type is always set to application/fhir+json
  instance.defaults.headers.common['Content-Type'] = 'application/fhir+json';
  instance.interceptors.request.use(
    async function (config) {
      if (!fhirCookieExists()) {
        await setFhirCookie();
      }

      if (config.headers === undefined) {
        config.headers = {};
      }

      config.headers.Authorization = 'Bearer ' + getFhirCookie();
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const fhirClient: FhirClient = {
    ...createAxiosFhirClient(normalClient),
    async createOne(resourceType, resource) {
      const response = await instance.post(resourceType, resource);
      // TODO: Support fetching 'indexed' fields
      return response.data.resource;
    },
    async updateOne(resourceType, resource) {
      const response = await instance.patch(`${resourceType}/${resource.id}`, resource);
      return response.data;
    },
    async patchOne(resourceType, resourceId, patch) {
      const response = await instance.patch(`${resourceType}/${resourceId}`, patch);
      // TODO: Support fetching 'indexed' fields
      return response.data.resource;
    },
  };

  return fhirClient;
}
