import { CarePlanOpportunity } from 'src/_mock/settings/_careplan-automation';
import client from '../_client';

// Conditions

export interface Condition {
  id: number;
  uuid: string;
  createdBy: string | null;
  createdOn: string | null;
  updatedBy: string | null;
  updatedOn: string | null;
  deletedOn: string | null;
  deletedBy: string | null;
  name: string;
  type: string;
  notes: string;
  rank: number;
  status: string;
}

export async function findConditions(filter?: any) {
  const query = new URLSearchParams(filter);
  const { data } = await client.get(`/care-plan/conditions?${query}`);
  return data;
}

export async function getCondition(id: string) {
  const { data } = await client.get(`/care-plan/conditions/${id}`);
  return data;
}

export async function createCondition(condition: any) {
  const { data } = await client.post(`/care-plan/conditions`, condition);
  return data;
}

export async function updateCondition(condition: any) {
  const { data } = await client.patch(`/care-plan/conditions/${condition.uuid}`, condition);
  return data;
}

export async function deleteCondition(id: string) {
  const { data } = await client.delete(`/care-plan/conditions/${id}`);
  return data;
}

// Opportunities

export interface Opportunity {
  id: number;
  uuid: string;
  createdBy: string | null;
  createdOn: string | null;
  updatedBy: string | null;
  updatedOn: string | null;
  deletedOn: string | null;
  deletedBy: string | null;
  name: string;
  alias: string;
  priority: string;
  sourceType: string;
  status: string;
}

export const OpportunityPriorities = [
  { value: 'low', label: 'Low' },
  { value: 'medium', label: 'Medium' },
  { value: 'high', label: 'High' },
];

export async function findOpportunities(filter?: any) {
  const query = new URLSearchParams(filter);
  const { data } = await client.get(`/care-plan/opportunities?${query}`);
  return data;
}

export async function getOpportunity(id: string) {
  const { data } = await client.get(`/care-plan/opportunities/${id}`);
  return data;
}

export async function createOpportunity(opportunity: any) {
  const { data } = await client.post(`/care-plan/opportunities`, opportunity);
  return data;
}

export async function updateOpportunity(opportunity: any) {
  const { data } = await client.patch(`/care-plan/opportunities/${opportunity.uuid}`, opportunity);
  return data;
}

export async function deleteOpportunity(id: string) {
  const { data } = await client.delete(`/care-plan/opportunities/${id}`);
  return data;
}

// Mapping between conditions and opportunities

export interface MappingConditionOpportunity {
  id: number;
  uuid: string;
  createdBy: string | null;
  createdOn: string | null;
  updatedBy: string | null;
  updatedOn: string | null;
  deletedOn: string | null;
  deletedBy: string | null;
  conditionId: number;
  opportunityId: number;
  condition: Condition;
  opportunity: Opportunity;
  status: 'active';
}

export async function findMappingsConditionOpportunity(filter?: any) {
  const query = new URLSearchParams(filter);
  const { data } = await client.get<MappingConditionOpportunity[]>(
    `/care-plan/mappings-condition-opportunity?${query}`
  );
  return data;
}

export async function getMappingsConditionOpportunity(id: string) {
  const { data } = await client.get(`/care-plan/mappings-condition-opportunity/${id}`);
  return data;
}

export async function createMappingsConditionOpportunity(mappings: any) {
  const { data } = await client.post(`/care-plan/mappings-condition-opportunity`, mappings);
  return data;
}

export async function updateMappingsConditionOpportunity(mapping: any) {
  const { data } = await client.patch(
    `/care-plan/mappings-condition-opportunity/${mapping.uuid}`,
    mapping
  );
  return data;
}

export async function deleteMappingsConditionOpportunity(id: string) {
  const { data } = await client.delete(`/care-plan/mappings-condition-opportunity/${id}`);
  return data;
}

// Goal Groups

export interface GoalGroup {
  id: number;
  uuid: string;
  createdBy: string | null;
  createdOn: string | null;
  updatedBy: string | null;
  updatedOn: string | null;
  deletedOn: string | null;
  deletedBy: string | null;
  name: string;
  status: string;
}

export async function findGoalGroups(filter?: any) {
  const query = new URLSearchParams(filter);
  const { data } = await client.get<GoalGroup[]>(`/care-plan/goal-groups?${query}`);
  return data;
}

export async function getGoalGroup(id: string) {
  const { data } = await client.get(`/care-plan/goal-groups/${id}`);
  return data;
}

export async function createGoalGroup(goalGroup: any) {
  const { data } = await client.post(`/care-plan/goal-groups`, goalGroup);
  return data;
}

export async function updateGoalGroup(goalGroup: any) {
  const { data } = await client.patch(`/care-plan/goal-groups/${goalGroup.uuid}`, goalGroup);
  return data;
}

export async function deleteGoalGroup(id: string) {
  const { data } = await client.delete(`/care-plan/goal-groups/${id}`);
  return data;
}

// Goals

export interface Goal {
  id: number;
  uuid: string;
  createdBy: string | null;
  createdOn: string | null;
  updatedBy: string | null;
  updatedOn: string | null;
  deletedOn: string | null;
  deletedBy: string | null;
  name: string;
  alias: string;
  status: string;
}

export async function findGoals(filter?: any) {
  const query = new URLSearchParams(filter);
  const { data } = await client.get<Goal[]>(`/care-plan/goals?${query}`);
  return data;
}

export async function getGoal(id: string) {
  const { data } = await client.get(`/care-plan/goals/${id}`);
  return data;
}

export async function createGoal(goal: any) {
  const { data } = await client.post(`/care-plan/goals`, goal);
  return data;
}

export async function updateGoal(goal: any) {
  const { data } = await client.patch(`/care-plan/goals/${goal.uuid}`, goal);
  return data;
}

export async function deleteGoal(id: string) {
  const { data } = await client.delete(`/care-plan/goals/${id}`);
  return data;
}

// Mapping between goal groups and goals

export interface MappingGoalGroupGoal {
  id: number;
  uuid: string;
  createdBy: string | null;
  createdOn: string | null;
  updatedBy: string | null;
  updatedOn: string | null;
  deletedOn: string | null;
  deletedBy: string | null;
  opportunityId: number;
  opportunity: CarePlanOpportunity;
  goalGroupId: number;
  goalId: number;
  status: string;
  goalGroup: GoalGroup;
  goal: Goal;
  interventions: string;
}

export async function findMappingsGoalGroupGoal(filter?: any) {
  const query = new URLSearchParams(filter);
  const { data } = await client.get<MappingGoalGroupGoal[]>(
    `/care-plan/mappings-goalgroup-goal?${query}`
  );
  return data;
}

export async function getMappingsGoalGroupGoal(id: string) {
  const { data } = await client.get(`/care-plan/mappings-goalgroup-goal/${id}`);
  return data;
}

export async function createMappingsGoalGroupGoal(mappings: any) {
  const { data } = await client.post(`/care-plan/mappings-goalgroup-goal`, mappings);
  return data;
}

export async function updateMappingsGoalGroupGoal(mapping: any) {
  const { data } = await client.patch(
    `/care-plan/mappings-goalgroup-goal/${mapping.uuid}`,
    mapping
  );
  return data;
}

export async function deleteMappingsGoalGroupGoal(id: string) {
  const { data } = await client.delete(`/care-plan/mappings-goalgroup-goal/${id}`);
  return data;
}

// Interventions

export interface Intervention {
  id: number | string;
  uuid: string;
  createdBy: string | null;
  createdOn: string | null;
  updatedBy: string | null;
  updatedOn: string | null;
  deletedOn: string | null;
  deletedBy: string | null;
  name: string;
  alias: string;
  createsAppointmentAlert: boolean;
  status: string;
}

export async function findInterventions(filter?: any) {
  const query = new URLSearchParams(filter);
  const { data } = await client.get<Intervention[]>(`/care-plan/interventions?${query}`);
  return data;
}

export async function getIntervention(id: string) {
  const { data } = await client.get(`/care-plan/interventions/${id}`);
  return data;
}

export async function createIntervention(intervention: any) {
  const { data } = await client.post(`/care-plan/interventions`, intervention);
  return data;
}

export async function updateIntervention(intervention: any) {
  const { data } = await client.patch(
    `/care-plan/interventions/${intervention.uuid}`,
    intervention
  );
  return data;
}

export async function deleteIntervention(id: string) {
  const { data } = await client.delete(`/care-plan/interventions/${id}`);
  return data;
}
