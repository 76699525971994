import { MouseEvent, useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  CircularProgress,
} from '@mui/material';
import {
  CareTeamParticipant,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import Iconify from 'src/components/Iconify';
import { RelatedPersonWrapper } from 'src/@nicheaim/fhir-base/wrappers/RelatedPerson';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import { formatAddress, getCareTeamRoles } from '../health-record/patient-info-header/components/utils';

export enum WidgetUserTypeEnum {
  PRACTITIONER,
  RELATED_PERSON,
  PROVIDER,
}

type WidgetUserPropsType = {
  onDelete: (row: any) => void;
  onEdit: (row: any) => void;
  participant: CareTeamParticipant;
  type?: WidgetUserTypeEnum;
}

const WidgetUser = ({ onDelete, onEdit, participant, type = WidgetUserTypeEnum.PRACTITIONER }: WidgetUserPropsType) => {
  const id = getFhirIdFromEntity(participant?.member?.reference ?? '');
  const [practitioner, setPractitioner] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (!isLoading) {
      let url = `/Practitioner/${id}`;

      switch(type) {
        case WidgetUserTypeEnum.RELATED_PERSON:
          url = `/RelatedPerson/${id}`;
          break;
      }

      axiosFhirInstance
        .get(url)
        .then(({ data }) => {
          setIsLoading(false);
          setPractitioner(RelatedPersonWrapper(data));
        })
        .catch((error) =>
          console.log({
            error,
          })
        );
    }
  }, [participant]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const handleEdit = () => {
    onEdit(participant);
    closeMenu();
  };

  const handleDelete = () => {
    onDelete(participant);
    closeMenu();
  };

  const renderPhoneNumbers = (practitioner: any) =>
    practitioner
      ?.getPhones()
      ?.filter((p) => p.value)
      .map((p, index: number) => (
        <Typography variant="caption" key={`phones-${index}`}>
          {`${index > 0 ? '/' : ''}${p?.value} (${p?.use ?? 'Other'})`}
        </Typography>
      ));

  const renderEmails = (practitioner: any) =>
    practitioner
      ?.getEmails()
      ?.filter((p) => p.value)
      .map((e, index: number) => (
        <Typography variant="caption" key={`emails-${index}`}>
          {`${index > 0 ? '/' : ''}${e?.value}`}
        </Typography>
      ));

  return (
    <Stack direction="row" mt={2}>
      <Stack sx={{ flex: 1, flexDirection: 'row' }}>
        <Grid>
          <Typography variant="subtitle2">
            {practitioner?.getFullNameWithTitles() || participant?.member?.display}
            <Typography variant="caption"> / {getCareTeamRoles(participant)}</Typography>
          </Typography>
          <Typography>
            {renderEmails(practitioner)} {renderPhoneNumbers(practitioner)}
          </Typography>
          {practitioner?.address && practitioner?.address?.length > 0 && (
            <Typography variant="caption">
              {formatAddress(practitioner?.address?.[0])}
            </Typography>
          )}
        </Grid>
        <Grid sx={{ paddingLeft: 2 }}>{isLoading && <CircularProgress size={15} />}</Grid>
      </Stack>
      <Stack>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleEdit}>
            <Stack direction="row" spacing={2}>
              <Iconify icon="eva:edit-fill" />
              <Typography variant="body2">Edit</Typography>
            </Stack>
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            <Stack direction="row" spacing={2}>
              <Iconify icon="eva:trash-2-outline" />
              <Typography variant="body2">Delete</Typography>
            </Stack>
          </MenuItem>
        </Menu>
        <IconButton onClick={openMenu}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default WidgetUser;
