import { TableRow, Typography, TableCell, TableCellProps } from '@mui/material';

export interface NoResultsTableRowProps {
  text: string;
  colSpan: number;
  align?: TableCellProps['align'];
}
const NoResultsTableRow = ({ colSpan, align = 'center', text = '' }: NoResultsTableRowProps) => (
  <TableRow>
    <TableCell colSpan={colSpan} align={align}>
      <Typography variant="body2" fontSize={'1rem'} fontWeight={500}>
        {text}
      </Typography>
    </TableCell>
  </TableRow>
);

export default NoResultsTableRow;
