import {
  Dialog,
  DialogTitle,
  Stack,
} from '@mui/material';
import {
  mapCommunicationsToNotesDisplay,
} from './common-utils';
import { useState } from 'react';
import NoteAddForm from './NoteAddForm';
import { TableCustom } from './TableCustom';
import { TABLE_HEAD_NOTES } from './table-head';
import { useCommunications } from 'src/@nicheaim/fhir-react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import { CommunicationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Communication';

type ModalProps = {
  open: boolean;
  patient: WrappedPatient | null;
  resource: any; 
  carePlan?: WrappedCarePlan | null;
  typeNote: string;
  onCancel: VoidFunction;
};

export default function ModalCommunication({
  open,
  patient,
  resource,
  carePlan, 
  typeNote,
  onCancel,
}: ModalProps) {
  const [openNote, setOpenNote] = useState(false);

  const [communications, { refresh: refreshCommunications, create: createCommunication }] =
    useCommunications({
      filter: {'part-of': resource?.id},
      map: CommunicationWrapper,
    });

  const handleCreateCommunicationFhir = async (data: any) => {
    let result: any = {};

    try {
      result = await createCommunication(data);

      setOpenNote(false);
      refreshCommunications();
    } catch (err) {
      console.log('error on handleCreateCommunicationFhir', err);
    }

    return result;
  };

  return (
    <>
      <Dialog open={open} onClose={onCancel} fullWidth={true} maxWidth="md">
        <DialogTitle>Notes</DialogTitle>
        <TableCustom
          title={''}
          data={mapCommunicationsToNotesDisplay(communications, [typeNote])}
          tableHead={TABLE_HEAD_NOTES}
          handleOpen={() => setOpenNote(true)}
          titleButton={'Add A Note'}
          openCollapseExternal={true}
        />
      </Dialog>
      <NoteAddForm
        open={openNote}
        patient={patient}
        onCancel={() => setOpenNote(false)}
        resource={[resource, carePlan]}
        handleCreate={handleCreateCommunicationFhir}
        typeNote={typeNote}
      />
    </>
  );
}
