// @ts-nocheck
import { useContext, useState } from 'react';
import {
  Divider,
  Box,
  Grid,
  Typography,
  FormControl,
  TextField,
  Alert,
  FormLabel,
  InputLabel,
} from '@mui/material';
import Bed from 'src/sections/careflow/rounding/Model/Bed';
import { PatientCard } from 'src/sections/careflow/common';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { CensusContext } from '../../CensusContext';
import api from 'src/services/api';
import moment from 'moment';
import BedAssignment from 'src/sections/careflow/rounding/Model/BedAssignment';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import _ from 'lodash';
import PatientSearchDropdown from 'src/sections/careflow/common/PatientSearchDropdown';

export type AddBedPropsType = {
  onSaveCallback: (bedId: number, bedAssignment: BedAssignment | null) => void;
  onCancelPress: () => void;
  bed: Bed;
};

const AddBed = ({ onSaveCallback, onCancelPress, bed }: AddBedPropsType) => {
  const [patientItem, setPatientItem] = useState<{ label: string; value: number } | null>(
    bed?.currentBedAssignment?.patient
      ? {
          label: bed.currentBedAssignment?.patient?.fullName,
          value: bed.currentBedAssignment?.patient?.id,
        }
      : null
  );
  const [startDate, setStartDate] = useState<string>(
    bed.currentBedAssignment?.start
      ? moment(bed.currentBedAssignment?.start)?.format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string | null>(
    bed.currentBedAssignment?.end
      ? moment(bed.currentBedAssignment?.end)?.format('YYYY-MM-DD')
      : null
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  

  const censusContext = useContext(CensusContext);
  

  const validateForm = () => {
    if (!patientItem || !startDate) {
      return false;
    }

    return true;
  };

  const onSavePress = async () => {
    setIsLoading(true);
    if (!validateForm()) {
      setIsLoading(false);
      setErrorMessage('Please fill all required fields and try again.');
      return;
    }

    if (patientItem) {
      api.roundings
        .editBedPatient(bed?.id, patientItem?.value, startDate, endDate)
        .then((response: { success: boolean; bedAssignment: BedAssignment | null }) => {
          if (response.success) {
            setErrorMessage(null);
            setSuccessMessage('Patient updated');
            setIsLoading(false);
            setTimeout(() => {
              onSaveCallback(bed.id, response.bedAssignment);
              onCancelPress();
            }, 800);
          }
        })
        .catch(() => {
          setSuccessMessage(null);
          setErrorMessage('Error updating the patient. Please try again');
          setIsLoading(false);
        });
    }
  };

  return (
    <form action="">
      <Box>
        <Box sx={{ my: 3 }}>
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {bed?.currentBedAssignment?.patient ? 'Edit bed patient' : 'Add bed patient'}
            </Typography>
          </Grid>
        </Box>
        <Divider />
        {bed?.currentBedAssignment?.patient && (
          <Grid item style={{ marginTop: '20px' }}>
            <FormControl fullWidth>
              <FormLabel sx={{ marginBottom: 2 }}>Current assigned patient</FormLabel>
              <PatientCard
                showCloseButton={false}
                id={getFhirIdFromEntity(bed?.currentBedAssignment?.patient?.fhirRefUri)}
              />
            </FormControl>
          </Grid>
        )}
        {!bed.currentBedAssignment?.patient && (
        <Grid item style={{ marginTop: '20px', marginBottom: '30px' }}>
          <FormControl fullWidth>
            <FormLabel sx={{ marginBottom: 2 }}>
              {bed?.currentBedAssignment?.patient ? 'Change patient' : 'Assign patient *'}
            </FormLabel>
            <PatientSearchDropdown
              value={patientItem}
              onChange={(_: any, value: any | null) => {
                setPatientItem(value);
              }}
              label=''
            />
          </FormControl>
        </Grid>)}
        <Grid item style={{ marginTop: '20px' }}>
          <FormControl fullWidth>
            <FormLabel sx={{ marginBottom: 2 }}>Assignment period</FormLabel>
          </FormControl>
        </Grid>
        <Grid container direction="row">
          <Grid item xs={6} style={{ marginTop: '15px' }}>
            <InputLabel shrink>Start date *</InputLabel>
            <FormControl fullWidth style={{ paddingRight: '5px' }}>
              <TextField
                value={startDate}
                defaultValue={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                type="date"
                variant="standard"
                required
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} style={{ marginTop: '15px' }}>
            <InputLabel shrink>End date</InputLabel>
            <FormControl fullWidth>
              <TextField
                value={endDate}
                defaultValue={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                type="date"
                variant="standard"
              />
            </FormControl>
          </Grid>
        </Grid>
        {errorMessage && (
          <Alert style={{ marginTop: '20px' }} severity="error">
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert style={{ marginTop: '20px' }} severity="success">
            {successMessage}
          </Alert>
        )}
        <ActionButtons
          isLoading={isLoading}
          leftButtonTitle="Cancel"
          onLeftButtonPress={censusContext.closeAddBedModal}
          onRightButtonPress={onSavePress}
        />
      </Box>
    </form>
  );
};

export default AddBed;
