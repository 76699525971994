import { Card, Container, Stack, Tab, Tabs, Typography } from '@mui/material';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { Navigate, Route, Routes, useMatch, useNavigate } from 'react-router';

// tabs
import AutomationsIndex from './automations';
import ActivityTypesIndex from './activity-types';
import ActivitiesIndex from './activities';

export default function ActivityListAutomationIndex() {
  const { themeStretch } = useSettings();
  const match = useMatch('/settings/activity-list-automation/:tab/*');
  const currentTab = match?.params.tab || 'automations';
  const navigate = useNavigate();

  return (
    <Page title="Activity List Automation">
      <Container maxWidth={themeStretch ? undefined : 'xl'} sx={{ height: '100%' }}>
        <Stack spacing={3} direction="column" sx={{ height: '100%' }}>
          {/* TODO: Move to Breadcrumbs component */}
          <Stack direction="row" alignItems="baseline">
            <Typography variant="h4" sx={{ mr: 1.5 }}>
              Care Plan
            </Typography>
            <Typography variant="body2" sx={{ mx: 1 }}>
              Dashboard
            </Typography>
            <Typography sx={{ mx: 1 }}>&#8226;</Typography>
            <Typography variant="body2" sx={{ mx: 1 }}>
              Configuration Settings
            </Typography>
            <Typography sx={{ mx: 1 }}>&bull;</Typography>
            <Typography variant="caption" sx={{ mx: 1 }}>
              Activity List Automation
            </Typography>
          </Stack>

          <Card>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              sx={{ px: 2, bgcolor: 'background.neutral' }}
              value={currentTab}
              onChange={(_, tab) => navigate(tab)}
            >
              <Tab value={'activities'} label="Activities" />
              <Tab value={'activity-types'} label="Activity Types" />
              <Tab value={'automations'} label="Automations" />
            </Tabs>

            <Routes>
              <Route index element={<Navigate to="activities" />} />
              <Route path="activities/*" element={<ActivitiesIndex />} />
              <Route path="activity-types/*" element={<ActivityTypesIndex />} />
              <Route path="automations/*" element={<AutomationsIndex />} />
            </Routes>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}
