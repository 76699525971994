import { Typography, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import { Select } from 'src/components/Select';
import api from 'src/services/api';
import useCarePlanAutomationFormStore, {
  AutomationRule,
  useCarePlanAutomationFormError,
  useCarePlanAutomationFormRule,
} from '../../store';

interface OpportunitySelectorProps {
  question: WrappedQuestionnaireItem;
  rule: AutomationRule;
}

export default function OpportunitySelector({ question, rule }: OpportunitySelectorProps) {
  const saving = useCarePlanAutomationFormStore((s) => s.loading);
  const { enabled } = useCarePlanAutomationFormRule(rule.id);
  const { opportunity, setOpportunity } = useCarePlanAutomationFormRule(rule.id);
  const error = useCarePlanAutomationFormError(`rule(${question.linkId}:${rule.id})-opportunity`);

  const { data: mappings = [], isLoading } = useQuery(['care-plan-automation/opportunities'], () =>
    api.carePlan.findMappingsConditionOpportunity()
  );

  const opportunities = mappings.reduce(
    (acc, mapping) => {
      const { condition, opportunity } = mapping;
      const conditionId = `${condition.id}`;
      const group = acc.groups.find((g) => g.id === conditionId);
      const option = { value: `${mapping.id}`, label: opportunity.name };

      if (group) {
        group.options.push(option);
      } else {
        acc.groups.push({
          id: conditionId,
          label: condition.name,
          options: [option],
        });
      }

      acc.options.push(option);
      return acc;
    },
    {
      groups: [],
      options: [],
    } as {
      groups: {
        id: string;
        label: string;
        options: {
          label: string;
          value: string;
        }[];
      }[];
      options: {
        label: string;
        value: string;
      }[];
    }
  );

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
      <Typography sx={{ minWidth: 120, pb: error ? 2 : 0 }}>Opportunity:</Typography>
      <Select
        styles={{
          container: (provided) => ({
            ...provided,
            flex: 1,
          }),
        }}
        error={error}
        isDisabled={isLoading || !enabled || saving}
        isLoading={isLoading}
        placeholder="Select Opportunity"
        value={opportunities.options.find((o) => o.value === opportunity)}
        onChange={(option) => setOpportunity(option?.value || '')}
        options={opportunities.groups}
      />
    </Stack>
  );
}
