import {
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Button,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { ROUNDING_VIEW_TYPES } from "../../controller";

type FiltersPropsType = {
  onChangeintervalsStatus: (value: string | null) => void
  status: string | null | undefined;
  onChangeDatesCallbacks: {
    onStartDateChange: (value: string) => void;
    onEndDateChange: (value: string) => void;
  },
  dateRange: {
    start: string,
    end: string,
  } | undefined,
  onSearchButtonPress: () => void;
  onClearSearchPress: () => void;
  viewType: ROUNDING_VIEW_TYPES;
  isRoundingSelected: boolean;
  disabled: boolean;
};

export const Filters = ({
  onChangeintervalsStatus,
  status,
  onChangeDatesCallbacks,
  dateRange,
  onSearchButtonPress,
  onClearSearchPress,
  viewType,
  isRoundingSelected = false,
  disabled,
}: FiltersPropsType) => (
  <Grid item xl={12} sx={{ mt: 2}}>
    <Grid container spacing={4}>
      <Grid item xl={2}>
        <FormControl fullWidth style={{ paddingRight: '5px' }}>
        <Select
          onChange={(e) => onChangeintervalsStatus?.(e.target.value)}
          value={status}
        >
          <MenuItem value={'all'}><Typography>All</Typography></MenuItem>
          <MenuItem value="completed"><Typography>Completed</Typography></MenuItem>
          <MenuItem value="incomplete"><Typography>Incomplete</Typography></MenuItem>
        </Select>
      </FormControl>
      </Grid>
      <Grid item xl={2} style={{ marginTop: '-23px' }}>
        <InputLabel shrink>Start date</InputLabel>
        <FormControl fullWidth style={{ paddingRight: '5px' }}>
          <TextField
            value={dateRange?.start}
            defaultValue={dateRange?.start}
            onChange={(e) => onChangeDatesCallbacks.onStartDateChange?.(e.target.value)}
            type="date"
            error={dateRange?.start ? false : true}
          />
        </FormControl>
      </Grid>
      <Grid item xl={2} style={{ marginTop: '-23px' }}>
        <InputLabel shrink>End date</InputLabel>
        <FormControl fullWidth>
          <TextField
            value={dateRange?.end}
            defaultValue={dateRange?.end}
            onChange={(e) => onChangeDatesCallbacks.onEndDateChange?.(e.target.value)}
            type="date"
            error={dateRange?.end ? false : true}
          />
        </FormControl>
      </Grid>
      <Grid item xl={2} style={{ marginTop: '1px' }}>
        <FormControl fullWidth>
          <Button
            variant="contained"
            sx={{
              padding: '15px'
            }}
            onClick={viewType === ROUNDING_VIEW_TYPES.ROUNDING_LIST_VIEW ? onSearchButtonPress : onClearSearchPress}
            disabled={disabled || !dateRange?.start || !dateRange?.end}
          >
            <Typography>
              {viewType === ROUNDING_VIEW_TYPES.ROUNDING_LIST_VIEW && !isRoundingSelected ? 'Search' : 'Clear'}
            </Typography>
          </Button>
        </FormControl>
      </Grid>
    </Grid>
  </Grid>
)
