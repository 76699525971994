export interface TaskResponse {
  patientId: string;
  patientFullName: string;
  fhirId: string;
  taskUUID: string;
  ownerType: string;
  ownerFhirId: string;
  ownerName: string;
  ownerEmail: string;
  ownerPhone: string;
  status: string;
  taskType: string;
  createdAt: string;
  patientBirthDate: string;
  requesterFhirId: string;
  requesterName: string;
  requesterEmail: string;
}

export interface PaginatedTaskResponse {
  data: TaskResponse[];
  links: {
    current: string;
  };
  meta: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
}

export enum TaskStatusValue {
  Draft = 'draft',
  Requested = 'requested',
  Received = 'received',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Ready = 'ready',
  Cancelled = 'cancelled',
  InProgress = 'in-progress',
  OnHold = 'on-hold',
  Failed = 'failed',
  Completed = 'completed',
  EnteredInError = 'entered-in-error',
}
