import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Button, MenuItem } from '@mui/material';
// components
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useQuestionnaire } from 'src/@nicheaim/fhir-react';
import { Questionnaire } from 'src/@nicheaim/fhir-base/mappings/Questionnaire';
import { paramCase } from 'change-case';

// ----------------------------------------------------------------------

type FormValue = {
  title: string;
  status: Questionnaire['status'];
};

const schema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  status: Yup.string().required('Status is required'),
});

const resolver = yupResolver(schema);

export default function EditAssessmentForm() {
  const navigate = useNavigate();
  const { assessment = '' } = useParams();
  const [currentAssessment, { update }] = useQuestionnaire(assessment);
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () =>
      ({
        title: currentAssessment?.title || '',
        status: currentAssessment?.status || 'draft',
      } as FormValue),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAssessment?.id || '']
  );

  const methods = useForm({ resolver, defaultValues });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (currentAssessment) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAssessment?.id || '']);

  const onSubmit = async (data: FormValue) => {
    try {
      await update({
        ...currentAssessment,
        resourceType: 'Questionnaire',
        title: data.title,
        name: paramCase(data.title),
        status: data.status as Questionnaire['status'],
      });

      reset();
      enqueueSnackbar('Update success!');
      navigate('..');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 3 }}>
        <Stack
          gap={3}
          sx={{
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <RHFTextField name="title" label="Title" />
          <RHFSelect name="status" label="Status">
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="draft">Draft</MenuItem>
            <MenuItem value="retired">Retired</MenuItem>
          </RHFSelect>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }} gap={2}>
          <Button variant="text" color="inherit" component={RouterLink} to="..">
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Save changes
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
