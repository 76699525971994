export enum SectionEnum {
  all = 'all',
  diagnosis = 'diagnosis',
  medications = 'medications',
  vitals = 'vitals',
  labs = 'labs',
  notes = 'notes',
  services = 'services',
  carePlan = 'carePlan',
}

export const getTableHeaders = () => [
  {
    label: 'Patient',
    width: '210px'
  },
  {
    label: 'Bed',
    width: '170px'
  },
  {
    label: 'Status',
    width: '240px'
  },
  {
    label: 'Location',
    width: '230px'
  },
  {
    label: 'Sections',
    width: '260px'
  },
  {
    label: 'Links',
    width: '110px'
  },
  {
    label: 'Actions',
    width: '100px'
  },
];

export const checkSection = (name: SectionEnum, sections: SectionEnum[]): boolean => {
  if (sections?.find(i => i === name)) return true;
  return false;
};

export const sectionIcons: {
  icon: string;
  customStyle: object;
  section: SectionEnum;
}[] = [
  {
    icon: 'material-symbols:grid-view-outline-rounded',
    customStyle: {},
    section: SectionEnum.all,
  },
  {
    icon: "material-symbols:insert-chart-outline",
    customStyle: {},
    section: SectionEnum.diagnosis
  },
  {
    icon: "material-symbols:add-box-outline",
    customStyle: {},
    section: SectionEnum.medications
  },
  {
    icon:"material-symbols:chart-data-outline",
    customStyle: {},
    section: SectionEnum.vitals
  },
  {
    icon:"icon-park-outline:experiment-one",
    customStyle: {
      marginRight: 1,
      height: 22,
      width: 22,
      marginTop: '2px',
    },
    section: SectionEnum.labs
  },
  {
    icon:"mdi:file-document-plus-outline",
    customStyle: {},
    section: SectionEnum.notes
  },
  {
    icon:"carbon:portfolio",
    customStyle: {},
    section: SectionEnum.services
  },
];
