import has from 'lodash/has';

export interface PaginateQuery {
  page?: number;
  limit?: number;
  sortBy?: [string, string][];
  searchBy?: string[];
  search?: string;
  filter?: {
    [column: string]: string | string[];
  };
}

export const paginateQueryToURLParams = (paginateQuery: PaginateQuery): URLSearchParams => {
  const paginationObj: any = {};
  if (has(paginateQuery, 'page')) {
    paginationObj.page = paginateQuery.page;
  }
  if (has(paginateQuery, 'limit')) {
    paginationObj.limit = paginateQuery.limit;
  }
  if (has(paginateQuery, 'search')) {
    paginationObj.search = paginateQuery.search;
  }
  if (has(paginateQuery, 'sortBy')) {
    paginationObj.sortBy = paginateQuery.sortBy;
  }
  return new URLSearchParams(paginationObj);
};
