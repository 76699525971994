import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Button } from '@mui/material';
// components
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { useCodeSystem } from 'src/@nicheaim/fhir-react';
import { useDialogStore } from 'src/stores/dialog';
import uuidv4 from 'src/utils/uuidv4';
import produce from 'immer';
import { useExitDestination } from 'src/services/api/patients/programs';
import api from 'src/services/api';

// ----------------------------------------------------------------------

type FormValue = {
  name: string;
};

type ExitDestinationFormProps = {
  isEdit?: boolean;
  currentExitDestination?: string;
};

const schema = Yup.object().shape({
  name: Yup.string().required('Reason is required'),
});

const resolver = yupResolver(schema);

export default function NewExitDestinationForm({
  isEdit,
  currentExitDestination,
}: ExitDestinationFormProps) {
  const navigate = useNavigate();
  const confirm = useDialogStore((s) => s.confirmDialog);
  const { enqueueSnackbar } = useSnackbar();
  const { data: exitDestination, isLoading } = useExitDestination(currentExitDestination || '', {
    enabled: !!currentExitDestination,
  });

  const defaultValues = useMemo(
    () =>
      ({
        name: exitDestination?.valueDisplayName || '',
      } as FormValue),
    [exitDestination]
  );

  const methods = useForm({ resolver, defaultValues });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentExitDestination) {
      reset(defaultValues);
    } else if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, exitDestination]);

  const onSubmit = async (data: FormValue) => {
    if (isLoading && currentExitDestination) return;

    try {
      if (isEdit && currentExitDestination) {
        await api.patients.programs.updateProgramExitDestination(currentExitDestination, {
          valueDisplayName: data.name,
        });
      } else {
        await api.patients.programs.createProgramExitDestination({
          valueDisplayName: data.name,
          valueCode: uuidv4(),
        });
      }

      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      navigate(isEdit ? '../..' : '..');
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = async () => {
    if (!exitDestination) return;

    // ask for confirmation before deleting
    const confirmed = await confirm({
      title: 'Delete Exit Destination',
      description: 'Are you sure you want to delete this exit destination?',
    });

    if (confirmed) {
      try {
        await api.patients.programs.deleteProgramExitDestination(currentExitDestination || '');

        enqueueSnackbar('Delete success!');
        navigate('..');
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} debug>
      <Box sx={{ p: 3 }}>
        <Stack
          gap={3}
          sx={{
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <RHFTextField name="name" label="Exit Destination" />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }} gap={2}>
          {isEdit && (
            <Button variant="text" color="error" onClick={onDelete}>
              Delete
            </Button>
          )}
          <Button variant="text" color="inherit" component={RouterLink} to="..">
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {!isEdit ? 'Create Exit Destination' : 'Save Changes'}
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
