import { useNavigate, useParams } from 'react-router';
import AssessmentAnswersCard from './components/AssessmentAnswersCard';
import { IconButton } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
interface ViewAssessmentProps {
  onZoom?: () => void;
}

export default function ViewAssessment({ onZoom }: ViewAssessmentProps) {
  const navigate = useNavigate();
  const patient = useCurrentPatient();
  const { assessment: assessmentId = '' } = useParams();

  return (
    <AssessmentAnswersCard
      assessmentId={assessmentId}
      actionBar={
        <>
          <IconButton>
            <Iconify icon="eva:search-fill" />
          </IconButton>
          <IconButton onClick={() => onZoom?.()}>
            <Iconify icon="eva:expand-fill" />
          </IconButton>
          <IconButton
            onClick={() => navigate(`/dashboard/patient/${patient?.id}/care-record/assessments`)}
          >
            <Iconify icon="material-symbols:close-rounded" />
          </IconButton>
        </>
      }
    />
  );
}
