// @ts-nocheck
import moment from 'moment';
import {
  Divider,
  Box,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  TextField,
  Alert,
  Select,
  MenuItem,
} from '@mui/material';
import { useCodeSystem, useBinarys, useDocumentReferences } from 'src/@nicheaim/fhir-react';
import PatientCard from 'src/sections/careflow/common/PatientCard';
import { DocumentReference } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import useAuth from 'src/hooks/useAuth';
import { getFhirIdFromReferenceString } from '../../tasks-activities/components/Activities/activities-utils';
import useObjectState from 'src/hooks/useObjectState';
import { config } from 'src/config';
import ActionButtons from '../../common/ActionButtons';

type AddAttachmentProps = {
  attachment: any;
  onSaveCallback: (attachment: any) => void;
  onCancelPress: () => void;
  patient: {
    id: string;
  };
};

const AddAttachment = ({
  attachment,
  onSaveCallback,
  onCancelPress,
  patient,
}: AddAttachmentProps) => {
  const [, { create, update }] = useDocumentReferences({
    autofetch: false,
  });
  const [_, { create: createBinary }] = useBinarys({
    autofetch: false,
  });
  const user = useAuth().getCurrentUser();
  const [{ type, name, file, selectedFile, error, success, isLoading }, updateState] =
    useObjectState({
      type: attachment?.type?.coding?.[0].code ?? '',
      name: attachment?.content?.[0]?.attachment?.title ?? '',
      file: null,
      selectedFile: null,
      error: null,
      success: null,
      isLoading: false,
    });
  const [types] = useCodeSystem('ccm-attachment-types');

  const isAValidForm = () => {
    let validation = true;

    if (!attachment?.id && !file) {
      validation = false;
    }
    if (!type || !name) {
      validation = false;
    }

    return validation;
  };

  const onSavePress = async () => {
    updateState({
      isLoading: true,
    });

    if (!isAValidForm()) {
      updateState({
        error: 'Please fill all fields',
        success: null,
        isLoading: false,
      });
      return;
    }
    if (attachment?.id && !selectedFile) {
      await createAttachment();
    } else {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64 = reader.result?.toString()?.replace(/^data:(.*,)?/, '');
        await createAttachment(base64);
      };
      reader.onerror = () => {
        updateState({
          error: 'Error reading the selected file',
          isLoading: false,
          success: null,
        });
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const createAttachment = async (base64: any = null) => {
    let newBinary = null;
    if (!attachment?.id) {
      newBinary = await createBinary({
        resourceType: 'Binary',
        data: base64,
        contentType: `application/${selectedFile?.name.split('.').pop()}`// selectedFile?.type,
      })
    }

    if (!newBinary) return;

    const payload: any = {
      id: attachment?.id ?? undefined,
      resourceType: 'DocumentReference',
      status: 'current',
      type: {
        coding: [
          {
            code: type,
            display: types?.concept?.find((t) => t.code === type)?.display,
          },
        ],
      },
      subject: {
        reference: `Patient/${patient?.id}`,
        type: 'Patient',
      },
      author: [
        {
          reference: `Practitioner/${getFhirIdFromReferenceString(user.user_fhir_uri)}`,
          type: 'Practitioner',
          display: user.email,
        },
      ],
      date: moment().format('YYYY-MM-DDTHH:MM:ssZ'),
      content: [
        selectedFile
          ? {
              attachment: {
                // contentType: selectedFile?.type,
                // size: selectedFile?.size,
                title: name,
                url: `${config.fhirServerUrl}/Binary/${newBinary?.[0]?.id}`
                // data: base64,
              },
            }
          : {
              attachment: {
                // contentType: attachment?.content?.[0]?.attachment?.contentType,
                // size: attachment?.content?.[0]?.attachment?.size,
                title: name,
                url: attachment?.content?.[0]?.attachment?.url,
              },
            },
      ],
    };

    if (attachment?.id) {
      update(payload)
        .then(() => {
          updateState({
            error: null,
            success: 'Attachment successfully updated',
            isLoading: false,
          });
          onSaveCallback({
            ...payload,
          });
        })
        .catch((error) =>
          updateState({
            error: error.message,
            success: null,
            isLoading: false,
          })
        );
    } else {
      create(payload)
        .then((newAttachment: DocumentReference[]) => {
          updateState({
            error: null,
            success: 'Attachment successfully created',
            isLoading: false,
          });
          setTimeout(() => {
            onSaveCallback(newAttachment);
          }, 1000);
        })
        .catch((error) =>
          updateState({
            error: error.message,
            success: null,
            isLoading: false,
          })
        );
    }
  };

  return (
    <form action="">
      <Box>
        <Box sx={{ my: 3 }}>
          <Grid item>
            <PatientCard id={patient?.id} />
          </Grid>
        </Box>
        <Box sx={{ my: 3 }}>
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {attachment?.id ? 'Edit attachment details' : 'Add attachment to patient'}
            </Typography>
          </Grid>
        </Box>
        <Divider />
        <Grid item style={{ marginTop: '15px' }}>
          <InputLabel shrink>Type *</InputLabel>
          <FormControl fullWidth>
            <Select
              labelId="category-label"
              value={type}
              onChange={(e) => updateState({ type: e.target.value })}
              variant="standard"
              required
            >
              {types?.concept?.map((code) => (
                <MenuItem key={code.code} value={code.code}>
                  {code.display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item style={{ marginTop: '15px' }}>
          <InputLabel shrink>Name / Description*</InputLabel>
          <FormControl fullWidth>
            <TextField
              value={name}
              defaultValue={name}
              onChange={(e) => updateState({ name: e.target.value })}
              variant="standard"
              required
            />
          </FormControl>
        </Grid>
        {!attachment?.id && (
          <Grid item style={{ marginTop: '15px' }}>
            <InputLabel shrink>File *</InputLabel>
            <FormControl fullWidth>
              <TextField
                value={file}
                defaultValue={file}
                onChange={(e) =>
                  updateState({ file: e.target.value as any, selectedFile: (e.target as any)?.files[0] })
                }
                variant="standard"
                required
                type="file"
              />
            </FormControl>
          </Grid>
        )}
        {error && (
          <Alert style={{ marginTop: '20px' }} severity="error">
            {error}
          </Alert>
        )}
        {success && (
          <Alert style={{ marginTop: '20px' }} severity="success">
            {success}
          </Alert>
        )}
        <ActionButtons
          isLoading={isLoading}
          leftButtonTitle='Cancel'
          onLeftButtonPress={onCancelPress}
          rightButtonTitle='Save'
          onRightButtonPress={onSavePress}
        />
      </Box>
    </form>
  );
};

export default AddAttachment;
