// @ts-nocheck
import { useEffect, useMemo, useState } from 'react';
import produce from 'immer';
import { Box, Card, Grid, Typography, Modal } from '@mui/material';
import { useCareTeams } from 'src/@nicheaim/fhir-react';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import { CareTeamParticipant } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { useEventBus } from 'src/event-bus';
import { ActionBar } from 'src/sections/careflow/common';
import CareTeamForm from './CareTeamForm';
import CareTeamFormEdit from './CareTeamFormEdit';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import WidgetUser, { WidgetUserTypeEnum } from 'src/sections/careflow/common/WidgetUser';

interface CareTeamRowProps {
  onDelete: (row: any) => void;
  onEdit: (row: any) => void;
  participant: CareTeamParticipant;
}

const CareTeamRow = (props: CareTeamRowProps) => {
  const resourceType = props.participant?.member?.reference?.split('/')?.[0];
  if (resourceType === 'Practitioner')
    return <WidgetUser {...props} type={WidgetUserTypeEnum.PRACTITIONER} />;
  if (resourceType === 'RelatedPerson')
    return <WidgetUser {...props} type={WidgetUserTypeEnum.RELATED_PERSON} />;
  return null;
};

const CareTeam = () => {
  const patient = useCurrentPatient();
  const { onEvent, removeEvent } = useEventBus();
  const [selectedPractitioner, setSelectedPractitioner] = useState<CareTeamParticipant | null>(
    null
  );
  const [showEditParticipantModal, setShowEditParticipantModal] = useState<boolean>(false);
  const [careTeams, { update, refresh }] = useCareTeams({
    filter: {
      subject: `${patient?.id}`,
    },
  });
  const participants = useMemo(
    () =>
      careTeams?.[0]?.participant?.filter((p) =>
        p?.role?.some((r) => r?.coding?.[0]?.system === 'careTeamRole')
      ) || [],
    [careTeams]
  );

  const [participantsDisplayed, setParticipantsDisplayed] = useState(participants);

  useEffect(() => {
    onEvent('UPDATE_CARETEAM_SUMMARY', refresh);
    return () => removeEvent('UPDATE_CARETEAM_SUMMARY', refresh);
  }, []);

  useEffect(() => {
    setParticipantsDisplayed(participants);
  }, [participants]);

  const handleAdd = () => {
    setSelectedPractitioner(null);
    setShowEditParticipantModal(true);
  };

  const handleEdit = (data: CareTeamParticipant) => {
    setSelectedPractitioner(data);
    setShowEditParticipantModal(true);
  };

  const handleDelete = (data: CareTeamParticipant) => {
    const careTeamUpdated = produce(careTeams[0], (draft) => {
      draft.participant = participants.map((p) => {
        if (
          getFhirIdFromEntity(p.member?.reference ?? '') ===
          getFhirIdFromEntity(data.member?.reference ?? '')
        ) {
          return {
            ...p,
            role: p?.role?.filter((r) => !r.coding?.find((c) => c.system === 'careTeamRole')),
          };
        }

        return p;
      });
    });
    update(careTeamUpdated);
  };

  const onUpdateParticipantCallback = (newCareTeam: CareTeamParticipant) => {
    setParticipantsDisplayed((prevParticipants) => {
      const newData = prevParticipants.map((participant: CareTeamParticipant) => {
        if (
          getFhirIdFromEntity(participant.member?.reference ?? '') ===
          getFhirIdFromEntity(newCareTeam.member?.reference ?? '')
        ) {
          return newCareTeam;
        }

        return participant;
      });

      return newData;
    });
  };

  return (
    <Card>
      <Modal open={showEditParticipantModal} onClose={() => setShowEditParticipantModal(false)}>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: 0,
            width: 400,
            height: '100%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflowY: 'scroll',
          }}
        >
          {selectedPractitioner && (
            <CareTeamFormEdit
              onSaveCallback={onUpdateParticipantCallback}
              data={selectedPractitioner}
              closeForm={() => setShowEditParticipantModal(false)}
            />
          )}

          {!selectedPractitioner && (
            <CareTeamForm closeForm={() => setShowEditParticipantModal(false)} />
          )}
        </Box>
      </Modal>
      <Grid container>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 2 }}
          xs={12}
        >
          <Grid item>
            <Typography variant="h6">Care Team</Typography>
          </Grid>
          <Grid item>{<ActionBar onAdd={handleAdd} />}</Grid>
        </Grid>
        <Grid item p={2} pt={0} xs={12}>
          <Box sx={{ height: '400px', overflow: 'auto' }}>
            {participantsDisplayed?.map((p: CareTeamParticipant, index: number) => (
              <CareTeamRow
                key={`person-${index}`}
                participant={p}
                onDelete={handleDelete}
                onEdit={handleEdit}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CareTeam;
