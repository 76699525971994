import { useValueSet } from 'src/@nicheaim/fhir-react';
import { Controller, useFormContext } from 'react-hook-form';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { RHFTextField, RHFSelect } from '../../../../../components/hook-form';
import { Box, Button, Checkbox, DialogActions, Grid, Stack, Typography } from '@mui/material';
import { MenuItem } from '@mui/material';

type Props = {
  onCancel: VoidFunction;
  nextStep: VoidFunction;
};

export default function OutboundReferralDetails({ nextStep, onCancel }: Props) {
  const { control } = useFormContext();

  const [serviceRequestTypeCodeList] = useValueSet('crs-service-request-type', {
    map: ValueSetWrapper,
  });

  return (
    <>
      <Grid container sx={{ p: 2 }}>
        <Grid item md={6}>
          <Stack sx={{ p: 1 }} alignItems="center">
            <RHFSelect name="code" label="Service Request" fullWidth={true}>
              <MenuItem disabled value="" />
              {serviceRequestTypeCodeList?.asList().map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.display}
                </MenuItem>
              ))}
            </RHFSelect>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 1 }}
            >
              <Typography variant="body2">Urgent?</Typography>

              <Controller
                name="priority"
                control={control}
                defaultValue={false}
                render={({ field }) => <Checkbox {...field} checked={field.value} />}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={6}>
          <Stack sx={{ p: 1 }}>
            <RHFTextField name="reasonCode" label="Description" multiline rows={4} />
          </Stack>
        </Grid>
      </Grid>
      <Stack spacing={2} alignItems="center">
        <DialogActions>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="outlined" color="info" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="outlined" color="info" onClick={nextStep}>
            Next
          </Button>
        </DialogActions>
      </Stack>
    </>
  );
}
