import BedToRounding from 'src/sections/careflow/rounding/Model/BedToRounding';
import client from '../_client';
import moment from 'moment';
import Catalog from 'src/sections/careflow/rounding/Model/Catalog';
import Interval from 'src/sections/careflow/rounding/Model/Interval';
import IntervalDetail from 'src/sections/careflow/rounding/Model/IntervalDetail';
import Rounding from 'src/sections/careflow/rounding/Model/Rounding';
import BedAssignment from 'src/sections/careflow/rounding/Model/BedAssignment';

type RoundingPagination = {
  take: number;
  skip: number;
};

type getIntervalsPropsType = {
  intervalId?: number | null;
  status?: string | null;
  dateRange?:
    | {
        start: string;
        end: string;
      }
    | undefined;
  pagination?: RoundingPagination;
};

export type getRoundingPropsType = { pagination?: RoundingPagination, locationFhirId?: string | null }

export async function getRoundings({pagination, locationFhirId }: getRoundingPropsType) {
  const { data } = await client.get<Rounding[]>(
    `/ccm/rounding/list?take=${pagination?.take}&skip=${pagination?.skip}&locationFhirId=${locationFhirId}`
  );

  return data;
}

export async function getRoundingTypes() {
  const { data } = await client.get<Catalog[]>(`/ccm/rounding/rounding-types`);

  return data;
}

export async function getRoundingPatientActivities() {
  const { data } = await client.get<Catalog[]>(`/ccm/rounding/patient-activities`);

  return data;
}

export async function getRoundingPatientActivityLocations() {
  const { data } = await client.get<Catalog[]>(`/ccm/rounding/patient-activity-locations`);

  return data;
}

export async function getBedsToRounding(roundingId: number) {
  const { data } = await client.get<BedToRounding[]>(`/ccm/rounding/beds-to-rounding/${roundingId}`);

  return data;
}

type CompleteIntervalDetailDataType = {
  patientActivityId: number;
  patientLocationId: number;
};

export async function completeIntervalDetail(
  id: number,
  { patientActivityId, patientLocationId }: CompleteIntervalDetailDataType
) {
  const { data } = await client.post<IntervalDetail>(`/ccm/rounding/complete-interval-detail/${id}`, {
    patientActivityId,
    patientLocationId,
  });

  return data;
}

export async function getIntervals({
  intervalId,
  status,
  dateRange,
  pagination,
}: getIntervalsPropsType) {
  let url = `/ccm/rounding/intervals/list?take=${pagination?.take}&skip=${pagination?.skip}`;

  if (status) {
    url = `${url}&status=${status}`;
  }

  if (intervalId) {
    url = `${url}&intervalId=${intervalId}`;
  }

  if (dateRange) {
    const start = moment(dateRange.start).utc().startOf('day').toISOString();
    const end = moment(dateRange.end).utc().endOf('day').toISOString();
    url = `${url}&start=${start}&end=${end}`;
  }

  const { data } = await client.get<Interval[]>(url);

  return data;
}

export async function getIntervalDetails({
  intervalId,
  pagination,
}: {
  intervalId: number;
  pagination: RoundingPagination;
}) {
  let url = `/ccm/rounding/intervals/${intervalId}/details?take=${pagination?.take}&skip=${pagination?.skip}`;

  const { data } = await client.get<{ intervalDetails: IntervalDetail[]; totalCount: number }>(url);

  return data;
}

export async function editBedPatient(
  bedId: number,
  patientId: number,
  start: string,
  end: string | null
) {
  const url = `ccm/rounding/update-bed-assignment`;
  const { data } = await client.post<BedAssignment>(url, {
    bedId,
    patientId,
    start,
    end,
  });

  return data;
}

export async function saveRounding(params: {
  id: number | undefined;
  categoryId: number;
  startInterval: string;
  interval: number;
  beds: number[];
  sections: string;
  locationFhirId: string;
}) {
  const { data } = await client.post<Rounding>(`ccm/rounding/save/${params.id}`, {
    ...params 
  });

  return data;
}
