import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  Tab,
} from "@mui/material"
import {
  useBinarys,
  useDocumentReferences,
  useHealthcareServices,
  useOrganizations,
  useValueSet
} from "src/@nicheaim/fhir-react";
import TableLink from "./TableLink";
import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import { isEmpty, isEqual } from "lodash";
import getFileData from "src/utils/getFileData";
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { useEffect, useMemo, useState } from "react";
import { UploadFiles } from "src/sections/crs/common";
import { FormProvider } from 'src/components/hook-form';
import { SubmitHandler, useForm } from "react-hook-form";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  DocumentReferenceWrapper,
} from "src/@nicheaim/fhir-base/wrappers/DocumentReference";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { ValueSetWrapper } from "src/@nicheaim/fhir-base/wrappers/ValueSet";
import { convertValueToValueSet } from "src/sections/crs/common/common-utils";
import { DocumentReference } from "src/@nicheaim/fhir-base/mappings/DocumentReference";
import { WrappedServiceRequest } from "src/@nicheaim/fhir-base/wrappers/ServiceRequest";
import { HealthcareServiceWrapper } from "src/@nicheaim/fhir-base/wrappers/HealthcareService";
import { CodeableConcept } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";

const HOST_API = process.env.REACT_APP_HOST_API;
const FHIR_API = process.env.REACT_APP_FHIR_API_BASE_URL;

interface ProgramChecked {
  programId: string; 
  checked: boolean; 
};

interface DocumentProgram {
  programChecked: ProgramChecked[];
  documentReference: DocumentReference;
};

type FormValues = {
  documentPrograms: DocumentProgram[];
  files: Blob[];
  documents: string[];
  documentsPHIN: DocumentReference[];
};

type Props = {
  patient: WrappedPatient | null;
  serviceRequest: WrappedServiceRequest | null;
  option?: string;
  open: boolean;
  onCancel: VoidFunction;
  workflowReferral?: boolean;
  handleNotes?: any;
  handleUpdateResource: (data: any) => Promise<any>;
};

export default function MissingNotes({
  patient,
  serviceRequest,
  option,
  open,
  onCancel,
  workflowReferral,
  handleNotes,
  handleUpdateResource,
}: Props) {

  const [valueTab, setValueTab] = useState('1');

  const { enqueueSnackbar } = useSnackbar();

  const authUser = useAuth().getCurrentUser();

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [organization,] = useOrganizations();

  const [, { create: createBinary }] = useBinarys({ autofetch: false });

  const [documentReference, {
    refresh: refreshDocumentReferences,
    create: createDocumentReference,
    update: updateDocumentReference,
  }]
    = useDocumentReferences({ filter: { subject: patient?.id }, map: DocumentReferenceWrapper });

  const [healhcareServices] = useHealthcareServices({
    filter: { 
      _id: serviceRequest?.getHealthcareServiceIds()?.join(',') ?? null
     },
    map: HealthcareServiceWrapper,
    autofetch: !!serviceRequest?.getHealthcareServiceIds()?.join(',')
  });

  const [categoryForm] = useValueSet('crs-workflow-step-form', { map: ValueSetWrapper });
  const categoryCoding = convertValueToValueSet(option || '', categoryForm);

  const linkDocumentsDefault = useMemo(
    () => documentReference?.filter(r => r.context?.related?.
      find(e => e.reference === `ServiceRequest/${serviceRequest?.id}`)).map((d) => d.id ?? ''),
    [documentReference]
  );

  const programs = useMemo(() => healhcareServices.map((e) => ({
    programId: e?.id,
    code: e?.program?.[0].coding?.[0]
  })
  ), [healhcareServices]);

  const documentsFiltered = useMemo(() => {
    const filteredDocuments = documentReference?.filter(a => {
      const hasValidEncounter = a.context?.related?.
        some(e => healhcareServices.some(r => `HealthcareService/${r.id}` === e.reference));
      const hasMatchingCategory = a.category?.some(p => p.text === option);
      return hasValidEncounter && hasMatchingCategory;
    });
  
    const simplifiedDocuments = filteredDocuments?.map(c => ({
      documentReference: c,
      programChecked: programs?.map(r => {
        const hasMatchingEncounter = c.context?.related?.
          some(s => s.reference === `HealthcareService/${r.programId}`);
        return hasMatchingEncounter && {programId : r?.programId, checked: true };
      }),
    }));
  
    return simplifiedDocuments;
  }, [documentReference]);

  useEffect(() => {
    //@ts-ignore
    setValue('documentPrograms', documentsFiltered)
    setValue('documents', linkDocumentsDefault)
  }, [open])

  const methods = useForm<FormValues>();

  const {
    handleSubmit,
    setValue,
    reset
  } = methods;

  const handleCreateBynary = async (data: any) => {
    const result = await createBinary(data);
    return result;
  };

  const handleCreateDocumentReference = async (data: any) => {
    const result = await createDocumentReference(data);
    return result;
  };

  const handleUpdateDocumentReference = async (data: any) => {
    const result = await updateDocumentReference(data);
    return result;
  };

  const convertBlobToBase64 = (file: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result?.toString().split(',')[1]);
      };
      reader.readAsDataURL(file);
    });

  const createBinaryCustom = async (data: FormValues): Promise<any> => {
    if (!data?.files?.length) return null;

    const resourceType = 'Binary';

    const mappedFile = await Promise.all(
      data.files.map(async (file: Blob) => {
        const contentType = file.type;
        const data = await convertBlobToBase64(file);
        const securityContext = { reference: `${patient?.resourceType}/${patient?.id}` };
        const name = getFileData(Object.assign(file)).name;
        const obj = { resourceType, contentType, securityContext, data };
        const binary = await handleCreateBynary(obj);

        const objNew = { ...binary[0], name: name };
        return objNew;
      })
    );

    return mappedFile;
  };

  const mapFormDataToDocumentReference = (binaryList: any) => {

    const organizationFhir = organization?.
      filter(e => e.id === authUser.organization_fhir_uri).
      map((r) => ({
        display: r.name,
        reference: `Organization/${r.id}`
      }))[0];

    const documentReference = binaryList?.map((binary: any) => ({
      resourceType: 'DocumentReference',
      status: 'current',
      subject: { reference: `${patient?.resourceType}/${patient?.id}` },
      author: [{
        ...(!isEmpty(authUser.user_fhir_uri.trim()) && {
          reference: `Practitioner/${getFhirIdFromEntity(authUser.user_fhir_uri)}`
        }),
        display: authUser.name
      }],
      ...(organizationFhir && {
        custodian: organizationFhir
      }),
      date: new Date().toISOString(),
      content: [
        {
          attachment: {
            contentType: `${binary.contentType}`,
            creation: new Date().toISOString(),
            title: binary.name,
            url: `${FHIR_API}/Binary/${binary.id}`,
          },
        },
      ],
      context: { related: [{ reference: `${serviceRequest?.resourceType}/${serviceRequest?.id}` }] }
    }));

    return documentReference;
  };

  const createDocumentReferencePHIN = async (data: DocumentReference[]): Promise<any> => {
    if (data === undefined) return null;

    try {
      const document = data.map((doc) => {
        const documentUniqueId = doc?.identifier?.find(
          (i) => i?.type?.text === 'documentUniqueId'
        )?.value;
        const repositoryUniqueId = doc?.identifier?.find(
          (i) => i?.type?.text === 'repositoryUniqueId'
        )?.value;
        const hcid = doc?.identifier?.find((i) => i?.type?.text === 'hcid')?.value;

        doc.content[0].attachment.url = `${HOST_API}/crs/document/${documentUniqueId}?repositoryId=${repositoryUniqueId}&hcid=${hcid}`;
        doc.subject = { reference: `${patient?.resourceType}/${patient?.id}` };
        doc.category = [{
          ...(categoryCoding && { coding: [categoryCoding] }),
          text: categoryCoding?.display
        }];

        const context = doc.context;
        doc.context = { ...context, related: [{ reference: `${serviceRequest?.resourceType}/${serviceRequest?.id}` }] };

        if (doc.context?.period?.end === null) delete doc.context?.period?.end;
        delete doc.content[0].attachment.data;
        delete doc.id;

        const { ...result } = doc;
        return result;
      });

      const documentReference = await handleCreateDocumentReference(document);

      return documentReference;
    } catch (e) {
      enqueueSnackbar('An error has ocurred.', { variant: 'error', autoHideDuration: 6000 });
    }
  };

  const updateDocumentsSelected = async (
    data: FormValues, 
    createdDocs: DocumentReference[] | undefined, 
    createdPhinDocs: DocumentReference[] | null, 
    resourceToRelate: any
  ): Promise<any> => {

    const setResourceRelated = Array.isArray(resourceToRelate) ? 
      `${resourceToRelate?.[0]?.resourceType}/${resourceToRelate?.[0]?.id}` :
      `${resourceToRelate?.resourceType}/${resourceToRelate?.id}`;

    const { documents } = data;

    const delRelatedResource = linkDocumentsDefault?.filter((e:any) => !documents?.some((s:any) => s === e));

    const documentsNotRelated = documentReference?.filter(p => delRelatedResource?.
      some((f:any) => p.id === f)).
        map((l) =>{

          const related = l.context?.related || [];  

          const notRelated = related?.
            filter(r => r.reference !== setResourceRelated) || [];
          
          if(l.context?.related && !isEmpty(notRelated)){ 
            l.context.related = [ ...notRelated ];
          }else{
            delete l.context?.related;
          }

          if(isEmpty(l.context)){
            delete l.context;
          }

          const {...result} = l;
          return result;
    })|| [];

    const documentsSelected = documents?.filter((e:any) => !linkDocumentsDefault?.some((s:any) => s == e));

    const documentReferenceSelected = documentReference?.filter(d => documentsSelected?.
      some((r: any) => d.id === r)).
        map((e) => {
          const context = e.context;
          const related = e.context?.related || [];  

          const resourceRelated = related.
            find(r => r.reference === setResourceRelated);

          if(isEmpty(resourceRelated)){
            related?.push({reference: setResourceRelated});
          }

          if(isEmpty(resourceRelated)){
            e.context = { 
              ...context, 
              related: [ 
                ...related
            ]};
          }else {
            e.context = {...context, related: [ ...related ]};
          }

          const {...result} = e;
          return result;
    }) || [];

    const createdDocuments = createdDocs?.map((e) => {
      const context = e.context;
      const related = e.context?.related || [];

      const resourceRelated = related.find(r => r.reference === setResourceRelated);
      
      if(isEmpty(resourceRelated)){
        e.context = {...context, related: [ ...related, { reference: setResourceRelated}]};
      }else {
        e.context = {...context, related: [ ...related ]};
      }
      const {...result} = e;
      return result;
    }) || [];

    const phinDocuments = createdPhinDocs?.map((e) => {
      const context = e.context;
      const related = e.context?.related || [];

      const resourceRelated = related.find(r => r.reference === setResourceRelated);
      
      if(isEmpty(resourceRelated)){
        e.context = {...context, related: [ ...related, { reference: setResourceRelated}]};
      }else {
        e.context = {...context, related: [ ...related ]};
      }
      const {...result} = e;
      return result;
    }) || [];

    const allDocuments = [ ...documentsNotRelated, ...documentReferenceSelected, ...createdDocuments, ...phinDocuments];

    const updateDocumentReference = await handleUpdateDocumentReference(allDocuments);

    if(resourceToRelate?.resourceType === 'ServiceRequest'){
      const relateDocuments = [ ...documentReferenceSelected, ...createdDocuments, ...phinDocuments];
      const getDocuments = mapDocumentReferenceToResource(resourceToRelate, relateDocuments, documentsNotRelated);
      if(getDocuments.length > 0){
        resourceToRelate.supportingInfo = getDocuments;
      }else{
        delete resourceToRelate.supportingInfo;
      }
      handleUpdateResource(resourceToRelate);
    }
    
    return allDocuments;
  };

  const mapProgramsToDocumentReference = (data: FormValues) => {
    const { documentPrograms } = data;

    const categoryMapLinkNotes: CodeableConcept[] = [{
      coding: [categoryCoding || {}],
      text: categoryCoding?.display
    }];

    const mapProgramToDocument = documentPrograms?.map((x1) => {

      const { programChecked, documentReference} = x1;

       programChecked?.map((x2) => {
        if(x2?.checked){
          const reference = {reference: `HealthcareService/${x2?.programId}`};
          const exist = (documentReference?.context?.related ?? []).filter((x3) => x3?.reference === reference.reference) ?? [];
          if(isEmpty(exist)){
            documentReference?.context?.related?.push(reference);
          }
        }else{
          if(documentReference?.context?.related !== undefined){
            documentReference.context.related = 
              (documentReference?.context?.related ?? []).filter((x3) => x3?.reference !== `HealthcareService/${x2?.programId}`);
          }
        }
      });

      const existCategory = documentReference?.category?.filter((x4) =>
        x4?.coding?.some((x5) => x5.code === categoryCoding?.code)
      );

      if(!existCategory || existCategory?.length === 0){
        documentReference.category = categoryMapLinkNotes;
      }

      return documentReference;
    })
    return mapProgramToDocument;
  };

  const updateDocuments = ( data: FormValues ) => {
    const { documents, files, documentsPHIN } = data;

    const arraysDocsEqual = isEqual(documents, linkDocumentsDefault);

    if((linkDocumentsDefault && 
      (documents.length !== linkDocumentsDefault.length)) || 
      !arraysDocsEqual ||
      files?.length > 0 ||
      documentsPHIN?.length > 0
    ){
      return true;
    }
    return false;
  };

  const mapDocumentReferenceToResource = (
    resource: any, 
    documentReference: DocumentReference[], 
    notRelate: DocumentReference[]
  ) => {
    const document = documentReference?.map((doc) => (`${doc?.resourceType}/${doc?.id}`));
    const notRelateMap = notRelate?.map((nDoc) => (`${nDoc?.resourceType}/${nDoc?.id}`));
    const supportingInfo = resource?.supportingInfo?.map((sd) => (`${sd?.reference}`)) ?? [];
    const setDocument = [...new Set([...supportingInfo, ...document])];
    const allDocuments = setDocument?.
      filter((x1) => !notRelateMap.
        some((x2) => x1 === x2)).map((x3) => ({reference: x3 })); 
    return allDocuments;
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {

    try {
      setOpenBackdrop(true);

      let documentReferences;
      let documentPHIN;

      if (data?.files?.length) {
        const resultBinary = await createBinaryCustom(data);
        const documentReference = mapFormDataToDocumentReference(resultBinary);
        documentReferences = await handleCreateDocumentReference(documentReference);
        if (data?.files?.length > 0) reset({ files: [] });
        enqueueSnackbar('Manual Upload documents have been saved.');
        refreshDocumentReferences();
      }

      if (data?.documentsPHIN?.length) {
        documentPHIN = await createDocumentReferencePHIN(data.documentsPHIN);
        if (data?.documentsPHIN?.length > 0) reset({ documentsPHIN: [] });
        enqueueSnackbar('Documents from PHIN have been saved.');
        refreshDocumentReferences();
      }

      const update = updateDocuments(data);

      if(update){
        const updateSelectedDocuments = await updateDocumentsSelected(data, documentReferences, documentPHIN, serviceRequest);
      }

      const resultMap = mapProgramsToDocumentReference(data);
      
      if (resultMap.length > 0 && valueTab === '1') {
        await handleUpdateDocumentReference(resultMap);
        refreshDocumentReferences();
        if (workflowReferral) handleNotes();
        enqueueSnackbar(`${option} have been saved`);
        onCancel();
      }

      setValueTab('1');
      setOpenBackdrop(false);

    } catch (e) {
      setOpenBackdrop(false);
      enqueueSnackbar('An error has occurred.', { variant: 'error' });
      onCancel();
    }
  };

  return (
    <Dialog open={open} onClose={onCancel} fullWidth={true} maxWidth="md">
      <DialogTitle>{option}</DialogTitle>
      <TabContext value={valueTab}>
        <Box sx={{ px: 3 }}>
          <TabList onChange={(e, value) => setValueTab(value)}
          >
            <Tab disableRipple value="1" label="Link" />
            <Tab
              disableRipple
              value="2"
              label="Upload"
              sx={{ '& .MuiTab-wrapper': { whiteSpace: 'nowrap' } }}
            />
          </TabList>
        </Box>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <TabPanel value="1" sx={{ p: 2 }}>
            <TableLink
              documents={documentReference}
              serviceRequest={serviceRequest}
              programs={programs}
              patientId={patient?.getMRN()?.value}
            />
          </TabPanel>
          <TabPanel value="2" sx={{ p: 2 }}>
            <Stack spacing={2} sx={{ mt: 2 }} flexDirection='column'>
              <UploadFiles
                label='referral'
                restrictFiles={false}
                documentReferences={documentReference}
                patient={patient}
                docRelated={linkDocumentsDefault}
              />
              <Stack alignItems="center">
                <DialogActions>
                  <Button variant="outlined" color="info" type="submit">
                    Save
                  </Button>
                </DialogActions>
              </Stack>
            </Stack>
          </TabPanel>
        </FormProvider>
      </TabContext>
    </Dialog>
  )
} 