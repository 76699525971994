// @ts-nocheck
import useObjectState from "src/hooks/useObjectState";
import Rounding from "./Model/Rounding";
import API from 'src/services/api';
import { useCallback, useEffect } from "react";
import IntervalDetail from "./Model/IntervalDetail";
import Interval from "./Model/Interval";
import moment from "moment";
import { useRoundingPatientActivities, useRoundingPatientLocations } from "src/services/api/rounding/index";

export enum ROUNDING_VIEW_TYPES {
  ROUNDING_LIST_VIEW,
  SEARCH_RESULT_VIEW
}

const DEFAULT_PAGINATION_TAKE: number = 10;

export type PaginationType = {
  take: number;
  page: number;
  total?: number;
};

type RoundingControllerState = {
  openAddRoundingModal: boolean;
  isLoading?: boolean | null;
  categoryId: number | null,
  locationFhirId?: string | null;
  selectedRounding?: Rounding | null,
  roundings?: Rounding[];
  intervals?: Interval[];
  intervalDetailsTotalCount?: number;
  intervalsStatus?: string | null;
  dateRange?: {
    start: string;
    end: string;
  };
  viewType?: ROUNDING_VIEW_TYPES;
  pagination: PaginationType;
  loadingIntervalDetails: boolean;
}

export const useController = () => {
  const [{
    openAddRoundingModal,
    isLoading,
    categoryId,
    locationFhirId,
    selectedRounding,
    roundings,
    intervals,
    intervalDetailsTotalCount,
    intervalsStatus,
    dateRange,
    viewType,
    pagination,
    loadingIntervalDetails,
  }, updateState] = useObjectState<RoundingControllerState>({
    openAddRoundingModal: false,
    isLoading: true,
    categoryId: null,
    locationFhirId: null,
    selectedRounding: null,
    roundings: [],
    intervalDetailsTotalCount: 0,
    intervalsStatus: 'all',
    dateRange: {
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    },
    viewType: ROUNDING_VIEW_TYPES.ROUNDING_LIST_VIEW,
    pagination: {
      take: DEFAULT_PAGINATION_TAKE,
      page: 0,
      total: 0,
    },
    loadingIntervalDetails: false,
  });
  const { data: patientActivities } = useRoundingPatientActivities();
  const { data: patientLocations } = useRoundingPatientLocations();

  const onChangeintervalsStatus = (value: string | null) => {
    updateState({
      intervalsStatus: value,
    })
  }

  const changeLocation = (id: string): void => {
    updateState({
      locationFhirId: id,
      isLoading: true,
      pagination: {
        ...pagination,
        page: 0,
      }
    })
  }

  const changeCategory = (id: number): void => {
    updateState({
      categoryId: id,
      isLoading: true,
      pagination: {
        ...pagination,
        page: 0,
      }
    })
  }

  const onStartDateChange = (start: string): void => {
    updateState((prevState: Partial<RoundingControllerState>) => ({
      dateRange: {
        start,
        end: prevState.dateRange?.end ?? moment().format('YYYY-MM-DD'),
      },
    }));
  };

  const onEndDateChange = (end: string): void => {
    updateState((prevState: Partial<RoundingControllerState>) => ({
      isLoading: true,
      dateRange: {
        end,
        start: prevState.dateRange?.start ?? moment().format('YYYY-MM-DD'),
      },
    }));
  };

  const onSearchButtonPress = (): void => {
    if (dateRange?.start && dateRange?.end) {
      updateState({
        isLoading: true,
        viewType: ROUNDING_VIEW_TYPES.SEARCH_RESULT_VIEW,
      });
    }
  }

  const onClearSearchPress = (): void => {
    updateState({
      isLoading: true,
      viewType: ROUNDING_VIEW_TYPES.ROUNDING_LIST_VIEW,
      selectedRounding: null,
    });
  }

  const completedIntervalDetail = (
    intervalDetail: IntervalDetail,
    roundingIndex: number,
    intervalIndex: number,
    intervalDetailIndex: number
  ): void => {
    updateState((prevState) => {
      const currentRoundings = prevState.roundings ?? [];
      let intervals: Interval[] | null | undefined = selectedRounding?.intervals ?? currentRoundings?.[roundingIndex].intervals;

      if (intervals) {
        const interval = intervals[intervalIndex];
        const intervalDetailListIndex = interval.intervalDetails?.findIndex(i => i.id === intervalDetailIndex);
        if (intervalDetailListIndex !== -1) {
          interval.intervalDetails[intervalDetailListIndex] = intervalDetail;
          interval.completedDatetime = interval.intervalDetails.every(i => i.isCompleted) ? new Date() : null;
          intervals[intervalIndex] = interval
        }
      }

     
      return {
        roundings: prevState.roundings?.map((rounding, index) => {
          if (index === roundingIndex) {
            return {
              ...rounding,
              intervals,
            }
          }

          return rounding;
        })
      }
    })
  }

  const onSaveRounding = (savedRounding: Rounding) => {
    const newRoundings = [...roundings ?? []];

    const index = newRoundings.findIndex((r) => r.id === savedRounding.id);
    if (index !== -1) {
      newRoundings[index] = savedRounding;
      updateState({
        roundings: newRoundings,
      })
    }
  }

  const getRoundings = useCallback((paginationParam: PaginationType) => {
    API.roundings.getRoundings({
      pagination: {
        take: paginationParam.take,
        page: paginationParam.page,
      },
      locationFhirId: locationFhirId,
      categoryId,
    })
    .then((roundingsResponse: Rounding[]) => {
      updateState({
        isLoading: false,
        roundings: roundingsResponse,
      })
    }).catch(error => console.log(error))
  }, [updateState, locationFhirId, categoryId])

  const getIntervals = useCallback((paginationParam: PaginationType) => {
    API.roundings.getIntervals({
      intervalId: null,
      locationFhirId,
      status: intervalsStatus,
      dateRange,
      pagination: {
        take: paginationParam.take,
        page: paginationParam.page,
      }
    })
    .then((intervalsResponse: { intervals: Interval[], total: number }) => {
      console.log({
        inervals: intervalsResponse.intervals
      })
      updateState({
        isLoading: false,
        intervals: intervalsResponse.intervals,
        pagination: {
          take: paginationParam.take,
          page: paginationParam.page,
          total: intervalsResponse.total,
        }
      })
    });
  }, [dateRange, intervalsStatus, updateState, locationFhirId])

  const getEntities = useCallback((paginationParam: PaginationType) => {
    if (viewType === ROUNDING_VIEW_TYPES.ROUNDING_LIST_VIEW) {
      getRoundings(paginationParam)
    } else if (viewType === ROUNDING_VIEW_TYPES.SEARCH_RESULT_VIEW) {
      getIntervals(paginationParam)
    }
  }, [getIntervals, getRoundings, viewType])

  const onSelectInterval = (rounding: Rounding, interval: Interval) => {
    updateState({
      viewType: ROUNDING_VIEW_TYPES.ROUNDING_LIST_VIEW,
      selectedRounding: {
        ...rounding,
        intervals: [
          interval
        ],
      }
    })
  }

  const getIntervalDetails = useCallback((
    intervalId: number,
    roundingIndex: number,
    intervalIndex: number,
    loadMore?: boolean,
  ) => {
    const currentRoundings = roundings ?? [];
    let newIntervals: Interval[] = currentRoundings?.[roundingIndex].intervals ?? [];
    let currentIntervalDetailsLength = newIntervals[intervalIndex].intervalDetails?.length ?? 0
    const defaultPagiantion = {
      take: DEFAULT_PAGINATION_TAKE,
      page: !loadMore ? 0 : currentIntervalDetailsLength, 
    };
    updateState({
      loadingIntervalDetails: true,
    })
    API.roundings.getIntervalDetails({
      intervalId,
      pagination: defaultPagiantion,
    })
    .then((intervalsResponse: { intervalDetails: IntervalDetail[]; totalCount: number }) => {
      updateState(() => {
        if (intervalsResponse.intervalDetails.length > 0) {
          const newIntervalDetails = loadMore ? [
            ...newIntervals[intervalIndex].intervalDetails ?? [],
            ...intervalsResponse.intervalDetails
          ] :  intervalsResponse.intervalDetails;
          
          const updatedInterval = {
            ...newIntervals[intervalIndex],
            intervalDetails: newIntervalDetails,
          }
          newIntervals[intervalIndex] = updatedInterval
        }

        currentRoundings[roundingIndex].intervals = newIntervals; 

        return {
          roundings: currentRoundings,
          intervalDetailsTotalCount: intervalsResponse.totalCount,
          loadingIntervalDetails: false,
        }
      })
    }).catch(error => console.log(error))
  }, [roundings, updateState])

  useEffect(() => {
    if (categoryId !== null && locationFhirId !== null) {
      getEntities({
        take: pagination?.take ?? DEFAULT_PAGINATION_TAKE,
        page: pagination?.page ?? 0,
      })
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [dateRange, intervalsStatus, updateState, viewType, locationFhirId, categoryId]);
 

  return [
    {
      pagination,
      categoryId,
      locationFhirId,
      openAddRoundingModal,
      isLoading,
      selectedRounding,
      roundings,
      intervals,
      intervalDetailsTotalCount,
      intervalsStatus,
      patientActivities,
      patientLocations,
      dateRange,
      viewType,
      loadingIntervalDetails,
    },
    {
      updateState,
      onSaveRounding,
      changeCategory,
      changeLocation,
      onChangeintervalsStatus,
      completedIntervalDetail,
      onStartDateChange,
      onEndDateChange,
      onSearchButtonPress,
      onClearSearchPress,
      onSelectInterval,
      getEntities: getIntervals,
      getIntervalDetails,
    }
  ]
};
