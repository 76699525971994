import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Button } from '@mui/material';
// components
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import { useActivityDefinitions, useCodeSystem } from 'src/@nicheaim/fhir-react';
import { ActivityDefinition } from 'src/@nicheaim/fhir-base/mappings/ActivityDefinition';
import RHFAutocomplete from 'src/components/hook-form/RHFAutocomplete';
import { CodeSystemConcept } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import fhirSystem from 'src/fhir/system';
import { useActiveActivityTypes } from 'src/fhir/hooks/activity-types';

// ----------------------------------------------------------------------

type FormValue = {
  name: string;
  type: CodeSystemConcept;
};

type PlanFormProps = {
  isEdit?: boolean;
  currentActivity?: ActivityDefinition;
};

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  type: Yup.object().nullable().required('Type is required'),
});

const resolver = yupResolver(schema);

export default function NewActivityForm({ isEdit, currentActivity }: PlanFormProps) {
  const navigate = useNavigate();
  const [activityTypes] = useActiveActivityTypes();
  const [, { create, update }] = useActivityDefinitions({ autofetch: false });
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () =>
      ({
        name: currentActivity?.title || '',
        type: activityTypes.find(
          (concept) => concept.code === currentActivity?.topic?.[0].coding?.[0].code
        ),
      } as FormValue),
    [currentActivity, activityTypes]
  );

  const methods = useForm({ resolver, defaultValues });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentActivity) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentActivity]);

  const onSubmit = async (data: FormValue) => {
    try {
      if (isEdit && currentActivity) {
        await update({
          ...currentActivity,
          title: data.name,
          topic: [
            {
              text: data.type.display,
              coding: [
                {
                  system: 'ccm-activity-types',
                  code: data.type.code,
                  display: data.type.display,
                },
              ],
            },
          ],
        });
      } else {
        const [activity] = await create({
          resourceType: 'ActivityDefinition',
          identifier: [
            {
              system: fhirSystem.activity.asString(),
            },
          ],
          title: data.name,
          topic: [
            {
              text: data.type.display,
              coding: [
                {
                  system: 'ccm-activity-types',
                  code: data.type.code,
                  display: data.type.display,
                },
              ],
            },
          ],
          status: 'active',
        });
        activity.identifier![0].value = activity.id;
        await update(activity);
      }

      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      navigate('..');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 3 }}>
        <Stack
          gap={3}
          sx={{
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <RHFAutocomplete
            name="type"
            fullWidth
            multiple={false}
            freeSolo={false}
            label="Activity Type"
            options={activityTypes}
            getOptionLabel={(option) => option.display || ''}
          />

          <RHFTextField name="name" label="Activity Name" />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }} gap={2}>
          <Button
            variant="text"
            color="inherit"
            component={RouterLink}
            to={isEdit ? '../..' : '..'}
          >
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {!isEdit ? 'Create Activity' : 'Save Changes'}
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
