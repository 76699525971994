import { Box, Stack } from '@mui/material';
import uuidv4 from '../../../../../utils/uuidv4';
import { ACTIVITY_LEGEND_DETAILS } from './ActivityRenderers/ActivityLegendRenderer';

export function Legend() {
  return (
    <Stack direction="column" sx={{ ml: 3, mb: 3 }}>
      <Box
        sx={{
          fontWeight: 'bold',
          fontStyle: 'italic',
          color: 'slategray',
          fontVariantCaps: 'small-caps',
          fontSize: 'larger',
          textDecoration: 'underline',
          letterSpacing: '1px',
          mb: 1,
          mt: 3,
        }}
      >
        Legend
      </Box>
      {Object.values(ACTIVITY_LEGEND_DETAILS).map((details) => (
        <Stack direction="row" key={uuidv4()} sx={{ my: 1, ml: 2 }}>
          <Box>{details.icon}</Box>
          <Box sx={{ fontSize: 'smaller' }}>{details.description}</Box>
        </Stack>
      ))}
    </Stack>
  );
}
