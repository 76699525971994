import api from "src/services/api";
import { useSnackbar } from "notistack";
import { capitalCase } from "change-case";
import { useEffect, useState } from "react";
import { Upload } from "src/sections/crs/common";
import { useFormContext } from "react-hook-form";
import Scrollbar from "src/components/Scrollbar";
import Viewer from "src/sections/crs/common/Viewer";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { getIdentifierDocumentReference } from "./common-utils";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { DocumentReference } from "src/@nicheaim/fhir-base/mappings/DocumentReference";
import { Box, Button, Card, Checkbox, Link, Stack, Tab, Typography } from "@mui/material";
import { WrappedDocumentReference } from "src/@nicheaim/fhir-base/wrappers/DocumentReference";

type Props = {
  label?: string;
  restrictFiles?: boolean;
  docsList?: boolean;
  patient: WrappedPatient | null;
  docRelated?: any;
  documentReferences: WrappedDocumentReference[] | null;
};
 
 export default function UploadFiles ({ label, restrictFiles, docsList, docRelated, documentReferences, patient }: Props) {
  
  const { setValue } = useFormContext();

  const { enqueueSnackbar } = useSnackbar();

  const [ data, setData ] = useState(null);

  const [ loading, setLoading ] = useState(0);

  const [ valueTab, setValueTab ] = useState('1');

  const [ showViewer, setShowViewer ] = useState(false);

  const [ selected, setSelected ] = useState<string[]>(docRelated);

  const [ selectedPHIN, setSelectedPHIN ] = useState<string[]>([]);

  const [ documentsFromPHIN, setDocumentsFromPHIN ] = useState<DocumentReference[]>();

  const onSelected = (id:string) => {
    const selectedIndex = selected.indexOf(id);

    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    setValue('documents',newSelected);
  };

  const onSelectedPHIN = (docPHIN: DocumentReference[]) => {

    docPHIN.map((doc) => {
      if(doc.id){
        const selectedIndexPHIN = selectedPHIN.indexOf(doc.id);

        let newSelectedPHIN: string[] = [];

        if (selectedIndexPHIN === -1) {
          newSelectedPHIN = newSelectedPHIN.concat(selectedPHIN, doc.id);
        } else if (selectedIndexPHIN === 0) {
          newSelectedPHIN = newSelectedPHIN.concat(selectedPHIN.slice(1));
        } else if (selectedIndexPHIN === selectedPHIN.length - 1) {
          newSelectedPHIN = newSelectedPHIN.concat(selectedPHIN.slice(0, -1));
        } else if (selectedIndexPHIN > 0) {
          newSelectedPHIN = newSelectedPHIN.concat(
            selectedPHIN.slice(0, selectedIndexPHIN),
            selectedPHIN.slice(selectedIndexPHIN + 1)
          );
        }
        setSelectedPHIN(newSelectedPHIN);
        setValue('documentsPHIN', newSelectedPHIN);
        
        newSelectedPHIN.map((opt) => {
          if(opt === doc.id){
            setValue('documentsPHIN', docPHIN);
          }
        })
      }
    })
  };

  const documentFHIR = documentReferences?.filter((opt) => getIdentifierDocumentReference('repositoryUniqueId', opt));

  const fetchDocumentsPHIN = async () => {

    try{
      
      setLoading(1);

      const patientId = patient?.getMRN()?.value;
      let data;
      if(patientId){
         data = await api.documents.getDocuments(patientId || '');

        if(data.entry){
          setLoading(2);
        } else if(!data.entry) {
          setLoading(3);
        }
      }else{
        setLoading(3);
      }

      let mintDocs: DocumentReference[] = [];
        for(const { resource: document } of data?.entry || []){
          if(Array.isArray(document)){
            mintDocs=document;
          }else{
            if(document){
              mintDocs.push(document);
            }
          }
        }
      setDocumentsFromPHIN(mintDocs);
    }catch(e) {
      enqueueSnackbar('An error has occurred to get documents from PHIN.', { variant:'error' }) 
    };
  };

  const handleClick = (documentSelected:any, )=>{
    setShowViewer(true);
    setData(documentSelected);
  }

  useEffect(() => {
    if(docsList){
      setValueTab('2');
    }
  },[docsList]);

  return (
    <>
      <Card sx={{ m: 1 }}>
        <TabContext value={valueTab}>
          <Box sx={{ px: 3 }}>
            <TabList onChange={(e, value) => setValueTab(value)} 
            >
              <Tab 
                disableRipple 
                value="1" 
                label="PHIN" 
                onClick={() => setShowViewer(false)}
              />
              <Tab 
                disableRipple 
                value="2" 
                label={`${capitalCase(label || '')} Attached Docs`} 
                onClick={() => setShowViewer(false)}
              />
              <Tab 
                disableRipple
                value="3"
                label="Manual Uploads"
                onClick={() => setShowViewer(false)}
                sx={{ '& .MuiTab-wrapper': { whiteSpace: 'nowrap' } }}
              />
            </TabList>
          </Box>

          <TabPanel value="1" sx={{ p: 3 }}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <Button
                variant='contained'
                color='info'
                onClick={() => fetchDocumentsPHIN()}
              >
                Search within PHIN
              </Button>
            </Stack>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <Scrollbar sx={{ height: 200, width: 600 }}>
                {loading === 1 &&  <Typography>Loading...</Typography>}
                {loading === 2 && 
                  documentsFromPHIN?.map((doc, index:number) => {
                    const getRepositoryUniqueId = doc?.identifier?.find(e => e.type?.text === "repositoryUniqueId")?.value;
                    const exist = documentFHIR?.[0]?.identifier?.find(s => s.value === getRepositoryUniqueId);
                    return(
                      <Stack direction="row" spacing={1} alignItems="center" key={doc.id}>
                        <Checkbox 
                          checked={ exist ? true : selectedPHIN.includes(doc.id || '')} 
                          onClick={() => onSelectedPHIN([doc])} 
                          disabled={exist ? true : false}
                        />
                        <Link 
                          variant="subtitle2" 
                          color="#1890FF" 
                          underline="always" 
                          onClick={() => handleClick(doc)}
                        >
                          {doc.content[0]?.attachment?.title}
                        </Link>
                      </Stack>
                    )
                  })
                }
                { loading === 3 &&  <Typography>Don't exist documents</Typography>}
              </Scrollbar>
            </Stack>
          </TabPanel>
          <TabPanel value="2" sx={{ p: 3 }}>
            <Stack spacing={2}>
              <Scrollbar sx={{ height: 200, width: 600 }}>
                {documentReferences?.map((item, index:number)=> {
                  return(
                    <Stack direction="row" spacing={1} alignItems="center" key={item.id}>
                      <Checkbox 
                        checked={selected?.includes(item.id || '')} 
                        onClick={() => onSelected(item.id || '')} 
                      />
                      <Link 
                        variant="subtitle2" 
                        color="#1890FF" 
                        underline="always" 
                        onClick={() => handleClick(item)}
                      >
                        {item.content[0]?.attachment?.title}
                      </Link>
                    </Stack>
                  )
                })}
              </Scrollbar>
            </Stack>
          </TabPanel>
          <TabPanel value="3" sx={{ p: 3 }}>
            <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
              <Upload restrictFiles={restrictFiles} />
            </Stack>
          </TabPanel>
        </TabContext>
      </Card>
      {showViewer && ( <Viewer documentReference={data} typeResource="documentReference" patientId={patient?.getMRN()?.value} />)}
    </>
  )
}