import { AxiosInstance } from "axios";
import { FhirResource } from "./resources";
import { HttpMethod, isResourceReturnMethod } from "./HttpMethod";
import {
  Repository
} from "src/nicheaim-infrastructure/application/ports/out/repositories";
import { TokenSource } from "./TokenSource";
import { SimpleMap } from "../../../../domain";

export class AxiosFHIRRepository<ResourceType extends FhirResource>
  implements Repository<string, ResourceType, SimpleMap> {

  constructor(
    private readonly resourceType: string,
    private readonly axiosInstance: AxiosInstance,
    private readonly tokenSource?: TokenSource,
  ) {
  }

  async getEntity(id: string): Promise<ResourceType | undefined> {
    return this.performOperation(HttpMethod.GET, {}, `${this.resourceType}/${id}`);
  }

  async findEntities(filter: SimpleMap): Promise<ResourceType[]> {
    return this.performOperation(HttpMethod.GET, { params: filter });
  }

  async createEntity(resource: FhirResource): Promise<ResourceType> {
    return this.performOperation(HttpMethod.POST, { data: resource });
  }

  async updateEntity(resource: FhirResource): Promise<ResourceType> {
    return this.performOperation(HttpMethod.PUT, { data: resource });
  }

  async deleteEntity(id: string): Promise<void> {
    return this.performOperation(HttpMethod.DELETE);
  }

  private async performOperation(
    method: HttpMethod,
    extraConfigs: object = {},
    url: string = `/${this.resourceType}`,
  ) {
    return (
      await this.axiosInstance(
        {
          method,
          url,
          headers: this.getHeaders(method),
          ...extraConfigs,
        })
    ).data;
  }

  private getHeaders(method: HttpMethod) {
    const { tokenSource } = this;
    return {
      'Content-Type': 'application/fhir+json; charset=UTF-8',
      'Accept': 'application/fhir+json',
      ...(isResourceReturnMethod(method) ? { 'Prefer': 'return=representation' } : {}),
      ...(tokenSource ? { 'Authorization': `Bearer ${tokenSource.getToken()}` } : {}),
    };
  }

}
