import { useCallback, useState } from 'react';
import {
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import { EntityContextMenu, SummaryTable } from 'src/sections/careflow/common';
import ServicesSummaryForm from './ServicesSummaryForm';
import { useServiceRequests } from 'src/@nicheaim/fhir-react';
import { parseShortFormat } from 'src/utils/formatTime';
import { ServiceRequest } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import AddModal from 'src/sections/careflow/common/AddModal';

interface ServicesSummaryProps {
  isExpanded?: boolean;
  onExpand?: (index: number) => void;
  patient: any;
}

const ServicesSummary = ({ isExpanded = false, onExpand, patient }: ServicesSummaryProps) => {
  const [showAddServiceForm, setShowAddServiceForm] = useState(false);
  const [selectedService, setSelectedService] = useState<any>(null);
  const [services, { remove }] = useServiceRequests({
    filter: {
      subject: `Patient/${patient?.id}`,
    },
  });

  const handleDelete = useCallback(
    (row: any) => {
      remove(row);
    },
    [remove]
  );

  return (
    <>
      <AddModal
        open={showAddServiceForm}
        onClose={() => setShowAddServiceForm(false)}
        Component={
          <ServicesSummaryForm onClose={() => setShowAddServiceForm(false)} data={selectedService} />
        }
      />

      <SummaryTable
        title="Services Summary"
        data={services ?? []}
        columns={[
          {
            header: 'Date',
            field: '$',
            render(service: ServiceRequest) {
              return (
                <Typography variant="body2">
                  {service.occurrencePeriod?.start
                    ? parseShortFormat(service.occurrencePeriod?.start)
                    : '-'}
                </Typography>
              )
            }
          },
          {
            header: 'Service / Code',
            field: '$',
            render(service: ServiceRequest) {
              return (
                <Stack>
                  <Typography variant="body2">{service?.category?.[0]?.coding?.[0]?.display}</Typography>
                  <Typography variant="caption">{service?.category?.[0]?.coding?.[0]?.code}</Typography>
                </Stack>
              )
            }
          },
          {
            header: 'Provider',
            field: '$',
            visible: isExpanded,
            render(service: ServiceRequest) {
              return service?.performer?.[0]?.display;
            }
          },
          {
            header: 'End Date',
            field: '$',
            visible: isExpanded,
            render(service: ServiceRequest) {
              return service.occurrencePeriod?.end ? parseShortFormat(service.occurrencePeriod?.end) : '-';
            }
          },
          {
            header: 'Services Received',
            field: '$',
            visible: isExpanded,
            render(service: ServiceRequest) {
              return service.category?.[0]?.text;
            }
          },
          {
            header: 'Date',
            field: '$',
            visible: isExpanded,
            render(service: ServiceRequest) {
              return service?.reasonCode?.[0].coding?.[0]?.display;
            }
          },
          {
            header: 'Data Source',
            field: '$',
            visible: isExpanded,
            render(service: ServiceRequest) {
              return 'Manual';
            }
          }
        ]}
        actionBar={
          <>
            <IconButton
              onClick={() => {
                setShowAddServiceForm(true);
                setSelectedService(null);
              }}
            >
              <Iconify icon="eva:plus-fill" />
            </IconButton>
            <IconButton onClick={() => onExpand?.(1)}>
              <Iconify icon="eva:expand-fill" />
            </IconButton>
          </>
        }
        rowContextMenu={(service: any) => (
          <EntityContextMenu
            entity={service}
            items={[
              {
                label: 'Edit',
                icon: 'eva:edit-2-outline',
                onPress: (service) => {
                  setSelectedService(service);
                  setShowAddServiceForm(true);
                },
              },
              {
                label: 'Delete',
                icon: 'eva:trash-2-outline',
                onPress: (service) => {
                  handleDelete(service)
                },
              },
            ]}
          />
        )}
      />
    </>
  );
};

export default ServicesSummary;
