import axiosFhirInstance from "./axiosFhirInstance";
import { CareTeamRepository } from "../../../ports/out/repositories";
import {
  AxiosFHIRRepository,
  TokenSource
} from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir";
import {
  CareTeam
} from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";

export class FHIRCareTeamRepository extends AxiosFHIRRepository<CareTeam> implements CareTeamRepository {
  constructor(tokenSource?: TokenSource) {
    super('CareTeam', axiosFhirInstance, tokenSource);
  }
}