import { useState, useEffect, useMemo, useRef } from 'react';
import { DatePicker } from '@mui/lab';
import produce from 'immer';
import {
  MenuItem,
  Stack,
  Typography,
  IconButton,
  TextField,
  Button,
  Autocomplete,
  Select,
  SelectChangeEvent,
  CircularProgress,
  FormControl,
  Grid,
  Alert,
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import { SHOW_SIDEBAR_COMPONENT, useEventBus } from 'src/event-bus';
import { useCodeSystem, useEncounters } from 'src/@nicheaim/fhir-react';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import {
  Coding,
  Encounter,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { PractitionerWrapper } from 'src/@nicheaim/fhir-base/wrappers/Practitioner';
import fhirSystem from 'src/fhir/system';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import DiagnosisSearchDropdown, { DropdownOptionType } from './components/DiagnosisSearchDropdown';

interface DiagnosisSummaryFormProps {
  data?: Encounter;
  onClose: () => void;
}

type OptionType = (DropdownOptionType & { system: string });

const DiagnosisSummaryForm = ({ data, onClose }: DiagnosisSummaryFormProps) => {
  const patient = useCurrentPatient();
  const id = data?.id;
  const isEditting = Boolean(id);

  const currentCoding = data?.diagnosis?.[0]?.use?.coding?.[0];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const initDate = data?.period?.start ? new Date(data?.period?.start) : null;
  const initDiagnosis: OptionType | null = { display: currentCoding?.display ?? '', code: currentCoding?.code ??'', system: currentCoding?.system ?? '' };
  const initPractitioner = data?.participant?.[0]?.individual?.reference;

  const [date, setDate] = useState<Date | null>(initDate);
  const [diagnosis, setDiagnosis] = useState<OptionType | undefined | null>(initDiagnosis);
  const [practitioner, setPractitioner] = useState(initPractitioner || '');

  const { dispatchEvent } = useEventBus();

  const [, { create: createEncounter, update }] = useEncounters();
  const [practitionerList, setPractitioners] = useState<any>([]);
  const importDiagnosis = useRef<Function>();

  const clearAll = () => {
    setDate(null);
    setDiagnosis(null);
    setPractitioner('');
  };

  const handleChangeProvider = (ev: SelectChangeEvent<string>) => {
    setPractitioner(ev.target.value);
  };

  const validateForm = () => {
    if (!diagnosis || !date || !practitioner) {
      return false;
    }

    return true;
  };

  const setErrorMessage = () => {
    setSuccess(null);
    setError('Please fill all the fields.');
    setIsLoading(false);
  };

  const setSuccessMessage = () => {
    setError(null);
    setSuccess('Diagnosis succssfully saved.');
    setIsLoading(false);
    setTimeout(() => {
      onClose();
    }, 1000);
  };

  const createDiagnosis = async () => {
    if (!validateForm()) {
      setErrorMessage();
      return;
    }

    if (diagnosis) {
      await importDiagnosis?.current?.(diagnosis);
      await createEncounter({
        resourceType: 'Encounter',
        status: 'finished',
        class: {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'AMB',
          display: 'ambulatory',
        },
        type: [
          {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: '11429006',
                display: 'Consultation',
              },
            ],
          },
        ],
        subject: {
          reference: `Patient/${patient?.id}`,
        },
        ...(practitioner
          ? {
              participant: [
                {
                  individual: {
                    reference: practitioner,
                  },
                },
              ],
            }
          : {}),
        period: {
          start: date?.toISOString() || '',
        },
        diagnosis: [
          {
            condition: {
              display: diagnosis?.display,
            },
            use: {
              coding: [
                {
                  code: diagnosis?.code,
                  display: diagnosis?.display,
                  system: diagnosis?.system,
                },
              ],
            },
          },
        ],
      });

      setSuccessMessage();
      clearAll();
      dispatchEvent('UPDATE_DIAGNOSIS_SUMMARY');
    }
  };

  const updateDiagnosis = async () => {
    if (!validateForm()) {
      setErrorMessage();
      return;
    }

    if (diagnosis) {
      const p = produce(data!, (draft) => {
        draft.diagnosis = [
          {
            condition: {
              display: diagnosis?.display,
            },
            use: {
              coding: [
                {
                  code: diagnosis?.code,
                  display: diagnosis?.display,
                  system: diagnosis?.system,
                },
              ],
            },
          },
        ];
        draft.period = {
          start: date?.toISOString() || '',
        };
        if (practitioner) {
          draft.participant = [
            {
              individual: {
                reference: practitioner,
              },
            },
          ];
        } else {
          delete draft.participant;
        }
      });
      await importDiagnosis?.current?.(diagnosis);
      await update(p);
      setSuccessMessage();
      clearAll();
      dispatchEvent('UPDATE_DIAGNOSIS_SUMMARY');
    }
  };

  const handleSave = async () => {
    setIsLoading(true);
    isEditting ? await updateDiagnosis() : await createDiagnosis();
  };

  useEffect(() => {
    axiosFhirInstance
      .get(`/Practitioner?identifier=${fhirSystem.systemUser.forCode('')}&_count=1000`)
      .then(({ data }) => {
        setPractitioners(data.entry?.map((p) => PractitionerWrapper(p.resource)) ?? []);
      });
  }, []);

  

  return (
    <Stack px={2}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="subtitle1">Add Diagnosis</Typography>
        <IconButton onClick={onClose}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>
      <DatePicker
        label="Date"
        value={date}
        onChange={setDate}
        renderInput={(params) => <TextField variant="standard" {...params} />}
      />
      <DiagnosisSearchDropdown
        value={diagnosis}
        onChange={(value, importDiagnosisParam) => {
          setDiagnosis(value)
          importDiagnosis.current = importDiagnosisParam
        }}
        sx={{
          mt: 3
        }}
        label="Diagnosis"
      />
      <Select
        displayEmpty
        fullWidth
        label="Provider"
        margin="dense"
        size="small"
        variant="standard"
        onChange={handleChangeProvider}
        value={practitioner}
        sx={{ mt: 2 }}
      >
        <MenuItem value={''} sx={{ fontStyle: 'italic' }}>
          None
        </MenuItem>
        {practitionerList?.map((p, index: number) => (
          <MenuItem key={`participant-${index}`} value={`${p?.resourceType}/${p?.id}`}>
            {p?.getFullNameWithTitles() ?? 'N/A'}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <Alert style={{ marginTop: '20px' }} severity="error">
          {error}
        </Alert>
      )}
      {success && (
        <Alert style={{ marginTop: '20px' }} severity="success">
          {success}
        </Alert>
      )}
      <ActionButtons
        isLoading={isLoading}
        onLeftButtonPress={clearAll}
        onRightButtonPress={handleSave}
      />
    </Stack>
  );
};

export default DiagnosisSummaryForm;
