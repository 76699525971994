// @ts-nocheck
import { useState, useEffect } from 'react';
import { spreadSxProp } from '../utils/cssStyles';
import {
  Typography,
  Checkbox,
  CheckboxProps,
  FormGroup,
  FormControlLabel,
  Box,
  SxProps,
} from '@mui/material';

export type OnCheckboxChange = (checkedItems: any[]) => void;
export type DataExtractor<T> = (item: any) => T;

export interface CheckboxListProps {
  containerSx?: SxProps;
  CheckBoxProps?: Omit<CheckboxProps, 'onChange'>;
  title: string;
  options: any[];
  keyExtractor: DataExtractor<string>;
  labelExtractor: DataExtractor<string>;
  onChange: OnCheckboxChange;
  externalCheckedItems: any[];
}

const CheckboxList = ({
  title,
  options,
  onChange,
  keyExtractor,
  CheckBoxProps,
  containerSx,
  labelExtractor,
  externalCheckedItems = [],
}: CheckboxListProps) => {
  const [checkedItems, setCheckedItems] = useState<any[]>(externalCheckedItems);
  const handleChange = (item: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let newCheckedItems = [];
    if (event.target.checked) {
      newCheckedItems = [...new Set([...checkedItems, item])];
    } else {
      newCheckedItems = checkedItems.filter((checkedItem) => checkedItem !== item);
    }
    setCheckedItems(newCheckedItems);
    onChange(newCheckedItems);
  };
  useEffect(() => {
    setCheckedItems(externalCheckedItems);
  }, [externalCheckedItems]);
  return (
    <Box sx={[...spreadSxProp(containerSx)]}>
      <Typography sx={{ marginBottom: 1 }} fontWeight={'bold'}>
        {title}
      </Typography>
      <FormGroup>
        {options.map((item) => (
          <FormControlLabel
            key={keyExtractor(item)}
            control={
              <Checkbox
                checked={(() => !!checkedItems.find((checkedItem) => item === checkedItem))()}
                onChange={handleChange(item)}
                {...CheckBoxProps}
              />
            }
            label={labelExtractor(item)}
          />
        ))}
      </FormGroup>
    </Box>
  );
};

export default CheckboxList;
