import isSameDay from 'date-fns/isSameDay';
import { longFormat } from '../../../../utils/formatTime';
import { ActivityStatus } from './ActivityStatus';
import { ActivityLegends } from './ActivityLegends';
import { ActivityPriority } from './ActivityPriority';
import { DateRange } from './DateRange';
import moment from 'moment';

export interface Activity {
  id: number;
  taskId: string;
  date: Date;
  start: Date;
  end: Date;
  patientName: string;
  patientAge: number;
  patientFhirId: string;
  activity: string;
  staff: string;
  staffFhirId: string;
  priority: ActivityPriority;
  status: ActivityStatus;
  allDay: boolean;
  type: string;
  activityTypeId: string;
  activityId: string;
  script: string;
  scriptId: string;
  contactType: string;
  note: string;
  repeat: string;
  reminder: number;
  contactMade: boolean;
  completedDate: Date;
  completedTime: Date;
  completedDuration: number;
  completedUnit: string;
  completedNote: string;
  createdAt: string;
  createdBy: string;
  updatedBy: string;
  updatedAt: string;
  deletedAt: string;
  name: string;
  legend?: ActivityLegends;
  specialty?: string;
  containsDay(date: Date): boolean;
  isInRange(range: DateRange): boolean;
  updateStatus(): void;
  isPastDue(): boolean;
  toJSON(): object;
}

export class ConcreteActivity implements Activity {
  readonly id: number;

  readonly taskId: string;

  readonly date: Date;

  readonly start: Date;

  readonly end: Date;

  readonly patientName: string;

  readonly patientAge: number;

  readonly patientFhirId: string;

  readonly contactType: string;

  readonly staff: string;

  readonly staffFhirId: string;

  readonly priority: ActivityPriority;

  readonly status: ActivityStatus;

  readonly allDay: boolean;

  readonly type: string;

  readonly activityTypeId: string;

  readonly activityId: string;

  readonly script: string;

  readonly scriptId: string;

  readonly note: string;

  readonly repeat: string;

  readonly reminder: number;

  readonly contactMade: boolean;

  readonly completedDate: Date;

  readonly completedTime: Date;

  readonly completedDuration: number;

  readonly completedUnit: string;

  readonly completedNote: string;

  readonly createdAt: string;

  readonly createdBy: string;

  readonly updatedBy: string;

  readonly updatedAt: string;

  readonly deletedAt: string;

  readonly name: string;

  public legend?: ActivityLegends;

  readonly specialty?: string;

  activity: string;

  constructor(
    activity: Omit<
      Activity,
      'validate' | 'containsDay' | 'isInRange' | 'updateStatus' | 'isPastDue' | 'toJSON'
    >
  ) {
    this.id = activity.id;
    this.taskId = activity.taskId;
    this.date = moment(activity.start).toDate();
    this.start = moment(activity.start).toDate();
    this.end = moment(activity.end).toDate();
    this.patientName = activity.patientName;
    this.patientAge = activity.patientAge;
    this.patientFhirId = activity.patientFhirId;
    this.activity = activity.activity;
    this.contactType = activity.contactType;
    this.staff = activity.staff;
    this.staffFhirId = activity.staffFhirId;
    this.priority = activity.priority;
    this.status = activity.status;
    this.allDay = activity.allDay;
    this.type = activity.type;
    this.activityTypeId = activity.activityTypeId;
    this.activityId = activity.activityId;
    this.script = activity.script;
    this.scriptId = activity.scriptId;
    this.note = activity.note;
    this.repeat = activity.repeat;
    this.reminder = activity.reminder;
    this.contactMade = activity.contactMade;
    this.completedDate = activity.completedDate;
    this.completedTime = activity.completedTime;
    this.completedDuration = activity.completedDuration;
    this.completedUnit = activity.completedUnit;
    this.completedNote = activity.completedNote;
    this.createdAt = activity.createdAt;
    this.createdBy = activity.createdBy;
    this.updatedBy = activity.updatedBy;
    this.updatedAt = activity.updatedAt;
    this.deletedAt = activity.deletedAt;
    this.name = activity.name;
    this.legend = activity.legend;
    this.specialty = activity.specialty;
    this.validate();
  }

  private validate() {
    if (this.start.getTime() > this.end.getTime()) {
      throw new Error(
        [
          'Activity with ID',
          `"${this.id}" is invalid.`,
          'Its Start Time',
          `(${longFormat(this.start)})`,
          'is greater than its End Time',
          `(${longFormat(this.end)})`,
        ].join(' ')
      );
    }
    this.updateStatus();
  }

  containsDay(date: Date) {
    return isSameDay(this.start, date) || isSameDay(this.end, date);
  }

  isInRange(range: DateRange): boolean {
    return (
      this.start.getTime() >= range.start.getTime() && this.end.getTime() <= range.end.getTime()
    );
  }

  updateStatus() {
    if (moment(this.end).isBefore(moment().startOf('day'))) {
      this.legend = ActivityLegends.PAST_DUE;
    } else if (moment(this.start).isAfter(moment().endOf('day'))) {
      this.legend = ActivityLegends.FUTURE_TASK;
    }
    else {
      this.legend = ActivityLegends.DUE_TODAY;
    }
  }

  isPastDue(): boolean {
    return this.legend === ActivityLegends.PAST_DUE;
  }

  toJSON() {
    return {
      id: this.id,
      type: this.type,
      name: this.name,
      start: moment(this.start).toISOString(),
      end: moment(this.end).toISOString(),
      status: this.status,
      legend: this.legend,
      priority: this.priority,
      script: this.script,
    };
  }
}
