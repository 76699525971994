import { Navigate, Route, Routes } from 'react-router';
import EditPLan from './edit';
// import EditClient from './edit';
import PlanLOBsIndex from './lobs';
import AddPatientToProgramForm from './lobs/$lob/programs/$program/patients/new';

export default function PlanIndex() {
  return (
    <Routes>
      <Route index element={<Navigate to="lobs" replace />} />
      <Route path="edit" element={<EditPLan />} />
      <Route path="lobs/*" element={<PlanLOBsIndex />} />
      <Route path="patients" element={<div />} />
      <Route path="patients/new" element={<AddPatientToProgramForm />} />
    </Routes>
  );
}
