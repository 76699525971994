// @ts-nocheck
import { useMemo, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
} from '@mui/material';
import moment from 'moment';
import { useCodeSystem, useCommunications } from 'src/@nicheaim/fhir-react';
import useAuth from 'src/hooks/useAuth';
import { CarePlan, Communication } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import PatientCard from 'src/sections/careflow/common/PatientCard';
import ActionButtons from 'src/sections/careflow/common/ActionButtons';
import { getFhirIdFromReferenceString } from '../../../tasks-activities/components/Activities/activities-utils';
import fhirSystem from 'src/fhir/system';
import useAddEntityRequestStates from 'src/hooks/useAddEntityRequestStates';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useActiveCarePlans } from 'src/fhir/hooks/care-plans';

export type AddBarrierModalPropsType = {
  onSaveCallback: () => void;
  onCancelPress: () => void;
  patient?: any;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddBarrierModal = ({ patient, onSaveCallback, onCancelPress }: AddBarrierModalPropsType) => {
  const [, { create: createCommunication }] = useCommunications({
    autofetch: false,
  });
  const [barriersCodeSystem] = useCodeSystem('ccm-careplan-barriers-types');
  const { user_fhir_uri } = useAuth().getCurrentUser();
  const [barrierItems, setBarrierItems] = useState<
    { value: string | undefined; label: string | undefined }[]
  >([]);
  const [{ isLoading, error, success }, { setIsLoading, setError, setSuccess }] =
    useAddEntityRequestStates();
  const [carePlans, { update: updateCarePlan }] = useActiveCarePlans({
    filter: {
      patient: patient?.id,
      _count: 10
    },
  });

  useEffect(() => {
    setBarrierItems(([...(carePlans ?? [])]?.pop()?.supportingInfo ?? []).map(i => {
      console.log({
        i
      })
      return {
        value: getFhirIdFromReferenceString(i.reference!),
        label: i.display,
      }
    }))
  }, [carePlans])

  console.log({
    barrierItems
  })

  const addedBarriers = useMemo(() => [...(carePlans ?? [])]?.pop()?.supportingInfo ?? [], [carePlans]);
  const barrierOptions = useMemo(
    () =>
      barriersCodeSystem?.concept?.map((option) => ({
        label: option.display ?? 'N/A',
        value: option.code,
      })) ?? [],
    [barriersCodeSystem]
  );

  // useEffect(
  //   () =>
  //     setBarrierItems(
  //       addedBarriers.map((sp) => ({
  //         value: sp.id,
  //         label: sp.display,
  //       }))
  //     ),
  //   [addedBarriers]
  // );

  const barriersToAdd = useMemo(() => barrierItems.filter(
    (sp) => !addedBarriers.find((i) => i.display === sp.label)
  ), [addedBarriers, barrierItems])

  const barriersToDelete = useMemo(() =>
  [...(carePlans ?? [])].pop()?.supportingInfo?.filter(
    (info: any) =>
      !barrierItems.find(barrier => barrier?.label === info.display)
    ).map(i => getFhirIdFromReferenceString(i.reference)) ?? [],
  [barrierItems, carePlans]);


  console.log({
    addedBarriers,
    barriersToAdd,
    barriersToDelete
  })

  const onSavePress = async () => {
    if (barrierItems.length === 0) {
      setError('You must select at least 1 barrier');
      return;
    }
    setIsLoading(true);
    const ownerId = getFhirIdFromReferenceString(user_fhir_uri);
    let savedCommunications: (Communication | null)[] = [];

    if (barriersToAdd.length > 0) {
      savedCommunications = await Promise.all(
        barriersToAdd.map(async (barrier) => {
          const payload: Communication = {
            resourceType: 'Communication',
            sent: moment.utc().toISOString(),
            status: 'completed',
            note: [
              {
                text: barrier.label ?? '',
                authorReference: {
                  reference: `Practitioner/${ownerId}`,
                  type: 'Practitioner',
                },
                time: moment.utc().toISOString(),
              },
            ],
            sender: {
              reference: `Practitioner/${ownerId}`,
              type: 'Practitioner',
            },
          };

          const communications = (await createCommunication(payload)) as Communication[];

          if (communications.length > 0) return Promise.resolve(communications[0]);

          return Promise.resolve(null);
        })
      );
    }

    await Promise.all(
      carePlans.map((carePlan) => {
        let tempCarePlan: CarePlan = {
          ...carePlan,
        };
        delete tempCarePlan.parsed;
        delete tempCarePlan._activityDefinition;
        delete tempCarePlan._condition;
        delete tempCarePlan._goal;

        const lastUpdateExtension = tempCarePlan?.extension?.find(i => i.url === fhirSystem.extension.CarePlan.lastUpdated.asString());

        if (lastUpdateExtension) {
          const index: number = tempCarePlan?.extension?.indexOf(lastUpdateExtension) ?? -1;
          if (index !== -1) {
            tempCarePlan.extension[index].valueDateTime = new Date().toISOString();
          }
        } else {
          tempCarePlan?.extension?.push({
            url: fhirSystem.extension.CarePlan.lastUpdated.asString(),
            valueDateTime:new Date().toISOString(),
          });
        }

        const updatedCarePlan = {
          ...tempCarePlan,
          supportingInfo: [
            ...addedBarriers.filter(a => !barriersToDelete.find(toDeleteId => toDeleteId === getFhirIdFromReferenceString(a.reference!))),
            ...savedCommunications
              .filter((c) => c !== null)
              .map((communication) => ({
                reference: `Communication/${communication?.id}`,
                display: communication?.note?.[0]?.text,
              })),
          ],
        };
        return updateCarePlan(updatedCarePlan).catch((err) => console.log('ERRRO CATH', err))
      })
    );

    setError(null);
    setSuccess('Barrier created successfully.');
    setTimeout(() => {
      onSaveCallback();
      onCancelPress();
    }, 1000);
  };

  return (
    <Box
      sx={{
        position: 'absolute' as 'absolute',
        top: 0,
        width: 400,
        height: '100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll',
      }}
    >
      <Box sx={{ my: 3 }}>
        <Grid item>
          <PatientCard id={patient?.id} />
        </Grid>
      </Box>
      <Box sx={{ my: 3 }}>
        <Grid item>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Add new barriers
          </Typography>
        </Grid>
      </Box>
      <Divider />
      <Grid item style={{ marginTop: '15px' }}>
        <FormControl fullWidth>
          <Autocomplete
            multiple
            disablePortal
            options={barrierOptions}
            value={barrierItems}
            disableCloseOnSelect
            onChange={(_: any, value: any | null, reason, changes) => {
              if (barrierItems.find(i => i?.label === changes?.option.label)) {
                setBarrierItems(value.filter((i: any) => i?.label !== changes?.option.label))
                return;
              }
              setBarrierItems(value);
            }}
            renderInput={(params) => (
              <TextField required variant="standard" {...params} label="Add barriers" />
            )}
            filterOptions={(options, state) => {
              const filteredOptions = options.filter((i) => i.label?.includes(state.inputValue));
              if (filteredOptions.length === 0) {
                return [
                  {
                    label: state.inputValue,
                    value: 'add_new_value',
                  },
                ];
              }

              return filteredOptions;
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={
                    barrierItems.find((i) => i?.label === option?.label) ? true : false
                  }
                />
                {option?.label}
              </li>
            )}
          />
        </FormControl>
      </Grid>
      {error && (
        <Alert style={{ marginTop: '20px' }} severity="error">
          {error}
        </Alert>
      )}
      {success && (
        <Alert style={{ marginTop: '20px' }} severity="success">
          {success}
        </Alert>
      )}
      <ActionButtons
        isLoading={isLoading}
        leftButtonTitle="Cancel"
        onLeftButtonPress={onCancelPress}
        onRightButtonPress={onSavePress}
      />
    </Box>
  );
};

export default AddBarrierModal;
