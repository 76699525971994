import Item from './Item';
import { useEffect } from 'react';
import { useDialogStore } from 'src/stores/dialog';
import { Button, Typography } from '@mui/material';
import useSmartLaunch from 'src/hooks/useSmartLaunch';
import { PROVIDER_WINDOW_LAUNCH_URL } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import { checkAclValidation, getRelatedAcls } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

interface ProviderWindowProps {
  userId: string;
  fhirUserId: string;
  patientId: string | null;
  patientData: boolean;
  patientMiNTIdentifier: boolean;
}

const getFhirIdFromUri = (uri: string): string => {
  const fhirIdParts = uri.split('/');
  return fhirIdParts?.[1] ? fhirIdParts[1] : '';
};

function ProviderWindow({
  userId,
  fhirUserId,
  patientId,
  patientData,
  patientMiNTIdentifier,
}: ProviderWindowProps) {
  const { confirmDialog } = useDialogStore();

  const { user } = useAuth();

  const { getSmartUrl, readyToBuild } = useSmartLaunch({
    userId,
    fhirUserId: getFhirIdFromUri(fhirUserId),
    patientId,
    smartAppBaseLaunchUrl: PROVIDER_WINDOW_LAUNCH_URL,
  });

  const canOpenMintNonExistentPatient = checkAclValidation({
    user,
    acl: getRelatedAcls(crsAcls.PROVIDER_WINDOW.OPEN_NON_EXISTENT_PATIENT),
  });

  const handleVerify = async () => {
    if (patientData && patientMiNTIdentifier && patientId) {
      handleOpenProviderWindow();
    } else if (!patientId) {
      await confirmDialog({
        title: 'Warning',
        confirmText: 'Ok',
        description: (
          <>
            <Typography gutterBottom>Unable to open Provider Window</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              <strong>NOTE:</strong> Patient MRN is missing.
            </Typography>
          </>
        ),
      });
    } else if (!patientMiNTIdentifier || !patientData) {
      const confirms = await confirmDialog({
        confirmText: canOpenMintNonExistentPatient ? 'Open' : 'OK',

        title: 'Warning',
        description: (
          <>
            {!!canOpenMintNonExistentPatient && (
              <Typography gutterBottom>Are you sure you want open "Provider Window"?</Typography>
            )}
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              <strong>NOTE:</strong> Patient does not exist in MiNT
            </Typography>

            {!patientData && (
              <>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  Please make sure to fill in this information, so that the patient can be
                  provisioned in MiNT.
                </Typography>
                <Item text="First Name / Last Name" color="success" />
                <Item text="Date of Birth" color="info" />
                <Item text="Gender" color="warning" />
                <Item text="Address (at least one record)" color="error" />
              </>
            )}
          </>
        ),
      });

      if (confirms && canOpenMintNonExistentPatient) {
        handleOpenProviderWindow();
      }
    }
  };

  const handleOpenProviderWindow = async () => {
    const url = await getSmartUrl();
    if (url) {
      window.open(url);
    }
  };

  useEffect(() => {
    console.log('fhirUserId: ', fhirUserId);
  }, [fhirUserId]);

  // const render = readyToBuild ? (
  //   <Button color="inherit" variant="outlined" size="small" onClick={handleVerify}>
  //     Provider Window
  //   </Button>
  // ) : (
  //   <Typography sx={{ mt: 2 }}>Loading...</Typography>
  // );

  return (
    <Button color="inherit" variant="outlined" size="small" onClick={handleVerify}>
      Provider Window
    </Button>
  );
}

export default ProviderWindow;
