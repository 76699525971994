import { Box, Typography, CircularProgress, SxProps } from '@mui/material';
import { spreadSxProp } from 'src/utils/cssStyles';

export interface LoadingComponentProps {
  sx?: SxProps;
}

export const LoadingComponent = ({ sx }: LoadingComponentProps) => (
  <Box
    sx={[
      {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        my: 2,
      },
      ...spreadSxProp(sx),
    ]}
  >
    <CircularProgress size={50} />
  </Box>
);

export interface NoResultTextProps {
  text: string;
}

export const NoResultText = ({ text }: NoResultTextProps) => (
  <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    <Typography variant="body2">{text}</Typography>
  </Box>
);
