import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { devtools, persist } from 'zustand/middleware';
import { usePatient } from 'src/@nicheaim/fhir-react';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';

interface State {
  currentPatientId: string;
  openPatientIds: string[];
}

interface Actions {
  setCurrentPatient(index: string): void;
  openPatient(id: string, options?: { setAsActive: boolean }): void;
  closePatient(id: string): string | undefined;
  closeAllPatients: () => void;
}

const getDefaultState = (): State => ({
  currentPatientId: '',
  openPatientIds: [],
});

const useStore = create(
  persist(
    devtools(
      immer<State & Actions, [['zustand/persist', unknown], ['zustand/devtools', never]]>(
        (set, get) => ({
          ...getDefaultState(),

          setCurrentPatient(id) {
            const { openPatientIds, currentPatientId } = get();
            if (!openPatientIds.length) {
              return;
            }

            if (currentPatientId === id) {
              return;
            }

            const index = openPatientIds.indexOf(id);
            if (index === -1) {
              return;
            }

            set(
              (state) => {
                state.currentPatientId = id;
              },
              false,
              {
                type: 'setCurrentPatient',
                // @ts-ignore as the devtools middleware doesn't support the third argument
                id,
              }
            );
          },

          openPatient(id, { setAsActive } = { setAsActive: true }) {
            if (get().openPatientIds.includes(id)) {
              return;
            }
            set(
              (state) => {
                state.openPatientIds.push(id);

                if (setAsActive) {
                  state.currentPatientId = id;
                }
              },
              false,
              {
                type: 'patient-viewer/openPatient',
                // @ts-ignore as the devtools middleware doesn't support the third argument
                id,
                options: { setAsActive },
              }
            );
          },

          closePatient(id: string) {
            const index = get().openPatientIds.indexOf(id);
            if (index === -1) {
              return;
            }

            set(
              (state) => {
                state.openPatientIds.splice(index, 1);

                // If the current patient is closed, set the next patient as the current patient
                if (index === 0 && state.openPatientIds.length > 0) {
                  state.currentPatientId = state.openPatientIds[0];
                } else if (index === state.openPatientIds.length) {
                  state.currentPatientId = state.openPatientIds[index - 1] || '';
                }
              },
              false,
              {
                type: 'patient-viewer/closePatient',
                // @ts-ignore as the devtools middleware doesn't support the third argument
                id,
              }
            );

            return get().currentPatientId;
          },

          closeAllPatients() {
            set((state) => {
              state.currentPatientId = '';
              state.openPatientIds = [];
            })
          }
        })
      ),
      {
        name: 'patient-tabs',
      }
    ),
    {
      name: 'patient-tabs',
    }
  )
);

export function useCurrentPatient() {
  const currentPatientId = useStore((state) => state.currentPatientId);
  const [patient] = usePatient(currentPatientId, {
    map: PatientWrapper,
  });

  return patient;
}

const usePatientTabsStore = Object.assign(useStore, {
  getDefaultState,
});

// @ts-expect-error
window.debug ??= {};
// @ts-expect-error
window.debug.patientTabsStore = usePatientTabsStore;

export default usePatientTabsStore;
