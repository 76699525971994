import { createWrapper } from '../../fhir-react/base';

export const PlanDefinitionWrapper = createWrapper('PlanDefinition', (planDefinition) => ({
  goalDescription: planDefinition?.goal?.[0]?.description?.text,
  goalCategory:
    planDefinition?.goal?.[0]?.category?.coding?.[0]?.code ??
    planDefinition?.useContext?.[0]?.code?.code ??
    planDefinition?.useContext?.[0]?.valueCodeableConcept?.coding?.[0]?.code,
}));

export type WrappedPlanDefinition = ReturnType<typeof PlanDefinitionWrapper>;
