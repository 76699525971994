import { IconButton, IconButtonProps, SvgIconProps } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

export interface CloseIconButtonProps extends IconButtonProps {
  iconProps?: SvgIconProps;
}

const CloseIconButton = ({ iconProps, ...props }: CloseIconButtonProps) => (
  <IconButton {...props}>
    <CloseIcon {...iconProps} />
  </IconButton>
);

export default CloseIconButton;
