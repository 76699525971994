import { 
  Box, 
  Card, 
  Grid, 
  Stack, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
} from "@mui/material";
import Row from "./Row";
import { isEmpty } from "lodash";
import { TableHeadCustom } from "src/components/table";
import { v4 as uuidv4 } from 'uuid';

type Props = {
  columns?: { id: string, label: string, align: string }[];
  data?: any;
  title?: string;
  page: number;
  count: number;
  rowsPerPage: number;
  workflowType: string;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRefresh: VoidFunction;
};

export default function SummaryTable ({ 
  columns, 
  data, 
  title,
  page,
  count,
  rowsPerPage,
  workflowType,
  onChangePage,
  onChangeRowsPerPage,
  handleRefresh
}: Props ){

  return (
    <Card sx={{ m: 1 }}>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer>
            <Stack direction="row" justifyContent="left" sx={{ m: 2 }}>
          <Typography variant="subtitle1">{title}</Typography>
            </Stack>
            <Table size="small" sx={{ mb: 2 }}>
              <TableHeadCustom headLabel={columns || []} />
              <TableBody>
                {!isEmpty(data) ? (
                  data?.map((row:any, index:number) => (
                    <Row 
                      key={uuidv4()} 
                      row={row} 
                      workflowType={workflowType}
                      handleRefresh={handleRefresh}
                    />
                  ))
                ):(
                  <TableCell colSpan={columns?.length}>
                    <Typography variant="body2" align="center">No rows</Typography>
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page - 1}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
    </Card>
  )
}