// @ts-nocheck
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// @mui
import { Box, Card, Container, Divider, Tab, Tabs, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { OrganizationManager } from 'src/@types/organization';
import api from 'src/services/api';
import OrganizationNewEditForm from 'src/sections/organization/OrganizationNewEditForm';
import { UserTable } from '../dashboard/UserList';

// ----------------------------------------------------------------------

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function OrganizationCreate() {
  const { themeStretch } = useSettings();

  const { pathname } = useLocation();

  const { uuid = '' } = useParams();

  const isEdit = pathname.includes('edit');

  const [currentOrganization, setCurrentOrganization] = useState<OrganizationManager>();
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (e: React.SyntheticEvent, selectedTab: number) => {
    setCurrentTab(selectedTab);
  };

  useEffect(() => {
    (async () => {
      const organization = await api.organizations.getOrganization(uuid);
      setCurrentOrganization(organization);
    })();
  }, []);

  return (
    <Page title="Organization: Create a new organization">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Create a new organization' : 'Edit organization'}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Organization', href: PATH_DASHBOARD.organization.list },
            { name: !isEdit ? 'New organization' : uuid },
          ]}
        />

        <Card>
          <Tabs value={currentTab} onChange={handleTabChange} variant="fullWidth">
            <Tab label="General" />
            <Tab label="Users" />
          </Tabs>

          <Divider />

          <TabPanel value={currentTab} index={0}>
            <OrganizationNewEditForm isEdit={isEdit} currentOrganization={currentOrganization} />
          </TabPanel>

          <TabPanel value={currentTab} index={1}>
            <UserTable organization={currentOrganization} />
          </TabPanel>
        </Card>
      </Container>
    </Page>
  );
}
