import { useContext } from 'react';
import { TenantThemeContext } from 'src/tenantTheme/careflow';

const useTenantTheme = () => {
  const context = useContext(TenantThemeContext);

  return context;
};

export default useTenantTheme;
