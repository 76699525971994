import client from './_client';
import moment from 'moment';
import IntervalDetail from 'src/sections/careflow/rounding/Model/IntervalDetail';

type RoundingPagination = {
  take: number;
  page: number;
};

type getIntervalsPropsType = {
  intervalId?: number | null;
  locationFhirId?: string | null;
  getIntervals?: string | null;
  status?: string | null;
  dateRange?:
    | {
        start: string;
        end: string;
      }
    | undefined;
  pagination?: RoundingPagination;
};

export async function getRoundings({ 
  pagination, locationFhirId, categoryId }:
  {
    pagination?: RoundingPagination,
    locationFhirId?: string | null,
    categoryId: number | null
  }) {
  const { data } = await client.get(
    `/ccm/rounding/list?take=${pagination?.take}&page=${pagination?.page}&locationFhirId=${locationFhirId}&categoryId=${categoryId}`
  );

  console.log({
    roundings: data
  })

  return data;
}

export async function getRoundingTypes() {
  const { data } = await client.get(`/ccm/rounding/rounding-types`);

  return data;
}

export async function getRoundingPatientActivities() {
  const { data } = await client.get(`/ccm/rounding/patient-activities`);

  return data;
}

export async function getRoundingPatientActivityLocations() {
  const { data } = await client.get(`/ccm/rounding/patient-activity-locations`);

  return data;
}

type CompleteIntervalDetailDataType = {
  patientActivityId: number;
  patientLocationId: number;
};

export async function completeIntervalDetail(
  id: number,
  { patientActivityId, patientLocationId }: CompleteIntervalDetailDataType
) {
  const { data } = await client.post(`/ccm/rounding/complete-interval-detail/${id}`, {
    patientActivityId,
    patientLocationId,
  });

  return data;
}

export async function getIntervals({
  intervalId,
  locationFhirId,
  status,
  dateRange,
  pagination,
}: getIntervalsPropsType) {
  let url = `/ccm/rounding/intervals/list?take=${pagination?.take}&page=${pagination?.page}`;

  if (status) {
    url = `${url}&status=${status}`;
  }

  if (intervalId) {
    url = `${url}&intervalId=${intervalId}`;
  }

  if (locationFhirId) {
    url = `${url}&locationFhirId=${locationFhirId}`;
  }

  if (dateRange) {
    const start = moment(dateRange.start).startOf('day').toISOString();
    const end = moment(dateRange.end).endOf('day').toISOString();
    url = `${url}&start=${start}&end=${end}`;
  }

  const { data } = await client.get(url);

  return data;
}

export async function getIntervalDetails({
  intervalId,
  pagination,
}: {
  intervalId: number;
  pagination: RoundingPagination;
}): Promise<{ intervalDetails: IntervalDetail[]; totalCount: number }> {
  let url = `/ccm/rounding/intervals/${intervalId}/details?take=${pagination?.take}&page=${pagination?.page}`;

  const { data } = await client.get(url);

  return data;
}

export async function editBedPatient(
  bedId: number,
  patientId: number,
  start: string,
  end: string | null,
  note: string | null,
  exitDestinationId: number,
) {
  const url = `ccm/rounding/update-bed-assignment`;
  const { data } = await client.post(url, {
    bedId,
    patientId,
    start,
    end,
    note,
    exitDestinationId,
  });

  return data;
}
