import { Box, Card, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useState } from 'react';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { PatientProvider, usePatient, usePatients } from 'src/@nicheaim/fhir-react';

export default function PatientsContextExample() {
  const [selectedPatient, setSelectedPatient] = useState('');
  const [patients] = usePatients();
  const [patient] = usePatient(selectedPatient, {
    map: PatientWrapper,
  });

  return (
    <Box p={3}>
      <Typography>Patients (with Context)</Typography>

      <InputLabel id="patient-selector">Patient</InputLabel>
      <Select
        labelId="patient-selector"
        label="Please a patient"
        value={selectedPatient}
        onChange={(ev) => setSelectedPatient(ev.target.value)}
      >
        {patients.map((patient) => (
          <MenuItem key={patient.id} value={patient.id}>
            {patient?.name?.[0].given?.[0]} {patient?.name?.[0].family}
          </MenuItem>
        ))}
      </Select>

      <PatientProvider id={patient?.id}>
        <PatientDetails />
      </PatientProvider>
    </Box>
  );
}

function PatientDetails() {
  return (
    <Card>
      <Typography>Patient Details</Typography>
    </Card>
  );
}
