import { useContext } from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import {
  quickActions as quickActionsItems,
  QuickActionType,
  QuickActionItemType,
} from "./constants";
import styles from './styles';
import { ActivitiesContext } from "../ActivitiesContext";
import { checkAclValidation } from "src/utils/permissions/permission.utils";
import useAuth from "src/hooks/useAuth";

type QuickActionsProps = {
  summaries: {
    activitiesSummary: any,
    referralsSummary: any;
    patientsSummary: any;
  }
}

export const QuickActions = ({
  summaries,
}: QuickActionsProps) => {
  const user = useAuth();
  const activitiesContext = useContext(ActivitiesContext);
  const quickActionItems = quickActionsItems({
    activities: summaries.activitiesSummary,
    referrals: summaries.referralsSummary,
    patients: summaries.patientsSummary,
  }).filter((item) => checkAclValidation({
    acl: item.permissions,
    user,
  }));
  return (
    <Grid item xs={12}>
      <Grid container>
        {quickActionItems.map((quickAction: QuickActionType) => (
          <Grid
            key={quickAction.id}
            item
            xs={12}
            md={6}
            lg={12}
            xl={3}
            style={{ padding: 5, opacity: quickAction.disabled ? 0.5 : 1 }}
          >
            <Grid item xs={12} style={styles.quickActionBox}>
              <Typography variant="h6" style={{ paddingBottom: '20px' }}>{quickAction.title}</Typography>
              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="center"
              >
                {quickAction.actions.map((action: QuickActionItemType, index: number) => (
                  <Grid
                    item
                    xs={4}
                    key={index}
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => !quickAction.disabled && action.onPress((value: any) => activitiesContext.setSelectedEntityAndState(value))}
                  >
                    <Grid
                      item
                      sx={[
                        styles.quickActionBoxCounterContainer,
                        {
                          backgroundColor: action.color,
                          ...(index === 0 ? { marginLeft: '18px' } : {}),
                        }
                      ]}
                    >
                      <Typography
                        style={{
                          color: '#dfe3e8',
                          textAlign: 'center',
                          fontSize: '28px',
                          fontWeight: 'bold',
                        }}
                      >{action.count}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{
                          marginTop: '10px',
                          color: '#637381',
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 'bold'
                        }}
                      >{action.title}</Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
};
