import { Box, TextField } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { SingleValue } from 'react-select';
import { QuestionnaireWrapper } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import { useLists } from 'src/@nicheaim/fhir-react';
import { CreatableSelect, Select } from 'src/components/Select';
import fhirSystem from 'src/fhir/system';
import useCarePlanAutomationFormStore, { useCarePlanAutomationFormError } from './store';
import { useAllAssessments } from 'src/fhir/hooks/assessments';

function AutomationSheetSelector() {
  const params = useParams();
  const assessmentId = useCarePlanAutomationFormStore((s) => s.selectedAssessmentId);
  const automationSheetId = useCarePlanAutomationFormStore((s) => s.selectedAutomationSheetId);
  const description = useCarePlanAutomationFormStore((s) => s.description);
  const setDescription = useCarePlanAutomationFormStore((s) => s.setDescription);
  const reset = useCarePlanAutomationFormStore((s) => s.reset);
  const selectAssessment = useCarePlanAutomationFormStore((s) => s.setSelectedAssessment);
  const loadAutomationSheet = useCarePlanAutomationFormStore((s) => s.loadAutomationSheet);
  const isLoadingAutomationSheet = useCarePlanAutomationFormStore((s) => s.loading);
  const assessmentError = useCarePlanAutomationFormError('assessment');
  const automationSheetError = useCarePlanAutomationFormError('automationSheet');

  const [assessments, { isLoading: isLoadingAssessments }] = useAllAssessments({
    filter: {
      status: 'active,draft',
    },
    map: QuestionnaireWrapper,
  });
  const [automationSheets, { create, isLoading: isLoadingAutomationSheets }] = useLists({
    filter: {
      identifier: assessmentId
        ? fhirSystem.automation.carePlan.withId(assessmentId).forCode('')
        : fhirSystem.automation.carePlan.forCode(''),
    },
  });
  const [isCreatingAutomationSheet, setIsCreatingAutomationSheet] = useState(false);

  const assessment = assessments.find((a) => a.id === assessmentId) || null;
  const automationSheet = automationSheets.find((sheet) => sheet.id === automationSheetId) || null;

  // reset automation sheet if assessment changes
  function handleSelectAssessment(assessment: SingleValue<{ value?: string; label?: string }>) {
    if (!assessment) {
      reset();
      return;
    }

    selectAssessment(assessment.value || '');
  }

  // if there is no assessment selected, try to infer it from the automation sheet
  const handleSelectAutomationSheet = useCallback(
    function (
      option: SingleValue<{
        value?: string;
        label?: string;
      }>
    ) {
      loadAutomationSheet(option?.value || '');
    },
    [loadAutomationSheet]
  );

  async function handleCreateAutomationSheet(name: string) {
    setIsCreatingAutomationSheet(true);
    const newAutomationSheetId = nanoid();

    const [createdAutomationSheet] = await create({
      resourceType: 'List',
      mode: 'working',
      status: 'current',
      title: name,
      date: new Date().toISOString(),
      identifier: [
        // Link this list to the selected assessment
        {
          system: fhirSystem.automation.carePlan.withId(assessmentId).asString(),
          use: 'official',
          value: newAutomationSheetId,
        },

        // This list is for care plan automation
        {
          system: fhirSystem.automation.carePlan.asString(),
          use: 'secondary',
          value: newAutomationSheetId,
        },
      ],
      note: [
        {
          text: description,
        },
      ],
    });
    setIsCreatingAutomationSheet(false);

    if (!createdAutomationSheet || !createdAutomationSheet.id) {
      throw new Error('Error creating automation sheet');
    }

    loadAutomationSheet(createdAutomationSheet.id);
  }

  useEffect(() => {
    if (!params.id || !assessments.length) return;

    handleSelectAutomationSheet({
      value: params.id,
    });
    // navigate('../new');
  }, [params.id, assessments, handleSelectAutomationSheet]);

  // User should not be able to create a new automation sheet if there is no assessment selected
  const AutomationSheetSelectComponent = assessmentId ? CreatableSelect : Select;

  return (
    <Box display="grid" gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }} gap={3}>
      <Select
        isClearable
        isDisabled={isLoadingAssessments}
        isLoading={isLoadingAssessments}
        placeholder="Assessment"
        error={assessmentError}
        value={
          assessment && {
            value: assessmentId,
            label: assessment?.title,
          }
        }
        options={assessments.map((questionnaire) => ({
          value: questionnaire.id!,
          label: questionnaire.title,
        }))}
        onChange={handleSelectAssessment}
      />

      <AutomationSheetSelectComponent
        isClearable
        error={automationSheetError}
        isDisabled={isLoadingAutomationSheets}
        isLoading={
          isCreatingAutomationSheet || isLoadingAutomationSheets || isLoadingAutomationSheet
        }
        placeholder={
          'Automation Sheet' + (assessmentId ? ' (Start typing to create a new one)' : '')
        }
        value={
          automationSheet && {
            value: automationSheet.id,
            label: automationSheet.title,
          }
        }
        options={automationSheets.map((automationSheet) => ({
          value: automationSheet.id,
          label: automationSheet.title,
        }))}
        onChange={handleSelectAutomationSheet}
        onCreateOption={handleCreateAutomationSheet}
      />

      <TextField
        placeholder="Description"
        multiline
        sx={{ gridColumn: 'span 2' }}
        value={description || ''}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />
    </Box>
  );
}

export default AutomationSheetSelector;
