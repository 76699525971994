export class SealedFhirSystem {
  constructor(protected system: string) {
    this.toString = function () {
      return this.asString();
    };
  }

  forCode(code: string = '') {
    return `${this.system}|${code}`;
  }

  asString(): string {
    return this.system;
  }

  withId(id: string) {
    return new FhirSystem(`${this.system}/${id}`);
  }
}

export class FhirSystem extends SealedFhirSystem {
  withSubsystem<Name extends string, FhirSubsystem extends SealedFhirSystem = FhirSystem>(
    name: Name,
    creator?: (subsystem: FhirSystem) => FhirSubsystem
  ): this & { [key in Name]: FhirSubsystem };
  withSubsystem<Name extends string, FhirSubsystem extends SealedFhirSystem = FhirSystem>(
    name: Name,
    subname: string,
    creator?: (subsystem: FhirSystem) => FhirSubsystem
  ): this & { [key in Name]: FhirSubsystem };
  withSubsystem<Name extends string, FhirSubsystem extends SealedFhirSystem = FhirSystem>(
    name: Name,
    subname?: string | ((subsystem: FhirSystem) => FhirSubsystem),
    creator?: (subsystem: FhirSystem) => FhirSubsystem
  ): this & { [key in Name]: FhirSubsystem } {
    const subsystem =
      typeof subname === 'string'
        ? new FhirSystem(`${this.system}/${subname || name}`)
        : new FhirSystem(`${this.system}/${name}`);

    Object.assign(this, {
      [name]: creator
        ? creator(subsystem)
        : typeof subname === 'function'
        ? subname(subsystem)
        : subsystem,
    });

    return this as this & { [key in Name]: FhirSubsystem };
  }

  seal() {
    const sealed = new SealedFhirSystem(this.system);
    Object.assign(sealed, this);
    return sealed as SealedFhirSystem & Omit<this, keyof FhirSystem>;
  }
}
