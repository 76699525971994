import { Button, Card, Grid, Stack, Typography } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { useEffect, useState } from 'react';
import NoteAddForm from '../../common/NoteAddForm';
import { SummaryTable } from 'src/sections/crs/common';
import { referralService } from 'src/crs/referral/services';
import { DocumentList } from './patientDocuments/DocumentList';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { TABLE_HEAD_ASSESSMENTS, TABLE_HEAD_NOTES } from '../../common/table-head';
import { useCommunications, useDocumentReferences } from 'src/@nicheaim/fhir-react';
import { AssessmentsFormsResponse } from 'src/crs/referral/services/ReferralService';
import { CommunicationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Communication';
import { mapAssessmentsForms, mapCommunicationsToNotesDisplay } from '../../common/common-utils';
import {
  AddNotes,
  CRS_ACLS,
  checkAclValidation,
  getRelatedAcls,
} from 'src/utils/permissions/permission.utils';
import StartAssessmentsButton from './StartAssessmentsButton';

type Props = {
  patient: WrappedPatient | null;
};

export default function PatientDocumentSummary({ patient }: Props) {
  const user = useAuth();
  const [communications, { create: createCommunication, refresh: refreshCommunications }] =
    useCommunications({ filter: { subject: patient?.id }, map: CommunicationWrapper });
  const [documentReferences, { refresh: refreshDocumentReference }] = useDocumentReferences({
    filter: { subject: patient?.id },
  });

  const [assessmentsForms, setAssessmentsForms] = useState<AssessmentsFormsResponse[]>([]);
  const [openNote, setOpenNote] = useState(false);

  const handleCloseAddNote = () => {
    setOpenNote(false);
  };

  const handleCreateCommunicationFhir = async (data: any) => {
    let result: any = {};
    try {
      result = await createCommunication(data);
      handleCloseAddNote();
      refreshCommunications();
    } catch (err) {
      console.log('error on handleCreateCommunicationFhir', err);
    }

    return result;
  };

  const fetchAssessments = async () => {
    const result: any = await referralService.referralApiClient.assessmentsByPatient(patient?.id);
    setAssessmentsForms(mapAssessmentsForms(result));
  };

  useEffect(() => {
    fetchAssessments();
  }, [patient]);

  return (
    <Card sx={{ py: 2, boxShadow: 'none' }}>
      <Card sx={{ m: 1 }}>
        <Stack direction="row" sx={{ m: 2 }} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="subtitle1">Assessments / Forms</Typography>
          {checkAclValidation({
            user,
            acl: getRelatedAcls(CRS_ACLS.PATIENT.ASSESSMENTS.START),
          }) && (
            <StartAssessmentsButton
              keyRegistry="crs-patient-assessments"
              patientId={patient?.id ?? ''}
            />
          )}
        </Stack>
        <SummaryTable header={TABLE_HEAD_ASSESSMENTS} data={assessmentsForms} />
      </Card>
      <Card sx={{ m: 1 }}>
        <DocumentList
          patient={patient}
          documentReferences={documentReferences}
          showFilters={true}
          refreshDocumentReference={refreshDocumentReference}
          openCollapseExternal={true}
          handleUpdateResource={null}
        />
      </Card>

      <Card sx={{ m: 1 }}>
        <Stack sx={{ m: 2 }}>
          <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
            <Grid item xs={10}>
              <Typography variant="subtitle1">Notes</Typography>
            </Grid>
            {checkAclValidation({ user, acl: getRelatedAcls(AddNotes) }) && (
              <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
                <Button size="small" sx={{ height: '36px' }} onClick={() => setOpenNote(true)}>
                  Add A Note
                </Button>
              </Grid>
            )}
          </Grid>
        </Stack>
        <SummaryTable
          header={TABLE_HEAD_NOTES}
          data={mapCommunicationsToNotesDisplay(communications, [])}
        />
      </Card>

      <NoteAddForm
        open={openNote}
        patient={patient}
        resource={[]}
        onCancel={handleCloseAddNote}
        handleCreate={handleCreateCommunicationFhir}
        typeNote="notes_patient"
      />
    </Card>
  );
}
