import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

type PromptModalPropsType = {
  title?: string;
  subtitle?: string;
  leftButtonTitle?: string;
  rightButtonTitle?: string;
  onContinuePress: () => void;
  onCancelPress: () => void;
  open: boolean;
  disableButtons: boolean;
}

const PromptModal = ({
  title,
  subtitle,
  leftButtonTitle,
  rightButtonTitle,
  onContinuePress = () => {},
  onCancelPress = () => {},
  open = false,
  disableButtons = false
}: PromptModalPropsType) => (
  <Dialog
    open={open}
    onClose={onCancelPress}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="delete-note-modal-title">
      {title ?? "Are you sure you want to delete this record?"}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="delete-note-modal-description">
       {subtitle ?? "This action cannot be undone."}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button disabled={disableButtons} onClick={onCancelPress}>{leftButtonTitle ?? "No, go back"}</Button>
      <Button disabled={disableButtons} onClick={onContinuePress}>
        {rightButtonTitle ?? "Yes, delete"}
      </Button>
    </DialogActions>
  </Dialog>
)

export default PromptModal;
