import { Stack, Typography } from '@mui/material';
import moment from 'moment';

interface CreatedUpdatedProps {
  by?: string;
  date?: string;
}

export function CreatedUpdated({ by, date }: CreatedUpdatedProps) {
  return (
    <Stack>
      <Stack direction="row" alignItems="baseline">
        <Typography variant="caption" color="GrayText" sx={{ pr: 1 }}>
          B:
        </Typography>
        <Typography variant="body2">{by ?? 'N/A'}</Typography>
      </Stack>
      <Stack direction="row" alignItems="baseline">
        <Typography variant="caption" color="GrayText" sx={{ pr: 1 }}>
          D:
        </Typography>
        <Typography variant="body2">{date ? moment(date).format('MMM D, YYYY') : 'N/A'}</Typography>
      </Stack>
    </Stack>
  );
}
