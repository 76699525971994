import * as Yup from 'yup';
import produce from 'immer';
import { isEmpty } from 'lodash';
import uuidv4 from "src/utils/uuidv4";
import { useSnackbar } from "notistack";
import { camelCase } from "change-case";
import InputMask from 'react-input-mask';
import { Add } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from "react-hook-form";
import { useJsApiLoader } from "@react-google-maps/api";
import { usePatient, useValueSet } from "src/@nicheaim/fhir-react";
import { ValueSetWrapper } from "src/@nicheaim/fhir-base/wrappers/ValueSet";
import { FormProvider, RHFSelect, RHFTextField } from "src/components/hook-form";
import { PatientWrapper, WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import AddAddress from "src/sections/crs/referral/components/workflow-step/AddressChecklistItem/AddAddress";
import { Button, Card, Dialog, DialogTitle, Grid, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { PatientContact } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
  
type FormValue = {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  gender: string;
  emailUse: string;
  email: string;
  phoneUse: string;
  street: string;
  street2: string;
  city: string;
  district: string;
  phone: string;
  state: string;
  zipCode: string;
};
    
type EditModeProps = {
  patient: WrappedPatient | null;
  contact: PatientContact | null;
  open: boolean;
  onClose: VoidFunction;
};
    
export default function RelatedPersonAction ({ patient, contact, open, onClose }: EditModeProps) {
  
  const { enqueueSnackbar } = useSnackbar();
  const [ openAddAddress, setOpenAddAddress ] = useState(false);
  const [ address, setAddress ] = useState({});

  const [genderCategories] = useValueSet('us-core-birthsex', { map: ValueSetWrapper });
  const [, { update }] = usePatient(patient!.id!, {
    map: PatientWrapper,
  });

  const EventSchema =  Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Email must be a valid email address'),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [contact]);

  const JsOptions = useMemo<any>(() => ({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries: ['places'],
  }), []);
  const { isLoaded } = useJsApiLoader(JsOptions);

  const defaultValues = useMemo(
    () =>
    ({
      id: contact?.id || '',
      lastName: contact?.name?.family || '',
      firstName: contact?.name?.given?.[0] || '',
      middleName: contact?.name?.given?.[1] || '',
      gender: contact?.gender || '',
      emailUse: contact?.telecom?.filter(e => e.system === "email")?.[0].use || '',
      email: contact?.telecom?.filter(e => e.system === "email")?.[0].value || '',
      phoneUse: contact?.telecom?.filter(e => e.system === "phone")?.[0].use || '',
      phone: contact?.telecom?.filter(e => e.system === "phone")?.[0].value || '',
      street: contact?.address?.line?.[0] || '',
      street2: contact?.address?.line?.[1] || '',
      city: contact?.address?.city || '',
      district: contact?.address?.district || '',
      state: contact?.address?.state || '',
      zipCode: contact?.address?.postalCode || '',
    } as FormValue),
    [contact]
  );

  const methods = useForm({ resolver: yupResolver(EventSchema), defaultValues });

  const { reset, control, handleSubmit, setValue } = methods;

  const saveChanges = async (data: any) => {
    setValue('street', data.line[0]);
    setValue('street2', data.line[1]);
    setValue('city', data.city);
    setValue('district', data.district);
    setValue('zipCode', data.postalCode);
    setValue('state', data.state);
    setAddress(data);
    setOpenAddAddress(false);
  };

  const onSubmit = async (data: FormValue) => {
    try {
      
      const email: any = {
        rank: 1,
        use: data?.emailUse,
        system: 'email',
        value: data?.email,
      };

      const phone: any = {
        rank: 1,
        use: data?.phoneUse,
        system: 'phone',
        value: data?.phone,
      };

      const gender: any = data?.gender;

      const getContact = patient?.contact?.find((c: any) => c.id === data?.id);
      const getAddress = !isEmpty(address) ? address : getContact?.address;

      await update(
        produce(patient!, (draft) => {
          draft.contact = [
            ...(draft.contact?.filter((c: any) => c.id !== data?.id) || []),
            {
              id: data?.id || uuidv4(),
              name: {
                family: data?.lastName,
                given: [data?.firstName, data?.middleName],
              },
              telecom: [ phone, email ],
              address: getAddress,
              gender: gender
            },
          ];
        })
      );

      setAddress({});

      onClose();
      enqueueSnackbar(`Related Person was ${data?.id ? 'updated': 'created'}.`);
    } catch {
      onClose();
      enqueueSnackbar(`Related Person was not ${data?.id ? 'updated': 'created'}.`, { variant: 'error' });
    }
  };
  if (!open) return null;

  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth="md">
        <DialogTitle>Member Relationship</DialogTitle>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} sx={{ p: 2}}>
            <Grid item xs={12}>
              <Card sx={{ p: 2}}>
                <Typography style={{ display: "flex", justifyContent: "space-between" }} variant="button" sx={{ textTransform: 'uppercase' }}>
                  Personal Details
                </Typography>
                <Grid container spacing={2} sx={{ pt: 1 }}>
                  <Grid item xs={4}>
                    <RHFTextField
                      name="firstName"
                      label="First Name" 
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RHFTextField 
                      name="middleName"
                      label="Middle Name"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RHFTextField 
                      name="lastName"
                      label="Last Name" 
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RHFSelect name="gender" label="Gender" fullWidth={true}>
                      <MenuItem disabled></MenuItem>
                      {genderCategories?.asList().map((option) => (
                        <MenuItem key={option.code} value={camelCase(option.display || '')}>
                          {option.display}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ p: 2}}>
                <Typography style={{ display: "flex", justifyContent: "space-between" }} variant="button" sx={{ textTransform: 'uppercase' }}>
                  Contact Details
                </Typography>
                <Grid container spacing={2} sx={{ pt: 1 }}>
                  <Grid item xs={4}>
                    <RHFSelect
                      name="emailUse"
                      label="Email Use"
                      fullWidth
                    >
                      <MenuItem value="home">Home</MenuItem>
                      <MenuItem value="work">Work</MenuItem>
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={8}>
                    <RHFTextField 
                      name="email"
                      label="Email" 
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RHFSelect
                      name="phoneUse"
                      label="Phone Use"
                      fullWidth
                    >
                      <MenuItem value="home">Home</MenuItem>
                      <MenuItem value="work">Work</MenuItem>
                      <MenuItem value="mobile">Mobile</MenuItem>
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <InputMask mask="(999) 999-9999"  value={value} onChange={onChange}>
                          {(inputProps: any) => (
                            <TextField
                              label="Phone"
                              {...inputProps}
                              fullWidth
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        </InputMask>
                      )}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ p: 2}}>
                <Typography style={{ display: "flex", justifyContent: "space-between" }} variant="button" sx={{ textTransform: 'uppercase' }}>
                  Address Details
                  <Button color="primary" startIcon={<Add />} onClick={() => setOpenAddAddress(true)}>
                    Add
                  </Button>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <RHFTextField 
                      name="street"
                      label="Street" 
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RHFTextField 
                      name="street2"
                      label="Street 2" 
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                      <RHFTextField  
                        name="city"
                        label="City" 
                        fullWidth
                        disabled
                      />
                      <RHFTextField 
                        name="district"
                        label="County" 
                        fullWidth
                        disabled
                      />
                      <RHFTextField 
                        name="state"
                        label="State" 
                        fullWidth
                        disabled
                      />
                      <RHFTextField 
                        name="zipCode"
                        label="ZIP Code" 
                        fullWidth
                        disabled
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} justifyContent="center" sx={{ pb: 2 }} >
            <Button color="primary" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" type='submit'>
              Save
            </Button>
          </Stack>
        </FormProvider>
      </Dialog>
      <Dialog open={openAddAddress} maxWidth="md">
        <DialogTitle> Add Address</DialogTitle>
        <Card sx={{ p: 2, mt:2 }}>
          <AddAddress
            handleClose={() => setOpenAddAddress(false)}
            handleSave={saveChanges} 
          />
        </Card>
      </Dialog>
    </>
  );
};