export type TableHead = {
  id: string;
  label: string;
  align: string;
};

export const TABLE_HEAD_NOTES = [
  { id: 'topic', label: 'Topic', align: 'center' },
  { id: 'author', label: 'Author', align: 'left' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'description', label: 'Description', align: 'left' },
  { id: 'date', label: 'Date', align: 'center' },
];

export const TABLE_HEAD_OBSERVATION = [
  { id: 'code', label: 'Code', align: 'left' },
  { id: 'issued', label: 'Issued', align: 'left' },
  { id: 'value', label: 'Value', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'actions', label: 'Actions', align: 'center' },
];

export const TABLE_HEAD_ASSESSMENTS = [
  { id: 'surveyId', label: 'Survey Id', align: 'center' },
  { id: 'responseId', label: 'Response Id', align: 'left' },
  { id: 'language', label: 'Language', align: 'left' },
  { id: 'type', label: 'Type', align: 'center' },
  { id: 'submitDate', label: 'Submit Date', align: 'center' },
  { id: 'previewassessments', label: 'Preview', align: 'center' },
];

export const TABLE_HEAD_DOCUMENTS_PATIENT = [
  { id: 'preview', label: 'Preview', align: 'left' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'author', label: 'Author', align: 'left' },
  { id: 'custodian', label: 'Custodian', align: 'left' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'category', label: 'Category', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'actions', label: 'Actions', align: 'center' },
];

export const TABLE_HEAD_ATTACH = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'author', label: 'Author', align: 'left' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'mimeType', label: 'Mime Type', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'preview', label: 'Preview', align: 'center' },
];

export const STATUS_OPTION_IMMUNIZATION = [
  { label: 'In Progress', value: 'not-done' },
  { label: 'Completed', value: 'completed' },
  { label: 'Canceled', value: 'entered-in-error' },
  { label: 'In Progress', value: 'in-progress' },
];

export const TABLE_HEAD_IMMUNIZATIONS = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'doses', label: 'Doses', align: 'left' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'occurrence', label: 'Occurrence', align: 'left' },
  { id: 'lotNumber', label: 'Lot Number', align: 'left' },
  { id: 'vaccineCode', label: 'Vaccine Code', align: 'left' },
];

export const TABLE_HEAD_CASES = [
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'assignedTo', label: 'Assigned To', align: 'left' },
  { id: 'scope', label: 'Scope', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
];

export const TABLE_HEAD_CONTACT = [
  { id: 'when', label: 'When', align: 'center' },
  { id: 'outcome', label: 'Outcome', align: 'left' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'nextContact', label: 'Next Contact', align: 'left' },
  { id: 'notes', label: 'Notes', align: 'center' },
];

export const TABLE_HEAD_RELATED_PERSON = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'contact', label: 'Contact', align: 'left' },
  { id: 'actions', label: 'Actions', align: 'center' },
];

export const TABLE_HEAD_CONSENT_FORM = [
  { id: 'medium', label: 'Medium', align: 'center' },
  { id: 'author', label: 'Author', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
];

export const TABLE_HEAD_DISPOSITION_REFERRAL_CASE = [
  { id: 'caseNumber', label: 'Case Number', align: 'left' },
  { id: 'created', label: 'Created', align: 'left' },
  { id: 'assignedTo', label: 'Assigned To', align: 'left' },
  { id: '', label: '', align: 'center' },
];

export const TABLE_HEAD_CHILD_REFERRAL = [
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'assign', label: 'Assigned To', align: 'left' },
  { id: 'created', label: 'Created On', align: 'left' },
  { id: 'service', label: 'Service', align: 'left' },
  { id: 'referred', label: 'Referred From', align: 'left' },
  { id: 'performer', label: 'Referred To', align: 'left' },
  { id: 'location', label: 'Location', align: 'left' },
  { id: '', label: '', align: 'center' },
];

export const TABLE_HEAD_CHILD_REFERRAL_SEARCH_SERVICE = [
  { id: 'service', label: 'Service', align: 'left' },
  { id: 'organization', label: 'Organization', align: 'left' },
  { id: 'location', label: 'Location', align: 'left' },
  { id: '', label: '', align: 'center' },
];

export const TABLE_HEAD_PATIENT_LIST = [
  { id: 'mrn', label: 'MRN', align: 'left' },
  { id: 'referral', label: 'Patient', align: 'left' },
  { id: 'birthDate', label: 'Date of Birth', align: 'left' },
  { id: 'demographics', label: 'Demographics', align: 'left' },
  { id: 'registrationDate', label: 'Registration Date', align: 'left' },
  /* { id: 'status', label: 'Status', align: 'left' }, */
  { id: '', label: '' },
];

export const TABLE_HEAD_REFERRAL_LIST = [
  { id: 'internalNumber', label: 'Referral No.', align: 'left' },
  { id: 'referral', label: 'Patient', align: 'left' },
  { id: 'demographics', label: 'Demographics', align: 'left' },
  { id: 'referringProvider', label: 'Referring Provider', align: 'left' },
  { id: 'requesterOrganization', label: 'Requester Organization', align: 'left' },
  { id: 'registrationDate', label: 'Registration Date', align: 'left' },
  { id: 'step', label: 'Step', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: '', label: '' },
];

export const TABLE_HEAD_CASES_LIST = [
  { id: 'internalNumber', label: 'Case No.', align: 'left' },
  { id: 'patient', label: 'Patient', align: 'left' },
  { id: 'assignmentType', label: 'Assignment Type', align: 'left' },
  { id: 'scope', label: 'Scope', align: 'left' },
  { id: 'step', label: 'Step', align: 'left' },
  { id: 'date', label: 'Registration Date', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
];

export const TABLE_HEAD_REFERRAL_PATIENT = [
  { id: 'number', label: 'Number', align: 'left' },
  { id: 'created', label: 'Created', align: 'left' },
  { id: 'birthDate', label: 'Age', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'workflowStatus', label: 'Workflow Status', align: 'left' },
  { id: 'owner', label: 'Owner', align: 'left' },
  { id: 'scope', label: 'Scope', align: 'left' },
  { id: 'step', label: 'Step', align: 'left' },
  { id: 'menu', label: '', align: 'left' },
];

export const TABLE_HEAD_CASE_PATIENT = [
  { id: 'number', label: 'Number', align: 'left' },
  { id: 'created', label: 'Created', align: 'left' },
  { id: 'birthDate', label: 'Age', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'workflowStatus', label: 'Workflow Status', align: 'left' },
  { id: 'owner', label: 'Owner', align: 'left' },
  { id: 'scope', label: 'Scope', align: 'left' },
  { id: 'step', label: 'Step', align: 'left' },
  { id: 'menu', label: '', align: 'left' },
];

export const TABLE_HEAD_APPOINTMENT = [
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'description', label: 'Description', align: 'left' },
  { id: 'start', label: 'Start', align: 'left' },
  { id: 'end', label: 'End', align: 'left' },
  { id: 'participants', label: 'Participants', align: 'left' },
  { id: 'created', label: 'Created', align: 'left' },
  { id: '', label: '', align: 'left' },
];

export const TABLE_HEAD_APPOINTMENT_PARTICIPANT = [
  { id: 'participant', label: 'Participant', align: 'left' },
  { id: 'required', label: 'Required', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: '', label: '', align: 'left' },
];