import { createContext } from 'react';

export type ActivitiesContextType = {
  setSelectedEntity: (selectedActivity: any) => void;
  acceptActivity: (activity: any) => void;
  startActivity: (activity: any) => void;
  cancelActivity: (activity: any) => void;
  setSelectedEntityAndState: (value: any) => void;
  openAddEntityModal: (activity?: any | null | undefined) => void;
  toggleAddCommunicationModal: (entity?: any | null | undefined) => void;
  onCreateOrUpdateEntity: (entity: any) => void;
  onCreateOrUpdateMultipleActivities: (activities: any[]) => void;
  showCalendarForPatient: (patient: any) => void;
  scheduleActivityForPatient: (patient: any, isCompleted?: boolean) => void;
};

export const ActivitiesContext = createContext<ActivitiesContextType>({
  setSelectedEntity: (selectedActivity: any) => {},
  acceptActivity: (activity: any) => {},
  startActivity: (activity: any) => {},
  cancelActivity: (activity: any) => {},
  setSelectedEntityAndState: (value: any) => {},
  openAddEntityModal: (activity?: any | null | undefined) => {},
  toggleAddCommunicationModal: (entity?: any | null | undefined) => {},
  onCreateOrUpdateEntity: (entity: any) => {},
  onCreateOrUpdateMultipleActivities: (activities: any[]) => {},
  showCalendarForPatient: (patient: any) => {},
  scheduleActivityForPatient: (patient: any, isCompleted?: boolean) => {},
});

export const ActivitiesProvider = ActivitiesContext.Provider;
