import * as Yup from 'yup';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Link as RouterLink,
  useLoaderData,
  useNavigate,
  useRouteLoaderData,
} from 'react-router-dom';
// form
import { UseFormSetValue, UseFormWatch, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Stack,
  Button,
  Divider,
  Typography,
  IconButton,
  Switch,
  CircularProgress,
} from '@mui/material';
// components
import { FormProvider, RHFTextField } from 'src/components/hook-form';
import {
  useActivityDefinitions,
  useCodeSystem,
  useHealthcareServices,
  useLists,
  useOrganizations,
  useQuestionnaires,
} from 'src/@nicheaim/fhir-react';
import Iconify from 'src/components/Iconify';
import uuidv4 from 'src/utils/uuidv4';
import fhirSystem from 'src/fhir/system';
import RHFAutocomplete from 'src/components/hook-form/RHFAutocomplete';
import { Organization } from 'src/@nicheaim/fhir-base/mappings/Organization';
import { HealthcareService } from 'src/@nicheaim/fhir-base/mappings/HealthcareService';
import {
  ActivityDefinition,
  CodeSystemConcept,
  Questionnaire,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import fhirDuration from 'src/fhir/codes/duration';
import { useActiveActivityTypes } from 'src/fhir/hooks/activity-types';
import { useActiveActivityDefinitions } from 'src/fhir/hooks/activities';
import { useAllAssessments } from 'src/fhir/hooks/assessments';

// ----------------------------------------------------------------------

type FormValue = {
  plan: Organization | null;
  lob: Organization | null;
  program: HealthcareService | null;
  anchorEvent: {
    id: string;
    display: string;
  } | null;
  rules: Record<string, ActivityAutomationRule>;
};

interface ActivityAutomationRule {
  id: string;
  activityType: CodeSystemConcept;
  activity: ActivityDefinition;
  dst?: Questionnaire | null;
  contactType: CodeSystemConcept;
  timeframe: {
    value: string;
    unit: {
      value: string;
      label: string;
    };
  };
}
export type NewAutomationFormProps = {
  isEdit?: boolean;
  currentAutomation?: {
    id: string;
    plan: Organization;
    lob: Organization;
    program: HealthcareService;
    anchorEvent: string;
  } | null;
};

const anchorEvents = [
  {
    id: 'program-enrollment',
    display: 'Program Enrollment',
  },
  {
    id: 'staff-assignment',
    display: 'Staff Assignment',
  },
];

export default function NewAutomationForm({ isEdit, currentAutomation }: NewAutomationFormProps) {
  const [rulesLoading, setRulesLoaded] = useState(false);
  const navigate = useNavigate();
  const [activityIds, setActivityIds] = useState<string[]>([]);
  const [activityTypes, { isLoading: isLoadingActivityTypes }] = useActiveActivityTypes();
  const [contactTypes, {}] = useCodeSystem('ccm-activity-contact-types');
  const [
    activities,
    {
      isLoading: isLoadingActivityDefinitions,
      update: updateActivityDefinitions,
      get: getActivityDefinition,
    },
  ] = useActiveActivityDefinitions({
    pagination: {
      pageSize: 1000,
    },
  });

  const [assessments] = useAllAssessments({
    filter: {
      status: 'active,draft',
    },
    pagination: {
      pageSize: 1000,
    },
  });

  const defaultValues = useMemo(
    () =>
      ({
        program: currentAutomation?.program || null,
        plan: currentAutomation?.plan || null,
        lob: currentAutomation?.lob || null,
        anchorEvent: currentAutomation?.anchorEvent
          ? anchorEvents.find((event) => event.id === currentAutomation.anchorEvent) || null
          : null,
        rules: {},
      } as FormValue),
    [currentAutomation]
  );

  const resolver = useMemo(
    () =>
      yupResolver(
        Yup.object().shape({
          plan: Yup.object().nullable().required('Plan is required'),
          lob: Yup.object().nullable().required('LOB is required'),
          program: Yup.object().nullable().required('Program is required'),
          anchorEvent: Yup.object().nullable().required('Anchor Event is required'),

          rules: Yup.object().shape(
            activityIds.reduce((acc, val) => {
              acc[val] = Yup.object().shape({
                timeframe: Yup.object().shape({
                  value: Yup.string().nullable().required('Timeframe value is required'),
                  unit: Yup.object().nullable().required('Timeframe unit is required'),
                }),
                dst: Yup.object().nullable(),
                activityType: Yup.object().nullable().required('Activity Type is required'),
                activity: Yup.object().nullable().required('Activity is required'),
                contactType: Yup.object().nullable().required('Contact Type is required'),
              });

              return acc;
            }, {} as Record<string, Yup.AnyObjectSchema>)
          ),
        })
      ),
    [activityIds]
  );

  const methods = useForm({ resolver, defaultValues });

  const { enqueueSnackbar } = useSnackbar();

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    watch,
    setValue,
  } = methods;

  const untypedSetValue = setValue as any as UseFormSetValue<any>;
  const untypedWatch = watch as any as UseFormWatch<any>;

  const plan: string = untypedWatch('plan' as any)?.id || '';
  const lob: string = untypedWatch('lob' as any)?.id || '';
  const program: string = untypedWatch('program' as any)?.id || '';
  const anchorEvent: string = untypedWatch('anchorEvent')?.id || '';

  const [[automationSheet], { update: updateList, create: createList, find: findLists }] = useLists(
    {
      filter: {
        identifier: fhirSystem.automation.activity.withId(anchorEvent).forCode(program),
      },
      autofetch: !!anchorEvent && !!program,
    }
  );

  const [plans, { isLoading: isLoadingPlans }] = useOrganizations({
    filter: {
      identifier: fhirSystem.healthPlan.forCode(''),
    },
  });
  const [lobs, { isLoading: isLoadingLobs }] = useOrganizations({
    filter: {
      identifier: fhirSystem.lineOfBusiness.withId(plan).forCode(''),
    },
    autofetch: !!plan,
  });
  const [programs, { isLoading: isLoadingPrograms }] = useHealthcareServices({
    filter: {
      identifier: fhirSystem.program.withId(lob).forCode(''),
    },
    autofetch: !!lob,
  });

  const isLoading =
    isLoadingActivityTypes ||
    isLoadingActivityDefinitions ||
    isLoadingLobs ||
    isLoadingPlans ||
    isLoadingPrograms;

  useEffect(() => {
    untypedSetValue('lob', null);
  }, [plan, untypedSetValue]);

  useEffect(() => {
    untypedSetValue('program', null);
  }, [lob, untypedSetValue]);

  useEffect(() => {
    untypedSetValue('anchorEvent', null);
  }, [program, untypedSetValue]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setRulesLoaded(true);
    untypedSetValue('rules', {});
    setActivityIds([]);
    loadSavedRules(anchorEvent).then(() => {
      setRulesLoaded(false);
    });
  }, [anchorEvent, isLoading, automationSheet, untypedSetValue]);

  useEffect(() => {
    if (isEdit && currentAutomation) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentAutomation]);

  async function loadSavedRules(anchorEvent: string) {
    if (!program || !anchorEvent) {
      console.log('no program or anchor event');
      return;
    }

    const [sheet] = await findLists({
      identifier: fhirSystem.automation.activity.withId(anchorEvent).forCode(program),
    });

    if (!sheet) {
      console.log('no sheet');
      return;
    }
    const activityDefinitions = await Promise.all(
      (sheet.entry || []).map((entry) => getActivityDefinition(entry.item.id || ''))
    );

    const rules = activityDefinitions.map((activityDefinition) => {
      const activityType = activityTypes.find(
        (type) => type.code === activityDefinition.topic?.[0].coding?.[0].code
      );

      const activityId = activityDefinition.identifier
        ?.find((identifier) =>
          identifier.system?.startsWith(fhirSystem.automation.activity.rule.asString() + '/')
        )
        ?.system?.split('/')
        .pop();

      const activity = activities?.find((activity) => activity.id === activityId);

      const extensionContactType = activityDefinition.extension?.find(
        (extension) =>
          extension.url === fhirSystem.extension.ActivityDefinition.contactType.asString()
      )?.valueCoding;

      const extensionDst = activityDefinition.extension?.find(
        (extension) => extension.url === fhirSystem.extension.ActivityDefinition.dst.asString()
      )?.valueReference;

      const contactType = contactTypes?.concept?.find(
        (type) => type.code === extensionContactType?.code
      );

      const dst = assessments?.find((dst) => dst.id === extensionDst?.id);

      const timeframe: ActivityAutomationRule['timeframe'] = {
        value: activityDefinition.timingDuration?.value?.toString() || '1',
        unit:
          fhirDuration.find((unit) => unit.value === activityDefinition.timingDuration?.unit) ||
          fhirDuration[0],
      };

      if (!activityType || !activity || !contactType) {
        console.log('no activity type, activity, or contact type');
        return null;
      }

      const rule: ActivityAutomationRule = {
        id: activityDefinition.id || '',
        activityType,
        activity,
        contactType,
        dst,
        timeframe,
      };

      return rule;
    });

    const finalRules = rules
      .filter((rule): rule is ActivityAutomationRule => !!rule)
      .reduce((acc, rule) => {
        if (rule) {
          acc[rule.id] = rule;
        }

        return acc;
      }, {} as Record<string, ActivityAutomationRule>);

    untypedSetValue('rules', finalRules);

    setActivityIds(Object.keys(finalRules));
  }

  const onSubmit = async (data: FormValue) => {
    try {
      const [sheet] = await findLists({
        identifier: fhirSystem.automation.activity.withId(anchorEvent).forCode(program),
      });

      for (const definitionId of activityIds) {
        const definition = data.rules[definitionId];
        const { dst, activity, activityType, contactType, timeframe } = definition;

        await updateActivityDefinitions({
          id: definitionId,
          resourceType: 'ActivityDefinition',
          status: 'active',
          title: activity.title || activity.name || `{ id: ${activity.id} }`,
          identifier: [
            {
              system: fhirSystem.automation.activity.rule.asString(),
              value: definitionId,
            },
            {
              system: fhirSystem.automation.activity.rule.withId(activity.id || '').asString(),
              value: definitionId,
            },
          ],
          extension: [
            {
              url: fhirSystem.extension.ActivityDefinition.contactType.asString(),
              valueCoding: {
                code: contactType.code,
                system: 'ccm-contact-types',
                display: contactType.display,
              },
            },
            {
              url: fhirSystem.extension.ActivityDefinition.dst.asString(),
              valueReference: dst
                ? {
                    display: dst.title || dst.name || `{ id: ${dst.id} }`,
                    reference: `${dst.resourceType}/${dst.id}`,
                    type: dst.resourceType,
                    id: dst.id,
                  }
                : undefined,
            },
          ],
          topic: [
            {
              text: activityType?.display,
              coding: [
                {
                  system: 'ccm-activity-types',
                  code: activityType?.code,
                  display: activityType?.display,
                },
              ],
            },
          ],
          timingDuration: {
            value: +timeframe.value,
            unit: timeframe.unit.value,
          },
        });
      }

      if (sheet) {
        await updateList({
          ...sheet,
          entry: activityIds.map((activityId) => ({
            item: {
              reference: `ActivityDefinition/${activityId}`,
              type: 'ActivityDefinition',
              id: activityId,
            },
          })),
        });
      } else {
        await createList({
          resourceType: 'List',
          status: 'current',
          mode: 'working',
          title: `${data.anchorEvent?.display} - ${data.program?.name}`,
          entry: activityIds.map((activityId) => ({
            item: {
              reference: `ActivityDefinition/${activityId}`,
              type: 'ActivityDefinition',
              id: activityId,
            },
          })),
          identifier: [
            {
              system: fhirSystem.automation.activity.asString(),
              value: data.program?.id || '',
            },
            {
              system: fhirSystem.automation.activity.withId(data.anchorEvent?.id || '').asString(),
              value: data.program?.id || '',
            },
          ],
        });
      }

      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      navigate('..');
    } catch (error) {
      console.error(error);
    }
  };

  function addActivity() {
    setActivityIds([...activityIds, uuidv4()]);
  }

  function removeActivity(id: string) {
    setActivityIds(activityIds.filter((activity) => activity !== id));
  }

  async function handleToggleStatus(status: boolean) {
    if (!automationSheet) {
      console.error('Automation sheet not found');
      return;
    }

    await updateList({
      ...automationSheet,
      status: status ? 'current' : 'retired',
    });
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 3 }}>
        <Stack gap={3}>
          <Stack
            gap={3}
            direction={{
              xs: 'column',
              md: 'row',
            }}
          >
            <RHFAutocomplete
              name="plan"
              fullWidth
              size="small"
              label={plans.length ? 'Select a health plan' : 'No health plans found'}
              disabled={!plans.length || isEdit}
              options={plans}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name || '')}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />

            <RHFAutocomplete
              name="lob"
              fullWidth
              size="small"
              label={
                plan
                  ? lobs.length
                    ? 'Select a line of business'
                    : 'No lines of business found'
                  : 'First select a health plan'
              }
              disabled={!plan || !lobs.length || isEdit}
              options={lobs}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name || '')}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />

            <RHFAutocomplete
              name="program"
              fullWidth
              size="small"
              label={
                lob
                  ? programs.length
                    ? 'Select a program'
                    : 'No programs found'
                  : 'First select a line of business'
              }
              disabled={!lob || !programs.length || isEdit}
              options={programs}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name || '')}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Stack>

          <Stack direction="row" alignItems="center" gap={1}>
            <RHFAutocomplete
              name="anchorEvent"
              fullWidth
              size="small"
              label={program ? 'Select an anchor event' : 'First select a program'}
              disabled={!plan || !lob || !program || isEdit}
              options={anchorEvents}
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.display || ''
              }
              sx={{
                width: {
                  xs: '100%',
                  md: '50%',
                },
              }}
              onChange={(event, value) => {
                if (value && typeof value === 'object' && 'id' in value) {
                  loadSavedRules(value.id || '');
                }
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              readOnly={isEdit}
            />

            <Button
              variant="outlined"
              onClick={() => addActivity()}
              disabled={!plan || !lob || !program || !anchorEvent}
            >
              Add Activity
            </Button>

            {isEdit && (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                marginLeft="auto"
                gap={1}
              >
                <Typography color="textSecondary">
                  {automationSheet?.status === 'current' ? 'Enabled' : 'Disabled'}
                </Typography>
                <Switch
                  name="enabled"
                  onChange={(_, checked) => handleToggleStatus(checked)}
                  checked={automationSheet?.status === 'current'}
                />
              </Stack>
            )}
          </Stack>

          <Divider />

          {/* Activities to create */}
          {rulesLoading ? (
            <Stack justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Stack>
          ) : activityIds.length ? (
            activityIds.map((activityId) => (
              <Stack key={activityId} direction="row" gap={3} alignItems="flex-start">
                <IconButton onClick={() => removeActivity(activityId)}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
                <Stack gap={3} flex={1}>
                  <Stack direction="row" gap={3} alignItems="center">
                    <RHFAutocomplete
                      name={`rules.${activityId}.activityType`}
                      fullWidth
                      size="small"
                      label="Activity Type"
                      options={activityTypes}
                      getOptionLabel={(option) =>
                        typeof option === 'string'
                          ? option
                          : option.display || option.code || `{id: ${option.id}`
                      }
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                    <RHFAutocomplete
                      name={`rules.${activityId}.activity`}
                      fullWidth
                      size="small"
                      label="Activity"
                      options={activities}
                      getOptionLabel={(option) =>
                        typeof option === 'string'
                          ? option
                          : option.title || option.name || `{id: ${option.id}}`
                      }
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                    <RHFAutocomplete
                      name={`rules.${activityId}.dst`}
                      fullWidth
                      size="small"
                      label="Script/DST"
                      options={assessments}
                      getOptionLabel={(option) =>
                        typeof option === 'string'
                          ? option
                          : option.title || option.name || `{id: ${option.id}}`
                      }
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                  </Stack>
                  <Stack direction="row" gap={2} alignItems="center">
                    <RHFTextField
                      name={`rules.${activityId}.timeframe.value`}
                      fullWidth
                      label="Timeframe"
                      size="small"
                      type="number"
                    />

                    <RHFAutocomplete
                      name={`rules.${activityId}.timeframe.unit`}
                      fullWidth
                      label="Unit"
                      size="small"
                      options={fhirDuration}
                      getOptionLabel={(option) =>
                        typeof option === 'string' ? option : option.label || ''
                      }
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                    />
                    <RHFAutocomplete
                      name={`rules.${activityId}.contactType`}
                      fullWidth
                      label="Contact Type"
                      size="small"
                      options={contactTypes?.concept || []}
                      getOptionLabel={(option) =>
                        typeof option === 'string' ? option : option.display || ''
                      }
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                  </Stack>
                </Stack>
              </Stack>
            ))
          ) : (
            <Typography variant="body2" color="text.secondary" textAlign="center">
              No activities added. Click on the button above to add one.
            </Typography>
          )}
        </Stack>

        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }} gap={2}>
          <Button variant="text" color="inherit" component={RouterLink} to="..">
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Save
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}
