import _mock from './_mock';

export const patientList = [
  {
    id: '8170438b-881c-41d9-821b-0537b6ff5cd9',
    mrn: 'RF-001MT',
    referral: 'George King',
    phone: '201-535-5717',
    email: 'hector.beltran+test@nicheaim.com',
    birthDate: new Date('1995-08-25'),
    gender: 'Male',
    demographics: 'White',
    race: 'Not Hispanic or Latino',
    registrationDate: new Date('2013-10-10'),
    status: 'active',
    rejected: 'rejected',
  },
  {
    id: '1dacfc57-0f4f-4d67-bc16-23305fa7eaa6',
    mrn: 'RF-002MT',
    referral: 'Jonathan Kent',
    phone: '201-535-5717',
    email: 'jonathankent@outlook.com',
    birthDate: new Date('1939-09-28'),
    gender: 'Male',
    demographics: 'White',
    race: 'Not Hispanic or Latino',
    registrationDate: new Date('2014-10-11'),
    status: 'active',
    rejected: 'rejected',
  },
];

export const fhirReferralList = [
  {
    id: '88d9c0ba-8e99-4d4a-8aa3-d4d2a793b55b',
    mrn: 'RF-002MT',
    referral: 'Jonathan Kent',
    phone: '201-535-5717',
    email: 'jonathankent@outlook.com',
    birthDate: new Date('1939-09-28'),
    gender: 'Male',
    demographics: 'White',
    race: 'Not Hispanic or Latino',
    registrationDate: new Date('2014-10-11'),
    status: 'active',
    rejected: 'rejected',
  },
];

export const fhirCaseList = [
  {
    id: '59ad96b8-7c78-4aa9-83ff-1f9f30a4f7cd',
    mrn: 'RF-002MT',
    patient: 'Jonathan Kent',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    assignmentType: 'CRS',
    scope: 'Urgent Referral',
    step: 'Coordinate Care',
    date: new Date('2021-12-20'),
    status: 'open',
  },
];

export const referralList = [
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc64',
    mrn: 'RF-001MT',
    referral: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    gender: 'Male',
    demographics: 'White',
    race: 'Not Hispanic or Latino',
    registrationDate: new Date('2014-10-10'),
    status: 'active',
    rejected: 'rejected',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc65',
    mrn: 'RF-002MT',
    referral: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    gender: 'Male',
    demographics: 'White',
    race: 'Not Hispanic or Latino',
    registrationDate: new Date('2014-10-10'),
    status: 'inactive',
    rejected: 'rejected',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc66',
    mrn: 'RF-003MT',
    referral: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    gender: 'Male',
    demographics: 'White',
    race: 'Not Hispanic or Latino',
    registrationDate: new Date('2014-10-10'),
    status: 'active',
    rejected: 'rejected',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc67',
    mrn: 'RF-004MT',
    referral: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    gender: 'Male',
    demographics: 'White',
    race: 'Not Hispanic or Latino',
    registrationDate: new Date('2014-10-10'),
    status: 'inactive',
    rejected: 'no rejected',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc68',
    mrn: 'RF-005MT',
    referral: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    gender: 'Male',
    demographics: 'White',
    race: 'Not Hispanic or Latino',
    registrationDate: new Date('2014-10-10'),
    status: 'active',
    rejected: 'no rejected',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc69',
    mrn: 'RF-006MT',
    referral: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    gender: 'Male',
    demographics: 'White',
    race: 'Not Hispanic or Latino',
    registrationDate: new Date('2014-10-10'),
    status: 'active',
    rejected: 'rejected',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc70',
    mrn: 'RF-007MT',
    referral: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    gender: 'Male',
    demographics: 'White',
    race: 'Not Hispanic or Latino',
    registrationDate: new Date('2014-10-10'),
    status: 'active',
    rejected: 'no rejected',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc71',
    mrn: 'RF-008MT',
    referral: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    gender: 'Male',
    demographics: 'White',
    race: 'Not Hispanic or Latino',
    registrationDate: new Date('2014-10-10'),
    status: 'inactive',
    rejected: 'no rejected',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc72',
    mrn: 'RF-009MT',
    referral: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    gender: 'Male',
    demographics: 'White',
    race: 'Not Hispanic or Latino',
    registrationDate: new Date('2014-10-10'),
    status: 'active',
    rejected: 'rejected',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc73',
    mrn: 'RF-0010MT',
    referral: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    gender: 'Male',
    demographics: 'White',
    race: 'Not Hispanic or Latino',
    registrationDate: new Date('2014-10-10'),
    status: 'inactive',
    rejected: 'no rejected',
  },
];

export const referralPatient = [
  {
    referralNumber: '312457-1',
    created: 'Nov 11, 2021 (42 days ago)',
    status: 'Referred',
    assignedTo: 'Diana Lott',
    scope: 'urgent referral',
    step: 1,
  },
  {
    referralNumber: '312460-1',
    created: 'Dec 17, 2021 (94 days ago)',
    status: 'Referred',
    assignedTo: 'Joshua Gruber',
    scope: 'emergency referral',
    step: 6,
  },
  {
    referralNumber: '312463-1',
    created: 'Nov 19, 2021 (44 days ago)',
    status: 'Referred',
    assignedTo: 'Laura Mitchell',
    scope: 'urgent referral',
    step: 2,
  },
  {
    referralNumber: '312466-1',
    created: 'Nov 11, 2021 (42 days ago)',
    status: 'Referred',
    assignedTo: 'Steve Martin',
    scope: 'non urgent referral',
    step: 5,
  },
];

export const referralSummaryData = [
  {
    id: '12345678',
    status: 'Referred',
    urgent: 'Yes',
    scope: 'Urgent Referral',
    dispositionReason: 'N/A',
    dispositionBy: 'N/A',
    dispositionNote: 'N/A',
    dispositionOn: 'N/A',
    assigned: null,
    description: 'CRS Referral',
    date: new Date('2021-06-17'),
  },
];

export const patientSummaryData = [
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc89',
    mrn: 'RF-008MT',
    name: 'Roger Billingley',
    birthDate: new Date('1939-09-28'),
    ageGroup: 'Adult',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    address: '3382 Pine Tree Lane, Washington, MD, 20005',
    gender: 'Male',
    race: 'White',
    ethnicity: 'Not Hispanic or Latino',
    enrollments: 'N/A',
    proxy: 'N/A',
  },
];

export const referralInformationData = [
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc96',
    pcprn: 'No',
    hirn: 'No',
    ckn: 'Yes',
    cktn: 'No',
    ckpon: 'No',
    ckq: '1',
    cknt: 'N/A',
    pdp: '201-535-5717',
    pda: '2820 Briarwood Road',
    pdc: 'Springfield',
    pds: 'Missouri',
    pdz: '65804',
    ckcn: 'Door',
  },
];

export const immunizationsList = [
  {
    name: 'Viral hepatitis, type A',
    doses: '1 of 3',
    status: 'in progress',
    ocurrence: '2021-12-11',
    lotNumber: 'AAJN11K',
    vaccineCode: '52',
  },
  {
    name: 'Viral hepatitis, type B',
    doses: '2 of 3',
    status: 'completed',
    ocurrence: '2022-12-11',
    lotNumber: 'AAJN23K',
    vaccineCode: '56',
  },
];

export const casesListReferral = [
  {
    status: 'Open',
    assignedTo: 'Daniela Gonzalez-Osoria',
    scope: 'Urgent Referral',
    date: '2021-02-02',
  },
];

export const contactAttempts = [
  {
    when: 'Jun 22, 2021',
    outcome: 'Answer-by-client',
    nextContact: '2021-06-23',
    notes: 'N/A',
  },
];

export const assessmentsForms = [
  {
    surveyId: '186739',
    responseId: '176',
    language: 'EN',
    type: 'COVID Care SDoH Assessment',
    submitDate: '2021-06-23',
  },
  {
    surveyId: '959828',
    responseId: '100',
    language: 'EN',
    type: 'COVID Care Referral Form',
    submitDate: '2021-06-27',
  },
];

export const notes = [
  {
    title: 'Clinical impressions',
    author: 'Steve Harris',
    description:
      'Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
    date: 'Jul 19, 2021 10:31',
  },
  {
    title: 'Reason for referral',
    author: 'Ruth Foley',
    description:
      'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged',
    date: 'Mar 09, 2021 17:15',
  },
  {
    title: 'Indications',
    author: 'Timothy Shattuck',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    date: 'Feb 20, 2021 09:34',
  },
];

export const attachments = [
  {
    name: 'Urgent Referral Form- Gilda Mesa 2.pdf',
    author: 'ASSESSMENTS_SYSTEM',
    type: 'COVID Care Referral Form-PaperVersion',
    mimeType: 'application/pdf',
    date: '2021-06-27',
    preview: '',
  },
  {
    name: 'Urgent Referral Form- Gilda Mesa 3.pdf',
    author: 'ASSESSMENTS_SYSTEM',
    type: 'COVID Care Referral Form-PaperVersion',
    mimeType: 'application/csv',
    date: '2021-06-29',
    preview: '',
  },
];

export const caseList = [
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc64',
    mrn: 'RF-001MT',
    patient: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    assignmentType: 'CRS',
    scope: 'Urgent Referral',
    step: 'Coordinate Care',
    date: new Date('2021-12-20'),
    status: 'open',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc65',
    mrn: 'RF-002MT',
    patient: 'Katherine Manzano',
    phone: '360-364-8795',
    email: 'kmanzano@gmail.com',
    birthDate: new Date('1984-07-07'),
    assignmentType: 'CHW',
    scope: 'Urgent Referral',
    step: 'Close Case',
    date: new Date('2021-01-04'),
    status: 'open',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc66',
    mrn: 'RF-003MT',
    patient: 'Ann Hawley',
    phone: '512-460-4458',
    email: 'ann.hawley23@gmail.com',
    birthDate: new Date('1978-11-26'),
    assignmentType: 'CHW',
    scope: 'Covid Care Referral',
    step: 'Close Case',
    date: new Date('2021-02-23'),
    status: 'closed',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc67',
    mrn: 'RF-004MT',
    patient: 'Carlton Nelson',
    phone: '315-686-0678',
    email: 'carlton.nelson@gustr.com',
    birthDate: new Date('1990-12-14'),
    assignmentType: 'CHW',
    scope: 'Urgent Referral',
    step: 'Close Case',
    date: new Date('2021-01-04'),
    status: 'open',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc68',
    mrn: 'RF-005MT',
    patient: 'Matthew Hughes',
    phone: '217-715-3016',
    email: 'm.huges41@gmail.com',
    birthDate: new Date('1980-06-21'),
    assignmentType: 'CHW',
    scope: 'Urgent Referral',
    step: 'Close Case',
    date: new Date('2021-02-23'),
    status: 'open',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc69',
    mrn: 'RF-006MT',
    patient: 'Matthew Hughes',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    assignmentType: 'CHW',
    scope: 'Covid Care Referral',
    step: 'Close Case',
    date: new Date('2021-05-12'),
    status: 'closed',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc70',
    mrn: 'RF-007MT',
    patient: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    assignmentType: 'CHW',
    scope: 'Urgent Referral',
    step: 'Close Case',
    date: new Date('2021-04-09'),
    status: 'open',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc71',
    mrn: 'RF-008MT',
    patient: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    assignmentType: 'CHW',
    scope: 'Covid Care Referral',
    step: 'Close Case',
    date: new Date('2021-05-16'),
    status: 'closed',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc72',
    mrn: 'RF-009MT',
    patient: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    assignmentType: 'CHW',
    scope: 'Covid Care Referral',
    step: 'Close Case',
    date: new Date('2021-05-10'),
    status: 'closed',
  },
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bebc73',
    mrn: 'RF-0010MT',
    patient: 'Roger Billingley',
    phone: '201-535-5717',
    email: 'rogerdbillingsley@outlook.com',
    birthDate: new Date('1939-09-28'),
    assignmentType: 'CHW',
    scope: 'Urgent Referral',
    step: 'Close Case',
    date: new Date('2021-05-30'),
    status: 'closed',
  },
];

export const caseInformation = [
  {
    id: '7dc5a670-3e85-4d98-b2cc-2cb889bhcc01',
    status: 'Referred',
    urgent: 'Yes',
    scope: 'Urgent Referral',
    assigned: '',
    description: 'CRS Referral',
    date: new Date('2021-07-21'),
  },
];

export const caseSummary = [
  {
    title: 'Pathways',
    total: 6,
  },
  {
    title: 'Open tasks',
    total: 5,
  },
  {
    title: 'Tasks past due',
    total: 2,
  },
  {
    title: 'Tasks due in 2 Days',
    total: 1,
  },
  {
    title: 'Active outbound ref',
    total: 1,
  },
];

export const taskList = [
  {
    id: '04999d08-c30c-4318-8326-325656b32b89',
    task: 'Remind user about their consent form',
    status: 'Requested',
    type: 'Applicable Consent',
    assigned: new Date('2021-12-11'),
    category: 'ndpp',
    dueTo: new Date('2022-01-12'),
  },
  {
    id: '3cebb931-f68d-4841-aa8e-4a6632f020f5',
    task: 'Identify health concerns',
    status: 'In Progress',
    type: 'Follow Up',
    assigned: new Date('2021-12-12'),
    category: 'tndpp',
    dueTo: new Date('2022-01-13'),
  },
  {
    id: '04168ddb-feb6-4dea-bee8-244883689c69',
    task: 'Update NDPP template document',
    status: 'In Progress',
    type: 'Follow Up',
    assigned: new Date('2022-01-05'),
    category: 'dsmes',
    dueTo: new Date('2022-01-19'),
  },
  {
    id: 'd8ab50fb-76a9-428a-bc67-8d9a3a66ecd0',
    task: 'Visit CM to make sure the client has a clear education',
    status: 'On Hold',
    type: 'Preliminary Results',
    assigned: new Date('2021-12-10'),
    category: 'cr',
    dueTo: new Date('2022-02-18'),
  },
  {
    id: 'dd6d0495-0fce-41cf-8885-fee630170017',
    task: 'Confirm appointments with CM',
    status: 'Requested',
    type: 'Follow Up',
    assigned: new Date('2021-12-18'),
    category: 'mtm',
    dueTo: new Date('2022-02-20'),
  },
  {
    id: 'c2bf7359-8f56-456d-b85c-507589090bc5',
    task: 'Confirm appointments with CM',
    status: 'On Hold',
    type: 'Preliminary Results',
    assigned: new Date('2021-03-09'),
    category: 'rpm',
    dueTo: new Date('2022-03-10'),
  },
  {
    id: '04999d08-c30c-4318-8326-325656b32b90',
    task: 'Remind user about their consent form',
    status: 'On Hold',
    type: 'Applicable Consent',
    assigned: new Date('2021-12-11'),
    category: 'ndpp',
    dueTo: new Date('2022-01-12'),
  },
  {
    id: 'dd6d0495-0fce-41cf-8885-fee630170018',
    task: 'Confirm appointments with CM',
    status: 'On Hold',
    type: 'Follow Up',
    assigned: new Date('2021-12-18'),
    category: 'mtm',
    dueTo: new Date('2022-02-20'),
  },
  {
    id: '04168ddb-feb6-4dea-bee8-244883689c70',
    task: 'Update NDPP template document',
    status: 'On Hold',
    type: 'Follow Up',
    assigned: new Date('2022-01-05'),
    category: 'dsmes',
    dueTo: new Date('2022-01-19'),
  },
];

export const outboundList = [
  {
    id: '3cebb931-f68d-4841-aa8r-4a6632f020f5',
    serviceReferral: 'Remote Monitoring',
    status: 'Pending',
    childs: '2',
    annotations: '6',
    docs: '15',
    assignedTo: 'Coon Medical Inc (Leonard Santacruz',
    assigned: new Date('2021-01-27'),
    category: 'ndpp',
    dueTo: new Date('2022-01-12'),
  },
  {
    id: '3cebb931-f68d-4841-aa8e-4a6632f020f5',
    serviceReferral: 'Health Worker',
    status: 'Pending',
    childs: '0',
    annotations: '2',
    docs: '9',
    assignedTo: 'Wealthy Ideas',
    assigned: new Date('2022-01-20'),
    category: 'ndpp',
    dueTo: new Date('2022-01-19'),
  },
  {
    id: '04168ddb-feb6-4dea-bee8-244883689c69',
    serviceReferral: 'Health Worker',
    status: 'Pending',
    childs: '0',
    annotations: '2',
    docs: '9',
    assignedTo: 'Wealthy Ideas',
    assigned: new Date('2022-01-20'),
    category: 'tndpp',
    dueTo: new Date('2022-01-19'),
  },
  {
    id: 'd8ab50fb-76a9-428a-bc67-8d9a3a66ecd0',
    serviceReferral: 'Health Worker',
    status: 'Pending',
    childs: '0',
    annotations: '2',
    docs: '9',
    assignedTo: 'Wealthy Ideas',
    assigned: new Date('2022-01-20'),
    category: 'dsmes',
    dueTo: new Date('2022-01-19'),
  },
  {
    id: 'dd6d0495-0fce-41cf-8885-fee630170017',
    serviceReferral: 'Health Worker',
    status: 'Pending',
    childs: '0',
    annotations: '2',
    docs: '9',
    assignedTo: 'Wealthy Ideas',
    assigned: new Date('2022-01-20'),
    category: 'cr',
    dueTo: new Date('2022-01-19'),
  },
  {
    id: 'c2bf7359-8f56-456d-b85c-507589090bc5',
    serviceReferral: 'Health Worker',
    status: 'Pending',
    childs: '0',
    annotations: '2',
    docs: '9',
    assignedTo: 'Wealthy Ideas',
    assigned: new Date('2022-01-20'),
    category: 'cr',
    dueTo: new Date('2022-01-19'),
  },
  {
    id: '04999d08-c30c-4318-8326-325656b32b90',
    serviceReferral: 'Health Worker',
    status: 'Pending',
    childs: '0',
    annotations: '2',
    docs: '9',
    assignedTo: 'Wealthy Ideas',
    assigned: new Date('2022-01-20'),
    category: 'mtm',
    dueTo: new Date('2022-01-19'),
  },
  {
    id: 'dd6d0495-0fce-41cf-8885-fee630170018',
    serviceReferral: 'Health Worker',
    status: 'Pending',
    childs: '0',
    annotations: '2',
    docs: '9',
    assignedTo: 'Wealthy Ideas',
    assigned: new Date('2022-01-20'),
    category: 'mtm',
    dueTo: new Date('2022-01-19'),
  },
  {
    id: '04168ddb-feb6-4dea-bee8-244883689c70',
    serviceReferral: 'Health Worker',
    status: 'Pending',
    childs: '0',
    annotations: '2',
    docs: '9',
    assignedTo: 'Wealthy Ideas',
    assigned: new Date('2022-01-20'),
    category: 'rpm',
    dueTo: new Date('2022-01-19'),
  },
];

export const NoteReview = [...Array(5)].map((_, index) => ({
  id: _mock.id(index),
  name: _mock.name.fullName(index),
  note: _mock.text.description(index),
  date: _mock.time(index),
  tags: ['doctors', 'internal', 'referral'],
}));
