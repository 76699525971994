import { CCM_ACLS } from 'src/utils/permissions/permission.utils';
import { useTaskActivitiesStore } from '../task-activities.store';

export enum EntityTypeStatesEnum {
  ALL = 'all',
  NEW = 'requested',
  REFERRAL_NEW = 'draft',
  REFERRAL_PENDING = 'on-hold',
  PENDING = 'Pending',
  ACTIVE = 'Active',
  PAST_DUE = 'Past due',
  ACCEPTED = 'active',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  DUE_TODAY = 'Due today',
  FUTURE_TASK = 'Future task',
  PATIENT_NEW = 'new',
  PATIENT_PENDING = 'pending',
  PATIENT_ACTIVE = 'active',
  PATIENT_INACTIVE = 'inactive',
  PATIENT_ALL = 'all',
}

export type QuickActionType = {
  id: number;
  title: string;
  disabled: boolean;
  permissions: string[];
  actions: QuickActionItemType[];
};

export type QuickActionItemType = {
  title: string;
  count: number;
  color: string;
  onPress: (setter: Function) => void;
};

export const ENTITY_TYPES = {
  VOID: {
    identifier: -1,
    title: 'No Access',
    states: {},
  },
  PATIENTS: {
    identifier: 0,
    title: 'Patients',
    states: {
      new: EntityTypeStatesEnum.PATIENT_NEW,
      pending: EntityTypeStatesEnum.PATIENT_PENDING,
      active: EntityTypeStatesEnum.PATIENT_ACTIVE,
    },
  },
  ACTIVITIES: {
    identifier: 1,
    title: 'Activities',
    states: {
      new: EntityTypeStatesEnum.NEW,
      pastdue: EntityTypeStatesEnum.PAST_DUE,
      today: EntityTypeStatesEnum.DUE_TODAY,
    },
  },
  REFERRALS: {
    identifier: 3,
    title: 'Referrals',
    states: {
      new: EntityTypeStatesEnum.REFERRAL_NEW,
      pending: EntityTypeStatesEnum.REFERRAL_PENDING,
      accepted: EntityTypeStatesEnum.ACCEPTED,
    },
  },
  ALERTS: {
    identifier: 4,
    title: 'Alerts',
    states: {
      new: EntityTypeStatesEnum.NEW,
      pending: EntityTypeStatesEnum.PENDING,
      active: EntityTypeStatesEnum.ACTIVE,
    },
  },
};

export const quickActions = ({ activities, referrals, patients }: any): QuickActionType[] => [
  {
    id: 0,
    title: 'My Patients',
    disabled: false,
    permissions: [
      CCM_ACLS.TASK_ACTIVITIES.name,
      CCM_ACLS.TASK_ACTIVITIES.PATIENTS.name + '.*',
      CCM_ACLS.TASK_ACTIVITIES.PATIENTS.name,
      CCM_ACLS.CCM_ADMIN,
      CCM_ACLS.CCM_SYSTEM_ADMIN,
    ],
    actions: [
      {
        title: 'NEW',
        count: patients?.newCount ?? '...',
        color: '#5cb394',
        onPress: (setter: Function): void =>
          setter({
            entity: ENTITY_TYPES.PATIENTS,
            state: ENTITY_TYPES.PATIENTS.states.new,
          }),
      },
      {
        title: 'PENDING',
        count: patients?.pendingCount ?? '...',
        color: '#db2b39',
        onPress: (setter: Function): void =>
          setter({
            entity: ENTITY_TYPES.PATIENTS,
            state: ENTITY_TYPES.PATIENTS.states.pending,
          }),
      },
      {
        title: 'ACTIVE',
        count: patients?.activeCount ?? '...',
        color: '#00569d',
        onPress: (setter: Function): void =>
          setter({
            entity: ENTITY_TYPES.PATIENTS,
            state: ENTITY_TYPES.PATIENTS.states.active,
          }),
      },
    ],
  },
  {
    id: 1,
    title: 'My Activities',
    disabled: false,
    permissions: [
      CCM_ACLS.TASK_ACTIVITIES.name,
      CCM_ACLS.TASK_ACTIVITIES.ACTIVITIES.name + '.*',
      CCM_ACLS.TASK_ACTIVITIES.ACTIVITIES.name,
      CCM_ACLS.CCM_ADMIN,
      CCM_ACLS.CCM_SYSTEM_ADMIN,
    ],
    actions: [
      {
        title: 'NEW',
        count: activities?.newCount ?? '...',
        color: '#5cb394',
        onPress: (setter: Function): void => {
          useTaskActivitiesStore.getState().setStatus('requested');
          useTaskActivitiesStore.getState().setDueStatus('all');
          setter({
            entity: ENTITY_TYPES.ACTIVITIES,
            state: ENTITY_TYPES.ACTIVITIES.states.new,
          });
        },
      },
      {
        title: 'PAST DUE',
        count: activities?.pastDueCount ?? '...',
        color: '#db2b39',
        onPress: (setter: Function): void => {
          useTaskActivitiesStore.getState().setStatus('all');
          useTaskActivitiesStore.getState().setDueStatus('past_due');
          setter({
            entity: ENTITY_TYPES.ACTIVITIES,
            state: ENTITY_TYPES.ACTIVITIES.states.pastdue,
          });
        },
      },
      {
        title: 'DUE TODAY',
        count: activities?.dueTodayCount ?? '...',
        color: '#00569d',
        onPress: (setter: Function): void => {
          useTaskActivitiesStore.getState().setStatus('all');
          useTaskActivitiesStore.getState().setDueStatus('due_today');
          setter({
            entity: ENTITY_TYPES.ACTIVITIES,
            state: ENTITY_TYPES.ACTIVITIES.states.today,
          });
        },
      },
    ],
  },
  {
    id: 2,
    title: 'My Referrals',
    disabled: false,
    permissions: [
      CCM_ACLS.TASK_ACTIVITIES.name,
      CCM_ACLS.TASK_ACTIVITIES.REFERRALS.name + '.*',
      CCM_ACLS.TASK_ACTIVITIES.REFERRALS.name,
      CCM_ACLS.CCM_ADMIN,
      CCM_ACLS.CCM_SYSTEM_ADMIN,
    ],
    actions: [
      {
        title: 'REQUESTED',
        count: referrals?.newCount ?? '...',
        color: '#5cb394',
        onPress: (setter: Function): void =>
          setter({
            entity: ENTITY_TYPES.REFERRALS,
            state: ENTITY_TYPES.REFERRALS.states.new,
          }),
      },
      {
        title: 'PENDING',
        count: referrals?.pendingCount ?? '...',
        color: '#db2b39',
        onPress: (setter: Function): void =>
          setter({
            entity: ENTITY_TYPES.REFERRALS,
            state: ENTITY_TYPES.REFERRALS.states.pending,
          }),
      },
      {
        title: 'ACCEPTED',
        count: referrals?.acceptedCount ?? '...',
        color: '#00569d',
        onPress: (setter: Function): void =>
          setter({
            entity: ENTITY_TYPES.REFERRALS,
            state: ENTITY_TYPES.REFERRALS.states.accepted,
          }),
      },
    ],
  },
  {
    id: 3,
    title: 'My Alerts',
    disabled: true,
    permissions: [
      CCM_ACLS.TASK_ACTIVITIES.name,
      CCM_ACLS.TASK_ACTIVITIES.ALERTS.name + '.*',
      CCM_ACLS.TASK_ACTIVITIES.ALERTS.name,
      CCM_ACLS.CCM_ADMIN,
      CCM_ACLS.CCM_SYSTEM_ADMIN,
    ],
    actions: [
      {
        title: 'NEW',
        count: 0,
        color: '#5cb394',
        onPress: (setter: Function): void =>
          setter({
            entity: ENTITY_TYPES.ALERTS,
            state: ENTITY_TYPES.ALERTS.states.new,
          }),
      },
      {
        title: 'PENDING',
        count: 0,
        color: '#db2b39',
        onPress: (setter: Function): void =>
          setter({
            entity: ENTITY_TYPES.ALERTS,
            state: ENTITY_TYPES.ALERTS.states.pending,
          }),
      },
      {
        title: 'ACTIVE',
        count: 0,
        color: '#00569d',
        onPress: (setter: Function): void =>
          setter({
            entity: ENTITY_TYPES.ALERTS,
            state: ENTITY_TYPES.ALERTS.states.active,
          }),
      },
    ],
  },
];
