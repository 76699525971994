import { Box } from '@mui/material';
import Label from 'src/components/Label';
import DataTable, { DataTableColumn } from 'src/components/table/DataTable';
import ActivitiesToolbar from './ActivitiesToolbar';
import { useActivityDefinitions } from 'src/@nicheaim/fhir-react';
import fhirSystem from 'src/fhir/system';
import ActivitiesContextMenu from './ActivitiesContextMenu';
import { useMemo } from 'react';
import { useActivityTypes } from 'src/fhir/hooks/activity-types';
import { useActiveActivityDefinitions } from 'src/fhir/hooks/activities';

const columns = [
  {
    header: 'Activity Name',
    field: 'title',
  },
  {
    header: 'Type',
    field: 'topic.0.coding.0.display',
  },
  {
    header: 'Status',
    field: 'status',
    render(value) {
      return <Label color={value === 'active' ? 'success' : 'info'}>{value}</Label>;
    },
  },
] as DataTableColumn[];

export default function ActivitiesTable() {
  const [activities] = useActiveActivityDefinitions({
    pagination: {
      pageSize: 1000,
    },
  });

  return (
    <Box>
      <ActivitiesToolbar />

      <DataTable
        selectable
        columns={columns}
        data={activities}
        rowContextMenu={(activity) => <ActivitiesContextMenu item={activity} />}
      />
    </Box>
  );
}
