import * as Yup from 'yup';
import { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Button, Link, Typography } from '@mui/material';
// components
import { FormProvider } from 'src/components/hook-form';
import { useQuestionnaires } from 'src/@nicheaim/fhir-react';
import fhirSystem from 'src/fhir/system';
import RHFList from 'src/components/hook-form/RHFList';
import { useQuery } from '@tanstack/react-query';
import api from 'src/services/api';
import { ASSESSMENTS_BASE_URL } from 'src/config';
import { useDialogStore } from 'src/stores/dialog';
import {
  Questionnaire,
  QuestionnaireItem,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import {
  APIGetAssessmentDetailsResponse,
  APIGetAssessmentDetailsResponse_ArrayQuestionType,
  APIGetAssessmentDetailsResponse_Question,
  APIGetAssessmentDetailsResponse_QuestionType,
} from 'src/services/api/assessments';
import { paramCase } from 'change-case';
import { useAllAssessments } from 'src/fhir/hooks/assessments';

// ----------------------------------------------------------------------

type FormValue = {
  surveysToImport: string[];
  surveysToReimport: string[];
};

const schema = Yup.object().shape({
  surveysToImport: Yup.array().of(Yup.string()).required('You need to select at least one survey'),
  surveysToReimport: Yup.array()
    .of(Yup.string())
    .required('You need to select at least one survey'),
});

const resolver = yupResolver(schema);

export default function NewAssessmentForm() {
  const navigate = useNavigate();
  const { confirmDialog } = useDialogStore();
  const { data: surveys = [] } = useQuery(['surveys'], () =>
    api.assessments.listAvailableAssessments()
  );
  const [
    assessments,
    { find: findQuestionnaires, update: updateQuestionnaire, create: createQuestionnaire },
  ] = useAllAssessments({
    pagination: {
      pageSize: 1000,
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () =>
      ({
        surveysToImport: [],
        surveysToReimport: [],
      } as FormValue),
    []
  );

  const methods = useForm({ resolver, defaultValues });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValue) => {
    const confirmed = await confirmDialog({
      title: 'Are you sure?',
      description: (
        <Box>
          <Typography>The following assessments will be imported:</Typography>

          <Box sx={{ mt: 2 }}>
            {data.surveysToImport
              .map((survey) => surveys.find((s) => s.id === survey)!)
              .filter(Boolean)
              .map((survey) => (
                <Typography key={survey.id} color="text.secondary" variant="body2">
                  {survey.title}
                </Typography>
              ))}
          </Box>

          <Typography>The following will be updated:</Typography>

          <Box sx={{ mt: 2 }}>
            {data.surveysToReimport
              .map((survey) => surveys.find((s) => s.id === survey)!)
              .filter(Boolean)
              .map((survey) => (
                <Typography key={survey.id} color="text.secondary" variant="body2">
                  {survey.title}
                </Typography>
              ))}
          </Box>
        </Box>
      ),
    });

    if (!confirmed) {
      return;
    }

    try {
      const result = await Promise.allSettled(
        surveys
          .filter(
            (survey) =>
              data.surveysToImport.includes(survey.id) || data.surveysToReimport.includes(survey.id)
          )
          .map(async (survey) => {
            const surveyDetails = await api.assessments.getAssessmentDetails(survey.id);

            if (!surveyDetails) {
              console.info(`No survey details found for ${survey.id}, skipping`);
              return;
            }

            console.info(`Syncing survey ${survey.id}`);
            console.debug(JSON.stringify(surveyDetails, null, 2));

            if (!surveyDetails.questions || !Object.keys(surveyDetails.questions)) {
              console.info(`No questions found for ${survey.id}, skipping`);
              return;
            }

            const questionGroups = Object.values(surveyDetails.questions).reduce(
              (acc, question) => {
                const group = (acc[question.group_id] ??= {
                  id: question.group_id,
                  name: question.group_name,
                  questions: [],
                });

                group.questions.push(question);
                return acc;
              },
              {} as Record<string, QuestionGroup>
            );

            console.info(`Syncing ${Object.keys(questionGroups).length} question groups`);
            console.debug(JSON.stringify(questionGroups, null, 2));

            const questions = Object.values(questionGroups).map(
              (group) =>
                ({
                  type: 'group',
                  id: group.id,
                  linkId: `/${group.id}`,
                  text: group.name,
                  ...(group.questions.length > 0  && { item: group.questions.map((question) =>
                    resolveQuestion(question, `/${group.id}`)
                  )}),
                } as QuestionnaireItem)
            );
            console.debug('Generated questions for the assessment ', { questions });

            const surveyIdentifier = fhirSystem.assessments.assessment.forCode(survey.id);

            console.debug(`Getting Questionnaire for (identifier=${surveyIdentifier})`);
            const [matchingQuestionnaire] = await findQuestionnaires({
              identifier: surveyIdentifier,
            });

            if (matchingQuestionnaire) {
              console.info(
                `Updating Questionnaire ${matchingQuestionnaire.id} (survey-id: ${survey.id})`
              );
              await updateQuestionnaire({
                ...matchingQuestionnaire,
                item: questions,
              })
              
            } else {
              console.info(`Creating Questionnaire for survey ${survey.id}`);
              await createQuestionnaire({
                resourceType: 'Questionnaire',
                status: 'active',
                identifier: [
                  {
                    system: fhirSystem.assessments.assessment.asString(),
                    value: survey.id,
                  },
                ],
                name: paramCase(survey.title),
                title: survey.title,
                item: questions,
              })
            }
          })
      );

      const status = result.reduce(
        (r, acc) => {
          if (acc.status === 'fulfilled') {
            r.success++;
          } else {
            r.error++;
          }
          return r;
        },
        { success: 0, error: 0 }
      );
      if (status.error) {
        enqueueSnackbar(
          `Failed to import ${status.error} assessments out of ${status.success + status.error}`,
          { variant: 'error' }
        );
        return;
      }

      reset();
      enqueueSnackbar(
        `Successfully imported ${data.surveysToImport.length}${
          data.surveysToReimport.length ? `/reimported ${data.surveysToReimport.length}` : ''
        } assessments`
      );
      navigate('..');
    } catch (error) {
      enqueueSnackbar('Failed to import assessments', { variant: 'error' });
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 3 }}>
        <Stack gap={3}>
          <Typography>
            Please select the assessments you would like to import into the application from the
            Lime Survey platform. If you would like to create a new one{' '}
            <Link href={`${ASSESSMENTS_BASE_URL}/admin/survey/sa/newsurvey`} target="_blank">
              click here to go to Lime Survey.
            </Link>
          </Typography>

          <RHFList
            label="Available Assessments"
            name="surveysToImport"
            items={surveys
              .filter(
                (survey) =>
                  !assessments.find(
                    (assessment) =>
                      assessment.identifier?.find(
                        (i) => i.system === fhirSystem.assessments.assessment.asString()
                      )?.value === survey.id
                  )
              )
              .map((survey) => ({
                label: survey.title,
                value: survey.id,
              }))}
          />

          <RHFList
            label="Already Imported Assessments"
            name="surveysToReimport"
            items={surveys
              .filter((survey) =>
                assessments.find(
                  (assessment) =>
                    assessment.identifier?.find(
                      (i) => i.system === fhirSystem.assessments.assessment.asString()
                    )?.value === survey.id
                )
              )
              .map((survey) => ({
                label: survey.title,
                value: survey.id,
              }))}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }} gap={2}>
          <Button variant="text" color="inherit" component={RouterLink} to="..">
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Import Assessments
          </LoadingButton>
        </Stack>
      </Box>
    </FormProvider>
  );
}

function resolveQuestion(question: Question, parentLinkId: string): QuestionnaireItem {
  const linkId = `${parentLinkId}/${question.id}`;
  const questionOptions = (question.options || []).map((option) => ({
    valueCoding: {
      code: option.code,
      display: option.answer,
    },
  }));
  const questionSubquestionsAsCode = getSubquestions(question).map((subquestion) => ({
    valueCoding: {
      code: subquestion.code,
      display: subquestion.question,
    },
  }));

  const mapping: Partial<
    Record<APIGetAssessmentDetailsResponse_QuestionType, () => Partial<QuestionnaireItem>>
  > = {
    'short free text': () => ({
      type: 'string',
    }),
    'multiple short text': () => resolveArrayQuestion(question, 'short free text', linkId),
    'long free text': () => ({
      type: 'text',
    }),
    'huge free text': () => ({
      type: 'text',
    }),
    '5 point choice': () => ({
      type: 'choice',
      answerOption: [
        { valueInteger: 1 },
        { valueInteger: 2 },
        { valueInteger: 3 },
        { valueInteger: 4 },
        { valueInteger: 5 },
      ],
    }),
    'array (5 point choice)': () => resolveArrayQuestion(question, '5 point choice', linkId),
    'array (10 point choice)': () =>
      resolveArrayQuestionAs(
        question,
        () => ({
          type: 'choice',
          answerOption: [
            { valueInteger: 1 },
            { valueInteger: 2 },
            { valueInteger: 3 },
            { valueInteger: 4 },
            { valueInteger: 5 },
            { valueInteger: 6 },
            { valueInteger: 7 },
            { valueInteger: 8 },
            { valueInteger: 9 },
            { valueInteger: 10 },
          ],
        }),
        linkId
      ),
    'yes/no': () => ({
      type: 'choice',
      answerOption: [
        {
          valueCoding: {
            code: 'Y',
            display: 'Yes',
          },
        },
        {
          valueCoding: {
            code: 'N',
            display: 'No',
          },
        },
      ],
    }),
    'array (yes/no/uncertain)': () =>
      resolveArrayQuestionAs(
        question,
        () => ({
          type: 'choice',
          answerOption: [
            {
              valueCoding: {
                code: 'Y',
                display: 'Yes',
              },
            },
            {
              valueCoding: {
                code: 'N',
                display: 'No',
              },
            },
            {
              valueCoding: {
                code: 'U',
                display: 'Uncertain',
              },
            },
          ],
        }),
        linkId
      ),
    'date/time': () => ({
      type: 'dateTime',
    }),
    'list (radio)': () => ({
      // FIXME: need to identify if "other" is enabled and change to open-choice
      type: 'choice',
      answerOption: questionOptions,
    }),
    'list (dropdown)': () => ({
      // FIXME: need to identify if "other" is enabled and change to open-choice
      type: 'choice',
      answerOption: questionOptions,
    }),
    'list with comment': () => ({
      // FIXME: there is no way to get comment from this question type
      type: 'choice',
      answerOption: questionOptions,
    }),
    'multiple choice': () => ({
      type: 'choice',
      repeats: true,
      answerOption: questionSubquestionsAsCode,
    }),
    get 'multiple choice with comments'() {
      return this['multiple choice'];
    },
    equation: () => ({
      // TODO: need to figure out a better way to handle this
      type: 'string',
    }),
    'file upload': () => ({
      type: 'attachment',
    }),
    gender: () => ({
      type: 'choice',
      answerOption: [
        {
          valueCoding: {
            code: 'M',
            display: 'Male',
          },
        },
        {
          valueCoding: {
            code: 'F',
            display: 'Female',
          },
        },
      ],
    }),
    'language switch': () => ({
      type: 'choice',
      answerOption: [
        {
          valueCoding: {
            code: 'en',
            display: 'English',
          },
        },
      ],
    }),
    'numerical input': () => ({
      type: 'decimal',
    }),
    'multiple numerical input': () => resolveArrayQuestion(question, 'numerical input', linkId),
    'array (increase/same/decrease)': () =>
      resolveArrayQuestionAs(
        question,
        () => ({
          type: 'choice',
          answerOption: [
            {
              valueCoding: {
                code: 'I',
                display: 'Increase',
              },
            },
            {
              valueCoding: {
                code: 'S',
                display: 'Same',
              },
            },
            {
              valueCoding: {
                code: 'D',
                display: 'Decrease',
              },
            },
          ],
        }),
        linkId
      ),
    'array (numbers)': () =>
      resolveArrayQuestionAs(
        question,
        (q) => ({
          id: q.id,
          type: 'question',
          text: q.question,
          item: getSubquestions(q).map((subquestion) =>
            resolveQuestion(
              {
                ...subquestion,
                type: 'numerical input',
              },
              `${linkId}/${q.id}`
            )
          ),
        }),
        linkId
      ),
    'array (texts)': () =>
      resolveArrayQuestionAs(
        question,
        (q) => ({
          type: 'question',
          text: q.question,
          item: getSubquestions(q).map((subquestion) =>
            resolveQuestion(
              {
                ...subquestion,
                type: 'short free text',
              },
              `${linkId}/${q.id}`
            )
          ),
        }),
        linkId
      ),
    array: () =>
      resolveArrayQuestionAs(
        question,
        (q) => ({
          type: 'choice',
          answerOption: questionOptions,
        }),
        linkId
      ),
    // FIXME: // ranking: () => ({}),
    'text display': () => ({
      type: 'display',
    }),
    'array by column': () =>
      resolveArrayQuestionAs(
        question,
        (q) => ({
          type: 'choice',
          answerOption: questionOptions,
        }),
        linkId
      ),
    'array dual scale': () => ({
      type: 'display',
      text: 'Unsupported question type: array dual scale',
    }),
    // resolveArrayQuestionAs(
    //   question,
    //   (q) => ({
    //     id: q.id,
    //     type: 'question',
    //     text: q.question,
    //     item: getSubquestions(q).map((subquestion) =>
    //       resolveQuestion(
    //         {
    //           ...subquestion,
    //           type: 'short free text',
    //         },
    //         `${linkId}/${q.id}`
    //       )
    //     ),
    //   }),
    //   linkId
    // ),
  };

  const mapper =
    mapping[question.type.toLowerCase() as APIGetAssessmentDetailsResponse_QuestionType] ??
    (() => ({
      type: 'display',
      text: `Unsupported question type: ${question.type}`,
    }));

  if (!mapper) {
    throw new Error(`No mapper found for question type ${question.type}`);
  }

  return {
    id: question.id,
    linkId,
    text: question.question, // TODO: Consider stripping html, meanwhile stripping on the frontend when needed
    ...mapper(),
  } as QuestionnaireItem;
}

function resolveArrayQuestion(
  question: Question,
  arrayType: APIGetAssessmentDetailsResponse_ArrayQuestionType,
  parentLinkId: string
) {
  return {
    type: 'group',
    text: question.question,
    item: getSubquestions(question).map((subquestion) =>
      resolveQuestion(
        {
          ...subquestion,
          type: arrayType,
        },
        parentLinkId
      )
    ),
  } as QuestionnaireItem;
}

function resolveArrayQuestionAs(
  question: Question,
  resolver: (question: APIGetAssessmentDetailsResponse_Question) => Partial<QuestionnaireItem>,
  parentLinkId: string
) {
  return {
    type: 'group',
    text: question.question,
    item: getSubquestions(question).map((subquestion) => ({
      text: subquestion.question, // TODO: Consider stripping html, meanwhile stripping on the frontend when needed
      id: subquestion.id,
      ...resolver(subquestion),
      linkId: `${parentLinkId}/${subquestion.id}`,
    })),
  } as QuestionnaireItem;
}

function getSubquestions(question: Question): APIGetAssessmentDetailsResponse_Question[] {
  return Array.isArray(question.subquestions)
    ? question.subquestions
    : Object.values(question.subquestions || {});
}

export type Question = APIGetAssessmentDetailsResponse['questions'][number];

export interface QuestionGroup {
  id: Question['group_id'];
  name: Question['group_name'];
  questions: Question[];
}
