import { Route, Routes, useNavigate, useParams } from 'react-router';
import AutomationSheetsForm from './AutomationSheetsForm';
import AutomationSheetsTable from './AutomationSheetsTable';
import AutomationSheetsToolbar from './AutomationSheetsToolbar';
import { useEffect } from 'react';
import useCarePlanAutomationFormStore from './AutomationSheetsForm/store';

function CarePlanAutomationTab() {
  return (
    <Routes>
      <Route index element={<CarePlanAutomationList />} />
      <Route path="edit" element={<AutomationSheetsForm />} />
      <Route path=":sheet/*" element={<AutomationSheetsEdit />} />
    </Routes>
  );
}

function AutomationSheetsEdit() {
  const { sheet = '' } = useParams();
  const navigate = useNavigate();
  const reset = useCarePlanAutomationFormStore((s) => s.reset);
  const loadAutomationSheet = useCarePlanAutomationFormStore((s) => s.loadAutomationSheet);

  useEffect(() => {
    // FIXME: Should warn about previous changes before editting.
    reset();
    loadAutomationSheet(sheet).then(() => navigate('../edit'));
  }, [sheet, reset, loadAutomationSheet, navigate]);

  return null;
}

function CarePlanAutomationList() {
  return (
    <>
      <AutomationSheetsToolbar />
      <AutomationSheetsTable />
    </>
  );
}

export default Object.assign(CarePlanAutomationTab, {
  path: 'automations',
});
