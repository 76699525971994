import { ReactNode, useEffect, useRef, useState } from 'react';

import agent from '../../../api/agent';

interface IRefreshSSOProps {
  children: ReactNode;
  meta?: ReactNode;
  //   handleOnClose: () => void;
}

export default function RefreshSSO({ children, meta }: IRefreshSSOProps) {
  const [showUserSessionsIframe, setShowUserSessionsIframe] = useState<boolean>(false);

  const tick = useRef<any>();
  const [startCloseTimer, setStartCloseTimer] = useState<boolean>(false);
  const closeTick = useRef<any>();

  const [userSessionsUrl, setUserSessionsUrl] = useState<string>();

  async function getUserSessionUrl() {
    try {
      const result = await agent.User.getUserSessionsRedirectUrl();
      setUserSessionsUrl(result.user_sessions_url);
    } catch (ex) {
      console.log('handled exeption: ', ex);
    }
  }

  useEffect(() => {
    getUserSessionUrl();

    tick.current = setInterval(() => {
      setShowUserSessionsIframe(true);
      setStartCloseTimer(true);
    }, 5 * 60 * 1000); // display close button after 5 mins

    return () => clearInterval(tick.current);
  }, []);

  useEffect(() => {
    if (startCloseTimer) {
      closeTick.current = setInterval(() => {
        setShowUserSessionsIframe(false);
        setStartCloseTimer(false);
      }, 30 * 1000); // display close button after 30 seconds
    } else {
      clearInterval(closeTick.current);
    }

    return () => clearInterval(closeTick.current);
  }, [startCloseTimer]);

  return (
    <div>
      <div>{children}</div>

      <div>
        {userSessionsUrl && showUserSessionsIframe && (
          <iframe
            src={userSessionsUrl}
            title="oidcUserSessions"
            style={{ border: 0, height: '0', width: '0' }}
          />
        )}
      </div>
    </div>
  );
}
