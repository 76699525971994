import client from '../../_client';
import { LabelColor } from 'src/components/Label';

type CarePlanStatusStatus = 'active' | 'inactive';

export interface CarePlanStatus {
  id: number;
  uuid: string;
  createdBy: string | null;
  createdOn: string | null;
  updatedBy: string | null;
  updatedOn: string | null;
  deletedOn: string | null;
  deletedBy: string | null;

  name: string;
  color: LabelColor;
  abbreviation: string;
  status: CarePlanStatusStatus;
  treatAsInactive: boolean;
}

export async function findCarePlanStatuses(filter?: any) {
  const query = new URLSearchParams(filter);
  const { data } = await client.get<CarePlanStatus[]>(`/care-plan/statuses?${query}`);
  return data;
}

export async function getCarePlanStatus(id: string) {
  const { data } = await client.get<CarePlanStatus>(`/care-plan/statuses/${id}`);
  return data;
}

export async function createCarePlanStatus(status: any) {
  const { data } = await client.post<CarePlanStatus>(`/care-plan/statuses`, status);
  return data;
}

export async function updateCarePlanStatus(status: any) {
  const { data } = await client.patch<CarePlanStatus>(`/care-plan/statuses/${status.uuid}`, status);
  return data;
}

export async function deleteCarePlanStatus(id: string) {
  const { data } = await client.delete<CarePlanStatus>(`/care-plan/statuses/${id}`);
  return data;
}
