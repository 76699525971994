const ccmAcls = {
  CCM_ADMIN: 'ccm.*',
  CCM_SYSTEM_ADMIN: 'admin.*',
  PATIENT_CARD: {
    name: 'ccm.patient_card.*',
    read: 'ccm.patient_card.read',
    action: 'ccm.patient_card.action',
  },
  SEARCH: {
    name: 'ccm.searchbar.*',
    SIMPLE_SEARCH: 'ccm.searchbar.simplesearch',
    ADVANCED_SEARCH: 'ccm.searchbar.advancedsearch',
  },
  TASK_ACTIVITIES: {
    name: 'ccm.tasks_activities.*',
    MENU: 'ccm.tasks_activities.menu',
    ALL: 'ccm.tasks_activities.*',
    ACTIVITIES: {
      name: 'ccm.tasks_activities.activities.*',
      READ: 'ccm.tasks_activities.activities.read',
      WRITE: 'ccm.tasks_activities.activities.write',
    },
    PATIENTS: {
      name: 'ccm.tasks_activities.patients.*',
      READ: 'ccm.tasks_activities.patients.read',
      WRITE: 'ccm.tasks_activities.patients.write',
    },
    REFERRALS: {
      name: 'ccm.tasks_activities.referrals.*',
      READ: 'ccm.tasks_activities.referrals.read',
      WRITE: 'ccm.tasks_activities.referrals.write',
    },
    ALERTS: {
      name: 'ccm.tasks_activities.alerts.*',
      READ: 'ccm.tasks_activities.alerts.read',
      WRITE: 'ccm.tasks_activities.alerts.write',
    },
  },
  CENSUS: {
    name: 'ccm.census',
    READ: 'ccm.census.read',
  },
  ROUNDINGS: {
    name: 'ccm.roundings',
    READ: 'ccm.rondings.read',
    WRITE: 'ccm.roundings.write',
  },
  GROUP_SESSIONS: {
    name: 'ccm.group_sessions',
    READ: 'ccm.group_sessions.read',
    WRITE: 'ccm.group_sessions.write',
  },
  MED_PASS: {
    name: 'ccm.medpass',
    READ: 'ccm.medpass.read',
    WRITE: 'ccm.medpass.write',
  },
  PATIENt_DEMOGRAPHICS: {
    name: 'ccm.demographics',
    READ: 'ccm.demographics.read',
  },
  HEALTH_RECORD: {
    name: 'ccm.healthrecord',
    READ: 'ccm.healthrecord.read',
  },
  CAREPLAN: {
    name: 'ccm.careplan',
    write: 'ccm.careplan.write',
    read: 'ccm.careplan.read',
  },
  REPORTS: {
    name: 'ccm.reports',
    READ: 'ccm.reports.read',
  },
};

export default ccmAcls;
