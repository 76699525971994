// @ts-nocheck
import CalendarToday from '@mui/icons-material/CalendarToday';
import {
  Avatar,
  Card,
  Chip,
  Container,
  Grid,
  Modal,
  TablePagination,
  Typography,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { useCallback, useEffect } from 'react';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { PromptModal } from 'src/sections/careflow/common';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import { longFormat } from '../../../../../utils/formatTime';
import { ConcreteActivity } from '../../model/Activity';
import { getRange, RangeOption } from '../../utils/date-range';
import { PATEINTS_LIST_PERSON } from '../Patients/constants';
import EditPatient from '../Patients/EditPatient/EditPatient';
import PatientsFilters from '../Patients/PatientsFilter/PatientsFilter';
import { PatientsListTable } from '../Patients/PatientsListTable';
import { RangeStepper } from '../RangeStepper';
import AddReferral from '../Referrals/AddReferral/AddReferral';
import {
  REFERRALS_LIST_PERSON,
  REFERRALS_LIST_STATUS,
  REFERRALS_LIST_TYPES,
} from '../Referrals/constants';
import ReferralsFilters from '../Referrals/ReferralsFilter/ReferralsFilters';
import { ReferralsListTable } from '../Referrals/ReferralsListTable';
import ReferralViewModel from '../Referrals/ViewModel/ReferralViewModel';
import { Option } from '../ToggleButtonOptions';
import { ActivitiesProvider } from './ActivitiesContext';
import { ActivityCalendarView } from './ActivityCalendarView';
import ActivityFilters from './ActivityFilters/ActivityFilters';
import { ActivityListView } from './ActivityListView';
import {
  ACTIVITIES_LIST_TYPES,
  ACTIVITIES_VIEW_TYPES,
  activityStatusFilterOptions,
} from './constants';
import { Legend } from './Legend';
import { QuickActions } from './QuickActions';
import { EntityTypeStatesEnum, ENTITY_TYPES } from './QuickActions/constants';
import ScheduleActivity from './ScheduleActivity';
import StandardDialogProvider from '../../../common/StandardDialogProvider';
import StandardLeftDialogProvider from './StandardLeftDialogProvider';
import useController, { UseControllerReturnType } from './useController';
import { useTaskActivitiesStore } from './task-activities.store';
import moment from 'moment-timezone';
import AddModal from 'src/sections/careflow/common/AddModal';
import AddCommunication from '../../../communication/AddCommunication';
import { Communication } from '../../../../../@nicheaim/fhir-base/mappings/Communication';
import ReferralNotes from '../Referrals/ReferralNotes/ReferralNotes';
moment.tz.setDefault('America/Los_Angeles');

export function Activities() {
  const [
    // state
    {
      isLoading,
      selectedEntityType,
      entities,
      patients,
      showAddEntityModal = false,
      showDeleteEntityModal = false,
      disableDeleteButtons = false,
      selectedEntity,
      activitiesViewType = ACTIVITIES_VIEW_TYPES.LIST,
      activitiesListType = ACTIVITIES_LIST_TYPES.PATIENT,

      currentReferralsListType = REFERRALS_LIST_TYPES.OUTBOUND,
      currentReferralsListStatus = REFERRALS_LIST_STATUS.ALL,
      currentReferralsListPerson = REFERRALS_LIST_PERSON.ME,

      shouldCompletedActivity,

      patientsListPerson,

      patientId,
      selectedPatient,

      currentRange,
      pagination,

      activitiesSummary,
      referralsSummary,
      patientsSummary,
      showAddCommunicationModal,
      referralId,
    },
    // actions
    {
      updateState,
      getEntities,
      acceptActivity,
      startActivity,
      cancelActivity,
      setSelectedEntity,
      setSelectedEntityAndState,
      onSaveEntity,
      onSaveMultipleEntities,
      onDeleteEntity,
      openAddEntityModal,
      toggleAddCommunicationModal,
    },
  ]: UseControllerReturnType = useController();
  const store = useTaskActivitiesStore();
  const { themeStretch } = useSettings();
  const nowDate: Date = new Date();
  const currentPatient = useCurrentPatient();

  const showCalendarForPatient = (patient: any) => {
    updateState({
      activitiesViewType: ACTIVITIES_VIEW_TYPES.CALENDAR,
      activitiesListType: ACTIVITIES_LIST_TYPES.PATIENT,
      currentRange: {
        option: RangeOption.TODAY,
        value: getRange(RangeOption.TODAY, moment().toDate()),
      },
      patientId: patient.id,
      selectedPatient: {
        label: patient.name,
        value: patient.fhirId,
      },
      selectedEntityType: {
        entity: ENTITY_TYPES.ACTIVITIES,
        state: null,
      },
      entities: [],
      total: 0,
    });
  };

  const scheduleActivityForPatient = (patient: any, shouldCompletedActivity: boolean = false) => {
    updateState({
      activitiesViewType: ACTIVITIES_VIEW_TYPES.LIST,
      activitiesListType: ACTIVITIES_LIST_TYPES.PATIENT,
      selectedEntityType: {
        entity: ENTITY_TYPES.ACTIVITIES,
        state: EntityTypeStatesEnum.ALL,
      },
      entities: [],
      selectedEntity: {
        patientFhirId: patient?.fhirId,
      },
      showAddEntityModal: true,
      shouldCompletedActivity,
    });
  };

  const renderDeleteEntityModal = () => (
    <PromptModal
      open={showDeleteEntityModal}
      onContinuePress={onDeleteEntity}
      onCancelPress={() =>
        updateState({
          showDeleteEntityModal: false,
        })
      }
      disableButtons={disableDeleteButtons}
    />
  );

  const renderAddEntity = () => {
    let patient: { fhirId: string | undefined } = {
      fhirId: patientId,
    };

    if (activitiesListType === ACTIVITIES_LIST_TYPES.STAFF || !patientId) {
      patient = {
        fhirId: currentPatient?.id,
      };
    }

    if (selectedEntity) {
      patient = {
        fhirId: selectedEntity?.patientFhirId,
      };
    }
    let RnderAddComponent: JSX.Element = <></>;
    switch (selectedEntityType.entity.identifier) {
      case ENTITY_TYPES.PATIENTS.identifier:
        RnderAddComponent = (
          <EditPatient
            userPatient={selectedEntity}
            onSaveCallback={onSaveEntity}
            patient={patient}
          />
        );
        break;
      case ENTITY_TYPES.ACTIVITIES.identifier:
        RnderAddComponent = (
          <ScheduleActivity
            activity={selectedEntity}
            onSaveCallback={onSaveEntity}
            patient={patient}
            shouldCompletedActivity={shouldCompletedActivity!}
          />
        );
        break;
      case ENTITY_TYPES.REFERRALS.identifier:
        RnderAddComponent = (
          <AddReferral
            referral={selectedEntity}
            onSaveCallback={onSaveEntity}
            onSaveMultipleCallback={onSaveMultipleEntities}
            // patient={patient}
          />
        );
        break;
      case ENTITY_TYPES.ALERTS.identifier:
        break;
    }

    return showAddEntityModal ? (
      <AddModal
        Component={RnderAddComponent}
        open={showAddEntityModal}
        onClose={() =>
          updateState({
            showAddEntityModal: false,
            shouldCompletedActivity: false,
          })
        }
        containerStyle={{
          width: '450px',
        }}
      />
    ) : null;
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    getEntities({ pagination: { take: pagination?.take ?? 0, page: newPage } });
  };

  // TODO: Extract to a component
  const renderPagination = () => (
    <TablePagination
      component="div"
      count={pagination?.total ?? 0}
      page={pagination?.page ?? 0}
      onPageChange={handleChangePage}
      rowsPerPage={pagination?.take ?? 0}
      rowsPerPageOptions={[]}
      labelDisplayedRows={() =>
        `Showing ${
          entities?.length ?? 0
        } ${selectedEntityType.entity.title?.toLowerCase()} | Page ${
          pagination && pagination?.total && pagination?.total > 0 ? pagination?.page + 1 : 0
        } of ${pagination && pagination?.total && Math.ceil(pagination?.total / pagination?.take)}`
      }
      showFirstButton
      showLastButton
    />
  );

  // TODO: Extract to a component
  const renderMyActivities = () => (
    <>
      <Grid item xl={12}>
        <Grid container spacing={4}>
          <ActivityFilters
            activitiesViewType={activitiesViewType}
            activitiesListType={activitiesListType}
            patients={patients}
            selectedPatient={selectedPatient}
            status={store.status}
            dueStatus={store.dueStatus}
            activitiesRange={currentRange.option}
            onChangeCurrentRange={(selectedOption: Option) => {
              const rangeOption = selectedOption.value as RangeOption;
              const now: Date = new Date();
              updateState({
                activitiesViewType: ACTIVITIES_VIEW_TYPES.LIST,
                currentRange: {
                  option: rangeOption,
                  value: getRange(rangeOption, now),
                },
              });
            }}
            onChangeActivitiesViewType={(viewType: ACTIVITIES_VIEW_TYPES) => {
              updateState({ activitiesViewType: viewType });
            }}
            onChangeActivitiesListType={(listType: ACTIVITIES_LIST_TYPES) => {
              updateState({ activitiesListType: listType, patientId: null });
            }}
            onChangePatient={(currentSelectedPatient: any | null) => {
              updateState({ patientId: currentSelectedPatient.value });
              updateState({ selectedPatient: currentSelectedPatient })
            }}
            onChangeStatus={(_: any, value: any | null) => {
              store.setStatus(value.value);
              // TODO: Remove this updateState call after separating out functionalities
              updateState((prevState: any) => ({
                selectedEntityType: {
                  entity: prevState.selectedEntityType.entity,
                  state: EntityTypeStatesEnum.ALL,
                },
              }));
            }}
            onChangeDueStatus={(_: any, value: any | null) => {
              store.setDueStatus(value.value);
              // TODO: Remove this updateState call after separating out functionalities
              updateState((prevState: any) => ({
                selectedEntityType: {
                  entity: prevState.selectedEntityType.entity,
                  state: EntityTypeStatesEnum.ALL,
                },
              }));
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <RangeStepper
          range={currentRange}
          onDateRangeChanged={(newRange) => updateState({ currentRange: newRange })}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card>
          <>
            {activitiesViewType === ACTIVITIES_VIEW_TYPES.CALENDAR && (
              <ActivityCalendarView
                now={nowDate}
                activities={entities as ConcreteActivity[]}
                currentRange={currentRange}
                patientId={patientId ?? ''}
                isLoading={isLoading}
                total={pagination?.total ?? 0}
              />
            )}
            {activitiesViewType === ACTIVITIES_VIEW_TYPES.LIST && (
              <ActivityListView
                isLoading={isLoading}
                now={nowDate}
                activities={entities as ConcreteActivity[]}
                total={pagination?.total ?? 0}
                currentRange={currentRange}
                patientId={patientId ?? ''}
                isPatientView={activitiesListType === ACTIVITIES_LIST_TYPES.PATIENT}
              />
            )}
            <Legend />
            {renderPagination()}
          </>
        </Card>
      </Grid>
    </>
  );

  useEffect(() => {
    if (
      selectedEntityType?.entity?.identifier === ENTITY_TYPES.REFERRALS.identifier &&
      selectedEntityType?.state &&
      Object.values(REFERRALS_LIST_STATUS).includes(selectedEntityType?.state)
    ) {
      updateState({ currentReferralsListStatus: selectedEntityType?.state });
    }
  }, [selectedEntityType?.state, selectedEntityType?.entity?.identifier]);

  const renderAddCommunicationModal = () => (
    <Modal
      open={showAddCommunicationModal!}
      onClose={() => toggleAddCommunicationModal()}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '40px' }}
    >
      <ReferralNotes
        referral={selectedEntity}
        onCancel={() => toggleAddCommunicationModal()}
      />
    </Modal>
  );

  const renderMyReferrals = () => (
    <>
      <Grid item xl={12}>
        <Grid container spacing={4}>
          <ReferralsFilters
            currentReferralsListType={currentReferralsListType}
            currentReferralsListStatus={currentReferralsListStatus}
            currentReferralsListPerson={currentReferralsListPerson}
            status={selectedEntityType.state}
            onChangeReferralsListStatus={(listStatus: REFERRALS_LIST_STATUS) => {
              console.log('DEBUG listStatus: ', listStatus);
              updateState({ currentReferralsListStatus: listStatus });
            }}
            onChangeReferralsListType={(listType: REFERRALS_LIST_TYPES) => {
              updateState({ currentReferralsListType: listType });
            }}
            onChangeReferralsListPerson={(listPeson: REFERRALS_LIST_PERSON) => {
              updateState({ currentReferralsListPerson: listPeson });
            }}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <RangeStepper
            range={currentRange}
            onDateRangeChanged={(newRange) => updateState({ currentRange: newRange })}
          />
        </Grid>
        <Grid item xl={12}>
          <Card>
            <>
              <ReferralsListTable
                referrals={entities as ReferralViewModel[]}
                isLoading={isLoading}
                onDeleteItem={(referral) =>
                  updateState({ showDeleteEntityModal: true, selectedEntity: referral })
                }
              />
              {renderPagination()}
            </>
          </Card>
        </Grid>
      </Grid>
    </>
  );

  const debounceChangePatientSearch = useCallback(
    debounce((value: string) => updateState({ patientSearch: value }), 700),
    []
  );

  const renderMyPatients = () => (
    <>
      <Grid item xl={12}>
        <Grid container spacing={4}>
          <PatientsFilters
            selectedPatientListPerson={patientsListPerson}
            onChangePatientsListPerson={(listPeson: PATEINTS_LIST_PERSON) => {
              updateState({ patientsListPerson: listPeson });
            }}
            onClearStatusFilter={() => {
              updateState((prevState: any) => ({
                selectedEntityType: {
                  entity: prevState.selectedEntityType.entity,
                  state: EntityTypeStatesEnum.PATIENT_ALL,
                },
              }));
            }}
            onChangeSearch={debounceChangePatientSearch}
          />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end">
          <RangeStepper
            range={currentRange}
            onDateRangeChanged={(newRange) => updateState({ currentRange: newRange })}
          />
        </Grid>
        <Grid item xl={12}>
          <Card>
            <>
              <PatientsListTable
                isLoading={isLoading}
                patients={entities}
                filterStatus={selectedEntityType.state}
                onDeleteItem={(patient) =>
                  updateState({ showDeleteEntityModal: true, selectedEntity: patient })
                }
              />
              {renderPagination()}
            </>
          </Card>
        </Grid>
      </Grid>
    </>
  );

  const renderSelectedView = () => {
    switch (selectedEntityType.entity.identifier) {
      case ENTITY_TYPES.PATIENTS.identifier:
        return renderMyPatients();
      case ENTITY_TYPES.ACTIVITIES.identifier:
        return renderMyActivities();
      case ENTITY_TYPES.REFERRALS.identifier:
        return renderMyReferrals();
      case ENTITY_TYPES.ALERTS.identifier:
    }
  };

  return (
    <ActivitiesProvider
      value={{
        setSelectedEntity,
        acceptActivity,
        startActivity,
        cancelActivity,
        setSelectedEntityAndState,
        openAddEntityModal,
        onCreateOrUpdateEntity: onSaveEntity,
        onCreateOrUpdateMultipleActivities: onSaveMultipleEntities,
        showCalendarForPatient,
        scheduleActivityForPatient,
        toggleAddCommunicationModal,
      }}
    >
      <Page title="Tasks / Activities">
        <StandardDialogProvider>
          <StandardLeftDialogProvider>
            {/* ===================== DELETE ENTITY BEGIN =====================   */}
            {renderDeleteEntityModal()}
            {/* ===================== SCHEDULE NEW ENTITY BEGIN ===================== */}
            {renderAddEntity()}
            {/* ===================== SCHEDULE NEW ENTITY END ===================== */}
            <Container maxWidth={themeStretch ? false : 'xl'}>
              <Grid container spacing={3}>
                {/* ===================== HEADER BREADCRUMPS BEGIN ===================== */}
                <Grid item xs={12}>
                  <Breadcrumbs
                    title="Tasks / Activities"
                    links={[
                      {
                        href: '/dashboard',
                        name: 'Dashboard',
                      },
                      {
                        href: '',
                        name: selectedEntityType.entity.title,
                      },
                    ]}
                  />
                </Grid>
                {/* ===================== HEADER BREADCRUMPS END ===================== */}

                {/* ===================== QUICK ACTIONS BEGIN ===================== */}
                <QuickActions
                  summaries={{
                    activitiesSummary,
                    referralsSummary,
                    patientsSummary,
                  }}
                />
                {/* ===================== QUICK ACTIONS END ===================== */}

                {/* ===================== HEADER TITLE BEGIN ===================== */}
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mx: 1, fontWeight: 'bold', mb: -2 }}>
                    {selectedEntityType.entity.identifier === -1
                      ? 'No Access'
                      : 'My ' + selectedEntityType.entity.title}{' '}
                    {
                      activityStatusFilterOptions.find(
                        (i) =>
                          i.key ===
                          `${selectedEntityType.entity.title?.toLowerCase()}_${selectedEntityType.state
                            ?.toLowerCase()
                            .replace(' ', '_')}`
                      )?.label
                    }
                  </Typography>
                </Grid>
                {/* ===================== HEADER TITLE END ===================== */}

                {/* ===================== ENTITY LIST BEGIN ===================== */}
                <Grid item xs={12}>
                  <Chip
                    label={longFormat(nowDate)}
                    variant="outlined"
                    color="primary"
                    sx={{ mx: 1 }}
                    avatar={
                      <Avatar>
                        <CalendarToday sx={{ fontSize: '10pt' }} />
                      </Avatar>
                    }
                  />
                </Grid>
                {renderSelectedView()}
                {renderAddCommunicationModal()}
                {/* ===================== ENTITY LIST END ===================== */}
              </Grid>
            </Container>
          </StandardLeftDialogProvider>
        </StandardDialogProvider>
      </Page>
    </ActivitiesProvider>
  );
}
