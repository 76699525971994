import { UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as actions from './actions';
import { useMemo } from 'react';

export function useExitDestinations(
  options?: Omit<UseQueryOptions<actions.ProgramExitDestination[], unknown>, 'queryFn' | 'queryKey'>
) {
  return useQuery(['program-exit-destinations'], () => actions.getExitDestinations(), options);
}

export function useActiveExitDestinations(
  options?: Omit<UseQueryOptions<actions.ProgramExitDestination[], unknown>, 'queryFn' | 'queryKey'>
) {
  const query = useExitDestinations(options);

  return useMemo(
    () => ({
      ...query,
      data: (query.data || []).filter((item) => item.isActive),
    }),
    [query]
  );
}

export function useExitDestination<TError, TData>(
  uuid: string,
  options?: Omit<
    UseQueryOptions<
      actions.ProgramExitDestination,
      TError,
      actions.ProgramExitDestination,
      string[]
    >,
    'initialData' | 'queryFn' | 'queryKey'
  >
) {
  return useQuery(
    ['program-exit-destinations', uuid],
    () => actions.getExitDestination(uuid),
    options
  );
}
