import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router';
import api from 'src/services/api';
import OpportunitiesForm from './OpportunitiesForm';
import OpportunitiesTable from './OpportunitiesTable';
import OpportunitiesToolbar from './OpportunitiesToolbar';

function OpportunitiesTab() {
  return (
    <Routes>
      <Route index element={<OpportunitiesList />} />
      <Route path="new" element={<OpportunitiesForm />} />
      <Route path="edit/:id" element={<OpportunitiesEditForm />} />
    </Routes>
  );
}

function OpportunitiesList() {
  return (
    <>
      <OpportunitiesToolbar />
      <OpportunitiesTable />
    </>
  );
}

function OpportunitiesEditForm() {
  const params = useParams();
  const {
    data: opportunity,
    isLoading,
    refetch,
  } = useQuery(['opportunities', params.id], async () => {
    if (!params.id) {
      return null;
    }

    return api.carePlan.getOpportunity(params.id);
  });

  useEffect(() => {
    refetch();
  }, []);

  if (!opportunity && !isLoading) return <Navigate to=".." />;
  return <OpportunitiesForm isEdit currentOpportunity={opportunity} />;
}

export default Object.assign(OpportunitiesTab, {
  path: 'opportunities',
});
