import client from '../_client';

export interface GetMedicationParams {
  search?: string;
  take?: number
}

export async function getMedications({ search, take }: GetMedicationParams) {
  let url = `/ccm/medication/list?`;

  if (search) {
    url = `${url}&search=${search}`;
  }

  if (take) {
    url = `${url}&take=${take}`;
  }
  const { data } = await client.get<any[]>(url);

  return data;
}