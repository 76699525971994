import { MouseEvent, ReactElement, useState } from 'react';
import {
  Grid,
  Typography,
  Card,
  IconButton,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableRow,
  TableBody,
  Menu,
} from '@mui/material';
import Iconify from 'src/components/Iconify';

interface SummaryTableProps {
  actionBar?: ReactElement;
  columns?: string[];
  contextMenu?: ReactElement;
  contextMenuTitle?: string;
  data?: any;
  rowRenderer: (row: any, index: number) => ReactElement;
  title?: string;
}

const SummaryTable = ({
  actionBar,
  columns,
  contextMenu,
  contextMenuTitle,
  data,
  rowRenderer,
  title,
}: SummaryTableProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  return (
    <Card>
      <Grid container>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 2 }}
          xs={12}
        >
          <Grid item>
            <Typography variant="subtitle1">{title}</Typography>
          </Grid>
          <Grid item>{actionBar}</Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table size="small" sx={{ mb: 2 }}>
              {columns && (
                <TableHead>
                  <TableRow>
                    {columns.map((label: any, index: number) => (
                      <TableCell key={`col-${index}`}>{label}</TableCell>
                    ))}
                    {contextMenu && <TableCell>{contextMenuTitle}</TableCell>}
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {data?.map((row: any, index: number) => (
                  <TableRow key={`row-${index}`}>
                    {rowRenderer(row, index)}
                    {/* {contextMenu && (
                      <TableCell align="right">
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={closeMenu}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                          {contextMenu}
                        </Menu>
                        <IconButton onClick={openMenu}>
                          <Iconify icon="eva:more-vertical-fill" />
                        </IconButton>
                      </TableCell>
                    )} */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SummaryTable;
