import { 
  Button, 
  Card, 
  Dialog, 
  DialogActions, 
  DialogTitle, 
  Grid, 
  Stack, 
  TextField 
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

type EditOwnedProps = {
  open:boolean;
  onClose: VoidFunction;
  users: any[];
  ownedId: string;
  onOwnedAssign: (owned: string) => Promise<any>;
};

const AssignUser = ({ open, onClose, users, ownedId, onOwnedAssign }: EditOwnedProps ) => {

  const { enqueueSnackbar } = useSnackbar();

  const [ ownedSelected, setOwnedSelected ] = useState('');

  useEffect(() => {
    setOwnedSelected(ownedId)
  }, [ownedId]);

  const handleClose = () => {
    onClose();
  };

  const onSubmit =  async () => {
    
    try{
      await onOwnedAssign(ownedSelected);
      handleClose();
      enqueueSnackbar('Case assigned');

    }catch(e){
      enqueueSnackbar('Case was not assigned.', { variant:'error' });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>Assinged To</DialogTitle>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2}  sx={{ p: 2 }}>
              <TextField
                select
                fullWidth
                SelectProps={{ native: true }}
                value={ownedSelected}
                onChange={(ev) => setOwnedSelected(ev.target.value)}
              >
                {users?.map((option) => (
                  <option key={`us-${option.uuid}`} value={option.id}>
                    {`${option.firstName} ${option.lastName}`}
                  </option>
                ))}
              </TextField>
              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>

              <Button variant="contained" color="info" onClick={handleClose}>
                Cancel
              </Button>

              <Button variant="contained" color="info" onClick={onSubmit}>
                Submit
              </Button>
            </DialogActions>
          </Stack>
        </Card>
    </Dialog>
  );
};

export default AssignUser;