import { FORESIGHT_REPORTS_LAUNCH_URL } from 'src/config';
import useSmartLauncher from 'src/hooks/useSmartLauncher';

interface IReportsProps {
  iamUserId: string;
  fhirUserId: string;
  patientId: string | null;
}

export default function ReportsIframe({ iamUserId, fhirUserId, patientId }: IReportsProps) {
  const { isDone, launchUrl, errorMsg } = useSmartLauncher({
    iamUserId,
    fhirUserId: fhirUserId,
    patientId,
    smartAppBaseLaunchUrl: FORESIGHT_REPORTS_LAUNCH_URL,
  });

  if (errorMsg) {
    return <div>{errorMsg}</div>;
  }

  return isDone && launchUrl ? (
    <iframe
      scrolling="no"
      src={launchUrl}
      title="foresights"
      style={{ border: 0, minHeight: '70vh', height: '100%', width: '100%' }}
    />
  ) : (
    <div>loading</div>
  );
}
