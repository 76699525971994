import { Typography, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import { Select } from 'src/components/Select';
import api from 'src/services/api';
import useCarePlanAutomationFormStore, {
  AutomationRule,
  useCarePlanAutomationFormError,
  useCarePlanAutomationFormRule,
} from '../../store';

interface InterventionSelectorProps {
  question: WrappedQuestionnaireItem;
  rule: AutomationRule;
}

export default function InterventionSelector({ question, rule }: InterventionSelectorProps) {
  const saving = useCarePlanAutomationFormStore((s) => s.loading);
  const { enabled } = useCarePlanAutomationFormRule(rule.id);
  const { intervention, setIntervention } = useCarePlanAutomationFormRule(rule.id);
  const error = useCarePlanAutomationFormError(`rule(${question.linkId}:${rule.id})-intervention`);
  const { data: interventions = [], isLoading } = useQuery(
    ['care-plan-automation/interventions'],
    () => api.carePlan.findInterventions()
  );

  const options = interventions.map((i) => ({
    value: `${i.id}`,
    label: i.name,
  }));

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
      <Typography sx={{ minWidth: 120, pb: error ? 2 : 0 }}>Intervention:</Typography>
      <Select
        styles={{
          container: (provided) => ({
            ...provided,
            flex: 1,
          }),
        }}
        error={error}
        isDisabled={isLoading || !enabled || saving}
        isLoading={isLoading}
        placeholder="Select Intervention"
        options={options}
        value={options.find((o) => o.value === intervention)}
        onChange={(option) => {
          setIntervention(option?.value || '');
        }}
      />
    </Stack>
  );
}
