import { createContext, ReactNode, useEffect, useReducer, useState } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from 'src/@types/auth';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { getAcls, getAclsFromStorage } from 'src/services/api/permissions';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<
  (JWTContextType & { validateCareflowToken: () => boolean; getCurrentUser: () => any }) | null
>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const [isAclLoading, setIsAclLoading] = useState(true);

  useEffect(() => {
    const getAsyncAcls = async () => {
      await getAcls();
      setIsAclLoading(false);
    };
    getAsyncAcls();
  }, []);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/api/account/my-account');
          const { user } = response.data;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post('/api/account/login', {
      email,
      password,
    });
    const { accessToken, user } = response.data;
    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const validateCareflowToken = () => {
    const careflowToken = Cookies.get('careflowToken');

    if (careflowToken && isValidToken(careflowToken)) {
      const decoded = jwtDecode<any>(careflowToken);
      const user = {
        displayName: decoded.preferred_username,
        email: decoded.email,
      };
      dispatch({
        type: Types.Login,
        payload: {
          user,
        },
      });
      return true;
    }
    return false;
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const getCurrentUser = () => {
    // getACLS()
    const careflowToken = Cookies.get('careflowToken');
    const acls = getAclsFromStorage();

    if (careflowToken) {
      const decoded = jwtDecode<any>(careflowToken);
      const user = {
        user_fhir_uri: decoded.user_fhir_uri,
        organization_fhir_uri: decoded.organization_fhir_uri,
        id: decoded.user_uid,
        displayName: decoded.preferred_username,
        name: decoded.name,
        email: decoded.email,
        tenant: decoded.tenant,
        role: decoded.role,
        organization: decoded.organization,
        userName: decoded.username,
        acls,
      };
      return user;
    }

    return null;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        validateCareflowToken,
        getCurrentUser,
      }}
    >
      {!isAclLoading && children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
