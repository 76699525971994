import { ActivityStatus, DateRange } from '../../model';
import { ConcreteActivity } from '../../model/Activity';
import { chain, sortBy } from 'lodash';
import { compareAsc, startOfDay } from 'date-fns';

export interface DayActivities {
  readonly date: Date;
  readonly activities: ConcreteActivity[];
  readonly status?: ActivityStatus;
}

export function getFhirIdFromReferenceString(fhirReferenceString: string) {
  const referenceSplitted = fhirReferenceString?.split('/');
  return referenceSplitted?.length > 1 ? referenceSplitted[1] : undefined;
}

export function getSortedActivities(
  now: Date,
  activities: ConcreteActivity[],
  dateRange?: DateRange
): ConcreteActivity[] {
  return sortBy(
    activities
      .filter((activity) => !dateRange || activity.isInRange(dateRange))
      .map((activity) => 
        // activity.updateStatus(now);
         activity
      ),
    ['start', 'end']
  );
}

export function getPastDueActivities(
  now: Date,
  activities: ConcreteActivity[]
): ConcreteActivity[] {
  return getSortedActivities(now, activities).filter((activity) => activity.isPastDue());
}

export function getActivitiesByStatus(
  now: Date,
  activities: ConcreteActivity[],
  status: ActivityStatus
): ConcreteActivity[] {
  console.log({
    now,
    activities,
    status,
  });
  return getSortedActivities(now, activities).filter((activity) => activity.status === status);
}

export function getActivitiesByDate(
  now: Date,
  activities: ConcreteActivity[],
  dateRange: DateRange
): DayActivities[] {
  const nonPastDueActivities = getSortedActivities(now, activities, dateRange).filter(
    (activity) => !activity.isPastDue()
  );
  const uniqueDaysMap = nonPastDueActivities.reduce((uniqueDays, activity) => {
    uniqueDays.set(activity.start.toDateString(), activity.start);
    uniqueDays.set(activity.end.toDateString(), activity.end);
    return uniqueDays;
  }, new Map<String, Date>());
  const uniqueDays = Array.from(uniqueDaysMap.values()).map(startOfDay).sort(compareAsc);
  return uniqueDays.reduce((dayActivities, date) => {
    dayActivities.push({
      date,
      activities: nonPastDueActivities.filter((activity) => activity.containsDay(date)),
    });
    return dayActivities;
  }, [] as DayActivities[]);
}

export function getPatientNamesFromActivities(activities: ConcreteActivity[]): string[] {
  return chain(activities).groupBy('patientName').keys().sort().value();
}
