import { Typography } from "@mui/material";
import moment from "moment";
import { DocumentReference } from "src/@nicheaim/fhir-base/mappings/DocumentReference"
import { shortFormat } from "src/utils/formatTime";

export const SUMMARY_ATTACHMENTS_COLUMNS = [
  {
    header: 'Type',
    field: '$',
    render: (attachment: DocumentReference) => <Typography>{attachment.type?.coding?.[0].display}</Typography>,
  },
  {
    header: 'Name / Description',
    field: '$',
    render: (attachment: DocumentReference) => <Typography>{attachment?.content?.[0]?.attachment?.title}</Typography>,
  }
];

export const EXPANDED_ATTACHMENTS_COLUMNS = [
  ...SUMMARY_ATTACHMENTS_COLUMNS,
  {
    header: 'Date',
    field: 'date',
    render: (value: Date) => <Typography>{shortFormat(moment(value).toDate())}</Typography>,
  },
];