// @ts-nocheck
import { capitalize } from 'lodash';
import {
  Address,
  CareTeamParticipant,
  CodeableConcept,
  ContactPoint,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

export const getCareTeamRoles = (member: CareTeamParticipant) =>
  member?.role
    ?.filter((r: CodeableConcept) => r?.coding?.find((c) => c.system === 'careTeamRole'))
    ?.map((r) => r?.text)
    ?.filter((r) => r)
    ?.join(' / ');

export const getCareTeamRolesExcludingLeader = (member: CareTeamParticipant) =>
  member?.role
    ?.filter((r: CodeableConcept) => r?.coding?.find((c) => c.system === 'careTeamRole') && r?.text !== 'Team Leader')
    ?.map((r) => r?.text)
    ?.filter((r) => r)
    ?.join(' / ');

export const isCareTeamLeader = (member: CareTeamParticipant) =>
  member?.role?.some(
    (r: CodeableConcept) => r?.text === 'Team Leader'
  );

export const formatAddress = (address: Address) => {
  if (!address) return '';
  const addr = [];
  if (address?.line) addr.push(address.line.filter((l) => l).join(', '));
  if (address?.city) addr.push(address.city);
  if (address?.state) addr.push(address.state);
  if (address?.postalCode) addr.push(address.postalCode);
  if (address?.use) {
    addr.push(`(${capitalize(address.use)})`)
  } else {
    addr.push(`(Other)`);
  }
  return addr.join(', ');
};

export const normalizeAddresses = (addresses: Address[]) =>
  addresses.map(address => normalizeAddress(address));

export const normalizeAddress = (address: Address) => ({
  city: address.city,
  line: address.line,
  postalCode: address.postalCode,
  state: address.state,
  ...(address.use !== 'other' ? { use: address.use } : {}),
});

export const normalizePhone = (phone: ContactPoint) => ({
  system: phone.system,
  value: phone.value,
  ...(phone.use === 'other' ? {} : { use: phone.use })
});

export const normalizePhones = (phones: ContactPoint[]) =>
  phones.map(phone => normalizePhone(phone));
