// @ts-nocheck
import { Box, Card, Container, Divider, Grid, IconButton, Modal, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import Page from 'src/components/Page';
import Breadcrumbs from 'src/components/Breadcrumbs';
import Filters from './components/Filters/Filters';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MedicationAdministration } from 'src/@nicheaim/fhir-base/mappings/MedicationAdministration';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import moment from 'moment';
import ComponentWithLoading from '../common/ComponentWithLoading';
import TabItem from '../group-sessions/types/TabItem';
import { MedicationRequest } from 'src/@nicheaim/fhir-base/mappings/MedicationRequest';
import { useQuery } from '@tanstack/react-query';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import { MedicationDispense } from 'src/@nicheaim/fhir-base/mappings/MedicationDispense';
import { Route, Routes, useNavigate } from 'react-router';
import Iconify from 'src/components/Iconify';
import MedicationAdministrationsSummary from '../health-record/medication-administrations/MedicationAdministrationsSummary';
import { getFhirIdFromReferenceString } from '../tasks-activities/components/Activities/activities-utils';

const TAB_VALUES: TabItem[] = [
  {
    key: 0,
    value: 1,
    label: 'Incomplete',
  },
  {
    key: 1,
    value: 2,
    label: 'Completed',
  },
];

const medPassColumns = [
  "Taken",
  "Time",
  "Patient",
  "Medication",
  "Quantity",
  "Inv",
  "Refills",
  "PRN",
  "Staff",
  "Order",
];

export const MedPass = () => {
  const navigate = useNavigate();
  const [dispenses, setDispenses] = useState<MedicationDispense[]>([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(TAB_VALUES[0].key as number);
  const [date, setDate] = useState<Date | null>(new Date());
  const [selectedLocation, setSelectedLocation] = useState<{ label: string; value: string; } | undefined>(undefined);
  const status = selectedTabIndex === 0 ? 'in-progress' : 'completed,not-done';
  const start = moment(date).startOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
  const end = moment(date).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');
  const location = selectedLocation ? `&identifier=${selectedLocation?.value}` : '';
  const [selectedPatientId, setSelectedPatientId] = useState<string | undefined | null>(null);

  const getMedicationAdministrations = async () =>
    axiosFhirInstance.get(
      `/MedicationAdministration?_sort=-authoredOn&_include=MedicationAdministration:request&status=${status}&effective-time=ge${start}&effective-time=le${end}${location}`
    );

  const { data, isLoading, refetch } = useQuery(['medication-administrations'], getMedicationAdministrations);
  
  const medicationAdministrations = useMemo(() => data?.data?.entry?.map(i => i.resource) ?? [], [data]);

  const administrations: MedicationAdministration[]
    = useMemo(() =>
      medicationAdministrations
      .filter(i => i.resourceType === 'MedicationAdministration'),
      [medicationAdministrations]
    );
  const medicationRequests: MedicationRequest[]
    = useMemo(() =>
      medicationAdministrations
      .filter(i => i.resourceType === 'MedicationRequest'),
      [medicationAdministrations]
    );

  const medPassOptions: (MedicationAdministration & { medicationRequest: MedicationRequest })[] = useMemo(() => administrations.map(ads => ({
      ...ads,
      medicationRequest: medicationRequests.find(r => r.id === getFhirIdFromEntity(ads.request?.reference ?? '')),
    }))
  , [administrations, medicationRequests])

  const renderTabs = useCallback(
    () => (
      <Tabs value={selectedTabIndex} variant="scrollable" scrollButtons="auto">
        {TAB_VALUES?.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            onClick={() => {
              setSelectedTabIndex(tab.key as number);
            }}
            id={`status-tab-${tab.key}`}
            {...{
              'aria-controls': `status-tabpanel-${tab.key}`,
            }}
          />
        ))}
      </Tabs>
    ),
    [selectedTabIndex]
  );

  useEffect(() => {
    refetch();
  }, [date, refetch, selectedLocation, status]);

  useEffect(() => {
    const getData = async (item: MedicationAdministration) => {
      const { data } = await axiosFhirInstance
      .get(`/MedicationDispense?prescription=${item.request?.reference?.split('/')?.[1]}&_sort-whenPrepared`);

      return data?.entry?.map((i: any) => i.resource) ?? [];
    }

    medicationAdministrations.forEach((i: MedicationAdministration) => {
      getData(i).then((data) => {
        setDispenses(p => ([
          ...p,
          ...data
        ]));
      })
    })
  }, [medicationAdministrations]);

  return (
    <Page title="MedPass">
      <Routes>
        <Route
          path="medication-details/:medicationId/:date"
          element={
            <Modal
              open={true}
              onClose={() => {
                setSelectedPatientId(null);
                navigate('/dashboard/med-pass');
              }}
            >
              <Box
                sx={{
                  position: 'absolute' as 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '80%',
                  bgcolor: 'background.paper',
                  border: '2px solid #000',
                  boxShadow: 24,
                  borderRadius: 2,
                }}
              >
                <MedicationAdministrationsSummary
                  patient={{
                    id: selectedPatientId,
                  }}
                  isExpanded={true}
                  onExpand={() => {}}
                  showExpandButton={false}
                  showPatientColumn={false}
                  showPatientHeader={true}
                />
              </Box>
            </Modal>
          }
        />
      </Routes>
      <Container maxWidth={'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Breadcrumbs
              title='Med Pass'
              links={[
                {
                  href: '/dashboard',
                  name: 'Dashboard',
                },
                {
                  href: '',
                  name: 'Med Pass',
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} >
          <Grid container sx={{ mt: 3 }}>
            <Filters
              onChangeLocation={(value) => setSelectedLocation(value)}
              onChangeDate={(value) => setDate(value)}
              date={date}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} >
          <Card sx={{ marginBottom: 2, marginTop: 3 }}>
            <TableContainer sx={{ padding: 2 }}>
              <Grid justifyContent="space-between" container direction="row">
                <Grid item xl={9}>
                  {renderTabs()}
                </Grid>
              </Grid>
              <Divider sx={{ marginBottom: 2 }} />
              <ComponentWithLoading
                isLoading={isLoading}
                Component={
                  <Table>
                    <TableHead>
                      <TableRow>
                        {medPassColumns.map((col: any, index: number) => (
                          <TableCell style={{ width: col }} key={index}>
                            <Typography>{col}</Typography>
                          </TableCell>
                        ))}
                        <TableCell>&nbsp;</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {medPassOptions.map((item, index) => (
                        <TableRow key={index} style={{ cursor: 'pointer' }}>
                          <TableCell>
                            <Typography>{item.status === 'completed' ? 'Yes' : 'No'}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{moment(item.effectiveDateTime).format('MMM DD YYYY hh:mm a')}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{item.subject.display}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{item.medicationReference?.display}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{item.dosage?.rateQuantity?.value ?? 0}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{dispenses.find(i => i.authorizingPrescription?.[0].reference === item?.request?.reference)?.quantity?.value}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{item.medicationRequest?.dispenseRequest?.numberOfRepeatsAllowed}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{item.medicationRequest?.dosageInstruction?.[0]?.asNeededBoolean ? 'Yes' : 'No'}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{item.performer?.[0]?.actor.display ?? 'N/A'}</Typography>
                          </TableCell>
                          <TableCell>
                            <IconButton onClick={() =>
                              navigate(`/dashboard/patient/${getFhirIdFromReferenceString(item.subject?.reference ?? '')}/health-record/medication-request/${getFhirIdFromEntity(item.request?.reference ?? '')}`)}>
                              <Typography color="green">{item.request?.display || 'Go to order'}</Typography>
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <Iconify
                              icon="material-symbols:open-in-new"
                              style={{
                                width: 30,
                                height: 30,
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                setSelectedPatientId(getFhirIdFromReferenceString(item.subject.reference ?? ''));
                                navigate(`/dashboard/med-pass/medication-details/${item.id}/${moment(date).format('YYYY-MM-DD')}`);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                }
              />
              {(medPassOptions.length === 0 && !isLoading) && (
                <Grid
                  item
                  justifyItems={'center'}
                  alignContent="center"
                  alignItems={'center'}
                  sx={{ padding: 4, width: '100%' }}
                >
                  <Typography variant="h6" textAlign="center">
                    No Medications
                  </Typography>
                </Grid>
              )}
            </TableContainer>
          </Card>
        </Grid>
      </Container>
    </Page>
  );
};
