import { isAllowed } from './permission.utils';

export interface PermissionsContainer {
  [key: string]: any;
  isAllowed(...requiredPermissions: string[]): boolean;
}

export interface PermissionsExtractor {
  (permissionsSource: any): string[];
}

export class PermissionsExtractionError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export function newPermissionsContainer(
  permissionsSource: string[] | any,
  permissionsExtractor?: PermissionsExtractor
): PermissionsContainer {
  if (!permissionsSource) {
    return NO_ALLOWING_CONTAINER;
  }

  let permissions: string[];
  if (!permissionsExtractor) {
    if (isStringArray(permissionsSource)) {
      permissions = permissionsSource;
    } else {
      throw new PermissionsExtractionError('Permissions extractor function was not provided.');
    }
  } else {
    try {
      permissions = permissionsExtractor(permissionsSource);
    } catch (error) {
      throw new PermissionsExtractionError(
        `Unable to extract permissions from source: "${error.message}"`
      );
    }
  }
  return {
    permissions,

    isAllowed(...requiredPermissions): boolean {
      return isAllowed(permissions, ...requiredPermissions);
    },
  };
}

export const NO_ALLOWING_CONTAINER: PermissionsContainer = {
  isAllowed(..._): boolean {
    return false;
  },
};

function isStringArray(obj: any): boolean {
  return Array.isArray(obj) && obj.every((item) => typeof item === 'string');
}
