import * as assessments from './assessments';
import * as users from './users';
import * as organizations from '../../sections/settings/care-plan-automation/organizations';
import * as roles from './roles';
import * as carePlan from './care-plan';
import * as notes from './notes';
import * as ccmNotes from './ccm-notes';
import * as activities from './activities';
import * as patients from './patients';
import * as documents from './documents';
import * as referrals from './referrals';
import * as roundings from './rounding';
import * as groupSessions from './groupSessions';
import * as settings from './settings';

const api = {
  assessments,
  users,
  organizations,
  roles,
  carePlan,
  notes,
  ccmNotes,
  activities,
  patients,
  documents,
  referrals,
  roundings,
  groupSessions,
  settings,
};

export default api;
