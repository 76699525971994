import { capitalCase } from 'change-case';
import { Box, Tab, Tabs } from '@mui/material';
import useTabs from '../../../../hooks/useTabs';
import ContactDetails from './details/ContactDetails';
import PatientGroups from './PatientGroups';
import PatientInsurance from './PatientInsurance';
import PatientReferralCases from './PatientReferralCases';
import PersonalDetails from './details/PersonalDetails';
import { PatientDetails } from '../PatientDetails';
import { Section, TwoColumnLayout } from 'src/utils/layout';
import { IdentifierDetails } from './details/IdentifierDetails';
import { NewAddressDetails } from './details/NewAddressDetails';
import PatientDocuments from './PatientDocuments';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PatientHealth from './PatientHealth';
import { usePatient, useValueSet } from 'src/@nicheaim/fhir-react';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { useCallback, useMemo } from 'react';
import produce from 'immer';
import { Identifier } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import PatientTasks from './PatientTasks';

export default function PatientTabs({ patient, onRefresh }: PatientDetails) {
  const history = useLocation();
  const pathuser =
    history.pathname?.slice(history.pathname?.lastIndexOf('/') + 1, history.pathname?.length)
      .length > 20 ||
    history.pathname?.slice(history.pathname?.lastIndexOf('/') + 1, history.pathname?.length) ==
      'general'
      ? 'general'
      : history.pathname?.slice(history.pathname?.lastIndexOf('/') + 1, history.pathname?.length);

  const PATIENT_TABS = [
    {
      value: 'general',
      component: <PatientDetailsView patient={patient} onRefresh={onRefresh} />,
      path: 'general',
    },
    {
      value: 'referrals_and_cases',
      component: <PatientReferralCases />,
      path: 'referrals-cases',
    },
    {
      value: 'health',
      component: <PatientHealth patient={patient} />,
      path: 'health',
    },
    {
      value: 'groups',
      component: <PatientGroups patient={patient} />,
      path: 'groups',
    },
    {
      value: 'insurance',
      component: <PatientInsurance patient={patient} />,
      path: 'insurance',
    },
    {
      value: 'documents',
      component: <PatientDocuments patient={patient} />,
      path: 'documents',
    },
    {
      value: 'tasks',
      component: <PatientTasks patient={patient} />,
      path: 'tasks',
    },
  ];

  const { currentTab, onChangeTab } = useTabs(
    PATIENT_TABS.filter((tab) => pathuser == tab.path)[0].value
  );

  return (
    <>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={onChangeTab}
      >
        {PATIENT_TABS.map((tab) => (
          <Tab
            component={Link}
            to={`/dashboard/crs/patient/${patient.id}/${tab.path}`}
            disableRipple
            key={tab.value}
            label={capitalCase(tab.value)}
            value={tab.value}
          />
        ))}
      </Tabs>

      {PATIENT_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </>
  );
}

function PatientDetailsView({ patient, onRefresh }: PatientDetails) {
  const [patientIdentifierTypesRecord] = useValueSet('crs-identifier-type', {
    map: ValueSetWrapper,
  });

  const patientIdentifierTypes = useMemo(
    () => patientIdentifierTypesRecord?.asList?.() ?? [],
    [patientIdentifierTypesRecord]
  );

  const [, { update: updatePatient }] = usePatient(patient.id!, { map: PatientWrapper });

  const onUpdateIdentifiers = useCallback(
    async (newIdentifiers: Identifier[]) => {
      await updatePatient(
        produce(patient!, (draft) => {
          draft.identifier = newIdentifiers;
        })
      );
    },
    [patient]
  );

  return (
    <TwoColumnLayout>
      <Section>
        <PersonalDetails patient={patient} />
      </Section>
      <Section>
        <ContactDetails patient={patient} />
      </Section>
      <Section fullWidth>
        <NewAddressDetails patient={patient} onRefresh={onRefresh} />
      </Section>
      <Section>
        <IdentifierDetails
          identifiers={patient?.identifier ?? []}
          catalog={patientIdentifierTypes}
          onUpdateIdentifiers={onUpdateIdentifiers}
        />
      </Section>
      {/* <Section>
        <OtherDetails />
      </Section> */}
    </TwoColumnLayout>
  );
}
