import { Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import produce from 'immer';
import { useState } from 'react';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { usePatient } from 'src/@nicheaim/fhir-react';
import Iconify from 'src/components/Iconify';
import { SHOW_SIDEBAR_COMPONENT, useEventBus } from 'src/event-bus';
import { useStandardLoadingBackdrop } from 'src/sections/careflow/common/StandardLoadingBackdropProvider';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import uuidv4 from 'src/utils/uuidv4';
import AddressField from './personal-details/AddressField';
import RelationshipSelector from './personal-details/components/RelationshipSelector';
import EmailField from './personal-details/EmailField';
import PhoneField from './personal-details/PhoneField';
import { normalizeAddress, normalizePhones } from './utils';

interface ContactFormProps {
  data?: any;
}

interface Telecom {
  rank?: number;
  system: string;
  use?: string;
  value: string;
}

const ContactForm = ({ data }: ContactFormProps) => {
  const { dispatchEvent } = useEventBus();
  const closeForm = () => dispatchEvent(SHOW_SIDEBAR_COMPONENT, null);
  const patient = useCurrentPatient();
  const [, { update }] = usePatient(patient!.id!, {
    map: PatientWrapper,
  });

  const loadingBackdrop = useStandardLoadingBackdrop();

  const isPhone = (telecom: Telecom) => telecom.system === 'phone';
  const isEmail = (telecom: Telecom) => telecom.system === 'email';
  const isFavoritePhone = (telecom: Telecom) => telecom.system === 'phone' && telecom.rank === 1;

  const initFirstName = data?.name?.given[0];
  const initMiddleName = data?.name?.given[1];
  const initLastName = data?.name?.family;
  const initRelationship = data?.relationship?.[0].coding?.[0];
  const initPhones = data?.telecom?.filter(isPhone);
  const initEmails = data?.telecom?.filter(isEmail);
  const initAddress = data?.address;

  const [firstName, setFirstName] = useState(initFirstName || (data?.firstName ?? ''));
  const [middleName, setMiddleName] = useState(initMiddleName || '');
  const [lastName, setLastName] = useState(initLastName || (data?.lastName ?? ''));
  const [relationship, setRelationship] = useState(initRelationship || {});
  const [phones, setPhones] = useState(
    initPhones?.length > 0 ? initPhones : [{ system: 'phone', use: 'mobile' }]
  );
  const [emails, setEmails] = useState(
    initEmails?.length > 0 ? initEmails : [{ system: 'email', use: 'home' }]
  );
  const [address, setAddress] = useState(
    initAddress || { use: 'home', line: [], city: '', state: '', postalCode: '' }
  );

  const clearAll = () => {
    setFirstName('');
    setMiddleName('');
    setLastName('');
    setRelationship('');
    setPhones([{ system: 'phone', use: 'mobile' }]);
    setEmails([{ system: 'email', use: 'home' }]);
    setAddress({ use: 'home', line: ['', ''], city: '', state: '', postalCode: '' });
  };

  const handleAddPhone = () => {
    setPhones([...phones, { system: 'phone', use: 'mobile' }]);
  };

  const handleChangePhoneValue = (value: string, index: number) => {
    const updatedPhones = [...phones];
    updatedPhones[index] = { ...updatedPhones[index], value };
    setPhones(updatedPhones);
  };

  const handleChangePhoneType = (use: string, index: number) => {
    const updatedPhones = [...phones];
    updatedPhones[index] = { ...updatedPhones[index], use };
    setPhones(updatedPhones);
  };

  const handleRemovePhone = (index: number) => {
    setPhones([...phones.slice(0, index), ...phones.slice(index + 1)]);
  };

  const handleFavoritePhone = (value: any, index: number) => {
    const updatedPhones = phones.map((e: any) => ({ ...e, rank: 2 }));
    if (!value) {
      updatedPhones[index].rank = 1;
    }
    setPhones(updatedPhones);
  };

  const handleAddEmail = () => {
    setEmails([...emails, { system: 'email', use: 'home' }]);
  };

  const handleChangeEmailValue = (value: string, index: number) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = { ...updatedEmails[index], value };
    setEmails(updatedEmails);
  };

  const handleChangeEmailType = (use: string, index: number) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = { ...updatedEmails[index], use };
    setEmails(updatedEmails);
  };

  const handleRemoveEmail = (index: number) => {
    setEmails([...emails.slice(0, index), ...emails.slice(index + 1)]);
  };

  const handleFavoriteEmail = (value: any, index: number) => {
    const updatedEmails = emails.map((e: any) => ({ ...e, rank: 2 }));
    if (!value) {
      updatedEmails[index].rank = 1;
    }
    setEmails(updatedEmails);
  };

  const handleChangeRelationship = (newRelationship: any) => {
    setRelationship(newRelationship);
  };

  const handleSave = async () => {
    console.log({
      parsed: normalizePhones(phones.filter((p: any) => p.hasOwnProperty('value'))),
      unparsed: phones.filter((p: any) => p.hasOwnProperty('value')),
    });
    loadingBackdrop.open();

    await update(
      produce(patient!, (draft) => {
        draft.contact = [
          ...(draft.contact?.filter((c: any) => c.id !== data?.id) || []),
          {
            id: data?.id || uuidv4(),
            name: {
              family: lastName,
              given: [firstName, middleName],
            },
            relationship: [
              {
                coding: [
                  {
                    system: 'http://hl7.org/fhir/ValueSet/relatedperson-relationshiptype',
                    code: relationship?.code,
                    display: relationship?.display,
                  },
                ],
              },
            ],
            telecom: [
              ...normalizePhones(phones.filter((p: any) => p.hasOwnProperty('value'))),
              ...emails.filter((e: any) => e.hasOwnProperty('value')),
            ],
            address: normalizeAddress(address),
          },
        ];
      })
    );
    clearAll();
    closeForm();
    loadingBackdrop.close();
  };

  return (
    <Stack px={2}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="subtitle1">Add Contact</Typography>
        <IconButton onClick={closeForm}>
          <Iconify icon="eva:close-fill" />
        </IconButton>
      </Stack>
      <TextField
        fullWidth
        label="First Name"
        margin="dense"
        variant="standard"
        size="small"
        onChange={(ev) => setFirstName(ev.target.value)}
        value={firstName}
      />
      <TextField
        fullWidth
        label="Middle Name"
        margin="dense"
        size="small"
        variant="standard"
        onChange={(ev) => setMiddleName(ev.target.value)}
        value={middleName}
      />
      <TextField
        fullWidth
        label="Last Name"
        margin="dense"
        size="small"
        variant="standard"
        onChange={(ev) => setLastName(ev.target.value)}
        value={lastName}
      />
      <RelationshipSelector onChange={handleChangeRelationship} value={relationship} />
      {phones?.map((phone: any, index: number) => (
        <PhoneField
          key={`phone-${index}`}
          isLast={phones.length - 1 === index}
          onAdd={() => handleAddPhone()}
          onChangeType={(type) => handleChangePhoneType(type, index)}
          onChangeValue={(value) => handleChangePhoneValue(value, index)}
          onSelectFavorite={(event: React.MouseEvent<HTMLElement>, value: any) =>
            handleFavoritePhone(value, index)
          }
          onRemove={phones.length > 1 ? () => handleRemovePhone(index) : undefined}
          rank={phone.rank}
          type={phone.use}
          value={phone.value}
        />
      ))}
      {emails.map((email: any, index: number) => (
        <EmailField
          key={`email-${index}`}
          isLast={emails.length - 1 === index}
          onAdd={() => handleAddEmail()}
          onChangeType={(type) => handleChangeEmailType(type, index)}
          onChangeValue={(value) => handleChangeEmailValue(value, index)}
          onSelectFavorite={(event: React.MouseEvent<HTMLElement>, value: any) =>
            handleFavoriteEmail(value, index)
          }
          onRemove={phones.length > 1 ? () => handleRemoveEmail(index) : undefined}
          rank={email.rank}
          type={email.use}
          value={email.value}
        />
      ))}
      <AddressField value={address} onChange={setAddress} />
      <Stack pt={4} alignItems="center" direction="row" justifyContent="space-evenly">
        <Button variant="outlined" onClick={clearAll}>
          Clear All
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default ContactForm;
