import { Dialog, DialogContent, DialogTitle, Grid, Stack } from '@mui/material';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface IStandardDialogContextData {
  setElement: (element: JSX.Element | null) => void;
}

const initialValue: IStandardDialogContextData = { setElement: () => {} };

const StandardDialogContext = createContext<IStandardDialogContextData>(initialValue);

export function useStandardDialog() {
  const { setElement } = useContext<IStandardDialogContextData>(StandardDialogContext);

  function closeStandardDialog() {
    setElement(null);
  }

  return { open: setElement, close: closeStandardDialog };
}

export interface IStandardDialogData {
  isOpen: boolean;
  childrenDialogElement?: JSX.Element;
}

interface IStandardDialogProviderProps {
  children: ReactNode;
  meta?: ReactNode;
  //   handleOnClose: () => void;
}

export default function StandardDialogProvider({ children, meta }: IStandardDialogProviderProps) {
  const [childrenDialogElement, setChildrenDialogElement] = useState<JSX.Element | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(() => {
    if (childrenDialogElement === null) {
      setOpenDialog(false);
    } else {
      setOpenDialog(true);
    }
  }, [childrenDialogElement]);

  function _setChildrenDialogElement(element: JSX.Element | null) {
    setChildrenDialogElement(element);
  }

  return (
    <StandardDialogContext.Provider value={{ setElement: _setChildrenDialogElement }}>
      {/* <div
        style={{
          position: 'relative',
          width: '100%',
        }}
      >
        {childrenDialogElement && (
          <div
            style={{
              position: 'absolute',
              top: '10%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '9999',
            }}
          >
            <Card>{childrenDialogElement}</Card>
          </div>
        )}

      </div> */}

      <div>{children}</div>
      <Dialog
        open={openDialog}
        fullWidth
        maxWidth="md"
        // onClose={() => {
        //   _setChildrenDialogElement(null);
        // }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              {/* <Typography variant="subtitle1">{data.title}</Typography> */}
            </Stack>
          </Grid>
          {/* <Grid item xs={6}>
            <Stack direction="row" justifyContent="end">
              <Button
                color="inherit"
                variant="outlined"
                size="small"
                onClick={() => {
                  handleOnClose(currentSelectedRadioOption);
                }}
              >
                Done
              </Button>
            </Stack>
          </Grid> */}
        </Grid>

        {/* <Typography variant="body2">{data.text}</Typography> */}

        {childrenDialogElement && childrenDialogElement}
      </Dialog>
    </StandardDialogContext.Provider>
  );
}
