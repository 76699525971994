import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { WrappedGoal } from 'src/@nicheaim/fhir-base/wrappers/Goal';
import { TaskWrapper, WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { Option, onSuccess } from 'src/@types/crs/case';
import CustomDrawer, { CustomDrawerProps } from 'src/components/CustomDrawer';
import { getSeverityDueDateData } from '../../helpers/getSeverityDueDateData';
import { capitalize } from 'src/utils/string';
import { spreadSxProp } from 'src/utils/cssStyles';
import { updateTask } from 'src/services/api/case';
import { useSnackbar } from 'notistack';
import { TaskStatusValue } from 'src/@types/crs/task';
import TaskStatusSeverity from '../../task/TaskStatusSeverity';
import { useTask } from 'src/@nicheaim/fhir-react';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';

interface TaskDetailDrawerProps extends Omit<CustomDrawerProps, 'title' | 'anchor'> {
  taskExternal?: WrappedTask | null;
  goal?: WrappedGoal | null;
  onEdit: (task: WrappedTask) => void;
  onSuccesfulEdit: onSuccess;
  taskId?: string;
}

const { valueTextFontSize, blackTextColor }: { [k: string]: SxProps } = {
  valueTextFontSize: {
    fontSize: '0.8rem',
  },
  blackTextColor: {
    color: '#212b36',
  },
};

const getFormattedDate = (date: moment.Moment): string => {
  const { formattedDueDate } = getSeverityDueDateData(date);
  if (formattedDueDate === 'N/A') return '';
  return formattedDueDate;
};

const TaskDetailDrawer = ({
  taskExternal,
  taskId,
  goal,
  onEdit,
  onSuccesfulEdit,
  ...props
}: TaskDetailDrawerProps) => {
  const [selectedStatus, setSelectedStatus] = useState<string>(taskExternal?.status ?? '');
  const [isEditMode, setIsEditMode] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [taskRecord, { isFetching: isTaskLoading }] = useTask(taskId, {
    map: TaskWrapper,
    autofetch: !!taskId,
  });
  const {
    valueSets: [taskStatuses],
  } = useValueSetsByIdentifiers(['crs-task-status']);

  const task = useMemo(() => {
    if (taskExternal) return taskExternal;
    if (taskRecord) return taskRecord;
    return null;
  }, [taskRecord, taskExternal]);

  useEffect(() => {
    setSelectedStatus(task?.status ?? '');
    setIsEditMode(false);
  }, [task]);

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setSelectedStatus(event.target.value as string);
  };
  const toggleEditMode = () => {
    setIsEditMode((isEditMode) => !isEditMode);
  };

  const handleTaskUpdate = async () => {
    const response = await updateTask({ status: selectedStatus }, task?.id as string);
    if (!response) {
      enqueueSnackbar('Unable to update Task Status. Please Try Again', {
        variant: 'error',
      });
      return;
    }
    onSuccesfulEdit(response);
  };

  const taskDetails: LabelValueProps[] = [
    { label: 'Activity Type', value: task?.getTaskTypeDisplay() ?? task?.getTaskType() ?? '' },
    ...(task?.getForDisplay()
      ? [{ label: 'Patient', value: task?.getForDisplay() as string }]
      : []),
    { label: 'Created On', value: getFormattedDate(moment(task?.authoredOn ?? null)) },
    { label: 'Last Modified Date', value: getFormattedDate(moment(task?.lastModified ?? null)) },
    { label: 'Task Start Date', value: getFormattedDate(moment(task?.getStartDate() ?? null)) },
    { label: 'Task End Date', value: getFormattedDate(moment(task?.getEndDate() ?? null)) },
    { label: 'Status', value: capitalize(task?.priority ?? '') },
    { label: 'Priority', value: capitalize(task?.priority ?? '') },
    { label: 'Task Owner', value: task?.getOwnerDisplay() ?? task?.getOwnerId() ?? '' },
    { label: 'Description', value: task?.description ?? '' },
    { label: 'Comments', value: task?.getComments() ?? '' },
  ];

  return (
    <CustomDrawer
      {...props}
      title="Task Details"
      anchor="right"
      containerSx={{ width: '25vw' }}
      contentContainerSx={{ padding: 0 }}
    >
      {!isTaskLoading && task ? (
        <>
          <Container sx={{ backgroundColor: '#f4f6f8' }}>
            {!!goal && (
              <Section title="Goal">
                <Typography sx={[blackTextColor, valueTextFontSize]}>
                  {goal?.getPlainDescription?.()}
                </Typography>
              </Section>
            )}
            <Section title="Detail">
              {taskDetails.map((taskDetail) => (
                <>
                  {taskDetail?.label !== 'Status' ? (
                    <LabelValue key={taskDetail.label} {...taskDetail} />
                  ) : (
                    <LabelValue
                      key={taskDetail.label}
                      label={taskDetail.label}
                      labelSx={{ alignSelf: 'center' }}
                      value={
                        <Box
                          display={'flex'}
                          flexDirection={'row'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                        >
                          {!isEditMode ? (
                            <TaskStatusSeverity
                              taskStatuses={taskStatuses?.asList?.() ?? []}
                              status={selectedStatus as TaskStatusValue}
                              sx={{ width: '30%' }}
                            />
                          ) : (
                            <Select
                              value={selectedStatus}
                              onChange={handleStatusChange}
                              sx={{ height: 30 }}
                            >
                              {taskStatuses?.asList?.()?.map?.((option) => (
                                <MenuItem key={option.code} value={option.code}>
                                  {option.display}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                          <IconButton
                            onClick={() => {
                              if (isEditMode) {
                                handleTaskUpdate();
                              }
                              toggleEditMode();
                            }}
                          >
                            {isEditMode ? (
                              <SaveIcon htmlColor="#00ab55" />
                            ) : (
                              <EditIcon htmlColor="#919eab" />
                            )}
                          </IconButton>
                        </Box>
                      }
                    />
                  )}
                </>
              ))}
            </Section>
          </Container>
          <Container sx={{ mt: 3 }}>
            <Button
              sx={{ height: 48 }}
              fullWidth
              onClick={() => {
                onEdit(task as WrappedTask);
              }}
              variant="contained"
            >
              Edit
            </Button>
          </Container>
        </>
      ) : (
        <Box flex={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      )}
    </CustomDrawer>
  );
};

const Container = ({ children, sx }: { children: React.ReactNode; sx?: SxProps }) => (
  <Box
    sx={[
      {
        px: 2.8,
      },
      ...spreadSxProp(sx),
    ]}
  >
    {children}
  </Box>
);

type LabelValueKeys = Option<string | React.ReactNode>;

interface LabelValueProps extends LabelValueKeys {
  labelSx?: SxProps;
}

const LabelValue = ({ label, value, labelSx }: LabelValueProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', my: 2 }}>
    <Typography sx={[valueTextFontSize, { flex: 1, color: '#637381' }, ...spreadSxProp(labelSx)]}>
      {label}
    </Typography>
    <Typography sx={[valueTextFontSize, blackTextColor, { flex: 2 }]}>{value}</Typography>
  </Box>
);

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

const Section = ({ title, children }: SectionProps) => (
  <Box sx={{ my: 3 }}>
    <Typography sx={{ fontWeight: 'bold', mb: 3 }}>{title}</Typography>
    {children}
  </Box>
);

export default TaskDetailDrawer;
