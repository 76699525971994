import { IconButton } from '@mui/material';
import { EntityContextMenu, PromptModal, SummaryTable } from 'src/sections/careflow/common';
import Iconify from 'src/components/Iconify';
import { getAttachmentsContextMenuItems } from './utils';
import { useDocumentReferences } from 'src/@nicheaim/fhir-react';
import AddAttachment from './AddAttachment';
import useObjectState from 'src/hooks/useObjectState';
import { EXPANDED_ATTACHMENTS_COLUMNS, SUMMARY_ATTACHMENTS_COLUMNS } from './constants';
import AddModal from '../../common/AddModal';

type AttachmentSummaryProps = {
  patient: {
    id: string;
  };
  isExpanded: boolean;
  onExpand: (index: number) => void;
};

const AttachmentSummary = ({ patient, isExpanded = false, onExpand }: AttachmentSummaryProps) => {
  const [attachments, { remove, refresh, nextPage }] = useDocumentReferences({
    filter: {
      subject: patient?.id,
      _sort: '-date',
    },
    pagination: {
      pageSize: 10,
    },
  });
  const [{ showDeleteAttachmentModal, showAddAttachmentModal, selectedAttachment }, updateState] =
    useObjectState({
      showDeleteAttachmentModal: false,
      showAddAttachmentModal: false,
      selectedAttachment: null,
    });

  const renderDeleteNotePrompt = () => (
    <PromptModal
      open={showDeleteAttachmentModal}
      onContinuePress={() => {
        if (selectedAttachment) {
          remove(selectedAttachment)
            .then(() => {
              updateState({
                showDeleteAttachmentModal: false,
                selectedAttachment: null,
              });
              refresh();
            })
            .catch(() => {
              updateState({
                showDeleteAttachmentModal: false,
                selectedAttachment: null,
              });
            });
        }
      } }
      onCancelPress={() =>
        updateState({
          showDeleteAttachmentModal: false,
        })}
      disableButtons={false}
    />
  );

  const onCreateAttachmentCallback = (attachment: any) => {
    updateState({
      selectedAttachment: null,
      showAddAttachmentModal: false,
      showDeleteAttachmentModal: false,
    });
    refresh();
  };

  const renderAddAttachmentModal = () => (
    <AddModal
      open={showAddAttachmentModal}
      onClose={() =>
        updateState({
          showAddAttachmentModal: false,
        })
      }
      Component={
        <AddAttachment
          attachment={selectedAttachment}
          onSaveCallback={onCreateAttachmentCallback}
          onCancelPress={() =>
            updateState({
              showAddAttachmentModal: false,
            })
          }
          patient={{
            id: patient?.id,
          }}
        />
      }
    />
  );

  return (
    <>
      {renderAddAttachmentModal()}
      {renderDeleteNotePrompt()}
      <SummaryTable
        title="Attachments summary"
        data={attachments}
        columns={isExpanded ? EXPANDED_ATTACHMENTS_COLUMNS : SUMMARY_ATTACHMENTS_COLUMNS}
        actionBar={
          <>
            <IconButton
              onClick={() =>
                updateState({
                  showAddAttachmentModal: true,
                })
              }
            >
              <Iconify icon="eva:plus-fill" />
            </IconButton>
            <IconButton onClick={() => onExpand?.(7)}>
              <Iconify icon="eva:expand-fill" />
            </IconButton>
          </>
        }
        fetchMore={() => nextPage()}
        rowContextMenu={(currentAttachment: any) => (
          <EntityContextMenu
            entity={currentAttachment}
            items={getAttachmentsContextMenuItems(updateState)}
          />
        )}
      />
    </>
  );
};

export default AttachmentSummary;
