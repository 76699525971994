import { useEffect, useRef, useState } from 'react';

interface UseIntersectOptions {
  firstRef?: React.RefObject<any>;
  secondRef?: React.RefObject<any>;
  onChange?: (isIntersecting: IntersectionObserverEntry) => void;
  rootMargin?: string;
  threshold?: number | number[];
}

export default function useIntersect({
  firstRef,
  secondRef,
  onChange,
  rootMargin,
  threshold,
}: UseIntersectOptions) {
  // FIXME: Use a better type than any
  const internalFirstRef = useRef<any>(null);
  const internalSecondRef = useRef<any>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (
      (!firstRef?.current && !internalFirstRef.current) ||
      (!secondRef?.current && !internalSecondRef.current)
    ) {
      return;
    }

    const ref1 = firstRef?.current || internalFirstRef.current;
    const ref2 = secondRef?.current || internalSecondRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting !== isIntersecting) {
            setIsIntersecting(entry.isIntersecting);
            onChange?.(entry);
          }
        });
      },
      {
        root: ref1,
        rootMargin,
        threshold,
      }
    );

    observer.observe(ref2);

    return () => {
      observer.disconnect();
    };
  }, [isIntersecting, rootMargin, threshold, onChange, firstRef, secondRef]);

  return {
    firstRef: internalFirstRef,
    secondRef: internalSecondRef,
    isIntersecting,
  };
}
