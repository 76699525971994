import { Box } from '@mui/material';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import shallow from 'zustand/shallow';
import Rule from '../Rule';
import useCarePlanAutomationFormStore from '../store';

interface QuestionRulesProps {
  question: WrappedQuestionnaireItem;
}

function QuestionRules({ question }: QuestionRulesProps) {
  const rules = useCarePlanAutomationFormStore(
    (s) => s.rules.filter((r) => r.question === question.linkId),
    shallow
  );

  return (
    <Box>
      {rules
        .filter((r) => r.status !== 'deleted')
        .map((rule) => (
          <Rule key={rule.id} question={question} rule={rule} />
        ))}
    </Box>
  );
}

export default QuestionRules;
