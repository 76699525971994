import { FORESIGHT_ANALYTICS_LAUNCH_URL } from 'src/config';
import useSmartLauncher from 'src/hooks/useSmartLauncher';

interface IAnalyticsIframeProps {
  iamUserId: string;
  fhirUserId: string;
  patientId: string | null;
}

export default function AnalyticsIframe({
  iamUserId,
  fhirUserId,
  patientId,
}: IAnalyticsIframeProps) {
  const { isDone, launchUrl, errorMsg } = useSmartLauncher({
    iamUserId,
    fhirUserId: fhirUserId,
    patientId,
    smartAppBaseLaunchUrl: FORESIGHT_ANALYTICS_LAUNCH_URL,
  });

  if (errorMsg) {
    return <div>{errorMsg}</div>;
  }

  return isDone && launchUrl ? (
    <iframe
      scrolling="no"
      src={launchUrl}
      title="foresights"
      style={{ border: 0, height: '100%', width: '100%' }}
    />
  ) : (
    <div>loading</div>
  );
}
