import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import CaseTaskGrid from '../CaseTaskGrid';
import Iconify from 'src/components/Iconify';
import useObjectState from 'src/hooks/useObjectState';
import { useValueSets } from 'src/@nicheaim/fhir-react';
import React, { useEffect, useMemo, useState } from 'react';
import { TaskGridRowData } from '../../../../../@types/crs/case';
import { getTaskGridRows } from 'src/sections/crs/helpers/common';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import SeverityStatus, { AlertSeverity } from 'src/components/SeverityStatus';
import { ValueSetComposeIncludeConcept } from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources/resources';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import { WrappedHealthcareService } from 'src/@nicheaim/fhir-base/wrappers/HealthcareService';

interface TaskByPathwayProps {
  tasks: WrappedTask[];
  patient: WrappedPatient | null;
  healthCareServices: WrappedHealthcareService[];
  carePlan?: WrappedCarePlan | null;
  refreshEntities?: Function;
  refreshTasks: Function;
  openCollapseExternal?: boolean;
  isCollapsible?: boolean;
}
interface TaskByPathwayState {
  selectedPathway: string;
}

interface TaskByPathway {
  id?: string | undefined;
  code: string | undefined;
  display?: string | undefined;
}

const pathwayAll: ValueSetComposeIncludeConcept = {
  code: 'all',
  display: 'All',
};

const TasksByPathway = React.memo(
  ({
    patient,
    tasks,
    carePlan,
    healthCareServices,
    refreshEntities,
    refreshTasks,
    openCollapseExternal,
    isCollapsible = true,
  }: TaskByPathwayProps) => {
    const [{ selectedPathway }, updateState] = useObjectState<TaskByPathwayState>({
      selectedPathway: 'all',
    });

    // Get pathways
    const [, { isLoading: isPathWaysLoading }] = useValueSets({
      filter: {
        identifier: 'crs-path-ways',
      },
      map: ValueSetWrapper,
    });

    const {
      valueSets: [taskStatuses],
    } = useValueSetsByIdentifiers(['crs-task-status']);

    const goalGridRows = useMemo(
      () => getTaskGridRows(tasks, taskStatuses?.asList?.() ?? []),
      [tasks, taskStatuses]
    );

    const handlePathwayTabChange = (_: React.SyntheticEvent, pathway: string) => {
      updateState({
        selectedPathway: pathway,
      });
    };

    const programs = healthCareServices?.map((e) => ({
      id: e.id,
      code: e.program?.[0].coding?.[0].code,
      display: e.program?.[0].coding?.[0].display,
    }));

    const pathwaysList: TaskByPathway[] = [pathwayAll, ...(programs ?? [])];

    const [openCollapse, setOpenCollapse] = useState(!isCollapsible);

    useEffect(() => {
      if (openCollapseExternal === undefined) return;
      setOpenCollapse(!!openCollapseExternal);
    }, [openCollapseExternal]);

    return (
      <>
        <Card>
          {!isPathWaysLoading ? (
            <>
              {isCollapsible && (
                <Stack sx={{ m: 2 }}>
                  <Grid container display={'flex'} alignItems={'center'} paddingRight={2}>
                    <Grid item xs={10}>
                      <Typography sx={{ ml: 1 }}>
                        List of Tasks
                        <IconButton onClick={() => setOpenCollapse(!openCollapse)}>
                          <Iconify
                            icon={
                              openCollapse
                                ? 'eva:arrow-ios-upward-fill'
                                : 'eva:arrow-ios-downward-fill'
                            }
                          />
                        </IconButton>
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              )}

              <Collapse in={openCollapse}>
                <Tabs
                  scrollButtons={'auto'}
                  variant={'scrollable'}
                  value={selectedPathway}
                  onChange={handlePathwayTabChange}
                  aria-label="PathWay Tab"
                  sx={{ ml: 2 }}
                >
                  {pathwaysList.map((pathway) => {
                    const { code, id } = pathway;
                    const getTasks = getTaskGridRows(
                      getGoalsByPathWay(code, goalGridRows, id),
                      taskStatuses?.asList?.() ?? []
                    );
                    const statuses = getStatusCountByPathway(getTasks);
                    return (
                      <Tab
                        key={code}
                        label={
                          <Box display={'flex'} flexDirection={'row'}>
                            <Typography sx={{ fontSize: '1em' }} variant="caption">
                              {code?.toUpperCase()}{' '}
                            </Typography>
                            {Object.entries(statuses).map(([severity, count]) => (
                              <SeverityStatusCount
                                key={severity}
                                count={count}
                                severity={severity as AlertSeverity}
                              />
                            ))}
                          </Box>
                        }
                        value={code}
                      />
                    );
                  })}
                </Tabs>
                <CardContent className={'cardContent'} sx={{ padding: 0 }}>
                  <Box sx={{ marginTop: 2, paddingBottom: 0 }}>
                    {pathwaysList.map((pathway) => {
                      const { code, id } = pathway;
                      const taskGridRowsByPathway = getGoalsByPathWay(code, goalGridRows, id);
                      return (
                        <TabPanel key={code} pathway={code} selectedPathway={selectedPathway}>
                          <CaseTaskGrid
                            tasks={taskGridRowsByPathway}
                            patient={patient as WrappedPatient}
                            carePlan={carePlan}
                            healthCareServices={healthCareServices}
                            onSuccessfulCreation={() => {
                              refreshEntities?.();
                            }}
                            onSuccessfulEdit={() => {
                              refreshTasks();
                            }}
                          />
                        </TabPanel>
                      );
                    })}
                  </Box>
                </CardContent>
              </Collapse>
            </>
          ) : (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                my: 2,
              }}
            >
              <CircularProgress size={50} />
            </Box>
          )}
        </Card>
      </>
    );
  }
);

interface TabPanelProps {
  children: React.ReactNode;
  selectedPathway: string;
  pathway: string | undefined;
}

const TabPanel = ({ children, selectedPathway, pathway }: TabPanelProps): JSX.Element => (
  <>{selectedPathway === pathway ? <>{children}</> : null}</>
);

type DueDateSeverities = AlertSeverity.SUCCESS | AlertSeverity.ERROR | AlertSeverity.WARNING;

export type StatusSeverityCount = Record<DueDateSeverities, number>;

const getStatusCountByPathway = (tasks: TaskGridRowData[]): StatusSeverityCount => {
  const statuses: StatusSeverityCount = {
    [AlertSeverity.ERROR]: 0,
    [AlertSeverity.WARNING]: 0,
    [AlertSeverity.SUCCESS]: 0,
  };
  for (const {
    endDate: { severity },
  } of tasks) {
    if (statuses.hasOwnProperty(severity as AlertSeverity))
      statuses[severity as DueDateSeverities]++;
  }
  return statuses;
};

const getGoalsByPathWay = (
  pathwayCode: string | undefined,
  tasks: TaskGridRowData[],
  healthCareServiceId: string | undefined
) => {
  if (!pathwayCode || pathwayCode === pathwayAll.code) return tasks.map((e) => e.wrappedTask);
  return tasks
    .filter((e) =>
      e?.basedOn?.some((r) => r.reference === `HealthcareService/${healthCareServiceId}`)
    )
    .map((e) => e.wrappedTask);
};

interface SeverityStatusCountProps {
  count: number;
  severity: AlertSeverity;
}

const SeverityStatusCount = ({ count, severity }: SeverityStatusCountProps) => (
  <SeverityStatus status={{ message: String(count), severity }} sx={{ height: '20px', ml: 0.5 }} />
);

export default TasksByPathway;
