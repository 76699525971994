import { Alert } from '@mui/material';
import { useNavigate } from 'react-router';
import { useOrganizations } from 'src/@nicheaim/fhir-react';
import Label from 'src/components/Label';
import DataTable, { DataTableColumn } from 'src/components/table/DataTable';
import fhirSystem from 'src/fhir/system';
import ClientsToolbar from './ClientsToolbar';

const columns: DataTableColumn[] = [
  {
    header: 'Client',
    field: 'name',
  },
  {
    header: 'Status',
    field: 'active',
    render(active = true) {
      return <Label color={active ? 'success' : 'info'}>{active ? 'Active' : 'Inactive'}</Label>;
    },
  },
];

export default function ClientsTable() {
  const navigate = useNavigate();
  const [clients] = useOrganizations({
    filter: {
      identifier: fhirSystem.client.forCode(''),
    },
  });

  return (
    <div>
      <ClientsToolbar />

      {clients.length ? (
        <DataTable
          selectable
          clickeable
          columns={columns}
          data={clients}
          onItemEdit={(item) => navigate(`${item.id}/edit`)}
          onItemClick={(item) => navigate(item.id || '')}
          sx={{ m: 2 }}
        />
      ) : (
        <Alert severity="info" sx={{ m: 2 }}>
          No clients found
        </Alert>
      )}
    </div>
  );
}
