import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import * as Yup from 'yup';
import moment from 'moment';
import { capitalCase } from 'capital-case';
import { useEffect, useMemo } from 'react';
import { MobileDateTimePicker } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AppointmentParticipantList from './AppointmentParticipantList';
import { getReferenceResourceId } from 'src/sections/crs/helpers/common';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { WrappedAppointment } from 'src/@nicheaim/fhir-base/wrappers/Appointment';

export enum AppointmentStatus {
  PROPOSED = 'proposed',
  PENDING = 'pending',
  BOOKED = 'booked',
  ARRIVED = 'arrived',
  FULFILLED = 'fulfilled',
  CANCELLED = 'cancelled',
  NO_SHOW = 'noshow',
  ENTERED_IN_ERROR = 'entered-in-error',
  CHECKED_IN = 'checked-in',
  WAITLIST = 'waitlist',
}

export enum AppointmentParticipantRequired {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
  INFORMATION_ONLY = 'information-only'
}

export enum AppointmentParticipantStatus {
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  TENTATIVE = 'tentative',
  NEEDS_ACTION = 'needs-action',
}

type CustomParticipant = {
  actor: {
    label: string;
    value: string;
  },
  status: string;
  required: string;
};

type FormValue = {
  status: string;
  start: string;
  end: string;
  description: string;
  participant: CustomParticipant[];
};

type Props = {
  patient: WrappedPatient | null;
  appointment: WrappedAppointment | null;
  open: boolean;
  onClose: VoidFunction;
  handleAppointment: (data: any) => Promise<any>;
};

export function AppointmentForm({ 
  patient,
  appointment, 
  open, 
  onClose, 
  handleAppointment
}: Props) {

  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [open]);

  const EventSchema =  Yup.object().shape({
    status: Yup.string()
      .required('Status is required'),
    start: Yup.date()
      .required('Start is required')
      .nullable()
      .transform((curr, orig) => moment(orig).isValid() ? curr : null),
    end: Yup.date()
      .required('End is required')
      .nullable()
      .transform((curr, orig) => moment(orig).isValid() ? curr : null)
      .min(
        Yup.ref('start'),
        "Start Date can't be greater than End Date"
      ),
    description: Yup.string()
      .required('Description is required'),
    participant: Yup.array(
      Yup.object({
        status: Yup.string()
        .nullable()
        .transform((curr, orig) => orig === '' ? curr : null)
        .min(
          Yup.ref('actor'),
          "Status is required"
        ),
        required: Yup.string()
        .nullable()
        .transform((curr, orig) => orig === '' ? curr : null)
        .min(
          Yup.ref('actor'),
          "Required is required"
        ),
      })
    )
  });

  const defaultValues = useMemo(
    () =>
      ({
        status: appointment?.status || '',
        start: appointment?.start || null,
        end: appointment?.end || null,
        description: appointment?.description || '',
        participant: appointment?.participant?.
        filter((x1) => getReferenceResourceId(x1.actor?.reference ?? '') !== patient?.id)
        .map((x2) => ({
          ...x2,
          actor: {
            label: x2.actor?.display,
            value: x2.actor?.reference && getReferenceResourceId(x2.actor?.reference)
          },
        })) || []
      } as FormValue),
    [appointment]
  );
 
  const methods = useForm<FormValue>({ resolver: yupResolver(EventSchema) });

  const { reset, control, handleSubmit } = methods;

  const mapAppointment = (dataForm: FormValue) => {
    const setParticipant = dataForm?.participant?.map((x1) => ({
      ...x1,
      actor: {
        reference: `Practitioner/${x1.actor?.value}`,
        display: x1.actor?.label
      },
    })) ?? [];
    
    const mapParticipant: any = [
      ...setParticipant, 
      {
      actor: {
        reference: `${patient?.resourceType}/${patient?.id}`,
        display: patient?.getFullName()
      },
      required: 'required',
      status: 'accepted' 
    }];

    return {
      ...dataForm, 
      resourceType: 'Appointment', 
      ...(appointment !== null && {id: appointment?.id}),
      start: dataForm?.start && moment(dataForm?.start).toISOString(),
      end: dataForm?.end && moment(dataForm?.end).toISOString(),
      participant: mapParticipant,
      ...(appointment === null && {created: new Date().toISOString()}),
    }
  };

  const onSubmit = async (dataForm: FormValue) => {
    const resultMap = mapAppointment(dataForm);
    handleAppointment(resultMap);
    handleClose();
  };

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="md">
      <DialogTitle>{appointment ? 'Edit' : 'Add'} </DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <RHFSelect name="status" label="Status">
                  <MenuItem disabled></MenuItem>
                  {Object.entries(AppointmentStatus).map((option) => (
                    <MenuItem key={option[0]} value={option[1]}>
                      {capitalCase(option[1])}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} sx={{ width: 1 }}>
                  <Controller
                    name="start"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <MobileDateTimePicker
                        {...field}
                        label="Start"
                        renderInput={(params) => (
                          <TextField 
                            {...params} 
                            fullWidth 
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    name="end"
                    control={control}
                    render={({ field, fieldState: { error }  }) => (
                      <MobileDateTimePicker
                        {...field}
                        label="End"
                        renderInput={(params) => (
                          <TextField 
                          {...params} 
                          fullWidth 
                          error={!!error}
                          helperText={error?.message}
                        />
                        )}
                      />
                    )}
                  />
                </Stack>
                <AppointmentParticipantList />
                <RHFTextField name="description" label="Description" multiline rows={4} />
              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />

              <Button variant="contained" color="info" onClick={handleClose}>
                Cancel
              </Button>

              <Button variant="contained" color="info" type="submit">
                Submit
              </Button>
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
}
