import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from '@mui/material';
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import useTable from '../../../hooks/useTable';
import { ReferralManager } from 'src/@types/crs/referral';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { ReferralStatics, ReferralTable, ReferralTableToolbar } from 'src/sections/crs/referral';
import { TableHeadCustom } from 'src/components/table';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { referralService } from 'src/crs/referral/services';
import { PaginateQuery } from 'src/api/pagination/dtos';
import { PaginatedReferralDto } from 'src/crs/referral/services/ReferralService';
import useObjectState from 'src/hooks/useObjectState';
import {
  GridViewModules,
  GridView,
  getGridViewsByModule,
  GridViewConverter,
} from 'src/services/api/gridView';
import { debounce, wait } from 'src/utils/timers';
import { TABLE_HEAD_REFERRAL_LIST } from '../common/table-head';
import { referralDtoToReferralManager } from '../common/common-utils';
import NoResultsTableRow from '../common/NoResultsTableRow';
import LoadingTableRow from '../common/LoadingTableRow';

const mintReferralSincroinzer = process.env.REACT_APP_MINT_REFERRAL_SINCRONIZER;

interface GridViewState {
  isGridViewsLoading: boolean;
  gridViews: GridView[] | null;
  gridViewSelected: number;
}

const defaultViewGrid: GridView = {
  id: 0,
  code: 'All',
  name: 'All',
  description: 'All',
  scope: 'GLOBAL',
  data: {},
  isDefault: false,
  module: GridViewModules.REFERRALLIST,
  isPredefined: true,
};

export default function ReferralList() {
  const { page, orderBy, rowsPerPage, onChangePage, onChangeRowsPerPage, setPage } = useTable({
    defaultRowsPerPage: 5,
    defaultOrderBy: 'createdOn',
    initialIndex: 1,
    defaultCurrentPage: 1,
  });

  const { themeStretch } = useSettings();

  const [tableData, setTableData] = useState<PaginatedReferralDto | undefined | null>(null);

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [filterMrn, setFilterMrn] = useState('');
  const [search, setSearch] = useState<string>('');
  const [{ gridViews, isGridViewsLoading, gridViewSelected }, updateState] =
    useObjectState<GridViewState>({
      isGridViewsLoading: true,
      gridViews: [],
      gridViewSelected: defaultViewGrid.id,
    });

  const getPaginateQuery = (page: number, rowsPerPage: number, search: string) => {
    const paginateQuery: PaginateQuery = {
      page: page,
      limit: rowsPerPage,
      sortBy: [[orderBy, 'DESC']],
    };
    if (search) {
      paginateQuery.search = search;
    }

    return paginateQuery;
  };

  const fetchReferralList = async (
    page: number,
    rowsPerPage: number,
    search: string,
    gridViewSelected: number
  ) => {
    setIsLoading(true);
    await wait();
    const gridView = gridViewSelected ? findGridViewById(gridViewSelected) : null;
    const referrals = await referralService.getAll(
      getPaginateQuery(page, rowsPerPage, search),
      gridView?.dataConverted?.filter as PaginateQuery['filter']
    );
    setIsLoading(false);
    if (referrals) {
      setTableData(referrals);
    }
  };

  const getReferralList = useCallback(debounce(fetchReferralList, 600), [gridViews]);

  useEffect(() => {
    const getReferralGridViews = async () => {
      const gridViews = await getGridViewsByModule(
        GridViewModules.REFERRALLIST,
        GridViewConverter.PaginateQuery
      );
      await wait();
      updateState({
        isGridViewsLoading: false,
        gridViews: [defaultViewGrid, ...(gridViews || [])],
      });
    };
    getReferralGridViews();
  }, [updateState]);

  useEffect(() => {
    getReferralList(page, rowsPerPage, search, gridViewSelected);
  }, [page, rowsPerPage, filterMrn, search, gridViewSelected]);

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(event, newPage + 1);
  };

  const handleFilterMrn = (filterMrn: string) => {
    setFilterMrn(filterMrn);
    setPage(1);
  };

  const findGridViewById = (gridViewId: number): GridView | undefined =>
    gridViews?.find(({ id }) => id === gridViewId);

  const handleOnGridValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateState({
      gridViewSelected: Number(event.target.value),
    });
  };

  const dataFormatted = (): ReferralManager[] => {
    const data = tableData?.data ? tableData.data.map((r) => referralDtoToReferralManager(r)) : [];
    return data;
  };

  const rows = dataFormatted();

  return (
    <Page title="Referral List">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          title=""
          heading=""
          links={[
            { name: 'Home', href: PATH_DASHBOARD.root },
            { name: 'CRS' },
            { name: 'Referral List', href: PATH_DASHBOARD.crs.referral },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Typography variant="h4">Referral List</Typography>
          </Grid>
          <Grid item xs={3} display="flex" justifyContent="space-around">
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              onClick={() => {
                getReferralList(page, rowsPerPage, search, gridViewSelected);
              }}
            >
              Refresh
            </Button>
            <Button
              sx={{ float: 'right' }}
              variant="contained"
              href={`${mintReferralSincroinzer}`}
              target="_blank"
            >
              Sync referrals
            </Button>
          </Grid>

          <Grid item xs={12}>
            <ReferralStatics isOpen={open} onOpen={() => setOpen(!open)} />
          </Grid>

          <Grid item xs={12}>
            <Card>
              <Stack direction="row" sx={{ py: 2, px: 2 }}>
                <Grid xs={6}>
                  <ReferralTableToolbar
                    isLoading={isGridViewsLoading}
                    filterMrn={filterMrn}
                    gridViewSelected={gridViewSelected}
                    onFilterMrn={handleFilterMrn}
                    onGridValueChange={handleOnGridValueChange}
                    options={gridViews ?? []}
                    search={search}
                    onSearch={setSearch}
                  />
                </Grid>
                <Grid xs={6}>
                  <Stack justifyContent="flex-end">
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      count={tableData ? tableData.meta.totalItems : 0}
                      rowsPerPage={rowsPerPage}
                      page={page - 1}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={onChangeRowsPerPage}
                      sx={{ borderTop: 0 }}
                    />
                  </Stack>
                </Grid>
              </Stack>
              <TableContainer>
                <Table>
                  <TableHeadCustom headLabel={TABLE_HEAD_REFERRAL_LIST} />
                  <TableBody>
                    {!isLoading ? (
                      rows?.length ? (
                        rows.map((row) => <ReferralTable key={row.id} row={row} />)
                      ) : (
                        <NoResultsTableRow
                          colSpan={TABLE_HEAD_REFERRAL_LIST.length}
                          text="No Referral Found."
                        />
                      )
                    ) : (
                      <LoadingTableRow colSpan={TABLE_HEAD_REFERRAL_LIST.length} />
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
