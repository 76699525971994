import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  closeBtn: {
    color: "#fff",
    backgroundColor: "#FF4842",
    "&:hover": {
      backgroundColor: "#FF4842",
    },
  },
  nextBtn: {
    color: "#fff",
    backgroundColor: "#00AB55",
    "&:hover": {
      backgroundColor: "#00AB55",
    },
  },
});

const Transitions = ({ transitions, handleTransition, isOnCurrentStep}: any) => {
  const classes: any = useStyles({});

  const displayButtons = transitions
    ?.sort((a: any, b: any) => {
      // const parseConditionA = JSON.parse(a.pre_conditions);
      // const parseConditionB = JSON.parse(b.pre_conditions);

      return b.code.localeCompare(a.code);
    })
    .map((item: any, index: 0) => {
      const parseMeta: any = JSON.parse(item?.metadata);

      return (
        <>
          {!isOnCurrentStep && item.name !== 'Reject' ? null
          : <Button
            className={classes?.[parseMeta?.ui?.className] || null}
            onClick={() => handleTransition(item)}
          >
            {item.name}
          </Button>}

          {/* {index === 0 && <Box sx={{ flex: '1 1 auto' }} />} */}
        </>
      );
    });

  return displayButtons;
};

export default Transitions;
