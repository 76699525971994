import { SxProps } from '@mui/material';
import { TaskStatusValue } from 'src/@types/crs/task';
import SeverityStatus, { AlertSeverity } from 'src/components/SeverityStatus';
import { capitalize } from 'src/utils/string';
import { spreadSxProp } from 'src/utils/cssStyles';
import { TaskStatus } from '../case/components/TasksGrid/TaskModal';

interface TaskStatusSeverityProps {
  status: TaskStatusValue;
  sx?: SxProps;
  taskStatuses: TaskStatus[];
}

const TaskStatusSeverity = ({ status, sx, taskStatuses }: TaskStatusSeverityProps): JSX.Element => {
  let statusDisplay: string | undefined = taskStatuses?.find(
    ({ code }) => code === status
  )?.display;

  statusDisplay = statusDisplay ? statusDisplay : capitalize(status ?? '');
  return (
    <SeverityStatus
      sx={[...spreadSxProp(sx)]}
      status={{
        severity: taskStatusesSeverity?.[status as TaskStatusValue] ?? AlertSeverity.DEFAULT,
        message: statusDisplay,
      }}
    />
  );
};

export const taskStatusesSeverity: { [k in TaskStatusValue]?: AlertSeverity } = {
  draft: AlertSeverity.DEFAULT,
  requested: AlertSeverity.DEFAULT,
  received: AlertSeverity.DEFAULT,
  accepted: AlertSeverity.SUCCESS,
  completed: AlertSeverity.SUCCESS,
  ready: AlertSeverity.SUCCESS,
  'on-hold': AlertSeverity.WARNING,
  cancelled: AlertSeverity.ERROR,
  rejected: AlertSeverity.ERROR,
  failed: AlertSeverity.ERROR,
  'entered-in-error': AlertSeverity.ERROR,
  'in-progress': AlertSeverity.SUCCESS,
};

export default TaskStatusSeverity;
