// @ts-nocheck
import {
  Card,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Typography,
  TableBody,
  Grid,
  Modal,
  Box,
  TablePagination,
} from "@mui/material";
import { getSessionsTableColumns } from '../../constants';
import SessionType from "../../types/SessionType";
import useObjectState from "src/hooks/useObjectState";
import SessionUsersModal from "../SessionUsersModal/SessionUsersModal";
import SessionItem from "./SessionItem";
import API from 'src/services/api';
import { useCallback, useEffect } from "react";
import GroupSessionType from "../../types/GroupSessionType";
import SessionParticipantType from "../../types/SessionParticipantType";
import { SessionTableContext } from "./DetailTableContext";
import useAddEntityStates from "src/hooks/useAddEntityStates";
import AddSessionModal from "../AddSessionModal/AddSessionModal";
import { useMatch } from "react-router";
import moment from "moment";

export enum SessionsFilterEnum {
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  COMPLETED_AND_CANCELLED = 'completed/cancelled',
  INCOMPLETE = 'incomplete',
  SCHEDULED = 'scheduled',
  PAST_DUE = 'past_due',
}

type SessionTablePropsType = {
  item: GroupSessionType;
  filter: SessionsFilterEnum;
  title?: string;
  onSelectSession?: (groupSession: SessionType) => void;
}

const DEFAULT_PAGINATION_TAKE = 10;

const SessionTable = ({
  item: groupSession,
  title,
  filter,
  onSelectSession
}: SessionTablePropsType) => {
  const params = useMatch('/dashboard/group-sessions/:groupSessionId/sessions')?.params;
  const groupSessionId = params?.groupSessionId;
  const [{
    sessions,
    showSessionUsersModal,
    selectedSession,
    pagination,
    isLoading,
  }, updateState] = useObjectState<{
    sessions: SessionType[];
    showSessionUsersModal: boolean;
    selectedSession: SessionType | null;
    pagination: {
      take: number;
      page: number;
      total: number;
    },
    isLoading: boolean;
  }>({
    sessions: [],
    showSessionUsersModal: false,
    selectedSession: null,
    pagination: {
      take: DEFAULT_PAGINATION_TAKE,
      page: 0,
      total: 0,
    },
    isLoading: true,
  });
  const [{
    selectedEntity,
    showAddEntityModal,
  }, {
    openAddEntityModal,
    closeAddEntityModal,
    setSelectedEntity,
  }] = useAddEntityStates<SessionType>();

  const onPressSessionItem = (item: SessionType) => {
    updateState({
      showSessionUsersModal: true,
      selectedSession: item,
    })
    onSelectSession?.(item);
  }

  const getEntities = useCallback((take: number, page: number) => {
    API.groupSessions.getSessionsByGroupSession(groupSessionId, {
      filter,
      take,
      page,
    })
    .then(data => {
      updateState({
        sessions: data.sessions,
        pagination: {
          take: take,
          page: page,
          total: data.total,
        },
        isLoading: false,
      })
    }).catch(error => console.log(error))
  }, [])

  const handleChangePage = (_: any, newPage: number) => {
    getEntities( pagination?.take ?? 0, newPage);
  };

  useEffect(() => {
    getEntities(DEFAULT_PAGINATION_TAKE, 0);
  }, [getEntities])

  const updateSessionParticipant = (sessionParticipant: SessionParticipantType) => {
    let indexes: number[] = []
    sessions.forEach((session, sessionIndex) => {
      session.sessionParticipants.find((sp, spIndex) => {
        if (sp.id === sessionParticipant.id) {
          indexes = [sessionIndex, spIndex]
          return sp
        }

        return null
      })
    });

    const newSessions = [...sessions];
    newSessions[indexes[0]].sessionParticipants[indexes[1]] = sessionParticipant;

    updateState({
      sessions: newSessions
    })
  }

  const updateSession = async (session: SessionType) => {
    updateState({
      sessions: sessions.map(currentSession => {
        if (currentSession.id === session.id) {
          return session;
        }

        return currentSession;
      })
    })
  }

  const updateSessionNote = async (id: number, note: string) => {
    const session = await API.groupSessions.updateSessionNote(id, note);

    if (!session) return;

    updateState({
      sessions: sessions.map(currentSession => {
        if (currentSession.id === session.id) {
          return session;
        }

        return currentSession;
      })
    })
  }
  

  return (
    <SessionTableContext.Provider
      value={{
        updateSessionParticipant,
        updateSession,
        updateSessionNote,
        openAddEntityModal,
        setSelectedSession: setSelectedEntity,
      }}
    >
      <Modal
        open={showAddEntityModal}
        onClose={closeAddEntityModal}
      >
        <AddSessionModal
          session={selectedEntity}
          onCloseModal={closeAddEntityModal}
          onSaveCallback={updateSession}
        />
      </Modal>
      <Card sx={{ marginBottom: 2, marginTop: 3 }}>
        <Modal
          open={showSessionUsersModal}
          onClose={() => updateState({ showSessionUsersModal: false })}
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxHeight: '80%',
              overflow: 'scroll',
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: 2,
              p: 4,
            }}
          >
            <SessionUsersModal
              items={selectedSession?.sessionParticipants ?? []}
              title={`${selectedSession?.groupSession.name} - ${moment(selectedSession?.start).format('hh:mm a')} - ${moment(selectedSession?.end).format('hh:mm a')}`}
            />
          </Box>
        </Modal>
        {title && (
          <Grid sx={{ padding: 3, paddingBottom: 1 }}>
            <Typography variant="h5">{title}</Typography>
          </Grid>
        )}
        <TableContainer sx={{ padding: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                {getSessionsTableColumns.map((col: any, index: number) => (
                  <TableCell style={{ width: col.width }} key={index}>
                    <Typography>
                      {col.label}
                    </Typography>
                  </TableCell>
                ))}
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sessions.map((item) => (
                <SessionItem
                  key={item.id}
                  item={item}
                  onPress={onPressSessionItem}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {sessions.length === 0 && (
          <Grid
            item
            justifyItems={'center'}
            alignContent="center"
            alignItems={"center"}
            sx={{ padding: 2, width: '100%' }}
          >
            <Typography
              variant="h6"
              textAlign="center"
            >
              No Sessions found
            </Typography>
          </Grid>
        )}
        <TablePagination
          component="div"
          count={pagination?.total ?? 0}
          page={pagination?.page ?? 0}
          onPageChange={handleChangePage}
          rowsPerPage={pagination?.take ?? 0}
          rowsPerPageOptions={[]}
          labelDisplayedRows={() =>
            `Showing ${sessions?.length ?? 0} Sessions | Page ${
              pagination && pagination?.total && pagination?.total > 0 ? pagination?.page + 1 : 0
            } of ${pagination && pagination?.total && Math.ceil(pagination?.total / pagination?.take)}`
          }
          showFirstButton
          showLastButton
        />
      </Card>
    </SessionTableContext.Provider>
  );
}

export default SessionTable;
