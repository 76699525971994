import {
  Button,
  Card,
  Collapse,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { TableHead } from './table-head';
import SummaryTable from './SummaryTable';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/Iconify';

type Props = {
  tableHead: TableHead[];
  data: any;
  title: string;
  childrenButtons?: React.ReactNode;
  handleOpen?: VoidFunction;
  titleButton?: string;
  statusList?: any;
  openCollapseExternal: boolean;
}

export function TableCustom({
  tableHead,
  data,
  title,
  childrenButtons,
  handleOpen,
  titleButton,
  statusList,
  openCollapseExternal
}: Props){

  const [ openCollapse, setOpenCollapse ] = useState(false);

  useEffect(() => {
    setOpenCollapse(openCollapseExternal)
  }, [openCollapseExternal]);

  return (
    <Card>
      <Stack sx={{ m: 2 }}>
        <Grid
          container
          display={'flex'}
          alignItems={'center'}
          paddingRight={2}
        >
          <Grid item xs={childrenButtons ? 8 : 10}>
            <Typography>
              {title}
              <IconButton onClick={() => setOpenCollapse(!openCollapse)}>
                <Iconify icon={ openCollapse ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'} />
              </IconButton>
            </Typography>
          </Grid>
          {childrenButtons}
          {titleButton && (
            <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
              <Button 
                size="small" 
                sx={{ height: '36px' }}
                onClick={handleOpen}
              >
                {titleButton}
              </Button>
            </Grid>
          )}
        </Grid>
      </Stack>
      <Collapse in={openCollapse}>
        <SummaryTable
          header={tableHead}
          data={Array.isArray(data) ? data : []}
          statusList={statusList}
        />
      </Collapse>
    </Card>
  )
}