import { CaseResponse } from 'src/crs/case/service/CaseService';

export enum CaseIntent {
  PROPOSAL = 'proposal',
  PLAN = 'plan',
  ORDER = 'order',
  OPTION = 'option',
}
export enum CasePriority {
  ROUTINE = 'routine',
  URGENT = 'urgent',
}

export enum CaseStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  ON_HOLD = 'on-hold',
  REVOKED = 'revoked',
  COMPLETED = 'completed',
  ENTERED_IN_ERROR = 'entered-in-error',
  UNKNOWN = 'unknown',
}

export class CaseDto {
  createdBy: string;

  createdOn: string;

  updatedBy: string;

  updatedOn: string;

  caseId: string;

  caseUUID: string;

  fhirId: string;

  workflowInstanceId: number;

  workflowStatus: string;

  workflowSubstatus: string;

  workflowStageId: string;

  workflowStageName: string;

  workflowStageOrder: number;

  workflowDispositionReason: string;

  workflowDispositionNote: string;

  workflowDispositionOn: string;

  workflowDispositionAt: string;

  workflowOwnedBy: string;

  workflowScope: string;

  indexedFhirRefUri: string;

  indexedSubjectFhirRefUri: string;

  indexedsubjectMrn: string;

  indexedsubjectFullName: string;

  indexedsubjectPhone: string;

  indexedsubjectEmail: string;

  indexedsubjectDob: Date;

  indexedStatus: string;

  internalNumber: string;

  subjectDOBFormatted: string;

  subjectAge: string;

  createdOnFormatted: string;

  fromCaseResponse(dto: CaseResponse) {
    this.createdBy = dto.createdBy;
    this.createdOn = dto.createdOn;
    this.updatedBy = dto.updatedBy;
    this.updatedOn = dto.updatedOn;
    this.caseId = dto.caseId;
    this.caseUUID = dto.caseUUID;
    this.fhirId = dto.fhirId;
    this.workflowInstanceId = dto.workflowInstanceId;
    this.workflowStageId = dto.workflowStageId;
    this.workflowStatus = dto.workflowStatus;
    this.workflowSubstatus = dto.workflowSubstatus;
    this.workflowStageName = dto.workflowStageName;
    this.workflowStageOrder = dto.workflowStageOrder;
    this.workflowDispositionReason = dto.workflowDispositionReason;
    this.workflowDispositionNote = dto.workflowDispositionNote;
    this.workflowDispositionOn = dto.workflowDispositionOn;
    this.workflowDispositionAt = dto.workflowDispositionAt;
    this.workflowOwnedBy = dto.workflowOwnedBy;
    this.workflowScope = dto.workflowScope;
    this.indexedFhirRefUri = dto.indexedFhirRefUri;
    this.indexedSubjectFhirRefUri = dto.indexedSubjectFhirRefUri;
    this.indexedsubjectMrn = dto.indexedsubjectMrn;
    this.indexedsubjectFullName = dto.indexedsubjectFullName;
    this.indexedsubjectPhone = dto.indexedsubjectPhone;
    this.indexedsubjectEmail = dto.indexedsubjectEmail;
    this.indexedStatus = dto.indexedStatus;

    if (dto.indexedsubjectDob) {
      this.indexedsubjectDob = new Date(dto.indexedsubjectDob);
    }
    this.internalNumber = dto.internalNumber;
    this.subjectDOBFormatted = dto.subjectDOBFormatted;
    this.subjectAge = dto.subjectAge;
    this.createdOnFormatted = dto.createdOnFormatted;
  }
}
