import { useState } from "react";
import { isEmpty } from "lodash";
import useTable from "src/hooks/useTable";
import { TableHeadCustom } from "src/components/table";
import RelatedPersonAction from "./relatedPersons/RelatedPersionAction";
import {  WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { TABLE_HEAD_RELATED_PERSON } from "src/sections/crs/common/table-head";
import { Box, Button, Grid, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, Typography } from "@mui/material";
import RelatedPersonRow from "./relatedPersons/RelatedPersonRow";

type Props = {
  patient: WrappedPatient | null;
}

export function RelatedPersons({ patient }: Props){

  const [ open, setOpen ] = useState(false);

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({});

  return(
    <>
      <Stack sx={{ m: 2 }}>
        <Grid
          container
          display={'flex'}
          alignItems={'center'}
          paddingRight={2}
        >
          <Grid item xs={10}>
            <Typography variant="subtitle1"> 
              Relationship  
            </Typography>
          </Grid>
            <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
              <Button 
                size="small" 
                sx={{ height: '36px' }}
                onClick={() => setOpen(true)}
              >
                Add Relationship
              </Button>
            </Grid>
        </Grid>
      </Stack>
      <TableContainer>
        <Table size="small" sx={{ mb: 2 }}>
          <TableHeadCustom headLabel={TABLE_HEAD_RELATED_PERSON} />
          <TableBody>
            {!isEmpty(patient?.contact) ? (
              patient?.contact?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index: number) => (
                <RelatedPersonRow key={'rel-p-' + index} row={row} patient={patient}/>
              ))):(
              <TableCell colSpan={TABLE_HEAD_RELATED_PERSON?.length}>
                <Typography variant="body2" align="center">No rows</Typography>
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={ patient?.contact ?  patient.contact.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </Box>
      <RelatedPersonAction 
        patient={patient} 
        contact={null} 
        open={open} 
        onClose={() => setOpen(false)}
      />
    </>
  )
}