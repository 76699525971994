import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import * as actions from './actions';

export function useNoteTypes(
  options?: Omit<UseQueryOptions<actions.NoteTypesCatalog[], unknown>, 'queryFn' | 'queryKey'>
) {
  return useQuery(['ccm-note-types'], actions.listTypes, options);
}

export function useNotes(
  patientId: string,
  options?: Omit<UseQueryOptions<actions.Note[], unknown>, 'queryFn' | 'queryKey'>
) {
  return useQuery(['ccm-notes', patientId], () => actions.listNotes(patientId), options);
}
