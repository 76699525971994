import { ChangeEvent } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { Select } from 'src/components/Select';
import useCarePlanAutomationFormStore, {
  AutomationRule,
  useCarePlanAutomationFormError,
  useCarePlanAutomationFormRule,
} from '../../store';
import { WrappedQuestionnaireItem } from 'src/@nicheaim/fhir-base/wrappers/Questionnaire';
import fhirDuration from 'src/fhir/codes/duration';

interface TimeframeSelectorProps {
  question: WrappedQuestionnaireItem;
  rule: AutomationRule;
}

export default function TimeframeSelector({ question, rule }: TimeframeSelectorProps) {
  const saving = useCarePlanAutomationFormStore((s) => s.loading);
  const { enabled, timeframe, timeframeUnit, setTimeframe, setTimeframeUnit } =
    useCarePlanAutomationFormRule(rule.id);
  const error = useCarePlanAutomationFormError(`rule(${question.linkId}:${rule.id})-timeframe`);

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
      <Typography sx={{ minWidth: 120, pb: error ? 2 : 0 }}>Timeframe:</Typography>
      <Stack>
        <Stack direction="row" gap={1}>
          <TextField
            disabled={!enabled || saving}
            type="number"
            size="small"
            value={timeframe}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setTimeframe(e.target.valueAsNumber)}
          />

          <Select
            isDisabled={!enabled || saving}
            value={fhirDuration.find((u) => u.value === timeframeUnit)}
            options={fhirDuration}
            onChange={(option) => setTimeframeUnit(option?.value || fhirDuration[0].value)}
          />
        </Stack>

        {error && (
          <Typography color="error" variant="caption">
            {error}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}
