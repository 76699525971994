import { MedicationRequest } from "src/@nicheaim/fhir-base/mappings/MedicationRequest";
import { Coding } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";

export interface MedicationRequestFormProps {
  patient?: {
    fhirId: string
  };
  medicationRequest?: MedicationRequest;
  onClose: () => void;
}

export type Option = { label: string | undefined; value: string | undefined; group?: string; routes?: Coding[] } | null;

export enum PeriodUnitFhirEnum {
  MINUTE = 'min',
  HOUR = 'h',
  DAY = 'd',
  WEEK = 'wk',
  MONTH = 'mo'
}

export enum DayOfWeekFhirEnum {
  MONDAY = 'mon',
  TUESDAY = 'tue',
  WEDNESDAY = 'wed',
  THRUSDAY = 'thu',
  FRIDAY = 'fri',
  SATURRDAY = 'sat',
  SUNDAY = 'sun'
}

export type MedicationRequestFormState = {
  rxnumber: string;
  barcode: string;
  location: Option;
  medication: Option;
  route: Option;
  reasonForUse: Option;
  startDate: Date;
  startHour: string;
  endDate: Date;
  endHour: string;
  requester: string;
  note: string;
  selfAdministered: boolean;
  allowPRN: boolean;
  refills: number;
  inventory: number;
  schedule: {
    interval_amount: number;
    interval_type: PeriodUnitFhirEnum;
    frequency: number;
    times: {
      index: number;
      time: string;
      quantity: number;
    }[];
  };
}
