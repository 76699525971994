import { Alert } from '@mui/material';
import { useParams } from 'react-router';
import { useOrganizations } from 'src/@nicheaim/fhir-react';
import fhirSystem from 'src/fhir/system';
import NewPlanForm from '../new';

export default function EditPLan() {
  const { plan: planId = '' } = useParams();
  const [[plan], { isLoading }] = useOrganizations({
    filter: {
      identifier: fhirSystem.healthPlan.forCode(planId),
    },
  });

  if (!plan && !isLoading) return <Alert severity="error">Plan not found</Alert>;
  return <NewPlanForm isEdit currentPlan={plan!} />;
}
