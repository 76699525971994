import {
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import Iconify from 'src/components/Iconify';
import fhirSystem from 'src/fhir/system';
import { IPlanProgramRow, Membership, ProgramEnrollment } from './PlansDetails';
import { Group } from 'src/@nicheaim/fhir-base/mappings/Group';

interface IContextMenuProps {
  row: IPlanProgramRow;
  handleEdit: (row: IPlanProgramRow) => void;
}

function ContextMenu({ row, handleEdit }: IContextMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  return (
    <TableCell align="right">
      <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
        <MenuItem
          onClick={() => {
            handleEdit(row);
            closeMenu();
          }}
        >
          <Stack direction="row" spacing={2}>
            <Iconify icon="eva:edit-fill" />
            <Typography variant="body2">Edit</Typography>
          </Stack>
        </MenuItem>
      </Menu>

      <IconButton onClick={openMenu}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
    </TableCell>
  );
}

interface IPlansDetailsExpandedProps {
  actionBar: JSX.Element;
  patientId: string;
  enrolledPrograms: Membership[];
  hideContextMenu?: boolean;
  handleEdit?: (row: IPlanProgramRow) => void;
}

const PlansDetailsExpanded = ({
  actionBar,
  patientId,
  enrolledPrograms,
  hideContextMenu,
  handleEdit,
}: IPlansDetailsExpandedProps) => (
    <Card>
      <Grid container sx={{ p: 4 }}>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 2, width: '100%' }}
          xs={12}
        >
          <Grid item>
            <Typography variant="subtitle1">Plans / Programs Details</Typography>
          </Grid>
          <Grid item>{actionBar}</Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Plan ID</TableCell>
                  <TableCell>Program ID</TableCell>
                  <TableCell>Plan / Program</TableCell>
                  <TableCell>Dates</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enrolledPrograms?.map((programEnrollment) => (
                  <ProgramEnrollment
                    key={programEnrollment.membership.id}
                    expanded
                    membership={programEnrollment}
                    patientId={patientId}
                    hideContextMenu={hideContextMenu}
                    handleEdit={handleEdit}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Card>
  );

export default PlansDetailsExpanded;
