import SummaryTable from './SummaryTable';
import useTable from 'src/hooks/useTable';
import { useEffect, useState } from 'react';
import { caseService } from 'src/crs/case/service';
import { PaginateQuery } from 'src/api/pagination/dtos';
import { caseDtoToCaseManager } from 'src/sections/crs/common/common-utils';
import { TABLE_HEAD_CASE_PATIENT } from 'src/sections/crs/common/table-head';

const URL_API = `${process.env.REACT_APP_FHIR_API_BASE_URL}/Patient`;



export default function PatientCase({ patientId }: any) {

  const [ caseData, setCaseData ] = useState <any | undefined | null>(null);
  const [refresh, setRefresh] = useState(false);

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } =
  useTable({
    defaultRowsPerPage: 5,
    defaultOrderBy: 'id',
    initialIndex: 1,
    defaultCurrentPage: 1,
  });

  const getPaginateQuery = () => {
    const paginationQuery: PaginateQuery = { page: page, limit: rowsPerPage };
    paginationQuery.filter = { indexedSubjectFhirRefUri: `${URL_API}/${patientId}`};
    return paginationQuery;
  };

  const getCaseList = async () => {
    const cases = await caseService.getAll(getPaginateQuery());
    if (cases) {
      setCaseData(cases);
    }
  };

  useEffect(() => {
    getCaseList();
  }, [page, rowsPerPage, patientId]);

  useEffect(() => {
    if(refresh){
      getCaseList();
      setRefresh(false);
    }
  }, [refresh, caseData]);

  const handlePageChange = (event: unknown, newPage: number) => {
    onChangePage(event, newPage + 1);
  };

  const handleRefresh = () => {
    setRefresh(true);
  };

  const data = caseData?.data ? caseData.data.map((r:any) => caseDtoToCaseManager(r)) : [];

  return(
    <SummaryTable 
      title='Cases'
      columns={TABLE_HEAD_CASE_PATIENT}
      data={data}
      page={page}
      count={caseData ? caseData.meta.totalItems : 0}
      rowsPerPage={rowsPerPage}
      workflowType={'CASES_WORKFLOW'}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={onChangeRowsPerPage}
      handleRefresh={handleRefresh}
    />
  );
};