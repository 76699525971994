import { LoadingButton } from '@mui/lab';
import { Button, IconButton, Stack, Switch, Tooltip } from '@mui/material';
import useCarePlanAutomationFormStore from './store';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/Iconify';

function FormActions() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const loading = useCarePlanAutomationFormStore((s) => s.loading);
  const enabled = useCarePlanAutomationFormStore((s) => s.enabled);
  const setEnabled = useCarePlanAutomationFormStore((s) => s.setEnabled);
  const saveChanges = useCarePlanAutomationFormStore((s) => s.save);
  const reset = useCarePlanAutomationFormStore((s) => s.reset);
  const clearRules = useCarePlanAutomationFormStore((s) => s.clearRules);

  async function save() {
    if (!(await saveChanges())) {
      const [firstError] = useCarePlanAutomationFormStore.getState().errors[0];
      enqueueSnackbar('Please fix the validation errors before saving: ' + firstError, {
        variant: 'error',
      });
      return;
    }

    enqueueSnackbar('Changes saved successfully.');
  }

  return (
    <Stack direction="row-reverse" sx={{ mb: 2 }} justifyContent="space-between">
      <Stack direction="row-reverse" gap={1} alignItems="center">
        <LoadingButton
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={save}
          loading={loading}
          startIcon={<Iconify icon="eva:save-outline" />}
        >
          Save
        </LoadingButton>

        <Tooltip title="Discard changes">
          <IconButton onClick={() => reset()} disabled={loading}>
            <Iconify icon="system-uicons:reset" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Clear rules">
          <IconButton onClick={() => clearRules()} disabled={loading}>
            <Iconify icon="icon-park-outline:clear" />
          </IconButton>
        </Tooltip>
        <Tooltip title="If disabled, no rules will be executed when running the automation.">
          <Switch
            checked={enabled}
            onChange={(e) => setEnabled(e.target.checked)}
            size="small"
            disabled={loading}
          />
        </Tooltip>
      </Stack>

      <Button color="error" onClick={() => navigate('..')} disabled={loading}>
        Cancel
      </Button>
    </Stack>
  );
}

export default FormActions;
