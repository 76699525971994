import client from './_client';

const baseURL = 'crs/referral';

export interface ReferralStatusStatistics {
  closed: number;
  open: number;
  rejected: number;
  unspecified: number;
}

export interface ReferralCreationDateStatistics {
  month: number;
  year: number;
  numOfRecords: number;
}
export interface ReferralStatistics {
  status: ReferralStatusStatistics;
  creationDate: ReferralCreationDateStatistics[];
}

export const getReferralStatistics = async (): Promise<ReferralStatistics> => {
  const { data } = await client.get<ReferralStatistics>(`${baseURL}/statistics/all`);
  return data;
};
