import axios, { AxiosInstance } from 'axios';
import Cookies from 'js-cookie';
import { HOST_API } from 'src/config';
import { FhirClient } from '../index';

export const FHIR_COOKIE_KEY = 'fhir-cookie';

async function getFhirAccessToken() {
  const baseApiUrl = HOST_API;
  const accessToken = Cookies.get('careflowToken');

  let headers: any = {};

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  let res;

  try {
    res = await axios.get(baseApiUrl + '/auth/fhir-token', {
      headers: headers,
    });
  } catch (e) {
    console.log(e);
  }

  if (res?.data?.access_token) {
    return res.data;
  }

  return undefined;
}

export function fhirCookieExists() {
  return Cookies.get(FHIR_COOKIE_KEY) ? true : false;
}

export function getFhirCookie() {
  return Cookies.get(FHIR_COOKIE_KEY);
}

export async function setFhirCookie() {
  const response = await getFhirAccessToken();

  if (response === undefined || response.access_token === undefined || response.exp === undefined)
    return;

  const expDate = new Date(response.exp * 1000);

  Cookies.set(FHIR_COOKIE_KEY, response.access_token, {
    expires: expDate,
  });
}

export function createAxiosFhirClient(client: string | AxiosInstance): FhirClient {
  const instance =
    typeof client === 'string'
      ? axios.create({
          baseURL: client,
        })
      : client;

  // make sure content type is always set to application/fhir+json
  instance.defaults.headers.common['Content-Type'] = 'application/fhir+json';
  instance.interceptors.request.use(
    async function (config) {
      if (!fhirCookieExists()) {
        await setFhirCookie();
      }

      if (config.headers === undefined) {
        config.headers = {};
      }

      config.headers.Authorization = 'Bearer ' + getFhirCookie();
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return {
    async findAll(resourceType, filter) {
      const searchParams = new URLSearchParams();

      for (const key in filter) {
        if (Array.isArray(filter[key])) {
          searchParams.delete(key);
          filter[key].forEach((value: any) => searchParams.append(key, value));
        } else if (typeof filter[key] === 'object') {
          filter[key] = JSON.stringify(filter[key]);
        } else if (filter[key] !== undefined) {
          searchParams.append(key, filter[key]);
        }
      }

      const response = await instance.get(resourceType, {
        params: searchParams,
      });
      return response.data;
    },
    async findById(resourceType, id) {
      const response = await instance.get(`${resourceType}/${id}`);
      return response.data;
    },
    async createOne(resourceType, resource) {
      const response = await instance.post(resourceType, resource);
      return response.data;
    },
    async updateOne(resourceType, resource) {
      const response = await instance.put(`${resourceType}/${resource.id}`, resource);
      return response.data;
    },
    async patchOne(resourceType, resourceId, patch) {
      const response = await instance.patch(`${resourceType}/${resourceId}`, patch, {
        headers: {
          'Content-Type': 'application/json-patch+json',
        },
      });
      return response.data;
    },
    async removeOne(resourceType, resourceId) {
      const response = await instance.delete(`${resourceType}/${resourceId}`, { data: null });
      return response.data;
    },
  };
}
