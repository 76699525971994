import { useCallback, useState } from "react";
import {
  Grid,
  Tabs,
  Tab,
  Card,
  Modal,
} from "@mui/material";
import TabItem from "../../types/TabItem";
import SessionTable, { SessionsFilterEnum } from "../SessionTable/SessionTable";
import GroupSessionType from "../../types/GroupSessionType";
import { useNavigate } from "react-router";

const TAB_VALUES: TabItem[] = [
{
  key: 0,
  value: SessionsFilterEnum.COMPLETED as string,
  label: 'Active',
},
{
  key: 1,
  value: SessionsFilterEnum.COMPLETED_AND_CANCELLED as string,
  label: 'Completed/Cancelled',
},
];

type GroupSessionDetailTablePropsType = {
  item: GroupSessionType;
}

export const DetailTable = ({
  item
}: GroupSessionDetailTablePropsType) => {
  const navigate = useNavigate();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(TAB_VALUES[0].key);
  
  const renderTabs = useCallback(() => {
    ;
    return (
      <Tabs
        value={selectedTabIndex}
        variant="scrollable"
        scrollButtons="auto"
      >
        {TAB_VALUES?.map((tab) => (
          <Tab
            key={tab.key}
            label={tab.label}
            onClick={() => {
              setSelectedTabIndex(tab.key)
            }}
            id={`status-tab-${tab.value}`}
            {
              ...{
                'aria-controls': `status-tabpanel-${tab.key}`,
              }
            }
          />
        ))}
      </Tabs>
    )
  }, [selectedTabIndex]);

  return (
    <>
      <Card sx={{ marginBottom: 2, marginTop: 3 }}>
        <Grid sx={{ padding: 2 }}>
          <Grid justifyContent="space-between" container direction="row">
            <Grid item xl={9}>
              {renderTabs()}
            </Grid>
          </Grid>
        </Grid>
      </Card>
      {(selectedTabIndex !== TAB_VALUES[1].key) && (
        <SessionTable
          title="Scheduled"
          item={item}  
          filter={SessionsFilterEnum.SCHEDULED}   
        />
      )}

      {(selectedTabIndex !== TAB_VALUES[1].key) && (
        <SessionTable
          title="Incomplete"
          item={item}  
          filter={SessionsFilterEnum.INCOMPLETE}   
        />
      )}

      {(selectedTabIndex !== TAB_VALUES[1].key) && (
        <SessionTable
          title="Past Due"
          item={item}  
          filter={SessionsFilterEnum.PAST_DUE}   
        />
      )}

      {selectedTabIndex === TAB_VALUES[1].key && (
        <SessionTable
          title="Completed/Cancelled"
          item={item}  
          filter={SessionsFilterEnum.COMPLETED_AND_CANCELLED}   
        />
      )}
    </>
  )
}