import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import Label from 'src/components/Label';
import DataTable, { DataTableColumn } from 'src/components/table/DataTable';
import api from 'src/services/api';
import { CreatedUpdated } from '../common';
import InterventionsContextMenu from './InterventionsContextMenu';

const columns: DataTableColumn[] = [
  {
    header: 'Intervention',
    field: 'name',
  },
  {
    header: 'Alias Name',
    field: 'alias',
  },
  {
    header: 'Status',
    field: 'status',
    render(value) {
      return (
        <Label color={value === 'active' ? 'success' : 'info'}>
          {value === 'active' ? 'Active' : 'Inactive'}
        </Label>
      );
    },
  },
  {
    header: 'Appointment Alert',
    field: 'createsAppointmentAlert',
    render(value) {
      return <Typography color={value ? 'green' : 'red'}>{value ? 'Yes' : 'No'}</Typography>;
    },
  },
  {
    header: 'Created',
    field: '$',
    render(value) {
      return <CreatedUpdated by={value.createdBy} date={value.createdOn} />;
    },
  },
  {
    header: 'Updated',
    field: '$',
    render(value) {
      return <CreatedUpdated by={value.updatedBy} date={value.updatedOn} />;
    },
  },
];

export default function InterventionsTable() {
  const { data: interventions = [], refetch } = useQuery(
    ['interventions'],
    async () =>
      (await api.carePlan.findInterventions({
        status: '',
      })) as any[]
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <DataTable
        columns={columns}
        data={interventions}
        selectable
        rowContextMenu={(item: any) => (
          <InterventionsContextMenu
            item={item}
            onDelete={() => {
              refetch();
            }}
          />
        )}
      />
    </div>
  );
}
