// @ts-nocheck
import { TableRow, TableCell, Typography, Box, Stack, IconButton, TextField } from '@mui/material';
import SessionParticipantType from '../../types/SessionParticipantType';
import Iconify from 'src/components/Iconify';
import { EntityContextMenu, PatientCard } from 'src/sections/careflow/common';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { SessionTableContext } from '../SessionTable/DetailTableContext';
import useObjectState from 'src/hooks/useObjectState';
import api from 'src/services/api';
import usePatientTabsStore from 'src/stores/patient-tabs';

type SessionTablePropsType = {
  item: SessionParticipantType;
};

const SessionUserItem = ({ item }: SessionTablePropsType) => {
  const patientStore = usePatientTabsStore();
  const [{ isEditingNote, note }, updateState] = useObjectState({
    isEditingNote: false,
    note: '',
  });
  const detailTableContext = useContext(SessionTableContext);
  const navigate = useNavigate();
  console.log({
    ass: item.sessionParticipantAssessments,
  });
  const areAssessmentsCompleted: boolean = item.sessionParticipantAssessments
    .filter((a) => a.groupSessionAssessment.isRequired)
    .every((i) => i.isCompleted)
    ? true
    : false;

  const saveSessionParticipantNote = async (id: number) => {
    const sessionParticipant = await api.groupSessions.updateSessionParticipantNote(id, note);

    if (!sessionParticipant) return;

    detailTableContext.updateSessionParticipant(sessionParticipant);
    updateState({
      isEditingNote: false,
      note: '',
    });
  };

  return (
    <TableRow>
      <TableCell>
        <PatientCard
          id={getFhirIdFromEntity(item.participant.patient.fhirRefUri ?? '')}
          showCloseButton={false}
        />
      </TableCell>
      <TableCell>
        <Box flexDirection="row">
          <Box
            sx={{
              height: '30px',
              fontWeight: 'bold',
              fontSize: 'smaller',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              maxWidth: 120,
              paddingLeft: '10px',
              backgroundColor: areAssessmentsCompleted ? '#00b894' : '#d63031',
              color: 'white',
            }}
          >
            {item.sessionParticipantAssessments.length > 0 ? (
              <>
                {areAssessmentsCompleted ? 'Completed' : 'Incomplete'}
                <EntityContextMenu
                  color="white"
                  entity={item}
                  items={item.sessionParticipantAssessments.map((a) => ({
                    label: `${a.groupSessionAssessment?.assessmentFhirName} - ${
                      a.isCompleted ? 'Completed' : 'Incomplete'
                    } (${a.groupSessionAssessment.isRequired ? 'Required' : 'Optional'})`,
                    value: a.groupSessionAssessment?.assessmentFhirId,
                    icon: 'ic:outline-launch',
                    onPress: () => {
                      const patientFhirId = getFhirIdFromEntity(item.participant.patient.fhirRefUri);
                      
                      patientStore.setCurrentPatient(patientFhirId ?? '');
                      patientStore.openPatient(patientFhirId ?? '');

                      navigate(`/dashboard/patient/${patientFhirId}/care-record/assessments/new/${a.groupSessionAssessment.assessmentFhirId}`, {
                        state: {
                          sessionParticipantAssessmentId: a.id,
                        }
                      });
                    },
                    rules: () => true,
                  }))}
                />
              </>
            ) : (
              <Typography fontSize={13}>No assessments</Typography>
            )}
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        <TableCell sx={{ width: '300px' }}>
          <Stack direction="row" spacing={2}>
            {isEditingNote ? (
              <TextField
                value={note}
                onChange={(e) =>
                  updateState({
                    note: e.target.value,
                  })
                }
                sx={{
                  top: -10,
                  left: -25,
                  height: '10px',
                }}
              />
            ) : (
              <Typography variant="body2" sx={{ width: '200px', marginTop: '7px' }}>
                {item.note || 'N/A'}
              </Typography>
            )}

            <IconButton sx={{ top: '-7px' }}>
              {isEditingNote && note === item.note ? (
                <Iconify
                  icon={'material-symbols:close-rounded'}
                  fontSize={25}
                  onClick={() =>
                    updateState({
                      note: '',
                      isEditingNote: false,
                    })
                  }
                />
              ) : (
                <Iconify
                  icon={isEditingNote ? 'mdi:content-save-check-outline' : 'eva:edit-2-outline'}
                  fontSize={25}
                  onClick={() => {
                    if (isEditingNote) {
                      saveSessionParticipantNote(item.id);
                    } else {
                      updateState((prev) => ({
                        note: item.note,
                        isEditingNote: !prev.isEditingNote,
                      }));
                    }
                  }}
                />
              )}
            </IconButton>
          </Stack>
        </TableCell>
      </TableCell>
      <TableCell>
        <Iconify
          icon="material-symbols:check-circle-outline-rounded"
          sx={{
            marginRight: 1,
            height: 50,
            width: 50,
            cursor: 'pointer',
          }}
          color={item.attended ? '#00b894' : '#b2bec3'}
          onClick={() => {
            api.groupSessions
              .toggleSessionParticipantAttended(item.id)
              .then((response: SessionParticipantType) => {
                detailTableContext.updateSessionParticipant(response);
              });
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default SessionUserItem;
