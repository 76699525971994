import moment from "moment";
import { useState } from "react";
import { capitalCase } from "change-case";
import Iconify from "src/components/Iconify";
import { AppointmentForm } from "./AppointmentForm";
import { TableMoreMenu } from "src/components/table";
import { Box, Chip, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { WrappedAppointment } from "src/@nicheaim/fhir-base/wrappers/Appointment"

type Props = {
  row: WrappedAppointment | null;
  patient: WrappedPatient | null;
  handleAppointment: (data: any) => Promise<any>;
}

export function Row({
  row,
  patient,
  handleAppointment
}: Props){

  const [ data, setData ] = useState(null);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleEdit = (row: any) => {
    handleCloseMenu();
    setData(row);
    setOpenModalEdit(true);
  };

  return(
    <>
      <TableRow>
        <TableCell>
          {row?.status && capitalCase(row?.status)}
        </TableCell>
        <TableCell>
          <Box sx={{ maxWidth: 300 }}>
            <Typography style={{ cursor: 'pointer' }} noWrap sx={{ fontSize: `0.75rem` }}>
              {row?.description}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          {row?.start && moment(row?.start).format('MM/DD/YYYY')}
        </TableCell>
        <TableCell>
          {row?.end && moment(row?.end).format('MM/DD/YYYY')}
        </TableCell>
        <TableCell>
          <Box sx={{ maxWidth: 200 }}>
          {row?.participant?.map((e: any, index: number) => (
            <Chip 
              clickable={false}
              size="small" 
              key={index} 
              label={e?.actor?.display} 
              sx={{ m: 0.2 }} 
            />
          ))}
          </Box>
        </TableCell>
        <TableCell>
          {row?.created && moment(row?.created).format('MM/DD/YYYY')}
        </TableCell>
        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={[
              <MenuItem key={'menu-i-edit-'} onClick={() => handleEdit(row)}>
                <Iconify icon={'mdi:pencil'} />
                Edit
              </MenuItem>
            ]}
          />
        </TableCell>
      </TableRow>
      <AppointmentForm 
        patient={patient}
        appointment={data}
        open={openModalEdit}
        onClose={()=> setOpenModalEdit(false)}
        handleAppointment={handleAppointment}
      />
    </>
  )
}