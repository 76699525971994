import { Activity, ActivityLegends } from 'src/sections/careflow/tasks-activities/model';
import {
  ACTIVITY_LEGENDS,
  isActivityLegend,
} from 'src/sections/careflow/tasks-activities/model/ActivityLegends';
import { ActivityRenderer } from './ActivityRenderer';
import { chain } from 'lodash';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { styled } from '@mui/material/styles';
import { ListItemIcon, Tooltip, Typography, Box } from '@mui/material';

const ICON_PROPERTIES = {
  width: 15,
  height: 15,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& svg': { width: '100%', height: '100%' },
};

const COLORS_BY_LEGEND: Record<ActivityLegends, { bgColor: string; textColor: string }> = {
  [ActivityLegends.NEW]: { bgColor: '#78b0e863', textColor: '#00000090' },
  [ActivityLegends.READY]: { bgColor: '#78b0e863', textColor: '#00000090' },
  [ActivityLegends.COMPLETED]: { bgColor: '#000000', textColor: '#ffffff' },
  [ActivityLegends.CANCELLED]: { bgColor: '#919EAB63', textColor: '#00000090' },

  [ActivityLegends.PAST_DUE]: { bgColor: '#DB2B3963', textColor: '#00000090' },
  [ActivityLegends.DUE_TODAY]: { bgColor: '#FFA40063', textColor: '#00000090' },
  [ActivityLegends.FUTURE_TASK]: { bgColor: '#98CE0063', textColor: '#00000090' },
};

interface ActivityLegendDetails {
  status: string;
  description?: string;
  icon: any;
  getLabel: any;
}

export const ACTIVITY_LEGEND_DETAILS: Record<string, ActivityLegendDetails> = chain(
  Object.entries(COLORS_BY_LEGEND) as [ActivityLegends, { bgColor: string; textColor: string }][]
)
  .map(([status, color]) => {
    const description = ACTIVITY_LEGENDS.get(status || '')?.description;
    return {
      status,
      description,
      icon: getFlagIcon(color.bgColor, description),
      getLabel: getLegendLabel(color.bgColor, color.textColor, description),
    };
  })
  .keyBy('status')
  .value();

function getFlagIcon(color: string, statusDescription?: string) {
  const IconStyle = styled(ListItemIcon)({ ...ICON_PROPERTIES, color });
  return (
    <Tooltip title={statusDescription || ''}>
      <IconStyle>
        <SvgIconStyle src={`/assets/icons/indicators/ic_flag.svg`} />
      </IconStyle>
    </Tooltip>
  );
}

function getLegendLabel(bgColor: string, textColor: string, statusDescription?: string) {
  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        borderRadius: '10px',
        padding: '5px',
        maxWidth: '100px',
        marginTop: '5px',
        marginLeft: '5px',
      }}
    >
      <Typography color={textColor} textAlign="center" fontSize="11px" fontWeight="bold">
        {statusDescription}
      </Typography>
    </Box>
  );
}

// TODO: Extract to a component
export class ActivityLegendRenderer implements ActivityRenderer {
  constructor(
    private readonly activity: Omit<
      Activity,
      'toJSON' | 'isPastDue' | 'containsDay' | 'isInRange' | 'updateStatus'
    >
  ) {}

  render(showLabel: boolean = false): any {
    if (!this.activity.legend) {
      return null;
    }

    const currentLegend = ACTIVITY_LEGEND_DETAILS[this.activity.legend];
    if (showLabel) {
      return currentLegend.getLabel;
    }
    return currentLegend.icon;
  }
}
