import {
  Box,
  IconButton,
  Stack,
  Typography,
  Card,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useQuestionnaireResponse } from 'src/@nicheaim/fhir-react';
import { useNavigate, useParams } from 'react-router';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ASSESSMENTS_BASE_URL } from 'src/config';
import styled from '@emotion/styled';
import { useCurrentPatient } from 'src/stores/patient-tabs';
import { useActiveAssessments, useAllAssessments } from 'src/fhir/hooks/assessments';

interface FillAssessmentProps {
  onZoom?: () => void;
  onSurveyCompleted?: () => void;
}

// TODO: Prevent filling out inactive assessments
export default function FillAssessment({ onZoom, onSurveyCompleted }: FillAssessmentProps) {
  const patient = useCurrentPatient();
  const navigate = useNavigate();
  const { assessment: assessmentId = '' } = useParams();
  const [questionnaireResponse] = useQuestionnaireResponse(assessmentId);
  const limeSurveyId = questionnaireResponse?.identifier?.system?.split('/').at(-1);
  const token = questionnaireResponse?.identifier?.value;

  const [availableAssessments = []] = useAllAssessments({
    filter: {
      identifier: limeSurveyId,
    },
    pagination: {
      pageSize: 1000,
    },
    autofetch: !!limeSurveyId,
  });

  const title = availableAssessments.find((a) =>
    a.identifier?.some((i) => i.value === limeSurveyId)
  )?.title;

  return (
    <Card sx={{ height: `calc(100vh - 200px)`, display: 'flex', flexDirection: 'column' }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2 }}>
        <Typography variant="subtitle1">{title}</Typography>
        <Box>
          <IconButton>
            <Iconify icon="eva:search-fill" />
          </IconButton>
          <IconButton onClick={() => onZoom?.()}>
            <Iconify icon="eva:expand-fill" />
          </IconButton>
          <IconButton
            onClick={() => navigate(`/dashboard/patient/${patient?.id}/care-record/assessments`)}
          >
            <Iconify icon="material-symbols:close-rounded" />
          </IconButton>
        </Box>
      </Stack>

      <RunningAssessment
        assessmentId={limeSurveyId || ''}
        token={token || ''}
        onSurveyCompleted={onSurveyCompleted}
      />
    </Card>
  );
}

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  margin-bottom: 10px;
`;

interface RunningAssessmentProps {
  assessmentId: string;
  token: string;
  onSurveyCompleted?: () => void;
}

function RunningAssessment({ assessmentId, token, onSurveyCompleted }: RunningAssessmentProps) {
  const [loaderVisible, setLoaderVisible] = useState(true);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframeUrl = useMemo(() => {
    const url = new URL(`${ASSESSMENTS_BASE_URL}/survey/index/sid/${assessmentId}`);

    url.searchParams.append('newtest', 'Y');

    const redirectUrl = new URL(window.location.href);
    redirectUrl.search = '';
    redirectUrl.hash = '';
    redirectUrl.pathname = `/survey-saved`;
    url.searchParams.append('redirect', redirectUrl.toString());

    if (token) {
      url.searchParams.append('token', token);
    }

    return url.toString();
  }, [assessmentId, token]);

  function showLoader() {
    setLoaderVisible(true);
  }

  function hideLoader() {
    setLoaderVisible(false);
  }

  function onIframeLoad() {
    const iframeWindow = iframeRef.current?.contentWindow;
    if (!iframeWindow) {
      return;
    }

    hideLoader();
    iframeWindow.onbeforeunload = showLoader;
  }

  useEffect(
    function injectLoaderOnSubmit() {
      const iframeDocument = iframeRef.current?.contentDocument;
      if (!iframeDocument) {
        return;
      }

      const submitButton = iframeDocument.querySelector('#ls-button-submit[value="movesubmit"]');
      if (!submitButton) {
        return;
      }

      const onSubmit = function (e: Event) {
        showLoader();
      };

      submitButton.addEventListener('click', onSubmit);

      return () => {
        submitButton.removeEventListener('click', onSubmit);
      };
    },
    [iframeRef.current]
  );

  useEffect(() => {
    function onMessage(e: MessageEvent) {
      if (e.data === 'saved') {
        onSurveyCompleted?.();
      }
    }

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);
  return (
    <Box position="relative" flex={1}>
      <Backdrop open={loaderVisible} sx={{ position: 'absolute' }}>
        <CircularProgress />
      </Backdrop>
      <Iframe
        ref={iframeRef}
        key={iframeUrl}
        src={iframeUrl}
        title="Running assessment"
        onLoad={onIframeLoad}
      />
    </Box>
  );
}
