import { Grid, Stack, Typography } from '@mui/material';
import { WrappedTask } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { WrappedServiceRequest } from 'src/@nicheaim/fhir-base/wrappers/ServiceRequest';
import { useEffect, useState } from 'react';
import { getSeverityDueDateData } from '../../helpers/getSeverityDueDateData';
import moment from 'moment';
import { WrappedCarePlan } from 'src/@nicheaim/fhir-base/wrappers/CarePlan';
import { carePlan } from 'src/_mock/_careflow';

type Props = {
  tasks: WrappedTask[] | null;
  serviceRequest: WrappedServiceRequest[] | null;
  carePlan: WrappedCarePlan | null;
};

interface TaskInformation {
  open: WrappedTask[];
  pastDue: WrappedTask[];
  dueIn2Days: WrappedTask[];
}

const CaseSummary = ({ tasks, serviceRequest, carePlan }: Props) => {
  const activeOutboundReferral = serviceRequest?.filter((e) => e.status === 'active').length;
  const [taskInformation, setTaskInformation] = useState<TaskInformation>({
    open: [],
    pastDue: [],
    dueIn2Days: [],
  });
  const [goalsCount, setGoalsCount] = useState(0);

  useEffect(() => {
    const taskInformation: TaskInformation = {
      open: [],
      pastDue: [],
      dueIn2Days: [],
    };
    tasks?.map?.((task) => {
      const endDate = moment(task?.executionPeriod?.end ?? null);
      const { isPastDue, timeDiff, timeFactor } = getSeverityDueDateData(endDate);
      if (isPastDue) {
        taskInformation.pastDue.push(task);
      } else {
        taskInformation.open.push(task);
        if (timeFactor === 'day' && timeDiff === 2) taskInformation.dueIn2Days.push(task);
      }
    });
    setTaskInformation(taskInformation);
  }, [tasks]);

  useEffect(() => {
    const groupedGoals = carePlan?.getGroupedGoals?.();
    let goalsCount = 0;
    if (groupedGoals) goalsCount = Object.keys(groupedGoals).length;

    setGoalsCount(goalsCount);
  }, [carePlan]);

  return (
    <Stack direction="row" sx={{ m: 4 }}>
      <Grid item xs={2.4} sx={{ backgroundColor: '#008ecce6', p: 1 }}>
        <Typography sx={{ color: 'white' }}>Pathways: {goalsCount}</Typography>
      </Grid>
      <Grid item xs={2.4} sx={{ backgroundColor: '#523249e6', p: 1 }}>
        <Typography sx={{ color: 'white' }}>Open tasks: {taskInformation.open.length}</Typography>
      </Grid>
      <Grid item xs={2.4} sx={{ backgroundColor: '#dc3545e6', p: 1 }}>
        <Typography sx={{ color: 'white' }}>
          Tasks past due: {taskInformation.pastDue.length}
        </Typography>
      </Grid>
      <Grid item xs={2.4} sx={{ backgroundColor: '#b69329e6', p: 1 }}>
        <Typography sx={{ color: 'white' }}>
          Tasks due in 2 Days: {taskInformation.dueIn2Days.length}
        </Typography>
      </Grid>
      <Grid item xs={2.4} sx={{ backgroundColor: '#0b3954e6', p: 1 }}>
        <Typography sx={{ color: 'white' }}>
          Active outbound ref: {activeOutboundReferral ? activeOutboundReferral : '0'}
        </Typography>
      </Grid>
    </Stack>
  );
};

export default CaseSummary;
