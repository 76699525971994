import create from 'zustand';
import { ActivityDueStatusType, ActivityStatusType } from './constants';

type State = {
  status: ActivityStatusType;
  dueStatus: ActivityDueStatusType;
};

type Actions = {
  setStatus: (status: ActivityStatusType) => void;
  setDueStatus: (dueStatus: ActivityDueStatusType) => void;
};

export const useTaskActivitiesStore = create<State & Actions>((set) => ({
  status: 'all',
  dueStatus: 'all',

  setStatus: (status) => {
    if (['completed', 'cancelled'].includes(status)) {
      set({ dueStatus: 'all' });
    }

    set({ status });
  },
  setDueStatus: (dueStatus) => set({ dueStatus }),
}));
